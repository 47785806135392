import React from 'react';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { getApiData,postApiData, getAgentId} from '../../services/Api';
import { Redirect } from 'react-router';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';



class filterList extends React.Component {
    constructor(props) {
        super(props);
        //console.log("props=>",props);
		this.state={
                  locationFilter:[],
                  zipList:[],
                  zipFilter:'',
                  zip_text_box_error:'',
                  maxPrice:-1,
                  minPrice:-2,
                  locationBed:[],
                  bathrooms:[{'layout':0.00},{'layout':1.00},{'layout':1.50},{'layout':2.00},{'layout':2.50},{'layout':3.00},{'layout':3.50},{'layout':4.00},{'layout':4.50},{'layout':5.00}],
                  selectedBedRooms:'',
                  selectedBathRooms:'',
                  filterCompany:[],
                  filterBuilding:[],
                  selectedCompany:'',
                  selectedBuilding:[],
                  selectedBuildingId:'',
                  selectedBuildingname:'',
                  makingOfFilters:[],
                  buildingList:'',
                  buildingListvalue:'',
                  amenitiesList:'',
                  amenitiesListvalue:'',
                  filterCity:[],
                  selectedCity:'',
                  selectedManhattan:'',
                  selectedNewJersey:'',
                  selectedQueens:'',
                  selectedStatenIsland:'',
                  selectedBronx:'',
                  selectedBrooklyn:'',
                  VR:0,
                  VR_checked:'',
                  selectAllManhattan:0,
                  selectAllBrooklyn:0,
                  selectAllBronx:0,
                  selectAllQueens:0,
                  selectAllStatenIsland:0,
                  selectAllNewJersey:0,
                  manhattanConut:0,
                  selectAllManhattanChecked:'',
                  selectAllBrooklynChecked:'',
                  selectAllQueensChecked:'',
                  selectAllBronxChecked:'',
                  selectAllStatenIslandChecked:'',
                  selectAllNewJerseyChecked:'',
                  showonly:0,
                  valueShowOnly:1,
                  selectedMedia:'',
                  error_price:'',
                  countOfManhattan:0,
                  countOfQueens:0,
                  countOfBrooklyn:0,
                  countOfBronx:0,
                  countOfStatenIsland:0,
                  countOfNewJersey:0,
                  locationCount:0,
                  searchParm:"",
                  buildingsearchdata:[]
                  
                }
         
        // to close the dropdown on clickoutside
    this.setWrapperRef1 = this.setWrapperRef1.bind(this);
    this.setWrapperRef2= this.setWrapperRef2.bind(this);
    this.setWrapperRef3 = this.setWrapperRef3.bind(this);
    this.setWrapperRef4 = this.setWrapperRef4.bind(this);
    this.setWrapperRef5 = this.setWrapperRef5.bind(this);
    this.setWrapperRef6 = this.setWrapperRef6.bind(this);
    this.setWrapperRef7 = this.setWrapperRef7.bind(this);
    
    this.handleClickOutside = this.handleClickOutside.bind(this);

    
        
    }

    resetFilter=()=>{ 
      //console.log("feed filter reset")
        this.setState({
          zipList:[],
          zipFilter:'',
          zip_text_box_error:'',
          maxPrice:-1,
          minPrice:-2,
          selectedBedRooms:'',
          selectedBathRooms:'',
          selectedCompany:'',
          selectedBuilding:[],
          selectedBuildingId:'',
          selectedBuildingname:'',
          buildingList:'',
          amenitiesList:'',
          selectedCity:'',
          selectedManhattan:'',
          selectedNewJersey:'',
          selectedQueens:'',
          selectedStatenIsland:'',
          selectedBronx:'',
          selectedBrooklyn:'',
          VR:0,
          VR_checked:'',
          selectAllManhattan:0,
          selectAllBrooklyn:0,
          selectAllBronx:0,
          selectAllQueens:0,
          selectAllStatenIsland:0,
          selectAllNewJersey:0,
          manhattanConut:0,
          selectAllManhattanChecked:'',
          selectAllBrooklynChecked:'',
          selectAllQueensChecked:'',
          selectAllBronxChecked:'',
          selectAllStatenIslandChecked:'',
          selectAllNewJerseyChecked:'',
          showonly:0,
          valueShowOnly:1,
          selectedMedia:'',
          error_price:'',
          countOfManhattan:0,
          countOfQueens:0,
          countOfBrooklyn:0,
          countOfBronx:0,
          countOfStatenIsland:0,
          countOfNewJersey:0,
          locationCount:0,
          searchParm:"",
          buildingsearchdata:[]
        },()=>{
         document.getElementById('companySearch').value="";
         document.getElementById('buildingSearch').value="";
         
      this.state.filterCompany.forEach(function(client,index) {
          client.flag=0;
      });
      this.state.filterBuilding.forEach(function(client,index) {
        client.flag=0;
      });
      this.state.locationBed.map((s,i)=>{
         document.getElementById('bed_'+i).checked=false;
      })
      this.state.bathrooms.map((s,i)=>{
        document.getElementById('bath_'+i).checked=false;
     })

      
         this.searchFeed();
         this.props.handleResetFilter('reset');
        })
    }
    
/**
 * make filter dyanamic
 */
componentWillMount =()=>{
    document.removeEventListener('mousedown', this.handleClickOutside);
}

componentDidMount =()=>{
  /**
     * MS-390
     */
    this.prepareFilterLocation();
    this.prepareFilterBed();
    this.prepareFilterCompany();
    this.prepareFilterBuilding();
    this.prepareFilterCity();
    this.MakeAdvanceFilter();
    this.getCityCount();
    /**
     * MS-309
     */
    document.addEventListener('mousedown', this.handleClickOutside);
}


/**
   * MS-390 Start
   */
  priceFilter=param=>event=>{
   // console.log("param",param);
    if(param[1]=='min'){
      
        this.setState({
          minPrice:param[0]
        });
    }else if(param[1]=='max'){
      this.setState({
        maxPrice:param[0]
      });
    }
  }
  SelectAll=param=>event=>{
    let value=param[0];
    let checkFlag='';
    switch(value){
      case 'Manhattan':
          checkFlag=this.state.selectAllManhattan;
          if(checkFlag==0){
            this.setState({
              selectedManhattan:''
            },()=>{
              let list='';
              let count=0;
              this.state.filterCity.map((item, index)=>{
                count=count+1;
                if(list==''){
                  if(item.city=='Manhattan'){
                    list=item.neighborhood;
                  }
                }else{
                  if(item.city=='Manhattan'){
                    list=list+','+item.neighborhood;
                  }
                }
              });

              if(this.state.filterCity.length==count){
                  this.setState({
                    selectedManhattan:list,
                    selectAllManhattan:1,
                    selectAllManhattanChecked:'checked'
                  },()=>{
                    this.locationCount();
                  })
              }
            })
          }else if(checkFlag==1){
            this.setState({
              selectedManhattan:'',
              selectAllManhattan:0,
              selectAllManhattanChecked:''
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'Brooklyn':
          checkFlag=this.state.selectAllBrooklyn;
          if(checkFlag==0){
            this.setState({
              selectedBrooklyn:''
            },()=>{
              let list='';
              let count=0;
              this.state.filterCity.map((item, index)=>{
                count=count+1;
                if(list==''){
                  if(item.city=='Brooklyn'){
                    list=item.neighborhood;
                  }
                }else{
                  if(item.city=='Brooklyn'){
                    list=list+','+item.neighborhood;
                  }
                }
              });

              if(this.state.filterCity.length==count){
                  this.setState({
                    selectedBrooklyn:list,
                    selectAllBrooklyn:1,
                    selectAllBrooklynChecked:'checked'
                  },()=>{
                    this.locationCount();
                  })
              }
            })
          }else if(checkFlag==1){
            this.setState({
              selectedBrooklyn:'',
              selectAllBrooklyn:0,
              selectAllBrooklynChecked:''
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'Bronx':
          checkFlag=this.state.selectAllBronx;
          if(checkFlag==0){
            this.setState({
              selectedBronx:''
            },()=>{
              let list='';
              let count=0;
              this.state.filterCity.map((item, index)=>{
                count=count+1;
                if(list==''){
                  if(item.city=='Bronx'){
                    list=item.neighborhood;
                  }
                }else{
                  if(item.city=='Bronx'){
                    list=list+','+item.neighborhood;
                  }
                }
              });

              if(this.state.filterCity.length==count){
                  this.setState({
                    selectedBronx:list,
                    selectAllBronx:1,
                    selectAllBronxChecked:'checked'
                  },()=>{
                    this.locationCount();
                  })
              }
            })
          }else if(checkFlag==1){
            this.setState({
              selectedBronx:'',
              selectAllBronx:0,
              selectAllBronxChecked:''
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'Queens':
          checkFlag=this.state.selectAllQueens;
          if(checkFlag==0){
            this.setState({
              selectedQueens:''
            },()=>{
              let list='';
              let count=0;
              this.state.filterCity.map((item, index)=>{
                count=count+1;
                if(list==''){
                  if(item.city=='Queens'){
                    list=item.neighborhood;
                  }
                }else{
                  if(item.city=='Queens'){
                    list=list+','+item.neighborhood;
                  }
                }
              });

              if(this.state.filterCity.length==count){
                  this.setState({
                    selectedQueens:list,
                    selectAllQueens:1,
                    selectAllQueensChecked:'checked'
                  },()=>{
                    this.locationCount();
                  })
              }
            })
          }else if(checkFlag==1){
            this.setState({
              selectedQueens:'',
              selectAllQueens:0,
              selectAllQueensChecked:''
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'Staten_Island':
          checkFlag=this.state.selectAllStatenIsland;
          if(checkFlag==0){
            this.setState({
              selectedStatenIsland:''
            },()=>{
              let list='';
              let count=0;
              this.state.filterCity.map((item, index)=>{
                count=count+1;
                if(list==''){
                  if(item.city=='Staten Island'){
                    list=item.neighborhood;
                  }
                }else{
                  if(item.city=='Staten Island'){
                    list=list+','+item.neighborhood;
                  }
                }
              });

              if(this.state.filterCity.length==count){
                  this.setState({
                    selectedStatenIsland:list,
                    selectAllStatenIsland:1,
                    selectAllStatenIslandChecked:'checked'
                  },()=>{
                    this.locationCount();
                  })
              }
            })
          }else if(checkFlag==1){
            this.setState({
              selectedStatenIsland:'',
              selectAllStatenIsland:0,
              selectAllStatenIslandChecked:''
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'New_Jersey':
          checkFlag=this.state.selectAllNewJersey;
          if(checkFlag==0){
            this.setState({
              selectedNewJersey:''
            },()=>{
              let list='';
              let count=0;
              this.state.filterCity.map((item, index)=>{
                count=count+1;
                if(list==''){
                  if(item.city=='New Jersey'){
                    list=item.neighborhood;
                  }
                }else{
                  if(item.city=='New Jersey'){
                    list=list+','+item.neighborhood;
                  }
                }
              });

              if(this.state.filterCity.length==count){
                  this.setState({
                    selectedNewJersey:list,
                    selectAllNewJersey:1,
                    selectAllNewJerseyChecked:'checked'
                  },()=>{
                    this.locationCount();
                  })
              }
            })
          }else if(checkFlag==1){
            this.setState({
              selectedNewJersey:'',
              selectAllNewJersey:0,
              selectAllNewJerseyChecked:''
            },()=>{
              this.locationCount();
            })
          }
      break;
      }
  }
  locationCount(){
   // console.log("this.state.selectedNewJersey",this.state.selectedNewJersey)
      let Manhattan=(this.state.selectedManhattan!='')?this.state.selectedManhattan.split(',').length:"0";
      let Brooklyn=(this.state.selectedBrooklyn!='')?this.state.selectedBrooklyn.split(',').length:"0";
      let Bronx=(this.state.selectedBronx!='')?this.state.selectedBronx.split(',').length:"0";
      let Queens=(this.state.selectedQueens!='')?this.state.selectedQueens.split(',').length:"0";
      let Staten_Island=(this.state.selectedStatenIsland!='')?this.state.selectedStatenIsland.split(',').length:"0";
      let New_Jersey=(this.state.selectedNewJersey!='')?this.state.selectedNewJersey.split(',').length:"0";
      
      let sum=parseInt(Manhattan)+parseInt(Brooklyn)+parseInt(Bronx)+parseInt(Queens)+parseInt(Staten_Island)+parseInt(New_Jersey);
      this.setState({
        locationCount:sum
      });

  }
  handleLocation=param=>event=>{
    let key=param[0];
    let value=param[1];
    let list='';
    let listValue=[];
    let flag=0;
    let count=0;
    switch(key){
      case 'Manhattan':
          list=this.state.selectedManhattan;
          if(this.state.selectedManhattan!=''){
            listValue=list.split(',')
          }
          let flag=0;
          let count=0;
          for(var i = 0 ; i < listValue.length ; i++) {
            count=count+1;
            if(listValue[i] == value) {
              listValue.splice(i, 1);
             // console.log("listValue",listValue);
              this.setState({
                selectedManhattan:listValue.sort().join(','),linkingLoader:false,selectAllManhattanChecked:'',selectAllManhattan:0
              },()=>{
                this.locationCount();
              })
              flag=1;
              break;
            }
          }
          if(listValue.length==count && flag==0){
            listValue.push(value);
            this.setState({
              selectedManhattan:listValue.sort().join(','),linkingLoader:false,
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'Brooklyn':
          list=this.state.selectedBrooklyn;
          listValue=[];
          if(this.state.selectedBrooklyn!=''){
            listValue=list.split(',')
          }
          flag=0;
          count=0;
          for(var i = 0 ; i < listValue.length ; i++) {
            count=count+1;
            if(listValue[i] == value) {
              listValue.splice(i, 1);
             // console.log("listValue",listValue);
              this.setState({
                selectedBrooklyn:listValue.sort().join(','),linkingLoader:false
              },()=>{
                this.locationCount();
              })
              flag=1;
              break;
            }
          }
          if(listValue.length==count && flag==0){
            listValue.push(value);
            this.setState({
              selectedBrooklyn:listValue.sort().join(','),linkingLoader:false,
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'Bronx':
          list=this.state.selectedBronx;
          listValue=[];
          if(this.state.selectedBronx!=''){
            listValue=list.split(',')
          }
          flag=0;
          count=0;
          for(var i = 0 ; i < listValue.length ; i++) {
            count=count+1;
            if(listValue[i] == value) {
              listValue.splice(i, 1);
              //console.log("listValue",listValue);
              this.setState({
                selectedBronx:listValue.sort().join(','),linkingLoader:false
              },()=>{
                this.locationCount();
              })
              flag=1;
              break;
            }
          }
          if(listValue.length==count && flag==0){
            listValue.push(value);
            this.setState({
              selectedBronx:listValue.sort().join(','),linkingLoader:false,
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'Queens':
          list=this.state.selectedQueens;
          listValue=[];
          if(this.state.selectedQueens!=''){
            listValue=list.split(',')
          }
          flag=0;
          count=0;
          for(var i = 0 ; i < listValue.length ; i++) {
            count=count+1;
            if(listValue[i] == value) {
              listValue.splice(i, 1);
              //console.log("listValue",listValue);
              this.setState({
                selectedQueens:listValue.sort().join(','),linkingLoader:false
              },()=>{
                this.locationCount();
              })
              flag=1;
              break;
            }
          }
          if(listValue.length==count && flag==0){
            listValue.push(value);
            this.setState({
              selectedQueens:listValue.sort().join(','),linkingLoader:false,
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'Staten_Island':
          list=this.state.selectedStatenIsland;
          listValue=[];
          if(this.state.selectedStatenIsland!=''){
            listValue=list.split(',')
          }
          flag=0;
          count=0;
          for(var i = 0 ; i < listValue.length ; i++) {
            count=count+1;
            if(listValue[i] == value) {
              listValue.splice(i, 1);
              //console.log("listValue",listValue);
              this.setState({
                selectedStatenIsland:listValue.sort().join(','),linkingLoader:false
              },()=>{
                this.locationCount();
              })
              flag=1;
              break;
            }
          }
          if(listValue.length==count && flag==0){
            listValue.push(value);
            this.setState({
              selectedStatenIsland:listValue.sort().join(','),linkingLoader:false,
            },()=>{
              this.locationCount();
            })
          }
      break;
      case 'New_Jersey':
        //console.log("this.state.selectedNewJersey",this.state.selectedNewJersey)
          list=this.state.selectedNewJersey;
          listValue=[];
          if(this.state.selectedNewJersey!=''){
            listValue=list.split(',')
          }
          flag=0;
          count=0;
          for(var i = 0 ; i < listValue.length ; i++) {
            count=count+1;
            if(listValue[i] == value) {
              listValue.splice(i, 1);
          //    console.log("listValue",listValue);
              this.setState({
                selectedNewJersey:listValue.sort().join(','),linkingLoader:false
              },()=>{
                this.locationCount();
              })
              flag=1;
              break;
            }
          }
          if(listValue.length==count && flag==0){
            listValue.push(value);
            this.setState({
              selectedNewJersey:listValue.sort().join(','),linkingLoader:false,
            },()=>{
              this.locationCount();
            })
          }
      break;
      }
    
  }
  
  setPrice=event=>{
    let key=event.target.id;
    if(key=='min_price'){
        this.setState({
          minPrice:(event.target.value=='')?-2:event.target.value
        },()=>{
         
        });
    }
    if(key=='max_price'){
      this.setState({
        maxPrice:(event.target.value=='')?-1:event.target.value
      },()=>{
        
      });
  }
  }
  prepareFilterLocation(){
    let where=[{
    url :"/getFilterNeighbor"
    }];
    getApiData(where,true).then(res=>{
   // console.log("prepareFilterLocation=>",res.data);
      this.setState({
          locationFilter:res.data
      });
    }).catch(err=>{
      this.setState({linkingLoader:true},()=>{
        console.log(err);
      })
       
    }) 
}

getCityCount(){
  let where=[{
  url :"/getCityCount"
  }];
  getApiData(where,true).then(res=>{
  //console.log("getCityCount=>",res.data);
    
  res.data.map((data,index)=>{
   // console.log("data",data)
    if(data.city='Manhattan'){
      this.setState({
        countOfManhattan:data.count
      })
    }

    if(data.city='Brooklyn'){
      this.setState({
        countOfBrooklyn:data.count
      })
    }

    if(data.city='Bronx'){
      this.setState({
        countOfBronx:data.count
      })
    }

    if(data.city='Queens'){
      this.setState({
        countOfQueens:data.count
      })
    }

    if(data.city='Staten Island'){
      this.setState({
        countOfStatenIsland:data.count
      })
    }

    if(data.city='New Jersey'){
      this.setState({
        countOfNewJersey:data.count
      })
    }

  })
  
  // this.setState({
  //       cityCount:res.data
  //   });

    
  }).catch(err=>{
    this.setState({linkingLoader:true},()=>{
      console.log(err);
    })
     
  }) 
}

prepareFilterBed(){
  let where=[{
  url :"/getLayout"
  }];
  getApiData(where,true).then(res=>{
  //console.log("prepareFilterBed=>",res.data);
    this.setState({
        locationBed:res.data
    });
  }).catch(err=>{
    this.setState({linkingLoader:true},()=>{
      console.log(err);
    })
     
  }) 
}

prepareFilterCompany(){
  let where=[{
  url :"/getCompany"
  }];
  getApiData(where,true).then(res=>{
  //console.log("getbuilding=>",res.data);
    this.setState({
        filterCompany:res.data
    });
  }).catch(err=>{
    this.setState({linkingLoader:true},()=>{
      console.log(err);
    })
     
  }) 
}
prepareFilterBuilding(){
  let where=[{
  url :"/getbuilding"
  }];
  getApiData(where,true).then(res=>{
  //console.log("getbuilding=>",res.data);
    this.setState({
        filterBuilding:res.data
    });
  }).catch(err=>{
    this.setState({linkingLoader:true},()=>{
      console.log(err);
    })
     
  }) 
}

prepareFilterCity(){
  let where=[{
  url :"/getCity"
  }];
  getApiData(where,true).then(res=>{
//    console.log("filterCity",res.data)
    this.setState({
        filterCity:res.data
    });
  }).catch(err=>{
    this.setState({linkingLoader:true},()=>{
      console.log(err);
    })
     
  }) 
}



removeZip=param=>e=>{
 // console.log("param building",param);
  if(param[1]=='zip'){
    let zipList=this.state.zipList;
    let index = zipList.indexOf(param[0]); 
    if (index > -1) {
      zipList.splice(index, 1)
      this.setState({
        zipList:zipList
      });
    }
  }else if(param[1]=='company'){
    let companyList=this.state.selectedCompany.split(',');
    let index = companyList.indexOf(param[0]); 
    if (index > -1) {
      companyList.splice(index, 1)
      this.setState({
        selectedCompany:companyList.join(',')
      });
    }
  }else if(param[1]=='building'){
    //console.log("this.state.selectedBuildingId",this.state.selectedBuildingId)
    let buildingList=this.state.selectedBuilding.split(',');
    let index = buildingList.indexOf(param[0]); 
    if (index > -1) {
      buildingList.splice(index, 1)
      this.setState({
        selectedBuilding:buildingList.join(',')
      });
    }

    // let buildingListID=this.state.selectedBuildingId.split(',');
    // let index = buildingList.indexOf(param[0]); 
    // if (index > -1) {
    //   buildingList.splice(index, 1)
    //   this.setState({
    //     selectedBuilding:buildingList.join(',')
    //   });
    // }


  }
    
}

handleFilterInputes=event=>{
  let key=event.target.id;
  let enterKey=event.keyCode||event.which;
  let ziplist=this.state.zipList;
  //console.log("event.target.value.length",event.target.value,'asas',this.state.valueShowOnly,'asasa',this.state.showonly);
  switch(key){
  case 'zip_text_box':
  if((event.target.value!='') && isNaN(event.target.value)){
    this.setState({
      zip_text_box_error:'Only numbers are allowed',
    });
  }else if(event.target.value.length>5){
    this.setState({
      zip_text_box_error:'Only 5 digits are allowed',
    });
  }else if((event.target.value!='' && event.target.value.length==5) && (enterKey==13 || enterKey==16)){
    if(ziplist.indexOf(event.target.value)==-1){
      ziplist.push(event.target.value);
      this.setState({
        ziplist:ziplist,
        zip_text_box_error:'',
        zipFilter:''
      },()=>{
  
      });
    }else{
      this.setState({
        zip_text_box_error:'Already in list',
      });
    }
    
  }else{
    this.setState({
        zip_text_box_error:'',
        zipFilter:event.target.value
    });
  }
  break;
  case 'showonly':
    if(event.target.value==1){
      this.setState({
        showonly:1,
        valueShowOnly:0,
      })
    }

    if(event.target.value==0){
      this.setState({
        showonly:0,
        valueShowOnly:1,
      })
    }
    
    break;
    case '_VR_':     
    if(this.state.VR==0){
        this.setState({
            VR:1,
            VR_checked:'checked'
        });
    }else if(this.state.VR==1){
        this.setState({
            VR:0,
            VR_checked:''
        });
    }
    break;
  }

}

 bedroomFilter=event=>{
  
  let value=event.target.value;
  this.state.buildingsearchdata.push(value);
  let list=this.state.selectedBedRooms;
  let listValue=[];
  if(this.state.selectedBedRooms!=''){
    listValue=list.split(',')
  }
  let flag=0;
  let count=0;
  for(var i = 0 ; i < listValue.length ; i++) {
    count=count+1;
    if(listValue[i] == value) {
      listValue.splice(i, 1);
     // console.log("listValue",listValue);
      this.setState({
        selectedBedRooms:listValue.sort().join(','),linkingLoader:false,
      })
      flag=1;
      break;
    }
  }
  if(listValue.length==count && flag==0){
    listValue.push(value);
    this.setState({
      selectedBedRooms:listValue.sort().join(','),linkingLoader:false,
    })
  }
 
}

bathroomFilter=event=>{
  let value=event.target.value;
  let list=this.state.selectedBathRooms;
  let listValue=[];
  if(this.state.selectedBathRooms!=''){
    listValue=list.split(',')
  }
  let flag=0;
  let count=0;
  for(var i = 0 ; i < listValue.length ; i++) {
    count=count+1;
    if(listValue[i] == value) {
      listValue.splice(i, 1);
      //console.log("listValue",listValue);
      this.setState({
        selectedBathRooms:listValue.sort().join(','),linkingLoader:false,
      })
      flag=1;
      break;
    }
  }
  if(listValue.length==count && flag==0){
    listValue.push(value);
    this.setState({
      selectedBathRooms:listValue.sort().join(','),linkingLoader:false,
    })
  }
}
companyFilter=event=>{
  let value=event.target.value;
  let list=this.state.selectedCompany;
  let listValue=[];
  if(this.state.selectedCompany!=''){
    listValue=list.split(',')
  }
  let flag=0;
  let count=0;
  for(var i = 0 ; i < listValue.length ; i++) {
    count=count+1;
    if(listValue[i] == value) {
      listValue.splice(i, 1);
      //console.log("listValue",listValue);
      this.setState({
        selectedCompany:listValue.sort().join(','),linkingLoader:false,
      })
      flag=1;
      break;
    }
  }
  
  if(listValue.length==count && flag==0){
    listValue.push(value);
    this.setState({
      selectedCompany:listValue.sort().join(','),linkingLoader:false,
    })
  }
}


 buildingFilter=param=>event=>{
  //console.log("param=>",param);
  let value={id:param[0],name:param[1]};
  let count=0;
  let flag=0;
  let len=this.state.selectedBuilding.length;
  if(len>0){

    this.state.selectedBuilding.map((item,i)=>{
      count=count+1
        if(item.name==value.name){
          flag=1;
          this.setState(state => (this.state.selectedBuilding.splice(i, 1)))
        }
    })
  }
    if(count==len && flag==0){
      this.setState(state => (this.state.selectedBuilding.push(value)))
    }
  //   for (let i = 0, p = Promise.resolve(); i < this.state.selectedBuilding.length; i++) {
  //     p = p.then(_ => new Promise(resolve =>{
  //           if(this.state.selectedBuilding[i].name==value.name){
  //             this.state.selectedBuilding.splice(i, 1);
  //           }
  //     }
  // ));
  //   }
  // }else{
  //   this.setState(state => (this.state.selectedBuilding.push(value)))
  // }
  // this.buildingFilterIds(param[0])
  // let value=param[1];
  // let list=this.state.selectedBuilding;
  // let listValue=[];
  // if(this.state.selectedBuilding!=''){
  //   listValue=list.split(',')
  // }
  // let flag=0;
  // let count=0;
  // for(var i = 0 ; i < listValue.length ; i++) {
  //   count=count+1;
  //   if(listValue[i] == value) {
  //     listValue.splice(i, 1);
  //     console.log("listValue",listValue);
  //     this.setState({
  //       selectedBuilding:listValue.sort().join(','),linkingLoader:false,
  //     })
  //     flag=1;
  //     break;
  //   }
  // }
  
  // if(listValue.length==count && flag==0){
  //   listValue.push(value);
  //   this.setState({
  //     selectedBuilding:listValue.sort().join(','),linkingLoader:false,
  //   })
  // }
}

buildingFilterIds(id){
  let value=id;
  let list=this.state.selectedBuildingId;
  let listValue=[];
  if(this.state.selectedBuildingId!=''){
    listValue=list.split(',')
  }
  let flag=0;
  let count=0;
  for(var i = 0 ; i < listValue.length ; i++) {
    count=count+1;
    if(listValue[i] == value) {
      listValue.splice(i, 1);
      //console.log("listValue",listValue);
      this.setState({
        selectedBuildingId:listValue.sort().join(','),linkingLoader:false,
      })
      flag=1;
      break;
    }
  }
  
  if(listValue.length==count && flag==0){
    listValue.push(value);
    this.setState({
      selectedBuildingId:listValue.sort().join(','),linkingLoader:false,
    })
  }
}


filterMedia=event=>{
  let value=event.target.value;
  let list=this.state.selectedMedia;
  let listValue=[];
  if(this.state.selectedMedia!=''){
    listValue=list.split(',')
  }
  let flag=0;
  let count=0;
  for(var i = 0 ; i < listValue.length ; i++) {
    count=count+1;
    if(listValue[i] == value) {
      listValue.splice(i, 1);
     // console.log("listValue",listValue);
      this.setState({
        selectedMedia:listValue.sort().join(','),linkingLoader:false,
      })
      flag=1;
      break;
    }
  }
  if(listValue.length==count && flag==0){
    listValue.push(value);
    this.setState({
      selectedMedia:listValue.sort().join(','),linkingLoader:false,
    })
  }
}
 /**
  * MS-390 end
  */


     // Clickoutside
        setWrapperRef1(node) {
            this.wrapperRef1 = node;
        }
        setWrapperRef2(node) {
          this.wrapperRef2 = node;
      }
        setWrapperRef3(node) {
          this.wrapperRef3 = node;
       }
       setWrapperRef4(node) {
        this.wrapperRef4 = node;
        }
        setWrapperRef5(node) {
            this.wrapperRef5 = node;
        }
        setWrapperRef6(node) {
          this.wrapperRef6 = node;
        }
        setWrapperRef7(node) {
          this.wrapperRef7 = node;
        }
     

        

        handleClickOutside(event) {
            if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
                var x = document.getElementById("LocationList");
                if (x.style.display === "block") {
                    x.style.display = "none";
                  } 
                  var xx = document.getElementById("filterDd");
                  if (xx.style.backgroundImage  = "url('/combo_dd_up.png')") {
                    xx.style.backgroundImage  = "url('/combo-dropdown.png')"; 
                    
                  } 
                
       }
       if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
        var x = document.getElementById("PriceList");
        if (x.style.display === "block") {
            x.style.display = "none";
              } 
              var xx = document.getElementById("filterDd1");
              if (xx.style.backgroundImage  = "url('/combo_dd_up.png')") {
                xx.style.backgroundImage  = "url('/combo-dropdown.png')";                 
              }   
        }
        if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {
          var x = document.getElementById("BedroomList");
          if (x.style.display === "block") {
              x.style.display = "none";
                } 
                var xx = document.getElementById("filterDd2");
                if (xx.style.backgroundImage  = "url('/combo_dd_up.png')") {
                  xx.style.backgroundImage  = "url('/combo-dropdown.png')";                 
                }   
        }
        if (this.wrapperRef4 && !this.wrapperRef4.contains(event.target)) {
          var x = document.getElementById("BathroomList");
          if (x.style.display === "block") {
              x.style.display = "none";
                }  
                var xx = document.getElementById("filterDd3");
                if (xx.style.backgroundImage  = "url('/combo_dd_up.png')") {
                  xx.style.backgroundImage  = "url('/combo-dropdown.png')";                 
                }  
        }
        if (this.wrapperRef5 && !this.wrapperRef5.contains(event.target)) {
          var x = document.getElementById("CompanyList");
          if (x.style.display === "block") {
              x.style.display = "none";
                }
                var xx = document.getElementById("filterDd4");
                if (xx.style.backgroundImage  = "url('/combo_dd_up.png')") {
                  xx.style.backgroundImage  = "url('/combo-dropdown.png')";                 
                }    
        }
        if (this.wrapperRef6 && !this.wrapperRef6.contains(event.target)) {
          var x = document.getElementById("BuildingList");
          if (x.style.display === "block") {
              x.style.display = "none";
                }  
                var xx = document.getElementById("filterDd5");
                if (xx.style.backgroundImage  = "url('/combo_dd_up.png')") {
                  xx.style.backgroundImage  = "url('/combo-dropdown.png')";                 
                }  
        }
        if (this.wrapperRef7 && !this.wrapperRef7.contains(event.target)) {
          var x = document.getElementById("MediaList");
          if (x.style.display === "block") {
              x.style.display = "none";
                } 
                var xx = document.getElementById("filterDd6");
                if (xx.style.backgroundImage  = "url('/combo_dd_up.png')") {
                  xx.style.backgroundImage  = "url('/combo-dropdown.png')";                 
                }   
        }

}

/**
 * get values nighborhood
 */
MyNeighborhood=()=> {
    let myneighborhood = [{
        url: "/neighborhood",
    }];
    getApiData(myneighborhood,true).then(res => {
        this.setState({neighborhoodList:res.data});
    }).catch(error => {
        if(error.response){
           //this.setState({myerrormsg: error.response.data.error.message});
        }
    });
}
 /**
 * get values Nestio Feed nighborhood
 */
MyFeedNeighborhood=()=> {
    let myneighborhood = [{
        url: "/neighborhoodfeed",
    }];
    getApiData(myneighborhood,true).then(res => {
        this.setState({neighborhoodList:res.data});
    }).catch(error => {
        if(error.response){
          // this.setState({myerrormsg: error.response.data.error.message});
        }
    });
}
/**
 * get values from DB
 */
MakeAdvanceFilter=()=> {
    let makingOFFilters = [{
        url: "/filters",
    }];
    getApiData(makingOFFilters,true).then(res => {
        this.setState({makingOfFilters:res.data});
    }).catch(error => {
        if(error.response){
          //  this.setState({myerrormsg: error.response.data.error.message});
        }
    });
}

/**
 *  AdvanceSearch open advance filter box
 */
//<img src="/advance-search.png" id="openMsg"/><img src="/advance-search.png" id="closeMsg" style={{display:'none'}}/>

AdvanceSearch() {
    var x = document.getElementById("A_Filter");
          if (x.style.display === "none") {   
            document.getElementById("openMsg").style.display = "none"; 
            document.getElementById("closeMsg").style.display = "block";      
        x.style.display = "block";
      
    } else {
        document.getElementById("openMsg").style.display = "block"; 
        document.getElementById("closeMsg").style.display = "none";    
        x.style.display = "none";

    }
}


/**
* make filter
*/


/**
 * setAdvanceFilters will make a string of ids
 */
setAdvanceFilters=param=>event=>{
    let value=event.target.value;
    let data=param[0];
   if(this.state.amenitiesList==''){
       this.setState({amenitiesList:value})
       this.setState({amenitiesListvalue:data})
   }
   if(this.state.amenitiesList!=''){
    this.removeValue(this.state.amenitiesList,value,',',(cberr,cbres)=>{
        this.setState({amenitiesList:cbres});
    });
    this.removeValue(this.state.amenitiesListvalue,data,',',(cberr,cbres)=>{
      this.setState({amenitiesListvalue:cbres});
  });
    }   
}

setBuildingFilters=param=>event=>{
  let value=event.target.value;
  let data=param[0];
 if(this.state.buildingList==''){
     this.setState({buildingList:value})
     this.setState({buildingListvalue:data})
 }
 if(this.state.buildingList!=''){
  this.removeValue(this.state.buildingList,value,',',(cberr,cbres)=>{
      this.setState({buildingList:cbres});
  });
  this.removeValue(this.state.buildingListvalue,data,',',(cberr,cbres)=>{
    this.setState({buildingListvalue:cbres});
});
  }   
}

removeValue(list, value, separator,cb){
  separator = separator || ",";
  var values = list.split(separator);
  for(var i = 0 ; i < values.length ; i++) {
    if(values[i] == value) {
      values.splice(i, 1);
      //this.setState({amenitiesList:values.join(separator)})
      cb(null,values.join(separator));
    }else{
      let _list_=list+","+value;
      //this.setState({amenitiesList:list})
      cb(null,_list_)
  }
  }
}




  OpenLocation() {
    
    var x = document.getElementById("LocationList");
    if (x.style.display === "none") {
      x.style.display = "block";
      
    } else {
      x.style.display = "none";
    }

    var xx = document.getElementById("filterDd");
    if (xx.style.backgroundImage  = "url('/combo-dropdown.png')") {
      xx.style.backgroundImage  = "url('/combo_dd_up.png')"; 
      
    } else {
      xx.style.backgroundImage  = "url('/combo-dropdown.png')"; 
    }


  }
  OpenPrice() {
    var x = document.getElementById("PriceList");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    var xx = document.getElementById("filterDd1");
    if (xx.style.backgroundImage  = "url('/combo-dropdown.png')") {
      xx.style.backgroundImage  = "url('/combo_dd_up.png')"; 
      
    } else {
      xx.style.backgroundImage  = "url('/combo-dropdown.png')"; 
    }
  }
  OpenBedroom() {
    var x = document.getElementById("BedroomList");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    var xx = document.getElementById("filterDd2");
    if (xx.style.backgroundImage  = "url('/combo-dropdown.png')") {
      xx.style.backgroundImage  = "url('/combo_dd_up.png')"; 
      
    } else {
      xx.style.backgroundImage  = "url('/combo-dropdown.png')"; 
    }
  }
  OpenBathroom() {
    var x = document.getElementById("BathroomList");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    var xx = document.getElementById("filterDd3");
    if (xx.style.backgroundImage  = "url('/combo-dropdown.png')") {
      xx.style.backgroundImage  = "url('/combo_dd_up.png')"; 
      
    } else {
      xx.style.backgroundImage  = "url('/combo-dropdown.png')"; 
    }
  }
  OpenCompany() {
    var x = document.getElementById("CompanyList");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    var xx = document.getElementById("filterDd4");
    if (xx.style.backgroundImage  = "url('/combo-dropdown.png')") {
      xx.style.backgroundImage  = "url('/combo_dd_up.png')"; 
      
    } else {
      xx.style.backgroundImage  = "url('/combo-dropdown.png')"; 
    }
  }
  OpenBuilding() {
    var x = document.getElementById("BuildingList");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    var xx = document.getElementById("filterDd5");
    if (xx.style.backgroundImage  = "url('/combo-dropdown.png')") {
      xx.style.backgroundImage  = "url('/combo_dd_up.png')"; 
      
    } else {
      xx.style.backgroundImage  = "url('/combo-dropdown.png')"; 
    }
  }
  OpenMedia() {
    var x = document.getElementById("MediaList");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    var xx = document.getElementById("filterDd6");
    if (xx.style.backgroundImage  = "url('/combo-dropdown.png')") {
      xx.style.backgroundImage  = "url('/combo_dd_up.png')"; 
      
    } else {
      xx.style.backgroundImage  = "url('/combo-dropdown.png')"; 
    }
  }
  search=(event)=>{
   // console.log("dsdsd",event.target.id,event.target.value);
    if(event.target.id=='companySearch'){
    let value=event.target.value.trim();
			//if(this.state.searchParm.length>1){	
    if(value==''){
      this.state.filterCompany.forEach(function(client,index) {
          client.flag=0;
      });
      this.setState({searchMsg:false});
    }else {
    var len = this.state.filterCompany.length;
					var count=0;
					var vflag=0;
					this.state.filterCompany.forEach(function(comapny,index) {
						
						if(comapny.mgt_company.trim().toLowerCase().includes(value.trim().toLowerCase())){
							comapny.flag=1;
							vflag=1;
							count=count+1;
						}else{
							comapny.flag=0;
							if(vflag!=1)
							vflag=0
							count=count+1;
						}
	
					});
					
					if(len==count){
						if(vflag==0){
							this.setState({searchMsg:true});
							
						}else{
							this.setState({searchMsg:false});
							
						}

						
          }
        }
      }
      if(event.target.id=='buildingSearch'){
        let value=event.target.value.trim();
          //if(this.state.searchParm.length>1){	
        if(value==''){
          this.state.filterBuilding.forEach(function(client,index) {
              client.flag=0;
          });
          this.setState({searchMsg:false});
        }else {
        var len = this.state.filterBuilding.length;
              var count=0;
              var vflag=0;
              var showCount=0;
              if(value!='' && value.length>2){

              this.state.filterBuilding.forEach(function(building,index) {
                if(building.building_name.trim().toLowerCase().includes(value.trim().toLowerCase()) && showCount<21 ){
                  showCount=showCount+1;
                  building.flag=1;
                  vflag=1;
                  count=count+1;
                }else{
                  building.flag=0;
                  if(vflag!=1)
                  vflag=0
                  count=count+1;
                }
      
              });
            }else{
                        
              this.state.filterBuilding.forEach(function(listing,index) {
                  listing.flag=0;
                  
              });
              
          }
              
              if(len==count){
                if(vflag==0){
                  this.setState({searchMsg:true});
                  
                }else{
                  this.setState({searchMsg:false});
                  
                }
    
                
              }
            }
          }
  }
searchClick=(event)=>{
  document.getElementById('show_loader_on_click1').style.display='block';
      let len=this.state.selectedBuilding.length;
      let count=0;
      let ids='';
      let name='';
      this.state.selectedBuilding.map((item,i)=>{
        count=count+1;
        ids=(ids=='')?item.id:ids+','+item.id;
        name=(name=='')?item.name:name+','+item.name;
      })
      if(count==len){
        this.setState({
          selectedBuildingId:ids,
          selectedBuildingname:name
        },()=>{
          this.searchFeed(); 
        }) 
        
      }
      
}

searchFeed(){
 
  document.getElementById('show_loader_on_click1').style.display='block';
  this.props.handleReturnedResult({
    selectedBuilding:this.state.selectedBuildingname,
    selectedBuildingId:this.state.selectedBuildingId,
    selectedCompany:this.state.selectedCompany,
    selectedBedRooms:this.state.selectedBedRooms,
    selectedBathRooms:this.state.selectedBathRooms,
    selectedManhattan:this.state.selectedManhattan,
    selectedQueens:this.state.selectedQueens,
    selectedStatenIsland:this.state.selectedStatenIsland,
    selectedBronx:this.state.selectedBronx,
    selectedBrooklyn:this.state.selectedBrooklyn,
    selectedNewJersey:this.state.selectedNewJersey,
    maxPrice:this.state.maxPrice,
    minPrice:this.state.minPrice,
    amenitiesList:this.state.amenitiesList,
    buildingamenities:this.state.buildingList,
    zipList:this.state.zipList,
    vr:this.state.VR,
    showonly:this.state.showonly,
    selectedMedia:this.state.selectedMedia,
    recordPerPage:0,
    recordNextPage:50,
    orderBy:'',
    orderByState:''
});

}

//----Saved filter data--

savedsearchfilter=(e)=>{
  if(this.state.selectedBuildingId=='' && this.state.selectedCompany=='' && this.state.selectedBedRooms=='' && this.state.selectedBathRooms=='' &&
  this.state.selectedManhattan=='' && this.state.selectedQueens=='' && this.state.selectedStatenIsland==''
  && this.state.selectedBronx=='' && this.state.selectedBrooklyn=='' && this.state.selectedNewJersey==''
  && this.state.amenitiesList=='' && this.state.maxPrice=='-1' && this.state.minPrice=='-2'
  && this.state.amenitiesListvalue=='' && this.state.buildingList=='' && this.state.buildingListvalue==''
  && this.state.zipList.toString()=='' && this.state.showonly==0 && this.state.VR==0
  && this.state.selectedMedia==''
  ){
    alert('Please select atleast one search criteria before saving your search.')
  }
  else{
    e.preventDefault();
    this.setState({linkingLoader:true})
      const formData = new FormData();
  
      formData.append('selectedBuilding',this.state.selectedBuildingname)
      formData.append('selectedBuildingId',this.state.selectedBuildingId)
      formData.append('agent_id',getAgentId())
      formData.append('selectedCompany',this.state.selectedCompany)
      formData.append('selectedBedRooms',this.state.selectedBedRooms)
      formData.append('selectedBathRooms',this.state.selectedBathRooms)
      formData.append('selectedManhattan',this.state.selectedManhattan)
      formData.append('selectedQueens',this.state.selectedQueens)
      formData.append('selectedStatenIsland',this.state.selectedStatenIsland)
      formData.append('selectedBronx',this.state.selectedBronx)
      formData.append('selectedBrooklyn',this.state.selectedBrooklyn)
      formData.append('selectedNewJersey',this.state.selectedNewJersey)
      formData.append('maxPrice',this.state.maxPrice)
      formData.append('minPrice',this.state.minPrice)
      formData.append('amenitiesList',this.state.amenitiesList)
      formData.append('amenitiesListvalue',this.state.amenitiesListvalue)
      formData.append('buildingamenities',this.state.buildingList)
      formData.append('buildingListvalue',this.state.buildingListvalue)
      formData.append('zipList',this.state.zipList)
      formData.append('vr',this.state.VR)
      formData.append('showonly',this.state.showonly)
      formData.append('selectedMedia',this.state.selectedMedia)
      formData.append('listing_type',this.props.listing_type)
  
      let where;
   
       where = [{
          url: '/agent/savesearchfeeddata'
        }];
      
      postApiData(where,formData,true).then(res => {
        console.log('res', res);
          if(res) 
          {
            //document.getElementById('FormModal').click();
            this.setState({linkingLoader:false})
              
          }
         
    }).catch(error => {
    alert('error',error);
    });
  }
 
}
render() {
console.log('sgfsahjdagshdj',this.state.buildingsearchdata)
  let splitAmenities=(this.state.amenitiesList!='')?this.state.amenitiesList:'';
    let splitBuilding=(this.state.buildingList!='')?this.state.buildingList:'';
    let CompanyList='';
if(this.state.selectedCompany!=''){
  CompanyList=this.state.selectedCompany.split(',').map((company,index)=>{
    return <li>{company}<Link to ><span className="closeTagZip" onClick={this.removeZip([company,'company'])}>X</span></Link></li>
  })
}
let BuildingList=this.state.selectedBuilding.map((building,index)=>{
    return <li>{building.name}<Link to ><span className="closeTagZip" onClick={this.buildingFilter([building.id,building.name])}>X</span></Link></li>
  })


    

    let zipList=this.state.zipList.map((zip,index)=>{
    return <li>{zip}<Link to ><span className="closeTagZip" onClick={this.removeZip([zip,'zip'])}>X</span></Link></li>
  })

  function checkDisable(value,list,limit){
    let arr = list.split(',');
    if(arr.includes(value) && (arr.length==undefined || arr.length==limit || arr.length!=limit) ){
      return false
    }else if(!arr.includes(value) && arr.length<limit){
      return false
  }else if(!arr.includes(value) && arr.length==limit){
    return true
}
  }

  function addActive(list,value){
    if(list!=null && list!=undefined){
      let arr = list.split(',');
    return arr.includes(value)?true:false;
    }
    
  }

  function addActiveBuilding(list,value){
    let count=0;
    let len= list.length;
    let flag=0;
    list.map((item,i)=>{
      count=count+1;
        if(item.name==value){
          //console.log("item.name",item.name,value)
          flag=1;
        }
    })

    if(flag==1 && len==count){
      return true
    }
    if(flag==0 && len==count){
      return false
    }
  }

  



  let bedroom=this.state.locationBed.map((bed,index)=>{
    return <div className="form-group"><input type="checkbox" id={'bed_'+index} value={bed.layout} onClick={this.bedroomFilter} 
    disabled = {checkDisable(bed.layout,this.state.selectedBedRooms,25)? "disabled" : ""}/>
    <label for={'bed_'+index}>{bed.layout}</label></div>
  })
  let bathroom=this.state.bathrooms.map((bath,index)=>{
    return <div className="form-group"><input type="checkbox" id={'bath_'+index} value={bath.layout}
    onClick={this.bathroomFilter}/><label for={'bath_'+index}>{bath.layout}</label></div>
  })

  let company=this.state.filterCompany.map((company,index)=>{
    return (company.flag==1)?<div className="form-group"><input type="checkbox" id={'company_'+index} value={company.mgt_company} onClick={this.companyFilter} className={(addActive(this.state.selectedCompany,company.mgt_company)?'active':'')}/><label for={'company_'+index}>{company.mgt_company}</label></div>:''
  })

  let building=this.state.filterBuilding.map((building,index)=>{
    return (building.flag==1)?<div className="form-group"><input type="checkbox" id={'building_'+index} value={building.id} onClick={this.buildingFilter([building.id,building.building_name])} 
    className={(addActiveBuilding(this.state.selectedBuilding,building.building_name))?'active':''}
    /><label for={'building_'+index}>{building.building_name}</label></div>:''
  })

  var row_1 = this.state.makingOfFilters.map((item, index)=>{
    return (item.row_sequence=='1')?<div class="checkbox-inline"><input type="checkbox" id={`id_`+item.id}  checked={(splitAmenities!='' && splitAmenities.split(',').indexOf(item.id.toString())!=-1)?'checked':''} value={item.id} onChange={this.setAdvanceFilters([item.amenity_name])}/><label for={`id_`+item.id} >{item.amenity_name}</label></div>
    :null;
});

var row_2 = this.state.makingOfFilters.map((item, index)=>{
    return (item.row_sequence=='2')?<div class="checkbox-inline"><input type="checkbox" id={`id_`+item.id}  checked={(splitAmenities!='' && splitAmenities.split(',').indexOf(item.id.toString())!=-1)?'checked':''} value={item.id} onChange={this.setAdvanceFilters([item.amenity_name])}/><label for={`id_`+item.id} >{item.amenity_name}</label></div>:null;
});

var row_3 = this.state.makingOfFilters.map((item, index)=>{
    return (item.row_sequence=='3')?<div class="checkbox-inline"><input type="checkbox" id={`id_`+item.id}  checked={(splitAmenities!='' && splitAmenities.split(',').indexOf(item.id.toString())!=-1)?'checked':''} value={item.id} onChange={this.setAdvanceFilters([item.amenity_name])}/><label for={`id_`+item.id} >{item.amenity_name}</label></div>:null;
});


var row_4 = this.state.makingOfFilters.map((item, index)=>{
    return (item.row_sequence=='4')?<div class="checkbox-inline"><input type="checkbox" id={`id_`+item.id}  checked={(splitBuilding!='' && splitBuilding.split(',').indexOf(item.id.toString())!=-1)?'checked':''} value={item.id} onChange={this.setBuildingFilters([item.amenity_name])}/><label for={`id_`+item.id} >{item.amenity_name}</label></div>:null;
});

var Manhattan=this.state.filterCity.map((item, index)=>{
return (item.city=='Manhattan')?<div className="form-group" ><input type="checkbox" id={`manhattan_`+index} value={item.neighborhood} onClick={this.handleLocation([item.city,item.neighborhood])} className={(addActive(this.state.selectedManhattan,item.neighborhood)?'active':'')}/><label for={`manhattan_`+index}>{unescape(item.neighborhood)}</label></div>:null;
});

var Brooklyn=this.state.filterCity.map((item, index)=>{
  return (item.city=='Brooklyn')?<div className="form-group" ><input type="checkbox" id={`Brooklyn_`+index} value={item.neighborhood} onClick={this.handleLocation([item.city,item.neighborhood])} className={(addActive(this.state.selectedBrooklyn,item.neighborhood)?'active':'')}/><label for={`Brooklyn_`+index}>{unescape(item.neighborhood)}</label></div>:null;
  });

  var Bronx=this.state.filterCity.map((item, index)=>{
    return (item.city=='Bronx')?<div className="form-group" ><input type="checkbox" id={`Bronx_`+index} value={item.neighborhood} onClick={this.handleLocation([item.city,item.neighborhood])} className={(addActive(this.state.selectedBronx,item.neighborhood)?'active':'')}/><label for={`Bronx_`+index}>{unescape(item.neighborhood)}</label></div>:null;
    });

    var Queens=this.state.filterCity.map((item, index)=>{
      return (item.city=='Queens')?<div className="form-group" ><input type="checkbox" id={`Queens_`+index} value={item.neighborhood} onClick={this.handleLocation([item.city,item.neighborhood])} className={(addActive(this.state.selectedQueens,item.neighborhood)?'active':'')}/><label for={`Queens_`+index}>{unescape(item.neighborhood)}</label></div>:null;
      });

      var Staten_Island=this.state.filterCity.map((item, index)=>{
        return (item.city=='Staten Island')?<div className="form-group" ><input type="checkbox" id={`Staten_Island_`+index} value={item.neighborhood} onClick={this.handleLocation(['Staten_Island',item.neighborhood])} className={(addActive(this.state.selectedStatenIsland,item.neighborhood)?'active':'')}/><label for={`Staten_Island_`+index}>{unescape(item.neighborhood)}</label></div>:null;
        });
        
        var New_Jersey=this.state.filterCity.map((item, index)=>{
          return (item.city=='New Jersey')?<div className="form-group" ><input type="checkbox" id={`New_Jersey_`+index} value={item.neighborhood} onClick={this.handleLocation(['New_Jersey',item.neighborhood])} className={(addActive(this.state.selectedNewJersey,item.neighborhood)?'active':'')}/><label for={`New_Jersey_`+index}>{unescape(item.neighborhood)}</label></div>:null;
          });
    
    
  
return (
    <div>
      <button class="btn btn-default search-filter pull-right mr-5" onClick={this.resetFilter} style={{'margin-bottom':'0px'}}><img src="/reset-filter.png" width="55"/></button>
      <div className="clearfix"></div>
                {(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}


<div className="listing-filter row d-flex agent-filter">

    
        <div className="panel_1" style={{position:'relative'}}>
               
                <div id="filterDd" className="neighbor-filter list-filter" onClick={this.OpenLocation}> 
                Location  <span className="count" id="count_location">({this.state.locationCount})</span>
                </div>

                <div className="boxes neighbor" id="LocationList" style={{display:'none'}}  ref={this.setWrapperRef1}>
            
                 <Tabs>
								<TabList>
									<Tab >Neighborhood</Tab>
									<Tab>Zipcode</Tab>
                  <Tab>Map</Tab>
								</TabList>
						
								<TabPanel>
                        <Tabs>
                        <TabList className="check-box">
                          <Tab><div className="form-group"><input type="checkbox" id="VR" checked={this.state.selectAllManhattanChecked} onClick={this.SelectAll(['Manhattan'])}/><label for="VR"></label></div>Manhattan <span className="count" id="count_city">({(this.state.selectedManhattan!='')?this.state.selectedManhattan.split(',').length:"0"})</span></Tab>
                         <Tab><div className="form-group"><input type="checkbox" id="VR1" checked={this.state.selectAllBrooklyn}  onClick={this.SelectAll(['Brooklyn'])}/><label for="VR1"></label></div>Brooklyn <span className="count" id="count_city">({(this.state.selectedBrooklyn!='')?this.state.selectedBrooklyn.split(',').length:"0"})</span></Tab>
                         <Tab><div className="form-group"><input type="checkbox" id="VR2" checked={this.state.selectAllBronx}  onClick={this.SelectAll(['Bronx'])}/><label for="VR2"></label></div>Bronx <span className="count" id="count_city">({(this.state.selectedBronx!='')?this.state.selectedBronx.split(',').length:"0"})</span></Tab>
                        <Tab><div className="form-group"><input type="checkbox" id="VR3" checked={this.state.selectAllQueens} onClick={this.SelectAll(['Queens'])}/><label for="VR3"></label></div>Queens <span className="count" id="count_city">({(this.state.selectedQueens!='')?this.state.selectedQueens.split(',').length:"0"})</span></Tab>
                         <Tab><div className="form-group"><input type="checkbox" id="VR4" checked={this.state.selectAllStatenIsland}  onClick={this.SelectAll(['Staten_Island'])}/><label for="VR4"></label></div> Staten Island<span className="count" id="count_city">({(this.state.selectedStatenIsland!='')?this.state.selectedStatenIsland.split(',').length:"0"})</span></Tab>
                         <Tab><div className="form-group"><input type="checkbox" id="VR6" checked={this.state.selectAllNewJersey}  onClick={this.SelectAll(['New_Jersey'])}/><label for="VR6"></label></div> New Jersey <span className="count" id="count_city">({(this.state.selectedNewJersey!='')?this.state.selectedNewJersey.split(',').length:"0"})</span></Tab>
                        </TabList>

                        <TabPanel>
                             <div className="row clearfix">
                                <div className="col-sm-12 location-listwidget">                               
                                  {Manhattan}
                                </div>
                              </div>
                              <p className="text-right mt-4">{/*<Link to className="btn btn-default">Apply</Link>*/}</p>
                        </TabPanel>

                        <TabPanel>                             
                             <div className="row clearfix">
                                <div className="col-sm-12 location-listwidget">
                                {Brooklyn}
                                </div>
                                
                              </div>
                        </TabPanel>

                        <TabPanel>                             
                             <div className="row clearfix">
                                <div className="col-sm-12 location-listwidget">
                                {Bronx}
                                </div>
                                
                              </div>
                        </TabPanel>

                        <TabPanel>                             
                             <div className="row clearfix">
                                <div className="col-sm-12 location-listwidget" >
                                {Queens}
                                </div>
                                
                              </div>
                        </TabPanel>

                        <TabPanel>                             
                             <div className="row clearfix">
                                <div className="col-sm-12 location-listwidget">
                                {Staten_Island}
                                </div>
                                
                              </div>
                        </TabPanel>

                        <TabPanel>                             
                             <div className="row clearfix">
                                <div className="col-sm-12 location-listwidget">
                                {New_Jersey}
                                </div>
                                
                              </div>
                        </TabPanel>


                        </Tabs>
               </TabPanel>
                      <TabPanel> <div class="ziptag">
                      <ul className="clearfix">
                      {zipList}
                      </ul>
                        
                        <div className="form-group">
                        <input type="text" id="zip_text_box" onChange={this.handleFilterInputes} onKeyDown={this.handleFilterInputes} value={this.state.zipFilter} placeholder="Enter valid zip" className="form-control" style={{maxWidth:'120px'}}/>
                        <span className="inline-error-class"> {this.state.zip_text_box_error !==''?this.state.zip_text_box_error:''}   </span>

                        
                      </div>
                      </div>
                      </TabPanel>
                      <TabPanel >
                        <img src="/map.png" width="100%"/>

                      </TabPanel>
                </Tabs>
                </div>
        </div>
        <div className="panel_1 price-box" style={{position:'relative',}} >
               
                <div id="filterDd1" className="neighbor-filter list-filter" onClick={this.OpenPrice}> 
                 Price <span className="count" id="count_bed">(
                 {
                  
                   ((this.state.minPrice!='' && this.state.minPrice>-2) || this.state.minPrice>=0)
                   ?(this.state.maxPrice!='' && this.state.maxPrice>-1)?2:1
                   :(this.state.maxPrice!='' && this.state.maxPrice>-1)?1:0
                  }
                 )</span>
                </div>

                <div className="boxes pricelist" id="PriceList" style={{display:'none'}} ref={this.setWrapperRef2}>
                
               

                    <div className="row clearfix">
                      <div className="col-xs-6 col-sm-6" style={{borderRight:'1px solid #d2d2d2'}}>
                      <input type="text" id="min_price" value={(this.state.minPrice!=-2)?this.state.minPrice:''} onChange={this.setPrice} className="form-control" placeholder="Min" /> 
                            <ul className="price-list">
                              <li className={(this.state.minPrice==0)?'active':''}    onClick={(this.state.maxPrice>0 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([0,'min']):''}>$0</li>
                              <li className={(this.state.minPrice==500)?'active':''} onClick={(this.state.maxPrice>500 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([500,'min']):''}>$500</li>
                              <li className={(this.state.minPrice==750)?'active':''} onClick={(this.state.maxPrice>750 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([750,'min']):''}>$750</li>
                              <li className={(this.state.minPrice==1000)?'active':''} onClick={(this.state.maxPrice>1000 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([1000,'min']):''}>$1000</li>
                              <li className={(this.state.minPrice==1250)?'active':''} onClick={(this.state.maxPrice>1250 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([1250,'min']):''}>$1250</li>
                              <li className={(this.state.minPrice==1500)?'active':''} onClick={(this.state.maxPrice>1500 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([1500,'min']):''}>$1500</li>
                              <li className={(this.state.minPrice==1750)?'active':''} onClick={(this.state.maxPrice>1750 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([1750,'min']):''}>$1750</li>
                              <li className={(this.state.minPrice==2000)?'active':''} onClick={(this.state.maxPrice>2000 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([2000,'min']):''}>$2000</li>
                              <li className={(this.state.minPrice==2500)?'active':''} onClick={(this.state.maxPrice>2500 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([2500,'min']):''}>$2500</li>
                              <li className={(this.state.minPrice==3000)?'active':''} onClick={(this.state.maxPrice>3000 || this.state.maxPrice==-1 || this.state.maxPrice=='')?this.priceFilter([3000,'min']):''}>$3000</li>
                            </ul>
                      </div>
                      <div className="col-xs-6 col-sm-6">
                      <input type="text" value={(this.state.maxPrice!=-1)?this.state.maxPrice:''} id="max_price" onChange={this.setPrice} className="form-control" placeholder="Max" /> 
                      {this.state.error_price!==''?(<div className="alert alert-danger"><strong>{this.state.error_price}</strong></div>):(null)}

                            <ul style={{border:'0'}} className="price-list">
                              <li className={(this.state.maxPrice==1000)?'active':''} onClick={(this.state.minPrice<1000)?this.priceFilter([1000,'max']):''}>$1000</li>
                              <li className={(this.state.maxPrice==1500)?'active':''} onClick={(this.state.minPrice<1500)?this.priceFilter([1500,'max']):''}>$1500</li>
                              <li className={(this.state.maxPrice==1750)?'active':''} onClick={(this.state.minPrice<1750)?this.priceFilter([1750,'max']):''}>$1750</li>
                              <li className={(this.state.maxPrice==2000)?'active':''} onClick={(this.state.minPrice<2000)?this.priceFilter([2000,'max']):''}>$2000</li>
                              <li className={(this.state.maxPrice==2250)?'active':''} onClick={(this.state.minPrice<2250)?this.priceFilter([2250,'max']):''}>$2250</li>
                              <li className={(this.state.maxPrice==2500)?'active':''} onClick={(this.state.minPrice<2500)?this.priceFilter([2500,'max']):''}>$2500</li>
                              <li className={(this.state.maxPrice==2750)?'active':''} onClick={(this.state.minPrice<2750)?this.priceFilter([2750,'max']):''}>$2750</li>
                              <li className={(this.state.maxPrice==3000)?'active':''} onClick={(this.state.minPrice<3000)?this.priceFilter([3000,'max']):''}>$3000</li>
                              <li className={(this.state.maxPrice==3500)?'active':''} onClick={(this.state.minPrice<3500)?this.priceFilter([3500,'max']):''}>$3500</li>
                              <li className={(this.state.maxPrice==4000)?'active':''} onClick={(this.state.minPrice<4000)?this.priceFilter([4000,'max']):''}>$4000</li>
                            </ul>


                      </div>

                    </div>
                </div>
        </div>
        <div className="panel_1" style={{position:'relative'}}>
               
               <div id="filterDd2" className="neighbor-filter list-filter" onClick={this.OpenBedroom}> 
               Bedrooms <span className="count" id="count_bed">({(this.state.selectedBedRooms!='')?this.state.selectedBedRooms.split(',').length:"0"})</span>
               </div>

               <div className="boxes neighbor common-box inputChecks" id="BedroomList" style={{display:'none'}}  ref={this.setWrapperRef3} >
                 <div className="form-group">
                   <input type="text" readonly="true" value={this.state.selectedBedRooms} className="form-control" placeholder="Bedroom" /> 
                   </div>
                       <div className="scroll-list">{bedroom}</div>
                           
               </div>
       </div>
       <div className="panel_1" style={{position:'relative'}}>
               
               <div id="filterDd3" className="neighbor-filter list-filter" onClick={this.OpenBathroom}> 
               Bathrooms <span className="count" id="count_bath">({(this.state.selectedBathRooms!='')?this.state.selectedBathRooms.split(',').length:"0"})</span>
               </div>

               <div className="boxes neighbor common-box inputChecks" id="BathroomList" style={{display:'none'}}  ref={this.setWrapperRef4}>
               <div className="form-group">
                   <input type="text" value={this.state.selectedBathRooms} readOnly='true' className="form-control"  placeholder="Bathroom"/> 
                   </div>
                   <div className="scroll-list ">{bathroom}</div>
                       
               </div>
       </div>
       <div className="panel_1" style={{position:'relative'}}>
               
               <div id="filterDd4" className="neighbor-filter list-filter" onClick={this.OpenCompany}> 
               Company  <span className="count" id="count_Comp">({(this.state.selectedCompany!='')?this.state.selectedCompany.split(',').length:"0"})</span>
               </div>

               <div className="boxes neighbor common-box" id="CompanyList" style={{display:'none'}}  ref={this.setWrapperRef5}>
               <div className="form-group ziptag">
               <ul className="clearfix ">
               {CompanyList}
                      </ul>
               
                   <input type="text" id="companySearch" onKeyDown={this.search} onKeyUp={this.search}  className="form-control"  placeholder="Company"/> 
                   </div>
                   <div className="scroll-list maxheight" > {company}</div>
                   {(this.state.searchMsg)?'No suggestions available':''}
               </div>
       </div>
       <div className="panel_1" style={{position:'relative'}}>
               
               <div id="filterDd5" className="neighbor-filter list-filter" onClick={this.OpenBuilding}> 
               Building  <span className="count" id="count_build">({this.state.selectedBuilding.length})</span>
               </div>

               <div className="boxes neighbor common-box" id="BuildingList" style={{display:'none'}}  ref={this.setWrapperRef6}>
               <div className="form-group ziptag">
               <ul className="clearfix">{BuildingList}</ul>
               
                   <input type="text" id="buildingSearch" onKeyDown={this.search} onKeyUp={this.search}  className="form-control"  placeholder="Building"/> 
                   </div>
                   <div className="scroll-list maxheight" > {building}</div>
               </div>
       </div>
       <div className="panel_1 media-box" style={{position:'relative'}}>
               
               <div id="filterDd6" className="neighbor-filter list-filter" onClick={this.OpenMedia}> 
               Media  <span className="count" id="count_media">({(this.state.selectedMedia!='')?this.state.selectedMedia.split(',').length:"0"})</span>
               </div>

               <div className="boxes neighbor common-box" id="MediaList" style={{display:'none'}}  ref={this.setWrapperRef7}>
               <div className="form-group ">
                   <input type="text" value={this.state.selectedMedia} readOnly="true" className="form-control"  placeholder="Media"/> 
                   </div>
                       <div className="form-group"><input type="checkbox" id="VP" value="VR preview" className={(addActive(this.state.selectedMedia,'VR preview')?'active':'')} onClick={this.filterMedia}/><label for="VP">VR Preview</label></div>
                       <div className="form-group"><input type="checkbox" id="PH" value="Has Photo" className={(addActive(this.state.selectedMedia,'Has Photo')?'active':'')} onClick={this.filterMedia}/><label for="PH">Has Photo</label></div>
                       <div className="form-group"><input type="checkbox" id="FP" value="Has FloorPlans" className={(addActive(this.state.selectedMedia,'Has FloorPlans')?'active':'')} onClick={this.filterMedia}/><label for="FP">Has FloorPlans</label></div>
                     
               </div>
       </div>
<div className="panel_4">
<div className="search-wrap"><button  onClick={this.searchClick} className='btn btn-default search-filter'><img src="/search-filter.png" width="55"/></button></div>
<Link to className="advance-search-widget clearfix" onClick={this.AdvanceSearch}><span style={{float:'left'}} >Advanced Search</span> <img src="/advance-search.png" id="openMsg"/><img src="/advance-search.png" id="closeMsg" style={{display:'none'}}/></Link>
</div>

<div className="advance-search" id="A_Filter" style={{display:'none'}} ref={this.setWrapperRef10}>
<div class="filter-block">

<h5 class="title">Unit Amenities</h5>
<div class="form-group">
{row_1}
</div>
<div class="form-group">
{row_2}
{row_3}

</div>
<h5 class="title">Building amenities</h5>
<div class="form-group">
{row_4}
</div>
</div>

</div>   
  <div className="commoncheck show-only">
  <div className="form-group "><input type="checkbox" id="showonly" checked={(this.state.valueShowOnly==0)?'checked':''} value={this.state.valueShowOnly} onChange={this.handleFilterInputes}/><label for="showonly">Show only units broker or tenant incentives</label></div>
  </div>
  {this.props.userType!='Manager'?<Link to onClick={this.savedsearchfilter} className="savesearch-link">Save Search</Link>:''} 
</div>{/*<p className="text-center " id="linkId" ref = "cpDev1" ><Link to onClick={this.rentFilter}>I’m looking to purchase an apartment</Link></p>*/}</div>
                );
    }
}



export default filterList;