import React from 'react';
import {Link} from 'react-router-dom';

class AdminHeader extends React.Component {

    constructor(props) {
        super(props);
          
         
          
        this.state = {
            userDetails: JSON.parse(localStorage.getItem('adminUserDetails'))

          }; 
    }


    componentDidMount() {
    }

    render() {
        
        return (
               <header className="fixedHeader">     
                    <nav class="navbar navbar-expand-md bg-light navbar-dark">
                    <a class="navbar-brand logo" href="#"><img src="/logo.png" width="100%" /></a> <span className="header-tag">Admin Dashboard</span>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <img src="/menu-icon.png"/>
                    </button>
                    <div class="collapse navbar-collapse " id="collapsibleNavbar">
                        <ul class="navbar-nav mr-auto">
                            <li className="nav-item"><Link to="/admin/agent" lass="nav-link"> Home</Link></li> 
                            <li className="nav-item"><Link to="/admin/logout" lass="nav-link"> Logout</Link></li> 
                            <li className="nav-item"><Link to lass="nav-link">Hi, {this.state.userDetails.username} </Link></li>
                            <li className="">{localStorage.getItem('agentImageUrl')?(<img alt="Profile" src={localStorage.getItem('agentImageUrl')} width="40" className="user rounded-circle"/>):(null)}</li>
                        </ul> 
                    </div>  
                    </nav>
                </header>
                );
    }
}
export default AdminHeader;