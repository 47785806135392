import React from "react";
import { Redirect } from 'react-router';
import {getApiData, getToken, removeToken,getAdminToken} from '../../services/Api';

class Logout extends React.Component {
    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}
class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            name: "",
            logout: false
        };
       
    }

    componentDidMount() {
     this.onLogout();
    //    this.destroyMySession(getAdminToken(),(err,resdata)=>{
    //     if(resdata){
    //         this.setState({logout:true});
    //     }
    // })
    }

    // destroyMySession(value,cb){
    //     localStorage.removeItem(value);
    //     cb(null,true);
    // }

    async onLogout() {
        
            localStorage.removeItem('admin_token');
            localStorage.removeItem('adminUserDetails');
            this.setState({logout:true});
         
    }
    
   

    render() {
        if (this.state.logout) {
           return (<Redirect to={'/admin/login'}/>);
        }
        return (
                <div>Logged out</div>
                );
    }
}

export default Logout;
