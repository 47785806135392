import React, {lazy} from "react";
import { Link } from "react-router-dom";
import AgentHeader from "../../common/AgentHeader";
import AgentSideBar from "../../common/AgentSideBar";
import ManagerHeader from "../../common/ManagerHeader";
import ManagerSidebar from "../../common/ManagerSidebar";
import { Redirect } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "react-input-range/lib/css/index.css";
import Switch from "react-switch";
import { validatePhone } from "../../../services/validation";
import { getApiData, postApiData, getAgentId,getBaseUrl } from "../../../services/Api";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fromBlob } from 'image-resize-compress';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SalesCreator from './SalesListingCreator';
import Moment from 'moment';
import { isNullOrUndefined, isNull } from 'util';
import Dropzone  from 'react-dropzone';
const checkedBoxes =[];
const marketBoxes =[];
class ListingCreator extends React.Component {
  constructor(props) {
    super(props);
    console.log('props',this.props)
    this.state = {
     
      listing_info_address: "",
      unit_number: "",
      bedrooms: "",
      bathrooms: "",
      Exclusive_expiration_Date_error:'',
      square_footage: "",
      description: "",
      price: "",
      date_available: new Date(),
      min_lease_term: "12",
      furnished: 0,
      list_images:'',
      nestoImages:0,
      unit_amenities: [],
      owner_pays_data:[],
      marketing: [],
      incentives: "",
      tenant_incentive: "",
     
      imagePath: "",
      kuula_vr_link: "",
      listing_information: "",
      noFee:false,
      imagePathArray: [],
      exposures:'',
      pets_policy_error:'',
      //error
      building_address_error: "",
      building_name_error: "",
      landlord_name_error: "",
      landlord_email_error: "",
      landlord_phone_error: "",
      CoBrokeAgreement:'',
      copercentage_broker:'',
      unit_number_error: "",
      bedrooms_error: "",
      bathrooms_error: "",
      square_footage_error:"",
      price_error: "",
      min_lease_term_error: "",
      date_available_error: "",
      kuula_vr_link_error: "",
      default_application_template_error: "",
      screenings_required_error: "",

      data:[],
      linkingLoader: false,
      myerrormsg:'',
      flag:0,
      successQuote:'0',
      imageForDb:[],
      checkedBoxes:[],
      marketBoxes:[],
      building_address_auto:'',
      buildingList:[],
      building_id:0,
      listing_info_address:'',
      selected_building_name: 'skyward',
      startDate:new Date(),
      buildingDW:'',
      buildingCW:'',
      imageCount:0,
			isImage: false  ,
			tabPanel:'',
      existCheckboxSelection:true,
      existmarketCheckboxSelection:true,
            redirect:false  ,
            listing_id:this.props.listing_id  ? this.props.listing_id    :false,
             amenityList:[],
            imageIdFromDb:[],
            imageIdForDb:[],
            checkFlag:0,
            furnishedFlag:0,
            addressFlag:0,
            listingFlag:0,
            incentiveFlag:0,
            building_ownership:'Multifamily',
            total_rooms:"",
            layoutData:'',
            total_rooms_error:'',
            Exclusive_expiration_Date:'',
            commission_percentage:'',
      my_split:0,
      co_broker_split:100,
      final_precentage:0,
      pets_policy:'',
      owner_pays_broker:'',
      renthop_approval:true,
      skyward_approval:true,
      streetEasy_approval:true,
      RLS_approval:true,
      status:1,
      rent_hop_export:0,
    };
    this.handleChangeDate= this.handleChangeDate.bind(this);
    this.handleAddBuilding= this.handleAddBuilding.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  rotate= param=>event =>{
    const { imagePathArray } = this.state;
    var index = param[1];
    let newRotation = param[2]+90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState(state => (this.state.imagePathArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForDb[index].current_rotation = newRotation))
  }
  

  rotateleft= param=>event =>{
    const { imagePathArray } = this.state;
    var index = param[1];
    let newRotation = param[2]-90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState(state => (this.state.imagePathArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForDb[index].current_rotation = newRotation))
  }

  rotateimagelist= param=>event =>{
    const { list_images } = this.state;
    var index = param[1];
    let newRotation = param[2]+90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState(state =>(list_images.map((item,i)=>{
      if(index==i){
        item.rotation=newRotation
      }
    })
    ))

    let where = [{
      url: `/creator/updateimagerotation/${list_images[index].id}`
  }];

  postApiData(where, {"rotation": newRotation},true)
  .then(res => {
      if(res.data)
      {
        this.setState({'pageLoader':false});
      }
              
}).catch(error => {
 this.setState({myerrormsg: error.response});
});
  
  }

  rotateleftimagelist= param=>event =>{
    this.setState({'pageLoader':true});
    const { list_images } = this.state;
    var index = param[1];
  
    let newRotation = param[2]-90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState(state =>(list_images.map((item,i)=>{
      if(index==i){
        item.rotation=newRotation
      }
    })
    ))

    let where = [{
      url: `/creator/updateimagerotation/${list_images[index].id}`
  }];

  postApiData(where, {"rotation": newRotation},true)
  .then(res => {
      if(res.data)
      {
        this.setState({'pageLoader':false});
      }
              
}).catch(error => {
 this.setState({myerrormsg: error.response});
});

  }

  onDrop = (files) => {
    var imageFile = files;
  
  
    if (files) {
      for(var i=0;i<imageFile.length;i++){
      let imgFlag = true;
      const maxFileSize = 5242880; //5mb
      const value = files[i].name;
    
      const ext = value.substring(value.lastIndexOf("."));
      if (![".jpg", ".JPG", ".jpeg", ".png"].includes(ext)) {
        this.setState({ mymsg: ext + " is not supported file extension." });
        imgFlag = false;
      }
      if (files[i].size > maxFileSize) {
        this.setState({ mymsg: "The uploaded image is too large. The max image file size is set to 5MB" });
        imgFlag = false;
      }
      if (imgFlag) {
       
  
        var options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
          fromBlob(imageFile[i], 80, 'auto', 'auto', 'webp')
           .then(compressedFile => {

            var file = new File([compressedFile], value);
            var temp = [...this.state.imagePathArray];
            var temp2 = [...this.state.imageForDb];

            temp.push({file:URL.createObjectURL(file),"current_rotation":0});
            temp2.push({file:file,"current_rotation":0});
            this.setState(
              {
                imageForDb: temp2,
                imagePathArray: temp
              },
              () => {
                
                this.state.imageCount=this.state.imagePathArray.length;
              
                this.state.isImage=true;
            

                }
            );
          })
          .catch(function(error) {
            console.log(error.message);
          });
      }
    }
    }
  };
  ownerpays=()=>{
    let where=[{
      url:'/agent/ownerPays'
    }];
    postApiData(where,{'tableName':'owner_pays_broker_master'},true).then(res=>{
      console.log("--res",res);
      this.setState({owner_pays_data:res.data})
    }).catch(error=>{

    })
  }
  onBuildlingAndListingdataById=()=>{
    if(this.state.listing_id!== false ){
      this.setState({'pageLoader':true} );
    let listingWhere=[{
        url : '/agent/feeddataByIdForEdit'
    }];
    postApiData(listingWhere,{'listing_id':this.state.listing_id},true).then(res=>{
        var listingItem = res.data.records[0];
          let insent=listingItem.incentives.split(',');  
            this.setState({
                listing_info_address:listingItem.listing_info_address,
                listing_information:listingItem.listing_information,
                unit_number:listingItem.unit_number ,
                bedrooms:listingItem.bedrooms,
                bathrooms:listingItem.bathrooms,
                price:listingItem.price,
                Neighbourhood:listingItem.neighborhood,
                description:unescape(listingItem.description),
                furnished:listingItem.furnished,
                square_footage:listingItem.square_footage,
                incentives:(insent[0]!=undefined)?insent[0]:'',
                owner_pays:(insent[1]!=undefined)?insent[1]:'',
                tenant_incentive:listingItem.tenant_incentive,
                noFee:listingItem.noFee,
                agent_name:listingItem.name,
                agent_email:listingItem.email,
                agent_mob:listingItem.mob_number,
                date_available: (listingItem.date_available!='0000-00-00' && listingItem.date_available)?Moment(listingItem.date_available).format('MMMM DD, YYYY'):Moment(new Date()).format('MMMM DD, YYYY'),
                amenityList : res.data.listRecords,
                listing_status:res.data.records[0].status,
                list_images:res.data.image,
                min_lease_term: (listingItem.min_lease_term!='null' && listingItem.min_lease_term!=0) ? (listingItem.min_lease_term) :(12) ,
                kuula_vr_link:listingItem.kuula_vr_link,
                checkFlag:1,
                furnishedFlag:1,
                addressFlag:1,
                listingFlag:1,
                incentiveFlag:1,
                rentHop:listingItem.rent_hop_export,
                building_ownership:(listingItem.building_ownership != 'undefined' && listingItem.building_ownership != 'None' && listingItem.building_ownership != 'Multi-family' && listingItem.building_ownership != 'Non-stabilized lease' && listingItem.building_ownership != 'Stabilized lease' && listingItem.building_ownership != 'Non-stabilized sublease' && listingItem.building_ownership != 'Commercial or professional' && listingItem.building_ownership != 'Stabilized sublease' && listingItem.building_ownership != 'Single-family') ? listingItem.building_ownership :listingItem.building_ownership == 'Single-family'?'Single Family':'Multifamily',
                Exclusive_expiration_date: (listingItem.exclusive_expiration_date!='0000-00-00' && listingItem.exclusive_expiration_date)?Moment(listingItem.exclusive_expiration_date).format('MMMM DD, YYYY'):'',
                exposures:(listingItem.exposures=='null' || !listingItem.exposures)?'':listingItem.exposures,
                layout:listingItem.layout,
                unit_condition:listingItem.unit_condition,
                 unit_view:listingItem.unit_view,
                total_rooms:listingItem.total_rooms,
                nestoImages:res.data.image.length,
            },()=>{
              this.state.imageCount=res.data.image.length
              if(res.data.image.length>0)
                 this.setState({isImage:true})

             })
            
              if(!isNullOrUndefined(listingItem.unit_view) && listingItem.unit_view!=''){
            document.getElementById('unit_view').value = listingItem.unit_view ;
           
              }
              if(!isNullOrUndefined(listingItem.unit_condition) && listingItem.unit_condition!=''){
            document.getElementById('unit_condition').value = listingItem.unit_condition;
           
              }
              if(!isNullOrUndefined(listingItem.pets) && listingItem.pets!=''){
                document.getElementById('pets').value = listingItem.pets;
                this.setState({pets_policy:listingItem.pets})
                
                  }else{
                    document.getElementById('pets').disabled =false ;
                  }
                  if(!isNullOrUndefined(listingItem.co_broke_agreement) && listingItem.co_broke_agreement!='' && listingItem.co_broke_agreement!='undefined'){
                    document.getElementById('CoBrokeAgreement').value = listingItem.co_broke_agreement;
                  
                      }
                      
                  if(!isNullOrUndefined(listingItem.owner_pays_broker) && listingItem.owner_pays_broker!='' && listingItem.owner_pays_broker!='undefined'){
                  
                    document.getElementById('owner_pays_broker').value = listingItem.owner_pays_broker;
                   
                      }    
                      if(!isNullOrUndefined(listingItem.owner_pays_amount) && listingItem.owner_pays_amount!='' && listingItem.owner_pays_amount!='undefined'){
                        document.getElementById('owner_pays_amount').value = listingItem.owner_pays_amount;
                        
                          }   
            this.setState({'pageLoader':false});
    }).catch(err=>{
    
    })
  }
}




  componentWillMount=()=>{
    this.getData();
    this.ownerpays();
    this.layout();
      this.onBuildlingAndListingdataById();
    
   
        //this.buildingOpt();
  }

  layout =()=>{
    let where=[{
      url:'/agent/layout'
    }]
    postApiData(where,{'tableName':'listing_layout'},true).then(res=>{
      console.log("res.data",res.data);
        this.setState({
          layoutData:res.data
        })
       
    }).catch(error=>{

    })
  }
    handleChangeDate(date) {
    this.setState({
      startDate: date,
      date_available:date
    });
	}
	
	// setTab=()=>{
	// 	this.setState({tabPanel:'Sales'})
	// }

  handleAddBuilding()
  {
     var addr=document.getElementById('Address').value
     
     if(this.props.userType=="Agent")
     {
        window.location.href="/agent/building/add/"+addr;
     }
     if(this.props.userType=="Manager")
     {
        window.location.href="/manager/build/"+addr;
     }
  }
async buildingOpt(addr){
    let buildingParam = [{
            url: "/nestio/getbuildingbyaddress/"+addr
    }];
        getApiData(buildingParam,true).then(res => {
        this.setState({'buildingList':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
    }   
handleBuildingSearch = event => {
  this.setState({addressFlag:0})
    var selected_building_name = event.target.getAttribute('name');

    this.setState({
        selected_building_name: selected_building_name
    })
        var key=event.target.id; 
    switch(key){
        case 'Address':
              
                this.setState({searchParm:event.target.value,listing_info_address: event.target.value},()=>{
      //if(this.state.searchParm.length>-1){
                var value=this.state.searchParm;
                if(value!='' && value.length>2){
                  this.buildingOpt(value);
                 
      this.state.buildingDW = this.state.buildingList.map((aw,index) => 
    (aw.id>0) ? <li id="searchkey" key={aw.id} value={aw.id} name={aw.building_address} onClick={this.handleBuildingSearch}>{aw.building_address}</li>:<li onClick={this.handleAddBuilding}>No match found to entered criteria. Add a new Building.</li>);



                    this.state.buildingList.forEach(function(building,index) {
                        if(building.building_address.trim().toLowerCase().includes(value.trim().toLowerCase())){
                                building.flag=1;
                            }else{
                                building.flag=0;
                            }
                        }); 
                }else{
                  document.getElementById('building_search_id_values').style.display = 'none';
                    this.state.buildingList.forEach(function(building,index) {
                        building.flag=0;
                    });
                    
                }
            })       
                //}
    break;
    case 'searchkey':
    var value=event.target.value;
                let $this = this;
      this.setState({ agent_id: event.target.value}, () => {
        this.state.buildingList.forEach(function(building,index) {
          if(building.id==value){
              document.getElementById('building_search_id_values').style.display = "none";
                                            document.getElementById('Address').value=building.building_address;
                                            $this.setState({listing_info_address:building.building_address});
          }else{
            building.flag=0;
          }
        });
          
        document.getElementById('building_search_id_values').style.display = "none";
        });
    break;
    case 'default':
    break;
     
    }
    document.getElementById('building_search_id_values').style.display = "block";
}
contains (arr, key, val) {
  let flag=0;
  let count=0;
  for (var i = 0; i < arr.length; i++) {
    count=count+1;
    if(arr[i][key] == val){
      flag=1;
      return true;
    } 
  }
  if(flag==0 && count==arr.length){
    return false;
  }
 
}
  getData=()=>{
    let applicationList = [{
          url: "/creator",
    }];
   
      postApiData(applicationList,{'additional_documents':'additional_documents','application_template':'application_template','application_screening':'application_screening','building_amenities':'building_amenities','marketing':'marketing'},false).then(res => {
        this.setState({
          linkingLoader: true,
          data:res.data,
        },()=>{
            res.data[4].map((value)=>{
              var val=this.contains(this.state.marketing, "name", value.name); //true
              if(value.approval_required==1 && value.name=='Renthop'){
                this.setState({renthop_approval:false});
                if(value.default_set==1){
                  this.setState({status:0});
                }
              }
              
              if(value.approval_required==1 && value.name=='Skyward'){
                this.setState({skyward_approval:false});
                if(value.default_set==1){
                  this.setState({status:0});
                }
              }
              if(value.approval_required==1 && value.name=='Localize'){
                this.setState({streetEasy_approval:false});
                if(value.default_set==1){
                  this.setState({status:0});
                }
              }
              if(value.approval_required==1 && value.name=='RLS'){
                this.setState({RLS_approval:false});
                if(value.default_set==1){
                  this.setState({status:0});
                }
              }
              if( value.default_set==1 &&  value.name=='RLS'){
                this.setState({RLSFlag:1});
              }
              if(this.state.marketing.length==0 && value.default_set==1){
                this.setState(state => (this.state.marketBoxes.push(value)));
              }else if(val && value.default_set==1)
              {
                this.setState(state => (this.state.marketBoxes.push(value)));
              }
                this.setState({ linkingLoader: false, flag:1})
            })

        })
         
      }).catch(error => {
      if(error.response){
          this.setState({myerrormsg: error.response.data.error.message});
      }
      });
  }
 
  handleSubmit = (e) => {
    e.preventDefault();
    let status=1;
    let rent_hop_export=0;
    this.state.marketBoxes.map((item)=>{

      if(item.name=='Renthop'){
        rent_hop_export=1;
      }
      if(item.approval_required==1){
        status=0
      }

    })
    if(document.getElementById("layout")){
      var layout = document.getElementById("layout").value;
      }
      if(document.getElementById("unit_view")){
      var unit_view=document.getElementById("unit_view").value;
      }
      if(document.getElementById("unit_condition")){
      var unit_condition=document.getElementById("unit_condition").value;
      }
    if(document.getElementById("pets")){
      var pets_policy=document.getElementById("pets").value;
    }
    // console.log("layout",layout);
    // console.log("unit_view",unit_view);
    // console.log("unit_conition",unit_condition);
    // console.log("pets_policy",pets_policy);
    // console.log("this.state.ownership",this.state.building_ownership);
    // console.log("this.state.exposure",this.state.exposures);
    // console.log("this.state.total_rooms",this.state.total_rooms);
    // console.log("Exclusive_expiration_Date",this.state.Exclusive_expiration_Date);
    const {
      building_address, building_name,landlord_name,landlord_email,landlord_phone,
      required_additional_documents,default_application_template,screenings_required,
      listing_information,listing_info_address,unit_number,bedrooms,bathrooms,
      square_footage,description, price,date_available,min_lease_term,furnished,incentives,
      checkedBoxes,marketBoxes,tenant_incentive,kuula_vr_link,imageForDb,noFee,linkingLoader,
      listing_id,image,building_id,imageIdFromDb,
    } = this.state;
    if(document.getElementById("owner_pays_broker")){
      var owner_pays_broker=document.getElementById("owner_pays_broker").value;
    }
    if(document.getElementById("CoBrokeAgreement")){
      var CoBrokeAgreement=document.getElementById("CoBrokeAgreement").value;
  }
    // if(this.ValidURL(this.state.kuula_vr_link) == false && this.state.kuula_vr_link!=''){
	
    //   this.setState({kuula_vr_link_error:'Please enter valid url'})
    // }else{
    //   this.setState({kuula_vr_link_error:''})

    let where = [{
      url: '/agent/editDetail'
    }];
    const formData = new FormData();
    formData.append('listing_id',listing_id)
    formData.append('listing_information',listing_information)
    formData.append('listing_info_address',listing_info_address)
    formData.append('unit_number',unit_number)
    formData.append('bedrooms',bedrooms)
    formData.append('bathrooms',bathrooms)
    formData.append('square_footage',square_footage)
    formData.append('description',description)
    formData.append('price',price)
    formData.append('date_available',date_available)
    formData.append('min_lease_term',min_lease_term)
    formData.append('incentives',incentives+','+this.state.owner_pays)
    formData.append('furnished',furnished)
    formData.append('unit_amenities',JSON.stringify(checkedBoxes))
    formData.append('rent_hop_export',rent_hop_export)
    formData.append('status',status)
    formData.append('marketing',JSON.stringify(marketBoxes))
    formData.append('tenant_incentive',tenant_incentive)
    formData.append('kuula_vr_link',kuula_vr_link)
    formData.append('noFee',noFee)
    formData.append('listing_type','Rental')
    // if(document.getElementById('searchkey')){
    // formData.append('building_id',document.getElementById('searchkey').value)
    // }
    formData.append('imageId',JSON.stringify(imageIdFromDb))
    if(this.props.userType=="Manager"){
      formData.append('agent_id',0)
    }else{
    formData.append('agent_id',getAgentId())
  }
    imageForDb.map((image,index)=>{
      formData.append('images',image.file);
   
    })
    imageForDb.map((image,index)=>{
         formData.append('images_rotation',image.current_rotation);
      
       })
    if(this.state.owner_pays == "Owner pays"){
      formData.append('owner_pays_broker',owner_pays_broker)
      formData.append('owner_amount',this.state.owner_amount)
      }
      if(this.state.incentives== "Co-broke"){
      formData.append('commission_percentage',this.state.commission_percentage)
      formData.append('my_split',this.state.my_split)
      }

    formData.append('layout',layout)
    formData.append('unit_view',unit_view)
    formData.append('unit_condition',unit_condition)
    formData.append('pets_policy',pets_policy)
    formData.append('building_ownership',this.state.building_ownership)
    formData.append('exposures',this.state.exposures)
    formData.append('total_rooms',this.state.total_rooms)
    formData.append('Exclusive_expiration_Date',this.state.Exclusive_expiration_date)
      postApiData(where,formData).then(res => {
                    if(res.data==202) // listing already exist
                    {
                      this.setState({linkingLoader:false, successQuote: '2',});
                           window.scrollTo(500, 0);
                    }
                    else
                    {
                      this.setState({ linkingLoader:true},()=>{
                        //  if(this.props.userType=="Agent")
                        // window.location.href="../../list/edit/";
                        //  if(this.props.userType=="Manager")
                        //   window.location.href="../../../managerlist/edit/";
                        this.setState({
                          redirect:true
                        })
                          this.setState({linkingLoader:false, successQuote: '1',});
                      })  
                    }
      }).catch(error => {
      this.setState({myerrormsg: error.response});
      });
 //   }
};

  //name attribute here is id from the Database 
  multiselectCheckbox = (param, value, name) => {
    if (param == "building_amenities") {
      var stateArray = this.state.building_amenities;
    }
    if (param == "marketing") {
      var stateArray = this.state.marketing;
    }
    if (param == "unit_amenities") {
      var stateArray = this.state.unit_amenities;
    }

    var flag = 0;
    var  temp ={related_id:name, name:value};
    var removeIndex;
    // this is launches only one when the array is empty
    if (stateArray.length === 0) {
      stateArray.push(temp);
    } else {
      //checking for the element if already exists
      stateArray.map(item => {
        if (item.name == value) {
          removeIndex = item;
          flag = 1;
        }
      });
    }
    //if flag=0, then element can be pushed
    if (flag === 0) {
      stateArray.push(temp);
      //removing duplicate elements if any
      stateArray = [...new Set(stateArray)];
    } else {
      //removing elements from the array on uncheck
      var index = stateArray.indexOf(removeIndex);
      stateArray.splice(index, 1);
    }
    this.setState(
      {
        [param]: stateArray
      },
      () => {
    
      }
    );
  };

  handleCheck=()=> {
    
    this.setState({noFee: !this.state.noFee,
    checkFlag:0});
  }

  handleCheckBox =(e, check)=>{
    let checkedBoxes = [...this.state.checkedBoxes];
    if(e.target.checked) {
    checkedBoxes.push(check)
    } else {
     let index = checkedBoxes.findIndex((ch) => ch.id === check.id);
      checkedBoxes.splice(index, 1);
    }
    this.setState({checkedBoxes});
  }

  handleMarketCheckBox =(e, check)=>{
    let marketBoxes = [...this.state.marketBoxes];
    if(e.target.checked) {
    marketBoxes.push(check)
    } else {
     let index = marketBoxes.findIndex((ch) => ch.id === check.id);
      marketBoxes.splice(index, 1);
    }
    this.setState({marketBoxes});
  }

  handleChange = e => {
    
    const id = e.target.id;
    const value = e.target.value;
    const dataset = e.currentTarget.dataset.id;
    const name = e.target.name;
	// console.log("id",id);
	// console.log("value",value);
  //  console.log('id : ', id);
  //   console.log('value : ', value);
  //   console.log('dataset : ', dataset);
  if(id==="owner_pays_broker"){
    this.setState({
      owner_pays_broker:value
    })
  }
    if (
      id === "building_address" ||
      id === "building_name" ||
      id === "landlord_name" ||
      id === "landlord_email" ||
      id === "landlord_phone"
    ) {
      var error = id + "_error";
      if (value == "") {
        // console.log("_error : ", error);
        this.setState({
          [error]: "Field cannot be empty",
          [id]: [value]
        });
      } else if (id == "landlord_phone") {
        const phoneValue = this.formValidate("landlord_phone", value);
        this.setState(
          {
            [id]: phoneValue
          },
          () => {
            // console.log("landlord_phone : ", this.state.landlord_phone);
          }
        );
      } else {
        this.setState({
          [error]: "",
          [id]: [value]
        });
      }
    }

    if (
      id == "required_additional_documents" ||
      id == "default_application_template" ||
      id == "screenings_required"
    ) {
      var error = id + "_error";
      if (value == "" || value == 0) {
        // console.log("_error : ", error);
        this.setState(
          {
            [error]: "Field cannot be empty",
            [id]: [value]
          },
          () => {
            // console.log("dropdown : ", value);
          }
        );
      } else {
        this.setState(
          {
            [error]: "",
            [id]: [value]
          },
          () => {
            // console.log("dropdown : ", value);
          }
        );
      }
    }

    if (
      id == "Elevator" ||
      id == "Doorman" ||
      id == "Laundry" ||
      id == "Gym" ||
      id == "Outdoor areas" ||
      id == "Live-in super" ||
      id == "Heat & Hot Water included"
    ) {
      this.multiselectCheckbox("building_amenities", value, name);
    }

    if (
      id == "Exclusive" ||
      id == "Open Listing" ||
      id == "Address"
    ) {
      this.setState({
        listingFlag:0,
      })

      if (id != "Address") {
        this.setState({
          listing_information: dataset
        });
        this.state.listing_informatio=dataset
      } else {
        this.setState({
          listing_info_address: value
				});
      }
    }

    if (id == "bedrooms" || id == "bathrooms" || id == "date_available" || id == "price" || id=="min_lease_term" || id=="total_rooms") {
      this.setState({
        checkFlag:0,
      })
      var error = id + "_error";
      if (value == "") {
        
        this.setState({
          [error]: "Field cannot be empty",
          [id]: [value]
        });
      } 
      if (isNaN(value) || value<-1) {
        
        this.setState({
          [error]: "Please enter numbers only",
          [id]: [value]
        });
      } 
      else {
        this.setState({
          [error]: "",
          [id]: [value]
        });
      }
    }
    if (id == "date_available" || id == "unit_number") {
      this.setState({
        checkFlag:0,
      })
      var error = id + "_error";
      if (value == "") {
        
        this.setState({
          [error]: "Field cannot be empty",
          [id]: [value]
        });
      } else {
        this.setState({
          [error]: "",
          [id]: [value]
        });
      }
    }

    if (id == "square_footage" ) {
      this.setState({
        checkFlag:0,
      })
     var error = id + "_error";
     if ((isNaN(value)  || value<0) && value != "") {
        this.setState({
          [error]: "Please enter numbers only",
          [id]: [value]
        });
      } 
      else
      {
          this.setState({
             [error]: "",
            [id]: [value]
          });
      }
    }
    if(id == "description")
    {
      this.setState({
        checkFlag:0,
      })
       this.setState({
        [id]: [value]
      });
    }

    if (id == "Nestio" || id == "Localize Syndication" || id == "RLS") {
      this.multiselectCheckbox("marketing", value, name);
    }

    if (
      id == "Cats allowed" ||
      id == "Dogs allowed" ||
      id == "Outdoor space" ||
      id == "Duplex" ||
      id == "Hardwood Floors" ||
      id == "Renovated bathroom" ||
      id == "Renovated Kitchen" ||
      id == "Dishwasher" ||
      id == "W/D" ||
      id == "Furnished/D" ||
      id == "Virtual Doorman"
    ) {
      this.multiselectCheckbox("unit_amenities", value, name);
    }

    if (
      id == "CYOF" ||
      id == "Owner pays" ||
      id == "Co-broke" ||
      id == "tenant_incentive" ||
      id == "noFee"
    ) {
      if (id === "tenant_incentive") {
        this.setState({
          tenant_incentive: value
        });
      } 
      else {
        if( id === "CYOF"){
          if(this.state.incentives!=""){
            this.setState({
              incentives: '',
              commission_percentage:'',
              my_split:'',
              co_broker_split:'',
              final_precentage:''
              
            },()=>{
              
              this.setState({incentives: dataset})
             });
          }
          else{
            this.setState({
              incentives: dataset,
              commission_percentage:'',
              my_split:0,
              co_broker_split:100,
              final_precentage:''
            });
          }
          
        }else{
          if(id==="Co-broke"){
            if(this.state.incentives!=""){
              this.setState({
                incentives: '',
              },()=>{
                this.setState({incentives: dataset})
              });
            }
            else{
              this.setState({
                incentives: dataset
              });
            }

          }
          else{
            this.setState({
              incentives: dataset
            });
          } 
       
      }
      }
      if (id == "noFee") {
        if (this.state.noFee == 0) {
          this.setState({
            noFee: 1
          });
        } else {
          this.setState({
            noFee: 0
          });
        }
      }
    }
    if (id == "CoBrokeAgreement") {
      if(document.getElementById("CoBrokeAgreement").value==""){
        this.setState(
          { 
            CoBrokeAgreement_error:"field cannot be empty",
            CoBrokeAgreement: ''
          });
      }
      else{
        this.setState(
          {
            CoBrokeAgreement_error:"",
            CoBrokeAgreement: document.getElementById("CoBrokeAgreement").value
          });
      }
      
    }
    if (id == "pets") {
      if(document.getElementById("pets").value==""){
        this.setState(
          { 
            pets_policy_error:"field cannot be empty",
            pets_policy: ''
          });
      }
      else{
        this.setState(
          {
            pets_policy_error:"",
            pets_policy: document.getElementById("pets").value
          });
      }
      
    }
    if (id == "kuula_vr_link") {
      this.setState(
        {
          [id]: value
        },
        () => {
          // console.log("kuula_vr_link : ", this.state.kuula_vr_link);
        }
      );
    }
  };

  onVrHandleChange=(event)=>{
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;

    if(id==='kuula_vr_link'){
      var error = id + "_error";
      this.setState({
        [error]: "",
        [id]: [value],
        validation:true
      });
     }
  }

  ValidURL=(str)=> {
    var regex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
    if(!regex .test(str)) {
      return false;
    } else {
      return true;
    }
  }


  handleChangeStatus = checked => {
    this.setState({
      furnishedFlag:0
    })
    if (checked) this.setState({ furnished: 1 });
    else this.setState({ furnished: 0 });
  };
 
  fileChangedHandler = event => {
    var imageFile = event.target.files;
    if (event.target.files) {
      for(var i=0;i<imageFile.length;i++){
      let imgFlag = true;
      const maxFileSize = 5242880; //5mb
      const value = event.target.files[i].name;
      const ext = value.substring(value.lastIndexOf("."));
      if (![".jpg", ".JPG", ".jpeg", ".png"].includes(ext)) {
        this.setState({ mymsg: ext + " is not supported file extension." });
        imgFlag = false;
      }
      if (event.target.files[i].size > maxFileSize) {
        this.setState({ mymsg: "File size is too big, upto 5MB allowed." });
        imgFlag = false;
      }
      if (imgFlag) {
        var options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        fromBlob(imageFile[i], 80, 'auto', 'auto', 'webp')
            .then(compressedFile => {
           
            var file = new File([compressedFile], value);
            var temp = [...this.state.imagePathArray];
            var temp2 = [...this.state.imageForDb];

            temp.push(URL.createObjectURL(file));
            temp2.push(file);
            this.setState(
              {
                imageForDb: temp2,
                imagePathArray: temp
              },
              () => {
                this.state.imageCount+=1;
                this.setState({imageCount:this.state.imageCount})
                this.state.isImage=true;
                }
            );
          })
          .catch(function(error) {
            // console.log(error.message);
          });
      }
    }
    }
  };
  
  
  closeBlobImage = image => {
    const { imagePathArray } = this.state;

    var index = imagePathArray.indexOf(image);
    let alteredImagePathArray = [...this.state.imagePathArray];
    let alteredImageForDb = [...this.state.imageForDb];
    alteredImagePathArray.splice(index, 1);
    // if(alteredImagePathArray.length==0){
    //   this.setState({
    //     imageCount:alteredImagePathArray.length
    //   })
    // }
    // else
    // {
    //   this.setState({
    //     imageCount:alteredImagePathArray.length
    //   })
    // }
    this.setState({imageCount:this.state.imageCount-1})
      alteredImageForDb.splice(index, 1);
     
      this.setState({ imagePathArray: alteredImagePathArray, imageForDb:alteredImageForDb},()=>{
      });
       }; 
  


  removeImage = imageId => { 
    
    this.state.imageIdForDb.push(imageId);
    this.setState({imageIdFromDb: this.state.imageIdForDb})
    document.getElementById(imageId).style.display ='none';
    this.setState({imageCount:this.state.imageCount-1})
  };

  formValidate(key, value) {
    switch (key) {
      case "landlord_phone":
        return this.validateEmpPhoneNumber(value);
        break;
    }
  }
  validateEmpPhoneNumber(value) {
    let res = validatePhone(value);
    if (res.status == false) {
      this.setState({ landlord_phone_error: "Phone number is not valid." });
      document.getElementById("landlord_phone").value = res.value;
      return res.value;
    } else {
      this.setState({ landlord_phone_error: "" });
      document.getElementById("landlord_phone").value = res.value;
      return res.value;
    }
  }

  validation = () => {
    
    const {
      listing_info_address,
      unit_number,
      bedrooms,
      bathrooms,
      price,
      date_available,
      min_lease_term,
      imageCount,
      isImage,
      total_rooms,
      description,
      pets_policy,
      building_ownership
    } = this.state;

  if(imageCount>0 || isImage)
  { 
  
    return (
      listing_info_address.toString().trim() !="" &&
      unit_number.toString().trim() != "" &&
      bedrooms.toString().trim() != "" &&
      bathrooms.toString().trim() != "" &&
      price.toString().trim() != "" &&
      date_available.toString().trim() != "" &&
      min_lease_term.toString().trim() != "" && 
      !isNaN(bedrooms) &&
      bedrooms>-1 &&
      !isNaN(bathrooms) &&
      bathrooms>0 &&
      !isNaN(price) &&
      price>0 &&
      imageCount >2 &&
      !isNaN(total_rooms) &&
      total_rooms >-1 &&
      total_rooms.toString().trim() != "" && 
      description!="" && 
      pets_policy!="" &&
      building_ownership!="" && building_ownership!='None'
    );
  }
  else{ 
    return (
      listing_info_address.toString().trim() !="" &&
      unit_number.toString().trim() != "" &&
      bedrooms.toString().trim() != "" &&
      bathrooms.toString().trim() != "" &&
      price.toString().trim() != "" &&
      date_available.toString().trim() != "" &&
      min_lease_term.toString().trim() != "" && 
      !isNaN(bedrooms) &&
      bedrooms >-1 &&
      !isNaN(bathrooms) &&
      bathrooms >0 &&
      !isNaN(price) &&
       price >0&&
       !isNaN(total_rooms)&&
       total_rooms >-1 &&
       total_rooms.toString().trim() != "" && 
       description!="" &&
       pets_policy!="" && 
       building_ownership!="" && building_ownership!='None'
    );
  }
  };

  onHandleCheckbox =  (event) =>{
    console.log("event.currentTarget.value",event.currentTarget.value)
    this.setState({
        exposures:event.currentTarget.value
    })
  }
  handleChangeExpirationDate=(expirationDate)=> {
    if(expirationDate=="" || expirationDate==null ){
    
      this.setState({
        Exclusive_expiration_Date:'',
        Exclusive_expiration_Date_error:'field cannot be empty'
  
      });
    }
    else{
      this.setState({
        Exclusive_expiration_date:Moment(expirationDate).format('MMMM DD, YYYY'),
        Exclusive_expiration_Date_error:''
      });
    }
  }
  
  onHandleChange=(event)=>{
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;
  
    if(id == "Condo" || id == "Co-op" || id == "Condop" || id == "Single Family" || id == "Multifamily"){
        this.setState({
            building_ownership : dataset
        })
    }
       
         if(id =="East" || id =="West" || id=="North" || id=="South"){
            this.setState({
                Exposures:[value]
            })
        }
       
    else{
        this.setState({
            [id]:[value]
        });
    }
  }
  changeHandler=(event)=>{
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;
    console.log('----dataset',dataset);
    console.log('id : ', id);
      console.log('value--- : ', this.state.owner_pays);
      console.log('dataset ----: ', dataset);
    const name = event.target.name;
    if(id=="Owner_pays"){
      if(this.state.owner_pays!=""){
        this.setState({
          owner_pays : '',
        },()=>{
          
        })
        this.state.owner_pays='';
      }
      else if(this.state.owner_pays==undefined){
        this.setState({
          owner_pays : dataset,
        },()=>{
          
        })
        this.state.owner_pays=dataset;
      }
      else{
        this.setState({
          owner_pays : dataset,
        },()=>{
          
        })
        this.state.owner_pays=dataset;
      }
      
    }
  
    if(id==="owner_amount"){
      if(value==""){
        this.setState({
          owner_amount :""
        })
      }else if(isNaN(value)){
        this.setState({
          owner_amount:""
        })
      }
      else{
      this.setState({
        owner_amount : value,
      })
    }
    }
    
  }
  onChangeHandler=(event)=>{
    // commission_percentage,
    // my_split,
    // co_broke_split
    
                if(event.target.id==="co_broke_split"){
                  this.setState({
                    [event.target.id]:event.target.value,
                    [event.target.id+"error"]:""
                  })
                }
                if(event.target.id==="commission_percentage"){
                  if(event.target.value =="" ){
                    this.setState({
                      [event.target.id]:"",
                      [event.target.id+"error"]:"Field can not be empty."
                        
                    })
                  }
                  else if(isNaN(event.target.value)){
                    this.setState({
                      [event.target.id]:"",
                      [event.target.id+"error"]:"Enter only numbers."
                    })
                  }else{
                    if( event.target.value <=100){
                    var _commission=this.state.my_split*event.target.value;
                    var _percentage=_commission / 100;
  
                    var _cocommission=event.target.value *(100-this.state.my_split);
                    var _copercentage=_cocommission/100;
  
                  this.setState({
                    [event.target.id]:event.target.value,
                    final_precentage:_percentage,
                    [event.target.id+"error"]:"",
                    copercentage_broker:_copercentage
                })
              }
            }
           }
                if( event.target.id==="my_split"){
                  if(event.target.value =="" ){
                    this.setState({
                      [event.target.id]:event.target.value,
                          co_broker_split:100,
                          final_precentage:0,
                          copercentage_broker:this.state.commission_percentage,
                          [event.target.id+"error"]:"Field can not be empty."
                    })
                  }else if(isNaN(event.target.value)){
                    this.setState({
                      [event.target.id]:"",
                      [event.target.id+"error"]:"Enter only number"
                    })
                  }
                  else{
                   
                    if( event.target.value <=100){
                    var _co_broker_split=100 - event.target.value
                    var _commission=event.target.value * this.state.commission_percentage;
                    var _percentage=_commission / 100;
                             
                    var _cocommission=this.state.commission_percentage*_co_broker_split;
                    var _copercentage=_cocommission/100;
  
   
                  this.setState({
                    [event.target.id]:event.target.value,
                    co_broker_split:100 - event.target.value,
                    final_precentage:_percentage,
                    [event.target.id+"error"]:"",
                    copercentage_broker:_copercentage
                  })
                  
                 
                }
              }
            }
  }
  exposuresCheckBox=(event)=>{ 
    var exposuresCheckBoxs;  
    var length;   
    if(this.state.exposures){
       exposuresCheckBoxs= this.state.exposures.split(',');
       var length=exposuresCheckBoxs.length;
   
    
    var flag=0;var count=0; var length=exposuresCheckBoxs.length;
        
            exposuresCheckBoxs.map(item=>{
              count=count+1;
                if(item==event.target.value){
                  var index = exposuresCheckBoxs.indexOf(event.target.value);
                      exposuresCheckBoxs.splice(index, 1);
                      flag=1;
                    var expo=  exposuresCheckBoxs.join(',');
                      this.setState({
                        exposures:expo
                      })
                }
            })
            if(length ==count && flag==0){
                  exposuresCheckBoxs.push(event.target.value);
                  var expo=exposuresCheckBoxs.join(',');
                  this.setState({
                    exposures:expo
                  })
            }
          }
          else{
            var expocheck=[]
            expocheck.push(event.target.value);
            var expo=expocheck.join(',');
            this.setState({
              exposures:expo
            })
          }
  } 
  render() {
     console.log("this.state",this.state.total_rooms 
   
      ,this.state.marketBoxes
      )
    if(this.state.redirect == true){
      if(this.props.userType == 'Agent'){
      return (<Redirect to={'/agent/list/edit'}/>);
    }
    if(this.props.userType == 'Manager'){
      return (<Redirect to={'/managerlist/edit'}/>)
    }
    }
    const { userType, heading } = this.props;
    if(this.props.userType == 'Agent'){
      
    }
    const {
      listing_information,
      listing_info_address,
      unit_number,
      bedrooms,
      bathrooms,
      square_footage,
      description,
      price,
      date_available,
      min_lease_term,
      incentives,
      tenant_incentive,
      imagePathArray,
      kuula_vr_link,
      amenityList,
      checkedBoxes,
      marketBoxes,
      list_images,
      //error
      building_address_error,
      landlord_name_error,
      landlord_email_error,
      landlord_phone_error,
      unit_number_error,
      bedrooms_error,
      bathrooms_error,
      square_footage_error,
      price_error,
      date_available_error,
      min_lease_term_error,
      default_application_template_error,
      screenings_required_error,kuula_vr_link_error,
      data,
      linkingLoader,
      building_ownership,
      total_rooms,
      total_rooms_error
    } = this.state;
    const blobImages = imagePathArray.map((image,index) => {
      for(let i=0; i<=imagePathArray.length;i++){
      return (
       
        <div className="col-sm-3">
        <div className="slider-frame" style={{width:'100%'}}>
       
        <img src={image.file} style={{transform: `rotate(${image.current_rotation}deg)`}} width="100%" />
          <div
            to
            className="close-thumb"
            onClick={this.closeBlobImage.bind(this, image)}
          >
            Close
          </div>
          <div className="rotate-wrap">
          <input onClick={this.rotateleft([image.file,index,image.current_rotation,])} type="button" value="" className="left-rotate" />
          <input onClick={this.rotate([image.file,index,image.current_rotation,])} type="button" value="" className="right-rotate" />
        </div>
        </div>
        </div>
      );
      }
    });

    var check;
    var image_list;
   
    if(this.state.list_images){
      image_list = this.state.list_images.map((item,index)=>{
        check=item.large_image.indexOf("https://");
        if(check==-1){
          check=item.large_image.indexOf("http://");
        }
          return   <div className="col-sm-3"  id={item.id} >
       <div className="slider-frame" style={{width:'100%'}}  >
      
      <img src={check > -1 ?  item.large_image : getBaseUrl()+"/containers/listing_images/download/" + item.large_image} style={{transform: `rotate(${item.rotation}deg)`}} width="100%" />
       {/* <div className="add-img" style={{background:'url('+ item.large_image+') center', backgroundSize:'cover'}} >
           </div>           */}
         <div
           to
           className="close-thumb"
           onClick={this.removeImage.bind(this, item.id)}
         >
           Close
         </div>

        {(check==-1)? <div className="rotate-wrap">
         <input onClick={this.rotateleftimagelist([item.large_image,index,item.rotation,])} type="button" value="" className="left-rotate" />
         <input onClick={this.rotateimagelist([item.large_image,index,item.rotation,])} type="button" value="" className="left-rotate" />
       </div>:''}
       </div>
       </div> 
        
        })
      
      }
    
    let document_name;
    let application_template;
    let screening_name;
    let Building_amenities;
    let Unit_amenities;
    let marketing;
    
    if(this.state.flag == 1){
       document_name = data[0].map((value)=>{
        return <option value={value.document_name}>{value.document_name}</option>
      })
      application_template = data[1].map((value)=>{
        return  <option value={value.application_template}>{value.application_template}</option>
      })
      screening_name = data[2].map((value)=>{
        return  <option value={value.screening_name}>{value.screening_name}</option>
      })
     
      Unit_amenities = data[3].map((value)=>{
        if(value.type == 1){
          var  val=contains(this.state.amenityList, "name", value.amenity_name); //true
          if(val && this.state.existCheckboxSelection)
          {
             checkedBoxes.push(value);
             this.setState({existCheckboxSelection:false});
             this.setState({checkedBoxes});
          }
                return <div className="checkbox-inline">
                <input
                  type="checkbox"
                  id={value.amenity_name}
                  value={value.amenity_name}
                  defaultChecked={val}
                  checked={checkedBoxes.find((ch) => ch.id === value.id)}
                  onChange={(e) => this.handleCheckBox(e, value)}
                />
                <label htmlFor={value.amenity_name}>{value.amenity_name}</label>
              </div>
        }
      });

     
     
        
     
  console.log(data[4]);
      marketing = data[4].map((value)=>{
        
        // var  val=contains(this.state.amenityList, "name", value.name); //true
        // if(val && this.state.existmarketCheckboxSelection)
        // {
        //    marketBoxes.push(value);
        //    this.setState({existmarketCheckboxSelection:false});
        //    this.setState({marketBoxes});
        // }
          // var  val=contains(this.state.amenityList, "name", value.name);
           if(this.state.rentHop ==1 && value.name=="Renthop" &&  this.state.existmarketCheckboxSelection){
          marketBoxes.push(value);
          this.setState({existmarketCheckboxSelection:false});
          this.setState({marketBoxes});
        }

      if(value.name!="RLS")    
   {
       
        
          return <div className="checkbox-inline">
                  <input 
                    type="checkbox" 
                    id={value.name} 
                    value={value.name}
                    // defaultChecked={val}
                    // checked={marketBoxes.find((ch) => ch.id === value.id)}
                    checked={ marketBoxes.find((ch) => ch.id === value.id)|| this.state.rentHop ==1 && value.name=="Renthop"}
                    onChange={(e) => this.handleMarketCheckBox(e, value)}
                    disabled={this.state.rentHop ==1 && value.name=="Renthop"}
                  
                  />
                  <label htmlFor={value.name}>{value.name}</label>
                </div>
   }
         });
    }

        function contains (arr, key, val) {
          for (var i = 0; i < arr.length; i++) {
            if(arr[i][key] === val) return 'Checked';
          }
          return false;
        }	

        var ownerpayData;
        if(this.state.owner_pays_data){
          ownerpayData =  this.state.owner_pays_data.map((item,index)=>{
          return   <option  value={item.owner_pays_broker} >{item.owner_pays_broker}</option>
          })}
        var dropdownData;
        if(this.state.layoutData ){
       dropdownData =  this.state.layoutData.map((item,index)=>{
          return   <option   value={item.layout} selected={this.state.layout===item.layout ? true:false} >{item.layout}</option>
          })}
    return (
      <div>
        {userType=='Agent'? <AgentHeader /> : <ManagerHeader />}
        <main>
          {userType=='Agent'? <AgentSideBar /> : <ManagerSidebar />}
          <div className="content">
            <div className="work-section">
              <div className="dashboard">
                <h2>{heading}</h2>
								{/* <Tabs>
								<TabList>
									<Tab onClick={()=>this.setTab()}>Rental</Tab>
									<Tab onClick={()=>this.setTab('Sales')}>SALES</Tab>
								</TabList>
						
								<TabPanel> */}
                {(linkingLoader) && <div className="show_loader_on_click" id="show_loader_on_click"></div>}
                <form onSubmit={this.handleSubmit}>
                {(this.state.successQuote==1)?(<div className="alert alert-success"><strong>Form Successfully Submitted!</strong></div>):(this.state.successQuote==2)?(<div className="alert alert-danger"><strong>Listing Already Exist, Please Try To Add Another Listing</strong></div>):''} 
                  <div className="rental-application listing-creator-section">
                    <div className="listing-block">
                               
                                        
                      {/* ........................................ */}
                      <div className="listing-block">
                        <h4>Listing information*</h4>
                        <ul className="btn-inline">
                          <li
                            className="btn"
                            style={
                              listing_information == "Exclusive"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            onClick={this.handleChange}
                            id="Exclusive"
                            data-id="Exclusive"
                          >
                            Exclusive
                          </li>
                          {(this.state.RLSFlag)?<li
                            className="btn disabled"
                            style={{ visibility: "visible" }}
                            id="Open Listing"
                            data-id="Open Listing"
                          >
                            Open Listing
                          </li>:
                          <li
                            className="btn"
                            style={
                              listing_information == "Open Listing" || listing_information == "null"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            onClick={this.handleChange}
                            id="Open Listing"
                            data-id="Open Listing"
                          >
                            Open Listing
                          </li>}
                          {(this.state.listing_information=="Exclusive" ||this.state.listing_information!="" && this.state.listing_information!="Open Listing" && this.state.listing_information!="null") ?
                          <li>  <div className="form-group" style={{width:'210px', textAlign:'center', margin:'0px', position:'relative', top:'10px'}}>
              <label style={{textAlign:'center'}}>Exclusive expiration date*</label>
                {/* <input type="text" className="form-control" /> */}
                {/* selected={this.state.Exclusive_expiration_date} */}
                                <DatePicker
                                                 
                                                 onChange={this.handleChangeExpirationDate}
                                                 dateFormat="MMMM d, yyyy"
                                                 value={this.state.Exclusive_expiration_date}
                                                 timeCaption="time"
                                                 className="form-control"
                                                 id="Exclusive_expiration"                               
                                                  />        
                    </div>
                    <span className="inline-error-class">
                                                            {" "}
                                                            {this.state.Exclusive_expiration_Date_error !== ""
                                                                ? this.state.Exclusive_expiration_Date_error
                                                                : ""}
                                                        </span> 
                      </li>:""}
                        </ul>
                        
                        <div className="row">
                          <div className="col-sm-5">
                            <label>Address*</label>
                            <div className="form-group">
                            {listing_info_address!=='' && listing_info_address!=="null" && this.state.addressFlag==1 ?  
                              <input type="text" className="form-control" id="Address" onChange={this.handleBuildingSearch} onKeyDownCapture={this.handleBuildingSearch} onKeyUpCapture={this.handleBuildingSearch}  autoComplete="off" value={(this.state.listing_info_address!=0)?this.state.listing_info_address:''}  
                              style={ listing_info_address ==="null" || listing_info_address =='' ? { borderBottom: '2px solid red' } :  { visibility: "visible" }} readOnly/>
                              :
                               <input type="text" className="form-control" id="Address" onChange={this.handleBuildingSearch} onKeyDownCapture={this.handleBuildingSearch} onKeyUpCapture={this.handleBuildingSearch}  autoComplete="off" value={(this.state.listing_info_address!=0)?this.state.listing_info_address:''}  
                               style={ listing_info_address ==="null" || listing_info_address =='' ? { borderBottom: '2px solid red' } :  { visibility: "visible" }} />                          
                              }
                              <div className="box agentNameOnBoard" id="building_search_id_values" style={{display:'none'}} >
                                              <ul>{this.state.buildingDW}
                                             </ul>
                                        </div>
                                        <span className="inline-error-class">
                                                            {" "}
                                                            {building_address_error !== ""
                                                                ? building_address_error
                                                                : ""}
                                                        </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Unit Number*</label>
                           {unit_number !=='' && unit_number !== "null" ?   
                            <input
                              type="text"
                              className="form-control"
                              id="unit_number"
                              value={unit_number}
                              onChange={this.handleChange}
                              style={ unit_number ==="null" || unit_number =='' ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                              readOnly
                           />
                            :
                            <input
                            type="text"
                            className="form-control"
                            id="unit_number"
                            value={unit_number}
                            onChange={this.handleChange}
                            style={ unit_number ==="null" || unit_number =='' ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                       
                         />
                            }
                            <small className="leftbedge">#</small>
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {unit_number_error !== ""
                                ? unit_number_error
                                : ""}
                            </span>
                          </div>
                        </div>
                               <ul className="btn-inline sell-inline-btn">
                <li onClick={this.onHandleChange} className="btn" id="Multifamily" data-id="Multifamily"
                                style={ building_ownership == "Multifamily" || building_ownership == "Multi-family" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Multifamily</li>
                <li onClick={this.onHandleChange} className="btn" id="Condo" data-id="Condo"
                                style={ building_ownership == "Condo" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Condo </li>                     
                                <li onClick={this.onHandleChange} className="btn" id="Co-op" data-id="Co-op" 
                                style={ building_ownership == "Co-op" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Co-op</li>
                <li onClick={this.onHandleChange} className="btn" id="Condop" data-id="Condop"
                                style={ building_ownership == "Condop" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Condop</li>
                <li onClick={this.onHandleChange} className="btn" id="Single Family" data-id="Single Family" 
                                style={ building_ownership == "Single Family" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Single Family</li>
              </ul>
         

              
                      </div>

                      {/* ........................................ */}
                      <div className="listing-block">
                        <h4>Unit information</h4>
                        <div className="form-inline">
                        <div className="form-group">
                            <label>Total Rooms*</label>
                            {total_rooms!==0 && this.state.checkFlag==1?   
                            <input
                              type="text"
                              className="form-control"
                              id="total_rooms"
                              value={total_rooms}
                               onChange={this.handleChange}
                              style={ total_rooms.toString().trim()==''? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                              
                            />:
                            <input
                              type="text"
                              className="form-control"
                              id="total_rooms"
                              value={total_rooms}
                              onChange={this.handleChange}
                              style={ total_rooms.toString().trim()==''? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            />
                            }
                            {/* <small className="leftbedge">#</small> */}
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {total_rooms_error !== ""
                                ? total_rooms_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Bedrooms*</label>
                            {bedrooms!=='' && bedrooms!=="null" && bedrooms!==0 && this.state.checkFlag==1 ?   
                            <input
                              type="text"
                              className="form-control"
                              id="bedrooms"
                              value={bedrooms}
                              onChange={this.handleChange}
                              style={ bedrooms =='' || bedrooms==="null"? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                              
                            />
                            :
                            <input
                            type="text"
                            className="form-control"
                            id="bedrooms"
                            value={bedrooms}
                            onChange={this.handleChange}
                            style={ bedrooms.toString().trim()=='' || bedrooms.toString().trim()==="null"? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                          />
                            }
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {bedrooms_error !== "" ? bedrooms_error : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Layout</label>
                          
                              <select  id="layout" className="form-control" style={{width:'200px'}}>
                                <option>Select</option>
                                {dropdownData}
                              </select>
                           
                          </div>
                          <div className="form-group">
                            <label>Bathrooms*</label>
                            {bathrooms!=='' && bathrooms!=="null" && this.state.checkFlag==1 ?  
                            <input type="text" className="form-control"  id="bathrooms" value={bathrooms} onChange={this.handleChange}
                            style={ bathrooms =='' || bathrooms==="null" || bathrooms<=0 ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            
                              />
                            :
                            <input type="text" className="form-control"  id="bathrooms" value={bathrooms} onChange={this.handleChange}
                              style={ bathrooms =='' || bathrooms==="null"  || bathrooms<=0 ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            />
                            }
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {bathrooms_error !== "" ? bathrooms_error : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Square Footage</label>
                           
                            <input type="text" className="form-control size" id="square_footage" value={square_footage} onChange={this.handleChange} />
                            
                            <small className="rightbedge">sq. ft.</small>
                            <br />
                             <span className="inline-error-class">
                              {" "}
                              {square_footage_error !== "" ? square_footage_error : ""}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Description*</label>
                            <div className="form-group">                    
                            <textarea id="description"
                             value={description} 
                             onChange={this.handleChange}
                             style={ description =="" ? { border: '2px solid red',width:'98%'} :  { visibility: "visible",width:'98%' }}/>
                          
                            </div>
                          </div>
                        </div>

                        <div className="clearfix">
                        <div className="check-block pull-left">
                      <label style={{marginBottom:'15px'}}>Exposures</label>
                        <div class="form-group ">
                          <div className="checkbox-inline">
                                                            <input type="checkbox"
                                                             id="North"
                                                            onChange={this.exposuresCheckBox}
                                                             value="North"
                                                             name="myCheckbox"
                                                            checked={(this.state.exposures!="" && this.state.exposures)?(this.state.exposures.includes('North'))? 'checked':'':''}
                                                             />
                              <label for="North">North</label>
                            </div>
                            <div className="checkbox-inline">
                                                            <input type="checkbox"
                                                             id="South"
                                                          onChange={this.exposuresCheckBox}
                                                            value="South"
                                                             name="myCheckbox"
                                                             checked={(this.state.exposures!="" && this.state.exposures)?(this.state.exposures.includes('South'))? 'checked':'':''}
                                                             />
                              <label for="South">South</label>
                            </div>
                            <div className="checkbox-inline">
                                                            <input type="checkbox" 
                                                             id="East"
                                                             onChange={this.exposuresCheckBox}
                                                            value="East"
                                                             name="myCheckbox"
                                                             checked={(this.state.exposures!="" && this.state.exposures)?(this.state.exposures.includes('East'))? 'checked':'':''}
                                                             />
                              <label for="East">East </label>
                            </div>
                            <div className="checkbox-inline">
                                                            <input type="checkbox" 
                                                             id="West"
                                                             onChange={this.exposuresCheckBox}
                                                             value="West"
                                                             name="myCheckbox"
                                                             checked={(this.state.exposures!="" && this.state.exposures)?(this.state.exposures.includes('West'))? 'checked':'':''}
                                                             />
                              <label for="West">West</label>
                            </div>
                          </div>  
                      </div>
                      <div class="form-inline pull-left" style={{marginLeft:'24px'}}>
                          
                          <div className="form-group">
                            <label>Unit Conditon </label>
                              <select id="unit_condition"className="form-control" style={{width:'200px'}}   
                       >    
                                  <option value="">Select</option>   
                                  <option value="New">New</option>   
                                  <option value="Excellent">Excellent</option>   
                                  <option value="Good">Good</option>   
                                  <option value="Fair">Fair</option>   
                                  <option value="Poor">Poor</option>  
                                  <option value="Wrecked">Wrecked</option> 
                              </select>
                                                        {/* <input type="text" 
                                                           className="form-control"
                                                        id="unit_view"
                                                        onChange={this.onHandleChange}
                                                        value={unit_view}/> */}
                                                         {/* <br /> */}
                                          {/* <span className="inline-error-class">
                                        {" "}
                                           {unit_view_error !== ""
                                             ? unit_view_error
                                             : ""}
                                          </span>  */}
                          </div>
                          <div className="form-group">
                            <label>Unit View</label>
                            <select id="unit_view" className="form-control" style={{width:'200px'}}> 
                                <option value="">Select</option>   
                                <option value="Open View">Open View</option>   
                                <option value="Obstructed View">Obstructed View</option>   
                                <option value="Partially Obstructed View">Partially Obstructed View</option>                               
                             </select>
                                                        {/* <input type="text" 
                                                           className="form-control"
                                                        id="unit_condition"
                                                        value={unit_condition}
                                                        onChange={this.onHandleChange}/>
                                                     <br />
                                          <span className="inline-error-class">
                                        {" "}
                                           {unit_condition_error !== ""
                                             ?unit_condition_error
                                             : ""}
                                          </span>  */}
                          </div>
                          <div className="form-group">
                            <label>Pet Policy*</label>
                            <select id="pets"
                             className="form-control"
                              style={{width:'200px'}} 
                              onChange={this.handleChange}
                              style={ this.state.pets_policy=="" || this.state.pets_policy=="Select" ? { borderBottom: '2px solid red',width:'200px'} :  { visibility: "visible",width:'200px' }}
                              > 
                                <option value="">Select</option>   
                                <option value="Cats Allowed">Cats Allowed</option>   
                                <option value="Dogs Allowed">Dogs Allowed</option>   
                                <option value="Pets Allowed">Pets Allowed</option>     
                                <option value="No Pets Allowed">No Pets Allowed</option>                             
                             </select>
                          </div>   
                    </div>
                    </div>
                      </div>

                      {/* ........................................ */}
                      <div className="listing-block">
                        <h4>Lease information</h4>
                        <div className="form-inline">
                          <div className="form-group">
                            <label>Price* </label>
                         {price!=='' && price!=="null"  && price!==0  && this.state.checkFlag==1?    
                            <input
                              type="text"
                              className="form-control"
                              id="price"
                              value={price}
                              onChange={this.handleChange}
                              style={ price == 0 ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            
                            />
                            :  
                            <input
                            type="text"
                            className="form-control"
                            id="price"
                            value={price}
                            onChange={this.handleChange}
                            style={ price == 0 ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            
                          /> 
                            }
                            <small className="leftbedge">$</small>
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {price_error !== ""
                                ? price_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Date Available*</label>
                            {date_available!=='' && date_available!=='null' && this.state.checkFlag==1  ?  
                            <DatePicker
                             
                              onChange={this.handleChangeDate}
                              dateFormat="MMMM d, yyyy"
                              timeCaption="time"
                              className="form-control"
                              id="date_available"     
                              value={ this.state.startDate=='' &&  this.state.startDate=='0000-00-00'? Moment(date_available).format('MM/DD/YYYY') :Moment(this.state.startDate).format('MM/DD/YYYY')}                    
                              // style={ bathrooms =='' || bathrooms==="null"? { borderBottom: '2px solid red' } :  { visibility: "visible" }}                   
                                               
                                                  />
                                :
                                <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate}
                                dateFormat="MMMM d, yyyy"
                                timeCaption="time"
                                className="form-control"
                                id="date_available"     
                                value={ this.state.startDate=='' ? Moment(date_available).format('MM/DD/YYYY') :Moment(this.state.startDate).format('MM/DD/YYYY')}                    
                                // style={ bathrooms =='' || bathrooms==="null"? { borderBottom: '2px solid red' } :  { visibility: "visible" }}                   
                                                    />
                                }
                               <br />
                            <span className="inline-error-class">
                              {" "}
                              {date_available_error !== ""
                                ? date_available_error
                                : ""}
                            </span>                   
                           </div>
                          <div className="form-group">
                            <label>Min. Lease Term*</label>
                          {min_lease_term!=='' && min_lease_term!=="null" && min_lease_term!==0 && this.state.checkFlag==1 ?   
                            <input
                              type="text"
                              className="form-control"
                              id="min_lease_term"
                              value={min_lease_term}
                              onChange={this.handleChange}
                              style={ min_lease_term == '' || min_lease_term==="null"?  { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                              
                            />
                            :
                            <input
                            type="text"
                            className="form-control"
                            id="min_lease_term"
                            value={min_lease_term}
                            onChange={this.handleChange}
                            style={ min_lease_term == '' || min_lease_term==="null"?  { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                          />
                             }
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {min_lease_term_error !== ""
                                ? min_lease_term_error
                                : ""}
                            </span>    
                          </div>
                          <div className="form-group" id="switch"> 
                          {this.state.furnished==1 && this.state.furnishedFlag==1 ?
                            <Switch
                              onChange={this.handleChangeStatus}
                              checked={this.state.furnished}
                              className="react-switch"
                              id="normal-switch"
                           
                           
                            />:
                            <Switch
                              onChange={this.handleChangeStatus}
                              checked={this.state.furnished}
                              className="react-switch"
                              id="normal-switch"
                            />}
                            <span style={{ marginLeft: "10px" }}>
                              Furnished
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* ........................................ */}
                      <div className="listing-block check-block">
                        <h4>Unit Amenities</h4>

                        <div
                          className="form-group"
                          // onChange={this.handleChange}
                        >
                        {Unit_amenities}
                        </div>
                      </div>
                      
                      {/* ........................................ */}
                      <div className="listing-block">
                        <h4>Incentives</h4>
                        <ul className="btn-inline">
                          <li
                            className="btn"
                            onClick={this.handleChange}
                            style={
                              incentives == "CYOF"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            id="CYOF"
                            data-id="CYOF"
                          >
                            CYOF
                          </li>
                          <li
                            className="btn"
                            onClick={this.changeHandler}
                            style={
                             this.state.owner_pays == "Owner pays"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            // onClick={this.handleChange}
                            id="Owner_pays"
                            data-id="Owner pays"
                          >
                            Owner pays
                          </li>
                          <li
                            className="btn"
                            onClick={this.handleChange}
                            style={
                              incentives == "Co-broke"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            id="Co-broke"
                            data-id="Co-broke"
                          >
                            Co-broke
                          </li>
                        </ul>

                        <div
                          className="form-inline"
                          onChange={this.handleChange}
                        >
                           {this.state.owner_pays=="Owner pays" ?
                          <div>
                        <div className="form-group">
                            <label>Owner Pays Broker</label>
                              <select  id="owner_pays_broker"  className="form-control" style={{width:'200px'}}>
                                <option value="">Select</option>
                                {ownerpayData }
                              </select>
                             
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="owner_amount"
                              value={this.state.owner_amount}
                              onChange={this.changeHandler}
                            />
                            <small className="rightbedge">{this.state.owner_pays_broker=="Month(s) Rent" ? '':(this.state.owner_pays_broker=='Percentage Of Yearly Rent' || this.state.owner_pays_broker=='Percentage Of Monthly Rent') ?  "%" :"$"}</small>
                          </div>
                          </div> :""}
                          {(this.state.incentives=="Co-broke") ? 
                          <div>
                          <div className="form-group">
                            <label for="Tenant incentive">
                              Commission Percentage
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="commission_percentage"
                              value={this.state.commission_percentage}
                              onChange={this.onChangeHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.commission_percentage ==undefined || this.state.commission_percentage=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />
                            <small className="rightbedge">%</small>
                            <br/>
                            <div> <span className="inline-error-class"></span></div>
                           
                          </div>
                          <div className="form-group">
                            <label for="Tenant incentive">
                             My Split
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="my_split"
                              value={this.state.my_split}
                              onChange={this.onChangeHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.my_split ==undefined || this.state.my_split=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />
                             <span>{this.state.final_precentage ? this.state.final_precentage+"%" : ''}</span>
                            {this.state.final_precentage?"":<small className="rightbedge">%</small>}
                         
                          </div>
                          <div className="form-group">
                            <label for="Tenant incentive">
                              Co-Broker's Split
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="co_broke_split"
                              value={this.state.co_broker_split +"%"}
                              // onChange={this.onChangeHandler}
                              disabled
                            />   <span >{this.state.copercentage_broker ? this.state.copercentage_broker+"%":''}</span>
                            {this.state.copercentage_broker?"":<small className="rightbedge">%</small>} 
                         
                           </div>
                          </div>
                         : ""}
                          <div className="form-group">
                            <label for="Tenant incentive">
                              Tenant incentive
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="tenant_incentive"
                              value={tenant_incentive}
                            />
                            <small className="righttbedge">months free</small>
                          </div>
                          <div
                            className="check-block"
                            style={{ display: "inline" }}
                          >
                            <div className="form-group ">
                              <div className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  id="noFee"
                                  value="noFee"
                                  onChange={this.handleCheck}
                                  checked={this.state.noFee}
                                />
                                <label htmlFor="noFee">Advertise as No Fee</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="listing-block check-block">
                        <h4>Marketing</h4>
                        <div
                          className="form-group"
                          // onChange={this.handleChange}
                        >
                        {marketing}
                        </div>
                      </div>
                      <div className="listing-block">
                        <h4>Media</h4>
                         (Please upload at least 3 images)  
                        <div className="row clerfix">
                        
                          <div className="col-sm-12">
                          <div className="row clerfix">
                          {image_list }
                          {blobImages}
                          <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
             
              <div className="col-sm-3">
                <div className="file-upload add-image-block"></div>
              </div>
                              </div>
                             
          </section>
        )}
      </Dropzone>
                          </div>
                          
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <div className="form-group">
                              <label>VR Link</label>
                              <input
                                type="text"
                                className="form-control"
                                id="kuula_vr_link"
                                value={kuula_vr_link}
                                onChange={this.onVrHandleChange}
                              />
                               <span className="inline-error-class">
                              {" "}
                              {kuula_vr_link_error !== ""
                                ? kuula_vr_link_error
                                : ""}
                            </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                           disabled={!this.validation()}
                          className="btn btn-default"
                          style={{ margin: "10px" }}
                        >
                          Submit listing for approval
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
								{/* </TabPanel>	
								<TabPanel>

								<SalesCreator propsValue={[{'userType':this.props.userType , 'tabPanel':this.state.tabPanel}]} />
								
								</TabPanel>
							</Tabs> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

ListingCreator.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
};
export default ListingCreator;
