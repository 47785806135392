import React from 'react';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { getApiData,postApiData} from '../../services/Api';
import { Redirect } from 'react-router';



class filterList extends React.Component {
    constructor(props) {
        super(props);
        console.log("props=>",props);
		this.state={
            _value_: { min: (props._filter_._minPrice_!=undefined)?props._filter_._minPrice_:0, max: (props._filter_._maxPrice_!=undefined)?props._filter_._maxPrice_:4250000 },
            bedRoom_0:(props._filter_!=undefined)?props._filter_.bedRoom_0:-1,
            bedRoom_1:(props._filter_!=undefined)?props._filter_.bedRoom_1:0,
            bedRoom_2:(props._filter_!=undefined)?props._filter_.bedRoom_2:0,
            bedRoom_3:(props._filter_!=undefined && props._filter_.maxBed)?3:0,
            bedRoom_0_checked:(props._filter_!=undefined && props._filter_.bedRoom_0!=-1)?'checked':'',
            bedRoom_1_checked:(props._filter_!=undefined && props._filter_.bedRoom_1==1)?'checked':'',
            bedRoom_2_checked:(props._filter_!=undefined && props._filter_.bedRoom_2==2)?'checked':'',
            bedRoom_3_checked:(props._filter_!=undefined && props._filter_.maxBed)?'checked':'',
            makingOfFilters:[],
            neighborhoodList:[],
            amenitiesList:(props._filter_!=undefined && props._filter_.amenities)?props._filter_.amenities:'',
            buildingList:(props._filter_!=undefined && props._filter_.building)?props._filter_.building:'',
            redirect:false,
            pageType:(props._filter_!=undefined)?props._filter_.pageType:'landing',
            pageParams:window.location.search,
            neighborhoodString:(props._filter_!=undefined && props._filter_.neighborhood!='')? props._filter_.neighborhood.substring(0,props._filter_.neighborhood.indexOf(',')):'',
            selectedneighborhood:(props._filter_!=undefined)?props._filter_.neighborhood:'',
            VR:(props._filter_!=undefined)?props._filter_.vr:'0',
            VR_checked:(props._filter_!=undefined && props._filter_.vr==1)?'checked':'',
            filterType:"Sell",
            steps:'300000',
            buildingType:(props._filter_!=undefined && props._filter_.buildingType!=undefined)? props._filter_.buildingType:'',
             recordNextPage:(props._filter_.recordNextPage!=undefined && props._filter_.recordNextPage)?props._filter_.recordNextPage:'10',
             selectedBuildingId:'',
             addressParam:[],
             address:(props._filter_.address!=undefined)?props._filter_.address:'',
        }
         console.log('Values==>',this.state._value_)
        // to close the dropdown on clickoutside
    this.setWrapperRef2 = this.setWrapperRef2.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.rentFilter=this.rentFilter.bind(this);
  
    }
    
/**
 * make filter dyanamic
 */
componentWillMount =()=>{
    this.MyNeighborhood();
    this.MakeAdvanceFilter();  
    document.removeEventListener('mousedown', this.handleClickOutside);
}

componentDidMount =()=>{

    if(this.props.handleReturnedResult){
        this.props.handleReturnedResult({
            bedRoom_0:this.state.bedRoom_0,
            bedRoom_1:this.state.bedRoom_1,
            bedRoom_2:this.state.bedRoom_2,
            maxBed:(this.state.bedRoom_3==3)?true:false,
            _maxPrice_:this.state._value_.max,
            _minPrice_:this.state._value_.min,
            amenities:this.state.amenitiesList,
            building:this.state.buildingList,
            neighborhood:this.state.selectedneighborhood,
            vr:this.state.VR,
            filterType:"Sell",
            buildingType:this.state.buildingType,
            address:this.state.address
        });

    }
    

    document.addEventListener('mousedown', this.handleClickOutside);
}
/* Blank Address field */

        // Clickoutside
        setWrapperRef2(node) {
            this.wrapperRef2 = node;
        }

        

        handleClickOutside(event) {
            if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
                var x = document.getElementById("cityListRent");
   
        x.style.display = "none";
    }
}

/**
 * get values nighborhood
 */
MyNeighborhood=()=> {
    let myneighborhood = [{
        url: "/neighborhood",
    }];
    getApiData(myneighborhood,false).then(res => {
        this.setState({neighborhoodList:res.data});
    }).catch(error => {
        if(error.response){
           this.setState({myerrormsg: error.response.data.error.message});
        }
    });
}
 
/**
 * get values from DB
 */
MakeAdvanceFilter=()=> {
    let makingOFFilters = [{
        url: "/filters",
    }];
    getApiData(makingOFFilters,false).then(res => {
        this.setState({makingOfFilters:res.data});
        this.addressDD();
    }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
    });
}

/**
 *  AdvanceSearch open advance filter box
 */
AdvanceSearch() {
    var x = document.getElementById("A_Filter_R");
          if (x.style.display === "none") {   
            document.getElementById("openMsg_r").style.display = "none"; 
            document.getElementById("closeMsg_r").style.display = "block";      
            x.style.display = "block";
      
    } else {
        document.getElementById("openMsg_r").style.display = "block"; 
        document.getElementById("closeMsg_r").style.display = "none";    
        x.style.display = "none";
       

    }
}

/*filterShowHide() {
    var x = document.getElementById("A_Filter_R");
    if (x.style.display === "none") {
        x.style.display = "block";
    } else {
        x.style.display = "none";
    }
}*/

rentFilter() {
    console.log("this.props",this.props);
    var sFilter = document.getElementById("saleFilter");
    var rFilter = document.getElementById("rentFilter");  
    rFilter.style.display = "none";
    sFilter.style.display = "block";
    if(this.props!=undefined && this.state.pageType!='landing'){
        this.props.searchT({
            searchType:'Default',
            value:{
                min:0,
                max:4000000
            }
        });
    }
}

/**
* make filter
*/

search=(event) =>{
    var key=event.target.id; 
    let flag=0;
    console.log("event.target.id",event.target.id);
    switch(key){
    case 'bedRoom_0':     
        if(this.state.bedRoom_0==-1){
            this.setState({
                bedRoom_0:0,
                bedRoom_0_checked:'checked'
            });
        }else if(this.state.bedRoom_0==0){
            this.setState({
                bedRoom_0:-1,
                bedRoom_0_checked:''
            });
        }
        break;
    case 'bedRoom_1':     
    if(this.state.bedRoom_1==0){
        this.setState({
            bedRoom_1:1,
            bedRoom_1_checked:'checked'
        });
    }else if(this.state.bedRoom_1==1){
        this.setState({
            bedRoom_1:0,
            bedRoom_1_checked:''
        });
    }
    break;
    case 'bedRoom_2':     
    if(this.state.bedRoom_2==0){
        this.setState({
            bedRoom_2:2,
            bedRoom_2_checked:'checked'
        });
    }else if(this.state.bedRoom_2==2){
        this.setState({
            bedRoom_2:0,
            bedRoom_2_checked:''
        });
    }
    break;
    case 'bedRoom_3':     
    if(this.state.bedRoom_3==0){
        this.setState({
            bedRoom_3:3,
            bedRoom_3_checked:'checked'
        });
    }else if(this.state.bedRoom_3==3){
        this.setState({
            bedRoom_3:0,
            bedRoom_3_checked:''
        });
    }
    break;
    case '_VR_':     
    if(this.state.VR==0){
        this.setState({
            VR:1,
            VR_checked:'checked'
        });
    }else if(this.state.VR==1){
        this.setState({
            VR:0,
            VR_checked:''
        });
    }
    break;
    case 'searchAddressParm':
        var value=event.target.value;
        document.getElementById('searchAddressParm_UL').style.display='block'
        this.setState({
            address:value
        })

        if(value==''){
			this.state.addressParam.forEach(function(client,index) {
				if(client.building_name && client.building_name.trim().toLowerCase().includes(value.trim().toLowerCase())){
					client.flag=0;
					}
				});
			this.setState({searchAddressMsg:false});
		}else {
			var len = this.state.addressParam.length;
			var count=0;
			var vflag=0;

			this.state.addressParam.forEach(function(client,index) {
				if(client.building_name &&  client.building_name.trim().toLowerCase().includes(value.trim().toLowerCase())){
						client.flag=1;
						vflag=1;
						count=count+1;
					}else{
						client.flag=0;
						if(vflag!=1){
                            vflag=0
                        }
						count=count+1;
					}

				});

			if(len==count){
				if(vflag==0){
					this.setState({searchAddressMsg:true});
					
				}else{
					this.setState({searchAddressMsg:false});				
				}	
			}
		}
    }

    
    if(event.target.id=='buildingSearch'){
        let value=event.target.value.trim();
          //if(this.state.searchParm.length>1){	
        if(value==''){
          this.state.neighborhoodList.forEach(function(neighbor,index) {
            neighbor.flag=0;
          });
          this.setState({searchMsg:false});
        }else {
        var len = this.state.neighborhoodList.length;
              var count=0;
              var vflag=0;
              if(value!='' && value.length>2){
              this.state.neighborhoodList.forEach(function(neighbor,index) {
                
                if(neighbor.neighborhood.trim().toLowerCase().includes(value.trim().toLowerCase())){
                    neighbor.flag=1;
                  vflag=1;
                  count=count+1;
                }else{
                    neighbor.flag=0;
                  if(vflag!=1)
                  vflag=0
                  count=count+1;
                }
      
              });
            }else{
                        
              this.state.neighborhoodList.forEach(function(neighbor,index) {
                neighbor.flag=0;
                  
              });
              
          }
              if(len==count){
                if(vflag==0){
                  this.setState({searchMsg:true});
                  
                }else{
                  this.setState({searchMsg:false});
                  
                }
    
                
              }
            }
          }
    
}

/**
 *  Search button click
 */

searchClick=(event) =>{
   
    if(this.state.pageType!='search'){
        this.makingOfParams();
    }else{
        this.props.handleReturnedResult({
            bedRoom_0:this.state.bedRoom_0,
            bedRoom_1:this.state.bedRoom_1,
            bedRoom_2:this.state.bedRoom_2,
            bedRoom_3:this.state.bedRoom_3,
            maxBed:(this.state.bedRoom_3==3)?true:false,
            _maxPrice_:this.state._value_.max,
            _minPrice_:this.state._value_.min,
            amenities:this.state.amenitiesList,
            building:this.state.buildingList,
            neighborhood:this.state.selectedneighborhood,
            vr:this.state.VR,
            filterType:"Sell",
            pageType:'search',
            buildingType:this.state.buildingType,
            address:this.state.address
       });
    }
}

resetFilter=()=>{
    this.setState({
        value: { min: 0, max: 5000 },
        bedRoom_0:-1,
        bedRoom_1:0,
        bedRoom_2:0,
        bedRoom_3:0,
        bedRoom_0_checked:'',
        bedRoom_1_checked:'',
        bedRoom_2_checked:'',
        bedRoom_3_checked:'',
        redirect:false,
        page:'',
        pageParams:window.location.search,
        neighborhoodString:'',
        selectedneighborhood:'',
        VR:'0',
        VR_checked:'',
        pageType:"search",
        recordPerPage:'50',
        recordNextPage:'10',
        selectedBuildingId:'',
        address:''
    },()=>{

        this.props.handleReturnedResult({
            bedRoom_0:this.state.bedRoom_0,
            bedRoom_1:this.state.bedRoom_1,
            bedRoom_2:this.state.bedRoom_2,
            bedRoom_3:this.state.bedRoom_3,
            maxBed:(this.state.bedRoom_3==3)?true:false,
            maxPrice:this.state.value.max,
            minPrice:this.state.value.min,
            amenities:this.state.amenitiesList,
            building:this.state.buildingList,
            neighborhood:this.state.selectedneighborhood,
            vr:this.state.VR,
            pageType:"search",
            recordPerPage:this.state.recordPerPage,
            recordNextPage:this.state.recordNextPage,
            address:this.state.address

        });
        document.getElementById('buildingSearch').value="";
        this.state.neighborhoodList.forEach(function(neighbor,index) {
            neighbor.flag=0;
          });
    })
  

}

/**
 * setAdvanceFilters will make a string of ids
 */
setAdvanceFilters=(event) =>{
    let value=event.target.value;
   if(this.state.amenitiesList==''){
       this.setState({amenitiesList:value})
   }
   if(this.state.amenitiesList!=''){
    this.removeValue(this.state.amenitiesList,value,',',(cberr,cbres)=>{
        this.setState({amenitiesList:cbres});
    });
    }   
}

setBuildingType=(event) =>{
    let value=event.target.value;
   if(this.state.buildingType==''){
       this.setState({buildingType:value})
   }
   if(this.state.buildingType!=''){
    this.removeValue(this.state.buildingType,value,',',(cberr,cbres)=>{
        this.setState({buildingType:cbres});
    });
    }   
}

setBuildingFilters=(event) =>{
    let value=event.target.value;
   if(this.state.buildingList==''){
       this.setState({buildingList:value})
   }
   if(this.state.buildingList!=''){
    this.removeValue(this.state.buildingList,value,',',(cberr,cbres)=>{
        this.setState({buildingList:cbres});
    });
    }   
}
/**
 * making filter value for neighbor 
 */
handleNeighbor=(event)=>{
    let value=event.target.value;
    if(this.state.neighborhoodFilter==''){
        this.setState({neighborhoodFilter:value,neighborhoodString:value})
    }
    if(this.state.neighborhoodFilter!=''){
     this.removeValue(this.state.neighborhoodFilter,value,',',(cberr,cbres)=>{
        if(cbres!=''){
            var listName=cbres.split(',');
            this.setState({neighborhoodFilter:cbres,neighborhoodString:listName[0]});
        }else{
            this.setState({neighborhoodFilter:cbres,neighborhoodString:''});
        }
        
     });
     } 
}
/**
 * removeValue will manage the amenities
 */
removeValue(list, value, separator,cb){
    separator = separator || ",";
    var values = list.split(separator);
    for(var i = 0 ; i < values.length ; i++) {
      if(values[i] == value) {
        values.splice(i, 1);
        //this.setState({amenitiesList:values.join(separator)})
        cb(null,values.join(separator));
      }else{
        let _list_=list+","+value;
        //this.setState({amenitiesList:list})
        cb(null,_list_)
    }
    }
  }
OpenListRent() {
    var x = document.getElementById("cityListRent");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }
/**
 * redirect while search button click 
 */
makingOfParams()
{
    this.setState({redirect:true});
}
handleSliderSteps = event =>  {
   
    this.setState({_value_:event})

    if(this.state._value_.min>300000){
        this.state.steps='100000' 
    }
    else if(this.state._value_.min>180000){
        this.state.steps='200000' ;
    }
    if(this.state._value_.min>800000){
        this.state.steps='250000';
    }
    console.log(this.state._value_.min);
    
}

//-- start making neighbourhood filter----

removeZip=param=>e=>{
    if(param[1]=='neighborhood'){
        let neighborhoodList=this.state.selectedneighborhood.split(',');
        let index = neighborhoodList.indexOf(param[0]); 
        if (index > -1) {
          neighborhoodList.splice(index, 1)
          this.setState({
            selectedneighborhood:neighborhoodList.join(',')
          });
        }
      }
        
    }
  
    neighborhoodFilterclick=(event)=>{
       
      let value=event.target.value;
      
      let list=this.state.selectedneighborhood;
      let listValue=[];
      if(this.state.selectedneighborhood!=''){
        listValue=list.split(',')
      }
      let flag=0;
      let count=0;
      for(var i = 0 ; i < listValue.length ; i++) {
        count=count+1;
        if(listValue[i] == value) {
          listValue.splice(i, 1);
          console.log("listValue",listValue);
          this.setState({
              selectedneighborhood:listValue.sort().join(','),linkingLoader:false,
          })
          flag=1;
          break;
        }
      }
      
      if(listValue.length==count && flag==0){
        listValue.push(value);
        this.setState({
          selectedneighborhood:listValue.sort().join(','),linkingLoader:false,
        })
      }
    }
    async addressDD(){

        let where=[{
            url :"/getListing"
            }];
            postApiData(where,{'listing_type':'Sales'},true).then(res=>{
            console.log("getbuilding=>",res.data);
            this.setState({'addressParam' :res.data});
            }).catch(err=>{
                this.setState({myerrormsg: err.response.data.error.message});
            })
        }
    
            handleClickOnAddress=param=>e=>{
                var value=param[0];
                this.setState({
                    address:value
                });
          
                let addressParam=this.state.addressParam;
                let count=0;
                document.getElementById('searchAddressParm_UL').style.display='none'
                    addressParam.forEach(function(client,index) {    
                        if(client.flag==1 && count<21){
                                count=count+1;
                                client.flag=0;
                            }
                        });
                        this.setState({'addressParam':addressParam})
                    }
            
   
     /* Blank Address field */
resetAddress=(event)=>{
   
    this.setState({address:''});
} 
render() {
    let splitAmenities=(this.state.amenitiesList!='')?this.state.amenitiesList:'';
    let splitBuilding=(this.state.buildingList!='')?this.state.buildingList:'';
    if(this.state.redirect==true){
        return (<Redirect to={{ 
            pathname: '/client/listing/browselist', 
            bedRoom_0:this.state.bedRoom_0,
            bedRoom_1:this.state.bedRoom_1,
            bedRoom_2:this.state.bedRoom_2,
            bedRoom_3:this.state.bedRoom_3,
            maxBed:(this.state.bedRoom_3==3)?true:false,
            _maxPrice_:this.state._value_.max,
            _minPrice_:this.state._value_.min,
            amenities:this.state.amenitiesList,
            building:this.state.buildingList,
            neighborhood:this.state.selectedneighborhood,
            vr:this.state.VR,
            filterType:"Sell",
            buildingType:this.state.buildingType,
            address:this.state.address,
        }}/>);
    }
    if(this.state.makingOfFilters.length<1){
        return false;
    }
    var row_1 = this.state.makingOfFilters.map((item, index)=>{
        return (item.row_sequence=='1')?<div className="checkbox-inline">
                <input type="checkbox" id={`id_rent`+item.id} checked={(splitAmenities!='' && splitAmenities.split(',').indexOf(item.id.toString())!=-1)?'checked':''} value={item.id} onChange={this.setAdvanceFilters}/>
                <label htmlFor={`id_rent`+item.id}>{item.amenity_name}</label>
            </div>:null;
    });

    var row_2 = this.state.makingOfFilters.map((item, index)=>{
        return (item.row_sequence=='2')?<div className="checkbox-inline">
                <input type="checkbox" id={`id_rent`+item.id} checked={(splitAmenities!='' && splitAmenities.split(',').indexOf(item.id.toString())!=-1)?'checked':''} value={item.id} onChange={this.setAdvanceFilters}/>
                <label htmlFor={`id_rent`+item.id}>{item.amenity_name}</label>
            </div>:null;
    });

    var row_3 = this.state.makingOfFilters.map((item, index)=>{
        return (item.row_sequence=='3')?<div className="checkbox-inline">
                <input type="checkbox" id={`id_rent`+item.id} value={item.id} checked={(splitAmenities!='' && splitAmenities.split(',').indexOf(item.id.toString())!=-1)?'checked':''} onChange={this.setAdvanceFilters}/>
                <label htmlFor={`id_rent`+item.id}>{item.amenity_name}</label>
            </div>:null;
    });


    var row_4 = this.state.makingOfFilters.map((item, index)=>{
        return (item.row_sequence=='4')?<div className="checkbox-inline">
                <input type="checkbox" id={`id_rent`+item.id} checked={(splitBuilding!='' && splitBuilding.split(',').indexOf(item.id.toString())!=-1)?'checked':''} value={item.id} onChange={this.setBuildingFilters}/>
                <label htmlFor={`id_rent`+item.id}>{item.amenity_name}</label>
            </div>:null;
    });


    var neighborhoodArray='';
    neighborhoodArray= this.state.neighborhoodList.map((item, index)=>{
        // return <span><input type="checkbox"  value={item.neighborhood} onClick={this.handleNeighbor} id={`box-`+index}/> <label for={`box-`+index}>{unescape(item.neighborhood)}</label></span>;
       return (item.flag==1)?<div className="form-group"><input type="checkbox" id={`box-`+index}  value={item.neighborhood} onClick={this.neighborhoodFilterclick} className={(addActive(this.state.selectedneighborhood,item.neighborhood))?'active':''}/><label for={`box-`+index}>{unescape(item.neighborhood)}</label></div>:''
    });

    let neighborhoodList1=''; 
    if(this.state.selectedneighborhood!=''){
        neighborhoodList1=this.state.selectedneighborhood.split(',').map((neighborhoodselect,index)=>{
        return <li>{neighborhoodselect}<Link to ><span className="closeTagZip" onClick={this.removeZip([neighborhoodselect,'neighborhood'])}>X</span></Link></li>
      })
    }
    
    function addActive(list,value){
        if(list!=null && list!=undefined){
          let arr = list.split(',');
        return arr.includes(value)?true:false;
        }
        
      }

      let adCount=0;
      const addressDW = this.state.addressParam.map((sw,index) => {
        if(sw.flag==1){
            adCount=adCount+1
        }
        return (sw.flag==1 && adCount<21) ?<li style={{cursor:'Pointer'}} onClick={this.handleClickOnAddress([sw.building_name])}>{sw.building_name}</li>:''
      });
    
    
return (
  <div>
   <button class="btn btn-default search-filter pull-right mr-5 resetImgFilter" onClick={this.resetFilter} style={{'margin-bottom':'0px'}}><img src="/reset-filter.png" width="55"/></button>
      <div className="clearfix"></div>
<div className="listing-filter row">

<div className="panel_1">
        <h4 className="title">Bedrooms</h4>
        <div className="duration-widget">
            <div className="form-group">
                <input type="checkbox" id="bedRoom_0" name="bedRoom" checked={this.state.bedRoom_0_checked}  onChange={this.search} />
                <label for="bedRoom_0">        
                    <div className="txt">0</div>
                </label>
                <input type="checkbox" id="bedRoom_1" name="bedRoom" checked={this.state.bedRoom_1_checked} value={this.state.bedRoom_1} onChange={this.search} />
                <label for="bedRoom_1">        
                    <div className="txt">1</div>
                </label>
                <input type="checkbox" id="bedRoom_2"  name="bedRoom" checked={this.state.bedRoom_2_checked}  value={this.state.bedRoom_2} onChange={this.search} />
                <label for="bedRoom_2">        
                    <div className="txt">2</div>
                </label>
                <input type="checkbox" id="bedRoom_3"  name="bedRoom" checked={this.state.bedRoom_3_checked}  value={this.state.bedRoom_3} onChange={this.search} />
                <label for="bedRoom_3">        
                <div className="txt">3<sub>+</sub></div>
                </label>
            </div>    
        </div>
</div>
<div className="panel_2">
        <h4 className="title">Price range</h4>
       {/* <InputRange
        formatLabel={_value_ => (_value_<4000000)?(_value_>900000)?`${(_value_/1000000)+' M'}`:`${'$'+_value_.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}`:`No Limit`}
        draggableTrack={false}
        maxValue={4100000}
        minValue={0}
        value={this.state._value_}
        step={this.state.steps}
        onChange={_value_ => this.setState({ _value_ },() => (this.state._value_.min>300000)?this.setState({steps:100000}):this.setState({steps:300000}))} 
       
       /> */}
        <InputRange
        draggableTrack
        formatLabel={_value_ => (_value_<4100000)?(_value_>900000)?`${(_value_/1000000)+' M'}`:`${'$'+_value_.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}`:`No Limit`}
        draggableTrack={false}
        maxValue={4250000}
        minValue={0}
        value={this.state._value_}
        step={this.state.steps}
        onChange={this.handleSliderSteps} 
       
        />
</div>
{/* <div className="panel_3" style={{position:'relative'}}>
        <h4 className="title">Neighborhood</h4>
        <div className="neighbor-filter list-filter" onClick={this.OpenListRent}> 
            <span id="Firstcityname">{this.state.neighborhoodString}</span> <span id="CityCount">{(this.state.neighborhoodFilter!='' && this.state.neighborhoodFilter.split(',').length>1)? ' +'+(this.state.neighborhoodFilter.split(',').length-1) +' more..':''} </span>
        </div>

        <div className="boxes neighborhoodDD" id="cityListRent" style={{display:'none'}} ref={this.setWrapperRef2}>
           {neighborhoodArray}
        </div>
</div> */}

{/* ---start new design for filter--- */}

<div className="panel_3" style={{position:'relative', marginTop:'20px'}}>
               
               <div id="filterDd5" className="neighbor-filter list-filter" onClick={this.OpenListRent}> 
               Neighborhood  <span className="count" id="CityCount">({(this.state.selectedneighborhood!='')?this.state.selectedneighborhood.split(',').length:"0"})</span>
               </div>

               <div className="boxes neighbor common-box" id="cityListRent" style={{display:'none',width:'100%'}}  ref={this.setWrapperRef2}>
               <div className="form-group ziptag">
               <ul className="clearfix">{neighborhoodList1}</ul>
               
                   <input type="text" id="buildingSearch" onKeyDown={this.search} onKeyUp={this.search}  className="form-control"  placeholder="Neighborhood"/> 
                   </div>
                   <div className="scroll-list maxheight" > {neighborhoodArray}</div>
               </div>
       </div>
{/* ---END---        */}
<div className="panel_4">
<div className="search-wrap"><button  onClick={this.searchClick} className='btn btn-default search-filter'><img src="/search-filter.png" width="55"/></button></div>

<Link to className="advance-search-widget clearfix" onClick={this.AdvanceSearch}><span style={{float:'left'}}>Advanced Search</span> <img src="/advance-search.png" id="openMsg_r"/><img src="/advance-search.png" id="closeMsg_r" style={{display:'none'}}/></Link>
</div>

<div className="advance-search" id="A_Filter_R" style={{display:'none'}}>
<div className="filter-block">
    {/** MS-1154 */}
<h5 className="title">Address</h5>
        <div className="form-group">
        <div id="addressSearchfront" ref={this.setWrapperRef3}>
        <div className="box">
        <input type="text" value={this.state.address} id="searchAddressParm" autocomplete="pass" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} style={{display:'block',background: 'none'}}/>
        <button type="button" class="close" onClick={this.resetAddress}>
          <span>×</span>
        </button>
        <ul id="searchAddressParm_UL">
        {addressDW}
        </ul>
        {(this.state.searchAddressMsg)?'No suggestions available':''}
        </div>
        </div>
        </div>
        {/** MS-1154 */}
        <div className="form-group">
            <input type="checkbox" value={this.state.VR} id="_VR_" checked={this.state.VR_checked} onChange={this.search}/>
            <label htmlFor="_VR_">Only show units with VR preview</label>
        </div>
        <h5 className="title">Property type</h5>
        <div className="form-group">
        <div className="checkbox-inline">
                <input type="checkbox" id="Condo" value="Condo" checked={(this.state.buildingType.split(',').indexOf('Condo')!=-1)?'checked':''} onChange={this.setBuildingType}/>
                <label htmlFor="Condo">Condo</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="Co-op" value="Co-op" checked={(this.state.buildingType.split(',').indexOf('Co-op')!=-1)?'checked':''} onChange={this.setBuildingType}/>
                <label htmlFor="Co-op">Co-op</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="Other" value="Other" checked={(this.state.buildingType.split(',').indexOf('Other')!=-1)?'checked':''} onChange={this.setBuildingType}/>
                <label htmlFor="Other">Other</label>
            </div>
            </div>
        <h5 className="title">Unit Amenities</h5>


        
            
        <div className="form-group">
        {row_1}
        {/* <div className="checkbox-inline">
                <input type="checkbox" id="2"/>
                <label htmlFor="2">Cats allowed</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="3"/>
                <label htmlFor="3">Dogs allowed</label>
            </div> */}
        </div>
        <div className="form-group">
        {row_2}
        {row_3}
        {/* <div className="checkbox-inline">
                <input type="checkbox" id="4"/>
                <label htmlFor="4">Duplex</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="5"/>
                <label htmlFor="5">Outdoor space</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="6"/>
                <label htmlFor="6">Hardwood floors</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="7"/>
                <label htmlFor="7">Renovated bathroom</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="8"/>
                <label htmlFor="8">Renovated Kitchen</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="9"/>
                <label htmlFor="9">Dishwasher</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="10"/>
                <label htmlFor="10">W/D</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="11"/>
                <label htmlFor="11">Furnished/D</label>
            </div> */}
        </div>
        <h5 className="title">Building amenities</h5>
        </div>
        <div className="form-group">
        {row_4}
        {/* <div className="checkbox-inline">
                <input type="checkbox" id="12"/>
                <label htmlFor="12">Elevator</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="13"/>
                <label htmlFor="13">Doorman</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="14"/>
                <label htmlFor="14">Virtual Doorman</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="15"/>
                <label htmlFor="15">Gym</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="16"/>
                <label htmlFor="16">Outdoor areas</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" id="17"/>
                <label htmlFor="17">Live-in super</label>
            </div> */}
          
        {/*<p className="text-right hidden hidden-xs"><Link to style={{textDecoration:'underline',marginTop:'10px'}} onClick={this.AdvanceSearch}>Hide Advance Search</Link> </p>*/} 
</div>

</div>   
</div><p className="text-center"><Link to onClick={this.rentFilter}>I’m looking to rent an apartment</Link></p></div>
                );
    }
}



export default filterList;