import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import { getToken,getOnBoardingDone, getUserId,getApiData } from '../../services/Api';
import { Redirect } from 'react-router';
import Modal from 'react-responsive-modal';
class Rentalform extends React.Component {
	
    render() {

        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}

class Content extends React.Component {
    render() {
        return (
                <div>
                    <Header />
                    <main>
                         <div className="container dashboard">
                            <div className="rental-application"><h2 className="text-center">Application for Rental</h2>
                        <hr/>
<div className="row clearfix">
            <div className="col-sm-4">
                <label> Lease Length</label>
                <div className="form-group">
                   <input type="radio" name="radio"/> 1 Year <input type="radio" name="radio"/> 2 Year
                </div>
            </div>
            <div className="col-sm-4">
                <label>Submitted by</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label> Approved by</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
</div>
<h5>Sources</h5>
<div className="row clearfix">            
            <div className="col-sm-3">                                                       
                <div className="form-group">
                     <label className="radio-inline"><input type="radio" name="radio"/> Walk In</label>  <label className="radio-inline"><input type="radio" name="radio"/> Newspaper</label>  <input type="text" className="form-control" placeholder="Name"/>
                                                                       
                </div>
            </div>
            <div className="col-sm-3">                                                       
                <div className="form-group">
                     <label className="radio-inline"><input type="radio" name="radio"/>Super</label>  <input type="text" className="form-control" placeholder="Name"/>
                                                                       
                </div>
            </div>
            <div className="col-sm-3">                                                       
                <div className="form-group">
                     <label className="radio-inline"><input type="radio" name="radio"/>Broker</label>  <input type="text" className="form-control" placeholder="Broker Company"/>
                                                                       
                </div>
            </div>
            <div className="col-sm-3">                                                       
                <div className="form-group">
                     <label className="radio-inline"><input type="radio" name="radio"/>Other</label>  <input type="text" className="form-control" placeholder="Describe"/>
                                                                       
                </div>
            </div>
    </div>
<h5>Applicant Information</h5>
    <div className="row clearfix">
            <div className="col-sm-3">
                <label> First Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Middle Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Last Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> JR/SR</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Date of Birth</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> SSN</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Home Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Work Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Cell Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Email </label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>

    <h5>Current Address</h5>
    <div className="row clearfix">
            <div className="col-sm-5">
                <label> Street Address</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> City</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-2">
                <label> State</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-2">
                <label> Zip</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>


            <div className="col-sm-6">
                <label> Landlord/Managing Agent Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-6">
                <label> Landlord/Managing Agent Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Monthly Rent</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Date In</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Date Out</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Reason for Leaving </label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>

    <h5>Previous Address (If less the 2 year or current)</h5>
    <div className="row clearfix">
            <div className="col-sm-5">
                <label> Street Address</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> City</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-2">
                <label> State</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-2">
                <label> Zip</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>


            <div className="col-sm-6">
                <label> Landlord/Managing Agent Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-6">
                <label> Landlord/Managing Agent Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Monthly Rent</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Date In</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Date Out</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Reason for Leaving </label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>
    <h5>Bank Information</h5>
    <div className="row clearfix">
            <div className="col-sm-4">
                <label>Checking Account Bank Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Account Number</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Phone Number</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Saving Account Bank Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Account Number</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Phone Number</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>
    <h5>Employment & Income Information</h5>
    <div className="row clearfix">
            <div className="col-sm-4">
                <label>Present Employer Company</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Occupation</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Annual Aalary</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Supervisor Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Supervisor Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Start Date</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Previous Employer Company</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Occupation</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Annual Aalary</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Supervisor Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Supervisor Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Start Date</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-8">
                <label>Other Income Description</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Annual Income</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Goverment Housing Subsidy</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Type</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Amount</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>
    <h5>Business/Personal References</h5>
    <div className="row clearfix">
            <div className="col-sm-3">
                <label>Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label>Address</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label>Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label>Relationship</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label>Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label>Address</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label>Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label>Relationship</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>

    <h5>Co-Applicant Information (If Applicable)</h5>
    <div className="row clearfix">
            <div className="col-sm-3">
                <label> First Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Middle Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Last Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> JR/SR</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Date of Birth</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> SSN</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Home Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Work Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Cell Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Email </label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>
    <h5>Co-Applicant Current Address (If Different)</h5>
    <div className="row clearfix">
            <div className="col-sm-5">
                <label> Street Address</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> City</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-2">
                <label> State</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-2">
                <label> Zip</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>


            <div className="col-sm-6">
                <label> Landlord/Managing Agent Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-6">
                <label> Landlord/Managing Agent Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Monthly Rent</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Date In</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Date Out</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-3">
                <label> Reason for Leaving </label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>
    <h5>Co-Applicant Bank Information (If Applicable)</h5>
    <div className="row clearfix">
            <div className="col-sm-4">
                <label>Checking Account Bank Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Account Number</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Phone Number</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Saving Account Bank Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Account Number</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Phone Number</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>
    <h5>Co-Applicant Employment & Income Information (If Applicable)</h5>
    <div className="row clearfix">
            <div className="col-sm-4">
                <label>Present Employer Company</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Occupation</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Annual Aalary</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Supervisor Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Supervisor Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Start Date</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Previous Employer Company</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Occupation</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Annual Aalary</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Supervisor Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Supervisor Phone</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Start Date</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-8">
                <label>Other Income Description</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Annual Income</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Goverment Housing Subsidy</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Type</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Amount</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
    </div>

    <h5>Other who will Occupy the Apartment</h5>
    <div className="row clearfix">
            <div className="col-sm-4">
                <label>Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Relation</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Age</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Name</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Relation</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-sm-4">
                <label>Age</label>
                <div className="form-group">
                    <input type="text" className="form-control"/>
                </div>
            </div>
     </div> 
     <h5>Pets</h5>
    <div className="row clearfix">
            <div className="col-sm-4">
                <label>Pets?</label>
                <div className="form-group">
                    <input type="radio" name="radio"/> Yes  <input type="radio" name="radio"/> No
                </div>
            </div>
            <div className="col-sm-8">
                <label>Discription</label>
                <div className="form-group">
                    <textarea className="form-control" rows="4"></textarea>
                </div>
            </div>
           
     </div>   
     <p className="information">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
     <div className="row clearfix">
            <div className="col-xs-4 col-sm-4">
                
                <div className="form-group">
                     <input type="text" className="form-control"/>
                </div>
                <label>Signed/Applicant</label>
            </div>
            <div className="col-xs-2 col-sm-2">
                
                <div className="form-group">
                     <input type="text" className="form-control"/>
                </div>
                <label>Date</label>
            </div>
            <div className="col-xs-4 col-sm-4">
                
                <div className="form-group">
                     <input type="text" className="form-control"/>
                </div>
                <label>Signed/Co-Applicant</label>
            </div>
            <div className="col-xs-2 col-sm-2">
                
                <div className="form-group">
                     <input type="text" className="form-control"/>
                </div>
                <label>Date</label>
            </div>
     </div> 
</div>
</div>
</main>
</div>
);
    }
}
export default Rentalform;