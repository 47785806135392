import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/ManagerHeader';
import Sidebar from '../common/ManagerSidebar';
import { getManagerToken, getApiData, postApiData } from '../../services/Api';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router';
import AgentDetail from './AgentDetail';


class Dashboard extends React.Component {
    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}

class Content extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
			client_list:[],
			clientDW:[],
			where:{},
			ckActive:{'checked':true,'value':1},
			ckInactive:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
			open: false,
			searchClientId:'',
			searchParm:"",
			searchMsg:"",
			myerrormsg:"",
			clientId:'',
			agentDetail:[],
			slots_error:"",
			total_slots:"",
			edit_slots:'',
			available_slots:"",
			new_Slot_value:''
		  };
		  this.createWhere();
		    // Added for DropdownClose on Clickoutside
		this.setWrapperRef1 = this.setWrapperRef1.bind(this);
		this.setWrapperRef2 = this.setWrapperRef2.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		}
		
	componentWillMount(){
		window.scroll(0,0);
		document.addEventListener('mousedown', this.handleClickOutside);
		this.getClientList();
		this.agentDD();
		this.slotDetail();
	}
	componentWillUnmount(){
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef1(node) {
		this.wrapperRef1 = node;
	  }

	  setWrapperRef2(node) {
		this.wrapperRef2 = node;
	  }

	  handleClickOutside(event) {
		if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
			document.getElementById('search').classList.remove("show");
		}

		if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
			document.getElementById('status').classList.remove("show");
		  }


	  }
	
	//open model
	onOpenModal = () => {
		document.body.classList.add('modalTransparent');
                this.setState({ open: true });
	};
	//close model
	onCloseModal = () => {
		this.setState({ open: false });
								this.getClientList();
								this.slotDetail();
	};
    // client list
	async getClientList() {
		this.setState({linkingLoader:true});

		let applicationList = [{
            url: "/agent/list",
		}];
		var len =Object.keys(this.state.where);
		if(len.length>0){
			applicationList[0].where=this.state.where;
		}
        getApiData(applicationList,true).then(res => {
					console.log("--client_list",res.data);
		this.setState({'client_list':res.data,linkingLoader:false});
		this.setState({
			agent_total_slot:res.data.total_slot
		})
		document.getElementById('search').classList.remove("show");
		document.getElementById('status').classList.remove("show");
		
        }).catch(error => {
			this.setState({linkingLoader:false});

        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}
	//agent Dropdown
	async agentDD(){
		let clientList = [{
            url: "/agent/agentDD",
		}];
        getApiData(clientList,true).then(res => {
        this.setState({'clientDW':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}
	//Filters reset
	resetFilter=()=>{

		this.setState({
			client_list:[],
			where:{},
			ckActive:{'checked':true,'value':1},
			ckInactive:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
			searchClientId:'',
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('searchParm').value='';
			document.getElementById('searchClick').classList.remove("active");
			document.getElementById('Inactive').checked = true;
			document.getElementById('Active').checked = true;
			this.agentDD();
			this.createWhere();

		  });
		  
	}
	//search handling
	search=(event) =>{
		this.setState({[event.target.id]: event.target.value});
	 var key=event.target.id;
	
	  switch(key){
		case 'Inactive':
		if(this.state.ckInactive.value==0 && this.state.ckInactive.checked==false){
			this.state.ckInactive.checked=true;
			this.state.statusFil=1;
			this.state.ckInactive.value=1;
			this.createWhere();
		}else if(this.state.ckInactive.value==1 && this.state.ckInactive.checked==true){
			this.state.ckInactive.checked=false;
			this.state.ckInactive.value=0;
			this.createWhere();
		}        
			break;
		case 'Active':  
		if(this.state.ckActive.value==0 && this.state.ckActive.checked==false){
			this.state.ckActive.checked=true;
			this.state.statusFil=1;
			this.state.ckActive.value=1;
			this.createWhere();
		}else if(this.state.ckActive.value==1 && this.state.ckActive.checked==true){
			this.state.ckActive.checked=false;
			this.state.ckActive.value=0;
			this.createWhere();
		}        
			break;
		case 'searchParm':
		this.setState({searchParm:event.target.value});
			var value=event.target.value.trim();
			if(event.key=='Enter'){
				this.getAgentData(value);

			}else if(value==''){
				this.state.clientDW.forEach(function(client,index) {
						client.flag=0;
				});
				this.setState({searchMsg:false});
			}else {
				var len = this.state.clientDW.length;
				var count=0;
				var vflag=0;
				this.state.clientDW.forEach(function(client,index) {
					
					if(client.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
						client.flag=1;
						vflag=1;
						count=count+1;
					}else{
						client.flag=0;
						if(vflag!=1)
						vflag=0
						count=count+1;
					}

				});
				
				if(len==count){
					if(vflag==0){
						this.setState({searchMsg:true});
						
					}else{
						this.setState({searchMsg:false});	
					}	
				}
			}
		break;
		case 'searchkey':
		var value=event.target.value;
		this.getAgentData(value);
			
		break;
		case 'default':
		this.createWhere();
		break;
		 
		}
		if(this.state.statusFil>0){
			document.getElementById('statusKey').classList.add("active");
		}else{
			document.getElementById('statusKey').classList.remove("active");
		}
	  }
	  async createWhere(){
		this.state.where={};
		var inValue="";
		if(this.state.ckInactive.value==1){
			this.state.where.submit=2;		
		}
		if(this.state.ckActive.value==1){
			this.state.where.Active=1;
		}
		if(this.state.searchClientID!=''){
			this.state.where.searchClientId=this.state.searchClientId;
		}
		this.getClientList();
	}
	//model start
        handleClick = param => e => {
             let where = [{
			   url: "/agent/detail/"+param[0]
		   }];    
		   getApiData(where, true).then(res => { 
                        if(res.data){
                          this.setState({agentDetail:res.data});
                          this.onOpenModal();

                        }
	   });
        }
	//model end	
	handleClickOnAgent=param=>e=>{
		var value=param[0];
		this.getAgentData(value);
	}
	getAgentData(value){
		this.setState({ searchClientId: value}, () => {
			this.state.clientDW.forEach(function(client,index) {
			if(client.name==value){
					document.getElementById('searchClick').classList.add("active");
					document.getElementById('searchParm').value=value;
				}else{
					client.flag=0;
				}
			});
			document.getElementById('searchClick').classList.add("active");
			this.createWhere();
		  });
		}
		resetAgentParm=()=>{
			this.setState({
				client_list:[],
				where:{},
				searchClientId:'',
				searchParm:'',
				myerrormsg:"",
			  },()=>{
				document.getElementById('searchParm').value='';
				document.getElementById('searchClick').classList.remove("active");
				document.getElementById('searchClick').classList.remove("show");
				
				this.agentDD();
				this.createWhere();
	
			  });
		}

		resetStatus=()=>{
			this.setState({
				client_list:[],
				where:{},
				ckActive:{'checked':true,'value':1},
				ckInactive:{'checked':true,'value':1},
				searchParm:'',
				myerrormsg:"",
			  },()=>{
				document.getElementById('searchParm').value='';
				document.getElementById('searchClick').classList.remove("active");
				document.getElementById('searchClick').classList.remove("show");
				document.getElementById('Inactive').checked = true;
			document.getElementById('Active').checked = true;
			
				
				this.createWhere();
	
			  });
		}
		
		// total Slot function 
		slotDetail=()=>{
			let where=[{
				url:'/managers/slotDetail'
			}];
			getApiData(where,true).then(res=>{
						console.log("---res--",res.data[0].total_slots);
						this.setState({
							total_slots:res.data[0].total_slots,
							available_slots:res.data[0].available_slots,
							slot_Id:res.data[0].id
						})
			}).catch(error=>{

			})
		}

		handleChange=(event)=>{
			console.log("---valueee",event.target.value);
			const id = event.target.id;
			const value = event.target.value;
			const dataset = event.currentTarget.dataset.id;
			
			if(isNaN(value)){
				
			this.setState({
				slots_error:"Enter Only Numbers"
			})
		  }
		  else if(value==''){
			console.log("---isNaNnnnnnn",event.target.value);
			this.setState({
				slots_error:"Field cannot be empty"
			})

			
				
			}
			else if(value < 0 && value > 99999){
				console.log("---value",event.target.value);
				this.setState({
					slots_error:"Enter value between 0 to 99999"
				})

			}
			else{
				this.setState({
					new_Slot_value:value,
					 slots_error:""
					 })
			
			}
			
		}

		validation=()=>{

			return 	  (this.state.new_Slot_value!="" && this.state.new_Slot_value > 0 && this.state.new_Slot_value<=99999) ;
		}

		totalSlotEdit=()=>{
			let where=[{
				url:"/managers/editSlot"
			}];
			postApiData(where,{'total_slots':this.state.new_Slot_value,'id':this.state.slot_Id},true).then(res=>{
					console.log("-,res---res",res.data.success);
					if(res.data.success==true){
					document.getElementById("SlotEditModal").click();
					}
					this.slotDetail();

			}).catch(error=>{

			})

		}

		
		
    render() {
		if (!getManagerToken()) {
            return (<Redirect to={'/manager/landing'}/>);
		  }
                 const { open } = this.state;

		  
		const clientDW = this.state.clientDW.map((dw,index) => 
		(dw.flag==1) ? <li id="searchkey"  onClick={this.handleClickOnAgent([dw.name])}>{dw.name}</li>:''
		);

		
		var listItems='';
		if(this.state.client_list.length>0){
			listItems = this.state.client_list.map((client,index) => 
			<tr onClick={this.handleClick([client.agentId])} className="agentRow">
			<td><Link to key={client.agentId}>{client.name}</Link> <Link to={{pathname:'/agent/agentprofile' , type:'Manager' , agent_id:client.agentId}}><i className="fa fa-pencil text-black "  aria-hidden="true"></i></Link></td>
			<td align="center">{client.sort_order==999?0:client.sort_order}</td>
			<td></td>
			<td></td>
			<td align="center">{client.renthop_slot}/{client.total_slot}</td>
			<td align="center">{client.status}</td>				
		</tr>
	);	
		}
		
		// else{
		// 	listItems = 
		// 	<tr>
		// 	<td colSpan="2">No Record found for the searched criteria.</td>				
		// </tr>
		// }

		console.log("agentDetail",this.state.agentDetail);
	
        return (
                <div>
                    <Header />
                    <main>
					<Sidebar />
					<Modal open={open} onClose={this.onCloseModal} little>
                         <AgentDetail listData={this.state.agentDetail} available_slots={this.state.available_slots} total_slot={this.state.agent_total_slot} id={this.state.slot_Id} onClick={this.onCloseModal}/>
                                 
		</Modal>
					<div className="content">
						<div className="work-section">
						<div className="dashboard">

						{(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}

								<h2 className="pull-left">Agents</h2>

								<button onClick={this.resetFilter} className="btn btn-default pull-right ">Reset all filters</button>
								<div className="panel-body table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
												<th width="50%">Agent name <Link to id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search"></Link>
												<div id="search" class="collapse table-collapse client-collapse" ref={this.setWrapperRef1}>

													<div className="box">
													
													<input type="text" id="searchParm" autocomplete="off" class="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
													{/* <div className="filterSearchReset"><Link to className="clearFilter" onClick={this.resetAgentParm}><i class="fa fa-undo"></i> Reset</Link></div> */}
													
														<ul>
														{clientDW}
														</ul>
														{(this.state.searchMsg)?'No suggestions available':''}
													</div>
												</div>
												</th>
												<th>Sort Order</th>
												<th></th>
												{/* <th>Total Slots: {this.state.total_slots}  <Link to className="editsmall"  data-toggle="modal" data-target="#SlotEditModal" > <i className="fa fa-pencil text-white "  aria-hidden="true"></i></Link></th> */}
												<th>Slots Available: {this.state.available_slots}/{this.state.total_slots} <Link to className="editsmall"  data-toggle="modal" data-target="#SlotEditModal" > <i className="fa fa-pencil text-white "  aria-hidden="true"></i></Link></th>
												<th>Slots Available/Allotted</th>
												<th>Status <Link to id="statusKey" data-toggle="collapse" data-target="#status" className="icon-wrap status" onclick={this.search}></Link>
												<div id="status" class="collapse table-collapse status-collapse clientstatus-collapse" ref={this.setWrapperRef2}>
													<div className="box">
													<div className="filterSearchReset"><Link to className="clearFilter" onClick={this.resetStatus}><i class="fa fa-undo"></i> Reset</Link></div>
													<input type="checkbox" id="Inactive" defaultChecked={this.state.ckInactive.checked} value={this.state.ckInactive.value} onChange={this.search}/>
													<label for="Inactive">Inactive</label>
													<input type="checkbox" id="Active"  defaultChecked={this.state.ckActive.checked} value={this.state.ckActive.value} onChange={this.search}/>
													<label for="Active">Active</label>
													
													</div>
												</div>		
												</th>
											
											</tr>
										</thead>
										<tbody>
										{(this.state.client_list.length>0 && !this.state.linkingLoader)?listItems:(this.state.client_list.length==0 && !this.state.linkingLoader)?<tr><td colSpan="2" className="managerGridCol">No Record Found</td></tr>:'' }			
										</tbody>
										</table>
									</div>
							</div>

						</div>
					</div>	

	

										 	<div id="SlotEditModal" className="modal fade" >
                         <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header" >
														<h4 className="modal-title">Add Total Slots</h4>
                            <button type="button" id="SlotEditModal" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
															<form onSubmit={this.totalSlotEdit}>
																													<input
                                                            type="text"
                                                            className="form-control"
                                                            id="total_slots"
															value={this.state.new_Slot_value}
														
																														onChange={this.handleChange}
																													
                                                        />
																												 <span className="inline-error-class">
                                                            {" "}
                                                            {this.state.slots_error !== ""
                                                                ? this.state.slots_error
                                                                : ""}
                                                        </span>
														<br></br>
																												<button
																										type="submit"
																										disabled={!this.validation()}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                              </button>
																							</form>
											</div>
									
									</div>
								</div>
							</div>	   

							 	    
                          
                    </main>     
                </div>

                );
    }
}
export default Dashboard;
