import React, { Component } from 'react';
import ManagerListEdit from './ManagerListing';

class ManagerListingEdit extends Component {
    constructor(props){
        super(props);
        window.scroll(0,0);
    }
    render() {
        return (
            <div>
                <ManagerListEdit 
                userType='Manager'
                salesTab={this.props.location.salesTabFlag}
                />
            </div>
        );
    }
}

export default ManagerListingEdit;