import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import { postApiData, getUserId} from '../../services/Api';

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {is_stripe_completed: false, card_holder_name: '', application_id: props.applicationId, error_card_name: '',error_token:'',error_msg:''};
        this.stripeSubmit = this.stripeSubmit.bind(this);
    }
    handleStripeChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });

    }

    async stripeSubmit() {
        let token = await this.props.stripe.createToken({name: this.state.card_holder_name});
        let where = [{
                url: "/stripe/save"
            }];
        if (token.token && token.token.id) {
           if (!this.state.card_holder_name) {
                this.setState({error_card_name: 'Card holder name is required.'});
            } else {
                document.getElementById('show_loader_on_click_id').style.display = "block";
                postApiData(where, {"id": token.token.id,"client_id":getUserId(),"application_id":this.state.application_id}, true).then(res => {
                    console.log("res=>",res);
                    // send token to client server
                    if (res.data.id) {
                        this.props.handleStripeSubmit(token,res.data);
                        this.setState({is_stripe_completed: true,error_msg:''});
                        document.getElementById('show_loader_on_click_id').style.display = "none";
                    }
                    else{
                        this.setState({is_stripe_completed: false,error_msg:'Unable to process your request, please check card details and try again.'});
                        document.getElementById('show_loader_on_click_id').style.display = "none";
                    }
                }).catch(error => {
                    console.log("error",error);
                        //    this.setState({is_stripe_completed: false,error_msg:'Unable to process your request, please check card details and try again.'});
                          //  document.getElementById('show_loader_on_click_id').style.display = "none";
                        });
            }


        } else {
            this.setState({error_token: 'Please check your card details.'});
        }
    }

    render() {
        
        if (this.state.is_stripe_completed)
            return <div className="alert alert-success"><i class="fa fa-check-square-o"></i> Payment Successful</div>;
        return (
                <div className="stripe_payment">
                {this.state.error_msg!==''?(<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>):(null)}

         {this.state.error_token!==''?(<div className="alert alert-danger">{this.state.error_token}</div>):''}
                    <div className="show_loader_on_click" id="show_loader_on_click_id" style={{display: 'none' }}></div>
                    <p>Card Details</p>
                    <CardElement />
                    <br/>
                    <div className="form-group">
                        <input type="text" name="card_holder_name" id="card_holder_name" className="form-control" placeholder="Card Holder Name"  onChange={this.handleStripeChange}/>
                        <br/>
                        <span className="inline-error-class">{this.state.error_card_name}</span>
                    </div>
                    <div className="form-group text-center m-top">
                        <button onClick={this.stripeSubmit}  className="btn btn-secondary btn-submit">Make Payment</button>
                    </div>
                </div>
                            );
                }
    }

    export default injectStripe(CheckoutForm);
