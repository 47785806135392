import React from 'react';
import {Link} from 'react-router-dom';
import Header from '../../common/Header';
import { getApiData, getToken, getUserId,getOnBoardingDone,getBaseUrl} from '../../../services/Api';
import { Redirect } from 'react-router';
import { getFormatDateMDY , getFileHTML } from '../../../services/Util';
import Modal from 'react-responsive-modal';
import {containerBank, containerAdditional} from '../../../services/Const';
import PlaidDetails from './PlaidDetails';
import BuildDocDetails from './BuildDocDetails';
import { any } from 'prop-types';





class Detail extends React.Component {

    constructor(props) {
        super(props);
        console.log("---props",this.props.listData);
        this.state = {
                appDetail:props.listData,
                openBank:false,
                openPlaid:false,
                openAdditional:false,
                uploaded_additional_document:[],
                BuildAddDocs:any,
                openUploaded:false,
                device_type:'web'
        };
    }

    componentDidMount() {

     this.getAppBuildingDoc(this.state.appDetail.id);
     if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      //alert('mobile');
      this.setState({device_type:'mobile'});
  }

    }

    getAppBuildingDoc(app_id){
      let where = [{
          url: "/getDocList",
          where: {"app_id":app_id},
          }];
         getApiData(where,true).then(res => {
              if (res.data){
                 console.log('uploaded_additional_document',res.data);
                this.setState({uploaded_additional_document:res.data},()=>{
                    //do stuff here
                });
              }
          }).catch(error => {
          console.log('in catch', error);
          });
  }
    
    onOpenBankModal = () => {

      // if(this.state.device_type=='mobile'){
      //    var pdfpath =getBaseUrl()+"/containers/building-additional-doc/download/"+this.state.uploadedFile;
      //    window.open(pdfpath);
      //    this.setState({ openBank: false });
      // }
      // else{
         this.setState({ openBank: true });
    // }
     
    };

    onCloseBankModal = () => {
      this.setState({ openBank: false });
    }; 
    
     onOpenPlaidModal = () => {
       if(this.state.device_type=='mobile'){
         var pdfpath =getBaseUrl()+"/containers/plaid_pdf/download/"+this.state.appDetail.id+".pdf";
         window.open(pdfpath);
         this.setState({ openPlaid: false });
       } 
       else{
         this.setState({ openPlaid: true });
       }
      
    };
    
    onClosePlaidModal = () => {
      this.setState({ openPlaid: false });
    }; 


    onOpenOpenDocs = param =>e =>{
     // console.log('this.state.uploadedFileeeeeeeeeeeeeeeee',param[0].uploadedFile);
       this.setState({uploadedFile_mobiledevice:param[0].uploadedFile});
       this.setState({BuildAddDocs:{ext:param[0].ext,filename:param[0].fileName,uploadedFile:param[0].uploadedFile}},()=>{
        
         if(this.state.device_type=='mobile'){
            var pdfpath =getBaseUrl()+"/containers/building-additional-doc/download/"+this.state.uploadedFile_mobiledevice;
            window.open(pdfpath);
            this.setState({ openBuildAddDocs: false });
         }
         else{
            this.setState({ openBuildAddDocs: true });
         }
        
        
       })
     
    };
    
    onCloseOpenDocs = ()=>{
      this.setState({BuildAddDocs:{}},()=>{
         this.setState({ openBuildAddDocs: false });
       })
    }; 
    
    onOpenAdditionalModal = () => {

      if(this.state.device_type=='mobile'){
         if(this.state.appDetail.ad_file_1){
            var pdfpath = getBaseUrl()+'/containers/'+containerAdditional+'/download/'+this.state.appDetail.ad_file_1;
            window.open(pdfpath);
            this.setState({ openAdditional: false });
           }
           if(this.state.appDetail.ad_file_2){
              var pdfpath = getBaseUrl()+'/containers/'+containerAdditional+'/download/'+this.state.appDetail.ad_file_2;
            window.open(pdfpath);
            this.setState({ openAdditional: false });
           }
           if(this.state.appDetail.ad_file_3){
              var pdfpath = getBaseUrl()+'/containers/'+containerAdditional+'/download/'+this.state.appDetail.ad_file_3;
            window.open(pdfpath);
            this.setState({ openAdditional: false });
           }
      }
      else{
         this.setState({ openAdditional: true });
      }

 
    };

    onCloseAdditionalModal = () => {
      this.setState({ openAdditional: false });
    }; 

    onOpenUploadPdfModal = () => {
      if(this.state.device_type=='mobile'){
         var pdfpath =getBaseUrl()+"/containers/client-gov-id/download/"+this.state.appDetail.gov_issued_id;
         window.open(pdfpath);
         this.setState({ openUploaded: false });
      }
      else{
         this.setState({ openUploaded: true });
      }

    
    };

    onCloseUploadPdfModal = () => {
      this.setState({ openUploaded: false });
    }; 
  
    render() {
      const { openBank, openAdditional, appDetail, openPlaid } = this.state;

      if(!this.state.appDetail){
          return (<div></div>);
      }
      let additionalBuildingButtons='';

          if(this.state.uploaded_additional_document.length>0){
            additionalBuildingButtons = this.state.uploaded_additional_document.map((docName,index) =>{
            return <div className="upload-btn-wrapper statement-btn">
                <button className="btn" type="button" onClick={this.onOpenOpenDocs([docName])}>{docName.fileName} </button>
            </div>
            
            });    
          }
      
     
      var filename=this.state.appDetail.gov_issued_id;
      var ext = filename.substring(filename.lastIndexOf('.')).toLowerCase();

         var pdfpath =getBaseUrl()+"/containers/client-gov-id/download/"+appDetail.gov_issued_id;
        return (
<div>
   <h2 className="text-center">View submitted application</h2>
   <div className="modal-content client-modal">
      <div className="modal-header">
         <h4 className="modal-title head">Application for: {appDetail.apartment_address}, {appDetail.unit_number}</h4>
      </div>
      <div className="modal-body profile">
         <div className="row clearfix">
            <div className="col-sm-7">
               <p className="small light-txt">Requested lease duration</p>
               <p>{appDetail.lease_months} Month</p>
               <div className="row clearifx">
                  <div className="col-sm-6">
                     <p className="small light-txt">Name</p>
                     <p>{appDetail.name}</p>
                  </div>
                  <div className="col-sm-6">
                     <p className="small light-txt">Date of birth</p>
                     <p>{getFormatDateMDY(appDetail.date_of_birth)}</p>
                  </div>
               </div>
               <p className="small light-txt">Photo Identification</p>
               {(!appDetail.uploadTypeFlag)? (ext !=='.pdf') ? 
               <p><img src={getBaseUrl()+"/containers/client-gov-id/download/"+appDetail.gov_issued_id} height="130px" width="210px"/></p>
               :<div className="upload-btn-wrapper idproof-btn">
               <button className="btn" type="button" onClick={this.onOpenUploadPdfModal}>View Id Proof</button>
               </div>
               :''}
               {(appDetail.uploadTypeFlag) ? 
               <p><img src={appDetail.imageData} height="130px" width="210px"/></p>
               : ''}
               <p className="small light-txt">Current address</p>
               <p>{appDetail.current_address}</p>
               <p className="small light-txt">Current landlord</p>
               <p>{appDetail.landlord_management_company_name}</p>
               <p className="small light-txt">Pets?</p>
               <p>{(appDetail.is_pets=='1')?'Yes':'No'}. {appDetail.pets_description}</p>
               <div className="row clearifx">
                  <div className="col-sm-8">
                     <p className="small light-txt">Current employer</p>
                     <p>{(appDetail.current_employer)?appDetail.current_employer:'-'} <span className="ph-no">{appDetail.employer_phone_number}</span></p>
                  </div>
                  <div className="col-sm-4">
                     <p className="small light-txt">Job title</p>
                     <p>{(appDetail.job_title)?appDetail.job_title:'-'}</p>
                  </div>
               </div>
            </div>
            <div className="col-sm-5">
               <p className="small light-txt">Uploaded documents</p>
               <div className="upload-btn-wrapper statement-btn">
                  {(appDetail.is_plaid)?
                  <button className="btn" type="button" onClick={this.onOpenPlaidModal}>Plaid / Bank Statements </button>:
                  <button className="btn" type="button" onClick={this.onOpenBankModal}>Plaid / Bank Statements </button>}
               </div>
               <div className="upload-btn-wrapper statement-btn">
                  <button className="btn" type="button" onClick={this.onOpenAdditionalModal}>Additional Documents</button>
               </div>

               {additionalBuildingButtons}

               

               {(appDetail.is_plaid)?
               <Modal open={openPlaid} onClose={this.onClosePlaidModal} center>
                  <PlaidDetails app_id={appDetail.id} onClick={this.onClosePlaidModal} dashboard='true'/>
               </Modal>
               :''}
               
               <Modal open={this.state.openBuildAddDocs} onClose={this.state.onCloseBuildAddDocs} center>
                  <BuildDocDetails fileDetails={this.state.BuildAddDocs} onClick={this.onCloseOpenDocs}/>
               </Modal>

               <Modal open={openBank} onClose={this.onCloseBankModal} center>
                  <div className="modal-content client-modal">
                     <div className="modal-header">
                        <h4 className="modal-title">Plaid / Bank Statements</h4>
                     </div>
                     {(!appDetail.is_plaid && (appDetail.bd_file_1 || appDetail.bd_file_2 || appDetail.bd_file_3))?
                     <div className="modal-body profile">
                        <div className="recipt">
                           {(appDetail.bd_file_1)?
                           <p>{getFileHTML(appDetail.bd_file_1,getBaseUrl()+'/containers/'+containerBank+'/download/'+appDetail.bd_file_1)}</p>
                           :''}
                           {(appDetail.bd_file_2)?
                           <p>{getFileHTML(appDetail.bd_file_2,getBaseUrl()+'/containers/'+containerBank+'/download/'+appDetail.bd_file_2)}</p>
                           :''}
                           {(appDetail.bd_file_3)?
                           <p>{getFileHTML(appDetail.bd_file_3,getBaseUrl()+'/containers/'+containerBank+'/download/'+appDetail.bd_file_3)}</p>
                           :''}
                        </div>
                     </div>
                     :
                     <div className="modal-body profile">
                        <div className="recipt">No Records</div>
                     </div>
                     }
                  </div>
               </Modal>
               <Modal open={openAdditional} onClose={this.onCloseAdditionalModal} center>
                  <div className="modal-content client-modal">
                     <div className="modal-header">
                        <h4 className="modal-title">Additional Document</h4>
                     </div>
                     {(appDetail.ad_file_1 || appDetail.ad_file_2 || appDetail.ad_file_3)?
                     <div className="modal-body profile">
                        <div className="recipt">
                           {appDetail.ad_file_1 && this.state.device_type=='web'?
                           <p>{getFileHTML(appDetail.ad_file_1,getBaseUrl()+'/containers/'+containerAdditional+'/download/'+appDetail.ad_file_1)}</p>
                           :<Link to={getBaseUrl()+'/containers/'+containerAdditional+'/download/'+appDetail.ad_file_1} download></Link>}
                           {appDetail.ad_file_2 && this.state.device_type=='web'?
                           <p>{getFileHTML(appDetail.ad_file_2,getBaseUrl()+'/containers/'+containerAdditional+'/download/'+appDetail.ad_file_2)}</p>
                           :<Link to={getBaseUrl()+'/containers/'+containerAdditional+'/download/'+appDetail.ad_file_2} download></Link>}
                           {appDetail.ad_file_3 && this.state.device_type=='web'?
                           <p>{getFileHTML(appDetail.ad_file_3,getBaseUrl()+'/containers/'+containerAdditional+'/download/'+appDetail.ad_file_3)}</p>
                           :<Link to={getBaseUrl()+'/containers/'+containerAdditional+'/download/'+appDetail.ad_file_3} download></Link>}
                        </div>
                     </div>
                     :
                     <div className="modal-body profile">
                        <div className="recipt">No Records</div>
                     </div>
                     }
                         <div class="container"><button type="button" className="btn cl-btn pull-right" onClick={this.onCloseAdditionalModal} >Close  <img src="/close.png"/></button></div>                                           
                  </div>
               </Modal>
            </div>
            <Modal open={this.state.openUploaded} onClose={this.onCloseUploadPdfModal} center>
            <div className="modal-content client-modal">
                     <div className="modal-header">
                        <h4 className="modal-title">Photo Identification Document</h4>
                     </div>
          <embed src={pdfpath} width="600" height="500" alt="pdf"></embed>
          </div>
          <div class="container"><button type="button" className="btn cl-btn pull-right" onClick={this.onCloseUploadPdfModal} >Close  <img src="/close.png"/></button></div>
               </Modal>
         </div>
         <button type="button" className="btn cl-btn pull-right" onClick={this.props.onClick} >Close  <img src="/close.png"/></button>                                                                       
      </div>
   </div>
</div>
                );
    }
}
export default Detail;