import React, { Fragment,lazy } from "react";
import { Link } from "react-router-dom";
import AgentHeader from "../../common/AgentHeader";
import AgentSideBar from "../../common/AgentSideBar";
import ManagerHeader from "../../common/ManagerHeader";
import ManagerSidebar from "../../common/ManagerSidebar";
import { Redirect } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "react-input-range/lib/css/index.css";
import Switch from "react-switch";
import { validatePhone } from "../../../services/validation";
import { getApiData, postApiData, getAgentId, getBaseUrl,getAgentDomain } from "../../../services/Api";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fromBlob } from 'image-resize-compress';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SalesCreator from './SalesListingCreator';
import Moment from 'moment';
import { isNullOrUndefined, isNull } from 'util';
import { getCurrentFormatDate } from "../../../services/Util";
import Dropzone from 'react-dropzone';
import DragSortableList from 'react-drag-sortable';
import Modal from 'react-responsive-modal';
import ViewlistingDoc from '../../common/ViewlistingDoc';
import ManagerEditListDoc from '../../common/ManagerEditListDoc';
//import { tr } from "date-fns/esm/locale";


const checkedBoxes = [];
const marketBoxes = [];
let sortedListVar;
// var addCount=[1];
var addCount = [];
var finalOpenHouseArr = [];
class ListingCreator extends React.Component {
  constructor(props) {
    super(props);
    console.log("props",this.props)
    this.state = {
      files: [],
      listing_info_address: "",
      listing_info_address_org: "",
      unit_number: "",
      bedrooms: "",
      bathrooms: "",
      square_footage: "",
      description: "",
      price: "",
      date_available: "",
      min_lease_term: "12",
      furnished: 0,
      list_images: '',
      unit_amenities: [],
      marketing: [],
      incentives: '',
      tenant_incentive: "",
      pets_policy: "",
      imagePath: "",
      kuula_vr_link: "",
      lat:'',
      long:'',
      listing_information: "",
      noFee: false,
      OwnerPaysPlusConcessionsYN: 0,
      imagePathArray: [],
      imagePathfloorArray: [],
      Exclusive_expiration_Date: "",
      CoBrokeAgreement: '',
      copercentage_broker: '',
      buildingTypeName:'',
      //error
      building_address_error: "",
      building_name_error: "",
      landlord_name_error: "",
      landlord_email_error: "",
      landlord_phone_error: "",
      Exclusive_expiration_Date_error: "",
      unit_number_error: "",
      bedrooms_error: "",
      bathrooms_error: "",
      square_footage_error: "",
      price_error: "",
      min_lease_term_error: "",
      date_available_error: "",
      kuula_vr_link_error: "",
      default_application_template_error: "",
      screenings_required_error: "",
      pets_policy_error: "",
      CoBrokeAgreement_error: '',
      data: [],
      linkingLoader: false,
      myerrormsg: '',
      flag: 0,
      successQuote: '0',
      imageForDb: [],
      imageForfloorDb: [],
      checkedBoxes: [],
      marketBoxes: [],
      building_address_auto: '',
      buildingList: [],
      building_id: 0,
      listing_info_address: '',
      selected_building_name: 'skyward',
      startDate: '',
      buildingDW: '',
      buildingCW: '',
      imageCount: 0,
      isImage: false,
      tabPanel: '',
      existCheckboxSelection: true,
      existmarketCheckboxSelection: true,
      redirect: false,
      building_id: this.props.building_id ? this.props.building_id : false,
      listing_id: this.props.listing_id ? this.props.listing_id : false,
      unit_no: this.props.unit_number ? this.props.unit_number : false,
      edit: this.props.edit ? this.props.edit : "no",
      amenityList: [],
      imageIdFromDb: [],
      imageIdForDb: [],
      building_ownership: 'Multifamily',
      total_rooms: null,
      layoutData: '',
      total_rooms_error: '',
      Exclusive_expiration_date: '',
      commission_percentage: '',
      my_split: 0,
      co_broker_split: 100,
      final_precentage: 0,
      PreWarYN: false,
      LobbyAttendant: false,
      VOWEntireListingDisplay: false,
      VOWAddressDisplay: false,
      VOWConsumerComment: false,
      VOWAutomatedValuationDisplay: false,
      NewDevelopment: false,
      IDXEntireListingDisplay: false,
      Bonus: false,
      FreeRent: false,
      RLSFlag: 0,
      openHouseDate: '',
      openHouseData: [{
        'date': ''
        , 'start_time': ''
        , 'end_time': ''
        , 'repeat_type':''
        , 'selected_days':[]
        , 'repeat_until':''
        , 'repeat_end_date':''
      }],
      owner_pays_data: [],
      exposures: '',
      expo: '',
      selectedOption: '',
      brokerOnly: false,
      appointmentOnly: false,
      _owner_pays_broker_: '',

      selected_status: 1,
      renthop_approval: true,
      skyward_approval: true,
      streetEasy_approval: true,
      RLS_approval: true,
      status: 1,
      rent_hop_export: 0,
      rent_hop_export_initial: 0,
      newShowing_agentList: [],
      primary_agentList: [],
      agentList: [],
      secondary_agent_error: '',
      primary_agent_error: '',
      secondary_agent_id: '',
      primary_agent_id: '',
      third_agent_error: '',
      third_agent_id: '',
      primary_agent_name: '',
      imagePath3: '',
      openViewer: false,
      editOpen: false,
      MaxLeaseMonths: 12,
      LeaseTerm: 'One Year',
      documentFile_name: [],
      imagePathDocumentArray: [],
      imageFordocumentDb:[],
      BonusRemarks:'',
      FreeRentRemarks:'',
      image_list_value:[],
      streeteasy_status:1,
      RLS_status:1,
      renthop_status:1,
      video_link: "",
      video_link_error: "",

    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleAddBuilding = this.handleAddBuilding.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDropfloor = this.onDropfloor.bind(this);
    this.onDropDocument = this.onDropDocument.bind(this);
  }

  rotate = param => event => {
    const { imagePathArray } = this.state;
    var index = param[1];
    let newRotation = param[2] + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState(state => (this.state.imagePathArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForDb[index].current_rotation = newRotation))
  }
  //Agent Details for front 
  getAgentList = (secondary_agent_id) => {

    if (secondary_agent_id == null)
      secondary_agent_id = '9999999999999';  // this is to do not set the 0 default agent id

    let whereFind = [{
      url: "/front/agent/list/" + secondary_agent_id
    }];

    postApiData(whereFind).then(res => {
      this.setState({ 'agentData': res.data }, () => {

      })
      this.state.agentData.map((item, index) => {
        this.setState({ newShowing_agent_name: item.name });
      });
    })
      .catch(error => {
        if (error.response) {
          console.log("error :", error)
          this.setState({ myerrormsg: error.response.data.error.message });
        }
      });
  }

  getprimaryAgentList = (primary_agent_id) => {

    if (primary_agent_id == null)
      primary_agent_id = '9999999999999';  // this is to do not set the 0 default agent id

    let whereFind = [{
      url: "/front/agent/list/" + primary_agent_id
    }];

    postApiData(whereFind).then(res => {
      this.setState({ 'primaryagentData': res.data }, () => {

      })
      this.state.primaryagentData.map((item, index) => {
        this.setState({ primary_agent_name: item.name });
      });
    })
      .catch(error => {
        if (error.response) {
          console.log("error :", error)
          this.setState({ myerrormsg: error.response.data.error.message });
        }
      });
  }

  getThirdAgentList = (third_agent_id) => {

    if (third_agent_id == null)
      third_agent_id = '9999999999999';  // this is to do not set the 0 default agent id

    let whereFind = [{
      url: "/front/agent/list/" + third_agent_id
    }];

    postApiData(whereFind).then(res => {
      this.setState({ 'thirdagentData': res.data }, () => {

      })
      this.state.thirdagentData.map((item, index) => {
        this.setState({ third_agent_name: item.name });
      });
    })
      .catch(error => {
        if (error.response) {
          console.log("error :", error)
          this.setState({ myerrormsg: error.response.data.error.message });
        }
      });
  }

  rotateleft = param => event => {
    const { imagePathArray } = this.state;
    var index = param[1];
    let newRotation = param[2] - 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState(state => (this.state.imagePathArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForDb[index].current_rotation = newRotation))
  }

  rotateimagelist = param => event => {
    const { list_images } = this.state;
    var index = param[1];
    let newRotation = param[2] + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState(state => (list_images.map((item, i) => {
      if (index == i) {
        item.rotation = newRotation
      }
    })
    ))

    let where = [{
      url: `/creator/updateimagerotation/${list_images[index].id}`
    }];

    postApiData(where, { "rotation": newRotation }, true)
      .then(res => {
        if (res.data) {
          this.setState({ 'pageLoader': false });
        }

      }).catch(error => {
        this.setState({ myerrormsg: error.response });
      });

  }

  rotateleftimagelist = param => event => {
    this.setState({ 'pageLoader': true });
    const { list_images } = this.state;
    var index = param[1];

    let newRotation = param[2] - 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState(state => (list_images.map((item, i) => {
      if (index == i) {
        item.rotation = newRotation
      }
    })
    ))

    let where = [{
      url: `/creator/updateimagerotation/${list_images[index].id}`
    }];

    postApiData(where, { "rotation": newRotation }, true)
      .then(res => {
        if (res.data) {
          this.setState({ 'pageLoader': false });
        }

      }).catch(error => {
        this.setState({ myerrormsg: error.response });
      });

  }


  rotatefloorimagelist = param => event => {
    const { list_images } = this.state;
    var index = param[1];
    let newRotation = param[2] + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState(state => (list_images.map((item, i) => {
      if (index == i) {
        item.rotation = newRotation
      }
    })
    ))

    let where = [{
      url: `/creator/updateimagerotation/${list_images[index].id}`
    }];

    postApiData(where, { "rotation": newRotation }, true)
      .then(res => {
        if (res.data) {
          this.setState({ 'pageLoader': false });
        }

      }).catch(error => {
        this.setState({ myerrormsg: error.response });
      });

  }

  rotateleftfloorimagelist = param => event => {
    this.setState({ 'pageLoader': true });
    const { list_images } = this.state;
    var index = param[1];

    let newRotation = param[2] - 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState(state => (list_images.map((item, i) => {
      if (index == i) {
        item.rotation = newRotation
      }
    })
    ))

    let where = [{
      url: `/creator/updateimagerotation/${list_images[index].id}`
    }];

    postApiData(where, { "rotation": newRotation }, true)
      .then(res => {
        if (res.data) {
          this.setState({ 'pageLoader': false });
        }

      }).catch(error => {
        this.setState({ myerrormsg: error.response });
      });


  }

  rotatefloor = param => event => {
    const { imagePathfloorArray } = this.state;
    var index = param[1];
    let newRotation = param[2] + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState(state => (this.state.imagePathfloorArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForfloorDb[index].current_rotation = newRotation))

  }

  rotateleftfloor = param => event => {
    const { imagePathfloorArray } = this.state;
    var index = param[1];
    let newRotation = param[2] - 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState(state => (this.state.imagePathfloorArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForfloorDb[index].current_rotation = newRotation))
  }

  onDrop = (files) => {
    var imageFile = files;


    if (files) {
      for (var i = 0; i < imageFile.length; i++) {
        let imgFlag = true;
        const maxFileSize = 5242880; //5mb
        const value = files[i].name;

        const ext = value.substring(value.lastIndexOf("."));
        if (![".jpg", ".JPG", ".jpeg", ".png"].includes(ext)) {
          this.setState({ mymsg: ext + " is not supported file extension." });
          imgFlag = false;
        }
        if (files[i].size > maxFileSize) {
          this.setState({ mymsg: "The uploaded image is too large. The max image file size is set to 5MB" });
          imgFlag = false;
        }
        if (imgFlag) { 

          fromBlob(imageFile[i], 80, 'auto', 'auto', 'webp')
            .then(compressedFile => {

              var file = new File([compressedFile], value);
              var temp = [...this.state.imagePathArray];
              var temp2 = [...this.state.imageForDb];

              temp.push({ file: URL.createObjectURL(file), "current_rotation": 0 });
              temp2.push({ file: file, "current_rotation": 0 });
              this.setState(
                {
                  imageForDb: temp2,
                  imagePathArray: temp
                },
                () => {

                  this.state.imageCount+=1;
                  this.setState({imageCount:this.state.imageCount})
                  this.state.isImage=true;


                }
              );
            })
            .catch(function (error) {
              console.log(error.message);
            });
        }
      }
    }
  };

  onDropfloor = (filesfloor) => {
    var imageFile = filesfloor;
    if (filesfloor) {
      for (var i = 0; i < imageFile.length; i++) {
        let imgFlag = true;
        const maxFileSize = 5242880; //5mb
        const value = filesfloor[i].name;
        const ext = value.substring(value.lastIndexOf("."));
        if (![".jpg", ".JPG", ".jpeg", ".png", ".pdf"].includes(ext)) {
          this.setState({ mymsgfloor: ext + " is not supported file extension." });
          imgFlag = false;
        }
        if (filesfloor[i].size > maxFileSize) {
          this.setState({ mymsgfloor: "The uploaded image is too large. The max image file size is set to 5MB" });
          imgFlag = false;
        }
        if (imgFlag) { 
          fromBlob(imageFile[i], 80, 'auto', 'auto', 'webp')
            .then(compressedFile => {

              var file = new File([compressedFile], value);
              var temp = [...this.state.imagePathfloorArray];
              var temp2 = [...this.state.imageForfloorDb];

              temp.push({ file: URL.createObjectURL(file), "current_rotation": 0 });
              temp2.push({ file: file, "current_rotation": 0 });
              this.setState(
                {
                  imageForfloorDb: temp2,
                  imagePathfloorArray: temp
                },
                () => {


                }
              );
            })
            .catch(function (error) {
              console.log(error.message);
            });
        }
      }
    }
  };

  // start ticket 873 drop zone
  onDropDocument = (filesdocument) => {
    var imageFile = filesdocument;
  
    if (filesdocument) {
      for (var i = 0; i < imageFile.length; i++) {
        let imgFlag = true;
        const maxFileSize = 5242880; //5mb
        const value = filesdocument[i].name;


        const ext = value.substring(value.lastIndexOf("."));
        if (![".jpg", ".JPG", ".jpeg", ".png", ".pdf"].includes(ext)) {
          this.setState({ mymsgdocument: ext + " is not supported file extension." });
          imgFlag = false;
        }
        if (filesdocument[i].size > maxFileSize) {
          this.setState({ mymsgdocument: "The uploaded image is too large. The max image file size is set to 5MB" });
          imgFlag = false;
        }
        if (imgFlag) {



          var file = imageFile[i];
          var temp = [...this.state.imagePathDocumentArray];
          var temp2 = [...this.state.imageFordocumentDb];
          var temp3 = [...this.state.documentFile_name]

          temp.push({ file: URL.createObjectURL(file) });
          temp2.push({ file: file });
          temp3.push({ value })
          this.setState(
            {
              imageFordocumentDb: temp2,
              imagePathDocumentArray: temp,
              documentFile_name: temp3
            },
            () => {


            }
          );


        }
      }
    }
  };
  //-----------End----------------

  feeddataByIdForEdit = () => {
    this.setState({linkingLoader:true});
    if (this.state.building_id !== false && this.state.listing_id !== false && this.state.unit_no !== false) {
      
      let listingWhere = [{
        url: '/managers/dataByIdForEdit'
      }];
      postApiData(listingWhere, { 'building_id': this.state.building_id, 'listing_id': this.state.listing_id, 'unit_number': this.state.unit_no }, true).then(res => {
        var listingItem = res.data.records[0];

        console.log("this.state.Exclusive_expiration_Date", listingItem);

        var otherListingInfo = res.data.otherInfoRec[0];
        console.log("otherListingInfo", otherListingInfo, otherListingInfo, res.data.otherInfoRec.length)
        let insent = listingItem.incentives.split(',');
        console.log('dataByIdForEdit', res.data.image)
        this.setState({
          listing_info_address: listingItem.listing_info_address,
          listing_info_address_org:listingItem.listing_info_address,
          listing_information: listingItem.listing_information,
          unit_number: listingItem.unit_number,
          bedrooms: listingItem.bedrooms,
          bathrooms: listingItem.bathrooms,
          price: listingItem.price,
          Neighbourhood: listingItem.neighborhood,
          description: unescape(listingItem.description),
          furnished: listingItem.furnished,
          square_footage: listingItem.square_footage,
          incentives: (insent[0] != undefined) ? insent[0] : '',
          owner_pays: (insent[1] != undefined) ? insent[1] : '',
          tenant_incentive: listingItem.tenant_incentive,
          noFee: listingItem.noFee,
          OwnerPaysPlusConcessionsYN: listingItem.owner_pays_plus_concessions,
          agent_name: listingItem.name,
          primary_agent_id: listingItem.agent_id,
          agent_email: listingItem.email,
          agent_mob: listingItem.mob_number,
          date_available: (listingItem.date_available != '0000-00-00' && listingItem.date_available) ? Moment(listingItem.date_available).format('MMMM DD, YYYY') : Moment(new Date()).format('MMMM DD, YYYY'),
          amenityList: res.data.listRecords,
          listing_status: res.data.records[0].status,
         // streeteasy_status:res.data.records[0].streeteasy_status,
         // RLS_status:res.data.records[0].RLS_status,
          renthop_status:res.data.records[0].renthop_status,
          rent_hop_export:listingItem.rent_hop_export,
          list_images: res.data.image,
          list_doc_image: res.data.listingdocument,
          min_lease_term: (listingItem.min_lease_term!='null' && listingItem.min_lease_term!=0) ? (listingItem.min_lease_term) :(12) ,
          building_ownership: (listingItem.building_ownership != 'undefined' && listingItem.building_ownership != 'None' && listingItem.building_ownership != 'Multi-family' && listingItem.building_ownership != 'Non-stabilized lease' && listingItem.building_ownership != 'Stabilized lease' && listingItem.building_ownership != 'Non-stabilized sublease' && listingItem.building_ownership != 'Commercial or professional' && listingItem.building_ownership != 'Stabilized sublease' && listingItem.building_ownership != 'Single-family') ? listingItem.building_ownership :listingItem.building_ownership == 'Single-family'?'Single Family':'Multifamily',
          Exclusive_expiration_date: (listingItem.exclusive_expiration_date != '0000-00-00' && listingItem.exclusive_expiration_date) ? Moment(listingItem.exclusive_expiration_date).format('MMMM DD, YYYY') : '',
          exposures: listingItem.exposures,
          layout: listingItem.layout,
          lat:listingItem.latitude,
          long:listingItem.longitude,
          // unit_condition:listingItem.unit_condition,
          // unit_view:listingItem.unit_view,
          total_rooms: listingItem.total_rooms,
          kuula_vr_link: listingItem.kuula_vr_link,
          video_link:listingItem.video_link,
          PreWarYN: (listingItem.pre_war == "false") ? false : true,
          LobbyAttendant: (listingItem.lobby_attendant == "false") ? false : true,
          Year_Built: listingItem.year_built,
          NumberOfUnitsTotal: listingItem.number_of_units_total,
          LegalRoomsTotal: listingItem.legal_rooms_total,
          StoriesTotal: listingItem.stories_total,
          TaxBlock: listingItem.tax_block,
          TaxLot: listingItem.tax_lot,
          LotWidth: listingItem.lot_width,
          commission_percentage: listingItem.commission_percentage,
          my_split: listingItem.my_split,
          LotWidth: listingItem.lot_width,
          owner_amount: listingItem.owner_pays_amount,
          appointmentOnly: (listingItem.open_house_appointment == 1) ? true : false,
          brokerOnly: (listingItem.open_house_broker == 1) ? true : false,
          _owner_pays_broker_: listingItem.owner_pays_broker,
          secondary_agent_id: listingItem.secondary_agentId,
          third_agent_id: listingItem.tertiary_agentId
        })
        
              res.data.image.map((item, index) => {
                if (item.image_type != "floor_plan") {
                  this.state.imageCount+=1;
                  this.setState({imageCount:this.state.imageCount})
                  this.state.isImage=true;
                }
              })

              res.data.image.map((item)=>{
                if(item.image_type!="floor_plan"){
                  this.setState(state => (this.state.image_list_value.push(item),state));
                }
      
              })
                
              this.setState({ linkingLoader: false })

              this.state.amenityList.map((item, index) => {
                if(res.data.records[0].status==13){
                  if (item.name == "RLS") {
                    if (item.name == "RLS") {
                      this.state.RLSFlag = 1
                      this.setState({RLS_status:0})
                    }
                  }
                  if(item.name=='Localize'){
                    this.setState({streeteasy_status:0})
                  }
                 
                }
                else{
                  if (item.name == "RLS") {
                    this.state.RLSFlag = 1
                  }
                  this.setState({
                    streeteasy_status:res.data.records[0].streeteasy_status,
                    RLS_status:res.data.records[0].RLS_status
                  })
                }
       
              })


        if (res.data.otherInfoRec.length > 0) {
          this.setState({
            CoBrokeAgreement: otherListingInfo.co_broke_agreement,
            VOWEntireListingDisplay: (otherListingInfo.VOW_entire_listing_display == "false") ? false : true,
            VOWAddressDisplay: (otherListingInfo.VOW_address_display == "false") ? false : true,
            VOWConsumerComment: (otherListingInfo.VOW_consumer_comment == "false") ? false : true,
            VOWAutomatedValuationDisplay: (otherListingInfo.VOW_automated_valuation_display == "false") ? false : true,
            NewDevelopment: (otherListingInfo.new_development == "false") ? false : true,
            IDXEntireListingDisplay: (otherListingInfo.IDX_entire_listing_display == "false") ? false : true,
            Bonus: (otherListingInfo.bonus == "false") ? false : true,
            FreeRent: (otherListingInfo.free_rent == "false") ? false : true,
            ShowingInstructions: otherListingInfo.showing_instructions,
            FurnishedListPrice: otherListingInfo.furnished_listprice,
            FurnishedMinLeaseMonths: otherListingInfo.furnished_min_lease_months,
            FurnishedMaxLeaseMonths: otherListingInfo.furnished_max_lease_months,
            LeaseTerm: otherListingInfo.lease_term,
            MaxLeaseMonths: otherListingInfo.max_lease_months,
            BonusRemarks: otherListingInfo.bonus_remarks,
            FreeRentRemarks: otherListingInfo.free_rent_Remarks,
            air_conditioning_type: otherListingInfo.air_conditioning_type,
            views_remarks: otherListingInfo.views_remarks
          })
        }
        if (this.state.commission_percentage <= 100) {
          var _commission = this.state.my_split * this.state.commission_percentage;
          var _percentage = _commission / 100;
          var _cocommission = this.state.commission_percentage * (100 - this.state.my_split);
          var _copercentage = _cocommission / 100;
          this.setState({
            final_precentage: _percentage,
            copercentage_broker: _copercentage
          })
        }
        /* Get Secondory Agent name to show auto fill in the field */
        this.getAgentList(listingItem.secondary_agentId);
        this.getprimaryAgentList(listingItem.agent_id);
        this.getThirdAgentList(listingItem.tertiary_agentId);
        if (this.state.my_split <= 100) {
          var _co_broker_split = 100 - this.state.my_split
          var _commission = this.state.my_split * this.state.commission_percentage;
          var _percentage = _commission / 100;
          var _cocommission = this.state.commission_percentage * _co_broker_split;
          var _copercentage = _cocommission / 100;
          this.setState({

            co_broker_split: 100 - this.state.my_split,
            final_precentage: _percentage,
            copercentage_broker: _copercentage
          })
        }

        this.state.amenityList.map((item, index) => {
          if(res.data.records[0].status==13){
            if (item.name == "RLS") {
              if (item.name == "RLS") {
                this.state.RLSFlag = 1
                this.setState({RLS_status:0})
              }
            }
            if(item.name=='Localize'){
              this.setState({streeteasy_status:0})
            }
           
          }
          else{
            if (item.name == "RLS") {
              this.state.RLSFlag = 1
            }
            this.setState({
              streeteasy_status:res.data.records[0].streeteasy_status,
              RLS_status:res.data.records[0].RLS_status
            })
          }
 
        })


        if (!isNullOrUndefined(listingItem.unit_view) && listingItem.unit_view != '' && listingItem.unit_view != 'undefined') {
          document.getElementById('unit_view').value = listingItem.unit_view;

        }

        if (!isNullOrUndefined(listingItem.unit_condition) && listingItem.unit_condition != '' && listingItem.unit_condition != 'undefined') {
          document.getElementById('unit_condition').value = listingItem.unit_condition;

        }

        if (!isNullOrUndefined(listingItem.pets) && listingItem.pets != '' && listingItem.pets != 'undefined') {
          document.getElementById('pets').value = listingItem.pets;
          this.setState({ pets_policy: listingItem.pets })

        }


        if (!isNullOrUndefined(listingItem.building_type) && listingItem.building_type != '' && listingItem.building_type != 'undefined') {
          console.log('listingItem.building_type',listingItem.building_type)
          document.getElementById('buildingType').value = listingItem.building_type;
          this.setState({ buildingTypeName: listingItem.building_type });

        }

        if (!isNullOrUndefined(listingItem.co_broke_agreement) && listingItem.co_broke_agreement != '' && listingItem.co_broke_agreement != 'undefined') {
          document.getElementById('CoBrokeAgreement').value = listingItem.co_broke_agreement;

        }

        if (!isNullOrUndefined(listingItem.owner_pays_broker) && listingItem.owner_pays_broker != '' && listingItem.owner_pays_broker != 'undefined') {

          document.getElementById('owner_pays_broker').value = listingItem.owner_pays_broker;

        }

        if (!isNullOrUndefined(otherListingInfo.air_conditioning_type) && otherListingInfo.air_conditioning_type != '' && otherListingInfo.air_conditioning_type != 'undefined') {

          document.getElementById('air_conditioning_type').value = otherListingInfo.air_conditioning_type;

        }
        if (!isNullOrUndefined(listingItem.owner_pays_amount) && listingItem.owner_pays_amount != '' && listingItem.owner_pays_amount != 'undefined') {
          document.getElementById('owner_pays_amount').value = listingItem.owner_pays_amount;

        }
        if (!isNullOrUndefined(listingItem.listing_status) && (listingItem.listing_status == 1 || listingItem.listing_status == 20)) {
          document.getElementById('listing_status').value = this.state.listing_status;

        }
        this.setState({ linkingLoader: false }, () => {
          this.validation()
        });

      }).catch(err => {

      })
    }
  }

  componentWillMount = () => {
    window.scroll(0, 0);
    this.layout();
    this.getData();
    this.ownerpays();
    this.buildingType();
    this.cobrokeAgreement();
    this.airConditionType();
    this.feeddataByIdForEdit();
    this.openHousesDetail();
    this.agentSlots();
    this.agentOpt();

    //this.buildingOpt();
  }

  openHousesDetail = () => {
    addCount = []
    let where = [{
      url: '/managers/openHouses'
    }]
    postApiData(where, { 'listing_id': this.state.listing_id }, true).then(res => {
      console.log("----res----------", res.data);
      let data=[];
      let count=0;
      res.data.map((item, index) => {
        addCount.push(item);
        data.push(
          {
            date: item.date
            , start_time: item.start_time
            , end_time: item.end_time
            , repeat_type:item.repeat_type
            , selected_days:(item.selected_days!='')?item.selected_days.split(','):[]
            , repeat_until:item.repeat_until
            , repeat_end_date:item.repeat_end_date
          }
        );
        count=count+1;
        
      })

      if(count==res.data.length){
        this.setState({
          openHouseData: data
        });
      }
    }).catch(error => {

    })
  }

  vatidateduplidaterow = (index) => {

    var datevalue = this.state.openHouseData[index].date;
    var startvalue = this.state.openHouseData[index].start_time;
    var endvalue = this.state.openHouseData[index].end_time;
    var endDate = this.state.openHouseData[index].repeat_end_date;

    if (endvalue != "" && startvalue != "" && (startvalue >= Moment(endvalue, "h:mm A").format("HH:mm:ss") || Moment(startvalue, "h:mm A").format("HH:mm:ss") >= endvalue || startvalue >= endvalue) && startvalue != "00:00:00" && endvalue != "00:00:00") {
      alert("End time should be greater than Start time");
      this.setState(state => (this.state.openHouseData[index] = {
        date: datevalue
        , start_time: startvalue
        , end_time: ''
        , repeat_type: 'Never'
        , selected_days: []
        , repeat_until: ''
        , repeat_end_date: ''
      }, state));
    }
    if (datevalue != "" && startvalue != "" && endvalue != "") {

      this.state.openHouseData.map((item, _index_) => {

        if (new Date(item.date).getTime() == new Date(datevalue).getTime() && (item.start_time == Moment(startvalue, "h:mm A").format("HH:mm:ss") || new Date(item.start_time).toTimeString() == new Date(startvalue).toTimeString()) && (item.end_time == Moment(endvalue, "h:mm A").format("HH:mm:ss") || new Date(item.end_time).toTimeString() == new Date(endvalue).toTimeString()) && _index_ != index) {

          this.setState(state => (this.state.openHouseData[index] = {
            date: datevalue
            , start_time: startvalue
            , end_time: endvalue
            , repeat_type: 'Never'
            , selected_days: []
            , repeat_until: ''
            , repeat_end_date: ''
          }, state));
        }
      })
    }

//repeted end date should be greater than start date
if(datevalue!="" && endDate!="" ){
  if(new Date(datevalue).getTime()>new Date(endDate).getTime()){
    alert("End Date should be greater than Start Date");
    this.setState(state=>(this.state.openHouseData[index]={
      date: datevalue
      , start_time: startvalue
      , end_time: endvalue
      , repeat_type: 'Never'
      , selected_days: []
      , repeat_until: ''
      , repeat_end_date: ''
    },state)) ;
  }

}

  }


  handleDateChange = (param) => event => {


    if (param[1] == '_date_') {
      console.log("event-----",event);
      this.setState(state => (this.state.openHouseData[param[0]].date = event, state), () => {
        this.vatidateduplidaterow(param[0]);
      });
    }
    if (param[1] == '_start_time_') {
      this.setState(state => (this.state.openHouseData[param[0]].start_time = event, state), () => {
        this.vatidateduplidaterow(param[0]);
      });
    }
    if (param[1] == '_end_time_') {
      this.setState(state => (this.state.openHouseData[param[0]].end_time = event, state), () => {
        this.vatidateduplidaterow(param[0]);
      });
    }

    if(param[1]=='_repeat_'){
      console.log("event _repeat_",event.target.id,event.target.value)
      let value=event.target.value;
      this.setState(state=>(this.state.openHouseData[param[0]].repeat_type=value,state),()=>{
        this.vatidateduplidaterow(param[0]);
      }) ;
    }

    if(param[1]=='_repeat_until_'){
      let value=event.target.value;
      this.setState(state=>(this.state.openHouseData[param[0]].repeat_until=value,state),()=>{
        this.vatidateduplidaterow(param[0]);
      }) ;
    }


    if(param[1]=='_selectedWeekDay_'){
      if(this.state.openHouseData[param[0]].selected_days.find(el=>el==param[2])){
        let list=this.state.openHouseData[param[0]].selected_days.filter(item => item !== param[2])
        this.setState(state=>(this.state.openHouseData[param[0]].selected_days=list,state),()=>{
          this.vatidateduplidaterow(param[0]);
        })
      }else{
        this.setState(state=>(this.state.openHouseData[param[0]].selected_days.push(param[2]),state),()=>{
          this.vatidateduplidaterow(param[0]);
        })
      }
    }



    


  }

  end_date=(param)=>event=>{
    
      console.log('event',event);
      this.setState(state=>(this.state.openHouseData[param[0]].repeat_end_date=event,state),()=>{
        this.vatidateduplidaterow(param[0]);
      }) ;
    
  }

  layout = () => {
    let where = [{
      url: '/agent/layout'
    }]
    postApiData(where, { 'tableName': 'listing_layout' }, true).then(res => {
      console.log("res.data", res.data);
      this.setState({
        layoutData: res.data
      })

    }).catch(error => {

    })
  }
  ownerpays = () => {
    let where = [{
      url: '/agent/ownerPays'
    }];
    postApiData(where, { 'tableName': 'owner_pays_broker_master' }, true).then(res => {
      console.log("--res", res);
      this.setState({ owner_pays_data: res.data })
    }).catch(error => {

    })
  }

  buildingType = () => {
    let where = [{
      url: '/agent/buildingtype'
    }];
    postApiData(where, { 'tableName': 'building_type_master' }, true).then(res => {
      console.log("res---", res);
      this.setState({
        building_type: res.data
      })
    }).catch(error => {
      console.log("error---", error);
    })
  }

  cobrokeAgreement = () => {
    let where = [{
      url: '/agent/cobrokeagreement'
    }];
    postApiData(where, { 'tableName': 'co_broke_agreement' }, true).then(res => {
      console.log("--res", res.data);
      this.setState({
        co_broke_agreement: res.data
      })
    }).catch(error => {

    })
  }

  airConditionType = () => {
    let where = [{
      url: '/agent/airconditiontype'
    }];
    postApiData(where, { 'tableName': 'air_conditioning_type_master' }, true).then(res => {
      console.log("--res", res.data);
      this.setState({
        air_conditioning_type_master: res.data
      })
    }).catch(error => {

    })
  }


  handleChangeDate(date) {
    this.setState({
      startDate: date,
      date_available: date
    });
  }

  setTab = () => {
    this.setState({ tabPanel: 'Sales' })
  }

  handleAddBuilding() {
    var addr = document.getElementById('Address').value

    if (this.props.userType == "Agent") {
      window.location.href = "/agent/building/add/0/0/" + addr;
    }
    if (this.props.userType == "Manager") {
      window.location.href = "/manager/build/0/0/" + addr;
    }
  }
  async buildingOpt(addr) {
    let buildingParam = [{
      url: "/nestio/getbuildingbyaddress/" + addr
    }];
    getApiData(buildingParam, true).then(res => {
      this.setState({ 'buildingList': res.data });
    }).catch(error => {
      if (error.response) {
        this.setState({ myerrormsg: error.response.data.error.message });
      }
    });
  }
  handleBuildingSearch = event => {
    var selected_building_name = event.target.getAttribute('name');

    this.setState({
      selected_building_name: selected_building_name
    })
    var key = event.target.id;
    switch (key) {
      case 'Address':

        this.setState({ searchParm: event.target.value, listing_info_address: event.target.value }, () => {
          //if(this.state.searchParm.length>-1){
          var value = this.state.searchParm;
          if (value != '' && value.length > 2) {
            this.buildingOpt(value);

            this.state.buildingDW = this.state.buildingList.map((aw, index) =>
              (aw.id > 0) ? <li id="searchkey" key={aw.id} value={aw.id} name={aw.building_address} onClick={this.handleBuildingSearch}>{aw.building_address}</li> : <li onClick={this.handleAddBuilding}>No match found to entered criteria. Add a new Building.</li>);



            this.state.buildingList.forEach(function (building, index) {
              if (building.building_address.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                building.flag = 1;
              } else {
                building.flag = 0;
              }
            });
          } else {
            document.getElementById('building_search_id_values').style.display = 'none';
            this.state.buildingList.forEach(function (building, index) {
              building.flag = 0;
            });

          }
        })
        //}
        break;
      case 'searchkey':
        var value = event.target.value;
        let $this = this;
        this.setState({ agent_id: event.target.value }, () => {
          this.state.buildingList.forEach(function (building, index) {
            if (building.id == value) {
              document.getElementById('building_search_id_values').style.display = "none";
              document.getElementById('Address').value = building.building_address;
              $this.setState({ listing_info_address: building.building_address });
              $this.setState({ listing_info_address_org: building.building_address });
              $this.setState({lat:building.latitude});
              $this.setState({long:building.longitude});
              $this.setState({ PreWarYN: (building.pre_war == "false") ? false : true });
              $this.setState({ LobbyAttendant: (building.lobby_attendant == "false") ? 'false' : 'true' });
              $this.setState({ Year_Built: building.year_built });
              
              $this.setState({ buildingTypeName: building.building_type });
              $this.setState({ NumberOfUnitsTotal: building.number_of_units_total });
              $this.setState({ LegalRoomsTotal: building.legal_rooms_total })
              $this.setState({ StoriesTotal: building.stories_total })
              $this.setState({ TaxBlock: building.tax_block });
              $this.setState({ TaxLot: building.tax_lot });
              $this.setState({ LotWidth: building.lot_width });
            } else {
              building.flag = 0;
            }
          });

          document.getElementById('building_search_id_values').style.display = "none";
        });
        break;
      case 'default':
        break;

    }
    document.getElementById('building_search_id_values').style.display = "block";
  }

  contains(arr, key, val) {
    let flag = 0;
    let count = 0;
    for (var i = 0; i < arr.length; i++) {
      count = count + 1;
      if (arr[i][key] == val) {
        flag = 1;
        return true;
      }
    }
    if (flag == 0 && count == arr.length) {
      return false;
    }

  }


  getData = () => {
    let applicationList = [{
      url: "/creator",
    }];

    postApiData(applicationList, { 'additional_documents': 'additional_documents', 'application_template': 'application_template', 'application_screening': 'application_screening', 'building_amenities': 'building_amenities', 'marketing': 'marketing' }, false).then(res => {

      this.setState({
        linkingLoader: true,
        data: res.data,
      }, () => {
        this.setState({ linkingLoader: false, flag: 1 })
        res.data[4].map((value) => {
          var val = this.contains(this.state.marketing, "name", value.name); //true
          if (value.approval_required == 1 && value.name == 'Renthop') {
            this.setState({ renthop_approval: false });
            if (value.default_set == 1) {
              this.setState({ renthop_status: 0 });
            }
          }

          if (value.approval_required == 1 && value.name == 'Skyward') {
            this.setState({ skyward_approval: false });
            if (value.default_set == 1) {
              this.setState({ status: 0 });
            }
          }
          if (value.approval_required == 1 && value.name == 'Localize') {
            this.setState({ streetEasy_approval: false });
            if (value.default_set == 1) {
              this.setState({ streeteasy_status: 0 });
            }
          }
          if (value.approval_required == 1 && value.name == 'RLS') {
            this.setState({ RLS_approval: false });
            if (value.default_set == 1) {
              this.setState({ RLS_status: 0 });
            }
          }
          if (value.default_set == 1 && value.name == 'RLS') {
            this.setState({ RLSFlag: 1 });
          }
          if (this.state.marketing.length == 0 && value.default_set == 1) {
            this.setState(state => (this.state.marketing.push({ related_id: value.id, name: value.name })));
          } else if (val && value.default_set == 1) {
            this.setState(state => (this.state.marketing.push({ related_id: value.id, name: value.name })));
          }
          this.setState({ linkingLoader: false, flag: 1 })
        })
      })

    }).catch(error => {
      if (error.response) {
        this.setState({ myerrormsg: error.response.data.error.message });
      }
    });
  }
  contains(arr, key, val) {
    let flag = 0;
    let count = 0;
    for (var i = 0; i < arr.length; i++) {
      count = count + 1;
      if (arr[i][key] == val) {
        flag = 1;
        return true;
      }
    }
    if (flag == 0 && count == arr.length) {
      return false;
    }

  }
  handleSubmit = (e) => {
   
    e.preventDefault();

    let status = 1
    this.state.marketBoxes.map((item) => {
      if (item.approval_required == 1) {
        status = 0
      }
    });
    let count=0;
    let flag=0;

    this.state.openHouseData.map((item,_index_)=>{
        console.log("item=>",item);
        if(item.repeat_type!='Never' && item.repeat_until=='specificDate' && (item.repeat_end_date=='' || item.repeat_end_date=='0000-00-00') ){
          
          this.setState(state => (this.state.openHouseData[_index_] = {
        date: item.date
        , start_time: item.start_time
        , end_time: item.end_time
        , repeat_type: 'Never'
        , selected_days: []
        , repeat_until: ''
        , repeat_end_date: ''
      }, state));

          flag=1;
        }
        
        count=count+1;
        
    })
    if(count==this.state.openHouseData.length && flag==1){
      alert('End date should not be blank');
      return false;
    }
    const {
      building_address, building_name, landlord_name, landlord_email, landlord_phone,
      required_additional_documents, default_application_template, screenings_required,
      listing_information, listing_info_address, unit_number, bedrooms, bathrooms,
      square_footage, description, price, date_available, min_lease_term, furnished, incentives,
      checkedBoxes, marketBoxes, tenant_incentive, kuula_vr_link, imageForDb, imageForfloorDb, documentFile_name, imageFordocumentDb, noFee,lat,long, OwnerPaysPlusConcessionsYN, linkingLoader,
      listing_id, image, building_id, imageIdFromDb, selected_status, primary_agent_id, video_link
    } = this.state;
    console.log("this.state", this.state);
    if (document.getElementById("layout")) {
      var layout = document.getElementById("layout").value;
    }
    if (document.getElementById("unit_view")) {
      var unit_view = document.getElementById("unit_view").value;
    }
    if (document.getElementById("unit_condition")) {
      var unit_condition = document.getElementById("unit_condition").value;
    }
    if (document.getElementById("pets")) {
      var pets_policy = document.getElementById("pets").value;
    }
    if (document.getElementById("owner_pays_broker")) {
      var owner_pays_broker = document.getElementById("owner_pays_broker").value;
    }
    if (document.getElementById("buildingType")) {
      var buildingType = document.getElementById("buildingType").value;
    }
    if (document.getElementById("CoBrokeAgreement")) {
      var CoBrokeAgreement = document.getElementById("CoBrokeAgreement").value;
    }
    if (document.getElementById("air_conditioning_type")) {
      var AirConditioningType = document.getElementById("air_conditioning_type").value;
    }
 

      let where;

      if(this.state.edit == "yes")
      {
        where = [{
          url: '/managers/editDetail'
        }];
        
      }
      else{
        if(this.state.listing_status!=13){
          where = [{
            url: '/managers/editDetail'
          }];
        }
        else{
          where = [{
           url: '/addNewDetail'
         }];
       }
      }
     
      const formData = new FormData();
      formData.append('listing_id', listing_id)
      formData.append('listing_information', listing_information)
      formData.append('listing_info_address', listing_info_address)
      formData.append('unit_number', unit_number)
      formData.append('bedrooms', bedrooms)
      formData.append('bathrooms', bathrooms)
      formData.append('square_footage', square_footage)
      formData.append('description', description)
      formData.append('price', price)
      formData.append('date_available', date_available)
      formData.append('min_lease_term', min_lease_term)
      formData.append('incentives', incentives + ',' + this.state.owner_pays)
      formData.append('furnished', furnished)
      formData.append('unit_amenities', JSON.stringify(checkedBoxes))
      
      if(getAgentDomain()==='kw.com'){
        formData.append('domain','kw')
        formData.append('marketing',JSON.stringify([{related_id:'0',name:'KW'}]))
      }
      else{
        formData.append('marketing', JSON.stringify(marketBoxes))
      }
      
      formData.append('tenant_incentive', tenant_incentive)
      formData.append('kuula_vr_link', kuula_vr_link)
      formData.append('video_link',video_link)
      formData.append('noFee', noFee)
      formData.append('owner_pays_plus_concessions', OwnerPaysPlusConcessionsYN)
      formData.append('listing_type', 'Rental')
      if (document.getElementById('searchkey')) {
        formData.append('building_id', document.getElementById('searchkey').value)
      } else {
        formData.append('building_id', building_id)
      }
      formData.append('imageId', JSON.stringify(imageIdFromDb))
      if (this.props.userType != "Manager")
        formData.append('agent_id', getAgentId())
      imageForDb.map((image, index) => {
        formData.append('images', image.file)
      })
      imageForDb.map((image, index) => {
        formData.append('images_rotation', image.current_rotation);
      })
      imageForfloorDb.map((image, index) => {
        formData.append('imagesfloor', image.file)
      })
      imageForfloorDb.map((image, index) => {
        formData.append('imagesfloor_rotation', image.current_rotation);

      })
      imageFordocumentDb.map((image, index) => {
        formData.append('imagesdocument', image.file);

      })
      formData.append('latitude',lat)
      formData.append('longitude',long)
      formData.append('layout', layout)
      formData.append('unit_view', unit_view)
      formData.append('unit_condition', unit_condition)
      formData.append('pets_policy', pets_policy)
      formData.append('building_ownership', this.state.building_ownership)
      formData.append('exposures', this.state.exposures)
      formData.append('total_rooms', this.state.total_rooms)
      formData.append('Exclusive_expiration_Date', this.state.Exclusive_expiration_date)
      if (this.state.owner_pays == "Owner pays") {
        formData.append('owner_pays_broker', owner_pays_broker)
        formData.append('owner_amount', this.state.owner_amount)
      }
      if (this.state.incentives == "Co-broke") {
        formData.append('commission_percentage', this.state.commission_percentage)
        formData.append('my_split', this.state.my_split)
      }

      formData.append('BuildingType', buildingType)
      formData.append('PreWarYN', this.state.PreWarYN)
      formData.append('LobbyAttendant', this.state.LobbyAttendant)
      formData.append('Year_Built', this.state.Year_Built)
      formData.append('NumberOfUnitsTotal', this.state.NumberOfUnitsTotal)
      formData.append('LegalRoomsTotal', this.state.LegalRoomsTotal)
      formData.append('StoriesTotal', this.state.StoriesTotal)
      formData.append('TaxBlock', this.state.TaxBlock)
      formData.append('TaxLot', this.state.TaxLot)
      formData.append('LotWidth', this.state.LotWidth)

      formData.append('VOWEntireListingDisplay', this.state.VOWEntireListingDisplay)
      formData.append('VOWAddressDisplay', this.state.VOWAddressDisplay)
      formData.append('VOWConsumerComment', this.state.VOWConsumerComment)
      formData.append('VOWAutomatedValuationDisplay', this.state.VOWAutomatedValuationDisplay)
      formData.append('NewDevelopment', this.state.NewDevelopment)
      formData.append('IDXEntireListingDisplay', this.state.IDXEntireListingDisplay)
      formData.append('Bonus', this.state.Bonus)
      formData.append('FreeRent', this.state.FreeRent)
      formData.append('CoBrokeAgreement', CoBrokeAgreement)
      formData.append('ShowingInstructions', this.state.ShowingInstructions)
      formData.append('FurnishedListPrice', this.state.FurnishedListPrice)
      formData.append('FurnishedMinLeaseMonths', this.state.FurnishedMinLeaseMonths)
      formData.append('FurnishedMaxLeaseMonths', this.state.FurnishedMaxLeaseMonths)
      formData.append('LeaseTerm', this.state.LeaseTerm)
      formData.append('MaxLeaseMonths', this.state.MaxLeaseMonths)
      formData.append('BonusRemarks', this.state.BonusRemarks)
      formData.append('FreeRentRemarks', this.state.FreeRentRemarks)
      formData.append('views_remarks', this.state.views_remarks)
      formData.append('air_conditioning_type', AirConditioningType)



      formData.append('openHouse', JSON.stringify(this.state.openHouseData))
      formData.append('broker_only', (this.state.brokerOnly) ? 1 : 0)
      formData.append('appointment_only', (this.state.appointmentOnly) ? 1 : 0)
      if (this.props.userType == "Manager") {
        if (this.state.primary_agent_id != '' && this.state.primary_agent_id != undefined && this.state.primary_agent_id != null) {
          formData.append('agent_id', this.state.primary_agent_id)
        }
        else {
          formData.append('agent_id', primary_agent_id)
        }

        if (this.state.edit == "yes") {
          formData.append('status', this.state.listing_status)
          formData.append('rent_hop_export',this.state.rent_hop_export)
          formData.append('rent_hop_export_initial',this.state.rent_hop_export_initial)
          formData.append('streeteasy_status',1)
          formData.append('RLS_status',1)
          formData.append('renthop_status',1)
        }
        else{
          if(this.state.listing_status!=13){
          formData.append('status', selected_status)
          formData.append('rent_hop_export',this.state.rent_hop_export)
          formData.append('rent_hop_export_initial',this.state.rent_hop_export_initial)
          formData.append('streeteasy_status',1)
          formData.append('RLS_status',1)
          formData.append('renthop_status',1)
          }
          else{
          formData.append('status', 1)
          formData.append('rent_hop_export',this.state.rent_hop_export)
          formData.append('rent_hop_export_initial',this.state.rent_hop_export_initial)
          formData.append('streeteasy_status',1)
          formData.append('RLS_status',1)
          formData.append('renthop_status',1)
          }
          
        }
          
      }
      else {
      /*  if (this.state.primary_agent_id != '' && this.state.primary_agent_id != undefined && this.state.primary_agent_id != null) {
          formData.append('agent_id', this.state.primary_agent_id)
        }
        else {
          formData.append('agent_id', primary_agent_id)
        }*/
        if (this.state.edit == "yes") {
          formData.append('status', this.state.listing_status)
          formData.append('rent_hop_export',this.state.rent_hop_export)
          formData.append('rent_hop_export_initial',this.state.rent_hop_export_initial)
          formData.append('streeteasy_status',this.state.streeteasy_status)
          formData.append('RLS_status',this.state.RLS_status)
          formData.append('renthop_status',this.state.renthop_status)
          
        }
        else{
          if(this.state.listing_status!=13){
            formData.append('status', selected_status)
            formData.append('rent_hop_export',this.state.rent_hop_export)
            formData.append('rent_hop_export_initial',this.state.rent_hop_export_initial)
            formData.append('streeteasy_status',1)
            formData.append('RLS_status',1)
            formData.append('renthop_status',this.state.renthop_status)
          }
          else{
          formData.append('status', 1)
          formData.append('rent_hop_export',this.state.rent_hop_export)
          formData.append('rent_hop_export_initial',this.state.rent_hop_export_initial)
          formData.append('streeteasy_status',this.state.streeteasy_status)
          formData.append('RLS_status',this.state.RLS_status)
          formData.append('renthop_status',this.state.renthop_status)
          }
          
        }
          
      }

      formData.append("secondary_agentId", this.state.secondary_agent_id)
      formData.append("tertiary_agentId", this.state.third_agent_id)
      
      documentFile_name.map((filename, index) => {
        formData.append('docFileName', filename.value);

      })
      formData.append('list_images',JSON.stringify(this.state.list_images))

      postApiData(where, formData, true).then(res => {
        if (res.data == 202) // listing already exist
        {
          this.setState({ linkingLoader: false, successQuote: '2', });
          window.scrollTo(500, 0);
        }
        else {
          this.setState({ linkingLoader: true }, () => {
            if (this.props.userType == "Agent")
              window.location.href = "../../list/edit/";
            if (this.props.userType == "Manager")
              window.location.href = "../../../managerlist/edit/";
            this.setState({ linkingLoader: false, successQuote: '1', });
          })
        }
      }).catch(error => {
        this.setState({ myerrormsg: error.response });
      });
    
  };
  exposuresCheckBox = (event) => {
    var exposuresCheckBoxs;
    var length;
    if (this.state.exposures) {
      exposuresCheckBoxs = this.state.exposures.split(',');
      var length = exposuresCheckBoxs.length;


      var flag = 0; var count = 0; var length = exposuresCheckBoxs.length;

      exposuresCheckBoxs.map(item => {
        count = count + 1;
        if (item == event.target.value) {
          var index = exposuresCheckBoxs.indexOf(event.target.value);
          exposuresCheckBoxs.splice(index, 1);
          flag = 1;
          var expo = exposuresCheckBoxs.join(',');
          this.setState({
            exposures: expo
          })
        }
      })
      if (length == count && flag == 0) {
        exposuresCheckBoxs.push(event.target.value);
        var expo = exposuresCheckBoxs.join(',');
        this.setState({
          exposures: expo
        })
      }
    }
    else {
      var expocheck = []
      expocheck.push(event.target.value);
      var expo = expocheck.join(',');
      this.setState({
        exposures: expo
      })
    }
  }

  //name attribute here is id from the Database 
  multiselectCheckbox = (param, value, name,check) => {
    if (param == "building_amenities") {
      var stateArray = this.state.building_amenities;
    }
    if (param == "marketing") {
      var stateArray = this.state.marketing;
    }
    if (param == "unit_amenities") {
      var stateArray = this.state.unit_amenities;
    }


    var flag = 0;
    var temp = { related_id: name, name: value };
    var removeIndex;
    // this is launches only one when the array is empty
    if (stateArray.length === 0) {
      stateArray.push(temp);
      // if(value=="RLS"){
      //   this.setState({
      //     RLSFlag:1
      //   })
      // }

    } else {
      //checking for the element if already exists
      stateArray.map(item => {
        if (item.name == value) {
          removeIndex = item;
          flag = 1;
        }
      });
    }
    //if flag=0, then element can be pushed
    if (flag === 0) {
      stateArray.push(temp);
     
      // if (value == "Renthop") {
      //   this.setState({
      //     rent_hop_export: 1
      //   })
      // }
      //removing duplicate elements if any
      stateArray = [...new Set(stateArray)];
    } else {
      //removing elements from the array on uncheck

      var index = stateArray.indexOf(removeIndex);
     
      // if (value == "Renthop") {
      //    this.setState({
      //      rent_hop_export: 0
      //   })
      // }
      stateArray.splice(index, 1);
    }
    this.setState(
      {
        [param]: stateArray
      },
      () => {
        let len =this.state.marketing.length;
        let count=0;
        let flag=0;
        this.state.marketing.map(item=>{
          count=count+1;
            
            if(item.name=='RLS' && this.state.RLS_approval==false){
              flag=1;
              if(check){
                if(this.state.RLS_status==1){
                    this.setState({RLS_status:0})
                }
              }
              else{
                if(this.state.RLS_status==1){
                  this.setState({RLS_status:1})
              }
              else{
                this.setState({RLS_status:1})
              }
              }
             
            }
            
            if(item.name=='Localize' && this.state.streetEasy_approval==false){
              flag=1;
            if(check){
              if(this.state.streeteasy_status==1){
                  this.setState({streeteasy_status:0})
              }
            }
            else{
              if(this.state.streeteasy_status==1){
                this.setState({streeteasy_status:1})
            }
            else{
              this.setState({streeteasy_status:1})
            }
            }
          }
        })
          if(count==len && flag==0){
            this.setState({
              status:1
            })
          }

      }
    );
  };


  handleCheck = () => {

    this.setState({ noFee: !this.state.noFee });
  }
  handleOwnerPays = () => {
    this.setState({ OwnerPaysPlusConcessionsYN: !this.state.OwnerPaysPlusConcessionsYN });

  }

  handleCheckBox = (e, check) => {
    let checkedBoxes = [...this.state.checkedBoxes];
    if (e.target.checked) {
      checkedBoxes.push(check)
    } else {
      let index = checkedBoxes.findIndex((ch) => ch.id === check.id);
      checkedBoxes.splice(index, 1);
    }
    this.setState({ checkedBoxes });
  }

  handleMarketCheckBox = (e, check) => {

    let marketBoxes = [...this.state.marketBoxes];
    if (e.target.checked) {
      marketBoxes.push(check)
    
      if (check.name == 'RLS') {
        this.setState({
          RLSFlag: 1
        }, () => {
          if (this.state.VOWEntireListingDisplay == false) {
            this.setState({
              VOWEntireListingDisplay: !this.state.VOWEntireListingDisplay,

            })
          } if (this.state.VOWAddressDisplay == false) {
            this.setState({
              VOWAddressDisplay: !this.state.VOWAddressDisplay
            })
          }
          if (this.state.VOWConsumerComment == false) {
            this.setState({
              VOWConsumerComment: !this.state.VOWConsumerComment
            })
          }
          if (this.state.VOWAutomatedValuationDisplay == false) {
            this.setState({
              VOWAutomatedValuationDisplay: !this.state.VOWAutomatedValuationDisplay
            })
          }
          if (this.state.NewDevelopment == false) {
            this.setState({
              NewDevelopment: !this.state.NewDevelopment
            })
          }
          if (this.state.IDXEntireListingDisplay == false) {
            this.setState({
              IDXEntireListingDisplay: !this.state.IDXEntireListingDisplay
            })
          }
        })
      }
      if(check.name == 'Renthop'){
      
        this.setState({
          rent_hop_export: 1
        })
    }

    } else {
     
      let index = marketBoxes.findIndex((ch) => ch.id === check.id);
      marketBoxes.splice(index, 1);
      if (check.name == 'RLS') {
        this.setState({
          RLSFlag: 0
        }, () => {
          if (this.state.VOWEntireListingDisplay == true) {
            this.setState({
              VOWEntireListingDisplay: !this.state.VOWEntireListingDisplay,

            })
          } if (this.state.VOWAddressDisplay == true) {
            this.setState({
              VOWAddressDisplay: !this.state.VOWAddressDisplay
            })
          }
          if (this.state.VOWConsumerComment == true) {
            this.setState({
              VOWConsumerComment: !this.state.VOWConsumerComment
            })
          }
          if (this.state.VOWAutomatedValuationDisplay == true) {
            this.setState({
              VOWAutomatedValuationDisplay: !this.state.VOWAutomatedValuationDisplay
            })
          }
          if (this.state.NewDevelopment == true) {
            this.setState({
              NewDevelopment: !this.state.NewDevelopment
            })
          }
          if (this.state.IDXEntireListingDisplay == true) {
            this.setState({
              IDXEntireListingDisplay: !this.state.IDXEntireListingDisplay
            })
          }
        })
      }
      if(check.name == 'Renthop'){
      
        this.setState({
          rent_hop_export: 0
        })
     }
    }
    
    this.setState({ marketBoxes });
  }
  layoutChange = e => {
    if (e.target.value.indexOf("1 Bedroom") >= 0) {
      this.setState({ total_rooms: '3' });
    }
    else if (e.target.value.indexOf("2 Bedroom") >= 0) {
      this.setState({ total_rooms: '4' });
    }
    else if (e.target.value.indexOf("3 Bedroom") >= 0) {
      this.setState({ total_rooms: '5' });
    }
    else if (e.target.value.indexOf("4 Bedroom") >= 0) {
      this.setState({ total_rooms: '6' });
    }
    else if (e.target.value.indexOf("Studio") >= 0) {
      this.setState({ total_rooms: '2' });
    }
    else {
      this.setState({ total_rooms: '' });
    }
  }
  handleChange = e => {


    const id = e.target.id;
    const value = e.target.value;
    const dataset = e.currentTarget.dataset.id;
    const name = e.target.name;
    const check=e.target.checked;
    console.log("id", id);
    console.log("value", value);
    console.log('id : ', id);
    console.log('value : ', value);
    //   console.log('dataset : ', dataset);
    if (id === "owner_pays_broker") {
      this.setState({
        owner_pays_broker: value
      })
    }
    if (
      id === "building_address" ||
      id === "building_name" ||
      id === "landlord_name" ||
      id === "landlord_email" ||
      id === "landlord_phone"
    ) {
      var error = id + "_error";
      if (value == "") {
        // console.log("_error : ", error);
        this.setState({
          [error]: "Field cannot be empty",
          [id]: [value]
        });
      } else if (id == "landlord_phone") {
        const phoneValue = this.formValidate("landlord_phone", value);
        this.setState(
          {
            [id]: phoneValue
          },
          () => {
            // console.log("landlord_phone : ", this.state.landlord_phone);
          }
        );
      } else {
        this.setState({
          [error]: "",
          [id]: [value]
        });
      }
    }

    if (
      id == "required_additional_documents" ||
      id == "default_application_template" ||
      id == "screenings_required"
    ) {
      var error = id + "_error";
      if (value == "" || value == 0) {
        // console.log("_error : ", error);
        this.setState(
          {
            [error]: "Field cannot be empty",
            [id]: [value]
          },
          () => {
            // console.log("dropdown : ", value);
          }
        );
      } else {
        this.setState(
          {
            [error]: "",
            [id]: [value]
          },
          () => {
            // console.log("dropdown : ", value);
          }
        );
      }
    }

    if (
      id == "Elevator" ||
      id == "Doorman" ||
      id == "Laundry" ||
      id == "Gym" ||
      id == "Outdoor areas" ||
      id == "Live-in super" ||
      id == "Heat & Hot Water included"
    ) {
      this.multiselectCheckbox("building_amenities", value, name,check);
    }

    if (
      id == "Exclusive" ||
      id == "Open Listing +SE" ||
      id == "Open Listing" ||
      id == "Address"
    ) {
      if (id != "Address") {
        this.setState({
          listing_information: dataset
        });
      } else {
        this.setState({
          listing_info_address: value
        });
      }
    }

    if (id == "bedrooms" || id == "bathrooms" || id == "date_available" || id == "price" || id == "min_lease_term") {
      var error = id + "_error";
      if (value == "") {

        this.setState({
          [error]: "Field cannot be empty",
          [id]: [value]
        });
      }
      if (isNaN(value) || value < -1) {

        this.setState({
          [error]: "Please enter numbers only",
          [id]: [value]
        });
      }
      else {
        this.setState({
          [error]: "",
          [id]: [value]
        });
      }
    }
    if (id == "date_available" || id == "unit_number") {
      var error = id + "_error";
      if (value == "") {

        this.setState({
          [error]: "Field cannot be empty",
          [id]: [value]
        });
      } else {
        this.setState({
          [error]: "",
          [id]: [value]
        });
      }
    }

    if (id == "square_footage") {
      var error = id + "_error";
      if ((isNaN(value) || value < 0) && value != "") {
        this.setState({
          [error]: "Please enter numbers only",
          [id]: [value]
        });
      }
      else {
        this.setState({
          [error]: "",
          [id]: [value]
        });
      }
    }
    if (id == "description") {
      this.setState({
        [id]: [value]
      });
    }

    if (id == "Skyward" || id == "Nestio" || id == "Localize" || id == "RLS" || id == "Renthop") {
      this.multiselectCheckbox("marketing", value, name,check);
    }
 if(id=="buildingType"){
   this.setState({buildingTypeName:value})
 }
    if (
      id == "Cats allowed" ||
      id == "Dogs allowed" ||
      id == "Outdoor space" ||
      id == "Duplex" ||
      id == "Hardwood Floors" ||
      id == "Renovated bathroom" ||
      id == "Renovated Kitchen" ||
      id == "Dishwasher" ||
      id == "W/D in unit" ||
      id == "Furnished/D" ||
      id == "Virtual Doorman"
    ) {
      this.multiselectCheckbox("unit_amenities", value, name,check);
    }

    if (
      id == "CYOF" ||
      // id == "Owner pays" ||
      id == "Co-broke" ||
      id == "tenant_incentive" ||
      id == "noFee" || id == "OwnerPaysPlusConcessionsYN"
    ) {
      if (id == "tenant_incentive") {
        this.setState({
          tenant_incentive: value
        });
      } else {
        if (id == "CYOF") {
          if (this.state.incentives == "CYOF") {
            this.setState({
              incentives: '',
              commission_percentage: '',
              my_split: '',
              co_broker_split: '',
              final_precentage: ''

            }, () => {

              // this.setState({incentives: dataset})
            });
          }
          else {
            this.setState({
              incentives: dataset,
              commission_percentage: '',
              my_split: 0,
              co_broker_split: 100,
              final_precentage: ''
            });
          }

        } else {
          if (id == "Co-broke") {
            if (this.state.incentives == "Co-broke") {
              this.setState({
                incentives: '',

              }, () => {
                // this.setState({incentives: dataset})
              });
            }
            else {
              this.setState({
                incentives: dataset
              });
            }

          }
          else {
            this.setState({
              incentives: dataset
            });
          }

        }
      }
      if (id == "noFee") {
        if (this.state.noFee == 0) {
          this.setState({
            noFee: 1
          });
        } else {
          this.setState({
            noFee: 0
          });
        }
      }
      if (id == "OwnerPaysPlusConcessionsYN") {
        if (this.state.OwnerPaysPlusConcessionsYN == 0) {
          this.setState({
            OwnerPaysPlusConcessionsYN: 1
          });
        } else {
          this.setState({
            OwnerPaysPlusConcessionsYN: 0
          });
        }
      }
      // if(id=="Ower pays"){
      //   this.setState({
      //     owner_pay: ''
      //   });
      // }
    }

    if (id == "kuula_vr_link") {
      this.setState(
        {
          [id]: value
        },
        () => {
          // console.log("kuula_vr_link : ", this.state.kuula_vr_link);
        }
      );
    }

    if (id == "pets") {
      if (document.getElementById("pets").value == "") {
        this.setState(
          {
            pets_policy_error: "field cannot be empty",
            pets_policy: ''
          });
      }
      else {
        this.setState(
          {
            pets_policy_error: "",
            pets_policy: document.getElementById("pets").value
          });
      }

    }
    if (id == "video_link") {
      this.setState(
        {
          [id]: value,
          video_link_error:''
        });
    }

    if (id == "CoBrokeAgreement") {
      if (document.getElementById("CoBrokeAgreement").value == "") {
        this.setState(
          {
            CoBrokeAgreement_error: "field cannot be empty",
            CoBrokeAgreement: ''
          });
      }
      else {
        this.setState(
          {
            CoBrokeAgreement_error: "",
            CoBrokeAgreement: document.getElementById("CoBrokeAgreement").value
          });
      }

    }
  };

  exposures = () => {

  }


  onVrHandleChange=(event)=>{
    console.log('=>',event.target.id,event.target.value);
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;

    if(id==='kuula_vr_link'){
      var error = id + "_error";
      if(value==''){
        this.setState({
          [error]: "Please enter the valid VR Link",
          [id]: [value],
          validation:false
        });
      }
      else{ 
        console.log("=>",'else');

        if((value.indexOf('https://tours.moveskyward.com/') >=0 || value.indexOf('https://view.ricohtours.com/') >=0 || value.indexOf('https://kuula.co/') >=0 || value.indexOf('https://my.matterport.com') >=0 || value.indexOf('https://skyward360tours.com') >=0 ) || value==''){
          console.log('found');
          this.setState({
            validation:true
          });
        }
        
    else{
      console.log('not found');
    
      this.setState({
        [error]: "Please enter valid url",
        [id]: [value],
        validation:false
      });
   
     }
    }
    }

    //video_link
    if(id==='video_link'){
      var error = id + "_error";
      if(value==''){
        this.setState({
          [error]: "Please enter the valid Video Link",
          [id]: [value],
          validation:false
        });
      }
      else{ 
        

        if((value.indexOf('youtube') >=0 || value.indexOf('vimeo') >=0 )  || value==''){
          console.log('video link found');
          this.setState({
            validation:true
          });
        }
        
    else{
    
      this.setState({
        [error]: "Please enter valid url",
        [id]: [value],
        validation:false
      });
  
    }
    }
    }
  }
    onVrHandle=(event)=>{
      console.log('=>',event.target.id,event.target.value);
      const id = event.target.id;
      const value = event.target.value;

      
      if(id==='kuula_vr_link'){
        var error = id + "_error";
        this.setState({
          [error]: "",
          [id]: [value],
          validation:true
        });
       
      }
      if(id==='video_link'){
        var error = id + "_error";
        this.setState({
          [error]: "",
          [id]: [value],
          validation:true
        });
       
      }
      }


  ValidURL=(str)=> {
  
    str=String(str);
     
    if((str.indexOf('https://tours.moveskyward.com/') >=0 || str.indexOf('https://view.ricohtours.com/') >=0 || str.indexOf('https://kuula.co/') >=0 || str.indexOf('https://my.matterport.com') >=0 || str.indexOf('https://skyward360tours.com') >=0 ) || str=='' || str=='null'){
      this.ValidVideoLink(this.state.video_link);
      console.log('found');
      return true;
    }else{
    console.log('not found');
    return false;
    
    }
    // var regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    // if(!regex .test(str)) {
    //   return false;
    // } else {
    //   return true;
    // }
  
  }

  ValidVideoLink=(str)=> {
    
    str=String(str);
  console.log("Video URL==",str);
    if((str.indexOf('youtube') >=0 || str.indexOf('vimeo') >=0 ) || str.trim()=='' || str.trim()=='null' || str.trim()==null){
      console.log("Video URL=====",str);
         return true;
    }else{
    return false;
    
    }

  }


  handleChangeStatus = checked => {
    console.log("checked", checked);
    if (checked) {
      this.setState({ furnished: 1 });
    } else {
      this.setState({ furnished: 0, FurnishedListPrice: "", FurnishedMaxLeaseMonths: "", FurnishedMinLeaseMonths: "" });
    }
  };




  closeBlobImage = image => {
 
    const { imagePathArray } = this.state;

    var index = imagePathArray.indexOf(image);
    let alteredImagePathArray = [...this.state.imagePathArray];
    let alteredImageForDb = [...this.state.imageForDb];
    alteredImagePathArray.splice(index, 1);
    alteredImageForDb.splice(index, 1);
    this.setState({imageCount:this.state.imageCount-1})
    this.setState({ imagePathArray: alteredImagePathArray, imageForDb: alteredImageForDb }, () => {
    });
  };

  closeBlobfloorImage = image => {
    const { imagePathfloorArray } = this.state;
    var index = imagePathfloorArray.indexOf(image);
    let alteredImagePathArray = [...this.state.imagePathfloorArray];
    let alteredImageForDb = [...this.state.imageForfloorDb];
    // console.log('before alteredImageForDb : ', alteredImageForDb);
    alteredImagePathArray.splice(index, 1);
    alteredImageForDb.splice(index, 1);
    // console.log('after alteredImageForDb : ', alteredImageForDb);
    this.setState({ imagePathfloorArray: alteredImagePathArray, imageForfloorDb: alteredImageForDb }, () => {
      console.log('ImageForfloorDb after : ', this.state.imageForfloorDb);
    });
  };

  closeBlobDocumentImage = image => {
    const { imagePathDocumentArray } = this.state;
    var index = imagePathDocumentArray.indexOf(image);
    let alteredImagePathArray = [...this.state.imagePathDocumentArray];
    let alteredImageForDb = [...this.state.imageFordocumentDb];
    alteredImagePathArray.splice(index, 1);
    alteredImageForDb.splice(index, 1);
    this.setState({ imagePathDocumentArray: alteredImagePathArray, imageFordocumentDb: alteredImageForDb }, () => {

    });
  };

  removeImage = (imageId,imagetype) => {
    this.state.imageIdForDb.push(imageId);
    this.setState({ imageIdFromDb: this.state.imageIdForDb })
    document.getElementById(imageId).style.display = 'none';
    if(imagetype!="floor_plan"){
      this.setState({imageCount:this.state.imageCount-1})  
    }
    

  };

  formValidate(key, value) {
    switch (key) {
      case "landlord_phone":
        return this.validateEmpPhoneNumber(value);
        break;
    }
  }
  validateEmpPhoneNumber(value) {
    let res = validatePhone(value);
    if (res.status == false) {
      this.setState({ landlord_phone_error: "Phone number is not valid." });
      document.getElementById("landlord_phone").value = res.value;
      return res.value;
    } else {
      this.setState({ landlord_phone_error: "" });
      document.getElementById("landlord_phone").value = res.value;
      return res.value;
    }
  }

  validation = () => {

    const {

      listing_info_address,
      listing_info_address_org,
      unit_number,
      bedrooms,
      bathrooms,
      price,
      date_available,
      min_lease_term,
      imageCount,
      isImage,
      total_rooms,
      description,
      buildingType,
      NumberOfUnitsTotal,
      LegalRoomsTotal,
      StoriesTotal,
      TaxBlock,
      TaxLot,
      LotWidth,
      building_ownership,
      LeaseTerm,
      MaxLeaseMonths,
      kuula_vr_link,
      FurnishedListPrice,
      FurnishedMinLeaseMonths,
      FurnishedMaxLeaseMonths,
      furnished,
      FreeRentRemarks,
      BonusRemarks,
      pets_policy,
      Exclusive_expiration_date,
      commission_percentage,
      my_split,
      ShowingInstructions,
      CoBrokeAgreement,
      Bonus,
      FreeRent,
      building_id,
      video_link
    } = this.state;

    if (imageCount>0 && isImage) {
      return (

        listing_info_address.toString().trim() != "" &&
        unit_number.toString().trim() != "" &&
        bedrooms.toString().trim() != "" &&
        bathrooms.toString().trim() != "" &&
        price.toString().trim() != "" &&
        date_available.toString().trim() != "" &&
        min_lease_term.toString().trim() != "" &&
        !isNaN(bedrooms) &&
        bedrooms > -1 &&
        !isNaN(bathrooms) &&
        bathrooms > -1 &&
        !isNaN(price) &&
        price > 0 &&
        imageCount>2&&
        !isNaN(total_rooms) &&
        total_rooms > -1 &&
        total_rooms != null &&
        description.toString().trim() != "" &&
        pets_policy != "" &&
        LeaseTerm != "" &&
        building_id>0 &&
        this.ValidURL(this.state.kuula_vr_link) &&
        this.ValidVideoLink(video_link) &&
        listing_info_address_org ==listing_info_address &&
        building_ownership != "" && building_ownership != "None" &&
        (((this.state.listing_information == "Exclusive" || this.state.listing_information == true) && Exclusive_expiration_date != '') ? true : (this.state.listing_information == "Open Listing" || this.state.listing_information == "null") ? 'true' : '') 
        && (((this.state.Bonus == true && BonusRemarks!="") || (this.state.Bonus == false) ) ? true : false) &&
     (((this.state.FreeRent== true && FreeRentRemarks!="") || (this.state.FreeRent== false)) ?  true  : false) &&
        (this.state.RLSFlag == 1 ? (MaxLeaseMonths != "" && MaxLeaseMonths > 0 && TaxLot != "" && TaxBlock != "" && StoriesTotal != "" && StoriesTotal > -1 && ShowingInstructions != "" && CoBrokeAgreement != "" && NumberOfUnitsTotal != "") : true) &&
        (this.state.RLSFlag == 1 ? this.state.incentives == "Co-broke" ? commission_percentage != "" && my_split != "" : true : true) &&
        (this.state.RLSFlag == 1 ? this.state.incentives !== "" : true) &&
        (this.state.RLSFlag == 1 ? this.state.buildingTypeName !== "" : true) &&
        (this.props.userType == 'Manager' ? (this.state.primary_agent_id != '' || this.state.primary_agent_id === 0) : true) &&
        this.state.primary_agent_error == '' &&
        this.state.secondary_agent_error == '' &&
        this.state.third_agent_error == '' &&
        
        ((this.state.primary_agent_id != '' || this.state.primary_agent_id === 0) && (this.state.secondary_agent_id != '' || this.state.secondary_agent_id === 0) ? (this.state.primary_agent_id != this.state.secondary_agent_id) : true) &&
        ((this.state.primary_agent_id != '' || this.state.primary_agent_id === 0) && (this.state.third_agent_id != '' || this.state.third_agent_id === 0) ? (this.state.primary_agent_id != this.state.third_agent_id) : true) 
      //  (this.state.secondary_agent_id != '' && this.state.third_agent_id != '' ? this.state.secondary_agent_id != this.state.third_agent_id : true) &&
      //  (this.state.secondary_agent_id != null && this.state.third_agent_id != null ? this.state.secondary_agent_id != this.state.third_agent_id : true)



      );
    }
    else {

      return (

        listing_info_address.toString().trim() != "" &&
        unit_number.toString().trim() != "" &&
        bedrooms.toString().trim() != "" &&
        bathrooms.toString().trim() != "" &&
        price.toString().trim() != "" &&
        date_available.toString().trim() != "" &&
        min_lease_term.toString().trim() != "" &&
        !isNaN(bedrooms) &&
        bedrooms > -1 &&
        !isNaN(bathrooms) &&
        bathrooms > 0 &&
        !isNaN(price) &&
        price > 0 &&
        !isNaN(total_rooms) &&
        total_rooms > -1 &&
        total_rooms != null &&
        description.toString().trim() != "" &&
        pets_policy != "" &&
        LeaseTerm != "" &&
        building_id>0 &&
        this.ValidURL(this.state.kuula_vr_link) &&
        this.ValidVideoLink(video_link) &&
        listing_info_address_org ==listing_info_address &&
        building_ownership != "" && building_ownership != "None" &&
        (((this.state.listing_information == "Exclusive" || this.state.listing_information == true) && Exclusive_expiration_date != '') ? true : (this.state.listing_information == "Open Listing" || this.state.listing_information == "null") ? 'true' : '') 
        && (((this.state.Bonus == true && BonusRemarks!="") || (this.state.Bonus == false) ) ? true : false) &&
        (((this.state.FreeRent== true && FreeRentRemarks!="") || (this.state.FreeRent== false)) ?  true  : false) &&
           (this.state.RLSFlag == 1 ? (MaxLeaseMonths != "" && MaxLeaseMonths > 0 && TaxLot != "" && TaxBlock != "" && StoriesTotal != "" && StoriesTotal > -1 && ShowingInstructions != "" && CoBrokeAgreement != "" && NumberOfUnitsTotal != "") : true) &&
        (this.state.RLSFlag == 1 ? this.state.incentives == "Co-broke" ? commission_percentage != "" && my_split != "" : true : true) &&
        (this.state.RLSFlag == 1 ? this.state.incentives !== "" : true) &&
        (this.state.RLSFlag == 1 ? this.state.buildingTypeName !== "" : true) &&
        (this.props.userType == 'Manager' ? (this.state.primary_agent_id != '' || this.state.primary_agent_id === 0) : true) &&
        this.state.primary_agent_error == '' &&
        this.state.secondary_agent_error == '' &&
        this.state.third_agent_error == '' &&
        ((this.state.primary_agent_id != '' || this.state.primary_agent_id === 0) && (this.state.secondary_agent_id != '' || this.state.secondary_agent_id === 0) ? (this.state.primary_agent_id != this.state.secondary_agent_id) : true) &&
        ((this.state.primary_agent_id != '' || this.state.primary_agent_id === 0) && (this.state.third_agent_id != '' || this.state.third_agent_id === 0) ? (this.state.primary_agent_id != this.state.third_agent_id) : true) 
       // (this.state.secondary_agent_id != '' && this.state.third_agent_id != '' ? this.state.secondary_agent_id != this.state.third_agent_id : true)  || (this.state.secondary_agent_id != null && this.state.third_agent_id != null ? this.state.secondary_agent_id != this.state.third_agent_id : true)
        

      );
    }
  };

  onHandleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;

    if (id == "Condo" || id == "Co-op" || id == "Condop" || id == "Single Family" || id == "Multifamily") {
      if (id != "Single Family") {
        this.setState({
          building_ownership: dataset,
          LegalRoomsTotal: "",
          LotWidth: ""

        })
      } else {
        this.setState({
          building_ownership: dataset
        })
      }
    }


    if (id == 'total_rooms') {
      if (value == '') {
        this.setState({
          total_rooms_error: "field cannot be empty",
          total_rooms: [value]
        })
      } else if (isNaN(value)) {
        this.setState({
          total_rooms_error: "enter only numbers",
          total_rooms: [value]
        })
      }
      else {
        this.setState({
          total_rooms_error: '',
          total_rooms: [value]
        })
      }
    }
    else {
      this.setState({
        [id]: [value]
      });
    }
  }

  onHandleCheckbox = (event) => {
    this.setState({
      exposures: event.currentTarget.value
    })
  }
  handleChangeExpirationDate = (expirationDate) => {
    if (expirationDate == "" || expirationDate == null) {

      this.setState({
        Exclusive_expiration_Date: '',
        Exclusive_expiration_Date_error: 'field cannot be empty'

      });
    }
    else {
      this.setState({
        Exclusive_expiration_date: Moment(expirationDate).format('MMMM DD, YYYY'),
        Exclusive_expiration_Date_error: ''
      });
    }
  }


  checkboxHandler = (PreWarYN) => {
    this.setState({
      PreWarYN: !this.state.PreWarYN
    })
  }
  checkboxLobbyHandler = (LobbyAttendant) => {
    this.setState({
      LobbyAttendant: !this.state.LobbyAttendant
    })
  }

  onHandler = (event) => {
    if (event.target.id == "Year_Built") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }

    if (event.target.id == "NumberOfUnitsTotal") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value,
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }

    if (event.target.id == "LegalRoomsTotal") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }
    if (event.target.id == "StoriesTotal") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }
    if (event.target.id == "TaxBlock") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    } if (event.target.id == "TaxLot") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }
    if (event.target.id == "LotWidth") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }

  }

  onCoBrokehandler = (event) => {
    if (event.target.id == "ShowingInstructions") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "Field should not be blank.",
          [event.target.id]: event.target.value
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }
    if (event.target.id == "BonusRemarks") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }
    if (event.target.id == "FreeRentRemarks") {
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }

    if (event.target.id == "FurnishedListPrice") {
      console.log('rrres', event.target.id);
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }
    if (event.target.id == "FurnishedMinLeaseMonths") {
      console.log('rrres', event.target.id);
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }
    if (event.target.id == "FurnishedMaxLeaseMonths") {
      console.log('rrres', event.target.id);
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }
    if (event.target.id == "LeaseTerm") {
      console.log('rrres', event.target.id);
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      }
      // else if(isNaN(event.target.value)){
      //   this.setState({
      //     [event.target.id]:event.target.value,
      //     [event.target.id+"_error"]:"Enter only numbers."
      //   })
      // }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }
    if (event.target.id == "MaxLeaseMonths") {
      console.log('rrres', event.target.id);
      if (event.target.value == "") {
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id + "_error"]: "field can not be empty.",
          [event.target.id]: event.target.value
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: "Enter only numbers."
        })
      }
      else {
        this.setState({
          [event.target.id]: event.target.value,
          [event.target.id + "_error"]: ""
        })
      }
    }

  }


  oncheckBoxHandler = (event) => {
    if (event.target.id == "VOWEntireListingDisplay") {
      this.setState({
        VOWEntireListingDisplay: !this.state.VOWEntireListingDisplay
      })
    } if (event.target.id == "VOWAddressDisplay") {
      this.setState({
        VOWAddressDisplay: !this.state.VOWAddressDisplay
      })
    }
    if (event.target.id == "VOWConsumerComment") {
      this.setState({
        VOWConsumerComment: !this.state.VOWConsumerComment
      })
    }
    if (event.target.id == "VOWAutomatedValuationDisplay") {
      this.setState({
        VOWAutomatedValuationDisplay: !this.state.VOWAutomatedValuationDisplay
      })
    }
    if (event.target.id == "NewDevelopment") {
      this.setState({
        NewDevelopment: !this.state.NewDevelopment
      })
    }
    if (event.target.id == "IDXEntireListingDisplay") {
      this.setState({
        IDXEntireListingDisplay: !this.state.IDXEntireListingDisplay
      })
    }
    if (event.target.id == "Bonus") {
      if (!this.state.Bonus) {
        this.setState({
          Bonus: !this.state.Bonus,

        })
      } else {
        this.setState({
          Bonus: false,
          BonusRemarks: ""
        })
      }
    }
    if (event.target.id == "FreeRent") {
      if (!this.state.FreeRent) {
        this.setState({
          FreeRent: !this.state.FreeRent,
        })
      } else {
        this.setState({
          FreeRent: false,
          FreeRentRemarks: ""
        })
      }
    }
  }

  changeHandler = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;
    console.log('----dataset', dataset);
    console.log('id : ', id);
    console.log('value--- : ', value);
    console.log('dataset ----: ', dataset);
    const name = event.target.name;
    if (id === "Owner_pays") {
      if (this.state.owner_pays != "") {
        this.setState({
          owner_pays: '',
        })
      }
      else {
        this.setState({
          owner_pays: dataset,
        })
      }

    }

    if (id === "owner_amount") {
      if (value == "") {
        this.setState({
          owner_amount: ""
        })
      } else if (isNaN(value)) {
        this.setState({
          owner_amount: ""
        })
      }
      else {
        this.setState({
          owner_amount: value,
        })
      }
    }

  }

  onChangeHandler = (event) => {
    // commission_percentage,
    // my_split,
    // co_broke_split

    if (event.target.id === "co_broke_split") {
      this.setState({
        [event.target.id]: event.target.value,
        [event.target.id + "error"]: ""
      })
    }
    if (event.target.id === "commission_percentage") {
      if (event.target.value == "") {
        this.setState({
          [event.target.id]: "",
          [event.target.id + "error"]: "Field can not be empty."

        })
      }
      else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: "",
          [event.target.id + "error"]: "Enter only numbers."
        })
      } else {
        if (event.target.value <= 100) {
          var _commission = this.state.my_split * event.target.value;
          var _percentage = _commission / 100;

          var _cocommission = event.target.value * (100 - this.state.my_split);
          var _copercentage = _cocommission / 100;

          this.setState({
            [event.target.id]: event.target.value,
            final_precentage: _percentage,
            [event.target.id + "error"]: "",
            copercentage_broker: _copercentage
          })
        }
      }
    }
    if (event.target.id === "my_split") {
      if (event.target.value == "") {
        this.setState({
          [event.target.id]: event.target.value,
          co_broker_split: 100,
          final_precentage: 0,
          copercentage_broker: this.state.commission_percentage,
          [event.target.id + "error"]: "Field can not be empty."
        })
      } else if (isNaN(event.target.value)) {
        this.setState({
          [event.target.id]: "",
          [event.target.id + "error"]: "Enter only number"
        })
      }
      else {

        if (event.target.value <= 100) {
          var _co_broker_split = 100 - event.target.value
          var _commission = event.target.value * this.state.commission_percentage;
          var _percentage = _commission / 100;

          var _cocommission = this.state.commission_percentage * _co_broker_split;
          var _copercentage = _cocommission / 100;


          this.setState({
            [event.target.id]: event.target.value,
            co_broker_split: 100 - event.target.value,
            final_precentage: _percentage,
            [event.target.id + "error"]: "",
            copercentage_broker: _copercentage
          })


        }
      }
    }
  }

  OpenAdditionalHouse = () => event => {
    let count=0;
    let flag=0;
    var index=this.state.openHouseData.length;
    this.state.openHouseData.map((item,_index_)=>{
        console.log("item=>",item);
        if(item.repeat_type!='Never' && item.repeat_until=='specificDate' && (item.repeat_end_date=='' || item.repeat_end_date=='0000-00-00') ){
          this.setState(state => (this.state.openHouseData[_index_] = {
        date: item.date
        , start_time: item.start_time
        , end_time: item.end_time
        , repeat_type: 'Never'
        , selected_days: []
        , repeat_until: ''
        , repeat_end_date: ''
      }, state));

          flag=1;
        }
        
        count=count+1;
        
    })

    if(count==this.state.openHouseData.length && flag==1){
      alert('End date should not be blank');
    }else{
      this.setState(state => (
        this.state.openHouseData[index]={
          date: ''
          , start_time: ''
          , end_time: ''
          , repeat_type: 'Never'
          , selected_days: []
          , repeat_until: ''
          , repeat_end_date: ''
        },state))
    }

      
          // if(index>0){
          //   var repeat = this.state.openHouseData[index-1].repeat_type;
          //   var repeat_until = this.state.openHouseData[index-1].repeat_until;
          //   var endDate = this.state.openHouseData[index-1].repeat_end_date;
          //   if(repeat!='Never' && repeat_until=='specificDate' && endDate==''){
          //     alert('End date should not be blank');
          //     this.setState(state=>(this.state.openHouseData[index-1]={
          //       date: ''
          //       , start_time: ''
          //       , end_time: ''
          //       , repeat_type: 'Never'
          //       , selected_days: []
          //       , repeat_until: ''
          //       , repeat_end_date: ''
          //     },state)) ;
          //   }else{
          //     this.setState(state => (
          //       this.state.openHouseData[index]={
          //         date: ''
          //         , start_time: ''
          //         , end_time: ''
          //         , repeat_type: 'Never'
          //         , selected_days: []
          //         , repeat_until: ''
          //         , repeat_end_date: ''
          //       },state))
          //   }
          // }


  }

  preventKeystroke = (event) => {
    console.log("keycode---", event.keyCode);
    if (event.keyCode) {
      event.preventDefault();
    }
  }

  brokerCheckboxHandler = () => {
    this.setState({
      brokerOnly: !this.state.brokerOnly
    })
  }
  appointmentCheckboxHandler = (e) => {
    this.setState({
      appointmentOnly: !this.state.appointmentOnly
    })
  }
  statusChanged = () => event => {
    var status_id = ''
    var e = document.getElementById("listing_status");
    if (e) {
      status_id = e.options[e.selectedIndex].value;
    }

    this.setState({ selected_status: status_id });

  }

  closenewdiv = index => {

    this.setState(state => (this.state.openHouseData.splice(index, 1), state))

  };

  agentSlots = () => {
    let where = [{
      url: '/agent/getAgentSlot'
    }];
    postApiData(where, { 'agent_id': getAgentId() }, true).then(res => {
      console.log("---getAgentSlot", res.data[0]);
      this.setState({
        agentRentHopSlots: res.data[0].renthop_slot,
        agent_REBNYAgentID: res.data[0].REBNYAgentID
      })
    }).catch(error => {

    })
  }

  // --start agent auto select--

  async agentOpt() {
    let agentParam = [{
      url: "/agent/agentExclude?agent_id=" + getAgentId()
    }];
    getApiData(agentParam, true).then(res => {
      this.setState({ 'agentList': res.data, 'c_agentList': res.data, 'newShowing_agentList': res.data, 'primary_agentList': res.data });
    }).catch(error => {

    });
  }

  handleAgentSearchAssignNewShowing = event => {
    var selected_agent_name_newShowing = event.target.getAttribute('name')
    this.setState({
      selected_agent_name_newShowing: selected_agent_name_newShowing
    })
    var key = event.target.id;
    if (event.target.value == '') {
      this.setState({ secondary_agent_error: '', secondary_agent_id: '' })
      if (this.state.third_agent_id != '' && this.state.third_agent_id != '999999' && this.state.third_agent_id !=null) {
        this.setState({ secondary_agent_error: 'Please select Secondary Agent' })
      }
    }

    switch (key) {
      case 'newShowing_agent_search_id':
        this.setState({ searchParm: event.target.value, newShowing_agent_name: event.target.value, agenterror: '' }, () => {
          //if(this.state.searchParm.length>-1){
          var value = this.state.searchParm;
          console.log("value--", value);
          if (value != '') {

            var len = this.state.agentList.length;
            var count = 0;
            var vflag = 0;

            this.state.agentList.forEach(function (agent, index) {
              if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                agent.flag = 3;
                vflag = 1;
                count = count + 1;
              } else {
                agent.flag = 0;
                if (vflag != 1)
                  vflag = 0
                count = count + 1;
              }
            });
            if (len == count) {
              if (vflag == 0) {
                this.setState({ secondary_agent_error: 'This is not a Registered Agent' })
                document.getElementById('newShowing_agent_search_id_values').style.display = 'none';

              }
            }

          } else {

            document.getElementById('newShowing_agent_search_id_values').style.display = 'none';
            this.state.agentList.forEach(function (agent, index) {
              agent.flag = 0;
            });

          }
        })


        //}
        break;

      case 'searchkey_agent_assign_newShoiwng':
        var value = event.target.value;
        let $this = this;
        this.setState({ secondary_agent_error: '' })
        this.setState({ secondary_agent_id: event.target.value }, () => {
          if (this.state.third_agent_id != '' || this.state.third_agent_id === 0) {
            this.setState({ third_agent_error: '' })
          }
          if (this.state.secondary_agent_id === 0 && this.state.third_agent_id === 0) {
            this.setState({ third_agent_error: 'Please select different Agent' })
          }
          if (this.state.secondary_agent_id === this.state.third_agent_id) {
            this.setState({ secondary_agent_error: 'Please select different Agent' })
          }

          this.state.agentList.forEach(function (agent, index) {
            if (agent.id == value) {
              document.getElementById('newShowing_agent_search_id_values').style.display = "none";
              document.getElementById('newShowing_agent_search_id').value = agent.name;
              $this.setState({ newShowing_agent_name: agent.name });
            } else {
              agent.flag = 0;
            }
          });
          document.getElementById('newShowing_agent_search_id_values').style.display = "none";
        });
        break;

      case 'default':
        break;

    }

    //    agent_search_id_values
    document.getElementById('newShowing_agent_search_id_values').style.display = "block";

  }

  handleAgentSearchprimary = event => {
    var selected_agent_name_newShowing = event.target.getAttribute('name')
    this.setState({
      selected_agent_name_newShowing: selected_agent_name_newShowing
    })
    var key = event.target.id;
    if (event.target.value == '') {
      this.setState({ primary_agent_error: '', primary_agent_id: '' })
    }

    switch (key) {
      case 'primary_agentId':
        this.setState({ primarysearchParm: event.target.value, primary_agent_name: event.target.value, agenterror: '' }, () => {
          //if(this.state.searchParm.length>-1){
          var value = this.state.primarysearchParm;
          console.log("value--", value);
          if (value != '') {

            var len = this.state.agentList.length;
            var count = 0;
            var vflag = 0;

            this.state.agentList.forEach(function (agent, index) {
              if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                agent.flag = 3;
                vflag = 1;
                count = count + 1;
              } else {
                agent.flag = 0;
                if (vflag != 1)
                  vflag = 0
                count = count + 1;
              }
            });
            if (len == count) {
              if (vflag == 0) {
                this.setState({ primary_agent_error: 'This is not a Registered Agent' })
                document.getElementById('primary_agentId_values').style.display = 'none';

              }
            }

          } else {

            document.getElementById('primary_agentId_values').style.display = 'none';
            this.state.agentList.forEach(function (agent, index) {
              agent.flag = 0;
            });

          }
        })


        //}
        break;

      case 'searchkey_agent_assign_primary':
        var value = event.target.value;
        let $this = this;
        this.setState({ primary_agent_error: '' })
        this.setState({ primary_agent_id: event.target.value }, () => {
          this.state.agentList.forEach(function (agent, index) {

            if (agent.id == value) {
              document.getElementById('primary_agentId_values').style.display = "none";
              document.getElementById('primary_agentId').value = agent.name;

              $this.setState({ primary_agent_name: agent.name });
            } else {
              agent.flag = 0;
            }
          });
          document.getElementById('primary_agentId_values').style.display = "none";
        });
        break;
      case 'default':
        break;

    }

    //    agent_search_id_values

    document.getElementById('primary_agentId_values').style.display = "block";
  }

  handlethirdagentsearch = event => {
    var selected_agent_name_newShowing = event.target.getAttribute('name')
    this.setState({
      selected_agent_name_newShowing: selected_agent_name_newShowing
    })
    if (event.target.value == '') {
      this.setState({ third_agent_error: '', third_agent_id: '' }, () => {
        if (this.state.third_agent_id == '') {
          this.setState({ secondary_agent_error: '' })

        }

      })


    }



    var key = event.target.id;
    console.log("key--", key);
    switch (key) {
      case 'third_agentId':
        this.setState({ searchParm: event.target.value, third_agent_name: event.target.value, agenterror: '' }, () => {
          //if(this.state.searchParm.length>-1){
          var value = this.state.searchParm;

          if (value != '') {
            var len = this.state.agentList.length;
            var count = 0;
            var vflag = 0;
            this.state.agentList.forEach(function (agent, index) {
              if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                agent.flag = 3;
                vflag = 1;
                count = count + 1;

              } else {
                agent.flag = 0;

                if (vflag != 1)
                  vflag = 0
                count = count + 1;

              }
            });
            if (len == count) {
              if (vflag == 0) {
                this.setState({ third_agent_error: 'This is not a Registered Agent' })
                document.getElementById('third_agentId_values').style.display = 'none';

              }
            }

          } else {
            console.log("else--");
            document.getElementById('third_agentId_values').style.display = 'none';
            this.state.agentList.forEach(function (agent, index) {
              agent.flag = 0;
            });

          }
        })


        //}
        break;
      case 'third_agent_assign_newShoiwng':
        var value = event.target.value;
        let $this = this;
        this.setState({ third_agent_error: '' })
        this.setState({ third_agent_id: event.target.value }, () => {


          if ((this.state.secondary_agent_id === '' || this.state.secondary_agent_id !== 0) && this.state.secondary_agent_id === '99999') {
           
            this.setState({ third_agent_error: 'Please select Secondary Agent' })
          }

          if (this.state.secondary_agent_id === 0 && this.state.third_agent_id === 0) {
            this.setState({ third_agent_error: 'Please select different Agent' })
          }
          if (this.state.secondary_agent_id === this.state.third_agent_id) {
            this.setState({ third_agent_error: 'Please select different Agent' })
          }
          // if (this.state.secondary_agent_id === 0 && this.state.third_agent_id === 0) {
          //   this.setState({ third_agent_error: 'Please select Secondary Agent' })
          // }
          if (this.state.secondary_agent_id === '' && this.state.secondary_agent_id !== 0 && this.state.third_agent_id != '') {
           
            this.setState({ third_agent_error: 'Please select Secondary Agent' })
          }
          if (this.state.secondary_agent_id === ''  && this.state.third_agent_id !== '') {
            this.setState({ third_agent_error: 'Please select Secondary Agent' })
          }
          if (this.state.secondary_agent_id === 0 && this.state.third_agent_id === 0) {
            this.setState({ third_agent_error: 'Please select different Agent' })
          }

          this.state.agentList.forEach(function (agent, index) {

            if (agent.id == value) {
              document.getElementById('third_agentId_values').style.display = "none";
              document.getElementById('third_agentId').value = agent.name;
              $this.setState({ third_agent_name: agent.name });
            } else {
              agent.flag = 0;
            }
          });
          document.getElementById('third_agentId_values').style.display = "none";
        });
        break;
      case 'default':
        break;

    }

    //    agent_search_id_values
    document.getElementById('third_agentId_values').style.display = "block";
  }


  onSort = (sortedList, dropEvent) => {
    console.log("sortedList", sortedList);
    sortedListVar = sortedList;
    if (typeof sortedListVar != 'undefined' && sortedListVar instanceof Array) {
      console.log("in saveListOrder--", sortedListVar);
      console.log("length--", sortedListVar.length);
      let tempArr = [];
      if (sortedListVar.length > 0) {
        sortedListVar.map((item, i) => {
          if (!isNullOrUndefined(item.rank)) {
            tempArr[i] = item.content.props.children.props.children.props.id + '-' + item.rank;
            //tempRank[i]= item.rank;
            console.log('item.content.key', item.content.key)
          }
        })
        const formData = new FormData();
        formData.append('file', tempArr);
        let listingWhere = [{
          url: '/updateListImagesortorder'
        }];

        postApiData(listingWhere, formData, true).then(res=>{
         this.setState({imageCount:0})
        this.setState({image_list_value:[]
        },()=>{
          this.feeddataByIdForEdit();})
          
        }
        )
      }
    }
  }


  // view the document
  onOpenView = param => (e) => {
    this.setState({ listingData: param[0], openViewer: true });
  };

  onCloseView = () => {
    this.setState({ listingData: {}, openViewer: false }, () => {

    });
  };

  // Edit agent document
  onOpenEdit = param => (e) => {
    this.setState({
      listingsData: param[0],
      editOpen: true
    });
  };

  onCloseEdit = () => {
    this.setState({ editOpen: false, listingsData: {} }, () => {
      this.feeddataByIdForEdit();
    });
  };

  // delete the document

  deleteDocument(doc_id, file_path) {
    var r = window.confirm("Are you sure to delete the Uploaded document?");
    if (r == true) {
      this.setState({ linkingLoader: true });
      let where = [{
        url: "/deleteListingdocument"
      }];

      postApiData(where, { 'id': doc_id, 'file_path': file_path }, true).then(res_save => {
        console.log("res_save=>", res_save);
        if (res_save.data) {
          this.feeddataByIdForEdit();
          //call a function to get and update image
        }
        this.setState({ linkingLoader: false });
      })
    }
  }


  render() {
    var placeholder = (
      <div> DROP HERE ! </div>
    );
   
    if (this.state.building_id == false && this.state.listing_id == false && this.state.unit_no == false) {
      if (this.props.userType == 'Agent') {
        return (<Redirect to={{
          pathname: '/agent/list/edit',
        }} />);
      }
      if (this.props.userType == 'Manager') {
        return (<Redirect to={{
          pathname: '/managerlist/edit',
        }} />)
      }
    }
    const { userType, heading } = this.props;
    if (this.props.userType == 'Agent') {

    }
    if (this.state.RLSFlag == 1) {
      this.state.listing_information = "Exclusive";
      // this.state.incentives ="CYOF"
    }
    const {
      listing_information,
      listing_info_address,
      unit_number,
      bedrooms,
      bathrooms,
      square_footage,
      description,
      price,
      date_available,
      min_lease_term,
      incentives,
      tenant_incentive,
      imagePathArray,
      kuula_vr_link,
      amenityList,
      checkedBoxes,
      marketBoxes,
      list_images,
      imagePathfloorArray,
      imagePathDocumentArray,
      //error
      building_address_error,
      landlord_name_error,
      landlord_email_error,
      landlord_phone_error,
      unit_number_error,
      bedrooms_error,
      bathrooms_error,
      square_footage_error,
      price_error,
      date_available_error,
      min_lease_term_error,
      default_application_template_error,
      screenings_required_error, kuula_vr_link_error,
      data,
      linkingLoader,
      building_ownership,
      total_rooms,
      total_rooms_error,
      video_link,
      video_link_error
    } = this.state;
    const files = imagePathArray.map((image, index) => {
      for (let i = 0; i <= imagePathArray.length; i++) {
        return (

          <div className="col-sm-3">
            <div className="slider-frame" style={{ width: '100%' }}>

              <img src={image.file} style={{ transform: `rotate(${image.current_rotation}deg)` }} width="100%" />
              <div
                to
                className="close-thumb"
                onClick={this.closeBlobImage.bind(this, image)}
              >
                Close
          </div>
              <div className="rotate-wrap">
                <input onClick={this.rotateleft([image.file, index, image.current_rotation,])} type="button" value="" className="left-rotate" />
                <input onClick={this.rotate([image.file, index, image.current_rotation,])} type="button" value="" className="right-rotate" />
              </div>
            </div>
          </div>
        );
      }
    });

    const blobfloorImages = imagePathfloorArray.map((image, index) => {
      for (let i = 0; i <= imagePathfloorArray.length; i++) {
        return (

          <div className="col-sm-3">
            <div className="slider-frame" style={{ width: '100%' }}>

              <div className="add-img" style={{ background: 'url(' + image.file + ') center', backgroundSize: 'cover', transform: `rotate(${image.current_rotation}deg)` }} >
              </div>

              <div
                to
                className="close-thumb"
                onClick={this.closeBlobfloorImage.bind(this, image)}
              >
                Close
            </div>
              <div className="rotate-wrap">
                <input onClick={this.rotateleftfloor([image.file, index, image.current_rotation,])} type="button" value="" className="left-rotate" />
                <input onClick={this.rotatefloor([image.file, index, image.current_rotation,])} type="button" value="" className="right-rotate" />
              </div>
            </div>
          </div>
        );
      }

    });

    const blobdocumentImages = imagePathDocumentArray.map((image, index) => {
      console.log(this.state.documentFile_name)

      var regex = new RegExp("(.*?)\.(pdf)$");
      var pdfimage = false;
      if (regex.test(this.state.documentFile_name[index].value)) {
        pdfimage = true;

      }
      for (let i = 0; i <= imagePathDocumentArray.length; i++) {
        return (

          <div className="col-sm-3">
            <div className="slider-frame" style={{ width: '100%' }}>


              {pdfimage ? <div className="add-img" style={{ backgroundSize: 'cover' }}><i class="fa fa-file-pdf-o" aria-hidden="true">{this.state.documentFile_name[index].value}</i></div> : <div className="add-img" style={{ background: 'url(' + image.file + ') center', backgroundSize: 'cover', transform: `rotate(${image.current_rotation}deg)` }} >
              </div>}

              <div
                to
                className="close-thumb"
                onClick={this.closeBlobDocumentImage.bind(this, image)}
              >
                Close
                  </div>
              {/* <div className="rotate-wrap"> <input onClick={this.rotateleftdocument([image.file,index,image.current_rotation,])} type="button" value="" className="left-rotate"/>   <input onClick={this.rotatedocument([image.file,index,image.current_rotation,])} type="button" value="" className="right-rotate" />  </div> */}
            </div>
          </div>
        );
      }

    });


    var check;
    var image_list;

    if (this.state.list_images) {
      image_list = this.state.list_images.map((item, index) => {
        check = item.large_image.indexOf("https://");
        if (item.image_type != "floor_plan") {
          return <div className="col-sm-3" id={item.id} >
            <div className="slider-frame" style={{ width: '100%' }}  >

              <img src={check > -1 ? item.large_image : getBaseUrl() + "/containers/listing_images/download/" + item.large_image} style={{ transform: `rotate(${item.rotation}deg)` }} width="100%" />
              <div
                to
                className="close-thumb"
                onClick={this.removeImage.bind(this, item.id,item.image_type)}
              >
                Close
            </div>
              <div className="rotate-wrap">
                <input onClick={this.rotateleftimagelist([item.large_image, index, item.rotation,])} type="button" value="" className="left-rotate" />
                <input onClick={this.rotateimagelist([item.large_image, index, item.rotation,])} type="button" value="" className="right-rotate" />
              </div>
            </div>
          </div>
        }


      })

    }

    var image_list_floor;

    if (this.state.list_images) {
      image_list_floor = this.state.list_images.map((item, index) => {
        check = item.large_image.indexOf("https://");
        if (item.image_type != "photo") {
          return <div className="col-sm-3" id={item.id} >
            <div className="slider-frame" style={{ width: '100%' }}  >

              <img src={check > -1 ? item.large_image : getBaseUrl() + "/containers/listing_images/download/" + item.large_image} style={{ transform: `rotate(${item.rotation}deg)` }} width="100%" />
              <div
                to
                className="close-thumb"
                onClick={this.removeImage.bind(this, item.id,item.image_type)}
              >
                Close
              </div>
              <div className="rotate-wrap">
                <input onClick={this.rotateleftfloorimagelist([item.large_image, index, item.rotation,])} type="button" value="" className="left-rotate" />
                <input onClick={this.rotatefloorimagelist([item.large_image, index, item.rotation,])} type="button" value="" className="right-rotate" />
              </div>
            </div>
          </div>
        }


      })

    }

    let thumbnail;
    if (this.state.list_images) {
      thumbnail = this.state.image_list_value.map((item, i) => {
        var imagescheck;
        check = item.large_image.indexOf("https://");
        // if (item.image_type != "floor_plan") {
        //   imagescheck
        // }
        if (check == -1) {
          check = item.large_image.indexOf("http://");
        }
        return {
          content: (

            <React.Fragment key={item.id}>
            <tr style={{ cursor: 'all-scroll' }}>
              <div id={item.id}>
                <td>
                <img src={check > -1 ? item.large_image : getBaseUrl() + "/containers/listing_images/download/" + item.thumbnail_image} style={{ transform: `rotate(${item.rotation}deg)` }} width="80px" /></td>

                {/* <td> <input type="file" onChange={this.fileHandler([item.id,item.listing_id])} /></td> */}
                {/* <td><i class="fa fa-times" aria-hidden="true" onClick={()=>this.deleteImage(item.id)}></i></td> */}
              </div>
            </tr>
          </React.Fragment>
          )
        }
     // }

      })
    }
    let list_document;
    if (this.state.list_doc_image) {

      list_document = this.state.list_doc_image.map((item, index) => {
        check = item.file_name.indexOf("https://");

        return <div className="col-sm-3" id={item.id} >
          <div className="slider-frame" style={{ width: '100%' }}  >

            {item.file_name.includes('.pdf') ? <div className="add-img" style={{ backgroundSize: 'cover' }}><i class="fa fa-file-pdf-o" aria-hidden="true">{item.file_real_name}</i></div> : <img src={check > -1 ? item.file_name : getBaseUrl() + "/containers/listing_document/download/" + item.file_name} width="100%" />}
            {/* <div
                  to
                  className="close-thumb"
                  onClick={this.removeImage.bind(this, item.id)}
                >
                  Close
                </div> */}
            <div className="rotate-wrap">
              {/* <input onClick={this.rotateleftimagelist([item.large_image,index,item.rotation,])} type="button" value="" className="left-rotate" />
                <input onClick={this.rotateimagelist([item.large_image,index,item.rotation,])} type="button" value="" className="right-rotate" /> */}
              {item.file_name ? <td class="action-view text-center"><Link to onClick={this.onOpenView([item])}><i class="fa fa-eye"></i></Link>
                <a href={getBaseUrl() + "/containers/listing_document/download/" + item.file_name + "/true"} target="_blank" download><i class="fa fa-download"></i></a>
                {this.props.userType == "Manager" ? '' : <Link to onClick={() => this.deleteDocument(item.id, item.file_name)}><i class="fa fa-trash-o"></i></Link>}
                {this.props.userType == "Manager" ? '' : <Link to onClick={this.onOpenEdit([item])}><i class="fa fa-pencil-square-o"></i></Link>}
              </td>
                : ''}
            </div>
          </div>
        </div>



      })

    }

    //       let list_document;
    // if (this.state.list_doc_image) {
    // 	list_document = this.state.list_doc_image.map((s, i) =>
    // 		<tr>
    // 			<td>{i + 1}</td>
    // 			<td>{s.file_real_name}</td>
    // 			<td>{s.created_date ? Moment(s.created_date).format('L') : '-'}</td>

    // 			{s.file_name ? <td class="action-view text-center"><Link to onClick={this.onOpenView([s])}><i class="fa fa-eye"></i></Link>
    // 				<a href={getBaseUrl() + "/containers/listing_document/download/" + s.file_name + "/true"} target="_blank" download><i class="fa fa-download"></i></a>
    // 				<Link to><i class="fa fa-trash-o"></i></Link>
    // 			<Link to onClick={this.onOpenEdit([s])}><i class="fa fa-pencil-square-o"></i></Link>
    // 			</td>
    // 				: ''}


    // 		</tr>


    // 	);
    // }


    let document_name;
    let application_template;
    let screening_name;
    let Building_amenities;
    let Unit_amenities;
    let marketing;

    if (this.state.flag == 1) {
      document_name = data[0].map((value) => {
        return <option value={value.document_name}>{value.document_name}</option>
      })
      application_template = data[1].map((value) => {
        return <option value={value.application_template}>{value.application_template}</option>
      })
      screening_name = data[2].map((value) => {
        return <option value={value.screening_name}>{value.screening_name}</option>
      })

      Unit_amenities = data[3].map((value) => {
       
        if (value.type == 1) {
         
          var val = contains(this.state.amenityList, "name", value.amenity_name); //true
          if (val && this.state.existCheckboxSelection) {
            checkedBoxes.push(value);
            this.setState({ existCheckboxSelection: false });
            this.setState({ checkedBoxes });
          }
          return <div className="checkbox-inline">
            <input
              type="checkbox"
              id={value.amenity_name}
              value={value.amenity_name}
              defaultChecked={val}
              checked={checkedBoxes.find((ch) => ch.id === value.id)}
              onChange={(e) => this.handleCheckBox(e, value)}
            />
            <label htmlFor={value.amenity_name}>{value.amenity_name}</label>
          </div>
        }
      });

      marketing = data[4].map((value) => {
        
        var val = contains(this.state.amenityList, "name", value.name); //true
        if (val && this.state.existmarketCheckboxSelection) {
           if(value.name=='Renthop'){
             this.setState({rent_hop_export_initial:1})
            
           }
           
          marketBoxes.push(value);
          this.setState({ existmarketCheckboxSelection: false });
          this.setState({ marketBoxes });
        }
        
        if (value.name == "RLS") {
          //Agent can not reset the RLS checkbox
          if (this.state.agent_REBNYAgentID == null || this.state.agent_REBNYAgentID == undefined || this.state.agent_REBNYAgentID == "" || (this.props.userType=="Agent" && val)) {

            return <div className="checkbox-inline">
              <input
                type="checkbox"
                id={value.name}
                value={value.name}
                defaultChecked={getAgentDomain()=='kw.com'?'':val}
                checked={getAgentDomain()=='kw.com'?'':marketBoxes.find((ch) => ch.id === value.id)}
                onChange={(e) => this.handleMarketCheckBox(e, value)}
                disabled="true"
              />
              <label htmlFor={value.name}>{value.name}</label>
            </div>
          }
          else {
            return <div className="checkbox-inline">
              <input
                type="checkbox"
                id={value.name}
                value={value.name}
                defaultChecked={getAgentDomain()=='kw.com'?'':val}
                checked={getAgentDomain()=='kw.com'?'':marketBoxes.find((ch) => ch.id === value.id)}
                onChange={(e) => this.handleMarketCheckBox(e, value)}
              />
              <label htmlFor={value.name}>{value.name}</label>
            </div>
          }
        }
        else {
          return <div className="checkbox-inline">
            <input
              type="checkbox"
              id={value.name}
              value={value.name}
              defaultChecked={getAgentDomain()=='kw.com'?'':val}
              checked={getAgentDomain()=='kw.com'?'':marketBoxes.find((ch) => ch.id === value.id)}
              onChange={(e) => this.handleMarketCheckBox(e, value)}
              disabled={this.props.userType!="Manager"?(this.state.agentRentHopSlots == 0) ? value.name == "Renthop" : '':''}
            />
            <label htmlFor={value.name}>{value.name}</label>
          </div>
        }
      });
    }

    function contains(arr, key, val) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][key] === val) return 'Checked';
      }
      return false;
    }

    var dropdownData;
    if (this.state.layoutData) {
      dropdownData = this.state.layoutData.map((item, index) => {
        return <option value={item.layout} selected={this.state.layout === item.layout ? true : false} >{item.layout}</option>
      })
    }

    // Owner Pays DropDown
    var ownerpayData;
    if (this.state.owner_pays_data) {
      ownerpayData = this.state.owner_pays_data.map((item, index) => {
        return <option value={item.owner_pays_broker} >{item.owner_pays_broker}</option>
      })
    }
    // Owner Pays DropDown

    // Building type DropDown
    var buildingType;
    if (this.state.building_type) {
      buildingType = this.state.building_type.map(item => {
        return <option value={item.building_type}>{item.building_type}</option>
      })
    }
    // Building type DropDown

    // agent auto fill dropdown start
    const agentDWNewShowing = this.state.newShowing_agentList.map((baw, index) =>
      (baw.flag == 3) ? <li id="searchkey_agent_assign_newShoiwng" key={baw.id} value={baw.id} name={baw.name} onClick={this.handleAgentSearchAssignNewShowing}>{baw.name}</li> : ''
    );

    const primaryagentDWNewShowing = this.state.primary_agentList.map((baw, index) =>
      (baw.flag == 3) ? <li id="searchkey_agent_assign_primary" key={baw.id} value={baw.id} name={baw.name} onClick={this.handleAgentSearchprimary}>{baw.name}</li> : ''
    );

    const thirdagentDWNewShowing = this.state.newShowing_agentList.map((baw, index) =>
      (baw.flag == 3) ? <li id="third_agent_assign_newShoiwng" key={baw.id} value={baw.id} name={baw.name} onClick={this.handlethirdagentsearch}>{baw.name}</li> : ''
    );

    // --END--

    // Co-broke-agreement
    var co_broke_agreement;
    if (this.state.co_broke_agreement) {
      co_broke_agreement = this.state.co_broke_agreement.map(item => {
        return <option value={item.co_broke_Agreement}
          selected={(this.state.CoBrokeAgreement == item.co_broke_Agreement) ? 'selected' : ''}
        >{item.co_broke_Agreement}</option>
      })
    }
    // co-broke-agreement

    // Air Conditioning type
    var airconditiontype;
    if (this.state.air_conditioning_type_master) {
      airconditiontype = this.state.air_conditioning_type_master.map(item => {
        return <option value={item.air_conditioning_type}>{item.air_conditioning_type}</option>
      })
    }
    // Air Conditioning type

    console.log("---res", this.state.openHouseData);
    // Open Houses Block
    var newDiv;

    newDiv = this.state.openHouseData.map((item, index) => {
      return <div className="form-inline" >
        <div className="form-group">
          <label>Date </label>
          <DatePicker
            value={(this.state.openHouseData[index].date != null && this.state.openHouseData[index].date != undefined && this.state.openHouseData[index].date != '' && this.state.openHouseData[index].date != "0000-00-00") ? Moment(this.state.openHouseData[index].date).format('MMMM DD, YYYY') : ''}
            id={'date' + index}
            onChange={this.handleDateChange([index, '_date_'])}
            dateFormat="MMMM d, yyyy"
            timeCaption="time"
            className="form-control"
            onKeyDown={(e) => this.preventKeystroke(e)}
          />

        </div>
        <div className="form-group">
          <label>Start Time</label>
          <DatePicker
            id={'datePicker_time' + index}
            onChange={this.handleDateChange([index, '_start_time_'])}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="h:mm aa"
            timeCaption="time"
            autoComplete="off"
            className="form-control"
            onKeyDown={(e) => this.preventKeystroke(e)}
            value={(this.state.openHouseData[index].start_time != undefined && this.state.openHouseData[index].start_time != '' && this.state.openHouseData[index].start_time != '00:00:00') ? Moment(this.state.openHouseData[index].start_time, "hh:mm:ss").format("h:mm A") : ''}
          />
        </div>
        <div className="form-group">
          <label>End Time</label>
          <DatePicker
            id={'datePicker_time1' + index}
            onChange={this.handleDateChange([index, '_end_time_'])}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="h:mm aa"
            timeCaption="time"
            autoComplete="off"
            className="form-control"
            onKeyDown={(e) => this.preventKeystroke(e)}
            value={(this.state.openHouseData[index].end_time != undefined && this.state.openHouseData[index].end_time != '' && this.state.openHouseData[index].end_time != '00:00:00') ? Moment(this.state.openHouseData[index].end_time, "hh:mm:ss").format("h:mm A") : ''}
          />
        </div>
        <div className="form-group">
                                 <label>Repeat</label>
                                 <select id={'repeat'+index} className="form-control" style={{paddingLeft:'0', minWidth:'150px'}} onChange={this.handleDateChange([index,'_repeat_'])}>
                                    <option value="Never" selected={(this.state.openHouseData[index].repeat_type=='Never')?'selected':''}>Never</option>
                                    <option value="Daily" selected={(this.state.openHouseData[index].repeat_type=='Daily')?'selected':''}>Daily</option>
                                    <option value="Mon-Fri" selected={(this.state.openHouseData[index].repeat_type=='Mon-Fri')?'selected':''}>Mon-Fri</option>
                                    <option value="Sat-Sun" selected={(this.state.openHouseData[index].repeat_type=='Sat-Sun')?'selected':''}>Sat-Sun</option>
                                    <option value="selectedWeekDay" selected={(this.state.openHouseData[index].repeat_type=='selectedWeekDay')?'selected':''}>Select day of week</option>
                                 </select> 
                             </div> 
                             {(this.state.openHouseData[index].repeat_type=='selectedWeekDay')?<div className="form-group">
                                 <label>Repeat On</label>
                                 <ul id={'selectedWeekDay'+index} className="select-day">
                                   <li className={(this.state.openHouseData[index].selected_days.find(list => list === 'M'))?'active':''}   onClick={this.handleDateChange([index,'_selectedWeekDay_','M'])}>M</li>
                                   <li className={(this.state.openHouseData[index].selected_days.find(list => list === 'Tu'))?'active':''}  onClick={this.handleDateChange([index,'_selectedWeekDay_','Tu'])}>Tu</li>
                                   <li className={(this.state.openHouseData[index].selected_days.find(list => list === 'W'))?'active':''}   onClick={this.handleDateChange([index,'_selectedWeekDay_','W'])}>W</li>
                                   <li className={(this.state.openHouseData[index].selected_days.find(list => list === 'Th'))?'active':''}  onClick={this.handleDateChange([index,'_selectedWeekDay_','Th'])}>Th</li>
                                   <li className={(this.state.openHouseData[index].selected_days.find(list => list === 'F'))?'active':''}   onClick={this.handleDateChange([index,'_selectedWeekDay_','F'])}>F</li>
                                   <li className={(this.state.openHouseData[index].selected_days.find(list => list === 'Sa'))?'active':''}  onClick={this.handleDateChange([index,'_selectedWeekDay_','Sa'])}>Sa</li>
                                   <li className={(this.state.openHouseData[index].selected_days.find(list => list === 'Su'))?'active':''}  onClick={this.handleDateChange([index,'_selectedWeekDay_','Su'])}>Su</li>
                                 </ul>
                               
                             </div>:''}
                             {(this.state.openHouseData[index].repeat_type!='Never' && this.state.openHouseData[index].repeat_type!='')?<div className="form-group">
                                 <label>Repeat Until</label>
                                 <select id={'repeat_until'+index} className="form-control"  style={{paddingLeft:'0', minWidth:'150px'}} onChange={this.handleDateChange([index,'_repeat_until_'])}> 
                                    <option value="offMarket" selected={(this.state.openHouseData[index].repeat_until=='offMarket')?'selected':''}>Until off Market</option>   
                                    <option value="specificDate" selected={(this.state.openHouseData[index].repeat_until=='specificDate')?'selected':''}>Until Specific Date</option> 
                                 </select></div>:''}
                               
                              
                             {(this.state.openHouseData[index].repeat_until=='specificDate' && this.state.openHouseData[index].repeat_type!='Never')?<div className="form-group">
                                 <label>Repeat End Date</label>
                                 <DatePicker                               
                                  id={'repeat_end_date'+index}
                                  value={(this.state.openHouseData[index].repeat_end_date != null && this.state.openHouseData[index].repeat_end_date != undefined && this.state.openHouseData[index].repeat_end_date != '' && this.state.openHouseData[index].repeat_end_date != "0000-00-00") ? Moment(this.state.openHouseData[index].repeat_end_date).format('MMMM DD, YYYY') : null}
                                  dateFormat="MMMM d, yyyy"
                                  timeCaption="time"
                                  className="form-control"
                                  onKeyDown = {(e)=>this.preventKeystroke(e)}
                                  onChange={this.end_date([index,'_repeat_end_date_'])}
                              /></div>:''}
        <div
          to
          className="close-thumb form-group closeBtnCreator"
          onClick={this.closenewdiv.bind(this, index)}
        >
          <i class="fa fa-times"></i>
        </div>

      </div>

    })


    // Open Houses Block

    return (
      <div>
        {userType == 'Agent' ? <AgentHeader /> : <ManagerHeader />}
        <main>
          {userType == 'Agent' ? <AgentSideBar /> : <ManagerSidebar />}
          <div className="content">
            <div className="work-section">
              <div className="dashboard">
                <h2>{heading}</h2>
                {/* <Tabs>
								<TabList>
									<Tab onClick={()=>this.setTab()}>Rental</Tab>
									<Tab onClick={()=>this.setTab('Sales')}>SALES</Tab>
								</TabList>
						
								<TabPanel> */}
                {(this.state.linkingLoader)?<div style={{zIndex:'999999'}} className="show_loader_on_click" id="show_loader_on_click"></div>:''}
                <form onSubmit={this.handleSubmit}>
                  {(this.state.successQuote == 1) ? (<div className="alert alert-success"><strong>Form Successfully Submitted!</strong></div>) : (this.state.successQuote == 2) ? (<div className="alert alert-danger"><strong>Listing Already Exist, Please Try To Add Another Listing</strong></div>) : ''}
                  <div className="rental-application listing-creator-section">
                    <div className="listing-block">


                      {/* ........................................ */}
                      <div className="listing-block">
                        <h4 style={(this.state.listing_information == '') ? { color: 'red' } : { visibility: "visible" }}>Listing information*</h4>
                        <ul className="btn-inline">
                          <li
                            className="btn"
                            style={
                              listing_information == "Exclusive"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            onClick={this.handleChange}
                            id="Exclusive"
                            data-id="Exclusive"
                          >
                            Exclusive
                          </li>
                          {(this.state.RLSFlag) ? <li
                            className="btn disabled"
                            style={{ visibility: "visible" }}
                            id="Open Listing"
                            data-id="Open Listing"
                          >
                            Open Listing
                          </li> :
                            <li
                              className="btn"
                              style={
                                listing_information == "Open Listing" || listing_information == "null"
                                  ? { background: "#004F6B", color: "white" }
                                  : { visibility: "visible" }
                              }
                              onClick={this.handleChange}
                              id="Open Listing"
                              data-id="Open Listing"
                            >
                              Open Listing
                          </li>}
                          {(this.state.listing_information == "Exclusive" || this.state.listing_information != "" && this.state.listing_information != "Open Listing" && this.state.listing_information != "null") ?
                            <li>  <div className="form-group" style={{ width: '210px', textAlign: 'center', margin: '0px', position: 'relative', top: '10px' }}>
                              <label style={{ textAlign: 'center' }}>Exclusive expiration date*</label>
                              {/* <input type="text" className="form-control" /> */}
                              {/* selected={this.state.Exclusive_expiration_date} */}
                              <DatePicker

                                onChange={this.handleChangeExpirationDate}
                                dateFormat="MMMM d, yyyy"
                                value={this.state.Exclusive_expiration_date}
                                timeCaption="time"
                                className="form-control"
                                id="Exclusive_expiration"
                              />
                            </div>
                              <span className="inline-error-class">
                                {" "}
                                {this.state.Exclusive_expiration_Date_error !== ""
                                  ? this.state.Exclusive_expiration_Date_error
                                  : ""}
                              </span>
                            </li> : ""}
                        </ul>
                        <div className="row">
                          <div className="col-sm-5">
                            <label>Address*</label>
                            <div className="form-group">
                              <input type="text" className="form-control" id="Address" onChange={this.handleBuildingSearch} onKeyDownCapture={this.handleBuildingSearch} onKeyUpCapture={this.handleBuildingSearch} autoComplete="off" value={(this.state.listing_info_address != 0) ? this.state.listing_info_address : ''} style={(this.state.listing_info_address == '') ? { borderBottom: '2px solid red' } : { visibility: "visible" }} />
                              <div className="box agentNameOnBoard" id="building_search_id_values" style={{ display: 'none' }} >
                                <ul>{this.state.buildingDW}
                                </ul>
                              </div>
                              <span className="inline-error-class">
                                {" "}
                                {building_address_error !== ""
                                  ? building_address_error
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="form-inline">
                            <div className="form-group">
                              <label>Unit Number*</label>
                              <input
                                type="text"
                                className="form-control"
                                id="unit_number"
                                value={unit_number}
                                onChange={this.handleChange}
                                style={(this.state.unit_number == '') ? { borderBottom: '2px solid red' } : { visibility: "visible" }}
                              />
                              <small className="leftbedge">#</small>
                              <br />
                              <span className="inline-error-class">
                                {" "}
                                {unit_number_error !== ""
                                  ? unit_number_error
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className="btn-inline sell-inline-btn">
                          <li onClick={this.onHandleChange} className="btn" id="Multifamily" data-id="Multifamily"
                            style={building_ownership == "Multifamily" ? { background: "#004F6B", color: "white" }
                              : { visibility: "visible" }}>Multifamily</li>
                          <li onClick={this.onHandleChange} className="btn" id="Condo" data-id="Condo"
                            style={building_ownership == "Condo" ? { background: "#004F6B", color: "white" }
                              : { visibility: "visible" }}>Condo </li>
                          <li onClick={this.onHandleChange} className="btn" id="Co-op" data-id="Co-op"
                            style={building_ownership == "Co-op" ? { background: "#004F6B", color: "white" }
                              : { visibility: "visible" }}>Co-op</li>
                          <li onClick={this.onHandleChange} className="btn" id="Condop" data-id="Condop"
                            style={building_ownership == "Condop" ? { background: "#004F6B", color: "white" }
                              : { visibility: "visible" }}>Condop</li>
                          <li onClick={this.onHandleChange} className="btn" id="Single Family" data-id="Single Family"
                            style={building_ownership == "Single Family" ? { background: "#004F6B", color: "white" }
                              : { visibility: "visible" }}>Single Family</li>
                        </ul>
                      </div>

                      {/* ........................................ */}
                      <div className="listing-block">
                        <h4>Unit information</h4>
                        <div className="form-inline">
                          <div className="form-group">
                            <label>Layout</label>
                            <select id="layout" className="form-control" onChange={this.layoutChange} style={{ width: '200px' }}>
                              <option>Select</option>
                              {dropdownData}
                            </select>
                          </div>
                          <div className="form-group">
                            <label>Bedrooms*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="bedrooms"
                              value={bedrooms}
                              onChange={this.handleChange}
                              style={(this.state.bedrooms == '' && this.state.bedrooms != 0) ? { borderBottom: '2px solid red' } : { visibility: "visible" }}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {bedrooms_error !== "" ? bedrooms_error : ""}
                            </span>
                          </div>

                          <div className="form-group">
                            <label>Bathrooms*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="bathrooms"
                              value={bathrooms}
                              onChange={this.handleChange}
                              style={(this.state.bathrooms == '' && this.state.bathrooms.toString() === 0) ? { borderBottom: '2px solid red' } : { visibility: "visible" }}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {bathrooms_error !== "" ? bathrooms_error : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Total Rooms*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="total_rooms"
                              value={total_rooms}
                              onChange={this.onHandleChange}
                              style={(this.state.total_rooms == '' && this.state.total_rooms != 0) ? { borderBottom: '2px solid red' } : { visibility: "visible" }}
                            />
                            {/* <small className="leftbedge">#</small> */}
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {total_rooms_error !== ""
                                ? total_rooms_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Square Footage</label>
                            <input
                              type="text"
                              className="form-control size"
                              id="square_footage"
                              value={square_footage}
                              onChange={this.handleChange}
                            />
                            <small className="rightbedge">sq. ft.</small>
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {square_footage_error !== "" ? square_footage_error : ""}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Description*</label>
                            <div className="form-group">
                              <textarea
                                id="description"
                                value={description}
                                onChange={this.handleChange}
                                style={(this.state.description.toString().trim() == '') ? { border: '2px solid red', width: '98%' } : { visibility: "visible", width: '98%' }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="clearfix">
                          <div className="check-block pull-left">
                            <label style={{ marginBottom: '15px' }}>Exposures</label>
                            <div class="form-group ">
                              <div className="checkbox-inline">
                                <input type="checkbox"
                                  id="North"
                                  onChange={this.exposuresCheckBox}
                                  value="North"
                                  name="myCheckbox"
                                  checked={(this.state.exposures != "" && this.state.exposures) ? (this.state.exposures.includes('North')) ? 'checked' : '' : ''}
                                />
                                <label for="North">North</label>
                              </div>
                              <div className="checkbox-inline">
                                <input type="checkbox"
                                  id="South"
                                  onChange={this.exposuresCheckBox}
                                  value="South"
                                  name="myCheckbox"
                                  checked={(this.state.exposures != "" && this.state.exposures) ? (this.state.exposures.includes('South')) ? 'checked' : '' : ''}
                                />
                                <label for="South">South</label>
                              </div>
                              <div className="checkbox-inline">
                                <input type="checkbox"
                                  id="East"
                                  onChange={this.exposuresCheckBox}
                                  value="East"
                                  name="myCheckbox"
                                  checked={(this.state.exposures != "" && this.state.exposures) ? (this.state.exposures.includes('East')) ? 'checked' : '' : ''}
                                />
                                <label for="East">East </label>
                              </div>
                              <div className="checkbox-inline">
                                <input type="checkbox"
                                  id="West"
                                  onChange={this.exposuresCheckBox}
                                  value="West"
                                  name="myCheckbox"
                                  checked={(this.state.exposures != "" && this.state.exposures) ? (this.state.exposures.includes('West')) ? 'checked' : '' : ''}
                                />
                                <label for="West">West</label>
                              </div>
                            </div>
                          </div>
                          <div class="form-inline pull-left" style={{ marginLeft: '24px' }}>

                            <div className="form-group">
                              <label>Unit Condition </label>
                              <select id="unit_condition" className="form-control" style={{ width: '200px' }}>
                                <option>Select</option>
                                <option value="New">New</option>
                                <option value="Excellent">Excellent</option>
                                <option value="Good">Good</option>
                                <option value="Fair">Fair</option>
                                <option value="Poor">Poor</option>
                                <option value="Wrecked">Wrecked</option>
                              </select>
                              {/* <input type="text" 
                                                           className="form-control"
                                                        id="unit_view"
                                                        onChange={this.onHandleChange}
                                                        value={unit_view}/> */}
                              {/* <br /> */}
                              {/* <span className="inline-error-class">
                                        {" "}
                                           {unit_view_error !== ""
                                             ? unit_view_error
                                             : ""}
                                          </span>  */}
                            </div>
                            <div className="form-group">
                              <label>Unit View</label>
                              <select id="unit_view" className="form-control" style={{ width: '200px' }}>
                                <option>Select</option>
                                <option value="Open View">Open View</option>
                                <option value="Obstructed View">Obstructed View</option>
                                <option value="Partially Obstructed View">Partially Obstructed View</option>
                              </select>
                              {/* <input type="text" 
                                                           className="form-control"
                                                        id="unit_condition"
                                                        value={unit_condition}
                                                        onChange={this.onHandleChange}/>
                                                     <br />
                                          <span className="inline-error-class">
                                        {" "}
                                           {unit_condition_error !== ""
                                             ?unit_condition_error
                                             : ""}
                                          </span>  */}
                            </div>
                            <div className="form-group">
                              <label>Pet Policy*</label>
                              <select id="pets" className="form-control" style={(this.state.pets_policy == '') ? { borderBottom: '2px solid red', width: '200px' } : { visibility: "visible", width: '200px' }} onChange={this.handleChange}>
                                <option value="">Select</option>
                                <option value="Cats Allowed">Cats Allowed</option>
                                <option value="Dogs Allowed">Dogs Allowed</option>
                                <option value="Pets Allowed">Pets Allowed</option>
                                <option value="No Pets Allowed">No Pets Allowed</option>
                                <option value="case-by-case">Case-by-Case</option>
                              </select>
                            </div>
                            <div className="form-group">
                              {/* DropDown */}
                              <label>Air Conditioning Type</label>
                              <select id="air_conditioning_type" style={{width:'100%'}} className="form-control">
                                <option value="">Select</option>
                                {airconditiontype}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Views Remarks</label>
                            <div className="form-group">
                              <textarea
                                id="views_remarks"
                                value={this.state.views_remarks}
                                onChange={this.onHandleChange}
                                style={{ width: '98%' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ........................................ */}
                      <div className="listing-block">
                        <h4>Lease information</h4>
                        <div className="form-inline">
                          <div className="form-group">
                            <label>Price* </label>
                            <input
                              type="text"
                              className="form-control"
                              id="price"
                              value={price}
                              onChange={this.handleChange}
                              style={(this.state.price == '') ? { borderBottom: '2px solid red' } : { visibility: "visible" }}
                            />
                            <small className="leftbedge">$</small>
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {price_error !== ""
                                ? price_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Date Available*</label>
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleChangeDate}
                              dateFormat="MMMM d, yyyy"
                              timeCaption="time"
                              className="form-control"
                              id="date_available"
                              value={this.state.startDate == '' ? date_available : Moment(this.state.startDate).format('MM/DD/YYYY')}

                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {date_available_error !== ""
                                ? date_available_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Min. Lease Term*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="min_lease_term"
                              value={min_lease_term}
                              onChange={this.handleChange}
                              style={(this.state.min_lease_term == '') ? { borderBottom: '2px solid red' } : { visibility: "visible" }}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {min_lease_term_error !== ""
                                ? min_lease_term_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Max Lease Months</label>
                            <input
                              type="text"
                              className="form-control"
                              id="MaxLeaseMonths"
                              value={this.state.MaxLeaseMonths}
                              onChange={this.onCoBrokehandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.MaxLeaseMonths == undefined || this.state.MaxLeaseMonths == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" } : { visibility: "visible" }}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.MaxLeaseMonths_error !== ""
                                ? this.state.MaxLeaseMonths_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Lease Term*</label>
                            <select className="form-control" id="LeaseTerm" onChange={this.onCoBrokehandler}
                              style={(this.state.LeaseTerm == undefined || this.state.LeaseTerm == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" }}>
                              <option value="" selected={(this.state.LeaseTerm == "") ? 'selected' : ''}>Lease Term</option>
                              <option value="One Year" selected={(this.state.LeaseTerm == "One Year") ? 'selected' : ''}>One Year</option>
                              <option value="Two Year" selected={(this.state.LeaseTerm == "Two Year") ? 'selected' : ''}>Two Year</option>
                              <option value="Short-term" selected={(this.state.LeaseTerm == "Short-term") ? 'selected' : ''}>Short-term</option>
                              <option value="Month-to-month" selected={(this.state.LeaseTerm == "Month-to-month") ? 'selected' : ''}>Month-to-month</option>
                              <option value="Specific term" selected={(this.state.LeaseTerm == "Specific term") ? 'selected' : ''}>Specific term</option>
                              <option value="One or Two year" selected={(this.state.LeaseTerm == "One or Two year") ? 'selected' : ''}>One or Two year</option>
                              <option value="Short or Long term" selected={(this.state.LeaseTerm == "Short or Long term") ? 'selected' : ''}>Short or Long term</option>
                            </select>
                            {/* <input
                              type="text" 
                              className="form-control"
                              id="LeaseTerm"
                              value={this.state.LeaseTerm}
                              onChange={this.onCoBrokehandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.LeaseTerm ==undefined || this.state.LeaseTerm=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />    */}
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.LeaseTerm_error !== ""
                                ? this.state.LeaseTerm_error
                                : ""}
                            </span>
                          </div>

                          <div className="form-group" id="switch">
                            <Switch
                              onChange={this.handleChangeStatus}
                              checked={(this.state.furnished == 1) ? 'Checked' : ''}
                              className="react-switch"
                              id="normal-switch"
                            />
                            <span style={{ marginLeft: "10px" }}>
                              Furnished
                            </span>
                          </div>
                        </div>
                      </div>

                      {/*Building information*/}
                      <div className="listing-block">
                        <h4>Building information</h4>
                        <div className="form-inline">
                          <div className="form-group">
                            <label>Building Type</label>
                            <select id="buildingType" onChange={this.handleChange} className="form-control" style={(this.state.RLSFlag == 1) ? (this.state.buildingTypeName == 'High-rise' || this.state.buildingTypeName == 'Walk-up' || this.state.buildingTypeName == 'Elevator' || this.state.buildingTypeName == 'Hotel'  || this.state.buildingTypeName == 'Loft') ? { visibility: "visible",width:'200px'}  :{ borderBottom: '2px solid red',width:'200px' } : { visibility: "visible",width:'200px' }} >
                              <option value="">select</option>
                              {buildingType}
                            </select>
                          </div>
                          <div className="form-group">
                            <lable>Pre War {this.state.PreWarYN}</lable>
                            <input type="checkbox"
                              id="PreWarYN"
                              checked={(this.state.PreWarYN == true) ? 'checked' : ''}
                              onChange={this.checkboxHandler} />
                          </div>

                          <div className="form-group">
                            <label>Year Built</label>
                            <input
                              type="text"
                              maxLength={4}
                              className="form-control"
                              id="Year_Built"
                              value={this.state.Year_Built}
                              onChange={this.onHandler}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.Year_Built_error !== ""
                                ? this.state.Year_Built_error
                                : ""}
                            </span>
                          </div>

                          <div className="form-group">
                            <label>Number Of Units Total</label>
                            <input
                              type="text"
                              className="form-control"
                              id="NumberOfUnitsTotal"
                              value={this.state.NumberOfUnitsTotal}
                              onChange={this.onHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.NumberOfUnitsTotal == undefined || this.state.NumberOfUnitsTotal == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" } : { visibility: "visible" }}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.NumberOfUnitsTotal_error !== ""
                                ? this.state.NumberOfUnitsTotal_error
                                : ""}
                            </span>
                          </div>

                          {this.state.building_ownership == "Single Family" ?
                            <div className="form-group">
                              <label>Legal Rooms Total</label>
                              <input
                                type="text"
                                className="form-control"
                                id="LegalRoomsTotal"
                                value={this.state.LegalRoomsTotal}
                                onChange={this.onHandler}
                                style={(this.state.RLSFlag == 1) ? (this.state.LegalRoomsTotal == undefined || this.state.LegalRoomsTotal == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" } : { visibility: "visible" }}

                              />
                              <br />
                              <span className="inline-error-class">
                                {" "}
                                {this.state.LegalRoomsTotal_error !== ""
                                  ? this.state.LegalRoomsTotal_error
                                  : ""}
                              </span>
                            </div>
                            : ""}
                          <div className="form-group">
                            <label>Stories Total</label>
                            <input
                              type="text"
                              className="form-control"
                              id="StoriesTotal"
                              value={this.state.StoriesTotal}
                              onChange={this.onHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.StoriesTotal == undefined || this.state.StoriesTotal == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" } : { visibility: "visible" }}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.StoriesTotal_error !== ""
                                ? this.state.StoriesTotal_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Tax Block</label>
                            <input
                              type="text"
                              className="form-control"
                              id="TaxBlock"
                              value={this.state.TaxBlock}
                              onChange={this.onHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.TaxBlock == undefined || this.state.TaxBlock == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" } : { visibility: "visible" }}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.TaxBlock_error !== ""
                                ? this.state.TaxBlock_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Tax Lot</label>
                            <input
                              type="text"
                              className="form-control"
                              id="TaxLot"
                              value={this.state.TaxLot}
                              onChange={this.onHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.TaxLot == undefined || this.state.TaxLot == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" } : { visibility: "visible" }}

                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.TaxLot_error !== ""
                                ? this.state.TaxLot_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <lable>Lobby Attendant  </lable>
                            <input type="checkbox"
                              id="LobbyAttendant"
                              checked={(this.state.LobbyAttendant == true) ? 'checked' : ''}
                              onChange={this.checkboxLobbyHandler} />
                          </div>
                          {this.state.building_ownership == "Single Family" ?
                            <div className="form-group">
                              <label>Lot Width</label>
                              <input
                                type="text"
                                className="form-control"
                                id="LotWidth"
                                value={this.state.LotWidth}
                                onChange={this.onHandler}
                              />
                              <br />
                              <span className="inline-error-class">
                                {" "}
                                {this.state.LotWidth_error !== ""
                                  ? this.state.LotWidth_error
                                  : ""}
                              </span>
                            </div>
                            : ""}
                        </div>
                      </div>
                      {/*Building information*/}

                      {/* Listing Information */}
                      <div className="listing-block">
                        <h4>Listing information</h4>
                        <div className="form-inline">
                          <div className="form-group">
                            {/* DropDown */}
                            <label>Co-Broke Agreement</label>
                            <select id="CoBrokeAgreement" className="form-control" style={(this.state.RLSFlag == 1) ? (this.state.CoBrokeAgreement == undefined || this.state.CoBrokeAgreement == "") ? { borderBottom: '2px solid red' } : { visibility: "visible",width:'236px' } : { visibility: "visible",width:'236px' }} onChange={this.handleChange}>
                              <option value="">select</option>
                              {co_broke_agreement}
                            </select>
                          </div>
                          <div className="form-group">
                            <label>Showing Instructions</label>
                            <input
                              type="text"
                              className="form-control"
                              id="ShowingInstructions"
                              value={this.state.ShowingInstructions}
                              onChange={this.onCoBrokehandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.ShowingInstructions == undefined || this.state.ShowingInstructions == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" } : { visibility: "visible" }}
                            />
                          </div>

                          {/* Based on furnished */}
                          {this.state.furnished == 1 ?
                            <div>
                              <div className="form-group">
                                <label>Furnished List Price*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="FurnishedListPrice"
                                  value={this.state.FurnishedListPrice}
                                  onChange={this.onCoBrokehandler}
                                />
                                <br />
                                <span className="inline-error-class">
                                  {" "}
                                  {this.state.FurnishedListPrice_error !== ""
                                    ? this.state.FurnishedListPrice_error
                                    : ""}
                                </span>
                              </div>
                              <div className="form-group">
                                <label>Furnished Min Lease Months*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="FurnishedMinLeaseMonths"
                                  value={this.state.FurnishedMinLeaseMonths}
                                  onChange={this.onCoBrokehandler}
                                />
                                <br />
                                <span className="inline-error-class">
                                  {" "}
                                  {this.state.FurnishedMinLeaseMonths_error !== ""
                                    ? this.state.FurnishedMinLeaseMonths_error
                                    : ""}
                                </span>
                              </div>
                              <div className="form-group">
                                <label>Furnished Max Lease Months*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="FurnishedMaxLeaseMonths"
                                  value={this.state.FurnishedMaxLeaseMonths}
                                  onChange={this.onCoBrokehandler}
                                />
                                <br />
                                <span className="inline-error-class">
                                  {" "}
                                  {this.state.FurnishedMaxLeaseMonths_error !== ""
                                    ? this.state.FurnishedMaxLeaseMonths_error
                                    : ""}
                                </span>
                              </div>
                            </div> : ""}
                          {/* Based on furnished */}
                          <div>

                            <div className=" check-block">
                              <div className="form-group" style={{ paddingLeft: '0' }}>
                                <div className={this.state.RLSFlag == 1 ? this.state.VOWEntireListingDisplay != false ? "checkbox-inline" : "checkbox-inline alert" : "checkbox-inline"} style={{ padding: '0px' }}>
                                  <input type="checkbox"
                                    checked={(this.state.VOWEntireListingDisplay) ? 'checked' : ''}
                                    id="VOWEntireListingDisplay" value={this.state.VOWEntireListingDisplay} onChange={this.oncheckBoxHandler} />
                                  <label for="VOWEntireListingDisplay">VOW Entire Listing Display</label>
                                </div>

                                <div className={this.state.RLSFlag == 1 ? this.state.VOWAddressDisplay != false ? "checkbox-inline" : "checkbox-inline alert" : "checkbox-inline"} style={{ padding: '0px' }}>
                                  <input type="checkbox" id="VOWAddressDisplay" value={this.state.VOWAddressDisplay} checked={(this.state.VOWAddressDisplay) ? 'checked' : ''} onChange={this.oncheckBoxHandler} />
                                  <label for="VOWAddressDisplay">VOW Address Display</label>
                                </div>
                                <div className={this.state.RLSFlag == 1 ? this.state.VOWConsumerComment != false ? "checkbox-inline" : "checkbox-inline alert" : "checkbox-inline"} style={{ padding: '0px' }}>
                                  <input type="checkbox" id="VOWConsumerComment" value={this.state.VOWConsumerComment} checked={(this.state.VOWConsumerComment) ? 'checked' : ''} onChange={this.oncheckBoxHandler} />
                                  <label for="VOWConsumerComment">VOW Consumer Comment</label>
                                </div>
                                <div className={this.state.RLSFlag == 1 ? this.state.VOWAutomatedValuationDisplay != false ? "checkbox-inline" : "checkbox-inline alert" : "checkbox-inline"} style={{ padding: '0px' }}>
                                  <input type="checkbox" id="VOWAutomatedValuationDisplay" value={this.state.VOWAutomatedValuationDisplay} checked={(this.state.VOWAutomatedValuationDisplay) ? 'checked' : ''} onChange={this.oncheckBoxHandler} />
                                  <label for="VOWAutomatedValuationDisplay">VOW Automated Valuation Display</label>
                                </div>

                                <div className={this.state.RLSFlag == 1 ? this.state.NewDevelopment != false ? "checkbox-inline" : "checkbox-inline alert" : "checkbox-inline"} style={{ padding: '0px' }}>
                                  <input type="checkbox" id="NewDevelopment" value={this.state.NewDevelopment} checked={(this.state.NewDevelopment) ? 'checked' : ''} onChange={this.oncheckBoxHandler} />
                                  <label for="NewDevelopment">New Development</label>
                                </div>
                                {/* <div className="checkbox-inline">
     <input type="checkbox" id="NewDevelopment" value={this.state.NewDevelopment} onChange={this.oncheckBoxHandler} /> 
     <label for="NewDevelopment">New Development</label>
   </div> */}

                                <div className={this.state.RLSFlag == 1 ? this.state.IDXEntireListingDisplay != false ? "checkbox-inline" : "checkbox-inline alert" : "checkbox-inline"} style={{ padding: '0px' }}>
                                  <input type="checkbox" id="IDXEntireListingDisplay" checked={(this.state.IDXEntireListingDisplay) ? 'checked' : ''} value={this.state.IDXEntireListingDisplay} onChange={this.oncheckBoxHandler} />
                                  <label for="IDXEntireListingDisplay">IDX Entire Listing Display</label>
                                </div>

                                <div className="checkbox-inline" style={{ padding: '0px' }}>
                                  <input type="checkbox" id="Bonus" value={this.state.Bonus} checked={(this.state.Bonus) ? 'checked' : ''} onChange={this.oncheckBoxHandler} />
                                  <label for="Bonus">Bonus</label>
                                </div>
                                <div className="checkbox-inline" style={{ padding: '0px' }}>
                                  <input type="checkbox" id="FreeRent" checked={(this.state.FreeRent) ? 'checked' : ''} value={this.state.FreeRent} onChange={this.oncheckBoxHandler} />
                                  <label for="FreeRent">Free Rent</label>
                                </div>
                              </div>
                            </div>

                            {/* </div>  */}
                            {this.state.Bonus == true ?
                              <div className="form-group">
                                <label>Bonus Remarks*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="BonusRemarks"
                                  value={this.state.BonusRemarks}
                                  onChange={this.onCoBrokehandler}
                                />
                                <br />
                                <span className="inline-error-class">
                                  {" "}
                                  {this.state.BonusRemarks_error !== ""
                                    ? this.state.BonusRemarks_error
                                    : ""}
                                </span>
                              </div> : ""}

                            {this.state.FreeRent == true ?
                              <div className="form-group">
                                <label>Free Rent Remarks*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="FreeRentRemarks"
                                  value={this.state.FreeRentRemarks}
                                  onChange={this.onCoBrokehandler}
                                />
                                <br />
                                <span className="inline-error-class">
                                  {" "}
                                  {this.state.FreeRentRemarks_error !== ""
                                    ? this.state.FreeRentRemarks_error
                                    : ""}
                                </span>
                              </div> : ""}
                          </div>

                        </div>
                      </div>
                      {/* Listing Information */}
                      {/* Open Houses */}
                      <div className="listing-block">
                        <h4>Open Houses</h4>
                        {newDiv}
                        <div class="form-group ">
                          <div className="checkbox-inline" style={{ paddingLeft: '0' }}>
                            <input type="checkbox"
                              id="brokerOnly"
                              onChange={this.brokerCheckboxHandler}
                              checked={(this.state.brokerOnly == true) ? 'checked' : ''}
                            />

                            <label for="Broker Only"> Broker Only</label>
                          </div>
                          <div className="checkbox-inline">
                            <input type="checkbox"
                              id="appointmentOnly"
                              onChange={this.appointmentCheckboxHandler}
                              name="myCheckbox"
                              checked={(this.state.appointmentOnly == true) ? 'checked' : ''}
                            />
                            <label for="Appointment Only">Appointment Only</label>
                          </div>
                        </div>
                        <div className="form-inline">
                          <div className="form-group">
                            <Link to style={{ cursor: 'pointer' }} >
                              <i class="fa fa-plus" onClick={this.OpenAdditionalHouse()} aria-hidden="true"><small>ADD AN ADDITIONAL OPEN HOUSE</small></i>
                            </Link>
                          </div></div>
                      </div>
                      {/* Open Houses */}
                      {/* ........................................ */}
                      <div className="listing-block check-block">
                        <h4>Unit Amenities</h4>

                        <div
                          className="form-group"
                        // onChange={this.handleChange}
                        >
                          {Unit_amenities}
                        </div>
                      </div>

                      {/* ........................................ */}
                      <div className="listing-block">
                        <h4 style={this.state.RLSFlag == 1 ? this.state.incentives != '' || this.state.owner_pays != "" ? {} : { color: "red" } : {}}>Incentives</h4>
                        <ul className="btn-inline">
                          <li
                            className="btn"
                            onClick={this.handleChange}
                            style={
                              incentives == "CYOF"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            id="CYOF"
                            data-id="CYOF"
                          >
                            CYOF
                          </li>
                          <li
                            className="btn"
                            onClick={this.changeHandler}
                            style={
                              this.state.owner_pays == "Owner pays"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            // onClick={this.handleChange}
                            id="Owner_pays"
                            data-id="Owner pays"
                          >
                            Owner pays
                          </li>
                          <li
                            className="btn"
                            onClick={this.handleChange}
                            style={
                              incentives == "Co-broke"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            id="Co-broke"
                            data-id="Co-broke"
                          >
                            Co-broke
                          </li>
                        </ul>

                        <div
                          className="form-inline"
                          onChange={this.handleChange}
                        >
                          {this.state.owner_pays == "Owner pays" ?
                            <div>
                              <div className="form-group">
                                <label>Owner Pays Broker</label>
                                <select id="owner_pays_broker" className="form-control" style={{ width: '200px' }}>
                                  <option value="">Select</option>
                                  {ownerpayData}
                                </select>

                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="owner_amount"
                                  value={this.state.owner_amount}
                                  onChange={this.changeHandler}
                                />
                                <small className="rightbedge">{this.state.owner_pays_broker == "Month(s) Rent" ? '' : (this.state.owner_pays_broker == 'Percentage Of Yearly Rent' || this.state.owner_pays_broker == 'Percentage Of Monthly Rent') ? "%" : "$"}</small>
                              </div>
                            </div> : ""}
                          {(this.state.incentives == "Co-broke") ?
                            <div>
                              <div className="form-group">
                                <label for="Tenant incentive">
                                  Commission Percentage
                            </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="commission_percentage"
                                  value={this.state.commission_percentage}
                                  onChange={this.onChangeHandler}
                                  style={(this.state.RLSFlag == 1) ? (this.state.commission_percentage == undefined || this.state.commission_percentage == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" } : { visibility: "visible" }}
                                />
                                <small className="rightbedge">%</small>
                                <br />
                                <div> <span className="inline-error-class"></span></div>

                              </div>
                              <div className="form-group">
                                <label for="Tenant incentive">
                                  My Split
                            </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="my_split"
                                  value={this.state.my_split}
                                  onChange={this.onChangeHandler}
                                  style={(this.state.RLSFlag == 1) ? (this.state.my_split == undefined || this.state.my_split == "") ? { borderBottom: '2px solid red' } : { visibility: "visible" } : { visibility: "visible" }}
                                />
                                <span>{this.state.final_precentage ? this.state.final_precentage + "%" : ''}</span>
                                {this.state.final_precentage ? "" : <small className="rightbedge">%</small>}

                              </div>
                              <div className="form-group">
                                <label for="Tenant incentive">
                                  Co-Broker's Split
                            </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="co_broke_split"
                                  value={this.state.co_broker_split + "%"}
                                  // onChange={this.onChangeHandler}
                                  disabled
                                />   <span >{this.state.copercentage_broker ? this.state.copercentage_broker + "%" : ''}</span>
                                {this.state.copercentage_broker ? "" : <small className="rightbedge">%</small>}

                              </div>
                            </div>
                            : ""}
                          <div className="form-group">
                            <label for="Tenant incentive">
                              Tenant incentive
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="tenant_incentive"
                              value={tenant_incentive}
                            />
                            <small className="righttbedge">months free</small>
                          </div>
                          <div
                            className="check-block"
                            style={{ display: "inline" }}
                          >
                            <div className="form-group ">
                              <div className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  id="OwnerPaysPlusConcessionsYN"
                                  value="OwnerPaysPlusConcessionsYN"
                                  onChange={this.handleOwnerPays}
                                  checked={this.state.OwnerPaysPlusConcessionsYN}
                                />
                                <label htmlFor="OwnerPaysPlusConcessionsYN">Owner Pays Plus Concessions</label>
                              </div>
                            </div>
                            <div className="form-group ">
                              <div className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  id="noFee"
                                  value="noFee"
                                  onChange={this.handleCheck}
                                  checked={this.state.noFee}
                                />
                                <label htmlFor="noFee">Advertise as No Fee</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="listing-block" style={{ height: '100px' }}>
                        <div style={{ float: 'left', paddingRight: '20px' }}>
                          {this.props.userType == "Manager" ? <h4>Primary Agent</h4> : ''}
                          {this.props.userType == "Manager" ? <div
                            className="form-group"
                          >
                            <input type="text" style={{ width: '200px' }} id="primary_agentId" onChange={this.handleAgentSearchprimary} onKeyDownCapture={this.handleAgentSearchprimary} onKeyUpCapture={this.handleAgentSearchprimary} autoComplete="off" value={(this.state.primary_agent_name != 0) ? this.state.primary_agent_name : ''} className="form-control" />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.primary_agent_error !== ""
                                ? this.state.primary_agent_error
                                : ""}
                            </span>
                            <div className="box agentNameOnBoard" id="primary_agentId_values" style={{ display: 'none', width: '200px', position: 'absolute' }} >
                              <ul>{primaryagentDWNewShowing}</ul>
                            </div>
                          </div> : ''}
                        </div>
                        <div style={{ float: 'left', paddingRight: '20px' }}>
                          <h4>Secondary Agent</h4>
                          <div
                            className="form-group"
                          >
                            <input type="text" style={{ width: '200px' }} id="newShowing_agent_search_id" onChange={this.handleAgentSearchAssignNewShowing} onKeyDownCapture={this.handleAgentSearchAssignNewShowing} onKeyUpCapture={this.handleAgentSearchAssignNewShowing} autoComplete="off" value={(this.state.newShowing_agent_name != 0) ? this.state.newShowing_agent_name : ''} className="form-control" />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.secondary_agent_error !== ""
                                ? this.state.secondary_agent_error
                                : ""}
                            </span>
                            <div className="box agentNameOnBoard" id="newShowing_agent_search_id_values" style={{ display: 'none', width: '200px', position: 'absolute' }} >
                              <ul>{agentDWNewShowing}</ul>
                            </div>
                          </div>
                        </div>
                        <div style={{ float: 'left' }}>
                          <h4>Tertiary Agent</h4>
                          <div
                            className="form-group"
                          >
                            <input type="text" style={{ width: '200px' }} id="third_agentId" onChange={this.handlethirdagentsearch} onKeyDownCapture={this.handlethirdagentsearch} onKeyUpCapture={this.handlethirdagentsearch} autoComplete="off" value={(this.state.third_agent_name != 0) ? this.state.third_agent_name : ''} className="form-control" />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.third_agent_error !== ""
                                ? this.state.third_agent_error
                                : ""}
                            </span>
                            <div className="box agentNameOnBoard" id="third_agentId_values" style={{ display: 'none', width: '200px', position: 'absolute' }} >
                              <ul>{thirdagentDWNewShowing}</ul>
                            </div>
                          </div>
                        </div>

                      </div>
                      {getAgentDomain()!=='kw.com'?
                      <div className="listing-block check-block">
                        <h4>Marketing*</h4>
                        <div
                          className="form-group"
                          onChange={this.handleChange}
                        >
                          {marketing}
                        </div>
                      </div>:''}
                      <div className="listing-block">
                        <h4>Media</h4>
                        (Please upload at least 3 images)
                         <Link to className="img-edit editsmallicon" data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " style={{color:'red'}} aria-hidden="true"></i></Link>
                        <div className="row clerfix">
                          {/* <Link to className="img-edit editsmallicon" data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link> */}
                          <div className="col-sm-12">
                            <div className="row clerfix">
                              {image_list}
                              {files}
                              <Dropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                  <section className="container">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                      <input {...getInputProps()} />

                                      <div className="col-sm-3">
                                        <div className="file-upload add-image-block"></div>
                                      </div>
                                    </div>

                                  </section>
                                )}
                              </Dropzone>
                            </div>

                          </div>
                        </div>
                        <span className="inline-error-class">
                          {" "}
                          {this.state.mymsg !== ""
                            ? this.state.mymsg
                            : ""}
                        </span>
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="form-group">
                              <label>VR Link</label>
                              <input
                                type="text"
                                className="form-control"
                                id="kuula_vr_link"
                                value={kuula_vr_link}
                                onChange={this.onVrHandle}
                                onBlur={this.onVrHandleChange}
                                style={{ visibility: "visible" }}
                              /><span style={{fontSize:'11px'}}>Note: URL must be in these urls tours.moveskyward.com, view.ricohtours.com, kuula.co, my.matterport.com, skyward360tours.com</span> 
                              <span className="inline-error-class"><br />  
                                {" "}
                                {kuula_vr_link_error !== ""
                                  ? kuula_vr_link_error
                                  : ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-sm-5">
                            <div className="form-group">
                              <label>Video Link</label>
                              <input
                                type="text"
                                className="form-control"
                                id="video_link"
                                value={video_link}
                                onChange={this.onVrHandle}
                                onBlur={this.onVrHandleChange}
                                style={{visibility:"visible"}}
                              /> <span style={{fontSize:'11px'}}>Note: URL must be from youtube.com or vimeo.com</span> 
                               <span className="inline-error-class"><br/>
                              {" "}
                              {video_link_error !== ""
                                ? video_link_error
                                : ""}
                            </span>
                            </div>
                            </div>
                        </div>
                      </div>
                      <div className="listing-block">
                        <h4>Floor Plan</h4>

                        <div className="row clerfix">

                          <div className="col-sm-12">
                            <div className="row clerfix">
                              {image_list_floor}
                              {blobfloorImages}
                              <Dropzone onDrop={this.onDropfloor}>
                                {({ getRootProps, getInputProps }) => (
                                  <section className="container">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                      <input {...getInputProps()} />

                                      <div className="col-sm-3">
                                        <div className="file-upload add-image-block"></div>
                                      </div>

                                    </div>

                                  </section>
                                )}
                              </Dropzone>
                            </div>

                          </div>
                          <span className="inline-error-class">
                            {" "}
                            {this.state.mymsgfloor !== ""
                              ? this.state.mymsgfloor
                              : ""}
                          </span>
                        </div>


                      </div>




                      <div className="listing-block">


                        <h4>Documents</h4>
                        {/* {this.state.type == "Manager" ?<p className="text-right float-right"><button className="btn btn-default" type="button" data-toggle="modal" onClick={this.onOpenModal}>+ Add Document(s)</button></p>:''} */}

                        <div className="row clerfix">

                          <div className="col-sm-12">
                            <div className="row clerfix">
                              {console.log('list_document', list_document)}
                              {this.props.userType == "Manager" ?list_document!=undefined && list_document.length>0?list_document:'No Document Available':list_document}
                              {this.props.userType == "Manager" ? '' : blobdocumentImages}
                              {this.props.userType == "Manager" ? '' : <Dropzone onDrop={this.onDropDocument}>
                                {({ getRootProps, getInputProps }) => (
                                  <section className="container">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                      <input {...getInputProps()} />

                                      <div className="col-sm-3">
                                        <div className="file-upload add-image-block"></div>
                                      </div>

                                    </div>

                                  </section>
                                )}
                              </Dropzone>}
                            </div>

                          </div>
                          <span className="inline-error-class">
                            {" "}
                            {this.state.mymsgfloor !== ""
                              ? this.state.mymsgfloor
                              : ""}
                          </span>
                        </div>

                        {/* <table class="table table-striped agent-listing" style={{ marginTop: '5px' }}>
    
    <tbody>
      
      {list_document?list_document:<tr><td colSpan="4">You don’t have any available document yet</td></tr>}
      

    </tbody>
  </table> */}
                      </div>



                      <div className="row">
                        {(this.state.edit == "no" && this.state.listing_status!=13)? <div className="col-sm-3">
                          <label>Status</label>
                          <select class="form-control" onChange={this.statusChanged()} id="listing_status">
                            <option value="1">Active</option>
                            <option value="20">Coming Soon</option>
                          </select>	</div> : ''}
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            disabled={!this.validation()}
                            className="btn btn-default"
                            style={{ margin: "10px" }}
                          >
                            Submit listing for approval
                        </button>
                        </div>
                      </div>

                      <div className="modal fade" id="imageEdit" >
                        <div className="modal-dialog ">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4 className="modal-title" style={{color:'white'}}>Sort Image Order</h4>
                              <button type="button" id="imageEditClose" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                              <form encType="multipart/form-data">
                                <div className="clearfix">
                                  <table className="table table-striped">
                                    <th>Image</th>

                                    <tbody>

                                      <DragSortableList items={thumbnail} placeholder={placeholder} onSort={this.onSort} dropBackTransitionDuration={0.3} type="vertical" />
                                      {/* <tr>
										<td colSpan="3">
											{this.state.hideandshow!=1
										?	<i class="fa fa-plus" style={{cursor:'pointer'}} aria-hidden="true" onClick={this.openandhide}> Add New Image</i>
										:	<i class="fa fa-minus" style={{cursor:'pointer'}} aria-hidden="true" onClick={this.closeRow}> Add New Image</i>}
											</td>
										</tr> */}
                                      {/* {this.state.hideandshow !=0 
										  ?
										<tr>
										<td colSpan="4"> <input type="file" onChange={this.fileHandler()} /></td>
										</tr>:''} */}
                                      <tr><td colSpan="4">
                                        <span className="inline-error-class">
                                          {" "}
                                          {this.state.mymsg !== ""
                                            ? this.state.mymsg
                                            : ""}
                                        </span></td></tr>
                                    </tbody>
                                  </table>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <Modal open={this.state.openViewer} onClose={this.onCloseView} classNames="modal-sm">
                      <ViewlistingDoc listingData={this.state.listingData} onClose={this.onCloseView} />
                    </Modal>
                    <Modal open={this.state.editOpen} onClose={this.onCloseEdit} classNames="modal-sm">
                      <ManagerEditListDoc listingsData={this.state.listingsData} onClose={this.onCloseEdit} />
                    </Modal>
                  </div>
                </form>
                {/* </TabPanel>	
								<TabPanel>

								<SalesCreator propsValue={[{'userType':this.props.userType , 'tabPanel':this.state.tabPanel}]} />
								
								</TabPanel>
							</Tabs> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

ListingCreator.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
};
export default ListingCreator;
