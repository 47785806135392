import React from 'react';
import {postApiData,getBaseUrl} from '../../services/Api';
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";

class ViewClientDoc extends React.Component {
    constructor(props) {
		super(props);
		this.state={
            file_path:this.props.disclouserData,
			showLoader:false,
			error_msg:'',
			success_msg:'',
			invalid:false,
		}
	}

	componentWillMount(){
		if(this.props.disclouserData==null || this.props.disclouserData==undefined){
			this.setState({
				invalid:true
			})
		}
	}
	
	

	


    render() {
   
		let ext = this.state.file_path.substring(this.state.file_path.lastIndexOf('.')).toLowerCase();
		console.log("ext",ext);
		
	    return (<div className="modal-content">
{(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}				
		<div className="modal-header">
			<h4 className="modal-title">Doc Viewer</h4>
		</div>
		<div className="modal-body">
		{(this.state.error_msg!='')?<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>:''}
	{(this.state.success_msg !== '') ?<div className="alert alert-success"> {this.state.success_msg !==''?this.state.success_msg:''} </div>:''}
	<div className="modal-content client-modal">
            {([".jpg", ".JPG", ".jpeg", ".png"].includes(ext))?<img src={this.state.file_path} className="additionalImg"/>:<embed src={this.state.file_path} width="90%" height="500" style={{margin:'5%'}}></embed>}
			<button type="button" className="btn cl-btn pull-right" onClick={this.props.onClose} >Close  <img src="/close.png"/></button> 
            </div>
		</div>
	</div>);
    }
}



export default ViewClientDoc;