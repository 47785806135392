import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/AgentHeader';
import { getManagerToken, getApiData, postApiData, getBaseUrl } from '../../services/Api';
import { Redirect } from 'react-router';
import {AgentStatus,AgentShowingStatus} from '../../services/Const';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import Modal from 'react-responsive-modal';

class Toggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: (props.status == 2) ? true : false,
            id: props.agentId,
            status: props.status,
            showLoader:false,
           
        };

        this.handleChange = this.handleChange.bind(this);
      
    }
    handleChange() {
        this.setState({isChecked: !this.state.isChecked});
        let clientList = [{
                url: "/agent/access/" + this.state.id
            }];
        this.setState({showLoader:true});
        postApiData(clientList, {'status': (this.state.isChecked) ? 1 : 2}, true).then(res => {
            this.props.showStatus(res.data.status);
            this.setState({showLoader:false});
        }).catch(error => {
            if (error.response) {

            }
        });
    }

   
    render() {
       
        return (
            <div>
                <label className="switchToggle">
                    <input type="checkbox" defaultChecked={(this.state.status == 2) ? true : false} onChange={this.handleChange} />
                    <div className="sliderToggle"></div>
                </label>
              
                 {(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''} 
            </div>
                );
    }
}
// start code for agent showing toggle button

class ShowingToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCheckedshowing: (props.showing_status == 0) ? true : false,
            id: props.agentId,
            showing_status:props.showing_status,
            showLoader:false,
           
        };

      
        this.handleChangeshowing=this.handleChangeshowing.bind(this);
    }
  

    handleChangeshowing() {
        this.setState({isCheckedshowing: !this.state.isCheckedshowing});
        let clientList = [{
                url: "/agent/showing_access/" + this.state.id
            }];
        this.setState({showLoader:true});
        postApiData(clientList, {'showing_status': (this.state.isCheckedshowing) ? 1 : 0}, true).then(res => {
            this.props.showStatusshowing(res.data.showing_status);
            this.setState({showLoader:false});
        }).catch(error => {
            if (error.response) {

            }
        });
    }
    render() {
      
        return (
            <div>
                <label className="switchToggle">
                    <input type="checkbox" defaultChecked={(this.state.showing_status == 0) ? true : false} onChange={this.handleChangeshowing} />
                    <div className="sliderToggle"></div>
                </label>

                 {(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''} 
            </div>
                );
    }
}

// End code for agent showing toggle button

class AgentDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: 'No Number',
            imagePath: '',
            listData: (props.listData) ? props.listData : '',
            status: (props.listData) ? props.listData.status : '',
            showing_status: (props.listData) ? props.listData.showing_status : '',
            totalSlotsCheck:props.available_slots,
            agent_total_slot:props.total_slot,
            open: false,
            sort_open:false,
            allot_slots:props.listData.total_slot,
            total_allot_slots:props.listData.total_slot,
            agent_available_slot:props.listData.renthop_slot,
         //  new_allot_value:'',
            slots_error:'',
            new_sort_order:'',
            sort_order:999,
            sort_error:'',
            rentHop_slot_id:props.id
        }
        this.handleSortChange = this.handleSortChange.bind(this);
      
    }
    showStatus(status) {
        this.setState({status: status});
       
    }

    //start change state for agent showing

    showStatusshowing(showing_status) {
        this.setState({showing_status: showing_status});
    }

     //end change state for agent showing

    //open model
	onOpenModal = () => {
		document.body.classList.add('modalTransparent');
                this.setState({ open: true });
                
	};
	//close model
	onCloseModal = () => {
		this.setState({ open: false });
    };


    
    

    handleChange=(event)=>{
        console.log("---",event.target.value);
        const id = event.target.id;
        const value = event.target.value;
        const dataset = event.currentTarget.dataset.id;
      
        if(isNaN(value) ||  value<0){
        this.setState({
            slots_error:"Enter Only Numbers",
            allot_slots: value
        })
      }else if(value>this.state.totalSlotsCheck){
            this.setState({
                allot_slots:value,
                slots_error:"Please enter slot less than the available slots "+this.state.totalSlotsCheck+""
            })
        }else if(value==""){
            this.setState({
                allot_slots:value,
                slots_error:"Field cannot be empty"
            })
        }
        else if(value < (this.state.total_allot_slots-this.state.agent_available_slot))
        {
            this.setState({
                allot_slots:value,
                slots_error:"Alloted slots should be greater than used slots."
            })
        }
        else{
            this.setState({
           allot_slots:value,
            slots_error:""
            })
        }
        
    }

    validation=()=>{
        return 	 (
        this.state.slots_error.toString().trim()!= "")
     }
    validationSort=()=>{
        return 	 (this.state.sort_error.toString().trim()!= "");
    }
    editAllottedSlot=(event)=>{
        // console.log("----check",this.state.listData.id);
        // console.log("------",this.state.new_allot_value)
        // console.log("------",this.state.totalSlotsCheck)
        let where=[{
            url:"/managers/editAllotted"
        }];
        let new_available_slot=this.state.allot_slots-(this.state.total_allot_slots-this.state.agent_available_slot);
   
        postApiData(where,{'agentId':this.state.listData.id,'allotted_slots':this.state.allot_slots,'new_available_slot':new_available_slot,'total_available_slot':this.state.totalSlotsCheck},true).then(res=>{
        
            this.onCloseModal();
            document.getElementById("close").click();
           
        }).catch(error=>{

        })

    }

    
    //open sort model
	onOpenSortModal = () => {
		document.body.classList.add('modalTransparent');
                this.setState({ sort_open: true });
                
	};
	//close sort model
	onCloseSortModal = () => {
		this.setState({ sort_open: false });
    };

    handleSortChange=(event)=>{
        console.log("---",event.target.value);
        const value = event.target.value;
        if(isNaN(value) ||  value<0){
        this.setState({
            sort_error:"Enter Only Numbers",
            id: value
        })
        }
      else if(value==""){
            this.setState({
                sort_error:"Field cannot be empty"
            })
        }
        else{
            this.setState({
           new_sort_order:value,
           sort_error:""
            })
        }
        
    }

    editSortOrder=(event)=>{

        let where=[{
            url:"/managers/editSortOrder"
        }];
        postApiData(where,{'agentId':this.state.listData.id,'sort_order':this.state.new_sort_order},true).then(res=>{
          if(res){
           // this.setState({sort_order:this.state.new_sort_order})
            this.onCloseSortModal();
            document.getElementById("close").click();
          }
 
        }).catch(error=>{

        })

    }


    
    render() {
       
        if (!getManagerToken()) {
            return (<Redirect to={'/manager/landing'}/>);
        }
        const { open,sort_open } = this.state;
          //  this.state.allot_slots=this.state.listData.renthop_slot;
            this.state.sort_order=this.state.listData.sort_order;
        var image = '/member.png';
        var allStatus = AgentStatus();
        var allShowingStatus = AgentShowingStatus();
        const {listData, status,showing_status} = this.state;
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
        
        return (
                <div>
                    <main>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title head">Agent Details</h4>
                            </div>
                            <div className="modal-body profile">
                                <div className="form-section">
                                    <div className="clearfix profile-widget">
                                        <div className="info-wrap pull-left">
                                            <div className="heading-wrap profile-heading">
                                                {listData.name}
                                            </div>
                                            <div className="agentDetailStatus">Status:  <Toggle status={listData.status}  agentId={listData.id}  showStatus={this.showStatus.bind(this)}/> <span className={(status == '1') ? 'active' : ''}>{allStatus[status]}</span></div>
                                            <div className="agentDetailStatus">Showings:  <ShowingToggle showing_status={listData.showing_status}  agentId={listData.id}  showStatusshowing={this.showStatusshowing.bind(this)}/> <span className={(showing_status == '1') ? 'active' : ''}>{allShowingStatus[showing_status]}</span></div>
                                        </div>
                                        <div className="img-wrap pull-right">
                                            {/* {listData.image=='member.png' || listData.image==''?<img src={image} width="90" height="90" className="mx-auto d-block rounded-circle"/>:getBaseUrl()+"/containers/listing_images/download/" + listData.image} */}
                                            {listData.image.startsWith("https://")?<img src={listData.image} width="90" height="90" className="mx-auto d-block rounded-circle"/>:<img src={getBaseUrl()+"/containers/agent_image/download/" + listData.image} width="90" height="90" className="mx-auto d-block rounded-circle"/>}														
                                        </div>
                
                                    </div>
                                    <div className="contact-info">
                                        <div className="heading-wrap">
                                            Sort Order
                                        </div>
                                       <p>{this.state.sort_order==999?0:this.state.sort_order}<Link to className="editsmall"  onClick={this.onOpenSortModal} > <i className="fa fa-pencil "  aria-hidden="true"></i></Link></p> 
                                     </div>
                                    <div className="contact-info">
                                        <div className="heading-wrap">
                                           Alloted Slots <span style={{paddingLeft:'40px'}}>Available Slots</span>
                                        </div>
                                       <p>{this.state.allot_slots}<Link to className="editsmall"  onClick={this.onOpenModal} > <i className="fa fa-pencil "  aria-hidden="true"></i></Link><span style={{paddingLeft:'120px'}}>{this.state.agent_available_slot}</span></p> 
                                     </div>
                                        
                                    <div className="contact-info">
                                        <div className="heading-wrap">
                                            Contact 
                                        </div>
                                        <p>{(listData.mob_number == '' || listData.mob_number=='NA' || listData.mob_number==undefined) ? 'No Number':aes.decrypt(listData.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</p>
                                        <p><a href={'mailto:' + aes.decrypt(listData.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}>{aes.decrypt(listData.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</a></p>
                                    </div>
                                    <button type="button"  id="close" className="btn cl-btn pull-right" onClick={this.props.onClick} >Close  <img src="/close.png"/></button>                                                                       
                                </div> 
                            </div>
                        </div>

                    <Modal open={open} onClose={this.onCloseModal} center>
                    
                            <div className="modal-content">
                                <div className="modal-header" >
                                    <h4 className="modal-title">Add Slots</h4>
                                    {/* <button type="button" id="EditAllottedSlot" className="close" data-dismiss="modal">&times;</button> */}
                                </div>
                                <div className="modal-body" >
                                    <form >
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="total_slots"
                                            value={this.state.allot_slots}
                                            onChange={this.handleChange}

                                        />
                                        <span className="inline-error-class" style={{float :'left'}}>
                                            {" "}
                                            {this.state.slots_error !== ""
                                                ? this.state.slots_error
                                                : ""}
                                        </span><br/>
                                        <div><input type="button"
                                          onClick={this.editAllottedSlot}
                                            disabled={this.validation()}
                                            className="btn btn-default"
                                            value="Submit"
                                        /></div>
                                        
                                         
                                           
                                    </form>
                                    <button type="button" className="btn cl-btn pull-right" onClick={this.onCloseModal} >Close  <img src="/close.png"/></button>  
                                </div>

                            </div>
                     

                    </Modal>

                    <Modal open={sort_open} onClose={this.onCloseSortModal} center>
                    
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h4 className="modal-title">Edit Sort Order</h4>
                            {/* <button type="button" id="EditAllottedSlot" className="close" data-dismiss="modal">&times;</button> */}
                        </div>
                        <div className="modal-body" >
                            <form >
                                <input
                                    type="text"
                                    className="form-control"
                                    id="total_sort"
                                    defaultValue={this.state.sort_order==999?0:this.state.sort_order}
                                    onChange={this.handleSortChange}
                                    
                                />
                                <span className="inline-error-class" style={{float :'left'}}>
                                    {" "}
                                    {this.state.sort_error !== ""
                                        ? this.state.sort_error
                                        : ""}
                                </span><br/>
                                <div><input type="button"
                                  onClick={this.editSortOrder}
                                    disabled={this.validationSort()}
                                    className="btn btn-default"
                                    value="Submit"
                                /></div>
                                
                                 
                                   
                            </form>
                            <button type="button" className="btn cl-btn pull-right" onClick={this.onCloseSortModal} >Close  <img src="/close.png"/></button>  
                        </div>

                    </div>
             

            </Modal>

                    </main>				 
                </div>
                );
    }
}

export default AgentDetail;