import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/AgentHeader';
import SideBar from '../common/AgentSideBar';
import { Redirect } from 'react-router';
import {getApiData,postApiData, setAgentToken, getAgentToken, getAgentId,getBaseUrl} from '../../services/Api';
import LinkingDetail from './linkingPage';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';

let key = encHex.parse("0123456789abcdef0123456789abcdef");
	let iv =  encHex.parse("abcdef9876543210abcdef9876543210"); 
class Dashboard extends React.Component {

    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}

class Content extends React.Component {
	
	constructor(props) {

		

		super(props);
        this.state = {
			client_application:[],
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			searchClientId:'',
			searchParm:"",
			searchAddressId: '',
			searchAddressParm:'',
			addressParam:[],
			clientDW:[],
			statusFil:3,
			nameSearch:false,
			statusSearch:false,
			where:{},
			linkingLoader:false,
			clickedClientId:0,
			searchMsg:false,
			searchAddressMsg:false,
			myerrormsg:"",
			agentId:"",
			addmobile:'',
			approve_email_content:'',
			adverse_email_content:'',
			address:'',

			lease_made_month:'',
			lease_made_day:'',
			lease_made_year:'',
			owner_name:'',
			owner_address:'',
			tenant_name:'',
			tenant_address:'',
			owner_agrees_apartment:'',
			agrees_floor:'',
			agrees_building:'',
			agrees_borough:'',
			term_lease_year:'',
			term_lease_months:'',
			term_lease_days:'',
			term_lease_beginning:'',
			term_lease_ending:'',
			monthly_rent:'',
			security_deposit_amount:'',
			security_deposit_bank:'',
			security_deposit_bank_addr:'',
			facilities_rent:'',
			guaranty_apartment:'',
			guaranty_premises:'',
			guaranty_tenant:'',
			guaranty_expires:'',
			name_shown_income_tax:'',
			business_name:'',
			single_member_LLC:'',
			full_address:'',
			city_state_zip:'',
			social_security_number:'',
			employer_identification_number:'',
			name_of_tenant:'',
			lease_premises_address:'',
			apartment_number:'',
			date_of_lease:'',
			no_maintained:false,
			maintained:false,
			maintained_and_inspected:'',
			tenant_name1_signature:'',
			tenant_name1_date:'',
			tenant_name2_signature:'',
			tenant_name2_date:'',
			owner_name_signature:'',
			owner_name_date:'',
			nyc_tenant_name:'',
			nyc_sub_premises:'',
			nyc_apt:'',
			nyc_vacancy_lease_date:'',
			no_bedbug_history:false,
			bedbug_sub_eradication_measures:false,
			bedbug_no_sub_eradication_measures:false,
			employed_eradication_measures:false,
			not_employed_eradication_measures:false,
			bedbug_others:false,
			bedbug_others_reason:'',
			sign_of_teanant_date:'',
			sign_of_owner_date:'',
			certi_lessor1_date:'',
			certi_lessor2_date:'',
			certi_lessee1_date:'',
			certi_lessee2_date:'',
			certi_agent1_date:'',
			certi_agent2_date:'',
			child_live_apart:false,
			no_child_live_apart:false,
			want_win_gaurds:false,
			tenant_print:'',
			tenant_print_date:'',
			return_form_owner:'',
			child_under_six_live:false,
			child_under_six_no_live:false,
			print_occupant_name_addr_apart_no:'',
			return_this_form_to:'',
			building_located_at:'',
			representative_name:'',
			representative_sign_date:'',
		  };
		  this.createWhere();
		  this.search = this.search.bind(this);

		  // Added for DropdownClose on Clickoutside
		this.setWrapperRef1 = this.setWrapperRef1.bind(this);
		this.setWrapperRef2 = this.setWrapperRef2.bind(this);
		this.setWrapperRef3 = this.setWrapperRef3.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	
		}
		
	componentWillMount(){
		window.scroll(0,0);
		document.addEventListener('mousedown', this.handleClickOutside);
		var agent=getAgentId()
		this.getAgentdetail();
		this.getEmailContent();
		this.setState({agentId:agent},()=>{
			this.getClientApplication();
			this.clientDD();
			this.addressDD();
	

		})
		
	}
	componentDidMount() {
		
	 }	
        componentWillUnmount(){
					
			document.addEventListener('mousedown', this.handleClickOutside);
            this.getClientApplication();
	
            this.clientDD();
		}

		setWrapperRef1(node) {
			this.wrapperRef1 = node;
		  }
	
		  setWrapperRef2(node) {
			this.wrapperRef2 = node;
		  }

		  setWrapperRef3(node) {
			this.wrapperRef3 = node;
		  }
	
		  handleClickOutside(event) {
			  
			if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
				document.getElementById('search').classList.remove("show");
			}
	
			if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
				document.getElementById('status').classList.remove("show");
			  }
			  
			if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {
			document.getElementById('addressSearch').classList.remove("show");
			}
	
		  }

	//get application list
	async getClientApplication() {
		this.setState({linkingLoader:true});
		let applicationList = [{
            url: "/agent/applicationArchiveList",
		}];
		var len =Object.keys(this.state.where);
		if(len.length>0){
			applicationList[0].where=this.state.where;
		}
        getApiData(applicationList,true).then(res => {
			this.setState({'client_application':res.data,linkingLoader:false});
			document.getElementById('search').classList.remove("show");
		document.getElementById('status').classList.remove("show");
		document.getElementById('addressSearch').classList.remove("show");
		
        }).catch(error => {
			this.setState({linkingLoader:false});
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}

	// generate client Drop Down
	async clientDD(){
		let clientList = [{
						url: "/agent/clientDD",
						where:{ agent_id: this.state.agentId}
		}];
        getApiData(clientList,true).then(res => {
        this.setState({'clientDW':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}
	 

	async addressDD(){
		let addressParam = [{
				url: "/agent/addressDD",
				where:{ agent_id: this.state.agentId}
		}];
            getApiData(addressParam,true).then(res => {
				this.setState({'addressParam' :res.data});
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message});
                }
            });
	}
	//fliter reset
	resetFilter=()=>{
		this.setState({
			client_list:[],
			addressParam:[],
			where:{},
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
			searchClientId:'',
			searchAddressId:'',
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('searchParm').value='';
			document.getElementById('searchAddressParm').value='';
			document.getElementById('searchClick').classList.remove("active");
			document.getElementById('addressSearchClick').classList.remove("active");
			document.getElementById('Sent').checked = true;
			document.getElementById('submit').checked = true;
			document.getElementById('Incomplete').checked = true;
			this.clientDD();
			this.addressDD();
			this.createWhere();
		  });
	}

	resetsearchParm=()=>{
		this.setState({
			client_list:[],
			where:{},
			searchClientId:'',
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('searchParm').value='';
			document.getElementById('searchClick').classList.remove("active");
			this.clientDD();
			this.createWhere();

		  });
	}


	resetStatus=()=>{
		this.setState({
			client_list:[],
			where:{},
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
			myerrormsg:"",
		  },()=>{
			document.getElementById('Sent').checked = true;
			document.getElementById('submit').checked = true;
			document.getElementById('Incomplete').checked = true;
			this.createWhere();

		  });
	}

	//search handling
	search=(event) =>{
		this.setState({[event.target.id]: event.target.value});
	 var key=event.target.id; 
	  switch(key){
		case 'Sent':     
			if(this.state.ckSent.value==0 && this.state.ckSent.checked==false){
				this.state.ckSent.checked=true;
				this.state.ckSent.value=1;
				this.state.statusFil=1;
				this.createWhere();
			}else if(this.state.ckSent.value==1 && this.state.ckSent.checked==true){
				this.state.ckSent.checked=false;
				this.state.ckSent.value=0;
				this.createWhere();
			}
			break;
		case 'submit':
		if(this.state.ckSubmit.value==0 && this.state.ckSubmit.checked==false){
			this.state.ckSubmit.checked=true;
			this.state.ckSubmit.value=1;
			this.state.statusFil=1;
			this.createWhere();
		}else if(this.state.ckSubmit.value==1 && this.state.ckSubmit.checked==true){
			this.state.ckSubmit.checked=false;
			this.state.ckSubmit.value=0;
			this.createWhere();
		}        
			break;
		case 'Incomplete':  
		if(this.state.ckIncomplete.value==0 && this.state.ckIncomplete.checked==false){
			this.state.ckIncomplete.checked=true;
			this.state.ckIncomplete.value=1;
			this.state.statusFil=1;
			this.createWhere();
		}else if(this.state.ckIncomplete.value==1 && this.state.ckIncomplete.checked==true){
			this.state.ckIncomplete.checked=false;
			this.state.ckIncomplete.value=0;
			this.createWhere();
		}        
			break;
			case 'searchParm':
			this.setState({searchParm:event.target.value});
			//if(this.state.searchParm.length>1){	
				var value=this.state.searchParm.trim();
				if(event.key=='Enter'){
					this.getData(value);

				}else if(value==''){
					this.state.clientDW.forEach(function(client,index) {
							client.flag=0;
					});
					this.setState({searchMsg:false});
				}else {
					var len = this.state.clientDW.length;
					var count=0;
					var vflag=0;
					this.state.clientDW.forEach(function(client,index) {
						
						if(client.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
							client.flag=1;
							vflag=1;
							count=count+1;
						}else{
							client.flag=0;
							if(vflag!=1)
							vflag=0
							count=count+1;
						}
	
					});
					
					if(len==count){
						if(vflag==0){
							this.setState({searchMsg:true});
						}else{
							this.setState({searchMsg:false});
						}

						
					}


				}
		break;
		case 'searchkey':
		var value=event.target.value;
			this.setState({ searchClientId: event.target.value}, () => {
				this.state.clientDW.forEach(function(client,index) {
					if(client.name==value){
						document.getElementById('searchClick').classList.add("active");
						document.getElementById('searchParm').value=client.name;
					}else{
						client.flag=0;
					}
				});
				document.getElementById('searchClick').classList.add("active");
				this.createWhere();
			  });
		break;
		case 'searchAddressParm':
		this.setState({searchAddressParm:event.target.value});
		var value=event.target.value.trim();
		if(event.key=='Enter'){
			this.getAddressData(value);
		}else if(value==''){
			this.state.addressParam.forEach(function(client,index) {
				if(client.address.trim().toLowerCase().includes(value.trim().toLowerCase())){
					client.flag=0;
					}
				});
			this.setState({searchAddressMsg:false});
		}else {
			var len = this.state.addressParam.length;
			var count=0;
			var vflag=0;

			this.state.addressParam.forEach(function(client,index) {
				if(client.address.trim().toLowerCase().includes(value.trim().toLowerCase())){
						client.flag=1;
						vflag=1;
						count=count+1;
					}else{
						client.flag=0;
						if(vflag!=1)
						vflag=0
						count=count+1;
					}

				});

			if(len==count){
				if(vflag==0){
					this.setState({searchAddressMsg:true});
					
				}else{
					this.setState({searchAddressMsg:false});				
				}	
			}
		}
		break;
		case 'default':
		this.createWhere();
		break;
		 
		}
		if(this.state.statusFil>0){
			document.getElementById('statusKey').classList.add("active");
		}else{
			document.getElementById('statusKey').classList.remove("active");
		}
		
	  }
	  
	  //search clauses 
	async createWhere(){
		this.state.where={};
		if(this.state.ckSent.value==1){
			this.state.where.sent=3;
		}
		if(this.state.ckSubmit.value==1){
			this.state.where.submit=2;		
		}
		if(this.state.ckIncomplete.value==1){
			this.state.where.incomplete=1;
		}
		if(this.state.searchClientId!=''){
			this.state.where.searchClientId=this.state.searchClientId;
		}
		if(this.state.searchAddressId!=''){
			this.state.where.searchAddressId=this.state.searchAddressId;
		}
    this.state.where.agentId = getAgentId();
		this.getClientApplication();
	}
	
	//click event handling
	ClientHeadClick(){ document.getElementById('status').classList.remove("show");}
	StatusHeadClick(){document.getElementById('search').classList.remove("show");}
	
	//handleLinking
	handleLinking = param => e => {
		if(this.state.clickedClientId[3]==param[3]){
			this.setState({clickedClientId:0},()=>{
				this.setState({clickedClientId:param},()=>{});
			});
		}else{
			this.setState({clickedClientId:param},()=>{});
		}
		
	}
	handleClickOnList=param=>e=>{
		var value=param[0];
		this.getData(value);
	}
	AddressHeadClick(){
		document.getElementById('status').classList.remove("show");
		document.getElementById('addressSearch').classList.remove("show");
	}
	handleClickOnAddress=param=>e=>{
		var value=param[0];
		this.getAddressData(value);
	}
	getAddressData(value){
		this.setState({ searchAddressId: value}, () => {
			this.state.addressParam.forEach(function(client,index) {
				if(client.address==value){
					document.getElementById('addressSearchClick').classList.add("active");
					document.getElementById('searchAddressParm').value=client.address;
				}else{
					client.flag=0;
				}
			});
			document.getElementById('addressSearchClick').classList.add("active");
			this.createWhere();
		  });
	}
getData(value){
this.setState({ searchClientId: value}, () => {
	this.state.clientDW.forEach(function(client,index) {
		if(client.name==value){
			document.getElementById('searchClick').classList.add("active");
			document.getElementById('searchParm').value=client.name;
		}else{
			client.flag=0;
		}
	});
	document.getElementById('searchClick').classList.add("active");
	this.createWhere();
  });
}

handleRowClick=param=>e=>{

	if(param[1]=='Submitted'){
		this.setState({
			sendToEdit:1,
			clickedAppId:param[0]
		})
	}
	
}

checkApp2(value,val){
	if(val==0){
		document.getElementById(value).classList.remove('active');
	}

	if(val==1){
		document.getElementById(value).classList.add('active');
	}

	
	
	
	
    this.setState({myerrormsg:""});
	}
	downloadDocument=(appId,clientId)=>e=>{
		console.log("---",appId,clientId);
		let where=[{
			url:"/managers/downloadDocument/"+appId+"/"+clientId
		}];
		getApiData(where,true).then(res=>{
			// console.log("res----",res.data.Zipfile);
		var url= getBaseUrl()+"/containers/download_doc/download/"+res.data.Zipfile;
		// console.log("url",url);
		if(res.data.Zipfile){
			window.open(url);
		}
		else{
			alert("Unable to create zip,try again later.");
		}
		}).catch(error=>{

		})
	}
	archiveApplication=(appId)=>e=>{
	var r=window.confirm("Are your sure to make this application to archive?")
	if(r==true){

		let where=[{
			url:"/archiveApplication/"+appId
		}];
		getApiData(where,true).then(res=>{
			// console.log("res----",res.data.Zipfile);
		
		if(res.data.status){
			this.getClientApplication();
		}
		else{
			alert("Unable to create zip,try again later.");
		}
		}).catch(error=>{

		})
	}
	
	}	
	
	contentSave=(type)=>e=>{
		this.setState({linkingLoader:true})
		var divValue='';

		if(type=='adverse')
		 divValue=document.getElementById('divContentA').innerHTML;
		else
		 divValue=document.getElementById('divContent').innerHTML;

		let where=[{
			url:'/email/approvedata'
		}];
		// var formData = new FormData();
		// formData.append('content',abc);
		
		postApiData(where,{'agent_email':this.state.email,'content':divValue,'type':this.state.type,'app_id':this.state.appId,'client_email':this.state.client_email},true).then(res=>{
			
				this.setState({linkingLoader:false},()=>{
					document.getElementById('close').click();
					document.getElementById('Adverseclose').click();
				})
			this.getClientApplication();
				
			
		}).catch(error=>{

		})
	}

	Approve=(app_id,address,client_name,client_email,type)=>event=>{
		event.preventDefault();

		console.log(this.state.address,address);
		if(this.state.address){
		document.getElementById('approve_content_id').innerHTML=document.getElementById('approve_content_id').innerHTML.replace(this.state.address,address);
		}
		this.setState({
			appId:app_id,
			address:address,
			// agent_name:agent_name,
			client_name:client_name,
			client_email:aes.decrypt(client_email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8),
			// email:aes.decrypt(email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8),
			type:type
		},()=>{
			
		 document.getElementById('approve_content_id').innerHTML=document.getElementById('approve_content_id').innerHTML.replace("APARTMENT ADDRESS",address);
			
		})
	}

		getEmailContent=()=>{
         
			let where = [{
				url:'/agent/EmailContent/'
			}]
			getApiData(where,true).then(res=>{
					console.log('Email Contnet==>',res.data);
					this.setState({
						approve_email_content:res.data[0].html_value,
						adverse_email_content:res.data[1].html_value,
					})
					
					document.getElementById('approve_content_id').innerHTML=this.state.approve_email_content;
					document.getElementById('adverse_content_id').innerHTML=this.state.adverse_email_content;
					
			}).catch(error=>{

			})
		}
		getAgentdetail=()=>{
         
			let where = [{
				url:'/agent/Detail/'+getAgentId()
			}]
			getApiData(where,true).then(res=>{
					console.log("res",res);
					this.setState({
						agent_name:res.data.name,
						email:aes.decrypt(res.data.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)
					})
					
				
			}).catch(error=>{

			})
		}

//---Start Code for STANDARD FORM OF APARTMENT LEASE

getleaseriderdetail=(app_id)=>{
         
	let where = [{
		url:'/applications/getleaseriderdetail/'+app_id
	}]
	getApiData(where,true).then(res=>{
			console.log('getleaseriderdetail==>',res.data);
			var leaseriderdetail = res.data;
			if(leaseriderdetail!=undefined && leaseriderdetail!=""){
	this.setState({
	leaseriderId:leaseriderdetail.id,			
				
	lease_made_month:leaseriderdetail.lease_made_month,
	lease_made_day:leaseriderdetail.lease_made_day,
	lease_made_year:leaseriderdetail.lease_made_year,
	owner_name:leaseriderdetail.owner_name,
	owner_address:leaseriderdetail.owner_address,
	tenant_name:leaseriderdetail.tenant_name,
	tenant_address:leaseriderdetail.tenant_address,
	owner_agrees_apartment:leaseriderdetail.owner_agrees_apartment,
	agrees_floor:leaseriderdetail.agrees_floor,
	agrees_building:leaseriderdetail.agrees_building,
	agrees_borough:leaseriderdetail.agrees_borough,
	term_lease_year:leaseriderdetail.term_lease_year,
	term_lease_months:leaseriderdetail.term_lease_months,
	term_lease_days:leaseriderdetail.term_lease_days,
	term_lease_beginning:leaseriderdetail.term_lease_beginning,
	term_lease_ending:leaseriderdetail.term_lease_ending,
	monthly_rent:leaseriderdetail.monthly_rent,
	security_deposit_amount:leaseriderdetail.security_deposit_amount,
	security_deposit_bank:leaseriderdetail.security_deposit_bank,
	security_deposit_bank_addr:leaseriderdetail.security_deposit_bank_addr,
	facilities_rent:leaseriderdetail.facilities_rent,
	guaranty_apartment:leaseriderdetail.guaranty_apartment,
	guaranty_premises:leaseriderdetail.guaranty_premises,
	guaranty_tenant:leaseriderdetail.guaranty_tenant,
	guaranty_expires:leaseriderdetail.guaranty_expires,
	name_shown_income_tax:leaseriderdetail.name_shown_income_tax,
	business_name:leaseriderdetail.business_name,
	single_member_LLC:leaseriderdetail.single_member_LLC,
	full_address:leaseriderdetail.full_address,
	city_state_zip:leaseriderdetail.city_state_zip,
	social_security_number:leaseriderdetail.social_security_number,
	employer_identification_number:leaseriderdetail.employer_identification_number,
	name_of_tenant:leaseriderdetail.name_of_tenant,
	lease_premises_address:leaseriderdetail.lease_premises_address,
	apartment_number:leaseriderdetail.apartment_number,
	date_of_lease:leaseriderdetail.date_of_lease,
	no_maintained:(leaseriderdetail.no_maintained==1)?true:false,
	maintained:(leaseriderdetail.maintained==1)?true:false,
	maintained_and_inspected:leaseriderdetail.maintained_and_inspected,
	tenant_name1_signature:leaseriderdetail.tenant_name1_signature,
	tenant_name1_date:leaseriderdetail.tenant_name1_date,
	tenant_name2_signature:leaseriderdetail.tenant_name2_signature,
	tenant_name2_date:leaseriderdetail.tenant_name2_date,
	owner_name_signature:leaseriderdetail.owner_name_signature,
	owner_name_date:leaseriderdetail.owner_name_date,
	nyc_tenant_name:leaseriderdetail.nyc_tenant_name,
	nyc_sub_premises:leaseriderdetail.nyc_sub_premises,
	nyc_apt:leaseriderdetail.nyc_apt,
	nyc_vacancy_lease_date:leaseriderdetail.nyc_vacancy_lease_date,
	no_bedbug_history:(leaseriderdetail.no_bedbug_history==1)?true:false,
	bedbug_sub_eradication_measures:(leaseriderdetail.bedbug_sub_eradication_measures==1)?true:false,
	bedbug_no_sub_eradication_measures:(leaseriderdetail.bedbug_no_sub_eradication_measures==1)?true:false,
	employed_eradication_measures:(leaseriderdetail.employed_eradication_measures==1)?true:false,
	not_employed_eradication_measures:(leaseriderdetail.not_employed_eradication_measures==1)?true:false,
	bedbug_others:(leaseriderdetail.bedbug_others==1)?true:false,
	bedbug_others_reason:leaseriderdetail.bedbug_others_reason,
	sign_of_teanant_date:leaseriderdetail.sign_of_teanant_date,
	sign_of_owner_date:leaseriderdetail.sign_of_owner_date,
	certi_lessor1_date:leaseriderdetail.certi_lessor1_date,
	certi_lessor2_date:leaseriderdetail.certi_lessor2_date,
	certi_lessee1_date:leaseriderdetail.certi_lessee1_date,
	certi_lessee2_date:leaseriderdetail.certi_lessee2_date,
	certi_agent1_date:leaseriderdetail.certi_agent1_date,
	certi_agent2_date:leaseriderdetail.certi_agent2_date,
	child_live_apart:(leaseriderdetail.child_live_apart==1)?true:false,
	no_child_live_apart:(leaseriderdetail.no_child_live_apart==1)?true:false,
	want_win_gaurds:(leaseriderdetail.want_win_gaurds==1)?true:false,
	tenant_print:leaseriderdetail.tenant_print,
	tenant_print_date:leaseriderdetail.tenant_print_date,
	return_form_owner:leaseriderdetail.return_form_owner,
	child_under_six_live:(leaseriderdetail.child_under_six_live==1)?true:false,
	child_under_six_no_live:(leaseriderdetail.child_under_six_no_live==1)?true:false,
	print_occupant_name_addr_apart_no:leaseriderdetail.print_occupant_name_addr_apart_no,
	return_this_form_to:leaseriderdetail.return_this_form_to,
	building_located_at:leaseriderdetail.building_located_at,
	representative_name:leaseriderdetail.representative_name,
	representative_sign_date:leaseriderdetail.representative_sign_date,
	
				
			})
	}
	else{
		this.setState({
			leaseriderId:'',			
			
			lease_made_month:'',
			lease_made_day:'',
			lease_made_year:'',
			owner_name:'',
			owner_address:'',
			tenant_name:'',
			tenant_address:'',
			owner_agrees_apartment:'',
			agrees_floor:'',
			agrees_building:'',
			agrees_borough:'',
			term_lease_year:'',
			term_lease_months:'',
			term_lease_days:'',
			term_lease_beginning:'',
			term_lease_ending:'',
			monthly_rent:'',
			security_deposit_amount:'',
			security_deposit_bank:'',
			security_deposit_bank_addr:'',
			facilities_rent:'',
			guaranty_apartment:'',
			guaranty_premises:'',
			guaranty_tenant:'',
			guaranty_expires:'',
			name_shown_income_tax:'',
			business_name:'',
			single_member_LLC:'',
			full_address:'',
			city_state_zip:'',
			social_security_number:'',
			employer_identification_number:'',
			name_of_tenant:'',
			lease_premises_address:'',
			apartment_number:'',
			date_of_lease:'',
			no_maintained:false,
			maintained:false,
			maintained_and_inspected:'',
			tenant_name1_signature:'',
			tenant_name1_date:'',
			tenant_name2_signature:'',
			tenant_name2_date:'',
			owner_name_signature:'',
			owner_name_date:'',
			nyc_tenant_name:'',
			nyc_sub_premises:'',
			nyc_apt:'',
			nyc_vacancy_lease_date:'',
			no_bedbug_history:false,
			bedbug_sub_eradication_measures:false,
			bedbug_no_sub_eradication_measures:false,
			employed_eradication_measures:false,
			not_employed_eradication_measures:false,
			bedbug_others:false,
			bedbug_others_reason:'',
			sign_of_teanant_date:'',
			sign_of_owner_date:'',
			certi_lessor1_date:'',
			certi_lessor2_date:'',
			certi_lessee1_date:'',
			certi_lessee2_date:'',
			certi_agent1_date:'',
			certi_agent2_date:'',
			child_live_apart:false,
			no_child_live_apart:false,
			want_win_gaurds:false,
			tenant_print:'',
			tenant_print_date:'',
			return_form_owner:'',
			child_under_six_live:false,
			child_under_six_no_live:false,
			print_occupant_name_addr_apart_no:'',
			return_this_form_to:'',
			building_located_at:'',
			representative_name:'',
			representative_sign_date:'',
			
						
					})
	}
	
		
	}).catch(error=>{

	})
}

		setdata=(app_id,client_id,agent_id)=>event=>{
			event.preventDefault();
			
			this.setState({
				appId:app_id,
				clientId:client_id,
				agentId:getAgentId()
			})
			this.getleaseriderdetail(app_id);
		}

		handleOnChnage=(e)=>{
			let id = e.target.id;
			let value = e.target.value;
			if(value==""){
				this.setState({
					[id]:''
				})
			}
			else{
				this.setState({
					[id]:value
				})
			}
	   }

	   onchangecheckbox=(e)=>{
		   let id = e.target.id;
		   let value = e.target.value;
		   if(e.target.checked){
			   this.setState({
				   [id]:!this.state[id]
			   })
		   }
	   }

	   handleSubmit= (e) =>{
		
		 e.preventDefault();
		 this.setState({linkingLoader:true})
		   const formData = new FormData();
		   if(this.state.leaseriderId!=undefined && this.state.leaseriderId!="")
		   {
			   formData.append('id',this.state.leaseriderId)
		   }
		   formData.append('app_id',this.state.appId)
		   formData.append('client_id',this.state.clientId)
		   formData.append('agent_id',this.state.agentId)
		   formData.append('lease_made_month',this.state.lease_made_month)
		   formData.append('lease_made_day',this.state.lease_made_day)
		   formData.append('lease_made_year',this.state.lease_made_year)
		   formData.append('owner_name',this.state.owner_name)
		   formData.append('owner_address',this.state.owner_address)
		   formData.append('tenant_name',this.state.tenant_name)
		   formData.append('tenant_address',this.state.tenant_address)
		   formData.append('owner_agrees_apartment',this.state.owner_agrees_apartment)
		   formData.append('agrees_floor',this.state.agrees_floor)
		   formData.append('agrees_building',this.state.agrees_building)
		   formData.append('agrees_borough',this.state.agrees_borough)
		   formData.append('term_lease_year',this.state.term_lease_year)
		   formData.append('term_lease_months',this.state.term_lease_months)
		   formData.append('term_lease_days',this.state.term_lease_days)
		   formData.append('term_lease_beginning',this.state.term_lease_beginning)
		   formData.append('term_lease_ending',this.state.term_lease_ending)
		   formData.append('monthly_rent',this.state.monthly_rent)
		   formData.append('security_deposit_amount',this.state.security_deposit_amount)
		   formData.append('security_deposit_bank',this.state.security_deposit_bank)
		   formData.append('security_deposit_bank_addr',this.state.security_deposit_bank_addr)
		   formData.append('facilities_rent',this.state.facilities_rent)
		   formData.append('guaranty_apartment',this.state.guaranty_apartment)
		   formData.append('guaranty_premises',this.state.guaranty_premises)
		   formData.append('guaranty_tenant',this.state.guaranty_tenant)
		   formData.append('guaranty_expires',this.state.guaranty_expires)
		   formData.append('name_shown_income_tax',this.state.name_shown_income_tax)
		   formData.append('business_name',this.state.business_name)
		   formData.append('single_member_LLC',this.state.single_member_LLC)
		   formData.append('full_address',this.state.full_address)
		   formData.append('city_state_zip',this.state.city_state_zip)
		   formData.append('social_security_number',this.state.social_security_number)
		   formData.append('employer_identification_number',this.state.employer_identification_number)
		   formData.append('name_of_tenant',this.state.name_of_tenant)
		   formData.append('lease_premises_address',this.state.lease_premises_address)
		   formData.append('apartment_number',this.state.apartment_number)
		   formData.append('date_of_lease',this.state.date_of_lease)
		   formData.append('no_maintained',(this.state.no_maintained==true)?1:0)
		   formData.append('maintained',(this.state.maintained==true)?1:0)
		   formData.append('maintained_and_inspected',this.state.maintained_and_inspected)
		   formData.append('tenant_name1_signature',this.state.tenant_name1_signature)
		   formData.append('tenant_name1_date',this.state.tenant_name1_date)
		   formData.append('tenant_name2_signature',this.state.tenant_name2_signature)
		   formData.append('tenant_name2_date',this.state.tenant_name2_date)
		   formData.append('owner_name_signature',this.state.owner_name_signature)
		   formData.append('owner_name_date',this.state.owner_name_date)
		   formData.append('nyc_tenant_name',this.state.nyc_tenant_name)
		   formData.append('nyc_sub_premises',this.state.nyc_sub_premises)
		   formData.append('nyc_apt',this.state.nyc_apt)
		   formData.append('nyc_vacancy_lease_date',this.state.nyc_vacancy_lease_date)
		   formData.append('no_bedbug_history',(this.state.no_bedbug_history==true)?1:0)
		   formData.append('bedbug_sub_eradication_measures',(this.state.bedbug_sub_eradication_measures==true)?1:0)
		   formData.append('bedbug_no_sub_eradication_measures',(this.state.bedbug_no_sub_eradication_measures==true)?1:0)
		   formData.append('employed_eradication_measures',(this.state.employed_eradication_measures==true)?1:0)
		   formData.append('not_employed_eradication_measures',(this.state.not_employed_eradication_measures==true)?1:0)
		   formData.append('bedbug_others',(this.state.bedbug_others==true)?1:0)
		   formData.append('bedbug_others_reason',this.state.bedbug_others_reason)
		   formData.append('sign_of_teanant_date',this.state.sign_of_teanant_date)
		   formData.append('sign_of_owner_date',this.state.sign_of_owner_date)
		   formData.append('certi_lessor1_date',this.state.certi_lessor1_date)
		   formData.append('certi_lessor2_date',this.state.certi_lessor2_date)
		   formData.append('certi_lessee1_date',this.state.certi_lessee1_date)
		   formData.append('certi_lessee2_date',this.state.certi_lessee2_date)
		   formData.append('certi_agent1_date',this.state.certi_agent1_date)
		   formData.append('certi_agent2_date',this.state.certi_agent2_date)
		   formData.append('child_live_apart',(this.state.child_live_apart==true)?1:0)
		   formData.append('no_child_live_apart',(this.state.no_child_live_apart==true)?1:0)
		   formData.append('want_win_gaurds',(this.state.want_win_gaurds==true)?1:0)
		   formData.append('tenant_print',this.state.tenant_print)
		   formData.append('tenant_print_date',this.state.tenant_print_date)
		   formData.append('return_form_owner',this.state.return_form_owner)
		   formData.append('child_under_six_live',(this.state.child_under_six_live==true)?1:0)
		   formData.append('child_under_six_no_live',(this.state.child_under_six_no_live==true)?1:0)
		   formData.append('print_occupant_name_addr_apart_no',this.state.print_occupant_name_addr_apart_no)
		   formData.append('return_this_form_to',this.state.return_this_form_to)
		   formData.append('building_located_at',this.state.building_located_at)
		   formData.append('representative_name',this.state.representative_name)
		   formData.append('representative_sign_date',this.state.representative_sign_date)
		   

	   
		   let where;
	   if(this.state.leaseriderId!=undefined && this.state.leaseriderId!="")
		   {
		   
				where = [{
				   url: '/creator/updateleaseriderdetail'
				 }];
		   }

		   else{
			   
				where = [{
				   url: '/creator/addLease_rider_detail'
				 }];
		   }

			 postApiData(where,formData,true).then(res => {
			   console.log('res', res);
					 if(res) 
					 {
					   //document.getElementById('FormModal').click();
					   this.setState({linkingLoader:false})
						   
					 }
					
	   }).catch(error => {
	   alert('error',error);
	   });


	   }

//---End STANDARD FORM OF APARTMENT LEASE
handleDownload= (e) =>{
	e.preventDefault();
	let where = [{
		url: "/downloadFile",
	}];
	postApiData(where,{'app_id':this.state.appId},true)
	.then(res => {
		var url= getBaseUrl()+"/containers/lease_pdf/download/"+res.data.data+"/true";
		window.open(url);
		console.log("res=>=>",url);
	}).catch(error => {
		console.log("res=>=>",error);
		});
}	
	
    render() {
			
		if(this.state.sendToEdit!=undefined && this.state.sendToEdit==1){
			return (<Redirect to={'/agent/Appeditor/'+this.state.clickedAppId}/>);
		}
		if (!getAgentToken()) {
            return (<Redirect to={'/agent/landing'}/>);
		  }
const clientDW = this.state.clientDW.map((dw,index) => 
(dw.flag==1) ? <li id="searchkey" onClick={this.handleClickOnList([dw.name])}>{dw.name}</li>:''
);
const addressDW = this.state.addressParam.map((sw,index) => 
			(sw.flag==1) ?<li  onClick={this.handleClickOnAddress([sw.address])}>{sw.address}</li>:''
		);
var listItems='';
		if(this.state.client_application.length>0){
			listItems = this.state.client_application.map((application,index) => 
			<tr style={(application.status=='Submitted')?{cursor:'pointer'}:{cursor:''}} >
				<td onClick={this.handleRowClick([application.appid,application.status])}>{application.apartment_address}</td>
				<td onClick={this.handleRowClick([application.appid,application.status])}>{application.unit_number}</td>
				<td  className="managerGridCol" onClick={this.handleRowClick([application.appid,application.status])}>{application.realm}</td>
				<td  className="managerGridCol" onClick={this.handleRowClick([application.appid,application.status])}>{application.status}</td>
				{/*<td className="managerGridCol" ><Link to  onClick={this.downloadDocument(application.appid,application.clientId)}><i class="fa fa-download" aria-hidden="true"></i></Link></td>
				
				<td className="managerGridCol text-center"><Link className="approve-wrap" to onClick={this.Approve(application.appid,application.apartment_address,application.realm,application.email,'approve')}  data-toggle="modal" data-target="#Approve"> {application.approve==1 ?<i class="fa fa-usd" style={{fontWeight:"bold"}}></i>:""}</Link></td>
		<td className="managerGridCol text-center"><Link className="approve-wrap" to onClick={this.Approve(application.appid,application.apartment_address,application.realm,application.email,'adverse')}  data-toggle="modal" data-target="#AdverseAction"> {application.adverse==1 ? <i class="fa fa-times" ></i>:""}</Link></td>*/}
				{(application.status=='Submitted')?
				<td width="170" className="text-center actions-grid">
				<Link to title="Download Documents" onClick={this.downloadDocument(application.appid,application.clientId)}><i class="fa fa-download" aria-hidden="true"></i></Link>
				<Link className="approve-wrap" to title="Approve" onClick={this.Approve(application.appid,application.apartment_address,application.realm,application.email,'approve')}  data-toggle="modal" data-target="#Approve"> {application.approve==1 ?<i class="fa fa-usd" style={{fontWeight:"bold"}}></i>:""}</Link>
				<Link className="approve-wrap" to title="Adverse" onClick={this.Approve(application.appid,application.apartment_address,application.realm,application.email,'adverse')}  data-toggle="modal" data-target="#AdverseAction"> {application.adverse==1 ? <i class="fa fa-times" ></i>:""}</Link>
				<Link to  id={application.appid} data-toggle="collapse" data-target={"#client"+index} key={application.clientId}  onClick={this.handleLinking([application.clientId,application.apartment_address,application.unit_number,application.appid,index,this.state.agentId])} className={(application.linked_application!=0) ? "icon-wrap link active":"icon-wrap link"}></Link>	


											<div id={"client"+index} class="collapse table-collapse link-collapse">

												<div className="box">
												
												{(this.state.clickedClientId[3]==application.appid)?<LinkingDetail  handleLocationSubmit = {this.checkApp2.bind(this)} reqData={this.state.clickedClientId}/>:''}

												</div>
											</div>
											<Link to="" data-toggle="modal" data-target="#FormModal" onClick={this.setdata(application.appid,application.clientId,application.agentId)}><i class="fa fa-file-text"></i></Link>
											
											
											</td>:<td className="text-center actions-grid"></td>}

																	
			</tr>
);	
		}else{
			listItems = 
			<tr>
			<td colSpan="5">No Record found for the searched criteria.</td>				
		</tr>
		}
		
        return (

					
                <div >
                    <Header />
                    <main>
					<SideBar/>
				
					<div className="content">
						<div className="work-section">
						<div className="dashboard" >
						{(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}


							
								<h2 className="pull-left">Applications</h2>
								<button onClick={this.resetFilter} className="btn btn-default pull-right">Reset all filters</button>
								<div className="panel-body table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
													{/* ##################################### */}
													<th className="managerGridCol">Address<Link to  id="addressSearchClick" data-toggle="collapse" data-target="#addressSearch" className="icon-wrap search" onClick={this.AddressHeadClick}></Link>
													<div id="addressSearch" className="collapse table-collapse" ref={this.setWrapperRef3}>

													<div className="box">
												
													<input type="text" id="searchAddressParm" autocomplete="off" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
													
														<ul>
														{addressDW}
														</ul>
														{(this.state.searchAddressMsg)?'No suggestions available':''}
													</div>
												</div>
												</th>
												{/* ##################################### */}
												<th>Unit #</th>
												<th>Client name <Link to  id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="search" class="collapse table-collapse" ref={this.setWrapperRef1}>

													<div className="box">
												
													<input type="text" id="searchParm" autocomplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
													
														<ul>
														{clientDW}
														</ul>
														{(this.state.searchMsg)?'No suggestions available':''}
													</div>
												</div>
												
												</th>
												<th>Status <Link to id="statusKey" data-toggle="collapse" data-target="#status" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="status" class="collapse table-collapse status-collapse" ref={this.setWrapperRef2}>
												<div className="filterSearchReset"><Link to className="clearFilter" onClick={this.resetStatus}><i class="fa fa-undo"></i> Reset</Link></div>
													<div className="box">
													<div className="filterSearchReset"><Link to className="clearFilter" onClick={this.resetStatus}><i class="fa fa-undo"></i> Reset</Link></div>
													<input type="checkbox" id="Sent" defaultChecked={this.state.ckSent.checked} value={this.state.ckSent.value} onChange={this.search} />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" defaultChecked={this.state.ckSubmit.checked} value={this.state.ckSubmit.value} onChange={this.search}/>
													<label for="submit">Submitted</label>
													<input type="checkbox" id="Incomplete"  defaultChecked={this.state.ckIncomplete.checked} value={this.state.ckIncomplete.value} onChange={this.search}/>
													<label for="Incomplete">Incomplete</label>
													</div>
												</div>
												
												</th>
													{/*<th>Download</th>
												<th className="text-center">Approve</th>
													<th className="text-center">Adverse Action</th>*/}
													 <th width="160" className="text-center">Actions</th> 
											</tr>
										</thead>
										<tbody>
										{(this.state.client_application.length>0 && !this.state.linkingLoader)?listItems:(this.state.client_application.length==0 && !this.state.linkingLoader)?<tr><td colSpan="6" className="managerGridCol">No Record Found</td></tr>:'' }
										</tbody>
										</table>
									</div>
							</div>
							
						</div>
					</div>
	{/*Start Approve Modal */}
	<div class="application-approval">				
  <div class="modal fade" id="Approve" role="dialog">
    <div class="modal-dialog">
		        
      <div class="modal-content">
        <div class="modal-header">        
          <h4 class="modal-title">Congrats on your approval!!</h4>
        </div>
		<div class="application-approval-confirm">		  
		  <button type="button" onClick={this.contentSave('approve')} class="btn btn-default">Confirm</button>
          <button type="button" id="close" class="btn btn-default" data-dismiss="modal">Cancel</button>
        </div>
        <div class="modal-body">
		
		<div id="divContent" class="application-details" contenteditable="true">	
		
		  <p>Dear {this.state.client_name},</p>
		  <div id="approve_content_id">
		     
		  </div>
		  <div class="application-approval-footer">
			  Sincerely, <br/>
			{this.state.agent_name} <br/>
			{this.state.email}      <br/>
			{/* Agent@moveskyward.com <br/> */}
		  </div>
		  
		</div>
		
        </div>      
      </div>     
    </div>
  </div>
</div>
{/*End Approve Modal*/}	


{/*Start STANDARD FORM OF APARTMENT LEASE */}
	<div class="application-approval">				
  <div class="modal fade" id="FormModal" role="dialog">
    <div class="modal-dialog">
		        
      <div class="modal-content">
        <div class="modal-header">        
          <h4 class="modal-title">STANDARD FORM OF APARTMENT LEASE </h4>
		  <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
	
        <div class="modal-body">
		
		<div style={{fontSize:'11px'}}>
		<page size="A4">
					<div  style={{textAlign:'center', padding:'10px 30px 0px', }} contenteditable="false">
						<table width="65%" align="center" cellpadding="0" cellspacing="0" contenteditable="false">
										<tr>
											
											<td valign="bottom" align="center" contenteditable="false" style={{border:'1px solid #000'}}>
													
													<p contenteditable="false" style={{margin: '0', padding:'6px 0', lineHeight:'10px'}}>STANDARD FORM OF APARTMENT LEASE<br/>
													<span className="f10">(FOR APARTMENTS NOT SUBJECT TO THE RENT STABILIZATION LAW)</span><br/>
													<span className="f10">THE REAL ESTATE BOARD OF NEW YORK, INC.</span><br/>
													<span className="f10">© Copyright 1988. All Rights Reserved. Reproduction in whole or in part prohibited</span></p>
											</td>
											
										</tr>
									</table>
					</div>

					<div style={{padding:'12px 15px 10px',  fontSize:'9px',	lineHeight:'8px'}}>
						<label contenteditable="false" >
							<strong>PREAMBLE:</strong> This lease contains the agreements between You and Owner concerning Your rights
 and obligations and the rights and obligations of Owner. You and Owner have other rights and obligations which are set forth in government laws and regulations.  </label><br/>
						<label contenteditable="false">You should read this Lease and all of its attached parts carefully. If you have any questions, or if you do not understand any words or statements, get clarification. Once you and Owner sign this Lease You and Owner will be presumed to have read it and understood it. You and Owner admit that all agreements between You and Owner have been written into this Lease. You understand that any agreements made before or after this Lease was signed and not written into it will not be enforceable.</label>
	
						<table width="100%" cellpadding="0" cellspacing="4" style={{textTransform:'inherit'}} className="f10">
							<tr>
								<td contenteditable="false" style={{paddingBottom:'5px'}}>
								
								<table width="100%" cellpadding="0" cellspacing="0" style={{textTransform:'inherit'}} className="f10">
									<tr>
										<td contenteditable="false" style={{width:'20%'}}><label contenteditable="false">THIS LEASE is made on</label></td>
										<td contenteditable="false" style={{borderBottom:'1px solid #000000', textAlign:'center'}} width="71%">
										
										<table width="60%" align="center" cellpadding="0" cellspacing="0">
											<tr>
												<td contenteditable="false" width="33.33%"><input id="lease_made_month" value={this.state.lease_made_month} type="text" style={{border:'0px', width:'62px', margin:'0px 5px', fontSize:'11px'}}  onChange={this.handleOnChnage} /></td>
												<td contenteditable="false" width="33.33%"><input id="lease_made_day" value={this.state.lease_made_day} type="text" style={{border:'0px', width:'62px', margin:'0px 5px', fontSize:'11px'}}   onChange={this.handleOnChnage}/></td>
												<td contenteditable="false" width="33.33%"><input id="lease_made_year" value={this.state.lease_made_year} type="text" style={{border:'0px', width:'62px', margin:'0px 5px', fontSize:'11px'}}  onChange={this.handleOnChnage}/></td>
											</tr>
										
										</table>
										
										</td>
										<td contenteditable="false"><label contenteditable="false">Between</label></td>
										                                                                                                               
									</tr>
								</table>
								<table width="100%" cellpadding="0" cellspacing="0" style={{textTransform:'inherit'}} className="f10">
									<tr>
										<td contenteditable="false" width="20%"></td>
										<td contenteditable="false" width="71%">
										
										<table width="60%" align="center" cellpadding="0" cellspacing="0">										
											<tr>
												
												<td contenteditable="false" style={{textAlign:'center'}} width="33.33%"><label contenteditable="false">month</label></td>
												<td contenteditable="false"style={{textAlign:'center'}} width="33.33%"><label contenteditable="false">day</label></td>
												<td contenteditable="false" style={{textAlign:'center'}} width="33.33%"><label contenteditable="false">Year</label></td>
											</tr>
										</table>
										
										</td>
										<td contenteditable="false"></td>
										                                                                                                               
									</tr>
								</table>
								
								</td>
								
							</tr>
							<tr>
								<td contenteditable="false" style={{overflow:'hidden'}}>
									
									<label contenteditable="false" class="left1">Owner,</label>
									<div class="left2" contenteditable="false"><input type="text" id="owner_name" value={this.state.owner_name} className="bor-b inline-B w100" maxlength="300" onChange={this.handleOnChnage}/></div>
									
									
								</td>
							</tr>
							<tr>
								<td contenteditable="false" style={{overflow:'hidden'}}>
									
									<label contenteditable="false" class="left1">whose address is</label>
									<div class="left2" contenteditable="false"><input type="text" id="owner_address" value={this.state.owner_address} className="bor-b inline-B w100"  maxlength="400" onChange={this.handleOnChnage}/></div>
									
									
								</td>
							</tr>
							<tr>
								<td contenteditable="false" style={{overflow:'hidden'}}>
									
									<label contenteditable="false" class="left1">and You, the Tenant,</label>
									<div class="left2" contenteditable="false"><input type="text" id="tenant_name" value={this.state.tenant_name} className="bor-b inline-B w100"  maxlength="400" onChange={this.handleOnChnage}/></div>
									
									
								</td>
							</tr>
							<tr>
								<td contenteditable="false" style={{overflow:'hidden'}}>
									
									<label contenteditable="false" class="left1">whose address is</label>
									<div class="left2" contenteditable="false"><input type="text" id="tenant_address" value={this.state.tenant_address} className="bor-b inline-B w100"  maxlength="400" onChange={this.handleOnChnage}/></div>
									
									
								</td>
							</tr>
							<tr>
								<td contenteditable="false" style={{overflow:'hidden'}}>
									
									<strong>1.       <span style={{marginLeft:'15px'}}>APARTMENT AND USE	</span>	</strong>							
								</td>
							</tr>
							<tr>
								<td contenteditable="false">
									<label contenteditable="false"><img src="/hand.png" height="10"/> Owner agrees to lease to You Apartment </label>		
									<input type="text"  className="bor-b" id="owner_agrees_apartment" value={this.state.owner_agrees_apartment} onChange={this.handleOnChnage}   style={{width:'98px'}} maxlength="30"/>
									<label contenteditable="false"> On the </label>		
									<input type="text" className="bor-b inline-B" id="agrees_floor" value={this.state.agrees_floor} onChange={this.handleOnChnage} style={{width:'95px'}} maxlength="30"/>
									<label contenteditable="false">floor in the building at</label>
								</td>
							</tr>
							<tr>
								<td contenteditable="false">
										
									<input type="text"  className="bor-b" id="agrees_building" value={this.state.agrees_building} onChange={this.handleOnChnage}  style={{width:'200px'}} maxlength="30"/>
									<label contenteditable="false"> Borough of </label>		
									<input type="text"  className="bor-b " id="agrees_borough" value={this.state.agrees_borough} onChange={this.handleOnChnage} style={{width:'148px'}} maxlength="30"/>
									<label contenteditable="false">, City and State of New York.</label>
									<label  contenteditable="false">You shall use the Apartment for living purposes only. The Apartment may be occupied by the tenant or 
										tenants named above and by the immediate family of the tenant or tenants and by occupants as defined in and only 
										in accordance with Real Property Law § 235-f
									</label>
								</td>
							</tr>
							<tr>
									<td contenteditable="false" style={{overflow:'hidden'}}>
										
										<strong>2.      <span style={{marginLeft:'15px'}}>LENGTH OF LEASE	</span>	</strong>							
									</td>
								</tr>
								<tr>
									<td contenteditable="false">
										<label contenteditable="false"><img src="/hand.png" height="10"/> The term (that means the length) of this Lease is</label>		
										<input type="text" id="term_lease_year" value={this.state.term_lease_year} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', fontSize:'10px',  width:'98px'}} maxlength="30"/>
										<label contenteditable="false"> Year</label>		
										<input type="text" id="term_lease_months" value={this.state.term_lease_months} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', fontSize:'10px',  width:'138px'}} maxlength="30"/>
										<label contenteditable="false">Month</label>
									</td>
								</tr>
								<tr>
									<td contenteditable="false">
											
										<input type="text" id="term_lease_days" value={this.state.term_lease_days} onChange={this.state.term_lease_days} style={{border:'0px', borderBottom:'1px solid #000', fontSize:'10px',  width:'200px'}} maxlength="70"/>
										<label contenteditable="false">days, beginning on </label>		
										<input type="text" id="term_lease_beginning" value={this.state.term_lease_beginning} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', fontSize:'10px',  width:'242px'}} maxlength="80"/>
										<label contenteditable="false">and ending on</label> <input type="text" id="term_lease_ending" value={this.state.term_lease_ending} onChange={this.handleOnChnage} className="bor-b " style={{width:'150px'}} maxlength="50"/>
										<label  contenteditable="false">. If You do not do everything You agree to do in this Lease, Owner may have the right to end it before the above date. If Owner does not do everything that owner agrees to do in this Lease, You may have the right to end the Lease before ending date.
										</label>
									</td>
								</tr>
								<tr>
										<td contenteditable="false" style={{overflow:'hidden'}}>
											
											<strong>3.     <span style={{marginLeft: '15px'}}>RENT</span>		</strong>							
										</td>
									</tr>
									<tr>
										<td contenteditable="false">
											<label contenteditable="false" class="left1"><img src="/hand.png" height="10"/> Your monthly rent for the Apartment is $
											</label>		
											<div class="left2" contenteditable="false"><input type="text" id="monthly_rent" value={this.state.monthly_rent} onChange={this.handleOnChnage} style={{width:'100%'}} className="bor-b" maxlength="80"/></div>
													
											<input type="text" style={{width:'99.5%'}}  className="bor-b" maxlength="300"/>
											<label contenteditable="false">You must pay Owner the rent, in advance, on the first day of each month either at Owner's office or at another place that Owner
													may inform You of by written notice. You must pay the first month's rent to Owner when You sign
													this Lease if the lease begins on the first day of the month. If the Lease begins after the first day of the month, You must pay
													when you sign this lease (1) the part of the rent from the beginning date of this Lease until the last day of the month and (2 ) the
													full rent for the next full calendar month. If this Lease is a Renewal Lease, the rent for the first month of this Lease need not be
													paid until the first day of the month when the renewal term begins</label>
										</td>
									</tr>
									<tr>
											<td contenteditable="false" style={{overflow:'hidden'}}>
												
												<strong>4.  <span style={{marginLeft: '15px'}}>SECURITY DEPOSIT</span>		</strong>							
											</td>
										</tr>
										<tr>
											<td contenteditable="false">
												<label contenteditable="false" ><img src="/hand.png" height="10"/> You are required to give Owner the sum of $
												</label>		
												<input type="text" id="security_deposit_amount" value={this.state.security_deposit_amount} onChange={this.handleOnChnage} style={{width:'133px'}} className="bor-b" maxlength="80"/>
												<label contenteditable="false" >when You Sign this Lease as a security</label>		
												<label contenteditable="false" class="left1"> deposit, which is called in law a trust. Owner will deposit this security in

												</label>		
												<div class="left2" contenteditable="false"><input type="text" id="security_deposit_bank" value={this.state.security_deposit_bank} onChange={this.handleOnChnage} style={{width:'100%'}} className="bor-b" maxlength="80"/></div>
													
												<input type="text" className="bor-b" style={{width:'182px'}} maxlength="80"/>
												<label contenteditable="false" >bank at</label>	
												<input type="text" id="security_deposit_bank_addr" value={this.state.security_deposit_bank} onChange={this.handleOnChnage} style={{width:'200px'}} className="bor-b" maxlength="80"/>
												<label contenteditable="false" >. If the Building contains
												</label>	
												
												<label contenteditable="false">six or more apartments, the bank account will earn interest. If You carry out all of your agreements in this Lease, at the end of
														each calendar year Owner or the bank will pay to Owner 1% interest on the deposit for administrative costs and to You all other
														interest earned on the security deposit.</label>
														<div contenteditable="false">If You carry out all of your agreements in this Lease and if You move out of the Apartment and return it to Owner in the
																same condition it was in when You first occupied it, except for ordinary wear and tear or damage caused by fire or other casualty,
																Owner will return to You the full amount of your security deposit and interest to which You are entitled within 60 days after this
																Lease ends. However, if You do not carry out all your agreements in this Lease, Owner may keep all or part of your security
																deposit and any interest which has not yet been paid to You necessary to pay Owner for any losses incurred, including missed
																payments</div>
																<div contenteditable="false" >If Owner sells or leases the building, Owner will turn over your security, with interest, either to You or to the person
																		buying or leasing (lessee) the building within 5 days after the sale or lease. Owner will then notify You, by registered or certified
																		mail, of the name and address of the person or company to whom the deposit has been turned over. In such case, Owner will
																		have no further responsibility to You for the security deposit. The new owner or lessee will become responsible to You for the
																		security deposit.</div>
											</td>
										</tr>
										<tr>
											<td contenteditable="false">
												<label contenteditable="false" ><img src="/hand.png" height="10"/> 
												</label>		
												<input type="text" className="bor-b" style={{width:'133px'}} maxlength="80"/><br/>
												
										</td>
										</tr>
										<tr>
											<td contenteditable="false">
												
												<label contenteditable="false" style={{marginLeft:'25px', marginTop:'8px'}}>Space to be filled in.</label>	
										</td>
										</tr>
							</table>	
									
					
					</div>
					<div className="FooterBox" >
			
			<div style={{marginTop:'5px', textAlign:'center'}}>1</div>
		</div>
		</page>			
		<page size="A4">
				
		
				<div className="page2">

					<table width="100%" cellpadding="0" cellspacing="4"  className="main-box">
						<tr>
							<td contenteditable="false"><strong>5. <span className="ml-15">IF YOU ARE UNABLE TO MOVE IN</span></strong></td>
						</tr>
						<tr>	
							<td contenteditable="false" style={{paddingBottom:'5px'}}>
								<span className="ml-25">A situation could arise which might prevent Owner from letting You move into the Apartment on the beginning date set
in this Lease. If this happens for reasons beyond Owner's reasonable control, Owner will not be responsible for Your damages
or expenses, and this Lease will remain in effect. However, in such case, this Lease will start on the date when You can move
in, and the ending date in Article 2 will be changed to a date reflecting the full term of years set forth in Article 2. You will not have
to pay rent until the move-in date Owner gives You by written notice, or the date You move in, whichever is earlier. If Owner does
not give You notice that the move-in date is within 30 days after the beginning date of the term of this Lease as stated in Article
2, You may tell Owner in writing, that Owner has 15 additional days to let You move in, or else the Lease will end. If Owner does
not allow You to move in within those additional 15 days, then the Lease is ended. Any money paid by You on account of this
Lease will then be refunded promptly by Owner</span>
							</td>
						</tr>
						<tr>
							<td contenteditable="false"><strong>6. <span className="ml-15">CAPTIONS</span></strong></td>
						</tr>
						<tr>	
							<td contenteditable="false"  className="pb-0">
								<span className="ml-25">In any dispute arising under this Lease, in the event of a conflict between  the text and a caption, the text controls.</span>

							</td>
						</tr>
							<tr>
							<td contenteditable="false"><strong>7. <span className="ml-15">WARRANTY OF HABITABILITY</span></strong></td>
						</tr>
						<tr>	
							<td contenteditable="false"  className="pb-0">
								<span className="ml-25">A. All of the sections of this Lease are subject to the provisions of the Warranty of Habitability Law in the form it may
									have from time to time during this Lease. Nothing in this Lease can be interpreted to mean that You have given up any of your
									rights under that law. Under that law, Owner agrees that the Apartment and the Building are fit for human habitation and that
									there will be no conditions which will be detrimental to life, health or safety.</span>
									


							</td>
						</tr>
						<tr>	
							<td contenteditable="false"  className="pb-0">
								
									<span className="ml-25">B. You will do nothing to interfere or make more difficult Owner's efforts to provide You and all other occupants of the
										Building with the required facilities and services. Any condition caused by your misconduct or the misconduct of anyone under
										your direction or control shall not be a breach by Owner.</span>


							</td>
						</tr>
						<tr>
							<td contenteditable="false"><strong>8. <span className="ml-15">CARE OF YOUR APARTMENT-END OF LEASE-MOVING OUT</span></strong></td>
						</tr>
						<tr>	
							<td contenteditable="false"  className="pb-0">
								<span className="ml-25">A. You will take good care of the apartment and will not permit or do any damage to it, except for damage which occurs
									through ordinary wear and tear. You will move out on or before the ending date of this lease and leave the Apartment in good
									order and in the same condition as it was when You first occupied it, except for ordinary wear and tear and damage caused by
									fire or other casualty</span>
								


							</td>
						</tr>
						<tr>	
							<td contenteditable="false" className="pb-0">									
									<span className="ml-25">B. When this Lease ends, You must remove all of your movable property. You must also remove at your own expense,
										any wall covering, bookcases, cabinets, mirrors, painted murals or any other installation or attachment You may have installed
										in the Apartment, even if it was done with Owner's consent. You must restore and repair to its original condition those portions
										of the Apartment affected by those installations and removals. You have not moved out until all persons, furniture and other
										property of yours is also out of the Apartment. If your property remains in the Apartment after the Lease ends, Owner may either
										treat You as still in occupancy and charge You for use, or may consider that You have given up the Apartment and any property
										remaining in the Apartment. In this event, Owner may either discard the property or store it at your expense. You agree to pay
										Owner for all costs and expenses incurred in removing such property. The provisions of this article will continue to be in effect
										after the end of this Lease</span>


							</td>
						</tr>
						<tr>
							<td contenteditable="false"><strong>9. <span className="ml-15">CHANGES AND ALTERATIONS TO APARTMENT</span></strong></td>
							</tr>
						<tr>	
							<td contenteditable="false" className="pb-0">
								<span className="ml-25">You cannot build in, add to, change or alter, the Apartment in any way, including wallpapering, painting, repainting, or
									other decorating, without getting Owner's written consent before You do anything. Without Owner's prior written consent, You
									cannot install or use in the Apartment any of the following: dishwasher machines, clothes washing or drying machines, electric
									stoves, garbage disposal units, heating, ventilating or air conditioning units or any other electrical equipment which, in Owner's
									reasonable opinion, will overload the existing wiring installation in the Building or interfere with the use of such electrical wiring
									facilities by other tenants of the Building. Also, You cannot place in the Apartment water-filled furniture</span>


							</td>
						</tr>
						<tr>
							<td contenteditable="false"><strong>10. <span className="ml-9">YOUR DUTY TO OBEY AND COMPLY WITH LAWS, REGULATIONS AND LEASE RULES</span></strong></td>
							</tr>
						<tr>	
							<td contenteditable="false"  className="pb-0">
								<span className="ml-25"><strong>A. Government Laws and Orders.</strong>  You will obey and comply (1) with all present and future city, state and federal laws
									and regulations, which affect the Building or the Apartment, and (2) with all orders and regulations of Insurance Rating Organizations which affect the Apartment and the Building. You will not allow any windows in the Apartment to be cleaned from the
									outside, unless the equipment and safety devices required by law are used.</span>
								


							</td>
						</tr>
						<tr>	
							<td contenteditable="false"  className="pb-0">
							
									<span className="ml-25"><strong>B. Owner's Rules Affecting You.</strong>  You will obey all Owner's rules listed in this Lease and all future reasonable rules of
									Owner or Owner's agent. Notice of all additional rules shall be delivered to You in writing or posted in the lobby or other public
									place in the building. Owner shall not be responsible to You for not enforcing any rules, regulations or provisions of another
									tenant's lease except to the extent required by law</span>
									


							</td>
						</tr>
						<tr>	
							<td contenteditable="false"  className="pb-0">
							
									<span className="ml-25"><strong>C. Your Responsibility.</strong>  You are responsible for the behavior of yourself, of your immediate family, your servants and people who are visiting You. You will reimburse Owner as additional rent upon demand for the cost of all losses, damages, fines
										and reasonable legal expenses incurred by Owner because You, members of your immediate family, servants or people visiting
										You have not obeyed government laws and orders or the agreements or rules of this Lease.</span>


							</td>
						</tr>
						<tr>
							<td contenteditable="false"><strong>11. <span className="ml-9">OBJECTIONABLE CONDUCT</span></strong></td>
						</tr>
						<tr>	
							<td contenteditable="false"  className="pb-0">
								<span className="ml-25">As a tenant in the Building, You will not engage in objectionable conduct. Objectionable conduct means behavior
									which makes or will make the Apartment or the Building less fit to live in for You or other occupants. It also means anything
									which interferes with the right of others to properly and peacefully enjoy their Apartments, or causes conditions that are dangerous, hazardous, unsanitary and detrimental to other tenants in the Building. Objectionable conduct by You gives Owner the right
									to end this Lease</span>


							</td>
						</tr>
						<tr>
							<td contenteditable="false"><strong>12. <span className="ml-9">SERVICES AND FACILITIES</span></strong></td>
							</tr>
						<tr>	
							<td contenteditable="false"  className="pb-0">
								<span className="ml-25"><strong>A. Required Services.</strong>  Owner will provide cold and hot water and heat as required by law, repairs to the Apartment as
									required by law, elevator service if the Building has elevator equipment, and the utilities, if any, included in the rent, as set forth
									in sub-paragraph B. You are not entitled to any rent reduction because of a stoppage or reduction of any of the above services
									unless it is provided by law.</span><br/></td>
							
						</tr>
						<tr>
							<td contenteditable="false" style={{overflow:'hidden'}}>										
								<label contenteditable="false" class="left1"><img src="/hand.png" height="10"/> B. The following utilities are included in the rent</label>
										<div class="left2" contenteditable="false"><input type="text" id="facilities_rent" value={this.state.facilities_rent} onChange={this.handleOnChnage} className="bor-b inline-B w100" maxlength="400"/></div>
								
							</td>
						</tr>
						<tr>
							<td contenteditable="false">										
								<label contenteditable="false"><img src="/w-hand.png" height="10"/> C. Electricity and Other Utilities.</label>If Owner provides electricity or gas and the charges is included in the rent on
								Owner, You must arrange for this service directly with the utility company. You must also pay directly for telephone service if
								it is not included in the rent	
							</td>
						</tr>
						<tr>
							<td contenteditable="false">										
								<span className="ml-25"><strong> D. Appliances.</strong> Appliances supplied by Owner in the Apartment are for your use. They will be maintained and repaired or replaced by Owner, but if repairs or replacement are made necessary because of your negligence or misuse, You will pay Owner for the cost of such repair or replacement as additional rent.

								</span>
							</td>
						</tr>
						<tr>
							<td contenteditable="false">										
								<span className="ml-25"><strong> E. Elevator Service.</strong> If the elevator is the kind that requires an employee of Owner to operate it, Owner may end this service without reducing the rent if: (1) Owner gives You 10 days notice that this service will end; and (2) within a reasonable time


								</span>
							</td>
						</tr>
						<tr>
							<td contenteditable="false">
								<table width="100%">
									<tr>
										<td><label contenteditable="false" ><img src="/hand.png" height="10"/> </label>		
										<input type="text" className="bor-b" style={{width:'133px'}} maxlength="80"/><br/></td>
										<td style={{textAlign:'right', fontSize:'9px'}}>
										<label contenteditable="false" ><img src="/w-hand.png" height="10"/> Rider to be added, if necessary</label>
										 </td>
									</tr>
									<tr>
											<td><label contenteditable="false" className="ml-25" style={{marginTop:'8px'}}>Space to be filled in.</label></td>
											<td></td>
									</tr>
								</table>
								
								
						</td>
						</tr>
					
					</table>			
					

				
				
						
				
			
			</div>
			<div className="FooterBox">
	
				<div>2</div>
			</div>
			</page>	


			<page size="A4">
				
		
						<div className="page3 pagewrap">

								<table width="100%" cellpadding="0" cellspacing="4" className="main-box">
										<tr>
											<td>
													after the end of this year 10-day notice, Owner begins to substitute an automatic control type of elevator and proceeds diligently with its
													installation.
											</td>
										</tr>
										<tr>	
												<td contenteditable="false">
													<span sclassName="ml-25"><strong>F. Storeroom Use.</strong>  If Owner permits You to use any storeroom, laundry or any other facility located in the building but outside of
														the Apartment, the use of this storeroom or facility will be furnished to You free of charge and at your own risk, except for loss suffered by
														You due to Owner's negligence. You will operate at your expense any coin operated appliances located in such storeroom or laundries.
													</span>
												</td>
												
											</tr>
										<tr>
											<td contenteditable="false"><strong>13. <span className="ml-9">INABILITY TO PROVIDE SERVICES</span></strong></td>
										</tr>
										<tr>	
											<td contenteditable="false" style={{paddingBottom:'2px'}}>
												<span className="ml-25">Because of a strike, labor trouble, national emergency, repairs, or any other cause beyond Owner's reasonable control, Owner may not be able to provide or may be delayed in providing any services or in making any repairs to the Building. In
														any of these events, any rights You may have against Owner are only those rights which are allowed by laws in effect when the
														reduction in service occurs</span>
											</td>
										</tr>
										<tr>
												<td contenteditable="false"><strong>14. <span className="ml-9">ENTRY TO APARTMENT</span></strong></td>
											</tr>
											<tr>	
												<td contenteditable="false" style={{paddingBottom:'2px'}}>
													<span className="ml-25">During reasonable hours and with reasonable notice, except in emergencies, Owner may enter the Apartment for the
													following reasons:</span><br/>
													<span className="ml-25">(A) To erect, use and maintain pipes and conduits in and through the walls and ceilings of the Apartment; to inspect the
															Apartment and to make any necessary repairs or changes Owner decides are necessary. Your rent will not be reduced because
															of any of this work, unless required by Law.</span><br/>
													<span className="ml-25">(B) To show the Apartment to persons who may wish to become owners or lessees of the entire Building or may be
													interested in lending money to Owner;</span><br/>
													<span className="ml-25">(C) For four months before the end of the Lease, to show the Apartment to persons who wish to rent it;</span><br/>
													<span className="ml-25">(D) If during the last month of the Lease You have moved out and removed all or almost all of your property from the
															Apartment, Owner may enter to make changes, repairs, or redecorations. Your rent will not be reduced for that month
															and this Lease will not be ended by Owner's entry.</span><br/>
													 <span className="ml-25">(E) If at any time You are not personally present to permit Owner or Owner's representative to enter the Apartment and
															entry is necessary or allowed by law or under this lease, Owner or Owner's representatives may nevertheless enter the Apartment. Owner may enter by force in an emergency. Owner will not be responsible to You, unless during this entry, Owner or
															Owner's representative is negligent or misuses your property.</span>
																													
												</td>
											</tr>
											<tr>
													<td contenteditable="false"><strong>15. <span className="ml-9">ASSIGNING; SUBLETTING; ABANDONMENT</span></strong></td>
												</tr>
											<tr>	
													<td contenteditable="false" style={{paddingBottom:'2px'}}>
														<span className="ml-25"><strong>(a) Assigning and Subletting.</strong> You cannot assign this Lease or sublet the Apartment without Owner's advance written
															consent in each instance to a request made by You in the manner required by Real Property Law § 226-b. Owner may refuse
															to consent to a lease assignment for any reason or no reason, but if Owner unreasonably refuses to consent to request for a
															Lease assignment properly made, at your request in writing, Owner will end this Lease effective as of thirty days after your
															request. The first and every other time you wish to sublet the Apartment, You must get the written consent of Owner unless
															Owner unreasonably withholds consent following your request to sublet in the manner provided by Real Property Law § 226-
															b. Owner may impose a reasonable credit check fee on You in connection with an application to assign or sublet. If You fail
															to pay your rent Owner may collect rent from subtenant or occupant without releasing You from the Lease. Owner will credit
															the amount collected against the rent due from You. However, Owner's acceptance of such rent does not change the status
															of the subtenant or occupant to that of direct tenant of Owner and does not release You from this Lease.

															</span><br/>
															<span className="ml-25"><strong>(b) Abandonment</strong> If You move out of the Apartment (abandonment) before the end of this Lease without the consent
																of Owner, this Lease will not be ended (except as provided by law following Owner's unreasonable refusal to consent to an
																assignment or subletting requested by You.) You will remain responsible for each monthly payment of rent as it becomes due
																until the end of this Lease. In case of abandonment, your responsibility for rent will end only if Owner chooses to end this
																Lease for default as provided in Article 16.
	
																</span>
																														
													</td>
												</tr>
												<tr>
														<td contenteditable="false"><strong>16. <span className="ml-9">DEFAULT</span></strong></td>
												</tr>
												<tr>
														<td contenteditable="false">
															<span  className="ml-25">(1) You default under the Lease if You act in any of the following ways:</span><br/>
															<span  className="ml-40">(a) You fail to carry out any agreement or provision of this Lease;</span><br/>
															<span className="ml-40">(b) You or another occupant of the Apartment behaves in an objectionable manner;</span><br/>
															<span className="ml-40">(c) You do not take possession or move into the Apartment 15 days after the beginning of this Lease;</span><br/>
															<span className="ml-40">(d) You and other legal occupants of the Apartment move out permanently before this Lease ends;
																	If You do default in any one of these ways, other than a default in the agreement to pay rent, Owner may serve You with a written
																	notice to stop or correct the specified default within 10 days. You must then either stop or correct the default within 10 days, or,
																	if You need more than 10 days, You must begin to correct the default within 10 days and continue to do all that is necessary to
																	correct the default as soon as possible.</span><br/>
														</td>
												</tr>
												<tr>
														<td contenteditable="false">
															<span  className="ml-25">(2) If You do not stop or begin to correct a default within 10 days, Owner may give You a second written notice that this
																	Lease will end six days after the date the second written notice is sent to You. At the end of the 6-day period, this Lease will end and
																	You then must move out of the Apartment. Even though this Lease ends, You will remain liable to Owner for unpaid rent up to the end
																	of this Lease, the value of your occupancy, if any, after the Lease ends, and damages caused to Owner after that time as stated in
																	Article 18.</span><br/>
														</td>
												</tr>
												<tr>
														<td contenteditable="false">
															<span  className="ml-25">(3) If You do not pay your rent when this Lease requires after a personal demand for rent has been made, or within three
																	days after a statutory written demand for rent has been made, or if the Lease ends, Owner may do the following: (a) enter the
																	apartment and retake possession of it if You have moved out or (b) go to court and ask that You and all other occupants in the
																	Apartment be compelled to move out.</span><br/>
														</td>
												</tr>	
												<tr>
														<td contenteditable="false">
															<span  className="ml-25">Once this Lease has been ended, whether because of default or otherwise, You give up any right You might otherwise
																	have to reinstate or renew the Lease.</span>
														</td>
												</tr>
												<tr>
														<td contenteditable="false"><strong>17. <span  className="ml-9">REMEDIES OF OWNER AND YOUR LIABILITY</span></strong></td>
												</tr>
												<tr>
														<td contenteditable="false">
																If this Lease is ended by Owner because of your default, the following are the rights and obligations of You and Owner<br/>
															<span  className="ml-25">(a) You must pay your rent until this Lease has ended. Thereafter, You must pay an equal amount for what the law
																	calls "use and occupancy" until You actually move out.</span><br/>
															<span  className="ml-25">(b) Once You are out, Owner may re-rent the Apartment or any portion of it for a period of time which may end
																	before or after the ending date of this Lease. Owner may re-rent to a new tenant at a lesser rent or may charge a higher rent
																	than the rent in this Lease.</span><br/>
															<span  className="ml-25">(c) Whether the Apartment is re-rented or not, You must pay to Owner as damages:</span><br/>
															<span className="ml-25">(1) the difference between the rent in this Lease and the amount, if any, of the rents collected in any later lease or
																	leases of the Apartment for what would have been the remaining period of this Lease; and</span><br/>
															<span className="ml-40">(2) Owner's expenses for advertisements, broker's fees and the cost of putting the Apartment in good condition for re-rental; and</span><br/>						
															<span className="ml-40">***(3) Owner's expenses for attorney's fees.</span><br/>						
															<span  className="ml-25">(d) You shall pay all damages due in monthly installments on the rent day established in this Lease. Any legal action
																	brought to collect one or more monthly installments of damages shall not prejudice in any way Owner's right to collect the
																	damages for a later month by a similar action.</span><br/>
														</td>
												</tr>
												<tr>
														<td contenteditable="false" style={{fontSize:'9px', paddingTop:'8px'}}>
																***This may be deleted.

													
														</td>
												</tr>
								</table>		

					
						
						</div>
				
					<div className="FooterBox">	
						<div>3</div>
					</div>
					</page>	


					<page size="A4">
				
		
							<div className="pagewrap" style={{padding:'0px 15px 10px',  fontSize:'10px', lineHeight:'9px'}}>	
							<table width="100%" cellpadding="0" cellspacing="4" className="main-box">
								<tr>
									<td>
											If the rent collected by Owner from a subsequent tenant of the Apartment is more than the unpaid rent and damages which You
owe Owner, You cannot receive the difference. Owner's failure to re-rent to another tenant will not release or change your liability
for damages, unless the failure is due to Owner's deliberate inaction.
									</td>
								</tr>
								<tr>
										<td contenteditable="false"><strong>18. <span className="ml-9">ADDITIONAL OWNER REMEDIES</span></strong></td>
								</tr>
								<tr>
									<td>
											<span className="ml-25">If You do not do everything You have agreed to do, or if You do anything which shows that You intend not to do what You
											have agreed to do, Owner has the right to ask a Court to make You carry out your agreement or to give the Owner such other
											relief as the Court can provide. This is in addition to the remedies in Article 16 and 17 of this lease.</span><br/>
									</td>
								</tr>
								<tr>
										<td contenteditable="false"><strong>19. <span className="ml-9">FEES AND EXPENSES</span></strong></td>
								</tr>
								<tr>
									<td>
											<span className="ml-25"><strong>A. Owner's Right.</strong> You must reimburse Owner for any of the following fees and expenses incurred by Owner:</span><br/>
											<span className="ml-40">(1) Making any repairs to the Apartment or the Building which result from misuse or negligence by You or
													persons who live with You, visit You, or work for You;</span><br/>
											<span className="ml-40">(2) Repairing or replacing property damaged by Your misuse or negligence;</span><br/>
											<span className="ml-40">(3) Correcting any violations of city, state or federal laws or orders and regulations of insurance rating organizations concerning the Apartment or the Building which You or persons who live with You, visit You, or work for You have caused;
											</span><br/>
											<span className="ml-40">(4) Preparing the Apartment for the next tenant if You move out of your Apartment before the Lease ending date;</span><br/>		
											<span style={{marginLeft:'28px'}}>***(5) Any legal fees and disbursements for legal actions or proceedings brought by Owner against You because
													of a Lease default by You or for defending lawsuits brought against Owner because of your actions;
												</span><br/>		
											<span className="ml-25"><strong>B. Tenant's Right..</strong> Owner agrees that unless sub-paragraph 5 of this Article 19 has been stricken out of this Lease You have the right to collect reasonable legal fees and expenses incurred in a successful defense by You of a lawsuit brought by
												Owner against You or brought by You against Owner to the extent provided by Real Property Law, section 234.</span><br/>	

									</td>
								</tr>
								<tr>
										<td contenteditable="false"><strong>20. <span className="ml-9">PROPERTY LOSS, DAMAGES OR INCONVENIENCE</span></strong></td>
								</tr>
								<tr>
										<td>
												<span className="ml-25">Unless caused by the negligence or misconduct of Owner or Owner's agents or employees, Owner or Owner's agents
														and employees are not responsible to You for any of the following (1) any loss of or damage to You or your property in the
														Apartment or the Building due to any accidental or intentional cause, even a theft or another crime committed in the Apartment
														or elsewhere in the Building; (2) any loss of or damage to your property delivered to any employee of the Building (i.e., doorman,
														superintendent, etc.,); or (3) any damage or inconvenience caused to You by actions, negligence or violations of a Lease by any
														other tenant or person in the Building except to the extent required by law.</span><br/>
												<span className="ml-25">Owner will not be liable for any temporary interference with light, ventilation, or view caused by construction by or in
														behalf of Owner. Owner will not be liable for any such interference on a permanent basis caused by construction on any parcel
														of land not owned by Owner. Also, Owner will not be liable to You for such interference caused by the permanent closing, darkening
														or blocking up of windows, if such action is required by law. None of the foregoing events will cause a suspension or reduction
														of the rent or allow You to cancel the Lease.</span><br/>				
	
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false"><strong>21. <span className="ml-9">FIRE OR CASUALTY</span></strong></td>
								</tr>
								
								<tr>	
									<td contenteditable="false" className="pb-2">
										
										<span className="ml-25">A. If the Apartment becomes unusable, in part or totally, because of fire, accident or other casualty, this Lease will continue unless ended by Owner under C below or by You under D below. But the rent will be reduced immediately. This reduction will be based upon the part of the Apartment which is unusable.</span><br/>
										<span className="ml-25">B. Owner will repair and restore the Apartment, unless Owner decides to take actions described in paragraph C below.</span><br/>
										<span className="ml-25">C. After a fire, accident or other casualty in the Building, Owner may decide to tear down the Building or to substantially rebuild it. In such case, Owner need not restore the Apartment but may end this Lease. Owner may do this even if the Apartment has not been damaged, by giving You written notice of this decision within 30 days after the date when the damage occurred. If the Apartment is usable when Owner gives You such notice, this Lease will end 60 days from the last day of the calendar month in which You were given the notice.</span><br/>
										<span className="ml-25">D. If the Apartment is completely unusable because of fire, accident or other casualty and it is not repaired in 30 days, You may give Owner written notice that You end the Lease. If You give that notice, this Lease is considered ended on the day that the fire, accident or casualty occurred. Owner will refund your security deposit and the pro-rata portion of rents paid for the month in which the casualty happened.</span><br/>
										 <span className="ml-25">E. Unless prohibited by the applicable insurance policies, to the extent that such insurance is collected, You and Owner release and waive all right of recovery against the other or anyone claiming through or under each applicable policy by way of subrogation.</span>
																										
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong>22. <span className="ml-9">PUBLIC TAKING</span></strong></td>
								</tr>
								
								<tr>
									<td>
										<span className="ml-25">The entire building or a part of it can be acquired (condemned) by any government or government agency for a public or quasi-public use or purpose. If this happens, this Lease shall end on the date the government or agency take title and You shall have no claim against Owner for any damage resulting; You also agree that by signing this Lease, You assign to Owner any claim against the Government or Government agency for the value of the unexpired portion of this Lease.</span><br/>
													
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong>23. <span className="ml-9">SUBORDINATION CERTIFICATE AND ACKNOWLEDGMENTS</span></strong></td>
								</tr>
								<tr>
									<td>
										<span className="ml-25">All leases and mortgages of the Building or of the land on which the Building is located, now in effect or made after this Lease is signed, come ahead of this Lease. In other words, this Lease is "subject and subordinate to" any existing or future lease or mortgage on the Building or land, including any renewals, consolidations, modifications and replacements of these leases or mortgages. If certain provisions of any of these leases or mortgages come into effect, the holder of such lease or mortgage can end this lease. If this happens, You agree that You have no claim against Owner or such lease or mortgage holder. If Owner requests, You will sign promptly an acknowledgment of the "subordination" in the form that Owner requires.</span><br/>
										
										<span className="ml-25">You also agree to sign (if accurate) a written acknowledgment to any third party designated by Owner that this Lease is in effect, that Owner is performing Owner's obligations under this Lease and that you have no present claim against Owner.</span><br/>	
													
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong>24. <span className="ml-9">TENANT'S RIGHT TO LIVE IN AND USE THE APARTMENT</span></strong></td>
								</tr>
								
								<tr>
									<td>
										<span className="ml-25">If You pay the rent and any required additional rent on time and You do everything You have agreed to do in this Lease, your tenancy cannot be cut off before the ending date, except as provided for in Article 21, 22, and 23.</span><br/>
													
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong>25. <span className="ml-9">BILLS AND NOTICE</span></strong></td>
								</tr>
								<tr>
									<td>
										<span className="ml-25">A. Notices to You. Any notice from Owner or Owner's agent or attorney will be considered properly given to You if it (1) is in writing; (2) is signed by or in the name of Owner or Owner's agent; and (3) is addressed to You at the Apartment and delivered to You personally or sent by registered or certified mail to You at the Apartment. The date of service of any written notice by Owner to you under this agreement is the date of delivery or mailing of such notice.</span><br/>
													
									</td>
								</tr>
								
								<tr>
									<td contenteditable="false" style={{fontSize:'9px', paddingTop:'8px'}}>
											***This may be deleted.								
									</td>
								</tr>
								
							</table>
					</div>
					<div className="FooterBox">	
						<div>4</div>
					</div>
					</page>	


					<page size="A4">
				
		
						<div  className="pagewrap" style={{padding:'0px 15px 10px',  fontSize:'10px',	lineHeight:'9px'}}>

							<table width="100%" cellpadding="0" cellspacing="4"  className="main-box">
								
								<tr>	
									<td contenteditable="false" style={{paddingBottom:'5px'}}>
										<span  className="ml-25"><strong>B. Notices to Owner.</strong> If You wish to give a notice to Owner, you must write it and deliver it or send it by registered or certified mail to Owner at the address noted on page 1 of this Lease or at another address of which Owner or Agent has given You written notice.</span><br/>
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong>26. <span  className="ml-9">GIVING UP RIGHT TO TRIAL BY JURY AND COUNTERCLAIM</span></strong></td>
								</tr>
								<tr>
									<td>
										<span  className="ml-25">A. Both You and Owner agree to give up the right to a trial by jury in a court action, proceeding or counterclaim on any matters concerning this Lease, the relationship of You and Owner as Tenant and Landlord or your use or occupancy of the Apartment. This agreement to give up the right to a jury trial does not include claims for personal injury or property damage.</span><br/>
										
										<span  className="ml-25">B. If Owner begins any court action or proceeding against You which asks that You be compelled to move out, You cannot make a counterclaim unless You are claiming that Owner has not done what Owner is supposed to do about the condition of the Apartment or the Building.</span><br/>
													
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong>27. <span  className="ml-9">NO WAIVER OF LEASE PROVISIONS</span></strong></td>
								</tr>
								<tr>
									<td>
										<span  className="ml-25">A. Even if Owner accepts your rent or fails once or more often to take action against You when You have not done what You have agreed to do in this Lease, the failure of Owner to take action or Owner's acceptance of rent does not prevent Owner from taking action at a later date if You again do not do what You have agreed to do.</span><br/>
										
										<span  className="ml-25">B. Only a written agreement between You and Owner can waive any violation of this Lease.</span><br/>
										
										<span  className="ml-25">C. If You pay and Owner accepts an amount less than all the rent due, the amount received shall be considered to be in payment of all or a part of the earliest rent due. It will not be considered an agreement by Owner to accept this lesser amount in full satisfaction of all of the rent due.</span><br/>
										
										<span  className="ml-25">D. Any agreement to end this Lease and also to end the rights and obligations of You and Owner must be in writing, signed by You and Owner or Owner's agent. Even if You give keys to the Apartment and they are accepted by any employee, or agent, or Owner, this Lease is not ended.</span><br/>
													
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong>28. <span  className="ml-9">CONDITION OF THE APARTMENT</span></strong></td>
								</tr>
								<tr>
									<td>
										<span  className="ml-25">When You signed this Lease, You did not rely on anything said by Owner, Owner's agent or superintendent about the physical condition of the Apartment, the Building or the land on which it is built. You did not rely on any promises as to what would be done, unless what was said or promised is written in this Lease and signed by both You and Owner or found in Owner's floor plans or brochure shown to You before You signed the Lease. Before signing this Lease, You have inspected the apartment and You accept it in its present condition "as is," except for any condition which You could not reasonably have seen during your inspection. You agree that Owner has not promised to do any work in the Apartment except as specified in attached "Work" rider.</span><br/>
													
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong>29. <span  className="ml-9">DEFINITIONS</span></strong></td>
								</tr>
								<tr>
									<td>
										<span  className="ml-25">A. Owner: The term "Owner" means the person or organization receiving or entitled to receive rent from You for the Apartment at any particular time other than a rent collector or managing agent of Owner. "Owner" includes the owner of the land or Building, a lessor, or sublessor of the land or Building and a mortgagee in possession. It does not include a former owner, even if the former owner signed this Lease</span><br/>
										
										<span  className="ml-25">B. You: The Term "You" means the person or persons signing this Lease as Tenant and the successors and assigns of the signer. This Lease has established a tenant-landlord relationship between You and Owner</span><br/>
													
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong>30. <span  className="ml-9">SUCCESSOR INTERESTS</span></strong></td>
								</tr>
								<tr>
									<td>
										<span className="ml-25">The agreements in this Lease shall be binding on Owner and You and on those who succeed to the interest of Owner or You by law, by approved assignment or by transfer.</span><br/>
													
									</td>
								</tr>
								
								<tr>
										<td contenteditable="false"><strong><span>Owners Rules - a part of this lease - see page 6</span></strong></td>
								</tr>
								
								<tr>
										<td contenteditable="false"><span>TO CONFIRM OUR AGREEMENTS, OWNER AND YOU RESPECTIVELY SIGN THIS LEASE AS OF THE DAY AND YEAR FIRST WRITTEN ON PAGE 1.</span></td>
								</tr>
								
								<tr>
										<td contenteditable="false"><span>Witnesses</span></td>
								</tr>
								
								<tr>
										<td contenteditable="false">
										<table width="100%">
									<tr>
										<td contenteditable="false" width="50%">
										<input type="text"className="bor-b inline-B" style={{width:'85%'}} maxlength="400"/>																		
										</td>
										<td contenteditable="false" width="50%">
										<input type="text" className="bor-b inline-B" style={{width:'85%'}} maxlength="400"/>[L.S.]								</td>
									</tr>
									<tr>
										<td contenteditable="false" width="50%">
											<label contenteditable="false" style={{display:'block'}}></label>
										</td>
										<td contenteditable="false" width="50%">
											<label contenteditable="false" style={{display:'block'}}>Owner's Signature</label>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="50%">
										<input type="text" className="bor-b inline-B" style={{width:'85%'}} maxlength="400"/>																		
										</td>
										<td contenteditable="false" width="50%">
										<input type="text" className="bor-b inline-B" style={{width:'85%'}} maxlength="400"/>[L.S.]								</td>
									</tr>
									<tr>
										<td contenteditable="false" width="50%">
											<label contenteditable="false" style={{display:'block'}}></label>
										</td>
										<td contenteditable="false" width="50%">
											<label contenteditable="false"style={{display:'block'}}>Tenant's Signature</label>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="50%">
										<input type="text" className="bor-b inline-B" style={{width:'85%'}}  maxlength="400"/>																		
										</td>
										<td contenteditable="false" width="50%">
										<input type="text" className="bor-b inline-B" style={{width:'85%'}} maxlength="400"/>[L.S.]								</td>
									</tr>
									<tr>
										<td contenteditable="false" width="50%">
											<label contenteditable="false" style={{display:'block'}}></label>
										</td>
										<td contenteditable="false" width="50%">
											<label contenteditable="false" style={{display:'block'}}>Tenant's Signature</label>
										</td>
									</tr>
								</table>								
										</td>
								</tr>
								
								<tr align="center">
										<td contenteditable="false"><strong><span>GUARANTY</span></strong></td>
								</tr>
								<tr>
									<td>
										<span>The undersigned Guarantor guarantees to Owner the strict performance of and observance by Tenant of all the agreements, provisions and rules in the attached Lease. Guarantor agrees to waive all notices when Tenant is not paying rent or not observing and complying with all of the provisions of the attached Lease. Guarantor agrees to be equally liable with Tenant so that Owner may sue Guarantor directly without first suing Tenant. The Guarantor further agrees that his guaranty shall remain in full effect even if the Lease is renewed, changed or extended in any way and even if Owner has to make a claim against Guarantor. Owner and Guarantor agree to waive trial by jury in any action, proceeding or counterclaim brought against the other on any matters concerning the attached Lease or the Guaranty.</span><br/>
													
									</td>
								</tr>
								
								<tr>
									<td contenteditable="false">
										<table width="100%">
											<tr>
												<td contenteditable="false" width="50%">
													<label contenteditable="false">Dated, New York City</label>		
													<input type="text" className="bor-b" style={{width:'100px'}} maxlength="80"/><br/>
												</td>
											</tr>
											<tr>
												<td contenteditable="false" width="50%">	
													<input type="text" className="bor-b" style={{width:'200px'}} maxlength="150"/>
													<label contenteditable="false">Witness</label><br/>	
												</td>
												<td contenteditable="false" width="50%">	
													<input type="text"className="bor-b" style={{width:'200px'}} maxlength="150"/>
													<label contenteditable="false">Guarantor</label><br/>	
												</td>
											</tr>
											<tr>
												<td contenteditable="false" width="50%"></td>
												<td contenteditable="false" width="50%">	
													<input type="text" className="bor-b" style={{width:'200px'}} maxlength="150"/>
													<label contenteditable="false">Address</label><br/>	
												</td>
											</tr>
											
										</table>
										
										
								</td>
								</tr>
								
							
							</table>						
					
					</div>
					<div className="FooterBox">	
						<div>5</div>
					</div>
					</page>	

					<page size="A4">
				
		
						<div className="pagewrap" style={{padding:'0px 15px 10px',  fontSize:'10px', lineHeight:'10px'}}>

						<div style={{borderBottom:'0', borderTop:'0', padding:'0 20px', borderStyle:'double'}}>				
										<table width="100%" cellpadding="0" cellspacing="4">
											<tr>
												<td contenteditable="false">
													<label contenteditable="false" style={{marginBottom:'5px'}}>Apartment</label>		
													<input type="text" id="guaranty_apartment" value={this.state.guaranty_apartment}  onChange={this.handleOnChnage}  className="bor-b" style={{width:'400px',padding:'7px'}} maxlength="400"/><br/>
												</td>
											</tr>
											<tr>
												<td contenteditable="false">
													<label contenteditable="false" style={{marginBottom:'0'}}>Premises</label>		
													<input type="text" id="guaranty_premises" value={this.state.guaranty_premises}  onChange={this.handleOnChnage} className="bor-b" style={{width:'400px',padding:'7px'}} maxlength="400"/><br/>
												</td>
											</tr>
											<tr>
												<td contenteditable="false">
													<label contenteditable="false" style={{marginBottom:'0'}}>Tenant</label>		
													<input type="text" id="guaranty_tenant" value={this.state.guaranty_tenant}  onChange={this.handleOnChnage} className="bor-b" style={{width:'400px',padding:'7px'}} maxlength="400"/><br/>
												</td>
											</tr>
											<tr>
												<td contenteditable="false">
													<label contenteditable="false" style={{marginBottom:'0'}}>Expires</label>		
													<input type="text" id="guaranty_expires" value={this.state.guaranty_expires}  onChange={this.handleOnChnage} className="bor-b" style={{width:'400px',padding:'7px'}} maxlength="400"/><br/>
												</td>
											</tr>
										</table>
										<div  style={{borderBottom:'0', borderLeft:'0', borderRight:'0', borderStyle:'double', marginTop:'10px', paddingTop:'10px'}}>
										<table cellpadding="0" cellspacing="4" width="100%"> 
											<tr>
												<td contenteditable="false">
													<img src="/realestate.png"/>			
												</td>
												<td contenteditable="false" style={{textAlign:'center'}} width="70%">
												STANDARD FORM OF APARTMENT
												<div style={{fontFamily:'Times New Roman', fontSize:'18px'}}>Lease</div>
												<div style={{fontSize:'8px', margin:'5px 0'}}>The Real Estate Board of New York, Inc.<br/>
												Copyright 1988. All rights Reserved. Reproduction in whole or in part prohibited.</div>
												<div style={{fontSize:'11px'}}>ATTACHED RULES WHICH ARE A PART OF THE LEASEAS<br/> AS PROVIDED BY ARTICLE 10</div>
												</td>
												<td contenteditable="false">
												<img src="/realestate.png"/>
												</td>
											</tr>				
										</table>
										</div>

										</div>

							<table width="100%" cellpadding="0" cellspacing="4" className="main-box" >
								
								<tr>
									<td contenteditable="false"><strong>Public Access Ways</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">1. <span className="ml-15">(a) Tenants shall not block or leave anything in or on fire escapes, the sidewalks, entrances, driveways, elevators, stairways, or halls. Public access ways shall be used only for entering and leaving the Apartment and the Building. Only those elevators and passageways designated by Owner can be used for deliveries.</span><br/>
									
									<span className="ml-25">(b) Baby carriages, bicycles or other property of Tenants shall not be allowed to stand in the halls, passageways, public areas or courts of the Building.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>Bathroom and Plumbing Fixtures</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">2. <span className="ml-15">The bathrooms, toilets and wash closets and plumbing fixtures shall only be used for the purposes for which they were designed or built; sweepings, rubbish bags, acids or other substances shall not be placed in them.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>Refuse</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">3. <span className="ml-15">Carpets, rugs or other articles shall not be hung or shaken out of any window of the Building. Tenants shall not sweep or throw or permit to be swept or thrown any dirt, garbage or other substances out of the windows or into any of the halls, elevators or elevator shafts. Tenants shall not place any articles outside of the Apartments or outside of the building except in safe containers and only at places chosen by Owner.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>Elevators</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">4. <span className="ml-15">All non-automatic passenger and service elevators shall be operated only by employees of Owner and must not in any event be interfered with by Tenants. The service elevators, if any, shall be used by servants, messengers and trades people for entering and leaving, and the passenger elevators, if any, shall not be used by them for any purpose. Nurses with children, however, may use the passenger elevators.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>Laundry</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">5. <span className="ml-15">Laundry and drying apparatus, if any, shall be used by Tenants in the manner and at the times that the superintendent or other representative of Owner may direct. Tenants shall not dry or air clothes on the roof.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>Keys and Locks</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">6. <span className="ml-15">Owner may retain a pass key to the apartment. Tenants may install on the entrance of the Apartment an additional lock of not more than three inches in circumference. Tenants may also install a lock on any window but only in the manner provided by law. Immediately upon making any installation of either type, Tenants shall notify Owner or Owner's agent and shall give Owner or Owner's agent a duplicate key. If changes are made to the locks or mechanism installed by Tenants, Tenants must deliver keys to Owner. At the end of this Lease, Tenants must return to Owner all keys either furnished or otherwise obtained. If Tenants lose or fail to return any keys which were furnished to them, Tenants shall pay to Owner the cost of replacing them.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>Noise</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">7. <span className="ml-15">Tenants, their families, guests, employees, or visitors shall not make or permit any disturbing noises in the Apartment or Building or permit anything to be done that will interfere with the rights, comforts or convenience of other tenants. Also, Tenants shall not play a musical instrument or operate or allow to be operated a phonograph, CD player, radio or television set so as to disturb or annoy any other occupant of the Building.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>No Projections</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">8. <span className="ml-15">An aerial may not be erected on the roof or outside wall of the Building without the written consent of Owner. Also, awnings or other projections shall not be attached to the outside walls of the Building or to any balcony or terrace.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>No Pets</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">9. <span className="ml-15">Dogs or animals of any kind shall not be kept or harbored in the Apartment, unless in each instance it be expressly permitted in writing by Owner. This consent, if given, can be taken back by Owner at any time for good cause on reasonably given notice. Unless carried or on a leash, a dog shall not be permitted on any passenger elevator or in any public portion of the building. Also, dogs are not permitted on any grass or garden plot under any condition. BECAUSE OF THE HEALTH HAZARD AND POSSIBLE DISTURBANCE OF OTHER TENANTS WHICH ARISE FROM THE UNCONTROLLED PRESENCE OF ANIMALS, ESPECIALLY DOGS, IN THE BUILDING, THE STRICT ADHERENCE TO THE PROVISIONS OF THIS RULE BY EACH TENANT IS A MATERIAL REQUIREMENT OF EACH LEASE. TENANTS' FAILURE TO OBEY THIS RULE SHALL BE CONSIDERED A SERIOUS VIOLATION OF AN IMPORTANT OBLIGATION BY TENANT UNDER THIS LEASE. OWNER MAY ELECT TO END THIS LEASE BASED UPON THIS VIOLATION.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>Moving</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">10. <span className="ml-15">Tenants can use the elevator to move furniture and possessions only on designated days and hours. Owner shall not be liable for any costs, expenses or damages incurred by Tenants in moving because of delays caused by the unavailability of the elevator</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>Floors</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">11. <span className="ml-15">Apartment floors shall be covered with rugs or carpeting of at least 80% of the floor area of each room excepting only kitchens, pantries, bathrooms and hallways. The tacking strip for wall-to-wall carpeting will be glued, not nailed to the floor.</span></td>
								</tr>
								
								<tr>
									<td contenteditable="false"><strong>Window Guards</strong></td>
								</tr>
								<tr>
									<td contenteditable="false">12. <span className="ml-15">IT IS A VIOLATION OF LAW TO REFUSE, INTERFERE WITH INSTALLATION, OR REMOVE WINDOW GUARDS WHERE REQUIRED. (SEE ATTACHED WINDOW GUARD RIDER)</span><br/></td>
								</tr>

	
							</table>						
					
					</div>
					<div className="FooterBox">	
						<div>6</div>
					</div>
					</page>		


					<page size="A4">
					
						<div className="page7" style={{padding:'0px 15px 0px',  fontSize:'9px', lineHeight:'8px'}}>

						
							
									<table contenteditable="false" width="100%" cellpadding="0" cellspacing="0">
												
												<tr>
													<td contenteditable="false" width="25%" style={{borderRight:'1px solid #000', borderBottom:'1px solid #000', paddingBottom:'5px'}}>	
														<div style={{marginBottom:'5px'}}>Form <strong><span style={{fontSize:'18px'}}>W-9</span></strong><br/>
														(Rev. October 2018)<br/>
														Department of the Treasury<br/>
														Internal Revenue Service <br/>
														</div>
													</td>
													<td contenteditable="false" width="57%" style={{borderRight:'1px solid #000', borderBottom:'1px solid #000', textAlign:'center',  paddingBottom: '5px'}}>	
														<strong><span style={{fontSize:'14px', display:'block', lineHeight:'11px', marginBottom:'-5px'}}>Request for Taxpayer<br/>
														Identification Number and Certification</span></strong><br/>
														<img src="/arrow.png"/>Go to www.irs.gov/FormW9 for instructions and the latest information.
													</td>
													<td contenteditable="false" width="18%" style={{borderBottom:'1px solid #000', fontWeight:'bold',  paddingBottom:'5px', paddingLeft:'5px'}}>	
														<div style={{marginLeft:'5px'}}>Give Form to the<br/>
														requester. Do not<br/>
														send to the IRS.<br/>	
														</div>
													</td>
												</tr>										
											</table>
											<div class="frame" style={{minHeight:'200px', borderBottom:'1px solid #000', position:'relative'}}>
												<div class="rotatetxt">
														<strong>Print or type.</strong> <br/>
														See <strong>Specific Instructions</strong> on page 3.


												</div>
												<div style={{marginLeft:'18px', borderLeft:'1px solid #000'}}><table contenteditable="false" width="100%" cellpadding="1" cellspacing="0">
													<tr>
														<td contenteditable="false" style={{borderBottom:'1px solid #000'}}>
																<label contenteditable="false">1 Name (as shown on your income tax return). Name is required on this line; do not leave this line blank.
																	</label>
																<input type="text" id="name_shown_income_tax" value={this.state.name_shown_income_tax} onChange={this.handleOnChnage} style={{border:'0px', width:'100%',  fontSize:'10px'}} maxlength="300"/>
																
														</td>
													</tr>
													<tr>
															<td contenteditable="false" style={{borderBottom:'1px solid #000'}}>
																	<label contenteditable="false">2 Business name/disregarded entity name, if different from above</label>
																	<input type="text" id="business_name" value={this.state.business_name} onChange={this.handleOnChnage} style={{border:'0px', width:'100%',  fontSize:'10px'}} maxlength="300"/>
																	
															</td>
														</tr>
														<tr>
																<td contenteditable="false" style={{borderBottom:'1px solid #000'}}>
																	<table contenteditable="false" width="100%" cellpadding="0" cellspacing="0">
																		<tr>
																			<td contenteditable="false"  style={{padding:'2px 0px', borderRight:'1px solid #000'}} height="70">
																					<label contenteditable="false">3 Check appropriate box for federal tax classification of the person whose name is entered on line 1. Check only one of the
																						
																	following seven boxes. </label><br/>
																	<table contenteditable="false" width="100%" cellpadding="0" cellspacing="2" class="tables2">
																			<tr>
																				<td contenteditable="false" width="30%" >
																						<label contenteditable="false" style={{marginLeft:'0px'}}><input id="single_member_LLC" value={this.state.single_member_LLC} onChange={this.onchangecheckbox} checked={(this.state.single_member_LLC==true)?'checked':''} type="checkbox"/> Individual/sole proprietor or
																							single-member LLC</label>
																				</td>
																				<td contenteditable="false" >
																						<label contenteditable="false" style={{marginLeft:'0px'}}><input type="checkbox"/> C Corporation</label>
																				</td>
																				<td contenteditable="false" >
																						<label contenteditable="false" style={{marginLeft:'0px'}}><input type="checkbox"/> S Corporation</label>
																				</td>
																				<td contenteditable="false" >
																						<label contenteditable="false" style={{marginLeft:'0px'}}><input type="checkbox"/> Partnership</label>
																				</td>
																			
																				
																			</tr>
																			<tr>
																					<td contenteditable="false" width="30%" >
																							<label contenteditable="false" style={{marginLeft:'0px'}}><input type="checkbox"/> Trust/estate</label>
																					</td>
																					<td contenteditable="false" >
																						
																					</td>
																					<td contenteditable="false" >
																						
																					</td>
																					<td contenteditable="false" >
																						
																					</td>
																				
																					
																				</tr>

																	</table>
																	<table contenteditable="false" width="100%" cellpadding="0" cellspacing="2" class="tables2" >
																		<tr>
																			<td>
																				
																					<label contenteditable="false"  style={{marginLeft:'0px'}}><input type="checkbox"/> Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)<img src="/arrow.png"/> <input type="text" style={{border:'0px', width:'60px', borderBottom:'1px solid #000',  fontSize:'10px'}} maxlength="8"/>
																						<br/>
																						<strong>Note:</strong> Check the appropriate box in the line above for the tax classification of the single-member owner. Do not check
																						LLC if the LLC is classified as a single-member LLC that is disregarded from the owner unless the owner of the LLC is
																						another LLC that is not disregarded from the owner for U.S. federal tax purposes. Otherwise, a single-member LLC that
																						is disregarded from the owner should check the appropriate box for the tax classification of its owner.</label>  
																			</td>
																			
																		</tr>
																		<tr>
																				<td>
																						<div class="left1"><input type="checkbox"/><label contenteditable="false" style={{marginLeft:'0px'}}>Other (see instructions)<img src="/arrow.png"/> </label></div>
																						<div class="left2"><input type="text" style={{border:'0px', width:'60px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="200"/></div>
																				</td>
																		</tr>
																	</table>	
		
			
				
					
																			</td>
																			<td contenteditable="false" style={{padding: '2px 3px'}} width="25%" valign="top"> 
																					<label contenteditable="false">4 Exemptions (codes apply only to
																							certain entities, not individuals; see
																							instructions on page 3):
																							 </label><br/><br/>   
																							 <label contenteditable="false"> Exempt payee code (if any)	</label>
																							 <input type="text" style={{border:'0px', width:'21px', borderBottom:'1px solid #000',  fontSize:'10px'}} maxlength="8"/>	<br/><br/>
																							 <label contenteditable="false" style={{marginTop:'4px'}}> Exemption from FATCA reporting <br/>code (if any)
																								</label>
																							 <input type="text" style={{border:'0px', width:'80px', borderBottom:'1px solid #000',  fontSize:'10px'}} maxlength="8"/> <br/><br/>
																							 <label style={{fontSize:'7px', fontStyle:'italic'}}>(Applies to accounts maintained outside the U.S.)
																								</label>
																			</td>					

																		</tr>
																	</table>
																		
																</td>
															</tr>
														<tr>
																<td contenteditable="false" style={{borderBottom:'1px solid #000'}}>
																	<table contenteditable="false" width="100%" cellpadding="0" cellspacing="0">
																		<tr>
																			<td contenteditable="false"  style={{padding:'2px 0px', borderRight:'1px solid #000'}}>
																					<div  style={{borderBottom:'1px solid #000'}}>
																							<label contenteditable="false">5 Address (number, street, and apt. or suite no.) See instructions
																								</label>
																							<input type="text" id="full_address" value={this.state.full_address} onChange={this.handleOnChnage} style={{border:'0px', width:'100%',  fontSize:'10px'}}  maxlength="300"/>
																					</div>
																					
																					<div  style={{marginTop:'4px'}}>
																							<label contenteditable="false">6 City, state, and ZIP code</label>
																							<input type="text" id="city_state_zip" value={this.state.city_state_zip} onChange={this.handleOnChnage} style={{border:'0px', width:'100%',  fontSize:'10px'}}  maxlength="300"/>
																					</div>
																			</td>
																			<td contenteditable="false" style={{padding:'2px 3px'}} width="40%"> 
																				<label contenteditable="false">Requester’s name and address (optional)</label>
																				<textarea style={{border:'0px', width:'100%',  fontSize:'10px'}}  rows="2"></textarea></td>
																		</tr>
																	</table>
																		
																</td>
															</tr>
														<tr>
																<td contenteditable="false" style={{borderBottom:'0px solid #000'}}>
																		<label contenteditable="false">7 List account number(s) here (optional)</label>
																		<input type="text" style={{border:'0px', width:'100%',  fontSize:'10px'}} maxlength="300"/>
																		
																</td>
															</tr>
												</table>
											</div>
											</div>



											<table contenteditable="false" width="100%" cellpadding="0" cellspacing="0">
												<tr>
													<td contenteditable="false">
														
															<div contenteditable="false" style={{float:'left', background:'#000', color:'#fff', fontSize:'11px', padding:'3px 8px'}}>Part I</div>
															<div contenteditable="false" style={{fontSize:'12px', padding:'2px 8px',  marginLeft:'60px', fontWeight: 'bold'}}>Taxpayer Identification Number (TIN)</div>
															<div style={{clear:'both'}}></div>
													</td>
												</tr>
												<tr>
													<td contenteditable="false" style={{borderTop:'1px solid #000000', borderBottom:'1px solid #000000'}} className="pd1">
															<table contenteditable="false" width="100%" cellpadding="0" cellspacing="0">
																	<tr>
																		<td contenteditable="false" style={{padding:'4px'}} >
																				<label contenteditable="false" >Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid
																				backup withholding. For individuals, this is generally your social security number (SSN). However, for a
																				resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other
																				entities, it is your employer identification number (EIN). If you do not have a number, see How to get a
																				TIN, later.</label><br/><br/>
																				<label contenteditable="false" ><strong>Notes:</strong> If the account is in more than one name, see the instructions for line 1. Also see <em>What Name and
																				Number To Give the Requester</em> for guidelines on whose number to enter.</label>
																		</td>
																		<td contenteditable="false" width="132" valign="top" style={{paddingLeft:'4px'}}>
																			<div style={{border:'1px solid #000', borderTop:'0px', padding:'1px 3px', position:'relative'}}>Social security number</div>
																			<input type="text" id="social_security_number" value={this.state.social_security_number} onChange={this.handleOnChnage} style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderRight:'0px', width:'80px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="10"/>
																			{/*<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderRight:'0px', width:'15px', height:'12px',  fontSize:'10px', marginRight:'-3px'}} maxlength="2"/>
																			 <input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', width:'15px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="2"/>
																			&nbsp;&nbsp;-&nbsp;
																			<input type="text"style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderRight:'0px', width:'15px', height:'12px',  fontSize:'10px', marginRight:'-3px'}} maxlength="2"/>
																			<input type="text"style={{border:'0px', border:'1px solid #000', borderTop:'0px', width:'15px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="2"/>
																			&nbsp;&nbsp;-&nbsp;
																			<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderRight:'0px', width:'14px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="2"/>
																			<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderRight:'0px', width:'14px', height:'12px',  fontSize:'10px', marginRight:'-3px'}} maxlength="2"/>
																			<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderRight:'0px', width:'14px', height:'12px',  fontSize:'10px', marginRight:'-3px'}} maxlength="2"/>
																			<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', width:'14px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="2"/> */}
																			<strong style={{margin:'2px 0', display:'block'}}>Or</strong>
																			<div style={{border:'1px solid #000', padding:'1px 3px', position: 'relative'}}>Employer identification number</div>
																			<input type="text" id="employer_identification_number" value={this.state.employer_identification_number} onChange={this.handleOnChnage} style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderBottom:'0px', width:'80px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="15"/>
																			{/* <input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderBottom:'0px', width:'15px', height:'12px',  fontSize:'10px', marginRight:'-3px'}} maxlength="2"/>
																			<input type="text"style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderBottom:'0px', width:'15px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="2"/>
																			&nbsp;&nbsp;-&nbsp;
																			<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderBottom:'0px', width:'15px', height:'12px',  fontSize:'10px', marginRight:'-3px'}} maxlength="2"/>
																			<input type="text"style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderBottom:'0px', width:'15px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="2"/>
																			&nbsp;&nbsp;-&nbsp;
																			<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderBottom:'0px', width:'14px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="2"/>
																			<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderBottom:'0px', width:'14px', height:'12px',  fontSize:'10px', marginRight:'-3px'}} maxlength="2"/>
																			<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderBottom:'0px', width:'14px', height:'12px',  fontSize:'10px', marginRight:'-3px'}} maxlength="2"/>
																			<input type="text" style={{border:'0px', border:'1px solid #000', borderTop:'0px', borderBottom:'0px', width:'14px', height:'12px',  fontSize:'10px', marginRight:'-2px'}} maxlength="2"/> */}
																			</td>
																	</tr>
																</table>

													</td>
												</tr>
												<tr>
														<td contenteditable="false">
															
																<div contenteditable="false" style={{float:'left', background:'#000', color:'#fff', fontSize:'11px', padding:'3px 8px'}}>Part II</div>
																<div contenteditable="false" style={{fontSize:'12px', padding:'2px 8px',  marginLeft:'60px', fontWeight:'bold'}}>Certification</div>
																<div style={{clear:'both'}}></div>
														</td>
													</tr>
													<tr>
														<td contenteditable="false" style={{borderTop:'1px solid #000000', borderBottom:'1px solid #000000', padding:'2px 0'}}>
															<label contenteditable="false"> penalties of perjury, I certify that:</label>
															<ol style={{paddingLeft:'15px'}}>
																<li>The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and</li>
																<li> I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue
																		Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am
																		no longer subject to backup withholding; and</li>
																<li> I am a U.S. citizen or other U.S. person (defined below); and</li>
																<li>The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct</li>
															
															</ol>
															<label contenteditable="false"><stron>Certification instructions.</stron> You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because
																	you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid,
																	acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments
																	other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.</label>
														</td>
													</tr>
												
											</table>
											<table contenteditable="false" width="100%" cellpadding="0" cellspacing="0" className="f10">
												<tr>
													<td contenteditable="false" style={{fontSize:'12px', lineHeight:'12px', borderBottom:'1px solid #000'}} width="10%">
														<strong>Sign<br/>
														Here</strong>
													</td>
													<td contenteditable="false" style={{fontSize:'10px', borderBottom:'1px solid #000', borderLeft:'1px solid #000', padding:'4px'}}>
															Signature of<br/>
U.S. person<img src="/arrow.png"/>
														</td>
														<td contenteditable="false" style={{fontSize:'10px', borderBottom:'1px solid #000'}}>
															
																Date<img src="/arrow.png"/>
															</td>
												</tr>

											</table>
											<table contenteditable="false" width="100%" cellpadding="0" cellspacing="0" className="f10" className="ins">
													<tr>
														<td contenteditable="false" style={{fontSize:'11px', lineHeight:'12px',  padding:'4px 0'}} width="50%">
															<h3 contenteditable="false" style={{margin:'0px'}}>General Instructions</h3>
															<p contenteditable="false" className="space1">
																	Section references are to the Internal Revenue Code unless otherwise
																	noted.
															</p>
															<p contenteditable="false" className="space1">
																	Future developments. For the latest information about developments
																	related to Form W-9 and its instructions, such as legislation enacted
																	after they were published, go to <em>www.irs.gov/FormW9.</em>
															</p>
															<h3 ontenteditable="false" style={{margin:'0px'}}>Purpose of Form</h3>
															<p contenteditable="false" className="space1">
																	An individual or entity (Form W-9 requester) who is required to file an
																	information return with the IRS must obtain your correct taxpayer
																	identification number (TIN) which may be your social security number
																	(SSN), individual taxpayer identification number (ITIN), adoption
																	taxpayer identification number (ATIN), or employer identification number
																	(EIN), to report on an information return the amount paid to you, or other
																	amount reportable on an information return. Examples of information
																	returns include, but are not limited to, the following.
															</p>
															<p contenteditable="false" className="space1">• Form 1099-INT (interest earned or paid)</p>

														</td>
														<td contenteditable="false" valign="top" style={{padding:'4px'}}>
															<p contenteditable="false" className="space1">• Form 1099-DIV (dividends, including those from stocks or mutual
																	funds)</p>
															<p contenteditable="false" className="space1">• Form 1099-MISC (various types of income, prizes, awards, or gross
															proceeds)
															</p>
															<p contenteditable="false"className="space1">• Form 1099-B (stock or mutual fund sales and certain other
																transactions by brokers)
															</p>
															<p contenteditable="false" className="space1">• Form 1099-S (proceeds from real estate transactions)
																</p>
															<p contenteditable="false" className="space1">• Form 1099-K (merchant card and third party network transactions)</p>
															<p contenteditable="false" className="space3">• Form 1098 (home mortgage interest), 1098-E (student loan interest),
																	1098-T (tuition)
															</p>	
															<p contenteditable="false" className="space1">• Form 1099-C (canceled debt)</p>
															<p contenteditable="false" className="space1">• Form 1099-A (acquisition or abandonment of secured property)</p>		
															<label contenteditable="false" className="space2">Use Form W-9 only if you are a U.S. person (including a resident
																	alien), to provide your correct TIN. </label><br/><br/>
																	<label contenteditable="false" className="space2"><em>If you do not return Form W-9 to the requester with a TIN, you might
																			be subject to backup withholding.</em> See What is backup withholding,
																		<em>later.</em> </label>								
														</td>
														
													</tr>
	
												</table>
												<div style={{borderTop:'2px solid #000'}}>
													<table contenteditable="false" width="100%" cellpadding="4" cellspacing="0">
														<tr>
														<td align="center">
																<label contenteditable="false">Cat. No. 10231X</label>
														</td>
														<td width="35%" align="right">
																<label contenteditable="false"> Form <span style={{fontSize: '12px', fontWeight:'bold'}}>W-9</span> (Rev. 10-2018)</label>
														</td>
													</tr>
													</table>
												</div>
									
								
													
					
					</div>
					<div className="FooterBox">	
						<div>7</div>
					</div>
					</page>	


						<page size="A4">
						
				
		
					<div style={{padding:'12px 30px 10px'}}>
							<div style={{textAlign:'right', fontFamily:'Times New Roman'}}>LR/11/13/01</div>
							<div style={{textAlign:'center', fontFamily:'Times New Roman', fontSize:'18px'}} contenteditable="false">
							
									<table width="100%" align="center" cellpadding="0" cellspacing="0" contenteditable="false">
													<tr>
														
														<td valign="bottom" align="center" contenteditable="false" style={{border:'1px solid #000'}}>
																
																<p contenteditable="false" style={{margin:'0', padding:'6px 0'}}><strong>LEASE RIDER - LATE PAYMENTS<br/>
																<span style={{fontSize:'14px'}}>for use with</span><br/>
																<span>THE REAL ESTATE BOARD OF NEW YORK, INC.</span><br/>
																<span>APARTMENT LEASE FORMS</span><br/>
																<span>A1/88/A and A-9-01</span></strong></p>
														</td>
														
													</tr>
												</table>
								</div>
						<label contenteditable="false" style={{width:'300px', margin:'20px auto', display:'block', fontFamily:'Times New Roman', fontSize:'14px', lineHeight:'20px'}}>If any installment of rent or additional rent due hereunder is not paid on or before the tenth (10th) day of the calendar month during which such installment is due, Tenant shall pay Owner as additional rent, on or before the first (1st) day of the following month, a late fee equal to five percent (5%) of the monthly rent to defray owner's administrative and other costs in connection with such late payment.</label>
				
					</div>
					<div className="FooterBox">	
						<div>8</div>
					</div>
					</page>
					
					<page size="A4">
					<div style={{textAlign:'center', padding:'10px 30px 0px'}} contenteditable="false" >
						
						<div valign="bottom" align="center" contenteditable="false" style={{border:'1px solid #000'}}>
								<strong>THE REAL ESTATE BOARD OF NEW YORK, INC.<br/>
										SPRINKLER DISCLOSURE LEASE RIDER
								</strong>
							</div>
									
					</div>
		
					<div style={{padding:'12px 80px 10px'}} className="page9">
						
						<label contenteditable="false">Pursuant to the New York State Real Property Law, Article 7, Section 231-a, effective December 3, 2014 all residential leases must contain a conspicuous notice as to the existence or non-existence of a Sprinkler System in the Leased Premises.</label>
						
						<tr>
							<td contenteditable="false">
								<table width="100%">
									<tr>
										<td contenteditable="false" width="40%">
											<label contenteditable="false">Name of tenant(s):</label>		
										</td>
										<td contenteditable="false" width="60%">											
											<input type="text" id="name_of_tenant" value={this.state.name_of_tenant} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'70%',  fontSize:'10px'}} maxlength="80"/><br/>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="40%">
											<label contenteditable="false">Lease Premises Address:</label>		
										</td>
										<td contenteditable="false" width="60%">											
											<input type="text" id="lease_premises_address" value={this.state.lease_premises_address} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'70%',  fontSize:'10px'}} maxlength="80"/><br/>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="40%">
											<label contenteditable="false">Apartment Number:</label>		
										</td>
										<td contenteditable="false" width="60%">											
											<input type="text" id="apartment_number" value={this.state.apartment_number} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'70%',  fontSize:'10px'}} maxlength="80"/>(the “Leased Premises”)
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="40%">
											<label contenteditable="false">Date of Lease:</label>		
										</td>
										<td contenteditable="false" width="60%">											
											<input type="text" id="date_of_lease" value={this.state.date_of_lease} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'70%',  fontSize:'10px'}} maxlength="30"/>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false"><strong>CHECK ONE:</strong></td>
									</tr>
																
								</table>							
							</td>
						</tr>
						
						<tr>
							<td contenteditable="false">
								<label contenteditable="false"><span className="ml-15"><strong>1. </strong></span><input type="checkbox" id="no_maintained" value={this.state.no_maintained} checked={(this.state.no_maintained==true)?'checked':''} onChange={this.onchangecheckbox} /><strong>There is NO Maintained and Operative Sprinkler System in the Leased Premises.</strong></label><br/>
							</td>
						</tr>
						<tr>
							<td contenteditable="false">
								<label contenteditable="false"><span className="ml-15"><strong>2.</strong> </span><input type="checkbox" id="maintained" value={this.state.maintained} checked={(this.state.maintained==true)?'checked':''} onChange={this.onchangecheckbox} /><strong>There is a Maintained and Operative Sprinkler System in the Leased Premises.</strong> </label><br/><br/>
							</td>
						</tr>
						
						<tr>
							<td contenteditable="false">
								<label contenteditable="false"><span className="ml-40"><strong>A. </strong></span><strong>The last date on which the Sprinkler System was maintained and inspected was on.</strong> </label>
								<input type="text" id="maintained_and_inspected" value={this.state.maintained_and_inspected} onChange={this.handleOnChnage}  style={{border:'0px', borderBottom:'1px solid #000', width:'30%',  fontSize:'10px'}} maxlength="80"/><strong>.</strong><br/><br/>
							</td>
						</tr>
						
						<tr>
							<td contenteditable="false">
								<strong>A “Sprinkler System”</strong> is a system of piping and appurtenances designed and installed in accordance with generally accepted standards so that heat from a fire will automatically cause water to be discharged over the fire area to extinguish it or prevent its further spread(Executive Law of New York, Article 6-C, Section 155-a(5)).<br/>
							</td>
						</tr>
						<hr/>
						
						<tr>
							<td contenteditable="false"><strong>Acknowledgment & Signatures:</strong></td>
						</tr>
						<tr>
							<td contenteditable="false">I, the Tenant, have read the disclosureset forth above.I understand that this notice, as to the existence or non-existence of a Sprinkler System is being provided to me to help me make an informed decision about the Leased Premisesin accordance with New York State Real Property Law Article 7, Section 231-a.<br/></td>
						</tr>
						
						<tr>
									<td contenteditable="false">
										<table width="100%">
											<tr>
												<td contenteditable="false" width="20%">
													<label contenteditable="false">Tenant :</label>		
												</td>
												<td contenteditable="false" width="40%">
													<label contenteditable="false">Name:</label>		
													<input type="text" id="tenant_name1_signature" value={this.state.tenant_name1_signature} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100px',  fontSize:'10px'}} maxlength="80"/>
												</td>
												<td contenteditable="false" width="40%">
													<label contenteditable="false"></label>
												</td>
											</tr>
											<tr>
												<td contenteditable="false" width="20%">
													<label contenteditable="false"></label>		
												</td>
												<td contenteditable="false" width="40%">
													<label contenteditable="false">Signature:</label>		
													<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'100px', float:'right',  fontSize:'10px'}} maxlength="80"/>
												</td>
												<td contenteditable="false" style={{float:'right'}}>
													<label contenteditable="false">Date: </label>
													<input type="text" id="tenant_name1_date" value={this.state.tenant_name1_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100px',  fontSize:'10px'}} maxlength="80"/>
												</td>
											</tr>
											<tr>
												<td><br/></td>
											</tr>
											<tr>
												<td contenteditable="false" width="20%">
													<label contenteditable="false"></label>		
												</td>
												<td contenteditable="false" width="40%">
													<label contenteditable="false">Name:</label>		
													<input type="text" id="tenant_name2_signature" value={this.state.tenant_name2_signature} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100px', float:'right',  fontSize:'10px'}} maxlength="80"/>
												</td>
												<td contenteditable="false" width="40%">
													<label contenteditable="false"></label>
												</td>
											</tr>
											<tr>
												<td contenteditable="false" width="20%">
													<label contenteditable="false"></label>		
												</td>
												<td contenteditable="false" width="40%">
													<label contenteditable="false">Signature:</label>		
													<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'100px', float:'right',  fontSize:'10px'}} maxlength="80"/>
												</td>
												<td contenteditable="false" style={{float:'right'}}>
													<label contenteditable="false">Date: </label>
													<input type="text" id="tenant_name2_date" value={this.state.tenant_name2_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100px', float:'right',  fontSize:'10px'}} maxlength="80"/>
												</td>
											</tr>
											
											<tr>
												<td><br/></td>
											</tr>
											<tr>
												<td contenteditable="false" width="20%">
													<label contenteditable="false">Owner</label>		
												</td>
												<td contenteditable="false" width="40%">
													<label contenteditable="false">Name:</label>		
													<input type="text" id="owner_name_signature" value={this.state.owner_name_signature} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100px', float:'right',  fontSize:'10px'}} maxlength="80"/>
												</td>
												<td contenteditable="false" width="40%">
													<label contenteditable="false"></label>
												</td>
											</tr>
											<tr>
												<td contenteditable="false" width="20%">
													<label contenteditable="false"></label>		
												</td>
												<td contenteditable="false" width="40%">
													<label contenteditable="false">Signature:</label>		
													<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'100px', float:'right',  fontSize:'10px'}} maxlength="80"/>
												</td>
												<td contenteditable="false"  style={{float:'right'}}>
													<label contenteditable="false">Date: </label>
													<input type="text" id="owner_name_date" value={this.state.owner_name_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100px',  fontSize:'10px'}} maxlength="80"/>
												</td>
											</tr>
																						
										</table>
										
										
								</td>
								</tr>
				
					</div>
					<div className="FooterBox">	
						<div>9</div>
					</div>
					</page>	

					<page size="A4">
					
		
					<div className="page10"  style={{fontFamily:'Times New Roman', padding:'12px 30px 10px;'}}>
						
				
								
							<div contenteditable="false"  style={{textAlign:'center', fontSize:'16px', marginBottom:'10px'}}>
								<strong>
									Disclosure of Bedbug Infestation Rider<br/>
								</strong>
								
							</div>						
						
							<div style={{position:'absolute', top:'56px', left:'30px'}}><img src="/dh.png"/></div>	
						<table width="100%" cellpadding="4" style={{fontFamily:'Times New Roman', border:'1px solid #000', fontSize:'12px', position:'relative', padding:'5px' }}>
							
						<tr>	
								
							<td valign="bottom" align="center" contenteditable="false">								
								State of New York<br/>
								<strong>Division Housing and Community Renewal</strong><br/>
								House of Rent Administration<br/>
								Web Site: www.nysdhcr.gov
								
							</td>						
						</tr>
						<tr>		
							<td >						
								<table width="90%" align="center" style={{border:'1px solid #000'}}>
								
									<tr>		
										<td valign="bottom" align="center" contenteditable="false">								
											<strong>
												NOTICE TO TENANT<br/>
												DISCLOSURE OF BEDBUG INFESTATION HISTORY<br/>
											</strong>								
										</td>						
									</tr>

								</table>
							</td>						
						</tr>
						
							<tr>
								<td style={{padding:'0px 3px'}}>
									Pursuant to the NYC Housing Maintenance Code, an owner/managing agent of residential rental property shall furnish to reach tenant signing a vacancy lease a notice that sets forth the property's bedbug infestation history.
								</td>
							</tr>
							
							<tr>
							<td contenteditable="false">
								<table width="100%">
									<tr>
										<td contenteditable="false" width="100%">
											<label contenteditable="false">Name of tenant(s):</label>	<input type="text" id="nyc_tenant_name" value={this.state.nyc_tenant_name} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'70%',  fontSize:'10px'}} maxlength="80"/>	
										</td>
									
									</tr>										
									<tr><td contenteditable="false" width="100%" height="5"></td></tr>	
									<tr>
											<td contenteditable="false" width="100%">
												<label contenteditable="false">Subject Premises:</label>	<input type="text" id="nyc_sub_premises" value={this.state.nyc_sub_premises} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'71%',  fontSize:'10px'}} maxlength="80"/>	
											</td>
										
										</tr>
										<tr><td contenteditable="false" width="100%" height="5"></td></tr>
										<tr>
												<td contenteditable="false" width="100%">
													<label contenteditable="false">Apt. #:</label>	<input type="text" id="nyc_apt" value={this.state.nyc_apt} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'80px',  fontSize:'10px'}} maxlength="80"/>	
												</td>
											
											</tr>
											<tr><td contenteditable="false" width="100%" height="5"></td></tr>
											<tr>
													<td contenteditable="false" width="100%">
														<label contenteditable="false">Date of vacancy lease:</label>	<input type="text" id="nyc_vacancy_lease_date" value={this.state.nyc_vacancy_lease_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'80px',  fontSize:'10px'}} maxlength="80"/>	
													</td>
												
												</tr>
																				
								</table>							
							</td>
						</tr>
						
						<tr>
							<td contenteditable="false" style={{textAlign:'center'}}>
								<strong>BEDBUG INFESTATION HISTORY</strong><br/>
								(Only boxes checked apply)
							</td>
						</tr>
						<tr><td contenteditable="false" width="100%" height="5"></td></tr>
						<tr>
							<td contenteditable="false" >
								<span contenteditable="false" style={{marginLeft:'3px', float:'left'}}>[<input type="checkbox" id="no_bedbug_history" value={this.state.no_bedbug_history} checked={(this.state.no_bedbug_history==true)?'checked':''} onChange={this.onchangecheckbox} />]</span>
								<span >There is no history of any bedbug infestation within the past year in the building or in any apartment.<br/></span>
							</td>
						</tr>
						<tr><td contenteditable="false" width="100%" height="5"></td></tr>
						<tr>
							<td contenteditable="false" >
									<span contenteditable="false" style={{marginLeft:'3px', float:'left'}}>[<input type="checkbox" id="bedbug_sub_eradication_measures" value={this.state.bedbug_sub_eradication_measures} checked={(this.state.bedbug_sub_eradication_measures==true)?'checked':''} onChange={this.onchangecheckbox} />]</span>
									<span >During the past year the building had a bedbug infestation history that has been the subject of eradication measures. The location of the infestation was on the <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'20%',  fontSize:'10px'}} maxlength="80"/> floor(s).</span>
								
							</td>
						</tr>
						<tr><td contenteditable="false" width="100%" height="5"></td></tr>
						<tr>
							<td contenteditable="false" >
									<span contenteditable="false" style={{marginLeft:'3px', float:'left'}}>[<input type="checkbox" id="bedbug_no_sub_eradication_measures" value={this.state.bedbug_no_sub_eradication_measures} checked={(this.state.bedbug_no_sub_eradication_measures==true)?'checked':''} onChange={this.onchangecheckbox} />]</span>
									<span >During the past year the building had a bedbug infestation history on the <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'28%',  fontSize:'10px'}} maxlength="80"/> floor(s) and it has not been the subject of eradication measures.</span>
							</td>
						</tr>
						<tr><td contenteditable="false" width="100%" height="5"></td></tr>
						<tr>
							<td contenteditable="false" >
									<span contenteditable="false" style={{marginLeft:'3px', float:'left'}}>[<input type="checkbox" id="employed_eradication_measures" value={this.state.employed_eradication_measures} checked={(this.state.employed_eradication_measures==true)?'checked':''} onChange={this.onchangecheckbox} />]</span>
								<span >During the past year the building had a bedbug infestation history and eradication measures were employed.</span>
							</td>
						</tr>
						<tr><td contenteditable="false" width="100%" height="5"></td></tr>
						<tr>
							<td contenteditable="false" >
									<span contenteditable="false" style={{marginLeft:'3px', float:'left'}}>[<input type="checkbox" id="not_employed_eradication_measures" value={this.state.not_employed_eradication_measures} checked={(this.state.not_employed_eradication_measures==true)?'checked':''} onChange={this.onchangecheckbox} />]</span>
								<span >During the past year the building had a bedbug infestation history and eradication measures were not employed.</span>
							</td>
						</tr>
						<tr><td contenteditable="false" width="100%" height="5"></td></tr>
						<tr>
							<td contenteditable="false" >
									<span contenteditable="false"style={{marginLeft:'3px', float:'left'}}>[<input type="checkbox" id="bedbug_others" value={this.state.bedbug_others} checked={(this.state.bedbug_others==true)?'checked':''} onChange={this.onchangecheckbox} />]</span>
								<span >Other: <input type="text" id="bedbug_others_reason" value={this.state.bedbug_others_reason} onChange={this.handleOnChnage}  style={{border:'0px', borderBottom:'1px solid #000', width:'80%',  fontSize:'10px'}} maxlength="80"/></span>.<br/><br/><br/>
							</td>
						</tr>
						<tr><td contenteditable="false" width="100%" height="5"></td></tr>
						<tr>
							<td contenteditable="false">
								<table width="100%">
									<tr>
										<td contenteditable="false" width="70%">
											<label contenteditable="false">Signature of tenant(s):</label>
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'70%',  fontSize:'10px'}} maxlength="80"/><br/>											
										</td>
										<td contenteditable="false" width="30%">
											<label contenteditable="false">Dated:</label>
											<input type="text" id="sign_of_teanant_date" value={this.state.sign_of_teanant_date} onChange={this.handleOnChnage}  style={{border:'0px', borderBottom:'1px solid #000', width:'40%',  fontSize:'10px'}} maxlength="80"/><br/>
										</td>
									</tr>

									<tr>
										<td contenteditable="false" width="70%">
											<label contenteditable="false">Signature of Owner/Managing Agent :</label>
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'70%',  fontSize:'10px'}} maxlength="80"/><br/>											
										</td>
										<td contenteditable="false" width="30%">
											<label contenteditable="false">Dated:</label>
											<input type="text" id="sign_of_owner_date" value={this.state.sign_of_owner_date} onChange={this.handleOnChnage}  style={{border:'0px', borderBottom:'1px solid #000', width:'40%',  fontSize:'10px'}} maxlength="80"/><br/>
										</td>
									</tr>
									
								</table>							
							</td>
						</tr>
										
						</table>
						
				
					</div>
					<div className="FooterBox">	
						<div>10</div>
					</div>
					</page>

						
					<page size="A4">
					
		
					<div style={{padding:'12px 30px 10px'}} className="page11">
						
				
						<div style={{border:'1px solid #000', padding:'40px 0'}}>
						<table width="100%" >
						<tr>		
							<td valign="bottom" align="center" contenteditable="false">								
								<strong>Disclosure of Information on Lead-Based Paint and/or Lead-Based Paint Hazards</strong>	
							</td>						
						</tr>
						
						<tr>
							<td contenteditable="false"><strong><span className="ml-9">Lead Warning Statement</span></strong></td>
						</tr>
						
						<tr>
							<td>
								<span className="ml-9">Housing built before 1978 may contain lead-based paint. Lead from paint, paint chips, and dust can pose health hazards if not managed properly. Lead exposure is especially harmful to young children and preg-nant women. Before renting pre-1978 housing, lessors must disclose the presence of known lead-basedpaint and/or lead-based paint hazards in the dwelling. Lessees must also receive a federally approvedpamphlet on lead poisoning prevention.</span><br/>
											
							</td>
						</tr>
						<tr>
							<td contenteditable="false"><strong><span className="ml-9">Lessor’s Disclosure.</span></strong></td>
						</tr>

						<tr>
							<td contenteditable="false"><span className="ml-9">(a) Presence of lead-based paint and/or lead-based paint hazards (Check (i) or (ii) below):</span></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><span className="ml-15">(i) <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'10%', fontSize:'10px'}} maxlength="80"/>Known lead-based paint and/or lead-based paint hazards are present in the housing (explain).<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="80"/></span></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><span className="ml-15">(ii) <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'10%', fontSize:'10px'}} maxlength="80"/>Lessor has no knowledge of lead-based paint and/or lead-based paint hazards in the housing.</span></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><span className="ml-9">(b) Records and reports available to lessor (Check (i) or (ii) below):</span></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><span className="ml-15">(i) <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'10%', fontSize:'10px'}} maxlength="80"/>Lessor has provided the Lessee with all available records and reports pertaining to lead-based paintand/or lead-based paint hazards in the housing (list documents below).<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="80"/></span></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><span className="ml-15">(ii) <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'10%', fontSize:'10px'}} maxlength="80"/>Lessor has no reports or records pertaining to lead-based paint and/or lead-based paint hazards inthe housing.</span></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><strong><span className="ml-9">Lessee’s Acknowledgment</span></strong>(initial)</td>
						</tr>
						
						<tr>
							<td contenteditable="false"><span className="ml-9">(c)  <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'10%', fontSize:'10px'}} maxlength="80"/>Lessee has received copies of all information listed above.</span></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><span className="ml-9">(d)  <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'10%', fontSize:'10px'}} maxlength="80"/> Lessee has received the pamphlet Protect Your Family from Lead In Your Home.</span></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><strong><span className="ml-9">Agent’s Acknowledgment</span></strong>(initial)</td>
						</tr>
						
						<tr>
							<td contenteditable="false"><span className="ml-9">(e)  <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'10%', fontSize:'10px'}} maxlength="80"/>Agent has informed the lessor of the lessor’s obligations under 42 U.S.C. 4852d and is aware ofhis/her responsibility to ensure compliance.</span></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><strong><span className="ml-9">Certification of Accuracy</span></strong></td>
						</tr>
						
						<tr>
							<td contenteditable="false"><span className="ml-9">(e)  <input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'10%', fontSize:'10px'}} maxlength="80"/>The following parties have reviewed the information above and certify, to the best of their knowledge, thatthe information they have provided is true and accurate.</span></td>
						</tr>
						
						<tr>
							<td contenteditable="false">
								<table width="100%">
									<tr>
										<td contenteditable="false" width="25%">
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>											
										</td>
										<td contenteditable="false" width="25%">
											<input type="text" id="certi_lessor1_date" value={this.state.certi_lessor1_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>
										</td>
										<td contenteditable="false" width="25%">
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>											
										</td>
										<td contenteditable="false" width="25%">
											<input type="text" id="certi_lessor2_date" value={this.state.certi_lessor2_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Lessor</label>
										</td>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Date</label>
										</td>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Lessor</label>
										</td>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Date</label>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="25%">
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>											
										</td>
										<td contenteditable="false" width="25%">
											<input type="text" id="certi_lessee1_date" value={this.state.certi_lessee1_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>
										</td>
										<td contenteditable="false" width="25%">
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>											
										</td>
										<td contenteditable="false" width="25%">
											<input type="text" id="certi_lessee2_date" value={this.state.certi_lessee2_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Lessee</label>
										</td>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Date</label>
										</td>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Lessee</label>
										</td>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Date</label>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="25%">
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>											
										</td>
										<td contenteditable="false" width="25%">
											<input type="text" id="certi_agent1_date" value={this.state.certi_agent1_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>
										</td>
										<td contenteditable="false" width="25%">
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>											
										</td>
										<td contenteditable="false" width="25%">
											<input type="text" id="certi_agent2_date" value={this.state.certi_agent2_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/><br/>
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Agent</label>
										</td>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Date</label>
										</td>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Agent</label>
										</td>
										<td contenteditable="false" width="25%">
											<label contenteditable="false">Date</label>
										</td>
									</tr>									
								</table>							
							</td>
						</tr>
																
						</table>
					</div>
				
					</div>
					<div className="FooterBox">	
						<div>11</div>
					</div>
					</page>

					<page size="A4">
					
		
					<div style={{padding:'30px 30px 40px'}}>
							<table width="100%">	
								<tr>
									<td contenteditable="false" width="35%" align="center">
										<img src="/cityofnw.png" width="100"/>
										<br/>
										<strong>
											<span>THE CITY OF NEW YORK</span></strong><br/>
										DEPARTMENT OF HEALTH

										<table>
											<tr>
												<td contenteditable="false" style={{fontSize:'10px', textAlign:'center'}}>
														Rudolph W . Giuliani<br/>
													<em>Mayor</em>
												</td>
												<td contenteditable="false" style={{fontSize:'10px', textAlign:'center'}}>
														Neal L. Cohen, MD<br/>
														<em>Commissioner</em>
												</td>
											</tr>
										</table>
									</td>
									<td contenteditable="false" valign="top" >
											

											<table>
													<tr>
														<td contenteditable="false" style={{textAlign:'center', fontSize:'16px'}}>
																<strong><span contenteditable="false"><u style={{fontSize:'20px'}}>WINDOW GUARDS REQUIRED</u><br/>Lease Notice to Tenant</span></strong>
														</td>														
													</tr>
													<tr>
														<td contenteditable="false" className="f10">
																You are required by lawto have window guards installed in all windowsif a child 10 years of age or younger lives in your apartment
														</td>														
													</tr>
													<tr>
															<td contenteditable="false" height="10">
																
															</td>														
														</tr>
													<tr>
															<td contenteditable="false" className="f10">
																	<span contenteditable="false">Your landlord is required by lawto install window guards in your apartmentif you ask him to install window guards at any time (you need not give a reason).</span><br/>
																	<span contenteditable="false" style={{width:'100%', textAlign:'center', display:'block'}}>OR</span>
																	<span contenteditable="false">if a child 10 years of age or younger lives in your apartment,</span>

															</td>														
														</tr>
												</table>
										
									</td>
								</tr>
							</table>	
				
						
						<table width="100%">						
							<tr>
								<td contenteditable="false">
										<label contenteditable="false"><em>It is a violation of law</em> to refuse, interfere with installation, or remove window guards where required.</label>
								</td>
							</tr>
						</table>
						
						<table width="100%" style={{margin:'10px 0'}}>	
							<tr>
								<td contenteditable="false" style={{padding:'0px 150px'}}>
									<strong>CHECK ONE</strong>
									<table>
										<tr>
											<td contenteditable="false" valign="top"><input type="checkbox" id="child_live_apart" value={this.state.child_live_apart} checked={(this.state.child_live_apart==true)?'checked':''} onChange={this.onchangecheckbox} /></td>
											<td><label contenteditable="false">CHILDREN 10 YEARS OF AGE OR YOUNGER LIVE IN MY APARTMENT</label></td>
										</tr>
										<tr>
												<td contenteditable="false" colspan="2" height="5" ></td>
											
											</tr>
										<tr>
											<td contenteditable="false" valign="top" ><input type="checkbox" id="no_child_live_apart" value={this.state.no_child_live_apart} checked={(this.state.no_child_live_apart==true)?'checked':''} onChange={this.onchangecheckbox} /></td>
											<td><label contenteditable="false">NO CHILDREN 10 YEARS OF AGE OR YOUNGER LIVE IN MY APARTMENT</label></td>
										</tr>
										<tr>
												<td contenteditable="false" colspan="2" height="5" ></td>
											
											</tr>
										<tr>
											<td contenteditable="false" valign="top"><input type="checkbox" id="want_win_gaurds" value={this.state.want_win_gaurds} checked={(this.state.want_win_gaurds==true)?'checked':''} onChange={this.onchangecheckbox} /></td>
											<td><label contenteditable="false">I WANT WINDOW GUARDS EVEN THOUGHI HAVE NO CHILDREN 10 YEARS OF AGE OR YOUNGER</label></td>
										</tr>

									</table>
								</td>
							</tr>
							
						
						</table>
						
						<table width="100%">	
							
							<tr>
								<td contenteditable="false" width="40%"></td>
								<td contenteditable="false" width="60%">
									<input type="text" id="tenant_print" value={this.state.tenant_print} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="80"/>
								</td>
							</tr>
							
							<tr>
								<td contenteditable="false" width="40%"></td>
								<td contenteditable="false">
									Tenant (Print)
								</td>
							</tr>
							<tr>
								<td contenteditable="false" width="40%"></td>
								<td contenteditable="false" width="60%">
									<table width="100%">
									<tr>
										<td contenteditable="false" width="80%">
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/>
										</td>
										<td contenteditable="false" width="20%">
											<input type="text" id="tenant_print_date" value={this.state.tenant_print_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="80"/>
										</td>
									</tr>
									<tr>
										<td contenteditable="false" width="80%">
											Tenant's Signature:
										</td>
										<td contenteditable="false" width="20%">
											Date
										</td>
									</tr>
									
									<tr>
										<td contenteditable="false" width="80%">
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'80%', fontSize:'10px'}} maxlength="80"/>
										</td>
										<td contenteditable="false" width="20%">
											<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="80"/>
										</td>
									</tr>
									<tr>
										<td contenteditable="false" width="80%">
											Tenant's Address:
										</td>
										<td contenteditable="false" width="20%">
											Apt No.
										</td>
									</tr>
									</table>
								</td>
							</tr>
							</table>
							
							
								<div contenteditable="false"><strong><span>RETURN THIS FORM TO:</span></strong></div>
							
							
							<table width="100%" style={{padding:'0px 60px'}}>
								
								<tr>
									<td contenteditable="false"><input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="80"/></td>
								</tr>
								<tr>
									<td contenteditable="false">Owner/Manager</td>
								</tr>
								<tr>
									<td contenteditable="false"><input type="text" id="return_form_owner" value={this.state.return_form_owner} onChange={this.handleOnChnage}  style={{border:'0px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="80"/></td>
								</tr>
								<tr>
									<td contenteditable="false">Owner/Manager's Address</td>
								</tr>
								
						</table>
						<div contenteditable="false"><strong>WFO13</strong><em>(Rev. 11/98)</em></div>
					
								<div contenteditable="false" style={{textAlign:'center', fontSize:'14px'}}>
									<strong><em>For Further Information Call:<br/>Window Falls Prevention (212) 676-2158</em></strong>
								</div>
						
						
					</div>
					<div className="FooterBox">	
						<div>12</div>
					</div>
					</page>

					<page size="A4">

							
					<div style={{padding:'40px 80px 40px', fontFamily:'Times New Roman', lineHeight:'12px'}}>
						<table width="100%" cellpadding="0" cellspacing="0">
								<tr>
										<td contenteditable="false" style={{fontSize:'10px'}}>
												<img src="/rebny.png"/>(03/07)
										</td>
									</tr>
									<tr>
											<td contenteditable="false" height="15">
												
											</td>
										</tr>
							<tr>
								<td contenteditable="false" align="center">
										<strong>NEW YORK CITY LEAD PAINT NOTICE<br/>
											[To be Attached to the Lease of the Apartment]<br/>
									</strong>
									
									<u>LEASE/COMMENCEMENT OF OCCUPANCY NOTICE FOR PREVENTION OF LEAD-BASED PAINT HAZARDS—INQUIRY REGARDING CHILD</u>
								</td>
							</tr>
								<td contenteditable="false" height="10"></td>						
							<tr>
								<td contenteditable="false" >
										<span contenteditable="false">You are required by law to inform the owner if a child under six years of age resides or will reside in the dwelling unit (apartment) for which you are signing this lease/commencing occupancy. If such a child resides or will reside in the unit, the owner of the building is required to perform an annual visual inspection of the unit to determine the presence of lead-based paint hazards.
										<strong><u>IT IS IMPORTANT THAT YOU RETURN THIS FORM TO THE OWNER OR MANAGING AGENT OF YOUR BUILDING TO PROTECT THE HEALTH OF YOUR CHILD.</u></strong>
										If you do not respond to this notice, the owner is required to attempt to inspect your apartment to determine if a child under six years of age resides there.<br/>
										</span>
								</td>
							</tr>
						<tr>
						<td contenteditable="false" >
								<span  className="ml-25">
										If a child under six years of age does not reside in the unit now, but does come to live in it at any time during the year, you must inform the owner in writing immediately. If a child under six years of age resides in the unit, you should also inform the owner immediately at the address below if you notice any peeling paint or deteriorated subsurfaces in the unit during the year.<br/>
									</span>
						</td>
						</tr>
					
					<tr>
					<td contenteditable="false" >
							<span className="ml-25">
									Please complete this form and return one copy to the owner or his or her agent or representative when you sign the lease/commence occupancy of the unit. Keep one copy of this form for your records. You should also receive a copy of a pamphlet developed by the New York City Department of Health and Mental Hygiene explaining about lead-based paint hazards when you sign your lease/commence occupancy.<br/>
								</span>
					</td>
					</tr>
						<tr>
							<td contenteditable="false" height="10" >								
							</td>
						</tr>
					<tr>
						<td contenteditable="false" >
								<table width="100%">
										<tr>
											<td contenteditable="false" width="20%">
												<label contenteditable="false"><u>CHECK ONE:</u></label>		
											</td>
											<td contenteditable="false" width="80%">											
												<input type="checkbox" id="child_under_six_live" value={this.state.child_under_six_live} checked={(this.state.child_under_six_live==true)?'checked':''} onChange={this.onchangecheckbox} />A child under six years of age resides in the unit.
											</td>
										</tr>
										
										<tr>
											<td contenteditable="false" width="20%">
												<label contenteditable="false"></label>		
											</td>
											<td contenteditable="false" width="80%">											
												<input type="checkbox" id="child_under_six_no_live" value={this.state.child_under_six_no_live} checked={(this.state.child_under_six_no_live==true)?'checked':''} onChange={this.onchangecheckbox} />A child under six years of age does not reside in the unit.
											</td>
										</tr>
										
										
																	
									</table>
						</td>
					</tr>
						<tr>
							<td contenteditable="false" width="40%">
								<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'40%', fontSize:'10px'}} maxlength="80"/><label contenteditable="false">(Occupant signature) </label><br/>		
							</td>
						</tr>
				
					<tr>
						<td contenteditable="false" height="20" >								
						</td>
					</tr>
						<tr>
							<td contenteditable="false" >
								<label contenteditable="false" class="left1">Print occupant’s name, address and apartment number</label>
								<div class="left2" contenteditable="false"><input type="text" id="print_occupant_name_addr_apart_no" value={this.state.print_occupant_name_addr_apart_no} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="150"/></div>
							</td>
						</tr>
						
						<tr>
							<td contenteditable="false" width="100%">
								<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'99%', fontSize:'10px'}} maxlength="200"/><br/><br/>
							</td>
						</tr>
						<tr>
								<td contenteditable="false" height="20" >								
								</td>
							</tr>
						<tr>
							<td contenteditable="false" width="100%">
								<u>(NOT APPLICABLE TO RENEWAL LEASE) Certification by owner: I certify that I have complied with the provisions of §27-2056.8 of Article 14 of the Housing Maintenance Code and the rules promulgated thereunder relating to duties to be performed in vacant units, and that I have provided a copy of the New York City Department of Health and Mental Hygiene pamphlet concerning lead-based paint hazards to the occupant.</u><br/><br/>		
							</td>
						</tr>
						
						<tr>
							<td contenteditable="false" width="40%">
								<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'40%', fontSize:'10px'}} maxlength="200"/><label contenteditable="false">(Owner signature) </label><br/><br/>		
							</td>
						</tr>
						
						<tr>
							<td contenteditable="false" >
								<label contenteditable="false" class="left1">RETURN THIS FORM TO</label> 
								<div class="left2" contenteditable="false"><input type="text" id="return_this_form_to" value={this.state.return_this_form_to} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'100%', fontSize:'10px'}} maxlength="30"/></div>		
							</td>
						</tr>
						
						<tr>
							<td contenteditable="false">
								<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'99%', fontSize:'10px'}} maxlength="300"/><br/><br/>
							</td>
						</tr>
						<table width="100%">
						<tr>
							<td contenteditable="false" width="100%" style={{textAlign:'center'}}>
								OCCUPANT: KEEP ONE COPY FOR YOUR RECORDS <br/> OWNER COPY/OCCUPANT COPY <br/>
							</td>
						</tr>
						</table>
						</table>
				
					</div>
					<div className="FooterBox">	
						<div>13</div>
					</div>
					</page>
					<page size="A4">
		
		<div style={{padding:'40px 60px 10px'}}>
			<table width="100%">
				<tr>
					<td contenteditable="false" style={{textAlign:'center'}}>
						LEASE  RIDER <br/> <br/>OCCUPANCY  NOTICE  FOR  INDOOR ALLERGEN  HAZARDS
					</td>
				</tr>
				<tr>
					<td contenteditable="false" height="30">
					
					</td>
				</tr>
				<tr>
					<td contenteditable="false"  style={{lineHeight:'25px'}}>
						l.The owner of the building located at <input type="text" id="building_located_at" value={this.state.building_located_at} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'20%', fontSize:'10px'}} maxlength="80"/> is required, under New York City Administrative Code section 27-2017.1 et seq., to make an annual inspection for indoor allergen hazards (such as mold, mice, rats, and cockroaches) in your apartment and the common areas of the building. The owner must also inspect if you inform him or her that there is a condition in your apartment that is likely to cause an indoor allergen hazard, or you request an inspection, or the City of New York Department of Housing Preservation and Development has issued a violation requiring correction of an indoor allergen hazard for your apartment. If there is an indoor allergen hazard in your apartment, the owner is required to fix it, using the safe work practices that are provided in the law. The owner must also provide new tenants with a pamphlet containing information about indoor allergen hazards.<br/>
					</td>
				</tr>
				<tr>
					<td contenteditable="false"  style={{lineHeight:'25px'}}>
						2.The owner is also required, prior to your occupancy as a new tenant, to fix all visible mold and pest infestations in the apartment, as well as any underlying defects, like leaks, using the safe work practices provided in the law. If the owner provides carpeting or furniture, he or she must thoroughly clean and vacuum  it prior to occupancy. This notice must be signed by the owner or his or her representative, and state that he or she has complied with these requirements.<br/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false" style={{lineHeight:'25px'}}>
						I, <input type="text" id="representative_name" value={this.state.representative_name} onChange={this.handleOnChnage}  style={{border:'0px', borderBottom:'1px solid #000', width:'20%', fontSize:'10px'}} maxlength="80"/>(owner or representative name inprint), certify that I have complied with the requirements of the New York City Administrative Code section 27-2017.5 by removing all visible mold and pest infestations and any underlying defects,and where applicable, cleaning and vacuuming any carpeting and furniture that I have provided to the tenant. I have performed the required work using the safe work practices provided in the law.<br/>
					</td>
				</tr>
				<tr>
					<td contenteditable="false" height="30">								
					</td>
				</tr>
				</table>
				<table width="100%" style={{textAlign:'center'}}>
				
				<tr>
					<td contenteditable="false" width="40%">
						<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'40%', fontSize:'10px'}} maxlength="80"/>
					</td>
					<td contenteditable="false" width="40%">
						<input type="text" id="representative_sign_date" value={this.state.representative_sign_date} onChange={this.handleOnChnage} style={{border:'0px', borderBottom:'1px solid #000', width:'40%', fontSize:'10px'}} maxlength="80"/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false" width="40%">
						<label contenteditable="false">Signature </label>
					</td>
					<td contenteditable="false" width="40%">
						<label contenteditable="false">Date </label>
					</td>
				</tr>
				
			</table>
						
		</div>
		<div className="FooterBox">	
						<div>14</div>
					</div>
		</page>
		<page size="A4">
		
		<div style={{padding:'40px 60px 40px'}} className="page15">
			<table width="100%">
				<tr>
					<td contenteditable="false"  style={{textAlign:'center', fontSize:'12px'}}>
						<strong>PROCEDURE FOR TENANTS REGARDING SUSPECTED GAS LEAKS</strong><br/><br/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false">
						The law requires the owner of the premises to advise tenants that when they suspect that a gas leak has occurred, they should take the following actions:<br/><br/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false">
						1. Quickly open nearby doors and windows and then leave the building immediately; do not attempt to locate the leak. Do not turn on or off any electrical appliances, do not smoke or light matches or lighters, and do no use a house-phone or cell-phone whithin the building;<br/><br/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false">
						2. After leaving the building, from a safe distance away from the building, call 911 immediately to report the suspected gas leak;<br/><br/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false">
						3. After calling 911, call the gas service provider for this building as follows:<br/><br/>
					</td>
				</tr>
				<tr>
					<td contenteditable="false" height="30">
					
					</td>
				</tr>
				
					<tr>
						<td contenteditable="false">
						<table width="100%">
						
							<tr>
								<td contenteditable="false" width="40%">
									<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'40%', fontSize:'10px'}} maxlength="80" placeholder="Con Edition"/>
								</td>
								<td contenteditable="false" width="40%">
									<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'40%', fontSize:'10px'}} maxlength="80" placeholder="(800) 752-6633"/>
								</td>
							</tr>
							
							<tr>
								<td contenteditable="false" width="40%">
									<label contenteditable="false">Provider </label>
								</td>
								<td contenteditable="false" width="40%">
									<label contenteditable="false">Number </label>
								</td>
							</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td contenteditable="false" height="40">
						
						</td>
					</tr>
					
				<tr>
					<td contenteditable="false" style={{textAlign:'center', fontSize:'12px'}}>
						<strong>PROCEDIMIENTO PARA LOS INQUILINOS CUANDO HAY SOSPECHA DE FUGA DE GAS</strong><br/><br/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false">
						La ley require que el propietario de la casa o edificio informe a los inquilinos que cuando sospechan que se ha producido un escape de gas, deben tomar las siguientes medidas:<br/><br/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false">
						1. Abra rapidamente las puertas y ventanas cercanas y salga del edificio inmediatamente; No intente localizar el encienda fosforos ni encendedores, y no utilice un telefono de la casa o un telefono celular dentro del edificio;<br/><br/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false">
						2. Despues de salir del edificio, a una distancia segura del edificio, llame al 911 inmediatamente para reportar sus sospechas;<br/><br/>
					</td>
				</tr>
				
				<tr>
					<td contenteditable="false">
						3. Despues de llamar al 911, llame al proveedor de servicio de gas para este edificio, de la siguiente manera:<br/><br/>
					</td>
				</tr>
				
				<tr>
						<td contenteditable="false">
						<table width="100%">
					
							
							<tr>
								<td contenteditable="false" width="40%">
									<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'40%', fontSize:'10px'}} maxlength="80" placeholder="Con Edition"/>
								</td>
								<td contenteditable="false" width="40%">
									<input type="text" style={{border:'0px', borderBottom:'1px solid #000', width:'40%', fontSize:'10px'}} maxlength="80" placeholder="(800) 752-6633"/>
								</td>
							</tr>
							
							<tr>
								<td contenteditable="false" width="40%">
									<label contenteditable="false">Provider </label>
								</td>
								<td contenteditable="false" width="40%">
									<label contenteditable="false">Telefono </label>
								</td>
							</tr>
							</table>
						</td>
					</tr>
				
				</table>
				<div className="mt-3"><button type="button" class="btn btn-default" onClick={this.handleSubmit}>Save</button> 
				{(this.state.leaseriderId!=undefined && this.state.leaseriderId!="")?
				<button class="btn btn-default" style={{marginLeft:'30px'}} onClick={this.handleDownload}>Download</button>
				:<button class="btn" title="Save the template to download" style={{cursor:'not-allowed',marginLeft:'30px'}}>Download</button>
				}
				</div>			
		</div>
		<div className="FooterBox">	
						<div>15</div>
					</div>
		</page>
		


		</div>
        </div>      
      </div>     
    </div>
  </div>
</div>
{/*End STANDARD FORM OF APARTMENT LEASE*/}	


	{/*Start Adverse Action Modal */}
	<div class="application-approval">				
  <div class="modal fade" id="AdverseAction" role="dialog">
    <div class="modal-dialog">
		        
      <div class="modal-content">
        <div class="modal-header">        
          <h4 class="modal-title">Adverse Action Notice</h4>
        </div>
		<div class="application-approval-confirm">		  
		  <button type="button" onClick={this.contentSave('adverse')} class="btn btn-default">Confirm</button>
          <button type="button" id="Adverseclose" class="btn btn-default" data-dismiss="modal">Cancel</button>
        </div>
        <div class="modal-body">

		<div class="application-details" id="divContentA">	
		  <p>Dear {this.state.client_name},</p>
		  <div id="adverse_content_id">
		     
			 </div>
		 
		  <div class="application-approval-footer">
			  Sincerely, <br/>
			{this.state.agent_name} <br/>
			{this.state.email}<br/>
		  </div>
		</div>

        </div>      
      </div>     
    </div>
  </div>
</div>
{/*End Adverse Action Modal*/}	
				</main>     
            </div>


                );
    }
}
export default Dashboard;
