import React from 'react';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";


class Clanding extends React.Component {
    constructor(props) {
        super(props);
     
        this.state = {
          value: { min: 0, max: 5000 },
        };
      }
    AdvanceSearch() {
        var x = document.getElementById("A_Filter");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      }
    render() {
        return (
   

            <div className="w-bg" style={{height:'100vh'}}>
           
            <div className='container listing-container'>
                <div className="listing-landing">
                    <p className="text-center"><img src="/logo.png" alt="Skywards" /></p>
                    <div className="listing-filter row">
                            <div className="panel_1">
                                    <h4 className="title">Bedroom</h4>
                                    <div className="duration-widget">
                                                <div className="form-group">
                                                <label className="container-rd">
                                                    <input type="radio" name="lease_months" value="12" />
                                                    <span className="checkmark"></span>
                                                    <div className="txt">0</div>
                                                </label>
                                                <label className="container-rd">
                                                    <input type="radio" name="lease_months" value="12" />
                                                    <span className="checkmark"></span>
                                                    <div className="txt">1</div>
                                                </label>
                                                <label className="container-rd">
                                                    <input type="radio"  name="lease_months" value="18" />
                                                    <span className="checkmark"></span>
                                                    <div className="txt">2</div>
                                                </label>
                                                <label className="container-rd">
                                                    <input type="radio"  name="lease_months" value="24" />
                                                    <span className="checkmark"></span>
                                                    <div className="txt">3<sub>+</sub></div>
                                                </label>
                                              
                                                </div>
                                            </div>
                            </div>
                            <div className="panel_2">
                                    <h4 className="title">Price range</h4>
                                    <InputRange
                                    maxValue={5000}
                                    minValue={0}
                                    value={this.state.value}
                                    onChange={value => this.setState({ value })} />
                            </div>
                            <div className="panel_3">
                                    <h4 className="title">Neighborhood</h4>
                                    <div className="neighbor-filter black">
                                    <select>
                                        <option></option>                                    
                                    </select>
                                    </div>
                            </div>
                            <div className="panel_4">
                             <div className="search-wrap"><Link to="/client/browselist" className='btn btn-default'>Search</Link></div>
                             <Link to className="advance-search-widget" onClick={this.AdvanceSearch}>Advance Search <img src="/advance-search.png"/></Link>
                            </div>

                            <div className="advance-search" id="A_Filter" style={{display:'none'}}>
                            <div className="filter-block">
                                    <div class="form-group">
                                        <input type="checkbox" id="1"/>
                                        <label for="1">Only show units with VR preview</label>
                                    </div>
                                    <h5 className="title">Unit Amenities</h5>

                                    <div class="form-group">
                                       <div className="checkbox-inline">
                                            <input type="checkbox" id="2"/>
                                            <label for="2">Cats allowed</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="3"/>
                                            <label for="3">Dogs allowed</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                       <div className="checkbox-inline">
                                            <input type="checkbox" id="4"/>
                                            <label for="4">Duplex</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="5"/>
                                            <label for="5">Outdoor space</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="6"/>
                                            <label for="6">Hardwood floors</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="7"/>
                                            <label for="7">Renovated bathroom</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="8"/>
                                            <label for="8">Renovated Kitchen</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="9"/>
                                            <label for="9">Dishwasher</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="10"/>
                                            <label for="10">W/D</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="11"/>
                                            <label for="11">Furnished/D</label>
                                        </div>
                                    </div>
                                    <h5 className="title">Building amenities</h5>
                                    <div class="form-group">
                                       <div className="checkbox-inline">
                                            <input type="checkbox" id="12"/>
                                            <label for="12">Elevator</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="13"/>
                                            <label for="13">Doorman</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="14"/>
                                            <label for="14">Virtual Doorman</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="15"/>
                                            <label for="15">Gym</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="16"/>
                                            <label for="16">Outdoor areas</label>
                                        </div>
                                        <div className="checkbox-inline">
                                            <input type="checkbox" id="17"/>
                                            <label for="17">Live-in super</label>
                                        </div>
                                        
                                    </div>
                            </div>
                         </div>   
                        </div>
                        <p className="text-center"><Link to="/client/signup" className='btn btn-default'>Sign up & apply today</Link></p>
                         
                </div>
                
        </div>
    </div>

                );
    }
}
export default Clanding;
