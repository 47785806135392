import React, { Component } from 'react';
export default class DragAndDrop extends Component {
    state={
        onMouseoverValue:"",
        onMouseoverIndex:"",  //OldIndex
        onDragOver: "",
        tasks: this.props.tasks
           }
      componentDidMount=()=>{
      }
      onDragOver=(e)=>{
          e.preventDefault();
          var value = e.target.value
         
          this.setState({ onDragOver : value  })

          if(value != this.state.onDragOver && value != undefined)
            this.setState({
                onMouseoverIndex: value
              });
        }

      onDragStart=(ev, id)=>{
        //Index of the element picking up
          ev.dataTransfer.setData("id", id)
      }

      onDrop=(e)=>{
          const { tasks, onMouseoverIndex } = this.state;
          let id = e.dataTransfer.getData("id");
          const temp = tasks;
            function array_move(tasks, old_index, new_index) {
              tasks.splice(new_index, 0, tasks.splice(old_index, 1)[0]);
              return tasks; // for testing
            };
            array_move(tasks, id, onMouseoverIndex)
            this.setState({
              tasks: tasks,
          
            }, ()=>    console.log("Array :" , tasks));
          
      }
 
  render () {
      const { tasks } = this.state 
     const items = this.state.tasks.map((t, index)=>{
       if(t.flag == 0){
       return <li
          id={t.name}
          value={index}
          key={t.name}
          onDragStart={e=> this.onDragStart(e, index)}
          draggable
          className="draggable"
          style={{backgroundColor: "pink", marginBottom: "0px important"}}
          >
          {t.name}
        </li>
       }else{
        return <li
        id={t.name}
        value={index}
        key={t.name}
        onDragStart={e=> this.onDragStart(e, index)}
        className="draggable"
        style={{backgroundColor: "pink", marginBottom: "0px important"}}
        >
        {t.name}
      </li>
       }
         return 
      });
    return (
      <div className="container-drag">
        <ul className="droppable" onDragOver={e=> this.onDragOver(e)} onDrop={e=> this.onDrop(e)}>
            {items}
        </ul>
      </div>
    );
  }
}