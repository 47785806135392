import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { postApiData, setToken, setUserId, getToken, getPasswordSetDone, setPasswordSetDone } from '../../services/Api';
import { Redirect } from 'react-router';

class ChangePassword extends React.Component {
    render() {
        return (
            <div>
                <Content contentProp={this.props.content} />
            </div>
        );
    }
}


class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldpassword: "",
            password: "",
            new_password: "",
            confirm_password: "",
            mymsg: "",
            myerrormsg: "",
            is_password_set: getPasswordSetDone()
        };
        this.handleChange = this.handleChange.bind(this);
    }
    validateForm() {
        if(this.state.is_password_set == 1){
            return this.state.password.length > 0 && this.state.new_password.length >5  && this.state.confirm_password.length >5;
        }else{
            return this.state.new_password.length >5 && this.state.confirm_password.length >5;
        }
        
    }

    handleSubmit = event => {
        event.preventDefault();
        this.onPasswordUpdate();
    }


    async onPasswordUpdate() {
        let where = [{
            url: "/Clients/profile/change-password"

        }];
        const { password, new_password, confirm_password } = this.state;
        if (new_password.length < 6) {
            this.setState({ myerrormsg: "Password Min Length 6 chars" });
        } else {
            if (new_password !== confirm_password) {
                this.setState({ myerrormsg: "Confirm Password not matched." });
            } else {
                    postApiData(where, {'oldPassword': password, 'newPassword':new_password, 'is_password_set': 1},true)
                    .then(res => {
                        if (res.data.success) {
                            this.setState({ mymsg:res.data.message });
                            this.setState({ myerrormsg: '' });
                            this.setState({ password: '' });
                            this.setState({ new_password: '' });
                            this.setState({ confirm_password: '' });
                            setPasswordSetDone(1);
                        }
                    })
                    .catch(error => {
                        let str = error.response.data.error.message;
                        this.setState({ myerrormsg: str });
                    });
            }
        }
    }




    handleChange = (event) => {
        switch (event.target.id) {
            case 'password':
                if (event.target.value.length <= 0) {

                    this.setState({
                        myerror: 'Field is required.'
                    });
                } else {
                    this.setState({ myerror: '' });

                }
                break;
            case 'new_password':
                if (event.target.value.length <= 0) {

                    this.setState({
                        myerror2: 'Field is required.'
                    });
                } else if (event.target.value.length < 6) {

                    this.setState({
                        myerror2: 'Minimum length 6 characters'
                    });
                }else if (document.getElementById('confirm_password').value!="") {
                    if (event.target.value !== document.getElementById('confirm_password').value){
                        this.setState({
                            myerror2: "Passwords don't match"
                        });
                    }else{
                        this.setState({ myerror3: '',myerror2: '',});
                    }
                }
                else {
                    this.setState({ myerror3: '',myerror2: '',});
                }
                break;
            case 'confirm_password':
                if (event.target.value.length <= 0) {

                    this.setState({ myerror3: 'Field is required.'});
                }else if(event.target.value.length < 6){
                    this.setState({ myerror3: 'Minimum length 6 characters'});
                }
                else if (event.target.value !== document.getElementById('new_password').value) {
                    this.setState({
                        myerror3: "Password don't match!"
                    });
                } else {
                    this.setState({ myerror3: '',myerror2: '',});
                }
                break;

        }
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {
        if (!getToken()) {
            return (<Redirect to={'/'}/>);
          }
        return (
            <div>
                <Header />
                <main>
                    <div className='container'>
                        <h2 className="text-center">Change Password</h2>
                        {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
                        {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                        <div className='form-widget login-widget'>
                            <form onSubmit={this.handleSubmit}>
                            {getPasswordSetDone()!=0 ? 
                                <FormGroup controlId="password">
                                    <FormControl
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        type="password"
                                        required
                                        placeholder="Old password"
                                        required
                                    />
                                    <span className="inline-error-class"> {this.state.myerror !== '' ? this.state.myerror : ''}</span>
                                </FormGroup>
                                :''
                            }
                                <FormGroup controlId="new_password">
                                    <FormControl
                                        value={this.state.new_password}
                                        onChange={this.handleChange}
                                        type="password"
                                        required
                                        placeholder="New password"
                                        required
                                    />
                                    <span className="inline-error-class"> {this.state.myerror2 !== '' ? this.state.myerror2 : ''}</span>
                                </FormGroup>
                                <FormGroup controlId="confirm_password">
                                    <FormControl
                                        value={this.state.confirm_password}
                                        onChange={this.handleChange}
                                        type="password"
                                        required
                                        placeholder="Confirm Password"
                                        required
                                    />
                                    <span className="inline-error-class"> {this.state.myerror3 !== '' ? this.state.myerror3 : ''}</span>
                                </FormGroup>
                                <Button
                                    block
                                    disabled={!this.validateForm()}
                                    type="submit"
                                >
                                    Update
                           </Button>
                            </form>
                        </div>

                    </div>
                </main>
            </div>
        );
    }
}
export default ChangePassword;
