import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import {getApiData, postApiData, checkAdminToken,getBaseUrl,getAdminToken} from '../../services/Api';
import {validatePhone} from '../../services/validation';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import CryptoJS from "crypto-js";

class Company extends Component {
    constructor(props){
        super(props);
        this.state={
            companytDetail:[],
            pageLoader: false,
            successQuote: false,
            //Errors
            phone_number_error:'',
            //Agent Details
            company_id:'',
            provider_id:'',
            company_key:'',
            rebny_company_id:'',
            company_name:'',
            company_email:'',
            phone_number:'',
            company_fax:'',
            company_url:'',
            street_address:'',
            city:'',
            state:'',
            postal_code:'',
            remark:'',
            mob_number:"",
            mymsg:'',
            provider_id_error:'',
            company_key_error:'',
            rebny_company_id_error:'',
            company_name_error:'',
            company_email_error:'',
            phone_number_error:'',
            company_fax_error:'',
            website_error:'',
            street_address_error:'',
            city_error:'',
            state_error:'',
            postal_code_error:'',
            remark_error:'',
            company_url_error:'',
            unit_number_error:'',
            unit_number:''
        };
    }

    componentWillMount =()=>{
        this.getCompanyDetail();
       
    }
    handleChange=(e)=>{
       
        switch(e.target.id){
            case 'mob_number': 
            this.formValidate(e.target.id, e.target.value);
            break;
            case 'company_fax': 
            if(e.target.value != ''){
             this.formValidate(e.target.id, e.target.value);
            }
            break;
            case 'postal_code':
            if(e.target.value =='' ){
                this.setState({ postal_code_error : 'Field cannot be empty'})
            }else{
                this.setState({ postal_code_error : ''})
            }
            if(isNaN(e.target.value)){
                this.setState({ postal_code_error : 'Please enter number only.'})
            }else{
                this.setState({ postal_code_error : ''})
            }
            break;
            case 'state':
            if(e.target.value == ''){
                this.setState({ state_error : 'Field cannot be empty'})
            }else{
                this.setState({ state_error : ''})
            }
            break;
            case 'city':
            if(e.target.value == ''){
                this.setState({ city_error : 'Field cannot be empty'})
            }else{
                this.setState({ city_error : ''})
            }
            break;
            case 'street_address':
            if(e.target.value == ''){
                this.setState({ street_address_error : 'Field cannot be empty'})
            }else{
                this.setState({ street_address_error : ''})
            }
            break;
            case 'company_url':
            if(e.target.value == ''){
                this.setState({ company_url_error : 'Field cannot be empty'})
            }else{
                this.setState({ company_url_error : ''})
            }
            if(!this.isUrlValid(e.target.value)){
                this.setState({ company_url_error : 'Please enter Valid Url'})
            }else{
                this.setState({ company_url_error : ''})
            }
            break;
            case 'company_email':
            if(e.target.value == ''){
                this.setState({ company_email_error : 'Field cannot be empty'})
            }else{
                this.setState({ company_email_error : ''})
            }
            if(!this.validateEmail(e.target.value)){
                this.setState({ company_email_error : 'Please enter valid email address'})
            }
            else{
                this.setState({ company_email_error : ''})
            }
            break;
            case 'company_name':
            if(e.target.value == ''){
                this.setState({ company_name_error : 'Field cannot be empty'})
            }else{
                this.setState({ company_name_error : ''})
            }
            break;
            case 'unit_number':
                if(e.target.value == ''){
                    this.setState({ unit_number_error : 'Field cannot be empty'})
                }else{
                    this.setState({ unit_number_error : ''})
                }
                break;
            case 'rebny_company_id':
            if(e.target.value == ''){
                this.setState({ rebny_company_id_error : 'Field cannot be empty'})
            }else{
                this.setState({ rebny_company_id_error : ''})
            }
            break;
            case 'company_key':
            if(e.target.value == ''){
                this.setState({ company_key_error : 'Field cannot be empty'})
            }else{
                this.setState({ company_key_error : ''})
            }
            break;
            case 'provider_id':
                if(e.target.value == ''){
                    this.setState({ provider_id_error : 'Field cannot be empty'})
                }else{
                    this.setState({ provider_id_error : ''})
                }
                break;
           case 'company_id':
                if(e.target.value ==''){
                    this.setState({ company_id_error : 'Field cannot be empty'})
                }else{
                    this.setState({ company_id_error : ''})
                }
                break;
        }
      this.setState({
          [e.target.id] : e.target.value
      })
    }
    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    isUrlValid(userInput) {
        var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if(res == null)
            return false;
        else
            return true;
    } 
    formValidate(key,value){
    switch(key){
        case 'mob_number':
        this.validateEmpPhoneNumber(value);
        break;
        case 'company_fax':
        this.validateFaxNumber(value);
        break;
        }
    }
    validateEmpPhoneNumber(value){
        let res=validatePhone(value);
                    if(res.status==false){
                        this.setState({phone_number_error: 'Phone number is not valid.'});
                        document.getElementById('mob_number').value=res.value;
                    }else{
                        this.setState({phone_number_error: ''});
                        document.getElementById('mob_number').value=res.value;
                    }
                return res.status;
    } 
    validateFaxNumber(value){
        let res=validatePhone(value);
                    if(res.status==false){
                        this.setState({company_fax_error: 'Fax number is not valid.'});
                        document.getElementById('company_fax').value=res.value;
                    }else{
                        this.setState({company_fax_error: ''});
                        document.getElementById('company_fax').value=res.value;
                    }
                return res.status;
    } 

    getCompanyDetail=()=>{
        this.setState({'pageLoader':true});
        let whereEditor = [{
			url: "/admin/companyDetail",
			where:{
				'id':1
			}
		}];
		getApiData(whereEditor,true).then(res => {
          var data = res.data[0];
            this.setState({
                company_id: data.company_id,
                provider_id:data.provider_id,
                company_key:data.company_key,
                rebny_company_id:data.REBNY_company_id,
                company_name:data.company_name,
                company_email:data.company_email,
                mob_number:data.company_phone,
                company_fax:data.company_fax,
                company_url:data.company_url,
                street_address:data.street_address,
                city:data.city,
                unit_number:data.unit_number,
                state:data.state,
                postal_code:data.postal_code,
                remark:data.remarks,

            },()=>{
                this.setState({
                    'pageLoader':false,
                })
            })
		}).catch(error => {
				if(error.res){
					this.setState({myerrormsg: error.res.data});
				}
			});
    }
    
    //Handling insertion of Agent Detail
    handleCompanySubmit=(e)=>{
        this.addACompanyDetail()
    }

    validateForm() {
        return (
        this.state.phone_number_error == '' &&
        this.state.company_fax_error == '' &&
        this.state.company_email_error=='' &&
        this.state.company_url_error=='' &&
        this.state.mob_number != '' &&
        this.state.provider_id != '' &&
        this.state.company_key !='' &&
        this.state.rebny_company_id !='' &&
        this.state.company_name!='' &&
        this.state.company_email!='' &&
        this.state.company_url!='' &&
        this.state.street_address!='' &&
        this.state.city!='' &&
        this.state.state!='' &&
        this.state.unit_number!='' &&
       ( this.state.postal_code!='' && !isNaN(this.state.postal_code) && this.state.postal_code.length==5)
         ) 
    }

    async addACompanyDetail() {
        this.setState({ 'pageLoader':true});
        let where = [{
                url: '/admin/addCompanyDetail/'
            }];

            postApiData(where, {"company_id": this.state.company_id, 'provider_id': this.state.provider_id,"company_key":this.state.company_key,"REBNY_company_id": this.state.REBNY_company_id, 'company_name': this.state.company_name,"company_phone":this.state.mob_number,"company_fax": this.state.company_fax, 'company_url': this.state.company_url,"street_address":this.state.street_address,"unit_number": this.state.unit_number, 'city': this.state.city,"state":this.state.state, 'country': 'USA',"postal_code":this.state.postal_code, 'remarks': this.state.remark},true)
            .then(res => {
                window.scrollTo(0,0);
                            this.setState({'pageLoader':false, "successQuote":true, });
                      
        }).catch(error => {
           this.setState({myerrormsg: error.response});
        });
    }


    render() {
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }

        return (
            <div >
                {(!this.state.pageLoader==false)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                        <h2 className="pull-left">Company</h2>
                       
                        <div className="panel-body table-responsive">
                   
                        <Fragment>
                        <table className="table table-striped">
                            <thead><tr><th className="managerGridCol">Edit Company Details</th></tr></thead>
                        </table>

                        <form  style={{marginTop: "10px"}}>
                        {this.state.successQuote?(<div className="alert alert-success"><strong>Company Details Successfully Updated</strong></div>):(null)}
                        {this.state.mymsg !=''?(<div className="alert alert-danger"><strong>{this.state.mymsg}</strong></div>):(null)}
                        <div className="form-group">
                            <label forhtml="name">Company ID*</label>
                            <input type="text" id="company_id" className="form-control" value={this.state.company_id} placeholder="Company ID" />
                        </div>
                        <div className="form-group">
                            <label forhtml="title">Provider ID*</label>
                            <input type="text" id="provider_id" className="form-control" onChange={this.handleChange} value={this.state.provider_id} placeholder="Provider ID" />
                            <span className="inline-error-class"> {this.state.provider_id_error !==''?this.state.provider_id_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="company_key">Company Key*</label>
                            <input type="text" id="company_key" className="form-control" onChange={this.handleChange} value={this.state.company_key} placeholder="Company Key" />
                            <span className="inline-error-class"> {this.state.company_key_error !==''?this.state.company_key_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="rebny_company_id">REBNY Company ID*</label>
                            <input type="text" id="rebny_company_id" className="form-control" onChange={this.handleChange} value={this.state.rebny_company_id} placeholder="REBNY Company ID" />
                            <span className="inline-error-class"> {this.state.rebny_company_id_error !==''?this.state.rebny_company_id_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="company_name">Company Name*</label>
                            <input type="text" id="company_name" className="form-control" onChange={this.handleChange} value={this.state.company_name} placeholder="Company Name" />
                            <span className="inline-error-class"> {this.state.company_name_error !==''?this.state.company_name_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="company_email">Company Email*</label>
                            <input type="text" id="company_email" className="form-control" onChange={this.handleChange} value={this.state.company_email} placeholder="Company Email" />
                            <span className="inline-error-class"> {this.state.company_email_error !==''?this.state.company_email_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="mob_number">Company Phone*</label>
                            <input type="text"  id="mob_number" className="form-control" onChange={this.handleChange} value={this.state.mob_number == 0? '': this.state.mob_number} maxLength='12' placeholder="XXX-XXX-XXXX" />
                            <span className="inline-error-class"> {this.state.phone_number_error !==''?this.state.phone_number_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="company_fax">Company Fax*</label>
                            <input type="text"  id="company_fax" className="form-control" onChange={this.handleChange} value={this.state.company_fax == 0? '': this.state.company_fax} maxLength='12' placeholder="XXX-XXX-XXXX" />
                            <span className="inline-error-class"> {this.state.company_fax_error !==''?this.state.company_fax_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="website">Company Website URL*</label>
                            <input type="text"  id="company_url" className="form-control" onChange={this.handleChange} value={this.state.company_url} placeholder="Company Website URL" />
                            <span className="inline-error-class"> {this.state.company_url_error !==''?this.state.company_url_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="street_address">Street Adderss*</label>
                            <input type="text" id="street_address" className="form-control" onChange={this.handleChange} value={this.state.street_address} placeholder="Street Adderss" />
                            <span className="inline-error-class"> {this.state.street_address_error !==''?this.state.street_address_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="street_address">Unit Number*</label>
                            <input type="text" id="unit_number" className="form-control" onChange={this.handleChange} value={this.state.unit_number} placeholder="Street Adderss" />
                            <span className="inline-error-class"> {this.state.unit_number_error !==''?this.state.unit_number_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="city">City*</label>
                            <input type="text" id="city" className="form-control" onChange={this.handleChange} value={this.state.city} placeholder="City" />
                            <span className="inline-error-class"> {this.state.city_error !==''?this.state.city_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="state">State*</label>
                            <input type="text" id="state" className="form-control" onChange={this.handleChange} value={this.state.state} placeholder="State" />
                            <span className="inline-error-class"> {this.state.state_error !==''?this.state.state_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="postal_code">Country*</label>
                            <input type="text" id="postal_code" autoComplete="off" className="form-control" readonly value='USA' placeholder="Postal Code" />
                            <span className="inline-error-class"> </span>
                        </div>
                        <div className="form-group">
                            <label forhtml="postal_code">Postal Code*</label>
                            <input type="text" id="postal_code" maxLength='5' autoComplete="off" className="form-control" onChange={this.handleChange} value={this.state.postal_code} placeholder="Postal Code" />
                            <span className="inline-error-class"> {this.state.postal_code_error !==''?this.state.postal_code_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="remark">Remarks</label>
                            <textarea className="form-control" id="remark" rows="3" onChange={this.handleChange} value={this.state.remark} placeholder="Remarks"></textarea>
                            <span className="inline-error-class"> {this.state.remark_error !==''?this.state.remark_error:''}</span>
                        </div>
                        <div className="form-group">
                          
                   
                        </div>

                             <button type="button" onClick={this.handleCompanySubmit} className="btn btn-primary" disabled={!this.validateForm()}>Submit</button>
                        </form>
                        </Fragment>

                            </div>
                    </div>
                    
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default Company;