import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import FullViewPdf from "./FullViewPdf";
import PdfEditorHeader from "../common/PdfEditorHeader";
import {
  loadInittialFile,
  addNewFileToMerge,
  downloadFinalPdf,
} from "../../services/pdfservices";
import { getBaseUrl } from "../../services/Api";

const ModifyPdf = (props) => {
  const { match } = props;
  const path = match.params.path;
  const [loader, setLoader] = useState(false);
  const [renderPageNo, setRenderPageNum] = useState(0);
  const [items, setItems] = useState([]);
  const [servePath, setServePath] = useState("");
  const token = localStorage.getItem("token");
  const baseUrl = getBaseUrl(); 
  const fetchData = async () => {
    try {
      setLoader(true);
     const filePath = `/home/ubuntu/api/files/application_pdf/${path}`; //filename
      const result = await loadInittialFile(token, filePath);
      setServePath(result.servePath);
      const extractedNumbers = result.sendeResponsePng.map((fileName) => {
        const match = fileName.match(/^(\d+)/);
        return match ? match[1] : null;
      });
      setItems(extractedNumbers.filter((num) => num !== null));
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [path]);

  const handlePageNumber = (event) => {
    setRenderPageNum(event + 1);
  };

  const handleUpButton = (index) => {
    if (index > 0) {
      const updatedItems = [...items];
      [updatedItems[index], updatedItems[index - 1]] = [
        updatedItems[index - 1],
        updatedItems[index],
      ];
      setItems(updatedItems);
    }
  }; 
  const handleDownButton = (index) => {
    if (index < items.length - 1) {
      const updatedItems = [...items];
      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
      setItems(updatedItems);
    }
  };

  const handleDeleteButton = (index) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this page?"
    );
    if (confirmation) {
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
     }
  };

  const handlefileOnChange = async (index, e) => {
    try {
      const formData = new FormData();
      formData.append("pdf", e.target.files[0]);
      formData.append("items", [items]);
      formData.append("index", index);
      formData.append("servePath", servePath);
      setLoader(true);
      e.target.value = null;
      const response = await addNewFileToMerge(token, formData);
       setItems(response.sendeResponsePng);
       setLoader(false);
      // console.log("API Response:", response);
    } catch (error) {
      setLoader(false);
      console.error("API Error:", error.message);
    }
  };

  const handleFinalDownload = async () => {
    try {
      setLoader(true); 
     const response = await downloadFinalPdf(token, {
        items,
        servePath, 
      });
      setLoader(false);
      const pdfUrl = baseUrl + response.filePath;
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        console.log("API Response:", response);
      } else {
        console.error("Unable to open new tab");
      }
    } catch (error) {
      setLoader(false);
      console.error("API Error:", error.message);
    }
  };

  // Memoize FullViewPdf component
  const MemoizedFullViewPdf = useMemo(() => (
    <FullViewPdf
      renderPageNo={renderPageNo}
      items={items}
      setLoader={setLoader}
      servePath={servePath}
    />
  ), [renderPageNo, items, servePath]);

  return (
    <div>
      {loader && <div className="show_loader_on_click" id="show_loader_on_click"></div>}
      <PdfEditorHeader />
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-2">
            <div className="sidebar_content_pdf">
              {items.map((item, index) => (
                <div
                  className={`card position-relative mb-3 ${
                    (renderPageNo - 1) === index ? "border border-primary" : ""
                  }`}
                  key={index}
                  onClick={() => handlePageNumber(index)}
                >
                  <div className="page_no">{index + 1}</div>
                  <div className="pic_body">
                    <img
                      key={`${item}-${index}`}
                      src={`${baseUrl}${servePath}/png/${item}.1.png`}
                      alt={`Page ${index + 1}`}
                    />
                  </div>
                  <div className="priview_page_wrap">
                    <div
                      onClick={() => handleUpButton(index)}
                      className="icon_div"
                      style={{
                        borderBottom: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                      }}
                      title="Move Up"
                    >
                      <i
                        className={`  px-2 fa fa-angle-up  ${
                          index === 0 ? "disabled" : ""
                        }`}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      onClick={() => handleDownButton(index)}
                      className="icon_div"
                      style={{
                        borderBottom: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                      }}
                      title="Move Down"
                    >
                      <i
                        className={`  px-2 fa fa-angle-down  ${
                          index === items.length - 1 ? "disabled" : ""
                        }`}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      onClick={() => handleDeleteButton(index)}
                      className="icon_div"
                      style={{
                        borderBottom: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                      }}
                      title="Delete Page"
                    >
                      <i className="  px-2 fa fa-trash-o " aria-hidden="true"></i>
                    </div>
                    <div
                      className="icon_div"
                      style={{
                        borderBottom: "1px solid #ccc",
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                        width: "unset !important",
                        position: "relative",
                      }}
                      title="Add New Page"
                    >
                      <div className="add__file">
                        <div class="file-input">
                          <input
                            key={index + 1}
                            type="file"
                            name={`file-input-${index + 1}`}
                            accept=".pdf"
                            id={`file-input-${index + 1}`}
                            onChange={(e) => handlefileOnChange(index + 1, e)}
                            className="file-input__input"
                          />
                          <label
                            className="file-input__label"
                            htmlFor={`file-input-${index + 1}`}
                          >
                            <i
                              className="fa fa-plus-square-o px-2"
                              aria-hidden="true"
                            ></i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-10">
            <div className="main_content_pdf">
              {items && items.length > 0 && (
                <div className="d-flex justify-content-md-end">
                  <button className="btn btn-primary border-0"  onClick={handleFinalDownload}>
                    Download
                  </button>
                </div>
              )}

              {/* Render MemoizedFullViewPdf component */}
              {MemoizedFullViewPdf}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifyPdf;
