import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ManagerHeader from '../../common/ManagerHeader';
import ManagerSidebar from '../../common/ManagerSidebar';
import AgentHeader from '../../common/AgentHeader';
import AgentSidebar from '../../common/AgentSideBar';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Moment from 'moment';
import {getApiData, setAgentToken, getAgentToken, postApiData,getAgentId,getBaseUrl,getAgentName,getAgent_number,getAgentEmail} from '../../../services/Api';
import InputRange from 'react-input-range';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-input-range/lib/css/index.css';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import { element } from 'prop-types';
import DragSortableList from 'react-drag-sortable';
import { isNullOrUndefined } from 'util';
import { stat } from 'fs';


const checkedBoxes =[];
const mareketcheckedBoxes =[];
let key = encHex.parse("0123456789abcdef0123456789abcdef");
	let iv =  encHex.parse("abcdef9876543210abcdef9876543210"); 

	let sortedListVar;


class ManagerListing extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
		
			value: { min: 0, max: 10500 },	
			listingData:[],
			neighoberData:[],
			linkingLoader:true,
			addressData:[],
			building_address:'',
			listing_info_address:'',
			unit_number:'',
			bedrooms:'',
			bathrooms:'',
			price:'',
			priceSearch:{min: 100, max: 10500},
			Neighbourhood:'',
			description:'',
			description_show:'',
			furnished:1,
			furnished_status:false,
			square_footage:'',
			incentives:'',
			incentivesshow:'',
			tenant_incentive:'',
			agent_name:'',
			realname:'',
			agent_image:'',
			agent_email:'',
			agent_mob:'',
			amenity_name:'',
			owner_pays:'',
			date_available:'',
			date_available1:'',
			Building_Id:'',
			bID:'',
			status:'',
			commission_percentage:'',
            my_split:0,
			co_broker_split:100,
            final_precentage:0,
			amenity_list:[],
			building_amenity_list:[],
			amenityData:[],
			building_amenities:'',
			checkedBoxes:[],
			marketcheckedBoxes:[],
			validation:true,
			popupvalidation:false,
			listing_id:'',
			noFee:false,
			checked:false,
			imagePathArray:[],
			imagePath2:'',
			filename:'',
			price_error:'',
			bedrooms_error:'',
			bathrooms_error:'',
			square_footage_error:'',
			description_error:'',
			date_error:'',
			type:'',
			addressDW:[],
			neighborDW:[],
			addressDWS:[],
			where:{},
			ckPending:{'checked':true,'value':1},
			ckApproved:{'checked':true,'value':1},
			ckExpired:{'checked':false,'value':0},
			ckAll:{'checked':false,'value':0},
			ckcontractsigned:{'checked':false,'value':0},
			ckleasesigned:{'checked':false,'value':0},
			cksold:{'checked':false,'value':0},
			ckrented:{'checked':false,'value':0},
			ckwithdrawn:{'checked':false,'value':0},
			ckTOM30:{'checked':false,'value':0},
			cknotreleased:{'checked':false,'value':0},
			ckcomingsoon:{'checked':false,'value':0},

			ckRelist:{'checked':true,'value':1},
			ckbedroom_0:{'checked':true,'value':1},
			ckbedroom_1:{'checked':true,'value':1},
			ckbedroom_2:{'checked':true,'value':1},
			ckbedroom_3:{'checked':true,'value':1},
			ckbedroom_4:{'checked':true,'value':1},
			ckbathroom_1:{'checked':true,'value':1},
			ckbathroom_2:{'checked':true,'value':1},
			ckbathroom_3:{'checked':true,'value':1},
			ckbathroom_4:{'checked':true,'value':1},
			searchParm:"",
			searchNeighbor:'',
			existCheckboxSelection:true,
			isCheckboxSelected:true,
			searchListingnId:'',
			searchNeighborhood:'',
			image_vrurl:"",
			edit_description:"",
			image_list:"",
			startDate:'',
			maintenance:"",
			monthly_taxes:"",
			commission:"",
			tax_deduction:"",
			flip_tax:"",
			max_financing:"",
			number_of_shares:"",
			percentofcommon:"",
			kuula_vr_link:"",
			video_link:'',
			// sales info error
			maintenance_error:"",
			monthly_taxes_error:"",
			commission_error:"",
			tax_deduction_error:"",
			flip_tax_error:"",
			max_financing_error:"",
			number_of_shares_error:"",
			percentofcommon_error:"",
			kuula_vr_link_error:"",
			video_link_error:"",
			tabHide:(this.props.salesTab) ? true :false,
			orderBy:'',
			orderByState:'',
			marketing:"",
			marketingTableData:"",
			rentHopCheck:0,
			linkingLoader:false,
			imagePath3:'',
			list_id:'',
			listing_information:'',
			__flag__:'',
			imagePath4:'',
			hideandshow:0,
			selected_id:0,
			selected_status:'',
			close_price:'',
			close_price_error:'',
			UpperSearchDate:new Date(),
			selected_name:[],
			selected_email:[],
			content_value:"I found a new listings that I thought you'd be interested in-feel free to check through the links for more photos and information. Be sure to let me know what you think! If you'd did like to send feedback or request a viewing, you can submit a note on the form located on the right side of each listing.",
			mail_subject:'',
			image_list_value:[],
			instatilefirsttext:'',
			instatilelasttext:'',
			instatilefirsttext_error:'',
			instatilelasttext_error:'',
			instaListingId:'',
			instatileimage_error:'',
			recordPerPage:0,
			recordNextPage:20,
			hasMoreItems: true,
            nextHref: null,
            nextpage:20,
            firstPage:1,
			
	};
	this.setWrapperRef1 = this.setWrapperRef1.bind(this);
	this.setWrapperRef2 = this.setWrapperRef2.bind(this);
	this.setWrapperRef3 = this.setWrapperRef3.bind(this);
	this.setWrapperRef4 = this.setWrapperRef4.bind(this);
	this.setWrapperRef5 = this.setWrapperRef5.bind(this);
	//this.setWrapperRef6 = this.setWrapperRef6.bind(this);
	this.setWrapperRef7 = this.setWrapperRef7.bind(this);
	this.setWrapperRef10 = this.setWrapperRef10.bind(this);
	this.setWrapperRef11 = this.setWrapperRef11.bind(this);
	this.setWrapperRef12 = this.setWrapperRef12.bind(this);
	this.onSort = this.onSort.bind(this);
	this.handleClickOutside = this.handleClickOutside.bind(this);
	this.handleUpperSearchDate= this.handleUpperSearchDate.bind(this);
	this.iScroll = React.createRef();

	  this.createWhere();
	
	 
}
/**
 * make filter dyanamic
 */

onSort =(sortedList, dropEvent)=> {
    console.log("sortedList", sortedList);
	sortedListVar = sortedList;

	if( typeof sortedListVar != 'undefined' && sortedListVar instanceof Array ){
		console.log("in saveListOrder--", sortedListVar);
		console.log("length--", sortedListVar.length);
		let tempArr = [];
		


		if (sortedListVar.length > 0) {
			
			sortedListVar.map((item,i) => {
			
				if(!isNullOrUndefined(item.rank)){
					tempArr[i] = item.content.props.children.props.children.props.id+'-'+item.rank;
					//tempRank[i]= item.rank;
					
					console.log('item.content.key',item.content.key)
					
					
					
				}
				
			})



	const formData = new FormData();
	formData.append('file',tempArr);
  
	let listingWhere=[{
		url:'/managers/listingImageOrderUpdate'
	}];
	
	postApiData(listingWhere,formData,true).then(
	
	)

	 	}
	
	}

}
 updateImage =()=>{

	this.setState({
		image_list_value:[]
	},()=>{
		this.onBuildlingAndListingdataById(this.state.bID,this.state.list_id)
		this.getListingData()
	})
}	

	ClientHeadClick(){ document.getElementById('status').classList.remove("show");}
	StatusHeadClick(){document.getElementById('search').classList.remove("show");}
	StatusNeighborClick(){document.getElementById('neighbor').classList.add("show");}
	StatusBathClick(){document.getElementById('bath').classList.add("show");}
	StatusBrokerClick(){document.getElementById('broker').classList.add("show");}
	StatusLayoutClick(){document.getElementById('layout').classList.add("show");}
	


	resetFilter=()=>{
		this.setState({
			client_list:[],
			where:{},
			searchListingnId:'',
			searchNeighborhood:'',
			searchParm:"",
			searchNeighbor:'',
			priceSearch:{min: 100, max: 10500},
			myerrormsg:"",
			orderBy:"",
			orderByState:"",
			
		  },()=>{
			document.getElementById('searchParm').value='';
			//document.getElementById('searchNeighborParam').value='';
			document.getElementById('searchClick').classList.remove("active");
			document.getElementById('bathroomClick').classList.remove("active");
			document.getElementById('bedroomClick').classList.remove("active");
			document.getElementById('priceClick').classList.remove("active");
		//	document.getElementById('neighborClick').classList.remove("active");
			document.getElementById('statusClick').classList.remove("active");

			this.state.ckPending.value=1  

			this.state.ckApproved.value=1

			this.state.ckExpired.value=0
			this.state.ckAll.value=0

			this.state.ckcontractsigned.value=0  
			this.state.ckleasesigned.value=0
			this.state.cksold.value=0
			this.state.ckrented.value=0  
			this.state.ckwithdrawn.value=0
			this.state.ckTOM30.value=0
			this.state.cknotreleased.value=0
			this.state.ckcomingsoon.value=0

			this.state.ckAll.checked=false 
			this.state.ckPending.checked=true 
			

			this.state.ckApproved.checked=true

			this.state.ckExpired.checked=false

			this.state.ckcontractsigned.checked=false 
			this.state.ckleasesigned.checked=false
			this.state.cksold.checked=false
			this.state.ckrented.checked=false 
			this.state.ckwithdrawn.checked=false
			this.state.ckTOM30.checked=false
			this.state.cknotreleased.checked=false
			this.state.ckcomingsoon.checked=false


			document.getElementById("1_bedroom").checked=true
			document.getElementById("2_bedroom").checked=true
			document.getElementById("3_bedroom").checked=true
			document.getElementById("4_bedroom").checked=true

			document.getElementById("1_bathroom").checked=true
			document.getElementById("2_bathroom").checked=true
			document.getElementById("3_bathroom").checked=true
			document.getElementById("4_bathroom").checked=true

			document.getElementById("All").checked=false
			document.getElementById("Pending").checked=true
			document.getElementById("Approved").checked=true
			document.getElementById("Expired").checked=false

			document.getElementById("contractsigned").checked=false
			document.getElementById("leasesigned").checked=false
			document.getElementById("sold").checked=false
			document.getElementById("rented").checked=false
			document.getElementById("withdrawn").checked=false
			document.getElementById("TOM30").checked=false
			document.getElementById("notreleased").checked=false
			
			this.state.ckbedroom_0.value=1
			this.state.ckbedroom_1.value=1
			this.state.ckbedroom_2.value=1
			this.state.ckbedroom_3.value=1
			this.state.ckbedroom_4.value=1
			this.state.ckbathroom_1.value=1
			this.state.ckbathroom_2.value=1
			this.state.ckbathroom_3.value=1
			this.state.ckbathroom_4.value=1

			//this.getListingData();
			this.createWhere();

		  });
		 
	}
	resetsearchParm=()=>{
		this.setState({
			where:{},
			searchListingnId:'',
			searchNeighborhood:'',
			
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('searchParm').value='';
			//document.getElementById('searchNeighborParam').value='';
			document.getElementById('searchClick').classList.remove("active");
			document.getElementById('search').classList.remove("show");
			//this.getListingData();
			this.createWhere();

		  });
	}
	searchByPrice()
	{

		if(this.state.priceSearch.min==100 && this.state.priceSearch.max==10500){
			document.getElementById('priceClick').classList.remove("active");
		}else{
			document.getElementById('priceClick').classList.add("active");
		}
        this.createWhere();
	}
	search=(event) =>{
		//this.setState({[event.target.id]: event.target.value});
		//console.log("event",event);
			//console.log("event.target.id",event.target.id,"event.target.value",event.target.value);
	 var key=event.target.id;

	  switch(key){
	
		case 'All':
			if(this.state.ckAll.value==0 && this.state.ckAll.checked==false){
				this.state.ckAll.checked=true;
				this.state.ckPending.checked=true;
				this.state.ckApproved.checked=true;
				this.state.ckExpired.checked=true;
				this.state.ckcontractsigned.checked=true;
				this.state.ckleasesigned.checked=true;
				this.state.cksold.checked=true;
				this.state.ckrented.checked=true;
				this.state.ckwithdrawn.checked=true;
				this.state.ckTOM30.checked=true;
				this.state.cknotreleased.checked=true;
				this.state.ckAll.value=1;
				this.state.ckPending.value=1;
				this.state.ckApproved.value=1;
				this.state.ckExpired.value=1;
				this.state.ckcontractsigned.value=1;
				this.state.ckleasesigned.value=1;
				this.state.cksold.value=1;
				this.state.ckrented.value=1;
				this.state.ckwithdrawn.value=1;
				this.state.ckTOM30.value=1;
				this.state.cknotreleased.value=1;
				this.state.ckcomingsoon.value=1;
				this.state.statusFil=1;
				
				document.getElementById("Pending").checked=true
				document.getElementById("Approved").checked=true
				document.getElementById("Expired").checked=true

				document.getElementById("contractsigned").checked=true
				document.getElementById("leasesigned").checked=true
				document.getElementById("sold").checked=true
				document.getElementById("rented").checked=true
				document.getElementById("withdrawn").checked=true
				document.getElementById("TOM30").checked=true
				document.getElementById("notreleased").checked=true
				this.createWhere();
			}else if(this.state.ckAll.value==1 && this.state.ckAll.checked==true){
				this.state.ckAll.checked=false;
				this.state.ckPending.checked=true;
				this.state.ckApproved.checked=true;
				this.state.ckExpired.checked=false;
				this.state.ckcontractsigned.checked=false;
				this.state.ckleasesigned.checked=false;
				this.state.cksold.checked=false;
				this.state.ckrented.checked=false;
				this.state.ckwithdrawn.checked=false;
				this.state.ckTOM30.checked=false;
				this.state.cknotreleased.checked=false;
				this.state.ckAll.value=0;
				this.state.ckPending.value=1;
				this.state.ckApproved.value=1;
				this.state.ckExpired.value=0;
				this.state.ckcontractsigned.value=0;
				this.state.ckleasesigned.value=0;
				this.state.cksold.value=0;
				this.state.ckrented.value=0;
				this.state.ckwithdrawn.value=0;
				this.state.ckTOM30.value=0;
				this.state.cknotreleased.value=0;
				this.state.ckcomingsoon.value=0;
				
				this.state.ckAll.value=0;
				document.getElementById("Pending").checked=true
				document.getElementById("Approved").checked=true
				document.getElementById("Expired").checked=false

				document.getElementById("contractsigned").checked=false
				document.getElementById("leasesigned").checked=false
				document.getElementById("sold").checked=false
				document.getElementById("rented").checked=false
				document.getElementById("withdrawn").checked=false
				document.getElementById("TOM30").checked=false
				document.getElementById("notreleased").checked=false
				this.createWhere();
			}
			break;
		
		case 'Pending':
			if(this.state.ckPending.value==0 && this.state.ckPending.checked==false){
				this.state.ckPending.checked=true;
				this.state.ckPending.value=1;
				this.state.statusFil=1;
				this.createWhere();
			}else if(this.state.ckPending.value==1 && this.state.ckPending.checked==true){
				this.state.ckPending.checked=false;
				this.state.ckAll.checked=false;
				this.state.ckPending.value=0;
				document.getElementById("All").checked=false
				this.state.ckAll.value=0;
				this.createWhere();
			}
			break;
		case 'Approved':
		if(this.state.ckApproved.value==0 && this.state.ckApproved.checked==false){
			this.state.ckApproved.checked=true;
			this.state.statusFil=1;
			this.state.ckApproved.value=1;
			this.createWhere();
		}else if(this.state.ckApproved.value==1 && this.state.ckApproved.checked==true){
			this.state.ckApproved.checked=false;
			this.state.ckAll.checked=false;
			this.state.ckApproved.value=0;
			document.getElementById("All").checked=false
			this.state.ckAll.value=0;
			this.createWhere();
		}        
			break;
		case 'Expired': 
		if(this.state.ckExpired.value==0 && this.state.ckExpired.checked==false){
			this.state.ckExpired.checked=true;
			this.state.statusFil=1;
			this.state.ckExpired.value=1;
			this.createWhere();
		}else if(this.state.ckExpired.value==1 && this.state.ckExpired.checked==true){
			this.state.ckExpired.checked=false;
			this.state.ckAll.checked=false;
			document.getElementById("All").checked=false
			this.state.ckAll.value=0;
			this.state.ckExpired.value=0;
			this.createWhere();
		}        
			break;
	case 'contractsigned': 
		if(this.state.ckcontractsigned.value==0 && this.state.ckcontractsigned.checked==false){
			this.state.ckcontractsigned.checked=true;
			this.state.statusFil=1;
			this.state.ckcontractsigned.value=1;
			this.createWhere();
		}else if(this.state.ckcontractsigned.value==1 && this.state.ckcontractsigned.checked==true){
			this.state.ckcontractsigned.checked=false;
			this.state.ckAll.checked=false;
			document.getElementById("All").checked=false
			this.state.ckAll.value=0;
			this.state.ckcontractsigned.value=0;
			this.createWhere();
		}        
			break;	
	case 'leasesigned': 
	if(this.state.ckleasesigned.value==0 && this.state.ckleasesigned.checked==false){
		this.state.ckleasesigned.checked=true;
		this.state.statusFil=1;
		this.state.ckleasesigned.value=1;
		this.createWhere();
	}else if(this.state.ckleasesigned.value==1 && this.state.ckleasesigned.checked==true){
		this.state.ckleasesigned.checked=false;
		this.state.ckAll.checked=false;
		document.getElementById("All").checked=false
		this.state.ckAll.value=0;
		this.state.ckleasesigned.value=0;
		this.createWhere();
	}        
		break;
	case 'sold': 
	if(this.state.cksold.value==0 && this.state.cksold.checked==false){
		this.state.cksold.checked=true;
		this.state.statusFil=1;
		this.state.cksold.value=1;
		this.createWhere();
	}else if(this.state.cksold.value==1 && this.state.cksold.checked==true){
		this.state.cksold.checked=false;
		this.state.ckAll.checked=false;
		document.getElementById("All").checked=false
		this.state.ckAll.value=0;
		this.state.cksold.value=0;
		this.createWhere();
	}        
		break;
	case 'rented': 
	if(this.state.ckrented.value==0 && this.state.ckrented.checked==false){
		this.state.ckrented.checked=true;
		this.state.statusFil=1;
		this.state.ckrented.value=1;
		this.createWhere();
	}else if(this.state.ckrented.value==1 && this.state.ckrented.checked==true){
		this.state.ckrented.checked=false;
		this.state.ckAll.checked=false;
		document.getElementById("All").checked=false
		this.state.ckAll.value=0;
		this.state.ckrented.value=0;
		this.createWhere();
	}        
	break;
	case 'withdrawn': 
		if(this.state.ckwithdrawn.value==0 && this.state.ckwithdrawn.checked==false){
			this.state.ckwithdrawn.checked=true;
			this.state.statusFil=1;
			this.state.ckwithdrawn.value=1;
			this.createWhere();
		}else if(this.state.ckwithdrawn.value==1 && this.state.ckwithdrawn.checked==true){
			this.state.ckwithdrawn.checked=false;
			this.state.ckAll.checked=false;
			document.getElementById("All").checked=false
			this.state.ckAll.value=0;
			this.state.ckwithdrawn.value=0;
			this.createWhere();
		}        
	break;			
	case 'TOM30': 
		if(this.state.ckTOM30.value==0 && this.state.ckTOM30.checked==false){
			this.state.ckTOM30.checked=true;
			this.state.statusFil=1;
			this.state.ckTOM30.value=1;
			this.createWhere();
		}else if(this.state.ckTOM30.value==1 && this.state.ckTOM30.checked==true){
			this.state.ckTOM30.checked=false;
			this.state.ckAll.checked=false;
			document.getElementById("All").checked=false
			this.state.ckAll.value=0;
			this.state.ckTOM30.value=0;
			this.createWhere();
		}        
	break;
	case 'notreleased': 
	if(this.state.cknotreleased.value==0 && this.state.cknotreleased.checked==false){
		this.state.cknotreleased.checked=true;
		this.state.statusFil=1;
		this.state.cknotreleased.value=1;
		this.createWhere();
	}else if(this.state.cknotreleased.value==1 && this.state.cknotreleased.checked==true){
		this.state.cknotreleased.checked=false;
		this.state.ckAll.checked=false;
		document.getElementById("All").checked=false
			this.state.ckAll.value=0;
		this.state.cknotreleased.value=0;
		this.createWhere();
	}        
		break;
	case 'Expired': 
		if(this.state.ckcomingsoon.value==0 && this.state.ckcomingsoon.checked==false){
			this.state.ckcomingsoon.checked=true;
			this.state.statusFil=1;
			this.state.ckcomingsoon.value=1;
			this.createWhere();
		}else if(this.state.ckcomingsoon.value==1 && this.state.ckcomingsoon.checked==true){
			this.state.ckcomingsoon.checked=false;
			this.state.ckAll.checked=false;
			document.getElementById("All").checked=false
			this.state.ckAll.value=0;
			this.state.ckcomingsoon.value=0;
			this.createWhere();
		}        
			break;			
	case '0_bedroom':
	if(this.state.ckbedroom_0.value==0 && this.state.ckbedroom_0.checked==false){
		this.state.ckbedroom_0.checked=true;
		this.state.ckbedroom_0.value=1;
	
		this.createWhere();
	}else if(this.state.ckbedroom_0.value==1 && this.state.ckbedroom_0.checked==true){
		this.state.bedFil=1;
		this.state.ckbedroom_0.checked=false;
		this.state.ckbedroom_0.value=0;
		this.createWhere();
	}
	break;		
		case '1_bedroom':
		if(this.state.ckbedroom_1.value==0 && this.state.ckbedroom_1.checked==false){
			this.state.ckbedroom_1.checked=true;
			this.state.ckbedroom_1.value=1;
		
			this.createWhere();
		}else if(this.state.ckbedroom_1.value==1 && this.state.ckbedroom_1.checked==true){
			this.state.bedFil=1;
			this.state.ckbedroom_1.checked=false;
			this.state.ckbedroom_1.value=0;
			this.createWhere();
		}
		break;
		case '2_bedroom':
		if(this.state.ckbedroom_2.value==0 && this.state.ckbedroom_2.checked==false){
			this.state.ckbedroom_2.checked=true;
			this.state.ckbedroom_2.value=1;
			this.createWhere();
		}else if(this.state.ckbedroom_2.value==1 && this.state.ckbedroom_2.checked==true){
			this.state.ckbedroom_2.checked=false;
			this.state.bedFil=1;
			this.state.ckbedroom_2.value=0;
			this.createWhere();
		}
		break;
		case '3_bedroom':
		if(this.state.ckbedroom_3.value==0 && this.state.ckbedroom_3.checked==false){
			this.state.ckbedroom_3.checked=true;
			
			this.state.ckbedroom_3.value=1;
			this.createWhere();
		}else if(this.state.ckbedroom_3.value==1 && this.state.ckbedroom_3.checked==true){
			this.state.ckbedroom_3.checked=false;
			this.state.bedFil=1;
			this.state.ckbedroom_3.value=0;
			this.createWhere();
		}        
			break;
		case '4_bedroom': 
		if(this.state.ckbedroom_4.value==0 && this.state.ckbedroom_4.checked==false){
			this.state.ckbedroom_4.checked=true;
			this.state.bedFil=1;
			this.state.ckbedroom_4.value=1;
			this.createWhere();
		}else if(this.state.ckbedroom_4.value==1 && this.state.ckbedroom_4.checked==true){
			this.state.ckbedroom_4.checked=false;
			this.state.ckbedroom_4.value=0;
			this.createWhere();
		}        
			break;
			case '1_bathroom':
			if(this.state.ckbathroom_1.value==0 && this.state.ckbathroom_1.checked==false){
				this.state.ckbathroom_1.checked=true;
				this.state.ckbathroom_1.value=1;
				this.state.bathFil=1;
				this.createWhere();
			}else if(this.state.ckbathroom_1.value==1 && this.state.ckbathroom_1.checked==true){
				this.state.ckbathroom_1.checked=false;
				this.state.ckbathroom_1.value=0;
				this.createWhere();
			}
			break;
			case '2_bathroom':
			if(this.state.ckbathroom_2.value==0 && this.state.ckbathroom_2.checked==false){
				this.state.ckbathroom_2.checked=true;
				this.state.ckbathroom_2.value=1;
				this.state.bathFil=1;
				this.createWhere();
			}else if(this.state.ckbathroom_2.value==1 && this.state.ckbathroom_2.checked==true){
				this.state.ckbathroom_2.checked=false;
				this.state.ckbathroom_2.value=0;
				this.createWhere();
			}
			break;
			case '3_bathroom':
			if(this.state.ckbathroom_3.value==0 && this.state.ckbathroom_3.checked==false){
				this.state.ckbathroom_3.checked=true;
				this.state.bathFil=1;
				this.state.ckbathroom_3.value=1;
				this.createWhere();
			}else if(this.state.ckbathroom_3.value==1 && this.state.ckbathroom_3.checked==true){
				this.state.ckbathroom_3.checked=false;
				this.state.ckbathroom_3.value=0;
				this.createWhere();
			}        
				break;
			case '4_bathroom': 
			if(this.state.ckbathroom_4.value==0 && this.state.ckbathroom_4.checked==false){
				this.state.ckbathroom_4.checked=true;
				this.state.bathFil=1;
				this.state.ckbathroom_4.value=1;
				this.createWhere();
			}else if(this.state.ckbathroom_4.value==1 && this.state.ckbathroom_4.checked==true){
				this.state.ckbathroom_4.checked=false;
				this.state.ckbathroom_4.value=0;
				this.createWhere();
			}        
				break;			
		case 'searchParm':
			this.setState({searchParm:event.target.value});
			//if(this.state.searchParm.length>1){	
				var value=this.state.searchParm.trim();
				if(event.key=='Enter'){
					this.getData(value);

				}else if(value==''){
					this.state.addressData.forEach(function(address,index) {
						address.flag=0;
					});
					this.setState({searchMsg:false});
				}else {
					var len = this.state.addressData.length;
					var count=0;
					var vflag=0;
					this.state.addressData.forEach(function(address,index) {
						
						if(address.listing_info_address.trim().toLowerCase().includes(value.trim().toLowerCase())){
							address.flag=1;
							vflag=1;
							count=count+1;
						}else{
							address.flag=0;
							if(vflag!=1)
							vflag=0
							count=count+1;
						}
	
					});
				
					if(len==count){
						if(vflag==0){
							this.setState({searchMsg:true});
							
						}else{
							this.setState({searchMsg:false});
							
						}

						
					}


				}
         
				
			//}
		break;
		case 'searchNeighborParam':
			this.setState({searchNeighbor:event.target.value});
			//if(this.state.searchParm.length>1){	
				var value=this.state.searchNeighbor.trim();
				if(event.key=='Enter'){
					this.getData(value);

				}else if(value==''){
					
					this.state.neighoberData.forEach(function(address,index) {
						address.flag=0;
					});
					this.setState({searchMsg:false});
				}else {
					var len = this.state.neighoberData.length;
				
					var count=0;
					var vflag=0;
					this.state.neighoberData.forEach(function(address,index) {
					
					
						if(address.neighborhood.trim().toLowerCase().includes(value.trim().toLowerCase())){
							address.flag=1;
							vflag=1;
							count=count+1;
						}
					else{
							address.flag=0;
							if(vflag!=1)
							vflag=0
							count=count+1;
						}

					});
					
					if(len==count){
						if(vflag==0){
							this.setState({searchMsg:true});
							
						}else{
							this.setState({searchMsg:false});
							
						}
					}
				}
			break;	
		case 'searchkey':
		var value=event.target.value;
		this.setState({ searchListingnId: event.target.value}, () => {
				this.state.addressData.forEach(function(address,index) {
					if(address.listing_info_address==value){
						document.getElementById('searchClick').classList.add("active");
						document.getElementById('searchParm').value=address.listing_info_address; 
						
						//document.getElementById('search').classList.remove("show");
					}else{
						address.flag=0;
					}
				});
				document.getElementById('searchClick').classList.add("active");
				this.createWhere();
			  });
		break;
		case 'searchNeighbor':
		var value=event.target.value;
			this.setState({ searchNeighborhood: event.target.value}, () => {
				this.state.neighoberData.forEach(function(address,index) {
					if(address.neighborhood==value){
						document.getElementById('neighborClick').classList.add("active"); 
						document.getElementById('searchNeighborParam').value=address.neighborhood;	
						//document.getElementById('search').classList.remove("show");
					}else{
						address.flag=0;
					}
				});
				document.getElementById('neighborClick').classList.add("active");
				this.createWhere();
			  });
		break;
		case 'default':
		this.createWhere();
		break;
		 
		}
			if(this.state.ckPending.value==0 || this.state.ckApproved.value==0 || this.state.ckExpired.value==0 || this.state.ckcontractsigned.value==0 || this.state.ckleasesigned.value==0 || this.state.cksold.value==0 || this.state.ckrented.value==0 || this.state.ckwithdrawn.value==0 || this.state.ckTOM30.value==0 || this.state.cknotreleased.value==0 || this.state.ckcomingsoon.value==0){
				document.getElementById('statusClick').classList.add("active");
			}else{
				document.getElementById('statusClick').classList.remove("active");
			}
			if(this.state.ckbedroom_0.value==0 || this.state.ckbedroom_1.value==0 || this.state.ckbedroom_2.value==0 || this.state.ckbedroom_3.value==0 || this.state.ckbedroom_4.value==0){
				document.getElementById('bedroomClick').classList.add("active");
			}else{
				document.getElementById('bedroomClick').classList.remove("active");
			}
			if(this.state.ckbathroom_1.value==0 || this.state.ckbathroom_2.value==0 || this.state.ckbathroom_3.value==0 || this.state.ckbathroom_4.value==0){
				document.getElementById('bathroomClick').classList.add("active");
			}else{
				document.getElementById('bathroomClick').classList.remove("active");
			}
		
	  }	
	  async createWhere(){
		this.state.where={};
		var inValue="";
		
	   if(this.state.ckAll.value==1){
			this.state.where.all=1;
		}
		if(this.state.ckPending.value==1){
			this.state.where.pending=3;
		}
		if(this.state.ckApproved.value==1){
			this.state.where.approved=2;		
		}
		if(this.state.ckExpired.value==1){
			this.state.where.expired=1;
		}

		if(this.state.ckcontractsigned.value==1){
			this.state.where.contractsigned=4;
		}
		if(this.state.ckleasesigned.value==1){
			this.state.where.leasesigned=5;		
		}
		if(this.state.cksold.value==1){
			this.state.where.sold=6;
		}

		if(this.state.ckrented.value==1){
			this.state.where.rented=7;
		}
		if(this.state.ckwithdrawn.value==1){
			this.state.where.withdrawn=8;		
		}
		if(this.state.ckTOM30.value==1){
			this.state.where.TOM30=8;
		}
	    if(this.state.cknotreleased.value==1){
			this.state.where.notreleased=10;
		}
		if(this.state.ckcomingsoon.value==1){
			this.state.where.comingsoon=11;
		}

		if(this.state.ckbedroom_0.value==1){
			this.state.where.bedroom_0=1;
		}
		if(this.state.ckbedroom_1.value==1){
			this.state.where.bedroom_1=1;
		}
		if(this.state.ckbedroom_2.value==1){
			this.state.where.bedroom_2=1;
		}
		if(this.state.ckbedroom_3.value==1){
			this.state.where.bedroom_3=1;		
		}
		if(this.state.ckbedroom_4.value==1){
			this.state.where.bedroom_4=1;
		}
		if(this.state.ckbathroom_1.value==1){
			this.state.where.bathroom_1=1;
		}
		if(this.state.ckbathroom_2.value==1){
			this.state.where.bathroom_2=1;
		}
		if(this.state.ckbathroom_3.value==1){
			this.state.where.bathroom_3=1;		
		}
		if(this.state.ckbathroom_4.value==1){
			this.state.where.bathroom_4=1;
		}
		if(this.state.searchListingnId!=''){
			this.state.where.searchListingnId=this.state.searchListingnId;
		}
		if(this.state.searchNeighborhood!='')
		this.state.where.searchNeighborhood=this.state.searchNeighborhood;

		if(this.state.priceSearch!='')
		this.state.where.priceSearch=this.state.priceSearch;
				//this.state.where.agentId = getAgentId();
		//if(this.state.orderBy!=''){
			this.state.where.orderBy=this.state.orderBy
		//}
		//if(this.state.orderByState!=''){
			this.state.where.orderByState=this.state.orderByState
		//}		

				this.getListingData();
	} 
	componentWillMount(handle){
		
		document.addEventListener('mousedown', this.handleClickOutside);
		this.getListingData();
		this.getNeighborData();
		this.getAddressData();
		this.ownerpays();
		this.getAgentsClient();
	
		}
		escFunction(event){
		
			if(event.keyCode === 27) {
				if(event.target.id=="imgEdit")
				{
					document.getElementById('imageEditClose').click()
				}
				document.getElementById('closeVr').click()
				document.getElementById('priceEditClose').click()
				document.getElementById('bedbathsqEditClose').click()
				document.getElementById('incentiveEditClose').click()
				document.getElementById('buildingamenityClose').click()
				document.getElementById('descriptionEditClose').click()
				document.getElementById('marketingClose').click()
				document.getElementById('dateEditClose').click()
				document.getElementById('furnishedEditClose').click()
			  
			}
		  }
		  componentDidMount(){
			document.addEventListener("keydown", this.escFunction);
			window.scrollTo(0, 0);
			this.refs.iScroll.addEventListener("scroll", () => {
			  // alert('This is test')
				if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >=this.refs.iScroll.scrollHeight){
					//if(this.state.hasMoreItems){
					this.getListingData(false);
					//}
				}
			  });
		  }
		componentWillUnmount(handle){
			document.addEventListener("keydown", this.escFunction);
			
			document.addEventListener('mousedown', this.handleClickOutside);
           
		}


		  setWrapperRef1(node) {
			this.wrapperRef1 = node;
		  }
		  setWrapperRef2(node) {
			this.wrapperRef2 = node;
		  }
		  setWrapperRef3(node) {
			this.wrapperRef3 = node;
		  }
		  setWrapperRef4(node) {
			this.wrapperRef4 = node;
		  }
		  setWrapperRef5(node) {
			this.wrapperRef5 = node;
		  }
		  setWrapperRef7(node) {
			this.wrapperRef7 = node;
			}
			setWrapperRef10(node) {
				this.wrapperRef6 = node;
				}
	
				setWrapperRef11(node) {
					this.wrapperRef6 = node;
					}
		
			 setWrapperRef12(node) {
						this.wrapperRef12 = node;
			      } 
			

		  handleClickOutside(event) {
				if(event.target.id=='amenitiesEdit')
				{
					this.setState({checkedBoxes:[]});
			
				}
				if(event.target.id=='marketingEdit')
				{
					this.setState({	marketcheckedBoxes:[]});
				
				}
				if (this.wrapperRef12 && !this.wrapperRef12.contains(event.target)) {
					var x = document.getElementById("clientList");
					if (x.style.display === "block") {
						x.style.display = "none";
					  } 
					//   this.setState({
					// 	mail_subject:''
					//   })
					}
				
			if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
				document.getElementById('search').classList.remove("show");
			}
	
			if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
				document.getElementById('status').classList.remove("show");
			  }
			  if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {
				document.getElementById('neighbor').classList.remove("show");
			  }
			  if (this.wrapperRef4 && !this.wrapperRef4.contains(event.target)) {
				document.getElementById('price').classList.remove("show");
			  }	 	
			  if (this.wrapperRef5 && !this.wrapperRef5.contains(event.target)) {
				document.getElementById('layout').classList.remove("show");
			  }	 
			  if (this.wrapperRef7 && !this.wrapperRef7.contains(event.target)) {
				document.getElementById('bath').classList.remove("show");
				}	  
				if (this.wrapperRef10) {
				     
					this.setState({checkedBoxes:''});

					}
					if (this.wrapperRef11) {

						this.setState({marketcheckedBoxes:''});
						}
		
	    //console.log("PriceSearch==>",this.state.priceSearch)
	
		  }
/* Function to get the Distict Neighborhood list to show in the table header filter*/		  
		  getNeighborData()
		  {
			let listingWhere='';
			if(getAgentId()>0)
			{
				 listingWhere=[{
					url :"/agent/rentNeighbor/"+getAgentId()
				}];
			}
			else
			{	 
			  listingWhere=[{
				url : '/managers/rentNeighbor'
			}];
		   }

			getApiData(listingWhere,true).then(res=>{
				this.setState({
					neighoberData:res.data,
					},()=>{
				})
			}).catch(err=>{
	
		
		  }) 
		}
/* Function to get the Distict Address list to show in the table header filter*/		  
getAddressData()
{
  let listingWhere='';
  if(getAgentId()>0)
  {
	   listingWhere=[{
		  url :"/agent/rentAddress/"+getAgentId()
	  }];
  }
  else
  {	 
	listingWhere=[{
	  url : '/managers/rentAddress'
  }];
 }

  getApiData(listingWhere,true).then(res=>{
	  this.setState({
		  addressData:res.data,
		  },()=>{
	  })
  }).catch(err=>{


}) 
}		
		getRentalListing()
		{
			this.setState(
				{ type: "",linkingLoader:true },
				() => this.getListingData()
				);
				this.resetFilter();
		}	
		getSalesListing()
		{
			this.setState(
				{ type: "Sales" },
				() => this.getListingData()
			  );
			this.resetFilter();
			//this.getListingData()

		}		
 		getListingData(loadercheck=true){
			
			if(loadercheck){
			   this.setState({linkingLoader: true});	
			   this.setState({nextpage: 20});	
			   this.state.nextpage=20;
		   }
		  
			this.setState({
				TabPanel  : this.state.type,
				//linkingLoader:true
			})

		//this.setState({linkingLoader:true});
		let listingWhere='';
	   if(getAgentId()>0 || this.props.userType=='Agent')
	   {
		   if(this.state.type){
			   listingWhere=[{
				   url :"/agent/rentallistings/"+getAgentId(),
				   urlParms:"?type="+this.state.type
			   }];
		   }
		   else{
			   listingWhere=[{
				   url :"/agent/rentallistings/"+getAgentId()
			   }];
		   }
	   }
	   else
	   {
		   if(this.state.type){ 
	   listingWhere=[{
		   url : '/managers/rentalListing',
		   urlParms:"?type="+this.state.type
	   }];
	  }
			  else{
				
		   listingWhere=[{
		   url : '/managers/rentalListing'
	   }];
	  }
   }
   this.state.where.nextpage=this.state.nextpage;
	   var len =Object.keys(this.state.where);
			if(len.length>0){
				listingWhere[0].where=this.state.where;
			}
			
		getApiData(listingWhere,true).then(res=>{
			
			this.setState({
				listingData:res.data,
				building_Id:res.data.building_id,
				linkingLoader:false,
				totalRecords:res.data[0].total[0].total
				//imagePath3:''
			},()=>{
				this.setState({imagePath3:''})
			})
			console.log('Total=',this.state.totalRecords,'NextPage=',this.state.nextpage);
			if(this.state.nextpage<this.state.totalRecords ) {
                 
				if(this.state.firstPage==1)
				{
				var n= parseInt(this.state.nextpage)+10;
				this.setState({nextpage:n});
				}
			   
				
			} else {
				this.setState({
					hasMoreItems: false
				});
			}

		}).catch(err=>{
			this.setState({linkingLoader:false});
		})
	}

	statusChange=(id,status,streeteasy_status,RLS_status)=>{
		var skystatus;
		var street_status;
		var RLS_stat;
		if(status==0 || status==19){
			skystatus=1
		}
		if(status==1){
			skystatus=1
		}
		if(streeteasy_status==0){
			street_status=1
		}
		else{
			street_status=1
		}
		if(RLS_status==0){
			RLS_stat=1
		}
		else{
			RLS_stat=1
		}
		if(status=='TOM')
		  skystatus='19';

		
		let listingWhere=[{
			url : '/managers/statusChange'
		}];	
		if(status=='TOM'){   // Only TOM 30 Days Status Update
		postApiData(listingWhere,{'id':id,'status':skystatus},true).then(res=>{
			document.getElementById('ListingModal').click();
				this.getListingData();
		}).catch(err=>{

		})
	  }
	  else
	  {   // Update the Active and Pending Status
		postApiData(listingWhere,{'id':id,'status':skystatus,'streeteasy_status':street_status,'RLS_status':RLS_stat},true).then(res=>{
			document.getElementById('ListingModal').click();
				this.getListingData();
		}).catch(err=>{

		}) 
	  }
	}
popupHandler=(event)=>{
				
		const id =event.target.id;
		const value=event.target.value;
				var error = id + "_error";

				if(id=="close_price")
					{
						if(isNaN(value))
						{
						   this.setState({
							error: "Enter numbers only",
							   'close_price': value,
							   popupvalidation :false
						   }); 
						}
						else if(value==''){

							this.setState({
								error: "Please fill the close price",
								'close_price': value,
								popupvalidation :false
							}); 
						}
						else
						{
							this.setState({
								error: "",
								'close_price': value,
								popupvalidation :true
							}); 
							console.log(this.state.close_price)
						}
						
					}

				
		}
		preventKeystroke=(event)=>{
			
			if (event.keyCode) {
				event.preventDefault();
			}
		}			
  handleUpperSearchDate=(date) => {
		this.setState({ upperSearchDate: date }, function () {
			
	   });
		
	}	
	getListingId=(id)=>{

		
		document.getElementById("status_detailed_row").style.display="none";
		this.setState({selected_id:id})
		
	}

closePopup=()=>{
	
	this.state.close_price='';
	this.state.selected_status='';
	this.state.upperSearchDate='';

	this.setState({close_price:''})
	this.setState({selected_status:''})
	this.setState({upperSearchDate:''})
	document.getElementById("listing_new_status").options[0].selected=true;
	this.setState({popupvalidation:false})
	}	
statusDetailChange=()=>{
 
		let listingWhere=[{
			url : '/managers/statusUpdate'
		}];	
		postApiData(listingWhere,{'id':this.state.selected_id,'status':this.state.selected_status,'close_date':this.state.upperSearchDate,'close_price':this.state.close_price},true).then(res=>{
			document.getElementById('statusPopup').click();
				this.getListingData();
		}).catch(err=>{

		})
	}	
    statusChanged=()=>event=>{
		var status_id=''
		var e = document.getElementById("listing_new_status");
		if(e){
		    status_id= e.options[e.selectedIndex].value;
		}
		this.setState({selected_status:status_id});

		if(status_id==13 || status_id==14)
		{
			this.setState({popupvalidation:false})
			document.getElementById("status_detailed_row").style.display="flex";
		}
		else
		{
			this.setState({popupvalidation:true})
			document.getElementById("status_detailed_row").style.display="none";
		}
	}
	popupvalidation = () => {
		const {
			close_price,
			selected_status,
			upperSearchDate
		} = this.state;
		
	   if(selected_status==13 || selected_status==14)
	   {
		return (
			
			close_price.toString().trim() !="" && !isNaN(close_price) && upperSearchDate().trim()!=""
		);
	   }
	   else
	   {
		   return true;
	   }
	};		
	handleChangeDate=(date)=>{
		this.setState({
		startDate: date,
		});
		}


	onBuildlingAndListingdataById=(buildingid,listingid,unit_number,listingtype)=>{
		this.state.listing_id=listingid;
		this.state.bID=buildingid;
		
		
		this.setState({
			linkingLoader:true,
			listing_id:listingid,
			buildingid:buildingid,
			unitnumber:unit_number,
			listingtype:listingtype,
			hideandshow:0
		})
		let listingWhere=[{
			url : '/managers/dataById'
		}];
		postApiData(listingWhere,{'building_id':buildingid,'listing_id':listingid,'unit_number':unit_number},true).then(res=>{
			var listingItem = res.data.records[0];
			let insent=listingItem.incentives.split(',');
			
				this.setState({
					linkingLoader:false,
					incentivesshow:listingItem.incentives,
					listing_info_address:listingItem.listing_info_address,
					unit_number:listingItem.unit_number,
					bedrooms:listingItem.bedrooms,
					bathrooms:listingItem.bathrooms,
					price:listingItem.price,
					Neighbourhood:listingItem.neighborhood,
					description_show:unescape(listingItem.description.replace(/(?:\r\n|\r|\n)/g, '<br>')),
					description:unescape(listingItem.description),
					furnished:listingItem.furnished,
					square_footage:listingItem.square_footage,
					incentives:(insent[0]!=undefined)?insent[0]:'',
					owner_amount:listingItem.owner_pays_amount,
					owner_pays:(insent[1]!=undefined)?insent[1]:'',
					commission_percentage:listingItem.commission_percentage,
                    my_split:listingItem.my_split,
					tenant_incentive:listingItem.tenant_incentive,
					noFee:listingItem.noFee,
					agent_name:listingItem.name,
					agent_image:listingItem.image,
					agent_email:(listingItem.email!=="NA")?aes.decrypt(listingItem.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):listingItem.email,
					agent_mob:(listingItem.mob_number!=="NA")?aes.decrypt(listingItem.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):listingItem.mob_number,
					date_available: Moment(listingItem.date_available).format('M/D/YY'),
					date_available1: listingItem.date_available,
					amenity_list : res.data.listRecords,
					building_amenity_list:res.data.building_amenities,
					amenityData:res.data.amenity,
					listing_status:res.data.records[0].status,
					image_list:res.data.image,
					image_vrurl:listingItem.kuula_vr_link,
					kuula_vr_link:listingItem.kuula_vr_link,
					video_link:listingItem.video_link,
					marketing:res.data.markRecords,
					marketingTableData:res.data.marketingtableData,
					list_id:listingItem.id,
					listing_information:listingItem.listing_information				
				},()=>{
					document.getElementById('listingDesc').innerHTML=this.state.description_show
					document.getElementById('description').value=this.state.description
				})
				// Sortable.mount(new Swap());
				this.setState({
					imagePath3:'',
					image_list_value:[]
					})
					

				if(res.data.saleRecords[0]=='' || res.data.saleRecords[0]==undefined){
					
				this.setState({
					maintenance:"",
					monthly_taxes:"",
					commission:"",
					tax_deduction:"",
					flip_tax:"",
					max_financing:"",
					number_of_shares:"",
					percentofcommon:"",
				})}
				else{
                      
					var salesData = res.data.saleRecords[0];
					this.setState({
						maintenance:salesData.maintenance,
						monthly_taxes:salesData.monthly_taxes,
						commission:salesData.commission,
						tax_deduction:salesData.tax_deduction,
						flip_tax:salesData.flip_tax,
						max_financing:salesData.max_financing,
						number_of_shares:salesData.number_of_shares,
						percentofcommon:salesData.percent_of_common,
					})
				}

				res.data.image.map((item)=>{
					if(item.image_type!="floor_plan"){
						this.setState(state => (this.state.image_list_value.push(item),state));
					}

				})
             
				document.getElementById('owner_pays_broker').value = listingItem.owner_pays_broker;
				if( this.state.commission_percentage <=100){
					var _commission=this.state.my_split*this.state.commission_percentage;
					var _percentage=_commission / 100;
  					var _cocommission=this.state.commission_percentage *(100-this.state.my_split);
					var _copercentage=_cocommission/100;
  				  this.setState({
					final_precentage:_percentage,
					copercentage_broker:_copercentage
				})
			  }

			  if( this.state.my_split <=100){
				var _co_broker_split=100 - this.state.my_split
				var _commission=this.state.my_split * this.state.commission_percentage;
				var _percentage=_commission / 100;
 				var _cocommission=this.state.commission_percentage*_co_broker_split;
				var _copercentage=_cocommission/100;
			    this.setState({
				
				 co_broker_split:100 - this.state.my_split,
				 final_precentage:_percentage,
				 copercentage_broker:_copercentage
			     })
			}

				
				
				
		}).catch(err=>{
		
		})
	}


	handleCheckBox =param=>e=>{
	
	let check=param[0];
	console.log("check",check,"this.state.listing_id",this.state.listing_id);
	let __count=1;
	let __len=this.state.amenity_list.length;
	let __flag=0;
		for (var i = 0; i < __len; i++) {
			//console.log("this.state.amenity_list",this.state.amenity_list,"i",i,"this.state.amenity_list[i]['name']",this.state.amenity_list[i]['name']);
			
			if(this.state.amenity_list[i]!=undefined && this.state.amenity_list[i]['name']!=undefined && this.state.amenity_list[i]['name'] === check.amenity_name){
				this.state.amenity_list.splice(i, 1);
				__flag=1;
			}
		
		//   else
		//   {
		//     if(this.state.amenity_list[i]['name'] === check.amenity_name){
		//     this.state.amenity_list.push({
		//       'id': '',
		//       'listing_id': '',
		//       'name': check.amenity_name,
		//       'type': check.type,}
		//       )
		//            }
			
		__count=__count+1;
		console.log("__count",__count,"len",__len);
		}
					if(__count> __len && __flag==0){
						
						
						this.state.amenity_list.push({
							'id': check.id,
							'listing_id': this.state.listing_id,
							'name': check.amenity_name,
							'type': 0}
							)
					}
				 console.log("this.state.amenity_list",this.state.amenity_list)
	
	
		}

			checkBoxHandler =param=>e=>{
				let check=param[0];
				console.log("--check--",check);
					if(check.name=='Renthop'){
						this.setState({
							rentHopCheck :1
						})
					}
					
       

		let __count=1;
	let __len=this.state.marketing.length;
	let __flag=0;
		for (var i = 0; i < __len; i++) {
			//console.log("this.state.amenity_list",this.state.amenity_list,"i",i,"this.state.amenity_list[i]['name']",this.state.amenity_list[i]['name']);
			
			if(this.state.marketing[i]!=undefined && this.state.marketing[i]['name']!=undefined && this.state.marketing[i]['name'] === check.name){
				this.state.marketing.splice(i, 1);
				__flag=1;
			}
		
		
		__count=__count+1;
		console.log("__count",__count,"len",__len);
		}
					if(__count> __len && __flag==0){
						
						
						this.state.marketing.push(check)
					}
				 console.log("this.state.marketing",this.state.marketing)


			
			}


			handleCheck=()=> {
				
				this.setState({noFee: !this.state.noFee});
			}

			handleChange=(event)=>{
				const id =event.target.id;
				const value=event.target.value;
				const dataset = event.currentTarget.dataset.id;
				
				if(id === 'price' || id === 'bedrooms'|| id === 'bathrooms' || id === 'square_footage' || id==='date_available')
					var error = id + "_error";
					if ((value == "" || value ==">" || value== "<") && id!= 'square_footage') {
						this.setState({
							[error]: "Field cannot be empty",
							[id]: [value],
							validation :false
						});
					 }else if ( value <0) {
						this.setState({
							[error]: "Enter only positive number",
							[id]: [value],
							validation :false
						});
					 }
					 else if(isNaN(value))
					 {
						this.setState({
							[error]: "Enter numbers only",
							[id]: [value],
							validation :false
						});
					 }
					 else{
						this.setState({
							[error]: "",
							[id]: [value],
							validation:true
						});
					 }
					 
					 if(id=='description'){
						var error = id + "_error";
					     
						 if(value.toString().trim()=='')
						 {
							this.setState({
								[error]: "Field cannot be empty",
								description:value.toString().trim(),
								description_show: value.toString().trim(),
								[id]: [value.toString().trim()],
								validation :false
							});
							
						 }
						 else
						 {
						 document.getElementById('listingDesc').innerHTML=value.replace(/(?:\r\n|\r|\n)/g, '<br>');
						this.setState({description_show:value});
						this.setState({
							[error]: "",
							[id]: [value],
							validation:true
						});	
					 }
					}
					if(id=='instatilefirsttext'){
						var error = id + "_error";
					     
						 if(value.toString().trim()=='')
						 {
							this.setState({
								[error]: "Field cannot be empty",
								[id]: [value.toString().trim()],
							});
						 }
						 else
						 {
						this.setState({
							[error]: "",
							[id]: [value],
						});	
					 }
					}
					if(id=='instatilelasttext'){
						var error = id + "_error";
					     
						 if(value.toString().trim()=='')
						 {
							this.setState({
								[error]: "Field cannot be empty",
								[id]: [value.toString().trim()],
							});
						 }
						 else
						 {
						this.setState({
							[error]: "",
							[id]: [value],
						});	
					 }
					}			
					
					 if (
						id == "CYOF" ||
						id == "Co-broke" ||
						id == "tenant_incentive" ||
						id == "noFee"
					  ) {
						if (id === "tenant_incentive") {
						  this.setState({
							tenant_incentive: value
						  });
						} else{
						  if( id == "CYOF"){
							if(this.state.incentives=="CYOF"){
							  this.setState({
								incentives: '',
								commission_percentage:'',
								my_split:'',
								co_broker_split:'',
								final_precentage:''
								
							  },()=>{
								
							  // this.setState({incentives: dataset})
							  });
							}
							else{
							  this.setState({
								incentives: dataset,
								commission_percentage:'',
								my_split:0,
								co_broker_split:100,
								final_precentage:''
							  });
							}
							
						  }else{
							if(id=="Co-broke"){
							  if(this.state.incentives=="Co-broke"){
								this.setState({
								  incentives: '',
								  
								},()=>{
								//  this.setState({incentives: dataset})
								});
							  }
							  else{
								this.setState({
								  incentives: dataset
								});
							  }
				  
							}
							else{
							  this.setState({
								incentives: dataset
							  });
							} 
						 
						}
					}
						
						if (id == "noFee") {
						  if (this.state.noFee == 0) {
							this.setState({
							  noFee: 1
							});
						  } else {
							this.setState({
							  noFee: 0
							});
						  }
						}
						
					  }
					 
				}


onVrHandleChange=(event)=>{
    console.log('=>',event.target.id,event.target.value);
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;

    

    if(id==='kuula_vr_link'){
      var error = id + "_error";
      if(value==''){
        this.setState({
          [error]: "",
          [id]: [value],
          validation:true
        });
      }
      else{

		if((
			value.indexOf('https://www.tours.moveskyward.com') >=0 
			|| value.indexOf('https://tours.moveskyward.com') >=0 
			|| value.indexOf('https://www.view.ricohtours.com') >=0 
			|| value.indexOf('https://view.ricohtours.com') >=0 
			|| value.indexOf('https://www.kuula.co') >=0  
			|| value.indexOf('https://kuula.co') >=0 
			|| value.indexOf('https://my.matterport.com') >=0
			|| value.indexOf('https://www.my.matterport.com') >=0
			|| value.indexOf('https://skyward360tours.com') >=0
			|| value.indexOf('https://www.skyward360tours.com') >=0 
			) || value==''){
		console.log('found');
          this.setState({
            validation:true
          });
        }
        
    else{
      console.log('not found');
    
      this.setState({
        [error]: "Please enter valid url",
        [id]: [value],
        validation:false
      });
   
     }
    }
    }
  }
  onVideoHandleChange=(event)=>{
    console.log('=>',event.target.id,event.target.value);
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;

    

    if(id==='video_link'){
      var error = id + "_error";
      if(value==''){
        this.setState({
          [error]: "",
          [id]: [value],
          validation:true
        });
      }
      else{

        if((
			value.indexOf('https://www.youtube.com') >=0 
			|| value.indexOf('https://youtube.com') >=0
			|| value.indexOf('https://www.youtu.be.com') >=0 
			|| value.indexOf('https://youtu.be.com') >=0 
			|| value.indexOf('https://www.vimeo.com') >=0 
			|| value.indexOf('https://vimeo.com/') >=0
			|| value.indexOf('https://www.kuula.co') >=0 
			|| value.indexOf('https://kuula.co') >=0 
			|| value==''
			)){
          console.log('found');
          this.setState({
            validation:true
          });
        }
        
    else{
      console.log('not found');
    
      this.setState({
        [error]: "Please enter valid video url, url can have youtube or vimeo link",
        [id]: [value],
        validation:false
      });
   
     }
    }
    }
  } 
    onVrHandle=(event)=>{
      console.log('=>',event.target.id,event.target.value);
      const id = event.target.id;
      const value = event.target.value;

      
      if(id==='kuula_vr_link'){
        var error = id + "_error";
        this.setState({
          [error]: "",
          [id]: [value],
          validation:true
        });
       
      }
      }
	  onVideoHandle=(event)=>{
		console.log('=>',event.target.id,event.target.value);
		const id = event.target.id;
		const value = event.target.value;
  
		
		if(id==='video_link'){
		  var error = id + "_error";
		  this.setState({
			[error]: "",
			[id]: [value],
			validation:true
		  });
		 
		}
		}
				
		ValidURL=(str)=> {
					str=String(str);
					if((
						str.indexOf('https://www.tours.moveskyward.com') >=0 
						|| str.indexOf('https://tours.moveskyward.com') >=0 
						|| str.indexOf('https://www.view.ricohtours.com') >=0 
						|| str.indexOf('https://view.ricohtours.com') >=0 
						|| str.indexOf('https://www.kuula.co') >=0 
						|| str.indexOf('https://kuula.co') >=0 
						|| str.indexOf('https://www.my.matterport.com') >=0 
						|| str.indexOf('https://my.matterport.com') >=0
						|| str.indexOf('https://skyward360tours.com') >=0
						|| str.indexOf('https://www.skyward360tours.com') >=0
						) 
						|| str==''
						){
							 return true;
					}else{
					console.log('not found');
					return false;
					}
				  
				  }
		ValidVideoURL=(str)=> {
					
					str=String(str);
  
					if((
						str.indexOf('https://www.youtu.be.com') >=0 
						|| str.indexOf('https://youtu.be.com') >=0 
						|| str.indexOf('https://www.youtube.com') >=0 
						|| str.indexOf('https://youtube.com') >=0 
						|| str.indexOf('https://www.vimeo.com') >=0 
						|| str.indexOf('https://vimeo.com') >=0 
						|| str.indexOf('https://www.kuula.co') >=0 
						|| str.indexOf('https://kuula.co') >=0 
						|| str=='')){
							  return true;
					}else{
					console.log('not found');
					return false;
					
					}
				  
				  }
			
	vrSubmit=(event)=>{
				event.preventDefault();
				if(this.ValidURL(this.state.kuula_vr_link) == false && this.state.kuula_vr_link!=''){
	
					this.setState({kuula_vr_link_error:'please enter valid url'})
				}else{
					this.setState({kuula_vr_link_error:''})
				let where=[{
						url:'/managers/vrEdit'
				}];
				postApiData(where,{'vrlink':this.state.kuula_vr_link,'listing_id':this.state.listing_id},true).then(res=>{
					this.getListingData();
					document.getElementById("editVR").click();
				}).catch(error=>{
	
				})
				
			}
			
				}
	videoSubmit=(event)=>{
					event.preventDefault();
					if(this.ValidVideoURL(this.state.video_link) == false && this.state.video_link!=''){
		
						this.setState({video_link_error:'please enter valid url'})
					}else{
						this.setState({video_link_error:''})
					let where=[{
							url:'/managers/videoEdit'
					}];
					postApiData(where,{'videolink':this.state.video_link,'listing_id':this.state.listing_id},true).then(res=>{
						this.getListingData();
						document.getElementById("editVR").click();
					}).catch(error=>{
		
					})
					
				}
				
					}			
// handle = event => {
// 	console.log(event.keyCode);
// 	if (event.keyCode === 27) {
// 	  alert('You hit esc!')
// 	}
//   }

				
				onHandleChange=(event)=>{
					const id = event.target.id;
					const value = event.target.value;
					const dataset = event.currentTarget.dataset.id;
				
					if(  id == "maintenance" || id == "monthly_taxes" ||
						id == "commission" || id == "tax_deduction" || id == "flip_tax" ||
						id == "max_financing" || id == "number_of_shares" || id == "percentofcommon" ){
							var error = id + "_error";
							if ((isNaN(value)  || value<0) && value != ""){
								this.setState({
									[error]:"Please enter numbers only",
									[id]:""
								})
							}
								else{
									this.setState({
										[error]:"",
										[id]:[value] 
									})
								}
						 }
						
					else{
						this.setState({
							[id]:[value]
						});
					}
				  }
				

		validation = () => {
			const {
				price,
				bedrooms,
				bathrooms,
				square_footage,
				date_available,
				description,
			} = this.state;
 
			return (
				price.toString().trim() &&
			 	price.toString().trim() !="" &&
		   	    bedrooms.toString().trim() != "" &&
		     	bathrooms.toString().trim() != "" &&
			    square_footage.toString().trim() != "" &&
				description != 0 &&
		     	date_available != "" 
			);
		};
		
		resetCheckbox=()=>
		{
			this.setState({checkedBoxes:[]});
			this.setState({marketcheckedBoxes:[]});
		}
			amenitySubmit=(event)=>{
				event.preventDefault();
				let listingWhere=[{
						url : '/managers/amenityEdit'
				}];
				postApiData(listingWhere,{'amenity':this.state.amenity_list,'listing_id':this.state.listing_id},true).then(res=>{
					this.getListingData();
					var newbid=document.getElementById('UpdateBuildingId').value;
				    this.onBuildlingAndListingdataById(newbid,this.state.listing_id)
					document.getElementById("buildingamenityClose").click();
					this.setState({checkedBoxes:[]});
					
				}).catch(error=>{

				})
				var newbid=document.getElementById('UpdateBuildingId').value;
				
				this.onBuildlingAndListingdataById(newbid,this.state.listing_id)
			}


			marketingSubmit=(event)=>{
				event.preventDefault();
				if(this.props.userType ==='Agent'){

				this.state.agent_id=getAgentId();
				}else{
					this.state.agent_id=getAgentId();
				}
				console.log("---this.state.agent_id",this.state.agent_id);
				let listingWhere=[{
						url : '/managers/marketingEdit'
				}];

				if(this.state.agent_id!==null){
		  	var data=		postApiData(listingWhere,{'marketing':this.state.marketing,'listing_id':this.state.listing_id,'check':this.state.rentHopCheck,'agent_id':this.state.agent_id},true)
				}else{
		  	var data=		postApiData(listingWhere,{'marketing':this.state.marketing,'listing_id':this.state.listing_id,'check':this.state.rentHopCheck},true)
				}
		    data.then(res=>{
					this.getListingData();
					document.getElementById("marketingClose").click();
					this.setState({marketcheckedBoxes:[]});
					
				}).catch(error=>{

				})
			}
			
			handleChangeStatus = checked => {
				
				if (checked){
					 this.state.furnished=1
						this.furnishedSubmit(); 
				}
				else {
					this.state.furnished=0;
					this.furnishedSubmit();
				}
			  };
		

			furnishedSubmit=()=>{
				let listingWhere=[{
					url :'/managers/furnishedEdit'
				}];
				postApiData(listingWhere,{'furnished':this.state.furnished,'listing_id':this.state.listing_id},true).then(res=>{
					this.getListingData();
					//document.getElementById("furnishedEditClose").click();	
				}).catch(error=>{

				})
			}

			priceSubmit=(event)=>{
				event.preventDefault();
				let listingWhere=[{
					url:'/managers/priceEdit'
				}];
				postApiData(listingWhere,{'price':this.state.price,'listing_id':this.state.listing_id},true).then(res=>{
					this.getListingData();
					document.getElementById("priceEditClose").click();	
				}).catch(error=>{

				})
			}

			bedbathSqSubmit=(event)=>{
				event.preventDefault();
				let listingWhere=[{
					url : '/managers/bedbathsquEdit'
				}];
				postApiData(listingWhere,{'bedrooms':this.state.bedrooms,'bathrooms':this.state.bathrooms,'square_footage':this.state.square_footage,'listing_id':this.state.listing_id},true).then(res=>{
					this.getListingData();
					document.getElementById("bedbathsqEditClose").click();
				}).catch(error=>{

				})
			}

			dateSubmit=(event)=>{
				event.preventDefault();
				let listingWhere=[{
					url:'/managers/dateEdit'
				}];
				postApiData(listingWhere,{'date_available':Moment.utc(this.state.startDate).local().format('YYYY-MM-DD'),'listing_id':this.state.listing_id},true).then(res=>{
					this.getListingData();
					window.location.reload();
					document.getElementById("dateEditClose").click();
				}).catch(error=>{

				})
			}

			descriptionSubmit=(event)=>{
				event.preventDefault();
				let listingWhere=[{
					url:'/managers/descriptionEdit'
				}];
				postApiData(listingWhere,{'description':this.state.description,'listing_id':this.state.listing_id},true).then(res=>{
					this.getListingData();
					document.getElementById("descriptionEditClose").click();
				}).catch(error=>{
				})
			}

			instagramTileSubmit=(event)=>{
				event.preventDefault();
				let listingWhere=[{
					url:'/managers/instagramTileSave'
				}];
				this.setState({linkingLoader:true});
				postApiData(listingWhere,{'first_text':this.state.instatilefirsttext,'last_text':this.state.instatilelasttext,'listing_id':this.state.instaListingId,'agent_id':getAgentId()},true).then(res=>{
					this.setState({instatileimage_error:''});
					this.setState({linkingLoader:false});
					window.open(getBaseUrl()+"/containers/instagram_tile/download/"+this.state.instaListingId+".zip");
					
				}).catch(error=>{
					this.setState({instatileimage_error:'This listing has less that 3 images, so cound not create tiles.'});
				})
			}
			incentivesSubmit=(event)=>{
				event.preventDefault();
				const formData = new FormData();
				var noFee=0;
				if(this.state.noFee == true){
					 noFee = 1;
				}
				if(document.getElementById("owner_pays_broker")){
					var owner_pays_broker=document.getElementById("owner_pays_broker").value;
				  }

				formData.append('incentives',this.state.incentives+','+this.state.owner_pays)
				formData.append('noFee',noFee)
				formData.append('tenant_incentive',this.state.tenant_incentive)
				formData.append('listing_id',this.state.listing_id)
				if(this.state.owner_pays == "Owner pays"){
					formData.append('owner_pays_broker',owner_pays_broker)
					formData.append('owner_amount',this.state.owner_amount)
					}
					if(this.state.incentives== "Co-broke"){
					formData.append('commission_percentage',this.state.commission_percentage)
					formData.append('my_split',this.state.my_split)
					}
				let listingWhere=[{
					url : '/managers/incentiveEdit'
				}];
				postApiData(listingWhere,formData,true).then(res=>{
					this.getListingData();
					document.getElementById("incentiveEditClose").click();
				}).catch(error=>{

				})
			}

			//incetive detail select function
			incentive=(value)=>{
				this.setState({incentives:value})
			}

			

			getListingImage=()=>{
				let listingWhere=[{
					url:'/managers/imageList'
				}];
			postApiData(listingWhere,{'listing_id':this.state.listing_id},true).then(response=>{
					this.setState({
						filename : response.data
					})
			}).catch(err=>{

			})
			}

			salesSubmit = (event)=>{
				event.preventDefault();
				let where=[{
					url : '/managers/salesInfoEdit'
				}];
				postApiData(where,{
					'listing_id': this.state.listing_id,
					'maintenance':this.state.maintenance ,
					'monthly_taxes':this.state.monthly_taxes,
					'commission':this.state.commission,
					'tax_deduction':this.state.tax_deduction,
					'flip_tax':this.state.flip_tax,
					'max_financing':this.state.max_financing,
					'number_of_shares':this.state.number_of_shares,
					'percentofcommon':this.state.percentofcommon
						},true).then(res=>{
					this.getListingData();
					document.getElementById('salesEditClose').click();
				}).catch(error=>{

				})
			}

		  fileChangedHandler = (event) => {
			  console.log('----file',event.target.files[0])
        if(event.target.files[0]){
         let imgFlag = true;
        const maxFileSize = 5242880;   //5mb
        const value = event.target.files[0].name;
        const ext = value.substring(value.lastIndexOf('.'));
        if(!['.jpg','.jpeg','.JPG','.png','.PNG'].includes(ext)){
            this.setState({mymsg: ext+' is not supported file extension.'});
            imgFlag = false;
        }
        if(event.target.files[0].size>maxFileSize){
            this.setState({mymsg:"The uploaded image is too large. The max image file size is set to 5MB"})
            imgFlag = false;
            }
        if(imgFlag) { 
             this.setState({
				 selectedFile:event.target.files[0], 
				 imagePath2: URL.createObjectURL(event.target.files[0]) ,
				 imagePath4: URL.createObjectURL(event.target.files[0])
								})
    		    }
   		  }            
    }

handleClickOnList=param=>e=>{
	var value=param[0];

	this.getData(value,e.target.id);
	document.getElementById('search').classList.remove("show");
}
getData(value,id){
	if(id=='searchNeighbor')
	{
				this.setState({ searchNeighborhood: value}, () => {
			this.state.neighoberData.forEach(function(neighbor,index) {
				if(neighbor.neighborhood==value){
					document.getElementById('neighborClick').classList.add("active");
					document.getElementById('searchNeighborParam').value=neighbor.neighborhood;
					document.getElementById('neighbor').classList.remove("show");
				}
				else{
					neighbor.flag=0;
				}
			});
			document.getElementById('neighborClick').classList.add("active");
			this.createWhere();
		  });
	}
	else{
	
this.setState({ searchListingnId: value}, () => {
	this.state.listingData.forEach(function(address,index) {
		if(address.listing_info_address==value){
			document.getElementById('searchClick').classList.add("active");
			document.getElementById('searchParm').value=address.listing_info_address;
		}
		else{
			address.flag=0;
		}
	});
	document.getElementById('searchClick').classList.add("active");
	this.createWhere();
  });
}
}


listingCreatorRedirect=()=>{
	if(this.props.userType=='Manager'){
		window.location.href="/manager/creator/edit/"+this.state.buildingid +"/"+this.state.listing_id+"/"+this.state.unitnumber
	}
	if(this.props.userType == 'Agent')
	{
		window.location.href="/agent/creator/edit/"+this.state.buildingid +"/"+this.state.listing_id+"/"+this.state.unitnumber
	}
}
setOrderBy= param => e => {
	let key = param[0];
	if(key=='BR' && this.state.orderByState=='ASC'){
		this.setState({orderBy:'bedrooms',orderByState:'DESC'},()=>{
			this.createWhere();
		});
	}else if(key=='BR' && (this.state.orderByState=='DESC' || this.state.orderByState=='')){
		this.setState({orderBy:'bedrooms',orderByState:'ASC'},()=>{
			this.createWhere();
		});
	}else if(key=='BA' && this.state.orderByState=='ASC'){
		this.setState({orderBy:'bathrooms',orderByState:'DESC'},()=>{
			this.createWhere();
		});
	}else if(key=='BA'&& (this.state.orderByState=='DESC' || this.state.orderByState=='')){
		this.setState({orderBy:'bathrooms',orderByState:'ASC'},()=>{
			this.createWhere();
		});
	}else if(key=='Price' && this.state.orderByState=='ASC'){
		this.setState({orderBy:'price',orderByState:'DESC'},()=>{
			this.createWhere();
		});
	}else if(key=='Price' && (this.state.orderByState=='DESC' || this.state.orderByState=='')){
		this.setState({orderBy:'price',orderByState:'ASC'},()=>{
			this.createWhere();
		});
	}
}

fileHandler = params=>(e) => {
	
	this.setState({
		image_ID:params,
	})

	if(e.target.files[0]){
		
	   let imgFlag = true;
	const maxFileSize = 5242880;   //5mb
	const value = e.target.files[0].name;
	const ext = value.substring(value.lastIndexOf('.'));
	if(![".jpg", ".JPG", ".jpeg", ".png"].includes(ext)){
			this.setState({mymsg: ext+' is not supported file extension.'});
			imgFlag = false;
	}
	if(e.target.files[0].size>maxFileSize){
			this.setState({mymsg:"The uploaded image is too large. The max image file size is set to 5MB"})
			imgFlag = false;
			}
	if(imgFlag) { 
			   this.setState({
	   selectedFile:e.target.files[0], 
	   imagePath3: URL.createObjectURL(e.target.files[0]) ,

					})
					this.imageSubmit(this.state.list_id,e.target.files[0],params);
			   }
	   }


	if( typeof sortedListVar != 'undefined' && sortedListVar instanceof Array ){
		console.log("in saveListOrder--", sortedListVar);
		console.log("length--", sortedListVar.length);
		let tempArr = [];

		if (sortedListVar.length > 0) {
			
			sortedListVar.map((item,i) => {
			
				if(!isNullOrUndefined(item.rank)){
					tempArr[i] = item.content.key+','+item.rank;
				}
				
			})
		}

		 
		}            
 }



 

 imageSubmit=(id,file,imageId)=>{
	

		console.log('in if-----------');
	let listingWhere=[{
		url : '/containers/listing_images/upload'
	}];
	const formData = new FormData();
	formData.append('file',file);
	formData.append('listing_id',id)
	postApiData(listingWhere,formData,true).then(res=>{
		if(res){
			
			
			let where=[{
				url:'/managers/listingImageUpdate'
			}];
			if(imageId!=undefined && imageId!=''){
			var data=postApiData(where,{'id':imageId,'large_image':res.data.file_name,'thumbnail_image':res.data.file_name},true)
			}else{
				var data=postApiData(where,{'listing_id':id,'large_image':res.data.file_name,'thumbnail_image':res.data.file_name},true)
			}
			data.then(async(response)=>{
				if(response){
					
					this.setState({
						image_list_value:[]
					},()=>{
						this.onBuildlingAndListingdataById(this.state.bID,this.state.list_id)
						this.getListingData()
					})
					
						this.setState({
							imagePath3:''
							})

							
					}
			}).catch( error=>{
				 
				}) 
		}
	}).catch(error=>{
	
	})
}

componentWillUpdate(next,previous){
	if(previous){
			this.state.image_list=previous.image_list
	}
}
				 deleteImage=(imageId,image_list_value)=>{ 
					if(this.state.image_list_value.length<=3){
						alert('Please upload a new Image to complete this action!')         
							}
							else {
								let where=[{
									url:'/managers/imageDelete'
								}]
							   
								  var	form =new FormData();
								form.append("imageId",imageId) 
								postApiData(where,form,true).then(res=>{
									this.setState({
										image_list_value:[] 
									},()=>{
										this.onBuildlingAndListingdataById(this.state.bID,this.state.list_id)
										this.getListingData()
									})
								}).catch(error=>{
				
								})           
							}
							console.log('=>',image_list_value);
							}
									
				  

			openandhide=(event)=>{
				event.preventDefault();
				this.setState({
					hideandshow:1
				})
			}

			closeRow=(event)=>{
				event.preventDefault();
				this.setState({
					hideandshow:0
				})
			}

			instaTileListig=(id)=>{

				let listingWhere=[{
					url : '/managers/getinstaTileDetail'
				}];	
				this.setState({instatilefirsttext:''});
					this.setState({instatilelasttext:''});
				this.setState({linkingLoader:true});
				postApiData(listingWhere,{'listing_id':id,'agent_id':getAgentId()},true).then(res=>{
				//	console.log(res.data[0]);
					this.setState({instatilefirsttext:res.data[0].first_page_text});
					this.setState({instatilelasttext:res.data[0].last_page_text});
					this.setState({linkingLoader:false});
						//this.getListingData();
				}).catch(err=>{
					this.setState({linkingLoader:false});
				})

              this.setState({instaListingId:id});
          
			}
			deleteListing=(id,status,nestio_listing_id)=>{
			
			if(window.confirm('Are you sure you want to delete this Listing?')){
	            if(nestio_listing_id==null){
					let listingWhere=[{
						url : '/managers/deletelisting'
					}];	
					postApiData(listingWhere,{'id':id,'delete_flag':status,'status':0},true).then(res=>{
						//document.getElementById('ListingModal').click();
							this.getListingData();
					}).catch(err=>{
			
					})
				}
				else{
					let listingWhere=[{
						url : '/managers/deletelisting'
					}];	
					postApiData(listingWhere,{'id':id,'delete_flag':status,'status':0,'listing_source':1},true).then(res=>{
						//document.getElementById('ListingModal').click();
							this.getListingData();
					}).catch(err=>{
			
					})
				}

			}
			else{
				this.getListingData();
			}
				
			}

			ownerpays=()=>{
				let where=[{
				  url:'/agent/ownerPays'
				}];
				postApiData(where,{'tableName':'owner_pays_broker_master'},true).then(res=>{
				  //console.log("--res",res);
				  this.setState({owner_pays_data:res.data})
				}).catch(error=>{
			
				})
			  }

			  changeHandler=(event)=>{
  
				const id = event.target.id;
				const value = event.target.value;
				const dataset = event.currentTarget.dataset.id;
				const name = event.target.name;
				if(id==="Owner_pays"){
				  if(this.state.owner_pays!="" && this.state.owner_pays!=undefined){
					 
					this.setState({
					  owner_pays : '',
					})
				  }
				  else{
					this.setState({
					  owner_pays : dataset,
					//  incentives:dataset,
					})
					
				  }
				  
				}
				
			  
				if(id==="owner_amount"){
				  if(value==""){
					this.setState({
					  owner_amount :""
					})
				  }else if(isNaN(value)){
					this.setState({
					  owner_amount:""
					})
				  }
				  else{
				  this.setState({
					owner_amount : value,
				  })
				}
				}
				
			  }

			  onChangeHandler=(event)=>{
				// commission_percentage,
				// my_split,
				// co_broke_split
				
							if(event.target.id==="co_broke_split"){
							  this.setState({
								[event.target.id]:event.target.value,
								[event.target.id+"error"]:""
							  })
							}
							if(event.target.id==="commission_percentage"){
							  if(event.target.value =="" ){
								this.setState({
								  [event.target.id]:"",
								  [event.target.id+"error"]:"Field can not be empty."
									
								})
							  }
							  else if(isNaN(event.target.value)){
								this.setState({
								  [event.target.id]:"",
								  [event.target.id+"error"]:"Enter only numbers."
								})
							  }else{
								if( event.target.value <=100){
								var _commission=this.state.my_split*event.target.value;
								var _percentage=_commission / 100;
			  
								var _cocommission=event.target.value *(100-this.state.my_split);
								var _copercentage=_cocommission/100;
			  
							  this.setState({
								[event.target.id]:event.target.value,
								final_precentage:_percentage,
								[event.target.id+"error"]:"",
								copercentage_broker:_copercentage
							})
						  }
						}
					   }
							if( event.target.id==="my_split"){
							  if(event.target.value =="" ){
								this.setState({
								  [event.target.id]:event.target.value,
									  co_broker_split:100,
									  final_precentage:0,
									  copercentage_broker:this.state.commission_percentage,
									  [event.target.id+"error"]:"Field can not be empty."
								})
							  }else if(isNaN(event.target.value)){
								this.setState({
								  [event.target.id]:"",
								  [event.target.id+"error"]:"Enter only number"
								})
							  }
							  else{
							   
								if( event.target.value <=100){
								var _co_broker_split=100 - event.target.value
								var _commission=event.target.value * this.state.commission_percentage;
								var _percentage=_commission / 100;
										 
								var _cocommission=this.state.commission_percentage*_co_broker_split;
								var _copercentage=_cocommission/100;
			  
			   
							  this.setState({
								[event.target.id]:event.target.value,
								co_broker_split:100 - event.target.value,
								final_precentage:_percentage,
								[event.target.id+"error"]:"",
								copercentage_broker:_copercentage
							  })
							  
							 
							}
						  }
						}
			  }
			  OpenList() {
				var x = document.getElementById("clientList");
				if (x.style.display === "none") {
				  x.style.display = "block";
				} else {
				  x.style.display = "none";
				}
			  }

// get agents client detail

getAgentsClient=()=>{
	

	let where=[{
		url:'/agent/agents_client/'+getAgentId()
	}]
	getApiData(where,true).then(res=>{
		console.log("---res_client",res.data);
		this.setState({client_detail:res.data})
		
	}).catch(error=>{

	})
}

// --start-- send mail to client 

handleClickOnClient=param=>event=>{

	let value=param[1]; //id
	let value_name=param[0]; //name
	if(this.state.selected_email==''){
		this.setState({selected_email:value_name,selected_name:value})
	}
	if(!document.getElementById(event.target.id).checked){
	  
	  this.removeValue(this.state.selected_email,value_name,',',(cberr,cbres)=>{
	   
	   if(cbres!=''){
		 //var listName=cbres.split(',');
		 this.setState({selected_email:cbres});
	   }
	   else{
		 this.setState({selected_email:cbres});
	   }
	  });
	   this.removeValue(this.state.selected_name,value,',',(cberr,cbres)=>{
  
	   if(cbres!=''){
		var listName=cbres.split(',');
		 this.setState({selected_name:cbres});
	   }
	   else{
	   this.setState({selected_name:cbres});
	   }
	 
	   });
	  } 
   
	  if(document.getElementById(event.target.id).checked){
	   
	   this.addValue(this.state.selected_email,value_name,',',(cberr,cbres)=>{
				 if(cbres!=''){
					
					 this.setState({selected_email:cbres});
				}
				
			});
			
			this.addValue(this.state.selected_name,value,',',(cberr,cbres)=>{
			 if(cbres!=''){
			  var listName=cbres.split(',');
				 this.setState({selected_name:cbres});
			  }
			  
		  });
		   
	   }
  }

  /**
 * removeValue will manage the amenities
 */
removeValue(list, value, separator,cb){
	separator = separator || ",";
	let list_value=list.toString();
	var values = list_value.split(separator);
	for(var i = 0 ; i < values.length ; i++) {
	  if(values[i] == value) {
		values.splice(i, 1);
		//this.setState({amenitiesList:values.join(separator)})
		cb(null,values.join(separator));
	  }
	//   else{
	//     let _list_=list_value+","+value;
	//     //this.setState({amenitiesList:list})
	//     cb(null,_list_)
	// }
	}
  }
  
  addValue(list, value, separator,cb){
	  
	separator = separator || ",";
	if(list!=''){
	  var values = list.toString().split(separator);
	  for(var i = 0 ; i < values.length ; i++) {
		 if(values[i] == value) {
			values.splice(i, 1);
			cb(null,values.join(separator));
			}
		else{
		  let _list_=list+","+value;
		  cb(null,_list_)
	  }
	
	}
	}
	else{
	  cb(null,value) 
	}
	
  }

handleclientmail=(e)=>{
	this.setState({
		[e.target.id]:e.target.value
	})
}

validateclientemail=()=>{
	return(
		this.state.selected_email.length>0 &&
		this.state.content_value!="" &&
		this.state.mail_subject!=""
	)  
}
validationVR = () => {

    const {
      kuula_vr_link,
	} = this.state;

	return ( kuula_vr_link!="");
}
validationVideo = () => {

    const {
      video_link,
	} = this.state;

	return ( video_link!="");
}
sendmailToClient=(e)=>{
	var divValue='';
 
	divValue=document.getElementById('divContent').innerHTML;

    let where=[{
           url:'/email/send_Mali_ToClient'
   }];  
 
   var data={'client_name':this.state.selected_name, 'allclientemail':this.state.selected_email  ,'content':divValue,'subject':this.state.mail_subject };
   
   postApiData(where,data,true).then(res=>{
	   this.setState({mail_subject:'',selected_name:[],selected_email:[]})
	   this.setState(state => (this.state.content_value="I found a new listing that I thought you'd be interested in-feel free to check through the links for more photos and information. Be sure to let me know what you think! If you'd did like to send feedback or request a viewing, you can submit a note on the form located on the right side of each listing."))
       this.setState(state => (this.state.selected_name=[]))
	    document.getElementById('emailtoclient').click()
	
	
       
 }).catch(error=>{
   console.log('Error=>',error)
 }) 
}
//--end--



render() {

	

	var placeholder = (
		<div> DROP HERE ! </div>
	);

	// Owner Pays DropDown
	var ownerpayData;
	if(this.state.owner_pays_data){
		
	  ownerpayData =  this.state.owner_pays_data.map((item,index)=>{
	  return   <option   value={item.owner_pays_broker} >{item.owner_pays_broker}</option>
	  })}
	// Owner Pays DropDown

	const addressDW = this.state.addressData.map((dw,index) => 
	(dw.flag==1) ? <li id="searchkey" onClick={this.handleClickOnList([dw.listing_info_address])}>{dw.listing_info_address}</li>:''
	);
	  
	const neighborDW = this.state.neighoberData.map((dw,index) => 
	(dw.flag==1) ? <li id="searchNeighbor" onClick={this.handleClickOnList([dw.neighborhood])}>{dw.neighborhood}</li>:''
	);
	if(this.state.image_list=='' && this.state.image_list==undefined && this.state.image_list.length==''){
		return false;
	}

	// get client list
	var clientData;
	if(this.state.client_detail){
        clientData =  this.state.client_detail.map((item,index)=>{
			return    <span><input type="checkbox" id={index} onChange={this.handleClickOnClient([item.client_email,item.realm])} value={[item.client_email,item.realm]}/><label for={index}>{item.realm}</label></span>
			
		})
			

	}
   
	
	let {
		building_address, unit_number, bedrooms,
		bathrooms, price, Neighbourhood,
		description, furnished, square_footage,
		description_show,
		incentives, tenant_incentive, agent_name,agent_image,
		agent_email, agent_mob, date_available,
		listing_info_address,status, listingData,noFee,filename,neighoberData,
		amenity_list, amenityData, checkedBoxes,marketcheckedBoxes,listing_id,listing_status,furnished_status,list_image,
		 price_error, bedrooms_error, bathrooms_error,
		 square_footage_error, description_error, date_error,
		 maintenance,monthly_taxes,commission,tax_deduction,
		 flip_tax, max_financing,number_of_shares,percentofcommon,kuula_vr_link,video_link,marketing,marketingTableData,
			// sales info error
			maintenance_error,
			monthly_taxes_error,
			commission_error,
			tax_deduction_error,
			flip_tax_error,
			max_financing_error,
			number_of_shares_error,kuula_vr_link_error,video_link_error,instatilefirsttext_error,instatilelasttext_error,
			percentofcommon_error,bID}
		 =this.state;

	
	let listingValue = listingData.map((item,index)=>{
		
		let addr_array=item.building_address.split(',');
		return <React.Fragment><tr key={index}>
						<td><Link to onClick={()=>this.onBuildlingAndListingdataById(item.building_id,item.id,item.unit_number,item.listing_type)} data-toggle="modal" data-target="#ListingModal"><span style={{fontWeight:"bold"}}>{unescape(item.neighborhood)}</span><br /> {addr_array[0]},<br />{addr_array[1]},{addr_array[2]},{addr_array[3]}</Link> 
						{this.state.type!="Sales" ? this.props.userType == 'Manager'   ?
						 <Link to={{ pathname: '/manager/creator/edit/yes',
								   building_id:item.building_id,
								   listing_id:item.id,
								   unit_number:item.unit_number ,
								   type:'Rental',
								   edit:'yes'}} className="editsmall">
							  {(item.status ==0 || item.status ==1)?<i className="fa fa-pencil" aria-hidden="true" style={{padding:'5px'}}></i>:''}</Link>
                          
							: <Link to={{ pathname: '/agent/creator/edit/yes',building_id:item.building_id,
											listing_id:item.id,
											unit_number:item.unit_number,
											type:'Rental',
											edit:'yes' }} className="editsmall">
								{(item.status ==0 || item.status ==1)?<i className="fa fa-pencil" aria-hidden="true" style={{padding:'5px'}}></i>:''}</Link>
							: this.props.userType == 'Manager' ? <Link to={{ pathname: '/manager/sales/creator/edit/yes',
									building_id:item.building_id,
									listing_id:item.id,
									unit_number:item.unit_number,
									type:'Sales',
									edit:'yes'}} className="editsmall">
									{(item.status ==0 || item.status ==1)?<i className="fa fa-pencil" aria-hidden="true" style={{padding:'5px'}}></i>:''}</Link> 
							: <Link to={{ pathname: '/agent/sales/creator/edit/yes',building_id:item.building_id,
									listing_id:item.id,
									unit_number:item.unit_number,
									type:'Sales',
									edit:'yes'}} className="editsmall">
							{(item.status ==0 || item.status ==1)?<i className="fa fa-pencil" aria-hidden="true" style={{padding:'5px'}}></i>:''}</Link>	
							}
							<br></br>
							 <lable>Marketing: {item.image.id!=0 && item.image!=''?item.image.map((markdata)=>{
							var markSkydata= markdata.name=='Skyward'?'S ':'';
							var markstreetdata= markdata.name=='Localize'?'LO ':'';
							var markRLSdata= markdata.name=='RLS'?'RLS ':''; 
							var markRHdata= markdata.name=='Renthop'?'RH ':'';
						   let finaldata='';
							finaldata= markSkydata?markSkydata+',':'' + markstreetdata?markstreetdata+',':'' + markRLSdata?markRLSdata+',':'' + markRHdata?markRHdata+',':'';
							return (finaldata.substring(0, finaldata.length- 1))
						
						}):'N/A'}</lable>
								
								</td>
						<Link to onClick={()=>this.onBuildlingAndListingdataById(item.building_id,item.id,item.unit_number,item.listing_type)} data-toggle="modal" data-target="#ListingModal">
						<td>{item.unit_number}</td>
						{this.props.userType=='Manager'?<td>{item.primary_agent}{item.secondary_agent?<br />:''}{item.secondary_agent}{item.third_name?<br />:''}{item.third_name}</td>:''}
						<td>{item.bedrooms}BR</td>
						<td>{item.bathrooms}BA</td>
					<td>{item.price?'$'+item.price.toLocaleString():'$'+item.price}</td>
						<td>{item.rented_status_date!=null?Math.round(Math.abs(new Date(item.date_created).getTime() - new Date(item.rented_status_date).getTime())/ (1000 * 3600 * 24)):Math.round(Math.abs(new Date().getTime() - new Date(item.date_created).getTime())/ (1000 * 3600 * 24))}</td>
						
						{/* <td>{unescape(item.neighborhood)}</td> */}
						{(item.status ==0 || item.streeteasy_status==0 || item.RLS_status==0)?<td>Pending</td> : (item.status==1 && item.streeteasy_status==1 && item.RLS_status==1) ? <td>Active</td> : item.status==2 ? <td>Expired</td> : item.status==17 ? <td>Contract Signed</td> : item.status==18 ? <td>Lease Signed</td>: item.status==14 ? <td>Sold</td>: item.status==13 ? <td>Rented</td> : item.status==15 ? <td>Withdrawn</td> : item.status==19 ? <td>TOM 30 Days</td> : item.status==16 ? <td>Not Released</td>: item.status==20 ? <td>Coming Soon</td> 
						: (null)}
						</Link>
						{(item.status ==0 || item.streeteasy_status==0 || item.RLS_status==0 )  ?<td>{this.props.userType == 'Manager' ? <Link to onClick={()=>this.statusChange(item.id,item.status,item.streeteasy_status,item.RLS_status)} className="btn btn-small btn">Approve</Link>:(item.status ==19)?<Link to onClick={()=>this.statusChange(item.id,item.status,item.streeteasy_status,item.RLS_status)} className="btn btn-small btn">Set Active</Link>:''}</td> 
						:((item.status==1 && item.streeteasy_status==1 && item.RLS_status==1) || item.status ==20) && this.props.userType == 'Manager' ?<td><Link to onClick={()=>this.getListingId(item.id)}  className="btn btn-small btn-expire"  data-toggle="modal" data-target="#ExpirePop">Expire</Link><br /><br /><Link to onClick={()=>this.statusChange(item.id,'TOM',item.streeteasy_status,item.RLS_status)} className="btn btn-small btn">TOM 30 Days</Link></td>
						:(((item.status==1 && item.streeteasy_status==1 && item.RLS_status==1)) && this.props.userType == 'Agent') ?<td><Link to onClick={()=>this.getListingId(item.id)}  className="btn btn-small btn-expire"  data-toggle="modal" data-target="#ExpirePop">Expire</Link><br /><br /><Link to onClick={()=>this.statusChange(item.id,'TOM',item.streeteasy_status,item.RLS_status)} className="btn btn-small btn">TOM 30 Days</Link></td> 
						:(item.status ==19)?<td><Link to onClick={()=>this.getListingId(item.id)}  className="btn btn-small btn-expire"  data-toggle="modal" data-target="#ExpirePop">Expire</Link><br /><br /><Link to onClick={()=>this.statusChange(item.id,item.status,item.streeteasy_status,item.RLS_status)} className="btn btn-small btn" >Set Active</Link></td>
						:(item.status ==17 || item.status ==18 || item.status ==15 || item.status ==16 || item.status ==2)?<td><Link to onClick={()=>this.getListingId(item.id)}  className="btn btn-small btn-expire"  data-toggle="modal" data-target="#ExpirePop">Expire</Link><br /><br />{this.state.type!="Sales" ? this.props.userType == 'Manager' ?item.relist_status==0?  
						<Link to={{ pathname: '/manager/creator/edit/no',
							building_id:item.building_id,
							listing_id:item.id,
						  unit_number:item.unit_number,
						  edit:'no' }}
							className="btn btn-small btn-relist">Re-list</Link>:'' 
							: item.relist_status==0? <Link to={{ pathname: '/agent/creator/edit/no',
							building_id:item.building_id,
							listing_id:item.id,
						  unit_number:item.unit_number,
						  edit:'no', }}
							className="btn btn-small btn-relist">Re-list</Link>:'' 
					:this.props.userType == 'Manager'?item.relist_status==0 ?<Link to={{ pathname: '/manager/sales/creator/edit/no',
					building_id:item.building_id,
					listing_id:item.id,
				  unit_number:item.unit_number,
				  edit:'no', }}
					className="btn btn-small btn-relist">Re-list</Link>:''
					:item.relist_status==0?<Link to={{ pathname: '/agent/sales/creator/edit/no',
					building_id:item.building_id,
					listing_id:item.id,
				  unit_number:item.unit_number,
				  edit:'no', }}
					className="btn btn-small btn-relist">Re-list</Link>:''}</td>
						:item.status ==13 || item.status ==14 ?
						<td>{this.state.type!="Sales" ? this.props.userType == 'Manager' ?item.relist_status==0?  
							<Link to={{ pathname: '/manager/creator/edit/no',
								building_id:item.building_id,
								listing_id:item.id,
							  unit_number:item.unit_number,
							  edit:'no' }}
								className="btn btn-small btn-relist">Re-list</Link>:'' 
								: item.relist_status==0? <Link to={{ pathname: '/agent/creator/edit/no',
								building_id:item.building_id,
								listing_id:item.id,
							  unit_number:item.unit_number,
							  edit:'no', }}
								className="btn btn-small btn-relist">Re-list</Link>:'' 
						:this.props.userType == 'Manager'?item.relist_status==0 ?<Link to={{ pathname: '/manager/sales/creator/edit/no',
						building_id:item.building_id,
						listing_id:item.id,
					  unit_number:item.unit_number,
					  edit:'no', }}
						className="btn btn-small btn-relist">Re-list</Link>:''
						:item.relist_status==0?<Link to={{ pathname: '/agent/sales/creator/edit/no',
						building_id:item.building_id,
						listing_id:item.id,
					  unit_number:item.unit_number,
					  edit:'no', }}
						className="btn btn-small btn-relist">Re-list</Link>:''}</td>:<td>&nbsp;</td>}
						{this.props.userType == 'Manager'?<td><Link to onClick={()=>this.deleteListing(item.id,1,item.nestio_listing_id)} className="btn btn-small btn"><i class="fa fa-times"></i></Link></td>:''}
						
					</tr>
					{/* <tr key={index}><td colSpan='10' className="text-center">
					<button className="btn btn-default btn-table">Showsheet</button>
					<button className="btn btn-default btn-table">Brochure</button>
					<button className="btn btn-default btn-table">Postcard</button> 
					<button className="btn btn-default btn-table">Instagram Story</button> 
					<button className="btn btn-default btn-table" onClick={()=>this.instaTileListig(item.id)} data-toggle="modal" data-target="#instagramTileModal" >Instagram Tile</button>
					</td></tr> */}
					</React.Fragment>
				
	});
 							 												 										 	
	let listing_status_value= (listing_status == 0) ? <li>{this.props.userType== 'Manager' ?<button type="button"onClick={()=>this.statusChange(listing_id,1)} className="btn btn-big btn "  >Approve</button>:''}</li> 
	: (listing_status ==1) ? <li>
		<button type="button" onClick={()=>this.statusChange(listing_id,2)} className="btn btn-big btn-expire"  >Expire</button>
		<p style={{marginTop:'10px'}}><button type="button"  className="btn btn-big btn-relist"  data-toggle="modal" data-target="#emailtoclient">Email to Client</button></p>
	</li> 
	
	: (listing_status ==2) ? 
	
	
		this.state.listingtype =='Rental'  ? 
		<li><button type="button" onClick={()=>this.listingCreatorRedirect()} className="btn btn-big btn-relist"  >
		Re-list</button>
		<p style={{marginTop:'10px'}}><button type="button"  className="btn btn-big btn-relist"  data-toggle="modal" data-target="#emailtoclient">Email to Client</button></p>
		</li>
		:  '' 
		
	: null


	var marketingData;

	
	var setVal=0;
	if(this.state.marketing!==''){
	 marketingData = marketing.map((mark,index)=>{

		mark.name =="Renthop" ? flag=1:flag=0

		if(flag==1 && setVal==1){
			setVal=1;
		}
		else{
			return <li key={index}  >{mark.name}</li>
		}
		
	})
}

function contains (arr, key, val) {
	for (var i = 0; i < arr.length; i++) {
		if(arr[i][key] === val) 
		return true;
	}
	return false;
}
var flag;
var marketingItem;
if(this.state.marketingTableData!==''){
marketingItem=marketingTableData.map((item,index)=>{

	// var  val=contains(marketing, "name", item.name); //true
	// if(val && this.state.isCheckboxSelected)
	// {
	// 	 marketcheckedBoxes.push(item);
	// 	 this.setState({isCheckboxSelected:false});
	// 	 this.setState({marketcheckedBoxes});
	// }
	if(item.name!="RLS"){
        return <div className="checkbox-inline" key={item.name}>
	
	<input
		type="checkbox"
		id={item.name}
		checked={(contains(this.state.marketing,'name',item.name))}
		
		onClick={this.checkBoxHandler([item])}
		disabled={ item.name==="Renthop" ? flag ===1 :''}	
		/>
{/* onChange={(e) => this.checkBoxHandler(e, item)} */}
	<label htmlFor={item.name}>{item.name}</label>
	
	</div>
	}
	
})
}

function changeBackground(e) {
    e.target.style.background = 'red';
  }

	
let exist_amenity_name ;let am_id;

exist_amenity_name = amenity_list.map((value,index)=>{
	
		return 	<li key={index}  >{value.name}</li>
		}) 

		let exist_build_amenity;
		exist_build_amenity = this.state.building_amenity_list.map((value,index)=>{
			return <li key={index}>{value.amenity_name}</li>

		})

		
	let amenity_item =amenityData.map((data,i)=>{
		


	  return <div className="checkbox-inline" key={data.amenity_name}>
		<input
		 type="checkbox"
            id={data.amenity_name}
            checked={(contains(this.state.amenity_list,'name',data.amenity_name))}
            onClick={this.handleCheckBox([data])}
		/>
		<label htmlFor={data.amenity_name}>{data.amenity_name}</label>
	  </div>
	})
	
	const { userType} = this.props;
	if(this.props.userType == 'Manager'){
	   }

	var image_list;
	var check;
	var image_url;


	if(this.state.image_list){
	image_list = this.state.image_list.map((item,index)=>{
		check=item.large_image.indexOf("https://");
		return <div key={index}>
		<img src={this.state.imagePath2 && this.state.image_ID==item.id ? this.state.imagePath2 : (check > -1)  ?  item.large_image : getBaseUrl()+"/containers/listing_images/download/" + item.large_image} style={{transform: `rotate(${item.rotation}deg)`}} width="100%"/>
		{/*<Link to className="img-edit editsmallicon" onClick={this.imageEDIT(item.id)} data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>*/}
		
			<p className="legend" style={{display:'none'}}>
			<img src="/vr.png"/>{this.state.image_vrurl!='' ?<a href={this.state.image_vrurl} target="_blank"> Experience in VR </a> :<a href="#"> Experience in VR </a> } <Link to className="editsmall"  > 
			<i className="fa fa-pencil" aria-hidden="true" data-toggle="modal" data-target="#editVR"></i></Link></p>
		</div>
		})
		
	}

	
// 	if(this.state.image_list.length>0){
// thumbnail= this.state.image_list.map((value,index)=>{
// 	check=value.large_image.indexOf("https://");
// 	return <tr>
// 		<td>
// 		<div key={index}>
// 			<img src={this.state.imagePath3 && this.state.image_ID==value.id ? this.state.imagePath3: (check > -1)  ?  value.large_image : getBaseUrl()+"/containers/listing_images/download/" + value.thumbnail_image} width="100%"/> 
// 			 </div>
//  </td>
//  <td> <input type="file" onChange={this.fileHandler(value.id)} /></td>
//  <td><i class="fa fa-times" aria-hidden="true" onClick={()=>this.deleteImage(value.id)}></i></td>
// 	</tr> 
	
// });
// }
let thumbnail ;
if(this.state.image_list.length>0){
 thumbnail = this.state.image_list_value.map((item, i) => {
	check=item.large_image.indexOf("https://");
	if(check==-1){
        check=item.large_image.indexOf("http://");
	  }
	
	return {
		
		content: (

			<React.Fragment key={item.id}>
				<tr style={{cursor:'all-scroll'}}>
			<div id={item.id}>
				<td>
			<img src={this.state.imagePath3 && this.state.image_ID==item.id ? this.state.imagePath3: (check > -1)  ?  item.large_image : getBaseUrl()+"/containers/listing_images/download/" + item.thumbnail_image} style={{transform: `rotate(${item.rotation}deg)`}} width="80px"/></td>
			
				<td> <input type="file" onChange={this.fileHandler(item.id)} /></td>
                <td><a style={{cursor:'pointer'}} class="fa fa-times" aria-hidden="true" onClick={()=>this.deleteImage(item.id)}></a></td>
			</div>
			</tr>
			</React.Fragment>
		)
	}

})
}
	
	return (
                <div>
					{(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
					{(this.state.linkingLoader)?<div className="show_loader_on_click2" id="show_loader_on_click"></div>:''}
                {/* <div className="show_loader_on_click" id="show_loader_on_click" style={{'display':'none'}}></div>
                <div className="show_loader_on_click" id="show_loader_on_click1" style={{'display':'none'}}></div> */}


                    {userType == 'Manager' ?  <ManagerHeader/> : <AgentHeader />}
                    <main>
					{userType == 'Manager' ?  <ManagerSidebar/>: <AgentSidebar/> }
					<div className="content" style={{overflow:'auto','margin-bottom':'20px'}} ref="iScroll" >
						<div className="work-section">
						<div className="dashboard" >

						{(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}

						<div className="clearfix">
								<h2 className="pull-left">My Listings</h2>
								<button onClick={this.resetFilter} className="btn btn-default pull-right">Reset all filters</button>
						    </div><Tabs >
								<TabList>
									
									<Tab id="Rental" onClick={()=>this.getRentalListing()}>Rental</Tab>
									<Tab id="Sales"  onClick={()=>this.getSalesListing()} >Sales</Tab>
								</TabList>
								
								<TabPanel>
								<div className="panel-body">
									<div className="table-responsive " > 
									<table className="table table-striped agent-listing" >
										<thead>
											<tr>
												<th>Address <Link to  id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="search" class="collapse table-collapse client-collapse" ref={this.setWrapperRef1}>
												<div className="box">

												<input type="text" style={{maxWidth:'235px'}} id="searchParm" autoComplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
											    <span onClick={this.resetsearchParm}>(X)</span>
													<ul>
													{addressDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
												
												</div>
												</div>
												</th>
												<th>Unit #</th>
												{this.props.userType=='Manager'?<th>Agent(s)</th>:''}
												<th><Link to id="order_by_br"  className={
  (this.state.orderBy!='bedrooms')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='bedrooms' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='bedrooms' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='BR' onClick={this.setOrderBy(['BR'])}  >{(this.state.orderBy=='bedrooms')?this.state.orderByState:'None'}</Link>BR <Link to id="bedroomClick" data-toggle="collapse" data-target="#layout" className="icon-wrap status" onClick={this.StatusLayoutClick}  ></Link>
                        <div id="layout" class="collapse table-collapse status-collapse" style={{minWidth:'60px'}} ref={this.setWrapperRef5}>
												<div className="box" ref='1_bedroom'>
												    <input type="checkbox" id="0_bedroom" defaultChecked={this.state.ckbedroom_0.checked} value={this.state.ckbedroom_0.value} onChange={this.search} />
													<label for="0_bedroom">0</label>
													<input type="checkbox" id="1_bedroom" defaultChecked={this.state.ckbedroom_1.checked} value={this.state.ckbedroom_1.value} onChange={this.search}/>
													<label for="1_bedroom">1</label>
													<input type="checkbox" id="2_bedroom" defaultChecked={this.state.ckbedroom_2.checked} value={this.state.ckbedroom_2.value} onChange={this.search} />
													<label for="2_bedroom">2</label>
													<input type="checkbox" id="3_bedroom" defaultChecked={this.state.ckbedroom_3.checked} value={this.state.ckbedroom_3.value} onChange={this.search}/>
													<label for="3_bedroom">3</label>
                          							<input type="checkbox" id="4_bedroom" defaultChecked={this.state.ckbedroom_4.checked} value={this.state.ckbedroom_4.value} onChange={this.search}/>
													<label for="4_bedroom">4+</label>


											</div>		
												</div>
                        
                        </th>
                        <th><Link to id="order_by_ba" className={
  (this.state.orderBy!='bathrooms')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='bathrooms' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='bathrooms' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='BA' onClick={this.setOrderBy(['BA'])}   >{(this.state.orderBy=='bathrooms')?this.state.orderByState:'None'}</Link>BA <Link to id="bathroomClick" data-toggle="collapse" data-target="#ba" className="icon-wrap status" onClick={this.StatusBathClick} ></Link>
                       
                        <div id="bath" class="collapse table-collapse status-collapse" style={{minWidth:'60px'}} ref={this.setWrapperRef7}>
												<div className="box">
													<input type="checkbox" id="1_bathroom" defaultChecked={this.state.ckbathroom_1.checked} value={this.state.ckbathroom_1.value} onChange={this.search} />
													<label for="1_bathroom">1</label>
													<input type="checkbox" id="2_bathroom" defaultChecked={this.state.ckbathroom_2.checked} value={this.state.ckbathroom_2.value} onChange={this.search} />
													<label for="2_bathroom">2</label>
													<input type="checkbox" id="3_bathroom" defaultChecked={this.state.ckbathroom_3.checked} value={this.state.ckbathroom_3.value} onChange={this.search} />
													<label for="3_bathroom">3</label>
                          							<input type="checkbox" id="4_bathroom" defaultChecked={this.state.ckbathroom_4.checked} value={this.state.ckbathroom_4.value} onChange={this.search}/>
													<label for="4_bathroom">4+</label>
													</div>
												</div>
                        
                        </th>
											
												<th><Link to id="order_by_Price" className={
  (this.state.orderBy!='price')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='price' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='price' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='Price' onClick={this.setOrderBy(['Price'])}   >{(this.state.orderBy=='price')?this.state.orderByState:'None'}</Link>Price <Link to id="priceClick" data-toggle="collapse" data-target="#price" className="icon-wrap status" onClick={this.StatusPriceClick} ></Link>												
												<div id="price" class="collapse table-collapse" ref={this.setWrapperRef4}>
											
												<div className="listing-filter box " style={{margin:0, height:'90px'}}>
													<InputRange
													    formatLabel={value => (value>10000)?`No Limit`:`${'$'+value}`}
													   	maxValue={10500}
														minValue={0}
														value={this.state.priceSearch}
														onChange={priceSearch => this.setState({priceSearch })} onChangeComplete={value => this.searchByPrice(value)} />
													</div>
												</div></th>
												<th style={{'width':'25px'}}>Days on market</th>
												{/* <th>Marketing</th> */}
												{/* <th>Neighborhood <Link to id="neighborClick" data-toggle="collapse" data-target="#neighborhood" className="icon-wrap status" onClick={this.StatusNeighborClick} ></Link>												
												<div id="neighbor" class="collapse table-collapse client-collapse" ref={this.setWrapperRef3}>
												<div className="box">

												<input type="text" id="searchNeighborParam" autoComplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
											    <span onClick={this.resetsearchParm}>(X)</span>
													<ul>
													{neighborDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
												
												</div>
												</div>
							
												
												</th> */}
												<th>Status <Link to id="statusClick" data-toggle="collapse" data-target="#status" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="status" class="collapse table-collapse status-collapse" ref={this.setWrapperRef2}>
												<div className="box">
												<input type="checkbox" id="All" defaultChecked={this.state.ckAll.checked} value={this.state.ckAll.value} onChange={this.search} />
													<label for="All">Select All</label>
												<input type="checkbox" id="Pending" defaultChecked={this.state.ckPending.checked} value={this.state.ckPending.value} onChange={this.search} />
													<label for="Pending">Pending</label>
													<input type="checkbox" id="Approved" defaultChecked={this.state.ckApproved.checked} value={this.state.ckApproved.value} onChange={this.search}/>
													<label for="Approved">Active</label>
													<input type="checkbox" id="Expired"  defaultChecked={this.state.ckExpired.checked} value={this.state.ckExpired.value} onChange={this.search}/>
													<label for="Expired">Expired</label>
													<input type="checkbox" id="contractsigned"  defaultChecked={this.state.ckcontractsigned.checked} value={this.state.ckcontractsigned.value} onChange={this.search}/>
													<label for="contractsigned">Contract Signed</label>
													<input type="checkbox" id="leasesigned"  defaultChecked={this.state.ckleasesigned.checked} value={this.state.ckleasesigned.value} onChange={this.search}/>
													<label for="leasesigned">Lease Signed</label>
													<input type="checkbox" id="sold"  defaultChecked={this.state.cksold.checked} value={this.state.cksold.value} onChange={this.search}/>
													<label for="sold">Sold</label>
													<input type="checkbox" id="rented"  defaultChecked={this.state.ckrented.checked} value={this.state.ckrented.value} onChange={this.search}/>
													<label for="rented">Rented</label>
													<input type="checkbox" id="withdrawn"  defaultChecked={this.state.ckwithdrawn.checked} value={this.state.ckwithdrawn.value} onChange={this.search}/>
													<label for="withdrawn">Withdrawn</label>
													<input type="checkbox" id="TOM30"  defaultChecked={this.state.ckTOM30.checked} value={this.state.ckTOM30.value} onChange={this.search}/>
													<label for="TOM30">TOM 30</label>
													<input type="checkbox" id="notreleased"  defaultChecked={this.state.cknotreleased.checked} value={this.state.cknotreleased.value} onChange={this.search}/>
													<label for="notreleased">Not Released</label>
													
													</div>
												</div>
												
												</th>
											<th></th>
											{this.props.userType == 'Manager'? <th></th>:''}
											</tr>
										</thead>
										<tbody>
									
											{/* Rental listing */}
											{(listingData!=undefined && listingData!=null && listingData.length>0 && !this.state.linkingLoader)?listingValue:(listingData.length==0 &&  !this.state.linkingLoader)?'No records found.':''}
											{this.state.hasMoreItems ?<tr><td colSpan='9'> <p style={{'padding-bottom':'20px'}} className="loading"> Loading Items....</p></td></tr> : ""}
										</tbody>
										</table>
										</div>
									</div>
									</TabPanel>
					<div className="modal" id="ExpirePop">
									<div className="modal-dialog">
									<div className="modal-content" style={{minWidth:'100%'}}>
									
									
										<div className="modal-header">
										<h4 className="modal-title">Status</h4>
										<button type="button" className="close" id="statusPopup" onClick={this.closePopup} data-dismiss="modal">&times;</button>
										</div>
										
									
										<div className="modal-body">
										<div class="form-group">
											
												<select class="form-control" onChange={this.statusChanged()} id="listing_new_status">
												<option value="">Select Status</option>
													<option value="17">Contract Signed</option>
													<option value="18">Lease Signed</option>
													<option value="2">Expired</option>
													{this.state.type=='Sales'?<option value="14">Sold</option>:
													<option value="13">Rented</option>}
													<option value="15">Withdrawn</option>
													<option value="16">Not Released</option>		
												</select>	
											</div>
											<div className="row" id="status_detailed_row" style={{display:'none'}}>
												<div className="col-sm-4">
													<div class="form-group">
														<label >Close Date</label><br></br>
														<DatePicker
                                                                selected={this.state.upperSearchDate}
                                                                onChange={this.handleUpperSearchDate}
                                                                timeCaption="time"
                                                                className="form-control"
                                                               id="upperSearchDate"
															   onKeyDown = {(e)=>this.preventKeystroke(e)}
															   value={this.state.upperSearchDate}
                                                               
                                                                
                                                            />
														
													</div>
												</div>	
												<div className="col-sm-8">
													<div class="form-group">
														<label >{this.state.type==''?'Rental':'Sales'} Close Price</label>
														<input type="text" id="close_price" value={this.state.close_price} onChange={this.popupHandler} class="form-control"/>
														<span className="inline-error-class">
															{this.state.close_price_error !== ""
																? this.state.close_price_error
																: ""}
															</span>
													</div>
												</div>	
											</div>	
											<p className="text-right"><button disabled={!this.state.popupvalidation} class="btn btn-default" onClick={this.statusDetailChange}>Ok</button> &nbsp;
											<button class="btn btn-default" onClick={this.closePopup}  data-dismiss="modal">Cancel</button>
											</p>
										</div>
										
								
										
									</div>
									</div>
								</div>			
									<TabPanel >
								<div className="panel-body table-responsive ">
									<table className="table table-striped agent-listing">
										<thead>
											<tr>
												<th>Address <Link to  id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="search" class="collapse table-collapse client-collapse" ref={this.setWrapperRef1}>
												<div className="box">

												<input type="text" id="searchParm" autoComplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
											    <span onClick={this.resetsearchParm}>(X)</span>
													<ul>
													{addressDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
												</div>
												</div>
												</th>
												<th>Unit #</th>
												{this.props.userType=='Manager'?<th>Agent(s)</th>:''}
												<th><Link to id="order_by_br"  className={
  (this.state.orderBy!='bedrooms')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='bedrooms' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='bedrooms' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='BR' onClick={this.setOrderBy(['BR'])}  >{(this.state.orderBy=='bedrooms')?this.state.orderByState:'None'}</Link>BR <Link to id="bedroomClick" data-toggle="collapse" data-target="#br" className="icon-wrap status" onClick={this.StatusLayoutClick} ></Link>
                        <div id="layout" class="collapse table-collapse status-collapse" style={{minWidth:'60px'}} ref={this.setWrapperRef5}>
												<div className="box">
												<input type="checkbox" id="0_bedroom" defaultChecked={this.state.ckbedroom_0.checked} value={this.state.ckbedroom_0.value} onChange={this.search} />
													<label for="1_bedroom">0</label>
												<input type="checkbox" id="1_bedroom" defaultChecked={this.state.ckbedroom_1.checked} value={this.state.ckbedroom_1.value} onChange={this.search}/>
													<label for="1_bedroom">1</label>
													<input type="checkbox" id="2_bedroom" defaultChecked={this.state.ckbedroom_2.checked} value={this.state.ckbedroom_2.value} onChange={this.search} />
													<label for="2_bedroom">2</label>
													<input type="checkbox" id="3_bedroom" defaultChecked={this.state.ckbedroom_3.checked} value={this.state.ckbedroom_3.value} onChange={this.search}/>
													<label for="3_bedroom">3</label>
                          							<input type="checkbox" id="4_bedroom" defaultChecked={this.state.ckbedroom_4.checked} value={this.state.ckbedroom_4.value} onChange={this.search}/>
													<label for="4_bedroom">4+</label>
													</div>
												</div>
                        
                        </th>
                        <th><Link to id="order_by_ba" className={
  (this.state.orderBy!='bathrooms')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='bathrooms' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='bathrooms' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='BA' onClick={this.setOrderBy(['BA'])}   >{(this.state.orderBy=='bathrooms')?this.state.orderByState:'None'}</Link>BA <Link to id="bathroomClick" data-toggle="collapse" data-target="#ba" className="icon-wrap status" onClick={this.StatusBathClick} ></Link>
                       
                        <div id="bath" class="collapse table-collapse status-collapse" style={{minWidth:'60px'}} ref={this.setWrapperRef7}>
												<div className="box">
												<input type="checkbox" id="1_bathroom" defaultChecked={this.state.ckbathroom_1.checked} value={this.state.ckbathroom_1.value} onChange={this.search} />
													<label for="1_bathroom">1</label>
													<input type="checkbox" id="2_bathroom" defaultChecked={this.state.ckbathroom_2.checked} value={this.state.ckbathroom_2.value} onChange={this.search} />
													<label for="2_bathroom">2</label>
													<input type="checkbox" id="3_bathroom" defaultChecked={this.state.ckbathroom_3.checked} value={this.state.ckbathroom_3.value} onChange={this.search} />
													<label for="3_bathroom">3</label>
                          							<input type="checkbox" id="4_bathroom" defaultChecked={this.state.ckbathroom_4.checked} value={this.state.ckbathroom_4.value} onChange={this.search}/>
													<label for="4_bathroom">4+</label>
													</div>
												</div>
                        
                        </th>
												
												<th><Link to id="order_by_Price" className={
  (this.state.orderBy!='price')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='price' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='price' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='Price' onClick={this.setOrderBy(['Price'])}   >{(this.state.orderBy=='price')?this.state.orderByState:'None'}</Link>Price <Link to id="priceClick" data-toggle="collapse" data-target="#price" className="icon-wrap status" onClick={this.StatusPriceClick}  ></Link>												
												<div id="price" class="collapse table-collapse" ref={this.setWrapperRef4}>
												
												<div className="listing-filter box " style={{margin:0, height:'100px'}}>
												<InputRange
													    formatLabel={value => (value>10000)?`No Limit`:`${'$'+value}`}
													   	maxValue={10500}
														minValue={0}
														value={this.state.priceSearch}
														onChange={priceSearch => this.setState({priceSearch })} onChangeComplete={value => this.searchByPrice(value)} />
													</div>
													
												</div></th>
												<th>Days on market</th>
												
												{/* <th>Neighborhood <Link to id="neighborClick" data-toggle="collapse" data-target="#neighborhood" className="icon-wrap status" onClick={this.StatusNeighborClick} ></Link>												
												<div id="neighbor" class="collapse table-collapse status-collapse" ref={this.setWrapperRef3}>
												
													<div className="box">
													<input type="text" id="searchNeighborParam" autoComplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
											    <span onClick={this.resetsearchParm}>(X)</span>
													<ul>
													{neighborDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
													</div>
													
												</div>
							
												
												</th> */}
												<th>Status <Link to id="statusClick" data-toggle="collapse" data-target="#status" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="status" class="collapse table-collapse status-collapse" ref={this.setWrapperRef2}>
												
													<div className="box">
													<input type="checkbox" id="All" defaultChecked={this.state.ckAll.checked} value={this.state.ckAll.value} onChange={this.search} />
													<label for="All">Select All</label>
													<input type="checkbox" id="Pending" defaultChecked={this.state.ckPending.checked} value={this.state.ckPending.value} onChange={this.search} />
													<label for="Pending">Pending</label>
													<input type="checkbox" id="Approved" defaultChecked={this.state.ckApproved.checked} value={this.state.ckApproved.value} onChange={this.search}/>
													<label for="Approved">Active</label>
													<input type="checkbox" id="Expired"  defaultChecked={this.state.ckExpired.checked} value={this.state.ckExpired.value} onChange={this.search}/>
													<label for="Expired">Expired</label>
													<input type="checkbox" id="contractsigned"  defaultChecked={this.state.ckcontractsigned.checked} value={this.state.ckcontractsigned.value} onChange={this.search}/>
													<label for="contractsigned">Contract Signed</label>
													<input type="checkbox" id="leasesigned"  defaultChecked={this.state.ckleasesigned.checked} value={this.state.ckleasesigned.value} onChange={this.search}/>
													<label for="leasesigned">Lease Signed</label>
													<input type="checkbox" id="sold"  defaultChecked={this.state.cksold.checked} value={this.state.cksold.value} onChange={this.search}/>
													<label for="sold">Sold</label>
													<input type="checkbox" id="rented"  defaultChecked={this.state.ckrented.checked} value={this.state.ckrented.value} onChange={this.search}/>
													<label for="rented">Rented</label>
													<input type="checkbox" id="withdrawn"  defaultChecked={this.state.ckwithdrawn.checked} value={this.state.ckwithdrawn.value} onChange={this.search}/>
													<label for="withdrawn">Withdrawn</label>
													<input type="checkbox" id="TOM30"  defaultChecked={this.state.ckTOM30.checked} value={this.state.ckTOM30.value} onChange={this.search}/>
													<label for="TOM30">TOM 30</label>
													<input type="checkbox" id="notreleased"  defaultChecked={this.state.cknotreleased.checked} value={this.state.cknotreleased.value} onChange={this.search}/>
													<label for="notreleased">Not Released</label>
													<input type="checkbox" id="comingsoon"  defaultChecked={this.state.ckcomingsoon.checked} value={this.state.ckcomingsoon.value} onChange={this.search}/>
													<label for="comingsoon">Coming Soon</label>
													</div>
													
												</div>
												
												</th>
											<th></th>
											{this.props.userType == 'Manager'? <th></th>:''}
											{/* <th></th> */}
											</tr>
										</thead>
										<tbody>
										{/* Sales listing */}
										{(listingData!=undefined && listingData!=null && listingData.length>0)?listingValue:'No records found.'}
										{this.state.hasMoreItems ?<tr><td colSpan='9'> <p style={{'padding-bottom':'20px'}} className="loading"> Loading Items....</p></td></tr> : ""}
										</tbody>
										</table>
									</div>
								</TabPanel>
							
								</Tabs>	
							</div>
							
						</div>
					</div>	
                    </main> 

					 <div id="ListingModal" className="modal fade" role="dialog">
                        <div className="modal-dialog">

                        
                            <div className="modal-content">
						
                            <div className="modal-header" style={{borderBottom:'0'}}>
							<div style={{position:'absolute', left:'15px', top:'0px'}}><button type="button"  id="close" className="btn cl-btn " data-dismiss="modal"><img src="/close.png"/></button></div>
                                <div className="row clearfix">
                                    <div className="col-xs-6 col-sm-6">
                                        <h3>{listing_info_address},{unit_number}</h3>
                                        <p>{unescape(Neighbourhood)}</p>
																				{(this.state.listing_information=='true' || this.state.listing_information=='Exclusive') ? 
                                      <span className="excListing">Exclusive</span>
                                        // <span className="excListing">{this.state.listing_information=='true' || this.state.listing_information=='Exclusive' ? 'Exclusive': 'Open Listing'}</span>
                                          : <span className="openListing">Open Listing</span>}  
                                    </div>
                                    <div className="col-xs-6 col-sm-6 text-right">
                                        <p className="price">{price?'$'+price.toLocaleString():'$'+price}<Link to className="editsmall" data-toggle="modal" data-target="#priceEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></p>
                                        <p>{bedrooms} bedrooms, {bathrooms} bath  {square_footage>0?' | '+square_footage+' sq. ft.':'' }  <Link to className="editsmall"  data-toggle="modal" data-target="#bedbathsqEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></p>

                                    </div>
                                </div>    
                                
                            </div>
                            <div className="modal-body" >
                                <div className="listing-banner">
								<a data-toggle="modal" data-target="#gallery">
	
                             <Carousel showThumbs={false} infiniteLoop={true} emulateTouch={true} >
									
														 {/* <div>
										<Link to className="img-edit editsmall"  data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
									<img src="../../listing-banner.png" width="100%"/>
									<p className="legend"><img src="../../vr.png" />   Experience in VR </p>								
									</div>
									<div>
										<Link to className="img-edit editsmall"  data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
									<img src="../../listing-banner.png" width="100%"/>
									<p className="legend"><img src="../../vr.png" />   Experience in VR </p>								
									</div> */}
									{/* {image_listing !='' ?  image_listing
										:  
                                    <div>
                                    <img src={getBaseUrl()+"/containers/listing_image/download/1553008767861.jpg"}width="100%"/>
									<Link to className="img-edit editsmall"  data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
                                        <p className="legend"><img src="../../vr.png"/> Experience in VR <Link to className="editsmall"  > <i className="fa fa-pencil" aria-hidden="true"></i></Link></p>
                                    </div>
									} */}
									{ image_list!='' ?  image_list
									: <div>
									{/* <Link to className="img-edit editsmallicon" onClick={this.defaultImage(1)} data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link> */}
								<img src={this.state.imagePath3?this.state.imagePath3 :"/listing-banner.png"} width="100%"  />
								<p className="legend" style={{display:'none'}}><img src="/vr.png" />   Experience in VR <Link to className="editsmall"  > 
			         <i className="fa fa-pencil" aria-hidden="true" data-toggle="modal" data-target="#editVR"></i></Link></p>								
								</div>}
								
                                </Carousel></a>
								<Link to className="img-edit editsmallicon" data-toggle="modal" id="imgEdit" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
                                </div>
								<div className="row">
								<div className="detail-exvr col-sm-6"  style={{float:'right'}}>
								<p className="experience-vr" style={{maxWidth:'212px'}}><img src="/vr.png" />   Experience in VR <Link to className="editsmall"  > 
			         <i className="fa fa-pencil" aria-hidden="true" data-toggle="modal" data-target="#editVR"></i></Link></p>
					 		
                                    </div>
									<div className="detail-exvr col-sm-6" >
									<p className="experience-vr" style={{maxWidth:'212px'}}><img src="/video.png" />   Watch Video Tour <Link to className="editsmall"  > 
			         <i className="fa fa-pencil" aria-hidden="true" data-toggle="modal" data-target="#editVideo"></i></Link></p>
                                    </div>
									</div>
                                <div className="row clearfix">
								
                                    <div className="col-sm-8">
									<div className="incentive-box">
                                           <h4>Incentive Details <Link to className="editsmall pull-right" data-toggle="modal" data-target="#incentiveEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></h4>
										  <p>{((this.state.incentivesshow!='' && this.state.incentivesshow!='undefined' && this.state.incentivesshow!='undefined,' && this.state.incentivesshow!=',') || (tenant_incentive!='' && tenant_incentive!=undefined  && tenant_incentive!=',' && tenant_incentive!='undefined,'))? `${this.state.incentivesshow && this.state.incentivesshow!=',' && this.state.incentivesshow!='undefined,'?` Fee structure: ${this.state.incentivesshow}`:''}${tenant_incentive?` Commission: ${tenant_incentive}`:''}`:'N/A'}
											  
											  
											  {/* Fee structure: {this.state.incentivesshow} Commission: {tenant_incentive} */}
											  </p> 
                                        </div>
										{this.state.TabPanel == 'Sales' ?
										<div className="incentive-box">
                                         <h4>Sales Details <Link to className="editsmall pull-right" data-toggle="modal" data-target="#salesEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></h4>
										  <p>{maintenance?` CC/Maintenance: ${maintenance},`:''} {monthly_taxes?` Monthly Taxes: ${monthly_taxes},`:''} {commission?` Commission: ${commission},`:''}
										  {tax_deduction?` Tax Deduction: ${tax_deduction},`:''} {flip_tax?` Flip Tax: ${flip_tax},`:''} {max_financing?` Max Financing: ${max_financing},`:''}
										  {number_of_shares?` Number of Shares: ${number_of_shares},`:''} {percentofcommon?` % of Common: ${percentofcommon},`:''}</p>
									    </div>
										: ''}
                                        <div className="amenities-box">
                                           <h4>Unit Amenities <Link to className="editsmall pull-right" data-toggle="modal" data-target="#amenitiesEdit"> <i className="fa fa-pencil" aria-hidden="true" onClick={()=>this.onBuildlingAndListingdataById(bID,listing_id)}></i></Link></h4>
                                           <ul>
                                               {/* <li>Dishwasher</li>
                                               <li>Balcony</li>
                                               <li>Renovated Kitchen</li>
                                               <li>Elevator</li>
											   <li>Doorman</li> */}
											 {exist_amenity_name.length>0?exist_amenity_name:'N/A'}
                                           </ul>
                                        </div>
										<div className="amenities-box">
                                           <h4 >Building Amenities</h4>
                                           <ul>
                                               {/* <li>Dishwasher</li>
                                               <li>Balcony</li>
                                               <li>Renovated Kitchen</li>
                                               <li>Elevator</li>
											   <li>Doorman</li> */}
											  
											 {exist_build_amenity.length>0?exist_build_amenity:'NO Building Amenity Selected for this Building'}
                                           </ul>
                                        </div>
                                        <div className="discription-box">
                                            <h4>Listing Description <Link to className="editsmall pull-right" data-toggle="modal" data-target="#descriptionEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></h4>
                                            <p id="listingDesc" style={{whiteSpace:'pre-wrap'}}>{description_show.replace(/<[^>]*>?/gm, '')} </p>
                                        </div>
																				<div className="amenities-box">
                                           <h4>Marketing <Link to className="editsmall pull-right" data-toggle="modal" data-target="#marketingEdit"> <i className="fa fa-pencil" aria-hidden="true" onClick={()=>this.onBuildlingAndListingdataById(bID,listing_id)}></i></Link></h4>
                                           <ul>                      
											 											{marketingData}
                                           </ul>
                                        </div>
																			
                                        <ul className="btn-group-section d-block d-sm-none">
                                        <li><button type="button" className="btn apply-btn" data-dismiss="modal">Apply Now</button></li>
                                        <li><button type="button" className="btn btn-default" data-dismiss="modal">Request a showing</button></li>
                                        <li><button type="button" className="btn btn-default" data-dismiss="modal"> Save Listing <img src="../../star.png" width="20"/></button></li>
                                        <li><div className="map-wrap">
                                            <img src="/map.png" width="100%"/>

                                        </div></li>
                                    </ul>
									{this.props.userType == 'Manager'   ?    
                                        <div className="listed-box cleafix">
                                        <h4>Listed by </h4>

                                        <div className="info">
										{agent_image.startsWith("https://")?<img src={agent_image} width="75" height="75" className="rounded-circle"/>:<img src={getBaseUrl()+"/containers/agent_image/download/" + agent_image} width="75" height="75" className="rounded-circle"/>}
                                                
                                        </div>
										{/* {agent_image=='member.png' || agent_image==''?<img src="../../profile.png" width="75" height="75" className="rounded-circle"/>:<img src={agent_image} width="75" height="75" className="rounded-circle"/>} */}
                                        <p className="name">{agent_name}</p>
                                                <p>{agent_email}</p>
                                                <p>{agent_mob}</p>

                                        </div>
									:(null)}
                                    </div>
                                    <div className="col-sm-4 d-none d-sm-block text-right">
																			
									<p className="availabel-txt">Available {(new Date().setHours(0,0,0,0) > new Date(this.state.date_available1).setHours(0,0,0,0) || this.state.date_available1=='0000-00-00')? 'Immediately':date_available} <Link to data-toggle="modal"  data-target="#dateEdit"> <i className="fa fa-pencil editsmall" aria-hidden="true"></i></Link></p>
									
									<p className="unfurnish-txt">{furnished == 1 ? ('Furnished') : ('Unfurnished')} <Link to data-toggle="modal" data-target="#furnishedEdit"> <i className="fa fa-pencil editsmall" aria-hidden="true"></i></Link></p>
								<ul className="btn-group-section">
 															  	{/* <li><button type="button" className="btn btn-big btn expire"  >Expired</button></li>  */}
														 	{listing_status_value}
										</ul> 
                                     </div>
                                </div>              
                                <button type="button" id="ListingModal" className="btn cl-btn" data-dismiss="modal">Close  <img src="/close.png"/></button>
                            </div>
                          
                            </div>

                        </div>
                        </div> 
						<div id="gallery" className="modal fade" role="dialog">
                        <div className="modal-dialog">

                        
                            <div className="modal-content">
						
                        
                            <div className="modal-body" >
                                <div className="listing-banner">
								<button type="button" id="ListingModal" className="btn cl-btn" data-dismiss="modal"> <img src="/close.png"/></button>
	
                         		    <Carousel showThumbs={true} infiniteLoop={true} emulateTouch={true} >
									
														 {/* <div>
										<Link to className="img-edit editsmall"  data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
									<img src="../../listing-banner.png" width="100%"/>
									<p className="legend"><img src="../../vr.png" />   Experience in VR </p>								
									</div>
									<div>
										<Link to className="img-edit editsmall"  data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
									<img src="../../listing-banner.png" width="100%"/>
									<p className="legend"><img src="../../vr.png" />   Experience in VR </p>								
									</div> */}
									{/* {image_listing !='' ?  image_listing
										:  
                                    <div>
                                    <img src={getBaseUrl()+"/containers/listing_image/download/1553008767861.jpg"}width="100%"/>
									<Link to className="img-edit editsmall"  data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
                                        <p className="legend"><img src="../../vr.png"/> Experience in VR <Link to className="editsmall"  > <i className="fa fa-pencil" aria-hidden="true"></i></Link></p>
                                    </div>
									} */}
									{ image_list!='' ?  image_list
									: <div>
									{/* <Link to className="img-edit editsmallicon" onClick={this.defaultImage(1)} data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link> */}
								<img src={this.state.imagePath3?this.state.imagePath3 :"/listing-banner.png"} width="100%"/>
								<p className="legend"><img src="/vr.png" />   Experience in VR <Link to className="editsmall"  > 
			         <i className="fa fa-pencil" aria-hidden="true" data-toggle="modal" data-target="#editVR"></i></Link></p>								
								</div>}
								
                                </Carousel>
								<Link to className="img-edit editsmallicon" data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
                                </div>
                                         
                               
                            </div>
                          
                            </div>

                        </div>
                        </div> 		





						<div id="priceEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Price</h4>
                            <button type="button" id="priceEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
											<form  onSubmit={this.priceSubmit}>
									<input
                                         type="number"
											className="form-control"
											step="0.00001"
											id="price"
                                      		value={price}
                                 			onChange={this.handleChange}
                                   />
                                       <span className="inline-error-class">
                                            {" "}
                                                {price_error !== ""
                                                           ? price_error
                                                         : ""}
                                                        </span>
														<br></br>
												<button
                                                    type="submit"
                                                    disabled={!this.state.validation}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>
							<div id="bedbathsqEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Information</h4>
                            <button type="button" id="bedbathsqEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
										
									
							<form  onSubmit={this.bedbathSqSubmit}>
								<label>Bedrooms</label>
									<input
                                         type="number"
                                        className="form-control"
                                            id="bedrooms"
                                     value={bedrooms}
                                 onChange={this.handleChange}
                                   />   
																	 <span className="inline-error-class">
																	 {" "}
																			 {bedrooms_error !== ""
																			? bedrooms_error
																		: ""}
																		 </span>
																		 <br></br>
								     <label>Bathrooms</label>
								   <input
                                         type="number"
                                        className="form-control"
                                            id="bathrooms"
                                      value={bathrooms}
                                 onChange={this.handleChange}
                                   /> 
																	 <span className="inline-error-class">
																	 {" "}
																			 {bathrooms_error !== ""
																			? bathrooms_error
																		: ""}
																		 </span>
																		 <br></br>
								   <label>Sq.ft.</label>
								   <input
                                         type="text"
                                        className="form-control"
                                            id="square_footage"
                                     value={square_footage}
                                 onChange={this.handleChange}
                                   />
                                       <span className="inline-error-class">
                                            {" "}
                                                {square_footage_error != ""? square_footage_error: ""}
                                              </span>
											  <br></br>
												<button
                                                    type="submit"
                                                     disabled={!this.state.validation}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>
							<div id="incentiveEdit" className="modal fade" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Incentive Details</h4>
                            <button type="button" id="incentiveEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
													<form onSubmit={this.incentivesSubmit}>
												<ul className="btn-inline">
														<li className="btn"  id="CYOF" data-id="CYOF"  onClick={this.handleChange}  style={incentives == "CYOF"? { background: "#004F6B", color: "#ffffff",margin:'8px'}: { visibility: "visible",margin:'8px' } } >CYOF</li>
														<li className="btn"  onClick={this.changeHandler} id="Owner_pays" data-id="Owner pays"  style={this.state.owner_pays == "Owner pays"? { background: "#004F6B", color: "#ffffff" ,margin:'8px' }: { visibility: "visible" ,margin:'8px' }}>Owner pays</li>
														<li className="btn"  id="Co-broke"  data-id="Co-broke"  onClick={this.handleChange}  style={incentives == "Co-broke"? { background: "#004F6B", color: "#ffffff" ,margin:'8px' }: { visibility: "visible" ,margin:'8px' }}>Co-broke</li>
													</ul>

													<div class="form-inline">
													{this.state.owner_pays=="Owner pays"?
                          <div>
													<div className="form-group">
														<label>Owner Pays Broker</label>
														<select  id="owner_pays_broker"  className="form-control" style={{width:'200px'}}>
															<option value="">Select</option>
															{ownerpayData }
														</select>
														
													</div>
													<div className="form-group">
														<input
														type="text"
														className="form-control"
														id="owner_amount"
														value={this.state.owner_amount}
														onChange={this.changeHandler}
														/>
														<small className="rightbedge">{this.state.owner_pays_broker=="Month(s) Rent" ? '':(this.state.owner_pays_broker=='Percentage Of Yearly Rent' || this.state.owner_pays_broker=='Percentage Of Monthly Rent') ?  "%" :"$"}</small>
													</div>
						  </div> :""}
						  </div>
						  <div class="form-inline">
                          {(this.state.incentives=="Co-broke") ? 
                          <div>
                          <div className="form-group">
                            <label for="Tenant incentive">
                              Commission %
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="commission_percentage"
                              value={this.state.commission_percentage}
                              onChange={this.onChangeHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.commission_percentage ==undefined || this.state.commission_percentage=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />
                            <small className="rightbedge">%</small>
                            <br/>
                            <div> <span className="inline-error-class"></span></div>
                           
                          </div>
                          <div className="form-group">
                            <label for="Tenant incentive">
                             My Split
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="my_split"
                              value={this.state.my_split}
                              onChange={this.onChangeHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.my_split ==undefined || this.state.my_split=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />
                             <span>{this.state.final_precentage ? this.state.final_precentage+"%" : ''}</span>
                            {this.state.final_precentage?"":<small className="rightbedge">%</small>}
                         
                          </div>
                          <div className="form-group">
                            <label for="Tenant incentive">
                              Co-Broker's Split
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="co_broke_split"
                              value={this.state.co_broker_split +"%"}
                              // onChange={this.onChangeHandler}
                              disabled
                              style={(this.state.RLSFlag == 1) ? (this.state.co_broker_split ==undefined || this.state.co_broker_split=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />  
							<span >{this.state.copercentage_broker ? this.state.copercentage_broker+"%":''}</span>
                           {this.state.copercentage_broker?"":<small className="rightbedge">%</small>} 
                         
                           </div>
                          </div>
                         : ""}
														
															<div className="form-group">
																<label style={{margin:'7px'}}>Tenant Incentive</label>
																<input type="text" 
																className="form-control"
																id="tenant_incentive"
																value={tenant_incentive}
																onChange={this.handleChange}
																/>
																<small className="righttbedge" style={{margin:'7px'}}>months free</small>		
															</div>
															<div className="check-block" style={{display:'inline'}}>
																<div className="form-group ">
																	<div className="checkbox-inline">
																		<input type="checkbox" id="15" 
																		onChange={this.handleCheck}
																		checked={this.state.noFee}
																		/>
																		<label for="15">Advertise as No Fee</label>
																	</div>	
																</div>
															</div>
															
												</div>
												
																												
											
												<button
                                 type="submit"
                                className="btn btn-default"
                                  style={{ margin: "10px" }}
                                >
                                                    Submit 
                                  	    </button>
																												
												</form>		
										
										</div>
									</div>
								</div>
							</div>
							<div id="amenitiesEdit" className="modal fade" role="dialog" ref={this.setWrapperRef10}>
                        <div className="modal-dialog">

                            <div className="modal-content">
                            <div className="modal-header" >
									<h4 className="modal-title">Edit Amenities</h4>
                            <button type="button" id="buildingamenityClose" className="close" data-dismiss="modal" onClick={this.resetCheckbox} >&times;</button>
                             </div>
                            <div className="modal-body" >

							<div className="detail-box">
							<div className="container">
                                                   <form onSubmit={this.amenitySubmit}>
                                                    <div className="check-block">
                                                        <div
                                                            className="form-group"
																	onChange={this.handleChange}>
                                                   
                                                            {amenity_item}
																															
                                                        </div>
														</div>
			    								<button
                                                    type="submit"
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                              </button>
											<input type="hidden" id="UpdateBuildingId" value={bID}/>												
								</form>
						 </div>
                                         </div>

									</div>
								</div>
							</div>
						</div>	
						
						
							<div id="descriptionEdit" className="modal fade" >
                        <div className="modal-dialog ">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Description</h4>
                            <button type="button" id="descriptionEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
							<form onSubmit={this.descriptionSubmit} >
								
									<textarea 
									id="description"
									value={this.state.description}
									rows="4" cols="60"
									onChange={this.handleChange}
									/>
										
											  	
                                       <span className="inline-error-class">
                                            {" "}
                                                {description_error !== ""
                                                           ? description_error
                                                         : ""}
                                                        </span><br />
												<button
                                                    type="submit"
                                                    className="btn btn-default"
													style={{ margin: "10px" }}
													disabled={description_show.toString().trim()==''}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
								</div>
								<div id="dateEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Date</h4>
                            <button type="button" id="dateEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
														
							<form onSubmit={this.dateSubmit}>
									<DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleChangeDate}
                              dateFormat="MMMM d, yyyy"
                              timeCaption="time"
                              className="form-control"
															id="date_available" 
															value={ this.state.startDate=='' ? Moment(date_available).format('MM/DD/YYYY') :Moment(this.state.startDate).format('MM/DD/YYYY')}                        
                                                  />
												<button
                                                    type="submit"
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>
						  {/* <div className="modal fade" id="imageEdit">
                            <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit image</h4>
                                <button type="button" id="imageEditClose" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                     <form onSubmit={this.imageSubmit} encType="multipart/form-data">
                                    <div className="clearfix">
                                        <input type="file" onChange={this.fileChangedHandler} />
                                        </div>
                               
                                    <p className="text-center"><Button type="submit">Submit</Button></p>
                                    </form>
                                </div>
                            </div>
                            </div>
                        </div> */}

					<div id="furnishedEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Furnished</h4>
                            <button type="button" id="furnishedEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
						        	<form >
									<div className="form-group" id="switch">
                                       <Switch
                                        onChange={this.handleChangeStatus}
                                        checked={furnished}
                                        className="react-switch"
                                         id="normal-switch"
                                         />
                            <span style={{ marginLeft: "10px" }}>
                              Furnished
                            </span>
                          </div>
									</form>
										</div>
									</div>
								</div>
							</div>
							<div id="salesEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Sales Information</h4>
                            <button type="button" id="salesEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
										{/* <p><small>(please enter numbers only)</small></p> */}
											<form  onSubmit={this.salesSubmit}>
				CC/Maintenance<input type="text" className="form-control"  id="maintenance" value={maintenance} onChange={this.onHandleChange}/>
				<span className="inline-error-class" > {" "} {maintenance_error !== "" ? maintenance_error: ""}</span>
				Monthly Taxes<input type="text" className="form-control"  id="monthly_taxes" value={monthly_taxes} onChange={this.onHandleChange}/>
				<span className="inline-error-class"> {" "} {monthly_taxes_error !== "" ? monthly_taxes_error: ""}</span>
				Commission<input type="text" className="form-control"  id="commission" value={commission} onChange={this.onHandleChange}/>
				<span className="inline-error-class"> {" "} {commission_error !== "" ? commission_error: ""}</span>
				Tax Deduction<input type="text" className="form-control"  id="tax_deduction" value={tax_deduction} onChange={this.onHandleChange}/>
				<span className="inline-error-class"> {" "} {tax_deduction_error !== "" ? tax_deduction_error: ""}</span>
				Flip Tax<input type="text" className="form-control"  id="flip_tax" value={flip_tax} onChange={this.onHandleChange}/>
				<span className="inline-error-class"> {" "} {flip_tax_error !== "" ? flip_tax_error: ""}</span>
				Max Financing<input type="text" className="form-control"  id="max_financing" value={max_financing} onChange={this.onHandleChange}/>
				<span className="inline-error-class"> {" "} {max_financing_error !== "" ? max_financing_error: ""}</span>
				Number Of Shares<input type="text" className="form-control"  id="number_of_shares" value={number_of_shares} onChange={this.onHandleChange}/>
				<span className="inline-error-class"> {" "} {number_of_shares_error !== "" ? number_of_shares_error: ""}</span>
				% Of Common<input type="text" className="form-control"  id="percentofcommon" value={percentofcommon} onChange={this.onHandleChange}/>
				<span className="inline-error-class"> {" "} {percentofcommon_error !== "" ? percentofcommon_error: ""}</span>
												<button
                                                    type="submit"
                                                    disabled={!this.state.validation}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>

							<div className="modal fade" id="editVR">
                            <div className="modal-dialog modal-sm">

                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit VR Link</h4>
                            <button type="button" id="closeVr" className="close" data-dismiss="modal">&times;</button>
                                </div>
					            
	                              <div className="modal-body">
                                     <form onSubmit={this.vrSubmit} encType="multipart/form-data">
                                    <div className="clearfix" style={{marginBottom:"25px"}}>
																		<input
                                        type="text"
																				className="form-control"
																				id="kuula_vr_link"
                                      	value={kuula_vr_link}
										  onChange={this.onVrHandle}
										  onBlur={this.onVrHandleChange}
                                   /> <span className="inline-error-class">
																	 {" "}
																			 {	kuula_vr_link_error !== ""
																									? 	kuula_vr_link_error
																								: ""}
																						 </span>
                                        </div>
                              
                                    <p className="text-center"><Button type="submit" >Submit</Button></p>
                                    </form>
                                </div>
                           </div>
                            </div>
                        </div>
						<div className="modal fade" id="editVideo" style={{zIndex:'10000'}}>
                            <div className="modal-dialog modal-sm">

                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit Video Link</h4>
                            <button type="button" id="closeVr" className="close" data-dismiss="modal">&times;</button>
                                </div>
					            
	                              <div className="modal-body">
                                     <form onSubmit={this.videoSubmit} encType="multipart/form-data">
                                    <div className="clearfix" style={{marginBottom:"25px"}}>
																		<input
                                        type="text"
																				className="form-control"
																				id="video_link"
                                      	value={video_link}
										  onChange={this.onVideoHandle}
										  onBlur={this.onVideoHandleChange}
                                   /> <span className="inline-error-class">
																	 {" "}
										{	video_link_error !== ""
															? 	video_link_error
														: ""}
												</span>
                                        </div>
                              
                                    <p className="text-center"><Button type="submit" >Submit</Button></p>
                                    </form>
                                </div>
                           </div>
                            </div>
                        </div>			

										<div id="marketingEdit" className="modal fade" role="dialog" ref={this.setWrapperRef11}>
                        <div className="modal-dialog">

                            <div className="modal-content">
                            <div className="modal-header" >
									<h4 className="modal-title">Edit Marketing</h4>
                            <button type="button" id="marketingClose" className="close" data-dismiss="modal" onClick={this.resetCheckbox} >&times;</button>
                             </div>
                            <div className="modal-body" >

							<div className="detail-box">
							<div className="container">
                                                   <form onSubmit={this.marketingSubmit}>
                                                    <div className="check-block">
                                                        <div
                                                            className="form-group"
																	onChange={this.handleChange}>
                                                   
                                                            {marketingItem}
																															
                                                        </div>
														</div>
			    								<button
                                                    type="submit"
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                              </button>
											<input type="hidden" id="UpdateBuildingId" value={bID}/>												
								</form>
						 </div>
                                         </div>

									</div>
								</div>
							</div>
						</div>	
						<div className="modal fade" id="imageEdit" >
                            <div className="modal-dialog ">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit Image(s)</h4>
                                <button type="button" id="imageEditClose" onClick={this.updateImage} className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                     <form  encType="multipart/form-data">
                                    <div className="clearfix">
                                     <table className="table table-striped">
                                       <th>Image</th>
                                   
                                       <tbody>
									   {/* <ReactSortable swap items={thumbnail}  placeholder={placeholder}  onSort={this.onSort}  type="vertical" /> */}
										 <DragSortableList items={thumbnail}  placeholder={placeholder}  onSort={this.onSort} dropBackTransitionDuration={0.3} type="vertical" />
										 <tr>
										<td colSpan="3">
											{this.state.hideandshow!=1
										?	<i class="fa fa-plus" style={{cursor:'pointer'}} aria-hidden="true" onClick={this.openandhide}><p style={{fontFamily: 'MetropolisRegular', float: 'right', paddingLeft: '5px'}}>Add New Image</p> </i>
										:	<i class="fa fa-minus" style={{cursor:'pointer'}} aria-hidden="true" onClick={this.closeRow}> <p style={{fontFamily: 'MetropolisRegular', float: 'right', paddingLeft: '5px'}}>Add New Image</p></i>}
											</td>
										</tr>
										{this.state.hideandshow !=0 
										  ?
										<tr>
										<td colSpan="4"> <input type="file" onChange={this.fileHandler()} /></td>
										</tr>:''}
										<tr><td colSpan="4">
										<span className="inline-error-class">
                                            {" "}
                                                {this.state.mymsg !== ""
                                                           ? this.state.mymsg
                                                         : ""}
                                                        </span></td></tr>
                                       </tbody>
                                     </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            </div>
                        </div>
						<div id="instagramTileModal" className="modal fade" >
                        <div className="modal-dialog ">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Instagram Title Information</h4>
                            <button type="button" id="instagramTileClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
							<form onSubmit={this.instagramTileSubmit} >
								
									Text to show on first image: <input type="text" 
									id="instatilefirsttext"
									value={this.state.instatilefirsttext}
									rows="4" cols="60"
									onChange={this.handleChange}
									/> <span className="inline-error-class">
									{" "}
										{instatilefirsttext_error !== ""
												   ? instatilefirsttext_error
												 : ""}
												</span>
									<br /><br />
									Text to show on last image: <input type="text" 
									id="instatilelasttext"
									value={this.state.instatilelasttext}
									rows="4" cols="60"
									onChange={this.handleChange}
									/>
  	
                                       <span className="inline-error-class">
                                            {" "}
                                                {instatilelasttext_error !== ""
                                                           ? instatilelasttext_error
                                                         : ""}
                                                        </span><br /><br />
												<button
                                                    type="submit"
                                                    className="btn btn-default"
													style={{ margin: "10px" }}
													disabled={this.state.instatilelasttext=='' || this.state.instatilefirsttext==''}
                                                >
                                                    Download
                                  	    </button>
										  <br />
										  <span className="inline-error-class" style={{fontSize:'15px'}}>{this.state.instatileimage_error}</span>
									</form>
										</div>
									</div>
								</div>
								</div>

						<div id="emailtoclient" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Email to Client</h4>
                            <button type="button" id="dateEditClose" className="close" data-dismiss="modal" onClick={this.getAgentsClient}>&times;</button>
                                 </div>
                            <div className="modal-body" >
														
							<form>
								<div className="form-group">
								   <lable>Selected Client</lable>
									<div className="neighbor-filter list-filter" onClick={this.OpenList}> 
										<span id="ClientName">Selected Client</span> {this.state.selected_name} <span id="ClientCount"></span>
									</div>
										<div className="boxes neighborhoodDD clientL" id="clientList" style={{display:'none'}} ref={this.setWrapperRef12}>
										   
										    
											 {clientData}
											{/* <span><input type="checkbox" id="box-0" value=""/> <label for="box-0"></label></span>
											<span><input type="checkbox" id="box-1" value="Adem"/> <label for="box-1">Adem</label></span>
											<span><input type="checkbox" id="box-2" value="Carry"/> <label for="box-2">Carry</label></span>
											<span><input type="checkbox" id="box-3" value="Bolt"/> <label for="box-3">Bolt</label></span> */}
										</div>
							    </div>
								<div className="form-group">
								<lable>Subject</lable>
									<input type="text" id="mail_subject" className="form-control" value={this.state.mail_subject} onChange={this.handleclientmail} placeholder="Subject"/>
							    </div>
								<div className="form-group">
								<lable>Message</lable>
							    </div>
								<div id="divContent" class="form-group" contenteditable="true" style={{border:'solid 1px', padding:'8px'}}>
							<div id="contact_Email_id">
<p>Hello,</p>								
<p>{this.state.content_value}</p><br/>
<p>Please find the listing detail from below URL</p>
<p>{getBaseUrl()+'/client/listing/Details/'+this.state.listing_id}</p><br/>

{this.props.userType=='Manager'?<p>Thank you,<br/><br/> Josiah Hyatt <br/> Founder | Principal Real Estate Broker <br/> Skyward</p>:<p>Thank you,<br/><br/>{getAgentName()}<br/>
{aes.decrypt(getAgentEmail(), key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}<br/>
{aes.decrypt(getAgent_number(), key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}<br/> </p>}


							 


							</div>
					</div>

												<button
                                                    type="button"
                                                    className="btn btn-default"
													style={{ margin: "10px" }}
													disabled={!this.validateclientemail()}
													onClick={this.sendmailToClient}
                                                >
                                                    Send 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>

                </div>
                );
    }
}
export default ManagerListing;
