import React, { Component, Fragment , } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../../common/AdminHeader';
import AdminSideBar from '../../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken, postApiData, getApiData,getBaseUrl} from '../../../services/Api';
import {validatePhone} from '../../../services/validation';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isNullOrUndefined, isNull } from 'util';
import Moment from 'moment';

class AdminFileEdit extends Component {
    constructor(props){
        super(props);
        console.log("props",this.props)
        if(this.props.location.id!="" && this.props.location.id!=undefined){
				
            localStorage.setItem("File_id",this.props.location.id);
        }
        if(this.props.location.id==0){   // this is for Josia hayat id
        
            localStorage.setItem("File_id",this.props.location.id);
            }

   var id=	localStorage.getItem('File_id') 
        this.state={
           title:'',
           description:'',
           posted_on:'',
           startDate:'',
           id:id,
           linkingLoader:false
        }
    }
    componentWillMount(){
        this.getVideoDetailbyId();
        this.getTopic();
        this.getCategory();
       
      }

      getVideoDetailbyId=()=>{
          let where=[{
              url:'/admin/getFileDetailById'
          }]
          postApiData(where,{'id':this.state.id},true).then(res=>{
               console.log('res',res.data);
               this.setState({
                   title:res.data[0].title,
                   description:res.data[0].description,
                   category_id:res.data[0].category_id,
                   topic_id:res.data[0].topic_id,
                   posted_on:Moment(res.data[0].posted_on,"YYYY-MM-DD").format('MM/DD/YYYY'),
                   
               })
          }).catch(error=>{
console.log("e",error)
          })
      }

    getTopic=()=>{
        let where=[{
            url:'/admin/getMediaTopic'
        }]
        getApiData(where ,true).then(res=>{
          console.log('---res.data',res.data);
          this.setState({
              media_topic:res.data
          })
        }).catch(error=>{

        })
    }

    getCategory=()=>{
        let where=[{
            url:'/admin/getMediaCategory'
        }]
        getApiData(where ,true).then(res=>{
          console.log('---res.data',res.data);
          this.setState({
            media_category:res.data
        })
        }).catch(error=>{

        })
    }

    handleChange=(event)=>{
          if(event.target.id=="title" || event.target.id=="description"){
              this.setState({
                  [event.target.id]:event.target.value
              })
          }
          if(event.target.id=="_media_topic_" || event.target.id == "_media_category_"){
            this.setState({
               
            })
        }
    }

    handleChangeDate=(date)=> {
        this.setState({
          startDate: date,
          posted_on:date
          });
        }

        fileHandler =(e) => {
            console.log('---file',e.target.files[0])
            this.setState({
                    selectedFile:e.target.files[0],  
            })      
         }

         handleSubmit=(event)=>{
             event.preventDefault();
             console.log('file',this.state.selectedFile);
       
             if(document.getElementById("_media_topic_")){
                var media_topic=document.getElementById("_media_topic_").value;
                }
             if(document.getElementById("_media_category_")){
                var media_category=document.getElementById("_media_category_").value;
                }
                if(document.getElementById("_media_topic_").value=="" || document.getElementById("_media_category_").value==""){
                    if(document.getElementById("_media_topic_").value=="" && document.getElementById("_media_category_").value==""){
                     this.setState({
                     _media_topic_error:"Please select the value from dropDown",
                     _media_category_error:"Please select the value from dropDown"
                    })
                 }else if(document.getElementById("_media_topic_").value!="" ){
                     this.setState({
                         _media_topic_error:"",
                         _media_category_error:"Please select the value from dropDown"
                        })
                 }else if( document.getElementById("_media_category_").value!=""){
                     this.setState({
                         _media_topic_error:"Please select the value from dropDown",
                         _media_category_error:""
                        })
                 }
              } else{
             if(this.state.selectedFile!=undefined){
                this.setState({ linkingLoader:true})
             let Where=[{
                url : '/containers/media_file/upload'
            }];
            const formData = new FormData();
            formData.append('file',this.state.selectedFile);
            postApiData(Where,formData,true).then(response=>{
                console.log("es-----",response)
               
                    let where=[{
                        url:'/admin/editfileData'
                    }]
                    console.log("response_file_name",response.data.file_name)
                    var formData =new FormData();
                    formData.append('id',this.state.id)
                    formData.append('title',this.state.title)
                    formData.append('description',this.state.description)
                    formData.append('posted_on',this.state.posted_on)
                    formData.append('file',response.data.file_name)
                    formData.append('topic_id',media_topic)
                    formData.append('category_id',media_category)
                    postApiData(where,formData,true).then(res=>{
                        console.log("res.data",res.data.success);
                        if(res.data.success==true){
                            this.setState({ linkingLoader:true},()=>{
                                window.location.href=getBaseUrl()+'/admin/file/listing';
                                
                                this.setState({linkingLoader:false})
                            })
                        }
                    }).catch(error=>{

                    })
            })
        }else{
            this.setState({ linkingLoader:true})
            let where=[{
                url:'/admin/editfileData'
            }]
            var formData =new FormData();
            formData.append('id',this.state.id)
            formData.append('title',this.state.title)
            formData.append('description',this.state.description)
            formData.append('posted_on',this.state.posted_on)
            formData.append('topic_id',media_topic)
            formData.append('category_id',media_category)
            postApiData(where,formData,true).then(res=>{
                if(res.data.success==true){
                    this.setState({ linkingLoader:true},()=>{
                        window.location.href=getBaseUrl()+'/admin/file/listing';
                        
                        this.setState({linkingLoader:false})
                    })
                }
            }).catch(error=>{

            })
        }}
        }

        validation=()=>{
            const { title}=this.state;
            return(
                title.trim()!="" 
            )
        }
    render() {
        var media_topic;
        if(this.state.media_topic){
          media_topic = this.state.media_topic.map(item=>{
              if(item.id==this.state.topic_id){
            return <option value={item.id} selected>{item.topic}</option>
              }else{
                return <option value={item.id} >{item.topic}</option>
              }
          })
        }
        var media_category;
        if(this.state.media_category){
            media_category = this.state.media_category.map(item=>{
                if(item.id==this.state.category_id){
              return <option value={item.id} selected>{item.category}</option>
                }
              else{
              return <option value={item.id}>{item.category}</option>
              }
            })
          }
        return (
            <div >
                 {(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">File</h2>
                                     
                        <table className="table table-striped">
                            <thead><tr><th className="managerGridCol">Edit File</th></tr></thead>
                        </table>
                                
                                <form onSubmit={this.handleSubmit} style={{maxWidth:"70%", margin:"10px auto"}}>
                                {/* {this.state.successQuote?(<div className="alert alert-success"><strong>Address  Successfully Added</strong></div>):(null)}  */}
                                 
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label forhtml="name">Title*</label>
                                            <input type="text" className="form-control" id="title" onChange={this.handleChange} value={this.state.title} autoComplete="off"/>
                                            {/* <span className="inline-error-class"> {this.state.office_name_error !==''?this.state.office_name_error:''}</span> */}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label forhtml="Description">Description</label>
                                            <textarea  className="form-control" id="description" onChange={this.handleChange} value={this.state.description} autoComplete="off"/>
                                            {/* <span className="inline-error-class"> {this.state.mob_number_error !==''?this.state.mob_number_error:''}</span> */}
                                        </div>
                                        <div className="form-group col-md-6">
                                       <label>Media Topic</label>
                                        <select id="_media_topic_" className="form-control" style={{width:'200px'}} onChange={this.handleChange}>
                                         <option value="">select</option>
                                         {media_topic}
                                       </select>
                                       <span className="inline-error-class"> {this.state._media_topic_error !==''?this.state._media_topic_error:''}</span>
                                       </div>
                                       <div className="form-group col-md-6">
                                       <label>Media Category</label>
                                        <select id="_media_category_" className="form-control" style={{width:'200px'}} onChange={this.handleChange}>
                                         <option value="">select</option>
                                         {media_category}
                                       </select>
                                       <span className="inline-error-class"> {this.state._media_category_error !==''?this.state._media_category_error:''}</span>
                                       </div>
                                        <div className="form-group col-md-6">
                                            <label forhtml="Posted On">Posted On</label>
                                            <DatePicker
                                              selected={this.state.startDate}
                                            onChange={this.handleChangeDate}
                                             
                                              timeCaption="time"
                                              id="date_available"   
                                              className="form-control"     
                                              value={ this.state.startDate=='' ? this.state.posted_on :this.state.startDate}      
                                                  />
                                        </div>
                                        
                                        <div className="form-group col-md-6">
                                            <label  forhtml="File">File</label>
                                            <input type="file" onChange={this.fileHandler} value={this.state.audio_file}/>
                                            {/* <span className="inline-error-class"> {this.state.mob_number_error !==''?this.state.mob_number_error:''}</span> */}
                                        </div>
                                      </div>
                                        <div className="form-group">
                                        <button type="submit"  disabled={!this.validation()} className="btn btn-primary" >Submit</button>
                                     </div>
                                    </form>
                       
                            </div>
                    </div>
                 
                </div>
            </main>     
        </div>
        );
    }
}

export default AdminFileEdit;