import React from 'react';
import {Switch, Router, Route} from 'react-router-dom';
import './App.css';
import { createBrowserHistory } from "history";
import ClientLanding from './components/client/Landing';
import ClientAppLanding from './components/client/application/Detail';
import ClientSignUp from './components/client/SignUp';
import ClientLogin from './components/client/Login';
import ClientLogout from './components/client/Logout';
import AgentLogout from './components/agent/Logout';
import ClientDashboard from './components/client/Dashboard';
import ClientProfile from './components/client/Profile';
import ClientForgot from './components/client/Forgot';
import ClientVerified from './components/client/Verified';
import ClientChangePassword from './components/client/ChangePassword';
import ClientResetPwd from './components/client/ResetPassword';
import ClientOnBoarding from './components/client/OnBoarding';
import ClientNewApp from './components/client/NewApplication';
import ClientNewApp1 from './components/client/NewApplication1';
import ClientComponents from './components/client/Components';
import AgentNewApp from './components/agent/Application';
import AgentLanding from './components/agent/Login';
import AgentForgot from './components/agent/Forgot';
import AgentComponents from './components/agent/Components';
import AgentClient from './components/agent/Client';
import AgentAppeditor from './components/agent/Appeditor';
import ManagerAppeditor from './components/manager/Appeditor';
import webCam from './components/client/webcam';    
import plaidApi from './components/client/plaidApi';
import CheckoutForm from './components/client/stripe';
import stripenew from './components/client/stripenew';
import AgentClientDetail from './components/agent/Detail';
import ClientRentalform from './components/client/Rentalform';
import ManagerLanding from './components/manager/Landing';
import ManagerApplication from './components/manager/Application';
import ManagerClient from './components/manager/Client';
import ManagerAgent from './components/manager/Agent';
import ManagerLogout from './components/manager/Logout';
import ManagerTemplates from './components/manager/Templates';
import PageNotFound from './components/manager/NotFound';
import DragLink from './components/agent/Draglink';
import ClientClanding from './components/client/listing/Clanding';
import ClientBrowseList from './components/client/listing/BrowseList';
import ClientSaveListing from './components/client/listing/SaveListing';
import AgentAgentListing from './components/agent/listing/AgentListing';
import AgentListingFeed from './components/agent/listing/AgentListingFeed';
import AgentListingCreator from './components/agent/listing/AgentListingCreator';
import ManagerManagerListing from './components/manager/listing/ManagerListing';
import ManagerListingCreator from './components/manager/listing/ManagerListingCreator';
import ManagerListingFeed from './components/manager/listing/ManagerListingFeed';
import ManagerBuildings from './components/manager/listing/Buildings';
import Agentbuilding from './components/agent/listing/AgentBuilding';
import NotFound from './NotFound';
import DragAndDrop from './components/common/DragAndDrop';
import Makepayment from './components/client/Makepayment';
import OnePageMakepayment from './components/client/OnePageMakepayment';
import FrontendLanding from './components/frontend/Landing';
import PrivacyPolicy from './components/frontend/Privacypolicy';
import Terms from './components/frontend/terms';
import FrontendLocation from './components/frontend/Location';
import FrontendAgents from './components/frontend/Agents';
import FrontendAgentDetail from './components/frontend/AgentDetail';
import FrontendCareer from './components/frontend/Join';
import Dashboard from './components/admin/Dashboard';
import Listing from './components/admin/Listing';
import Edit from './components/admin/Edit';
import Agent from './components/admin/Agent';
import Company from './components/admin/Company';
import AdminLogin from './components/admin/Login';
import AdminLogout from './components/admin/Logout';
import AdminManager from './components/admin/Manager';
import AdminAddress from './components/admin/Address';
import AirConditionType from './components/admin/airconditiontype';
import BuildingType from './components/admin/buildindtypemaster';
import AdminBuildingEdit from './components/admin/buildingtypeEdit';
import AdminAirconditionEdit from './components/admin/AirConditionTypeEdit';
import AdminAddressEdit from './components/admin/AddressEdit';
import OwnerPayBroker from './components/admin/ownerpaysbrokermaster';
import OnwerPayBrokerEdit from './components/admin/ownerpaysbrokermasterEdit';
import CoBrokerAgreement from './components/admin/cobrokeagreementMaster';
import CoBrokerAgreementEdit from './components/admin/cobrokeagreementEdit';
import AdminMediaTopic from './components/admin/Media_topic';
import AdminMediaCategory from './components/admin/Media_Category';
import EmailVarifyPage from './components/client/EmailVerifyPage';

import AgentResources from './components/agent/AgentResource';
import ManagerResources from './components/manager/ManagerResource';
import ManagerListEdit from './components/manager/listing/ManagerListingEdit';
import AgentListEdit from './components/agent/listing/AgentListingEdit';
import Education from './components/agent/Education';
import EmailDomain from './components/admin/adddomain';

import ManagerBuild from './components/manager/listing/ManagerBuildingsAdd';
import ManagerBuildingAdd from './components/manager/listing/AddBuildings';

//listing creator for Edit
import AgentCreatorEdit from './components/agent/listing/CreatorEdit';
import DetailsFeed from './components/client/listing/Detailsfeed';
import ManagerCreatorEdit from './components/manager/listing/CreatorEdit'
//listing creator for Edit

// feed Creator Edit
import AgentFeedCreatorEdit from './components/agent/listing/FeedCreatorEdit';
import ManagerFeedCreatorEdit from './components/manager/listing/FeedCreatorEdit';
// feed Creator Edit

import ManagerShowings from './components/manager/Showings';
import AgentShowings from './components/agent/Showings';

import AgentBuildingAdd from './components/agent/listing/AddAgentBuilding';

import MapComponent from './components/mapcomponent/Map'

// pay deposit 
import Paydeposit from './components/client/paydeposit';
//pay deposit
import AgentProfile from './components/agent/AgentProfile';
import AgentEditProfile from './components/agent/AgentEditProfile';
import ClientDetails from './components/client/listing/Details';
import { isMobile } from 'react-device-detect';

// SalesCreator Edit 
import AgentSalesCreator from './components/agent/listing/SalesCreatorEdit';
import ManagerSalesCreator from './components/manager/listing/SalesCreatorEdit';


import manualRentalTraining from './components/manual/RentalTraining';
import manualTechno from './components/manual/Techno';
import manualWelcome from './components/manual/Welcome';
import manualBuyers from './components/manual/Buyers';
import manualSellers from './components/manual/Sellers';

// SalesCreator Edit

//Media Video 
import AdminVideo from './components/admin/Video';
import AdminVideoList from './components/admin/VideoListing';
import AdminVideoEdit from './components/admin/VideoEdit';
//Media video

//Media audio 
import AdminAudio from './components/admin/audio/Audio';
import AdminAudioList from './components/admin/audio/AudioListing';
import AdminAudioEdit from './components/admin/audio/AudioEdit';
//Media audio

// //Media file 
import AdminFile from './components/admin/file/File';
import AdminFileList from './components/admin/file/FileListing';
import AdminFileEdit from './components/admin/file/FileEdit';

import ManagerDocument from './components/manager/Document';
import ManagerDocument1 from './components/manager/Document1';
import AgentDocument from './components/agent/Document';
import AgentDocument1 from './components/agent/Document1';
import AdminManagerMarketing from './components/admin/ManagerMarketing';

import FrontendJoin from './components/frontend/Join';

import manualGlossary from './components/manual/Glossary';

import ClientUrbanform from './components/client/Urbanform';
import AgentChatbox from './components/agent/Chatbox';

import ManagerMarketinglist from './components/manager/listing/Marketinglist';
import AgentMarketinglist from './components/agent/listing/Marketinglist';
import AgentArchivedApp from './components/agent/ArchivedApplication';

import AgentLogin from './components/agent/Login';
import AgentSignup from './components/agent/Signup';
import AgentResetPwd from './components/agent/ResetPassword';
import AgentEmailVarifyPage from './components/agent/EmailVerifyPage';
import Vr from './components/frontend/Vr';
import FrontendPhilanthropy from './components/frontend/Philanthropy';
import FrontendTour360 from './components/frontend/Tour360';
import ManagerEducation from './components/manager/Education';


import ManageDocuments from './components/admin/managedocuments';
import ManageDocumentsEdit from './components/admin/managedocumentsEdit';
import ModifyPdf  from './components/modifyPdf/ModifyPdf';
import OneApplication  from './components/manager/OneApplication';
// //Media file
const history = createBrowserHistory();

const App = () =>
<Router history={history}>
    <div>
    <Switch>
    <Route exact path="/" component={FrontendLanding}/>
    <Route exact path="/client/signup" component={ClientSignUp}/>
    <Route exact path="/modifypdf/:path" component={ModifyPdf}/>
    <Route exact path="/client/login" component={ClientLogin}/>
    <Route exact path="/client/logout" component={ClientLogout}/>
    <Route exact path="/client/dashboard" component={ClientDashboard}/>
    <Route exact path="/client/profile" component={ClientProfile}/>
    <Route exact path="/client/forgot" component={ClientForgot}/>
    <Route exact path="/client/verified" component={ClientVerified}/>
    <Route exact path="/client/change-password" component={ClientChangePassword}/>
    <Route exact path="/client/reset-password/:access_token" component={ClientResetPwd}/>
    <Route exact path="/agent/reset-password/:access_token" component={AgentResetPwd}/>
    <Route exact path="/client/onboarding" component={ClientOnBoarding}/>
    <Route exact path="/client/application" component={ClientNewApp}/>
    <Route exact path="/client/application1" component={ClientNewApp1}/>
    <Route exact path="/client/application/:id" component={ClientAppLanding}/>
    <Route exact path="/client/components" component={ClientComponents}/>
    <Route exact path="/client/application/edit/:id" component={ClientNewApp}/>
    <Route exact path="/agent/application" component={AgentNewApp}/>
    <Route exact path="/agent/landing" component={AgentLanding}/>
    <Route exact path="/agent/logout" component={AgentLogout}/>
    <Route exact path="/agent/client" component={AgentClient}/>
    <Route exact path="/agent/components" component={AgentComponents}/>
    <Route exact path="/agent/Appeditor/:app_id" component={AgentAppeditor}/>
    <Route exact path="/client/webcam" component={webCam}/>
    <Route exact path="/client/plaid" component={plaidApi}/>
    <Route exact path="/client/stripe" component={CheckoutForm}/>
    <Route exact path="/client/stripenew" component={stripenew}/>
    <Route exact path="/agent/client/details/:id" component={AgentClientDetail}/>
    <Route exact path="/client/rentalform" component={ClientRentalform}/>    
    <Route exact path="/manager/landing" component={ManagerLanding}/>
    <Route exact path="/manager/application" component={ManagerApplication}/>
    <Route exact path="/manager/client" component={ManagerClient}/>
    <Route exact path="/manager/agent" component={ManagerAgent}/>
    <Route exact path="/manager/logout" component={ManagerLogout}/>
    <Route exact path="/manager/templates" component={ManagerTemplates}/>
    <Route exact path="/manager/notfound" component={PageNotFound}/>
    <Route exact path="/agent/draglink" component={DragLink}/>
    {/* <Route exact path="/client/listing/clanding" component={ClientClanding}/> */}
    <Route exact path="/client/listing/browselist" component={ClientBrowseList}/>
    <Route exact path="/client/listing/savelisting" component={ClientSaveListing}/>
    <Route exact path="/agent/listing/agentlisting" component={AgentAgentListing}/>
    <Route exact path="/agent/listing/listingfeed" component={AgentListingFeed}/>
    <Route exact path="/agent/listing/listingcreator" component={AgentListingCreator}/>
    <Route exact path="/manager/listing/managerlisting" component={ManagerManagerListing}/>
    <Route exact path="/manager/listing/listingfeed" component={ManagerListingFeed}/>
    <Route exact path="/manager/listing/ListingCreator" component={ManagerListingCreator}/>
    <Route exact path="/manager/listing/buildings" component={ManagerBuildings}/>
    <Route exact path="/agent/listing/buildings" component={Agentbuilding}/> 
      <Route exact path="/client/makepayment/:id/" component={Makepayment}/>
   
     <Route exact path="/frontend/landing" component={FrontendLanding}/>
    <Route exact path="/frontend/location" component={FrontendLocation}/> 
    <Route exact path="/frontend/agents" component={FrontendAgents}/>
    <Route exact path="/frontend/agentdetail/:id" component={FrontendAgentDetail}/>
    <Route exact path="/frontend/career" component={FrontendCareer}/>
    <Route exact path="/frontend/privacy-policy" component={PrivacyPolicy}/>
    <Route exact path="/frontend/terms-and-condition" component={Terms}/>
    <Route exact path="/manager/Appeditor/:app_id" component={ManagerAppeditor}/>

    <Route exact path="/DragAndDrop" component={DragAndDrop}/>
    <Route exact path="/admin/dashboard" component={Dashboard}/>
    <Route exact path="/admin/listing" component={Listing}/>
    <Route exact path="/admin/edit" component={Edit}/>
    <Route exact path="/admin/agent" component={Agent}/>
    <Route exact path="/admin/login" component={AdminLogin}/>
    <Route exact path="/admin/logout" component={AdminLogout}/>
    <Route exact path="/admin/manager" component={AdminManager}/>
    <Route exact path="/admin/address" component={AdminAddress}/>
    <Route exact path="/admin/airconditiontype" component={AirConditionType}/>
    <Route exact path="/admin/airconditiontypeedit/:id" component={AdminAirconditionEdit}/>
    <Route exact path="/admin/buildingedit/:id" component={AdminBuildingEdit}/>
    <Route exact path="/admin/buildingtype" component={BuildingType}/>
    <Route exact path="/admin/address/edit/:id" component={AdminAddressEdit}/>
    <Route exact path="/admin/ownerpaybroker" component={OwnerPayBroker}/>
    <Route exact path="/admin/ownerpaybrokeredit/:id" component={OnwerPayBrokerEdit}/>
    <Route exact path="/admin/cobrokeagreement" component={CoBrokerAgreement}/>
    <Route exact path="/admin/cobrokeagreementedit/:id" component={CoBrokerAgreementEdit}/>
    <Route exact path="/admin/mediatopic" component={AdminMediaTopic}/>
    <Route exact path="/admin/mediacategory" component={AdminMediaCategory}/>
    <Route exact path="/admin/adddomians" component={EmailDomain}/>

    <Route exact path="/client/account-verification/:acc_token" component={EmailVarifyPage}/>
   
      <Route exact path="/agent/resources" component={AgentResources}/>
      <Route exact path="/manager/resources" component={ManagerResources}/>

		<Route exact path="/manager/build/:id/:listing_id/:addr" component={ManagerBuild}/>
      <Route exact path="/manager/buildings/add" component={ManagerBuildingAdd}/>
      <Route exact path="/manager/build/:id/:listing_id" component={ManagerBuild}/>
       <Route exact path="/manager/build/:id" component={ManagerBuild}/>
		
		

      <Route exact path="/manager/build" component={ManagerBuild}/>

       <Route exact path="/agent/building/add/:id/:listing_id/:addr" component={AgentBuildingAdd}/>
      <Route exact path="/agent/building/add/:id/:listing_id" component={AgentBuildingAdd}/>
      <Route exact path="/agent/building/add/:id" component={AgentBuildingAdd}/>
      
      <Route exact path="/agent/building/add" component={AgentBuildingAdd}/>

        <Route exact path="/manager/showings" component={ManagerShowings}/>
        <Route exact path="/agent/showings" component={AgentShowings}/>

    <Route exact path="/managerlist/edit" component={ManagerListEdit}/>
    <Route exact path ="/agent/list/edit" component={AgentListEdit}/>
    {/* Creator Edit */}
    <Route exact path="/agent/creator/edit/:buildingid/:listingid/:unitnumber" component={AgentCreatorEdit}/>
    <Route exact path="/agent/creator/edit/:edit" component={AgentCreatorEdit}/>
    <Route exact path="/listing/details/:id" component={DetailsFeed}/>
     <Route exact path="/manager/creator/edit/:buildingid/:listingid/:unitnumber" component={ManagerCreatorEdit}/>
    <Route exact path="/manager/creator/edit/:edit" component={ManagerCreatorEdit}/>


    <Route exact path="/admin/manage-documents" component={ManageDocuments}/>
    <Route exact path="/admin/manage-documents/:id" component={ManageDocumentsEdit}/>
   
    {/* Creator Edit */}
    {/* Feed Creator Edit */}
    <Route exact path="/agent/feed/edit" component={AgentFeedCreatorEdit}/>
    <Route exact path="/agent/feed/edit/:listingid" component={AgentFeedCreatorEdit}/>
    <Route exact path="/manager/feed/edit" component={ManagerFeedCreatorEdit}/>
    <Route exact path="/manager/feed/edit/:listingid" component={ManagerFeedCreatorEdit}/>
    {/* Feed Creator Edit */}

    {/* Pay Deposit */}
    <Route exact path="/client/paydeposit" component={Paydeposit}/>
    {/* Pay Deposit */}
    <Route exact path="/agent/agentprofile" component={AgentProfile}/>
    <Route exact path="/agent/agenteditprofile" component={AgentEditProfile}/>
    <Route exact path="/admin/Company" component={Company}/>

  
    <Route exact path="/client/listing/Details/:id" component={ClientDetails}/>
    <Route exact path="/agent/sales/creator/edit/:edit" component={AgentSalesCreator}/>
    <Route exact path="/manager/sales/creator/edit/:edit" component={ManagerSalesCreator}/>

    <Route exact path="/manual/RentalTraining" component={manualRentalTraining}/>
    <Route exact path="/manual/Techno" component={manualTechno}/> 
    <Route exact path="/manual/Welcome" component={manualWelcome}/> 
    <Route exact path="/manual/Glossary" component={manualGlossary}/>
    <Route exact path="/manual/Buyers" component={manualBuyers}/>
    <Route exact path="/manual/Sellers" component={manualSellers}/>
    <Route exact path="/agent/education" component={Education}/>
    <Route exact path="/manager/education" component={ManagerEducation}/>
    
    {/* AdminVideo */}
    <Route exact path="/admin/video" component={AdminVideo}/>
    <Route exact path="/admin/video/listing" component={AdminVideoList}/>
    <Route exact path="/admin/video/edit" component={AdminVideoEdit}/>
    {/* AdminVideo */}
     {/* AdminAudio */}
     <Route exact path="/admin/audio" component={AdminAudio}/>
    <Route exact path="/admin/audio/listing" component={AdminAudioList}/>
    <Route exact path="/admin/audio/edit" component={AdminAudioEdit}/>
    {/* AdminAudio */}
     {/* AdminFile */}
     <Route exact path="/admin/file" component={AdminFile}/>
    <Route exact path="/admin/file/listing" component={AdminFileList}/>
    <Route exact path="/admin/file/edit" component={AdminFileEdit}/>
    {/* AdminFile */}
    <Route exact path= "/map" component={MapComponent}/>
    <Route exact path="/manager/document" component={ManagerDocument}/>
    <Route exact path="/manager/document/:id" component={ManagerDocument1}/>
    <Route exact path="/agent/document" component={AgentDocument}/>
    <Route exact path="/agent/document/:id" component={AgentDocument1}/>

    <Route exact path="/admin/managermarketing" component={AdminManagerMarketing}/>

    <Route exact path="/frontend/join" component={FrontendCareer}/>
    <Route exact path="/client/Urbanform" component={ClientUrbanform}/>
    <Route exact path="/manager/listing/Marketinglist" component={ManagerMarketinglist}/>
    <Route exact path="/agent/listing/Marketinglist" component={AgentMarketinglist}/>
    <Route exact path="/agent/Chatbox" component={AgentChatbox}/>
    <Route exact path="/agent/archiveapplication" component={AgentArchivedApp}/>
    <Route exact path="/agent/login" component={AgentLogin}/>
    <Route exact path="/agent/signup" component={AgentSignup}/>
    <Route exact path="/vr" component={Vr}/>
    <Route exact path="/agent/forgot" component={AgentForgot}/>
    <Route exact path="/agent/account-verification/:acc_token" component={AgentEmailVarifyPage}/>
    <Route exact path="/client/onepagemakepayment/:id/" component={OnePageMakepayment}/>
    <Route exact path="/frontend/Philanthropy" component={FrontendPhilanthropy}/>
    <Route exact path="/frontend/Tour360" component={FrontendTour360}/>
    <Route exact path="/manager/oneapplication" component={OneApplication}/>
    <Route path="/*" component={NotFound} />
    
  
   
    </Switch>
    </div>
</Router>

export default App;
