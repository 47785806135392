import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken, postApiData, getApiData} from '../../services/Api';
import {validatePhone} from '../../services/validation';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import axios from 'axios';

const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_MAP_API_KEY+"&libraries=geometry,drawing,places",
        // googleMapURL: "http://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCQ01ecqDWTYQnMlfK-hMJQ6_twhg8a3dg",
        loadingElement: <div style={{ height: `500%` }} />,
        containerElement: <div className="mapdiv" id="mapdiv" style={{ height: `200px`, width : `350px`}}/>,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentWillMount() {
            const refs = {}

            this.setState({
                position: null,
                onMarkerMounted: ref => {
                    refs.marker = ref;
                },

                onPositionChanged: () => {
                    var position = refs.marker.getPosition()
                    var lat = position.lat()
                    var lng = position.lng()
                    document.getElementById("newlat").value=lat.toString();
                   document.getElementById("newlng").value=lng.toString();
                    
                }
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap defaultZoom={15} defaultCenter={{ lat: -1.2884, lng:36.8233}}>
        {props.isMarkerShown && <Marker position={{ lat: -1.2884, lng:36.8233}}
         draggable={false} ref={props.onMarkerMounted} onPositionChanged={props.onPositionChanged} />}
    </GoogleMap>
    )
    
    var latitude ='';
    var longitude =  '';
class AdminAddress extends Component {
    constructor(){
        super();
        this.state={
            routeflag:true,
            addressList:[],
            //Form Fields States
            name:'',
            address_line_1:'',
            address_line_2:'',
            mob_number:'',
            timings:'',
            successQuote:false,
            AddAddressPanel: false,

            //Error
            office_name_error:'',
            mob_number_error:'',
            address_line_1_error:'',
            timings_error:'',
            isMarkerShown: false,
            position: '',
            lat:"",
            lng:"",
            deleteMsg:''
        }
    }

    componentWillMount =()=>{
        this.getAddressList();
    }

    handleChange=(e)=>{
        const {name, mob_number, address_line_1, address_line_2, timings } = this.state;
        switch(e.target.id){
            case 'name':
                if(e.target.value == ''){ this.setState({ office_name_error: 'Field cannot be empty'})  
                }else{ this.setState({ office_name_error: ''}) }
            break;
            case 'mob_number': 
                this.formValidate(e.target.id, e.target.value);
            break;
            case 'address_line_1':
                if(e.target.value == ''){ this.setState({ address_line_1_error: 'Field cannot be empty'})  
                }else {  this.setState({ address_line_1_error: ''})   }
            break;
            case 'timings':
                if(e.target.value == ''){ this.setState({ timings_error: 'Field cannot be empty'})  
                }else{  this.setState({ timings_error: ''})    }
            break;
        }
        this.setState({ [e.target.id] : e.target.value}, ()=>{
            console.log('mob_number', this.state.mob_number);
        })
    }

    formValidate(key,value){
        switch(key){
            case 'mob_number':
            this.validateEmpPhoneNumber(value);
            break;
            }
        }
    validateEmpPhoneNumber(value){
        let res=validatePhone(value);
                    if(res.status==false){
                        this.setState({mob_number_error: 'Phone number is not valid.'});
                        document.getElementById('mob_number').value=res.value;
                    }else{
                        this.setState({mob_number_error: ''});
                        document.getElementById('mob_number').value=res.value;
                    }
                return res.status;
        } 

    //Handle Insertion of Address information in DB
    handleAddressSubmit=(e)=>{
     

        const {name, mob_number, address_line_1, address_line_2, timings } = this.state;
        e.preventDefault();
        this.addAddressDetail(name, mob_number, address_line_1, address_line_2, timings ,latitude,longitude )
    }
    async addAddressDetail(name, mob_number, address_line_1, address_line_2, timings, latitude, longitude) {
        let where = [{
                url: '/admin/addAddressDetail/'
            }];
            postApiData(where, {"name": name,"mob_number": mob_number, "address_line_1":address_line_1, 'address_line_2': address_line_2, 'timings': timings, 'latitude':latitude, 'longitude':longitude},true).then(res => {
                        this.setState({ 'pageLoader':true},()=>{
                            this.setState({'pageLoader':false, "successQuote": true, name:'', address_line_1:'', address_line_2:'', mob_number:'', timings:'' });
                        })  
        }).catch(error => {
           this.setState({myerrormsg: error.response});
        });
    }

    validateForm() {
        return (this.state.mob_number.trim().length != ''  && this.state.name.trim().length != '' && this.state.address_line_1.trim().length !='' && this.state.timings.trim().length != '' && this.state.mob_number_error=='') 
    }

    backToAddressPanel=()=>{
        this.getAddressList()
        this.setState({
            pageLoader: true
        },()=>{
            this.setState({  pageLoader: false,  AddAddressPanel: !this.state.AddAddressPanel, successQuote :false,  })
        })
    }

    //Get Address list from DB
    getAddressList=()=> {
        let applicationList = [{
            url: "/admin/addressList",
        }];
        getApiData(applicationList,true).then(res => {
            this.setState({'addressList': res.data,'pageLoader':true},()=>{
                    this.setState({'pageLoader':false});
            })
            }).catch(error => {
            if(error.response){
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });
    }

    //Google map Functionality 

    getPosition(position){
        this.setState(position)
      }
     handleClick(event){
         var address=document.getElementById('address_line_1').value;
         var apiBaseUrl = "https://maps.googleapis.com/maps/api/geocode/json?address="+address+"&key="+process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        // var apiBaseUrl="http://localhost/myNode/phpapis/loc.php";
        if(address!=""){
            this.setState({
                address_line_1:address,
                address_line_1_error:""
            })
        }else{
            this.setState({
                address_line_1_error:"Enter your address."
            })
        }
         var self = this;
         var payload={
         
          
     }
         
     let currentComponent = this;
     axios.get(apiBaseUrl, payload).then(function (response,map) {
     
          currentComponent.setState({info:response.data.results})
    
            //this.prop.center:{lat:26.84,lng:80.94}
            //info.map(instlist=>instlist.geometry.location.lat)
          latitude =response.data.results[0].geometry.location.lat;
          longitude=response.data.results[0].geometry.location.lng;
            
            var mapval=new window.google.maps.Map(document.getElementById('mapdiv'), { 
            });
           
            var bounds  = new window.google.maps.LatLngBounds();
            var loc = new window.google.maps.LatLng(response.data.results[0].geometry.location.lat, response.data.results[0].geometry.location.lng);
            bounds.extend(loc);   
             var marker=new window.google.maps.Marker({
                map: mapval,
                position: response.data.results[0].geometry.location,
                draggable: true,
                animation: window.google.maps.Animation.DROP
                }); 
                marker.setMap(mapval);      
                mapval.fitBounds(bounds);       
                mapval.panToBounds(bounds); 
                window.google.maps.event.addListenerOnce(mapval, 'bounds_changed', function() {
                    if (this.getZoom()){
                        this.setZoom(16);
                    }
            });
           
                
                window.google.maps.event.addListener(marker, 'dragend', function() 
                {
                    var position =marker.getPosition()
                        var lat = position.lat()
                        var lng = position.lng()
                      latitude=lat.toString();
                       longitude=lng.toString();
                });
                   mapval.setZoom(1); 
                   var position =marker.getPosition()
                        var lat = position.lat()
                        var lng = position.lng()
                        latitude =lat.toString();
                       longitude=lng.toString();   
         })
     .catch(function (error) {
      console.log(error);
      });
     } 

     addressDelete =(id)=>{
        this.setState({'pageLoader':true},()=>{
            
            let where = [{
                url: "/admin/address/delete",
            }];
            postApiData(where,{'id':id},true).then(res => {
                this.setState({'deleteMsg':res.data.message},()=>{
                    this.getAddressList();
                })
            }).catch(error => {
                    if(error.res){
                        this.setState({myerrormsg: error.res.data});
                    }
                });
            });
     }
     

    render() {
        const { name, address_line_1, address_line_2, mob_number, timings, AddAddressPanel} = this.state;
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }

         var listItems = this.state.addressList.map((item, index)=>{
            return <tr  >
                    <td id={item.id}>{item.name}</td>
                    <td id={item.id}>{item.mob_number}</td>
                    <td id={item.id}>{item.address_line_1}</td>
                    <td id={item.id}>{item.address_line_2}</td>
                    <td id={item.id}>{item.timings}</td>
 
                    <td > <div className="pull-left"><button className="btn btn-default  "onClick={()=>this.addressDelete(item.id)}>Delete</button> </div></td>
                    <td>  <div className="pull-right"><Link className="btn btn-default " to={`/admin/address/edit/${item.id}`}>Edit</Link> </div>
                    </td>
                      
                   
                </tr>
        });

        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">Address</h2>
               
                        <button onClick={this.backToAddressPanel} className="btn btn-default pull-right ">{!AddAddressPanel ? "Add Address" : "Back"}</button>
                      
                        {(this.state.deleteMsg) ?(<div className="alert alert-success" style={{marginLeft:'200px', marginRight:'300px'}}><strong>{this.state.deleteMsg}</strong></div>):(null)}  <div className="panel-body table-responsive">
                        {!AddAddressPanel ? 
                            <table className="table table-striped address-table">
                            
                                <thead>
                                    <tr>
                                        <th className="managerGridCol">Office name</th>
                                        <th className="managerGridCol">Mobile</th>  
                                        <th className="managerGridCol">Address 1</th> 
                                        <th className="managerGridCol">Address 2</th> 
                                        <th className="managerGridCol">Timings</th> 
                                        <th className="managerGridCol text-center" colSpan="2">Action</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {listItems}
                                </tbody>
                            </table>
                        
                        :
                        <Fragment>
                        <table className="table table-striped">
                            <thead><tr><th className="managerGridCol">Add Address</th></tr></thead>
                        </table>
                                
                                <form onSubmit={this.handleAddressSubmit} style={{maxWidth:"70%", margin:"10px auto"}}>
                                {this.state.successQuote?(<div className="alert alert-success"><strong>Address  Successfully Added</strong></div>):(null)} 
                                 
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label forhtml="name">Office Name</label>
                                            <input type="text" className="form-control" id="name" onChange={this.handleChange} value={name} placeholder="Office Name" autoComplete="off"/>
                                            <span className="inline-error-class"> {this.state.office_name_error !==''?this.state.office_name_error:''}</span>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label forhtml="mob_number">Mobile Number</label>
                                            <input type="text"  className="form-control" id="mob_number" onChange={this.handleChange} value={mob_number} maxLength='12' placeholder="XXX-XXX-XXXX" autoComplete="off"/>
                                            <span className="inline-error-class"> {this.state.mob_number_error !==''?this.state.mob_number_error:''}</span>
                                        </div>
                                        </div>
                                        <div className="form-group">
                                            <label forhtml="address_line_1">Address</label>
                                            {/* <input type="text" className="form-control" id="address_line_1" onChange={this.handleChange} value={address_line_1} placeholder="280 West 115th Street, 1F"/> */}
                                            <input type="text" className="form-control" onBlur={(event) => this.handleClick(event,this._map)} style={{with:'400px'}} name="address" id="address_line_1" placeholder="280 West 115th Street, 1F" autoComplete="off"/>
                                            <span className="inline-error-class"> {this.state.address_line_1_error !==''?this.state.address_line_1_error:''}</span>
                                        </div>
                                        <div className="form-group">
                                            <label for="address_line_2">Address 2</label>
                                            <input type="text" className="form-control" id="address_line_2" onChange={this.handleChange} value={address_line_2} placeholder="New York, NY 10026" autoComplete="off"/>
                                        </div>
                                        <div className="form-group">
                                            <label for="timings">Timings</label>
                                            <input type="text" className="form-control" id="timings" onChange={this.handleChange} value={timings} placeholder="Mon-Thurs 10am-6pm  Fri-Sat 10am-8pm" autoComplete="off"/>
                                            <span className="inline-error-class"> {this.state.timings_error !==''?this.state.timings_error:''}</span>
                                        </div>
                                      
                                       
{/*                                      
                                          <div className="form-group " style={{float:'left'}} >
                                            <label for="timings">Latitude</label>
                                              <input type="text" className="form-control" style={{width:'200px'}} name="newlat" id="newlat" placeholder="latitude" readOnly/>
                                              <label for="timings">Longitude</label>
                                               <input type="text" className="form-control" style={{width:'200px'}} name="newlng" id="newlng" placeholder="longitude" readOnly/>
                                        </div> */}
                                        <div className="form-group "   >
                                        <MyMapComponent isMarkerShown={true} getPosition={this.getPosition} />
                                        </div>
                                     
                               
                                        <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={!this.validateForm()}>Submit</button>
                                     </div>
                                       
                             
                                    </form>
                        </Fragment>
                        }
                            </div>
                    </div>
                 
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default AdminAddress;