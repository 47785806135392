import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/ManagerHeader';
import Sidebar from '../common/ManagerSidebar';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";
import {getApiData,getBaseUrl,getAgentId,getManagerToken} from '../../services/Api';
import Modal from 'react-responsive-modal';
import AddFolder from '../common/AddFolder';
import EditFolder from '../common/EditFolder';

class ManagerDocument extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error_msg:'',
			showLoader:true,
			folder_list:[],
			success_msg:'',
			open: false,
			editOpen:false,
			listingData:{},
			editable:(!getManagerToken())?false:true
		};
		
}

 
  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false },()=>{
		this.getFolderList();
	});
  };

  onOpenEdit = param=>(e) => {
    this.setState({ 
		listingData:param[0],
		editOpen: true });
  };
 
  onCloseEdit = () => {
    this.setState({ editOpen: false,listingData:{}},()=>{
		this.getFolderList();
	});
  };


componentDidMount(){
	this.getFolderList();
}

getFolderList=()=>{
	let where=[{
		url:'/getFolderList'
	}]
	getApiData(where ,true).then(res=>{
	  this.setState({
		  folder_list:res.data,
		  error_msg:'',
		  showLoader:false,
	  })
	}).catch(error=>{
		this.setState({
			showLoader:false,
			error_msg:'Unable to load files'
		})
	})
}

deleteFolder=param=>event=>{
	let where=[{
		url:'/remove',
		where:{
			'id':param[0]
		}
	}]
	getApiData(where ,true).then(res=>{
	  this.setState({
		success_msg:'Record deleted'
	  })
	  this.getFolderList();
	}).catch(error=>{
		this.setState({
			showLoader:false,
			error_msg:'Unable to load files',
			success_msg:''
		})
	})
}


render() {
	const { open } = this.state;
	let  folder_list =(!this.state.showLoader && this.state.folder_list.length==0)?<tr>
	<td colSpan="4">No record Found
	</td>
</tr>:'';
	if(this.state.folder_list.length>0){
		folder_list=this.state.folder_list.map((item,index)=>{
			return <tr>
			<td>{index+1}</td>
			<td><Link to={'/manager/document/'+item.id} params={{ superClassName: item.name }}><i class="fa fa-folder"></i> {item.name}</Link> </td>											
			<td className="action-view text-center">
				<Link to onClick={this.deleteFolder([item.id])}><i class="fa fa-trash-o"></i></Link>
				{(this.state.editable)?<Link to onClick={this.onOpenEdit([item])}><i class="fa fa-pencil-square-o"></i></Link>:''}
			</td>
		</tr>
		});
	}
    return (
                <div>
                    <Header />
                    <main>
					<Sidebar />
					<div className="content">

						<div className="work-section">
						<div className="dashboard">

								<h2>Manager/<strong>Office Documents</strong></h2>
								{(this.state.error_msg!='')?<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>:''}
								{(this.state.success_msg !== '') ?<div className="alert alert-success"> {this.state.success_msg !==''?this.state.success_msg:''} </div>:''}
								{(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''} 
							
								<div className=" mb-2 float-right"  data-toggle="modal" onClick={this.onOpenModal}>
								{(this.state.editable)?<button className="btn btn-default" type="button">+ Add Folder</button>:''}
									</div>
									
								<div className="panel-body table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
												<th width="60">#</th>
												<th> Folder Name</th>											
												<th width="200" className="text-center">Action</th>
											</tr>
										</thead>
										<tbody>
											{folder_list}
										</tbody>

									</table>
								</div>	
								{/*-- Start Modal */}
								<Modal open={open} onClose={this.onCloseModal} classNames="modal-sm">
									<AddFolder onClose={this.onCloseModal}/>
									</Modal>
								{/*-- End Modal */}

								{/*-- edit Modal */}
								<Modal open={this.state.editOpen} onCloseEdit={this.onCloseEdit} classNames="modal-sm">
									<EditFolder listingData={this.state.listingData} onClose={this.onCloseEdit}/>
									</Modal>
								{/*-- End Modal */}
							</div>
						</div>
					</div>
                    </main>     
                </div>

                );
    }
}
export default ManagerDocument;
