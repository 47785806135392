import React from 'react';
import { Link } from 'react-router-dom';
import { getManagerToken, getApiData, postApiData } from '../../services/Api';

class EditAgents extends React.Component {
    constructor(props) {
		super(props);
		console.log(props.list);
		//props.listData[0])?props.listData[0]
		this.state={
			agentList:[]
			,agent_name:''
			,agent_id:''
			,noRow:true
			,msg:''
			,message:''
			,current_agent_id:props.list.agent_id
			,current_appId:props.list.appId
			,current_clientID:props.list.clientId
			,linked_application:props.list.linkedApplicationCount
			,current_address:props.list.address
			,current_unit:props.list.unit
		}
	}

	componentDidMount(){
		this.LinkedApplicationDetails(this.state.current_appId)
	}

	LinkedApplicationDetails(appId){
		let clientList = [{
			url: "/getLinkedApplication",
		}];
		
		let data={'appId':appId};
        postApiData(clientList,data,true).then(res => {
			console.log("res",res);
			if(res.data.length>0){
				this.setState({
					linked_application:res.data.length
					,message:'Agent for a linked application cannot be changed'
				})
			}else{
				this.setState({
					linked_application:res.data.length
					,message:''
				})
				this.agentOpt();
			}
        }).catch(error => {
			this.setState({
				message:'Something went wrong'
			})
        });
    }
	
	async agentOpt() {
		let agentParam = [{
			url: "/agent/agentDD"
		}];
		getApiData(agentParam, true).then(res => {
			this.setState({ 'agentList': res.data});
		}).catch(error => {

		});
	}
	handleAgentId=param=>event=>{
		var selected_agent_name = param[0]
		var agent_id = param[1]
		this.setState({
			agent_name: selected_agent_name
			,agent_id:agent_id
		},()=>{
			document.getElementById('agent_search_id_values').style.display = "none";
		})
	}

	handleAgentSearchAssign = event => {
		var key = event.target.id;
		let _this=this;
		switch (key) {
			case 'agent_search_id':
				this.setState({ searchParm: event.target.value, agent_name: event.target.value, agenterror: '' }, () => {
					//if(this.state.searchParm.length>-1){
					var value = this.state.searchParm;

					if (value != '') {
						let _count_=0;
						
						this.state.agentList.forEach(function (agent, index) {
							if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
								agent.flag = 2;
								if(_this.state.noRow==true){
									_this.setState({
										noRow:false
									})
								}
								
								console.log("flag--", agent.flag);
							} else {
								_count_=_count_+1;
								agent.flag = 0;
								console.log("flag--", agent.flag);
							}
						});

						if(_count_ == _this.state.agentList.length){

							_this.setState({
								noRow:true
							})
						}


					} else {
						console.log("else--");
						document.getElementById('agent_search_id_values').style.display = 'none';
						this.state.agentList.forEach(function (agent, index) {
							agent.flag = 0;
						});

					}
				})


				//}
				break;
			case 'searchkey_agent_assign':
				var value = event.target.value;
				let $this = this;
				this.setState({ agent_id: event.target.value }, () => {
					this.state.agentList.forEach(function (agent, index) {
						if (agent.id == value) {
							document.getElementById('agent_search_id_values').style.display = "none";
							document.getElementById('agent_search_id').value = agent.name;
							$this.setState({ agent_name: agent.name });
						} else {
							agent.flag = 0;
						}
					});
					document.getElementById('agent_search_id_values').style.display = "none";
				});
				break;
			case 'default':
				break;

		}

		document.getElementById('agent_search_id_values').style.display = "block";
	}

	validateForm(){
		return (this.state.agent_name!='')?true:false;
	}
	updateAgent=()=>{
		//console.log("Updated",this.state.agent_id)
		let data = {
			'agent_id':this.state.agent_id
			,'clientID':this.state.current_clientID
		    ,'appId':this.state.current_appId
		};
		var url = "/updateAgentId";
		let where = [{
			url: url
		}];
		postApiData(where, data, true).then(response => {
			this.setState({
				msg:"Agent Updated",
			})
		}).catch(error => {
			console.log('in catch', error);
			this.setState({
				message:"Something went wrong",
			})
		});
		
	}
		
    render() {
		
			const agentDW = this.state.agentList.map((baw, index) =>
			(baw.flag == 2 && baw.id!=this.state.current_agent_id) ? <li  key={baw.id} value={baw.id} name={baw.name} onClick={this.handleAgentId([baw.name,baw.id])}>{baw.name}</li>:''
			);

        return (
			<div>
				<main>
					<div className="modal-content">
						{(this.state.linkingLoader) ? <div className="show_loader_on_click" id="show_loader_on_click"></div> : ''}
						<div className="modal-header">
							<h4 className="modal-title head">Edit Agent</h4>
							<button type="button" className="btn cl-btn pull-right" onClick={this.props.onClick} style={{color:'#fff'}}>Close  <img src="/close.png"/></button>
						</div>
						<div className="modal-body profile">
						{(this.state.msg!='')?<div className="alert alert-success" ><strong>Profile Edit Successfully!</strong></div>:''}
						{(this.state.message!='')?<div className="alert alert-danger" ><strong>{this.state.message}</strong></div>:''}
						
							<div className="form-section">
								<div className="clearfix profile-widget">
									<div className="info-wrap pull-left">
										<div className="profile-heading">

										<div className="control-label col-sm-12">
												<label>AGENT NAME*</label>
												<div>
													<input type="text" id="agent_search_id" onChange={this.handleAgentSearchAssign} onKeyDownCapture={this.handleAgentSearchAssign} onKeyUpCapture={this.handleAgentSearchAssign} autoComplete="off" value={this.state.agent_name} className="form-control" />
													<div className="box agentNameOnBoard" id="agent_search_id_values" style={{ display: 'none' }} >
														<ul>{(this.state.noRow)?<li>No record found</li>:agentDW}</ul>
													</div>
													<br/>
													<button type="button" block='true'
											disabled={!this.validateForm()} className="btn btn-default" onClick={this.updateAgent}>Submit</button>
												</div>
												<span className="inline-error-class"> {this.state.agenterror !== '' ? this.state.agenterror : ''}   </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
                );
    }
}



export default EditAgents;