import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken} from '../../services/Api';

class Listing extends Component {
    constructor(props){
        super(props);
        this.state={
            routeFlag :true
        }
    }
  

     //fliter reset
	resetFilter=()=>{
    
    }
    render() {
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                        <h2 className="pull-left">Lisiting</h2>
                        <button className="btn btn-default pull-right">Reset All Filter</button>
                        <div className="panel-body table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="managerGridCol">Address<Link to  id="addressSearchClick" data-toggle="collapse" data-target="#addressSearch" className="icon-wrap search"></Link>
                                            <div id="addressSearch" className="collapse table-collapse" ref={this.setWrapperRef3}>
                                                
                                            </div>
                                        </th>
                                        <th>Unit #</th>
                                        <th>Client name <Link to  id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" ></Link>
                                            <div id="search" class="collapse table-collapse" ref={this.setWrapperRef1}>
                                            
                                            </div>
                                        </th>
                                        <th>Status <Link to id="statusKey" data-toggle="collapse" data-target="#status" className="icon-wrap status" ></Link>												
                                        <div id="status" class="collapse table-collapse status-collapse" ref={this.setWrapperRef2}>
                                        <div className="filterSearchReset"><Link to className="clearFilter"><i class="fa fa-undo"></i> Reset</Link></div>
                                           
                                        </div>
                                        
                                        </th>
                                        <th>Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Address</td>
                                        <td>123</td>
                                        <td>John</td>
                                        <td>Submitted</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Address</td>
                                        <td>123</td>
                                        <td>John</td>
                                        <td>Submitted</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Address</td>
                                        <td>123</td>
                                        <td>John</td>
                                        <td>Submitted</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                    </div>
                    
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default Listing;