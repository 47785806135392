import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {isEmail} from 'validator';
import {getApiData, postApiData,checkAdminToken,getAdminToken} from './../../services/Api'
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';

class AdminManager extends Component {
    constructor(props){
        super(props);
        this.state={
            addManager: false,
            dropdown: 1,
            email:"",
            name:"",
            myerror:"", //for email field
            myerror2:"", //for dropdown
            myerror3:"", //for Name field
            successQuote:false,
            myerrormsg:"",
            managerList:[],
            routeFlag:true,
            statusMsg: "",
            myerror4:""           
        };
        this.nameRef = React.createRef();
        this.emailRef = React.createRef();
    }

    handleChange = (event) => {   
        switch(event.target.id){
              case 'email':     
                  if (event.target.value.length === 0) { 
                    this.setState({
                                    myerror: 'Field is required.'
                                  });
                                  break;
                    }
                   else if (!isEmail(event.target.value)) {
                    this.setState({
                                    myerror: 'Email is not valid.'
                                  });
                    }else{
                            this.setState({
                                    [event.target.id]: event.target.value,
                                    myerror: ''
                                  });
                    }
                break;
                case 'dropdown':        
                    if (event.target.value.length == null) {
                    this.setState({
                                    myerror2: 'Field is required.'
                                  });
                    }else{
                          this.setState({
                                    [event.target.id]: event.target.value,
                                    myerror2: ''
                                  });
                    }
                break;
                case 'name':
                if(event.target.value.length == 0){
                    this.setState({
                        myerror3:'Field cannot be empty'
                    })
                }else{
                    this.setState({
                        [event.target.id]: event.target.value.trim(),
                        myerror3:''
                    })
                }
        
        }
    }

    async addManager(name, email, dropdown) {
        let where = [{
                url: "/admin/addManager"
            }];
            postApiData(where, {'name': name, 'email':email,'status':dropdown},true).then(res => {
                        this.setState({ 'pageLoader':true,  email:'', name:'',dropdown:1,myerror4:''},()=>{
                            this.nameRef.current.value = ''
                            this.emailRef.current.value = ''
                            this.setState({ 'pageLoader':false, "successQuote":true,});
                        })  
        }).catch(error => {
           this.setState({myerror4:error.response.data.error.message});
           this.setState({ 'pageLoader':false, "successQuote":true});
        });
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.name.length >0   && (this.state.myerror.length  == 0 && this.state.myerror3.length  == 0 ) ;
    }

    addManagerButton=()=>{
        this.getManagerList()
        this.setState({
            pageLoader: true
        },()=>{
            this.setState({  pageLoader: false, addManager: !this.state.addManager, successQuote :false, email:'', name:'',dropdown:1,  })
        })
    }

    handleFormSubmit=(e)=>{
        e.preventDefault();
        const { name, email, dropdown }= this.state
        this.addManager(name ,email, dropdown);
    }

    componentWillMount =()=>{
        this.getManagerList();
       
    }
    getManagerList=()=> {
        this.setState({'pageLoader':true});
        let applicationList = [{
            url: "/admin/managerList",
        }];
        getApiData(applicationList,true).then(res => {
        this.setState({'managerList': res.data,'pageLoader':true},()=>{
                this.setState({'pageLoader':false, 'myerrormsg':true});
        })
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
    }

    changeManagerStatus=(status,id)=>{
           
            let where = [{
                url: `/admin/manager/status/${id}`
            }];
            postApiData(where, {status},true).then(res => {
               if(res.data.message == 'success'){
                if(status == 2)
                {
                    this.setState({statusMsg:"Manager is deactivated."})
                }
                else if(status == 1){
                    this.setState({statusMsg:"Manager is activated."})
                }
               
            }
                        this.setState({ 'pageLoader':true},()=>{
                            this.getManagerList();
                        })  
        }).catch(error => {
            this.setState({myerrormsg: error.data.message});
         
        });
    }
    render() {
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        var { addManager, name, dropdown, email } = this.state;
        var style={
            form:{
              maxWidth: '50%', 
              margin: '10px auto'
            },
            dropdown:{
                backgroundColor: '#004f6b',
                borderRadius: '5px',
                color: 'white',
                width: '120px',
                height: '40px'
            }
        }
        var listItems = this.state.managerList.map((item, idex)=>{
            return <tr>
                
                <td>{item.name}</td>
                <td>{item.status == 1?"Active" : "Inactive"}</td>
                <td>{item.status == 1?(<button className="btn btn-default" onClick={()=>this.changeManagerStatus(2,item.id)} >Deactivate</button>) : (<button className="btn btn-default" onClick={()=>this.changeManagerStatus(1,item.id)} >Activate</button>) }</td>
            </tr>
        })
       
        
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">Manager</h2>
                <button className="btn btn-default pull-right" onClick={this.addManagerButton}>{!addManager ? "Add Manager" : "Back"}</button>
                        <div className="panel-body table-responsive">
                      
                        {this.state.statusMsg?(<div style={{marginLeft: '220px', marginRight: '370px'}} className="alert alert-success"><strong>{this.state.statusMsg}</strong></div>):(null)}
                        {!addManager ? 
                        
                            <table className="table table-striped">
                           
                                <thead>
                                    <tr>
                                        <th className="managerGridCol">Name</th> 
                                        <th className="managerGridCol">Status</th> 
                                        <th className="managerGridCol">Action</th> 
                                    </tr>
                                  </thead>
                                <tbody>
                                {listItems}
                                </tbody>
                                </table>
                                :
                                <Fragment>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className="managerGridCol">Add Manager</th> 
                                        </tr>
                                    </thead>
                                </table>
                                <form onSubmit={this.handleFormSubmit} style={style.form}>

                            {this.state.successQuote? (this.state.myerror4 ? (<div className="alert alert-danger"><strong>{this.state.myerror4}</strong></div>):(<div className="alert alert-success"><strong>Manager Successfully Added</strong></div>)):(null)}
                 
                                <div className="form-group">
                                    <label forhtml="name">Name</label>
                                    <input type="text" ref={this.nameRef} onChange={this.handleChange} className="form-control" id="name" placeholder="Name"/>
                                    <span className="inline-error-class"> {this.state.myerror3 !==''?this.state.myerror3:''}</span>
                                </div>
                                <div className="form-group">
                                    <label forhtml="addManager">Email</label>
                                    <input type="email"  ref={this.emailRef} onChange={this.handleChange} className="form-control" id="email" placeholder="Email"/>
                                    <span className="inline-error-class"> {this.state.myerror !==''?this.state.myerror:''}</span>
                                </div>
                                <div className="form-group">
                                    <div className="dropdown">
                                        <select id="dropdown"  onChange={this.handleChange} value={this.state.DropDownValue} style={style.dropdown}>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                        <span className="inline-error-class"> {this.state.myerror2 !==''?this.state.myerror2:''}</span>
                                    </div>
                                </div>
                                <button type="submit" disabled={!this.validateForm()} className="btn btn-primary" >Submit</button>
                                </form>
                                </Fragment>
                        }
                            </div>
                    </div>
                    
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default AdminManager;