import React from 'react';
import { Link , withRouter } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Header from '../../common/Header';
import FilterList from '../../common/FilterList';
import FilterListRent from '../../common/FilterListRent';
import { getApiData,getToken,getUserId,getBaseUrl, postApiData} from '../../../services/Api';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { Resizable, ResizableBox } from 'react-resizable';
import { Redirect } from 'react-router';
import ListDetails from '../../common/listDetails';
import L from 'leaflet';
import MarkerClusterGroup from './../../../react-leaflet-markercluster';
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';
//import './../../../main.scss';
//import './../../../leaflet.css';
import CarouselNew from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const redMarker = L.icon({
    iconUrl: './../../../red-filled-marker.svg',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });

  var id_arr=[];
  var flag=0;
  var image_list;

  var img_listing;
        var checkimg;
        var imgcheck;
        var image_list_data='';
        var divId;
        var image_data;
class BrowseList extends React.Component {
    constructor(props) {
        super(props);
// console.log("props Brows=>",props);
        this.state = {
            shown: true,
            scrollzoom:false,
            resultList:[],
            latLongList:[],
            totalRecords:0,
            hasMoreItems: true,
            filterValues:{
                bedRoom_0:(props.location.bedRoom_0!=undefined)?props.location.bedRoom_0:-1,
                bedRoom_1:(props.location.bedRoom_1!=undefined)?props.location.bedRoom_1:0,
                bedRoom_2:(props.location.bedRoom_2!=undefined)?props.location.bedRoom_2:0,
                bedRoom_3:(props.location.bedRoom_3!=undefined)?props.location.bedRoom_3:0,
                maxBed:(props.location.maxBed!=undefined && props.location.bedRoom_3==3)?true:false,
                maxPrice:(props.location.maxPrice!=undefined)?props.location.maxPrice.toLocaleString():51000,
                minPrice:(props.location.minPrice!=undefined)?props.location.minPrice.toLocaleString():0,
                _maxPrice_:(props.location._maxPrice_!=undefined)?props.location._maxPrice_.toLocaleString():4250000,
                _minPrice_:(props.location._minPrice_!=undefined)?props.location._minPrice_.toLocaleString():0,
                amenities:(props.location.amenities!=undefined)?props.location.amenities:'',
                building:(props.location.building!=undefined)?props.location.building:'',
                neighborhood:(props.location.neighborhood!=undefined)?props.location.neighborhood:'',
                vr:(props.location.vr!=undefined)?props.location.vr:'0',
                pageType:"search",
                filterType:(props.location.filterType!=undefined)?props.location.filterType:'Default',
                buildingType:(props.location.buildingType!=undefined)? props.location.buildingType:'',
                recordNextPage:10,
                address:(props.location.address!=undefined)? props.location.address:'',
            },
            sendToLogin:false,
            favList:[],
            clickedId:'',
            myerrormsg:'',
            searchType:(props.location.filterType!=undefined)?props.location.filterType:'Default',
            NoRecord:false,

            redirectOnborading:false,
            redirectDashboard: false,
            redirectApplyNow:false,
            linkingLoader:false,
        };
       this.myFunction=this.myFunction.bind(this)
       this.onClickactiveMap=this.onClickactiveMap.bind(this)
   
       this.iScroll = React.createRef();
      }
 
      // to show hide the detail of property
      toggle=param=>e=>{
        
         this.setState({clickedId:''});
        this.setState({
            shown: !this.state.shown
		},()=>{
            if(this.state.shown){
                this.setState({clickedId:''});
            }
           
            if(!this.state.shown && param[0]!=''){
                this.setState({clickedId:param[0]});
              }else{
                  if(param[0]!=''){
                    this.setState({clickedId:param[0],shown:false});
                  }
              }
             
              
        });

          
		// this.setState({
        //     shown: !this.state.shown
		// },()=>{

        //     if(this.state.shown){
        //         console.log("in if---",this.state.shown)
        //         this.setState({clickedId:''});
        //     }
        //     if(!this.state.shown){
        //         console.log("in if not ",this.state.shown)
        //         this.setState({clickedId:param[0]});
        //     }
        // });

	    }
        modalClose=param=>e=>{
            
            this.setState({
            shown: !this.state.shown
		},()=>{
            if(this.state.shown){
                this.setState({clickedId:''});
            }
        });
        }
      
      myFunction() {
        var y = document.getElementById("myFilter");
        if (y.style.display === "block") {
          y.style.display = "none";
        } else {
          y.style.display = "block";
        }
      }
      AdvanceSearch() {
        var z = document.getElementById("A_Filter");
        if (z.style.display === "none") {
          z.style.display = "block";
        } else {
          z.style.display = "none";
        }
      }
      componentWillMount(){
        this.getTotalRecords(this.state.filterValues);
        this.getAllLatLong();
        this.getlazyLoadList();
        
        if (getToken()) {
           this.getList();
        }

        
      }
getList(){
    this.setState({linkingLoader:false});
    let userId=getUserId();
          let filter={
                userId:userId,
            };
    let saveToFav = [{
        url: "/favList",
        where:filter
    }];
    getApiData(saveToFav,true).then(res => {
           this.setState({favList:res.data,linkingLoader:false});
    }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message,linkingLoader:false});
        }
    });
}
      

      componentDidMount()
      {
        this.refs.iScroll.addEventListener("scroll", () => {
           //alert('This is test')
            if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >=this.refs.iScroll.scrollHeight){
                
              this.getlazyLoadList();
            }
          });
      
            window.scrollTo(0, 0);
         
      }
      /**
       * this function will save this list in user fav list
       */
      saveToFavList=param=>e=>{
        let listindId=param[0];
        let userId;
        if (getToken()) {
            userId=getUserId();
            if(userId!='' && userId!=null){
            let filter={
                userId:userId,
                listId:listindId
            };

            let saveToFav = [{
                url: "/saveToFav",
                where:filter
            }];
            getApiData(saveToFav,true).then(res => {
                this.getList();
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message});
                }
            });
        }
        }else{
            this.setState({sendToLogin:true});
        }
    }


    onClickactiveMap(){
     
    var x = document.getElementById("disableMapWrap");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    this.setState({scrollzoom:true});
    }

    checkLoginBeforeShowing=param=>e=>{
        if(param[0]=='applyNow'){
            let clientId;
            if (getToken()) 
            {
                console.log("building", param[3]);
                console.log("address", param[1]);
                console.log("unit", param[2]);

                clientId=getUserId();
                    if(clientId!='' && clientId!=null){
                    let filter={
                        clientId:clientId,
                    };

                    let checkForApplyNow = [{
                        url: "/checkForApplyNow",
                        where:filter
                    }];
                    getApiData(checkForApplyNow,true).then(res => {
                       // console.log("res applyNow---",res.data);
                        if(res.data.length<=0){
                            this.setState({redirectOnborading: true, redirectApplyNow:false});      //redirect to onboarding
                        }
                        else if(!res.data[0].is_completed)
                        {
                            this.setState({redirectOnborading: true , redirectApplyNow:false});      //redirect to onboarding
                        }
                        else if(!res.data[0].emailVerified){
                            this.setState({redirectDashboard:true , redirectApplyNow:false});   //redirect to dashboard
                        }
                        else{
                               // this.setState({redirectOnborading: false,redirectDashboard:false, redirectApplyNow:true});
                                this.setState({redirectOnborading: false,redirectDashboard:false, redirectApplyNow:true},()=>{
                                    // this.props.history.push({
                                    //     pathname:"/client/application",
                                    //     state: { apartment_address: param[1], unit_number: param[2] }
                                        
                                    //    }); 
                                    localStorage.setItem('apartment_address',JSON.stringify(param[1]));
                                    localStorage.setItem('unit_number',JSON.stringify(param[2]));
                                    localStorage.setItem('building_id',JSON.stringify(param[3]));
                            });
                        }
                    }).catch(error => {
                        if(error.response){
                            this.setState({myerrormsg: error.response.data.error.message});
                        }
                    });
                }
                //return true;
            }
            else{
                this.setState({sendToLogin:true});
            }
        }
        else
        {
            if (getToken()) 
            {
                return true;
            }
            else{
                this.setState({sendToLogin:true});
            } 
        }
        

    }
/**
 * this function will get the total records come in the search filter
 * And get the value from DB
       */    
    getTotalRecords(withfilters)
    {
        let listingWhere='';
           listingWhere=[{
            url :"/totalRecord",
            where:withfilters
          }];
             
        getApiData(listingWhere,true).then(res=>{
         
          this.setState({
            totalRecords:res.data[0].total,
            },()=>{
              
          })
         
        }).catch(err=>{
    
        }) 

}    
      /**
       * this function will handle return value from Filter
       * And get the value from DB
       */
      getlazyLoadList()
      {
        this.setState({
            hasMoreItems: true  
        });
        this.getListAfterFilterApplied(this.state.filterValues)
      }  
      getListAfterFilterApplied(filter){

        this.getTotalRecords(filter); // Get total records in the applied search filter
        this.getAllLatLong(filter);

            let getFilteredRecord = [{
                url: "/listing",
                where:filter
            }];
            getApiData(getFilteredRecord,false).then(res => {
                this.setState({resultList:res.data,NoRecord:(res.data.length<1)?true:false});
                this.setState({filterValues:filter})

                if(this.state.filterValues.recordNextPage<this.state.totalRecords) {
  
                    var n= parseInt(this.state.filterValues.recordNextPage)+8;
                    this.state.filterValues.recordNextPage=n;
                   
                } else {
                    this.setState({
                        hasMoreItems: false
                    });
                }
                
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message});
                }
            });
        }

        /**
         * set search type
         */
        setSearchType(obj){
            this.setState({searchType:obj.searchType},()=>{   
                //this.getListAfterFilterApplied(this.state.filterValues);
                this.getAllLatLong(this.state.filterValues);
            });
        }

getAllLatLong(filter){


                let getAllRecord = [{
                    url: "/listing/getLatLong/"+this.state.searchType,
                    where:filter
                }];
                getApiData(getAllRecord,false).then(res => {
                    this.setState({latLongList:res.data});
                   
                    
                }).catch(error => {
                    if(error.response){
                        this.setState({myerrormsg: error.response.data.error.message});
                    }
                });
            }
       

    render() {
        var a='';
        
        console.log("searchType",this.state.searchType);
        if(this.state.sendToLogin==true){
            return (<Redirect to={'/client/login'}/>);
        }
        else {
            if(this.state.redirectOnborading==true){
                return (<Redirect to={'/client/onboarding'}/>);
            }
            else  if(this.state.redirectDashboard==true){
                return (<Redirect to={'/client/dashboard'}/>);
            }
            else  if(this.state.redirectApplyNow==true){
                return (<Redirect to={'/client/application/'}/>);
               
            }
        }
        var shown = {
			display: this.state.shown ? "block" : "none"
		};
		
		var hidden = {
			display: this.state.shown ? "none" : "block"
        }
       
        var result;
        if(this.state.resultList!=undefined && this.state.resultList.length>0){
        result = this.state.resultList.map((item, index)=>{
             let address= (item.listing_info_address.length>15)?(item.listing_info_address):item.listing_info_address;
             let unit = item.unit_number;
            return <ResizableBox className="box "  draggableOpts={{grid: [25, 25]}} >
                <div className="list-frame"  >
                    {item.kuula_vr_link?<div className="img-360">
                       <img src="/images/360.png" /> 
                    </div>:''}
                 {item.noFee?<div className="tag"></div>:''}
                 <CarouselNew
  additionalTransfrom={0}
  arrows
  autoPlaySpeed={3000}
  centerMode={false}
  containerClass="container"
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  }}
  showDots
  sliderClass=""
  slidesToSlide={1}
  swipeable
>
        

                {item.image.id!=0 && item.image!='' ? item.image.map(img_data=>{
                   
                     let img =(img_data.thumbnail_image!='' && img_data.thumbnail_image!=null)?img_data.thumbnail_image:'/listing-img.png';
                     let check=img.indexOf("https://");
                     let image=(check >-1 || img=='/listing-img.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
                 return    <Link to onClick={this.toggle([item.id])} className="view-full"><img src={image} style={{transform: `rotate(${img_data.rotation}deg)`}} /></Link>
                })  :  <Link to onClick={this.toggle([item.id])}>  <img src='/listing-img.png' /></Link>
                }

               
            </CarouselNew>
           
            
                {/* <div className="img-wrap" style={{background:'url('+ image +') no-repeat', backgroundSize:'cover'}}> </div>             */}
                 <Link to onClick={this.toggle([item.id])}> 
                <div className="listing-info clearfix">
                <div className="pull-left"><p class="area">{unescape(item.neighborhood)}</p>
                <h3 className="address" title={item.listing_info_address}>{address}, <span style={{textTransform:'none'}}>{unit}</span> </h3>
                <div className="cleafix">
                
                <p class="price">{item.price?'$'+item.price.toLocaleString():'$'+item.price}</p>
                <p class="room-info">{item.bedrooms} br/{item.bathrooms} ba/{item.square_footage}<span style={{textTransform:'lowercase'}}>sf</span></p></div>
                
                </div>
                </div></Link>
                <div className="pull-right"><Link to className={(this.state.favList.length>0 && this.state.favList.find(list => list.listId === item.id))?'star-block active':'star-block'} id={`fav_`+item.id} onClick={this.saveToFavList([item.id])}><svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="star" d="M33.6667 12.4L21.6834 11.3667L17 0.333374L12.3167 11.3834L0.333374 12.4L9.43337 20.2834L6.70004 32L17 25.7834L27.3 32L24.5834 20.2834L33.6667 12.4ZM17 22.6667L10.7334 26.45L12.4 19.3167L6.86671 14.5167L14.1667 13.8834L17 7.16671L19.85 13.9L27.15 14.5334L21.6167 19.3334L23.2834 26.4667L17 22.6667Z" fill="#BEB7B3"/>
</svg></Link></div>
                </div>
            
            </ResizableBox>
        });
      
    }

    if(this.state.NoRecord){
        result=<div>No record found</div>;
    }
      

  
        return (
<div className="w-bg FrontEnd">
    <Header />
    
    <main style={{paddingBottom:'0'}}>
    {(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}

    <div id="mobileListpopup">
                <div id="ListingModal" className="list-popup">
                        <div className="modal-dialog" style={{margin:'0px 10px'}}>
                            {(this.state.clickedId!='')?
                            <ListDetails _listId_={this.state.clickedId} closeModal={this.modalClose.bind(this)} clickToggle={this.toggle.bind(this)} saveToMYFavList={this.saveToFavList.bind(this)} checkLoginFORShowing={this.checkLoginBeforeShowing.bind(this)}/>
                            :''}
                        </div>
                        </div>
                </div>
    {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
        <div className="container listing-container">
                    <h2 className="text-center">Browse Listings</h2>
                    <div className="d-block d-sm-none refine-search" onClick={this.myFunction}>Refine your search</div>
                    
                    <div className="desktop-view" id="myFilter">
                    <div id="saleFilter" style={{display:(this.state.searchType=='Default')?'block':'none'}}>
                    {(this.state.searchType=='Default')?<FilterList searchT={this.setSearchType.bind(this)} handleReturnedResult = {this.getListAfterFilterApplied.bind(this)} _filter_={this.state.filterValues}  />:''}
                             </div>
                                <div id="rentFilter" style={{display:(this.state.searchType=='Sell')?'block':'none'}}>
                                {(this.state.searchType=='Sell')?<FilterListRent searchT={this.setSearchType.bind(this)} handleReturnedResult = {this.getListAfterFilterApplied.bind(this)} _filter_={this.state.filterValues}/>:''}
                                </div>
                    </div>         
                     {/*<div className="listing-result row"><div class="col-sm-6 col-md-8">
                         <div className="row clearfix">
                                {result}</div>
                         </div>
                         <div class="col-sm-6 col-md-4"><div class="map-section"><img src="/map.png" width="100%"/></div></div>
                         
        </div>*/}   
                    
                       

    </div>
    <div className="listing-result">
                        <SplitterLayout percentage secondaryInitialSize={30} >
                  
                            <div class="block" style={{overflow:'auto'}} ref="iScroll">
                                {result}
                    {(this.state.hasMoreItems && this.state.resultList.length>8) ? <p className="loading"> Loading Items....</p> : ""} 
                            </div>
                     
                          
                            <div >
                            <div class="map-section welcome-page" style={shown}>

        <Map className="markercluster-map" center={[40.78611770186631, -73.97203551192757]} zoom={12} maxZoom={18}  minZoom={4}>
          
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
          
              <MarkerClusterGroup>

                  
             {this.state.latLongList.map((s, index)=>{
                     
                  
                     return a=(s.latitude!='null' && s.longitude!='null' && s.longitude!='undefined')?<Marker key={s.id} position={[`${s.latitude}`, `${s.longitude}`]}  onClick={this.toggle([s.id])}>
                   {/*} <Popup>
                        <div>
                        {s.listing_info_address}<br />
                        <b>Unit:</b> {s.unit_number}<br />
                        <b>Layout:</b> {s.bedrooms}BD / {s.bathrooms}BA<br />
                        <b>Price:</b> ${s.price.toFixed(2)}
                        </div>
                    </Popup> */}
                    </Marker>:''
                  
              }
                    )};
                
          
              </MarkerClusterGroup>
                   






            </Map>
                            
                                {/*<img src="/map.png" width="100%"/>*/}
                            </div>
                            <div id="ListingModal">
                        <div className="modal-dialog" style={{margin:'0px 10px'}}>
                            {(this.state.clickedId!='')?<ListDetails _listId_={this.state.clickedId} closeModal={this.modalClose.bind(this)} clickToggle={this.toggle.bind(this)} saveToMYFavList={this.saveToFavList.bind(this)} checkLoginFORShowing={this.checkLoginBeforeShowing.bind(this)}/>:''}
                        </div>
                        </div>

                            </div>
                        </SplitterLayout>
                        
                </div>
                        
</main>

                   

<div className="Newskyward-design">
                <footer className>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> 
                     <li className="nav-item"><a href="https://blog.moveskyward.com/" target="_blank" > Blog </a></li> 
                     <li className="nav-item get-started-tab" ><Link to="/client/signup" className="getstart btn btn-default" >Login/Get Started</Link></li>
                  
                    
                    </ul>   
                   <div className="clearfix">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img src="/home-icon.png"/></li>
                       </ul>
                       <div className="logo"></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>

                   </div>
               </footer> 

                </div>   
</div>


                );
        }
        }
export default withRouter(BrowseList);
