import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/AgentHeader';
import { setAgentToken, getAgentToken, getApiData,getBaseUrl } from '../../services/Api';
import { Redirect } from 'react-router';
import onClickOutside from 'react-onclickoutside';
import DragAndDrop from './../common/DragAndDrop';
import { userInfo } from 'os';


class LinkingDetail extends React.Component {
    constructor(props) {
        super(props);
		this.state = {
            linkingLoader:false,
            reqDetails:(props.reqData)?props.reqData:0,
            index_id:0,
            linkingLoader:false,
			clickedClientId:0,
			unmatched_linking_list:[],
			paired_linking_list:[],
            myerrormsg:"",
            //Darg and Drop states
            onDragOverId : "",
            onMouseoverValue:"",
            onMouseoverIndex:"",  //OldIndex
            onDragOver: "",
            onDragStart:"",
            seq_number: "",
            tasks: [],
            UpadtedIndex: '',
            appId:"",
            checkFlag:0
          };
          this.filterList = this.filterList.bind(this)
        
    }
    //Darg And Drop code
    onDragOver=e=>{
        e.preventDefault();
        if(this.state.seq_number != null && e.target.value != null){
        var value = e.target.value;
        var id = e.target.getAttribute('id');

        this.setState({ onDragOver : value, onDragOverId : id })
        if(id != this.state.onDragOverId && id != null){
            this.setState({
                onDragOverId: id
            });
        }
        
        if(value != this.state.onDragOver && value != undefined ){
            if(id != null){
                this.setState({
                    onMouseoverIndex: value,
                    linkingLoader :false
                  },()=>{
                     this.setState({linkingLoader :true})
                  });
            }else{
            this.setState({
                onMouseoverIndex: this.state.onDragStart,
            })
            }
        }
        }
      }

    onDragStart=(ev, index)=>{
      //Index of the element picking up
        var seq_number = ev.target.getAttribute('id');
        this.setState({seq_number})
      //Setting Index of the element in Array as id
        if(this.state.seq_number != null){
            ev.dataTransfer.setData("id", index)
            this.setState({
                onDragStart: ev.dataTransfer.getData("id") 
            })
        }
    }

    onDrop=(e)=>{
        if(this.state.seq_number != null && this.state.onMouseoverIndex != undefined){
        const { tasks, onMouseoverIndex } = this.state;
        let id = e.dataTransfer.getData("id");
    
          function array_move(tasks, old_index, new_index) {
            tasks.splice(new_index, 0, tasks.splice(old_index, 1)[0]);
            return tasks; // for testing
          };
          array_move(tasks, id, onMouseoverIndex)
          this.setState({
            tasks: tasks,
            UpadtedIndex: (parseInt(onMouseoverIndex)+1),
            appId: tasks[onMouseoverIndex].appId,
        
          }, ()=> {
            this.UpdateSeq(this.state.tasks);
          });
        }
    }
//Code ends

UpdateSeq(list){

    this.setState({linkingLoader:true},()=>{
        let where = [{
            url: "/agent/clientList/drag",
            where:{
                list:list,
                appId:this.state.reqDetails[3],
                clientId:this.state.reqDetails[0]
            }
        }];
        getApiData(where,true).then(res => {
            if(res){
                this.ClickedRowDetails(this.state.reqDetails[0],this.state.reqDetails[1],this.state.reqDetails[2],this.state.reqDetails[3],this.state.reqDetails[5]);
            }
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.res.data.error.message});
        }
        });
    });
    


}

    handleClickOutside = () => {
        var index=0;
        if(this.state.index_id==0){
            index=this.state.reqDetails[4];
            this.setState({'index_id':index});
            document.getElementById('client'+index).classList.remove("show");
        }
        else{
            index=this.state.index_id;
            this.setState({'index_id':this.state.reqDetails[4]});
            document.getElementById('client'+index).classList.remove("show");
           if(document.getElementById('agentSearch')){
           }   
        }
      }

    componentWillMount() {
       
        this.ClickedRowDetails(this.state.reqDetails[0],this.state.reqDetails[1],this.state.reqDetails[2],this.state.reqDetails[3],this.state.reqDetails[5]);
	}
	//fecting details for clicked row
	ClickedRowDetails(clientId,address,unit,appId,agent){
		let clientList = [{
			url: "/agent/clientList/link",
			where:{
				'clientId':clientId,
				'address':address,
                'unit':unit,
                'appId':appId,
                'agent_id':agent
			}
        }];
        var count=0;
        var resLength=0;
       
        getApiData(clientList,true).then(res => {
                var linked=0;
                resLength=res.data.length;
                if(res.data.length>0){
                    res.data.map((item,index)=>{
                    count=count+1;
                    if(item.seq_number!=null)
                    {    
                    linked=linked+1;   
                    } 
                    if(count==resLength && resLength!=0)
                    {
                    if(linked>0){
                    this.setState({checkFlag:1,'unmatched_linking_list':res.data, 'tasks': res.data, 'linkingLoader':true},()=>{
                    this.props.handleLocationSubmit(this.state.reqDetails[3],1);
                    });
                    }
                    if(linked<1){
                    this.setState({checkFlag:0,'unmatched_linking_list':res.data, 'tasks': res.data, 'linkingLoader':true},()=>{
                    this.props.handleLocationSubmit(this.state.reqDetails[3],0);
                    });
                    }  
                    }             
                    })
                }else{
this.setState({'unmatched_linking_list':res.data, 'tasks': res.data, 'linkingLoader':true},()=>{
		    });
                }

		
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.res.data.error.message});
        }
        });
    }
    
    filterList=(event) =>{
        var value=event.target.value;
        if(value.length>2){
            this.state.unmatched_linking_list.map((client,index)=> {
                if(client.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
                    this.setState(state => (this.state.unmatched_linking_list[index].filterFlag = 0, state))
                }else{
                    this.setState(state => (this.state.unmatched_linking_list[index].filterFlag = 1, state))
                }
            });
        }else{
            this.state.unmatched_linking_list.map((client,index)=> {
            this.setState(state => (this.state.unmatched_linking_list[index].filterFlag = 0, state))
            });

        }
    }
    
    

    linkApplication= param => e => {
        this.setState({'linkingLoader':false},()=>{
        });
        
        let linkList = [{
			url: "/agent/clientList/link/application",
			where:{
				'clientId':param[0],
				'appId':param[1],
                'linkedClientId':param[2],
                'linkedApplicationId':param[3]
			}
		}];
        getApiData(linkList,true).then(res => {
            if(res.data){
                this.ClickedRowDetails(this.state.reqDetails[0],this.state.reqDetails[1],this.state.reqDetails[2],this.state.reqDetails[3],this.state.reqDetails[5]);
            }
              
        this.setState({'linkingLoader':true});
    }).catch(error => {
        if(error.response){
            this.setState({'linkingLoader':true});
            this.setState({myerrormsg: error.res.data.error.message});
        }
        });
    }

    LinkedApplicationDetails(clientId,address,unit,appId){
		let clientList = [{
			url: "/agent/clientList/linked/application",
			where:{
				'clientId':clientId,
				'address':address,
                'unit':unit,
                'appId':appId
			}
        }];
        getApiData(clientList,true).then(res => {
		this.setState({'paired_linking_list':res.data,'linkingLoader':true},()=>{
		});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.res.data.error.message});
        }
        });
    }

    // LinkedApplicationDetails= param => e => {
        
    // }
    
    render() {
        var liList='No matching application found.'; 
        if(this.state.unmatched_linking_list.length>0){
            liList = this.state.unmatched_linking_list.map((li,index) => 
            //if seq_number is not null, li is draggable
            (li.seq_number!=null)?
             <li 
             key={"li-"+index} 
             id={li.seq_number} 
             value={index}
             onDragStart={e=> this.onDragStart(e, index)}
             className="draggable clearfix"
             draggable
             onClick={this.linkApplication([this.state.reqDetails[0],this.state.reqDetails[3],li.clientId,li.appId])}
             >
             <div className="pull-left">{li.name} ({li.appId})</div>
             <div className="pull-right">
             <span key={"span"+li.appId} className={(li.seq_number!=null) ? "icon-wrap linkingFix link active":"icon-wrap linkingFix link "}>
             </span>{(li.seq_number!=null)?li.seq_number:''}</div>
             </li>

             :

             <li 
             key={"li-"+index} 
             id={li.seq_number}  
             value={index}
             onDragStart={e=> this.onDragStart(e, index)}
             className="draggable clearfix"
             onClick={this.linkApplication([this.state.reqDetails[0],this.state.reqDetails[3],li.clientId,li.appId])}
             >
             <div className="pull-left">{li.name} ({li.appId})</div>
             <div className="pull-right">
             <span key={"span"+li.appId} className={(li.seq_number!=null) ? "icon-wrap linkingFix link active":"icon-wrap linkingFix link "}>
             </span>{(li.seq_number!=null)?li.seq_number:''}</div>
             </li>
             );
            
        }
        // const liLinkedList = this.state.paired_linking_list.map((li,index) => 
        // (li.filterFlag==0)?<li key={"li"+index}><Link to key={li.appId} onClick={this.linkApplication([this.state.reqDetails[0],this.state.reqDetails[3],li.clientId,li.appId,])}>{li.name}({li.appId})<span className="pull-right" key={"span"+li.appId} className="icon-wrap link active"></span></Link></li>:''
        // );
        return (
			<div>
                <input id="filter" type="text" class="search-input" autoComplete="off"  onKeyPressCapture={this.filterList}  onKeyDownCapture={this.filterList}  onKeyUpCapture={this.filterList}/>
                {(!this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
                    {/* <ul>{liLinkedList}</ul> */}
                    {/* <ul>{liList}</ul>				  */}
                    <div className="container-drag">
                        <ul className="droppable" onDragOver={e=>this.onDragOver(e)} onDrop={e=> this.onDrop(e)}>
                            {liList}
                        </ul>
                    </div>
				 </div>
                );
    }
}



export default onClickOutside(LinkingDetail);