import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {getApiData,postApiData,checkAdminToken,getAdminToken} from '../../services/Api';

class Listing extends Component {
    constructor(props){
        super(props);
        this.state={
            routeFlag :true,
            linkingLoader:false,
            records:[],
            myerrormsg:''
        }
    }

    componentDidMount(){
        this.getList();
    }

    getList(){
        this.setState({
            linkingLoader:true
        })
            let getList = [{
                url: "/getMarketList",
            }];
            getApiData(getList,true).then(res => {
                this.setState({records:res.data,linkingLoader:false},()=>{
                });
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message,linkingLoader:false});
                }
            });
      }

      updateApprovalRequired(item){
        console.log("item",item)
        this.setState({
            linkingLoader:true
        })
            let updateApprovalRequired = [{
                url: "/updateDefaultSet",
            }];

            let data={
                'id':item[0].id,
                'approval_required':(item[0].approval_required==0)?1:0
            }
            postApiData(updateApprovalRequired,data,true).then(res => {
                this.getList();
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message,linkingLoader:false});
                }
            });
      }

    updateDefaultSet(item){
        this.setState({
            linkingLoader:true
        })
            let updateDefaultSet = [{
                url: "/updateDefaultSet",
            }];
            let data={
                'id':item[0].id,
                'default_set':(item[0].default_set==0)?1:0
            }
            postApiData(updateDefaultSet,data,true).then(res => {
                this.getList();
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message,linkingLoader:false});
                }
            });
      }


    handleCheckDefault=param=>event=>{
        this.setState({
            linkingLoader:true
        },()=>{
            this.updateDefaultSet(param);
            this.setState({
                linkingLoader:false
            })
        });

    }

    handleCheckApproval=param=>event=>{
        console.log("param",param)
        this.setState({
            linkingLoader:true
        },()=>{
            this.updateApprovalRequired(param);
            this.setState({
                linkingLoader:false
            })
        });
    }
  

    render() {
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }

         let  result='';
         if(this.state.records!=undefined){
            result = this.state.records.map((item, index)=>{
              return   <tr>
                                        <td>{index+1}</td>
                                        <td>{item.name}</td>
                                        <td className="text-center">
                                            <div class="check-block">                                            
                                                <div class="form-group " style={{marginBottom:'0'}}>
                                                    <div class="checkbox">
                                                        <input
                                                         type="checkbox" 
                                                         id={`approval_required`+index}
                                                         defaultChecked={(item.approval_required==1)?'checked':''}
                                                         onClick={this.handleCheckApproval([item])}
                                                         />
                                                        <label for={`approval_required`+index}></label>
                                                    </div>
                                                 </div>
                                             </div>
                                             </td>
                                        <td className="text-center">
                                            
                                        {(item.name=='Renthop')?'-':<div class="check-block">                                            
                                                <div class="form-group " style={{marginBottom:'0'}}>
                                                    <div class="checkbox">
                                                        <input type="checkbox" 
                                                         name="default_set"
                                                         id={`default_set`+index}
                                                        defaultChecked={(item.default_set==1)?'checked':''}
                                                         onClick={this.handleCheckDefault([item])}/>
                                                        <label for={`default_set`+index}></label>
                                                    </div>
                                                 </div>
                                             </div>}

                                        </td>
                                     
                                    </tr>
            })
        }
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                {(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
{this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                        <h2>Manage Marketing</h2>                      
                        <div className="panel-body table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th width="80">Sr. No.</th>
                                        <th className="managerGridCol">Marketing</th>                                     
                                        <th width="80" className="text-center">Approval Required</th>
                                        <th width="80" className="text-center">Default Set</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {result}
                                    {(!this.state.linkingLoader && this.state.records.length==0)?<tr>
                                        <td colSpan="4">
                                            No Records Found
                                        </td>
                                    </tr>:''}
                                </tbody>
                                </table>
                            </div>
                    </div>
                    
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default Listing;