import React from 'react';
import {postApiData, getApiData} from '../../services/Api';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutFormOne from './stripepaymentone';


class OnePageMakepayment extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            'mymsg':"",
            'myerrormsg':"",
            email: "",
            password: "",
            redirect:false,
            google_client_id:'',
            appDetail:[],
            myflag:"",
            id:(this.props.match.params.id)?this.props.match.params.id:'',
            add:'',
        }
         var handleStripeSubmit  = this.handleStripeSubmit.bind(this);

    }
async getApplicationDetail(){
 
     let where = [{
               url: "/onePageApplication/"+this.state.id
           }];    
           getApiData(where, true).then(res => { 
                        if(res.data){
                          this.setState({appDetail:res.data});
                          document.getElementById('message').value=this.state.appDetail.apartment_address+' Unit '+this.state.appDetail.unit_search_id;
                           
                        }
       });
}
    componentWillMount(){
        this.getApplicationDetail();
    }

handleStripeSubmit(token,stripeData){
                        let whereFind = [{
                                url: "/clients/stripe/onepagedeposit"
                            }];
                        let data = {'applicationId': this.state.id, 'response_data': JSON.stringify(stripeData)};
                        postApiData(whereFind, data,true).then(res => {
                            this.setState({is_stripe_completed:true});
                        })
                        .catch(error => {
                                   
                        });
                       
    } 

   render() {
      

      var handleStripeSubmit  =   this.handleStripeSubmit;
    return (
        <div className="form-container" id="application_form_section_nine" style={{ display: 'block' }}>
                        
                    <div className='form-widget login-widget final-widget'>
                    <div className="message ">
                                    <div> Payment Deposit
                                <p className="small"></p>
                                    </div>
                                </div>
                                <div className="form-section">
                               
                                
                                <div id="stripe_button_container_unsuccess" style={{ display: 'block' }}>
                                    <StripeProvider apiKey={process.env.REACT_APP_STRIP_PUBLIC_KEY}>
                                            <div className="example">
                                              <Elements>
                                                <CheckoutFormOne handleStripeSubmit = {handleStripeSubmit.bind(this)} applicationId={this.state.appId} address={this.state.add} />
                                              </Elements>
                                            </div>
                                    </StripeProvider>
                                </div>
                                {/* <ul className="pagination justify-content-center">
                                    <li className="page-item left"><button type="button" className="page-link" onClick={this.goPre}><i className="fa fa-chevron-left" aria-hidden="true"></i> Back</button></li>

                                    </ul> */}
                                </div>
                                </div></div>
                       
    );
  }
  
}
export default OnePageMakepayment;
