import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../common/ManagerHeader';
import Sidebar from '../../common/ManagerSidebar';
import { Redirect } from 'react-router';
import AgentHeader from "../../common/AgentHeader";
import AgentSideBar from "../../common/AgentSideBar";
import ManagerHeader from "../../common/ManagerHeader";
import ManagerSidebar from "../../common/ManagerSidebar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {getApiData, setAgentToken, getAgentToken,postApiData,getAgentName,getAgentEmail} from '../../../services/Api';
import LinkingDetail from '../../agent/linkingPage';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { validatePhone } from "../../../services/validation";
import InfiniteScroll from 'react-infinite-scroller';
import { isNullOrUndefined, isNull } from 'util';
import PropTypes from 'prop-types';
import { SSL_OP_DONT_INSERT_EMPTY_FRAGMENTS } from 'constants';
//import { el } from 'date-fns/esm/locale';
var aes = require("crypto-js/aes");
var encHex = require("crypto-js/enc-hex");
var padZeroPadding = require("crypto-js/pad-zeropadding");
var CryptoJS = require("crypto-js");
const checkedBoxes =[];

class Buildings extends React.Component 
{
	constructor(props) {
		super(props);
        this.state = {
			building_address: "",
            building_name: "",
            management_name: "",
            management_email: "",
            management_phone: "",
            neighborhood:"",
            required_additional_documents: "Default",
            default_application_template: "Default",
            screenings_required: "Default",
            default_application_template:'Skyward Standard',
            screenings_required: 0,
						building_amenities: [],
						req_additional_document:'',
						def_application_temp:'',
					building_amenity:[],
            building_address_error: "",
            building_name_error: "",
            management_name_error: "",
            management_email_error: "",
            management_phone_error: "",
            default_application_template_error: "",
            screenings_required_error: "",
						 screenings_required_error: "",
						 building_notes_error:"",
						 data:[],
						 building_notes:'',
				 tabledata:[],
				 validationStatus:true,
				 check:[],
				 
			 //
			 client_application:[],
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			searchClientId:'',
			searchParm:"",
			clientDW:[],
			statusFil:3,
			nameSearch:false,
			statusSearch:false,
			where:{},
			linkingLoader:false,
			clickedClientId:0,
			searchMsg:false,
			myerrormsg:"",
			checkedBoxes:[],
			existCheckboxSelection:true,
			addDocString:'',
			addDocValue:'',
			addDocValueName:'',
			addDocShow:'',
			neighoberData:[],
			searchNeighbor:'',
			brokerData:[],
			searchBroker:'',
			nextpage:20,
			totalRecords:0,
			hasMoreItems: true,
			firstPage:1,
			buildingAllData:[],
			contact_email_content:'',
			building_address_edit:'',
			management_name_edit:'',
			management_email_edit:'',
			management_phone_edit:'',
			building_notes_edit:'',
			Building_amenities_edit:'',
			addDocShow_edit:'',
			def_application_temp_edit:'',
			agent_email_building:'',
			is_skyward_runs_credit:'0',
			management_screen:'0',
			borough:'',
			building_street:'',
			building_number:'',
			building_name_array:[],
			building_street_array:[],
			longitude:'',
			latitude:'',
			building_full_street:'',
			tax_block:'',
			tax_lot:''
			,_required_additional_documents_:[],
			selectedaddDocId:'',
			//,addDocValueName:''
			selectedaddDoc:''
			};
			
			this.handleClickOutside = this.handleClickOutside.bind(this);
			this.setWrapperRef4 = this.setWrapperRef4.bind(this);
			this.setWrapperRef2 = this.setWrapperRef2.bind(this);
      this.setWrapperRef3 = this.setWrapperRef3.bind(this);
      this.setWrapperRef5 = this.setWrapperRef5.bind(this);
      this.setWrapperRefScr = this.setWrapperRefScr.bind(this);
    
			
    }
  
  
		ClientHeadClick(){ document.getElementById('search').classList.remove("show");}
		StatusNeighborClick(){document.getElementById('neighbor').classList.add("show");}
		StatusBrokerClick(){document.getElementById('broker').classList.add("show");}

		setWrapperRef4(node) {
			this.wrapperRef4 = node;
			}
			setWrapperRef2(node) {
				this.wrapperRef2 = node;
				}
				setWrapperRef3(node) {
					this.wrapperRef3 = node;
          }
          setWrapperRef5(node) {
            this.wrapperRef5 = node;
            }

					setWrapperRefScr(node) {this.setWrapperRefScr = node;}

        
componentWillMount(){
	window.scroll(0,0);
	document.addEventListener('mousedown', this.handleClickOutside);
	this.getAllBuildingData();
	this.getBuildingData();
	this.getNeighborData();
	this.getBrokerData();
	this.getData();
	this.getRequiredAdditinalDocument();
	//this.getEmailContent();
  this.getRequiredAdditinalDocument();
  document.addEventListener('mousedown', this.handleClickOutside);
  }		
componentDidMount(){
	document.addEventListener('mousedown', this.handleClickOutside);
	//this.getData();

  }
	resetCheckbox=(e)=>
	{
		if(e.target.id=='buildingamenityClose')
		{
		this.setState({checkedBoxes:[]});
		this.setState({existCheckboxSelection:true});
		}
	}
	resetsearchParm=()=>{
		this.setState({
			where:{},
			searchListingnId:'',
      searchNeighborhood:'',
      brokerSearch:'',
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('searchParm').value='';
      document.getElementById('searchNeighborParam').value='';
      document.getElementById('searchBrokerParam').value='';
			document.getElementById('searchClick').classList.remove("active");
      document.getElementById('search').classList.remove("show");
      document.getElementById('neighborClick').classList.remove("active");
			document.getElementById('brokerClick').classList.remove("active");

			this.getBuildingData();
			this.createWhere();

		  });
	}	
 handleClickOutside(event) {
		//	alert(event.target.id)
			if(event.target.id=='editPencile')
			{
				this.setState({checkedBoxes:[]});
		   this.setState({existCheckboxSelection:true});
			}
	if(event.target.id=='buildingamenities')
	{
		this.setState({checkedBoxes:[]});
		this.setState({existCheckboxSelection:true});
	}	
							
	if (this.wrapperRef4 && !this.wrapperRef4.contains(event.target)) {
		document.getElementById('search').classList.remove("show");
	}
	if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
		document.getElementById('neighbor').classList.remove("show");
	}
	if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {
		document.getElementById('broker').classList.remove("show");
  }
 
	
	
 }
 handleClickOutside(event) {
    if (this.wrapperRef5 && !this.wrapperRef5.contains(event.target)) {
      var y = document.getElementById("ScreeningsList");
      y.style.display = "none";
    }
}
/* Function to get the Distict Neighborhood list to show in the table header filter*/		  
getNeighborData()
{
let listingWhere='';

	listingWhere=[{
	url : '/managers/rentAllNeighbor'
}];

getApiData(listingWhere,true).then(res=>{
	this.setState({
		neighoberData:res.data,
		},()=>{
	})
}).catch(err=>{


}) 
}
/* Function to get the All Building list to show in the table header filter*/		  
getAllBuildingData()
{
let listingWhere='';

	listingWhere=[{
		url: "/building/allbuildingList",
}];

getApiData(listingWhere,true).then(res=>{
	console.log('buildingAllData',res.data);
	this.setState({
		buildingAllData:res.data,
		
		},()=>{
	})
}).catch(err=>{


}) 
}
/* Function to get the Distict Management Company list to show in the table header filter*/	
getBrokerData()
      {
          let listingWhere='';
             listingWhere=[{
              url :"/managers/rentBroker"
            }];
               
          getApiData(listingWhere,true).then(res=>{
            this.setState({
              brokerData:res.data,
              },()=>{
            })
          }).catch(err=>{
      
        
          }) 
        }

/**
 * making Selection value for Additional Document 
 */
// handleAddDoc=(event)=>{

// 	let value=event.target.value;

		
//   if(this.state.addDocValue==''){
//       this.setState({addDocValue:value,addDocString:value})
// 	}
// 	/*Remove Item in array if checkbox selected*/
//   if(this.state.addDocValue!='' && !document.getElementById(event.target.id).checked){
//    this.removeValue(this.state.addDocValue,value,',',(cberr,cbres)=>{
//       if(cbres!=''){
//           var listName=cbres.split(',');
//           this.setState({addDocValue:cbres,addDocString:listName[0]});
//       }else{
//           this.setState({addDocValue:cbres,addDocString:''});
//       }
// 	 });
// 	 }
	 
// 	 /*Add new Item in array if checkbox selected*/
// 	 if(this.state.addDocValue!='' && document.getElementById(event.target.id).checked){
// 		this.addValue(this.state.addDocValue,value,',',(cberr,cbres)=>{
// 			 if(cbres!=''){
// 					 var listName=cbres.split(',');
// 					 this.setState({addDocValue:cbres,addDocString:listName[0]});
// 			 }else{
// 					 this.setState({addDocValue:cbres,addDocString:''});
// 			 }
			 
// 		});
	
// 		} 
  
// }  
/**
 * removeValue will manage the amenities
 */
removeValue(list, value, separator,cb){
	console.log('removedata',list)
	separator = separator || ",";
	
		var values = list.toString().split(separator);
		for(var i = 0 ; i < values.length ; i++) {
		  if(values[i] == value) {
			values.splice(i, 1);
			cb(null,values.join(separator));
		  }
		}
	
	
	

  }
addValue(list, value, separator,cb){
	
  separator = separator || ",";
  if(list!=''){
	var values = list.toString().split(separator);
	for(var i = 0 ; i < values.length ; i++) {
	   if(values[i] == value) {
		  values.splice(i, 1);
		  cb(null,values.join(separator));
		  }
	  else{
		let _list_=list+","+value;
		cb(null,_list_)
	}
  
  }
  }
  else{
	cb(null,value) 
  }
  
}
OpenList() {
	var x = document.getElementById("documentList");

  if (x.style.display === "none") {
	x.style.display = "block";
	
	document.getElementById('arrowchange').classList.remove("fa-chevron-circle-down");
	document.getElementById('arrowchange').classList.add("fa-chevron-circle-up");
  } else {
	x.style.display = "none";
	document.getElementById('arrowchange').classList.add("fa-chevron-circle-down");
	document.getElementById('arrowchange').classList.remove("fa-chevron-circle-up");
  }


}
search=(event) =>{

	var key=event.target.id;
	 switch(key){
	 case 'searchParm':
		 this.setState({searchParm:event.target.value});
		 //if(this.state.searchParm.length>1){	
			 var value=this.state.searchParm.trim();
			 
			 if(event.key=='Enter'){
				 this.getsearchData(value);

			 }else if(value==''){
				 this.state.buildingAllData.forEach(function(address,index) {
					 address.flag=0;
				 });
				 this.setState({searchMsg:false});
			 }else {
				 var len = this.state.buildingAllData.length;
				 var count=0;
				 var vflag=0;
				 var showCount=0;
				 this.state.buildingAllData.forEach(function(address,index) {
					 
					 if(address.building_address.trim().toLowerCase().includes(value.trim().toLowerCase()) && showCount<15 ){
						showCount=showCount+1;
						 address.flag=1;
						 vflag=1;
						 count=count+1;
					 }else{
						 address.flag=0;
						 if(vflag!=1)
						 vflag=0
						 count=count+1;
					 }
 
				 });
			 
				 if(len==count){
					 if(vflag==0){
						 this.setState({searchMsg:true});
						 
					 }else{
						 this.setState({searchMsg:false});
						 
					 }

					 
				 }


			 }
		 //}
	 break;
	 case 'searchNeighborParam':
		 this.setState({searchNeighbor:event.target.value});
		 //if(this.state.searchParm.length>1){	
			 var value=this.state.searchNeighbor.trim();
			 if(event.key=='Enter'){
				 this.getsearchData(value);

			 }else if(value==''){
				 
				 this.state.neighoberData.forEach(function(address,index) {
					 address.flag=0;
				 });
				 this.setState({searchMsg:false});
			 }else {
				 var len = this.state.neighoberData.length;
			 
				 var count=0;
				 var vflag=0;
				 this.state.neighoberData.forEach(function(address,index) {
					 if(address.neighborhood.trim().toLowerCase().includes(value.trim().toLowerCase())){
						 address.flag=1;
						 vflag=1;
						 count=count+1;
					 }
				 else{
						 address.flag=0;
						 if(vflag!=1)
						 vflag=0
						 count=count+1;
					 }

				 });
				 
				 if(len==count){
					 if(vflag==0){
						 this.setState({searchMsg:true});
						 
					 }else{
						 this.setState({searchMsg:false});
						 
					 }
				 }
			 }
		 break;	
		 case 'searchBrokerParam':
		 console.log('Broker Value',event.target.value);
		this.setState({searchBroker:event.target.value});
		//if(this.state.searchParm.length>1){	
			var value=this.state.searchBroker.trim();
			if(event.key=='Enter'){
				this.getsearchData(value);

			}else if(value==''){
				this.state.brokerData.forEach(function(address,index) {
					address.flag=0;
				});
				this.setState({searchMsg:false});
			}else {
				var len = this.state.brokerData.length;
				var count=0;
				var vflag=0;
				this.state.brokerData.forEach(function(address,index) {
				
					if(address.mgt_company.trim().toLowerCase().includes(value.trim().toLowerCase())){
						address.flag=1;
						vflag=1;
						count=count+1;
					}
				else{
						address.flag=0;
						if(vflag!=1)
						vflag=0
						count=count+1;
					}
				

				});
				
				if(len==count){
					if(vflag==0){
						this.setState({searchMsg:true});
						
					}else{
						this.setState({searchMsg:false});
						
					}

					
				}

			}
			break;	
	 case 'searchkey':
	 var value=event.target.value;
	 
	 this.setState({ searchListingnId: event.target.value}, () => {
			 this.state.buildingAllData.forEach(function(address,index) {
				 if(address.building_address==value){
					 document.getElementById('searchClick').classList.add("active");
					 document.getElementById('searchParm').value=address.building_address; 
					 
					 //document.getElementById('search').classList.remove("show");
				 }else{
					 address.flag=0;
				 }
			 });
			 document.getElementById('searchClick').classList.add("active");
			 this.createWhere();
			 });
	 break;
	 case 'searchNeighbor':
	 var value=event.target.value;
		 this.setState({ searchNeighborhood: event.target.value}, () => {
			 this.state.neighoberData.forEach(function(address,index) {
				 if(address.neighborhood==value){
					 document.getElementById('neighborClick').classList.add("active"); 
					 document.getElementById('searchNeighborParam').value=address.neighborhood;	
					 //document.getElementById('search').classList.remove("show");
				 }else{
					 address.flag=0;
				 }
			 });
			 document.getElementById('neighborClick').classList.add("active");
			 this.createWhere();
			 });
	 break;
	 case 'searchBrokerData':
	 var value=event.target.value;
		 this.setState({ brokerSearch: event.target.value}, () => {
			 this.state.brokerData.forEach(function(address,index) {
				 if(address.mgt_company==value){
					 document.getElementById('brokerClick').classList.add("active"); 
					 document.getElementById('searchBrokerParam').value=address.mgt_company;	
					 //document.getElementById('search').classList.remove("show");
				 }else{
					 address.flag=0;
				 }
			 });
			 document.getElementById('brokerClick').classList.add("active");
			 this.createWhere();
			 });
	 break;
	 case 'default':
	 this.createWhere();
	 break;
		
	 }
		 
	 
	 }
	 async createWhere(){
		this.state.where={};
		var inValue="";
		//this.setState({data:[]});
		
		if(this.state.searchListingnId!=''){
			this.state.where.searchListingnId=this.state.searchListingnId;
		}
		if(this.state.searchNeighborhood!='')
		this.state.where.searchNeighborhood=this.state.searchNeighborhood;

		if(this.state.brokerSearch!='')
		this.state.where.brokerSearch=this.state.brokerSearch;
				//this.state.where.agentId = getAgentId();
				
				this.getBuildingData();
	} 	 	
		getBuildingData=()=>{

			this.state.where.nextpage = this.state.nextpage;

			let buildingwhere = [{
				url: "/building/buildingList",
			}];
			var len =Object.keys(this.state.where);
			if(len.length>0){
				buildingwhere[0].where=this.state.where;
			}
			
			getApiData(buildingwhere,true).then(res => {
				
			this.state.totalRecords=res.data[1][0].total

			 this.setState({
				data:res.data[0],
				pageLoader:true
			  },()=>{
				this.setState({pageLoader:false,flag:1})
				})

					if(this.state.nextpage<this.state.totalRecords ) {
									
						if(this.state.firstPage==1)
						{
						var n= parseInt(this.state.nextpage)+10;
						this.setState({nextpage:n});
						}
					
						
				} else {
						this.setState({
								hasMoreItems: false
						});
				}

			}).catch(error => {
			if(error.response){
				this.setState({myerrormsg: error.response.data.error.message});
			}
			});
		}

		getBuildingDataById=(id)=>{

			this.setState({buildingID:id})
			this.setState({'pageLoader':true});
        let whereEdit = [{
			url: "/building/buildingData",
			where:{
				'id':id
			}
		}];
		getApiData(whereEdit,true).then(res => {
			var buildingData =res.data[0];
			console.log('buildingData',res.data[0])
			this.setState({
				building_address: buildingData.building_address,
				building_name: buildingData.building_name,
				management_name: buildingData.management_name,
				management_email: buildingData.management_email,
				management_phone: buildingData.management_phone,
				latitude: buildingData.latitude,
				longitude: buildingData.longitude,
				tax_block:buildingData.tax_block,
				tax_lot:buildingData.tax_lot,
				//addDocValue: buildingData.additional_document,
				addDocShow:buildingData.additional_document,
				//addDocString: buildingData.additional_document,
				def_application_temp:buildingData.application_template,
				screenings_required:(buildingData.screening_required!='')?'Credit':'',
				building_amenity:res.data,
				building_notes:buildingData.building_notes.replace(/(<([^>]+)>)/ig,""),
				is_skyward_runs_credit:buildingData.is_skyward_runs_credit,
				management_screen:buildingData.management_screen,
				borough:buildingData.borough,
            },()=>{
              console.log("screenings_required screenings_required",this.state.screenings_required)
				// document.getElementById('box-0').checked=false;	
				// document.getElementById('box-1').checked=false;	
				// document.getElementById('box-2').checked=false;	
				// document.getElementById('box-3').checked=false;	
				// document.getElementById('box-4').checked=false;
				this.state.building_name_array=this.state.building_address.split(', ');
				this.state.building_full_street=this.state.building_name_array[0];
				this.state.building_street_array=this.state.building_name_array[0].split(' ');
				this.setState({building_number:this.state.building_street_array[0]});
				this.state.building_street_array.shift();
				this.state.building_street=this.state.building_street_array.join(' ');
				this.setState({building_street:this.state.building_street.replace(/ /g,'+')});
				
				//if(this.state.tax_lot==undefined)
			//	this.state.tax_lot=0;
                this.setState({
                    'pageLoader':false,
								})
								if(this.state.addDocShow!='NA' || this.state.addDocShow!='Default')
								{
									this.setState({selectedaddDoc:'',selectedaddDocId:''});
									this.getSeletedRequiredAdditinalDocument();
								
							}
								else{
									this.setState({selectedaddDoc:'',selectedaddDocId:''})
								}
							// 	var res = this.state.addDocValue.split(",");
								
							// for(var i=0;i<res.length;i++)
							// {
							// 	if(res[i]=='Employment Letter')
							// 	document.getElementById('box-0').checked=true;
							// 	if(res[i]=='Latest Tax Return')
							// 	document.getElementById('box-1').checked=true;
							// 	if(res[i]=='W2 or 1099')
							// 	document.getElementById('box-2').checked=true;
							// 	if(res[i]=='Latest Paystub 1')
							// 	document.getElementById('box-3').checked=true;
							// 	if(res[i]=='Latest Utility Bill')
							// 	document.getElementById('box-4').checked=true;
							// }
							
							// if((res.length==1 && res[0]=='Default') || res.length==0)
							// {
							// 	document.getElementById('box-0').checked=false;	
							// 	document.getElementById('box-1').checked=false;	
							// 	document.getElementById('box-2').checked=false;	
							// 	document.getElementById('box-3').checked=false;	
							// 	document.getElementById('box-4').checked=false;	
							// }
						})
						if(!isNullOrUndefined(buildingData.application_template) && buildingData.application_template!=''){
							document.getElementById('default_application_template').value = buildingData.application_template;
							// document.getElementById('default_application_template').disabled = true;
								}		
						
		}).catch(error => {
				if(error.res){
					this.setState({myerrormsg: error.res.data});
				}
			
			});
		}
		handleClickOnList=param=>e=>{
			var value=param[0];
		
			this.getsearchData(value,e.target.id);
			document.getElementById('search').classList.remove("show");
		}
		getsearchData(value,id){
			if(id=='searchNeighbor')
			{
						this.setState({ searchNeighborhood: value}, () => {
					this.state.neighoberData.forEach(function(neighbor,index) {
						if(neighbor.neighborhood==value){
							document.getElementById('neighborClick').classList.add("active");
							document.getElementById('searchNeighborParam').value=neighbor.neighborhood;
							document.getElementById('neighbor').classList.remove("show");
						}
						else{
							neighbor.flag=0;
						}
					});
					document.getElementById('neighborClick').classList.add("active");
					this.createWhere();
					});
			}
			else if(id=='searchBrokerData' || id=='searchBrokerParam')
			{
					this.setState({ brokerSearch: value}, () => {
					this.state.brokerData.forEach(function(address,index) {
						if(address.mgt_company==value){
							document.getElementById('brokerClick').classList.add("active");
							document.getElementById('searchBrokerParam').value=address.mgt_company;
							document.getElementById('broker').classList.remove("show");
						 
						}
						else{
							address.flag=0;
						}
					});
					document.getElementById('brokerClick').classList.add("active");
					this.createWhere();
					});
			}
			else{
			
		this.setState({ searchListingnId: value}, () => {
			this.state.data.forEach(function(address,index) {
				if(address.building_address==value){
					document.getElementById('searchClick').classList.add("active");
					document.getElementById('searchParm').value=address.building_address;
				}
				else{
					address.flag=0;
				}
			});
			document.getElementById('searchClick').classList.add("active");
			this.createWhere();
			});
		}
		}

		multiselectCheckbox = (param, value) => {
            if (param == "building_amenities") {
              var stateArray = this.state.building_amenities;
            }
           
        
            var flag = 0;
            // this is launches only one when the array is empty
            if (stateArray.length === 0) {
              stateArray.push(value);
            } else {
              //checking for the element if already exists
              stateArray.map(item => {
                if (item == value) {
                  flag = 1;
                }
              });
            }
            //if flag=0, then element can be pushed
            if (flag === 0) {
              stateArray.push(value);
              //removing duplicate elements if any
              stateArray = [...new Set(stateArray)];
            } else {
              //removing elements from the array on uncheck
              var index = stateArray.indexOf(value);
              stateArray.splice(index, 1);
            }
            this.setState(
              {
                [param]: stateArray
              },
              () => {
                
              }
            );
          };
          
        handleChange = e => {
            const id = e.target.id;
            const value = e.target.value;
            const dataset = e.currentTarget.dataset.id;
  
            // console.log('dataset : ', dataset);
        
            if (
              id === "building_address" ||
              id === "building_name" ||
              id === "management_name" ||
              id === "management_email" ||
							id === "management_phone" ||
							id === "building_notes"
            ) {
              var error = id + "_error";
              if (value == "") {
                console.log("_error : ", error);
                this.setState({
                  [error]: "Field cannot be empty",
									[id]: [value],
									validationStatus :false
                });
              } else if (id == "management_phone") {
                const phoneValue = this.formValidate("management_phone", value);
                this.setState(
                  {
										[id]: phoneValue,
										validationStatus:true
                  },
                  () => {
                    console.log("management_phone : ", this.state.management_phone);
                  }
                );
              } else {
                this.setState({
                  [error]: "",
									[id]: [value],
									validationStatus:true
                });
              }
            }
        
            if (
              id == "required_additional_documents" ||
              id == "default_application_template" ||
              id == "screenings_required"
            ) {
              var error = id + "_error";
              if (value == "" || value == 0) {
                console.log("_error : ", error);
                this.setState(
                  {
                    [error]: "Field cannot be empty",
										[id]: [value],
										validationStatus:false
                  },
                  () => {
                    
                  }
                );
              } else {
                this.setState(
                  {
                    [error]: "",
										[id]: [value],
										validationStatus:true,
                  },
                  () => {
                    console.log("dropdown : ", value);
                  }
                );
              }
            }
        
            if (
              id == "Elevator" ||
              id == "Doorman" ||
              id == "Laundry" ||
              id == "Gym" ||
              id == "Outdoor areas" ||
              id == "Live-in super" ||
              id == "Heat & Hot Water included"
            ) {
              this.multiselectCheckbox("building_amenities", value);
            }
        }

				buildingNotesChange=(event)=>{
					if(event.target.id == 'building_notes')
					{
						if(event.target.value == ">" || event.target.value == "<"){
							this.setState({
								building_notes_error :"This character is invalid"
							})
						}else if(this.isBuilding(event.target.value)==false ){
									this.setState({
										building_notes_error:"This character is invalid"
									})
							 
						}else if(event.target.value.length>= 100){
							this.setState({
								building_notes_error:"Building Notes length is too long"
							})
						}
						else{
							this.setState({
								building_notes_error:"",
								[event.target.id]:event.target.value
							})
						}
					}  
				}

				isBuilding(str){
					if(/^[a-zA-Z0-9\s,.@{}():?/$*+!~`^%#&_';="-]*$/.test(str))
					return true;
					else 
					return false;
			}
        formValidate(key, value) {
            switch (key) {
              case "management_phone":
                return this.validateEmpPhoneNumber(value);
                break;
            }
          }
          validateEmpPhoneNumber(value) {
            let res = validatePhone(value);
            if (res.status == false) {
              this.setState({ management_phone_error: "Phone number is not valid." });
              document.getElementById("management_phone").value = res.value;
              return res.value;
            } else {
              this.setState({ management_phone_error: "" });
              document.getElementById("management_phone").value = res.value;
              return res.value;
            }
          return res.status;
          }

          validation = () => {
  
            const {
              building_address,
              management_name,
              management_email,
              management_phone,
              management_phone_error,
              default_application_template,
              screenings_required,
            } = this.state;
       
            return (
             building_address.toString().trim() &&
             building_address.toString().trim() !="" &&
              management_name.toString().trim() != "" &&
              management_email.toString().trim() != "" &&
              management_phone.toString().trim() != "" &&
              default_application_template != 0 &&
              default_application_template != "" &&
              screenings_required != 0 &&
              screenings_required != "" &&
              management_phone_error == ""
            );
		  };
		  
		   getData=()=>{   
			let where = [{
				  url: "/building",
			}];
			  postApiData(where,{'additional_documents':'additional_documents','application_template':'application_template','application_screening':'application_screening','building_amenities':'building_amenities'},false).then(res => {
				
				this.setState({
				  tabledata:res.data,
				  
				  pageLoader:true
				},()=>{
					
				  this.setState({pageLoader:false,flag:1 })
				})
			  }).catch(error => {
			  if(error.response){
				  this.setState({myerrormsg: error.response.data.error.message});
			  }
			  });
		  }

			//Edit Function
			handleSubmitManagementName=(e)=>{
					e.preventDefault();
					const{management_name}=this.state;
					let whereFind = [{
            url: '/building/EditName'
        }];
        postApiData(whereFind,{'management_name':management_name,'id':this.state.buildingID},true).then(res => {
					document.getElementById("managementnameClose").click();
				
        })
        .catch(error => {  
        });
			}

			handleSubmitManagementContact=(e)=>{
					e.preventDefault();

					const{management_email,management_phone,buildingID}=this.state;
					let whereFind=[{
						url: '/building/EditContact'
					}];
					postApiData(whereFind,{'management_email':management_email,'management_phone':management_phone,'id':buildingID},true).then(res=>{
							document.getElementById("managementcontactClose").click();
					}).catch(error=>{

					});
			}

			handleSubmitAdditionalDoc=(e)=>{
					e.preventDefault();
					const{ required_additional_documents,buildingID}=this.state;
					let whereFind=[{
							url : '/building/EditDocuments'
					}];
					postApiData(whereFind,{'required_additional_documents':this.state.selectedaddDocId.toString(),'id':buildingID},true).then(res=>{
						
						this.setState({selectedaddDoc:''},()=>{
							
							this.getBuildingDataById(buildingID);
						})
						document.getElementById("additiondocClose").click();
						
						
					}).catch(error=>{

					})
			}

			handleSubmitApplicationTemp=(e)=>{
				e.preventDefault();
				const {default_application_template,buildingID}=this.state;
				let whereFind=[{
					url : '/building/EditTemplate'
				}];
				postApiData(whereFind,{'default_application_template':default_application_template,'id':buildingID},true).then(res=>{
					document.getElementById("applicationtempClose").click();
					this.getBuildingDataById(buildingID);
				}).catch(error=>{

				})
			}
		
			handleSubmitBuildingAmenity=(e)=>{
				e.preventDefault();
				const{building_amenities,buildingID}=this.state;
				let whereFind=[{
					url: '/building/EditAmenties'
				}];
				postApiData(whereFind,{'building_amenities':JSON.stringify(this.state.checkedBoxes),'id':buildingID},true).then(res=>{

					var newbid=document.getElementById('UpdateBuildingId').value;
				this.getBuildingDataById(newbid);
					document.getElementById("buildingamenityClose").click();
					
				}).catch(error=>{

				})
				
				
			}

			handleSubmitBuildingNotes=(event)=>{
				
				event.preventDefault();

				const{building_notes,buildingID}=this.state;
				let where=[{
					url : '/building/EditBuildingNotes'
				}];
				postApiData(where,{'building_notes':building_notes,'id':buildingID},true).then(res=>{
					document.getElementById("buildingamenityClose").click();
					document.getElementById("editbuildingnotesClose").click();
				}).catch(error=>{

				})
			}
			handleCheckBox =(e, check)=>{
		
        let checkedBoxes = [...this.state.checkedBoxes];
        if(e.target.checked) {
		  checkedBoxes.push(check)
		  
        } else {
         let index = checkedBoxes.findIndex((ch) => ch === check);
          checkedBoxes.splice(index, 1);
				}
         
				this.setState({checkedBoxes});
			}

			// getEmailContent=()=>{
         
			// 	let where = [{
			// 	  url:'/agent/EmailContent_builing/'
			// 	}]
			// 	getApiData(where,true).then(res=>{
			// 		console.log('Email Contnet==>',res.data);
			// 		this.setState({
			// 		  contact_email_content:res.data[0].html_value,
					 
			// 		})
					
			// 		document.getElementById('contact_Email_id').innerHTML=this.state.contact_email_content;
				   
				 
					
			// 	}).catch(error=>{
			
			// 	})
			//   }


			contacttoemail(building_address,management_name,management_email,management_phone,building_notes,Building_amenities,addDocShow,def_application_temp){
				this.setState({linkingLoader:true})
				//this.getBuildingDataById(this.state.buildingID);
				let key = encHex.parse("0123456789abcdef0123456789abcdef");
                let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
				this.setState({building_address_edit:building_address,management_name_edit:management_name,management_email_edit:management_email,management_phone_edit:management_phone,building_notes_edit:building_notes,Building_amenities_edit:Building_amenities,addDocShow_edit:this.state.selectedaddDoc,def_application_temp_edit:def_application_temp,agent_email_building:aes.decrypt(getAgentEmail(), key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)},()=>
				this.setState({linkingLoader:false})
			)
				
			}

			sendcontactmail(){
				  this.setState({linkingLoader:true})

				  var divValue='';
 
				   divValue=document.getElementById('divContent').innerHTML;
				   let where=[{
						  url:'/email/building_edit_send_mail'
				  }];  
				  
				  var data={'content':divValue,'subject':this.state.building_address_edit};
				  
				  postApiData(where,data,true).then(res=>{
				  
					this.setState({linkingLoader:false},()=>{
					
					  document.getElementById('contactmailclose').click();
					})
				 this.getAllBuildingData();
					
				  
				}).catch(error=>{
				  console.log('Error=>',error)
				})
				}

	handleScreening=(event)=>{
		if(event.target.id=="is_skyward_runs_credit"){
      if(event.target.value==0){
			this.setState({
				is_skyward_runs_credit:(event.target.value=='0')?'1':'0'
			},()=>{
        this.setState({management_screen:'0'})
          document.getElementById('is_management').checked=false
          this.setState({
						screenings_required:'Credit'
					},()=>{
              this.updateSkywardCredit();
							var x = document.getElementById("ScreeningsList");
							x.style.display = "block";
							
					})
			})
    }
  }


		if(event.target.id=="is_management"){
		 if(event.target.value=='0'){
			this.setState({
				management_screen:(event.target.value=='0')?'1':'0'
				
			},()=>{
        this.setState({is_skyward_runs_credit:'0'})
        document.getElementById('is_skyward_runs_credit').checked=false
				if(this.state.management_screen=='1'){
					this.setState({
						screenings_required:''
					},()=>{
              this.updateSkywardCredit();
							var x = document.getElementById("ScreeningsList");
							x.style.display = "none";
					})
				}else{
					this.updateSkywardCredit();
				}
				
			})
    }
  }

		if(event.target.id=="screening_credit"){
			if(this.state.screenings_required!=''){
				this.setState({
					screenings_required:''
				},()=>{
          this.setState({is_skyward_runs_credit:'0'})
          document.getElementById('is_skyward_runs_credit').checked=false
					this.updateSkywardCredit();
				})
			}else{
				this.setState({
					screenings_required:event.target.value
				},()=>{
          this.setState({is_skyward_runs_credit:'1'})
          document.getElementById('is_skyward_runs_credit').checked=true
					this.updateSkywardCredit();
				})
			}
			
		}

		
		
		
	}

	 updateSkywardCredit(){
		 console.log("this.state",this.state.screenings_required);
		let whereFind = [{
		url: '/building/updateSkywardCredit'
    }];
  
		postApiData(whereFind,{'is_skyward_runs_credit':this.state.is_skyward_runs_credit,'screening_required':this.state.screenings_required,'management_screen':this.state.management_screen,'id':this.state.buildingID},true).then(res => {
		document.getElementById("managementnameClose").click();
		})
		.catch(error => {  
		});
	}

	// ---Start require add document ticket-931 ----
	getRequiredAdditinalDocument(){
		let where = [{
		  url: "/getRequiredAdditinalDocument"
		  , where: { "where": { "document_flag": 1 } }
		}];
		getApiData(where, true).then(res => {
		  if (res.data) {
			
			this.setState({
			  _required_additional_documents_:res.data.data
			},()=>{
				console.log(this.state._required_additional_documents_);
			})
		  }
		});
	  }

 
// -----start selection value ------

  handleAddDoc=param=>event=>{
	let value=param[1]; //id
	let value_name=param[0]; //name
	if(this.state.selectedaddDocId==''){
		this.setState({selectedaddDocId:value,selectedaddDoc:value_name})
	}
	if(!document.getElementById(event.target.id).checked){
	
	 this.removeValue(this.state.selectedaddDocId,value,',',(cberr,cbres)=>{
		
		if(cbres!=''){
			//var listName=cbres.split(',');
			this.setState({selectedaddDocId:cbres});
		}
		else{
			this.setState({selectedaddDocId:cbres});
		}
	 });
	  this.removeValue(this.state.selectedaddDoc,value_name,',',(cberr,cbres)=>{
		  
		 
	  if(cbres!=''){
		 
		  this.setState({selectedaddDoc:cbres});
	  }
	  else{
		this.setState({selectedaddDoc:cbres});
	  }
  
	  });
	 } 

	 if(document.getElementById(event.target.id).checked){
		
		this.addValue(this.state.selectedaddDocId,value,',',(cberr,cbres)=>{
			 			 if(cbres!=''){
			 					
			 					 this.setState({selectedaddDocId:cbres});
						 }
						 
				 });
				 
				 this.addValue(this.state.selectedaddDoc,value_name,',',(cberr,cbres)=>{
					if(cbres!=''){
						
							this.setState({selectedaddDoc:cbres});
				   }
				   
		   });
				
		} 

  }
  
  
  
  /* making Selection value for add doc Values 
  */

  // ---Start get selectetd add document ticket-931 ----
	getSeletedRequiredAdditinalDocument(){
 
		let where = [{
		  url: "/getSelectedRequiredAdditinalDocument/"+this.state.addDocShow
		}];
		getApiData(where, true).then(res => {
			console.log('response data==>>',res.data.data)
		
		  if (res.data.data!=undefined) {
			res.data.data.map((list,index) => {
				
				if(this.state.selectedaddDoc==''){
				this.setState({selectedaddDoc:list['document_name']});
				this.setState({selectedaddDocId:list['id']})
					
				}else{
				this.setState({selectedaddDoc:this.state.selectedaddDoc+','+list['document_name'],selectedaddDocId:this.state.selectedaddDocId+','+list['id']})	;
					
				}
			   });
		  }
		});
	
	  }

	  OpenSList() {
				var x = document.getElementById("ScreeningsList");
		
		if (x.style.display === "none") {
			console.log("x.style.display block",x.style.display)
		  x.style.display = "block";
		} else {
			console.log("x.style.display none",x.style.display)
		  x.style.display = "none";
		}
	  }

	  //-----------End-----------------
    render() {
      console.log("screenings_required",this.state.screenings_required);
		
		// if (!getAgentToken()) {
    //         return (<Redirect to={'/agent/landing'}/>);
		//   }
		const { userType, heading } = this.props;

		const addressDW = this.state.buildingAllData.map((dw,index) => 
		(dw.flag==1) ? <li id="searchkey" onClick={this.handleClickOnList([dw.building_address])}>{dw.building_address}</li>:''
		);
		const neighborDW = this.state.neighoberData.map((dw,index) => 
	(dw.flag==1) ? <li id="searchNeighbor" onClick={this.handleClickOnList([dw.neighborhood])}>{unescape(dw.neighborhood)}</li>:''
	);
console.log(this.state.brokerData);
	const brokerDW = this.state.brokerData.map((dw,index) => 
	(dw.flag==1) ? <li id="searchBrokerData" onClick={this.handleClickOnList([dw.mgt_company])}>{dw.mgt_company}</li>:''
  );

 if(this.state.tabledata =='' && this.state.tabledata == undefined && this.state.tabledata.length == 0){
	return false;
  }


 var listItems='';
		if(this.state.client_application.length>0){
			listItems = this.state.client_application.map((application,index) => 
			<tr>
				<td>{application.apartment_address}</td>
				<td>{application.unit_number}</td>
				<td  className="managerGridCol">{application.realm}</td>
				<td  className="managerGridCol">{application.status}</td>
				{(application.status=='Submitted')?<td><Link to data-toggle="collapse" data-target={"#client"+index} key={application.clientId}  onClick={this.handleLinking([application.clientId,application.apartment_address,application.unit_number,application.appid,index])} className={(application.linked_application!=0) ? "icon-wrap link active":"icon-wrap link"}></Link>												
											<div id={"client"+index} class="collapse table-collapse link-collapse">

												<div className="box">
												
												{(this.state.clickedClientId[3]==application.appid)?<LinkingDetail reqData={this.state.clickedClientId}/>:''}

												</div>
											</div>
											</td>:<td>-</td>}											
			</tr>
 );	
		}else{
			listItems = 
			<tr>
			<td colSpan="5">No Record found for the searched criteria.</td>				
		</tr>
		}



		  const {
			building_address,
			building_name,
			management_name,
			management_email,
			management_phone,
			required_additional_documents,
			default_application_template,
			screenings_required,
			addDocValue,
			def_application_temp,
			checkedBoxes,
		    neighborhood,
			building_notes,
			tabledata,
			building_address_error,
			management_name_error,
			management_email_error,
			management_phone_error,
			default_application_template_error,
		   screenings_required_error,
			 building_notes_error,
		   data,
			 building_amenity
			
			//  check
			} = this.state;
			
		
		  //console.log("--table--data",this.state.tabledata[1])


		   let document_name;
		   let application_template;
		   let screening_name;
			 let building_amenity_name;
			
			 if(this.state.tabledata.length){
		   if(this.state.flag == 1 ){
			document_name = this.state.tabledata[0].map((value)=>{
			 return <option defaultValue={value.document_name}>{value.document_name}</option>
		   })
		   application_template = this.state.tabledata[1].map((value)=>{
			 return  <option  defaultValue={value.application_template}>{value.application_template}</option>
		   })
		   screening_name = this.state.tabledata[2].map((value)=>{
			 return  <option defaultValue={value.screening_name}>{value.screening_name}</option>
			 })
			}
			 
					function contains (arr, key, val) {
						
						for (var i = 0; i < arr.length; i++) {
							if(arr[i][key] === val) 
							{
									return 'Checked';
							}
						}
						return false;
					}
  
		   	building_amenity_name = tabledata[3].map((value)=>{

			 if(value.type == 0 && value.status == 1){
				
				var  val=contains(building_amenity, "amenity_name", value.amenity_name); //true
				
					if(val && this.state.existCheckboxSelection)
					{
						//alert(value.amenity_name)
						checkedBoxes.push(value.amenity_name);
						this.setState({existCheckboxSelection:false});
						this.setState({checkedBoxes});
					}

			   return <div className="checkbox-inline" key={value.amenity_name}>
		 			 <input
		 			   type="checkbox"
		 			   id={value.amenity_name}
						defaultValue={value.amenity_name}
						defaultChecked={val}
					  //checked={val}
				  	checked={checkedBoxes.find((ch) => ch === value.amenity_name)}
		 				onChange={(e) => this.handleCheckBox(e, value.amenity_name)}
		 			 />
		 			 <label htmlFor={value.amenity_name}>{value.amenity_name}</label>
		 		   </div>
		 	 }
		    });
		 
		}
		
	  // console.log("Rakesk==>",building_amenity);
		let Building_amenities;

		Building_amenities =this.state.building_amenity.map((item)=>{
			return 	item.amenity_name != null ? 
		 <div className="checkbox-inline">
			<input
				 type="checkbox"
				  // id={item.amenity_name}
				 value={item.amenity_name}
				 checked={item.amenity_name}
			/>
			 <label htmlFor={item.amenity_name}>{item.amenity_name}</label>
		</div> :
		(<lable>NO Building Amenity Selected for this Building</lable> )
	
		})

		let Building_amenities_edit;

		Building_amenities_edit =this.state.building_amenity.map((item)=>{
			return 	item.amenity_name != null ? item.amenity_name+', ':''
		 
	
		})

		 let screening_edit;

		// screening_edit =this.state.buildingAllData.map((item)=>{
		// 	return 	item.screening_required != null ? item.screening_required:''
		 
	
		// })


		// Start ticket -931 building add doc dropdown ----
		
       let checkselectedvalue=(this.state.selectedaddDocId!='')?this.state.selectedaddDocId.toString().split(','):[];
		const required_additional_documents_list = this.state._required_additional_documents_.map((list,index) => {
			return (<div> 
			  <span>
			  <input type="checkbox" id={`box-`+index} checked={checkselectedvalue.find((ch)=>ch==list.id)} onClick={this.handleAddDoc([list.document_name,list.id,index])}/> 
			  <label for={`box-`+index}>{list.document_name}</label>
			  </span>
		   </div>)
		   });

		  

		   // ----------End--------

	
        return (
          <div onclick="myFunction()">
            {userType == "Agent" ? <AgentHeader /> : <ManagerHeader />}
            <main>
              {userType == "Agent" ? <AgentSideBar /> : <ManagerSidebar />}
              <div className="content">
                <div className="work-section">
                  <div className="dashboard">
                    <h2 className="pull-left">Buildings</h2>
                    {userType == "Agent" ? (
                      ""
                    ) : (
                      <div className="pull-right Cr-showing-btn">
                        <Link to="/manager/build">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="15" cy="15" r="15" fill="#F26C52" />
                            <path
                              className="create-showing"
                              d="M24 16.7143H15.9643V24.75H13.2857V16.7143H5.25V14.0357H13.2857V5.99999H15.9643V14.0357H24V16.7143Z"
                              fill="white"
                            />
                          </svg>
                        </Link>
                      </div>
                    )}

                    <div className="panel-body table-responsive ">
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.createWhere.bind(this)}
                        hasMore={this.state.hasMoreItems}
                        loader={<div className="loader">Loading ...</div>}
                        useWindow={true}
                        threshold={100}
                      >
                        <table className="table table-striped ">
                          <thead>
                            <tr>
                              <th>
                                Address{" "}
                                <Link
                                  to
                                  id="searchClick"
                                  data-toggle="collapse"
                                  data-target="#search"
                                  className="icon-wrap search"
                                  onClick={this.ClientHeadClick}
                                ></Link>
                                <div
                                  id="search"
                                  class="collapse table-collapse client-collapse"
                                  ref={this.setWrapperRef4}
                                >
                                  <div className="box">
                                    <input
                                      type="text"
                                      style={{ maxWidth: "235px" }}
                                      id="searchParm"
                                      autocomplete="off"
                                      class="search-input"
                                      onChange={this.search}
                                      onKeyDownCapture={this.search}
                                      onKeyUpCapture={this.search}
                                    />
                                    <span onClick={this.resetsearchParm}>
                                      (X)
                                    </span>
                                    <ul>{addressDW}</ul>
                                    {this.state.searchMsg
                                      ? "No suggestions available"
                                      : ""}
                                  </div>
                                </div>
                              </th>

                              <th>
                                Neighborhood{" "}
                                <Link
                                  to
                                  id="neighborClick"
                                  data-toggle="collapse"
                                  data-target="#neighborhood"
                                  className="icon-wrap status"
                                  onClick={this.StatusNeighborClick}
                                ></Link>
                                <div
                                  id="neighbor"
                                  class="collapse table-collapse status-collapse"
                                  ref={this.setWrapperRef2}
                                >
                                  <div className="box">
                                    <input
                                      type="text"
                                      style={{ maxWidth: "151px" }}
                                      id="searchNeighborParam"
                                      autoComplete="off"
                                      class="search-input"
                                      onChange={this.search}
                                      onKeyDownCapture={this.search}
                                      onKeyUpCapture={this.search}
                                    />
                                    <span
                                      onClick={this.resetsearchParm}
                                      className="pull-right"
                                    >
                                      (X)
                                    </span>
                                    <ul>{neighborDW}</ul>
                                    {this.state.searchMsg
                                      ? "No suggestions available"
                                      : ""}
                                  </div>
                                </div>
                              </th>
                              <th>
                                Management Company{" "}
                                <Link
                                  to
                                  id="brokerClick"
                                  data-toggle="collapse"
                                  data-target="#broker"
                                  className="icon-wrap status"
                                  onClick={this.StatusBrokerClick}
                                ></Link>
                                <div
                                  id="broker"
                                  class="collapse table-collapse client-collapse"
                                  ref={this.setWrapperRef3}
                                >
                                  <div className="box">
                                    <input
                                      type="text"
                                      style={{ maxWidth: "232px" }}
                                      id="searchBrokerParam"
                                      autocomplete="off"
                                      class="search-input"
                                      onChange={this.search}
                                      onKeyDownCapture={this.search}
                                      onKeyUpCapture={this.search}
                                    />
                                    <span
                                      onClick={this.resetsearchParm}
                                      className="pull-right"
                                    >
                                      (X)
                                    </span>
                                    <ul>{brokerDW}</ul>
                                    {this.state.searchMsg
                                      ? "No suggestions available"
                                      : ""}
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length > 0 ? (
                              data.map((value, key) => {
                                return (
                                  <tr key={key}>
                                    <Link
                                      to
                                      onClick={() =>
                                        this.getBuildingDataById(value.id)
                                      }
                                      data-toggle="modal"
                                      data-target="#buildingModal"
                                    >
                                      <td>{value.building_address}</td>
                                      <td>{unescape(value.neighborhood)}</td>
                                      <td>{value.management_name}</td>
                                    </Link>
                                  </tr>
                                );
                              })
                            ) : (
                              <div
                                className="show_loader_on_click"
                                id="show_loader_on_click"
                              ></div>
                            )}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <div id="buildingModal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">Building Details</div>
                  <div className="modal-body">
                    <div className="detail-box landload-box">
                      <h3 className="title">{building_address}</h3>
                    </div>
                    <div className="detail-box">
                      <h3 className="title">
                        Landlord Name
                        {userType == "Agent" ? (
                          ""
                        ) : (
                          <Link
                            to
                            className="editsmall pull-right"
                            data-toggle="modal"
                            data-target="#editlandlordname"
                          >
                            {" "}
                            <i className="fa fa-pencil " aria-hidden="true"></i>
                          </Link>
                        )}
                      </h3>
                      <div className="info">
                        {management_name} <br />
                      </div>
                    </div>

                    <div className="detail-box">
                      <h3 className="title">
                        Contact
                        {userType == "Agent" ? (
                          ""
                        ) : (
                          <Link
                            to
                            className="editsmall pull-right"
                            data-toggle="modal"
                            data-target="#editcontact"
                          >
                            {" "}
                            <i className="fa fa-pencil " aria-hidden="true"></i>
                          </Link>
                        )}
                      </h3>
                      <div className="info">
                        {management_phone}
                        <br />
                        {management_email}
                      </div>
                    </div>
                    <div className="detail-box">
                      <h3 className="title">
                        Building Notes
                        {userType == "Agent" ? (
                          ""
                        ) : (
                          <Link to className="editsmall pull-right">
                            {" "}
                            <i
                              className="fa fa-pencil "
                              data-toggle="modal"
                              data-target="#editbuildingnotes"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        )}
                      </h3>
                      <div className="info">
                        {building_notes}
                        {/* Access not permitted on Sundays. Please email listings@moveskyward.com before submitting an application */}
                      </div>
                    </div>
                    <div className="detail-box">
                      <h3 className="title">
                        Amenities
                        {userType == "Agent" ? (
                          ""
                        ) : (
                          <Link
                            to
                            className="editsmall pull-right"
                            data-toggle="modal"
                            data-target="#buildingamenities"
                          >
                            {" "}
                            <i
                              className="fa fa-pencil "
                              id="editPencile"
                              aria-hidden="true"
                              onClick={() =>
                                this.getBuildingDataById(this.state.buildingID)
                              }
                            ></i>
                          </Link>
                        )}
                      </h3>
                      <div className="info">
                        <div className="check-block">
                          <div class="form-group">
                            <div className="checkbox-inline">
                              {Building_amenities}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detail-box">
                      <h3 className="title">
                        Required documents
                        {userType == "Agent" ? (
                          ""
                        ) : (
                          <Link
                            to
                            className="editsmall pull-right"
                            data-toggle="modal"
                            data-target="#reqadditionaldoc"
                          >
                            {" "}
                            <i className="fa fa-pencil " aria-hidden="true"></i>
                          </Link>
                        )}
                      </h3>
                      <div className="info">{this.state.selectedaddDoc}</div>
                    </div>
                    <div className="detail-box">
                      <h3 className="title">
                        Application template
                        {userType == "Agent" ? (
                          ""
                        ) : (
                          <Link
                            to
                            className="editsmall pull-right"
                            data-toggle="modal"
                            data-target="#defaultapptemplate"
                          >
                            {" "}
                            <i className="fa fa-pencil " aria-hidden="true"></i>
                          </Link>
                        )}
                      </h3>
                      <div className="info">{def_application_temp}</div>
                    </div>

                    <div className="row">
                      <div className="check-block">
                        <div className="form-group">
                          <div className="checkbox-inline">
                            <input
                              type="radio"
                              id="is_skyward_runs_credit"
                              value={this.state.is_skyward_runs_credit}
                              onClick={
                                userType == "Agent" ? "" : this.handleScreening
                              }
                              disabled={userType == "Agent" ? "disabled" : ""}
                              checked={
                                this.state.is_skyward_runs_credit == 1
                                  ? "true"
                                  : ""
                              }
                            />
                            <label htmlFor="is_skyward_runs_credit">
                              Skyward Runs Credit
                            </label>
                          </div>
                          <div className="checkbox-inline">
                            <input
                              type="radio"
                              id="is_management"
                              value={this.state.management_screen}
                              onClick={
                                userType == "Agent" ? "" : this.handleScreening
                              }
                              disabled={userType == "Agent" ? "disabled" : ""}
                              checked={
                                this.state.management_screen == 1
                                  ? "true"
                                  : ""
                              }
                            />
                            <label htmlFor="is_management">
                              Management Runs Credit
                            </label>
                          </div>
                          {/**Screenings required* */}
                          <div className="form-group " style={{marginTop:'20px', maxWidth:'300px'}}>
                            <div>Screenings required*</div>
                            <div>
                              <div
                                className={
                                  this.state.management_screen == 1
                                    ? "neighbor-filter list-filter disableClick"
                                    : "neighbor-filter list-filter"
                                }
                                onClick={this.OpenSList}
                              >
								  <span>{(this.state.screenings_required!='')?this.state.screenings_required:'No selection'}</span>
                              </div>
                              <div
                                className="boxes"
                                id="ScreeningsList"
                                style={{ display: "none" }}
                                ref={this.setWrapperRef5}
                              >
                                
				<span className={(this.state.screenings_required!='')?'active':'inactive'}><input type="checkbox" id="screening_credit" onClick={this.handleScreening} value="Credit"/> <label for="screening_credit">Credit</label></span>
                                
                              </div>
                              <span className="inline-error-class">
                                {screenings_required_error !== ""
                                  ? screenings_required_error
                                  : ""}
                              </span>
                            </div>
                          </div>
                          {/**Screenings required* */}

                        
                        </div>

                        <div className="clearfix">
                          <ul className="popLink">
                            <li>
                              <a
                                target="_blank"
                                href={`http://maps.nyc.gov/doitt/nycitymap/?searchType=AddressSearch&addressNumber=${this.state.building_number}&street=${this.state.building_street}&borough=${this.state.borough}`}
                              >
                                Interactive NYCmap
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href={`https://www.google.com/maps/dir//${this.state.building_address.replace(
                                  / /g,
                                  "+"
                                )}/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c2f66460334847:0xa9cabdb228e2c779!2m2!1d`}
                              >
                                Directions
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href={`https://www.schools.nyc.gov/find-a-school?sort=nearest&ShowZonedSchools=1&ShowCurrentYear=1&pg=1&school_borough=${
                                  this.state.borough
                                }&address=${this.state.building_full_street.replace(
                                  / /g,
                                  "+"
                                )}&latitude=${this.state.latitude}&longitude=${
                                  this.state.longitude
                                }`}
                              >
                                Schools
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href={`http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?boro=1&street=${this.state.building_street}&houseno=${this.state.building_number}`}
                              >
                                DoB
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href={`http://maps.nyc.gov/taxmap/map.htm?searchType=BblSearch&featureTypeName=EVERY_BBL&borough=${this.state.borough}&block=${this.state.tax_block}&lot=${this.state.tax_lot}`}
                              >
                                DoF{" "}
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href={`https://webapps.nyc.gov/CICS/f704/f403001I?BBL=1-0${this.state.tax_block}-0${this.state.tax_lot}`}
                              >
                                Tax Docs
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    {userType == "Agent" ? (
                      <p
                        className="text-center request-edit"
                        onClick={() =>
                          this.contacttoemail(
                            building_address,
                            management_name,
                            management_email,
                            management_phone,
                            building_notes,
                            Building_amenities_edit,
                            this.state.addDocShow,
                            def_application_temp
                          )
                        }
                      >
                        <Link
                          to
                          data-toggle="modal"
                          data-target="#contacttoemail"
                        >
                          <i class="fa fa-envelope"></i> Request for Edit of
                          Data
                        </Link>
                      </p>
                    ) : (
                      ""
                    )}

                    <p className="text-right">
                      <button
                        type="button"
                        className="btn cl-btn"
                        data-dismiss="modal"
                      >
                        Close <img src="/close.png" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="editlandlordname" className="modal fade">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Management Name</h4>
                    <button
                      type="button"
                      id="managementnameClose"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.handleSubmitManagementName}>
                      <input
                        type="text"
                        className="form-control"
                        id="management_name"
                        defaultValue={management_name}
                        onChange={this.handleChange}
                      />
                      <span className="inline-error-class">
                        {" "}
                        {management_name_error !== ""
                          ? management_name_error
                          : ""}
                      </span>
                      <button
                        type="submit"
                        disabled={!this.state.validationStatus}
                        className="btn btn-default"
                        style={{ margin: "10px" }}
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div id="editcontact" className="modal fade" role="dialog">
              <div className="modal-dialog ">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Contact</h4>
                    <button
                      type="button"
                      id="managementcontactClose"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="detail-box">
                      <form onSubmit={this.handleSubmitManagementContact}>
                        <input
                          type="text"
                          className="form-control"
                          id="management_phone"
                          defaultValue={management_phone}
                          onChange={this.handleChange}
                          maxLength="12"
                          placeholder="XXX-XXX-XXXX"
                        />
                        <span className="inline-error-class">
                          {" "}
                          {management_phone_error !== ""
                            ? management_phone_error
                            : ""}
                        </span>
                        <input
                          type="email"
                          className="form-control"
                          id="management_email"
                          defaultValue={management_email}
                          onChange={this.handleChange}
                        />
                        <span className="inline-error-class">
                          {" "}
                          {management_email_error !== ""
                            ? management_email_error
                            : ""}
                        </span>

                        <button
                          type="submit"
                          disabled={!this.state.validationStatus}
                          className="btn btn-default"
                          style={{ margin: "10px" }}
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="buildingamenities" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Amenities</h4>
                    <button
                      type="button"
                      id="buildingamenityClose"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.resetCheckbox}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="detail-box">
                      <div className="container">
                        <form onSubmit={this.handleSubmitBuildingAmenity}>
                          <div className="check-block">
                            <div
                              className="form-group"
                              onChange={this.handleChange}
                            >
                              {building_amenity_name}
                            </div>
                          </div>
                          <button
                            type="submit"
                            // disabled={!this.state.validationStatus}
                            className="btn btn-default"
                            style={{ margin: "10px" }}
                          >
                            Submit
                          </button>
                          <input
                            type="hidden"
                            id="UpdateBuildingId"
                            value={this.state.buildingID}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="defaultapptemplate" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Application Template</h4>
                    <button
                      type="button"
                      id="applicationtempClose"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="detail-box">
                      <form onSubmit={this.handleSubmitApplicationTemp}>
                        <div className="form-group">
                          <div className="neighbor-filter black">
                            <select
                              className="form-control"
                              id="default_application_template"
                              value={default_application_template}
                              onChange={this.handleChange}
                            >
                              {application_template}
                            </select>
                          </div>
                          {/* <span className="inline-error-class">
                                                            {" "}
                                                            {default_application_template_error !== ""
                                                                ? default_application_template_error
                                                                : ""}
                                                        </span> */}
                        </div>
                        <button
                          type="submit"
                          disabled={!this.state.validationStatus}
                          className="btn btn-default"
                          style={{ margin: "10px" }}
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="reqadditionaldoc" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Additional Documents</h4>
                    <button
                      type="button"
                      id="additiondocClose"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="detail-box">
                      <form onSubmit={this.handleSubmitAdditionalDoc}>
                        <div className="row clearfix">
                          <div className="container" id="requiredDoc">
                            <label onClick={this.OpenList}>
                              Required additional documents{" "}
                              <i
                                class="fa fa-chevron-circle-down"
                                id="arrowchange"
                                aria-hidden="true"
                              ></i>
                            </label>
                            <div className="form-group">
                              <div
                                className="neighbor-filter list-filter"
                                onClick={this.OpenList}
                              >
                                <span id="Firstcityname">
                                  {this.state.selectedaddDoc}{" "}
                                </span>{" "}
                                <span id="CityCount"></span>
                              </div>
                              <div
                                className="boxes"
                                id="documentList"
                                style={{ display: "none" }}
                                ref={this.setWrapperRef4}
                              >
                                {required_additional_documents_list}
                              </div>
                            </div>

                            <button
                              type="submit"
                              className="btn btn-default"
                              style={{ margin: "10px" }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="editbuildingnotes" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Building Notes</h4>
                    <button
                      type="button"
                      id="editbuildingnotesClose"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="detail-box">
                      <form onSubmit={this.handleSubmitBuildingNotes}>
                        <div className="form-group">
                          <div className="neighbor-filter black">
                            <textarea
                              id="building_notes"
                              // onChange={this.handleChange}
                              onChange={this.buildingNotesChange}
                              value={building_notes}
                              rows="4"
                              cols="60"
                            />
                            <span className="inline-error-class">
                              {" "}
                              {building_notes_error !== ""
                                ? building_notes_error
                                : ""}
                            </span>
                          </div>
                          <button
                            type="submit"
                            // disabled={!this.state.validationStatus}
                            className="btn btn-default"
                            style={{ margin: "10px" }}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Start  Modal */}
            <div class="application-approval" style={{ overflow: "auto" }}>
              <div class="modal fade" id="contacttoemail" role="dialog">
                <div class="modal-dialog" style={{ marginTop: "200px" }}>
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Contact by Email</h4>
                      <button type="button" class="close" data-dismiss="modal">
                        &times;
                      </button>
                    </div>

                    <div class="modal-body">
                      <p class="modal-title">
                        <strong>To</strong>
                      </p>
                      <div class="contact-info">
                        <div>
                          <label for="tl">
                            <p>listings@moveskyward.com</p>
                            <span id="Count"></span>
                          </label>
                        </div>
                      </div>
                      <strong>Subject</strong>

                      <div>
                        <input
                          title={
                            "Request for Edit of Building" +
                            " " +
                            this.state.building_address_edit
                          }
                          class="form-group col-sm-12"
                          type="text"
                          autocomplete="off"
                          readonly=""
                          value={
                            "Request for Edit of Building" +
                            " " +
                            this.state.building_address_edit
                          }
                        />
                      </div>
                      <div
                        id="divContent"
                        class="application-details"
                        contenteditable="true"
                      >
                        <div id="contact_Email_id">
                          <p>Hello,</p>
                          <p>
                            Landlord Name: {this.state.management_name_edit}
                          </p>
                          <p>
                            Contact: {this.state.management_email_edit} and{" "}
                            {this.state.management_phone_edit}
                          </p>
                          <p>
                            Building Notes: {this.state.building_notes_edit}
                          </p>
                          <p>Amenities: {this.state.Building_amenities_edit}</p>
                          <p>Required documents: {this.state.selectedaddDoc}</p>
                          <p>Screening: {this.state.screenings_required}</p>
                          <p>
                            Application template:{" "}
                            {this.state.def_application_temp_edit}
                          </p>
                          <p>
                            Thank you,
                            <br />
                            {getAgentName()}
                            <br />
                            {this.state.agent_email_building}
                          </p>
                        </div>
                      </div>
                      <div class="application-approval-confirm">
                        <button
                          type="button"
                          id="contactmailclose"
                          class="btn btn-default"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          onClick={() => this.sendcontactmail()}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*End Approve Modal*/}
          </div>
        );
    }
}

Buildings.propTypes = {
	userType: PropTypes.string,
	heading: PropTypes.string,
  };
export default Buildings;
