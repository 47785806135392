import React from 'react';
import {postApiData,getBaseUrl} from '../../services/Api';
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";

class ManagerEditListDoc extends React.Component {
    constructor(props) {
		super(props);
		this.state={
            name:this.props.listingsData.file_real_name,
            id:this.props.listingsData.id,
            file_path:this.props.listingsData.file_name,
			showLoader:false,
			error_msg:'',
			success_msg:'',
			invalid:false,
			add_doc_error:''
		}
	}

	componentWillMount(){
		if(this.props.listingsData==null || this.props.listingsData==undefined){
			this.setState({
				invalid:true
			})
		}
	}
	
	

	handleFrm=(e)=>{
		if(e.target.id=='name'){
			this.setState({
				name:e.target.value
			})
		}
	}
	validateStep(){
		return (this.state.name.trim()!=''
		&& this.state.add_doc_error=='');
	}

	fileHandler =(event) => {
		this.setState({linkingLoader:true});
		if(event.target.files[0]){
		 let imgFlag = true;
		const maxFileSize = 10485760;   //10mb
		const value = event.target.files[0].name;
		const ext = value.substring(value.lastIndexOf('.')).toLowerCase();
		if(!['.jpg','.jpeg','.JPG','.png','.pdf','.PNG'].includes(ext)){
			this.setState({add_doc_error: ext+' is not supported file extension.'})
			imgFlag = false;
		}
		if(event.target.files[0].size>maxFileSize){
			this.setState({add_doc_error:"File size is too big, upto 10MB allowed."})
			imgFlag = false;
			}
		if(imgFlag) {
			this.setState({uploaded_doc_error:""})
			this.setState({name:value,selectedFile:event.target.files[0],img_flag:true})
			this.setState({linkingLoader:false});
			//this.fileAddDocUpload(event.target.files[0],this.state.uploaded_doc_id);
		}
	 }
	 }

imageSubmit=()=>{
	this.setState({
		showLoader: true})
	if(this.state.img_flag){
		let agentDoc=[{
			url : '/containers/listing_document/upload'
		}];
		const formData = new FormData();
		formData.append('file',this.state.selectedFile);
		postApiData(agentDoc, formData,true).then(res => {
		if(res.data.success){
			this.updateFile(this.state.name,res.data.file_name,this.state.id);
		}
		}).catch(error=>{
			this.setState({
				showLoader: false,
				error_msg:'Something went wrong,Try after some time',
				 });
			});
	}else{
		this.updateFile(this.state.name,this.state.file_path,this.state.id);
	}
	
}

updateFile(file_name,file_path,id){
	let _this=this;
        let where = [{
			url: "/agentEditListingdocument"
			}];
			postApiData(where, {
				'document_name':file_name,
                'file_path':file_path,
                'id':id
				},true).then(res => {
					if(res.data.status=="success"){
						this.setState({
							showLoader: false,
							success_msg:'File updated',
							img_flag:false,
							selectedFile:{},
							
							 },()=>{
								setTimeout(
									function()
									{ 
										_this.props.onClose();
									}
									, 3000
									);
							 });
					}
			}).catch(error => {
			this.setState({
				showLoader: false,
				error_msg:'Something went wrong,Try after some time',
				 });
			});

}


    render() {
		if(this.state.invalid){
			return (<Redirect to={'/manager/document'}/>);
		}
	    return (<div className="modal-content">
{(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}				
		<div className="modal-header">
			<h4 className="modal-title">Edit Document</h4>
		</div>
		<div className="modal-body">
		{(this.state.error_msg!='')?<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>:''}
	{(this.state.success_msg !== '') ?<div className="alert alert-success"> {this.state.success_msg !==''?this.state.success_msg:''} </div>:''}
			{/* <div className="form-group" style={{marginBottom:'20px'}}>
				<input type="text" id="name" value={this.state.name} className="form-control" placeholder="Document Name" onChange={this.handleFrm} style={{border:'1px solid rgba(0,0,0,.5)', height:'50px', padding:'10px', borderRadius:'4px'}}/>
			</div> */}
			<div className="upload-btn-wrapper upload-doc mb-2" >
				<button className="btn btn-default" id="uploadBtn" type="button">+ Add Document</button>
				<FormGroup >
					<FormControl type="file"  onChange={this.fileHandler} />
				</FormGroup>
                <label style={{marginLeft:20}}>File: {this.state.name}</label>
			</div>
			<br/>
			<div>
			<span className="inline-error-class">  {" "}
                              {this.state.add_doc_error !== ""
                                ? this.state.add_doc_error
                                : ""}</span></div>
			<button type="button" className="btn btn-default"  disabled={!this.validateStep()}  onClick={this.imageSubmit}>Submit</button>
			<button type="button" className="btn cl-btn pull-right" onClick={this.props.onClose} >Close  <img src="/close.png"/></button> 

		</div>
	</div>);
    }
}



export default ManagerEditListDoc;