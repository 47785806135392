import React, { Component } from 'react';
import Buildings from '../../manager/listing/Buildings';

class Agentbuilding extends Component {
    render() {
        return (
            <div>
                <Buildings 
                userType='Agent'
                heading='Buildings'
                />
            </div>
        );
    }
}

export default Agentbuilding;