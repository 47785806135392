import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/ManagerHeader';
import Sidebar from '../common/ManagerSidebar';
import { getManagerToken,getManagerEmail,getApiData,postApiData,getBaseUrl, getManagerId } from '../../services/Api';
import { Redirect } from 'react-router';
import ContentEditable from 'react-contenteditable'
import {isEmail} from 'validator';
import Modal from 'react-responsive-modal';

class AppEditor extends React.Component {
	constructor(props){
		super(props);
		this.contentEditable = React.createRef();
		this.state={
			checkNext:0,
			is_credit:0,
			is_eviction:0,
			is_criminal:0,
			is_management:0,
			templateName:'',
			application_id:this.props.match.params.app_id,
			html:'',
			apartment_address:'',
			unit_number:'',
			html_guarantor_false:'',
			html_guarantor_true:'',
			is_guarantor_false_name:'',
			pages:'',
			linkingLoader:false,
			email:'listings@moveskyward.com',
			emailMsg:'',
			myerror:'',
			btnStatus:false,
			checkStatus:false,
			myerrormsg:'',
			db_is_criminal:0,
			db_is_eviction:0,
			db_is_credit:0,
			db_is_management:0,
			documentList:0,
			missingFiles:'',
			download_link:'',
			msg:''
		}
		this.stepHandler= this.stepHandler.bind(this);
		this.handleChange=this.handleChange.bind(this);
		this.emailRef = React.createRef();
		this.emailMsg = React.createRef();
		this.Emailbox = React.createRef();
	}

	componentWillMount(){
		this.checkPrevDownload();
		this.setState({linkingLoader:true},()=>{
			let whereEditor = [{
				url: "/appeditor/applicationEditor",
				where:{
					'application_id':this.state.application_id
				}
			}];
			getApiData(whereEditor,true).then(res => {
				console.log("res",res.data);
				this.setState({
					apartment_address:res.data[0].apartment_address,
					unit_number:res.data[0].unit_number,
					application_status:res.data[0].status,
					screen_flag:(res.data[0].credit_score!=null || res.data[0].credit!=0 || res.data[0].criminal!=0 || res.data[0].eviction!=0 )?true:false,
					linkingLoader:false,
					db_is_criminal:res.data[0].criminal,
					db_is_eviction:res.data[0].eviction,
					db_is_credit:res.data[0].credit,
					db_is_management:0,
					is_skyward_runs_credit:res.data[0].is_skyward_runs_credit,
				},()=>{
					
					this.screenDataForAppeditor();
				})
				
			}).catch(error => {
				this.setState({linkingLoader:false,myerrormsg: error.response.data.error.message});
				});
			});
	
	}
	
	checkPrevDownload=(check=0)=>{
		let where = [{
			url: "/appeditor/checkPrevDownload",
			where:{
				'application_id':this.state.application_id,
				'agent_id':getManagerId(),
				'agentType':'Manager'
			}
		}];
		getApiData(where,true).then(res => {
			if(res.data.status=='success'){
				this.setState({
					download_link:(res.data.data.length>0 && res.data.data[0].status==2)?res.data.data[0].download_link:'',
				},()=>{
					if(check==1){
						if(res.data.data.length==0){
							alert("Sorry, no Application PDF is available for this application. Please click the REQUEST PDF button to create a new request");
							this.setState({ 'linkingLoader':false,},()=>{})
						}else if(res.data.data.length>0 && (res.data.data[0].status==0 || res.data.data[0].status==1)){
							alert("Please wait while the PDF generation is in process. We will deliver an email to notify you as soon the Application is ready. Thanks!");
							this.setState({ 'linkingLoader':false,},()=>{})
						}else if(res.data.data[0].status==2 && this.state.download_link!==''){
						  window.open(this.state.download_link);
						}
						
					}
					else if(check==3){
						if(res.data.data.length==0){
							alert("Sorry, no Application PDF is available for this application. Please click the REQUEST PDF button to create a new request");
							this.setState({ 'linkingLoader':false,},()=>{})
						}else if(res.data.data.length>0 && (res.data.data[0].status==0 || res.data.data[0].status==1)){
							alert("Please wait while the PDF generation is in process. We will deliver an email to notify you as soon the Application is ready. Thanks!");
							this.setState({ 'linkingLoader':false,},()=>{})
						}else if(res.data.data[0].status==2 && this.state.download_link!==''){
							const lastSlashIndex = this.state.download_link.lastIndexOf('/');
							const secondLastSlashIndex = this.state.download_link.lastIndexOf('/', lastSlashIndex - 1);
							const filenameWithExtension = this.state.download_link.substring(secondLastSlashIndex + 1, lastSlashIndex);
						     window.open(getBaseUrl()+'/modifypdf/'+filenameWithExtension);
						}
					}
					else if(check==2){
						if(res.data.data.length==0){
							alert("Sorry, no Application PDF is available for this application. Please click the REQUEST PDF button to create a new request");
							this.setState({ 'linkingLoader':false,},()=>{})
						}else if(res.data.data.length>0 && (res.data.data[0].status==0 || res.data.data[0].status==1)){
							alert("Please wait while the PDF generation is in process. We will deliver an email to notify you as soon the Application is ready. Thanks!");
							this.setState({ 'linkingLoader':false,},()=>{})
						}else if(res.data.data[0].status==2 && this.state.download_link!==''){
						let where = [{
							url: "/email/send"
						}];
						postApiData(where, {'email':this.state.email ,'file':this.state.download_link, 'status':3,'id':this.state.application_id},true).then(res => {
							this.setState({
								emailMsg:res.data.message,
								checkStatus:res.data.status
							})
							this.emailRef.current.value = '';
							document.getElementById('closeModel').click();
							this.setState({ 'linkingLoader':false,},()=>{})  
									
					}).catch(error => {
						console.log("error", error);
						this.setState({linkingLoader:false,myerrormsg: error.response.data.error.message});
					 
					});
				}else{
					this.setState({linkingLoader:false,myerrormsg: "Something went wrong"});
				}
					}
				
				})
			}
		}).catch(error => {

			this.setState({linkingLoader:false,myerrormsg: "Something went wrong"});
			});
}



	//handle all form element
	stepHandler=(event) =>{
		var key=event.target.id;	
		this.setState({linkingLoader:true},()=>{
		switch(key){
			case 'is_credit':     
				if(this.state.is_credit==0){
					this.setState({is_credit:1,is_management:0,linkingLoader:false},()=>{
						
					});
				}else if(this.state.is_credit==1){
					this.setState({is_credit:0,is_management:0,linkingLoader:false},()=>{
						
					});
				}
				break;
				case 'is_eviction':     
				if(this.state.is_eviction==0){
					this.setState({is_eviction:1,is_management:0,linkingLoader:false},()=>{
						
					});
				}else if(this.state.is_eviction==1){
					this.setState({is_eviction:0,is_management:0,linkingLoader:false},()=>{
						
					});
				}
				break;
				case 'is_criminal':     
				if(this.state.is_criminal==0){
					this.setState({is_criminal:1,is_management:0,linkingLoader:false},()=>{
						
					});
				}else if(this.state.is_criminal==1){
					this.setState({is_criminal:0,is_management:0,linkingLoader:false},()=>{
						
					});
				}
				break;
			case 'next_button':
				this.doNext(this.state.checkNext);
			break;
			case 'prev_button':
				this.doPrev(this.state.checkNext);
			break;
			case 'is_management':
			if(this.state.is_management==0){
			this.setState({is_criminal:0,is_eviction:0,is_credit:0,is_management:1,linkingLoader:false},()=>{

			})
			}else if(this.state.is_management==1){
				this.setState({is_criminal:0,is_eviction:0,is_credit:0,is_management:0,linkingLoader:false},()=>{

				})
			}
			break;
			}
		});
	}

	doPrev(currentStep){
		let prevStep=currentStep;
		if(prevStep==0){
			this.setState({checkNext:0,linkingLoader:false},()=>{

			});
		}else if(prevStep==1){
			this.setState({checkNext:0,linkingLoader:false},()=>{

			});
		}else if(prevStep==2){
			this.setState({checkNext:1,linkingLoader:false},()=>{

			});
		}
	}

	doNext(currentStep){
		let nextStep=currentStep;
		console.log("nextStep222",nextStep);
		if(nextStep==2){
			this.setState({checkNext:2,linkingLoader:false},()=>{

			});
		}else if(nextStep==1){
			this.setState({checkNext:2,linkingLoader:false},()=>{
					this.getEditor();
			});
				
		}else if(nextStep==0){
			
			this.setState({
				checkNext:1,
				linkingLoader:false
				},()=>{

					if(this.state.screen_flag)
					{
						this.getEditor();
					}
					
			});
		}
	}


	getEditor(){
		let whereEditor = [{
			url: "/appeditor/editor",
			where:{
				'application_id':this.state.application_id,
				'templateName':this.state.templateName,
				'is_credit':(this.state.screen_flag && this.state.templateName!='')?this.state.db_is_credit:this.state.is_credit,
				'is_eviction':(this.state.screen_flag && this.state.templateName!='')?this.state.db_is_eviction:this.state.is_eviction,
				'is_criminal':(this.state.screen_flag && this.state.templateName!='')?this.state.db_is_criminal:this.state.is_criminal,
				'is_management':(this.state.screen_flag && this.state.templateName!='')?this.state.db_is_management:this.state.is_management,
			}
		}];
		getApiData(whereEditor,true).then(res => {
			this.setState({
				html:res.data.pages,
				page:res.data.cover_count,
				template:res.data.selectedTemplate,
				linked_ids:res.data.linked_ids,
				myerrormsg:res.data.error_msg,
				linkingLoader:false
			})
		}).catch(error => {
			this.setState({linkingLoader:false,myerrormsg: error.response.data.error.message});

			});

	}
	  setPreview=()=>{
		  document.getElementById('divIdToPrint').innerHTML=this.state.html;
	  }

	  handleChange=(event)=>{
		  this.setState({html:event.target.value},()=>{
			  console.log("event.target.value",event.target.value)
		  });
	  }

	  downloadPrevFile=()=>{

		this.checkPrevDownload(1);
 	 } 
	  openPdfEditor=()=>{

		this.checkPrevDownload(3);
 	 } 	 

	pdfDownload = () => {
		this.setState({ 'linkingLoader': true }, () => {
			let where = [{
				url: "/appeditor/createPdf",
			}];
			postApiData(where, {
				'application_id': this.state.application_id,
				'pages': this.state.html,
				'page_count': this.state.page,
				'template': this.state.template,
				'linked_ids': this.state.linked_ids,
				'is_credit': this.state.is_credit,
				'is_eviction': this.state.is_eviction,
				'is_criminal': this.state.is_criminal,
				'is_management': this.state.is_management,
				'email':getManagerEmail(),
				'agentType':'Manager',
				'agent_id':getManagerId()

			}, true).then(res => {
				this.setState({ linkingLoader: false, msg: 'PDF request is accepted, Link will be shared to you as soon PDF will be generated' })
			}).catch(error => {
				if (error.res) {
					this.setState({ linkingLoader: false, myerrormsg: error.response.data.error.message });
				}
			});
		});
	}

getFile(file_name,cb){
	//this.setState({ 'linkingLoader':false},()=>{
		var file=getBaseUrl()+"/containers/application_pdf/download/"+file_name+"/true"; 
		cb(null,file)
	//}) 
	
}

generateDocumentPDF=()=>{
	if(this.state.documentList==0){
		this.setState({ 'linkingLoader':true})
	let where = [{
		url: "/appeditor/generateDocumentPDF",
	}];
	postApiData(where,{
		'application_id':this.state.application_id,
		'pages':this.state.html,
		'page_count':this.state.page,
		'template':this.state.template,
		'linked_ids':this.state.linked_ids,
		'is_credit':this.state.is_credit,
		'is_eviction':this.state.is_eviction,
		'is_criminal':this.state.is_criminal,
		'is_management':this.state.is_management,	
	},true).then(res => {	
		let file_name=getBaseUrl()+"/containers/application_pdf/download/"+res.data.filename; 
				this.setState({
					documentList:file_name,
					missingFiles:res.data.missingFiles,
					linkingLoader:false,
				},()=>{
					this.onOpenModal();
				})		 
			
	}).catch(error => {
		
				this.setState({linkingLoader:false,myerrormsg: error.response.data.error.message});
			
		});
	}else{
		this.onOpenModal();
		this.setState({
			linkingLoader:false,
		})
	}
	
}

	mailSend=()=>{
		this.setState({ 'linkingLoader':true})
		this.checkPrevDownload(2);
	}

	emailPick=(event)=>{
		this.setState({email:event.target.value})
	
	}
	
	close = ()=>{
	}
	selectTemplate=(template)=>{
		console.log("template=>",template);
		this.setState({
			templateName:template,
		},()=>{
			if(template!='' && this.state.screen_flag){
					this.setState({
						'is_credit':this.state.db_is_credit,
						'is_eviction':this.state.db_is_eviction,
						'is_criminal':this.state.db_is_criminal,
						'is_management':this.state.db_is_management,
					})
			}else if(template=='' ){
				this.setState({
					'is_credit':0,
					'is_eviction':0,
					'is_criminal':0,
					'is_management':0,
				})
			}
		})
	}
	handleModelChange = (event) => {     
		switch(event.target.id){
					case 'email':     
							if (event.target.value.length==0) {
								this.setState({myerror: 'Field is required.',btnStatus:false});
								break;
								}
							 else if (!isEmail(event.target.value)) {
										
								this.setState({myerror: 'Email is not valid.',btnStatus:false});
								}else{
												this.setState({myerror: '',btnStatus:true});
								}
						break;
		}
		this.setState({
			email:event.target.value
		})
}

screenDataForAppeditor=()=>{
	this.setState({linkingLoader:false})
	console.log("apartment_address",this.state.apartment_address);
	let where =[{
		url:'/agent/buildingscreendata'
	}];
	postApiData(where,{'apartment_address':this.state.apartment_address},true).then(res=>{
		console.log("res.data",res.data);
			if(res.data[0].application_template==='Skyward Standard'){
				this.setState({
					templateName:'standard'
				})
			}else if(res.data[0].application_template==='Pinnacle'){
				this.setState({
					templateName:'pinnacle'
				})
			}else if(res.data[0].application_template==='City Skyline'){
				this.setState({
					templateName:'city_skyline'
				})
			}else if(res.data[0].application_template==='Beach Lane'){
				this.setState({
					templateName:'beach_lane'
				})
			}else if(res.data[0].application_template=='Monarch Realty Holdings'){
				this.setState({
					templateName:'monarch_realty_holdings'
				})
			}else if(res.data[0].application_template=='Urban American'){
				this.setState({
					templateName:'urban_american'
				})
			}else{
				this.setState({
					templateName:"standard"
				})
			}
			
		
			var str =res.data[0].screening_required;
			var str_array = str.split(',');
			for(var i = 0; i < str_array.length; i++) {
			   str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
			  console.log(str_array[i]);
			  if(str_array[i]==='Credit'){
				  this.setState({
					  'is_credit':1
					})
			  }else if(str_array[i]==='Eviction'){
				  this.setState({
					  'is_eviction':1
				  })
			  }else if(str_array[i]==='Criminal'){
							this.setState({
								'is_criminal':1
							})
						}
			}
			if(res.data[0].management_screen==1){
				this.setState({is_credit:0,is_eviction:0,is_criminal:0,is_management:res.data[0].management_screen})
			}
			if(res.data[0].management_screen==0){
				this.setState({is_management:res.data[0].management_screen})
			}
	}).catch(error=>{
		if(error.res){
			this.setState({myerrormsg: error.res.data});
		}
	})
}

onOpenModal = () => {
	this.setState({ open: true });
};

onCloseModal = () => {
	this.setState({ open: false });
};

handleClick = param => e => {
	this.onOpenModal();
}


	render() {
		if (!getManagerToken()) {
            return (<Redirect to={'/agent/landing'}/>);
					}
		if(this.state.application_status!=undefined && this.state.application_status==3){
			return (<Redirect to={'/agent/application'}/>);
		}	
			
        return (
                <div>
                    <Header />
                    <main>
					<Sidebar/>
					<div className="content">
						<div className="work-section">
							<div>
							{this.state.msg!==''?(<div className="alert alert-success"><strong>{this.state.msg}</strong></div>):(null)}	

							{this.state.myerrormsg!==''?(<div className="alert alert-danger noticeAlert"><strong>{this.state.myerrormsg}</strong></div>):(null)}		
{(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
							{this.state.missingFiles!=''?(<div className="alert alert-danger"><strong>Unable to read {this.state.missingFiles}.</strong></div>):(null)}		

								{(this.state.checkNext == 1 && !this.state.screen_flag)? <div className="form-panel"><div className="form-container">
										<div className='form-widget login-widget'>
											<div className="message">Select tenant screenings to run</div>
											<div className="form-section">
											<form >
												<div className="btn-wrap">
												<div className="btn-wrap">
													<label className="container-rd" style={{cursor:'not-allowed'}}>
														<input type="checkbox" name="is_credit" value={this.state.is_credit} id="is_credit" value={this.state.is_credit} id="is_credit" disabled='disabled'/>
														<span className="checkmark"
													style={
														this.state.is_credit==1
															  ? { background: "#004F6B" }
															  : { background: "#f1f1f1" , color:"black",visibility: "visible" }}
														></span>
														<div className="txt" 
											style={
												this.state.is_credit==1
													  ? {  color: "white" }
													  : {  color:"black",visibility: "visible" }}
														>Credit</div>
													</label>
													{/* <label className="container-rd" style={{cursor:(this.state.is_skyward_runs_credit==1)?'not-allowed':''}}>
														<input type="checkbox"  name="is_eviction" value={this.state.is_eviction} id="is_eviction" onClick={(this.state.is_skyward_runs_credit==1)?'':this.stepHandler} disabled={(this.state.is_skyward_runs_credit==1)?'disabled':''}/>
														<span className="checkmark" 
												style={
													this.state.is_eviction==1
														  ? { background: "#004F6B" }
														  : { background: "#f1f1f1" , color:"black",visibility: "visible" }}
														></span>
														<div className="txt"
															style={
																this.state.is_eviction==1
																	  ? {  color: "white" }
																	  : {  color:"black",visibility: "visible" }}
														>Eviction</div>
													</label> */}
													{/* <label className="container-rd" style={{cursor:(this.state.is_skyward_runs_credit==1)?'not-allowed':''}}>
														<input type="checkbox"  name="is_criminal" value={this.state.is_criminal} id="is_criminal" onClick={(this.state.is_skyward_runs_credit==1)?'':this.stepHandler} disabled={(this.state.is_skyward_runs_credit==1)?'disabled':''}/>
														<span className="checkmark"
														style={
															this.state.is_criminal==1
																  ? { background: "#004F6B" }
																  : { background: "#f1f1f1" , color:"black",visibility: "visible" }}
														></span>
														<div className="txt"
												style={
													this.state.is_criminal==1
														  ? {  color: "white" }
														  : {  color:"black",visibility: "visible" }}		
														>Criminal</div>
													</label> */}
													<label className="container-rd" style={{cursor:'not-allowed'}}>
														<input type="checkbox"  name="is_management" value={this.state.is_management} id="is_management" disabled='disabled' />
														<span className="checkmark"
															style={
																this.state.is_management==1
																	  ? { background: "#004F6B" }
																	  : { background: "#f1f1f1" , color:"black",visibility: "visible" }}
														></span>
														<div className="txt"
															style={
																this.state.is_management==1
																	  ? {  color: "white" }
																	  : {  color:"black",visibility: "visible" }}>Management Runs Credit</div>
													</label>
													</div>

												</div>
												</form>
												<ul className="pagination justify-content-center">
												<li className="page-item left"><button className="btn default prevBtnEditor" type="button" id="prev_button" onClick={this.stepHandler}></button></li>
													<li className="page-item mid"><button to=""></button></li>
													<li className="page-item right"><button className="btn default nextBtnEditor" type="button" id="next_button" onClick={this.stepHandler}></button></li>
												</ul>
												
							
											</div>
										</div>
									</div></div>:''}

									{(this.state.checkNext == 0)? <div className="form-panel"><div className="form-container">
										<div className='form-widget login-widget'>
											<div className="message">Select templates to run</div>
											<div className="form-section search-widget">
												<form >
													<div className="form-group">
														<input className="form-control search"/>
													</div>
													<ul className="auto-search-widget">
													<li><Link to id="standard" className={(this.state.templateName=='standard')?'active':''} onClick={()=>this.selectTemplate('standard')}>Skyward Standard</Link></li>	
														{/* <li><Link to>Skyward Standard</Link></li>
														<li><Link to>Urban American</Link></li>	 */}
														<li><Link to id="Pinnacle" className={(this.state.templateName=='pinnacle')?'active':''} onClick={()=>this.selectTemplate('pinnacle')}>Pinnacle</Link></li>	
														<li><Link to id="city_skyline" className={(this.state.templateName=='city_skyline')?'active':''} onClick={()=>this.selectTemplate('city_skyline')}>City Skyline</Link></li>	
														<li><Link to id="beach_lane" className={(this.state.templateName=='beach_lane')?'active':''} onClick={()=>this.selectTemplate('beach_lane')}>Beach Lane</Link></li>	
														<li><Link to id="monarch_realty_holdings" className={(this.state.templateName=='monarch_realty_holdings')?'active':''} onClick={()=>this.selectTemplate('monarch_realty_holdings')}>Monarch Realty Holdings</Link></li>	
														<li><Link to id="urban_american" className={(this.state.templateName=='urban_american')?'active':''} onClick={()=>this.selectTemplate('urban_american')}>Urban American</Link></li>	
														{/* <li><Link to>Stuyvesant</Link></li>		 */}
													</ul>
												</form>
												<ul className="pagination justify-content-center">
													<li className="page-item mid"><button to=""></button></li>
													<li className="page-item right">{(this.state.templateName=='')?<button className="btn default nextBtnEditor" type="button" id="next_button"></button>:<button className="btn default nextBtnEditor active" type="button" id="next_button" onClick={this.stepHandler}></button>}</li>
												</ul>
											</div>
										</div>
									</div></div>:''}


									

								</div>
							{((this.state.checkNext==2) || (this.state.checkNext == 1 && this.state.screen_flag) || (this.state.checkNext == 1 && this.state.templateName==''))?<div className="form-panel edit-application">
							
								<div className="form-container">
									<div className='form-widget login-widget'>
										<div className="message">Editing application for {this.state.apartment_address}, Unit {this.state.unit_number}</div>
										<div className="form-section agent-a4-section">
												<div class="workarea">
												<div className="clearfix">
														{/* <ul className="editing-menu">
															<li><Link to><img src="/edit.svg" width="50" height="50"/></Link></li>
															<li><Link to><img src="/cursor.svg" width="50" height="50"/></Link></li>
														</ul> */}
														<div className="editing-area" > 
														{(this.state.html!='')?<ContentEditable
														id="divIdToPrint"
              innerRef={this.contentEditable}
              html={this.state.html} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
							onChange={this.handleChange} // handle innerHTML change
              tagName='article' // Use a custom HTML tag (uses a div by default)
            />:<div className="show_loader_on_click" id="show_loader_on_click"></div>}
														</div>
												</div>			
												</div>
												{(this.state.emailMsg)?<div  className="noticeAlert alert alert-success" ref={this.emailMsg}><strong>{this.state.emailMsg}</strong></div>:null}
												 
										</div>
										<div className="row clearfix a4-footer">
												 	<div className="col-md-4">
													 <ul className="pagination justify-content-center">
													<li className="page-item left"><button className="btn default prevBtnEditor" type="button" id="prev_button" onClick={this.stepHandler}></button></li>
													<li className="page-item mid"><button to=""></button></li>
													<li className="page-item right"></li>
												</ul>
													 </div>
													 <div className="col-md-8 text-right d-flex">
													 
													 <button to="#" className="btn btn-default editor-btn" onClick={this.generateDocumentPDF}>Open Documents</button> 
													 
													 {(this.state.checkStatus==false)?<button to="#" className="btn btn-default editor-btn" onClick={this.pdfDownload}>Request Pdf</button>:''} 
													 <button to="#" className="btn btn-default editor-btn" onClick={this.downloadPrevFile}>Download</button>
													 <button to="#" className="btn btn-default editor-btn" onClick={this.openPdfEditor}>Open Editor</button>
																{(this.state.checkStatus==false)?<button to="#" className="btn btn-default editor-btn" data-toggle="modal" data-target="#Emailbox">Email to landlord</button>:''}
													 </div>

														
			<Modal open={this.state.open} onClose={this.onCloseModal} center>
			{this.state.missingFiles!=''?(<div className="alert alert-danger"><strong>Unable to read {this.state.missingFiles}.</strong></div>):(null)}		

			<embed src={this.state.documentList} width="600" height="500" alt="pdf"></embed>
			<p className="text-right" style={{marginRight:'30px'}}><button type="button" id="close" className="btn cl-btn" onClick={this.onCloseModal} >Close  <img src="/close.png"/></button></p>
			</Modal>




													 <div className="modal fade  email-modal emailpopup" id="Emailbox" >
															<div className="modal-dialog">
																<div className="modal-content">
																<button type="button" onClick={this.close} class="close" data-dismiss="modal" id="closeModel">&times;</button>
															
															
																<div className="modal-body profile">
																			<div className="form-section">
																			<div className="form-group">
																			<label>Email application to:</label>                                                 
																			<input className="form-control" placeholder="Email" id="email" value={this.state.email} onChange={this.handleModelChange}  ref={this.emailRef} onBlur={this.emailPick}/>
																			<span className="inline-error-class"> {this.state.myerror !==''?this.state.myerror:''}</span>
																			</div>
																			
																			 <p className="text-right">
													{(this.state.btnStatus==false)?<Link to className="btn">Send</Link>:<Link to className="btn btn-default"  onClick={this.mailSend}>Send</Link>}
																			 </p>
																		</div> 
																		<button type="button" className="btn cl-btn" onClick={this.close}  data-dismiss="modal" id="closeModel">Close  <img src="/close.png" /></button>
																</div>
															


															
																</div>
															</div>
													</div> 
													
												 </div>	
									</div>
								</div>
								

							</div>:''}

							<div></div>
							
									
						</div>
					</div>	


                        
                          
                    </main>     
                </div>

                );
    }
}

export default AppEditor;
