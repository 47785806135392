import React from 'react';
import {Link} from 'react-router-dom';
import { getApiData, getToken, getUserId,getOnBoardingDone } from '../../services/Api';
import {DEFAULT_AGENT_NAME} from '../../services/Const';

class Disclosure extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
                id:'',
                name: "",
                agent_name: "",
                signature: "",
                clientId: getUserId(),
                mymsg: "",
                completed_date:'',
                current_date: new Date(),
                is_completed:false,
                myerrormsg:"",
                details:(props.onBoarding)?props.onBoarding:[]
        };
    }
ShowDate() {
        if(this.state.details.is_completed){
            var d = new Date(this.state.details.completed_date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [month, day, year].join('-');
        }
}

    render() {
         const { details } = this.state;
       if(details.id){
        return (
               <div>
                  
                    <div className="modal-content client-modal">
                    <div className="modal-header">
                    <h4 className="modal-title head">View Agency & Fee Disclosure</h4>
                    </div>
                    <div className="modal-body profile">
									
										
									<p><img src="/images/app-logo.png" width="60" className="mx-auto d-block" /></p>
                                              <p className="text-center"> <strong>New York State<br/> Department of State Division of Licensing Services
                                              P.O. Box 22001 Albany, NY 12201-2001<br/>
                                              Customer Service: (518) 474-4429<br/>
                                              www.dos.state.ny.us
                                              </strong></p>


                                          

                                        <h4>THIS  IS  NOT  A  CONTRACT</h4>
                                        <p><em>New York State law requires real estate licensees who are  acting  as  agents  of  landlords  and  tenants  of  real property  to  advise  the  potential  landlords  and  tenants with whom they work of the nature of their agency rela-tionship  and  the  rights  and  obligations  it  creates.  This disclosure will help you to make informed choices about your relationship with the real estate broker and its sales agents.</em></p>
                                        <p><em>Throughout the transaction you may receive more than one  disclosure  form.  The  law  may  require  each  agent assisting in the transaction to present  you with this dis- closure form. A real estate agent is a person qualified to advise about real estate.</em></p>
                                        <p><em>If  you  need  legal,  tax  or  other  advice,  consult  with  a professional in that field.</em></p>
                                        <h5>Disclosure Regarding Real Estate Agency Relationships </h5>
                                        <h4>Landlord's Agent</h4>
                                        <p>A landlord's agent is an agent who is engaged by a land- lord  to  represent  the  landlord's  interest.  The  landlord's agent  does  this  by  securing  a  tenant  for  the  landlord's apartment  or house at a  rent  and on terms  acceptable to the  landlord.  A  landlord's  agent  has,  without  limitation, the following fiduciary duties to the landlord: reasonable care,  undivided  loyalty,  confidentiality,  full  disclosure, obedience  and  duty to  account.  A  landlord's  agent  does not  represent  the  interests  of  the  tenant.  The  obligations of a landlord's agent are also subject to any specific pro- visions set forth in an agreement between the agent and the  landlord.  In  dealings  with  the  tenant,  a  landlord's agent should (a) exercise reasonable skill and care in per- formance  of  the  agent's  duties;  (b)  deal  honestly,  fairly and in good faith; and (c) disclose all facts known to the agent  materially  affecting  the  value  or  desirability  of property, except as otherwise provided by law.</p>
                                        <h4>Tenant's Agent</h4>
                                        <p>A tenant's agent is an agent who is engaged by a tenant to represent  the  tenant's  interest.  The  tenant's  agent  does this by negotiating the rental or lease of an apartment or house at a rent and on terms acceptable to the tenant. A tenant's agent has, without limitation, the following fidu- ciary  duties  to  the  tenant:  reasonable  care,  undivided loyalty,  confidentiality,  full  disclosure,  obedience  and duty to account. A tenant's agent does not represent the interest  of  the  landlord.  The  obligations  of  a  tenant's agent are also subject to any specific provisions set forth in  an  agreement  between  the  agent  and  the  tenant.  In dealings with the landlord, a tenant's agent should (a) ex- ercise  reasonable  skill  and  care  in  performance  of  the agent's duties; (b) deal honestly, fairly and in good faith; and  (c)  disclose  all  facts  known  to  the  agent  materially affecting  the  tenant's  ability  and/or  willingness  to  per- form  a  contract  to  rent  or  lease  landlord's  property  that are not consistent with the agent's fiduciary duties to the tenant.</p>
                                        <h4>Broker's Agents</h4>
                                        <p>A broker's agent is an agent that cooperates or is engaged by a listing agent or a tenant's agent (but does not work for the same firm as the listing agent or tenant's agent) to assist  the  listing  agent  or  tenant's  agent  in  locating  a property to rent or lease for the listing agent's landlord or the  tenant  agent's  tenant.  The  broker's  agent  does  not have a direct relationship with the tenant or landlord and the tenant or landlord can not provide instructions or di- rection directly to the broker's agent. The tenant and the landlord therefore  do not have vicarious liability for the acts  of  the  broker's  agent.  The  listing  agent  or  tenant's agent do provide direction and instruction to the broker's agent and therefore the listing agent or tenant's agent will have liability for the acts of the broker's agent.</p>
                                        <h4>Dual Agent</h4>
                                        <p>A real estate broker may represent both the tenant and the landlord  if  both  the  tenant  and  landlord  give  their  in-formed  consent  in  writing.  In  such  a  dual  agency situa- tion, the agent will not be able to provide the full range of fiduciary duties to the landlord and the tenant. The obli- gations of an agent are also subject to any specific provi- sions set forth in an agreement between the agent, and the tenant and landlord. An agent acting as a dual agent must explain carefully to both the landlord and tenant that the agent  is  acting  for  the  other  party  as  well.  The  agent should also explain the possible effects of dual represen- tation,  including  that  by  consenting  to  the  dual  agency relationship  the  landlord  and  tenant  are  giving  up  their right to undivided loyalty.  A landlord and tenant  should carefully  consider  the  possible  consequences  of  a  dual agency  relationship  before  agreeing  to  such  representa- tion. A landlord or tenant may provide advance informed consent  to  dual  agency  by  indicating  the  same  on  this form.</p>
                                        <h4>Dual Agent with Designated Sales Agents</h4>
                                        <p>If the tenant and the landlord provide their informed con- sent  in  writing,  the  principals  and  the  real  estate  broker who represents both parties as a dual agent may designate a  sales  agent  to  represent  the  tenant  and  another  sales agent to represent the landlord. A sales agent works under the  supervision  of  the  real  estate  broker.  With  the  in- formed consent in writing of the tenant and the landlord, the designated sales agent for the tenant will function as the tenant's agent representing the interests of and advo- cating  on  behalf  of  the  tenant  and  the  designated  sales agent  for  the  landlord  will  function  as  the  landlord's agent representing the interests of and advocating on be- half of the landlord in the negotiations between the tenant and the landlord. A designated sales agent cannot provide the full range of fiduciary duties to the landlord or tenant. The designated sales agent must explain that like the dual agent under whose supervision they function, they cannot provide  undivided  loyalty.  A  landlord  or  tenant  should carefully  consider  the  possible  consequences  of  a  dual agency  relationship  with  designated  sales  agents  before agreeing to such representation. A landlord or tenant may provide  advance  informed  consent  to  dual  agency  with designated  sales  agents  by  indicating  the  same  on  this form. </p>
                                        
                                        
                                      <p>This form was provided to me by  <strong>{(details.agent_name)?details.agent_name:DEFAULT_AGENT_NAME}</strong> of Skyward Steel, LLC a licensed real estate broker acting in the interest of the:</p>
                                      
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Landlord as a (check relationship below)</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                        <span className="box fill"></span> <span className="info-txt">Tenant as a (check relationship below) </span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Landlord's agent</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                        <span className="box fill"></span> <span className="info-txt">Tenant's agent </span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Broker's agent </span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Broker's agent</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Dual agent</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                        <span className="box blank"></span> <span className="info-txt">Dual agent with designated sales agent For advance informed consent to either dual agency or dual agency with designated sales agents complete section below:</span>
                                        </label>
                                        </div>

                                        <div className="form-check">
                                        <label className="form-check-label">
                                        <span className="box fill"></span> <span className="info-txt">Advance informed consent dual agency</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Advance informed consent to dual agency with designated sales agents</span>
                                        </label>
                                        </div>
                                       
                                                                           
                                       

                                        <p>(We)  <strong>{details.name} acknowledge receipt of a copy of this disclosure form</strong> </p>
                                        <p>
                            			Signature : <span style={{fontFamily: "'Caveat', cursive",fontSize:'2em'}}><i>{details.signature}</i></span>
                            </p>
                             <p><strong>DATE</strong></p> <span>{this.ShowDate()}</span>   <hr/>
									
                                        <h5 className="text-center">Commission Agreement </h5>
                             <p><strong>DATE</strong></p> <span>{this.ShowDate()}</span>             
                                        <h4>TL;DR:</h4>
                                        <p>By signing below, you acknowledge that:</p>
                                            <ul>
                                                 <li> Viewing apartments will always be free, but if you apply for one and are approved, there may be a fee. </li>
                                                <li>We’ll always be upfront and let you know the fee in writing.</li>
                                                <li>Let us deal with the landlord! If we showed you the apartment and you’re approved for it (even if you applied directly with the landlord or another broker), you have to pay us the fee on the apartment.</li>
                                            </ul>
                                        <h5>Lawyerspeak:</h5>
                                        <p>There is no fee for viewing apartments through our platform or with one of our agents. Should you decide to lease an apartment that we have shown to you, the written commission on the listing (or 15% of the annual rent if none is specified) is due when you are approved by the Landlord. This fee applies whether or not you move into the apartment. You agree that Skyward is entitled to this commission if you decide to rent an apartment in a property, building or complex shown to you by Skyward or any of its Agents acting on its behalf whether you lease the property through us, the landlord, or another broker. This agreement shall remain in effect for one year AND SHALL SUPERCEDE ANY OTHER AGREEMENT MADE BY ANY AGENT UNLESS APPROVED IN WRITING BY MANAGEMENT. </p>
                                        <p>By signing below, you acknowledge the above terms and authorize us to act as your agent for the procurement of any properties shown to you by Skyward. Under no circumstances shall any form of compensation (monetary or non-monetary) be made payable directly to an Agent acting on behalf of Skyward. All fees must be directly paid to Skyward. </p>
                                        <p>It is also hereby acknowledged that in certain instances Skyward may receive additional compensation from the owner/landlord of the property and such compensation does not forgo or offset brokerage commission that you have agreed to pay to Skyward pursuant to the terms of this client registration/commission agreement. Thank you for allowing us to be of service to you.</p>
                                        
                                        <p><strong>{details.name}</strong></p>
                                        <p>Signature : <span style={{fontFamily: "'Caveat', cursive",fontSize:'2em'}}><i>{details.signature}</i></span></p>
                                    






                                        <button type="button" className="btn cl-btn pull-right" onClick={this.props.onClick} >Close  <img src="/close.png"/></button>
											
									</div>
								


								
									</div>
                      	  </div>
                );
    }else{
        return (<div></div>);
    }
    }
}
export default Disclosure;