import React, {lazy} from "react";
import { Link } from "react-router-dom";
import AgentHeader from "../../common/AgentHeader";
import AgentSideBar from "../../common/AgentSideBar";
import ManagerHeader from "../../common/ManagerHeader";
import ManagerSidebar from "../../common/ManagerSidebar";
import { Redirect } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "react-input-range/lib/css/index.css";
import Switch from "react-switch";
import { validatePhone } from "../../../services/validation";
import { getApiData, postApiData, getAgentId,getAgentDomain } from "../../../services/Api";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fromBlob } from 'image-resize-compress';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Moment from 'moment';
import Dropzone  from 'react-dropzone';
import { isNullOrUndefined, isNull } from 'util';
// var addCount=[1];
// var finalOpenHouseArr=[];
class ListingCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      listing_info_address: "",
      listing_info_address_org:'',
      unit_number: "",
      bedrooms: "",
      bathrooms: "",
      square_footage: "",
      description: "",
      price: "",
      date_available: "",
      min_lease_term: "",
      furnished: 0,
      building_ownership:"", 
      maintenance:"",
      monthly_taxes:"",
      commission:"",
      tax_deduction:"",
      flip_tax:"",
      RLSFlag:0,
      max_financing:"",
      number_of_shares:"",
      percentofcommon:"",
      Exclusive_expiration_Date:"",
      unit_amenities: [],
      marketing: [],
      incentives: "",
      tenant_incentive: "",
      unit_condition:"",
      unit_view:"",
      Exposures:[],  
      pets_policy:'', 
      imagePath: "",
      kuula_vr_link: "",
      listing_information: "",
      noFee: 0,
      imagePathArray: [],
      imagePathfloorArray: [],
      lat:'',
      long:'',
      //error
      finalOpenHouseArr:[{
        'date':'',
        'start_time':'',
        'end_time':''}],
      building_address_error: "",
      building_name_error: "",
      landlord_name_error: "",
      landlord_email_error: "",
      landlord_phone_error: "",
      kuula_vr_link_error:"",
      unit_number_error: "",
      bedrooms_error: "",
      bathrooms_error: "",
      pets_policy_error:'',
      square_footage_error:"",
      price_error: "",
      min_lease_term_error: "",
      date_available_error: "",
      Exclusive_expiration_Date_error:"",
      rent_hop_export:0,
      //sales info error
      maintenance_error:"",
      monthly_taxes_error:"",
      commission_error:"",
      tax_deduction_error:"",
      flip_tax_error:"",
      max_financing_error:"",
      number_of_shares_error:"",
      percentofcommon_error:"",
      description_error:"",
      unit_condition_error:"",
      unit_view_error:"",
      default_application_template_error: "",
      screenings_required_error: "",
      views_remarks:'',
      data:[],
      linkingLoader: false,
      myerrormsg:'',
      flag:0,
      successQuote:'0',
      imageForDb:[],
      imageForfloorDb:[], 
      building_address_auto:'',
      buildingList:[],
      building_id:0,
      listing_info_address:'',
      selected_building_name: 'skyward',
      startDate:'',
      buildingDW:'',
      buildingCW:'',
      imageCount:0,
      isImage: false,
      tabFlag:false,
      total_rooms:'',
      layoutData:'',
      total_rooms_error:'',
      startDate:'',
      openHouseDate:new Date(),
      
      openHouseData:[],
      brokerOnly: false,
      appointmentOnly:false,
      VOWEntireListingDisplay:false,
      VOWAddressDisplay:false,
      VOWConsumerComment:false,
      VOWAutomatedValuationDisplay:false,
      NewDevelopment:false,
      IDXEntireListingDisplay:false,
      Bonus:false,
      FreeRent:false,
      BonusRemarks:'',
      FreeRentRemarks:'',
      ShowingInstructions:'',
      PreWarYN:false,
      LobbyAttendant:false,
      FurnishedListPrice:'',
      FurnishedMinLeaseMonths:'',
      FurnishedMaxLeaseMonths:'',
      CoBrokeAgreement:'',
      newShowing_agentList:[],
      secondary_agent_error:'',
      secondary_agent_id:'',
      third_agent_error:'',
      third_agent_id:'',
      streeteasy_status:1,
      RLS_status:1,
      renthop_status:1,
      renthop_approval:true,
      skyward_approval:true,
      streetEasy_approval:true,
      status:1,
      video_link: "",
      video_link_error: "",
    };
    this.handleChangeDate= this.handleChangeDate.bind(this);
    this.handleAddBuilding= this.handleAddBuilding.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDropfloor = this.onDropfloor.bind(this);
  }

  rotate= param=>event =>{
    const { imagePathArray } = this.state;
    var index = param[1];
    let newRotation = param[2]+90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState(state => (this.state.imagePathArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForDb[index].current_rotation = newRotation))
  }

  rotateleft= param=>event =>{
    const { imagePathArray } = this.state;
    var index = param[1];
    let newRotation = param[2]-90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState(state => (this.state.imagePathArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForDb[index].current_rotation = newRotation))
  }

  rotatefloor= param=>event =>{
    const { imagePathfloorArray } = this.state;
    var index = param[1];
    let newRotation = param[2]+90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState(state => (this.state.imagePathfloorArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForfloorDb[index].current_rotation = newRotation))

  }

  rotateleftfloor= param=>event =>{
    const { imagePathfloorArray } = this.state;
    var index = param[1];
    let newRotation = param[2]-90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState(state => (this.state.imagePathfloorArray[index].current_rotation = newRotation))
    this.setState(state => (this.state.imageForfloorDb[index].current_rotation = newRotation))
  }

  onDrop = (files) => {
    var imageFile = files;
  
  
    if (files) {
      for(var i=0;i<imageFile.length;i++){
      let imgFlag = true;
      const maxFileSize = 5242880; //5mb
      const value = files[i].name;
    
      const ext = value.substring(value.lastIndexOf("."));
      if (![".jpg", ".JPG", ".jpeg", ".png"].includes(ext)) {
        this.setState({ mymsg: ext + " is not supported file extension." });
        imgFlag = false;
      }
      if (files[i].size > maxFileSize) {
        this.setState({ mymsg: "The uploaded image is too large. The max image file size is set to 5MB" });
        imgFlag = false;
      }
      if (imgFlag) {
              
        fromBlob(imageFile[i], 80, 'auto', 'auto', 'webp')
          .then(compressedFile => {

            var file = new File([compressedFile], value);
            var temp = [...this.state.imagePathArray];
            var temp2 = [...this.state.imageForDb];

            temp.push({file:URL.createObjectURL(file),"current_rotation":0});
            temp2.push({file:file,"current_rotation":0});
            this.setState(
              {
                imageForDb: temp2,
                imagePathArray: temp
              },
              () => {
                
                this.state.imageCount=this.state.imagePathArray.length;
              
                this.state.isImage=true;
            

                }
            );
          })
          .catch(function(error) {
            console.log(error.message);
          });
      }
    }
    }
  };

  onDropfloor = (filesfloor) => {
    var imageFile = filesfloor;

  if (filesfloor) {
    for(var i=0;i<imageFile.length;i++){
    let imgFlag = true;
    const maxFileSize = 5242880; //5mb
    const value = filesfloor[i].name;
   
    const ext = value.substring(value.lastIndexOf("."));
    if (![".jpg", ".JPG", ".jpeg", ".png",".pdf"].includes(ext)) {
      this.setState({ mymsgfloor: ext + " is not supported file extension." });
      imgFlag = false;
    }
    if (filesfloor[i].size > maxFileSize) {
      this.setState({ mymsgfloor: "The uploaded image is too large. The max image file size is set to 5MB"});
      imgFlag = false;
    }
    if (imgFlag) {


      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      fromBlob(imageFile[i], 80, 'auto', 'auto', 'webp')
        .then(compressedFile => {
         

          var file = new File([compressedFile], value);
          var temp = [...this.state.imagePathfloorArray];
          var temp2 = [...this.state.imageForfloorDb];

          temp.push({file:URL.createObjectURL(file),"current_rotation":0});
          temp2.push({file:file,"current_rotation":0});
          this.setState(
            {
              imageForfloorDb: temp2,
              imagePathfloorArray: temp
            },
            () => {
              

              }
          );
        })
        .catch(function(error) {
          console.log(error.message);
        });
    }
  }
  }
  };

  componentWillMount=()=>{
    this.getData();
    this.layout();
    this.ownerpays();
    this.agentSlots();
    this.buildingType();
    this.cobrokeAgreement();
    this.airConditionType();
    this.agentOpt();
    this.setState({
      date_available:new Date(),
      startDate:new Date()
    })
        // this.buildingOpt();
  }


  layout =()=>{
    let where=[{
      url:'/agent/layout'
    }]
    postApiData(where,{'tableName':'listing_layout'},true).then(res=>{
      console.log("res.data",res.data);
        this.setState({
          layoutData:res.data
        })
       
    }).catch(error=>{

    })
  }

  ownerpays=()=>{
    let where=[{
      url:'/agent/ownerPays'
    }];
    postApiData(where,{'tableName':'owner_pays_broker_master'},true).then(res=>{
      console.log("--res",res);
      this.setState({owner_pays_data:res.data})
    }).catch(error=>{

    })
  }

  buildingType=()=>{
    let where=[{
      url:'/agent/buildingtype'
    }];
    postApiData(where,{'tableName':'building_type_master'},true).then(res=>{
          console.log("res---",res);
          this.setState({
            building_type:res.data
          })
    }).catch(error=>{
      console.log("error---",error);
    })
  }

  cobrokeAgreement=()=>{
    let where=[{
      url:'/agent/cobrokeagreement'
    }];
    postApiData(where,{'tableName':'co_broke_agreement'},true).then(res=>{
      console.log("--res",res.data);
      this.setState({
        co_broke_agreement:res.data
      })
    }).catch(error=>{

    })
  }

  airConditionType=()=>{
    let where=[{
      url:'/agent/airconditiontype'
    }];
    postApiData(where,{'tableName':'air_conditioning_type_master'},true).then(res=>{
      console.log("--res",res.data);
      this.setState({
        air_conditioning_type_master:res.data
      })
    }).catch(error=>{

    })
  }


  handleChangeDate(date) {
    this.setState({
      startDate: date,
      date_available:date
    });
  }

  vatidateduplidaterow=(index)=>{
    var datevalue= this.state.finalOpenHouseArr[index].date;
    var startvalue = this.state.finalOpenHouseArr[index].start_time;
    var endvalue = this.state.finalOpenHouseArr[index].end_time;
    
    if(endvalue!="" && startvalue!="" && new Date(startvalue).toTimeString() >=new Date(endvalue).toTimeString()){
      alert("End time should be greater than Start time");
      this.setState(state=>(this.state.finalOpenHouseArr[index]={
        date:datevalue,
        start_time:startvalue,
        end_time:''
      },state)) ;
    }
    if(datevalue!="" && startvalue!="" && endvalue!="" ){
    
      this.state.finalOpenHouseArr.map((item,_index_)=>{
        
       if(new Date(item.date).getTime()==new Date(datevalue).getTime() && new Date(item.start_time).toTimeString()==new Date(startvalue).toTimeString() && new Date(item.end_time).toTimeString()==new Date(endvalue).toTimeString() && _index_!=index){
        this.setState(state=>(this.state.finalOpenHouseArr[index]={
          date:'',
          start_time:'',
          end_time:''
        },state)) ;
       }
      })
    }
    }
  
  handleDateChange=(param)=>event=> { 

    if(param[1]=='_date_'){
     this.setState(state=>(this.state.finalOpenHouseArr[param[0]].date=event,state),()=>{
      this.vatidateduplidaterow(param[0]);
    }) ;
    }
    if(param[1]=='_start_time_'){
      this.setState(state=>(this.state.finalOpenHouseArr[param[0]].start_time=event,state),()=>{
        this.vatidateduplidaterow(param[0]);
      }) ;
    }
    if(param[1]=='_end_time_'){
      this.setState(state=>(this.state.finalOpenHouseArr[param[0]].end_time=event,state),()=>{
        this.vatidateduplidaterow(param[0]);
      }) ;
    }

    }
  handleChangeExpirationDate=(expirationDate)=> {
    if(expirationDate=="" || expirationDate==null ){
    
      this.setState({
        Exclusive_expiration_Date:'',
        Exclusive_expiration_Date_error:'field cannot be empty'
  
      });
    }
    else{
      this.setState({
        Exclusive_expiration_Date:expirationDate,
        Exclusive_expiration_Date_error:''
      });
    }
  }

  handleAddBuilding()
  {
     var addr=document.getElementById('Address').value
     console.log("---->",this.props.userType)
     if(this.props.propsValue[0].userType=="Agent")
     {
      console.log("---->",this.props.propsValue[0].userType)
        window.location.href="/agent/building/add/0/0/"+addr;
     }
     if(this.props.propsValue[0].userType=="Manager")
     {  console.log("---->",this.props.propsValue[0].userType)
        window.location.href="/manager/build/0/0/"+addr;
     }
  }
async buildingOpt(addr){
    let buildingParam = [{
            url: "/nestio/getbuildingbyaddress/"+addr
    }];
        getApiData(buildingParam,true).then(res => {
         
        this.setState({'buildingList':res.data});
        if(!isNullOrUndefined(res.data[0].building_type) && res.data[0].building_type!=''){
          document.getElementById('buildingType').value = res.data[0].building_type ;
          // document.getElementById('unit_view').disabled = true;
          
            }else{
              // document.getElementById('unit_view').disabled =false ;
            }
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
    }   
handleBuildingSearch = event => {
    var selected_building_name = event.target.getAttribute('name');

    this.setState({
        selected_building_name: selected_building_name
    })
        var key=event.target.id; 
    switch(key){
        case 'Address':
              
                this.setState({searchParm:event.target.value,listing_info_address: event.target.value},()=>{
      //if(this.state.searchParm.length>-1){
                var value=this.state.searchParm;
                if(value!='' && value.length>2){
                  this.buildingOpt(value);
                 
      this.state.buildingDW = this.state.buildingList.map((aw,index) => 
    (aw.id>0) ? <li id="searchkey" key={aw.id} value={aw.id} name={aw.building_address} onClick={this.handleBuildingSearch}>{aw.building_address}</li>:<li onClick={this.handleAddBuilding}>No match found to entered criteria. Add a new Building.</li>);


                    this.state.buildingList.forEach(function(building,index) {
                        if(building.building_address.trim().toLowerCase().includes(value.trim().toLowerCase())){
                                building.flag=1;
                            }else{
                                building.flag=0;
                            }
                        }); 
                }else{
                  document.getElementById('building_search_id_values').style.display = 'none';
                    this.state.buildingList.forEach(function(building,index) {
                        building.flag=0;
                    });
                    
                }
            })       
                //}
    break;
    case 'searchkey':
    var value=event.target.value;
                let $this = this;
      this.setState({ agent_id: event.target.value}, () => {
        this.state.buildingList.forEach(function(building,index) {
          if(building.id==value){
              document.getElementById('building_search_id_values').style.display = "none";
                                            document.getElementById('Address').value=building.building_address;
                                            $this.setState({listing_info_address:building.building_address});
                                            $this.setState({listing_info_address_org:building.building_address});
                                            $this.setState({lat:building.latitude});
                                            $this.setState({long:building.longitude});
                                            $this.setState({building_id:building.id});
                                            $this.setState({PreWarYN:(building.pre_war=="false")?false:true});
                                            $this.setState({LobbyAttendant:(building.lobby_attendant=="false")?false:true});
                                            $this.setState({Year_Built:building.year_built});
                                            $this.setState({NumberOfUnitsTotal:building.number_of_units_total});
                                            $this.setState({LegalRoomsTotal:building.legal_rooms_total})
                                            $this.setState({StoriesTotal:building.stories_total})
                                            $this.setState({TaxBlock:building.tax_block});
                                            $this.setState({TaxLot:building.tax_lot});
                                            $this.setState({LotWidth:building.lot_width});
                                            
                                            $this.setState({buildingType:building.building_type});
                                            document.getElementById('buildingType').value = building.building_type ;
                                            // if(building.pre_war=='true')
                                            // document.getElementById('PreWarYN').checked=true;
                                            // else
                                            // document.getElementById('PreWarYN').checked=false;

                                            // if(building.lobby_attendant=='true')
                                            // document.getElementById("LobbyAttendant").checked=true;
                                            // else
                                            // document.getElementById("LobbyAttendant").checked=false;
                                         
          }else{
            building.flag=0;
          }
        });
          
        document.getElementById('building_search_id_values').style.display = "none";
        });
    break;
    case 'default':
    break;
     
    }
    document.getElementById('building_search_id_values').style.display = "block";
}

contains (arr, key, val) {
  let flag=0;
  let count=0;
  for (var i = 0; i < arr.length; i++) {
    count=count+1;
    if(arr[i][key] == val){
      flag=1;
      return true;
    } 
  }
  if(flag==0 && count==arr.length){
    return false;
  }
 
}

  getData=()=>{
    let applicationList = [{
          url: "/creator",
    }];
   
      postApiData(applicationList,{'additional_documents':'additional_documents','application_template':'application_template','application_screening':'application_screening','building_amenities':'building_amenities','marketing':'marketing'},false).then(res => {
        console.log('data ==> ', res.data);
        this.setState({
          linkingLoader: true,
          data:res.data,
        },()=>{
          res.data[4].map((value)=>{
            var val=this.contains(this.state.marketing, "name", value.name); //true
            if(value.approval_required==1 && value.name=='Renthop'){
              this.setState({renthop_approval:false});
              if(value.default_set==1){
                this.setState({renthop_status:0});
              }
            }
            
            if(value.approval_required==1 && value.name=='Skyward'){
              this.setState({skyward_approval:false});
              if(value.default_set==1){
                this.setState({status:0});
              }
            }
            if(value.approval_required==1 && value.name=='Localize'){
              this.setState({streetEasy_approval:false});
              if(value.default_set==1){
                this.setState({streeteasy_status:0});
              }
            }
            if(value.approval_required==1 && value.name=='RLS'){
              this.setState({RLS_approval:false});
              if(value.default_set==1){
                this.setState({RLS_status:0});
              }
            }
            if( value.default_set==1 &&  value.name=='RLS'){
              this.setState({RLSFlag:1});
            }
            if(this.state.marketing.length==0 && value.default_set==1){
              this.setState(state => (this.state.marketing.push({related_id:value.id,name:value.name})));
            }else if(val && value.default_set==1)
            {
              this.setState(state => (this.state.marketing.push({related_id:value.id,name:value.name})));
            }
              this.setState({ linkingLoader: false, flag:1})
          })
      })

      }).catch(error => {
      if(error.response){
          this.setState({myerrormsg: error.response.data.error.message});
      }
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    var Exposures='';
         this.state.Exposures.map(item=>{
          Exposures +=  item.name+',';
         })
    if(document.getElementById("layout")){
      var layout = document.getElementById("layout").value;
      }
      if(document.getElementById("unit_view")){
      var unit_view=document.getElementById("unit_view").value;
      }
      if(document.getElementById("unit_condition")){
      var unit_condition=document.getElementById("unit_condition").value;
      }
      if(document.getElementById("pets_policy")){
      var pets_policy=document.getElementById("pets_policy").value;
    }
      if(document.getElementById("owner_pays_broker")){
      var owner_pays_broker=document.getElementById("owner_pays_broker").value;
    }
      if(document.getElementById("buildingType")){
      var buildingType=document.getElementById("buildingType").value;
    }
      if(document.getElementById("CoBrokeAgreement")){
          var CoBrokeAgreement=document.getElementById("CoBrokeAgreement").value;
      }
      if(document.getElementById("air_conditioning_type")){
      var AirConditioningType=document.getElementById("air_conditioning_type").value;
      }
   
    const {
      listing_information,listing_info_address,unit_number,bedrooms,bathrooms,
      square_footage,description, price,date_available,
      unit_amenities,marketing,kuula_vr_link,imageForDb,imageForfloorDb,linkingLoader,
      building_ownership, maintenance,monthly_taxes,commission,tax_deduction,flip_tax,furnished,
      max_financing, number_of_shares,percentofcommon,Exclusive_expiration_Date,lat ,long,building_id, video_link
    } = this.state;

   
    let where = [{
      url: '/creator/salesDetailAdd'
    }];
    const formData = new FormData();
    formData.append('listing_information',listing_information)
    formData.append('listing_info_address',listing_info_address)
    formData.append('unit_number',unit_number)
    formData.append('bedrooms',bedrooms)
    formData.append('bathrooms',bathrooms)
    formData.append('square_footage',square_footage)
    formData.append('description',description)
    formData.append('price',price)
    formData.append('maintenance',maintenance)
    formData.append('monthly_taxes',monthly_taxes)
    formData.append('commission',commission)
    formData.append('tax_deduction',tax_deduction)
    formData.append('flip_tax',flip_tax)
    formData.append('max_financing',max_financing)
    formData.append('number_of_shares',number_of_shares)
    formData.append('percentofcommon',percentofcommon)
    formData.append('exposures',Exposures)
    formData.append('unit_view',unit_view)
    formData.append('unit_condition',unit_condition)
    formData.append('Exclusive_expiration_Date',Exclusive_expiration_Date)
    formData.append('date_available',date_available)
    formData.append('unit_amenities',JSON.stringify(unit_amenities))
    formData.append('kuula_vr_link',kuula_vr_link)
    formData.append('listing_type','Sales')
    formData.append('building_ownership',building_ownership)
    formData.append('building_id',building_id)
    formData.append('latitude',lat)
    formData.append('longitude',long)
  //   if(this.props.propsValue[0].userType =="Manager"){
  //     formData.append('agent_id',0)
  //     formData.append('status',1)
  //   }
  //  else if(marketing.length === 1 && marketing[0].name == "Renthop"){
  //     formData.append('agent_id',getAgentId());
  //     formData.append('status',1)
  //     formData.append('rent_hop_export',this.state.rent_hop_export)
  //   }
  //   else{
  //   formData.append('agent_id',getAgentId());
  //   formData.append('status',0)
  //   formData.append('rent_hop_export',this.state.rent_hop_export)
  //   }

    if(this.props.propsValue[0].userType =="Manager"){
      formData.append('agent_id',0)
      formData.append('status',1)
      formData.append('streeteasy_status',1)
      formData.append('RLS_status',1)
      formData.append('renthop_status',1)
      formData.append('marketing',JSON.stringify(marketing))
    }
    if(this.props.propsValue[0].userType =="Agent"){
      formData.append('agent_id',getAgentId())
      formData.append('rent_hop_export',this.state.rent_hop_export)
      if(getAgentDomain()==='kw.com'){
        formData.append('status',0)
        formData.append('domain','kw')
        formData.append('marketing',JSON.stringify([{related_id:'0',name:'KW'}]))
      }
      else{
        formData.append('marketing',JSON.stringify(marketing))
        formData.append('status',this.state.status)
      }
      formData.append('streeteasy_status',this.state.streeteasy_status)
      formData.append('RLS_status',this.state.RLS_status)
      formData.append('renthop_status',this.state.renthop_status)
    }

    imageForDb.map((image,index)=>{
      formData.append('images',image.file);
   
    })
    imageForDb.map((image,index)=>{
         formData.append('images_rotation',image.current_rotation);
      
       })
    imageForfloorDb.map((image,index)=>{
      formData.append('imagesfloor',image.file);
    
    })
    imageForfloorDb.map((image,index)=>{
      formData.append('imagesfloor_rotation',image.current_rotation);
    
    })
    formData.append('layout',layout)
    formData.append('pets_policy',pets_policy)
    formData.append('total_rooms',this.state.total_rooms)
    formData.append('BuildingType',buildingType)
    formData.append('PreWarYN',this.state.PreWarYN)
    formData.append('LobbyAttendant',this.state.LobbyAttendant)
    formData.append('furnished',furnished)
    formData.append('Year_Built',this.state.Year_Built)
    formData.append('NumberOfUnitsTotal',this.state.NumberOfUnitsTotal)
    formData.append('LegalRoomsTotal',this.state.LegalRoomsTotal)
    formData.append('StoriesTotal',this.state.StoriesTotal)
    formData.append('TaxBlock',this.state.TaxBlock)
    formData.append('TaxLot',this.state.TaxLot)
    formData.append('LotWidth',this.state.LotWidth)
    
    formData.append('VOWEntireListingDisplay',this.state.VOWEntireListingDisplay)
    formData.append('VOWAddressDisplay',this.state.VOWAddressDisplay)
    formData.append('VOWConsumerComment',this.state.VOWConsumerComment)
    formData.append('VOWAutomatedValuationDisplay',this.state.VOWAutomatedValuationDisplay)
    formData.append('NewDevelopment',this.state.NewDevelopment)
    formData.append('IDXEntireListingDisplay',this.state.IDXEntireListingDisplay)
    formData.append('Bonus',this.state.Bonus)
    formData.append('FreeRent',this.state.FreeRent)
    formData.append('CoBrokeAgreement',CoBrokeAgreement)
    formData.append('ShowingInstructions',this.state.ShowingInstructions)
    formData.append('FurnishedListPrice',this.state.FurnishedListPrice)
    formData.append('FurnishedMinLeaseMonths',this.state.FurnishedMinLeaseMonths)
    formData.append('FurnishedMaxLeaseMonths',this.state.FurnishedMaxLeaseMonths)
   // formData.append('LeaseTerm',this.state.LeaseTerm)
   // formData.append('MaxLeaseMonths',this.state.MaxLeaseMonths)
    formData.append('BonusRemarks',this.state.BonusRemarks)
    formData.append('FreeRentRemarks',this.state.FreeRentRemarks)
    formData.append('views_remarks',this.state.views_remarks)
    formData.append('air_conditioning_type',AirConditioningType)
    formData.append('openHouse',JSON.stringify(this.state.finalOpenHouseArr))
    formData.append('broker_only',this.state.brokerOnly)
    formData.append('appointment_only',this.state.appointmentOnly)
    formData.append("secondary_agentId", this.state.secondary_agent_id)
    formData.append("tertiary_agentId",this.state.third_agent_id)
    formData.append('video_link',video_link)
   
      postApiData(where,formData).then(res => {
                  console.log('res : ', res.data);
                  console.log('res : ', res);
                    if(res.data==202) // listing already exist
                    {
                      this.setState({linkingLoader:false, successQuote: '2',});
                           window.scrollTo(500, 0);
                    }
              else
                    {
                      this.setState({ linkingLoader:true},()=>{
                        this.setState({
                          tabFlag:true
                        })
                        //  if(this.props.propsValue[0].userType =="Agent")
                        // // window.location.href="../list/edit/?tabType="+this.props.propsValue[0].tabPanel;
                        // {
                        //     return <Redirect to={'../list/edit'}/>
                        // } 
                        //  if(this.props.userType=="Manager")
                        //   // window.location.href="../../managerlist/edit/";
                        // {
                        //     return <Redirect to={'../../managerlist/edit/'}/>
                        // }
                          this.setState({linkingLoader:false, successQuote: '1',});
                      })  
                    }
      }).catch(error => {
      this.setState({myerrormsg: error.response});
      });
  };

  //name attribute here is id from the Database 
  multiselectCheckbox = (param, value, name) => {
    if (param == "building_amenities") {
      var stateArray = this.state.building_amenities;
    }
    if (param == "marketing") {
      var stateArray = this.state.marketing;
    }
    if (param == "unit_amenities") {
      var stateArray = this.state.unit_amenities;
    }
    if(param == "Exposures" ){
      var stateArray = this.state.Exposures
    }
    var flag = 0;
    var  temp ={related_id:name, name:value};
    var removeIndex;
    // this is launches only one when the array is empty
    if (stateArray.length === 0) {
      stateArray.push(temp);
      if(value=="RLS"){
        console.log("---add-in if",value);
        this.setState({
          RLSFlag:1
        },()=>{
          if(this.state.VOWEntireListingDisplay==false){
            this.setState({
              VOWEntireListingDisplay:!this.state.VOWEntireListingDisplay,
            
          })
          }if(this.state.VOWAddressDisplay==false){
            this.setState({
              VOWAddressDisplay:!this.state.VOWAddressDisplay
          })
          }
          if(this.state.VOWConsumerComment==false){
            this.setState({
              VOWConsumerComment:!this.state.VOWConsumerComment
          })
          }
          if(this.state.VOWAutomatedValuationDisplay==false){
            this.setState({
              VOWAutomatedValuationDisplay:!this.state.VOWAutomatedValuationDisplay
          })
          }
          if(this.state.NewDevelopment==false){
            this.setState({
              NewDevelopment:!this.state.NewDevelopment
          })
          }
          if(this.state.IDXEntireListingDisplay==false){
            this.setState({
              IDXEntireListingDisplay:!this.state.IDXEntireListingDisplay
          })
          }
        })
      }
    } else {
      //checking for the element if already exists
      stateArray.map(item => {
        if (item.name == value) {
          removeIndex = item;
          flag = 1;
        }
      });
    }
    //if flag=0, then element can be pushed
    if (flag === 0) {
      stateArray.push(temp);
      if(value=="RLS"){
        console.log("---add-in if",value);
        this.setState({
          RLSFlag:1
        },()=>{
          if(this.state.VOWEntireListingDisplay==false){
            this.setState({
              VOWEntireListingDisplay:!this.state.VOWEntireListingDisplay,
            
          })
          }if(this.state.VOWAddressDisplay==false){
            this.setState({
              VOWAddressDisplay:!this.state.VOWAddressDisplay
          })
          }
          if(this.state.VOWConsumerComment==false){
            this.setState({
              VOWConsumerComment:!this.state.VOWConsumerComment
          })
          }
          if(this.state.VOWAutomatedValuationDisplay==false){
            this.setState({
              VOWAutomatedValuationDisplay:!this.state.VOWAutomatedValuationDisplay
          })
          }
          if(this.state.NewDevelopment==false){
            this.setState({
              NewDevelopment:!this.state.NewDevelopment
          })
          }
          if(this.state.IDXEntireListingDisplay==false){
            this.setState({
              IDXEntireListingDisplay:!this.state.IDXEntireListingDisplay
          })
          }
        })
      }
      if(value=="Renthop"){
        // console.log("---add-in if",value);
         this.setState({
           rent_hop_export:1
         })
       }
      //removing duplicate elements if any
      stateArray = [...new Set(stateArray)];
    } else {
      //removing elements from the array on uncheck
      var index = stateArray.indexOf(removeIndex);
      stateArray.splice(index, 1);
      if(value=="RLS"){
        console.log("--remove--in if",value);
        this.setState({
          RLSFlag:0
        },()=>{
            if(this.state.VOWEntireListingDisplay==true){
              this.setState({
                VOWEntireListingDisplay:!this.state.VOWEntireListingDisplay,
              
            })
            }if(this.state.VOWAddressDisplay==true){
              this.setState({
                VOWAddressDisplay:!this.state.VOWAddressDisplay
            })
            }
            if(this.state.VOWConsumerComment==true){
              this.setState({
                VOWConsumerComment:!this.state.VOWConsumerComment
            })
            }
            if(this.state.VOWAutomatedValuationDisplay==true){
              this.setState({
                VOWAutomatedValuationDisplay:!this.state.VOWAutomatedValuationDisplay
            })
            }
            if(this.state.NewDevelopment==true){
              this.setState({
                NewDevelopment:!this.state.NewDevelopment
            })
            }
            if(this.state.IDXEntireListingDisplay==true){
              this.setState({
                IDXEntireListingDisplay:!this.state.IDXEntireListingDisplay
            })
            }
            })
      }
      if(value=="Renthop"){
        // console.log("---add-in if",value);
         this.setState({
           rent_hop_export:0
         })
       }
    }
    this.setState(
      {
        [param]: stateArray
      },
      () => {
        let len =this.state.marketing.length;
        let count=0;
        let flag=0;
        this.state.marketing.map(item=>{
          count=count+1;
            if(item.name=='Renthop' && this.state.renthop_approval==false){
              flag=1;
              this.setState({
                renthop_status:0
              })
            }
            if(item.name=='RLS' && this.state.RLS_approval==false){
              flag=1;
              this.setState({
                RLS_status:0
              })
            }
            if(item.name=='Skyward' && this.state.skyward_approval==false){
              flag=1;
              this.setState({
                status:0
              })
            }
            if(item.name=='Localize' && this.state.streetEasy_approval==false){
              flag=1;
              this.setState({
                streeteasy_status:0
              })
            }
        })
          if(count==len && flag==0){
            this.setState({
              status:1
            })
          }
      }
    );
  };

  handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    const dataset = e.currentTarget.dataset.id;
    const name = e.target.name;

    if (
      id == "required_additional_documents" ||
      id == "default_application_template" ||
      id == "screenings_required"
    ) {
      var error = id + "_error";
      if (value == "" || value == 0) {
        this.setState({
            [error]: "Field cannot be empty",
            [id]: [value]
          },() => {
            console.log("dropdown : ", value);
          });
      } else {
        this.setState({
            [error]: "",
            [id]: [value]
          },() => {
            console.log("dropdown : ", value);
          });
      }
    }

    // if (
    //   id == "Elevator" ||
    //   id == "Doorman" ||
    //   id == "Laundry" ||
    //   id == "Gym" ||
    //   id == "Outdoor areas" ||
    //   id == "Live-in super" ||
    //   id == "Heat & Hot Water included"
    // ) {
    //   this.multiselectCheckbox("building_amenities", value, name);
    // }

    if(id =="East" || id =="West" || id=="North" || id=="South"){
      this.multiselectCheckbox("Exposures", value,name)
    }
   

    if (id == "Skyward" || id == "Nestio" || id == "Localize" || id == "RLS" || id =="Renthop" ) {
      this.multiselectCheckbox("marketing", value, name);
    }

    if (
      id == "Cats allowed" ||
      id == "Dogs allowed" ||
      id == "Outdoor space" ||
      id == "Duplex" ||
      id == "Hardwood Floors" ||
      id == "Renovated bathroom" ||
      id == "Renovated Kitchen" ||
      id == "Dishwasher" ||
      id == "W/D in unit" ||
      id == "Furnished/D" ||
      id == "Virtual Doorman"

    ) {
      this.multiselectCheckbox("unit_amenities", value, name);
    }
    
    if (id == "kuula_vr_link") {
      this.setState(
        {
          [id]: value
        },
        () => {
          console.log("kuula_vr_link : ", this.state.kuula_vr_link);
        }
      );
    }
    if (id == "pets_policy") {
      if(document.getElementById("pets_policy").value==""){
        this.setState(
          { 
            pets_policy_error:"field cannot be empty",
            pets_policy: ''
          });
      }
      else{
        this.setState(
          {
            pets_policy_error:"",
            pets_policy: document.getElementById("pets_policy").value
          });
      }
      
    }
    if (id == "video_link") {
      this.setState(
        {
          [id]: value,
          video_link_error:''
        });
    }

    if (id == "CoBrokeAgreement") {
      if(document.getElementById("CoBrokeAgreement").value==""){
        this.setState(
          { 
            CoBrokeAgreement_error:"field cannot be empty",
            CoBrokeAgreement: ''
          });
      }
      else{
        this.setState(
          {
            CoBrokeAgreement_error:"",
            CoBrokeAgreement: document.getElementById("CoBrokeAgreement").value
          });
      }
      
    }
  };
  layoutChange=e=>{
    if(e.target.value.indexOf("1 Bedroom")>=0){
      this.setState({total_rooms:'3'});
    }
    else if(e.target.value.indexOf("2 Bedroom")>=0){
      this.setState({total_rooms:'4'});
    }
    else if(e.target.value.indexOf("3 Bedroom")>=0){
      this.setState({total_rooms:'5'});
    }
    else if(e.target.value.indexOf("4 Bedroom")>=0){
      this.setState({total_rooms:'6'});
    }
    else if(e.target.value.indexOf("Studio")>=0){
      this.setState({total_rooms:'2'});
    }
    else
    {
      this.setState({total_rooms:''});
    }
  }
  onHandleChange=(event)=>{
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;

    if(id =='Exclusive' || id =='Open Listing' || id == 'Address'){
        if (id != "Address") {
       
            this.setState({
              listing_information: dataset
            });
          } else {
            this.setState({
              listing_info_address: value
            });
          }
    } 
    if(id == "Condo" || id == "Co-op" || id == "Condop" || id == "Single Family" || id == "Multifamily"){
        this.setState({
            building_ownership : dataset
        })
    }
    if(id == "bedrooms" || id == "bathrooms" ){
        var error = id + "_error";
        if(value.length==''){
            this.setState({
           [error]:'field cannot be empty',
           [id]:""
            })
        }
         else if(isNaN(value) || value<0) {
            this.setState({
              [error]: "Please enter numbers only",
              [id]:""
            });
          } else{
            this.setState({
                [error]:"",
                [id]:[value]
            })
        }
    }

    if ( id == "unit_number") {
      var error = id + "_error";
      if (value == "") {
        
        this.setState({
          [error]: "Field cannot be empty",
          [id]: [value]
        });
      } else {
        this.setState({
          [error]: "",
          [id]: [value]
        });
      }
    }
    if( id == "price" || id == "maintenance" || id == "monthly_taxes" ||
        id == "commission" || id == "tax_deduction" || id == "flip_tax" ||
        id == "max_financing" || id == "number_of_shares" || id == "percentofcommon" || id=="square_footage"){
            var error = id + "_error";
            if ((isNaN(value)  || value<0) && value != ""){
                this.setState({
                    [error]:"Please enter numbers only",
                    [id]:""
                })
            }
                else{
                    this.setState({
                        [error]:"",
                        [id]:[value] 
                    })
                }
         }
         if(id == "description"){
            var error = id + "_error";
            if( (this.isDescription(value)==false) ){
                this.setState({
                    [error]:"This character is invalid",
                    [id]:[value]
                })
            }else{
                this.setState({
                    [error]:"",
                    [id]:[value]
                })
            }
         }
        //  if(id =="East" || id =="West" || id=="North" || id=="South"){
        //     this.setState({
        //         Exposures:[value]
        //     })
        // }
        if(id=='total_rooms'){
          if(value==''){
              this.setState({
              total_rooms_error:"field cannot be empty",
              total_rooms:[value]
          })
          } else if(isNaN(value)){
            this.setState({
            total_rooms_error:"enter only numbers",
            total_rooms:[value]
        })
        }
          else{
          this.setState({
            total_rooms_error:'',
            total_rooms:[value]
          })}
        }
    else{
        this.setState({
            [id]:[value]
        });
    }
  }

  onVrHandleChange=(event)=>{
    console.log('=>',event.target.id,event.target.value);
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;

        if(id==='kuula_vr_link'){
      var error = id + "_error";
      if(value==''){
        this.setState({
          [error]: "Please enter the valid VR Link",
          [id]: [value],
          validation:false
        });
      }
      else{ 
        console.log("=>",'else');

        if((value.indexOf('https://tours.moveskyward.com/') >=0 || value.indexOf('https://view.ricohtours.com/') >=0 || value.indexOf('https://kuula.co/') >=0 || value.indexOf('https://my.matterport.com') >=0 || value.indexOf('https://skyward360tours.com') >=0) || value==''){
          console.log('found');
          this.setState({
            validation:true
          });
        }
        
    else{
      console.log('not found');
    
      this.setState({
        [error]: "Please enter valid url",
        [id]: [value],
        validation:false
      });
   
     }
    }
    }

    //video_link
    if(id==='video_link'){
      var error = id + "_error";
      if(value==''){
        this.setState({
          [error]: "Please enter the valid Video Link",
          [id]: [value],
          validation:false
        });
      }
      else{ 
        

        if((value.indexOf('youtube') >=0 || value.indexOf('vimeo') >=0 )  || value==''){
          console.log('video link found');
          this.setState({
            validation:true
          });
        }
        
    else{
    
      this.setState({
        [error]: "Please enter valid url",
        [id]: [value],
        validation:false
      });
  
    }
    }
    }
  }
    onVrHandle=(event)=>{
      console.log('=>',event.target.id,event.target.value);
      const id = event.target.id;
      const value = event.target.value;

      
      if(id==='kuula_vr_link'){
        var error = id + "_error";
        this.setState({
          [error]: "",
          [id]: [value],
          validation:true
        });
       
      }

      if(id==='video_link'){
        var error = id + "_error";
        this.setState({
          [error]: "",
          [id]: [value],
          validation:true
        });
       
      }
      }

  ValidURL=(str)=> {
    
    str=String(str);
  
    if((str.indexOf('https://tours.moveskyward.com/') >=0 || str.indexOf('https://view.ricohtours.com/') >=0 || str.indexOf('https://kuula.co/') >=0 || str.indexOf('https://my.matterport.com') >=0 || str.indexOf('https://skyward360tours.com') >=0) || str==''){
      console.log('found');
      return true;
    }else{
    console.log('not found');
    return false;
    
    }
    // var regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    // if(!regex .test(str)) {
    //   return false;
    // } else {
    //   return true;
    // }
  
  }

  ValidVideoLink=(str)=> {
    
    str=String(str);
  
    if((str.indexOf('youtube') >=0 || str.indexOf('vimeo') >=0 ) || str==''){
         return true;
    }else{
    return false;
    
    }

  }


    isDescription(str){
    if(/^[a-zA-Z0-9\s,.@{}():?/$*+!~`^%#&_';="-]*$/.test(str))
    return true;
    else 
    return false;
    }

    // onHandleCheckbox =  (event) =>{
    //     this.setState({
    //         Exposures:event.currentTarget.value
    //     })
    // }

  handleChangeStatus = checked => {
    if (checked) 
    {
    this.state.furnished=1;
     this.setState({furnished:1})
    }
    else 
    {
      this.setState({furnished:0})
    this.state.furnished= 0;
    this.state.FurnishedListPrice="";
    this.state.FurnishedMaxLeaseMonths='';
    this.state.FurnishedMinLeaseMonths='';
  }
  };
 

  closeBlobImage = image => {
    const { imagePathArray } = this.state;
    var index = imagePathArray.indexOf(image);
    let alteredImagePathArray = [...this.state.imagePathArray];
    let alteredImageForDb = [...this.state.imageForDb];
    // console.log('before alteredImageForDb : ', alteredImageForDb);
    alteredImagePathArray.splice(index, 1);
    alteredImageForDb.splice(index, 1);
    // console.log('after alteredImageForDb : ', alteredImageForDb);
    this.setState({ imagePathArray: alteredImagePathArray, imageForDb:alteredImageForDb },()=>{
      console.log('ImageForDb after : ', this.state.imageForDb);
    });
  };

  closeBlobfloorImage = image => {
    const { imagePathfloorArray } = this.state;
    var index = imagePathfloorArray.indexOf(image);
    let alteredImagePathArray = [...this.state.imagePathfloorArray];
    let alteredImageForDb = [...this.state.imageForfloorDb];
    // console.log('before alteredImageForDb : ', alteredImageForDb);
    alteredImagePathArray.splice(index, 1);
    alteredImageForDb.splice(index, 1);
    // console.log('after alteredImageForDb : ', alteredImageForDb);
    this.setState({ imagePathfloorArray: alteredImagePathArray, imageForfloorDb:alteredImageForDb },()=>{
      console.log('ImageForfloorDb after : ', this.state.imageForfloorDb);
    });
  };

  formValidate(key, value) {
    switch (key) {
      case "landlord_phone":
        return this.validateEmpPhoneNumber(value);
        break;
    }
  }
  validateEmpPhoneNumber(value) {
    let res = validatePhone(value);
    if (res.status == false) {
      this.setState({ landlord_phone_error: "Phone number is not valid." });
      document.getElementById("landlord_phone").value = res.value;
      return res.value;
    } else {
      this.setState({ landlord_phone_error: "" });
      document.getElementById("landlord_phone").value = res.value;
      return res.value;
    }
  }

  validation = () => {
  
    const {
    
      listing_info_address,
      listing_info_address_org,
      unit_number,
      bedrooms,
      bathrooms,
      price,
      imageCount,
      isImage,
      unit_number_error,
      bedrooms_error,
      bathrooms_error,
      maintenance_error,
      monthly_taxes_error,
      commission_error,
      tax_deduction_error,
      flip_tax_error,
      max_financing_error,
      number_of_shares_error,
      percentofcommon_error,
      description,
        square_footage_error,
        total_rooms,
        buildingType,
        NumberOfUnitsTotal,
        LegalRoomsTotal,
        StoriesTotal,
        TaxBlock,
        kuula_vr_link,
        TaxLot,
        LotWidth,
        building_ownership,
        LeaseTerm,
        MaxLeaseMonths,
        FurnishedListPrice,
        FurnishedMinLeaseMonths,
        FurnishedMaxLeaseMonths,
        furnished,
        FreeRentRemarks,
        BonusRemarks,
        pets_policy,
        Exclusive_expiration_Date,
        CoBrokeAgreement,
        building_id,
        video_link
    } = this.state;
  if(this.state.imagePathArray.length>0 && isImage)
  {
    return (
      listing_info_address.toString().trim() !="" &&
      unit_number.toString().trim() != "" &&
      bedrooms.toString().trim() != "" &&
      bathrooms.toString().trim() != "" &&
      this.state.imagePathArray.length >2&&
     unit_number_error.toString().trim!=""&&
     bedrooms_error.toString().trim!=""&&
     bathrooms_error.toString().trim!="" &&
     price.toString().trim() != "" &&
     !isNaN(bedrooms) &&
     bedrooms>-1 &&
     !isNaN(bathrooms) &&
     bathrooms>0 &&
     !isNaN(price) &&
     price>0 &&
     maintenance_error =="" &&
     monthly_taxes_error==""&&
     commission_error==""&&
     tax_deduction_error== ""&&
     flip_tax_error==""&&
     max_financing_error==""&&
     number_of_shares_error==""&&
     percentofcommon_error==""&&
     description.toString().trim()!=""&&
       square_footage_error==""&&
       !isNaN(total_rooms)&&
       total_rooms >-1 &&
       total_rooms != "" &&
       building_id>0 &&
       this.ValidURL(this.state.kuula_vr_link) &&
       this.ValidVideoLink(video_link) &&
       listing_info_address_org ==listing_info_address &&
     //  NumberOfUnitsTotal!=""&&
     //  StoriesTotal!=""&&   StoriesTotal>-1&&
    //   TaxBlock!=""&&
     //  TaxLot!=""&&
       pets_policy!="" &&
       (this.state.RLSFlag==1?CoBrokeAgreement!="":true)&&  
       building_ownership!="" && 
       (((this.state.Bonus == true && BonusRemarks!="") || (this.state.Bonus == false) ) ? true : false) &&
     (((this.state.FreeRent== true && FreeRentRemarks!="") || (this.state.FreeRent== false)) ?  true  : false) &&
       ((this.state.listing_information=="Exclusive" ||this.state.listing_information!="" && this.state.listing_information!="Open Listing")?Exclusive_expiration_Date:true) &&
       (this.state.RLSFlag==1 ?(TaxLot!="" && TaxBlock!="" && StoriesTotal!="" &&   StoriesTotal>-1 && NumberOfUnitsTotal!=""):true) &&
       this.state.secondary_agent_error=='' &&
     this.state.third_agent_error=='' &&
     (this.state.RLSFlag == 1 ? this.state.buildingType !== "" : true) &&
     (this.state.secondary_agent_id!='' && this.state.third_agent_id !=''?this.state.secondary_agent_id!=this.state.third_agent_id:true)
       
      //  LeaseTerm!="" &&
      //  MaxLeaseMonths!="" &&
      //  MaxLeaseMonths>0  &&
     //  building_ownership!="" ?building_ownership == "Single Family" ?  LegalRoomsTotal!="" &&  LotWidth!="" : true : false
       
    );
  }
  else{
    
    return (
      listing_info_address.toString().trim() !="" &&
      unit_number.toString().trim() != "" &&
      bedrooms.toString().trim() != "" &&
      bathrooms.toString().trim() != "" &&
      unit_number_error.toString().trim!=""&&
      bedrooms_error.toString().trim!=""&&
      bathrooms_error.toString().trim!="" &&
      price.toString().trim() != "" &&
      !isNaN(bedrooms) &&
      bedrooms>-1 &&
      !isNaN(bathrooms) &&
      !isNaN(price) &&
      price>0 &&
     (((this.state.Bonus == true && BonusRemarks!="") || (this.state.Bonus == false) ) ? true : false) &&
     (((this.state.FreeRent== true && FreeRentRemarks!="") || (this.state.FreeRent== false)) ?  true  : false) &&
      bathrooms>0 && 
      building_id>0 && 
      maintenance_error =="" &&
      monthly_taxes_error==""&&
      commission_error==""&&
      tax_deduction_error== ""&&
      flip_tax_error==""&&
      max_financing_error==""&&
      number_of_shares_error==""&&
      percentofcommon_error==""&&
      description.toString().trim()!=""&&
        square_footage_error==""&&
        !isNaN(total_rooms)&&
        total_rooms >-1 &&
        total_rooms != "" &&
        listing_info_address_org ==listing_info_address &&
      //  NumberOfUnitsTotal!=""&&
      //  StoriesTotal!=""&&
      //  TaxBlock!=""&&
      //  TaxLot!="" &&
        pets_policy!="" &&
        (this.state.RLSFlag==1?CoBrokeAgreement!="":true)&&  
        // LeaseTerm!="" &&
        // MaxLeaseMonths!="" &&
        //  MaxLeaseMonths>0 &&
        building_ownership!="" &&
        this.ValidURL(this.state.kuula_vr_link) &&
        this.ValidVideoLink(video_link) &&  
        ((this.state.listing_information=="Exclusive" ||this.state.listing_information!="" && this.state.listing_information!="Open Listing")?Exclusive_expiration_Date:true) &&
        (this.state.RLSFlag==1 ?(TaxLot!="" && TaxBlock!="" && StoriesTotal!="" &&   StoriesTotal>-1 && NumberOfUnitsTotal!=""):true) &&
        this.state.secondary_agent_error=='' &&
     this.state.third_agent_error=='' &&
     (this.state.RLSFlag == 1 ? this.state.buildingType !== "" : true) &&
     (this.state.secondary_agent_id!='' && this.state.third_agent_id !=''?this.state.secondary_agent_id!=this.state.third_agent_id:true)
         
      //  building_ownership!="" ?building_ownership == "Single Family" ?  LegalRoomsTotal!="" &&  LotWidth!="" : true : false  
        

    );
  }
  };


  checkboxHandler=(PreWarYN)=>{
    this.setState({
      PreWarYN:!this.state.PreWarYN
  })
  } 
  
  checkboxLobbyHandler=(LobbyAttendant)=>{
    this.setState({
     LobbyAttendant:!this.state.LobbyAttendant
    })
   }  

  onHandler=(event)=>{
    if(event.target.id=="Year_Built"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else if(isNaN(event.target.value)){
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:"Enter only numbers."
        })
      }
      else{
        this.setState({
         [event.target.id]:event.target.value,
         [event.target.id+"_error"]:""
        })
      }
    } 

    if(event.target.id=="NumberOfUnitsTotal"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value,
        })
      }else if(isNaN(event.target.value)){
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:"Enter only numbers."
        })
      }
      else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      }
    }
    
    if(event.target.id=="LegalRoomsTotal"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else if(isNaN(event.target.value)){
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:"Enter only numbers."
        })
      }
      else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      }
    }
    if(event.target.id=="StoriesTotal"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else if(isNaN(event.target.value)){
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:"Enter only numbers."
        })
      }
      else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      }
    }
    if(event.target.id=="TaxBlock"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else if(isNaN(event.target.value)){
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:"Enter only numbers."
        })
      }
      else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      }
    }if(event.target.id=="TaxLot"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else if(isNaN(event.target.value)){
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:"Enter only numbers."
        })
      }
      else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      }
    }
    if(event.target.id=="LotWidth"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else if(isNaN(event.target.value)){
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:"Enter only numbers."
        })
      }
      else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      }
    }

  }

  onCoBrokehandler=(event)=>{
    if(event.target.id=="ShowingInstructions"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      }
    }
    if(event.target.id=="BonusRemarks"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      } 
    }
    if(event.target.id=="FreeRentRemarks"){
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      } 
    }

    if(event.target.id=="FurnishedListPrice"){
          console.log('rrres',event.target.id);
      if(event.target.value==""){
        this.setState({
          // Year_Built:event.target.value,
          [event.target.id+"_error"]:"field can not be empty.",
          [event.target.id]:event.target.value
        })
      }else if(isNaN(event.target.value)){
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:"Enter only numbers."
        })
      }
      else{
        this.setState({
          [event.target.id]:event.target.value,
          [event.target.id+"_error"]:""
        })
      }
    }
    if(event.target.id=="FurnishedMinLeaseMonths"){
      console.log('rrres',event.target.id);
  if(event.target.value==""){
    this.setState({
      // Year_Built:event.target.value,
      [event.target.id+"_error"]:"field can not be empty.",
      [event.target.id]:event.target.value
    })
  }else if(isNaN(event.target.value)){
    this.setState({
      [event.target.id]:event.target.value,
      [event.target.id+"_error"]:"Enter only numbers."
    })
  }
  else{
    this.setState({
      [event.target.id]:event.target.value,
      [event.target.id+"_error"]:""
    })
  }
}
          if(event.target.id=="FurnishedMaxLeaseMonths"){
            console.log('rrres',event.target.id);
        if(event.target.value==""){
          this.setState({
            // Year_Built:event.target.value,
            [event.target.id+"_error"]:"field can not be empty.",
            [event.target.id]:event.target.value
          })
        }else if(isNaN(event.target.value)){
          this.setState({
            [event.target.id]:event.target.value,
            [event.target.id+"_error"]:"Enter only numbers."
          })
        }
        else{
          this.setState({
            [event.target.id]:event.target.value,
            [event.target.id+"_error"]:""
          })
        }
      }
       
 
}


oncheckBoxHandler=(event)=>{
  if(event.target.id=="VOWEntireListingDisplay"){
    this.setState({
      VOWEntireListingDisplay:!this.state.VOWEntireListingDisplay
  })
  }if(event.target.id=="VOWAddressDisplay"){
    this.setState({
      VOWAddressDisplay:!this.state.VOWAddressDisplay
  })
  }
  if(event.target.id=="VOWConsumerComment"){
    this.setState({
      VOWConsumerComment:!this.state.VOWConsumerComment
  })
  }
  if(event.target.id=="VOWAutomatedValuationDisplay"){
    this.setState({
      VOWAutomatedValuationDisplay:!this.state.VOWAutomatedValuationDisplay
  })
  }
  if(event.target.id=="NewDevelopment"){
    this.setState({
      NewDevelopment:!this.state.NewDevelopment
  })
  }
  if(event.target.id=="IDXEntireListingDisplay"){
    this.setState({
      IDXEntireListingDisplay:!this.state.IDXEntireListingDisplay
  })
  }
  if(event.target.id=="Bonus"){
    if(!this.state.Bonus){
    this.setState({
      Bonus:!this.state.Bonus,
    
  })}else{
   this.setState({
      Bonus:false,
      BonusRemarks:""
    })
   }
  }
  if(event.target.id=="FreeRent"){
    if(!this.state.FreeRent){
      this.setState({
        FreeRent:!this.state.FreeRent,
    })
    }else{
      this.setState({
        FreeRent:false,
        FreeRentRemarks:""
    })
   }
  }
}

changeHandler=(event)=>{
  const id = event.target.id;
  const value = event.target.value;
  const dataset = event.currentTarget.dataset.id;
  console.log('----dataset',dataset);
  console.log('id : ', id);
    console.log('value--- : ', value);
    console.log('dataset ----: ', dataset);
  const name = event.target.name;
  if(id==="Owner_pays"){
    this.setState({
      owner_pays : dataset,
    })
  }

  if(id==="owner_amount"){
    if(value==""){
      this.setState({
        owner_amount :""
      })
    }else if(isNaN(value)){
      this.setState({
        owner_amount:""
      })
    }
    else{
    this.setState({
      owner_amount : value,
    })
  }
  }
  
}

onChangeHandler=(event)=>{
  // commission_percentage,
  // my_split,
  // co_broke_split
  
              if(event.target.id==="co_broke_split"){
                this.setState({
                  [event.target.id]:event.target.value,
                  [event.target.id+"error"]:""
                })
              }
              if(event.target.id==="commission_percentage"){
                if(event.target.value =="" ){
                  this.setState({
                    [event.target.id]:"",
                    [event.target.id+"error"]:"Field can not be empty."
                      
                  })
                }
                else if(isNaN(event.target.value)){
                  this.setState({
                    [event.target.id]:"",
                    [event.target.id+"error"]:"Enter only numbers."
                  })
                }else{
                  if( event.target.value <=100){
                  var _commission=this.state.my_split*event.target.value;
                  var _percentage=_commission / 100;
                this.setState({
                  [event.target.id]:event.target.value,
                  final_precentage:_percentage,
                  [event.target.id+"error"]:""
              })
            }
          }
         }
              if( event.target.id==="my_split"){
                if(event.target.value =="" ){
                  this.setState({
                    [event.target.id]:event.target.value,
                        co_broker_split:100,
                        [event.target.id+"error"]:"Field can not be empty."
                  })
                }else if(isNaN(event.target.value)){
                  this.setState({
                    [event.target.id]:"",
                    [event.target.id+"error"]:"Enter only number"
                  })
                }
                else{
                  if( event.target.value <=100){
                  var _commission=event.target.value * this.state.commission_percentage;
                  var _percentage=_commission / 100;
                this.setState({
                  [event.target.id]:event.target.value,
                  co_broker_split:100 - event.target.value,
                  final_precentage:_percentage,
                  [event.target.id+"error"]:""
                })
              }
            }
          }
}

OpenAdditionalHouse=()=>{ 
 
  var index=this.state.finalOpenHouseArr.length;

     this.setState(state => (
       this.state.finalOpenHouseArr[index]={
       'date':"",
       'start_time':"",
       'end_time':""},state))

 }

  preventKeystroke=(event)=>{
    console.log("keycode---", event.keyCode);
    if (event.keyCode) {
        event.preventDefault();
    }
}

brokerCheckboxHandler=()=> {       
  this.setState({
    brokerOnly: !this.state.brokerOnly==true ? 1 :0
  })
}
appointmentCheckboxHandler=(e)=>{
  this.setState({
    appointmentOnly: !this.state.appointmentOnly==true ? 1 :0
  })
}

closenewdiv = index => {
       
  this.setState(state => (this.state.finalOpenHouseArr.splice(index, 1),state))

};

 // --start agent auto select--

 async agentOpt(){
  let agentParam = [{
    url: "/agent/agentExclude?agent_id="+getAgentId()
  }];
  getApiData(agentParam,true).then(res => {
  this.setState({'agentList':res.data, 'c_agentList':res.data, 'newShowing_agentList':res.data});
  }).catch(error => {
  
  });
}

handleAgentSearchAssignNewShowing = event => {
  var selected_agent_name_newShowing = event.target.getAttribute('name')
  this.setState({
      selected_agent_name_newShowing: selected_agent_name_newShowing
  })
  if(event.target.value==''){
    this.setState({secondary_agent_error:'',secondary_agent_id:''})
    if((this.state.third_agent_id!='' || this.state.third_agent_name!='') && this.state.third_agent_name!=null)
        {
          this.setState({secondary_agent_error:'Please select Secondary Agent'}) 
        }
  }
var key=event.target.id; 

  switch(key){
      case 'newShowing_agent_search_id':
              this.setState({searchParm:event.target.value,newShowing_agent_name: event.target.value, agenterror:''},()=>{
    //if(this.state.searchParm.length>-1){
      var value=this.state.searchParm;
      console.log("value--", value);
              if(value!=''){
                var len = this.state.agentList.length;
                var count=0;
                var vflag=0;
                  this.state.agentList.forEach(function(agent,index) {
                      if(agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
              agent.flag=3;
                vflag=1;
                count=count+1;
                          }else{
              agent.flag=0;
              if(vflag!=1)
              vflag=0
              count=count+1;
                          }
          }); 
          if(len==count){
            if(vflag==0){
              this.setState({secondary_agent_error:'This is not a Registered Agent'})
              document.getElementById('newShowing_agent_search_id_values').style.display = 'none';
              
            }
          }
        
              }else{
        console.log("else--");
                  document.getElementById('newShowing_agent_search_id_values').style.display = 'none';
                  this.state.agentList.forEach(function(agent,index) {
                      agent.flag=0;
                  });
                  
              }
          })
            
                    
              //}
  break;
  case 'searchkey_agent_assign_newShoiwng':
  var value=event.target.value;
              let $this = this;
              this.setState({secondary_agent_error:''})    
    this.setState({ secondary_agent_id: event.target.value}, () => {
      if(this.state.third_agent_id!='' || this.state.third_agent_id===0)
      {
        this.setState({third_agent_error:''}) 
      }
      if(this.state.secondary_agent_id===0 && this.state.third_agent_id===0)
  {
    this.setState({third_agent_error:'Please select different Agent'}) 
  }
      this.state.agentList.forEach(function(agent,index) {
        if(agent.id==value){
            document.getElementById('newShowing_agent_search_id_values').style.display = "none";
                                          document.getElementById('newShowing_agent_search_id').value=agent.name;
                                          $this.setState({newShowing_agent_name:agent.name});
        }else{
          agent.flag=0;
        }
      });
      document.getElementById('newShowing_agent_search_id_values').style.display = "none";
      });
  break;
  case 'default':
  break;
   
  }

//    agent_search_id_values
document.getElementById('newShowing_agent_search_id_values').style.display = "block";
}

handlethirdagentsearch = event => {
  var selected_agent_name_newShowing = event.target.getAttribute('name')
  this.setState({
      selected_agent_name_newShowing: selected_agent_name_newShowing
  })
  if(event.target.value==''){
    this.setState({third_agent_error:'',third_agent_id:''})
    if(this.state.third_agent_id==''){
      this.setState({secondary_agent_error:''}) 
    } 
  }
 
 
var key=event.target.id; 
console.log("key--", key);
  switch(key){
      case 'third_agentId':
              this.setState({searchParm:event.target.value,third_agent_name: event.target.value, agenterror:''},()=>{
    //if(this.state.searchParm.length>-1){
      var value=this.state.searchParm;
     
              if(value!=''){
                var len = this.state.agentList.length;
                var count=0;
                var vflag=0;
                  this.state.agentList.forEach(function(agent,index) {
                      if(agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
              agent.flag=3;
                vflag=1;
          count=count+1;
              
                          }else{
              agent.flag=0;
           
                if(vflag!=1)
                vflag=0
                count=count+1;
              
                          }
          }); 
          if(len==count){
            if(vflag==0){
              this.setState({third_agent_error:'This is not a Registered Agent'})
              document.getElementById('third_agentId_values').style.display = 'none';
              
            }
          }
        
              }else{
        console.log("else--");
                  document.getElementById('third_agentId_values').style.display = 'none';
                  this.state.agentList.forEach(function(agent,index) {
                      agent.flag=0;
                  });
                  
              }
          })
            
                    
              //}
  break;
  case 'third_agent_assign_newShoiwng':
  var value=event.target.value;
              let $this = this;
     this.setState({third_agent_error:''})        
    this.setState({ third_agent_id: event.target.value}, () => {
      if(this.state.secondary_agent_id=='' && this.state.secondary_agent_id!=0)
        {
          this.setState({third_agent_error:'Please select Secondary Agent'}) 
        }
        if(this.state.secondary_agent_id===0 && this.state.third_agent_id===0){
          this.setState({third_agent_error:'Please select different Agent'}) 
         }
      this.state.agentList.forEach(function(agent,index) {
    
        if(agent.id==value){
            document.getElementById('third_agentId_values').style.display = "none";
                                          document.getElementById('third_agentId').value=agent.name;
                                          $this.setState({third_agent_name:agent.name});
        }else{
          agent.flag=0;
        }
      });
      document.getElementById('third_agentId_values').style.display = "none";
      });
  break;
  case 'default':
  break;
   
  }

//    agent_search_id_values
document.getElementById('third_agentId_values').style.display = "block";
}

agentSlots=()=>{
  let where=[{
    url:'/agent/getAgentSlot'
  }];
  postApiData(where,{'agent_id':getAgentId()},true).then(res=>{
   console.log("---getAgentSlot",res.data[0]);
    this.setState({
      agentRentHopSlots:res.data[0].renthop_slot,
      agent_REBNYAgentID:res.data[0].REBNYAgentID
    })
  }).catch(error=>{

  })
  }

  render() {
    console.log('rlsflag------>',this.state.listing_information)
    if(this.state.RLSFlag==1){
      this.state.listing_information= "Exclusive";
     // this.state.incentives ="CYOF"
    }

   
    const { userType, heading } = this.props;
    if(this.props.userType == 'Agent'){
    }
    // const buildingDW = this.state.buildingList.map((aw,index) => 
    // (aw.flag==1 && aw.id>0) ? <li id="searchkey" key={aw.id} value={aw.id} name={aw.building_address} onClick={this.handleBuildingSearch}>{aw.building_address}</li>:'');
    
    console.log("this.state.tab",this.state.tabFlag)
    if(this.state.tabFlag ){
      console.log("this.props-->", this.props.propsValue[0].userType)
      if(this.props.propsValue[0].userType =='Agent'){
        return (<Redirect to={{
          pathname: '/agent/list/edit', 
         salesTabFlag:this.state.tabFlag,
        }}/>);
      }
      if(this.props.propsValue[0].userType == 'Manager'){
        return (<Redirect to={{
          pathname: '/managerlist/edit', 
          salesTabFlag:this.state.tabFlag,
        }}/>)
      }
    }

  


   
    const {
      listing_information,
      listing_info_address,
      unit_number,
      bedrooms,
      bathrooms,
      square_footage,
      description,
      price,
      date_available,
     
      imagePathArray,
      imagePathfloorArray,
      kuula_vr_link,
      building_ownership, 
      maintenance,
      monthly_taxes,
      commission,
      tax_deduction,
      flip_tax,
      max_financing,
      number_of_shares,
      percentofcommon,
      unit_condition,
      unit_view,
      //error
      building_address_error,
      unit_number_error,
      bedrooms_error,
      bathrooms_error,
      square_footage_error,
      price_error,
      date_available_error,
      //sales info error
      maintenance_error,
      monthly_taxes_error,
      commission_error,
      tax_deduction_error,
      flip_tax_error,
      max_financing_error,
      number_of_shares_error,
      percentofcommon_error,
      description_error,
        unit_condition_error,
        unit_view_error,kuula_vr_link_error,
      data,
      linkingLoader,
      // building_ownership,
      total_rooms,
      total_rooms_error,
      video_link,
      video_link_error
      
    } = this.state;

    const files = imagePathArray.map((image,index) => {
      for(let i=0; i<=imagePathArray.length;i++){
      return (
        <div className="col-sm-3">
        <div className="slider-frame" style={{width:'100%'}}>
       
          <div className="add-img" style={{background:'url('+ image.file +') center', backgroundSize:'cover',transform: `rotate(${image.current_rotation}deg)`}} >
            </div>
          
          <div
            to
            className="close-thumb"
            onClick={this.closeBlobImage.bind(this, image)}
          >
            Close
          </div>
          <div className="rotate-wrap"> 
            <input onClick={this.rotateleft([image.file,index,image.current_rotation,])} type="button" value=""  className="left-rotate" />
            <input onClick={this.rotate([image.file,index,image.current_rotation,])} type="button" value="" className="right-rotate"/>
            </div>
         
        </div>
        </div>
      );
      }
    });

    const blobfloorImages = imagePathfloorArray.map((image,index)=> {
      console.log('cccccccccccccccccccc',imagePathfloorArray);
      for(let i=0; i<=imagePathfloorArray.length;i++){
        return (
      
          <div className="col-sm-3">
          <div className="slider-frame" style={{width:'100%'}}>
       
            <div className="add-img" style={{background:'url('+ image.file +') center', backgroundSize:'cover',transform: `rotate(${image.current_rotation}deg)`}} >
              </div>
            
            <div
              to
              className="close-thumb"
              onClick={this.closeBlobfloorImage.bind(this, image)}
            >
              Close
            </div>
          
            <div className="rotate-wrap"> 
            <input onClick={this.rotateleftfloor([image.file,index,image.current_rotation,])} type="button" value=""  className="left-rotate" />
            <input onClick={this.rotatefloor([image.file,index,image.current_rotation,])} type="button" value="" className="right-rotate"/>
            </div>
          </div>
          </div>
        );
      }
      
    });
    
    let document_name;
    let application_template;
    let screening_name;
    let Building_amenities;
    let Unit_amenities;
    let marketing;
    // console.log('linkingLoader : ', linkingLoader);
    if(this.state.flag == 1){
       document_name = data[0].map((value)=>{
        return <option value={value.document_name}>{value.document_name}</option>
      })
      application_template = data[1].map((value)=>{
        return  <option value={value.application_template}>{value.application_template}</option>
      })
      screening_name = data[2].map((value)=>{
        return  <option value={value.screening_name}>{value.screening_name}</option>
      })
      
      Unit_amenities = data[3].map((value)=>{
        if(value.type == 1){
          return <div className="checkbox-inline">
                <input
                  type="checkbox"
                  id={value.amenity_name}
                  value={value.amenity_name}
                  name={value.id}
                />
                <label htmlFor={value.amenity_name}>{value.amenity_name}</label>
              </div>
        }
      });
      marketing = data[4].map((value)=>{
        if(value.name=="RLS"){
          if(this.state.agent_REBNYAgentID==null || this.state.agent_REBNYAgentID==undefined || this.state.agent_REBNYAgentID==""){
            return <div className="checkbox-inline">
            <input 
              type="checkbox" 
              id={value.name} 
              value={value.name}
              name={value.id}
              defaultChecked={getAgentDomain()=='kw.com'?'':(value.default_set==1)?'checked':''}
              disabled="true"
            />
            <label htmlFor={value.name}>{value.name}</label>
          </div>
          }
          else
          {
            return <div className="checkbox-inline">
            <input 
              type="checkbox" 
              id={value.name} 
              value={value.name}
              name={value.id}
              defaultChecked={getAgentDomain()=='kw.com'?'':(value.default_set==1)?'checked':''}
            />
            <label htmlFor={value.name}>{value.name}</label>
          </div>
          }
        }
        else{
          return <div className="checkbox-inline">
                  <input 
                    type="checkbox" 
                    id={value.name} 
                    value={value.name}
                    name={value.id}
                    defaultChecked={getAgentDomain()=='kw.com'?'':(value.default_set==1)?'checked':''}
                    disabled={this.props.propsValue[0].userType!="Manager"?(this.state.agentRentHopSlots==0)? value.name=="Renthop" :'':''}
                  />
                  <label htmlFor={value.name}>{value.name}</label>
                </div>
        }
          
      });
    }
    var dropdownData;
    if(this.state.layoutData ){
   dropdownData =  this.state.layoutData.map((item,index)=>{
      return   <option   value={item.value} >{item.layout}</option>
      })}
   // Owner Pays DropDown
   var ownerpayData;
   if(this.state.owner_pays_data){
     ownerpayData =  this.state.owner_pays_data.map((item,index)=>{
     return   <option   value={item.owner_pays_broker} >{item.owner_pays_broker}</option>
     })}
   // Owner Pays DropDown

   // Building type DropDown
     var buildingType;
     if(this.state.building_type){
       buildingType = this.state.building_type.map(item=>{
         return <option value={item.building_type}>{item.building_type}</option>
       })
     }
   // Building type DropDown

    // agent auto fill dropdown start
    const agentDWNewShowing = this.state.newShowing_agentList.map((baw,index) => 
    (baw.flag==3) ? <li id="searchkey_agent_assign_newShoiwng" key={baw.id} value={baw.id} name={baw.name} onClick={this.handleAgentSearchAssignNewShowing}>{baw.name}</li>:''
      );

      const thirdagentDWNewShowing = this.state.newShowing_agentList.map((baw,index) => 
      (baw.flag==3) ? <li id="third_agent_assign_newShoiwng" key={baw.id} value={baw.id} name={baw.name} onClick={this.handlethirdagentsearch}>{baw.name}</li>:''
        );
      
      // --END--

     // Co-broke-agreement
     var co_broke_agreement;
     if(this.state.co_broke_agreement){
       co_broke_agreement = this.state.co_broke_agreement.map(item=>{
         return <option value={item.co_broke_Agreement}>{item.co_broke_Agreement}</option>
       })
     }
     // co-broke-agreement

     // Air Conditioning type
     var airconditiontype;
     if(this.state.air_conditioning_type_master){
       airconditiontype = this.state.air_conditioning_type_master.map(item=>{
         return <option value={item.air_conditioning_type}>{item.air_conditioning_type}</option>
       })
     }
     // Air Conditioning type
      var newDiv;
      if(this.state.finalOpenHouseArr.length>0){
      newDiv = this.state.finalOpenHouseArr.map((item,index)=>{
      return  <div className="form-inline" >
                      <div className="form-group">
                                               <label>Date</label>
                                               <DatePicker
                                                selected={this.state.finalOpenHouseArr[index].date}
                                                id={'date'+index}
                                                onChange={this.handleDateChange([index,'_date_'])}
                                                dateFormat="MMMM d, yyyy"
                                                timeCaption="time"
                                                className="form-control"
                                                 onKeyDown = {(e)=>this.preventKeystroke(e)}
                                            />
                                           </div> 
                                           <div className="form-group">
                                               <label>Start Time</label>
                                               <DatePicker
                                                id={'datePicker_time'+index}
                                                selected={this.state.finalOpenHouseArr[index].start_time}
                                                onChange={this.handleDateChange([index,'_start_time_'])}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeFormat="h:mm aa"
                                                timeIntervals={15}
                                                dateFormat="h:mm aa"
                                                timeCaption="time"
                                                autoComplete="off"
                                                className="form-control"
                                                onKeyDown = {(e)=>this.preventKeystroke(e)}
                                            /> 
                                           </div>
                                           <div className="form-group">
                                               <label>End Time</label>
                                               <DatePicker
                                                id={'datePicker_time1'+index}
                                                selected={this.state.finalOpenHouseArr[index].end_time}
                                                onChange={this.handleDateChange([index,'_end_time_'])}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeFormat="h:mm aa"
                                                timeIntervals={15}
                                                dateFormat="h:mm aa"
                                                timeCaption="time"
                                                autoComplete="off"
                                                 className="form-control"
                                                onKeyDown = {(e)=>this.preventKeystroke(e)}
                                            /> 
                                           </div> 
                                           <div
                                to
                                className="close-thumb"
                                onClick={this.closenewdiv.bind(this, index)}
                              >
                                  Close
                              </div>

                      </div>
      })
    }
    return (
      <div>
        <main>
                {(linkingLoader) && <div className="show_loader_on_click" id="show_loader_on_click"></div>}
                <form onSubmit={this.handleSubmit}>
                {(this.state.successQuote==1)?(<div className="alert alert-success"><strong>Form Successfully Submitted!</strong></div>):(this.state.successQuote==2)?(<div className="alert alert-danger"><strong>Listing Already Exist, Please Try To Add Another Listing</strong></div>):''} 
                  
              <div className="rental-application listing-creator-section">
                            <div className="listing-block">
                  <div className="listing-block">
                      <h4 style={(this.state.listing_information == '') ?{ color: 'red' } : {visibility:"visible"}}>Listing information*</h4>
                      
                        <ul className="btn-inline">
                        <li
                            className="btn"
                            style={
                              listing_information == "Exclusive"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            onClick={this.onHandleChange}
                            id="Exclusive"
                            data-id="Exclusive"
                          >
                            Exclusive
                          </li>
                          {(this.state.RLSFlag==1)?<li
                            className="btn disabled"
                            style={{ visibility: "visible" }}
                            id="Open Listing"
                            data-id="Open Listing"
                           
                          >
                            Open Listing
                          </li>:
                          <li
                            className="btn"
                            style={
                              listing_information == "Open Listing" || listing_information == "null"
                                ?{background: "#004F6B", color: "white"}
                                : { visibility: "visible" }
                            }
                            onClick={this.onHandleChange}
                            id="Open Listing"
                            data-id="Open Listing"
                          >
                            Open Listing
                          </li>}
                          {/* <li
                            className="btn"
                            style={
                              listing_information == "Open Listing"
                                ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" }
                            }
                            onClick={this.onHandleChange}
                            id="Open Listing"
                            data-id="Open Listing"
                          >
                            Open Listing
                          </li> */}
                          {(this.state.listing_information=="Exclusive" ||this.state.listing_information!="" && this.state.listing_information!="Open Listing") ?
                          <li>  <div className="form-group" style={{width:'210px', textAlign:'center', margin:'0px', position:'relative', top:'10px'}}>
              <label style={{textAlign:'center'}}>Exclusive expiration date*</label>
                {/* <input type="text" className="form-control" /> */}
                                <DatePicker
                                                 selected={this.state.Exclusive_expiration_Date}
                                                 onChange={this.handleChangeExpirationDate}
                                                 dateFormat="MMMM d, yyyy"
                                                 timeCaption="time"
                                                 className="form-control"
                                                 id="Exclusive_expiration"                               
                                                  />        
                    </div>
                    <span className="inline-error-class">
                                                            {" "}
                                                            {this.state.Exclusive_expiration_Date_error !== ""
                                                                ? this.state.Exclusive_expiration_Date_error
                                                                : ""}
                                                        </span> 
                      </li>:
                     <li>  <div className="form-group" style={{width:'210px', textAlign:'center', margin:'0px', position:'relative', top:'10px'}}></div>
                     </li>   }
                        </ul>
                        <div className="row">
                          <div className="col-sm-5">
                            <label>Address*</label>
                            <div className="form-group">
                              <input type="text" className="form-control" id="Address" onChange={this.handleBuildingSearch} onKeyDownCapture={this.handleBuildingSearch} onKeyUpCapture={this.handleBuildingSearch}  autoComplete="off" value={(this.state.listing_info_address!=0)?this.state.listing_info_address:''}  style={(this.state.listing_info_address == '')  ?{ borderBottom: '2px solid red' } : {visibility:"visible"}}/>
                              <div className="box agentNameOnBoard" id="building_search_id_values" style={(this.state.RLSFlag == 1) ? (this.state.buildingType == undefined || this.state.buildingType == "") ? { borderBottom: '2px solid red',width:'200px' } : { visibility: "visible",width:'200px'} : { visibility: "visible",width:'200px' }} >
                                              <ul>{this.state.buildingDW}
                                             </ul>
                                        </div>
                                        <span className="inline-error-class">
                                                            {" "}
                                                            {building_address_error !== ""
                                                                ? building_address_error
                                                                : ""}
                                                        </span>
                            </div>
                          </div>

                          <div className="form-inline">
                          <div className="form-group">
                            <label>Unit Number*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="unit_number"
                              value={unit_number}
                              onChange={this.onHandleChange}
                              style={(this.state.unit_number == '') ?{ borderBottom: '2px solid red' } : {visibility:"visible"}}
                            />
                            <small className="leftbedge">#</small>
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {unit_number_error !== ""
                                ? unit_number_error
                                : ""}
                            </span>
                          </div>
                        </div>
                        </div>
              <ul className="btn-inline sell-inline-btn">
                <li onClick={this.onHandleChange} className="btn" id="Condo" data-id="Condo"
                                style={ building_ownership == "Condo" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Condo </li>                     
                                <li onClick={this.onHandleChange} className="btn" id="Co-op" data-id="Co-op" 
                                style={ building_ownership == "Co-op" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Co-op</li>
                <li onClick={this.onHandleChange} className="btn" id="Condop" data-id="Condop"
                                style={ building_ownership == "Condop" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Condop</li>
                <li onClick={this.onHandleChange} className="btn" id="Single Family" data-id="Single Family" 
                                style={ building_ownership == "Single Family" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Single Family</li>
                <li onClick={this.onHandleChange} className="btn" id="Multifamily" data-id="Multifamily"
                                style={ building_ownership == "Multifamily" ? { background: "#004F6B", color: "white" }
                                : { visibility: "visible" } }>Multifamily</li>
              </ul>
                        
                  </div>

                  <div className="listing-block">
                      <h4>Unit information</h4>
                      <div className="form-inline">
                      <div className="form-group">
                            <label>Layout</label>
                              <select  id="layout" className="form-control"  onChange={this.layoutChange}  style={{width:'200px'}}>
                                <option value="">Select</option>
                                {dropdownData}
                              </select>
                          </div>
                          <div className="form-group">
                            <label>Bedrooms*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="bedrooms"
                              value={bedrooms}
                              onChange={this.onHandleChange}
                              style={(this.state.bedrooms == '')?{ borderBottom: '2px solid red' } : {visibility:"visible"}}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {bedrooms_error !== "" ? bedrooms_error : ""}
                            </span>
                          </div>

                         
                          <div className="form-group">
                            <label>Bathrooms*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="bathrooms"
                              value={bathrooms}
                              onChange={this.onHandleChange}
                              style={(this.state.bathrooms == '') ?{ borderBottom: '2px solid red' } : {visibility:"visible"}}
                            />
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {bathrooms_error !== "" ? bathrooms_error : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Total Rooms*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="total_rooms"
                              value={total_rooms}
                              onChange={this.onHandleChange}
                              style={(this.state.total_rooms == '') ?{ borderBottom: '2px solid red' } : {visibility:"visible"}}
                            />
                            {/* <small className="leftbedge">#</small> */}
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {total_rooms_error !== ""
                                ? total_rooms_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Square Footage</label>
                            <input
                              type="text"
                              className="form-control size"
                              id="square_footage"
                              value={square_footage}
                              onChange={this.onHandleChange}
                            />
                            <small className="rightbedge">sq. ft.</small>
                            <br />
                             <span className="inline-error-class">
                              {" "}
                              {square_footage_error !== "" ? square_footage_error : ""}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Description*</label>
                            <div className="form-group">
                            <textarea
                                id="description"
                                value={description}
                                onChange={this.onHandleChange}
                                style={(this.state.description.toString().trim() == '') ?{ border: '2px solid red',width:'98%' } : {visibility:"visible",width:'98%'}}
                              />
                               <br />
                                          <span className="inline-error-class">
                                        {" "}
                                           {description_error !== ""
                                             ? description_error
                                             : ""}
                                          </span>
                            </div>
                          </div>
                        </div>
                      <div className="clearfix">
                      <div className="check-block pull-left">
                      <label style={{marginBottom:'15px'}}>Exposures</label>
                        <div class="form-group ">
                          <div className="checkbox-inline">
                                                            <input type="checkbox"
                                                             id="North"
                                                            onChange={this.handleChange}
                                                             value="North"
                                                             name="myCheckbox"/>
                              <label for="North">North</label>
                            </div>
                            <div className="checkbox-inline">
                                                            <input type="checkbox"
                                                             id="South"
                                                          onChange={this.handleChange}
                                                            value="South"
                                                             name="myCheckbox"
                                                             />
                              <label for="South">South</label>
                            </div>
                            <div className="checkbox-inline">
                                                            <input type="checkbox" 
                                                             id="East"
                                                             onChange={this.handleChange}
                                                            value="East"
                                                             name="myCheckbox"/>
                              <label for="East">East </label>
                            </div>
                            <div className="checkbox-inline">
                                                            <input type="checkbox" 
                                                             id="West"
                                                             onChange={this.handleChange}
                                                             value="West"
                                                             name="myCheckbox"/>
                              <label for="West">West</label>
                            </div>
                          </div>  
                      </div>
                      <div class="form-inline pull-left" style={{marginLeft:'24px'}}>
                          
                          <div className="form-group">
                            <label>Unit Condition </label>
                             <select id="unit_condition"className="form-control" style={{width:'200px'}}>    
                             <option value="">Select</option>   
                             <option value="New">New</option>   
                             <option value="Excellent">Excellent</option>   
                             <option value="Good">Good</option>   
                             <option value="Fair">Fair</option>   
                             <option value="Poor">Poor</option>  
                             <option value="Wrecked">Wrecked</option>  
                                                        </select>
                                                        {/* <input type="text" 
                                                           className="form-control"
                                                        id="unit_view"
                                                        onChange={this.onHandleChange}
                                                        value={unit_view}/> */}
                                                         {/* <br /> */}
                                          {/* <span className="inline-error-class">
                                        {" "}
                                           {unit_view_error !== ""
                                             ? unit_view_error
                                             : ""}
                                          </span>  */}
                          </div>
                          <div className="form-group">
                            <label>Unit View</label>
                            <select id="unit_view" className="form-control" style={{width:'200px'}}> 
                                <option value="">Select</option>   
                                <option value="Open View">Open View</option>   
                                <option value="Obstructed View">Obstructed View</option>   
                                <option value="Partially Obstructed View">Partially Obstructed View</option>                               
                             </select>
                                                        {/* <input type="text" 
                                                           className="form-control"
                                                        id="unit_condition"
                                                        value={unit_condition}
                                                        onChange={this.onHandleChange}/>
                                                     <br />
                                          <span className="inline-error-class">
                                        {" "}
                                           {unit_condition_error !== ""
                                             ?unit_condition_error
                                             : ""}
                                          </span>  */}
                          </div>
                          <div className="form-group">
                            <label>Pet Policy*</label>
                            <select id="pets_policy" className="form-control" style={(this.state.pets_policy == '') ?{ borderBottom: '2px solid red',width:'200px' } : {visibility:"visible",width:'200px'}}  onChange={this.handleChange} onChange={this.handleChange}> 
                                <option value="">Select</option>   
                                <option value="Cats Allowed">Cats Allowed</option>   
                                <option value="Dogs Allowed">Dogs Allowed</option>   
                                <option value="Pets Allowed">Pets Allowed</option>     
                                <option value="No Pets Allowed">No Pets Allowed</option>    
                                <option value="case-by-case">Case-by-Case</option>                          
                             </select>
                             <br />
                                          <span className="inline-error-class">
                                        {" "}
                                           {this.state.pets_policy_error !== ""
                                             ?this.state.pets_policy_error
                                             : ""}
                                          </span>  
                          </div> 
                          <div className="form-group">
                             {/* DropDown */}
                            <label>Air Conditioning Type</label>
                           <select id="air_conditioning_type" style={{width:'100%'}} className="form-control">
                             <option value="">Select</option>
                             {airconditiontype}
                           </select>
                          </div>   
                    </div>
                    </div>
                    <div className="row">
                          <div className="col-sm-12">
                            <label>Views Remarks</label>
                            <div className="form-group">
                              <textarea
                                id="views_remarks"
                                value={this.state.views_remarks}
                                onChange={this.onHandleChange}
                                style={{width:'98%'}}
                              />
                            </div>
                          </div>
                        </div>                  
                            
                  </div>
                
                  <div className="listing-block">
                      <h4>Sale information</h4>
                      <div class="form-inline">
                        <div className="form-group">
                         <label>Price* </label>
                                                <input
                                                   type="text"
                                                   className="form-control"
                                                   id="price"
                                                   value={price}
                                                   onChange={this.onHandleChange}
                                                   style={(this.state.price == '') ?{ borderBottom: '2px solid red' } : {visibility:"visible"}}
                                                   />
                                             <small className="leftbedge">$</small>
                                               <br />
                                          <span className="inline-error-class">
                                        {" "}
                                           {price_error !== ""
                                             ? price_error
                                             : ""}
                                          </span> 
                            </div>
                            <div className="form-group">
                          <label>Date Available</label>
                            {/* <input type="text" className="form-control"/> */}
                                                <DatePicker
                                                 selected={this.state.startDate}
                                                 onChange={this.handleChangeDate}
                                                 dateFormat="MMMM d, yyyy"
                                                 timeCaption="time"
                                                 className="form-control"
                                                id="date_available"                         
                                                     
                                                  />
                            </div>
                            <div className="form-group">
                              <label>CC/Maintenance</label>
                                                            <input type="text" 
                                                            className="form-control"
                                                            id="maintenance" 
                                                            onChange={this.onHandleChange}
                                                            value={maintenance}/>
                              <small className="leftbedge">$</small>  
                                                            <br />
                                                        <span className="inline-error-class">
                                                                {" "}
                                                              {maintenance_error !== ""
                                                                   ? maintenance_error
                                                                     : ""}
                                                         </span>      
                            </div>
                            <div className="form-group">
                              <label>Monthly Taxes</label>
                                                            <input type="text" 
                                                             className="form-control"
                                                             id="monthly_taxes"
                                                             onChange={this.onHandleChange} 
                                                             value={monthly_taxes}/>
                              <small className="leftbedge">$</small>
                                                            <br />
                                                        <span className="inline-error-class">
                                                                {" "}
                                                              {monthly_taxes_error !== ""
                                                                   ?monthly_taxes_error
                                                                     : ""}
                                                         </span>    
                            </div>
                          
                      </div>
                      <div class="form-inline sale-info-section">
                          
                          <div className="form-group">
                            <label>Commission </label>
                                                        <input type="text" 
                                                         className="form-control size"
                                                         id="commission"
                                                         onChange={this.onHandleChange}
                                                         value={commission} />
                              <small className="rightbedge">%</small> 
                                                            <br />
                                                        <span className="inline-error-class">
                                                                {" "}
                                                              {commission_error !== ""
                                                                   ?commission_error
                                                                     : ""}
                                                         </span>  
                          </div>
                          <div className="form-group">
                          <label>Tax Deduction </label>
                                                        <input type="text" 
                                                         className="form-control size" 
                                                         id="tax_deduction"
                                                         onChange={this.onHandleChange}
                                                         value={tax_deduction}/>
                              <small className="rightbedge">%</small>   
                                                            <br />
                                                        <span className="inline-error-class">
                                                                {" "}
                                                              {tax_deduction_error !== ""
                                                                   ?tax_deduction_error
                                                                     : ""}
                                                         </span>
                          </div>
                          <div className="form-group">
                          <label>Flip Tax </label>
                                                        <input type="text"
                                                          className="form-control size"
                                                          id="flip_tax" 
                                                          onChange={this.onHandleChange}
                                                          value={flip_tax}/>
                              <small className="rightbedge">%</small> 
                                                            <br />
                                                        <span className="inline-error-class">
                                                                {" "}
                                                              {flip_tax_error !== ""
                                                                   ?flip_tax_error
                                                                     : ""}
                                                         </span>  
                          </div>
                          <div className="form-group">
                          <label>Max Financing </label>
                                                        <input type="text"
                                                         className="form-control size"
                                                         id="max_financing"
                                                         onChange={this.onHandleChange}
                                                         value={max_financing} />
                              <small className="rightbedge">%</small> 
                                                            <br />
                                                        <span className="inline-error-class">
                                                                {" "}
                                                              {max_financing_error !== ""
                                                                   ?max_financing_error
                                                                     : ""}
                                                         </span>    
                          </div>
                          <div className="form-group" style={{width:'160px'}}>
                          <label>Number of Shares</label>
                                                        <input type="text"
                                                          className="form-control " 
                                                          id="number_of_shares"
                                                          onChange={this.onHandleChange}
                                                          value={number_of_shares}/>
                              <br />
                                                        <span className="inline-error-class">
                                                                {" "}
                                                              {number_of_shares_error !== ""
                                                                   ?number_of_shares_error
                                                                     : ""}
                                                         </span>
                          </div>
                          <div className="form-group">
                          <label>% of Common</label>
                                                        <input type="text"
                                                          className="form-control size"
                                                          id="percentofcommon"
                                                          onChange={this.onHandleChange}
                                                          value={percentofcommon} />
                              <small className="rightbedge">%</small> 
                                                            <br />
                                                        <span className="inline-error-class">
                                                                {" "}
                                                              {percentofcommon_error !== ""
                                                                   ?percentofcommon_error
                                                                     : ""}
                                                         </span>    
                          </div>
                        
                    </div>  
                  </div>
                  {/*Building information*/}
                  <div className="listing-block">
                        <h4>Building information</h4>
                     
                        <div className="form-inline">
                          <div className="form-group">
                          <label>Building Type</label>
                          <select id="buildingType" style={(this.state.RLSFlag == 1) ? (this.state.buildingType == 'High-rise' || this.state.buildingType == 'Walk-up' || this.state.buildingType == 'Elevator' || this.state.buildingType == 'Hotel'  || this.state.buildingType == 'Loft') ? { visibility: "visible",width:'200px'}  :{ borderBottom: '2px solid red',width:'200px' } : { visibility: "visible",width:'200px' }} className="form-control" style={{width:'200px'}} onChange={this.handleChange}>
                            <option value="">Select</option>
                            {buildingType}
                          </select>
                          </div>
                        
                          

                          <div className="form-group">
                            <label>Year Built</label>
                            <input
                              type="text" 
                              maxLength={4}
                              className="form-control"
                              id="Year_Built"
                              value={this.state.Year_Built}
                              onChange={this.onHandler}
                            />   
                             <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.Year_Built_error !== ""
                                ? this.state.Year_Built_error
                                : ""}
                            </span>
                          </div>
                          <div className="check-block" style={{display:'inline'}}> 
                         <div className="form-group">
                          <div className="checkbox-inline">
                                <input type="checkbox" id="PreWarYN"  value={this.state.PreWarYN}  checked={(this.state.PreWarYN==true)?'checked':''}  onChange={this.checkboxHandler} /> 
                                <label for="PreWarYN">Pre War</label>
                            </div>
                           </div> 
                           {/*<div className="form-group">
                            <div className="checkbox-inline">
                            <input type="checkbox"   
                                id="PreWarYN"
                                value={this.state.PreWarYN}
                                checked={this.state.PreWarYN}
                                onChange={this.checkboxHandler}/>               
                                <lable for="PreWarYN">Pre War</lable>
                            </div>
                          </div>*/}
                           </div>
                          <div className="form-group">
                            <label>Number Of Units Total</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="NumberOfUnitsTotal"
                              value={this.state.NumberOfUnitsTotal}
                              onChange={this.onHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.NumberOfUnitsTotal ==undefined || this.state.NumberOfUnitsTotal=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />  
                             <br />
                            { <span className="inline-error-class">
                              {" "}
                              {this.state.NumberOfUnitsTotal_error !== ""
                                ? this.state.NumberOfUnitsTotal_error
                                : ""}
                            </span>  }
                          </div>
                         
                          {this.state.building_ownership == "Single Family"?
                          <div className="form-group">
                            <label>Legal Rooms Total</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="LegalRoomsTotal"
                              value={this.state.LegalRoomsTotal}
                              onChange={this.onHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.LegalRoomsTotal ==undefined || this.state.LegalRoomsTotal=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            /> 
                             <br />
                            {/* <span className="inline-error-class">
                              {" "}
                              {this.state.LegalRoomsTotal_error !== ""
                                ? this.state.LegalRoomsTotal_error
                                : ""}
                            </span>   */}
                          </div>
                           :""}
                          <div className="form-group">
                            <label>Stories Total</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="StoriesTotal"
                              value={this.state.StoriesTotal}
                              onChange={this.onHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.StoriesTotal ==undefined || this.state.StoriesTotal=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />
                             <br />
                            { <span className="inline-error-class">
                              {" "}
                              {this.state.StoriesTotal_error !== ""
                                ? this.state.StoriesTotal_error
                                : ""}
                            </span>    }
                          </div>
                          <div className="form-group">
                            <label>Tax Block</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="TaxBlock"
                              value={this.state.TaxBlock}
                              onChange={this.onHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.TaxBlock ==undefined || this.state.TaxBlock=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />   
                             <br />
                            { <span className="inline-error-class">
                              {" "}
                              {this.state.TaxBlock_error !== ""
                                ? this.state.TaxBlock_error
                                : ""}
                            </span>   }
                          </div>
                          <div className="form-group">
                            <label>Tax Lot</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="TaxLot"
                              value={this.state.TaxLot}
                              onChange={this.onHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.TaxLot ==undefined || this.state.TaxLot=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />  
                             <br />
                            { <span className="inline-error-class">
                              {" "}
                              {this.state.TaxLot_error !== ""
                                ? this.state.TaxLot_error
                                : ""}
                            </span>  }
                          </div>
                         
                          {this.state.building_ownership == "Single Family" ?    
                          <div className="form-group">
                            <label>Lot Width</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="LotWidth"
                              value={this.state.LotWidth}
                              onChange={this.onHandler}
                            />   
                              <br />
                            {/* <span className="inline-error-class">
                              {" "}
                              {this.state.LotWidth_error !== ""
                                ? this.state.LotWidth_error
                                : ""}
                            </span>  */}
                          </div>
                           :""}
                           <div className="check-block" style={{display:'inline'}}> 
                            <div className="form-group">
                          <div className="checkbox-inline">
                                <input type="checkbox" id="LobbyAttendant"  value={this.state.LobbyAttendant}  checked={(this.state.LobbyAttendant==true)?'checked':''}  onChange={this.checkboxLobbyHandler} /> 
                                <label for="LobbyAttendant">Lobby Attendant</label>
                            </div>
                           </div> 
                           </div>
                        </div>
                        
                      </div>
                      {/*Building information*/}

                    

                        {/* Listing Information */}
                        <div className="listing-block">
                        <h4>Listing information</h4>
                        <div className="form-inline">
                           <div className="form-group">
                             {/* DropDown */}
                            <label>Co-Broke Agreement</label>
                           <select id="CoBrokeAgreement" 
                           className="form-control" 
                           style={(this.state.RLSFlag == 1) ? (this.state.CoBrokeAgreement ==undefined || this.state.CoBrokeAgreement=="")?{ borderBottom: '2px solid red' } :{visibility:"visible",width:'236px'}:{visibility:"visible",width:'236px'}}
                           onChange={this.handleChange}
                           >
                             <option value="">Select</option>
                             {co_broke_agreement}
                           </select>
                          </div>
                          <div className="form-group">
                            <label>Showing Instructions</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="ShowingInstructions"
                              value={this.state.ShowingInstructions}
                              onChange={this.onCoBrokehandler}
                            />   
                          </div>

                          <div className="form-group" id="switch">
                            <Switch
                              onChange={this.handleChangeStatus}
                              checked={this.state.furnished}
                              className="react-switch"
                              id="normal-switch"
                            />
                            <span style={{ marginLeft: "10px", verticalAlign:"top" }}>
                              Furnished
                            </span>
                          </div>
                        
                           {/* Based on furnished */}
                           {this.state.furnished == 1 ?
                          <div>
                          <div className="form-group">
                            <label>Furnished List Price</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="FurnishedListPrice"
                              value={this.state.FurnishedListPrice}
                              onChange={this.onCoBrokehandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.FurnishedListPrice ==undefined || this.state.FurnishedListPrice=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />   
                             <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.FurnishedListPrice_error !== ""
                                ? this.state.FurnishedListPrice_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Furnished Min Lease Months</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="FurnishedMinLeaseMonths"
                              value={this.state.FurnishedMinLeaseMonths}
                              onChange={this.onCoBrokehandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.FurnishedMinLeaseMonths ==undefined || this.state.FurnishedMinLeaseMonths=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />  
                             <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.FurnishedMinLeaseMonths_error !== ""
                                ? this.state.FurnishedMinLeaseMonths_error
                                : ""}
                            </span> 
                          </div>
                          <div className="form-group">
                            <label>Furnished Max LeaseMonths</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="FurnishedMaxLeaseMonths"
                              value={this.state.FurnishedMaxLeaseMonths}
                              onChange={this.onCoBrokehandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.FurnishedMaxLeaseMonths ==undefined || this.state.FurnishedMaxLeaseMonths=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />  
                             <br />
                            <span className="inline-error-class">
                              {" "}
                              {this.state.FurnishedMaxLeaseMonths_error !== ""
                                ? this.state.FurnishedMaxLeaseMonths_error
                                : ""}
                            </span> 
                          </div>
                          </div>:""}
                          {/* Based on furnished */}
                          <div>

<div className=" check-block">  
 <div className="form-group"  style={{paddingLeft:'0'}}>
   <div className={this.state.RLSFlag==1 ? this.state.VOWEntireListingDisplay!=false? "checkbox-inline":"checkbox-inline alert":"checkbox-inline"} style={{padding:'0px'}}>
       <input type="checkbox" id="VOWEntireListingDisplay" checked={(this.state.VOWEntireListingDisplay)?'checked':''} value={this.state.VOWEntireListingDisplay} onChange={this.oncheckBoxHandler} /> 
       <label for="VOWEntireListingDisplay">VOW Entire Listing Display</label>
   </div>

   <div className={this.state.RLSFlag==1 ? this.state.VOWAddressDisplay!=false? "checkbox-inline":"checkbox-inline alert":"checkbox-inline"} style={{padding:'0px'}}>
       <input type="checkbox" id="VOWAddressDisplay" value={this.state.VOWAddressDisplay} checked={(this.state.VOWAddressDisplay)?'checked':''} onChange={this.oncheckBoxHandler} /> 
       <label for="VOWAddressDisplay">VOW Address Display</label>
   </div>
   <div className={this.state.RLSFlag==1 ? this.state.VOWConsumerComment!=false? "checkbox-inline":"checkbox-inline alert":"checkbox-inline"} style={{padding:'0px'}}>
     <input type="checkbox" id="VOWConsumerComment" value={this.state.VOWConsumerComment} checked={(this.state.VOWConsumerComment)?'checked':''} onChange={this.oncheckBoxHandler} /> 
     <label for="VOWConsumerComment">VOW Consumer Comment</label>
   </div>
   <div className={this.state.RLSFlag==1 ? this.state.VOWAutomatedValuationDisplay!=false? "checkbox-inline":"checkbox-inline alert":"checkbox-inline"} style={{padding:'0px'}}>
   <input type="checkbox" id="VOWAutomatedValuationDisplay" value={this.state.VOWAutomatedValuationDisplay} checked={(this.state.VOWAutomatedValuationDisplay)?'checked':''} onChange={this.oncheckBoxHandler} /> 
     <label for="VOWAutomatedValuationDisplay">VOW Automated Valuation Display</label>
   </div>

   <div className={this.state.RLSFlag==1 ? this.state.NewDevelopment!=false? "checkbox-inline":"checkbox-inline alert":"checkbox-inline"} style={{padding:'0px'}}>
     <input type="checkbox" id="NewDevelopment" value={this.state.NewDevelopment} checked={(this.state.NewDevelopment)?'checked':''} onChange={this.oncheckBoxHandler} /> 
     <label for="NewDevelopment">New Development</label>
   </div>
   {/* <div className="checkbox-inline">
     <input type="checkbox" id="NewDevelopment" value={this.state.NewDevelopment} onChange={this.oncheckBoxHandler} /> 
     <label for="NewDevelopment">New Development</label>
   </div> */}

   <div className={this.state.RLSFlag==1 ? this.state.IDXEntireListingDisplay!=false? "checkbox-inline":"checkbox-inline alert":"checkbox-inline"} style={{padding:'0px'}}>
     <input type="checkbox" id="IDXEntireListingDisplay" checked={(this.state.IDXEntireListingDisplay)?'checked':''} value={this.state.IDXEntireListingDisplay} onChange={this.oncheckBoxHandler} /> 
     <label for="IDXEntireListingDisplay">IDX Entire Listing Display</label>
   </div>

   <div className={this.state.RLSFlag==1 ? this.state.Bonus!=false? "checkbox-inline":"checkbox-inline alert":"checkbox-inline"} style={{padding:'0px'}}>
   <input type="checkbox" id="Bonus" value={this.state.Bonus} onChange={this.oncheckBoxHandler} /> 
     <label for="Bonus">Bonus</label>
   </div>
   <div className={this.state.RLSFlag==1 ? this.state.FreeRent!=false? "checkbox-inline":"checkbox-inline alert":"checkbox-inline"} style={{padding:'0px'}}>
   <input type="checkbox" id="FreeRent" value={this.state.FreeRent} onChange={this.oncheckBoxHandler} /> 
     <label for="FreeRent">Free Rent</label>
   </div>
   </div>                            
 </div>

 {/* </div>  */}
 {this.state.Bonus ==true ?
 <div className="form-group">
   <label>Bonus Remarks*</label>
   <input
     type="text" 
     className="form-control"
     id="BonusRemarks"
     value={this.state.BonusRemarks}
     onChange={this.onCoBrokehandler}
   />  
    <br />
   <span className="inline-error-class">
     {" "}
     {this.state.BonusRemarks_error !== ""
       ? this.state.BonusRemarks_error
       : ""}
   </span>  
 </div>:""}
 
 {this.state.FreeRent == true? 
 <div className="form-group">
   <label>Free Rent Remarks*</label>
   <input
     type="text" 
     className="form-control"
     id="FreeRentRemarks"
     value={this.state.FreeRentRemarks}
     onChange={this.onCoBrokehandler}
   />  
    <br />
   <span className="inline-error-class">
     {" "}
     {this.state.FreeRentRemarks_error !== ""
       ? this.state.FreeRentRemarks_error
       : ""}
   </span>  
 </div>:""}
 </div>
                        
                        </div>
                      </div>
   {/* Open Houses */}
   <div className="listing-block">
                        <h4>Open Houses</h4>
                            { newDiv}
                            <div class="form-group ">
                          <div className="checkbox-inline" style={{paddingLeft:'0'}}>
                                                            <input type="checkbox"
                                                             id="brokerOnly"
                                                            onChange={this.brokerCheckboxHandler}
                                                          />
                                                        
                              <label for="Broker Only">Broker Only</label>
                            </div>
                            <div className="checkbox-inline">
                                                          <input type="checkbox"
                                                             id="appointmentOnly"
                                                             onChange={this.appointmentCheckboxHandler}
                                                             name="myCheckbox"/>
                              <label for="Appointment Only">Appointment Only</label>
                            </div>
                            </div>
                        <div className="form-inline">
                        <div className="form-group">
                          <Link to style={{cursor:'pointer'}} >
                        <i class="fa fa-plus" onClick={()=>this.OpenAdditionalHouse()} aria-hidden="true"><small>ADD AN ADDITIONAL OPEN HOUSE</small></i>
                        </Link>
                        </div></div>
                      </div>
                            {/* Open Houses */}
                  <div className="listing-block check-block">
                      <h4>Unit Amenities</h4>
                          <div
                                                 className="form-group"
                                                onChange={this.handleChange}
                                                       >
                                              {Unit_amenities}
                                          </div>  
                  </div>
                  <div className="listing-block" style={{height:'100px'}}>
                  <div style={{float:'left',paddingRight:'20px'}}>
                        <h4>Secondary Agent</h4>
                        <div
                          className="form-group"
                         
                        >
                       <input type="text" style={{width:'200px'}} id="newShowing_agent_search_id" onChange={this.handleAgentSearchAssignNewShowing} onKeyDownCapture={this.handleAgentSearchAssignNewShowing} onKeyUpCapture={this.handleAgentSearchAssignNewShowing}  autoComplete="off" value={(this.state.newShowing_agent_name!=0)?this.state.newShowing_agent_name:''}  className="form-control"/>
  
                            <span className="inline-error-class">
                              {" "}
                              {this.state.secondary_agent_error !== ""
                                ? this.state.secondary_agent_error
                                : ""}
                            </span>  
														<div className="box agentNameOnBoard" id="newShowing_agent_search_id_values" style={{display:'none',width:'200px'}} >
															<ul>{agentDWNewShowing}</ul>
                                                 </div>
                        </div>
                        </div>
                        <div style={{float:'left'}}>
                       <h4>Tertiary Agent</h4>
                        <div
                          className="form-group"
                        >
                       <input type="text" style={{width:'200px'}} id="third_agentId" onChange={this.handlethirdagentsearch} onKeyDownCapture={this.handlethirdagentsearch} onKeyUpCapture={this.handlethirdagentsearch}  autoComplete="off" value={(this.state.third_agent_name!=0)?this.state.third_agent_name:''}  className="form-control"/>
                       <span className="inline-error-class">
                              {" "}
                              {this.state.third_agent_error !== ""
                                ? this.state.third_agent_error
                                : ""}
                            </span>  
														<div className="box agentNameOnBoard" id="third_agentId_values" style={{display:'none',width:'200px', position:'absolute'}} >
															<ul>{thirdagentDWNewShowing}</ul>
                                                 </div>
                        </div>
                        </div>
                      </div>
                {getAgentDomain()!=='kw.com'?
                  <div className="listing-block check-block">
                      <h4>Marketing</h4>
                      <div
                                             className="form-group"
                                                onChange={this.handleChange}
                                                   >
                                             {marketing}
                                         </div>   
                  </div>:''}

                  <div className="listing-block">
                        <h4>Media</h4>
                         (Please upload at least 3 images)  
                        <div className="row clerfix">
                        
                          <div className="col-sm-12">
                          <div className="row clerfix">
                          {files}
                          <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
             
              <div className="col-sm-3">
                <div className="file-upload add-image-block"></div>
              </div>
                              </div>
                             
          </section>
        )}
      </Dropzone>
                          </div>
                          
                          </div>
                        </div>
                        <span className="inline-error-class">
                                            {" "}
                                                {this.state.mymsg !== ""
                                                           ? this.state.mymsg
                                                         : ""}
                                                        </span>

                        <div className="row">
                          <div className="col-sm-5">
                            <div className="form-group">
                              <label>VR Link</label>
                              <input
                                type="text"
                                className="form-control"
                                id="kuula_vr_link"
                                value={kuula_vr_link}
                                onChange={this.onVrHandle}
                                onBlur={this.onVrHandleChange}
                                style={{visibility:"visible"}}
                              /> <span style={{fontSize:'11px'}}>Note: URL must be in these urls tours.moveskyward.com, view.ricohtours.com, kuula.co, my.matterport.com, skyward360tours.com</span>
                                <span className="inline-error-class"><br />
                              {" "}
                              {kuula_vr_link_error !== ""
                                ? kuula_vr_link_error
                                : ""}
                            </span>
                            </div>
                          </div>

                          <div className="col-sm-5">
                            <div className="form-group">
                              <label>Video Link</label>
                              <input
                                type="text"
                                className="form-control"
                                id="video_link"
                                value={video_link}
                                onChange={this.onVrHandle}
                                onBlur={this.onVrHandleChange}
                                style={{visibility:"visible"}}
                              /> <span style={{fontSize:'11px'}}>Note: URL must be from youtube.com or vimeo.com</span> 
                               <span className="inline-error-class"><br/>
                              {" "}
                              {video_link_error !== ""
                                ? video_link_error
                                : ""}
                            </span>
                            </div>
                            </div>
                        </div>

                        <div className="listing-block">
                        <h4>Floor Plan</h4>
                       
                        <div className="row clerfix">
                        
                          <div className="col-sm-12">
                          <div className="row clerfix">
                          {blobfloorImages}
                          <Dropzone onDrop={this.onDropfloor}>
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
             
              <div className="col-sm-3">
                <div className="file-upload add-image-block"></div>
              </div>
                   
                              </div>
                           
          </section>
        )}
      </Dropzone>
                          </div>
                          
                          </div>
                          <span className="inline-error-class">
                                            {" "}
                                                {this.state.mymsgfloor !== ""
                                                           ? this.state.mymsgfloor
                                                         : ""}
                                                        </span>
                        </div>

                      
                      </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                           disabled={!this.validation()}
                          className="btn btn-default"
                          style={{ margin: "10px" }}
                        >
                          Submit listing for approval
                        </button>
                      </div>

                </div>
                                </div>
            </form>
            
        </main>
      </div>
    );
  }
}

ListingCreator.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
};
export default ListingCreator;


