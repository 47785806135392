import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/ManagerHeader';
import Sidebar from '../common/ManagerSidebar';
import { getManagerToken, getApiData } from '../../services/Api';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router';




class PageNotFound extends React.Component {

	

	render(){
			 return (
				
                <div>
                    <Header />
                    <main>
					
					
						

							<div className="errorPage text-center">
							<br/><br/><br/><br/>
								<h2 className="text-center">Oops !!! Something went wrong</h2>
								<br/>
								<button className="btn btn-default">Go Back</button>
							</div>
									
							
					

	
               				      
                          
                    </main>     
                </div>

				);
    }
}
export default PageNotFound;