import React from 'react';
import Webcam from "react-webcam";
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import {postApiData, getApiData, getUserId, getToken, setOnBoardingDone, getOnBoardingDone,getBaseUrl} from '../../services/Api';


class webCam extends React.Component {
    constructor(props){
        super()
        this.state={
            "imagescr":'',
        }
    }
    setRef = webcam => {
      this.webcam = webcam;
    };
   
    capture = () => {
      const imageSrc = this.webcam.getScreenshot();
      this.setState({"imagescr":imageSrc});
      this.convertFileToImage(imageSrc);
    };
    async convertFileToImage(file) {
         
    }
   
    render() {
      const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "environment"
      };
   
      return (
        <div>
          <Webcam
            audio={false}
            height={350}
            ref={this.setRef}
            screenshotFormat="image/jpeg"
            width={350}
            videoConstraints={videoConstraints}
          />
          <button onClick={this.capture}>Capture photo</button>
          <div>
              show content here!<br/><br/>
          {this.state.imagescr ? <img src={this.state.imagescr} /> : null}
          </div>
        </div>
        
      );
    }
  }
export default webCam;
