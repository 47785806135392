import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/AgentHeader';
import { getAgentId, getAgentToken, getApiData,getBaseUrl,postApiData } from '../../services/Api';
import { Redirect } from 'react-router';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import Modal from 'react-responsive-modal';
import ViewClientDoc from '../common/Viewclientdoc';

class Detail extends React.Component {
    constructor(props) {
		super(props);
		this.state={
			client_list:[],
			client_application:[],
			client_documents:[],
			clientId:(props.listData[0])?props.listData[0]:0,
            is_guarantor:(props.listData[1])?props.listData[1]:0,
			client_rel:[],
			phone:'No Number',
			imagePath:'',
			agentId:'',
			clickedClientId:0,
		}
    }

    componentWillMount() {
		this.getClientList();
		this.getClientApplication();
		this.getClientRel();
		this.getClientDocument();
	}
	//client Details
	async getClientList() {
		let applicationList = [{
			url: "/agent/clientList/filter",
			where:{ searchClientId: this.state.clientId}
		}];
        getApiData(applicationList,true).then(res => {
		this.setState({'client_list':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}
	//client application
	async getClientApplication() {
		let applicationList = [{
			url: "/agent/applicationList/filter",
			where:{ searchClientId: this.state.clientId}
		}];
		
        getApiData(applicationList,true).then(res => {
		this.setState({'client_application':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}
//client Document
async getClientDocument() {
	let applicationList = [{
		url: "/Clients/clientDocument/get_uploadedDocument/",
		where:{ searchClientId: this.state.clientId}
	}];
	
	getApiData(applicationList,true).then(res => {
	this.setState({'client_documents':res.data});
	}).catch(error => {
	if(error.response){
		this.setState({myerrormsg: error.response.data.error.message});
	}
	});
}
	//client relation
	async getClientRel() {
		let applicationList = [{
			url: "/agent/clientRelValues",
			where:{ searchClientId: this.state.clientId}
		}];
		
        getApiData(applicationList,true).then(res => {
		console.log("res=>=>",res);
		this.setState({'imagePath':res.data[0].image});
		this.setState({'phone':res.data[0].phone});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}

	//handleLinking
handleLinking = param => e => {
	if(this.state.clickedClientId[3]==param[3]){
		this.setState({clickedClientId:0},()=>{
			this.setState({clickedClientId:param},()=>{});
		});
	}else{
		this.setState({clickedClientId:param},()=>{});
	}
	
}
handleRowClick=param=>e=>{
	if(param[1]=='Submitted'){
		this.setState({
			sendToEdit:1,
			clickedAppId:param[0]
		})
	}
	
}
downloadDocument=(appId,clientId)=>e=>{
	console.log("---",appId,clientId);
	let where=[{
		url:"/managers/downloadDocument/"+appId+"/"+clientId
	}];
	getApiData(where,true).then(res=>{
		// console.log("res----",res.data.Zipfile);
	var url= getBaseUrl()+"/containers/download_doc/download/"+res.data.Zipfile;
	// console.log("url",url);
	if(res.data.Zipfile){
		window.open(url);
	}
	else{
		alert("Unable to create zip,try again later.");
	}
	
	}).catch(error=>{

	})
}

downloadDiscloserFee=(id)=>{
	this.setState({
		linkingLoader:true
	})
	let where=[{
	url:"/agent/discloserdownload"
	}]
	postApiData(where,{'id':id},true).then(res=>{
		this.setState({
			linkingLoader:false
		})	
		console.log('--res',res);
			var fileName=res.data[0].discloser_file+'.pdf';
			var url= getBaseUrl()+"/containers/application_pdf/download/"+fileName+"/true";
			if(res.data[0].discloser_file!=null){
				window.open(url);
			}else{
				this.pdfDownload(res.data[0]);
				//alert("Fee Disclosure is not available");
			}
	}).catch(error=>{
		this.setState({
			linkingLoader:false
		})
	})
}

pdfDownload=(data)=>{
	const randomFileName = Math.floor(Math.random() * (+100000 - +0)) + +0;
	var disfileName=data.first_name+'_'+randomFileName;
	disfileName=disfileName.replace(/ /g,"_")
	this.setState({ 'linkingLoader':true},()=>{
	   
let where = [{
	url: "/onboardings/onboardingPdf/createPdf",
}];
console.log("--change",disfileName)
postApiData(where,{'application_id':disfileName, 'signature':data.signature, 'completed_date':data.completed_date, 'name': data.first_name, 'selected_agent_name': data.name},true).then(res => {
	let where=[{
		url:"/managers/updatediscloser"
	}]
	postApiData(where,{'id':data.id,'discloser_file':disfileName},true).then(response=>{
		this.setState({
			linkingLoader:false
		})
		var fileName=disfileName+'.pdf';
		var url= getBaseUrl()+"/containers/application_pdf/download/"+fileName+"/true";
		if(fileName){
			window.open(url);
		}else{
			alert("Fee Disclosure is not available");
		}
	}).catch(error=>{
		this.setState({
			linkingLoader:false
		})
	})
	   
}).catch(error => {
	this.setState({
		linkingLoader:false
	})
		if(error.res){
			this.setState({myerrormsg: error.res.data});
		}
	});
});
}
onOpenView = param=>(e) =>  {
	this.setState({ listingData:param[0], openViewer: true });
  };
 
  onCloseView = () => {
	this.setState({ listingData:{},openViewer: false },()=>{
		
	});
  };
    render() {

		if(this.state.sendToEdit!=undefined && this.state.sendToEdit==1){
			return (<Redirect to={'/agent/Appeditor/'+this.state.clickedAppId}/>);
		}
		if (!getAgentToken()) {
            return (<Redirect to={'/agent/landing'}/>);
		  }
		  if(this.state.client_list.length<1){
			return false;
		  }
       if(this.state.client_list.length<1 && this.state.client_rel.length<1){
		   return false;
	   }
	   var image='/member.png';
	   if(this.state.imagePath!='' && this.state.imagePath!=null){
			image=getBaseUrl()+"/containers/profile/download/"+this.state.imagePath;
	   }
	   var listItems='No record found';
	   if(this.state.client_application.length>0){
		listItems = this.state.client_application.map((application,index) => 
	   <tr key={index} >
	   <td key={application.id} onClick={this.handleRowClick([application.appid,application.status])}>{application.unit_number}, {application.apartment_address}</td>
	   <td onClick={this.handleRowClick([application.appid,application.status])}>{application.status}</td>
	   <td ><i onClick={this.downloadDocument(application.appid,application.clientId)} class="fa fa-download" aria-hidden="true"></i></td></tr> 
	  
	   );
       
	   }
	   var listDocument='No record found';
	   if(this.state.client_documents.length>0){
		listDocument = this.state.client_documents.map((documents,index) => 
	   <tr key={index} >
	   <td key={documents.id}>{documents.document_name}</td>
	   <td>{documents.file_path}</td>
	   <td class="action-view text-center"><Link to onClick={this.onOpenView([documents])}><i class="fa fa-eye"></i></Link>
      <a href={getBaseUrl()+"/containers/client-uploaded-doc/download/"+documents.file_path+"/true"} target="_blank" download><i class="fa fa-download"></i></a></td></tr> 
	  
	   );
       
	   }  
	   let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv =  encHex.parse("abcdef9876543210abcdef9876543210");

	    return (
			<div>
			
			<main>
			<div className="modal-content">

			{(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
								
									<div className="modal-header">
										<h4 className="modal-title head">Client Details</h4>
									
									</div>

							
									<div className="modal-body profile">
												<div className="form-section">
												<div className="clearfix profile-widget">
													<div className="info-wrap pull-left">
															<div className="heading-wrap profile-heading">
															{this.state.client_list[0].realm} {(this.state.is_guarantor)?'(G)':''}
															</div>
															<p>Status: {this.state.client_list[0].status}</p>
															<p>Fee Agreement & Disclosure<i style={{cursor:'pointer'}} onClick={()=>this.downloadDiscloserFee(this.state.client_list[0].id)} class="fa fa-download download" aria-hidden="true"></i></p>
													</div>
													<div className="img-wrap pull-right">
														<img src={image} width="90" height="90" className="mx-auto d-block rounded-circle"/>														
													</div>
													
												</div>
												<div>
															<div className="heading-wrap">
															Applications
															</div>
															<div className="applications-list">
															<table className="table table-striped clientDetailPop">
																	<tbody>
																	{listItems}															
																	</tbody>
																	</table>
														
															</div>
													
															<div className="heading-wrap">
															Documents
															</div>
															<div className="applications-list">
															<table className="table table-striped clientDetailPop">
																	<tbody>
																	{listDocument}															
																	</tbody>
																	</table>
														
															</div>
												</div>
						<Modal open={this.state.openViewer} onCloseEdit={this.onCloseView} classNames="modal-sm">
				<ViewClientDoc listingData={this.state.listingData} onClose={this.onCloseView}/>
				</Modal>
												<div className="contact-info">
															<div className="heading-wrap">
															Contact
															</div>
															<p>{(this.state.phone!='')?aes.decrypt(this.state.phone, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):''}</p>
															<p><a href={'mailto:'+aes.decrypt(this.state.client_list[0].email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}>{aes.decrypt(this.state.client_list[0].email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</a></p>
													
												</div>
											</div> 
											<button type="button" className="btn cl-btn pull-right" onClick={this.props.onClick} >Close  <img src="/close.png"/></button>                                                                       
									</div>
									
									</div>
				 </main>				 
				 </div>
                );
    }
}



export default Detail;