import React from 'react';
import { Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import Header from '../common/Header';
import FacebookProvider, { Login } from 'react-facebook-sdk';
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import {postApiData,getApiData,getUserId, setToken, setUserId, getToken,setOnBoardingDone,getOnBoardingDone,getBaseUrl, manageRedirect, setPasswordSetDone , setOnboardingData, getOnboardingData,getManagerToken,getAgentToken,getAdminToken} from '../../services/Api';
import {isEmail} from 'validator';
import validator from 'validator';
import {GOOGLE_CLIENT_ID} from '../../services/Const';



class UserLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            'mymsg':"",
            'myerrormsg':"",
            email: "",
            password: "",
            redirect:false,
            google_client_id:'',
            myflag:""
        }
        
    }

    handleResponse = (data) => {
        if (typeof data.profile !== 'undefined'){
            this.signupFb(data.profile.name, data.profile.email);
        }
    }
    responseGoogle = (data) => {
        if (typeof data.profileObj !== 'undefined'){
            this.doLogin(data.profileObj.email,'',true);
          if ( data.profileObj.email.length ==0) {
            this.setState({emailerror: 'Field is required.'});
            }else if(!isEmail(data.profileObj.email)){
                this.setState({myerror: 'Email is not valid.'});
          }else{
            this.signupFb(data.profileObj.name, data.profileObj.email);
          }
        }
    }
     validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }
    handleChange = (event) => {     
        switch(event.target.id){
              case 'email':     
                  if (event.target.value.length ==0) {
                        
                    this.setState({
                                    emailerror: 'Field is required.'
                                    ,email:event.target.value.toLowerCase()
                                  });
                                  break;
                    }
                   else if (!isEmail(event.target.value)) {
                        
                    this.setState({
                                    myerror: 'Email is not valid.'
                                    ,email:event.target.value.toLowerCase()
                                  });
                    }else{
                            this.setState({
                                    myerror: ''
                                    ,email:event.target.value.toLowerCase()
                                  });
                    }
                break;
                case 'password':        
                    if (event.target.value.length<=0) {
                        
                    this.setState({
                                    myerror2: 'Field is required.'
                                    ,password:event.target.value
                                  });
                    }else{
                          this.setState({
                                    myerror2: ''
                                    ,password:event.target.value
                                  });
                    }
                break;
        
        }
        console.log("event.target.value.toLowerCase",event.target.value.toLowerCase())
        // this.setState({
        //     [event.target.id]:event.target.value.toLowerCase()
        // });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.onLogin();
    }
    

    handleError = (error) => {
        this.setState({error});
    }
    async signupFb(name, email, password) {
        let where = [{
                url: "/Clients"
            }];
        postApiData(where, {'email':email,'username':email,'realm':name,'emailVerified':1,'password':password,'is_social_login':1,'is_password_set':0}).then(res => {
             this.doLogin(email,'',true); 
        }).catch(error => {
           this.doLogin(email,'',true);  
        });
        
    }
    
    async flagstatus(id,token){
      let where = [{
            url: "/Clients",
            urlParms: "/" + id + "?access_token=" + token
        }];    
        getApiData(where, false).then(res => { 
            this.setState({myflag:res.data.flag});
            setOnBoardingDone(res.data.flag);
            this.setState({redirect:true});
                                 
    });
    }
    async doLogin(email,password,is_social_login=false) {
        console.log('response.data.imagePathresponse.data.imagePath')
        let where = [{
                url: "/Clients/login"
            }];
            postApiData(where, {'email':email.toLowerCase(),'password':password,'is_social_login':is_social_login}).then(res => {
              console.log('res.data.is_completed',res.data.onboarding.is_completed)
                if(res.data.id){
                    localStorage.setItem('account_verified',res.data.emailVerified);
                setToken(res.data.id);
                setUserId(res.data.userId);
                setOnboardingData(res.data.onboarding);
                localStorage.setItem('user_id', res.data.userId);          
                localStorage.setItem('user_name', res.data.realm);
                setOnBoardingDone(res.data.onboarding.is_completed);
                
                setPasswordSetDone(res.data.is_password_set);
               
                this.setState({myflag:res.data.flag});
                let clientRelWhere = [{
                    url: "/ClientRels",
                    urlParms: "?filter="+JSON.stringify({"where":{"clientId":getUserId()}})+"&access_token=" + getToken(),
                }];
                getApiData(clientRelWhere,true).then(response => {
                if(response.data.id){
                    
                    var imgsrc;
                    imgsrc = getBaseUrl()+"/containers/profile/download/"+response.data.imagePath;
                    localStorage.setItem('imageUrl',imgsrc);
                }
                }).catch(error => {
                console.log('in catch', error);
                });
                //clientRel data
                if(is_social_login==false){
                    let clientRelWhere = [{
                        url: "/ClientRels",
                        urlParms: "?filter="+JSON.stringify({"where":{"clientId":getUserId()}})+"&access_token=" + getToken(),
                    }];
                    getApiData(clientRelWhere,true).then(response => {
                    if(response.data.id){
                        console.log('response.data.imagePathresponse.data.imagePath',response.data)
                        var imgsrc;
                        imgsrc = getBaseUrl()+"/containers/profile/download/"+response.data.imagePath;
                        localStorage.setItem('imageUrl',imgsrc);
                    }
                    }).catch(error => {
                    console.log('in catch', error);
                    });
                }else{
                    this.setState({myflag:res.data.flag});
                }
                  
            }
            
        }).catch(error => {
           this.setState({myerrormsg: error.response.data.error.message});
        });
    }

    async onLogin() {
        const {email, password} = this.state;
        this.doLogin(email,password,false);
    }
    render() {
        if(getToken()){
             if (this.state.myflag==true || this.state.myflag) {
                 return (<Redirect to={'/client/dashboard'}/>);
              }else{ 
                 return (<Redirect to={'/client/onboarding'}/>);
              }
     }

     if(getManagerToken() && this.state.redirect==true){
        return (<Redirect to={'/manager/application'}/>);
    }
    if(getAgentToken()){
        return (<Redirect to={'/agent/application'}/>);
    }
    if(getAdminToken()){
        return (<Redirect to={'/admin/agent'}/>);
    }


        return (
                <div>
                    <Header />
                        <main>
                            <div className='container'>
                            <div className="logoPlaceholder">
                             <Link to ="/" className="navbar-brand" className="text-center"> <img src="/logo.png" width="200"/></Link>
                             </div>
                                <h2 className="text-center">Sign in to your account</h2>
                                {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
                                
                                {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                            
                                <div className='form-widget login-widget'>
                                <form onSubmit={this.handleSubmit}>
         <FormGroup controlId="email">
           <FormControl
             autoFocus
             placeholder="Email"
             value={this.state.email}
             onChange={this.handleChange}
           />
           <span className="inline-error-class"> {this.state.myerror !==''?this.state.myerror:''}</span>
         </FormGroup>
         <FormGroup controlId="password">
           <FormControl
             value={this.state.password}
             onChange={this.handleChange}
             type="password"
             placeholder="Password"
             className="required"
           />
           <span className="inline-error-class"> {this.state.myerror2 !==''?this.state.myerror2:''}</span>
         </FormGroup>
         <Button
           block
           disabled={!this.validateForm()}
           type="submit"
         >
           Sign In
         </Button>
       </form>
       <p className="text-center or-txt">Or Sign in with</p>
                                    <div className="form-group"> 
                                    <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_ID}>
                                    <Login className="btn facebook"
                                    scope="email"
                                    onResponse={this.handleResponse}
                                    onError={this.handleError}
                                    >
                                    <Link to="" className="btn facebook"> Facebook</Link>
                                    </Login>
                                    </FacebookProvider>
                                   
                                    </div>
                                    <div className="form-group"> 
                                    <GoogleLogin
                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                        render={renderProps => (
                                            <button className="btn google" onClick={renderProps.onClick}>Google</button>
                                            
                                        )}
                                        buttonText="Login"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        />                                   
                                    </div>
                                    <p className="text-right small"><Link to="/client/forgot" >Forgot Password?</Link></p>

                                   <div className="need-ac">
                                    Need an account? <Link to="/client/signup/">Sign Up</Link>
                                   </div>
                                </div>                               
                            </div>
                        </main>
                </div>
                );
    }
}
export default UserLogin;
