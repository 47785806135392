import React from 'react';
import { Link } from 'react-router-dom';
import ManagerHeader from '../common/ManagerHeader';
import ManagerSidebar from '../common/ManagerSidebar';
import AgentSidebar from '../common/AgentSideBar';
import AgentHeader from '../common/AgentHeader';
import { setAgentToken, getAgentToken, getApiData, getAgentId, getBaseUrl,postApiData,getManagerName,getManagerId } from '../../services/Api';
import Modal from 'react-responsive-modal';
import { FormGroup, FormControl } from "react-bootstrap";
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import Moment from 'moment';
import AgentDocument from '../common/AgentDocument';
import ViewAgentDoc from '../common/Viewagentdoc';
import AgentEditDocument from '../common/agentEditDocument';
import AddNotes from '../common/addNotes';
import EditAgentNotes from '../common/editAgentNotes';
import { get } from 'https';
import { confirmAlert } from 'react-confirm-alert';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

let key = encHex.parse("0123456789abcdef0123456789abcdef");
let iv = encHex.parse("abcdef9876543210abcdef9876543210");
class AgentProfile extends React.Component {
	constructor(props) {
		super(props);

		if (this.props.location.agent_id != "" && this.props.location.agent_id != undefined) {

			localStorage.setItem("IdAgent", this.props.location.agent_id);
		}
		if (this.props.location.agent_id == 0) {   // this is for Josia hayat id

			localStorage.setItem("IdAgent", this.props.location.agent_id);
		}


		var id = localStorage.getItem('IdAgent')

		this.state = {
			agent_detail: '',
			type: (!getAgentId()) ? "Manager" : "Agent",
			agent_id: (!getAgentId()) ? id : getAgentId(),
			agent_doc_detail: [],
			openViewer:false,
			editOpen:false,
			open: false,
			opennotes:false,
			agent_notes_details:[],
			editOpenNotes:false,
			date_of_birth: '',
		};

	}

	componentWillMount() {

		this.getAgentData();
		this.getRlsAgentId();
		setInterval(() => {
			this.getAgentData();
		}, 60000);
	   this.getAgentDocDetail(this.state.agent_id);
	   this.getAgentNotesDetails(this.state.agent_id);
	}

	

	getAgentData = () => {
		let where = [{
			url: '/agent/agentDetail/' + this.state.agent_id,
		}];
		// else{
		// where=[{
		// 	url:'/agent/agentDetail/'+getAgentId(),
		// }];}
		getApiData(where, true).then(res => {
			console.log("-----res", res.data[0]);
			var first_name = res.data[0].name.split(' ');
			var last_name = [];
			if (first_name.length > 0) {
				for (var i = 1; i < first_name.length; i++) {

					last_name.push(first_name[i]);
				}
			}
			if(res.data[0].date_of_birth !==null){
              
				this.setState({
						  date_of_birth:Moment(res.data[0].date_of_birth).format('MM/DD/YYYY')
				 }); 
			   }

			this.setState({
				agent_detail: res.data[0],
				email: aes.decrypt(res.data[0].email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8),
				office_phone: res.data[0].office_phone ? aes.decrypt(res.data[0].office_phone, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) : '',
				mob_number: aes.decrypt(res.data[0].mob_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8),
				first_name: first_name[0],
				last_name: last_name.join(' ')

			})

			this.getMinuteLeft();
			
		}).catch(error => {
			
		})
	}

	getRlsAgentId = () => {
		var value;
		if (this.state.type == "Manager") {
			value = 1000 + parseInt(this.state.agent_id)
		} else {
			value = 1000 + parseInt(getAgentId())
		}
		let where = [{
			url: '/agent/rlsID'
		}]
		getApiData(where, true).then(res => {
			console.log("---res", res);
			this.setState({
				rls_id: res.data[0].value + '-' + value
			})
		}).catch(error => {

		})
	}

	getMinuteLeft = () => {
		// console.log("--nextThirtyMin--",nextThirtyMin)
		// console.log("--clientShowingId--",clientShowingId)
		Moment.locale('en');

		var startTime = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		var endTime = Moment(this.state.agent_detail.last_login).local().format('YYYY-MM-DD HH:mm:ss');
		console.log("start--", startTime);
		console.log("endTime--", endTime);

		var fromDate = parseInt(new Date(startTime).getTime() / 1000);
		var toDate = parseInt(new Date(endTime).getTime() / 1000);

		var timeDiff = Math.round((fromDate - toDate) / 3600 * 60);  // will give difference in minutes
		if (timeDiff == 0) {
			console.log("zero----", timeDiff);
		}
		else {
			// console.log("timeDiff---",timeDiff);
			// alert("time"+timeDiff);
			this.setState({
				diffInMin: timeDiff
			})
			// return timeDiff;
		}

	}

	// start code for ticket-836 'document section'

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
		this.getAgentDocDetail(this.state.agent_id);
	};

	// start code for ticket-836 document section

	getAgentDocDetail = (agentID) => {
		
			let where = [{
				url: '/getAgentDocument/' + agentID,
			}];
			getApiData(where, true).then(res => {
				console.log('agent_doc_detail==>', res.data)
				this.setState({
					agent_doc_detail: res.data,
				})
			}).catch(error => {
				console.log('error===>', error)
			})
	}

	// view the document
	onOpenView = param=>(e) =>  {
		this.setState({ listingData:param[0], openViewer: true });
	  };
	 
	  onCloseView = () => {
		this.setState({ listingData:{},openViewer: false },()=>{
			
		});
	  };

	  // delete the document

	  deleteDocument(doc_id,file_path){
		var r=window.confirm("Are you sure to delete the Uploaded document?");
		if(r==true){
		this.setState({linkingLoader:true});
		let where = [{
			url: "/deleteagentdocument"
			}];
		   
		   postApiData(where, {'id':doc_id,'file_path':file_path}, true).then(res_save => {
				console.log("res_save=>",res_save);
				if (res_save.data){
					this.getAgentDocDetail(this.state.agent_id);
					//call a function to get and update image
				}
				this.setState({linkingLoader:false});
	 })
	}
	}

	// Edit agent document
	onOpenEdit = param=>(e) => {
		this.setState({ 
			listingsData:param[0],
			editOpen: true });
	  };
	 
	  onCloseEdit = () => {
		this.setState({ editOpen: false,listingsData:{}},()=>{
			this.getAgentDocDetail(this.state.agent_id);
		});
	  };

	  // save agent notes
	  saveAgentNotes=()=>{
						
		let where=[{
			url:'/agent/agentDetailEdit'
		}];
		var formData= new FormData();
		formData.append("manager_name",getManagerName());
		formData.append("manager_id",getManagerId());
		//formData.append("notes_description",);
		
	}

	// open add notes model

	onOpenNotes =() => {
		
		this.setState({ 
			
			opennotes: true });
	  };
	 
	  onCloseNotes = () => {
		this.setState({ opennotes: false},()=>{
			this.getAgentNotesDetails(this.state.agent_id);
		});
	  };

	  // start code for ticket-836 Add Notes section section

	getAgentNotesDetails = (agentID) => {
		
		let where = [{
			url: '/getAgentNotes/' + agentID,
		}];
		getApiData(where, true).then(res => {
			console.log('agent_notes_details==>', res.data)
			this.setState({
				agent_notes_details: res.data,
			})
		}).catch(error => {
			console.log('error===>', error)
		})
}

// Edit agent Notes description
onOpenEditNotes = param=>(e) => {
	this.setState({ 
		listingsData:param[0],
		editOpenNotes: true });
  };
 
  onCloseEditNotes = () => {
	this.setState({ editOpenNotes: false,listingsData:{}},()=>{
		this.getAgentNotesDetails(this.state.agent_id);
	});
  };

  deleteAccount = param =>e =>{
	console.log(param);
	var conf=false;
	conf=window.confirm("Are you sure want to delete your account? After deletion it will not be recoverable")
	console.log(conf);
	if(conf){
		this.deleteAgentAccount(param)
	}
	          
}

	deleteAgentAccount=(param)=>{
		let agent_id_encoded = base64_encode(param);
		console.log("agent_id_encoded==",agent_id_encoded);
		let whereFind = [{
			url: "/front/agent/deleteAgent/"+agent_id_encoded,
		
		 }];
		getApiData(whereFind,true).then(res => {
			if(res){
				alert('Your account deleted successfully, you can create a new account with same email again.')
				window.location.href=getBaseUrl()+"/agent/landing";
			}else{
				console.log("Something went wrong");
			}
				
		})
		.catch(error => {
			console.log(error);
		}); 
	} 

	render() {
		const { userType } = this.props;
		console.log("this.state.diffInMin", this.state.agent_doc_detail);
		let agentdocument;
		if (this.state.agent_doc_detail.length>=0) {
			agentdocument = this.state.agent_doc_detail.map((s, i) =>
				<tr>
					<td>{i + 1}</td>
					<td>{s.document_name}</td>
					<td>{s.created_date ? Moment(s.created_date).format('L') : '-'}</td>

					{s.file_path ? <td class="action-view text-center"><Link to onClick={this.onOpenView([s])}><i class="fa fa-eye"></i></Link>
						<a href={getBaseUrl() + "/containers/agent-document/download/" + s.file_path + "/true"} target="_blank" download><i class="fa fa-download"></i></a>
						{this.state.type == "Manager" ?<Link to onClick={()=>this.deleteDocument(s.id,s.file_path)}><i class="fa fa-trash-o"></i></Link>:''}
						{this.state.type == "Manager" ?<Link to onClick={this.onOpenEdit([s])}><i class="fa fa-pencil-square-o"></i></Link>:''}
					</td>
						: ''}


				</tr>


			);
		}

		let agentNotesDesc;
		if (this.state.agent_doc_detail.length>=0) {
			agentNotesDesc = this.state.agent_notes_details.map((s, i) =>
				<tr>
					<td>{i + 1}</td>
					<td>{s.updated_date ? Moment(s.updated_date).format('L') : '-'}</td>
					<td>{s.manager_name}</td>
					<td>{unescape(s.notes_description)}</td>

					{this.state.type == "Manager" ?<td class="action-view text-center">
					 <Link to onClick={this.onOpenEditNotes([s])}><i class="fa fa-pencil-square-o ml-2"></i></Link>
					</td>:''}
						


				</tr>


			);
		}
		return (
			<div>
				{this.state.type == "Manager" ? <ManagerHeader /> : <AgentHeader />}
				<main>
					{this.state.type == "Manager" ? <ManagerSidebar /> : <AgentSidebar />}
					<div className="content" style={{ display: "block" }}>
						<div className="work-section">
							<div className="dashboard">
								<div className="agentprofile view-profile" >
									<p>Last Login: {(this.state.agent_detail.last_login != null && this.state.agent_detail.last_login != '0000-00-00 00:00:00') ? this.state.diffInMin : ""} min. ago</p>
									<div className="row">
										<div className="col-lg-5 visible">
											<div className="agentprofile-Edit profile-edit">
												<div className="row cleafix">
													<div class="col-xs-6 col-sm-6">
														{/* <div className="agentprofile-img" style={{background:'url(/profile.png) center no-repeat'}}></div> */}
														{(this.state.agent_detail.image!=null && this.state.agent_detail.image!='' && this.state.agent_detail.image!='undefined' && this.state.agent_detail.image!='member.png')? (this.state.agent_detail.image.startsWith("https://") || this.state.agent_detail.image.startsWith("http://"))?
															<div className="agentprofile-img" style={{ background: 'url(' + this.state.agent_detail.image + ') center no-repeat', borderRadius: '50%' }}></div>
															: <div className="agentprofile-img" style={{ background: 'url(' + getBaseUrl() + "/containers/agent_image/download/" + this.state.agent_detail.image + ') center no-repeat', borderRadius: '50%' }}></div>
															: <div className="agentprofile-img" style={{ background: 'url(/profile.png) center no-repeat', backgroundSize: 'cover !important', borderRadius: '50%' }}></div>}

													</div>
													<div class="col-xs-6 col-sm-6">
														<ul className="user-btn-group">
															{/* <li><Link to='/agent/agenteditprofile' className="btn btn-default">Edit</Link></li> */}
															{this.state.type == "Manager" ?
																<Link to={{ pathname: '/agent/agenteditprofile', type: 'Manager', agent_id: this.state.agent_id }}><li><button className="btn btn-default">Edit</button></li>
																</Link>
																:
																<Link to={{ pathname: '/agent/agenteditprofile' }}><li><button className="btn btn-default">Edit</button></li>
																</Link>
																
															}
															
															
														</ul>

													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-9">
											<div className="agentprofile-wrap">
												<h5>Website Info</h5>
												<table className="table infotable">
													<tr>
														<td width="40%">RLS ID</td>
														{/* <td>23392</td> */}
														<td>{this.state.agent_detail.REBNYAgentID}</td>
													</tr>
													<tr>
														<td>Email</td>
														{/* <td>info@skyward.com</td> */}
														{/* <td>{this.state.agent_detail.email}</td> */}
														<td>{this.state.email}</td>
													</tr>
													<tr>
														<td>Title</td>
														{/* <td>Founder | Prinipal Real State Broker</td> */}
														<td>{this.state.agent_detail.title}</td>
													</tr>
													<tr>
														<td>Office Phone</td>
														{/* <td>(917) 400-6861</td> */}
														{/* <td>{this.state.agent_detail.office_phone}</td> */}
														<td>{this.state.office_phone == '&' ? 'NA' : this.state.office_phone}</td>
													</tr>
													<tr>
														<td>Mobile Phone</td>
														{/* <td>(917) 400-6861</td> */}
														{/* <td>{this.state.agent_detail.mob_number}</td> */}
														<td>{this.state.mob_number == '&' ? 'NA' : this.state.mob_number}</td>
													</tr>
													<tr>
														<td>Bio</td>
														<td>
															{unescape(this.state.agent_detail.description)}
														</td>
													</tr>
												</table>
											</div>
											{/* Start code for ticket-836 'agent info' */}
											<div className="agentprofile-wrap" style={{ marginTop: '30px', marginBottom: '30' }}>
												<h5>Agent Info</h5>
												<table className="table infotable">
													<tr>
														<td width="40%">First Name:</td>
														<td>{this.state.first_name}</td>
													</tr>
													<tr>
														<td width="40%">Last Name:</td>
														<td>{this.state.last_name}</td>
													</tr>
													<tr>
														<td width="40%">Date of birth:</td>
														<td>{this.state.date_of_birth?this.state.date_of_birth:'N/A'} </td>
													</tr>
													<tr>
													<td colSpan="2">
															<table className="table infotable">
																<tr>
																	<td width="20%">Agent's Entity:</td>
																	<td width="30%">{this.state.agent_detail.agent_entity?this.state.agent_detail.agent_entity:'N/A'}</td>
																	<td width="20%">License Number:</td>
																	<td width="30%">{this.state.agent_detail.licence_number?this.state.agent_detail.licence_number:'N/A'}</td>
																</tr>
																<tr>
																	<td>Address:</td>
																	<td>{this.state.agent_detail.address?this.state.agent_detail.address:'N/A'}</td>
																	<td>Expiration Date:</td>
																	<td>{this.state.agent_detail.expiration_date != '0000-00-00 00:00:00' && this.state.agent_detail.expiration_date != null && this.state.agent_detail.expiration_date != '' ? Moment(this.state.agent_detail.expiration_date).format('MMMM DD, YYYY') : 'N/A'}</td>
																</tr>
																<tr>
																	<td>City:</td>
																	<td>{this.state.agent_detail.city?this.state.agent_detail.city:'N/A'}</td>
																	<td>Website:</td>
																	<td>{this.state.agent_detail.website?this.state.agent_detail.website:'N/A'}</td>
																</tr>
																<tr>
																	<td>State:</td>
																	<td>{this.state.agent_detail.state?this.state.agent_detail.state:'N/A'}</td>
																	<td>Hire Date:</td>
																	<td>{this.state.agent_detail.hire_date != '0000-00-00 00:00:00' && this.state.agent_detail.hire_date != null && this.state.agent_detail.hire_date != '' ? Moment(this.state.agent_detail.hire_date).format('MMMM DD, YYYY') : 'N/A'}</td>
																</tr>
																<tr>
																	<td>Zip:</td>
																	<td>{this.state.agent_detail.zipcode?this.state.agent_detail.zipcode:'N/A'}</td>
																	<td>End Date:</td>
																	<td>{this.state.agent_detail.end_date != '0000-00-00 00:00:00' && this.state.agent_detail.end_date != null && this.state.agent_detail.end_date != '' ? Moment(this.state.agent_detail.end_date).format('MMMM DD, YYYY') : 'N/A'}</td>
																</tr>
															</table>
														</td>

													</tr>

												</table>
											</div>
											{/* End code for ticket-836 'agent info' */}
											<div class="panel-body table-responsive mt-5">

											<div className="clearfix mt-5">
													<h2  className=" float-left">Documents</h2>
													{this.state.type == "Manager" ?<p className="text-right float-right"><button className="btn btn-default" type="button" data-toggle="modal" onClick={this.onOpenModal}>+ Add Document(s)</button></p>:''}
												</div>
												
												<table class="table table-striped agent-listing" style={{ marginTop: '5px' }}>
													<thead>
														<tr>
															<th>#</th>
															<th> Document Name</th>
															<th> Date Uploaded</th>
															<th className="text-center" style={{width:'150px'}}>Action</th>

														</tr>
													</thead>
													<tbody>
														
														{agentdocument.length>0?agentdocument:<tr><td colSpan="4">You don’t have any available document yet</td></tr>}
														{/* <tr>
														<td width="40">1</td>
														<td >Real Estate</td>
														<td >05/02/2019</td>
														<td width="160" className="text-center">
															<a href=""><i class="fa fa-eye ml-2"></i></a>
															<a href=""><i className="fa fa-download ml-2"></i></a>
															<a href=""><i class="fa fa-trash-o ml-2"></i></a>
															<a href=""><i class="fa fa-pencil-square-o ml-2"></i></a>
														</td>
													
													</tr>
													<tr>
														<td width="40">2</td>
														<td >Real Estate</td>
														<td >05/02/2019</td>
														<td width="160" className="text-center">
															<a href=""><i class="fa fa-eye ml-2"></i></a>
															<a href=""><i className="fa fa-download ml-2"></i></a>
															<a href=""><i class="fa fa-trash-o ml-2"></i></a>
															<a href=""><i class="fa fa-pencil-square-o ml-2"></i></a>
														</td>
													
													</tr> */}

													</tbody>
												</table>
											</div>

											<Modal open={this.state.open} onClose={this.onCloseModal} classNames="modal-sm">
												<AgentDocument onClose={this.onCloseModal} agent_id={!getAgentId() ? this.state.agent_detail.id : getAgentId()} />
											</Modal>

											<Modal open={this.state.openViewer} onClose={this.onCloseView} classNames="modal-sm">
				                                <ViewAgentDoc listingData={this.state.listingData} onClose={this.onCloseView}/>
				                            </Modal>
											<Modal open={this.state.editOpen} onClose={this.onCloseEdit} classNames="modal-sm">
				                                <AgentEditDocument listingsData={this.state.listingsData} onClose={this.onCloseEdit}/>
				                            </Modal>
											<Modal open={this.state.opennotes} onClose={this.onCloseNotes} classNames="modal-sm">
				                                <AddNotes agent_id={this.state.agent_id} onClose={this.onCloseNotes}/>
				                            </Modal>
											<Modal open={this.state.editOpenNotes} onClose={this.onCloseEditNotes} classNames="modal-sm">
				                                <EditAgentNotes listingsData={this.state.listingsData} onClose={this.onCloseEditNotes}/>
				                            </Modal>

											<div class="panel-body table-responsive ">
												<div className="clearfix mt-5">
													<h2  className=" float-left">Notes</h2>
													{this.state.type == "Manager" ?<p className="text-right float-right"><button className="btn btn-default" type="button" data-toggle="modal" onClick={this.onOpenNotes}>+ Add Notes</button></p>:''}
												</div>	
											
												
												<table class="table table-striped agent-listing" style={{ marginTop: '5px' }}>
													<thead>
														<tr>
															<th>#</th>
															<th>Date</th>
															<th> Manager</th>
															<th> Notes</th>
															{this.state.type == "Manager" ?<th className="text-center" style={{width:'150px'}}>Action</th>:''}

														</tr>
													</thead>
													<tbody>
														{agentNotesDesc.length>0?agentNotesDesc:<tr><td colSpan="4">You don’t have any Notes</td></tr>}
														{/* <tr>
															<td width="40">1</td>
															<td >05/02/2019</td>
															<td >Mark</td>
															<td >Something related to notes goes here.</td>
															<td width="80" className="text-center">

																<a href=""><i class="fa fa-pencil-square-o ml-2"></i></a>
															</td>

														</tr>
														<tr style={{ background: 'none', border: '0', borderTop: '1px solid rgba(0,0,0,.1)' }}>
															<td width="40">1</td>
															<td >05/02/2019</td>
															<td >Mark</td>
															<td >Something related to notes goes here.</td>
															<td width="80" className="text-center">

																<a href=""><i class="fa fa-pencil-square-o ml-2"></i></a>
															</td>

														</tr> */}


													</tbody>
												</table>
											</div>
										</div>
										<div className="col-lg-3 hidden">
											<div className="agentprofile-Edit profile-edit">
												<div className="row cleafix">
													<div class="col-sm-12">
														{this.state.agent_detail.image ? this.state.agent_detail.image.startsWith("https://") ?
															<div className="agentprofile-img" style={{ background: 'url(' + this.state.agent_detail.image + ') center no-repeat', borderRadius: '50%' }}></div>
															: <div className="agentprofile-img" style={{ background: 'url(' + getBaseUrl() + "/containers/agent_image/download/" + this.state.agent_detail.image + ') center no-repeat', backgroundSize: 'cover !important', borderRadius: '50%' }}></div>
															: <div className="agentprofile-img" style={{ background: 'url(/profile.png) center no-repeat', borderRadius: '50%' }}></div>}
														{/* <div className="agentprofile-img" style={{background:'url(/profile.png) center no-repeat'}}></div> */}

													</div>
													<div class="col-sm-12">
														<ul className="user-btn-group mt-3" style={{margin:'0 auto'}}>
															{this.state.type == "Manager" ?
																<Link to={{ pathname: '/agent/agenteditprofile', type: 'Manager', agent_id: this.state.agent_id }}><li><button className="btn btn-default">Edit</button></li>
																</Link>
																:
																<Link to={{ pathname: '/agent/agenteditprofile' }}><li><button className="btn btn-default">Edit</button></li>
																</Link>
															}
														</ul>
														<button to="#" onClick={this.deleteAccount(this.state.agent_detail.id)} className="btn btn-default">Delete My Account</button>
																

													</div>
												</div>
											</div>
										</div>
									</div>


								</div>
							</div>

						</div>
					</div>
				</main>
				<div id="addNotes" class="modal fade show" role="dialog">
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">Add Note</div>
							<div class="modal-body">

								<div className="form-group">
									<textarea className="form-control textarea" ></textarea>
								</div>
								<button className="btn btn-default">Save</button>





								<p className="text-right"><button type="button" className="btn cl-btn" data-dismiss="modal" onClick={this.saveAgentNotes}>Close  <img src="/close.png" /></button></p>
							</div>


						</div>
					</div>
				</div>


			</div>


		);
	}
}
export default AgentProfile;