import React from 'react';
import {postApiData,getBaseUrl} from '../../services/Api';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";

class AddFiles extends React.Component {
    constructor(props) {
		super(props);
		this.state={
			linkingLoader:false,
			name:'',
			file_path:'',
			showLoader:false,
			error_msg:'',
			success_msg:'',
			img_flag:false,
			selectedFile:{},
			folder_id:this.props.folder_id
		}
	}
	
	createFiles=()=>{
		this.imageSubmit(this.state.folder_id,this.state.selectedFile,this.state.name)
	}

	handleFrm=(e)=>{
		if(e.target.id=='name'){
			this.setState({
				name:e.target.value
			})
		}
	}
	validateStep(){
		return (this.state.name.trim().length>0 && this.state.img_flag && !isNaN(this.state.folder_id))?true:false;
	}

	fileHandler =(e) => {
		console.log('---file',e.target.files[0])
	  
		if(e.target.files[0]){
			let imgFlag = true;
		 const maxFileSize = 50428800;   //50mb
		 const value = e.target.files[0].name;
	
		 if(e.target.files[0].size>maxFileSize){
				 this.setState({error_msg:"The uploaded image is too large. The max image file size is set to 50MB",img_flag:false})
				 imgFlag = false;
				 }
		 if(imgFlag) { 
					this.setState({
			selectedFile:e.target.files[0], 
			img_flag:true,
			error_msg:'',
			imagePath: URL.createObjectURL(e.target.files[0]) ,
				})
						 //this.imageSubmit(this.state.list_id,e.target.files[0],params);
					}
			}  
	 }

imageSubmit=(folder_id,file,name)=>{
	this.setState({
		showLoader: true})
	let _this=this;
	let rentalDoc=[{
		url : '/containers/rental_doc/upload'
	}];
	const formData = new FormData();
	formData.append('file',file);
	postApiData(rentalDoc, formData,'post',true).then(res => {
	if(res.data.success){
		let where = [{
			url: "/addFiles"
			}];
			postApiData(where, {
				'folder_id':folder_id,
				'file_name':this.state.name,
				'file_path':res.data.file_name,
				},'post',true).then(res => {
					if(res.data.status=="success"){
						this.setState({
							showLoader: false,
							success_msg:'File Added',
							img_flag:false,
							selectedFile:{},
							name:''
							 },()=>{
								setTimeout(
									function()
									{ 
										_this.props.onClose();
									}
									, 3000
									);
							 });
					}
			}).catch(error => {
			this.setState({
				showLoader: false,
				error_msg:'Something went wrong,Try after some time',
				 });
			});
	}
	}).catch(error=>{
		this.setState({
			showLoader: false,
			error_msg:'Something went wrong,Try after some time',
			 });
		});
}

    render() {
		//console.log("folder",this.state.id);
		return (<div className="modal-content">
{(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}				
		<div className="modal-header">
			<h4 className="modal-title">Add Document</h4>
		</div>
		<div className="modal-body">
		{(this.state.error_msg!='')?<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>:''}
	{(this.state.success_msg !== '') ?<div className="alert alert-success"> {this.state.success_msg !==''?this.state.success_msg:''} </div>:''}
			<div className="form-group">
				<input type="text" id="name" value={this.state.name} className="form-control" placeholder="Document Name" onChange={this.handleFrm}/>
			</div>
			<div className="upload-btn-wrapper upload-doc mb-2" >
				<button className="btn btn-default" id="uploadBtn" type="button">+ Add Document</button>
				<FormGroup >
					<FormControl type="file"  onChange={this.fileHandler} />
				</FormGroup>
			</div>
			<br/>
			<button type="button" className="btn btn-default"  disabled={!this.validateStep()}  onClick={this.createFiles}>Submit</button>
			<button type="button" className="btn cl-btn pull-right" onClick={this.props.onClose} >Close  <img src="/close.png"/></button> 

		</div>
	</div>)
    }
}



export default AddFiles;