import React from 'react';
import { getApiData,getBaseUrl} from '../../services/Api';
import Moment from 'moment';

class DetailFile extends React.Component {
    constructor(props) {
        super(props);
        console.log("Props",props)
		this.state={
            file:(props.listData[0])?props.listData[0]:'',
            title:(props.listData[0])?props.listData[1]:'',
            description:(props.listData[0])?props.listData[2]:'',
            date_post:(props.listData[0] && props.listData[3] && props.listData[3]!='0000-00-00')?props.listData[3]:(props.listData[0] && props.listData[4] && props.listData[4]!='0000-00-00')?props.listData[4]:'',
		}
    }

    componentWillMount() {
		this.setState({
			file:this.state.file
			,title:this.state.title
			,description:this.state.description
			,date_post:this.state.date_post
		})
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.file!=this.props.file){
            this.setState({
                file:this.state.file
                ,title:this.state.title
                ,description:this.state.description
                ,date_post:this.state.date_post
            })
        } else{

        }
    }
	

    render() {

        
            let ext = this.state.file.substring(this.state.file.lastIndexOf('.')).toLowerCase();
        

		

	    return (
			
	
									<div className="modal-content pdf-box">
									
									
										<div className="modal-header">
										<h4 className="modal-title">File</h4>
										<button type="button" className="close"  onClick={this.props.onClick} data-dismiss="modal">&times;</button>
										</div>
										
									
										<div className="modal-body">
										<div className="edu-video-img" style={{textAlign:'center', width:'100%', height:'600px'}}>
                                            {(ext=='.pdf')?<embed width="100%" height="580" src={getBaseUrl()+"/containers/media_file/download/"+this.state.file}/>:
                                      <a href={getBaseUrl()+"/containers/media_file/download/"+this.state.file+"/true"} target="_blank"><img src="/pdf-img.png"/></a>}
										</div>
											<div className="edu-wrap">
												<i>{(this.state.title)?this.state.title:''}</i>
												<h4>{(this.state.description)?this.state.description:''}</h4>
												<p className="post-date"><span>Posted </span>{Moment(this.state.date_post).format('MMMM, Y')}</p>
											</div>
                                        <p className="text-right"><button type="button" className="btn cl-btn pull-right" onClick={this.props.onClick} >Close  <img src="/close.png"/></button> </p>
										</div>
										
								
									
									</div>
								
				 
                );
    }
}



export default DetailFile;