import React from 'react';
import {Link} from 'react-router-dom';

class AdminSideBar extends React.Component {
    render() {
         var pathName = window.location.pathname;
        return (
            <div className="sidebar adminSidebar">
            <ul className="sidebar-nav">
            <li className={(pathName=='/admin/Agent') || (pathName=='/admin/Agent/')?'active':''}><Link to="/admin/Agent"><img src="/client.png"/>  <span>Agent</span></Link></li>
            <li className={(pathName=='/admin/Manager') || (pathName=='/admin/Manager/')?'active':''}><Link to="/admin/Manager"><i class="fa fa-user"></i> <span> Manager</span></Link></li>
            <li className={(pathName=='/admin/Address') || (pathName=='/admin/Address/')?'active':''}><Link to="/admin/Address"><i class="fa fa-location-arrow"></i>  <span>Address</span></Link></li>
            <li className={(pathName=='/admin/Company') || (pathName=='/admin/Company/')?'active':''}><Link to="/admin/Company"><i class="fa fa-user"></i>  <span>Company</span></Link></li>
            <li className={(pathName=='/admin/video/listing') || (pathName=='/admin/video/listing/')?'active':''}><Link to="/admin/video/listing"><i class="fa fa-video-camera" aria-hidden="true"></i> <span>Videos</span></Link></li>
            <li className={(pathName=='/admin/audio/listing') || (pathName=='/admin/audio/listing/')?'active':''}><Link to="/admin/audio/listing"><i class="fa fa-file-audio-o" aria-hidden="true"></i> <span>Audio</span></Link></li>
            <li className={(pathName=='/admin/file/listing') || (pathName=='/admin/file/listing/')?'active':''}><Link to="/admin/file/listing"><i class="fa fa-file" aria-hidden="true"></i> <span>File</span></Link></li>
            <li class="dropdown dropleft">
                 <Link to  className="dropdown-toggle" data-toggle="dropdown">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path  className="svgicon" d="M10.0001 16.3638H3.63647V17.2729H10.0001V16.3638Z" fill="black"/>
                        <path  className="svgicon" d="M16.3637 16.3636V17.2727H18.1819V0H4.54553V1.81818H5.45462V0.909091H17.2728V16.3636H16.3637Z" fill="black"/>
                        <path  className="svgicon" d="M13.6365 13.6362H3.63647V14.5453H13.6365V13.6362Z" fill="black"/>
                        <path  className="svgicon" d="M1.81824 2.91796V19.8089C1.81824 19.9143 1.90187 19.9998 2.00551 19.9998H15.2664C15.371 19.9998 15.4546 19.9152 15.4546 19.8089V2.91796C15.4546 2.81251 15.371 2.72705 15.2673 2.72705H2.00551C1.90187 2.72705 1.81824 2.8116 1.81824 2.91796ZM2.72733 3.63614H14.5455V19.0907H2.72733V3.63614Z" fill="black"/>
                        <path  className="svgicon" d="M13.6365 5.45459H3.63647V6.36368H13.6365V5.45459Z" fill="black"/>
                        <path  className="svgicon" d="M13.6365 8.18164H3.63647V9.09073H13.6365V8.18164Z" fill="black"/>
                        <path  className="svgicon" d="M13.6365 10.9092H3.63647V11.8183H13.6365V10.9092Z" fill="black"/>
                    </svg>  
                 <span> Master Data Setup </span>
              </Link>
            <div class="dropdown-menu"> 
                    <Link to="/admin/mediatopic" class="dropdown-item-text">Media Topic</Link> 
                    <Link to="/admin/adddomians" class="dropdown-item-text">Manage Email Domains</Link> 
                    <Link to="/admin/mediacategory" class="dropdown-item-text">Media Category</Link> 
                    <Link to="/admin/airconditiontype" class="dropdown-item-text">Air Conditioning Type Master</Link> 
                    <Link to="/admin/cobrokeagreement" class="dropdown-item-text">CoBroke Agreement</Link> 
                    <Link to="/admin/buildingtype" class="dropdown-item-text">Building Type Master</Link> 
                    <Link to="/admin/ownerpaybroker" class="dropdown-item-text">Owner Pays Broker Master</Link> 
                    <Link to="/admin/managermarketing" class="dropdown-item-text">Manage Marketing</Link> 
                    <Link to="/admin/manage-documents" class="dropdown-item-text">Manage Documents</Link> 
            </div>
         </li>



            {/*<li className={(pathName=='/admin/airconditiontype') || (pathName=='/admin/airconditiontype/')?'active':''}><Link to="/admin/airconditiontype"><i class="fa fa-user"></i>  <span>AirType</span></Link></li>
            <li className={(pathName=='/admin/buildingtype') || (pathName=='/admin/buildingtype/')?'active':''}><Link to="/admin/buildingtype"><i class="fa fa-building"></i>  <span>Building</span></Link></li>
            <li className={(pathName=='/admin/ownerpaybroker') || (pathName=='/admin/ownerpaybroker/')?'active':''}><Link to="/admin/ownerpaybroker"><i class="fa fa-user"></i>  <span>OwnerPay</span></Link></li>
            <li className={(pathName=='/admin/cobrokeagreement') || (pathName=='/admin/cobrokeagreement/')?'active':''}><Link to="/admin/cobrokeagreement"><i class="fa fa-user"></i>  <span>CoBroke</span></Link></li>
            <li className={(pathName=='/admin/mediatopic') || (pathName=='/admin/mediatopic/')?'active':''}><Link to="/admin/mediatopic"><i class="fa fa-user"></i>  <span>Media Topic</span></Link></li>
            <li className={(pathName=='/admin/mediacategory') || (pathName=='/admin/mediacategory/')?'active':''}><Link to="/admin/mediacategory"><i class="fa fa-user"></i>  <span>Media Category</span></Link></li>
            <li className={(pathName=='/admin/managermarketing') || (pathName=='/admin/managermarketing/')?'active':''}><Link to="/admin/managermarketing"><i class="fa fa-pie-chart"></i>  <span>Manage Marketing</span></Link></li>
                 <li className={pathName=='/admin/Listing'?'active':''}><Link to="/admin/Listing"><img src="/client.png"/>  Listing</Link></li>
                <li className={pathName=='/admin/Edit'?'active':''}><Link to="/admin/Edit"><img src="/application.png"/> Edit</Link></li> */}



            </ul>
        </div>
                );
    }
}
export default AdminSideBar;