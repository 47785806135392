import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/AgentHeader';
import SideBar from '../common/AgentSideBar';
import { setAgentToken, getAgentToken, getApiData , getAgentId} from '../../services/Api';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router';
import ClientDetail from './Detail';
import AgentChatbox from './Chatbox';
import onClickOutside from 'react-onclickoutside'


class Dashboard extends React.Component {
	
	handleClickOutside = () => {
			document.getElementById('search').classList.remove("show");
			document.getElementById('status').classList.remove("show");
	  }
	
	  
    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content} />
                </div>
                );
    }
}

class Content extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
			client_list:[],
			clientDW:[],
			where:{},
			notStarted:{'checked':true,'value':1},
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
      open: false,
			searchClientId:'',
			searchParm:"",
			searchMsg:false,
			myerrormsg:"",
			clientId:'',
			agentId:"",
			
		  };
		   // Added for DropdownClose on Clickoutside
		this.setWrapperRef1 = this.setWrapperRef1.bind(this);
		this.setWrapperRef2 = this.setWrapperRef2.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		  this.createWhere();
		}
		
		ClientHeadClick(){ document.getElementById('status').classList.remove("show");}
		StatusHeadClick(){document.getElementById('search').classList.remove("show");}	

	componentWillMount(){
		window.scroll(0,0);
		document.addEventListener('mousedown', this.handleClickOutside);
		var agent=getAgentId()
		this.setState({agentId:agent},()=>{
		this.getClientList();
		this.clientDD();
		});
	}
        
        componentWillUnmount(){
			document.addEventListener('mousedown', this.handleClickOutside);
            this.getClientList();
            this.clientDD();
		}
		
		setWrapperRef1(node) {
			this.wrapperRef1 = node;
		  }
	
		  setWrapperRef2(node) {
			this.wrapperRef2 = node;
		  }
	
		  handleClickOutside(event) {
			if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
				document.getElementById('search').classList.remove("show");
			}
	
			if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
				document.getElementById('status').classList.remove("show");
			  }
	
	
		  }
	
	//open model
	onOpenModal = () => {
		document.body.classList.add('modalTransparent');
                this.setState({ open: true });
	};
	//close model
	onCloseModal = () => {
            this.setState({ open: false });
	};
    // client list
	async getClientList() {
		this.setState({linkingLoader:true});
		let applicationList = [{
            url: "/agent/clientList",
		}];
		var len =Object.keys(this.state.where);
		if(len.length>0){
			applicationList[0].where=this.state.where;
		}
        getApiData(applicationList,true).then(res => {
        this.setState({'client_list':res.data,linkingLoader:false});
		document.getElementById('search').classList.remove("show");
		document.getElementById('status').classList.remove("show");
		
        }).catch(error => {
			this.setState({linkingLoader:false});
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}
	//client Dropdown
	async clientDD(){
		let clientList = [{
			url: "/agent/clientDD",
			where:{ agent_id: this.state.agentId}
		}];
        getApiData(clientList,true).then(res => {
        this.setState({'clientDW':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}
	//Full reset Filters reset
	resetFilter=()=>{
		this.setState({
			client_list:[],
			where:{},
			notStarted:{'checked':true,'value':1},
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
			searchClientId:'',
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('NotStart').checked = true;
			document.getElementById('searchParm').value='';
			document.getElementById('searchClick').classList.remove("active");
			document.getElementById('Sent').checked = true;
			document.getElementById('submit').checked = true;
			document.getElementById('Incomplete').checked = true;
			document.getElementById('statusKey').classList.remove("active")
			this.clientDD();
			this.createWhere();

		  });
		  
	}

	resetsearchParm=()=>{
		this.setState({
			client_list:[],
			where:{},
			searchClientId:'',
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('searchParm').value='';
			document.getElementById('searchClick').classList.remove("active");
			this.clientDD();
			this.createWhere();

		  });
	}


	resetStatus=()=>{
		this.setState({
			client_list:[],
			where:{},
			notStarted:{'checked':true,'value':1},
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
			myerrormsg:"",
		  },()=>{
			document.getElementById('NotStart').checked = true;
			document.getElementById('Sent').checked = true;
			document.getElementById('submit').checked = true;
			document.getElementById('Incomplete').checked = true;
			document.getElementById('statusKey').classList.remove("active")
			this.createWhere();

		  });
	}

	//search handling
	search=(event) =>{
		//this.setState({[event.target.id]: event.target.value});
		//console.log("event",event);
		//console.log("event.target.id",event.target.id,"event.target.value",event.target.value);
	 var key=event.target.id;
	  switch(key){
		case 'NotStart':     
		if(this.state.notStarted.value==0 && this.state.notStarted.checked==false){
			this.state.notStarted.checked=true;
			this.state.notStarted.value=1;
			this.state.statusFil=1;
			this.createWhere();
		}else if(this.state.notStarted.value==1 && this.state.notStarted.checked==true){
			this.state.notStarted.checked=false;
			this.state.notStarted.value=0;
			this.createWhere();
		}
		break;
		case 'Sent':     
			if(this.state.ckSent.value==0 && this.state.ckSent.checked==false){
				this.state.ckSent.checked=true;
				this.state.ckSent.value=1;
				this.state.statusFil=1;
				this.createWhere();
			}else if(this.state.ckSent.value==1 && this.state.ckSent.checked==true){
				this.state.ckSent.checked=false;
				this.state.ckSent.value=0;
				this.createWhere();
			}
			break;
		case 'submit':
		if(this.state.ckSubmit.value==0 && this.state.ckSubmit.checked==false){
			this.state.ckSubmit.checked=true;
			this.state.statusFil=1;
			this.state.ckSubmit.value=1;
			this.createWhere();
		}else if(this.state.ckSubmit.value==1 && this.state.ckSubmit.checked==true){
			this.state.ckSubmit.checked=false;
			this.state.ckSubmit.value=0;
			this.createWhere();
		}        
			break;
		case 'Incomplete':  
		if(this.state.ckIncomplete.value==0 && this.state.ckIncomplete.checked==false){
			this.state.ckIncomplete.checked=true;
			this.state.statusFil=1;
			this.state.ckIncomplete.value=1;
			this.createWhere();
		}else if(this.state.ckIncomplete.value==1 && this.state.ckIncomplete.checked==true){
			this.state.ckIncomplete.checked=false;
			this.state.ckIncomplete.value=0;
			this.createWhere();
		}        
			break;
		case 'searchParm':
			this.setState({searchParm:event.target.value});
			//if(this.state.searchParm.length>1){	
				var value=this.state.searchParm.trim();
				if(event.key=='Enter'){
					this.getData(value);

				}else if(value==''){
					this.state.clientDW.forEach(function(client,index) {
							client.flag=0;
					});
					this.setState({searchMsg:false});
				}else {
					var len = this.state.clientDW.length;
					var count=0;
					var vflag=0;
					this.state.clientDW.forEach(function(client,index) {
						
						if(client.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
							client.flag=1;
							vflag=1;
							count=count+1;
						}else{
							client.flag=0;
							if(vflag!=1)
							vflag=0
							count=count+1;
						}
	
					});
					
					if(len==count){
						if(vflag==0){
							this.setState({searchMsg:true});
							
						}else{
							this.setState({searchMsg:false});
							
						}

						
					}


				}
				

				
				
			//}
		break;
		case 'searchkey':
		var value=event.target.value;
			this.setState({ searchClientId: event.target.value}, () => {
				this.state.clientDW.forEach(function(client,index) {
					if(client.name==value){
						document.getElementById('searchClick').classList.add("active");
						document.getElementById('searchParm').value=client.name;
					}else{
						client.flag=0;
					}
				});
				document.getElementById('searchClick').classList.add("active");
				this.createWhere();
			  });
		break;
		case 'default':
		this.createWhere();
		break;
		 
		}
		if(this.state.statusFil>0){
			document.getElementById('statusKey').classList.add("active");
		}else{
			document.getElementById('statusKey').classList.remove("active");
		}
	  }
	  async createWhere(){
		this.state.where={};
		var inValue="";
		if(this.state.notStarted.value==1){
			this.state.where.notStarted=0;
		}
		if(this.state.ckSent.value==1){
			this.state.where.sent=3;
		}
		if(this.state.ckSubmit.value==1){
			this.state.where.submit=2;		
		}
		if(this.state.ckIncomplete.value==1){
			this.state.where.incomplete=1;
		}
		if(this.state.searchClientId!=''){
			this.state.where.searchClientId=this.state.searchClientId;
		}
                this.state.where.agentId = getAgentId();
		this.getClientList();
	}
	//model start
        handleClick = param => e => {
			this.setState({clientId:param},()=>{
				this.onOpenModal();
			});  
		}
	//model end	

		handleClickOnList=param=>e=>{
			var value=param[0];
			this.getData(value);
		}
getData(value){
	this.setState({ searchClientId: value}, () => {
		this.state.clientDW.forEach(function(client,index) {
			if(client.name==value){
				document.getElementById('searchClick').classList.add("active");
				document.getElementById('searchParm').value=client.name;
			}else{
				client.flag=0;
			}
		});
		document.getElementById('searchClick').classList.add("active");
		this.createWhere();
	  });
}

openChat=()=>{
	console.log("herer");
	this.state.openChat=true;
}
closeChat=()=>{
	console.log("hereraaaaaaa");
	this.state.openChat=false;
}
    render() {
		if (!getAgentToken()) {
            return (<Redirect to={'/agent/landing'}/>);
		  }
                 const { open } = this.state;

		  
		const clientDW = this.state.clientDW.map((dw,index) => 
		(dw.flag==1) ? <li id="searchkey" onClick={this.handleClickOnList([dw.name])}>{dw.name}</li>:''
		);
		var listItems='';
		if(this.state.client_list.length>0){
			listItems = this.state.client_list.map((client,index) => 
                    <tr onClick={this.handleClick([client.clientId ,client.is_guarantor])}>
			<td className="managerGridCol">{client.realm} {(client.is_guarantor)?'(G)':''}</td>
			<td className="managerGridCol">{(client.status!=null)?client.status:'Not Started'}</td>				
                    </tr>
	);	
	}
		//else{
		// 	listItems = 
		// 	<tr>
		// 	<td>No Record found for the searched criteria.</td>
		// 	<td></td>				
		// </tr>
		// }


        return (
                <div>
                    <Header />
                    <main>
					<SideBar/>
					<div className="content">
						<div className="work-section">
						<div className="dashboard">
						{(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}


							
								<h2 className="pull-left">Client</h2>
								<button onClick={this.resetFilter} className="btn btn-default pull-right ">Reset all filters</button>
								<div className="panel-body table-responsive">
									<table className="table table-striped clGrid">
										<thead>
											<tr>
												<th width="80%">Client name <Link to id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="search" class="collapse table-collapse client-collapse" ref={this.setWrapperRef1}>

													<div className="box">
													
													<input type="text" id="searchParm" autocomplete="off" class="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} />
													
														<ul>
														{clientDW}
														</ul>
														{(this.state.searchMsg)?'No suggestions available':''}

													</div>
												</div>
												</th>
												<th>Status <Link to id="statusKey" data-toggle="collapse" data-target="#status" className="icon-wrap status" onClick={this.StatusHeadClick}></Link>
												<div id="status" class="collapse table-collapse status-collapse clientstatus-collapse" ref={this.setWrapperRef2}>
													<div className="box">
													<div className="filterSearchReset"><Link to className="clearFilter" onClick={this.resetStatus}><i class="fa fa-undo"></i> Reset</Link></div>
													<input type="checkbox" id="Sent" defaultChecked={this.state.ckSent.checked} value={this.state.ckSent.value} onChange={this.search} />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" defaultChecked={this.state.ckSubmit.checked} value={this.state.ckSubmit.value} onChange={this.search}/>
													<label for="submit">Submitted</label>
													<input type="checkbox" id="Incomplete"  defaultChecked={this.state.ckIncomplete.checked} value={this.state.ckIncomplete.value} onChange={this.search}/>
													<label for="Incomplete">Incomplete</label>
													<input type="checkbox" id="NotStart" defaultChecked={this.state.notStarted.checked} value={this.state.notStarted.value} onChange={this.search} />
													<label for="NotStart">Not Started</label>
													
													</div>
												</div>		
												</th>
											
											</tr>
										</thead>
										<tbody>
										{(this.state.client_list.length>0 && !this.state.linkingLoader)?listItems:(this.state.client_list.length==0 && !this.state.linkingLoader)?<tr><td colSpan="2" className="managerGridCol">No Record Found</td></tr>:'' }			
											
										</tbody>
										</table>
									</div>
							</div>

						</div>
					</div>	

		<Modal open={open} onClose={this.onCloseModal} little>
                         <ClientDetail listData={this.state.clientId} onClick={this.onCloseModal}/>
                                 
		</Modal>
               				      
                          
                    </main>  
					<Modal open={this.state.openChat} little>
					<AgentChatbox onClose={this.closeChat} />
					</Modal> 

					<div className="OpenChat">
						<Link to onClick={this.openChat}><i class="fa fa-comments-o"></i></Link>
					</div> 
						

                </div>

                );
    }
}
export default onClickOutside(Dashboard);
//export default Dashboard;