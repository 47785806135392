import React, { Component } from 'react';
import ResourcesPage from '../agent/Resources';

class ManagerResource extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div>
                <ResourcesPage 
                userType='Manager'
                />
            </div>
        );
    }
}

export default ManagerResource;