import React from 'react';
import { Redirect } from 'react-router';


export function getCurrentFormatDate() {
    var d = new Date();
    var stDate = d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + d.getDate();
    return stDate;
}
export function getFormatDateMDY(date) {
    var d = new Date(date);
    var stDate = ("0" + (d.getMonth() + 1)).slice(-2) + '-' + d.getDate() + '-' + d.getFullYear();
    return stDate;
}

export function sliceFileName(str) {
    return str.slice(0, 10) + '.....' + str.slice(-10, -4) + str.substring(str.lastIndexOf('.'));
}
export function getFileHTML(filename, src) {
    var html = '';
    if (filename) {
        var ext = filename.substring(filename.lastIndexOf('.')).toLowerCase();
        if (ext == '.pdf') {
            html = <iframe src={src} width='100%' height='500px'></iframe>;
        } else {
            html = <img src={src} width='100%'/>;
        }
    }

    return html;
}
