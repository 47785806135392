import React from 'react';
import { Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import {isEmail} from 'validator';
import { Redirect } from 'react-router';
import {postApiData, getApiData, setManagerToken,getAdminToken,setManagerName,getToken,getAgentToken, getManagerToken,setManagerId, setManagerEmail} from '../../services/Api';
import {GOOGLE_CLIENT_ID} from '../../services/Const';




class Landing extends React.Component {
   
    constructor(props) {
        super(props);
        this.state= {
            'mymsg':"",
            'myerrormsg':"",
             email: "",
             password: "",
             redirect:false,
             google_client_id:'',
             myflag:""
        }
        
    }
    
   
    
   responseGoogle = (data) => {
        if (typeof data.profileObj !== 'undefined'){
          localStorage.setItem('managerImageUrl',data.profileObj.imageUrl);
          if ( data.profileObj.email.length ==0) {
            this.setState({emailerror: 'Field is required.'});
            }else if(!isEmail(data.profileObj.email)){
                this.setState({myerror: 'Email is not valid.'});
          }else{
            this.doLogin(data.profileObj.name, data.profileObj.email);
          }
        }
    }
    
     async doLogin(name,email) {
        let where = [{
                url: "/managers/auth/login"
            }];
            postApiData(where, {'name':name,'email':email}).then(res => {
            if(res.data.id){
                 
                 setManagerToken(res.data.id);
                 setManagerName(name);
                 setManagerId(res.data.userId);
                 setManagerEmail(email);
                 this.setState({redirect:true});
            }
        }).catch(error => {
           this.setState({myerrormsg: error.response.data.error.message});
        });
    }
    
    render() {
        if(getManagerToken() && this.state.redirect==true){
            return (<Redirect to={'/manager/resources'}/>);
        }
        if(getAgentToken()){
            return (<Redirect to={'/agent/resources'}/>);
        }
        if(getToken()){
            return (<Redirect to={'/client/dashboard'}/>);
        }
        if(getAdminToken()){
            return (<Redirect to={'/admin/Agent'}/>);
        }
       
       
        return (
            <div>
            <div className='container landing-content'>
            <div className='landing-section'>
            {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
            {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                            
             <img src="/logo.png" />
             <br/><br/>
               <GoogleLogin clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={renderProps => (
               <Link to="#" className="btn btn-default" onClick={renderProps.onClick}>Sign in with your Skyward Google account</Link>
                                            
                )}
                                        buttonText="Login"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        /> 
               </div>
            </div>
    </div>

                );
    }
}
export default Landing;
