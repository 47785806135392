import React from 'react';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside'
import socketIOClient from "socket.io-client";
import {getAgentName} from '../../services/Api';
import { object } from 'prop-types';
import Moment from 'moment';
//const socket = socketIOClient(process.env.REACT_APP_SOCKETURL);

class Chatbox extends React.Component {
	
	constructor(props) {
		super(props);
		console.log("this.props",this.props.listOfVar.socket);
		this.state = {
		  response: false
		  ,endpoint: process.env.REACT_APP_SOCKETURL
		  ,iAm:[]
		  ,nxLevel:true
		  ,chatArr:[]
		  ,agentName:getAgentName()
		  ,room:(this.props.listOfVar.room)?this.props.listOfVar.room:''
		  ,socket:(this.props.listOfVar.socket)?this.props.listOfVar.socket:socketIOClient(process.env.REACT_APP_SOCKETURL)
		  ,askToJoin:(this.props.listOfVar.room)?true:false
		  ,messageBox:''
		  ,isRoomClose:true
		};
		this.mesRef = React.createRef();
	  }
	
	  componentDidMount() {
		  console.log("this.state.askToJoin",this.state.askToJoin);
        const { endpoint } = this.state;
		let _data_;
		if(this.state.askToJoin){
			//openpopup
			this.setState({ 
				response:true,
				isRoomClose:false
			  },()=>{
				this.setState(state => (this.state.chatArr.push({
					'user':'server'
					,message:(this.state.askToJoin)?'Hi,Please join the chat':'No Chat Request'
					,enableAcceptButton:(this.state.askToJoin)?true:false
					,timez:Moment(new Date()).format('hh:mm a'),
					})))
			  });

		}else{
			//
			this.state.socket.once("user_manager",(room)=>{
				console.log("data chat request recived",room)
				this.setState({
				 room:room
				 ,isRoomClose:false
				},()=>{
					this.setState(state => (this.state.chatArr.push({
					'user':'server'
					,message:'Hi,Please join the chat'
					,enableAcceptButton:true
					,timez:Moment(new Date()).format('hh:mm a'),
					})))
				})
			});
		}
        
         //will close accept and decline pop-up
        this.state.socket.once('request_is_accepted',(data)=>{
			console.log("herererer1")
		});

		this.state.socket.on("closing_room",()=>{
			console.log('room close')
			this.setState({
				isRoomClose:true
			})
			this.setState(state => (this.state.chatArr.push({
				'user':'Agent'
				,message:'Chat is closed'
				,options:[]
				,iAm:''
				,nxLevel:true
				,timez:Moment(new Date()).format('hh:mm a'),
				})))
				var myVar=setTimeout(function(){ 
					var objDiv = document.getElementById("chatDiv");
				   objDiv.scrollTop +=objDiv.scrollTop+objDiv.scrollHeight 
					}, 300);	
		})
		           
    }
	acceptChatRoomRequest=()=>{
		this.aceptConnection(this.state.room);
	}
      
    aceptConnection(room){
        if(room){
			this.state.socket.emit('connecting_manager',room); 
			this.setState(state => (this.state.chatArr.push({
				'user':'user'
				,message:'How may I help you'
				,options:[]
				,iAm:''
				,nxLevel:true
				,timez:Moment(new Date()).format('hh:mm a')
				,room:room
				})))  

				this.state.socket.on("room_chat",(obj)=>{
					console.log("obj",obj)
					this.setState(state => (this.state.chatArr.push({
						'user':obj.userType
						,message:obj.msg
						,options:[]
						,iAm:''
						,nxLevel:true
						,timez:Moment(new Date()).format('hh:mm a')
						})))

						var myVar=setTimeout(function(){ 
							var objDiv = document.getElementById("chatDiv");
						   objDiv.scrollTop +=objDiv.scrollTop+objDiv.scrollHeight 
							}, 300);
				  })

				  //will close accept and decline pop-up
				  this.state.socket.once('request_is_accepted',(data)=>{
					console.log("request_is_accepted",data)
						});
      	}
    }
      
        

	
	  requiredHelp=()=>{
		console.log("Button Clicked Done");
		//this.scrollToBottom();
	  }
	  scrollToBottom = () => {
		  //console.log("this.mesRef",this.mesRef.current.scrollHeight)
		//   if(this.mesRef){
		// 	this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight+50;
		//   }
		
	  }
	  Minimize() {
		document.getElementById('Chatbox').style.height = "50px"
		var x = document.getElementById("Max");
		var x1 = document.getElementById("Min");
			if (x.style.display === "block") {
				x.style.display = "none";
				x1.style.display = "block";
			} else {
				x.style.display = "block";
				x1.style.display = "none";
			}
	}
	Maximize() {
		document.getElementById('Chatbox').style.height = "initial"
		var x = document.getElementById("Min");
		var x1 = document.getElementById("Max");
		if (x.style.display === "none") {
			x.style.display = "block";
			x1.style.display = "none";
		} else {
			x.style.display = "none";
			x1.style.display = "block";
		}
	}
	sendButtonClick=()=>{
		console.log("Send Button clicked manager",this.state.room)
		let tempObj = {
			msg: this.state.messageBox,
			from: 'ManagerName',
			userType:'Manager',
			room:this.state.room
		}
		this.state.socket.emit("agent_manager_chat",tempObj);
		// this.setState(state => (this.state.chatArr.push({
		// 	'user':'user'
		// 	,message:this.state.messageBox
		// 	,options:[]
		// 	,iAm:''
		// 	,nxLevel:true
		// 	,timez:Moment(new Date()).format('hh:mm a')
		// 	})))

			this.setState({
				messageBox:'',
				room:this.state.room
			})
			var myVar=setTimeout(function(){ 
				var objDiv = document.getElementById("chatDiv");
			   objDiv.scrollTop +=objDiv.scrollTop+objDiv.scrollHeight 
				}, 300);	
		  
	  }
	  handleMsg=(e)=>{
		console.log("event",e.target.id,e.target.value)
		if(e.target.value){
			this.setState({
				messageBox:e.target.value
			});
		}
	}
	_handleKeyDown=(e)=> {
		if (e.key === 'Enter' && !e.shiftKey) {
			this.sendButtonClick();
		}
	  
	}
	finishCurrentChat=()=>{
		this.state.socket.emit("finish_user_manager_chat",this.state.room)
		
	}
	 render() {
  
		console.log("this.state.chatArr",this.state.chatArr);
		let optionList = (this.state.chatArr.length>0)?
	   this.state.chatArr.map((opt,index) =>
	   (opt.user=='server' || opt.user=='Agent' )? 
	   <li class="left clearfix">
	   <span class="chat-img pull-left">
	   <img src="/skyward-icon.png" class="img-circle"/>
	   </span>
	   
	   <div class="chat-body clearfix">                              
		   <div className="Name"><strong class="primary-font">Skyward</strong></div>
		   
		   <div class="msgBox">
		   <p>{opt.message}</p>
		   {(opt.enableAcceptButton)?<button class="btn btn-default didntBtn" onClick={this.acceptChatRoomRequest}>Accept</button>:''}
		   {(opt.enableAcceptButton)?<button class="btn btn-default didntBtn">Denied</button>:''}
		   </div>
		   <small class="text-muted">{opt.timez}</small>

	   </div>
   </li>
		:<li class="right clearfix">
		<span class="chat-img pull-right">
		<img src="/users.jpg" class="img-circle"/></span>
		<div class="chat-body clearfix">                                
			<div className="clearfix">
			<div class="msgBox">
			{opt.message}
			</div>
			</div>
		<div className="text-right"><small class=" text-muted">{opt.timez}</small></div>
		</div>
	</li>
		):'';


        return (
                <div>
                 
                    <main>				
					
					<div id="Chatbox" class="OpenChatbox">
							<div className="header">
								<i class="fa fa-comments-o"></i> Skyward
								<Link to className="close" onClick={this.props.onClose}><img src="/close.png"/></Link>
								<Link id="Min" to className="close minimize" onClick={this.Minimize}><img src="/minimize.png"/></Link>
								<Link style={{display:'none'}} id="Max" to className="close maximize" onClick={this.Maximize}><img src="/maximize.png"/></Link> 
							</div>
							{/* <ScrollToBottom className={ ROOT_CSS } atBottom> */}
							<div className="chatBlock" id="chatDiv">
							<ul class="chat">
							{optionList}
							</ul>
							</div>
							{/* </ScrollToBottom> */}
							<div className="sendMsg" id='markDown'>
							<div class="input-group">
								<div class="input-group-prepend">
								<div class="input-group-text" id="btnGroupAddon"><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4995 0C5.59683 0 0 5.59683 0 12.4995C0 19.4021 5.59683 25 12.4995 25C19.4021 25 25 19.4021 25 12.4995C25 5.59683 19.4021 0 12.4995 0ZM15.1016 19.3725C14.4582 19.6265 13.946 19.819 13.5619 19.9524C13.1788 20.0857 12.7333 20.1524 12.2265 20.1524C11.4476 20.1524 10.8413 19.9619 10.4095 19.582C9.97778 19.2021 9.76296 18.7206 9.76296 18.1355C9.76296 17.9079 9.77884 17.6751 9.81058 17.4381C9.84339 17.2011 9.89524 16.9344 9.96614 16.6349L10.7714 13.7905C10.8423 13.5175 10.9037 13.2582 10.9524 13.0169C11.0011 12.7735 11.0243 12.5503 11.0243 12.3471C11.0243 11.9852 10.9492 11.7312 10.8 11.5884C10.6487 11.4455 10.364 11.3757 9.93968 11.3757C9.73228 11.3757 9.51852 11.4063 9.29947 11.4709C9.08254 11.5376 8.89418 11.5979 8.73968 11.6571L8.95238 10.781C9.47937 10.5661 9.98413 10.382 10.4656 10.2296C10.9471 10.0751 11.4021 9.99894 11.8307 9.99894C12.6042 9.99894 13.2011 10.1873 13.6212 10.5598C14.0392 10.9333 14.2497 11.419 14.2497 12.0159C14.2497 12.1397 14.2349 12.3577 14.2063 12.6688C14.1778 12.981 14.1238 13.2656 14.0455 13.527L13.2444 16.363C13.1788 16.5905 13.1206 16.8508 13.0677 17.1418C13.0159 17.4328 12.9905 17.655 12.9905 17.8042C12.9905 18.181 13.0741 18.4381 13.2434 18.5746C13.4106 18.7111 13.7037 18.7799 14.1185 18.7799C14.3143 18.7799 14.5333 18.745 14.781 18.6772C15.0265 18.6095 15.2042 18.5492 15.3164 18.4974L15.1016 19.3725ZM14.9598 7.86138C14.5862 8.20847 14.1365 8.38201 13.6106 8.38201C13.0857 8.38201 12.6328 8.20847 12.2561 7.86138C11.8815 7.51429 11.6921 7.09206 11.6921 6.59894C11.6921 6.10688 11.8825 5.6836 12.2561 5.33333C12.6328 4.98201 13.0857 4.80741 13.6106 4.80741C14.1365 4.80741 14.5873 4.98201 14.9598 5.33333C15.3333 5.6836 15.5206 6.10688 15.5206 6.59894C15.5206 7.09312 15.3333 7.51429 14.9598 7.86138Z" fill="#004F6B"/>
</svg>
</div>
								</div>
								<textarea id="msg" type="text" class="form-control" placeholder="Type Something..." onChange={this.handleMsg} onKeyDown={this._handleKeyDown} value={this.state.messageBox} ></textarea>
								<div class="input-group-prepend">
								<div class="input-group-text">
									<button className="sendbtn" onClick={(!this.state.isRoomClose)?this.sendButtonClick:(null)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path className="arrow" d="M19.7276 9.34241L13.776 3.39074C13.4128 3.02761 12.8239 3.02761 12.4608 3.39074C12.0976 3.75394 12.0976 4.34271 12.4608 4.70591L16.8249 9.07005H2.92995C2.41639 9.07005 2 9.48644 2 10C2 10.5135 2.41639 10.9299 2.92995 10.9299H16.8249L12.4609 15.2941C12.0977 15.6573 12.0977 16.2461 12.4609 16.6093C12.6425 16.7907 12.8805 16.8816 13.1185 16.8816C13.3565 16.8816 13.5945 16.7907 13.7761 16.6093L19.7276 10.6576C20.0908 10.2944 20.0908 9.70561 19.7276 9.34241Z" fill="#004F6B"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg></button>
{(!this.state.isRoomClose)?<button onClick={this.finishCurrentChat}>Finish</button>:''}
								</div>
								</div>
							</div>
							
							</div>	

					</div>	

		
               				      
                          
                    </main>    
					

                </div>

                );
    }
}
export default Chatbox;
