import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/AgentHeader';
import { getManagerToken, getApiData,getBaseUrl , postApiData } from '../../services/Api';
import { Redirect } from 'react-router';
import Modal from 'react-responsive-modal';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import ViewClientDoc from '../common/Viewclientdoc';

class Detail extends React.Component {
    constructor(props) {
		super(props);
		this.state={
			client_list:[],
			client_application:[],
			client_documents:[],
			clientId:(props.listData[0])?props.listData[0]:0,
                        preId:0,
                        is_guarantor:(props.listData[1])?props.listData[1]:0,
                        agentDW: (props.agentDW)?props.agentDW:[],
			client_rel:[],
                        modelAgentName:'',
			phone:'No Number',
			imagePath:'',
                        loader_status:false,
                        open: false
		}
    }

onOpenView = param=>(e) =>  {
	this.setState({ listingData:param[0], openViewer: true });
  };
 
  onCloseView = () => {
	this.setState({ listingData:{},openViewer: false },()=>{
		
	});
  };
    onOpenModal = () => {
		document.body.classList.add('modalTransparent');
                this.setState({ open: true });
	};
	//close model
	onCloseModal = () => {
		this.setState({ open: false });
	};
        
        handleClick = param => e => {
		this.onOpenModal();
	}
        handleClickOnAgent=param=>e=>{
		var value=param[0], id = param[1];
		this.getAgentData(value, id);
	}
        handleAgentSearch = event => {
    var key=event.target.id; 
    switch(key){
		case 'agent_search_id':
                this.setState({modelAgentName: event.target.value,agenterror:""},()=>{
                var value=this.state.modelAgentName;
                if(value!=''){
                    this.state.agentDW.forEach(function(agent,index) {
                        if(agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
                                agent.flag=1;
                            }else{
                                agent.flag=0;
                            }
						}); 
						var temp = 0;
						this.state.agentDW.forEach((agent)=>{
							if(agent.flag == 1){
								temp = 1
							}
						})
						if(temp == 0){
							var errorname =value + " is not registered"
							this.setState({
								agenterror:errorname
							})
						}	
                }else{
					document.getElementById('agent_search_id_values').style.display = 'none';
					this.setState({
						agenterror:'Name is required'
					})
                    this.state.agentDW.forEach(function(agent,index) {
                        agent.flag=0;
                    });    
                }
            })
							
				              
                //}
		break;
		case 'searchkey':
		var value=event.target.value;
                let $this = this;
			this.setState({ agent_id: event.target.value}, () => {
				this.state.agentDW.forEach(function(agent,index) {
					if(agent.id==value){
					    document.getElementById('agent_search_id_values').style.display = "none";
                                            document.getElementById('agent_search_id').value=agent.name;
                                            $this.setState({modelAgentName:agent.name});
					}else{
						agent.flag=0;
					}
				});
				document.getElementById('agent_search_id_values').style.display = "none";
			  });
		break;
		case 'default':
		break;
		 
    }
document.getElementById('agent_search_id_values').style.display = "block";
}
	getAgentData(value,id){
		this.setState({ modelAgentName: value,agent_id: id}, () => {
			this.state.agentDW.forEach(function(agent,index) {
			if(agent.name==value){
					document.getElementById('agent_search_id').value=value;
                                        document.getElementById('agent_search_id_values').style.display = "none";
				}else{
					agent.flag=0;
				}
			});
		  });
		}
                
        handleSubmitAgentName= event => {
		event.preventDefault();
		console.log("this.state.client_list",this.state.client_list);
        let whereFind = [{
            url: "/onboardings/"+this.state.preId,
        }];
        let agent_id = this.state.agent_id;
        postApiData(whereFind,{'agent_id':agent_id},true).then(res => {
			this.state.client_list[0].agent_name = this.state.modelAgentName;
			//MS-280 update all application incomplete appliaction
			let data = {
				'agent_id':this.state.agent_id,
				'clientID':this.state.client_list[0].clientId
			};
			var url = "/updateAgentId";
			let where = [{
				url: url
			}];
			postApiData(where, data, true).then(response => {
				console.log("res=>=>",res);
			}).catch(error => {
				console.log('in catch', error);
			});
			//MS-280
            this.onCloseModal();
        })
        .catch(error => {
            console.log('in catch', error);
        }); 
    }

        componentWillMount() {
	  this.getClientList();
	  this.getClientApplication();
	  this.getClientRel();
	  this.getClientDocument();
	}
        componentWillUnmount(){
          this.getClientList();
	  this.getClientApplication();
	  this.getClientRel();
        }
	//client Details
	async getClientList() {
            let applicationList = [{
		url: "/agent/clientList/filter",
		where:{ searchClientId: this.state.clientId}
	    }];
        getApiData(applicationList,true).then(res => {
            this.setState({'client_list':res.data,preId:res.data[0].id,loader_status:true});
        }).catch(error => {
            if(error.response){
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });
	}
//client Document
async getClientDocument() {
	let applicationList = [{
		url: "/Clients/clientDocument/get_uploadedDocument/",
		where:{ searchClientId: this.state.clientId}
	}];
	
	getApiData(applicationList,true).then(res => {
	this.setState({'client_documents':res.data});
	}).catch(error => {
	if(error.response){
		this.setState({myerrormsg: error.response.data.error.message});
	}
	});
}	
	//client application
	async getClientApplication() {
		let applicationList = [{
			url: "/agent/applicationList/filter",
			where:{ searchClientId: this.state.clientId}
		}];
		
        getApiData(applicationList,true).then(res => {
		this.setState({'client_application':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}

	//client relation
	async getClientRel() {
		let applicationList = [{
			url: "/agent/clientRelValues",
			where:{ searchClientId: this.state.clientId}
		}];
		
        getApiData(applicationList,true).then(res => {
		this.setState({'imagePath':res.data[0].image});
		this.setState({'phone':res.data[0].phone});
                
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}
	handleRowClick=param=>e=>{
		if(param[1]=='Submitted'){
			this.setState({
				sendToEdit:1,
				clickedAppId:param[0]
			})
		}
		
	}

	validate=()=>{
		var flag = 0;
		this.state.agentDW.forEach((agent)=>{
			if(agent.name == this.state.modelAgentName){
				flag = 1;
			}
		})
		return (this.state.agenterror == '' && flag == 1)
	}
	downloadDocument=(appId,clientId)=>e=>{
		console.log("---",appId,clientId);
		let where=[{
			url:"/managers/downloadDocument/"+appId+"/"+clientId
		}];
		getApiData(where,true).then(res=>{
			// console.log("res----",res.data.Zipfile);
		var url= getBaseUrl()+"/containers/download_doc/download/"+res.data.Zipfile;
		if(res.data.Zipfile){
			window.open(url);
		}
		else{
			alert("Unable to create zip,try again later.");
		}
		}).catch(error=>{
	
		})
	}

	// downloadDiscloserFee=(id)=>{
	// 	console.log("id",id)
	
	// 	let where=[{
	// 	url:"/agent/discloserdownload"
	// 	}]
	// 	postApiData(where,{'id':id},true).then(res=>{
	// 			console.log('--res',res);
	// 			var fileName=res.data[0].discloser_file+'.pdf';
	// 			var url= getBaseUrl()+"/containers/application_pdf/download/"+fileName;
	// 			if(res.data[0].discloser_file!=null){
	// 				window.open(url);
	// 			}else{
	// 				alert("Fee Disclosure is not available");
	// 			}
	// 	}).catch(error=>{
	
	// 	})
	// }
	downloadDiscloserFee=(id)=>{
		this.setState({
			linkingLoader:true
		})
		let where=[{
		url:"/agent/discloserdownload"
		}]
		postApiData(where,{'id':id},true).then(res=>{
			this.setState({
				linkingLoader:false
			})	
			console.log('--res',res);
				var fileName=res.data[0].discloser_file+'.pdf';
				var url= getBaseUrl()+"/containers/application_pdf/download/"+fileName+"/true";
				if(res.data[0].discloser_file!=null){
					window.open(url);
				}else{
					this.pdfDownload(res.data[0]);
					//alert("Fee Disclosure is not available");
				}
		}).catch(error=>{
			this.setState({
				linkingLoader:false
			})
		})
	}
	
	pdfDownload=(data)=>{
		const randomFileName = Math.floor(Math.random() * (+100000 - +0)) + +0;
		var disfileName=data.first_name+'_'+randomFileName;
		disfileName=disfileName.replace(/ /g,"_")
		this.setState({ 'linkingLoader':true},()=>{
		   
	let where = [{
		url: "/onboardings/onboardingPdf/createPdf",
	}];
	console.log("--change",disfileName)
	postApiData(where,{'application_id':disfileName, 'signature':data.signature, 'completed_date':data.completed_date, 'name': data.first_name, 'selected_agent_name': data.name},true).then(res => {
		let where=[{
			url:"/managers/updatediscloser"
		}]
		postApiData(where,{'id':data.id,'discloser_file':disfileName},true).then(response=>{
			this.setState({
				linkingLoader:false
			})
			var fileName=disfileName+'.pdf';
			var url= getBaseUrl()+"/containers/application_pdf/download/"+fileName+"/true";
			if(fileName){
				window.open(url);
			}else{
				alert("Fee Disclosure is not available");
			}
		}).catch(error=>{
			this.setState({
				linkingLoader:false
			})
		})
		   
	}).catch(error => {
		this.setState({
			linkingLoader:false
		})
			if(error.res){
				this.setState({myerrormsg: error.res.data});
			}
		});
	});
	}
	
    render() {
		if(this.state.sendToEdit!=undefined && this.state.sendToEdit==1){
			return (<Redirect to={'/manager/Appeditor/'+this.state.clickedAppId}/>);
		}
		if (!getManagerToken()) {
            return (<Redirect to={'/manager/landing'}/>);
		  }
		  if(this.state.client_list.length<1){
			return false;
		  }
       if(this.state.client_list.length<1 && this.state.client_rel.length<1){
		   return false;
	   }
	   var image='/member.png';
	   if(this.state.imagePath!=''  && this.state.imagePath!=null){
			image=getBaseUrl()+"/containers/profile/download/"+this.state.imagePath;
	   }
            const agentDW = this.state.agentDW.map((aw,index) => 
                (aw.flag==1) ? <li id="searchkey" key={aw.id} value={aw.id} onClick={this.handleClickOnAgent([aw.name,aw.id])}>{aw.name}</li>:''
		);
		var listItems='No record found';
		if(this.state.client_application.length>0){
		 listItems = this.state.client_application.map((application,index) => 
		<tr key={index} >
		<td key={application.id} onClick={this.handleRowClick([application.appid,application.status])}>{application.unit_number}, {application.apartment_address}</td>
		<td onClick={this.handleRowClick([application.appid,application.status])}>{application.status}</td>
		<td ><Link to onClick={this.downloadDocument(application.appid,application.clientId)}><i  class="fa fa-download" aria-hidden="true"></i></Link></td></tr>
			 );
		
		}
		var listDocument='No record found';
		if(this.state.client_documents.length>0){
		 listDocument = this.state.client_documents.map((documents,index) => 
		<tr key={index} >
		<td key={documents.id}>{documents.document_name}</td>
		<td>{documents.file_path}</td>
		<td class="action-view text-center"><Link to onClick={this.onOpenView([documents])}><i class="fa fa-eye"></i></Link>
	   <a href={getBaseUrl()+"/containers/client-uploaded-doc/download/"+documents.file_path+"/true"} target="_blank" download><i class="fa fa-download"></i></a></td></tr> 
	   
		);
		
		}  
		let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv =  encHex.parse("abcdef9876543210abcdef9876543210");	
        return (
			<div>
			
			<main>
                        {(!this.state.loader_status)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:<div className="modal-content">
				
						{(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
									<div className="modal-header">
										<h4 className="modal-title head">Client Details</h4>
									</div>
									<div className="modal-body profile">
												<div className="form-section">
												<div className="clearfix profile-widget">
													<div className="info-wrap pull-left">
															<div className="heading-wrap profile-heading">
															{this.state.client_list[0].realm} {(this.state.is_guarantor)?'(G)':''}
															</div>
															<p>Status: {this.state.client_list[0].status}</p>
															<p>Fee Agreement & Disclosure <i style={{cursor:'pointer'}} onClick={()=>this.downloadDiscloserFee(this.state.client_list[0].id)} class="fa fa-download download" aria-hidden="true"></i></p>
														</div>
													<div className="img-wrap pull-right">
														<img src={image} width="90" height="90" className="mx-auto d-block rounded-circle"/>														
													</div>
												</div>
												<div><div className="heading-wrap">Applications</div>
												<div className="applications-list">
												<table className="table table-striped managerDetailPop"><tbody>{listItems}</tbody>	</table>
											
												</div>
												</div>
												<div className="heading-wrap">
															Documents
															</div>
															<div className="applications-list">
															<table className="table table-striped clientDetailPop">
																	<tbody>
																	{listDocument}															
																	</tbody>
																	</table>
														
															</div>
															<Modal open={this.state.openViewer} onCloseEdit={this.onCloseView} classNames="modal-sm">
				<ViewClientDoc listingData={this.state.listingData} onClose={this.onCloseView}/>
				</Modal>
												<div className="contact-info">
                                                                                                <div className="heading-wrap">Contact</div>
												<p>{(this.state.phone!='')?aes.decrypt(this.state.phone, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):''}</p>
												<p><a href={'mailto:'+aes.decrypt(this.state.client_list[0].email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}>{aes.decrypt(this.state.client_list[0].email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</a></p>
												</div>
                                                                                                <div className="contact-info">
                                                                                                <div className="heading-wrap">Agent</div>
                                                                                                 <p>{this.state.client_list[0].agent_name}{(getManagerToken())?<Link to className="editsmall" onClick={this.handleClick()}><i className="fa fa-pencil" aria-hidden="true"></i></Link>:''}</p>
																								 <button type="button" className="btn cl-btn pull-right" onClick={this.props.onClick} >Close  <img src="/close.png"/></button>                                                                       
                                                                                                </div>
                                        <Modal open={this.state.open} onClose={this.onCloseModal} center>
                                        <div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title head">Edit Agent</h4>
									</div>
                                                                        <div className="modal-body profile"><form id="frm3" onSubmit={this.handleSubmitAgentName}>
                                        <div className="box" style={{marginBottom:'11px'}}>						
                                                <input type="text" id="agent_search_id" onChange={this.handleAgentSearch} onKeyDownCapture={this.handleAgentSearch} onKeyUpCapture={this.handleAgentSearch}  autoComplete="off" defaultValue={(this.state.modelAgentName)?this.state.modelAgentName:this.state.client_list[0].agent_name}  className="form-control"/>
                                                <div className="box agentNameOnBoard" id="agent_search_id_values" style={{display:'none'}} >
                                                      <ul>{agentDW}</ul>
													
                                                </div>
												<span className="inline-error-class"> {this.state.agenterror !==''?this.state.agenterror:''} </span><br/>
                                        </div>
                                            
                                            <button className="btn btn-default" disabled={!this.validate()}>Save</button> 
                                    </form></div></div>
                                 
                                        </Modal>
											</div> 
									</div>
									</div>}
			
				 </main>				 
				 </div>
                );
    }
}



export default Detail;