import React from 'react';
import { Link } from 'react-router-dom';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { GoogleLogin } from 'react-google-login';
import {isEmail} from 'validator';
import { Redirect } from 'react-router';
import {postApiData,getApiData, setAgentToken,setAgentName,getAgentId,setToken,getToken,getAdminToken,getAgentDomain, getAgentToken,getManagerToken,setAgentId,setAgentEmail,setAgentImage,setAgentDomain} from '../../services/Api';
//import {GOOGLE_CLIENT_ID} from '../../services/Const';


class UserLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
        'mymsg':"",
        'myerrormsg':"",
         email: "",
         password: "",
         redirect:false,
         google_client_id:'',
         myflag:""
    }
    
}
responseGoogle = (data) => {
  if (typeof data.profileObj !== 'undefined'){
      console.log('data.profileObj.imageUrl',data.profileObj.imageUrl);
    localStorage.setItem('agentImageUrl',data.profileObj.imageUrl);
    if ( data.profileObj.email.length ==0) {
      this.setState({emailerror: 'Field is required.'});
      }else if(!isEmail(data.profileObj.email)){
          this.setState({myerror: 'Email is not valid.'});
    }else{
      this.doLogin(data.profileObj.name, data.profileObj.email,data.profileObj.imageUrl);
    }
  }
}

async doLogin(name,email,image) {
  let where = [{
          url: "/agents/auth/login"
      }];
      postApiData(where, {'name':name,'email':email,'agentprofileimage':image}).then(res => {
      if(res.data.id){
          setAgentToken(res.data.id);
         this.setValueStorage(res.data,(err,result)=>{
              if(err){

              }
              if(result){
                  this.setState({redirect:true});
              }
          })
           
      }
  }).catch(error => {
     this.setState({myerrormsg: error.response.data.error.message});
  });
}
setValueStorage(data,cb) {
  console.log('data.agent_image',data.agent_image)
  setAgentToken(data.id);
           setAgentName(data.user_name);
           setAgentId(data.userId);
           setAgentEmail(data.user_email);
           setAgentImage(data.agent_image);
           setAgentDomain(data.domain);
           cb(null,true);
}
async onLogin() {
  const {email, password} = this.state;
  this.doCustomLogin(email,password,false);
}
handleSubmit = event => {
  event.preventDefault();
  this.onLogin();
}
async doCustomLogin(email,password,is_social_login=false) {
    let where = [{
          url: "/agents/auth/customlogin"
      }];
      postApiData(where, {'email':email.toLowerCase(),'password':password,'is_social_login':is_social_login}).then(res => {
        
          if(res.data.id){
              localStorage.setItem('account_verified',res.data.emailVerified);
          setAgentToken(res.data.id);
          setAgentName(res.data.user_name);
          setAgentId(res.data.userId);
          setAgentEmail(res.data.user_email); 
          setAgentDomain(res.data.domain);     
          //clientRel data
          this.setState({mymsg: 'Logdded in successfully.'});
          }
      
  }).catch(error => {
    console.log(error);
    this.setState({myerrormsg: error.response.data.error.message});
  });
}
validateForm() {
  return this.state.email.length > 0 && this.state.password.length > 0;
}
handleChange = (event) => {     
  switch(event.target.id){
        case 'email':     
            if (event.target.value.length ==0) {
                  
              this.setState({
                              emailerror: 'Field is required.'
                              ,email:event.target.value.toLowerCase()
                            });
                            break;
              }
             else if (!isEmail(event.target.value)) {
                  
              this.setState({
                              myerror: 'Email is not valid.'
                              ,email:event.target.value.toLowerCase()
                            });
              }else{
                      this.setState({
                              myerror: ''
                              ,email:event.target.value.toLowerCase()
                            });
              }
          break;
          case 'password':        
              if (event.target.value.length<=0) {
                  
              this.setState({
                              myerror2: 'Field is required.'
                              ,password:event.target.value
                            });
              }else{
                    this.setState({
                              myerror2: ''
                              ,password:event.target.value
                            });
              }
          break;
  
  }
  
  // this.setState({
  //     [event.target.id]:event.target.value.toLowerCase()
  // });
}
    render() {
    
      if(getAgentToken()){
        if(getAgentDomain()==='kw.com'){
      
        return (<Redirect to={'/agent/application'}/>);
        }
        else
        {
          return (<Redirect to={'/agent/showings'}/>);
        }
    }
    if(getToken()){
        return (<Redirect to={'/client/dashboard'}/>);
    }
    if(getManagerToken()){
        return (<Redirect to={'/manager/showings'}/>);
    }
    if(getAdminToken()){
        return (<Redirect to={'/admin/Agent'}/>);
    }
        return (
                <div>
                  
                        <main>
                            <div className='container'>
                            <div className="logoPlaceholder">
                             <Link to ="/" className="navbar-brand" className="text-center"> <img src="/logo.png" /></Link>
                             </div>
                                <h2 className="text-center">Sign in to your account</h2>
                                {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
                                
                                {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                            
                            
                                <div className='form-widget login-widget'>
                                <form onSubmit={this.handleSubmit}>
                                <FormGroup controlId="email">
           <FormControl
             autoFocus
             placeholder="Email"
             value={this.state.email}
             onChange={this.handleChange}
           />
           <span className="inline-error-class"> {this.state.myerror !==''?this.state.myerror:''}</span>
         </FormGroup>
         <FormGroup controlId="password">
           <FormControl
             value={this.state.password}
             onChange={this.handleChange}
             type="password"
             placeholder="Password"
             className="required"
           />
           <span className="inline-error-class"> {this.state.myerror2 !==''?this.state.myerror2:''}</span>
         </FormGroup>
         <Button
           block
           disabled={!this.validateForm()}
           type="submit"
         >
           Sign In
         </Button>
       </form>
       <p className="text-center or-txt">Or Sign in with</p>
                                    <div className="form-group"> 
                                   
                                   
                                    </div>
                                    <div className="form-group"> 
                                  <GoogleLogin clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                      render={renderProps => (
                                    <Link to="#" className="btn google" onClick={renderProps.onClick}>Sign in with your Google account</Link>
                                                                  
                                      )}
                                        buttonText="Login"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        /> 
                                        
                                            
                                                                       
                                    </div>
                                    <p className="text-right small"><Link to="/agent/forgot" >Forgot Password?</Link></p>

                                   <div className="need-ac">
                                    Need an account? <Link to="/agent/signup/">Sign Up</Link>
                                   </div>
                                </div>                               
                            </div>
                        </main>
                </div>
                );
    }
}
export default UserLogin;
