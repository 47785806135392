import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/FrontHeader';
import { postApiData} from '../../services/Api';
import {validatePhone} from '../../services/validation';

class Join extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef() ;
        this.state = {
            shown:true,
      
      
      }
    
    }
  


  
   


   

   


    render() {

      
        return (
   
          
            <div className="w-bg FrontEnd " style={{height:'100vh'}}>
             <Header/> 
             <div className="container workbody ourteam-section curtain">
                   
                <h3>MOVE SKYWARD PRIVACY POLICY</h3>
                <h4>PLEASE READ AND REVIEW THE FOLLOWING PRIVACY POLICY CAREFULLY BEFORE USING THIS WEBSITE.</h4>
                <p>Move Skyward respects your privacy, and we are committed to protecting your privacy.<br /> If you visit our Website (the &ldquo;Site&rdquo;) and/or choose to create an account with us, we have designed our system to protect your privacy. Our system will be able to ascertain your geographic location from your IP address, but we do not collect e-mail addresses or track the sites that you access while you use our services unless</p>
<p>(i) we are required to do so by law;</p>
<p>(ii) we have reason to believe that you have violated the terms and conditions of the Move Skyward Terms of Use;</p>
<p>(iii) you have caused or attempted to cause harm to us or the other users of our Site; and/or</p>
<p>(iv) you voluntarily provide us with personal information (as described below).</p>
<p>Two types of information may be collected when you visit our Site:</p>
<p><strong>Personal information</strong></p>
<p>(such as name, address, telephone number, e‑mail address, credit card numbers, or credit information) which is only collected by us if you voluntarily submit it to us. For example, if you voluntarily create an account with us on our Site or you place an order with us, we will receive the account information that you submit so that we may better ensure that you are receiving the information, account access or products that you requested; and</p>
<p><strong>Aggregate information</strong></p>
<p>automatically collected non-identifying statistics, (such as information about how many visitors log on to the Site). Our servers will log IP addresses and collect third‑party sites (and information from these sites) from your use of the Site. We use this information to measure the use of our Site, to improve the content of our Site, and to provide our users with the best possible services.</p>
<p>In sum, you can visit our Site without telling us who you are or revealing personal information about yourself. However, you can, if you choose, participate in additional secured applications. For access to these applications, you are required to create an account with us. This form includes information such as name and e-mail address. We use this information to enhance our services, to better meet the needs and preferences of our customers, to fulfill orders, to allow you to access account information and to enhance the security of our Site. If you create an account with us, we may also from time to time, send you information about system changes or account information that we think may be of interest.</p>
<p>Move Skyward does not rent, sell, or share personal information about you with other people or non-affiliated companies except to provide services that you have requested, when we have your permission, or under the following circumstances:</p>
<p>We respond to subpoenas, court orders, or legal process or to establish or exercise our legal rights or defend against legal claims.<br /> We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Use, or as otherwise required by law.</p>
<p>We may transfer information about you if we are acquired by or merged with another organization. In this event, we will notify you before information about you is transferred and becomes subject to a different privacy policy.</p>
<p><strong>Cookies</strong></p>
<p>We may also collect cookies. Cookies are small files of information that are stored on your computer&rsquo;s hard drive by your Web browser. The cookies we use do not contain any personal information. Most Web browsers automatically accept cookies, but you can change the preferences of your browser so that it does not accept them. In order to use all of the features of the Site and/or access all of the services, we recommend that you accept cookies.</p>
<p><strong>Links to Other Sites</strong></p>
<p>We may provide links to third-party external sites. The inclusion of any link does not imply our endorsement of the external site company, its Website or Websites, or the products and services it promotes or sells. When you leave our Site to visit one of these linked sites, the only information transferred to the third party is the fact that you came from our Site.</p>
<p>Move Skyward is not responsible or liable for the independent privacy policies of these third-party Websites. You should consult the privacy policies at these Websites to determine how your information may be used.</p>
<p><strong>Data Security</strong></p>
<p>Move Skyward is committed to online data security. We take steps to make all information received from you online as secure as reasonably possible from unauthorized access and use. Move Skyward&rsquo;s secure server software uses industry-standard Secure Socket Layer (SSL) encryption technology. SSL encodes your personal information, including your account number, credit card number, name, and address, as it travels over the Internet.</p>
<p><br /> While we strive to protect all information we receive when you log on to our Site, we cannot guarantee the security of any information you transmit to us online, and you do so at your own risk. If you have reason to believe that your interaction with us is no longer secure or that the security of any account you have with us has been compromised, please immediately notify us by contacting us at the following e-mail address: privacy@moveskyward.com.</p>
<p><br /> Please note that unauthorized access of our Site is prohibited and punishable under the Computer Fraud and Abuse Act of 1986 and the National Information Infrastructure Protection Act.</p>
<p><strong>Children&rsquo;s Privacy</strong></p>
<p>We believe that protecting the privacy of children is especially important. It is our intention to never collect or maintain information from children at our Site unless the disclosure of such information is done with verifiable parental or guardian consent. If a visitor known to be a child (under the age of 13) sends an e-mail to us, we will only use it to respond to the e-mail writer and not to create profiles or otherwise retain the information.</p>
<p>Our Site and services are not directed or intended for individuals under 18 years of age.</p>
<p><strong>International Users</strong></p>
<p>This Site and all services are United States-based and are controlled, operated and administered by Move Skyward from its office in the United States of America.&nbsp; This Privacy Policy is provided in accordance with and subject to applicable United States law. &nbsp;If you decide to continue to access this Site and the online services from your location outside the United States, you hereby agree that your use of the Site and related services is subject to this Privacy Policy and your personal information will be transferred or processed in the United States.</p>
<p><strong>California Residents and California Privacy Rights</strong></p>
<p>If you are a California resident, California Civil Code Section 1798.83 permits you to request information about our practices related to the collection and storage of personal information.<br /> In addition, if you are a California resident, the CCPA grants you the right to request certain information about our practices with respect to California information. In particular, you can request the following:</p>
<ul>
<li>The categories and specific pieces of your California information that we have collected.</li>
<li>The categories of sources from which we collected California information.</li>
<li>The business or commercial purposes for which we collected California information.</li>
</ul>
<p>You can submit a request to us for the following additional information:</p>
<ul>
<li>The categories of California information that we have shared with service providers who provide services for us, like processing your bill.</li>
</ul>
<p>To exercise your CCPA rights with respect to this information, please send your request here: privacy@moveskyward.com. These requests for disclosure are generally free.</p>
<p><strong>YOUR RIGHT TO REQUEST THE DELETION OF CALIFORNIA INFORMATION</strong></p>
<p>Upon your request, we will delete the California information we have collected about you, except for situations when that information is necessary for us to: provide you with a product or service that you requested; perform a contract we entered into with you; maintain the functionality or security of our systems; comply with or exercise rights provided by the law; or use the information internally in ways that are compatible with the context in which you provided the information to us, or that are reasonably aligned with your expectations based on your relationship with us.</p>
<p>To exercise your right to request the deletion of your California information, please send your request here: privacy@moveskyward.com. These requests are generally free.</p>
<p><strong>Your Consent</strong></p>
<p>By using our Site, you consent to the collection and use of this information in the manner we describe.</p>
<p><strong>Modifications</strong></p>
<p>If we decide to change our Privacy Policy in whole or in part, we will post a notice on our Site. Those changes will go into effect on the date posted in the notice and will apply to all users of our Site.</p>
<p><strong>Contact Us</strong></p>
<p>Should you have any questions or concerns about this Privacy Policy, please contact us here:&nbsp;privacy@moveskyward.com</p>
<p><strong>The Effective Date of this Privacy Policy is August 1, 2021 (revised as of November 4, 2021)</strong></p>
              </div>
                  
           
                   
               
              <div className="Newskyward-design">
                <footer style={{top:'-12px'}}>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     
                     <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li>
                     <li className="nav-item"><a href="https://blog.moveskyward.com/" target="_blank" > Blog </a></li>  
                     <li className="nav-item"><Link to="/frontend/privacy-policy"> Privacy Policy</Link></li>
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
                     
                  
                    
                    </ul>   
                    <div className="clearfix new_logo">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img className='logo_1st' src="/home-icon.png"/></li>
                       </ul>
                       {/* <img src="/NYCFC_AP3_Web.jpg"/> */}
                       <div className="logo" style={{marginTop:-36}}></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
                   <p className='text_1' style={{marginTop:48}}>*Skyward Steel, LLC DBA Skyward and Skyward Real Estate is committed to adhering to the guidelines of The New York State Fair Housing Regulations. To view The Fair Housing Notice <a style={{textDecoration:'underline'}} href="https://dos.ny.gov/system/files/documents/2024/06/nys-housing-and-anti-discrimination-notice_06.2024.pdf" target="_blank"> Please click here</a>
<br></br>
*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. <a href="/Legal_SOP_NYSAR_032122.pdf" style={{textDecoration:'underline'}} target="_blank">To View Please Click Here.</a>


</p>     </div>
               </footer> 

                </div>
              


              
              
    </div>

                );
    }
}
export default Join;
