import React, { Component } from 'react';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken} from '../../services/Api';

class Dashboard extends Component {
    constructor(){
        super();
      this.state={
          routeFlag:true
      }
        
    }
    componentWillMount(){
       // this.setState({routeFlag:checkAdminToken()})
       this.setState({routeFlag:getAdminToken()})
    }
   
    render() {
       
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        return (
            <div>
                <AdminHeader />
                <AdminSideBar />
                
            </div>
        );
    }
}

export default Dashboard;