import React from 'react';
import { Link } from 'react-router-dom';
import ManagerHeader from '../common/ManagerHeader';
import ManagerSidebar from '../common/ManagerSidebar';
import AgentSidebar from '../common/AgentSideBar';
import AgentHeader from '../common/AgentHeader';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { setAgentToken, getAgentToken, getApiData , getAgentId,getBaseUrl} from '../../services/Api';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router';
import Moment from 'moment';
import DetailAudio from '../common/AudioPlayer';
import DetailVideo from '../common/VideoPlayer';
import DetailFile from '../common/MediaFile';

class Education extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
			videoList:[]
			,audioList:[]
			,fileList:[]
			,media_category:[]
			,media_topic:[]
			,media_topic_filter:''
			,media_category_filter:''
			,media_search:''
			,error_msg:''
			,showLoader:true
			,open: false
		  };

		}
	
	componentDidMount(){
		this.getVideoList();
		this.getAudioList();
		this.getFileList();
		this.getTopic();
		this.getCategory();

	}
	getTopic=()=>{
        let where=[{
            url:'/admin/getMediaTopic'
        }]
        getApiData(where ,true).then(res=>{
          console.log('---res.data',res.data);
          this.setState({
              media_topic:res.data
          })
        }).catch(error=>{

        })
    }

    getCategory=()=>{
        let where=[{
            url:'/admin/getMediaCategory'
        }]
        getApiData(where ,true).then(res=>{
          console.log('---res.data',res.data);
          this.setState({
            media_category:res.data
        })
        }).catch(error=>{

        })
	}
	
	getFileList=()=>{
		let where=[{
			url:'/fileList'
			,where:{
                'topic_id':this.state.media_topic_filter
				,'category_id':this.state.media_category_filter
				,'search_text':this.state.media_search
			}
        }]
        getApiData(where ,true).then(res=>{
          console.log('---fileList',res.data);
          this.setState({
			fileList:res.data
			,showLoader:false
        })
        }).catch(error=>{
			this.setState({
				error_msg:'Unable to load files'
			})
        })
	}

	getAudioList=()=>{
		let where=[{
			url:'/audioList'
			,where:{
                'topic_id':this.state.media_topic_filter
				,'category_id':this.state.media_category_filter
				,'search_text':this.state.media_search
			}
        }]
        getApiData(where ,true).then(res=>{
          console.log('---audioList',res.data);
          this.setState({
			audioList:res.data
			,showLoader:false
        })
        }).catch(error=>{
			this.setState({
				error_msg:'Unable to load audioes'
			})
        })
	}

	getVideoList=()=>{
		let where=[{
			url:'/videoList'
			,where:{
                'topic_id':this.state.media_topic_filter
				,'category_id':this.state.media_category_filter
				,'search_text':this.state.media_search
			}
        }]
        getApiData(where ,true).then(res=>{
          console.log('---videoList',res.data);
          this.setState({
			videoList:res.data
			,showLoader:false
        })
        }).catch(error=>{
			this.setState({
				error_msg:'Unable to load videos'
			})
        })
	}
	filterSearch=(event)=>{
		let enterKey=event.keyCode||event.which;
		console.log("enterKey",enterKey);
		this.setState({
			media_search:event.target.value
		},()=>{
			if(enterKey==13 || enterKey==16){
				this.setState({
					showLoader:true
					,videoList:[]
					,audioList:[]
					,fileList:[]
				})
				this.getVideoList();
				this.getAudioList();
				this.getFileList();
			}
			
		});
	}

	filter=(event)=>{
		this.setState({
			[event.target.id]:event.target.value
		},()=>{
			this.setState({
				showLoader:true
				,videoList:[]
				,audioList:[]
				,fileList:[]
			})
			this.getVideoList();
			this.getAudioList();
			this.getFileList();
		});
	}

	openAudio=param=>event=>{
		console.log("param",param)
		this.setState({
			file:param[0]
			,title:param[1]
			,description:param[2]
			,date_post:param[3]
		})
	}

	//open model
	onOpenModal=param=>event=>{
		
		
                this.setState({ file:param[0]
					,title:param[1]
					,description:param[2]
					,date_post:param[3]
					,open: true });
					
	};

	openVideo=param=>event=>{
		
		this.setState({ file:param[0]
			,title:param[1]
			,description:param[2]
			,date_post:param[3]
			,openVideo: true });
};
onCloseModalVideo = () => {
	this.setState({ openVideo: false });
};
onOpenModalFile=param=>event=>{
		
	this.setState({ file:param[0]
		,title:param[1]
		,description:param[2]
		,date_post:param[3]
		,openFile: true });
};
onCloseModalFile = () => {
	this.setState({ openFile: false });
};
	//close model
	onCloseModal = () => {
		this.setState({ open: false });
	};
    // client list

    render() {
		const { userType} = this.props;

		var media_topic;
        if(this.state.media_topic){
          media_topic = this.state.media_topic.map(item=>{
            return <option value={item.id}>{item.topic}</option>
          })
        }
        var media_category;
        if(this.state.media_category){
            media_category = this.state.media_category.map(item=>{
              return <option value={item.id}>{item.category}</option>
            })
		  }
		  let  videoList =(!this.state.showLoader && this.state.videoList.length==0)?<div><div className="edu-files">No record Found</div></div>:'';

			if(this.state.videoList.length>0){
				videoList=this.state.videoList.map((item,index)=>{
					return <div >
							<Link to data-toggle="modal" onClick={this.openVideo([item.video_file,item.title,item.description,item.posted_on,item.created_date])}>
							<div className="edu-video-img">
								<video  className="edu-video-img" style={{width:'100%'}}>
								<source src={getBaseUrl()+"/containers/media_video/download/"+item.video_file+"/true"} />
								Your browser does not support the video tag.
								</video>
							</div>
						<div className="edu-wrap">
						<h4>{item.title}</h4>
					<i>{item.description}</i>
							<p className="post-date"><span>Posted</span> {(item.posted_on!='0000' || item.posted_on!='null')?Moment(item.posted_on).format('MMMM, Y'):Moment(item.created_date).format('MMMM, Y')}</p>
					</div>
					</Link>
				</div>
				});
			}
		 


			let  audioList =(!this.state.showLoader && this.state.audioList.length==0)?<div><div className="edu-files-img">No record Found</div></div>:'';
			if(this.state.audioList.length>0){
		audioList=this.state.audioList.map((item,index)=>{
			return <div>
				<Link to data-toggle="modal" onClick={this.onOpenModal([item.audio_file,item.title,item.description,item.posted_on,item.created_date])}>
			<div className="edu-audio-img">
				<img src="/audio-bar.png" width="100%"/>
				{/*</div>audio className="edu-audio-img" controls>
				<source src={getBaseUrl()+"/containers/media_audio/download/"+item.audio_file+"/true"}/>
				Your browser does not support the audio tag.
		</audio>*/}
			</div>
			<div className="edu-wrap">
			<h4>{item.title}</h4>
					<i>{item.description}</i>
					<p className="post-date"><span>Posted</span> {(item.posted_on!='0000' || item.posted_on!='null')?Moment(item.posted_on).format('MMMM, Y'):Moment(item.created_date).format('MMMM, Y')}</p>
			</div>
			</Link>
		</div>
		});
	}

	let  fileList =(!this.state.showLoader && this.state.fileList.length==0)?<div><div className="edu-files-img" style={{margin:'0px'}}>No record Found</div></div>:'';
	if(this.state.fileList.length>0){
		 fileList=this.state.fileList.map((item,index)=>{
			return <div>
				<Link to data-toggle="modal" onClick={this.onOpenModalFile([item.media_file,item.title,item.description,item.posted_on,item.created_date])}>
			<div className="edu-files-img">
				<a href={getBaseUrl()+"/containers/media_file/download/"+item.media_file} target="_blank"><img src="/pdf-img.png"/></a>
			</div>
			<div className="edu-wrap text-center">
					<h4>{item.title}</h4>
					<i>{item.description}</i>
					<p className="post-date"><span>Posted</span> {(item.posted_on!='0000' || item.posted_on!='null')?Moment(item.posted_on).format('MMMM, Y'):Moment(item.created_date).format('MMMM, Y')}</p>
			</div>
			</Link>
			</div>
		});
	}


		



		var settings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			initialSlide: 0,
			responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 3,
				  slidesToScroll: 3,
				  infinite: true,
				  dots: true
				}
			  },
			  {
				breakpoint: 600,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 2,
				  initialSlide: 2
				}
			  },
			  {
				breakpoint: 480,
				settings: {
				  slidesToShow: 1,
				  slidesToScroll: 1
				}
			  }
			]
		  };
        return (
                <div>
                   {userType == 'Manager' ? <ManagerHeader /> : <AgentHeader />} 
                    <main>
					{userType == 'Manager' ? <ManagerSidebar /> : <AgentSidebar />}
					<div className="content">
						<div className="work-section">
						<div className="dashboard">

				<div className="education-section">
				<div className="container">	
					{(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''} 

{(this.state.error_msg!='')?<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>:''}
						<div className="edu-top">						
							<div className="row"> 							
								<div className="col-sm-4">															
									<select className="form-control edu-select " id="media_category_filter" onChange={this.filter}>
									<option value="">Category</option>
										{media_category}
									</select>
								</div>
								<div className="col-sm-4">															
									<select className="form-control edu-select" id="media_topic_filter" onChange={this.filter}>
										<option value="">Topics</option>
										{media_topic}
									</select>
								</div>
								<div className="col-sm-4">
									<input className="form-control" id="media_search" type="text" onKeyUp={this.filterSearch} placeholder="Search"/>	
								</div>								
								</div>																									
							</div>
					</div>
					<div className="container-fluid">	
				


							<div className="modal" id="myVideo">
									<div className="modal-dialog modal-lg">
									<div className="modal-content" style={{minWidth:'100%'}}>
									
									
										<div className="modal-header">
										<h4 className="modal-title">Video</h4>
										<button type="button" className="close" data-dismiss="modal">&times;</button>
										</div>
										
									


									<Modal open={this.state.open} onClose={this.onCloseModal} little>
									<DetailAudio listData={[this.state.file,this.state.title,this.state.description,this.state.date_post]} onClick={this.onCloseModal}/>
									</Modal>


									<Modal open={this.state.openVideo} onClose={this.onCloseModalVideo} little>
									<DetailVideo listData={[this.state.file,this.state.title,this.state.description,this.state.date_post]} onClick={this.onCloseModalVideo}/>
									</Modal>


									<Modal open={this.state.openFile} onClose={this.onCloseModalFile} little>
									<DetailFile listData={[this.state.file,this.state.title,this.state.description,this.state.date_post]} onClick={this.onCloseModalFile}/>
									</Modal>

									


										<div className="modal-body">
										<div className="edu-video-img">
											{
												console.log("this.state.file",this.state.file)
											}
										<video className="edu-video-img" controls width="100%">
											<source src="https://www.moveskyward.com/containers/media_audio/download/mov_bbb.mp4" />
											Your browser does not support the video tag.
										</video>
										</div>
										<div className="edu-wrap">
												<i>{(this.state.title)?this.state.title:''}</i>
												<h4>{(this.state.description)?this.state.description:''}</h4>
												<p className="post-date"><span>Posted</span>{Moment(this.state.date_post).format('MMMM, Y')}</p>
											</div>
										</div>
										
								
										
									</div>
									</div>
								</div>


								<div className="modal" id="myAudio">
									<div className="modal-dialog">
									<div className="modal-content" style={{minWidth:'100%'}}>
									
									
										<div className="modal-header">
										<h4 className="modal-title">Audio</h4>
										<button type="button" className="close" data-dismiss="modal">&times;</button>
										</div>
										
									
										<div className="modal-body">
										<div className="edu-video-img" style={{textAlign:'center'}}>
											<audio className="edu-audio-img" controls>
												<source src={(this.state.file)?getBaseUrl()+"/containers/media_audio/download/"+this.state.file+"/true":''}/>											
											</audio>
										</div>
											<div className="edu-wrap">
												<i>{(this.state.title)?this.state.title:''}</i>
												<h4>{(this.state.description)?this.state.description:''}</h4>
												<p className="post-date"><span>Posted</span>{Moment(this.state.date_post).format('MMMM, Y')}</p>
											</div>
										</div>
										
								
										
									</div>
									</div>
								</div>

				<div className="edu-video education-sliders">
					<h3>Video</h3>
					<Slider {...settings}>
					{videoList}
		</Slider>							
						
				</div>

				<div className="edu-audio education-sliders">
					<h3>Audio</h3>
					<Slider {...settings}>
					{audioList}
					</Slider>							
						</div>


				<div className="edu-files education-sliders">
					<h3>Files</h3>	
				<Slider {...settings}>
					{fileList}
					</Slider>						

						</div>

						</div>
					</div>																
				</div>

			</div>
		</div>			             				                            
		</main>     
     </div>

     );
    }
}
export default Education;