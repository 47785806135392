import React,  { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken,postApiData, getApiData} from '../../services/Api';

class AirConditionType extends Component {
    constructor(){
        super();
        this.state={
            routeflag:true,
            domainList:[],
            //Form Fields States
            name:'',
            type:0,
            successQuote:false,
            AddAddressPanel: false,

            //Error
            office_name_error:'',
           
            isMarkerShown: false,
            position: '',
            lat:"",
            lng:"",
            deleteMsg:'',
            domain_error:''
        }
    }

    componentWillMount =()=>{
        window.scroll(0,0);
        this.getDomainList();
    }

   

    
   

    //Handle Insertion of Address information in DB
    handleSubmit=(e)=>{
   
        const {name,type} = this.state;
        e.preventDefault();
        
        this.addDomainDetail(name,type)
    }
    async addDomainDetail(name,type) {
        let where = [{
                url: '/admin/addDomain/'
            }];
            postApiData(where, {"domain": name,'type':type},true).then(res => {
                if(res.data==202){
                    this.setState({ office_name_error: 'Field cannot be empty'});
                }
                else if(res.data==203)
                {
                    this.setState({ office_name_error: this.state.name+' is already exist, please try any other value.'});
                }
                else{
                        this.setState({ 'pageLoader':true},()=>{
                            this.setState({'pageLoader':false, "successQuote": true, name:''});
                            setTimeout(
                                function() {
                                    this.setState({'successQuote':false});
                                }
                                .bind(this),
                                2000
                            );
                        }) 
                    }
                         
        }).catch(error => {
           this.setState({myerrormsg: error.response});
        });
    }

   

    backToAddressPanel=()=>{
        this.getDomainList()
        this.setState({
            pageLoader: true
        },()=>{
            this.setState({  pageLoader: false,  AddAddressPanel: !this.state.AddAddressPanel, successQuote :false,  })
        })
    }

    //Get Address list from DB
    getDomainList=()=> {
        let applicationList = [{
            url: "/admin/getDomainList",
        }];
        getApiData(applicationList,true).then(res => {
           
            this.setState({'domainList': res.data,'pageLoader':true},()=>{
                    this.setState({'pageLoader':false});
            })
            }).catch(error => {
            if(error.response){
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });
    }

  
         
      validateForm() {
        return ( this.state.name.trim().length != '' && this.state.name.trim()!='' && this.state.office_name_error.trim()=='' ) 
    }

     emailDomainDelete =(id)=>{
        alert('Are you sure want to delete?')
        this.setState({'pageLoader':true},()=>{
            
            let where = [{
                url: "/admin/emaildomain/delete",
            }];
            postApiData(where,{'id':id},true).then(res => {
                this.setState({'deleteMsg':res.data.message},()=>{
                    this.getDomainList();
                })

                setTimeout(
                    function() {
                        this.setState({'deleteMsg':''});
                    }
                    .bind(this),
                    2000
                );
            }).catch(error => {
                    if(error.res){
                        this.setState({myerrormsg: error.res.data});
                    }
                });
            });
     }

     handleChange=(e)=>{
        const {name } = this.state;
        switch(e.target.id){
            case 'name':
                console.log(e.target.value.length);
                if(e.target.value.length >=0){
                    this.setState({name:e.target.value})
                    if(this.state.type==1){
                        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) { 
                            this.setState({office_name_error:''});
                        }
                        else{
                            this.setState({office_name_error:'Please enter valid email address'});
                        }
                      }
                      if(this.state.type==0){
                        if (/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(e.target.value)) { 

                            this.setState({office_name_error:''});
                        }
                        else{
                            this.setState({office_name_error:'Please enter valid domain name'});
                        }
                      } 
                }else{ 
                    
                    this.setState({ office_name_error: 'Field cannot be empty'})
             }
            break;
            case 'domianType':
                this.setState({type:e.target.value},()=>{
                 
                });
               
                break;
        }
        
    }
    

    render() {
        const { name, address_line_1, address_line_2, mob_number, timings, AddAddressPanel} = this.state;
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }

         var listItems = this.state.domainList.map((item, index)=>{
            return <tr  >
                    <td id={item.id}>{item.emaildomain}</td>
                    <td>{item.type==0?'Email Domain':'individual Email'}</td>
                    <td className="action-grid">
                        <div className="pull-right"><button className="fa fa-trash" onClick={()=>this.emailDomainDelete(item.id)}></button> </div>
                        </td>
                </tr>
        });

        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">Verified Email/Domains</h2>
               
                        <button onClick={this.backToAddressPanel} className="btn btn-default pull-right ">{!AddAddressPanel ? "Add Email/Domain" : "Back"}</button>
                      
                        {(this.state.deleteMsg) ?(<div className="alert" style={{marginLeft:'200px', marginRight:'300px',color:'red'}}><strong>{this.state.deleteMsg}</strong></div>):(null)}  <div className="panel-body table-responsive">
                        {!AddAddressPanel ? 
                            <table className="table table-striped address-table">
                            
                                <thead>
                                    <tr>
                                        <th className="managerGridCol">Emails/Domains</th>
                                        <th>Type</th>
                                        <th className="managerGridCol text-right">Action</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {listItems}
                                </tbody>
                            </table>
                        
                        :
                        <Fragment>
                        <table className="table table-striped">
                            <thead><tr><th className="managerGridCol">Add Email/Domain</th></tr></thead>
                        </table>
                                
                                <form onSubmit={this.handleSubmit} style={{maxWidth:"70%", margin:"10px auto"}}>
                                {this.state.successQuote?(<div className="alert alert-success"><strong>Email/Domain Successfully Added</strong></div>):(null)} 
                                 
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label forhtml="name">Type </label>
                                            <select className="form-control" id="domianType" onChange={this.handleChange} style={{borderBottom: '2px solid', width: '200px'}}><option value="0">Email Domain</option><option value="1">individual Email</option></select>
                                            
                                        </div>
                                          <div className="form-group col-md-6">
                                            
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label forhtml="name">Email/Domain Name</label>
                                            <input type="text" className="form-control" id="name" onChange={this.handleChange} value={this.state.name} placeholder="Type Email/Domain" autoComplete="off"/>
                                            <span style={{fontSize:'11px'}}>Note: Domain should not contain http:// or https:// e.g. xyz.com</span>
                                            <br /><span className="inline-error-class"> {this.state.office_name_error !==''?this.state.office_name_error:''}</span>
                                        </div>
                                        </div>

                               
                                        <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={!this.validateForm()}>Submit</button>
                                     </div>
                                       
                             
                                    </form>
                        </Fragment>
                        }
                            </div>
                    </div>
                 
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default AirConditionType;