import React, { Component } from 'react';
import ManagerListingFeed from '../../agent/listing/ListingFeed';

class AgentFeed extends Component {
    render() {
        return (
            <div>
                <ManagerListingFeed  userType='Manager' />
            </div>
        );
    }
}

export default AgentFeed;