import React from 'react';
import { Link } from 'react-router-dom';



class Verified extends React.Component {
    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}

class Content extends React.Component {
   
    render() {
        return (
            <div>
            <div className='container'>
            <div className='landing-section'>
             <img src="/logo.png"  alt="logo"/>
              <p>Account Verified</p>
               <Link to="/client/login" className='btn btn-default'>Log In</Link>
               </div>
        </div>
    </div>

                );
    }
}
export default Verified;
