import React,  { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken,postApiData, getApiData} from '../../services/Api';

class OwnerPayBroker extends Component {
    constructor(){
        super();
        this.state={
            routeflag:true,
            ownerpayList:[],
            //Form Fields States
            name:'',
            successQuote:false,
            AddAddressPanel: false,

            //Error
            office_name_error:'',
      
            isMarkerShown: false,
            deleteMsg:''
        }
    }

    componentWillMount =()=>{
        window.scroll(0,0);
        this.getownerpaybrokerList();
    }

   

    
   

    //Handle Insertion of Address information in DB
    handleownerpaySubmit=(e)=>{
     

        const {name} = this.state;
        e.preventDefault();
        this.addownerpaybrokerDetail(name)
    }
    async addownerpaybrokerDetail(name) {
        let where = [{
                url: '/admin/Addownerpaybroker/'
            }];
            postApiData(where, {"name": name},true).then(res => {
                if(res.data==202){
                    this.setState({ office_name_error: 'Field cannot be empty'});
                }
                else if(res.data==203)
                {
                    this.setState({ office_name_error: this.state.name+' is already exist, please try any other value.'});
                }
                else{
                        this.setState({ 'pageLoader':true},()=>{
                            this.setState({'pageLoader':false, "successQuote": true, name:''});
                        }) 
                        setTimeout(
                            function() {
                                this.setState({'successQuote':false});
                            }
                            .bind(this),
                            2000
                        );  
                }
        }).catch(error => {
           this.setState({myerrormsg: error.response});
        });
    }

   

    backToAddressPanel=()=>{
        this.getownerpaybrokerList()
        this.setState({
            pageLoader: true
        },()=>{
            this.setState({  pageLoader: false,  AddAddressPanel: !this.state.AddAddressPanel, successQuote :false,  })
        })
    }

    //Get Address list from DB
    getownerpaybrokerList=()=> {
        let applicationList = [{
            url: "/admin/ownerpaybrokerList",
        }];
        getApiData(applicationList,true).then(res => {
           
            this.setState({'ownerpayList': res.data,'pageLoader':true},()=>{
                    this.setState({'pageLoader':false});
            })
            }).catch(error => {
            if(error.response){
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });
    }

  
         
      validateForm() {
        return ( this.state.name.trim().length != '' && this.state.name.trim()!='') 
    }

     ownerpaybrokerDelete =(id)=>{
        alert('Are you sure want to delete?')
        this.setState({'pageLoader':true},()=>{
            
            let where = [{
                url: "/admin/ownerpaybroker/delete",
            }];
            postApiData(where,{'id':id},true).then(res => {
                this.setState({'deleteMsg':res.data.message},()=>{
                    this.getownerpaybrokerList();
                })
                setTimeout(
                    function() {
                        this.setState({'deleteMsg':''});
                    }
                    .bind(this),
                    2000
                ); 
            }).catch(error => {
                    if(error.res){
                        this.setState({myerrormsg: error.res.data});
                    }
                });
            });
     }

     handleChange=(e)=>{
        const {name } = this.state;
        switch(e.target.id){
            case 'name':
                if(e.target.value == ''){ this.setState({ office_name_error: 'Field cannot be empty'})  
                }else{ this.setState({ office_name_error: ''}) }
            break;
           
        }
        this.setState({ [e.target.id] : e.target.value}, ()=>{
           
        })
    }
     

    render() {
        const { name, AddAddressPanel} = this.state;
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }

         var listItems = this.state.ownerpayList.map((item, index)=>{
            return <tr  >
                    <td id={item.id}>{item.owner_pays_broker}</td>
                    <td></td>
                    <td className="action-grid">
                        <div className="pull-right"><button className="fa fa-trash" onClick={()=>this.ownerpaybrokerDelete(item.id)}></button> </div>
                        <div className="pull-right"><Link className="fa fa-edit" to={`/admin/ownerpaybrokeredit/${item.id}`}></Link> </div>
                        </td>
                   
                </tr>
        });

        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">Owner Pay Broker</h2>
               
                        <button onClick={this.backToAddressPanel} className="btn btn-default pull-right ">{!AddAddressPanel ? "Add Owner Pay Broker" : "Back"}</button>
                      
                        {(this.state.deleteMsg) ?(<div className="alert alert-danger" style={{marginLeft:'200px', marginRight:'300px'}}><strong>{this.state.deleteMsg}</strong></div>):(null)}  <div className="panel-body table-responsive">
                        {!AddAddressPanel ? 
                            <table className="table table-striped address-table">
                            
                                <thead>
                                    <tr>
                                        <th className="managerGridCol">Type</th>
                                        <th></th>
                                        <th className="managerGridCol text-right">Action</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {listItems}
                                </tbody>
                            </table>
                        
                        :
                        <Fragment>
                        <table className="table table-striped">
                            <thead><tr><th className="managerGridCol">Add Owner Pay Broker</th></tr></thead>
                        </table>
                                
                                <form onSubmit={this.handleownerpaySubmit} style={{maxWidth:"70%", margin:"10px auto"}}>
                                {this.state.successQuote?(<div className="alert alert-success"><strong>Owner Pay Broker Successfully Added</strong></div>):(null)} 
                                 
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label forhtml="name">Type Name</label>
                                            <input type="text" className="form-control" id="name" onChange={this.handleChange} value={name} placeholder="Type Name" autoComplete="off"/>
                                            <span className="inline-error-class"> {this.state.office_name_error !==''?this.state.office_name_error:''}</span>
                                        </div>
                                      
                                        </div>

                               
                                        <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={!this.validateForm()}>Submit</button>
                                     </div>
                                       
                             
                                    </form>
                        </Fragment>
                        }
                            </div>
                    </div>
                 
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default OwnerPayBroker;