import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/ManagerHeader';
import Sidebar from '../common/ManagerSidebar';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";
import {getApiData,getBaseUrl,getManagerToken} from '../../services/Api';
import Modal from 'react-responsive-modal';
import AddFiles from '../common/AddFiles';
import EditFiles from '../common/EditFiles';
import ViewFiles from '../common/View';

import Moment from 'moment';

class ManagerDocument extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error_msg:'',
			showLoader:true,
			folder_list:[],
			success_msg:'',
			id:this.props.match.params.id,
			folder_name:'',
			open: false,
			editOpen:false,
			listingData:{},
			openViewer:false,
			editable:(!getManagerToken())?false:true
		};
}

componentDidMount(){
	this.getFileList();
	this.getFolderList();
}

getFolderList=()=>{
	let where=[{
		url:'/getFolderList',
		where:{
			'id':this.state.id
		}
	}]
	getApiData(where ,true).then(res=>{
	  this.setState({
		  folder_name:res.data[0].name,
		  error_msg:'',
		  showLoader:false,
	  })
	}).catch(error=>{
		this.setState({
			showLoader:false,
			error_msg:'Unable to load files'
		})
	})
}

getFileList=()=>{
	let where=[{
		url:'/getAllList',
		where:{
			'folder_id':this.state.id
		}
	}]
	getApiData(where ,true).then(res=>{
	  this.setState({
		  folder_list:res.data,
		  error_msg:'',
		  showLoader:false,
	  })
	}).catch(error=>{
		this.setState({
			showLoader:false,
			error_msg:'Unable to load files'
		})
	})
}

deleteFolder=param=>event=>{
	
	let where=[{
		url:'/removeFile',
		where:{
			'id':param[0]
		}
	}]
	getApiData(where ,true).then(res=>{
	  this.setState({
		success_msg:'Record deleted'
	  })
	  this.getFileList();
	}).catch(error=>{
		this.setState({
			showLoader:false,
			error_msg:'Unable to load files',
			success_msg:''
		})
	})

}

goBack=()=>{

	window.history.back();
	};
onOpenModal = () => {
	this.setState({ open: true });
  };
 
  onCloseModal = () => {
	this.setState({ open: false },()=>{
		this.getFileList();
	});
  };


  onOpenView = param=>(e) =>  {
	this.setState({ listingData:param[0], openViewer: true });
  };
 
  onCloseView = () => {
	this.setState({ listingData:{},openViewer: false },()=>{
		
	});
  };


  

  onOpenEdit = param=>(e) => {
	this.setState({ 
		listingData:param[0],
		editOpen: true });
  };
 
  onCloseEdit = () => {
	this.setState({ editOpen: false,listingData:{}},()=>{
		this.getFileList();
	});
  };

  onOpenDOcView=param=>(e)=>{
	this.setState({
		showLoader:true})
	let where=[{
		url:'/containers/rental_doc/doc_download/'+param[0],
		
	}]
	getApiData(where ,true).then(res=>{
		
	  if(res){
		window.open(res.data,'_blank');
		this.setState({
			showLoader:false})
	  }
	}).catch(error=>{
		this.setState({
			showLoader:false,
			error_msg:'Unable to load files'
		})
	})
  }

  onOpenxlsView=param=>(e)=>{
	this.setState({
		showLoader:true})
	let where=[{
		url:'/containers/rental_doc/xls_download/'+param[0],
		
	}]
	getApiData(where ,true).then(res=>{
		
	  if(res){
		window.open(res.data,'_blank');
		this.setState({
			showLoader:false})
	  }
	}).catch(error=>{
		this.setState({
			showLoader:false,
			error_msg:'Unable to load files'
		})
	})
  }

render() {
	const { open } = this.state;
	let  folder_list =(!this.state.showLoader && this.state.folder_list.length==0)?<tr>
	<td colSpan="4">No record Found
	</td>
</tr>:'';
	if(this.state.folder_list.length>0){
		folder_list=this.state.folder_list.map((item,index)=>{
			let ext = item.file_path.substring(item.file_path.lastIndexOf('.')).toLowerCase();
			return <tr>
			<td>{index+1}</td>
			<td>{item.file_name}</td>
			<td>{Moment(item.created_date,"YYYY-MM-DD").format('MM/DD/YYYY')}</td>
			<td className="action-view text-center">
			{([".jpg", ".JPG", ".jpeg", ".png",'.pdf'].includes(ext))?
			<Link to onClick={this.onOpenView([item])}><i class="fa fa-eye"></i></Link>:([".docx", ".doc"].includes(ext))?<Link to  onClick={this.onOpenDOcView([item.file_path])}><i class="fa fa-eye"></i></Link>:([".xlsx", ".xls"].includes(ext))?<Link to  onClick={this.onOpenxlsView([item.file_path])}><i class="fa fa-eye"></i></Link>:<a href={getBaseUrl()+"/containers/rental_doc/download/"+item.file_path+"/true"} target="_blank"><i class="fa fa-eye"></i></a>}
				<a href={getBaseUrl()+"/containers/rental_doc/download/"+item.file_path+"/true"} target="_blank"><i class="fa fa-download"></i></a>
				<Link to onClick={this.deleteFolder([item.id])}><i class="fa fa-trash-o"></i></Link>
				{(this.state.editable)?<Link to onClick={this.onOpenEdit([item])}><i class="fa fa-pencil-square-o"></i></Link>:''}
			</td>
		</tr>
		});
	}


    return (
                <div>
                    <Header />
                    <main>
					<Sidebar />
					<div className="content">
						<div className="work-section">
						<div className="dashboard">
								<h2>Manager/Office Documents/<strong>{this.state.folder_name}</strong></h2>
								{(this.state.error_msg!='')?<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>:''}
								{(this.state.success_msg !== '') ?<div className="alert alert-success"> {this.state.success_msg !==''?this.state.success_msg:''} </div>:''}
								{(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''} 
								<div className="mb-2 float-right" >
								{(this.state.editable)?<button className="btn btn-default"  type="button"  data-toggle="modal" onClick={this.onOpenModal}>+ Add Document(s)</button>:''}
								<button onClick={this.goBack} className="btn btn-default pull-right" style={{fontSize:'14px',marginLeft:'5px'}}>Back</button></div>

								<div className="panel-body table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
												<th width="60">#</th>
												<th>Document Name</th>
												<th>Date Uploaded</th>
												<th className="text-center">Action</th>
											</tr>
										</thead>
										<tbody>
											{folder_list}
										</tbody>
									</table>
								</div>	

				<Modal open={open} onClose={this.onCloseModal} classNames="modal-sm">
				<AddFiles onClose={this.onCloseModal} folder_id={this.state.id}/>
				</Modal>

				<Modal open={this.state.editOpen} onCloseEdit={this.onCloseEdit} classNames="modal-sm">
				<EditFiles listingData={this.state.listingData} onClose={this.onCloseEdit}/>
				</Modal>

				<Modal open={this.state.openViewer} onCloseEdit={this.onCloseView} classNames="modal-sm">
				<ViewFiles listingData={this.state.listingData} onClose={this.onCloseView}/>
				</Modal>


								{/*-- Start Modal */}
									<div className="modal docModal" id="myDoc">
										<div className="modal-dialog modal-sm">
											<div className="modal-content">

											
											<div className="modal-header">
												<h4 className="modal-title">Add Document</h4>
												<button type="button" className="close" data-dismiss="modal">&times;</button>
											</div>

										
											<div className="modal-body">
												<div className="form-group">
												
													<input type="text" className="form-control" placeholder="Document Name"/>
												</div>
												<div className="form-group">
												
													<input type="text" className="form-control" placeholder="Date"/>
												</div>
												<div className="upload-btn-wrapper upload-doc mb-2 float-right" >
													<button className="btn btn-default" id="uploadBtn" type="button">+ Add Document</button>
													<FormGroup >
														<FormControl type="file"  />
													</FormGroup>
												</div>
												<button type="button" className="btn btn-default" >Submit</button>
											</div>
										</div>
									</div>
									</div>
								{/*-- End Modal */}
								


							

							
							</div>
						</div>
					</div>
                    </main>     
                </div>

                );
    }
}
export default ManagerDocument;
