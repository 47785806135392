import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/AgentHeader';
import SideBar from '../common/AgentSideBar';
import Moment from 'moment';
import { Redirect } from 'react-router';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addDays from "date-fns/add_days";
import set_hours from "date-fns/set_hours";
import set_minutes from "date-fns/set_minutes";
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import { validatePhone, socialSecurityNumber } from '../../services/validation';
import { setAgentToken, getAgentToken, getApiData, getAgentId, postApiData } from '../../services/Api';
import { clientShowingStatus } from '../../services/Const';
import Modal from "react-responsive-modal";
import CreateShowingPopUp from './CreateShowingPopUp';
import AgentChatbox from './Chatbox';

class Agentshowings extends React.Component {

    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}
class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowingExist: '',
            showingData: [],
            isAvailShowingExist: '',
            availShowingData: [],
            clientList: [],
            clientSearchList: [],
            clientphone: [],
            addressData: [],
            addressData_mylead: [],
            addressData_availead: [],
            client_id: 0,
            searchParm: '',
            searchParm_mylead: '',
            searchParm_availead: '',
            searchParm_client: '',
            searchAddressvalue: '',
            account_verify: '',
            resendMsg: '',
            showingpopupid: '',
            completed_date: '',
            selected_client_name: 'JOSIAH HYATT', //default agent name,
            randomFileName: '',
            agent_name: '',
            agentList: [],
            client_fill_name: '',
            agent_id: 0,
            agent_name: '',
            selected_agent_name: 'JOSIAH HYATT',
            time: '',
            startDate: new Date(),
            agentId: '',
            address_value: '',
            unit_value: '',
            clientShowing: '',
            timeLeft: '',
            s_showingDate: '',
            messagePopup: '',
            s_showingTime: '',
            s_rescheduleStatus: '',
            s_clientEmail: '',
            s_showingDate_email: '',
            s_showingTime_email: '',
            s_showingAddress: '',
            s_showing_unit: '',
            s_clientName: '',
            split: '',
            open: false,
            open_mylead: false,
            open_availead: false,
            listingCreator: [],
            client_name: '',
            client_phone: '',
            listing_name: '',
            unit_name: '',
            s_agentName: '',
            mymsg: '',
            myerrormsg: '',
            unitListingCreator: [],
            phone_number: '',
            custom_client_email: '',
            upperSearchWhere: {},
            mylead_SearchWhere: {},
            availead_SearchWhere: {},
            upperSearchDate: '',
            mylead_SearchDate: '',
            availead_SearchDate: '',
            upperSearchTime: '',
            myleadSearchTime: '',
            availeadSearchTime: '',
            isLeadExist: '',
            isChecked: false,
            checkSplit: true,
            client_fill_email: '',
            currentTime: new Date().getHours() + 1,
            googleEventId:''

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.confirmAccept = this.confirmAccept.bind(this);
        this.confirmShowing = this.confirmShowing.bind(this);
        this.createUpperWhere = this.createUpperWhere.bind(this);
        this.createmyleadWhere = this.createmyleadWhere.bind(this);
        this.createavaileadWhere = this.createavaileadWhere.bind(this);
        this.handleUpperSearchDate = this.handleUpperSearchDate.bind(this);
        this.handlemyleadSearchDate = this.handlemyleadSearchDate.bind(this);
        this.handleUpperSearchTime = this.handleUpperSearchTime.bind(this);
        this.handlemyleadSearchTime = this.handlemyleadSearchTime.bind(this);
        this.handleavaileadSearchDate = this.handleavaileadSearchDate.bind(this);
        this.handleavaileadSearchTime = this.handleavaileadSearchTime.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef1 = this.setWrapperRef1.bind(this);
        this.setWrapperRef2 = this.setWrapperRef2.bind(this);
        this.setWrapperRef3 = this.setWrapperRef3.bind(this);
        this.setWrapperRef4 = this.setWrapperRef4.bind(this);
        this.setWrapperRef5 = this.setWrapperRef5.bind(this);
        this.setWrapperRef6 = this.setWrapperRef6.bind(this);
        this.setWrapperRef7 = this.setWrapperRef7.bind(this);
        this.setWrapperRef8 = this.setWrapperRef8.bind(this);
        this.setWrapperRef9 = this.setWrapperRef9.bind(this);
        this.setWrapperRef10 = this.setWrapperRef10.bind(this);
    }

    ClientHeadClick() {
        document.getElementById('search').classList.remove("show");
        document.getElementById('search_mylead').classList.remove("show");
        document.getElementById('search_availead').classList.remove("show");
        document.getElementById('cnsearch').classList.remove("show");
    }

    getCurrentDate(format) {

        var createdTime = Moment(new Date(), ['HH:mm:ss']).format('HH:mm:ss');
        var createdDate = Moment(new Date(), ['YYYY-MM-DD']).format('YYYY-MM-DD');
        if (format == 'dateTime') {
            return createdDate = createdDate + ' ' + createdTime;
        } else {
            return createdDate;
        }

    }



    componentDidMount() {
        window.scroll(0, 0);
        document.addEventListener('mousedown', this.handleClickOutside);
        this.setState({
            linkingLoader: true,
        }, () => {
            //console.log(Moment(new Date()).add(5, 'days'));
            //setTimeout(this.getShowingListContinous(), 60000);
            this.pageLoad();
        });
    }

    pageLoad() {
        Promise.all([
            , this.getAvailableShowing()
            , this.clientOpt()
            , this.getLeadList()
            , this.agentOpt()
            , this.getAvailable_AddressData()
            , this.getmyLead_AddressData()
            , this.getConfirmed_AddressData()
            , this.getagentshowingstatus()
            , this.getShowingList()
        ]).then(result => {
            console.log("result", result);
            this.setState({
                linkingLoader: false
            })
        })
            .catch((error) => {
                console.log("something went wrong", error)
                this.setState({
                    linkingLoader: false
                })
            })
    }

    componentWillUnmount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef1(node) {
        this.wrapperRef1 = node;
    }

    setWrapperRef2(node) {
        this.wrapperRef2 = node;
    }
    setWrapperRef3(node) {
        this.wrapperRef3 = node;
    }
    setWrapperRef4(node) {
        this.wrapperRef4 = node;
    }
    setWrapperRef5(node) {
        this.wrapperRef5 = node;
    }
    setWrapperRef6(node) {
        this.wrapperRef6 = node;
    }
    setWrapperRef7(node) {
        this.wrapperRef7 = node;
    }
    setWrapperRef8(node) {
        this.wrapperRef8 = node;
    }
    setWrapperRef9(node) {
        this.wrapperRef9 = node;
    }
    setWrapperRef10(node) {
        this.wrapperRef10 = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
            document.getElementById('Date').classList.remove("show");

        }
        if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {

            document.getElementById('Time').classList.remove("show");
        }
        if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {

            document.getElementById('search').classList.remove("show");
        }
        if (this.wrapperRef4 && !this.wrapperRef4.contains(event.target)) {

            document.getElementById('Date_mylead').classList.remove("show");
        }
        if (this.wrapperRef5 && !this.wrapperRef5.contains(event.target)) {

            document.getElementById('mylead_Time').classList.remove("show");
        }
        if (this.wrapperRef6 && !this.wrapperRef6.contains(event.target)) {

            document.getElementById('search_mylead').classList.remove("show");
        }
        if (this.wrapperRef7 && !this.wrapperRef7.contains(event.target)) {

            document.getElementById('Date_availead').classList.remove("show");
        }
        if (this.wrapperRef8 && !this.wrapperRef8.contains(event.target)) {

            document.getElementById('availead_Time').classList.remove("show");
        }
        if (this.wrapperRef9 && !this.wrapperRef9.contains(event.target)) {

            document.getElementById('search_availead').classList.remove("show");
        }
        if (this.wrapperRef10 && !this.wrapperRef10.contains(event.target)) {

            document.getElementById('cnsearch').classList.remove("show");
        }

    }
    handleChange(date) {
        this.setState({
            time: date
        });
        document.getElementById('datePicker_time').value = date;
    }
    handleChangeDate(date) {

        var selectedDate = Moment(date).format('YYYY-MM-DD');
        var todaysDate = Moment(new Date()).format('YYYY-MM-DD');
        if (selectedDate == todaysDate) {
            if (this.state.time != '') {
                this.state.time = '';
            }
            this.setState({
                currentTime: new Date().getHours() + 1
            });
        }
        else {
            this.setState({
                currentTime: '7'
            });
        }

        this.setState({
            startDate: date
        });
    }

    handleUpperSearchDate = (date) => {
        this.setState({ upperSearchDate: date }, function () {
            document.getElementById('c_date_statusKey').classList.add("active");
            this.createUpperWhere();
            //  this.createmyleadWhere();
        });

    }

    handlemyleadSearchDate = (date) => {

        this.setState({ mylead_SearchDate: date }, function () {

            document.getElementById('m_date_statusKey').classList.add("active");
            this.createmyleadWhere()
        });
    }

    handleavaileadSearchDate = (date) => {
        this.setState({ availead_SearchDate: date }, function () {

            document.getElementById('a_date_statusKey').classList.add("active");
            this.createavaileadWhere()
        });
    }


    handleUpperSearchTime = (time) => {
        this.setState({ upperSearchTime: time }, function () {

            document.getElementById('c_time_statusKey').classList.add("active");
            this.createUpperWhere();
        });

    }

    handlemyleadSearchTime = (time) => {
        this.setState({ myleadSearchTime: time }, function () {

            document.getElementById('m_time_statusKey').classList.add("active");
            this.createmyleadWhere();
        });

    }
    handleavaileadSearchTime = (time) => {
        this.setState({ availeadSearchTime: time }, function () {
            document.getElementById('a_time_statusKey').classList.add("active");
            this.createavaileadWhere();
        });

    }

    onOpenModal = () => {
        this.setState({ open: true });
    };
    onOpenmyleadModal = () => {
        this.setState({ open_mylead: true });
    };
    onOpenavaileadModal = () => {
        this.setState({ open_availead: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });

    };
    onClosemyleadModal = () => {
        this.setState({ open_mylead: false });

    };
    onCloseavaileadModal = () => {
        this.setState({ open_availead: false });

    };




    hideClientPopup = () => {
        document.getElementById('client_search_id_values').style.display = 'none';
    }




    handleAgentSearch = event => {
        var selected_agent_name = event.target.getAttribute('name')

        this.setState({
            selected_agent_name: selected_agent_name
        })
        var key = event.target.id;
        switch (key) {
            case 'agent_search_id':
                this.setState({ searchParm: event.target.value, agent_name: event.target.value }, () => {
                    //if(this.state.searchParm.length>-1){
                    var value = this.state.searchParm;
                    if (value != '') {
                        this.state.agentList.forEach(function (agent, index) {
                            if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                                agent.flag = 1;
                            } else {
                                agent.flag = 0;
                            }
                        });
                    } else {
                        document.getElementById('agent_search_id_values').style.display = 'none';
                        this.state.agentList.forEach(function (agent, index) {
                            agent.flag = 0;
                        });

                    }
                })
                //}
                break;
            case 'searchkey2':
                var value = event.target.value;
                let $this = this;
                this.setState({ agent_id: event.target.value }, () => {
                    this.state.agentList.forEach(function (agent, index) {
                        if (agent.id == value) {
                            document.getElementById('agent_search_id_values').style.display = "none";
                            document.getElementById('agent_search_id').value = agent.name;
                            document.getElementById('agent_selected_id').value = agent.id;
                            $this.setState({ agent_name: agent.name });
                        } else {
                            agent.flag = 0;
                        }
                    });

                    document.getElementById('agent_search_id_values').style.display = "none";
                });
                break;
            case 'default':
                break;

        }
        document.getElementById('agent_search_id_values').style.display = "block";
    }

    async getShowingList() {

        //   this.setState({linkingLoader:true});
        return new Promise((resolve, reject) => {
            let clientRelWhere = [{
                url: "/showings/agentShowing/" + getAgentId()

            }];
            var len = Object.keys(this.state.upperSearchWhere);
            if (len.length > 0) {
                clientRelWhere[0].where = this.state.upperSearchWhere;
            }

            getApiData(clientRelWhere, true).then(res => {
                // console.log('ResponseData=>',res.data);
                this.setState({ showingData: res.data, linkingLoader: false });
                document.getElementById('Date').classList.remove("show");
                document.getElementById('Time').classList.remove("show");
                if (res.data.length) {
                    this.setState({ isShowingExist: true });
                    resolve(true);
                }
            }).catch(error => {
                //this.setState({linkingLoader:false});
                reject(false);
            });
        })

    }

    async getLeadList() {
        // console.log("---res")
        return new Promise((resolve, reject) => {
            let clientRelWhere = [{
                url: "/showings/agentLead/" + getAgentId()

            }];
            var len = Object.keys(this.state.mylead_SearchWhere);
            if (len.length > 0) {
                clientRelWhere[0].where = this.state.mylead_SearchWhere;
            }

            getApiData(clientRelWhere, true).then(res => {
                console.log('ResponseData=>', res.data);
                this.setState({ leadData: res.data });
                document.getElementById('Date_mylead').classList.remove("show");
                document.getElementById('mylead_Time').classList.remove("show");
                if (res.data.length) {
                    this.setState({ isLeadExist: true });
                    resolve(true);
                } else {
                    this.setState({ isLeadExist: false });
                    resolve(true);
                }
            }).catch(error => {
                reject(true);

            });
        })
    }

    async getShowingListContinous() {
        setInterval(() => {
            let clientRelWhere = [{
                url: "/showings/agentShowing/" + getAgentId()

            }];
            var len = Object.keys(this.state.upperSearchWhere);
            if (len.length > 0) {
                clientRelWhere[0].where = this.state.upperSearchWhere;
            }
            getApiData(clientRelWhere, true).then(res => {
                this.setState({ showingData: res.data });
                if (res.data.length) {
                    this.setState({ isShowingExist: true });
                }
            }).catch(error => {

            });
        }, 60000);
    }

    getAvailableShowing() {
        return new Promise((resolve, reject) => {
            let clientRelWhere = [{
                url: "/showings/availableShowing"
            }];
            var len = Object.keys(this.state.availead_SearchWhere);
            if (len.length > 0) {
                clientRelWhere[0].where = this.state.availead_SearchWhere;
            }
            getApiData(clientRelWhere, true).then(res => {
                console.log('availShowingData=>', res.data);
                this.setState({ availShowingData: res.data });
                document.getElementById('Date_availead').classList.remove("show");
                document.getElementById('availead_Time').classList.remove("show");
                if (res.data.length) {
                    this.setState({ isAvailShowingExist: true });
                    resolve(true);
                }
                else {
                    this.setState({ isAvailShowingExist: false });
                    resolve(true);
                }
            }).catch(error => {
                reject(false);
            });
        })

    }
    clientOpt() {
        return new Promise((resolve, reject) => {
            let agentParam = [{
                url: "/showings/clientName",
                where: {
                    'agent_id': getAgentId()
                }

            }];
            getApiData(agentParam, true).then(res => {
                this.setState({ clientList: res.data });
                resolve(true);
            }).catch(error => {
                if (error.response) {
                    this.setState({ myerrormsg: error.response.data.error.message });
                    reject("clientOpt");
                }
            });
        });

    }


    

    agentOpt() {
        return new Promise((resolve, reject) => {
            let agentParam = [{
                url: "/agent/agentDD"
            }];
            getApiData(agentParam, true).then(res => {
                this.setState({ 'agentList': res.data });
                resolve(true);
            }).catch(error => {
                if (error.response) {
                    this.setState({ myerrormsg: error.response.data.error.message });
                }
                reject(false);
            });
        })
    }

    listingOpt() {
        return new Promise((resolve, reject) => {
            this.setState({
                linkingLoader: true
            })
            let agentParam = [{
                url: "/showings/listingCreator"
            }];
            getApiData(agentParam, true).then(res => {
                this.setState({ 'listingCreator': res.data, linkingLoader: false });
                resolve(true);
            }).catch(error => {
                if (error.response) {
                    this.setState({ myerrormsg: error.response.data.error.message, linkingLoader: false });
                    reject(true);
                }
            });
        }).catch(error => {
            this.setState({ myerrormsg: error.response.data.error.message, linkingLoader: false });
        });

    }

    async checkEligiblity() {

        return new Promise((resolve, reject) => {
            let currentDate = this.getCurrentDate('date');
            let checkEligiblity = [{
                url: "/showings/checkEligiblity"
            }];
            var form = new FormData();
            form.append("agentId", getAgentId());
            form.append("updated_at", currentDate);
            postApiData(checkEligiblity, form, true).then(res => {
                // console.log("response--",res.data.length);
                if (res.data.length >= 3) {
                    resolve(3);            //3=> count is greater/equal to three for the day
                }
                else if (res.data.length > 0 && res.data.length < 3) {
                    var count = 0;
                    var j = 0;

                    // console.log("---next showingDateRec--", showingDateRec);
                    for (var i = 1; i <= res.data.length; i++) {
                        var dateFromTable = Moment.utc(res.data[j]['showing_date']).local().format('YYYY-MM-DD');
                        var showingDateRec = Moment.utc(this.state.s_showingDate).local().format('YYYY-MM-DD');
                        // console.log("showingDateRec--",showingDateRec);
                        // console.log("dateFromTable--",dateFromTable);
                        if (showingDateRec == dateFromTable) {
                            count = count + 1;
                            // console.log('in if --',count);
                        }

                        j++;
                    }
                    // console.log('count--',count);
                    if (count < 2) {
                        resolve(1);      //1=> showing date under the count 2
                    } else {
                        resolve(2);     //2=> showing date crossing the count 2
                    }

                }
                else {
                    resolve(1);      //1=> showing date under the count 2
                }
                // document.getElementById("closeAcceptSchudling").click();
                // this.getShowingList();
                // this.getAvailableShowing();
            }).catch(error => {
                // console.log(error);
            });
        });
    }

    async confirmAccept() {


        const result = await this.checkEligiblity();
        //console.log("result---", result);
        if (result == 1) {
            let currentDate = this.getCurrentDate('dateTime');
            let acceptShowing = [{
                url: "/showings/acceptShowing"

            }];
            var form = new FormData();
            form.append("agentId", getAgentId());
            form.append("clientShowingId", this.state.clientShowing);
            form.append("updated_at", currentDate);


            postApiData(acceptShowing, form, true).then(res => {
                // console.log("response--",res);
                // console.log("response--",res.data.message);
                if (res.data.success == true) {
                    document.getElementById("acceptSchudling").click();
                    const acceptFlag = true;
                    //this.updateTimeKitForAction(this.state.clientShowing);
                    this.confirmShowing(acceptFlag);
                    this.getShowingList();
                    this.getAvailableShowing();
                }
                else {
                    document.getElementById("acceptSchudling").click();
                    alert(res.data.message)
                    //  this.setState({myerrormsg: res.data.message});
                }

            }).catch(error => {

            });
        }
        else {
            if (result == 2) {
                alert("You can accept only two showings for the same date.");

            }
            else if (result == 3) {
                alert("You can accept only three showings per day.");

            }
        }


    }


    async checkEligiblityExclusive() {

        return new Promise((resolve, reject) => {
            var createdTime = Moment(this.state.s_showingTime, ['HH:mm:ss']).format('HH:mm:ss');

            var createdDate = Moment(this.state.s_showingDate, ['YYYY-MM-DD']).format('YYYY-MM-DD');
            let showingDate = createdDate + ' ' + createdTime;


            let checkEligiblity = [{
                url: "/showings/checkEligiblityExclusive"
            }];
            var form = new FormData();
            form.append("listingId", this.state.listing_id);
            form.append("showingDate", showingDate);
            postApiData(checkEligiblity, form, true).then(res => {
                //  console.log("response-checkEligiblityExclusive-",res.data.length);
                if (res.data.length > 0) {
                    //document.getElementById("closeAcceptSchudling").click();
                    //this.getShowingList();
                    //this.getAvailableShowing();
                    resolve(3);            //3=> count is greater/equal to three for the day
                }
                else {
                    resolve(1);
                }

            }).catch(error => {
                // console.log(error);
            });
        });
    }

    async confirmShowing(acceptFlag = false) {

        // if (acceptFlag == true) {
        //     var result = 1;
        // }
        // else {
        //     var result = await this.checkEligiblityExclusive();
        // }

      //  if (result == 1) {  // Ticket 1301 commented this section now agent can accept as many as showing in a day. Changed on 11 Jan 2022

            let confirmShowing = [{
                url: "/showings/confirmShowing"

            }];
            var form = new FormData();
            let currentDate = this.getCurrentDate('dateTime')
            form.append("clientShowingId", this.state.clientShowing);
            form.append("agent_id", getAgentId());
            form.append("updated_at", currentDate);
            form.append("reschedule_status", this.state.s_rescheduleStatus);
            form.append("clientEmail", this.state.s_clientEmail);
            form.append("showingDate", this.state.s_showingDate_email);
            form.append("showingTime", this.state.s_showingTime_email);
            form.append("showingAddress", this.state.s_showingAddress);
            form.append("showingUnit", this.state.s_showing_unit);
            form.append("clientName", this.state.s_clientName);
            form.append("showingDateTime", this.state.s_showingDate);
            form.append("googleEventId", this.state.googleEventId);

            form.append("lead", 0);
            postApiData(confirmShowing, form, true).then(res => {

                if (res.data.success) {
                    if (this.state.timekit_id != "" && this.state.timekit_id != null) {
                        this.updateTimeKitForAction(this.state.clientShowing, 'agent_id');
                    } else {

                        this.updateTimeKitForShowing(this.state.clientShowing, 'true');
                    }

                    document.getElementById("closeConfirmSchudling").click();
                    this.getShowingList();
                    this.getLeadList();
                    this.getAvailableShowing();
                    // document.location.reload();
                }
                else {
                    this.setState({ myerrormsg: res.data.message });
                }

            }).catch(error => {

            });
        //}
        // else {
        //     if (result == 3) {
        //         alert("You cannot accept overlapping showing. It should be +30 Min in difference.");
        //         document.getElementById("closeConfirmSchudling").click();
        //     }
        // }
    }

    setListingId(listingId, s_showingDate) {
        this.setState({ clientShowing: listingId, s_showingDate: s_showingDate });
    }

    setShowingsId(listingId, s_showingDate, s_showing_time, showing_address, address_value, showing_unit, unit_value, reschedule_status, client_email, email, client_name, client_fullname, timekit_id, listing_id,googleEventId) {
        let showingTimeForEmail = Moment(s_showing_time, ['h:mm:ss']).format('hh:mm A');
        let showingDateForEmail = Moment(s_showingDate).format('dddd, MMMM Do');
        this.setState({
            clientShowing: listingId, s_showingDate: s_showingDate, s_rescheduleStatus: reschedule_status,
            s_showingDate_email: showingDateForEmail, s_showingTime_email: showingTimeForEmail,
            timekit_id: timekit_id, listing_id: listing_id, s_showingTime: s_showing_time
        });
        if (showing_address == null) {

            this.setState({ s_showingAddress: address_value })
        }
        else {

            this.setState({ s_showingAddress: showing_address })
        }

        if (showing_unit == null) {
            this.setState({ s_showing_unit: unit_value })
        }
        else {
            this.setState({ s_showing_unit: showing_unit })

        }

        if (client_email == null) {
            this.setState({ s_clientEmail: email })

        }
        else {
            this.setState({ s_clientEmail: client_email })
        }
        if (client_name == null) {
            this.setState({ s_clientName: client_fullname })
        }
        else {
            this.setState({ s_clientName: client_name })

        }

        this.setState({
            googleEventId:googleEventId
        })

    }
    setShowingsCancel(listingId, s_showingDate, s_showingTime, showingAddress, address_value, showingUnit, unit_value, agentName, googleEventId,clientEmail,email) {
        this.setState({
            clientShowing: listingId, s_showingDate: s_showingDate, s_showingTime: s_showingTime,
            s_agentName: agentName
        });

        if (showingAddress == null) {
            this.setState({ s_showingAddress: address_value })

        }
        else {
            this.setState({ s_showingAddress: showingAddress })
        }
        if (showingUnit == null) {
            this.setState({ s_showing_unit: unit_value })

        }
        else {
            this.setState({ s_showing_unit: showingUnit })
        }
        if (clientEmail == null) {
            this.setState({ s_clientEmail: email })

        }
        else {
            this.setState({ s_clientEmail: clientEmail })
        }
        this.setState({
            googleEventId:googleEventId
        })
    }

    getMinuteLeft = (nextThirtyMin, clientShowingId) => {
        // console.log("--nextThirtyMin--",nextThirtyMin)
        // console.log("--clientShowingId--",clientShowingId)
        Moment.locale('en');

        var startTime = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        var endTime = Moment(nextThirtyMin).local().format('YYYY-MM-DD HH:mm:ss');
        // console.log("start--",startTime);
        // console.log("endTime--",endTime);

        var fromDate = parseInt(new Date(startTime).getTime() / 1000);
        var toDate = parseInt(new Date(endTime).getTime() / 1000);

        var timeDiff = Math.round((toDate - fromDate) / 3600 * 60);  // will give difference in minutes
        if (timeDiff == 0) {
            this.moveShowingToAvailable(clientShowingId);
            this.moveLeadToAvailable(clientShowingId);
        }
        else {
            // console.log("timeDiff---",timeDiff);
            // alert("time"+timeDiff);
            return timeDiff;
        }



    }

    moveShowingToAvailable = (clientShowingId) => {
        // console.log("---in lead move to available")
        let moveToAvailable = [{
            url: "/showings/moveToAvailable"

        }];
        var form = new FormData();
        form.append("id", clientShowingId);

        postApiData(moveToAvailable, form, true).then(res => {
            // console.log("---res",res)
            this.timekitShowingToAvailable(clientShowingId);
            this.getShowingList();
            this.getLeadList();
            this.getAvailableShowing();
        }).catch(error => {

        });

    }

    moveLeadToAvailable = (clientShowingId) => {
        // console.log("---in lead move to available")
        let moveToAvailable = [{
            url: "/showings/moveLeadToAvailable"

        }];
        var form = new FormData();
        form.append("id", clientShowingId);

        postApiData(moveToAvailable, form, true).then(res => {
            //   console.log("---res",res)
            this.timekitShowingToAvailable(clientShowingId);
            this.getShowingList();
            this.getLeadList();
            this.getAvailableShowing();
        }).catch(error => {

        });

    }
    createNewShowing = () => {
        document.getElementById('createnewshowing').disabled=true;
        
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv = encHex.parse("abcdef9876543210abcdef9876543210");

        let agentFlag = 0;
        let showingTime;
        let startDate = Moment(this.state.startDate).format('YYYY-MM-DD');
        if (this.state.time != '' && this.state.time != undefined) {
            showingTime = Moment(this.state.time).format('HH:mm:ss');
        }
        else {
            showingTime = '23:59:59';
        }

        startDate = startDate + ' ' + showingTime;
        let split = this.state.split;
        let email = 'info@moveskyward.com';
        let createShowing = [{
            url: "/showings/createShowing"

        }];
        var form = new FormData();
        // if(document.getElementById('searchkey2')){
        //     if(document.getElementById('searchkey2').value==0 || document.getElementById('searchkey2').value==''){
        //         form.append("agent_id", -1);
        //         agentFlag=0;
        //     }
        //     else{
        //         form.append("agent_id", document.getElementById('searchkey2').value);
        //         agentFlag=1;
        //     }

        // }
        // else{
        //     form.append("agent_id", -1);
        //     agentFlag=0;
        if (this.state.isChecked != true) {
            form.append("agent_id", getAgentId());
        }
        else {
            form.append("created_by", getAgentId());
        }


        if (this.state.client_id == 0) {

            form.append("client_id", "");
        }
        else {
            form.append("client_id", this.state.client_id);
        }
        //form.append("listing_id", document.getElementById('searchkey_unit').value);
        if (document.getElementById('listing_search_id').value) {
            form.append("listing_add", document.getElementById('listing_search_id').value);
        }
        else {
            form.append("listing_add", this.state.address_value);
        }

        if (document.getElementById('unit_search_id').value) {
            form.append("listing_unit", document.getElementById('unit_search_id').value);
        }
        else {
            form.append("listing_unit", this.state.unit_value);
        }


        form.append("showing_time", showingTime);
        form.append("showing_date", startDate);
        // form.append("client_name",this.state.client_name);
        if (this.state.client_name) {
            form.append("client_name", this.state.client_name);
        }
        else {
            form.append("client_name", this.state.client_fill_name);
        }
        if (this.state.clientphone.phone_number) {
            form.append("client_contact", aes.decrypt(this.state.clientphone.phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8));
        }
        else {
            form.append("client_contact", this.state.phone_number);
        }
        if (this.state.client_fill_email.email) {
            form.append("email", aes.decrypt(this.state.client_fill_email.email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8));
        }
        else {
            form.append("email", this.state.custom_client_email);
        }

        // form.append("email",email);
        form.append("split", split);
        form.append("status", 2);
        form.append('notes', this.state.notes);

        postApiData(createShowing, form, false).then(res => {
            console.log("response--", res);
            console.log("response--", res.data.message.insertId);
            if (res.data.success) {

                this.setState({ unit_value: '', unit_name: '', address_value: '', client_name: '', phone_number: '', custom_client_email: '' })
                this.setState({ clientphone: { phone_number: '' } });
                document.getElementById("newShowingClose").click();
                // window.location.reload();
                this.getShowingList();
                this.getAvailableShowing();
                this.updateTimeKitForShowing(res.data.message.insertId, true);


            }
            else {
                this.setState({ myerrormsg: res.data.message });
            }

        }).catch(error => {

        });
    }

    updateTimeKitForShowing(clientShowingId, flag = false) {

        let timeKitShowing = [{
            url: "/showings/createShowing/" + clientShowingId
        }];
        getApiData(timeKitShowing, false).then(res => {
            console.log("response---", res);
            if (flag) {
                this.updateTimeKitForAction(clientShowingId, 'agent_id');
            }
        }).catch(error => {
        });
    }





    validateForm = () => {

        if (this.state.isChecked == true) {
            if (this.state.clientphone.phone_number) {
                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;

                return this.state.client_name.length > 0

                    && this.state.address_value != "" && this.state.unit_value != "" && pattern.test(document.getElementById('clientEmail_search_id').value) === true && this.state.split.length > 0
            }
            else {
                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;

                return this.state.client_name.length > 0

                    && this.state.address_value != "" && this.state.unit_value != "" && this.state.phone_number.length == 12 && pattern.test(document.getElementById('clientEmail_search_id').value) === true && this.state.split.length > 0
            }
        }
        else {
            if (this.state.clientphone.phone_number) {
                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;

                return this.state.client_name.length > 0

                    && this.state.address_value != "" && this.state.unit_value != "" && pattern.test(document.getElementById('clientEmail_search_id').value) === true
            }
            else {
                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;

                return this.state.client_name.length > 0

                    && this.state.address_value != "" && this.state.unit_value != "" && this.state.phone_number.length == 12 && pattern.test(document.getElementById('clientEmail_search_id').value) === true
            }
        }



    }

    validatePhone(value) {
        let res = validatePhone(value);
        if (res.status == false) {
            this.setState({ phoneerror: 'Phone number is not valid.' });
            document.getElementById("clientNo_search_id").value = res.value;
        } else {
            this.setState({ phoneerror: '' });
            document.getElementById("clientNo_search_id").value = res.value;
        }
        return res.status;
    }

    

    confirmCancelShowing = () => {
        let cancelShowing = [{
            url: "/showings/cancelShowing"

        }];
        var form = new FormData();
        form.append("status", '3');
        form.append("clientShowingId", this.state.clientShowing);
        form.append("eventId", this.state.googleEventId);

        postApiData(cancelShowing, form, true).then(res => {
            console.log("response--", res);
            console.log("response--", res.data.message);
            if (res.data.success) {
                console.log("clientShowingId---", this.state.clientShowing);
                this.updateTimeKitForAction(this.state.clientShowing);
                this.sendEmailForCancellation();
                document.getElementById("closeCancelShowing").click();
                this.getShowingList();
                this.getAvailableShowing();
            }
            else {
                this.setState({ myerrormsg: res.data.message });
            }
        }).catch(error => {

        });
    }



    updateTimeKitForAction(clientShowingId) {

        let timeKitShowing = [{
            url: "/showings/updateShowing/" + clientShowingId + "/status"
        }];
        getApiData(timeKitShowing, true).then(res => {
            console.log("response---", res);
        }).catch(error => {
        });
    }

    timekitShowingToAvailable(clientShowingId) {

        let timeKitShowing = [{
            url: "/showings/updateShowingToAvailable/" + clientShowingId
        }];
        getApiData(timeKitShowing, true).then(res => {
            console.log("response---", res);
        }).catch(error => {
        });
    }


    sendEmailForCancellation = () => {

        let showingDate = Moment(this.state.s_showingDate).format('dddd, MMMM DD');
        let showingTime = Moment(this.state.s_showingTime, ['h:mm:ss']).format('hh:mm A');

        let sendEmail = [{
            url: "/showings/emailCancellationManager"

        }];
        var form = new FormData();
        form.append("showingAddress", this.state.s_showingAddress);
        form.append("showingUnit", this.state.s_showing_unit);
        form.append("showingDate", showingDate);
        form.append("showingTime", showingTime);
        form.append("agentName", this.state.s_agentName);
        form.append("managerId", 1);
        form.append("clientEmail", this.state.s_clientEmail);

        postApiData(sendEmail, form, true).then(res => {

            if (res.data.success) {
                this.setState({ mymsg: res.data.message });
            }
            else {
                this.setState({ myerrormsg: res.data.message });
            }
        }).catch(error => {
            this.setState({ myerrormsg: 'There is an error.' });
        });
    }

    defaultState = () => {
        this.setState(
            {
                agent_name: 0
                , client_name: ''
                , listing_name: 0
                , unit_name: 0
                , time: ''
                , address_value: ''
                , unit_value: ''
                , startDate: new Date()
                , split: ''
                , phone_number: ''
                , custom_client_email: ''
                , client_fill_email: ''
                , isChecked: false
                , notes: ''
                , clientphone: { phone_number: '' }
                , createShowingOpen: true
            }, () => {

            });
        if (document.getElementById('searchkey2')) {
            document.getElementById('searchkey2').value = "";
        }
    }

    createShowingClose = () => {
        console.log("clicked");
        this.setState({
            createShowingOpen: false
        })
    }




    async createUpperWhere() {
        this.state.upperSearchWhere = {};

        if (this.state.upperSearchDate != '') {
            let upperSearchDate = Moment(this.state.upperSearchDate).format('YYYY-MM-DD');
            this.state.upperSearchWhere.showing_date = upperSearchDate;
        }

        if (this.state.upperSearchTime != '') {
            let upperSearchTime = Moment(this.state.upperSearchTime, ['h:mm:ss']).format('HH:mm:ss');
            this.state.upperSearchWhere.showing_time = upperSearchTime;
        }

        if (this.state.searchAddressvalue != '') {
            //  let upperSearchTime = Moment(this.state.upperSearchTime,['h:mm:ss']).format('HH:mm:ss');
            this.state.upperSearchWhere.showingAddress = this.state.searchAddressvalue;
        }

        if (this.state.searchClientvalue != '') {
            //  let upperSearchTime = Moment(this.state.upperSearchTime,['h:mm:ss']).format('HH:mm:ss');
            this.state.upperSearchWhere.client_name = this.state.searchClientvalue;
        }
        this.getShowingList();
    }

    async createmyleadWhere() {
        this.state.mylead_SearchWhere = {};


        if (this.state.mylead_SearchDate != '') {
            let mylead_SearchDate = Moment(this.state.mylead_SearchDate).format('YYYY-MM-DD');

            this.state.mylead_SearchWhere.showing_date = mylead_SearchDate;
        }
        if (this.state.myleadSearchTime != '') {
            let myleadSearchTime = Moment(this.state.myleadSearchTime, ['h:mm:ss']).format('HH:mm:ss');
            this.state.mylead_SearchWhere.showing_time = myleadSearchTime;
        }
        if (this.state.searchAddressvalue != '') {
            //  let upperSearchTime = Moment(this.state.upperSearchTime,['h:mm:ss']).format('HH:mm:ss');
            this.state.mylead_SearchWhere.showingAddress = this.state.searchAddressvalue;
        }

        this.getLeadList();
    }

    async createavaileadWhere() {
        this.state.availead_SearchWhere = {};


        if (this.state.availead_SearchDate != '') {
            let availead_SearchDate = Moment(this.state.availead_SearchDate).format('YYYY-MM-DD');
            this.state.availead_SearchWhere.showing_date = availead_SearchDate;
        }
        if (this.state.availeadSearchTime != '') {
            let availeadSearchTime = Moment(this.state.availeadSearchTime, ['h:mm:ss']).format('HH:mm:ss');
            this.state.availead_SearchWhere.showing_time = availeadSearchTime;
        }

        if (this.state.searchAddressvalue != '') {
            //  let upperSearchTime = Moment(this.state.upperSearchTime,['h:mm:ss']).format('HH:mm:ss');
            this.state.availead_SearchWhere.showingAddress = this.state.searchAddressvalue;
        }
        this.getAvailableShowing();
    }



    resetsearchParm = () => {
        this.setState({
            where: {},
            upperSearchTime: '',
            upperSearchDate: '',
            mylead_SearchDate: '',
            myleadSearchTime: '',
            availead_SearchDate: '',
            availeadSearchTime: '',
            searchParm: "",
            searchParm_mylead: "",
            searchParm_availead: "",
            searchParm_client: "",
            searchAddressvalue: '',
            searchClientvalue: '',
            myerrormsg: "",
        }, () => {
            document.getElementById('searchParm').value = '';
            document.getElementById('searchParm_mylead').value = '';
            document.getElementById('searchParm_availead').value = '';
            document.getElementById('searchParm_client').value = '';
            document.getElementById('c_time_statusKey').classList.remove("active");
            document.getElementById('c_address_searchClick').classList.remove("active");
            document.getElementById('c_client_searchClick').classList.remove("active");
            document.getElementById('c_date_statusKey').classList.remove("active");
            document.getElementById('searchClick_mylead').classList.remove("active");
            document.getElementById('searchClick_availead').classList.remove("active");
            document.getElementById('search').classList.remove("show");
            document.getElementById('search_mylead').classList.remove("show");
            document.getElementById('search_availead').classList.remove("show");
            document.getElementById('cnsearch').classList.remove("show");
            document.getElementById('a_date_statusKey').classList.remove("active");
            document.getElementById('a_time_statusKey').classList.remove("active");
            document.getElementById('m_date_statusKey').classList.remove("active");
            document.getElementById('m_time_statusKey').classList.remove("active");
            this.getShowingList();
            this.getLeadList();
            this.createUpperWhere();
            this.createmyleadWhere();
            this.createavaileadWhere();

        });
    }

    preventKeystroke = (event) => {
        console.log("keycode---", event.keyCode);
        if (event.keyCode) {
            event.preventDefault();
        }
    }

    splithandleChange(event) {
        this.setState({ split: event.target.value });
        if (this.state.split.length > 0 && this.state.isChecked == true) {

            this.setState({
                checkSplit: true
            })
        } else {
            this.setState({
                checkSplit: false
            })
        }

    }

    
    




    search = (event) => {

        var key = event.target.id;

        switch (key) {

            case 'searchParm':
                this.setState({ searchParm: event.target.value });
                //  console.log('asssssssssssssssssssssssssss',this.state.searchParm)
                if (this.state.searchParm.length > 2) {
                    var value = this.state.searchParm.trim();
                    if (event.key == 'Enter') {
                        this.getData(value, key);

                    } else if (value == '') {
                        this.state.addressData.forEach(function (address, index) {
                            address.flag = 0;
                        });
                        this.setState({ searchMsg: false });
                    } else {
                        var len = this.state.addressData.length;
                        var count = 0;
                        var vflag = 0;
                        this.state.addressData.forEach(function (address, index) {

                            if (address.listing_info_address.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                                address.flag = 1;
                                vflag = 1;
                                count = count + 1;
                            } else {
                                address.flag = 0;
                                if (vflag != 1)
                                    vflag = 0
                                count = count + 1;
                            }

                        });

                        if (len == count) {
                            if (vflag == 0) {
                                this.setState({ searchMsg: true });

                            } else {
                                this.setState({ searchMsg: false });

                            }


                        }


                    }

                }
                break;

            case 'searchParm_mylead':
                this.setState({ searchParm_mylead: event.target.value });
                //  console.log('asssssssssssssssssssssssssss',this.state.searchParm)
                //if(this.state.searchParm.length>1){	
                var value = this.state.searchParm_mylead.trim();
                if (event.key == 'Enter') {
                    this.getData(value, key);

                } else if (value == '') {
                    this.state.addressData_mylead.forEach(function (address, index) {
                        address.flag = 0;
                    });
                    this.setState({ searchMsg: false });
                } else {
                    var len = this.state.addressData_mylead.length;
                    var count = 0;
                    var vflag = 0;
                    this.state.addressData_mylead.forEach(function (address, index) {

                        if (address.listing_info_address.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                            address.flag = 1;
                            vflag = 1;
                            count = count + 1;
                        } else {
                            address.flag = 0;
                            if (vflag != 1)
                                vflag = 0
                            count = count + 1;
                        }

                    });

                    if (len == count) {
                        if (vflag == 0) {
                            this.setState({ searchMsg: true });

                        } else {
                            this.setState({ searchMsg: false });

                        }


                    }


                }

                //}
                break;

            case 'searchParm_availead':
                this.setState({ searchParm_availead: event.target.value });
                //  console.log('asssssssssssssssssssssssssss',this.state.searchParm)
                //if(this.state.searchParm.length>1){	
                var value = this.state.searchParm_availead.trim();
                if (event.key == 'Enter') {
                    this.getData(value, key);

                } else if (value == '') {
                    this.state.addressData_availead.forEach(function (address, index) {
                        address.flag = 0;
                    });
                    this.setState({ searchMsg: false });
                } else {
                    var len = this.state.addressData_availead.length;
                    var count = 0;
                    var vflag = 0;
                    this.state.addressData_availead.forEach(function (address, index) {

                        if (address.listing_info_address.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                            address.flag = 1;
                            vflag = 1;
                            count = count + 1;
                        } else {
                            address.flag = 0;
                            if (vflag != 1)
                                vflag = 0
                            count = count + 1;
                        }

                    });

                    if (len == count) {
                        if (vflag == 0) {
                            this.setState({ searchMsg: true });

                        } else {
                            this.setState({ searchMsg: false });

                        }


                    }


                }

                //}
                break;

            case 'searchParm_client':

                this.setState({ searchParm_client: event.target.value });
                //  console.log('asssssssssssssssssssssssssss',this.state.searchParm)
                //if(this.state.searchParm.length>1){	
                var value = this.state.searchParm_client.trim();
                if (event.key == 'Enter') {
                    this.getData(value, key);

                } else if (value == '') {
                    this.state.clientList.forEach(function (client, index) {
                        client.flag = 0;
                    });
                    this.setState({ searchMsg: false });
                } else {
                    var len = this.state.clientList.length;
                    var count = 0;
                    var vflag = 0;
                    this.state.clientList.forEach(function (client, index) {

                        if (client.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                            client.flag = 1;
                            vflag = 1;
                            count = count + 1;
                        } else {
                            client.flag = 0;
                            if (vflag != 1)
                                vflag = 0
                            count = count + 1;
                        }

                    });

                    if (len == count) {
                        if (vflag == 0) {
                            this.setState({ searchMsg: true });

                        } else {
                            this.setState({ searchMsg: false });

                        }


                    }


                }
                break;

        }


    }

    handleClickOnList = param => e => {
        var value = param[0];

        this.getData(value, e.target.id);
        document.getElementById('search').classList.remove("show");
        document.getElementById('search_mylead').classList.remove("show");
        document.getElementById('search_availead').classList.remove("show");
        document.getElementById('cnsearch').classList.remove("show");


    }


    getData(addressvalue, id) {

        if (id == 'searchkey') {
            this.setState({ searchAddressvalue: addressvalue }, () => {
                this.state.addressData.forEach(function (address, index) {
                    if (address.listing_info_address == addressvalue) {

                        //  document.getElementById('statusKey').classList.remove("show");
                        document.getElementById('searchParm').value = addressvalue;
                        document.getElementById('c_address_searchClick').classList.add("active");


                    }
                    else {
                        address.flag = 0;
                    }

                });
                this.createUpperWhere();


            });

        }

        if (id == 'searchkey_client') {
            this.setState({ searchClientvalue: addressvalue }, () => {
                this.state.clientList.forEach(function (client, index) {
                    if (client.name == addressvalue) {


                        //document.getElementById('statusKey').classList.remove("show");
                        document.getElementById('searchParm_client').value = addressvalue;
                        document.getElementById('c_client_searchClick').classList.add("active");

                    }
                    else {
                        client.flag = 0;
                    }

                });
                this.createUpperWhere();


            });

        }

        if (id == 'searchkey_mylead') {
            this.setState({ searchAddressvalue: addressvalue }, () => {
                this.state.addressData_mylead.forEach(function (address, index) {
                    if (address.listing_info_address == addressvalue) {

                        document.getElementById('searchClick_mylead').classList.add("active");

                        document.getElementById('searchParm_mylead').value = addressvalue;

                    }
                    else {
                        address.flag = 0;
                    }

                });

                this.createmyleadWhere();

            });

        }

        if (id == 'searchkey_availead') {
            this.setState({ searchAddressvalue: addressvalue }, () => {
                this.state.addressData_availead.forEach(function (address, index) {
                    if (address.listing_info_address == addressvalue) {

                        document.getElementById('searchClick_availead').classList.add("active");

                        document.getElementById('searchParm_availead').value = addressvalue;

                    }
                    else {
                        address.flag = 0;
                    }

                });

                this.createavaileadWhere();

            });

        }


    }



    getAvailable_AddressData = () => {
        return new Promise((resolve, reject) => {

            let listingWhere = [{
                url: "/showings/showing/avashowingAdd"
            }];

            getApiData(listingWhere, true).then(res => {

                this.setState({
                    addressData_availead: res.data
                }, () => {
                    resolve(true)
                })
            }).catch(err => {

                reject(false)
            })
        })
    }
    getConfirmed_AddressData = () => {
        return new Promise((resolve, reject) => {
            let listingWhere = [{
                url: "/showings/showing/confshowingAdd",
                where: {
                    'agent_id': getAgentId()
                }
            }];

            getApiData(listingWhere, true).then(res => {

                this.setState({
                    addressData: res.data
                }, () => {
                    resolve(true)
                })
            }).catch(err => {
                reject(false)

            })
        })

    }
    getmyLead_AddressData = () => {
        return new Promise((resolve, reject) => {
            let listingWhere = [{
                url: "/showings/showing/myLeadshowingAdd",
                where: {
                    'agent_id': getAgentId()
                }
            }];

            getApiData(listingWhere, true).then(res => {

                this.setState({
                    addressData_mylead: res.data
                }, () => {
                    resolve(true)
                })
            }).catch(err => {

                reject(true)
            })
        })

    }

    handleClick(id, showing_date, client_name, client_fullname, showing_time, showingAddress, address_value, showingUnit, unit_value, agent_name, phone_number, client_phone_number, client_email, email, split, notes) {

        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv = encHex.parse("abcdef9876543210abcdef9876543210");
        this.setState({ showingpopupid: id, showing_date_confirm: Moment(showing_date).format('YYYY-MM-DD'), showing_time_confirm: Moment(showing_time, ['h:mm:ss']).format('hh:mm A'), confirm_split: split, confirm_notes: notes }, () => {
            this.onOpenModal();
            console.log('eeeeeeeeeeeeeeeeeeeeeeeeeee', this.state.showingpopupid);
        });

        if (showingAddress == null) {
            this.setState({ showingAddressconfirm: address_value })

        }
        else {
            this.setState({ showingAddressconfirm: showingAddress })
        }
        if (showingUnit == null) {
            this.setState({ showingunitconfirm: unit_value })

        }
        else {
            this.setState({ showingunitconfirm: showingUnit })
        }
        if (client_name == null) {
            this.setState({ client_name_confirm: client_fullname })

        }
        else {
            this.setState({ client_name_confirm: client_name })
        }
        if (phone_number == null) {
            this.setState({ client_phone_confirm: aes.decrypt(client_phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })

        }
        else {
            this.setState({ client_phone_confirm: aes.decrypt(phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })
        }
        if (client_email == null) {
            this.setState({ client_email_confirm: aes.decrypt(email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })

        }
        else {
            this.setState({ client_email_confirm: aes.decrypt(client_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })
        }



    }

    handleClickmylead(id, showing_date, showing_time, showingAddress, address_value, showingUnit, unit_value, price, bedrooms, bathrooms, notes, split) {
        this.setState({ showingmyleadid: id, showing_date_mylead: Moment(showing_date).format('YYYY-MM-DD'), showing_time_mylead: Moment(showing_time, ['h:mm:ss']).format('hh:mm A'), mylead_price: price, mylead_bedrooms: bedrooms, mylead_bathrooms: bathrooms, mylead_notes: notes, mylead_split: split }, () => {
            this.onOpenmyleadModal();
            // console.log('eeeeeeeeeeeeeeeeeeeeeeeeeee',this.state.showingpopupid);
        });

        if (showingAddress == null) {
            this.setState({ showingAddressmylead: address_value })

        }
        else {
            this.setState({ showingAddressmylead: showingAddress })
        }
        if (showingUnit == null) {
            this.setState({ showingunitmylead: unit_value })

        }
        else {
            this.setState({ showingunitmylead: showingUnit })
        }
    }

    handleClickavailead(id, showing_date, showing_time, showingAddress, address_value, showingUnit, unit_value, split, price, bedrooms, bathrooms, notes) {
        this.setState({ showingavaileadid: id, showing_date_availead: Moment(showing_date).format('YYYY-MM-DD'), showing_time_availead: Moment(showing_time, ['h:mm:ss']).format('hh:mm A'), availead_price: price, availead_bedrooms: bedrooms, availead_bathrooms: bathrooms, availead_split: split, availead_notes: notes }, () => {
            this.onOpenavaileadModal();
            // console.log('eeeeeeeeeeeeeeeeeeeeeeeeeee',this.state.showingpopupid);
        });

        if (showingAddress == null) {
            this.setState({ showingAddressavailead: address_value })

        }
        else {
            this.setState({ showingAddressavailead: showingAddress })
        }
        if (showingUnit == null) {
            this.setState({ showingunitavailead: unit_value })

        }
        else {
            this.setState({ showingunitavailead: showingUnit })
        }
    }

    // get agent showing

    async getagentshowingstatus() {

        return new Promise((resolve, reject) => {
            let clientParam = [{
                url: "/showings/getAgentShowing/" + getAgentId()
            }];


            getApiData(clientParam, true).then(res => {


                this.setState({ agent_showing_status: res.data[0].showing_status });
                resolve(true);

            }).catch(error => {
                if (error.response) {
                    this.setState({ myerrormsg: error.response.data.error.message });
                }
                reject(false);
            });
        })

    }

    openChat = () => {
        this.state.openChat = true;
    }
    closeChat = () => {
        this.state.openChat = false;
    }
    reload = () =>{
        console.log("reload")
        this.setState({linkingLoader:true})
        Promise.all([
        this.getLeadList()
        ,this.getAvailableShowing()
        ,this.getAvailableShowing()
        ]).then(result => {
            console.log("result", result);
            this.setState({
                linkingLoader: false
            })
        })
            .catch((error) => {
                console.log("something went wrong", error)
                this.setState({
                    linkingLoader: false
                })
            })
        
    }

    render() {
        if (!getAgentToken()) {
            return (<Redirect to={'/agent/landing'}/>);
		  }
        const addressDW = this.state.addressData.map((dw, index) =>
            (dw.flag == 1) ? <li key={index} id="searchkey" onClick={this.handleClickOnList([dw.listing_info_address])}>{dw.listing_info_address}</li> : ''
        );

        const addressDWmylead = this.state.addressData_mylead.map((dw, index) =>
            (dw.flag == 1) ? <li key={index} id="searchkey_mylead" onClick={this.handleClickOnList([dw.listing_info_address])}>{dw.listing_info_address}</li> : ''
        );

        const addressDWavailead = this.state.addressData_availead.map((dw, index) =>
            (dw.flag == 1) ? <li key={index} id="searchkey_availead" onClick={this.handleClickOnList([dw.listing_info_address])}>{dw.listing_info_address}</li> : ''
        );

        const clientsearchname = this.state.clientList.map((dw, index) =>
            (dw.flag == 1) ? <li key={index} id="searchkey_client" onClick={this.handleClickOnList([dw.name])}>{dw.name}</li> : ''
        );

        const listingDWUnitList = this.state.unitListingCreator.map((un, index) =>
            (un.flag == 1) ? <li id="searchkey_unit" key={un.id} value={un.id} name={un.unit} onClick={this.handleListingCreatorUnitSearch}>{un.unit}</li> : '');

        const agentDW = this.state.agentList.map((aw, index) =>
            (aw.flag == 1 && aw.id > 0) ? <li id="searchkey2" key={aw.id} value={aw.id} name={aw.name} onClick={this.handleAgentSearch}>{aw.name}</li> : '');

        var showingstatus = clientShowingStatus();
        const { isShowingExist } = this.state;
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv = encHex.parse("abcdef9876543210abcdef9876543210");

        Moment.locale('en'); let minuteLeft;
        let showingItems = this.state.showingData.map((s) =>
            <tr key={s.id} className={(s.reschedule_status == 0) ? "" : "danger"} >
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{Moment(s.showing_date).format('M/D')}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(s.showing_time != '23:59:59') ? Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A') : '--'}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(!s.showingAddress) ? s.address_value : s.showingAddress}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(!s.showingUnit) ? s.unit_value : s.showingUnit}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(!s.client_name) ? s.client_fullname : s.client_name}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(!s.phone_number) ? aes.decrypt(s.client_phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) : aes.decrypt(s.phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(s.client_email) ? aes.decrypt(s.client_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) : aes.decrypt(s.email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{s.split}</td>
                {s.reschedule_status != 1 ? (<td>&nbsp;</td>) : (<td>EXPIRES IN {this.getMinuteLeft(s.minuteLeft, s.id)} MINUTES</td>)}


                {(s.status == 1) ? (<td align="center"><Link to='#' onClick={() => this.setShowingsCancel(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name,s.googleEventId,s.client_email,s.email)} data-toggle="modal" data-target="#cancelSchudling">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="#333333" xmlns="http://www.w3.org/2000/svg">
                        <path className="blk-icon" d="M14 1.41003L12.59 3.05176e-05L7 5.59003L1.41 3.05176e-05L0 1.41003L5.59 7.00003L0 12.59L1.41 14L7 8.41003L12.59 14L14 12.59L8.41 7.00003L14 1.41003Z" fill="#333333" />
                    </svg>
                </Link></td>) :
                    (<td align="center">
                        <Link to='#' data-toggle="modal" onClick={() => this.setShowingsId(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.reschedule_status, s.client_email, s.email, s.client_name, s.client_fullname, s.timekit_id, s.listing_id,s.googleEventId)} data-target="#ReAssignShowing">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="#333333" xmlns="http://www.w3.org/2000/svg">
                                <path className="blk-icon" d="M16 9.00005V16H2V9.00005H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V9.00005H16ZM10 9.67005L12.59 7.09005L14 8.50005L9 13.5L4 8.50005L5.41 7.09005L8 9.67005V4.86374e-05H10V9.67005Z" fill="black" />
                            </svg>
                        </Link>
                    </td>)}
            </tr>
        );

        const { isAvailShowingExist } = this.state;


        let availShowingItems = this.state.availShowingData.map((s) =>

            <tr className="success" key={s.id}>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes)}>{Moment(s.showing_date).format('M/D')}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes)}>{(s.showing_time != '23:59:59') ? Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A') : '--'}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes)}>{(!s.showingAddress) ? s.address_value : s.showingAddress}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes)}>{(!s.showingUnit) ? s.unit_value : s.showingUnit}</td>
                {/* <td>{(!s.client_name)?s.client_fullname:s.client_name}</td> */}
                {/* <td>{(!s.phone_number)?aes.decrypt(s.client_phone_number, key, {iv: iv , padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):aes.decrypt(s.phone_number, key, {iv: iv , padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</td>
                 <td>{s.split}</td> */}
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes)}>
                {s.price?'$'+s.price.toLocaleString():'$'+s.price}
                </td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes)}>{s.bedrooms}BR/{s.bathrooms}BA</td>
                <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes)}>{s.split}</td>
                <td align="center">&nbsp;</td>

                <td align="center">
                    {this.state.agent_showing_status == 1 ? s.block_showing == 0 ? <Link to='#' data-toggle="modal" onClick={() => this.setShowingsId(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.reschedule_status, s.client_email, s.email, s.client_name, s.client_fullname, s.timekit_id, s.listing_id,s.googleEventId)} data-target="#acceptSchudling">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="#333333" xmlns="http://www.w3.org/2000/svg">
                            <path className="blk-icon" d="M16 9.00005V16H2V9.00005H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V9.00005H16ZM10 9.67005L12.59 7.09005L14 8.50005L9 13.5L4 8.50005L5.41 7.09005L8 9.67005V4.86374e-05H10V9.67005Z" fill="black" />
                        </svg>

                    </Link> : '' : ''}
                </td>

            </tr>);

        const { isLeadExist } = this.state;
        let leadsItems;

        Moment.locale('en'); let minutesLeft;
        if (this.state.leadData) {
            leadsItems = this.state.leadData.map((s) =>
                <tr key={s.id} className={s.listing_information != 'null' ? s.listing_information == "Open Listing" ? "danger" : "" : "danger"} >
                    <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickmylead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.price, s.bedrooms, s.bathrooms, s.notes, s.split)}>{Moment(s.showing_date).format('M/D')}</td>
                    <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickmylead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.price, s.bedrooms, s.bathrooms, s.notes, s.split)}>{(s.showing_time != '23:59:59') ? Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A') : '--'}</td>
                    <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickmylead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.price, s.bedrooms, s.bathrooms, s.notes, s.split)}>{(!s.showingAddress) ? s.address_value : s.showingAddress}</td>
                    <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickmylead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.price, s.bedrooms, s.bathrooms, s.notes, s.split)}>{(!s.showingUnit) ? s.unit_value : s.showingUnit}</td>
                    {/* <td>{(!s.client_name)?s.client_fullname:s.client_name}</td>
                                                          <td>{(!s.phone_number)?aes.decrypt(s.client_phone_number, key, {iv: iv , padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):aes.decrypt(s.phone_number, key, {iv: iv , padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</td>
                                                          <td>{s.split}</td> */}
                    <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickmylead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.price, s.bedrooms, s.bathrooms, s.notes, s.split)}>
                    {s.price?'$'+s.price.toLocaleString():'$'+s.price}
                   </td>
                    <td style={{ cursor: 'pointer' }} onClick={() => this.handleClickmylead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.price, s.bedrooms, s.bathrooms, s.notes, s.split)}>{s.bedrooms}BR/{s.bathrooms}BA</td>
                    {s.listing_information != 'null' ? s.listing_information == "Open Listing" ? (<td>EXPIRES IN {this.getMinuteLeft(s.minuteLeft, s.id)} MINUTES</td>)
                        : (<td>&nbsp;</td>) : (<td>EXPIRES IN {this.getMinuteLeft(s.minuteLeft, s.id)} MINUTES</td>)}


                    {(s.status == 1) ? (<td align="center"><Link to='#' onClick={() => this.setShowingsCancel(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name)} data-toggle="modal" data-target="#cancelSchudling">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="#333333" xmlns="http://www.w3.org/2000/svg">
                            <path className="blk-icon" d="M14 1.41003L12.59 3.05176e-05L7 5.59003L1.41 3.05176e-05L0 1.41003L5.59 7.00003L0 12.59L1.41 14L7 8.41003L12.59 14L14 12.59L8.41 7.00003L14 1.41003Z" fill="#333333" />
                        </svg>
                    </Link></td>) :
                        (<td align="center">
                            <Link to='#' data-toggle="modal" onClick={() => this.setShowingsId(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.reschedule_status, s.client_email, s.email, s.client_name, s.client_fullname, s.timekit_id, s.listing_id, s.googleEventId)} data-target="#ReAssignShowing">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="#333333" xmlns="http://www.w3.org/2000/svg">
                                    <path className="blk-icon" d="M16 9.00005V16H2V9.00005H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V9.00005H16ZM10 9.67005L12.59 7.09005L14 8.50005L9 13.5L4 8.50005L5.41 7.09005L8 9.67005V4.86374e-05H10V9.67005Z" fill="black" />
                                </svg>
                            </Link>
                        </td>)}
                </tr>
            );
        }
        const { open, open_mylead, open_availead } = this.state;

        return (
            <div>
                <Header />
                <main>
                    <SideBar />
                    <div className="content">
                        <div className="work-section">
                            <div className="dashboard">
                                {(this.state.linkingLoader) ? <div className="show_loader_on_click" id="show_loader_on_click"></div> : ''}

                                {this.state.mymsg !== '' ? (<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>) : (null)}
                                {this.state.myerrormsg !== '' ? (<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>) : (null)}


                                <div className="clearfix">
                                    <h2 className="pull-left">Confirmed Showings</h2>
                                    <div className="pull-right Cr-showing-btn">
                                        <Link to='#' onClick={this.defaultState}><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="15" cy="15" r="15" fill="#F26C52" />
                                            <path className="create-showing" d="M24 16.7143H15.9643V24.75H13.2857V16.7143H5.25V14.0357H13.2857V5.99999H15.9643V14.0357H24V16.7143Z" fill="white" />
                                        </svg></Link>
                                    </div>
                                </div>

                                <Modal open={this.state.createShowingOpen} little>
                                    <CreateShowingPopUp onClick={this.createShowingClose} reList={this.reload}/>
                                </Modal>

                                <div className="panel-body table-responsive showingWrap">
                                    <table className="table table-striped agent-listing showingtable" style={{ marginTop: '0px', marginBottom: '60px' }}>
                                        <thead>
                                            <tr>
                                                <th>Date
                                                <Link to='#' id="c_date_statusKey" data-toggle="collapse" data-target="#Date" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
                                                    <div id="Date" className="collapse table-collapse status-collapse" ref={this.setWrapperRef1}>


                                                        <div className="box">
                                                            <label>Date</label>
                                                            <DatePicker
                                                                selected={this.state.upperSearchDate}
                                                                onChange={this.handleUpperSearchDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                timeCaption="time"
                                                                className="form-control"
                                                                minDate={new Date()}
                                                                id="upperSearchDate"
                                                                onKeyDown={(e) => this.preventKeystroke(e)}


                                                            />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Time
                                                    <Link to='#' id="c_time_statusKey" data-toggle="collapse" data-target="#Time" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
                                                    <div id="Time" className="collapse table-collapse status-collapse" ref={this.setWrapperRef2}>

                                                        <div className="box">

                                                            <label>Time</label>

                                                            <DatePicker
                                                                id="upperSearchTime"
                                                                selected={this.state.upperSearchTime}
                                                                onChange={this.handleUpperSearchTime}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeFormat="h:mm aa"
                                                                timeIntervals={15}
                                                                dateFormat="h:mm aa"
                                                                timeCaption="time"
                                                                className="form-control"
                                                                onKeyDown={(e) => this.preventKeystroke(e)}
                                                            />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                        </div>

                                                    </div>
                                                </th>
                                                <th>Address
                                                    <Link to='#' id="c_address_searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
                                                    <div id="search" className="collapse table-collapse client-collapse" ref={this.setWrapperRef3}>
                                                        <div className="box">

                                                            <input type="text" id="searchParm" autoComplete="off" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                            <ul>
                                                                {addressDW}
                                                            </ul>

                                                            {(this.state.searchMsg) ? 'No suggestions available' : ''}
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Unit</th>

                                                <th>Client Name
												<Link to='#' id="c_client_searchClick" data-toggle="collapse" data-target="#cnsearch" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
                                                    <div id="cnsearch" className="collapse table-collapse client-collapse" ref={this.setWrapperRef10}>
                                                        <div className="box">

                                                            <input type="text" id="searchParm_client" autoComplete="off" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                            <ul>
                                                                {clientsearchname}
                                                            </ul>
                                                            {(this.state.searchMsg) ? 'No suggestions available' : ''}

                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Client Phone</th>
                                                <th>Client Email</th>
                                                <th>Split</th>

                                                <th></th>
                                                <th style={{ textAlign: 'center' }}>Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isShowingExist && !this.state.linkingLoader) ? showingItems : (!isShowingExist && !this.state.linkingLoader) ? <tr><td colSpan="10">You don’t have any confirmed showings yet</td></tr> : ''}
                                        </tbody>
                                    </table>
                                </div>
                                
                                {/* My leads */}

                                <div className="clearfix">
                                    <h2 className="pull-left">My Leads</h2>
                                    {/* <div className="pull-right Cr-showing-btn">
											<Link to='#' data-toggle="modal" data-target="#CreateShowing"  onClick={()=>this.defaultState()}><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="15" cy="15" r="15" fill="#F26C52"/>
										<path className="create-showing" d="M24 16.7143H15.9643V24.75H13.2857V16.7143H5.25V14.0357H13.2857V5.99999H15.9643V14.0357H24V16.7143Z" fill="white"/>
										</svg></Link>

									</div> */}
                                </div>
                                <div className="panel-body table-responsive showingWrap">
                                    <table className="table table-striped agent-listing showingtable" style={{ marginTop: '0px', marginBottom: '60px' }}>
                                        <thead>
                                            <tr>
                                                <th>Date
                                                <Link to='#' id="m_date_statusKey" data-toggle="collapse" data-target="#Date_mylead" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
                                                    <div id="Date_mylead" className="collapse table-collapse status-collapse" ref={this.setWrapperRef4}>


                                                        <div className="box">
                                                            <label>Date</label>
                                                            <DatePicker
                                                                selected={this.state.mylead_SearchDate}
                                                                onChange={this.handlemyleadSearchDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                timeCaption="time"
                                                                className="form-control"
                                                                minDate={new Date()}
                                                                id="mylead_SearchDate"
                                                                onKeyDown={(e) => this.preventKeystroke(e)}


                                                            />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Time
                                                    <Link to='#' id="m_time_statusKey" data-toggle="collapse" data-target="#mylead_Time" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
                                                    <div id="mylead_Time" className="collapse table-collapse status-collapse" ref={this.setWrapperRef5}>

                                                        <div className="box">
                                                            <label>Time</label>

                                                            <DatePicker
                                                                id="myleadSearchTime"
                                                                selected={this.state.myleadSearchTime}
                                                                onChange={this.handlemyleadSearchTime}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeFormat="h:mm aa"
                                                                timeIntervals={15}
                                                                dateFormat="h:mm aa"
                                                                timeCaption="time"
                                                                className="form-control"
                                                                onKeyDown={(e) => this.preventKeystroke(e)}
                                                            />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Address
                                                <Link to='#' id="searchClick_mylead" data-toggle="collapse" data-target="#search_mylead" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
                                                    <div id="search_mylead" className="collapse table-collapse client-collapse" ref={this.setWrapperRef6}>
                                                        <div className="box">

                                                            <input type="text" id="searchParm_mylead" autoComplete="off" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                            <ul>
                                                                {addressDWmylead}
                                                            </ul>

                                                            {(this.state.searchMsg) ? 'No suggestions available' : ''}
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Unit</th>

                                                {/* <th>Client Name 
												<Link to='#'  id="searchClick" data-toggle="collapse" data-target="#cnsearch" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="cnsearch" className="collapse table-collapse client-collapse">
												<div className="box">

												<input type="text" id="searchParm" autoComplete="off" className="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
                                                <span onClick={this.resetsearchParm}>(X)</span>
													<ul>
													{clientDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
												
												</div>
												</div>
												</th> */}
                                                <th>Price</th>
                                                <th>Layout</th>
                                                <th>Status</th>
                                                <th style={{ textAlign: 'center' }}>Accept </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isLeadExist != false && !this.state.linkingLoader) ? leadsItems : (isLeadExist == false && !this.state.linkingLoader) ? <tr><td colSpan="9">You don’t have any leads yet</td></tr> : ''}

                                        </tbody>
                                    </table>
                                </div>
                                           
                                {/* My leads */}

                                <div className="clearfix">
                                    <h2 className="pull-left">Available Leads</h2>

                                </div>

                                <div className="panel-body table-responsive showingWrap">
                                    <table className="table table-striped agent-listing showingtable" style={{ marginTop: '0px', marginBottom: '60px' }}>
                                        <thead>
                                            <tr>
                                                <th>Date
                                            <Link to='#' id="a_date_statusKey" data-toggle="collapse" data-target="#Date_availead" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
                                                    <div id="Date_availead" className="collapse table-collapse status-collapse" ref={this.setWrapperRef7}>


                                                        <div className="box">
                                                            <label>Date</label>
                                                            <DatePicker
                                                                selected={this.state.availead_SearchDate}
                                                                onChange={this.handleavaileadSearchDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                timeCaption="time"
                                                                className="form-control"
                                                                minDate={new Date()}
                                                                id="availead_SearchDate"
                                                                onKeyDown={(e) => this.preventKeystroke(e)}


                                                            />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Time
                                                <Link to='#' id="a_time_statusKey" data-toggle="collapse" data-target="#availead_Time" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
                                                    <div id="availead_Time" className="collapse table-collapse status-collapse" ref={this.setWrapperRef8}>

                                                        <div className="box">
                                                            <label>Time</label>

                                                            <DatePicker
                                                                id="availeadSearchTime"
                                                                selected={this.state.availeadSearchTime}
                                                                onChange={this.handleavaileadSearchTime}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeFormat="h:mm aa"
                                                                timeIntervals={15}
                                                                dateFormat="h:mm aa"
                                                                timeCaption="time"
                                                                className="form-control"
                                                                onKeyDown={(e) => this.preventKeystroke(e)}
                                                            />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Address
                                                <Link to='#' id="searchClick_availead" data-toggle="collapse" data-target="#search_availead" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
                                                    <div id="search_availead" className="collapse table-collapse client-collapse" ref={this.setWrapperRef9}>
                                                        <div className="box">

                                                            <input type="text" id="searchParm_availead" autoComplete="off" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} />
                                                            <span onClick={this.resetsearchParm}>(X)</span>
                                                            <ul>
                                                                {addressDWavailead}
                                                            </ul>

                                                            {(this.state.searchMsg) ? 'No suggestions available' : ''}
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Unit</th>
                                                {/* <th>Client Name <Link to='#'  id="searchClick" data-toggle="collapse" data-target="#cnsearch1" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="cnsearch1" className="collapse table-collapse client-collapse">
												<div className="box">

												<input type="text" id="searchParm" autoComplete="off" className="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
									
											
													{(this.state.searchMsg)?'No suggestions available':''}
												</div>
												</div>
												</th> */}
                                                <th>Price</th>
                                                <th>Layout</th>
                                                <th>Split</th>
                                                <th></th>
                                                <th style={{ textAlign: 'center' }}>Accept</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isAvailShowingExist && !this.state.linkingLoader && availShowingItems) ? availShowingItems : (!isAvailShowingExist && !this.state.linkingLoader) ? <tr><td colSpan="9">You don’t have any available leads yet</td></tr> : ''}
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>


                    <div className="modal fade requestBox" id="cancelSchudling" role="dialog">
                        <div className="modal-dialog">


                            <div className="modal-content">
                                <div className="modal-header">

                                    <h4 className="modal-title">Cancel a showing</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-horizontal">
                                        <p className="title"><strong>ARE YOU SURE YOU WANT TO CANCEL YOUR SHOWING ?  </strong></p>
                                        <p>{this.state.s_showingAddress}, {this.state.s_showing_unit}</p>
                                        <div className="form-group row" style={{ fontSize: '14px', marginTop: '0' }}>
                                            <div className="control-label col-sm-4 light-text">
                                                <strong>{Moment(this.state.s_showingTime, ['h:mm:ss']).format('hh:mm A')}</strong>
                                            </div>
                                            <div className="control-label col-sm-8 light-text">
                                                <strong>{Moment(this.state.s_showingDate).format('dddd, MMMM Do')}</strong>
                                            </div>
                                        </div>
                                        <div className="form-group row">

                                            <div className="control-label col-sm-4">
                                                <button type="button" className="btn btn-default" data-toggle="modal" onClick={this.confirmCancelShowing} >Yes</button>
                                            </div>
                                            <div className="control-label col-sm-4">
                                                <button type="button" id="closeCancelShowing" className="btn btn-small" data-dismiss="modal">No</button>
                                            </div>

                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>
                    </div>



                    {/* confirm for accept showing */}
                    <div className="modal fade requestBox" id="acceptSchudling" role="dialog">
                        <div className="modal-dialog">


                            <div className="modal-content">
                                <div className="modal-header">

                                    <h4 className="modal-title">Accept a showing</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-horizontal">
                                        <p className="title"><strong>ARE YOU SURE YOU WANT TO ACCEPT THIS SHOWING ?  </strong></p>
                                        {/* <p>548 W 114th St, Unit 3D</p>
                                            <div className="form-group row" style={{fontSize:'14px', marginTop:'0'}}>
                                                    <div className="control-label col-sm-4 light-text">
                                                      <strong>3:30 pm</strong>
                                                    </div>
                                                    <div className="control-label col-sm-8 light-text">
                                                    <strong>Tuesday, February 5th </strong>
                                                    </div>                                           
                                        </div> */}
                                        <div className="form-group row">

                                            <div className="control-label col-sm-4">
                                                <button type="button" className="btn btn-default" data-toggle="modal" data-target="#CallModal" onClick={this.confirmAccept} >Yes</button>
                                            </div>
                                            <div className="control-label col-sm-4">
                                                <button type="button" id="closeAcceptSchudling" className="btn btn-small" data-dismiss="modal">No</button>
                                            </div>

                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>
                    </div>


                    {/* confirm for accept showing before 30 min */}
                    <div className="modal fade requestBox" id="ReAssignShowing" role="dialog">
                        <div className="modal-dialog">


                            <div className="modal-content">
                                <div className="modal-header">

                                    <h4 className="modal-title">Confirm showing</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-horizontal">
                                        <p className="title"><strong>ARE YOU SURE YOU WANT TO CONFIRM THIS SHOWING ?  </strong></p>
                                        {/* <p>548 W 114th St, Unit 3D</p>
                                            <div className="form-group row" style={{fontSize:'14px', marginTop:'0'}}>
                                                    <div className="control-label col-sm-4 light-text">
                                                      <strong>3:30 pm</strong>
                                                    </div>
                                                    <div className="control-label col-sm-8 light-text">
                                                    <strong>Tuesday, February 5th </strong>
                                                    </div>                                           
                                        </div> */}
                                        <div className="form-group row">

                                            <div className="control-label col-sm-4">
                                                <button type="button" className="btn btn-default" data-toggle="modal" data-target="#CallModal" onClick={this.confirmShowing} >Yes</button>
                                            </div>
                                            <div className="control-label col-sm-4">
                                                <button type="button" id="closeConfirmSchudling" className="btn btn-small" data-dismiss="modal">No</button>
                                            </div>

                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>
                    </div>

                    <Modal open={open} onClose={this.onCloseModal} >
                        <div className="modal-header" >
                            <h4 className="modal-title">Showing Detail</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.onCloseModal}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-horizontal">
                                <div className="form-group row">
                                    <div className="control-label col-sm-12" >
                                        <label>Address: </label><span style={{ fontSize: '12px' }}>{this.state.showingAddressconfirm}</span>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="control-label col-sm-6">
                                        <label>Unit: </label> <span style={{ fontSize: '12px' }}>{this.state.showingunitconfirm}</span>

                                    </div>

                                    <div className="control-label col-sm-6">
                                        {(this.state.confirm_split != "undefined") ? <React.Fragment><label>Split: </label><span style={{ fontSize: '12px' }}>{this.state.confirm_split}</span></React.Fragment> : <React.Fragment><label>Split: </label></React.Fragment>}

                                    </div>

                                </div>


                                <div className="form-group row">
                                    <div className="control-label col-sm-6">
                                        <label>Time:</label> <span style={{ fontSize: '12px' }}>{this.state.showing_time_confirm}</span>

                                    </div>
                                    <div className="control-label col-sm-6">
                                        <label>Date: </label> <span style={{ fontSize: '12px' }}>{this.state.showing_date_confirm}</span>

                                    </div>
                                </div>


                                <div className="form-group row">
                                    <div className="control-label col-sm-12">
                                        <label>Client Name:</label> <span style={{ fontSize: '12px' }}>{this.state.client_name_confirm}</span>

                                    </div>

                                </div>


                                <div className="form-group row">
                                    <div className="control-label col-sm-12">

                                        <label>Client number: </label><span style={{ fontSize: '12px' }}> {this.state.client_phone_confirm}</span>

                                    </div>


                                </div>

                                <div className="form-group row">
                                    <div className="control-label col-sm-12" >
                                        <label>Client Email: </label> <span style={{ fontSize: '12px' }}>{this.state.client_email_confirm}</span>

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="control-label col-sm-12">
                                        {(this.state.confirm_notes != "undefined") ? <React.Fragment><label>Notes: </label><span style={{ fontSize: '12px' }}>{this.state.confirm_notes}</span></React.Fragment> : <React.Fragment><label>Notes: </label></React.Fragment>}


                                    </div>

                                </div>

                            </div>

                        </div>
                    </Modal>

                    <Modal open={open_mylead} onClose={this.onClosemyleadModal} >
                        <div className="modal-header" >
                            <h4 className="modal-title">Showing Detail</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.onClosemyleadModal}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-horizontal">
                                <div className="form-group row">
                                    <div className="control-label col-sm-12" >
                                        <label>Address:</label> <span style={{ fontSize: '12px' }}>{this.state.showingAddressmylead}</span>

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="control-label col-sm-6">
                                        <label>Unit:</label> <span style={{ fontSize: '12px' }}>{this.state.showingunitmylead}</span>

                                    </div>

                                    <div className="control-label col-sm-6">
                                        {(this.state.mylead_split != "undefined") ? <React.Fragment><label>Split:</label> <span style={{ fontSize: '12px' }}>{this.state.mylead_split}</span></React.Fragment> : <React.Fragment><label>Split:</label></React.Fragment>}


                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="control-label col-sm-6">
                                        <label>Time:</label> <span style={{ fontSize: '12px' }}>{this.state.showing_time_mylead}</span>

                                    </div>
                                    <div className="control-label col-sm-6">
                                        <label>Date:</label> <span style={{ fontSize: '12px' }}>{this.state.showing_date_mylead}</span>

                                    </div>
                                </div>


                                <div className="detail-box row">
                                    <div className="detail-box col-sm-6">
                                        <label>Bedrooms:</label> <span style={{ fontSize: '12px' }}>{this.state.mylead_bedrooms}</span>

                                    </div>
                                    <div className="detail-box col-sm-6">
                                        <label>Bathrooms:</label> <span style={{ fontSize: '12px' }}>{this.state.mylead_bathrooms}</span>

                                    </div>


                                </div>

                                <div className="form-group row">
                                    <div className="control-label col-sm-12" >
                                        <label>Price:</label> <span style={{ fontSize: '12px' }}>
                                        {this.state.mylead_price?'$'+this.state.mylead_price.toLocaleString():'$' +this.state.mylead_price}
                                        
                                        
                                        </span>

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="control-label col-sm-12" >
                                        {(this.state.mylead_notes != "undefined") ? <React.Fragment> <label>Notes:</label> <span style={{ fontSize: '12px' }}>{this.state.mylead_notes}</span></React.Fragment> : <React.Fragment> <label>Notes:</label></React.Fragment>}


                                    </div>
                                </div>

                            </div>

                        </div>
                    </Modal>

                    <Modal open={open_availead} onClose={this.onCloseavaileadModal} >
                        <div className="modal-header" >
                            <h4 className="modal-title">Showing Detail</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.onCloseavaileadModal}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-horizontal">
                                <div className="form-group row">
                                    <div className="control-label col-sm-12" >
                                        <label>Address:</label>  <span style={{ fontSize: '12px' }}>{this.state.showingAddressavailead}</span>

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="control-label col-sm-6">
                                        <label>Unit:</label> <span style={{ fontSize: '12px' }}>{this.state.showingunitavailead}</span>
                                        <div>

                                        </div>
                                    </div>

                                    <div className="control-label col-sm-6">
                                        {(this.state.availead_split != "undefined") ? <React.Fragment> <label>Split: </label> <span style={{ fontSize: '12px' }}>{this.state.availead_split}</span></React.Fragment> : <React.Fragment> <label>Split:</label></React.Fragment>}


                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="control-label col-sm-6">
                                        <label>Time: </label> <span style={{ fontSize: '12px' }}>{this.state.showing_time_availead}</span>

                                    </div>
                                    <div className="control-label col-sm-6">
                                        <label>Date: </label> <span style={{ fontSize: '12px' }}>{this.state.showing_date_availead}</span>

                                    </div>
                                </div>


                                <div className="detail-box row">
                                    <div className="detail-box col-sm-6">
                                        <label>Bedrooms:</label> <span style={{ fontSize: '12px' }}>{this.state.availead_bedrooms}</span>

                                    </div>
                                    <div className="detail-box col-sm-6">
                                        <label>Bathrooms: </label> <span style={{ fontSize: '12px' }}>{this.state.availead_bathrooms}</span>

                                    </div>


                                </div>

                                <div className="form-group row">
                                    <div className="control-label col-sm-12" >
                                        <label>Price: </label> <span style={{ fontSize: '12px' }}>
                                        {this.state.availead_price?'$' +this.state.availead_price.toLocaleString():'$' +this.state.availead_price}
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="control-label col-sm-12" >
                                        {(this.state.availead_notes != "undefined") ? <React.Fragment> <label>Notes:</label> <span style={{ fontSize: '12px' }}>{this.state.availead_notes}</span></React.Fragment> : <React.Fragment> <label>Notes:</label></React.Fragment>}


                                    </div>
                                </div>

                            </div>

                        </div>
                    </Modal>

                    {/* <Modal open={this.state.openChat} little>
					<AgentChatbox onClose={this.closeChat} />
					</Modal> 

					<div className="OpenChat">
						<Link to onClick={this.openChat}><i class="fa fa-comments-o"></i></Link>
					</div>      */}


                </main>
            </div>

        );
    }
}
export default Agentshowings;