import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/ManagerHeader';
import Sidebar from '../common/ManagerSidebar';
import { getManagerToken, getApiData, postApiData } from '../../services/Api';
import { Redirect } from 'react-router';
import Moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addDays from "date-fns/add_days";
import set_hours from "date-fns/set_hours";
import set_minutes from "date-fns/set_minutes";
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import { validatePhone, socialSecurityNumber } from '../../services/validation';

import Modal from "react-responsive-modal";
class ManagerShowings extends React.Component {

    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}
class Content extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isAvailShowingExist: '',
			availShowingData: [],
			isShowingExist: '',
			showingData: [],
			clientList: [],
			confirm_clientList: [],
			avail_clientList: [],
			listingCreator: [],
			addressData_availead: [],
			addressConfData: [],
			client_contact_number: '',
			searchConfClient: '',
			split: '',
			client_names: '',
			phone_number: '',
			searchParm: "",
			searchAddressvalue: '',
			client_phone_number: '',
			clientName: '',
			showingTime: '',
			showingDate: '',
			clientShowingId: '',
			addressData: [],
			upperSearchWhere: {},
			availead_SearchWhere: {},
			where: {},
			open: false,
			open_availead: false,
			selected_agent_name: 'JOSIAH HYATT', //default agent name,
			searchParm: '',
			agent_name: 0,
			agentList: [],
			agent_id: 0,
			agenterror: '',
			clientphone: [],
			addressDW: [],
			addressDWconfirmshowing: [],
			c_agentName: '',
			c_clientName: '',
			c_showingTime: '',
			c_showingDate: '',
			c_clientShowingId: '',
			c_agentList: [],
			address_value: '',
			unit_value: '',
			unit_name: '',
			showingAddress: '450 Amsterdam Ave',
			showingUnit: '4F',
			c_prevAgentEmail: '',
			c_clientEmail: '',
			client_fill_email: '',
			upperSearchDate: '',
			mymsg: '',
			myerrormsg: '',
			Assignflag: '',
			time: '',
			startDate: new Date(),
			client_name: '',
			listing_name: '',
			newShowing_agentList: [],
			newShowing_agent_name: 0,
			unitListingCreator: [],
			client_id: 0,
			currentTime: new Date().getHours() + 1,
			block_showing: false,
			upperSearchTime: '',
			availead_SearchDate: '',
			availeadSearchTime: '',
			searchAvailClient: ''


		};
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.setWrapperRef1 = this.setWrapperRef1.bind(this);
		this.setWrapperRef2 = this.setWrapperRef2.bind(this);
		this.setWrapperRef3 = this.setWrapperRef3.bind(this);
		this.setWrapperRef10 = this.setWrapperRef10.bind(this);
		this.setWrapperRef7 = this.setWrapperRef7.bind(this);
		this.setWrapperRef8 = this.setWrapperRef8.bind(this);
		this.setWrapperRef9 = this.setWrapperRef9.bind(this);
		this.setWrapperRef11 = this.setWrapperRef11.bind(this);
		this.confirmAssignment = this.confirmAssignment.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.updateTimeKitForShowing = this.updateTimeKitForShowing.bind(this);
		this.splithandleChange = this.splithandleChange.bind(this);
		this.handleUpperSearchDate = this.handleUpperSearchDate.bind(this);
		this.handleUpperSearchTime = this.handleUpperSearchTime.bind(this);

		this.createWhere();
	}
	ClientHeadClick() {
		document.getElementById('search').classList.remove("show");
		//document.getElementById('confirmsearch').classList.remove("show");
	}


	async createWhere() {
		this.state.where = {};
		var inValue = "";

		if (this.state.searchListingnId != '') {
			this.state.where.searchListingnId = this.state.searchListingnId;
		}

		this.getAvailableShowing();
	}

	componentWillMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		window.scroll(0, 0);
		this.setState({
			linkingLoader: true,
		}, () => {
			this.listingOpt();
			this.getAddressData();
			this.getConfirmed_AddressData();
			this.getAvailable_AddressData();
			this.getAvailableShowing();
			this.agentOpt();
			this.clientOpt();
			this.clientConfOpt();
			this.clientAvailOpt();
			this.getShowingList();
		})

		console.log(Moment(new Date()).add(5, 'days'));


	}
	componentWillUnmount() {
		document.addEventListener('mousedown', this.handleClickOutside);

	}
	getAvailable_AddressData = () => {

		let listingWhere = [{
			url: "/showings/showing/avashowingAdd"
		}];

		getApiData(listingWhere, true).then(res => {

			this.setState({
				addressData_availead: res.data
			}, () => {
			})
		}).catch(err => {


		})
	}
	setWrapperRef1(node) {
		this.wrapperRef1 = node;
	}

	setWrapperRef2(node) {
		this.wrapperRef2 = node;
	}
	setWrapperRef3(node) {
		this.wrapperRef3 = node;
	}
	setWrapperRef10(node) {
		this.wrapperRef10 = node;
	}
	setWrapperRef7(node) {
		this.wrapperRef7 = node;
	}
	setWrapperRef8(node) {
		this.wrapperRef8 = node;
	}
	setWrapperRef9(node) {
		this.wrapperRef9 = node;
	}
	setWrapperRef11(node) {
		this.wrapperRef11 = node;
	}
	splithandleChange(event) {
		this.setState({ split: event.target.value });
	}
	handleUpperSearchDate = (date) => {
		this.setState({ upperSearchDate: date }, function () {
			document.getElementById('c_date_statusKey').classList.add("active");
			document.getElementById('Date').classList.remove("show");
			this.createUpperWhere();
			//  this.createmyleadWhere();
		});

	}
	handleUpperSearchTime = (time) => {
		this.setState({ upperSearchTime: time }, function () {

			document.getElementById('c_time_statusKey').classList.add("active");
			document.getElementById('Time').classList.remove("show");
			this.createUpperWhere();
		});

	}
	handleavaileadSearchDate = (date) => {
		this.setState({ availead_SearchDate: date }, function () {

			document.getElementById('a_date_statusKey').classList.add("active");
			this.createavaileadWhere()
		});
	}
	handleavaileadSearchTime = (time) => {
		this.setState({ availeadSearchTime: time }, function () {
			document.getElementById('a_time_statusKey').classList.add("active");
			this.createavaileadWhere();
		});

	}
	handleClickOutside(event) {

		if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
			document.getElementById('Date').classList.remove("show");

		}
		if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {

			document.getElementById('Time').classList.remove("show");
		}
		if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {

			document.getElementById('search').classList.remove("show");
		}
		if (this.wrapperRef10 && !this.wrapperRef10.contains(event.target)) {

			document.getElementById('cnsearch').classList.remove("show");
		}
		if (this.wrapperRef7 && !this.wrapperRef7.contains(event.target)) {

			document.getElementById('Date_availead').classList.remove("show");
		}
		if (this.wrapperRef8 && !this.wrapperRef8.contains(event.target)) {

			document.getElementById('availead_Time').classList.remove("show");
		}
		if (this.wrapperRef9 && !this.wrapperRef9.contains(event.target)) {

			document.getElementById('search_availead').classList.remove("show");
		}
		if (this.wrapperRef11 && !this.wrapperRef11.contains(event.target)) {

			document.getElementById('cnsearch1').classList.remove("show");
		}



	}
	async createUpperWhere() {
		this.state.upperSearchWhere = {};
		if (this.state.upperSearchDate != '') {
			let upperSearchDate = Moment(this.state.upperSearchDate).format('YYYY-MM-DD');
			this.state.upperSearchWhere.showing_date = upperSearchDate;
		}

		if (this.state.upperSearchTime != '') {
			let upperSearchTime = Moment(this.state.upperSearchTime, ['h:mm:ss']).format('HH:mm:ss');
			this.state.upperSearchWhere.showing_time = upperSearchTime;
		}

		if (this.state.searchAddressvalue != '') {
			//  let upperSearchTime = Moment(this.state.upperSearchTime,['h:mm:ss']).format('HH:mm:ss');
			this.state.upperSearchWhere.showingAddress = this.state.searchAddressvalue;
		}

		if (this.state.searchClientvalue != '') {
			//  let upperSearchTime = Moment(this.state.upperSearchTime,['h:mm:ss']).format('HH:mm:ss');
			this.state.upperSearchWhere.client_name = this.state.searchClientvalue;
		}
		this.getShowingList();
	}
	async createavaileadWhere() {
		this.state.availead_SearchWhere = {};


		if (this.state.availead_SearchDate != '') {
			let availead_SearchDate = Moment(this.state.availead_SearchDate).format('YYYY-MM-DD');
			this.state.availead_SearchWhere.showing_date = availead_SearchDate;
			document.getElementById('Date_availead').classList.remove("show");
		}
		if (this.state.availeadSearchTime != '') {
			let availeadSearchTime = Moment(this.state.availeadSearchTime, ['h:mm:ss']).format('HH:mm:ss');
			this.state.availead_SearchWhere.showing_time = availeadSearchTime;
			document.getElementById('availead_Time').classList.remove("show");
		}

		if (this.state.searchAddressvalue != '') {
			//  let upperSearchTime = Moment(this.state.upperSearchTime,['h:mm:ss']).format('HH:mm:ss');
			this.state.availead_SearchWhere.showingAddress = this.state.searchAddressvalue;
		}
		this.getAvailableShowing();
	}
	resetsearchParm = () => {
		this.setState({
			where: {},
			upperSearchWhere: {},
			upperSearchTime: '',
			upperSearchDate: '',
			availead_SearchDate: '',
			availeadSearchTime: '',
			searchParm: "",
			searchParm_mylead: "",
			searchParm_availead: "",
			searchParm_client: "",
			searchAddressvalue: '',
			searchClientvalue: '',
			myerrormsg: "",
		}, () => {
			if (document.getElementById('searchParm'))
				document.getElementById('searchParm').value = '';
			if (document.getElementById('searchParm_availead'))
				document.getElementById('searchParm_availead').value = '';
			if (document.getElementById('searchParm_client'))
				document.getElementById('searchParm_client').value = '';
			if (document.getElementById('c_time_statusKey'))
				document.getElementById('c_time_statusKey').classList.remove("active");
			if (document.getElementById('c_address_searchClick'))
				document.getElementById('c_address_searchClick').classList.remove("active");
			if (document.getElementById('c_client_searchClick'))
				document.getElementById('c_client_searchClick').classList.remove("active");
			if (document.getElementById('c_date_statusKey'))
				document.getElementById('c_date_statusKey').classList.remove("active");
			if (document.getElementById('searchClick_mylead'))
				document.getElementById('searchClick_mylead').classList.remove("active");
			if (document.getElementById('searchClick_availead'))
				document.getElementById('searchClick_availead').classList.remove("active");
			if (document.getElementById('search'))
				document.getElementById('search').classList.remove("show");
			if (document.getElementById('search_mylead'))
				document.getElementById('search_mylead').classList.remove("show");
			if (document.getElementById('search_availead'))
				document.getElementById('search_availead').classList.remove("show");
			if (document.getElementById('cnsearch'))
				document.getElementById('cnsearch').classList.remove("show");
			if (document.getElementById('a_date_statusKey'))
				document.getElementById('a_date_statusKey').classList.remove("active");
			if (document.getElementById('a_time_statusKey'))
				document.getElementById('a_time_statusKey').classList.remove("active");
			if (document.getElementById('searchkey_confirm'))
				document.getElementById('searchkey_confirm').value = '';

			if (document.getElementById('Date')) {
				document.getElementById('Date').classList.remove("active");
				document.getElementById('Date').classList.remove("show");
			}
			if (document.getElementById('Time')) {
				document.getElementById('Time').classList.remove("active");
				document.getElementById('Time').classList.remove("show");
				document.getElementById('Date_availead').classList.remove("show");
				document.getElementById('availead_Time').classList.remove("show");
			}
			if (document.getElementById('c_Availclient_searchClick')) {
				document.getElementById('c_Availclient_searchClick').classList.remove("active");
				document.getElementById('searchAvail_client').value = '';
				document.getElementById('cnsearch1').classList.remove("show");
			}
			this.getShowingList();
			this.createUpperWhere();
			this.createavaileadWhere();

		});
	}
	async getAvailableShowing() {

		let clientRelWhere = '';

		if (this.state.searchAddressvalue) {

			clientRelWhere = [{
				url: "/showings/availableShowing",
				urlParms: "?type=" + this.state.searchAddressvalue
			}];
		}
		else {
			clientRelWhere = [{
				url: "/showings/availableShowing"
			}];
		}
		var len = Object.keys(this.state.availead_SearchWhere);
		if (len.length > 0) {
			clientRelWhere[0].where = this.state.availead_SearchWhere;
		}

		getApiData(clientRelWhere, true).then(res => {
			this.setState({ availShowingData: res.data });
			if (res.data.length) {
				this.setState({ isAvailShowingExist: true });
			}
			else {
				this.setState({ isAvailShowingExist: false });
			}
		}).catch(error => {

		});
	}

	search = (event) => {

		var key = event.target.id;

		switch (key) {

			case 'searchParm':
				this.setState({ searchParm: event.target.value });
				//if(this.state.searchParm.length>1){	
				var value = this.state.searchParm.trim();
				if (event.key == 'Enter') {
					this.getData(value);

				} else if (value == '') {
					this.state.addressConfData.forEach(function (address, index) {
						address.flag = 0;
					});
					this.setState({ searchMsg: false });
				} else {
					var len = this.state.addressConfData.length;
					var count = 0;
					var vflag = 0;
					this.state.addressConfData.forEach(function (address, index) {

						if (address.listing_info_address.trim().toLowerCase().includes(value.trim().toLowerCase())) {
							address.flag = 1;
							vflag = 1;
							count = count + 1;
						} else {
							address.flag = 0;
							if (vflag != 1)
								vflag = 0
							count = count + 1;
						}

					});

					if (len == count) {
						if (vflag == 0) {
							this.setState({ searchMsg: true });

						} else {
							this.setState({ searchMsg: false });

						}


					}


				}

				//}
				break;

			case 'searchParm_confirm':
				this.setState({ searchParm: event.target.value });
				//if(this.state.searchParm.length>1){	
				var value = this.state.searchParm.trim();
				if (event.key == 'Enter') {
					this.getData(value);

				} else if (value == '') {
					this.state.addressData.forEach(function (address, index) {
						address.flag = 0;
					});
					this.setState({ searchMsg: false });
				} else {
					var len = this.state.addressData.length;
					var count = 0;
					var vflag = 0;
					this.state.addressData.forEach(function (address, index) {

						if (address.listing_info_address.trim().toLowerCase().includes(value.trim().toLowerCase())) {
							address.flag = 1;
							vflag = 1;
							count = count + 1;
						} else {
							address.flag = 0;
							if (vflag != 1)
								vflag = 0
							count = count + 1;
						}

					});

					if (len == count) {
						if (vflag == 0) {
							this.setState({ searchMsg: true });

						} else {
							this.setState({ searchMsg: false });

						}


					}


				}

				//}
				break;

			case 'searchParm_agent':
				this.setState({ searchParm: event.target.value });
				//if(this.state.searchParm.length>1){	
				var value = this.state.searchParm.trim();
				if (event.key == 'Enter') {
					this.getData(value);

				} else if (value == '') {

					this.state.c_agentList.forEach(function (agent, index) {
						if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
							agent.flag = 1;
						} else {
							agent.flag = 0;
						}
					});

					this.setState({ searchMsg: false });
				} else {
					var len = this.state.c_agentList.length;
					var count = 0;
					var vflag = 0;
					this.state.c_agentList.forEach(function (agent, index) {

						if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
							agent.flag = 1;
							vflag = 1;
							count = count + 1;
						} else {
							agent.flag = 0;
							if (vflag != 1)
								vflag = 0
							count = count + 1;
						}

					});

					if (len == count) {
						if (vflag == 0) {
							this.setState({ searchMsg: true });

						} else {
							this.setState({ searchMsg: false });

						}


					}


				}
			case 'searchConf_client':
				this.setState({ searchConfClient: event.target.value });
				var value = this.state.searchConfClient.trim();
				if (event.key == 'Enter') {
					this.getData(value, event.target.id);

				} else if (value == '') {
					this.state.confirm_clientList.forEach(function (client, index) {
						if (client.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
							client.flag = 1;
						} else {
							client.flag = 0;
						}
					});

					this.setState({ searchMsg: false });
				} else {
					var len = this.state.confirm_clientList.length;
					var count = 0;
					var vflag = 0;
					this.state.confirm_clientList.forEach(function (client, index) {

						if (client.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
							client.flag = 1;
							vflag = 1;
							count = count + 1;
						} else {
							client.flag = 0;
							if (vflag != 1)
								vflag = 0
							count = count + 1;
						}

					});

					if (len == count) {
						if (vflag == 0) {
							this.setState({ searchMsg: true });

						} else {
							this.setState({ searchMsg: false });

						}


					}


				}

			//}
			case 'searchAvail_client':
				this.setState({ searchAvailClient: event.target.value });
				var value = this.state.searchAvailClient.trim();
				if (event.key == 'Enter') {
					this.getData(value, event.target.id);

				} else if (value == '') {
					this.state.avail_clientList.forEach(function (client, index) {
						if (client.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
							client.flag = 1;
						} else {
							client.flag = 0;
						}
					});

					this.setState({ searchMsg: false });
				} else {
					var len = this.state.avail_clientList.length;
					var count = 0;
					var vflag = 0;
					this.state.avail_clientList.forEach(function (client, index) {

						if (client.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
							client.flag = 1;
							vflag = 1;
							count = count + 1;
						} else {
							client.flag = 0;
							if (vflag != 1)
								vflag = 0
							count = count + 1;
						}

					});

					if (len == count) {
						if (vflag == 0) {
							this.setState({ searchMsg: true });

						} else {
							this.setState({ searchMsg: false });

						}


					}


				}
				break;
			case 'searchParm_availead':
				this.setState({ searchParm_availead: event.target.value });

				var value = event.target.value.trim();
				if (event.key == 'Enter') {
					this.getData(value, key);

				} else if (value == '') {
					this.state.addressData_availead.forEach(function (address, index) {
						address.flag = 0;
					});
					this.setState({ searchMsg: false });
				} else {
					var len = this.state.addressData_availead.length;
					var count = 0;
					var vflag = 0;
					this.state.addressData_availead.forEach(function (address, index) {

						if (address.listing_info_address.trim().toLowerCase().includes(value.trim().toLowerCase())) {
							address.flag = 1;
							vflag = 1;
							count = count + 1;
						} else {
							address.flag = 0;
							if (vflag != 1)
								vflag = 0
							count = count + 1;
						}

					});

					if (len == count) {
						if (vflag == 0) {
							this.setState({ searchMsg: true });

						} else {
							this.setState({ searchMsg: false });

						}


					}


				}
		}


	}

	async getShowingList() {
		this.setState({ linkingLoader: true });
		let clientRelWhere = '';

		if (this.state.confirmagentvalue) {
			clientRelWhere = [{
				url: "/showings/agentShowingForManager",
				urlParms: "?type=" + this.state.confirmagentvalue
			}];
		}
		else {
			clientRelWhere = [{
				url: "/showings/agentShowingForManager"
			}];
		}
		var len = Object.keys(this.state.upperSearchWhere);
		if (len.length > 0) {
			clientRelWhere[0].where = this.state.upperSearchWhere;
		}

		getApiData(clientRelWhere, true).then(res => {
			console.log('showingData', res.data)

			this.setState({ showingData: res.data });

			if (res.data.length > 0) {
				this.setState({
					isShowingExist: true,
					linkingLoader: false
				});
			}
			else {
				this.setState({ isShowingExist: false, linkingLoader:false });
			}
		}).catch(error => {
			this.setState({ linkingLoader: false });
		});
	}

	setAssign(clientName, showing_time, showing_date, clientShowingId, agentName, prevAgentEmail, clientEmail, showingAddress, showingUnit, address_value, unit_value, client_fullname, client_phone_number, flag, timekit_id) {
		this.setState({ agenterror: '', agent_search_id: '', agent_name: '' });
		if (address_value != "") {
			this.setState({ address_value: address_value });
		}
		if (unit_value != "") {
			this.setState({ unit_value: unit_value });
		}
		if (client_fullname != "") {
			this.setState({ client_fullname: client_fullname });
		}
		if (client_phone_number != "") {
			this.setState({ client_phone_number: client_phone_number });
		}
		if (clientName != "") {
			this.setState({ clientName: clientName });
		}
		if (clientEmail != "") {
			this.setState({ c_clientEmail: clientEmail });
		}
		if (showing_time != "") {
			this.setState({ showingTime: showing_time });
		}
		if (showing_date != "") {
			this.setState({ showingDate: showing_date });
		}
		if (clientShowingId != "") {
			this.setState({ c_clientShowingId: clientShowingId });
		}
		if (prevAgentEmail != "") {
			this.setState({ c_prevAgentEmail: prevAgentEmail });
		}
		if (agentName != "") {
			this.setState({ c_agentName: agentName });
		}
		if (showingAddress == null) {
			this.setState({ showingAddress: address_value });
		}
		else {
			this.setState({ showingAddress: showingAddress });
		}
		if (showingUnit == null) {
			this.setState({ showingUnit: unit_value });
		}
		else {
			this.setState({ showingUnit: showingUnit });
		}

		this.setState({ Assignflag: flag, timekit_id: timekit_id });

	}

	setReassign(clientName, clientfullname, showing_time, showing_date, clientShowingId, agentName, prevAgentEmail, clientEmail, showingAddress, address_value, showingUnit, unit_value) {
		this.setState({ c_agenterror: '', c_agent_search_id: '', agent_name: '' });
		if (clientName == null) {
			this.setState({ c_clientName: clientfullname });

		}
		else {
			this.setState({ c_clientName: clientName });

		}
		if (clientEmail != "") {
			this.setState({ c_clientEmail: clientEmail });
		}
		if (showing_time != "") {
			this.setState({ c_showingTime: showing_time });
		}
		if (showing_date != "") {
			this.setState({ c_showingDate: showing_date });
		}
		if (clientShowingId != "") {
			this.setState({ c_clientShowingId: clientShowingId });
		}
		if (agentName != "") {

			this.setState({ c_agentName: agentName });
		}
		if (prevAgentEmail != "") {
			this.setState({ c_prevAgentEmail: prevAgentEmail });
		}
		if (showingAddress == null) {
			this.setState({ c_showingAddress: address_value });

		}
		else {
			this.setState({ c_showingAddress: showingAddress });

		}
		if (showingUnit == null) {
			this.setState({ c_showingUnit: unit_value });
			//console.log('showingUnit',showingUnit);

		}
		else {
			this.setState({ c_showingUnit: showingUnit });
		}


	}

	async clientOpt() {
		let agentParam = [{
			url: "/showings/addShowing/clientSearch"

		}];
		getApiData(agentParam, true).then(res => {
			this.setState({ 'clientList': res.data });
		}).catch(error => {
			if (error.response) {
				this.setState({ myerrormsg: error.response.data.error.message });
			}
		});
	}
	async clientConfOpt() {
		let agentParam = [{
			url: "/showings/clientName",
			where: {
				'agent_id': '0'
			}

		}];
		getApiData(agentParam, true).then(res => {
			this.setState({ 'confirm_clientList': res.data });
		}).catch(error => {
			if (error.response) {
				this.setState({ myerrormsg: error.response.data.error.message });
			}
		});
	}
	async clientAvailOpt() {
		let agentParam = [{
			url: "/showings/clientName",
			where: {
				'agent_id': '0',
				'avail_client': '1'
			}

		}];
		getApiData(agentParam, true).then(res => {
			this.setState({ 'avail_clientList': res.data });
		}).catch(error => {
			if (error.response) {
				this.setState({ myerrormsg: error.response.data.error.message });
			}
		});
	}

	async listingOpt() {
		this.setState({
			linkingLoader:true
		})
		let agentParam = [{
			url: "/showings/listingCreator"
		}];
		getApiData(agentParam, true).then(res => {
			this.setState({ 'listingCreator': res.data,linkingLoader:false });
		}).catch(error => {
			if (error.response) {
				this.setState({ myerrormsg: error.response.data.error.message,linkingLoader:false });
			}
		});
	}
	async agentOpt() {
		let agentParam = [{
			url: "/agent/agentDD"
		}];
		getApiData(agentParam, true).then(res => {
			this.setState({ 'agentList': res.data, 'c_agentList': res.data, 'newShowing_agentList': res.data });
		}).catch(error => {

		});
	}

	handleAgentSearch = event => {
		var selected_agent_name = event.target.getAttribute('name')

		console.log('selected_agent_name', selected_agent_name);

		this.setState({
			selected_agent_name: selected_agent_name
		})
		var key = event.target.id;
		console.log("key--", key);
		switch (key) {
			case 'c_agent_search_id':
				this.setState({ searchParm: event.target.value, agent_name: event.target.value, agenterror: '' }, () => {
					//if(this.state.searchParm.length>-1){
					var value = this.state.searchParm;
					if (value != '') {

						this.state.c_agentList.forEach(function (agent, index) {
							if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
								agent.flag = 1;
							} else {
								agent.flag = 0;
							}
						});

					} else {
						document.getElementById('c_agent_search_id_values').style.display = 'none';
						this.state.c_agentList.forEach(function (agent, index) {
							agent.flag = 0;
						});

					}
				})


				//}
				break;
			case 'searchkey_agent_reassign':
				var value = event.target.value;
				let $this = this;
				this.setState({ agent_id: event.target.value }, () => {
					this.state.c_agentList.forEach(function (agent, index) {
						if (agent.id == value) {
							document.getElementById('c_agent_search_id_values').style.display = "none";
							document.getElementById('c_agent_search_id').value = agent.name;
							$this.setState({ agent_name: agent.name });
						} else {
							agent.flag = 0;
						}
					});
					document.getElementById('c_agent_search_id_values').style.display = "none";
				});
				break;
			case 'default':
				break;

		}

		document.getElementById('c_agent_search_id_values').style.display = "block";
	}


	handleAgentSearchAssign = event => {
		var selected_agent_name = event.target.getAttribute('name')
		this.setState({
			selected_agent_name: selected_agent_name
		})
		var key = event.target.id;

		switch (key) {
			case 'agent_search_id':
				this.setState({ searchParm: event.target.value, agent_name: event.target.value, agenterror: '' }, () => {
					//if(this.state.searchParm.length>-1){
					var value = this.state.searchParm;

					if (value != '') {

						this.state.agentList.forEach(function (agent, index) {
							if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
								agent.flag = 2;
								console.log("flag--", agent.flag);
							} else {
								agent.flag = 0;
								console.log("flag--", agent.flag);
							}
						});

					} else {
						console.log("else--");
						document.getElementById('agent_search_id_values').style.display = 'none';
						this.state.agentList.forEach(function (agent, index) {
							agent.flag = 0;
						});

					}
				})


				//}
				break;
			case 'searchkey_agent_assign':
				var value = event.target.value;
				let $this = this;
				this.setState({ agent_id: event.target.value }, () => {
					this.state.agentList.forEach(function (agent, index) {
						if (agent.id == value) {
							document.getElementById('agent_search_id_values').style.display = "none";
							document.getElementById('agent_search_id').value = agent.name;
							$this.setState({ agent_name: agent.name });
						} else {
							agent.flag = 0;
						}
					});
					document.getElementById('agent_search_id_values').style.display = "none";
				});
				break;
			case 'default':
				break;

		}

		document.getElementById('agent_search_id_values').style.display = "block";
	}

	handleEmailChange = event => {


		this.setState({ client_fill_email: { email: '' },client_id:'' });

		if (event.target.id == 'clientEmail_search_id') {

			this.setState({ custom_client_email: event.target.value });

		}
	}
	handleAgentSearchAssignNewShowing = event => {
		var selected_agent_name_newShowing = event.target.getAttribute('name')
		this.setState({
			selected_agent_name_newShowing: selected_agent_name_newShowing
		})
		var key = event.target.id;
		console.log("key--", key);
		switch (key) {
			case 'newShowing_agent_search_id':
				this.setState({ searchParm: event.target.value, newShowing_agent_name: event.target.value, agenterror: '' }, () => {
					//if(this.state.searchParm.length>-1){
					var value = this.state.searchParm;
					console.log("value--", value);
					if (value != '') {

						this.state.agentList.forEach(function (agent, index) {
							if (agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
								agent.flag = 3;
								console.log("flag--", agent.flag);
							} else {
								agent.flag = 0;
								console.log("flag--", agent.flag);
							}
						});

					} else {
						console.log("else--");
						document.getElementById('newShowing_agent_search_id_values').style.display = 'none';
						this.state.agentList.forEach(function (agent, index) {
							agent.flag = 0;
						});

					}
				})


				//}
				break;
			case 'searchkey_agent_assign_newShoiwng':
				var value = event.target.value;
				let $this = this;
				this.setState({ agent_id: event.target.value }, () => {
					this.state.agentList.forEach(function (agent, index) {
						if (agent.id == value) {
							document.getElementById('newShowing_agent_search_id_values').style.display = "none";
							document.getElementById('newShowing_agent_search_id').value = agent.name;
							$this.setState({ newShowing_agent_name: agent.name });
						} else {
							agent.flag = 0;
						}
					});
					document.getElementById('newShowing_agent_search_id_values').style.display = "none";
				});
				break;
			case 'default':
				break;

		}

		//    agent_search_id_values
		document.getElementById('newShowing_agent_search_id_values').style.display = "block";
	}

	confirmAssignment = () => {

		if (document.getElementById("agent_search_id").value != "") {
			console.log("-agent_search_id-", document.getElementById("agent_search_id").value);
			if (document.getElementById('agent_search_id_values').style.display == "none") {
				if (document.getElementById("searchkey_agent_assign").value >= 0) {
					let acceptShowing = [{
						url: "/showings/acceptShowing"

					}];
					var form = new FormData();
					form.append("agentId", document.getElementById('searchkey_agent_assign').value);
					form.append("clientShowingId", this.state.c_clientShowingId);

					postApiData(acceptShowing, form, true).then(res => {
						console.log("response--", res);
						console.log("response--", res.data.message);
						document.getElementById("myAssignClose").click();
						if (this.state.timekit_id != "" && this.state.timekit_id != null) {
							this.updateTimeKitForAction(this.state.c_clientShowingId, 'agent_id');
						} else {

							this.updateTimeKitForShowing(this.state.c_clientShowingId, 'true');
						}

						this.getShowingList();
						this.getAvailableShowing();
					}).catch(error => {

					});
				}
				else {
				
					this.setState({ agenterror: "Agent cannot be blank." });
				}
			}
			else {
				this.setState({ agenterror: "Agent not exists in the record." });
			}
		}
		else {
			
			this.setState({ agenterror: "Agent cannot be blank." });
		}

	}

	confirmReAssignment = () => {

		if (document.getElementById("c_agent_search_id").value != "") {
			if (document.getElementById('c_agent_search_id_values').style.display == "none") {
				if (document.getElementById("searchkey_agent_reassign").value >=0 ) {
					let acceptShowing = [{
						url: "/showings/reassignConfirmedShowings"

					}];
					var form = new FormData();
					form.append("agentId", document.getElementById('searchkey_agent_reassign').value);
					form.append("clientShowingId", this.state.c_clientShowingId);

					postApiData(acceptShowing, form, true).then(res => {
						console.log("response--", res);
						console.log("response--", res.data.message);
						if (res.data.success) {
							this.sendEmailToAgents(document.getElementById('searchkey_agent_reassign').value);
							this.updateTimeKitForAction(this.state.c_clientShowingId, 'agent_id');
							document.getElementById("ReAssignClose").click();
							this.getShowingList();
							this.getAvailableShowing();
						}

					}).catch(error => {

					});
				}
				else {
					
					this.setState({ c_agenterror: "Agent cannot be blank." });
				}
			}
			else {
				this.setState({ c_agenterror: "Agent not exists in the record." });
			}


		}
		else {
		
			this.setState({ c_agenterror: "Agent cannot be blank." });
		}
	}

	sendEmailToAgents = (newAgentId) => {
		let sendEmail = [{
			url: "/showings/emailToAgents"

		}];
		var form = new FormData();
		form.append("showingAddress", this.state.c_showingAddress);
		form.append("showingUnit", this.state.c_showingUnit);
		form.append("showingDate", this.state.c_showingDate);
		form.append("showingTime", this.state.c_showingTime);

		form.append("previousAgentEmail", this.state.c_prevAgentEmail);
		form.append("previousAgentName", this.state.c_agentName);
		form.append("newAgentId", newAgentId);
		form.append("clientEmail", this.state.c_clientEmail);
		form.append("clientName", this.state.c_clientName);


		postApiData(sendEmail, form, true).then(res => {
			console.log("response--", res);
			console.log("response--", res.data.message);
			if (res.data.success) {
				this.setState({ mymsg: res.data.message });
			}
			else {
				this.setState({ myerrormsg: res.data.message });
			}

		}).catch(error => {
			this.setState({ myerrormsg: 'There is an error.' });
		});
	}

	confirmCancelShowing = () => {
		this.setState({ 'linkingLoader': true });
		let cancelShowing = [{
			url: "/showings/cancelShowing"

		}];
		var form = new FormData();
		form.append("status", '3');
		form.append("clientShowingId", this.state.c_clientShowingId);
		this.sendEmailForCancellation();
		this.setState({ 'linkingLoader': false });
		postApiData(cancelShowing, form, true).then(res => {
			console.log("response--", res);
			console.log("response--", res.data.message);

			document.getElementById("closeCancelShowing").click();
			this.updateTimeKitForAction(this.state.c_clientShowingId, 'status');
			this.getShowingList();
			this.getAvailableShowing();
		}).catch(error => {

		});
	}

	sendEmailForCancellation = () => {
		let sendEmail = [{
			url: "/showings/sendEmailForCancellation"

		}];
		var form = new FormData();
		form.append("showingAddress", this.state.showingAddress);
		form.append("showingUnit", this.state.showingUnit);
		form.append("showingDate", this.state.showingDate);
		form.append("showingTime", this.state.showingTime);

		form.append("previousAgentEmail", this.state.c_prevAgentEmail);
		form.append("previousAgentName", this.state.c_agentName);
		form.append("clientEmail", this.state.c_clientEmail);
		form.append("clientName", this.state.clientName);
		form.append("Assignflag", this.state.Assignflag);				//check for reassing or assign



		postApiData(sendEmail, form, true).then(res => {
			console.log("response--", res);
			console.log("response--", res.data.message);
			if (res.data.success) {
				this.setState({ mymsg: res.data.message });
			}
			else {
				this.setState({ myerrormsg: res.data.message });
			}

		}).catch(error => {
			this.setState({ myerrormsg: 'There is an error.' });
		});
	}

	handleClientSearch = event => {
		var selected_client_name = event.target.getAttribute('name');

		this.setState({
			selected_client_name: selected_client_name,client_id:''
		})


		var key = event.target.id;
		switch (key) {
			case 'client_search_id':
				this.setState({ searchParm: event.target.value, client_name: event.target.value }, () => {
					//if(this.state.searchParm.length>-1){
					var value = this.state.searchParm;
					var len = this.state.clientList.length;
					var count = 0;
					var vflag = 0;
					if (value != '') {
						this.state.clientList.forEach(function (client, index) {
							if (client.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
								client.flag = 1;
								vflag = 1;
								count = count + 1;
							} else {
								client.flag = 0;
								if (vflag != 1)
									vflag = 0
								count = count + 1;
							}
						});
					} else {
						document.getElementById('client_search_id_values').style.display = 'none';
						this.state.clientList.forEach(function (client, index) {
							client.flag = 0;
						});

					} if (len == count) {
						if (vflag == 0) {
							// this.setState({searchMsg:true});
							document.getElementById('client_search_id_values').style.display = 'none';

						} else {
							this.setState({ searchMsg: false });

						}
					}
				})
				//}
				break;

			case 'searchkey_client':
				var value = event.target.value;
				let $this = this;
				this.setState({ agent_id: event.target.value }, () => {
					this.state.clientList.forEach(function (client, index) {
						if (client.id == value) {
							document.getElementById('client_search_id_values').style.display = "none";
							document.getElementById('client_search_id').value = client.name;
							document.getElementById('client_selected_id').value = client.id;
							document.getElementById('clientNo_search_id').value = client.id;
							$this.setState({ client_name: client.name });
							$this.setState({ client_phone: client.id });
							$this.setState({ client_id: client.id },()=>{
                                $this.clientphone();
							$this.clientfillemail();
							});
							

						} else {
							client.flag = 0;
						}
					});

					document.getElementById('client_search_id_values').style.display = "none";
				});
				break;
			case 'clientNo_search_id':
				this.setState({ clientphone: { phone_number: '' } });
				this.setState({ phone_number: '' })

				break;
			case 'default':
				break;

		}
		document.getElementById('client_search_id_values').style.display = "block";
	}

	clearStateData = () => {
		console.log("clearStateData");
		this.setState({ 
			client_name: 0,
			 agent_name: 0, 
			 listing_name: 0, 
			 unit_name: 0, 
			 agenterror: '', 
			 newShowing_agent_name: 0, 
			 selected_agent_name_newShowing:'',
			 time: '', 
			 split: '', 
			 phone_number: '', 
			 notes: '',
			 clientphone: { phone_number: '' },
			 address_value:'',
			},()=>{
				//this.agentOpt();
			});
		// if (document.getElementById('searchkey_agent_assign_newShoiwng')) {
		// 	document.getElementById('searchkey_agent_assign_newShoiwng').value = "";
		// }
	}
	handleChange(date) {
		this.setState({
			time: date
		});
		document.getElementById('datePicker_time').value = date;
	}
	handleChangeDate(date) {

		var selectedDate = Moment(date).format('YYYY-MM-DD');
		var todaysDate = Moment(new Date()).format('YYYY-MM-DD');
		if (selectedDate == todaysDate) {
			if (this.state.time != '') {
				this.state.time = '';
			}
			this.setState({
				currentTime: new Date().getHours() + 1
			});
		}
		else {
			this.setState({
				currentTime: '7'
			});
		}

		this.setState({
			startDate: date
		});
	}

	async clientphone() {

		let clientParam = [{
			url: "/showings/clientphone/" + this.state.client_id
		}];
		console.log('clientParam', clientParam);

		getApiData(clientParam, true).then(res => {

			this.setState({ clientphone: res.data[0] });
			this.setState({ phone_number: res.data[0] });

		}).catch(error => {
			if (error.response) {
				this.setState({ myerrormsg: error.response.data.error.message });
			}
		});
	}
	async clientfillemail() {

		let clientParam = [{
			url: "/showings/clientemail/" + this.state.client_id
		}];


		getApiData(clientParam, true).then(res => {
			console.log('client_fill_email', res.data);
			this.setState({ client_fill_email: res.data[0] });
			this.setState({ custom_client_email: res.data[0] });

		}).catch(error => {
			if (error.response) {
				this.setState({ myerrormsg: error.response.data.error.message });
			}
		});
	}
	validateForm = () => {
		if (this.state.clientphone.phone_number) {
			const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,9}[\.][a-zA-Z]{2,9}/g;
			return this.state.client_name.length > 0
				// &&  document.getElementById('listing_search_id_values').style.display=='none'  && document.getElementById('unit_search_id_values').style.display=='none'
				&& this.state.address_value != "" && this.state.unit_value != "" && pattern.test(document.getElementById('clientEmail_search_id').value) === true
		}
		else {
			const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,9}[\.][a-zA-Z]{2,9}/g;
			return this.state.client_name.length > 0
				// &&  document.getElementById('listing_search_id_values').style.display=='none'  && document.getElementById('unit_search_id_values').style.display=='none'
				&& this.state.address_value != "" && this.state.unit_value != "" && this.state.phone_number.length == 12 && pattern.test(document.getElementById('clientEmail_search_id').value) === true
		}


	}

	validatePhone(value) {
		let res = validatePhone(value);
		if (res.status == false) {
			this.setState({ phoneerror: 'Phone number is not valid.' });
			document.getElementById("clientNo_search_id").value = res.value;
		} else {
			this.setState({ phoneerror: '' });
			document.getElementById("clientNo_search_id").value = res.value;
		}
		return res.status;
	}
	formValidate(key, value) {
		switch (key) {
			case 'clientNo_search_id':
				this.validatePhone(value);
				break;

		}
	}




	handleListingCreatorSearch = event => {
		var selected_listing_name = event.target.getAttribute('name');

		this.setState({ address_value: event.target.value });
		//alert(selected_client_name);
		this.setState({
			selected_listing_name: selected_listing_name
		})
		var key = event.target.id;
		switch (key) {
			case 'listing_search_id':
				this.setState({ listingSearchParm: event.target.value, listing_name: event.target.value }, () => {
					if (this.state.listingSearchParm.length > 2) {
						var value = this.state.listingSearchParm;
						var len = this.state.listingCreator.length;
						var count = 0;
						var vflag = 0;
						var showCount = 0;
						console.log('listingSearchParm', this.state.listingSearchParm);
						if (value != '') {
							this.state.listingCreator.forEach(function (listing, index) {
								//console.log("listingName", listing.name);
								if (listing.name.trim().toLowerCase().includes(value.trim().toLowerCase()) && showCount < 10) {
									listing.flag = 1;
									vflag = 1;
									showCount = showCount + 1;
									count = count + 1;
								} else if (listing.flag == 1) {
									listing.flag = 0;
									if (vflag != 1)
										vflag = 0
								} else {
									count = count + 1;
								}
							});
						} else {
							document.getElementById('listing_search_id_values').style.display = 'none';
							this.state.listingCreator.forEach(function (listing, index) {
								listing.flag = 0;
							});

						} if (len == count) {
							if (vflag == 0) {
								document.getElementById('listing_search_id_values').style.display = 'none';
								//this.setState({searchMsg:true});

							} else {
								this.setState({ searchMsg: false });

							}
						}
					}
					if (this.state.listingSearchParm.length <= 2) {

						document.getElementById('listing_search_id_values').style.display = 'none';
					}
				})
				//}
				break;
			case 'searchkey_listing':
				var value = event.target.value;
				let $this = this;
				this.setState({ listing_id: event.target.value }, () => {
					this.state.listingCreator.forEach(function (listing, index) {
						if (listing.id == value) {
							document.getElementById('listing_search_id_values').style.display = "none";
							document.getElementById('listing_search_id').value = listing.name;
							// document.getElementById('listing_search_id').value=listing.id;
							// document.getElementById('unit_search_id').value=listing.id;
							//$this.setState({listing_name:listing.name, unit_name:listing.unit});
							$this.setState({ listing_name: listing.name });
							if (document.getElementById('listing_search_id').value != "") {
								let agentParam = [{
									url: "/showings/UnitlistingCreator",
									urlParms: "?listingname=" + document.getElementById('listing_search_id').value
								}];
								getApiData(agentParam, true).then(res => {

									$this.setState({ 'unitListingCreator': res.data });
								}).catch(error => {

									if (error.response) {
										this.setState({ myerrormsg: error.response.data.error.message });
									}
								});
							}
						} else {
							listing.flag = 0;
						}
					});

					document.getElementById('listing_search_id_values').style.display = "none";
				});
				break;
			case 'default':
				break;

		}
		document.getElementById('listing_search_id_values').style.display = "block";
	}


	handleListingCreatorUnitSearch = event => {
		var selected_unit_name = event.target.getAttribute('name');

		this.setState({ unit_value: event.target.value });
		//alert(selected_client_name);
		this.setState({
			selected_unit_name: selected_unit_name
		})
		var key = event.target.id;
		switch (key) {
			case 'unit_search_id':
				this.setState({ unitSearchParm: event.target.value, unit_name: event.target.value }, () => {
					//if(this.state.searchParm.length>-1){
					var value = this.state.unitSearchParm;
					console.log('unitSearchParm', this.state.unitSearchParm);
					var len = this.state.unitListingCreator.length;
					var count = 0;
					var vflag = 0;
					if (value != '') {
						this.state.unitListingCreator.forEach(function (unit, index) {
							console.log("unitName", unit.unit);
							if (unit.unit.trim().toLowerCase().includes(value.trim().toLowerCase())) {
								unit.flag = 1;
								vflag = 1;
								count = count + 1;
							} else {
								unit.flag = 0;
								if (vflag != 1)
									vflag = 0
								count = count + 1;
							}
						});
					} else {
						document.getElementById('unit_search_id_values').style.display = 'none';
						this.state.unitListingCreator.forEach(function (unit, index) {
							unit.flag = 0;
						});

					} if (len == count) {
						if (vflag == 0) {
							// this.setState({searchMsg:true});
							document.getElementById('unit_search_id_values').style.display = "none";

						} else {
							this.setState({ searchMsg: false });

						}
					}
				})
				//}
				break;
			case 'searchkey_unit':
				var value = event.target.value;
				let $this = this;
				this.setState({ unit_id: event.target.value }, () => {
					this.state.unitListingCreator.forEach(function (unit, index) {
						if (unit.id == value) {
							document.getElementById('unit_search_id_values').style.display = "none";
							document.getElementById('unit_search_id').value = unit.unit;
							// document.getElementById('unit_search_id').value=unit.id;
							// document.getElementById('listing_search_id').value=unit.id;
							// $this.setState({unit_name:unit.unit, listing_name:unit.name});
							$this.setState({ unit_name: unit.unit });
						} else {
							unit.flag = 0;
						}
					});

					document.getElementById('unit_search_id_values').style.display = "none";
				});
				break;
			case 'default':
				break;

		}
		document.getElementById('unit_search_id_values').style.display = "block";
	}

	createNewShowing = () => {
		document.getElementById('createnewshowing').disabled=true;
		this.setState({linkingLoader:true})   
		let key = encHex.parse("0123456789abcdef0123456789abcdef");
		let iv = encHex.parse("abcdef9876543210abcdef9876543210");

		let agentFlag = 0;
		let showingTime;
		let startDate = Moment(this.state.startDate).format('YYYY-MM-DD');
		if (this.state.time != '' && this.state.time != undefined) {
			showingTime = Moment(this.state.time).format('HH:mm:ss');
		}
		else {
			showingTime = '23:59:59';
		}
		startDate = startDate + ' ' + showingTime;
		let split = this.state.split;
		let email = 'info@moveskyward.com';
		let createShowing = [{
			url: "/showings/createShowing"
		}];
		var form = new FormData();
		if (document.getElementById('searchkey_agent_assign_newShoiwng')) {
			if (document.getElementById('searchkey_agent_assign_newShoiwng').value == 0 || document.getElementById('searchkey_agent_assign_newShoiwng').value == '') {
				form.append("agent_id", -1);
				agentFlag = 0;
			}
			else {
				form.append("agent_id", document.getElementById('searchkey_agent_assign_newShoiwng').value);
				agentFlag = 1;
			}
		}
		else {
			form.append("agent_id", -1);
			agentFlag = 0;
		}
		if (this.state.client_id == 0) {
			form.append("client_id", "");
		}
		else {
			form.append("client_id", this.state.client_id);
		}
		//form.append("listing_id", document.getElementById('searchkey_unit').value);
		form.append("listing_add", document.getElementById('listing_search_id').value);
		form.append("listing_unit", document.getElementById('unit_search_id').value);
		form.append("showing_time", showingTime);
		form.append("showing_date", startDate);
		form.append("client_name", this.state.client_name);
		if (this.state.clientphone.phone_number) {
			form.append("client_contact", aes.decrypt(this.state.clientphone.phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8));
		}
		else {
			form.append("client_contact", this.state.phone_number);
		}
		if (this.state.client_fill_email.email) {
			form.append("email", aes.decrypt(this.state.client_fill_email.email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8));
		}
		else {
			form.append("email", this.state.custom_client_email);
		}
		form.append("split", split);
		form.append("status", 2);

		postApiData(createShowing, form, true).then(res => {
			if (res.data.success) {
				this.setState({linkingLoader:false})
				this.setState({ unit_value: '', unit_name: '', address_value: '' })
				window.location.reload();
				document.getElementById("newShowingClose").click();
				this.getShowingList();
				this.getAvailableShowing();
				if (agentFlag) {
					this.updateTimeKitForShowing(res.data.message.insertId);
				}

			}
			else {
				this.setState({ myerrormsg: res.data.message });
			}

		}).catch(error => {

		});
	}

	closepopup = event => {
		window.location.reload();
	}

	updateTimeKitForShowing(clientShowingId, flag = false) {

		let timeKitShowing = [{
			url: "/showings/createShowing/" + clientShowingId
		}];
		getApiData(timeKitShowing, true).then(res => {
			console.log("response---", res);
			if (flag) {
				this.updateTimeKitForAction(this.state.c_clientShowingId, 'agent_id');
			}
		}).catch(error => {
		});
	}

	updateTimeKitForAction(clientShowingId, columnName) {

		let timeKitShowing = [{
			url: "/showings/updateShowing/" + clientShowingId + "/" + columnName
		}];
		getApiData(timeKitShowing, true).then(res => {
			console.log("response---", res);
		}).catch(error => {
		});
	}

	preventKeystroke = (event) => {
		console.log("keycode---", event.keyCode);
		if (event.keyCode) {
			event.preventDefault();
		}
	}
	hideClientPopup = () => {
		document.getElementById('client_search_id_values').style.display = 'none';
	}
	handlePhoneChange = event => {

		this.formValidate(event.target.id, event.target.value);

		this.setState({ clientphone: { phone_number: '' },client_id:'' });

		if (event.target.id === 'clientNo_search_id') {
			if (this.isNum(event.target.value) != true) {
				this.setState({ phone_number: '' })
			}
			else {
				this.setState({ phone_number: event.target.value });
			}
		}

	}

	isNum(num) {
		if (/^[0-9-]*$/.test(num))
			return true;
		else
			return false;
	}



	handleClickOnList = param => e => {
		var value = param[0];

		this.getData(value, e.target.id);
		document.getElementById('search').classList.remove("show");
		//document.getElementById('confirmsearch').classList.remove("show");
		document.getElementById('c_agent_search_id').classList.remove("show");

	}
	handleClickConfOnList = param => e => {
		var value = param[0];

		this.getData(value, e.target.id);
		document.getElementById('cnsearch').classList.remove("show");
		document.getElementById('c_client_searchClick').classList.remove("show");

	}
	handleClickAvailOnList = param => e => {
		var value = param[0];

		this.getData(value, e.target.id);

		document.getElementById('c_Availclient_searchClick').classList.remove("show");
		document.getElementById('cnsearch1').classList.remove("show");

	}

	handleClickOnAddrList = param => e => {
		var value = param[0];

		this.getData(value, e.target.id);
		document.getElementById('search').classList.remove("show");
		document.getElementById('c_address_searchClick').classList.add("active");


	}
	handleClickAvailAddOnList = param => e => {
		var value = param[0];

		this.getData(value, e.target.id);
		document.getElementById('search_availead').classList.remove("show");
		document.getElementById('searchClick_availead').classList.add("active");


	}
	getData(addressvalue, id) {
		var _this = this;

		if (id == 'searchkey') {
			this.setState({ searchAddressvalue: addressvalue }, () => {
				this.state.addressData.forEach(function (address, index) {
					if (address.listing_info_address == addressvalue) {

						document.getElementById('searchClick').classList.add("active");
						document.getElementById('searchParm').value = addressvalue;

					}
					else {
						address.flag = 0;
					}

				});
				this.getAvailableShowing();
				this.getShowingList();

			});

		}

		if (id == 'searchkey_confirm') {

			this.state.addressConfData.forEach(function (address, index) {
				if (address.listing_info_address == addressvalue) {

					document.getElementById('c_address_searchClick').classList.add("active");
					//document.getElementById('searchParm_confirm').value=addressvalue;
					document.getElementById('searchParm').value = addressvalue;
					_this.state.upperSearchWhere.searchConfAddress = addressvalue;


				}
				else {
					address.flag = 0;
				}

			});
			this.getShowingList();
			this.getAvailableShowing();

		}
		if (id == 'searchkeyConf_client') {

			document.getElementById('c_client_searchClick').classList.add("active");
			document.getElementById('searchConf_client').value = addressvalue;
			_this.state.upperSearchWhere.searchConfClient = addressvalue;


			this.getShowingList();
			this.getAvailableShowing();

		}
		if (id == 'searchkeyAvail_client') {

			document.getElementById('c_Availclient_searchClick').classList.add("active");
			document.getElementById('searchAvail_client').value = addressvalue;
			_this.state.availead_SearchWhere.searchAvailClient = addressvalue;


			this.getShowingList();
			this.getAvailableShowing();

		}

		if (id == 'searchkeyavail') {

			document.getElementById('searchClick_availead').classList.add("active");
			document.getElementById('searchParm_availead').value = addressvalue;
			_this.state.availead_SearchWhere.showingAddress = addressvalue;


			this.getShowingList();
			this.getAvailableShowing();

		}

		if (id == 'searchkey_agent_reassign') {
			//var selected_agent_name = event.target.getAttribute('name')
			this.setState({ confirmagentvalue: addressvalue }, () => {
				this.state.c_agentList.forEach(function (agent, index) {
					if (agent.name == addressvalue) {
						document.getElementById('c_agent_search_id_values').style.display = "none";

						_this.setState({ agent_name: addressvalue })
					}
					else {
						agent.flag = 0;
					}

				});
				//this.getShowingList();
				//this.getAvailableShowing();
			});
		}

		// document.getElementById('searchClick').classList.add("active");
		// document.getElementById('searchClick_confirm').classList.add("active");

		this.createWhere();

	}


	getAddressData = () => {

		let listingWhere = [{
			url: "/showings/showing/avashowingAdd"
		}];

		getApiData(listingWhere, true).then(res => {

			this.setState({
				addressData: res.data,
			}, () => {
			})
		}).catch(err => {


		})
	}
	getConfirmed_AddressData = () => {
		let listingWhere = [{
			url: "/showings/showing/confshowingAdd",
			where: {
				'agent_id': 0
			}
		}];

		getApiData(listingWhere, true).then(res => {

			this.setState({
				addressConfData: res.data
			}, () => {
			})
		}).catch(err => {


		})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onOpenavaileadModal = () => {
		this.setState({ open_availead: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });

	};

	onCloseavaileadModal = () => {
		this.setState({ open_availead: false });

	};

	handleClick(id, showing_date, client_name, client_fullname, showing_time, showingAddress, address_value, showingUnit, unit_value, agent_name, phone_number, client_phone_number, client_email, email, split, notes) {

		let key = encHex.parse("0123456789abcdef0123456789abcdef");
		let iv = encHex.parse("abcdef9876543210abcdef9876543210");
		this.setState({ showingpopupid: id, showing_date_confirm: Moment(showing_date).format('YYYY-MM-DD'), showing_time_confirm: Moment(showing_time, ['h:mm:ss']).format('hh:mm A'), confirm_split: split, confirm_notes: notes, confirm_agent: agent_name }, () => {
			this.onOpenModal();

		});

		if (showingAddress == null) {
			this.setState({ showingAddressconfirm: address_value })

		}
		else {
			this.setState({ showingAddressconfirm: showingAddress })
		}
		if (showingUnit == null) {
			this.setState({ showingunitconfirm: unit_value })

		}
		else {
			this.setState({ showingunitconfirm: showingUnit })
		}
		if (client_name == null) {
			this.setState({ client_name_confirm: client_fullname })

		}
		else {
			this.setState({ client_name_confirm: client_name })
		}
		if (phone_number == null) {
			this.setState({ client_phone_confirm: aes.decrypt(client_phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })

		}
		else {
			this.setState({ client_phone_confirm: aes.decrypt(phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })
		}
		if (client_email == null) {
			this.setState({ client_email_confirm: aes.decrypt(email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })

		}
		else {
			this.setState({ client_email_confirm: aes.decrypt(client_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })
		}



	}

	handleClickavailead(id, showing_date, showing_time, showingAddress, address_value, showingUnit, unit_value, split, price, bedrooms, bathrooms, notes, client_name, client_fullname, phone_number, client_phone_number) {

		let key = encHex.parse("0123456789abcdef0123456789abcdef");
		let iv = encHex.parse("abcdef9876543210abcdef9876543210");
		this.setState({ showingavaileadid: id, showing_date_availead: Moment(showing_date).format('YYYY-MM-DD'), showing_time_availead: Moment(showing_time, ['h:mm:ss']).format('hh:mm A'), availead_price: price, availead_bedrooms: bedrooms, availead_bathrooms: bathrooms, availead_split: split, availead_notes: notes }, () => {
			this.onOpenavaileadModal();

		});

		if (showingAddress == null) {
			this.setState({ showingAddressavailead: address_value })

		}
		else {
			this.setState({ showingAddressavailead: showingAddress })
		}
		if (showingUnit == null) {
			this.setState({ showingunitavailead: unit_value })

		}
		else {
			this.setState({ showingunitavailead: showingUnit })
		}

		if (client_name == null) {
			this.setState({ client_name_availead: client_fullname })

		}
		else {
			this.setState({ client_name_availead: client_name })
		}
		if (phone_number == null) {
			this.setState({ client_phone_availead: aes.decrypt(client_phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })

		}
		else {
			this.setState({ client_phone_availead: aes.decrypt(phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) })
		}
	}

	// create handle change for block_showing 

	handleCheck = (e, id, block) => {
		if (block == 1) {
			this.state.block_showing = true;
		}
		else {
			this.state.block_showing = false;
		}
		this.setState({ block_showing: !this.state.block_showing }, () => {

			var block_showings;
			if (this.state.block_showing == true) {
				block_showings = 1;
			}
			else {
				block_showings = 0;
			}
			let where = [{
				url: "/showings/editBlock_showing"
			}];
			postApiData(where, { 'id': id, 'block_showing': block_showings }, true).then(res => {
				if (res) {
					this.getAvailableShowing();
				}

			}).catch(error => {

			})

		});



	}


	render() {

	if (!getManagerToken()) {
			return (<Redirect to={'/manager/landing'}/>);
 }
		const addressDW = this.state.addressData.map((dw, index) =>
			(dw.flag == 1) ? <li key={index} id="searchkey" onClick={this.handleClickOnList([dw.listing_info_address])}>{dw.listing_info_address}</li> : ''
		);

		const addressDWavailead = this.state.addressData_availead.map((dw, index) =>
			(dw.flag == 1) ? <li key={index} id="searchkeyavail" onClick={this.handleClickAvailAddOnList([dw.listing_info_address])}>{dw.listing_info_address}</li> : ''
		);
		const addressDWconfirmshowing = this.state.addressConfData.map((dw, index) =>
			(dw.flag == 1) ? <li key={index} id="searchkey_confirm" onClick={this.handleClickOnAddrList([dw.listing_info_address])}>{dw.listing_info_address}</li> : ''
		);



		const listingDWUnitList = this.state.unitListingCreator.map((un, index) =>
			(un.flag == 1) ? <li id="searchkey_unit" key={un.id} value={un.id} name={un.unit} onClick={this.handleListingCreatorUnitSearch}>{un.unit}</li> : '');

		const listingDWList = this.state.listingCreator.map((ls, index) =>
			(ls.flag == 1) ? <li id="searchkey_listing" key={ls.id} value={ls.id} name={ls.name} onClick={this.handleListingCreatorSearch}>{ls.name}</li> : '');


		const clientDWList = this.state.clientList.map((client, index) =>
			(client.flag == 1 && client.id > 0) ? <li id="searchkey_client" key={client.id} value={client.id} name={client.name} onClick={this.handleClientSearch}>{client.name}</li> : '');

		const clientDW = this.state.c_agentList.map((aw, index) =>
			(aw.flag == 1 && aw.id >= 0) ? <li id="searchkey_agent_reassign" key={aw.id} value={aw.id} name={aw.name} onClick={this.handleClickOnList([aw.name])}>{aw.name}</li> : ''
		);

		const clientsearchname = this.state.confirm_clientList.map((dw, index) =>
			(dw.flag == 1) ? <li key={index} id="searchkeyConf_client" onClick={this.handleClickConfOnList([dw.name])}>{dw.name}</li> : ''
		);
		const clientAvailsearchname = this.state.avail_clientList.map((dw, index) =>
			(dw.flag == 1) ? <li key={index} id="searchkeyAvail_client" onClick={this.handleClickAvailOnList([dw.name])}>{dw.name}</li> : ''
		);

		const agentDW = this.state.agentList.map((baw, index) =>
			(baw.flag == 2 && baw.id >= 0) ? <li id="searchkey_agent_assign" key={baw.id} value={baw.id} name={baw.name} onClick={this.handleAgentSearchAssign}>{baw.name}</li> : ''
		);

		const agentDWNewShowing = this.state.newShowing_agentList.map((baw, index) =>
			(baw.flag == 3 && baw.id >= 0) ? <li id="searchkey_agent_assign_newShoiwng" key={baw.id} value={baw.id} name={baw.name} onClick={this.handleAgentSearchAssignNewShowing}>{baw.name}</li> : ''
		);
		let key = encHex.parse("0123456789abcdef0123456789abcdef");
		let iv = encHex.parse("abcdef9876543210abcdef9876543210");
		Moment.locale('en');
		const { isShowingExist } = this.state;

		let showingItems = this.state.showingData.map((s) =>



			<tr key={s.id} className={(s.reschedule_status == 1 || s.status == 3) ? "danger" : ""} >
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{Moment(s.showing_date).format('M/D')}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(s.showing_time != '23:59:59') ? Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A') : '--'}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(!s.showingAddress) ? s.address_value : s.showingAddress}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(!s.showingUnit) ? s.unit_value : s.showingUnit}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{s.agent_name}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(!s.client_name) ? s.client_fullname : s.client_name}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClick(s.id, s.showing_date, s.client_name, s.client_fullname, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.agent_name, s.phone_number, s.client_phone_number, s.client_email, s.email, s.split, s.notes)}>{(!s.phone_number) ? aes.decrypt(s.client_phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) : aes.decrypt(s.phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)}</td>

				{/* <td>{(s.reschedule_staus==1)?'EXPIRES IN 47 MINUTES':''} </td> */}

				<td align="center">
					<Link to="#" data-toggle="modal" data-target="#ReAssign" onClick={() => this.setReassign(s.client_name, s.client_fullname, Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A'), Moment(s.showing_date).format('dddd, MMMM DD'), s.id, s.agent_name, s.agent_email, s.client_email, s.showingAddress, s.address_value, s.showingUnit, s.unit_value)}>
						<svg width="20" height="14" viewBox="0 0 20 14" fill="#333333" xmlns="http://www.w3.org/2000/svg">
							<path className="blk-icon" d="M7.01 9.00003H0V11H7.01V14L11 10L7.01 6.00003V9.00003ZM12.99 8.00003V5.00003H20V3.00003H12.99V3.05176e-05L9 4.00003L12.99 8.00003Z" fill="#333333" />
						</svg>
					</Link>
				</td>

				{s.status != 3 ? (<td align="center"><Link to="#" onClick={() => this.setAssign(s.client_name, Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A'), Moment(s.showing_date).format('dddd, MMMM DD'), s.id, s.agent_name, s.agent_email, s.client_email, s.showingAddress, s.showingUnit, s.address_value, s.unit_value, 'reAssign')} data-toggle="modal" data-target="#cancelAvailableSchedule">
					<svg width="14" height="14" viewBox="0 0 14 14" fill="#333333" xmlns="http://www.w3.org/2000/svg">
						<path className="blk-icon" d="M14 1.41003L12.59 3.05176e-05L7 5.59003L1.41 3.05176e-05L0 1.41003L5.59 7.00003L0 12.59L1.41 14L7 8.41003L12.59 14L14 12.59L8.41 7.00003L14 1.41003Z" fill="#333333" />
					</svg>
				</Link></td>) : (<td align="center"></td>)}

			</tr>
		);


		const { isAvailShowingExist } = this.state;
		let availShowingItems = this.state.availShowingData.map((s, l) =>
			<tr key={l} className="success">
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes, s.client_name, s.client_fullname, s.phone_number, s.client_phone_number)}>{Moment(s.showing_date).format('M/D')}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes, s.client_name, s.client_fullname, s.phone_number, s.client_phone_number)}>{(s.showing_time != '23:59:59') ? Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A') : '--'}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes, s.client_name, s.client_fullname, s.phone_number, s.client_phone_number)}>{(!s.showingAddress) ? s.address_value : s.showingAddress}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes, s.client_name, s.client_fullname, s.phone_number, s.client_phone_number)}>{(!s.showingUnit) ? s.unit_value : s.showingUnit}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes, s.client_name, s.client_fullname, s.phone_number, s.client_phone_number)}>{(!s.client_name) ? s.client_fullname : s.client_name}</td>
				<td style={{ cursor: 'pointer' }} onClick={() => this.handleClickavailead(s.id, s.showing_date, s.showing_time, s.showingAddress, s.address_value, s.showingUnit, s.unit_value, s.split, s.price, s.bedrooms, s.bathrooms, s.notes, s.client_name, s.client_fullname, s.phone_number, s.client_phone_number)}>{(!s.phone_number) ? aes.decrypt(s.client_phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) : aes.decrypt(s.phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)}</td>

				<td align="center">
					<Link to="#" data-toggle="modal" onClick={() => this.setAssign(s.client_name, Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A'), Moment(s.showing_date).format('dddd, MMMM DD'), s.id, '', '', s.client_email, s.showingAddress, s.showingUnit, s.address_value, s.unit_value, s.client_fullname, s.client_phone_number, 'assign', s.timekit_id)} data-target="#Assign">
						<svg width="20" height="14" viewBox="0 0 20 14" fill="#333333" xmlns="http://www.w3.org/2000/svg">
							<path className="blk-icon" d="M7.01 9.00003H0V11H7.01V14L11 10L7.01 6.00003V9.00003ZM12.99 8.00003V5.00003H20V3.00003H12.99V3.05176e-05L9 4.00003L12.99 8.00003Z" fill="#333333" />
						</svg>

					</Link>
				</td>
				<td align="center"><Link to="#" data-toggle="modal" onClick={() => this.setAssign(s.client_name, Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A'), Moment(s.showing_date).format('dddd, MMMM DD'), s.id, '', '', s.client_email, s.showingAddress, s.showingUnit, s.address_value, s.unit_value, s.client_fullname, s.client_phone_number, 'assign', s.timekit_id)} data-target="#cancelAvailableSchedule">
					<svg width="14" height="14" viewBox="0 0 14 14" fill="#333333" xmlns="http://www.w3.org/2000/svg">
						<path className="blk-icon" d="M14 1.41003L12.59 3.05176e-05L7 5.59003L1.41 3.05176e-05L0 1.41003L5.59 7.00003L0 12.59L1.41 14L7 8.41003L12.59 14L14 12.59L8.41 7.00003L14 1.41003Z" fill="#333333" />
					</svg>
				</Link></td>
				<td align="center">	<input type="checkbox"
					onChange={(e) => this.handleCheck(e, s.id, s.block_showing)}

					checked={s.block_showing}

				/>
				</td>
			</tr>
		);

		const { open, open_availead } = this.state;

		return (
			<div>
				<Header />
				<main>
					<Sidebar />
					<div className="content">
						<div className="work-section">
							<div className="dashboard">
								{(this.state.linkingLoader) ? <div className="show_loader_on_click" id="show_loader_on_click"></div> : ''}

								{this.state.mymsg !== '' ? (<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>) : (null)}
								{this.state.myerrormsg !== '' ? (<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>) : (null)}

								<div className="clearfix">
									<h2 className="pull-left">Confirmed Showings</h2>
									<div className="pull-right Cr-showing-btn">
										<Link to="#" data-toggle="modal" onClick={this.clearStateData} data-target="#CreateShowing">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="15" cy="15" r="15" fill="#F26C52" />
											<path className="create-showing" d="M24 16.7143H15.9643V24.75H13.2857V16.7143H5.25V14.0357H13.2857V5.99999H15.9643V14.0357H24V16.7143Z" fill="white" />
										</svg>
										</Link>

									</div>
								</div>

								<div className="panel-body table-responsive showingWrap">
									<table className="table table-striped agent-listing showingtable" style={{ marginTop: '0px', marginBottom: '60px' }}>
										<thead>
											<tr>
												<th>Date <Link to='#' id="c_date_statusKey" data-toggle="collapse" data-target="#Date" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
													<div id="Date" className="collapse table-collapse status-collapse" ref={this.setWrapperRef1}>


														<div className="box">
															<label>Date</label>
															<DatePicker
																selected={this.state.upperSearchDate}
																onChange={this.handleUpperSearchDate}
																dateFormat="MMMM d, yyyy"
																timeCaption="time"
																className="form-control"
																minDate={new Date()}
																id="upperSearchDate"
																onKeyDown={(e) => this.preventKeystroke(e)}


															/>
															<span onClick={this.resetsearchParm}>(X)</span>
														</div>
													</div>
												</th>
												<th>Time
												<Link to='#' id="c_time_statusKey" data-toggle="collapse" data-target="#Time" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
													<div id="Time" className="collapse table-collapse status-collapse" ref={this.setWrapperRef2}>

														<div className="box">

															<label>Time</label>

															<DatePicker
																id="upperSearchTime"
																selected={this.state.upperSearchTime}
																onChange={this.handleUpperSearchTime}
																showTimeSelect
																showTimeSelectOnly
																timeFormat="h:mm aa"
																timeIntervals={15}
																dateFormat="h:mm aa"
																timeCaption="time"
																className="form-control"
																onKeyDown={(e) => this.preventKeystroke(e)}
															/>
															<span onClick={this.resetsearchParm}>(X)</span>
														</div>

													</div>
												</th>
												<th>Address
												<Link to='#' id="c_address_searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
													<div id="search" className="collapse table-collapse client-collapse" ref={this.setWrapperRef3}>
														<div className="box">

															<input type="text" id="searchParm" autoComplete="off" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} />
															<span onClick={this.resetsearchParm}>(X)</span>
															<ul>
																{addressDWconfirmshowing}
															</ul>

															{(this.state.searchMsg) ? 'No suggestions available' : ''}
														</div>
													</div>
												</th>
												<th>Unit</th>
												<th>Agent Name
												{/* <Link to id="searchClick_agent" data-toggle="collapse" data-target="#search_agent" className="icon-wrap search"></Link>
												<div id="search_agent" class="collapse table-collapse client-collapse" ref={this.setWrapperRef3}>

													<div className="box">
													
													<input type="text" id="searchParm_agent" autocomplete="off" class="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>*/}
													{/* <div className="filterSearchReset"><Link to className="clearFilter" onClick={this.resetAgentParm}><i class="fa fa-undo"></i> Reset</Link></div>  */}
													{/*<span onClick={this.resetsearchParm}>(X)</span>
														<ul>
														{clientDW}
														</ul>
														{(this.state.searchMsg)?'No suggestions available':''}
													</div>
												</div> */}

												</th>
												<th>Client Name
												<Link to='#' id="c_client_searchClick" data-toggle="collapse" data-target="#cnsearch" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
													<div id="cnsearch" className="collapse table-collapse client-collapse" ref={this.setWrapperRef10}>
														<div className="box">

															<input type="text" id="searchConf_client" autoComplete="off" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} />
															<span onClick={this.resetsearchParm}>(X)</span>
															<ul>
																{clientsearchname}
															</ul>
															{(this.state.searchMsg) ? 'No suggestions available' : ''}

														</div>
													</div>
												</th>
												<th>Client Phone</th>
												<th style={{ textAlign: 'center' }}>Reassign</th>
												<th style={{ textAlign: 'center' }}>Cancel</th>
											</tr>
										</thead>
										<tbody>
											{(isShowingExist && !this.state.linkingLoader) ? showingItems : (!isShowingExist && !this.state.linkingLoader) ? <tr><td colSpan="9">You don’t have any confirmed showings yet</td></tr> : ''}


										</tbody>


									</table>
								</div>
								


								<div className="clearfix">
									<h2 className="pull-left">Available Leads</h2>
									{/*<div className="pull-right Cr-showing-btn">
											<Link to="#" data-toggle="modal" data-target="#CreateShowing"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="15" cy="15" r="15" fill="#F26C52"/>
										<path className="create-showing" d="M24 16.7143H15.9643V24.75H13.2857V16.7143H5.25V14.0357H13.2857V5.99999H15.9643V14.0357H24V16.7143Z" fill="white"/>
										</svg></Link>

									</div>*/}
								</div>

								<div className="panel-body table-responsive showingWrap">
									<table className="table table-striped agent-listing" style={{ marginTop: '0px', marginBottom: '60px' }}>
										<thead>
											<tr>
												<th>Date
											<Link to='#' id="a_date_statusKey" data-toggle="collapse" data-target="#Date_availead" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
													<div id="Date_availead" className="collapse table-collapse status-collapse" ref={this.setWrapperRef7}>


														<div className="box">
															<label>Date</label>
															<DatePicker
																selected={this.state.availead_SearchDate}
																onChange={this.handleavaileadSearchDate}
																dateFormat="MMMM d, yyyy"
																timeCaption="time"
																className="form-control"
																minDate={new Date()}
																id="availead_SearchDate"
																onKeyDown={(e) => this.preventKeystroke(e)}


															/>
															<span onClick={this.resetsearchParm}>(X)</span>
														</div>
													</div>
												</th>
												<th>Time
												<Link to='#' id="a_time_statusKey" data-toggle="collapse" data-target="#availead_Time" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>
													<div id="availead_Time" className="collapse table-collapse status-collapse" ref={this.setWrapperRef8}>

														<div className="box">
															<label>Time</label>

															<DatePicker
																id="availeadSearchTime"
																selected={this.state.availeadSearchTime}
																onChange={this.handleavaileadSearchTime}
																showTimeSelect
																showTimeSelectOnly
																timeFormat="h:mm aa"
																timeIntervals={15}
																dateFormat="h:mm aa"
																timeCaption="time"
																className="form-control"
																onKeyDown={(e) => this.preventKeystroke(e)}
															/>
															<span onClick={this.resetsearchParm}>(X)</span>
														</div>
													</div>
												</th>
												<th>Address
												<Link to='#' id="searchClick_availead" data-toggle="collapse" data-target="#search_availead" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
													<div id="search_availead" className="collapse table-collapse client-collapse" ref={this.setWrapperRef9}>
														<div className="box">

															<input type="text" id="searchParm_availead" autoComplete="off" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} />
															<span onClick={this.resetsearchParm}>(X)</span>
															<ul>
																{addressDWavailead}
															</ul>

															{(this.state.searchMsg) ? 'No suggestions available' : ''}
														</div>
													</div>
												</th>
												<th>Unit</th>

												<th>Client Name
													<Link to='#' id="c_Availclient_searchClick" data-toggle="collapse" data-target="#cnsearch1" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
													<div id="cnsearch1" className="collapse table-collapse client-collapse" ref={this.setWrapperRef11}>
														<div className="box">

															<input type="text" id="searchAvail_client" autoComplete="off" className="search-input" onChange={this.search} onKeyDownCapture={this.search} onKeyUpCapture={this.search} />
															<span onClick={this.resetsearchParm}>(X)</span>
															<ul>
																{clientAvailsearchname}
															</ul>
															{(this.state.searchMsg) ? 'No suggestions available' : ''}

														</div>
													</div>
												</th>
												<th>Client Phone</th>

												<th style={{ textAlign: 'center' }}>Assign</th>
												<th style={{ textAlign: 'center' }}>Cancel</th>
												<th>Block</th>
											</tr>
										</thead>
										<tbody>
											{(isAvailShowingExist && !this.state.linkingLoader) ? availShowingItems : (!isAvailShowingExist && !this.state.linkingLoader) ? <tr><td colSpan="9">You don’t have any available leads yet</td></tr> : ''}
										</tbody>
									</table>
									</div>
							
							</div>
						</div>
					</div>
					<div className="modal fade requestBox" id="CreateShowing" role="dialog">
						<div className="modal-dialog">


							<div className="modal-content">
								<div className="modal-header">

									<h4 className="modal-title">Create a new showing</h4>
									<button type="button" className="close" data-dismiss="modal"  id="newShowingClose">&times;</button>
								</div>
								<div className="modal-body">
									<div className="form-horizontal">
										<div className="form-group row">
											<div className="control-label col-sm-12">
												<label>Address</label>
												<div>
													<input type="text" id="listing_search_id" onChange={this.handleListingCreatorSearch} onKeyDownCapture={this.handleListingCreatorSearch} onKeyUpCapture={this.handleListingCreatorSearch} autoComplete="off" value={(this.state.listing_name != 0) ? this.state.listing_name : this.state.address_value} className="form-control" />
													<input type="hidden" name="listing_selected_id" id="listing_selected_id" value="0" />
													<div className="box agentNameOnBoard" id="listing_search_id_values" style={{ display: 'none' }} >
														<ul>{listingDWList}</ul>
													</div>
												</div>
											</div>
										</div>
										<div className="form-group row">
											<div className="control-label col-sm-6">
												<label>Unit</label>
												<div>
													<input type="text" id="unit_search_id" onChange={this.handleListingCreatorUnitSearch} onKeyDownCapture={this.handleListingCreatorUnitSearch} onKeyUpCapture={this.handleListingCreatorUnitSearch} autoComplete="off" value={(this.state.unit_name != 0) ? this.state.unit_name : this.state.unit_value} className="form-control" />
													<input type="hidden" name="unit_selected_id" id="unit_selected_id" value="0" />
													<div className="box agentNameOnBoard" id="unit_search_id_values" style={{ display: 'none' }} >
														<ul>{listingDWUnitList}</ul>
													</div>
												</div>
											</div>

											<div className="control-label col-sm-6">
												<label>Split</label>
												<div>
													<input
														type="text"
														className="form-control"
														id="split"
														value={this.state.split}
														onChange={this.splithandleChange}
													/>
												</div>
											</div>
										</div>
										<div className="form-group row">

											<div className="control-label col-sm-4">
												<label>Time</label>
												<DatePicker
													id="datePicker_time"
													selected={this.state.time}
													onChange={this.handleChange}
													showTimeSelect
													showTimeSelectOnly
													timeFormat="h:mm aa"
													autoComplete="off"
													timeIntervals={15}
													dateFormat="h:mm aa"
													timeCaption="time"
													autoComplete="off"
													minTime={set_hours(set_minutes(new Date(), 0),0)}
													maxTime={set_hours(set_minutes(new Date(), 45), 23)}
													className="form-control"
													onKeyDown={(e) => this.preventKeystroke(e)}
												/>
											</div>
											<div className="control-label col-sm-8">
												<label>Date</label>
												<DatePicker
													selected={this.state.startDate}
													onChange={this.handleChangeDate}
													dateFormat="MMMM d, yyyy"
													timeCaption="time"
													className="form-control"
													minDate={new Date()}
													maxDate={addDays(new Date(), 7)}
													onKeyDown={(e) => this.preventKeystroke(e)}
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="control-label col-sm-6">
												<label>CLIENT NAME</label>
												<div>
													<input type="text" id="client_search_id" onChange={this.handleClientSearch} onKeyDownCapture={this.handleClientSearch} onKeyUpCapture={this.handleClientSearch} autoComplete="off" value={(this.state.client_name != 0) ? this.state.client_name : ''} className="form-control" />
													<input type="hidden" name="client_selected_id" id="client_selected_id" value="0" />
													<div className="box agentNameOnBoard" id="client_search_id_values" style={{ display: 'none' }} >
														<ul>{clientDWList}</ul>
													</div>
												</div>
											</div>

											<div className="control-label col-sm-6">
												<label>CLIENT NUMBER</label>
												<div>
													<input type="text" id="clientNo_search_id" onChange={this.handlePhoneChange} onKeyDownCapture={this.handlePhoneChange} onKeyUpCapture={this.handlePhoneChange} autoComplete="off" onFocus={this.hideClientPopup} value={(this.state.clientphone.phone_number) ? aes.decrypt(this.state.clientphone.phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) : this.state.phone_number} minLength='12' maxLength='12' className="form-control" />
													<span style={{color:'#A9A9A9',fontSize:'14px'}}>ex. xxx-xxx-xxxx</span>
													<input type="hidden" name="clientNo_selected_id" id="clientNo_selected_id" value="0" />
													<div className="box agentNameOnBoard" id="clientNo_search_id_values" style={{ display: 'none' }} >
														<ul>{clientDW}</ul>
													</div>
												</div>
											</div>
										</div>
										<div className="form-group row">
											<div className="control-label col-sm-10">
												<label>Email address</label>
												<div>
													<input type="email" id="clientEmail_search_id" onChange={this.handleEmailChange} onKeyDownCapture={this.handleEmailChange} onKeyUpCapture={this.handleEmailChange} autoComplete="off" value={(this.state.client_fill_email.email) ? aes.decrypt(this.state.client_fill_email.email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) : this.state.custom_client_email} className="form-control" />
													<input type="hidden" name="clientNo_selected_id" id="clientNo_selected_id" value="0" />
													<div className="box agentNameOnBoard" id="clientNo_search_id_values" style={{ display: 'none' }} >
														<ul>{clientDW}</ul>
													</div>

												</div>
											</div>
										</div>
										<div className="form-group row">
											<div className="control-label col-sm-12">
												<label>AGENT NAME (Optional)</label>
												<div>
													<input type="text" id="newShowing_agent_search_id" onChange={this.handleAgentSearchAssignNewShowing} onKeyDownCapture={this.handleAgentSearchAssignNewShowing} onKeyUpCapture={this.handleAgentSearchAssignNewShowing} autoComplete="off" value={(this.state.newShowing_agent_name != 0) ? this.state.newShowing_agent_name : ''} className="form-control" />
													<div className="box agentNameOnBoard" id="newShowing_agent_search_id_values" style={{ display: 'none' }} >
														<ul>{agentDWNewShowing}</ul>
													</div>
												</div>
												<span className="inline-error-class"> {this.state.agenterror !== '' ? this.state.agenterror : ''}   </span>
											</div>

										</div>
									</div>
									<div className="form-group text-right">
										<button id="createnewshowing" type="button" block='true'
											disabled={!this.validateForm()} className="btn btn cl-btn" onClick={() => this.createNewShowing()}>Create  <img src="/nextEditor-hover.png" /></button>
									</div>


								</div>

							</div>

						</div>
					</div>

					{/* Start Reassign confirmed showing */}
					<div className="modal fade requestBox" id="ReAssign" role="dialog">
						<div className="modal-dialog">


							<div className="modal-content">
								<div className="modal-header">

									<h4 className="modal-title">Reassign a showing</h4>
									<button type="button" className="close" data-dismiss="modal" id="ReAssignClose">&times;</button>
								</div>
								<div className="modal-body">
									<div className="form-horizontal">
										<small className="light-text">{(this.state.c_showingAddress) ? this.state.c_showingAddress : this.state.address_value}, {(this.state.c_showingUnit) ? this.state.c_showingUnit : this.state.unit_value}</small>
										<div className="form-group row" style={{ marginTop: '0' }} >
											<div className="control-label col-sm-4 light-text">
												<strong>{this.state.c_showingTime}</strong>
											</div>
											<div className="control-label col-sm-8 light-text">
												<strong>{this.state.c_showingDate}</strong>
											</div>
										</div>
										
										<div className="form-group row">
											<div className="control-label col-sm-12">
												<label>AGENT NAME</label>
												<div>
													<input type="text" id="c_agent_search_id" onChange={this.handleAgentSearch} onKeyDownCapture={this.handleAgentSearch}
														onKeyUpCapture={this.handleAgentSearch} autoComplete="off"
														value={(this.state.agent_name != 0) ? this.state.agent_name : ''} className="form-control" />
													<div className="box agentNameOnBoard" id="c_agent_search_id_values" style={{ display: 'none' }} >
														<ul>{clientDW}</ul>
													</div>
												</div>
												<span className="inline-error-class"> {this.state.c_agenterror !== '' ? this.state.c_agenterror : ''}   </span>
											</div>

										</div>
									</div>
									<div className="form-group text-right">
										<button type="button" onClick={this.confirmReAssignment} className="btn btn cl-btn">Reassign  <img src="/nextEditor-hover.png" /></button>
									</div>


								</div>

							</div>

						</div>
					</div>
					{/* End of confirmed showing */}

					{/* start assign modal */}
					<div className="modal fade requestBox" id="Assign" role="dialog">
						<div className="modal-dialog">


							<div className="modal-content">
								<div className="modal-header">

									<h4 className="modal-title">Assign a showing</h4>
									<button type="button" className="close" data-dismiss="modal" id="myAssignClose">&times;</button>
								</div>
								<div className="modal-body">
									<div className="form-horizontal">
										<div className="form-group row">
											<div className="control-label col-sm-12">
												<label>Address</label>
												<input type="text" className="form-control" readOnly placeholder={(this.state.showingAddress) ? this.state.showingAddress : this.state.address_value} />
											</div>
										</div>
										<div className="form-group row">
											<div className="control-label col-sm-12">
												<label>Unit</label>
												<input type="text" className="form-control" readOnly placeholder={(this.state.showingUnit) ? this.state.showingUnit : this.state.unit_value} />
											</div>
										</div>
										<div className="form-group row">

											<div className="control-label col-sm-4">
												<label>Time</label>
												<input type="text" className="form-control" readOnly defaultValue={this.state.showingTime} />
											</div>
											<div className="control-label col-sm-8">
												<label>Date</label>
												<input type="text" className="form-control" readOnly defaultValue={this.state.showingDate} />
											</div>
										</div>
										<div className="form-group row">
											<div className="control-label col-sm-12">
												<label>CLIENT NAME</label>
												<input type="text" className="form-control" readOnly defaultValue={(this.state.clientName) ? this.state.clientName : this.state.client_fullname} />
											</div>
										</div>
										<div className="form-group row">
											<div className="control-label col-sm-12">
												<label>AGENT NAME</label>
												<div>
													<input type="text" id="agent_search_id" onChange={this.handleAgentSearchAssign} onKeyDownCapture={this.handleAgentSearchAssign} onKeyUpCapture={this.handleAgentSearchAssign} autoComplete="off" value={(this.state.agent_name != 0) ? this.state.agent_name : ''} className="form-control" />
													<div className="box agentNameOnBoard" id="agent_search_id_values" style={{ display: 'none' }} >
														<ul>{agentDW}</ul>
													</div>
												</div>
												<span className="inline-error-class"> {this.state.agenterror !== '' ? this.state.agenterror : ''}   </span>
											</div>

										</div>
									</div>
									<div className="form-group text-right">
										<button type="button" onClick={this.confirmAssignment} className="btn btn cl-btn">Assign  <img src="/nextEditor-hover.png" /></button>
									</div>


								</div>

							</div>

						</div>
					</div>
					{/* end Assign modal */}

					{/* Cancel showing */}
					<div className="modal fade requestBox" id="cancelAvailableSchedule" role="dialog">
						<div className="modal-dialog">


							<div className="modal-content">
								<div className="modal-header">

									<h4 className="modal-title">Cancel a showing</h4>
								</div>
								<div className="modal-body">
									<div className="form-horizontal">
										<p className="title"><strong>ARE YOU SURE YOU WANT TO CANCEL YOUR SHOWING ?  </strong></p>
										<p>{this.state.showingAddress}, {this.state.showingUnit}</p>
										<div className="form-group row" style={{ fontSize: '14px', marginTop: '0' }}>
											<div className="control-label col-sm-4 light-text">
												<strong>{this.state.showingTime}</strong>
											</div>
											<div className="control-label col-sm-8 light-text">
												<strong>{this.state.showingDate}</strong>
											</div>
										</div>
										<div className="form-group row">

											<div className="control-label col-sm-4">
												<button type="button" className="btn btn-default" data-toggle="modal" onClick={this.confirmCancelShowing} >Yes</button>
											</div>
											<div className="control-label col-sm-4">
												<button type="button" id="closeCancelShowing" className="btn btn-small" data-dismiss="modal">No</button>
											</div>

										</div>
									</div>



								</div>

							</div>

						</div>
					</div>
					{/* End for cancel showing */}

					<Modal open={open} onClose={this.onCloseModal} >
						<div className="modal-header" >
							<h4 className="modal-title">Showing Detail</h4>
							<button type="button" className="close" data-dismiss="modal" onClick={this.onCloseModal}>&times;</button>
						</div>
						<div className="modal-body">
							<div className="form-horizontal">
								<div className="form-group row">
									<div className="control-label col-sm-12" >
										<label>Address: </label><span style={{ fontSize: '12px' }}>{this.state.showingAddressconfirm}</span>
										<div>

										</div>
									</div>
								</div>
								<div className="form-group row">
									<div className="control-label col-sm-6">
										<label>Unit: </label> <span style={{ fontSize: '12px' }}>{this.state.showingunitconfirm}</span>

									</div>

									<div className="control-label col-sm-6">
										{(this.state.confirm_split != "undefined") ? <React.Fragment><label>Split: </label><span style={{ fontSize: '12px' }}>{this.state.confirm_split}</span></React.Fragment> : <React.Fragment><label>Split: </label></React.Fragment>}

									</div>

								</div>


								<div className="form-group row">
									<div className="control-label col-sm-6">
										<label>Time:</label> <span style={{ fontSize: '12px' }}>{this.state.showing_time_confirm}</span>

									</div>
									<div className="control-label col-sm-6">
										<label>Date: </label> <span style={{ fontSize: '12px' }}>{this.state.showing_date_confirm}</span>

									</div>
								</div>


								<div className="form-group row">
									<div className="control-label col-sm-12">
										<label>Client Name:</label> <span style={{ fontSize: '12px' }}>{this.state.client_name_confirm}</span>

									</div>

								</div>


								<div className="form-group row">
									<div className="control-label col-sm-12">

										<label>Client number: </label><span style={{ fontSize: '12px' }}> {this.state.client_phone_confirm}</span>

									</div>


								</div>

								<div className="form-group row">
									<div className="control-label col-sm-12" >
										<label>Client Email: </label> <span style={{ fontSize: '12px' }}>{this.state.client_email_confirm}</span>

									</div>
								</div>

								<div className="form-group row">
									<div className="control-label col-sm-12" >
										<label>Agent Name: </label> <span style={{ fontSize: '12px' }}>{this.state.confirm_agent}</span>

									</div>
								</div>

								<div className="form-group row">
									<div className="control-label col-sm-12">
										{(this.state.confirm_notes != "undefined") ? <React.Fragment><label>Notes: </label><span style={{ fontSize: '12px' }}>{this.state.confirm_notes}</span></React.Fragment> : <React.Fragment><label>Notes: </label></React.Fragment>}


									</div>

								</div>

							</div>

						</div>
					</Modal>

					{/* <Modal open={open_mylead} onClose={this.onClosemyleadModal} >
        <div className="modal-header" >
                <h4 className="modal-title">Showing Detail</h4> 
                <button type="button" className="close" data-dismiss="modal" onClick={this.onClosemyleadModal}>&times;</button>
            </div>
                <div className="modal-body">
                <div className="form-horizontal">
                    <div className="form-group row">                                            
                        <div className="control-label col-sm-12" >
                            <label>Address:</label> <span style={{fontSize:'12px'}}>{this.state.showingAddressmylead}</span>
                            
                        </div>
                    </div>
				<div className="form-group row">                                            
                    <div className="control-label col-sm-6">
                        <label>Unit:</label> <span style={{fontSize:'12px'}}>{this.state.showingunitmylead}</span>
                        
                    </div>

                    <div className="control-label col-sm-6">
                    {(this.state.mylead_split!="undefined")?<React.Fragment><label>Split:</label> <span style={{fontSize:'12px'}}>{this.state.mylead_split}</span></React.Fragment>:<React.Fragment><label>Split:</label></React.Fragment>}
                        
                        
                    </div>
                </div>
                    <div className="form-group row">
                    <div className="control-label col-sm-6">
                                <label>Time:</label> <span style={{fontSize:'12px'}}>{this.state.showing_time_mylead}</span>
                                
                            </div>
                            <div className="control-label col-sm-6">
                                <label>Date:</label> <span style={{fontSize:'12px'}}>{this.state.showing_date_mylead}</span>
                                
                            </div>                                               
                    </div>


                    <div className="detail-box row">
                    <div className="detail-box col-sm-6">
                                <label>Bedrooms:</label> <span style={{fontSize:'12px'}}>{this.state.mylead_bedrooms}</span>
                               
                            </div>
                            <div className="detail-box col-sm-6">
                                <label>Bathrooms:</label> <span style={{fontSize:'12px'}}>{this.state.mylead_bathrooms}</span>
                               
                            </div> 

                                                                     
                    </div>

                    <div className="form-group row">                                            
                        <div className="control-label col-sm-12" >
                            <label>Price:</label> <span style={{fontSize:'12px'}}>$ {this.state.mylead_price}</span>
                           
                        </div>
                    </div>  

                    <div className="form-group row">                                            
                        <div className="control-label col-sm-12" >
                        {(this.state.mylead_notes!="undefined")?<React.Fragment> <label>Notes:</label> <span style={{fontSize:'12px'}}>{this.state.mylead_notes}</span></React.Fragment>:<React.Fragment> <label>Notes:</label></React.Fragment>}
                           
                           
                        </div>
                    </div>  
                    
                    </div>
                    
                    </div>
        </Modal> */}

					<Modal open={open_availead} onClose={this.onCloseavaileadModal} >
						<div className="modal-header" >
							<h4 className="modal-title">Showing Detail</h4>
							<button type="button" className="close" data-dismiss="modal" onClick={this.onCloseavaileadModal}>&times;</button>
						</div>
						<div className="modal-body">
							<div className="form-horizontal">
								<div className="form-group row">
									<div className="control-label col-sm-12" >
										<label>Address:</label>  <span style={{ fontSize: '12px' }}>{this.state.showingAddressavailead}</span>

									</div>
								</div>
								<div className="form-group row">
									<div className="control-label col-sm-6">
										<label>Unit:</label> <span style={{ fontSize: '12px' }}>{this.state.showingunitavailead}</span>
										<div>

										</div>
									</div>

									<div className="control-label col-sm-6">
										{(this.state.availead_split != "undefined") ? <React.Fragment> <label>Split: </label> <span style={{ fontSize: '12px' }}>{this.state.availead_split}</span></React.Fragment> : <React.Fragment> <label>Split:</label></React.Fragment>}


									</div>
								</div>

								<div className="form-group row">
									<div className="control-label col-sm-6">
										<label>Time: </label> <span style={{ fontSize: '12px' }}>{this.state.showing_time_availead}</span>

									</div>
									<div className="control-label col-sm-6">
										<label>Date: </label> <span style={{ fontSize: '12px' }}>{this.state.showing_date_availead}</span>

									</div>
								</div>

								<div className="form-group row">
									<div className="control-label col-sm-12">
										<label>Client Name:</label> <span style={{ fontSize: '12px' }}>{this.state.client_name_availead}</span>

									</div>

								</div>


								<div className="form-group row">
									<div className="control-label col-sm-12">

										<label>Client number: </label><span style={{ fontSize: '12px' }}> {this.state.client_phone_availead}</span>

									</div>


								</div>




								{/* <div className="detail-box row">
                    <div className="detail-box col-sm-6">
                                <label>Bedrooms:</label> <span style={{fontSize:'12px'}}>{this.state.availead_bedrooms}</span>
                                
                            </div>
                            <div className="detail-box col-sm-6">
                                <label>Bathrooms: </label> <span style={{fontSize:'12px'}}>{this.state.availead_bathrooms}</span>
                                
                            </div> 

                                                                     
                    </div>

                    <div className="form-group row">                                            
                        <div className="control-label col-sm-12" >
                            <label>Price: </label> <span style={{fontSize:'12px'}}>$ {this.state.availead_price}</span>
                            
                           
                        </div>
                    </div>  */}

								<div className="form-group row">
									<div className="control-label col-sm-12" >
										{(this.state.availead_notes != "undefined") ? <React.Fragment> <label>Notes:</label> <span style={{ fontSize: '12px' }}>{this.state.availead_notes}</span></React.Fragment> : <React.Fragment> <label>Notes:</label></React.Fragment>}


									</div>
								</div>

							</div>

						</div>
					</Modal>

				</main>
			</div>

		);
	}
}
export default ManagerShowings;
