import React from 'react';
import {Link} from 'react-router-dom';
import { getApiData, getToken, getUserId,getAgentId,postApiData,getAgentName,getAgentDomain,getOnBoardingDone,setAgent_number,getAgentImage,getBaseUrl} from '../../services/Api';
import ReactModal from 'react-modal';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import CryptoJS from 'crypto-js';
import {validatePhone} from '../../services/validation';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.nav_bar_col = React.createRef();
        
        this.state = {
            showSign: true,
            user_id: localStorage.getItem('user_id'),
            user_name: "",
            showModal:false,
            mobile_number:'',
            mobile_error:''
          }; 
          this.onLoad = this.onLoad.bind(this);
          this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentDidMount() {
        this.onLoad();
        this.agentmobileData();
    }

    agentmobileData = ()=>{
        let where=[{
                url:'/agent/agentmobile/'+getAgentId(),
                
        }];
        getApiData(where,true).then(res=>{
            this.setState({addmobile:res.data[0].mob_number});
            setAgent_number(res.data[0].mob_number);
            if(this.state.addmobile=='NA' || this.state.addmobile==''){
                this.setState({showModal: true});
            }
            else{
                this.setState({showModal: false});	
            }
        
        }).catch(error=>{

        })
}
handleCloseModal () {
    this.setState({ showModal: false });
  }


validation = () => {
    var regex =/^\d{3}-\d{3}-\d{4}$/g;
    const {

        mobile_number,
    }=this.state;

    return(
        mobile_number.toString().trim()!="" &&
        mobile_number.length >=12 && 
        regex.test(mobile_number)
    )

}

mobilenoSubmit=(event)=>{
    event.preventDefault();
    let key = encHex.parse("0123456789abcdef0123456789abcdef");
    let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
let mobile_no = aes.encrypt(this.state.mobile_number, key, {iv: iv, padding:padZeroPadding}).toString();

    let listingWhere=[{
        url:'/agent/agentmobile/'+getAgentId()
    }];
    postApiData(listingWhere,{'mob_number':mobile_no},true).then(res=>{
    if(res){
        this.handleCloseModal();
    }
        
    }).catch(error=>{

    })
}

handleChange=(event)=>{
   
    this.formValidate(event.target.id,event.target.value);
}
formValidate(key,value){
    switch(key){
            case 'phone_number':        
                     this.validatePhone(value);
                    break;

            }
}
validatePhone(value){
    let res=validatePhone(value);
                         if(res.status==false){
                                this.setState({mobile_error: 'Phone number is not valid.',mobile_number:res.value});
                                 //document.getElementById("phone_number").value=res.value;
                         }else{
                                 this.setState({mobile_error: '',mobile_number:res.value});
                                // document.getElementById("phone_number").value=res.value;
                         }
     return res.status;
}

    async onLoad(){
        this.setState({user_name: getAgentName()})
        if(getToken()){
           let where = [{
            url: "/Clients",
            urlParms: "/" + getUserId() + "?access_token=" + getToken()
        }];
    getApiData(where, false).then(res => {
        if(res.data.id){
            localStorage.setItem('user_id', res.data.userId);
            this.setState({'user_name':res.data.realm,'showSign':false});
        }
        
    }).catch(error => {
       
    }); 
        }
    }
    render() {
        
        return (
               <header className="fixedHeader">
                         
                <nav class="navbar navbar-expand-md bg-light navbar-dark">
                <a class="navbar-brand logo" href="#"><img src="/logo.png" width="100%" /></a> <span className="header-tag">Agent Dashboard</span>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <img src="/menu-icon.png"/>
                </button>
                <div class="collapse navbar-collapse " id="collapsibleNavbar">
                    <ul class="navbar-nav mr-auto">
                        <li className="nav-item"><Link to="/agent/application" lass="nav-link"> Home</Link></li> 
                        <li className="nav-item"><Link to="/agent/logout" lass="nav-link"> Logout</Link></li> 
                        <li className="nav-item"><Link to="/agent/application" lass="nav-link">Hi, {this.state.user_name}</Link></li>
                       <Link to="/agent/agentprofile">
                       {(getAgentImage()!=null && getAgentImage()!='' && getAgentImage()!='undefined' && getAgentImage()!='member.png')?(getAgentImage().startsWith("https://") || getAgentImage().startsWith("http://"))?<li className=""><img alt="Profile" src={getAgentImage()} width="40" className="user rounded-circle"/></li>:<li className=""><img alt="Profile" src={getBaseUrl()+"/containers/agent_image/download/"+getAgentImage()} width="40" className="user rounded-circle"/></li>:<li className=""><img alt="Profile" src="/member.png" width="40" className="user rounded-circle"/></li>}
                        {/* <li className="">{getAgentImage()?(<img alt="Profile" src={getAgentImage()} width="40" className="user rounded-circle"/>):(null)}</li> */}
                        </Link>
                    </ul> 
                </div>  
                </nav>
                <ReactModal  
   isOpen={this.state.showModal}
   onAfterOpen={this.afterOpenModal}
   contentLabel="onRequestClose Example"
   onRequestClose={this.handleCloseModal}
   shouldCloseOnOverlayClick={false} 
className="modal-dialog modal-sm">
    <div className="modal-content">
    <div className="modal-header" >
                        <h4 className="modal-title">Mobile Number</h4></div>
                        <div className="modal-body" >
<form  onSubmit={this.mobilenoSubmit}>
                            <input type="text"
                                    className="form-control"
                                    maxLength="12"
                                    placeholder="XXX-XXX-XXXX"
                                    id="phone_number"
              value={this.state.mobile_number}
              onChange={this.handleChange}
              
              />
                               
                                        <button
                    type="submit"
                 disabled={!this.validation()}
                    className="btn btn-default"
                    style={{ margin: "10px" }}
                >
                    Submit 
                </button>
                            </form>
                            </div>
                            <span className="inline-error-class">
                      {" "}
                      {this.state.mobile_error !== ""?this.state.mobile_error:''}
                    </span>	
                            </div>
                            
  {/* <button hidden='hidden' onClick={this.handleCloseModal}>Close Modal</button> */}
</ReactModal>
                </header>






                );
    }
}
export default Header;