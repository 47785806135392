import React, { Component } from 'react'
import PlaidLink from 'react-plaid-link'
import { postApiData} from '../../services/Api';
const CLIENT_ID=process.env.REACT_APP_PLAID_CLIENT_ID;
const SECRET_KEY=process.env.REACT_APP_PLAID_SECRET_KEY;
const ACCESS_KEY="";
const ITEM_ID="";
class plaidApi extends Component {
    
    constructor() {
        super();
        

        this.state = {
            accounts:[],
            institutions:{},
            publicTokenLogin:""
            
          };
        }

  handleOnSuccess=(token, metadata)=> {
      this.setState({accounts:metadata.accounts,institutions:metadata.institution,publicTokenLogin:token});
      
        let where = [{
            url: "https://sandbox.plaid.com/item/public_token/exchange",
            plaid:"true"
        }];
        let data={
            "client_id" :CLIENT_ID,
            "secret" :SECRET_KEY,
            "public_token":this.state.publicTokenLogin
        }
        postApiData(where,data).then(res => {
                // send token to client server
            })
            .catch(error => {
                console.log(error);
            });
  }
  handleOnExit() {
    // handle the case when your user exits Link
  }
  render() {
    return (
        <div className="plaid-tab">
      <PlaidLink
        clientName={process.env.REACT_APP_PLAID_CLIENT_ID}
        env="sandbox"
        product={["auth", "transactions"]}
        publicKey={process.env.REACT_APP_PLAID_PRODUCT_KEY}
        onExit={this.handleOnExit}
        webhook='http://localhost:3001/client/plaid'
        onSuccess={this.handleOnSuccess}>
        Open Link and connect your bank!
      </PlaidLink>
      {/* <br/>
<PlaidLink
        clientName={process.env.REACT_APP_PLAID_CLIENT_ID}
        env="sandbox"
        item={["auth", "transactions"]}
        publicKey="c6e41e9e2ba17765e24ea5e1cc3f0d"
        onExit={this.handleOnExit}
        onSuccess={this.handleOnSuccess}>
        Open Link and connect your bank!
      </PlaidLink> */}
      
<table>
<tr>
    <td>Id</td><td>Name</td><td>Mass</td><td>Type</td></tr>
 {this.state.accounts.map((account,i) => {
   return <tr>
      <td>{account.id}</td>
      <td>{account.name}</td>
      <td>{account.mass}</td>
      <td>{account.type}</td>
      </tr>
})}

</table>
</div>
    )
  }
}
export default plaidApi