import React from 'react';
import ScrollspyNav from "react-scrollspy-nav";
import $ from 'jquery';
import '../manual/highlight';
import '../manual/finder';
import 'jquery.scrollto';
import { getApiData, postApiData, getAgentId,getBaseUrl } from "../../services/Api";

class Welcome extends React.Component {

  constructor(props){
    super(props);
    this.state = {
    pageContent:'',
    linkingLoader:false,
    editable:getAgentId()>0 || getAgentId()==0?"false":"true"
    }
  }
  componentWillMount(){
		this.getPageContent();
	}
  getPageContent=()=>{
         
		let where = [{
			url:'/managers/manualPageContent/'
		}]
		postApiData(where,{'page':'welcome_manual_content'},true).then(res=>{
				this.setState({
					pageContent:res.data[0].html_value,
					
				},()=>{
          document.getElementById('result-list').innerHTML=unescape(this.state.pageContent);
        })
		
			
		}).catch(error=>{

		})
  }
  contentSave=(type)=>e=>{
		this.setState({linkingLoader:true})
		var divValue='';

		 divValue=document.getElementById('result-list').innerHTML;
	
		let where=[{
			url:'/managers/manualContetSave'
		}];
		// var formData = new FormData();
		// formData.append('content',abc);
		
		postApiData(where,{'page':'welcome_manual_content','content':divValue},true).then(res=>{
			
				this.setState({linkingLoader:false},()=>{
				alert("Welcome Manual Published Successfully.")
				})
				
		}).catch(error=>{

		})
	} 
    render() {
        return (
          <div class="manual-section" > 
          
          <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-light" >
          <img src="/logo.png" class="img-fluid logo" style={{maxWidth:'300px'}}/>
 
 
    <ul class="navbar-nav mr-auto p-1 m-auto">
      <li class="nav-item d-none d-sm-block">
       
      </li>
    </ul>
    <ul class="navbar-nav d-none d-md-block d-lg-block d-xl-block col-md-2 text-right">
      <li class="nav-item ml-2">
      <button className="btn my-2 my-sm-0 finder-activator" type="submit" data-finder-activator data-toggle="collapse" data-target="#demo">
              <i className="fa fa-search"></i>
            </button>
        {getAgentId()>0 || getAgentId()==0 ?'' :<button class="btn btn-default pull-right saveBtnManual" onClick={this.contentSave('approve')}>Publish</button>}</li>

    </ul>
    <ul class="navbar-nav d-block d-sm-none">
      <li class="nav-item ml-2">
          <button class="btn my-2 my-sm-0 finder-activator" type="submit" data-toggle="collapse" data-target="#demo">
            <i class="fa fa-search"></i>
          </button>
      
      
      <button class="btn btn-default pull-right saveBtnManual" >SAVE</button></li>
    </ul>

</nav>
<div class="container-fluid">
          <div class="row">
          <div class="col-xl-9 col-lg-9 col-xs-12 p-1 divtopmargin right content" id="result-list" data-finder-content contenteditable={this.state.editable}>
  

</div>
<nav class="d-none d-sm-none d-md-block col-xl-3 col-lg-4 left" id="myScrollspy">
      <div class="leftdiv col-xl-3 col-sm-4" style={{paddingLeft:'0px'}}>
      <ScrollspyNav scrollTargetIds={["Part-One", "Information", "Core-Beliefs","Who-Who-Roles","Part-Two","STEPS-TO-BUILDING-SALES-BUSINESS","BEST-PRACTICES-FOR-RENTAL-AGENTS","CHECKLIST-KEYS-FOR-ASUCCESSFUL-START","PART-THREE-COST-SUMMARY","START-UP-COST","TECH-PACKAGE","PART-FOUR-OFFICE-POLICY","Office-Policies","Access-Offices","Mailboxes","Key-Policy","SKYWARD-GOOGLE-DRIVE","Apps-Download", "FEE-AGREEMENTS-DISCLOSURE-FORMS","Social-Media-Policy","FINANCIAL-POLICIES", "HOW-TO-GET-PAID", "Payment-Processes","PART-FIVE-MARKETING", "Marketing-Materials", "OUR-SERVICE-PARTNERS", "LISTING-FEE-POLICY", "PART-SIX", "FEE-AGREEMENTS-DISCLOSURE-FORMS", "APPLICATION-PROCESS", "REMINDER"]} activeNavClass="active" scrollDuration="1500" headerBackground="false">
      <ul class="nav nav-pills flex-column" style={{paddingRight:'15px'}}>
		  <li class="nav-item">
          <a class="nav-link" href="#Part-One">Part One</a>    
			<ul style={{paddingLeft:'20px'}} type="none">
			<li>
				  <a class="nav-link" href="#Information">Important Information </a>          
				</li>
				<li> <a class="nav-link" href="#Core-Beliefs">Core Beliefs and Values</a></li>
				<li> <a class="nav-link" href="#Who-Who-Roles">Who's Who-Roles and Responsibilities</a></li>
			</ul>
        </li>
        
        <li class="nav-item">
          <a class="nav-link" href="#Part-Two">PART TWO</a>
          <ul style={{paddingLeft:'20px'}} type="none">
            <li>
              <a class="nav-link" href="#STEPS-TO-BUILDING-SALES-BUSINESS">Best Practices for Sales Agents</a>
            </li>
            <li>
              <a class="nav-link" href="#BEST-PRACTICES-FOR-RENTAL-AGENTS">BEST PRACTICES FOR RENTAL AGENTS</a>
            </li>
            <li>
              <a class="nav-link" href="#CHECKLIST-KEYS-FOR-ASUCCESSFUL-START">CHECKLIST — KEYS FOR A SUCCESSFUL START</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#PART-THREE-COST-SUMMARY">PART THREE</a>
          <ul style={{paddingLeft:'20px'}} type="none">
            <li>
              <a class="nav-link" href="#START-UP-COST">START-UP COST</a>
            </li>
            <li>
              <a class="nav-link" href="#TECH-PACKAGE">TECH PACKAGE</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#PART-FOUR-OFFICE-POLICY">PART FOUR</a>
          <ul style={{paddingLeft:'20px'}} type="none">
            <li>
              <a class="nav-link" href="#Office-Policies">Office Policies</a>
			  <ul style={{paddingLeft:'20px'}} type="none">
					<li>
					  <a class="nav-link" href="#Access-Offices">Access to Offices</a>
					 </li>
					  <li>
					  <a class="nav-link" href="#Mailboxes">Mailboxes</a>
					 </li>
					  <li>
					  <a class="nav-link" href="#Key-Policy">Key Policy</a>
					 </li>
					<li>
					  <a class="nav-link" href="#SKYWARD-GOOGLE-DRIVE">Google Drive</a>
					</li>
					<li>
					  <a class="nav-link" href="#Apps-Download">Apps to Download</a>
					</li>
					<li>
					  <a class="nav-link" href="#FEE-AGREEMENTS-DISCLOSURE-FORMS">Fee Agreements and Disclosures</a>
					</li>
					<li>
					  <a class="nav-link" href="#Social-Media-Policy">Social Media Policy</a>
					</li>
					 
				 </ul>			  
            </li>

			<li>
              <a class="nav-link" href="#FINANCIAL-POLICIES">FINANCIAL POLICIES</a>
			  
			  <ul style={{paddingLeft:'20px'}} type="none">
					<li>
					  <a class="nav-link" href="#HOW-TO-GET-PAID">HOW TO GET PAID</a>
					 </li>
					 <li>
					  <a class="nav-link" href="#Payment-Processes">Payment Processes</a>
					 </li>
       
				 </ul>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#PART-FIVE-MARKETING">PART FIVE</a>
          <ul style={{paddingLeft:'20px'}} type="none">
            <li>
              <a class="nav-link" href="#Marketing-Materials">Marketing Materials</a>
            </li>
            <li>
              <a class="nav-link" href="#OUR-SERVICE-PARTNERS">OUR SERVICE PARTNERS</a>
            </li>
			<li>
              <a class="nav-link" href="#LISTING-FEE-POLICY">LISTINGS AND LISTING FEE POLICY</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#PART-SIX">PART SIX</a>
          <ul style={{paddingLeft:'20px'}} type="none">
            <li>
              <a class="nav-link" href="#FEE-AGREEMENTS-DISCLOSURE-FORMS">Fee Agreements, Disclosures</a>
            </li>
            <li>
              <a class="nav-link" href="#APPLICATION-PROCESS">APPLICATION PROCESS</a>
            </li>
            <li>
              <a class="nav-link" href="#REMINDER">REMINDER</a>
            </li>
          </ul>
        </li>
      </ul>
      </ScrollspyNav>
      </div>
    </nav>
    <div className="finder active collapse" id="demo">
              <input id="finderInput" type="text" className="finder-input" onChange={this.searchDem} placeholder="Search Here..."/>
                <button id="finderPrev" className="btn btn-finder btn-finder-prev">
                  <i className="fa fa-angle-up"></i>
                </button>
                <button id="finderNext" className="btn btn-finder btn-finder-next">
                  <i className="fa fa-angle-down"></i>
                </button>
                <button id="finderClose" data-toggle="collapse" data-target="#demo" className="btn btn-finder btn-finder-close">
                  <i className="fa fa-times"></i>
                </button>
              </div>
              </div>
  
             </div>

    
       </div>     
);
    }
}
export default Welcome;