import React from 'react';
import Header from '../common/Header';
import { Link } from 'react-router-dom';
import {postApiData} from '../../services/Api';
import { Button, FormGroup, FormControl } from "react-bootstrap";


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mymsg: "",
            myerrormsg: "",
            password: "",
            confirm_password:"",
            redirect: false
        };

    }
    componentDidMount() {
       
    }

    async forgotPassword() {
        let where = [{
                url: "/Clients/reset-password?"+this.props.match.params.access_token
            }];
        const {password,confirm_password} = this.state;
        if (password.length < 6) {
            this.setState({ myerrormsg: "Password Min Length 6 chars" });
        } else {
             if (password !== confirm_password) {
                this.setState({ myerrormsg: "Confirm Password not matched." });
            } else {
                 postApiData(where, {'newPassword': password}).then(res => {
            if(res.data.success){
                this.setState({mymsg: 'Password reset successfully, Please login to continue.'});
                this.setState({myerrormsg: '',password:'',confirm_password:''}); 
            }
        })
        .catch(error => {
            this.setState({myerrormsg: error.response.data.error.message});
        });
            }
           
        }
        
    }
    validateForm() {
        return this.state.password.length > 0 &&  this.state.confirm_password.length > 0;
    }
    handleChange = (event) => {
        switch (event.target.id) {
            case 'password':
                if (event.target.value.length <= 0) {
                    this.setState({myerror: 'Field is required.'});
                } else if(event.target.value.length < 6){
                    this.setState({ myerror: 'Minimum length 6 characters'});
                }else {
                    this.setState({ myerror: '' });

                }
                break;
            case 'confirm_password':
                if (event.target.value.length <= 0) {

                    this.setState({ myerror2: 'Field is required.'});
                }else if(event.target.value.length < 6){
                    this.setState({ myerror2: 'Minimum length 6 characters'});
                }
                else if (event.target.value !== document.getElementById('password').value) {
                    this.setState({
                        myerror2: "Password don't match!"
                    });
                } else {
                    this.setState({ myerror2: '',});
                }
                break;

        }
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.forgotPassword();
    }
    render() {
        return (
                <div>
                    <Header />
                    <main>
                    <div className='container'>
                            <h2 className="text-center">Reset Password</h2>
                            {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
                            {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                            <div className='form-widget login-widget'>
                            <form onSubmit={this.handleSubmit}>
         <FormGroup controlId="password">
           <FormControl
             value={this.state.password}
             onChange={this.handleChange}
             type="password"
             placeholder="Password"
           />
           <span className="inline-error-class"> {this.state.myerror !== '' ? this.state.myerror : ''}</span>
         </FormGroup>
         <FormGroup controlId="confirm_password">
           <FormControl
             value={this.state.confirm_password}
             onChange={this.handleChange}
             type="password"
             placeholder="Confirm Password"
           />
           <span className="inline-error-class"> {this.state.myerror2 !== '' ? this.state.myerror2 : ''}</span>
         </FormGroup>
         <Button
           block
           disabled={!this.validateForm()}
           type="submit"
         >
           Submit
         </Button>
       </form>
                                
                            </div>
                
                        </div>
                    </main>
                </div>
                );
    }
}

export default ResetPassword;
