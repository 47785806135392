import React from 'react';
import { Link } from 'react-router-dom';
import AgentHeader from '../../common/AgentHeader';
import AgentSideBar from '../../common/AgentSideBar';
import ManagerHeader from '../../common/ManagerHeader';
import ManagerSideBar from '../../common/ManagerSidebar';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Moment from 'moment';
import {getApiData,postApiData,getBaseUrl,getAgentId, getAgentName,getAgent_number,getAgentEmail,deleteApiData} from '../../../services/Api';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { fromBlob } from 'image-resize-compress';
import { connect } from 'tls';
import FeedFilterList from '../../common/FeedFilterList';
//import InfiniteScroll from 'react-infinite-scroller';
//import InfiniteScroll from 'react-infinite-scroll-component';
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import SalesFeedFilterList from '../../common/FeedFilterSalesList';
import DragSortableList from 'react-drag-sortable';
import { isNullOrUndefined } from 'util';
import Dropzone  from 'react-dropzone';
let sortedListVar;
const checkedBoxes =[];
const mareketcheckedBoxes =[];
let key = encHex.parse("0123456789abcdef0123456789abcdef");
	let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
class ListingFeed extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
      isChecked: false,
            rentalListing:[],
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			searchClientId:'',
			searchParm:"",
      addressDW:[],
      marketBoxes:[],
      agent_email_id:'',
      contact_send_email:'',
      statusFil:3,
      contact_to_subject:'Requesting more information on',
      contact_email_content:'',
			nameSearch:false,
			statusSearch:false,
			where:{},
			linkingLoader:true,
			clickedClientId:0,
			searchMsg:false,
      myerrormsg:"",
      feedlist_id:'',
      imageIdFromDb:[],
      imageIdForDb:[],
      filterValues:{
        selectedBuilding:'',
        selectedBuildingId:'',
        selectedCompany:'',
        selectedBedRooms:'',
        selectedBathRooms:'',
        selectedManhattan:'',
        selectedQueens:'',
        selectedStatenIsland:'',
        selectedBronx:'',
        selectedBrooklyn:'',
        selectedNewJersey:'',
        maxPrice:-1,
        minPrice:-2,
       
        amenitiesList:'',
        buildingamenities:'',
        nestoImages:'',
       
        zipList:[],
        vr:0,
        showonly:0,
        selectedMedia:'',
        recordPerPage:0,
        recordNextPage:50,
        orderBy:'',
        orderByState:''
                      },
      isListingExist:false,
      totalRecords:0,
      totalRlsRecords:0,
      totalOpenRecords:0,
      totalMarketRecords:0,
      isMarketable:1,
            value: { min: 0, max: 10500 },
            kuula_vr_link:'',
            listing_info_address:"",
            bedrooms:"",
            bathrooms:"",
            furnished:0,
            date_available:"",
            square_footage:"",
            neighborhood:"",
            incentives:"",
            tenant_incentive:"",
            description:"",
            price:"",
            listing_information:'',
            amenity_list:[],
            image_list:[],
            date:'',
            time:'',
            listingImages:[],
            isImageExist:false,
            imagePathArray: [],
            imageForDb:[],
            agent_id:getAgentId(),
            newImageCount:0,
            listing_feed_id:'',
            imageCount:0,
            showhide:'',
            priceSearch:{min: 100, max: 10500},
            neighborDW:[],
            brokerDW:[],
            neighoberData:[],
            brokerData:[],
            listingAddressData:[],
            ckPending:{'checked':true,'value':1},
            ckApproved:{'checked':true,'value':1},
            ckExpired:{'checked':true,'value':1},
            ckRelist:{'checked':true,'value':1},
            ckbedroom_0:{'checked':true,'value':1},
            ckbedroom_1:{'checked':true,'value':1},
            ckbedroom_2:{'checked':true,'value':1},
            ckbedroom_3:{'checked':true,'value':1},
            ckbedroom_4:{'checked':true,'value':1},
            ckbathroom_0:{'checked':true,'value':1},
            ckbathroom_1:{'checked':true,'value':1},
			      ckbathroom_2:{'checked':true,'value':1},
			      ckbathroom_3:{'checked':true,'value':1},
            ckbathroom_4:{'checked':true,'value':1},
            total_l_checked:'',
            rls_l_checked:'',
            open_l_checked:'',
            market_l_checked :'',
            searchNeighbor:'',
            searchListingnId:'',
            searchNeighborhood:'',
            searchBroker:'',
            hasMoreItems: true,
            nextHref: null,
            nextpage:50,
            firstPage:1,
            filterType:1,
            orderBy:'',
            orderByState:'',
            rent_hop_export:'',
            contact_name:'',
            phone:'',
            email:'',
            listingfeedType:'',
            existCheckboxSelection:true,
            isCheckboxSelected:true,
            amenityData:[],
            checkedBoxes:[],
            validate:true,
            price_error:'',
            bedrooms_error:'',
            bathrooms_error:'',
            square_footage_error:'',
            description_error:'',
            date_error:'',
            startDate:'',
            imagePath2:'',
            flag:0,
            imagePath3:'',
            layoutData:'',
            type:'Rental',
            hideandshow:0,
            selected_name:[],
            selected_email:[],
            content_value:"I found a new listing that I thought you'd be interested in-feel free to check through the links for more photos and information. Be sure to let me know what you think!",
            mail_subject:'',
            selected_list_id:[],
            selected_list_id_num:[],
            multichk_name:[],
            multichk_email:[],
            mail_subject_multiple:'',
            content_value_multiple:"I found a few listings that I thought you'd be interested in-feel free to check through the links for more photos and information. Be sure to let me know what you think!",
            total_rooms:"",
            total_rooms_error:'',
            owner_pays:'',
            commission_percentage:'',
            renthop_approval:true,
            skyward_approval:true,
            streetEasy_approval:true,
      my_split:0,
      co_broker_split:100,
      final_precentage:0,
      marketing:[],
      min_lease_term:'12',
            unregistered_clients_email:[],
            unregistered_clients_email1:[],
            unregistered_clients_email1_error:'',
            unregistered_feedclients_email1:[],
            unregistered_feedclients_email1_error:'',
            pets_policy:"",
            building_ownership:'Multifamily',
            feedfilterdata:[],
            action:'',
            feedfilteractionId:'',
            frequencyId:'',
            frequency:'',
            feedfiltercli_name:[],
            feedfiltercli_email:[],
            feedfilterEmaillist:'',
            listing_agent_id:'',
            agentImag:'',
            listingData:[],
  };
  this.setWrapperRef1 = this.setWrapperRef1.bind(this);
	this.setWrapperRef2 = this.setWrapperRef2.bind(this);
	this.setWrapperRef3 = this.setWrapperRef3.bind(this);
	this.setWrapperRef4 = this.setWrapperRef4.bind(this);
  this.setWrapperRef5 = this.setWrapperRef5.bind(this);
  this.setWrapperRef6 = this.setWrapperRef6.bind(this);
  this.setWrapperRef10 = this.setWrapperRef10.bind(this);
  this.setWrapperRef12 = this.setWrapperRef12.bind(this);
  this.setWrapperRef13 = this.setWrapperRef13.bind(this); 
  this.setWrapperRef14 = this.setWrapperRef14.bind(this);
	this.onSort = this.onSort.bind(this);
  this.handleClickOutside = this.handleClickOutside.bind(this);
  this.onDrop = this.onDrop.bind(this);
   // this.createWhere();
    this.imageSubmit=this.imageSubmit.bind(this);
    // this.imageUpdate=this.imageUpdate.bind(this);
   this.iScroll = React.createRef();
    
    this.handleShowImage= this.handleShowImage.bind(this);
  
}
 //ClientHeadClick(){ document.getElementById('broker').classList.remove("show");}
	StatusHeadClick(){document.getElementById('search').classList.remove("show");}
  //StatusNeighborClick(){document.getElementById('neighbor').classList.add("show");}
 // StatusBrokerClick(){document.getElementById('broker').classList.add("show");}
  //StatusBathClick(){document.getElementById('bath').classList.add("show");}

	// resetCheckbox=()=>
	// 	{
	// 		this.setState({checkedBoxes:[]});
		
  // 	}
  
  onSort =(sortedList, dropEvent)=> {
    console.log("sortedList", sortedList);
	sortedListVar = sortedList;

	if( typeof sortedListVar != 'undefined' && sortedListVar instanceof Array ){
		console.log("in saveListOrder--", sortedListVar);
		console.log("length--", sortedListVar.length);
		let tempArr = [];
		


		if (sortedListVar.length > 0) {
			
			sortedListVar.map((item,i) => {
			
				if(!isNullOrUndefined(item.rank)){
					tempArr[i] = item.content.props.children.props.children.props.id+'-'+item.rank;
					//tempRank[i]= item.rank;
					
					console.log('item.content.key',item.content.key)
					
					
					
				}
				
			})



	const formData = new FormData();
	formData.append('file',tempArr);
  
	let listingWhere=[{
		url:'/managers/listingImageOrderUpdate'
	}];
	
	postApiData(listingWhere,formData,true).then(
		
    this.feedDetailById(this.state.list_id),
    this.getlazyLoadList()
		
	)


	 	}
		
	}
}

rotate= param=>event =>{
  const { imagePathArray } = this.state;
  var index = param[1];
  let newRotation = param[2]+90;
  if(newRotation >= 360){
    newRotation =- 360;
  }
  this.setState(state => (this.state.imagePathArray[index].current_rotation = newRotation))
  this.setState(state => (this.state.imageForDb[index].current_rotation = newRotation))
}

rotateleft= param=>event =>{
  const { imagePathArray } = this.state;
  var index = param[1];
  let newRotation = param[2]-90;
  if(newRotation >= 360){
    newRotation =- 360;
  }
  this.setState(state => (this.state.imagePathArray[index].current_rotation = newRotation))
  this.setState(state => (this.state.imageForDb[index].current_rotation = newRotation))
}

rotateimagelist= param=>event =>{
  const { listingImages } = this.state;
  var index = param[1];
  let newRotation = param[2]+90;
  if(newRotation >= 360){
    newRotation =- 360;
  }
  this.setState(state =>(listingImages.map((item,i)=>{
    if(index==i){
      item.rotation=newRotation
    }
  })
  ))

  let where = [{
    url: `/creator/updateimagerotation/${listingImages[index].id}`
}];

postApiData(where, {"rotation": newRotation},true)
.then(res => {
    if(res.data)
    {
      this.setState({'pageLoader':false});
    }
            
}).catch(error => {
this.setState({myerrormsg: error.response});
});

}

rotateleftimagelist= param=>event =>{
  this.setState({'pageLoader':true});
  const { listingImages } = this.state;
  var index = param[1];

  let newRotation = param[2]-90;
  if(newRotation >= 360){
    newRotation =- 360;
  }
  this.setState(state =>(listingImages.map((item,i)=>{
    if(index==i){
      item.rotation=newRotation
    }
  })
  ))

  let where = [{
    url: `/creator/updateimagerotation/${listingImages[index].id}`
}];

postApiData(where, {"rotation": newRotation},true)
.then(res => {
    if(res.data)
    {
      this.setState({'pageLoader':false});
    }
            
}).catch(error => {
this.setState({myerrormsg: error.response});
});

}

onDrop = (files) => {
  var imageFile = files;


  if (files) {
    for(var i=0;i<imageFile.length;i++){
    let imgFlag = true;
    const maxFileSize = 5242880; //5mb
    const value = files[i].name;
  
    const ext = value.substring(value.lastIndexOf("."));
    if (![".jpg", ".JPG", ".jpeg", ".png"].includes(ext)) {
      this.setState({ mymsg: ext + " is not supported file extension." });
      imgFlag = false;
    }
    if (files[i].size > maxFileSize) {
      this.setState({ mymsg: "The uploaded image is too large. The max image file size is set to 5MB" });
      imgFlag = false;
    }
    if (imgFlag) {        
        fromBlob(imageFile[i], 80, 'auto', 'auto', 'webp')
          .then(compressedFile => {
         
          var file = new File([compressedFile], value);
          var temp = [...this.state.imagePathArray];
          var temp2 = [...this.state.imageForDb];

          temp.push({file:URL.createObjectURL(file),"current_rotation":0});
          temp2.push({file:file,"current_rotation":0});
          this.setState(
            {
              imageForDb: temp2,
              imagePathArray: temp
            },
            () => {
              if(this.state.count ==true){
                var totalLength = this.state.imagePathArray.length;
               
                var final=this.state.newImageCount + totalLength;
                
            this.setState({
              newImageCount:final
            })
            if(this.state.newImageCount==0){
              this.setState({
                newImageCount:this.state.imagePathArray.length
              })
            }
          }else{
            this.setState({
              newImageCount:this.state.imagePathArray.length
            })
          }
            

              }
          );
        })
        .catch(function(error) {
          console.log(error.message);
        });
    }
  }
  }
};


	resetsearchParm=()=>{
		this.setState({
      neighborDW:[],
      brokerDW:[],
      listingAddressData:[],
			where:{},
			searchListingnId:'',
      searchNeighborhood:'',
      brokerSearch:'',
			searchParm:"",
			myerrormsg:"",
		  },()=>{
      document.getElementById('searchParm').value='';
      document.getElementById('search').classList.remove("show");
     /* document.getElementById('searchNeighbor').value='';
      document.getElementById('searchBroker').value='';
			document.getElementById('searchClick').classList.remove("active");
      
      document.getElementById('neighborClick').classList.remove("active");
			document.getElementById('brokerClick').classList.remove("active");*/

			this.getRentalListing('reset');
			//this.createWhere();

		  });
	}
	searchByPrice()
	{
		if(this.state.priceSearch.min==100 && this.state.priceSearch.max==10500){
			document.getElementById('priceClick').classList.remove("active");
		}else{
			document.getElementById('priceClick').classList.add("active");
    }
    
        this.createWhere();
	}
	search=(event) =>{
		//this.setState({[event.target.id]: event.target.value});
		//console.log("event",event);
			//console.log("event.target.id",event.target.id,"event.target.value",event.target.value);
	 var key=event.target.id;
	  switch(key){
		
		case 'Pending':
			if(this.state.ckPending.value==0 && this.state.ckPending.checked==false){
				this.state.ckPending.checked=true;
				this.state.ckPending.value=1;
				this.state.statusFil=1;
				this.createWhere();
			}else if(this.state.ckPending.value==1 && this.state.ckPending.checked==true){
				this.state.ckPending.checked=false;
				this.state.ckPending.value=0;
				this.createWhere();
			}
			break;
		case 'Approved':
		if(this.state.ckApproved.value==0 && this.state.ckApproved.checked==false){
			this.state.ckApproved.checked=true;
			this.state.statusFil=1;
			this.state.ckApproved.value=1;
			this.createWhere();
		}else if(this.state.ckApproved.value==1 && this.state.ckApproved.checked==true){
			this.state.ckApproved.checked=false;
			this.state.ckApproved.value=0;
			this.createWhere();
		}        
			break;
		case 'Expired': 
		if(this.state.ckExpired.value==0 && this.state.ckExpired.checked==false){
			this.state.ckExpired.checked=true;
			this.state.statusFil=1;
			this.state.ckExpired.value=1;
			this.createWhere();
		}else if(this.state.ckExpired.value==1 && this.state.ckExpired.checked==true){
			this.state.ckExpired.checked=false;
			this.state.ckExpired.value=0;
			this.createWhere();
		}        
      break;
      case '0_bedroom':
      if(this.state.ckbedroom_0.value==0 && this.state.ckbedroom_0.checked==false){
        this.state.ckbedroom_0.checked=true;
        this.state.ckbedroom_0.value=1;
        this.state.statusFil=1;
        this.createWhere();
      }else if(this.state.ckbedroom_0.value==1 && this.state.ckbedroom_0.checked==true){
        this.state.ckbedroom_0.checked=false;
        this.state.ckbedroom_0.value=0;
        this.createWhere();
      }
      break;  
		case '1_bedroom':
		if(this.state.ckbedroom_1.value==0 && this.state.ckbedroom_1.checked==false){
			this.state.ckbedroom_1.checked=true;
			this.state.ckbedroom_1.value=1;
			this.state.statusFil=1;
			this.createWhere();
		}else if(this.state.ckbedroom_1.value==1 && this.state.ckbedroom_1.checked==true){
			this.state.ckbedroom_1.checked=false;
			this.state.ckbedroom_1.value=0;
			this.createWhere();
		}
		break;
		case '2_bedroom':
		if(this.state.ckbedroom_2.value==0 && this.state.ckbedroom_2.checked==false){
			this.state.ckbedroom_2.checked=true;
			this.state.ckbedroom_2.value=1;
			this.state.statusFil=1;
			this.createWhere();
		}else if(this.state.ckbedroom_2.value==1 && this.state.ckbedroom_2.checked==true){
			this.state.ckbedroom_2.checked=false;
			this.state.ckbedroom_2.value=0;
			this.createWhere();
		}
		break;
		case '3_bedroom':
		if(this.state.ckbedroom_3.value==0 && this.state.ckbedroom_3.checked==false){
			this.state.ckbedroom_3.checked=true;
			this.state.statusFil=1;
			this.state.ckbedroom_3.value=1;
			this.createWhere();
		}else if(this.state.ckbedroom_3.value==1 && this.state.ckbedroom_3.checked==true){
			this.state.ckbedroom_3.checked=false;
			this.state.ckbedroom_3.value=0;
			this.createWhere();
		}        
			break;
		case '4_bedroom': 
		if(this.state.ckbedroom_4.value==0 && this.state.ckbedroom_4.checked==false){
			this.state.ckbedroom_4.checked=true;
			this.state.statusFil=1;
			this.state.ckbedroom_4.value=1;
			this.createWhere();
		}else if(this.state.ckbedroom_4.value==1 && this.state.ckbedroom_4.checked==true){
			this.state.ckbedroom_4.checked=false;
			this.state.ckbedroom_4.value=0;
			this.createWhere();
		}        
      break;
      case '0_bathroom':
			if(this.state.ckbathroom_0.value==0 && this.state.ckbathroom_0.checked==false){
				this.state.ckbathroom_0.checked=true;
				this.state.ckbathroom_0.value=1;
				this.state.bathFil=1;
				this.createWhere();
			}else if(this.state.ckbathroom_0.value==1 && this.state.ckbathroom_0.checked==true){
				this.state.ckbathroom_0.checked=false;
				this.state.ckbathroom_0.value=0;
				this.createWhere();
			}
			break;
      case '1_bathroom':
			if(this.state.ckbathroom_1.value==0 && this.state.ckbathroom_1.checked==false){
				this.state.ckbathroom_1.checked=true;
				this.state.ckbathroom_1.value=1;
				this.state.bathFil=1;
				this.createWhere();
			}else if(this.state.ckbathroom_1.value==1 && this.state.ckbathroom_1.checked==true){
				this.state.ckbathroom_1.checked=false;
				this.state.ckbathroom_1.value=0;
				this.createWhere();
			}
			break;
			case '2_bathroom':
			if(this.state.ckbathroom_2.value==0 && this.state.ckbathroom_2.checked==false){
				this.state.ckbathroom_2.checked=true;
				this.state.ckbathroom_2.value=1;
				this.state.bathFil=1;
				this.createWhere();
			}else if(this.state.ckbathroom_2.value==1 && this.state.ckbathroom_2.checked==true){
				this.state.ckbathroom_2.checked=false;
				this.state.ckbathroom_2.value=0;
				this.createWhere();
			}
			break;
			case '3_bathroom':
			if(this.state.ckbathroom_3.value==0 && this.state.ckbathroom_3.checked==false){
				this.state.ckbathroom_3.checked=true;
				this.state.bathFil=1;
				this.state.ckbathroom_3.value=1;
				this.createWhere();
			}else if(this.state.ckbathroom_3.value==1 && this.state.ckbathroom_3.checked==true){
				this.state.ckbathroom_3.checked=false;
				this.state.ckbathroom_3.value=0;
				this.createWhere();
			}        
				break;
			case '4_bathroom': 
			if(this.state.ckbathroom_4.value==0 && this.state.ckbathroom_4.checked==false){
				this.state.ckbathroom_4.checked=true;
				this.state.bathFil=1;
				this.state.ckbathroom_4.value=1;
				this.createWhere();
			}else if(this.state.ckbathroom_4.value==1 && this.state.ckbathroom_4.checked==true){
				this.state.ckbathroom_4.checked=false;
				this.state.ckbathroom_4.value=0;
				this.createWhere();
			}        
				break;		  	
		case 'searchParm':
			this.setState({searchParm:event.target.value});
			//if(this.state.searchParm.length>1){	
        //console.log("this.state.searchParm",event.target.value,this.state.listingAddressData);
				var value=this.state.searchParm.trim();
				if(event.key=='Enter'){
					this.getData(value);

				}else if(value==''){
					this.state.listingAddressData.forEach(function(address,index) {
						address.flag=0;
					});
					this.setState({searchMsg:false});
				}else {
					var len = this.state.listingAddressData.length;
					var count=0;
          var vflag=0;
          var showCount=0;
          if(value!='' && value.length>2){
					this.state.listingAddressData.forEach(function(address,index) {
						
						if(address.listing_info_address.trim().toLowerCase().includes(value.trim().toLowerCase())  && showCount<21){
              showCount=showCount+1;
							address.flag=1;
							vflag=1;
							count=count+1;
						}else{
							address.flag=0;
							if(vflag!=1)
							vflag=0
							count=count+1;
						}
	
          });
        }
					
					if(len==count){
						if(vflag==0){
							this.setState({searchMsg:true});
							
						}else{
							this.setState({searchMsg:false});
						}
					}
				}
         
				
			//}
		break;
		case 'searchNeighbor':
			this.setState({searchNeighbor:event.target.value});
			//if(this.state.searchParm.length>1){	
				var value=this.state.searchNeighbor.trim();
				if(event.key=='Enter'){
					this.getData(value);

				}else if(value==''){
					this.state.neighoberData.forEach(function(address,index) {
						address.flag=0;
					});
					this.setState({searchMsg:false});
				}else {
					var len = this.state.neighoberData.length;
					var count=0;
					var vflag=0;
					this.state.neighoberData.forEach(function(address,index) {
					
					
						if(address.neighborhood.trim().toLowerCase().includes(value.trim().toLowerCase())){
							address.flag=1;
							vflag=1;
							count=count+1;
						}
					else{
							address.flag=0;
							if(vflag!=1)
							vflag=0
							count=count+1;
						}
					
	
					});
					
					if(len==count){
						if(vflag==0){
							this.setState({searchMsg:true});
							
						}else{
							this.setState({searchMsg:false});
							
						}

						
					}


				}
      break;
      case 'searchBroker':
       console.log('Broker Value',event.target.value);
			this.setState({searchBroker:event.target.value});
			//if(this.state.searchParm.length>1){	
				var value=this.state.searchBroker.trim();
				if(event.key=='Enter'){
					this.getData(value);

				}else if(value==''){
					this.state.brokerData.forEach(function(address,index) {
						address.flag=0;
					});
					this.setState({searchMsg:false});
				}else {
					var len = this.state.brokerData.length;
					var count=0;
					var vflag=0;
					this.state.brokerData.forEach(function(address,index) {
					
						if(address.mgt_company.trim().toLowerCase().includes(value.trim().toLowerCase())){
							address.flag=1;
							vflag=1;
							count=count+1;
						}
					else{
							address.flag=0;
							if(vflag!=1)
							vflag=0
							count=count+1;
						}
					
	
					});
					
					if(len==count){
						if(vflag==0){
							this.setState({searchMsg:true});
							
						}else{
							this.setState({searchMsg:false});
							
						}

						
					}

				}
			break;	
		case 'searchkey':
		var value=event.target.value;
			this.setState({ searchListingnId: event.target.value}, () => {
				this.state.rentalListing.forEach(function(address,index) {
					if(address.listing_info_address==value){
						document.getElementById('searchClick').classList.add("active");
						document.getElementById('searchParm').value=address.listing_info_address; 
						
						//document.getElementById('search').classList.remove("show");
					}else{
						address.flag=0;
					}
				});
				document.getElementById('searchClick').classList.add("active");
				this.createWhere();
			  });
		break;
		case 'searchNeighborData':
		var value=event.target.value;
			this.setState({ searchNeighborhood: event.target.value}, () => {
				this.state.neighoberData.forEach(function(address,index) {
					if(address.neighborhood==value){
						document.getElementById('neighborClick').classList.add("active"); 
						document.getElementById('searchNeighbor').value=address.neighborhood;	
						//document.getElementById('search').classList.remove("show");
					}else{
						address.flag=0;
					}
				});
				document.getElementById('neighborClick').classList.add("active");
				this.createWhere();
			  });
    break;
    case 'searchBrokerData':
		var value=event.target.value;
			this.setState({ brokerSearch: event.target.value}, () => {
				this.state.brokerData.forEach(function(address,index) {
					if(address.mgt_company==value){
						document.getElementById('brokerClick').classList.add("active"); 
						document.getElementById('searchBroker').value=address.mgt_company;	
						//document.getElementById('search').classList.remove("show");
					}else{
						address.flag=0;
					}
				});
				document.getElementById('brokerClick').classList.add("active");
				this.createWhere();
			  });
		break;
		case 'default':
		this.createWhere();
		break;
		 
		}
    
   /* if(this.state.ckbedroom_0.value==0 || this.state.ckbedroom_1.value==0 || this.state.ckbedroom_2.value==0 || this.state.ckbedroom_3.value==0 || this.state.ckbedroom_4.value==0){
      document.getElementById('bedroomClick').classList.add("active");
    }else{
      document.getElementById('bedroomClick').classList.remove("active");
    }
    if(this.state.ckbathroom_0.value==0 || this.state.ckbathroom_1.value==0 || this.state.ckbathroom_2.value==0 || this.state.ckbathroom_3.value==0 || this.state.ckbathroom_4.value==0){
      document.getElementById('bathroomClick').classList.add("active");
    }else{
      document.getElementById('bathroomClick').classList.remove("active");
    }*/
    }	
    
getcheckFilterList=(listingType)=>event=>
{
  if(listingType=='market'){
      if(this.state.market_l_checked=='checked')
      {
        this.setState({
        market_l_checked:'',
        open_l_checked:'',
        rls_l_checked:'',
        total_l_checked:''
      });
      listingType='total'
      }
      else{
        this.setState({
          market_l_checked:'checked',
          open_l_checked:'',
          rls_l_checked:'',
          total_l_checked:'',
        });
        }
  }
  if(listingType=='open'){
    if(this.state.open_l_checked=='checked')
    {
      this.setState({
      market_l_checked:'',
      open_l_checked:'',
      rls_l_checked:'',
      total_l_checked:''
    });
    listingType='total';
    }
    else{
      this.setState({
        market_l_checked:'',
          open_l_checked:'checked',
          rls_l_checked:'',
          total_l_checked:'',
      });
      }
}
  
  if(listingType=='rls'){
       if(this.state.rls_l_checked=='checked')
       {
       this.state.rls_l_checked=false
      listingType='total'
       }
       else{
        this.setState({
          market_l_checked:'',
            open_l_checked:'',
            rls_l_checked:'checked',
            total_l_checked:'',
        });
       }
   }
   if(listingType=='total'){  
       if(this.state.total_l_checked=='checked')
       this.state.total_l_checked='';
     else{
      this.setState({
        market_l_checked:'',
          open_l_checked:'',
          rls_l_checked:'',
          total_l_checked:'checked',
      });
        
        }
   }

   if(listingType=='contact_email_check'){  
    if(this.state.first_email_checked=='checked'){
      this.state.first_email_checked='';
      
    }
  else{
   this.setState({
       first_email_checked:'checked',
   });
  
     }
    }
     if(listingType=='agent_email_check'){  
      if(this.state.second_email_checked=='checked'){
        this.state.second_email_checked='';
        
      }
    else{
     this.setState({
      second_email_checked:'checked',
     });
    
       }

}

if(this.state.second_email_checked=='' && this.state.first_email_checked==''){  
  alert('Please select recipient')
  this.setState({
    first_email_checked:'checked',
});

}
  //alert(this.state.market_l.checked)
       this.createWhere(listingType);
}
getlazyLoadList()
{
  this.createWhere();
}
 createWhere(listingType){

    // alert(this.state.filterType)
    //  alert(this.state.listingfeedType)
if(listingType)
{

  this.state.listingfeedType=listingType
}
		this.state.where={};
    let inValue=0;
    if(document.getElementById('searchParm')){
      
      if(document.getElementById('searchParm').value!=''){
        this.state.hasMoreItems=false;
        this.state.filterType=1;
      }
    }
    
		if(this.state.searchListingnId!=''){
			this.state.where.searchListingnId=this.state.searchListingnId;
		}
		if(this.state.searchNeighborhood!='')
		this.state.where.searchNeighborhood=this.state.searchNeighborhood;

		if(this.state.priceSearch!='')
    this.state.where.priceSearch=this.state.priceSearch;
    
    if(this.state.brokerSearch!='')
		this.state.where.brokerSearch=this.state.brokerSearch;
        //this.state.where.agentId = getAgentId();
      this.state.filterValues.orderBy=this.state.orderBy
      this.state.filterValues.orderByState=this.state.orderByState

      this.state.where.orderBy=this.state.orderBy
      this.state.where.orderByState=this.state.orderByState
    
        this.state.where.perpage=50;

     //alert('ListingFeed=='+this.state.listingfeedType)
        if(this.state.listingfeedType=='rls')
        {
          this.getRentalRlsListing(this.state.filterType);
        }
        else if(this.state.listingfeedType=='open')
        {
          this.getRentalOpenListing(this.state.filterType);
        }
        else if(this.state.listingfeedType=='market')
        {
          this.getRentalMarketListing(this.state.filterType);
        }
        else
        {
          if(this.state.filterType==0)
          {
            this.getListAfterFilterApplied(this.state.filterValues); 
          }
          else
          {
            this.getRentalListing();
          }
        }
        
        if(document.getElementById('searchParm')){
      
          if(document.getElementById('searchParm').value!=''){
            this.state.hasMoreItems=false;
            this.state.filterType=1;
            this.getRentalListing();
            inValue=1;
          }
        }
        console.log('ListingType:',listingType);
       
        

        //if(this.state.filterType==0)
        //this.getListAfterFilterApplied(this.state.filterValues);
	}

componentWillMount(){
 
    this.getTotalRecords();
    this.getRentHopDetailByAgentId();
    this.getEmailContent();
    this.getAllAdrress();
    this.getRLSTotalRecords();
    this.getOpenTotalRecords();
    this.getMarketTotalRecords();
    this.getAgentsClient();
    this.getDatamarketing();
    this.ownerpays();
    this.layout();
   // this.getSaveSearchfeedData();
   this.setState({total_l_checked:'checked',  
            market_l_checked:'',
            open_l_checked:'',
            rls_l_checked:''})
  document.addEventListener('mousedown', this.handleClickOutside);
    
    this.getNeighborData();
    this.getBrokerData();
    this.getlazyLoadList();

        }
        escFunction(event){
		
          if(event.keyCode === 27) {
            if(event.target.id=="imgEdit")
            {
              document.getElementById('imageEditClose').click()
            }
            document.getElementById('closeVr').click()
            document.getElementById('priceEditClose').click()
            document.getElementById('bedbathsqEditClose').click()
            document.getElementById('incentiveEditClose').click()
            document.getElementById('buildingamenityClose').click()
            document.getElementById('descriptionEditClose').click()
            document.getElementById('noteEditClose').click()
            document.getElementById('dateEditClose').click()
            document.getElementById('furnishedEditClose').click()
            
           }
          }    
           
        componentWillUnmount(){
          document.addEventListener("keydown", this.escFunction);
          document.addEventListener('mousedown', this.handleClickOutside);
               
        }
      
        setWrapperRef1(node) {
          this.wrapperRef1 = node;
          }
      
          setWrapperRef2(node) {
          this.wrapperRef2 = node;
          }
          setWrapperRef3(node) {
          this.wrapperRef3 = node;
          }
          setWrapperRef4(node) {
          this.wrapperRef4 = node;
          }
          setWrapperRef5(node) {
          this.wrapperRef5 = node;
          }
          setWrapperRef6(node) {
            this.wrapperRef6 = node;
          }
          setWrapperRef10(node) {
            this.setWrapperRef10 = node;
          }
          setWrapperRef12(node) {
            this.wrapperRef12 = node;
          } 
          setWrapperRef13(node) {
            this.wrapperRef13 = node;
          } 
          setWrapperRef14(node) {
            this.wrapperRef14 = node;
          }    

          handleClickOutside(event) {
      
            console.log("target=",event.target.id);
            
            if(event.target.id=='amenitiesEdit'){
            this.setState({checkedBoxes:[]});
            this.feedDetailById(this.state.listingid);
            }
          
            if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
              document.getElementById('search').classList.remove("show");
            }

            if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
            document.getElementById('broker').classList.remove("show");
            }
            if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {
            document.getElementById('neighbor').classList.remove("show");
            }
            if (this.wrapperRef4 && !this.wrapperRef4.contains(event.target)) {
            document.getElementById('price').classList.remove("show");
            }	 	
            if (this.wrapperRef5 && !this.wrapperRef5.contains(event.target)) {
            document.getElementById('layout').classList.remove("show");
            }	 
            if (this.wrapperRef6 && !this.wrapperRef6.contains(event.target)) {
              document.getElementById('bath').classList.remove("show");
            }	  
             
            if (this.wrapperRef10 && !this.wrapperRef10.contains(event.target)) {
                document.getElementById('amenitiesEdit').classList.remove("show");
            }	  

            if (this.wrapperRef12 && !this.wrapperRef12.contains(event.target)) {
            var x = document.getElementById("clientList");
             if (x.style.display === "block") {
              x.style.display = "none";
             }
            }

            if (this.wrapperRef13 && !this.wrapperRef13.contains(event.target)) {
              var x = document.getElementById("clientListmultiple");
              if (x.style.display === "block") {
                x.style.display = "none";
              }
            }
            if (this.wrapperRef14 && !this.wrapperRef14.contains(event.target)) {
                var x = document.getElementById("feedfilteremail");
                if (x.style.display === "block") {
                  x.style.display = "none";
                }
            }
      
          }
  /* Function to get the Distict Neighborhood list to show in the table header filter*/		  
      getNeighborData()
      {
          let listingWhere='';
             listingWhere=[{
              url :"/agent/rentFeedNeighbor"
            }];
               
          getApiData(listingWhere,true).then(res=>{
            this.setState({
              neighoberData:res.data,
              },()=>{
            })
          }).catch(err=>{
      
        
          }) 
        }
  getBrokerData()
      {
          let listingWhere='';
             listingWhere=[{
              url :"/agent/rentFeedBroker"
            }];
               
          getApiData(listingWhere,true).then(res=>{
            this.setState({
              brokerData:res.data,
              },()=>{
            })
          }).catch(err=>{
      
        
          }) 
        }
    getTotalRecords(withfilters)
        {
          console.log("---type",this.state.type)
            let listingWhere='';
               listingWhere=[{
                url :"/agent/renttotalrecord",
                where:withfilters,
                urlParms:"?type="+this.state.type
              }];
                 
            getApiData(listingWhere,true).then(res=>{
             
              this.setState({
                totalRecords:res.data[0].total,
                },()=>{
              })
             
            }).catch(err=>{
       
          
            }) 
            
    } 
    getRLSTotalRecords(withfilters)
    {
      let listingWhere='';
if(withfilters)
{    
       listingWhere=[{
            url :"/agent/rentRlsrecord",
            where:withfilters,
            urlParms:"?type="+this.state.type
          }];
    }
    else{
       
      listingWhere=[{
        url :"/agent/rentRlsrecord",
        urlParms:"?type="+this.state.type
      }];
    }
            
        getApiData(listingWhere,true).then(res=>{
          
          this.setState({
            totalRlsRecords:res.data[0].total
            },()=>{
          })
        }).catch(err=>{
        }) 
}
getOpenTotalRecords(withfilters)
{
 
let listingWhere='';
if(withfilters)
{
       listingWhere=[{
        url :"/agent/rentOpenListings",
        where:withfilters,
        urlParms:"?type="+this.state.type
      }];
  }
  else
  {
    
    listingWhere=[{
     url :"/agent/rentOpenListings",
     urlParms:"?type="+this.state.type
   }];
  }        
    getApiData(listingWhere,true).then(res=>{
     
      this.setState({
        totalOpenRecords:res.data[0].total
        },()=>{
      })
    }).catch(err=>{

  
    }) 
}
getMarketTotalRecords(withfilters)
{
    let listingWhere='';

    if(withfilters)
    {   
       listingWhere=[{
        url :"/agent/rentMarketListings",
        where:withfilters,
        urlParms:"?type="+this.state.type
      }];
    }
    else{
      listingWhere=[{
        url :"/agent/rentMarketListings",
        urlParms:"?type="+this.state.type
      }];
    }
    getApiData(listingWhere,true).then(res=>{
      
      this.setState({
        totalMarketRecords:res.data[0].total
        },()=>{
      })
    }).catch(err=>{

  
    }) 
}
  getAllAdrress()
      {
          let listingWhere='';
             listingWhere=[{
              url :"/agent/getAllAdrress",
              urlParms:"?type="+this.state.type
            }];
               
          getApiData(listingWhere,true).then(res=>{
          	 //this.setState({addressDW:res.data});
            this.setState({
              listingAddressData:res.data,
              },()=>{
            })
          }).catch(err=>{
      
        
          }) 
        }     
        
        
        getRentalFeedListing()
        {
          this.setState(
            { type: "Rental" ,linkingLoader:true,
          total_l_checked:'checked',  
            market_l_checked:'',
            open_l_checked:'',
            rls_l_checked:'',
            filterType:1,
            listingfeedType:'',
            filterValues:{
              selectedBuilding:'',
              selectedBuildingId:'',
              selectedCompany:'',
              selectedBedRooms:'',
              selectedBathRooms:'',
              selectedManhattan:'',
              selectedQueens:'',
              selectedStatenIsland:'',
              selectedBronx:'',
              selectedBrooklyn:'',
              selectedNewJersey:'',
              maxPrice:-1,
              minPrice:-2,
              amenitiesList:'',
              buildingamenities:'',
              zipList:[],
              vr:0,
              showonly:0,
              selectedMedia:'',
              recordPerPage:0,
              recordNextPage:50,
              orderBy:'',
              orderByState:''
                            },


          },
            () => this.componentWillMount(),
            ()=>this.getlazyLoadList(),
            // ()=>this.createWhere()
            // () => this.getRentalListing()
            );
            // this.resetFilter();
        }	
        getSalesFeedListing()
        {
          this.setState(
            { type: "Sales",linkingLoader:true,
            total_l_checked:'checked',  
            market_l_checked:'',
            open_l_checked:'',
            rls_l_checked:'',
            filterType:1,
            listingfeedType:'',
            filterValues:{
              selectedBuilding:'',
              selectedBuildingId:'',
              selectedCompany:'',
              selectedBedRooms:'',
              selectedBathRooms:'',
              selectedManhattan:'',
              selectedQueens:'',
              selectedStatenIsland:'',
              selectedBronx:'',
              selectedBrooklyn:'',
              selectedNewJersey:'',
              maxPrice:-1,
              minPrice:-2,
              amenitiesList:'',
              buildingamenities:'',
              zipList:[],
              vr:0,
              showonly:0,
              selectedMedia:'',
              recordPerPage:0,
              recordNextPage:50,
              orderBy:'',
              orderByState:''
                            },
          },
            () => this.componentWillMount(),
            ()=>this.getlazyLoadList()
            // ()=>this.createWhere()
            // () => this.getRentalListing()
            );
          // this.resetFilter();
          //this.getListingData()
    
        }

 async getRentalListing(val){
   
   if(val=='reset')
   {
     this.getAllAdrress();
     this.state.hasMoreItems=true;
     this.state.filterType=1;
     document.getElementById('searchParm').value='';
     this.state.where.searchListingnId='';
     document.getElementById('searchClick').classList.remove("active");
    
   }
 
   this.state.where.nextpage = this.state.nextpage;

console.log("-----in getRentaListing",val);
            let clientRelWhere = [{
                url: '/agent/rentalfeedlistings/',
                urlParms:"?type="+this.state.type
            }];
            var len =Object.keys(this.state.where);
			if(len.length>0){
				clientRelWhere[0].where=this.state.where;
			}
      
            getApiData(clientRelWhere, true).then(res => {
                this.setState({rentalListing:res.data,linkingLoader:false});
                 //this.setState({addressDW:res.data,linkingLoader:false});
                
               if(res.data.length){
                   this.setState({isListingExist:true});
                   //this.state.filterType=1;
                }
                //alert(this.state.nextpage+'<'+this.state.totalRecords);
                if(this.state.nextpage<this.state.totalRecords ) {
                 
                  if(this.state.firstPage==1)
                  {
                  var n= parseInt(this.state.nextpage)+10;
                  this.setState({nextpage:n});
                  }
                 
                  
              } else {
                  this.setState({
                      hasMoreItems: false
                  });
              }
            
           
                }).catch(error => {
                    
                });
        } 
  async getRentalRlsListing(){

         // this.state.filterValues.recordNextPage = this.state.nextpage;
       //   alert(filterApplied);
               let clientRelWhere='';
          
                    clientRelWhere = [{
                       url: '/agent/rentalRlsfeedlistings/',
                       where:this.state.filterValues,
                       urlParms:"?type="+this.state.type
                   }];
                  
           
                   getApiData(clientRelWhere, true).then(res => {
                       this.setState({rentalListing:res.data});
                        //this.setState({addressDW:res.data,linkingLoader:false});
                       
                      if(res.data.length){
                          this.setState({isListingExist:true});
                          //this.state.filterType=1;
                       }
                      // alert(this.state.filterValues.recordNextPage+'<'+this.state.totalRLSRecords+'>'+this.state.firstPage);
                       if(this.state.filterValues.recordNextPage<this.state.totalRLSRecords ) {
                         if(this.state.firstPage==1)
                         {
                          var n= parseInt(this.state.filterValues.recordNextPage)+10;
                          this.state.filterValues.recordNextPage=n;
                         }
                        
                     } else {
                         this.setState({
                             hasMoreItems: false
                         });
                     }
                   
       
                       }).catch(error => {
                           
                       });
               }
 async getRentalOpenListing(){

                //this.state.where.nextpage = this.state.nextpage;
                     
                let clientRelWhere='';
               
                        clientRelWhere = [{
                           url: '/agent/rentalOpenfeedlistings/',
                           where:this.state.filterValues,
                           urlParms:"?type="+this.state.type
                       }];
               
                         getApiData(clientRelWhere, true).then(res => {
                             this.setState({rentalListing:res.data});
                              //this.setState({addressDW:res.data,linkingLoader:false});
                             
                            if(res.data.length){
                                this.setState({isListingExist:true});
                               // this.state.filterType=1;
                             }
                             //alert(this.state.filterValues.recordNextPage+'<'+this.state.totalOpenRecords);
                             if(this.state.filterValues.recordNextPage<this.state.totalOpenRecords ) {
                              
                               if(this.state.firstPage==1)
                               {
                               var n= parseInt(this.state.filterValues.recordNextPage)+10;
                               this.state.filterValues.recordNextPage=n;
                               }
                              
                               
                           } else {
                               this.setState({
                                   hasMoreItems: false
                               });
                           }
                         
             
                             }).catch(error => {
                                 
                             });
          }
  async getRentalMarketListing(){

            //this.state.where.nextpage = this.state.nextpage;
                 
            let clientRelWhere='';
            clientRelWhere = [{
                           url: '/agent/rentalMarketfeedlistings/',
                           where:this.state.filterValues,
                           urlParms:"?type="+this.state.type
                       }];
              
               
                     getApiData(clientRelWhere, true).then(res => {
                         this.setState({rentalListing:res.data});
                          //this.setState({addressDW:res.data,linkingLoader:false});
                         
                        if(res.data.length){
                            this.setState({isListingExist:true});
                           // this.state.filterType=1;
                         }
                         //alert(this.state.filterValues.recordNextPage+'<'+this.state.totalMarketRecords);
                         if(this.state.filterValues.recordNextPage<this.state.totalMarketRecords ) {
                          
                           if(this.state.firstPage==1)
                           {
                           var n= parseInt(this.state.filterValues.recordNextPage)+10;
                           this.state.filterValues.recordNextPage=n;
                           }
                          
                           
                       } else {
                           this.setState({
                               hasMoreItems: false
                           });
                       }
                     
         
                         }).catch(error => {
                             
                         });
                 }                                           
AdvanceSearch() {
	var x = document.getElementById("A_Filter");
	if (x.style.display === "none") {
	  x.style.display = "block";
	} else {
	  x.style.display = "none";
	}
  }
  componentDidMount()
  {
    document.addEventListener("keydown", this.escFunction);
    window.scrollTo(0, 0);
    this.refs.iScroll.addEventListener("scroll", () => {
      // alert('This is test')
        if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >=this.refs.iScroll.scrollHeight){
            
          this.getlazyLoadList();
        }
      });
  }
  feedDetailById=(feedId)=>{
      // console.log("feedId",feedId)
   this.setState({listingid:feedId,
    linkingLoader:true,
    selected_list_id:[],
    selected_list_id_num:[],
 })
   this.setState({
    contactData:'',
    checkedBoxes:[],
    hideandshow:0
   })
    let where=[{
        url:'/agent/feeddatabyid'
    }];
    
    postApiData(where,{'id':feedId},true).then(res=>{
       
        var listing_records = res.data.records[0];
        let insent=listing_records.incentives.split(',');
       console.log('res.data.records[0]',res.data.records[0])
       var notesval=listing_records.notes?listing_records.notes.replace(/(<([^>]+)>)/ig,""): '';
       var descriptionval=listing_records.description?listing_records.description.replace(/(<([^>]+)>)/ig,""): '';
        this.setState({
          linkingLoader:false,
            listing_info_address: listing_records.listing_info_address,
            bedrooms:listing_records.bedrooms,
            total_rooms:listing_records.total_rooms,
            bathrooms:listing_records.bathrooms,
            furnished:listing_records.furnished,
            date_available:(listing_records.date_available!='0000-00-00' && listing_records.date_available)?Moment(listing_records.date_available).format('MMMM DD, YYYY'):Moment(new Date()).format('MMMM DD, YYYY'),
            
            square_footage:listing_records.square_footage,
            neighborhood:listing_records.neighborhood,
            description:unescape(descriptionval),
            tenant_incentive:listing_records.tenant_incentive,
            price:listing_records.price,
            unit_number:listing_records.unit_number,
            listing_information :listing_records.listing_information,
            amenity_list :res.data.amentiyrecords,
            image_list :res.data.imagename_list,
            isMarketable :listing_records.marketable,
            isListingInfo:listing_records.listing_information,
            date: Moment(listing_records.date_created).format('M/D/YY'),
            time: Moment(listing_records.date_created).format('HH:mm'),
            url_vrData:listing_records.kuula_vr_link,
            listing_source:listing_records.listing_source,
            rent_hop_export:listing_records.rent_hop_export,
            notes:(unescape(notesval)!=='null' ? unescape(notesval) :''),
            amenityData:res.data.amenity,
            kuula_vr_link:listing_records.kuula_vr_link,
            list_id:listing_records.id,
            layout:listing_records.layout,
            commission_percentage:listing_records.commission_percentage,
            my_split:listing_records.my_split,
            incentives:(insent[0]!=undefined)?insent[0]:'',
            owner_pays:(insent[1]!=undefined)?insent[1]:'',
            noFee:listing_records.noFee,
            min_lease_term:(listing_records.min_lease_term!='null' && listing_records.min_lease_term!=0) ? (listing_records.min_lease_term) :(12) ,
            owner_amount:listing_records.owner_pays_amount,
            owner_pays_broker:listing_records.owner_pays_broker,
            listing_agent_id:listing_records.agent_id
        },()=>{
          document.getElementById('listingDesc').innerHTML=this.state.description.replace(/(?:\r\n|\r|\n)/g, '<br>')
          document.getElementById('description').value=this.state.description
          this.getContactDataByFeed(feedId,this.state.listing_agent_id);
         
            this.buttonShowHide();
             this.setState({
                      imagePath3:''
                      })

                      if( this.state.commission_percentage <=100){
                        var _commission=this.state.my_split*this.state.commission_percentage;
                        var _percentage=_commission / 100;
                          var _cocommission=this.state.commission_percentage *(100-this.state.my_split);
                        var _copercentage=_cocommission/100;
                          this.setState({
                        final_precentage:_percentage,
                        copercentage_broker:_copercentage
                      })
                      }
              
                      if( this.state.my_split <=100){
                      var _co_broker_split=100 - this.state.my_split
                      var _commission=this.state.my_split * this.state.commission_percentage;
                      var _percentage=_commission / 100;
                       var _cocommission=this.state.commission_percentage*_co_broker_split;
                      var _copercentage=_cocommission/100;
                        this.setState({
                      
                       co_broker_split:100 - this.state.my_split,
                       final_precentage:_percentage,
                       copercentage_broker:_copercentage
                         })
                    }
                    console.log('listing_records.pets',listing_records.pets)

                    if(!isNullOrUndefined(listing_records.pets) && listing_records.pets!='' && listing_records.pets!='undefined'){
                      if(listing_records.pets=='Cats Only'){
                        document.getElementById('pets').value = 'Cats Allowed';
                        this.setState({pets_policy:listing_records.pets})
                      }
                      else if(listing_records.pets=='Dogs Only'){
                        document.getElementById('pets').value = 'Dogs Allowed';
                        this.setState({pets_policy:listing_records.pets})
                      }
                      else if(listing_records.pets=='Pets Allowed Case by Case'){
                        document.getElementById('pets').value = 'Pets Allowed';
                        this.setState({pets_policy:listing_records.pets})
                      }
                      else if(listing_records.pets=='Cats/Small Dogs Allowed'){
                        document.getElementById('pets').value = 'Pets Allowed';
                        this.setState({pets_policy:listing_records.pets})
                      }
                      else if(listing_records.pets=='No dogs'){
                        document.getElementById('pets').value = 'No Pets Allowed';
                        this.setState({pets_policy:listing_records.pets})
                      }
                      else if(listing_records.pets=='No pets'){
                        document.getElementById('pets').value = 'No Pets Allowed';
                        this.setState({pets_policy:listing_records.pets})
                      }
                      else if(listing_records.pets=='Dogs under 20 pounds'){
                        document.getElementById('pets').value = 'Dogs Allowed';
                        this.setState({pets_policy:listing_records.pets})
                      }
                      else if(listing_records.pets=='No cats'){
                        document.getElementById('pets').value = 'No Pets Allowed';
                        this.setState({pets_policy:listing_records.pets})
                      }
                      else if(listing_records.pets=='Select' || listing_records.pets==null || listing_records.pets==undefined){
                        document.getElementById('pets').value = '';
                        this.setState({pets_policy:''})
                      }
                      else{
                        document.getElementById('pets').value = listing_records.pets;
                      this.setState({pets_policy:listing_records.pets})
                      }
                      
                      
                       }

                        if(!isNullOrUndefined(listing_records.owner_pays_broker) && listing_records.owner_pays_broker!='' && listing_records.owner_pays_broker!='undefined'){
                  
                          document.getElementById('owner_pays_broker').value = listing_records.owner_pays_broker;
                         
                            }
            
        })

      
    }).catch(error=>{

    })
  }
  getContactDataByFeed=(listingId,listing_agent_id)=>{
  
    let where=[{
      url:'/agent/contactData'
  }];
  postApiData(where,{"listing_Id":listingId,"listing_agent_id":listing_agent_id},true).then(res=>{
    console.log('contactData==>',res.data[0].name)
 
 this.setState({
   contactData:res.data,
   
});
  }).catch(error=>{

  })

  }
  buttonShowHide=()=>{
   
      let where=[{
          url:'/agent/buttonshowhide'
      }];
      postApiData(where,{"address":this.state.listing_info_address,"unit_number":this.state.unit_number},true).then(res=>{
            this.setState({showhide:res.data.count})
      }).catch(error=>{

      })
  }

  handleShowImage = event =>  {
  
    var listing_id = event.target.getAttribute('id');
  
    this.setState({
      feedlist_id:listing_id
    })
    this.setState({vrlink:document.getElementById('vrlink'+listing_id).value})
    this.setState({listing_feed_id:listing_id})
    this.setState({ imagePathArray:[]});
    this.setState({imageCount:0});
    let clientRelWhere = [{
        url: "/nestio/getFeedListingImages/"+listing_id
    }];
    getApiData(clientRelWhere, true).then(res => {
           this.feedDetailById(listing_id);
        if(res.data.length==0){
            this.setState({listingImages:res.data});
            this.setState({
              newImageCount:res.data.length,
              count:false,
              nestoImages:res.data.length
            })
          this.setState({isImageExist:false})
        }

        if(res.data[0].status=='202')
         {
            // sending building id to add as new building
            if(this.props.userType=='Agent')
              window.location.href="/agent/building/add/"+res.data[0].building_id+"/"+res.data[0].listing_id;
            else
            window.location.href="/manager/build/"+res.data[0].building_id+"/"+res.data[0].listing_id;
         }
         else
         {
          this.setState({listingImages:res.data});
          this.setState({
            newImageCount:res.data.length,
            count : true,
            nestoImages:res.data.length,
          })
          this.setState({isImageExist:true})
         }
        }).catch(error => {
            
        });
  }
//  fileChangedHandler =event => {
//     var imageFile = event.target.files;
//     if (event.target.files) {
//     for(var i=0;i<imageFile.length;i++){
//       let imgFlag = true;
//       const maxFileSize = 5242880; //5mb
//       const value = event.target.files[i].name;
//       const ext = value.substring(value.lastIndexOf("."));
//       if (![".jpg", ".JPG", ".jpeg", ".png"].includes(ext)) {
//         this.setState({ mymsg: ext + " is not supported file extension." });
//         imgFlag = false;
//       }
//       if (event.target.files[i].size > maxFileSize) {
//         this.setState({ mymsg: "The uploaded image is too large. The max image file size is set to 5MB" });
//         imgFlag = false;
//       }
//       if (imgFlag) {
       
//           this.setState({
//             selectedFile:event.target.files[0],
//             // imagePath2: URL.createObjectURL(event.target.files[0]) ,
//             imagePath3: URL.createObjectURL(event.target.files[0]) ,
//             flag:0
//           })
//           // this.imageSubmit(this.state.list_id,this.state.imageID,this.state.selectedFile);
//         var options = {
//           maxSizeMB: 1,
//           maxWidthOrHeight: 1920,
//           useWebWorker: true
//         };
//         imageCompression(imageFile[i], options)
//           .then(compressedFile => {
           
//             var file = new File([compressedFile], value);
//             var temp = [...this.state.imagePathArray];
//             var temp2 = [...this.state.imageForDb];

//             temp.push(URL.createObjectURL(file));
//             temp2.push(file);
//             this.setState(
//               {
//                 imageForDb: temp2,
         
//                 imagePathArray: temp
//               },
//               () => {
//                 // this.state.imageCount=this.state.imagePathArray.length;
                
//                  if(this.state.count ==true){
//                     var totalLength = this.state.imagePathArray.length;
                   
//                     var final=this.state.newImageCount + totalLength;
                    
//                 this.setState({
//                   newImageCount:final
//                 })
//                 if(this.state.newImageCount==0){
//                   this.setState({
//                     newImageCount:this.state.imagePathArray.length
//                   })
//                 }
//               }else{
//                 this.setState({
//                   newImageCount:this.state.imagePathArray.length
//                 })
//               }
//                 }
//             );
        
//           })
//           .catch(function(error) {
//             console.log(error.message);
//           });
//       }
//     }
//     }
//   };
  

  closeBlobImage = image => {
    let { imagePathArray } = this.state;
    var index = imagePathArray.indexOf(image);
    let alteredImagePathArray = [...this.state.imagePathArray];
    let alteredImageForDb = [...this.state.imageForDb];
    
    alteredImagePathArray.splice(index, 1);
    if(alteredImagePathArray.length==0){
    this.setState({
      newImageCount:alteredImagePathArray.length+this.state.nestoImages
    })
  }
  else
  {
    this.setState({
      newImageCount:alteredImagePathArray.length
    })
  }
    alteredImageForDb.splice(index, 1);
    
    this.setState({ imagePathArray: alteredImagePathArray, imageForDb:alteredImageForDb},()=>{
    });
  }; 

  feedPostToListing=(listing_source)=>{
    document.getElementById('ListingModal').click();

  
  
    
        // let clientRelWhere = [{
        //     url: "/nestio/getFeedListingImages/"+this.state.listingid
        // }];
        // getApiData(clientRelWhere, true).then(res => {
        //   console.log('test1',res);
        //    if(res.data.length>0){
        //     if(res.data[0].status=='202' && res.data.length!=0 )
        //      {
              
        //         // sending building id to add as new building
        //         if(this.props.userType=='Agent')
        //           window.location.href="/agent/building/add/"+res.data[0].building_id+"/"+res.data[0].listing_id;
        //         else
        //         window.location.href="/manager/build/"+res.data[0].building_id+"/"+res.data[0].listing_id;
        //      }
        //      else
        //      {
        //        alert('test2');
        //        let where=[{
        //         url:'/agent/posttoskyward'
        //       }];
        //       postApiData(where,{'listing_id':this.state.listingid},true).then(res=>{
        //     if(res.data.length==0){
        //       alert('test3');
        //               if(window.location.pathname=="/manager/listing/listingfeed/")
        //                           window.location.href="../../feed/edit/"+this.state.listingid;
        //               if(window.location.pathname=="/manager/listing/listingfeed")
        //                            window.location.href="../feed/edit/"+this.state.listingid;
        //               if(window.location.pathname=="/agent/listing/listingfeed/")
        //                            window.location.href="../../feed/edit/"+this.state.listingid;
        //                if(window.location.pathname=="/agent/listing/listingfeed")
        //                             window.location.href="../feed/edit/"+this.state.listingid;             
        //        }
        //        else{
        //         alert('test4');
        //         if(window.location.pathname=="/manager/listing/listingfeed/")
        //         {
        //           window.location.href="../../feed/edit/"+this.state.listingid;
        //         }
        //         else if(window.location.pathname=="/manager/listing/listingfeed")
        //         {
        //           window.location.href="../feed/edit/"+this.state.listingid;
        //         }
        //         if(window.location.pathname=="/agent/listing/listingfeed/")
        //         {
        //           alert('test5');
        //           window.location.href="../../feed/edit/"+this.state.listingid;
        //         }
        //         else if(window.location.pathname=="/agent/listing/listingfeed")
        //         {
        //           window.location.href="../feed/edit/"+this.state.listingid;
        //         }
                 
        //        }
        //       }).catch(error=>{
                
        //       })
                
        //      }
        //     }
        //     }).catch(error => {
        //         console.log("in catch",error);
        //     });
      
  }

  postToRentHop=(value)=>{
    let where=[{
      url:"/agent/removeSlot"
    }];
    postApiData(where,{'agentId':this.state.agent_id,'allotted_Slot':value},true).then(res=>{
        if(res.data.success==true){
          let where=[{
            url:"/agent/renthopExport"
          }];
          console.log("rentHOP",value)
          postApiData(where,{"listing_id":this.state.listingid,"renthop":value,'listing_info_address':this.state.listing_info_address},true).then(res=>{
              document.getElementById("close").click();
              window.location.href="../feed/edit/"+this.state.listingid;
          }).catch(error=>{

          })
        }
    }).catch(error=>{

    })

  }


  
  ValidURL=(str)=> {
   
    str=String(str);
  
    if((str.indexOf('https://tours.moveskyward.com/') >=0 || str.indexOf('https://view.ricohtours.com/') >=0 || str.indexOf('https://kuula.co/') >=0 || str.indexOf('https://my.matterport.com') >=0 || str.indexOf('https://skyward360tours.com') >=0) || str==''){
      return true;
    }else{
   
    return false;
    
    }
    // var regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    // if(!regex .test(str)) {
    //   return false;
    // } else {
    //   return true;
    // }
  }

  
  handleSubmit = (e) => {
    e.preventDefault();
    if(document.getElementById("layout")){
      var layout = document.getElementById("layout").value;
    }
    if(document.getElementById("pets")){
      var pets_policy=document.getElementById("pets").value;
    }
    if(document.getElementById("owner_pays_broker")){
      var owner_pays_broker=document.getElementById("owner_pays_broker").value;
    }
    var listing_id = e.target.getAttribute('id');
    var kuula=document.getElementById('kuula_vr_link'+listing_id).value;
   this.setState({
     listing_id:this.state.feedlist_id
   })
    var r=true;
 

    // e.preventDefault();
  
    const {
      kuula_vr_link,imageForDb,linkingLoader,description,price,date_available,min_lease_term,
      furnished,incentives,bedrooms,bathrooms,marketing,tenant_incentive,noFee,
    } = this.state;
    
    
    let where = [{
      url: '/creator/addfeedlistingtoagent/'
    }];
  
    const formData = new FormData();
    
    formData.append('kuula_vr_link',kuula_vr_link)
    formData.append('listing_id',this.state.feedlist_id)
    formData.append('imageId',JSON.stringify(this.state.imageIdFromDb))
    formData.append('status',1);
    formData.append('description',description)
    formData.append('price',price)
    if(this.state.startDate==''){
      formData.append('date_available',this.state.date_available)
    }else{
     formData.append('date_available',this.state.startDate)
    }
    formData.append('min_lease_term',min_lease_term)
    formData.append('incentives',incentives+','+this.state.owner_pays)
    formData.append('furnished',furnished)
    // if(this.state.isChecked == true){
    //   formData.append('rent_hop_export',1)
    //   formData.append('renthop_slot',1);
    // }
    if(this.state.owner_pays == "Owner pays"){
      formData.append('owner_pays_broker',owner_pays_broker)
      formData.append('owner_amount',this.state.owner_amount)
      }
      if(this.state.incentives== "Co-broke"){
      formData.append('commission_percentage',this.state.commission_percentage)
      formData.append('my_split',this.state.my_split)
      }
      formData.append('layout',layout)
      formData.append('pets_policy',pets_policy)
      formData.append('total_rooms',this.state.total_rooms)
      formData.append('bedrooms',bedrooms)
      formData.append('bathrooms',bathrooms)
      formData.append('noFee',noFee)
      formData.append('tenant_incentive',tenant_incentive)
      formData.append('marketing',JSON.stringify(marketing))
      formData.append('building_ownership',this.state.building_ownership)
   
 if(this.props.userType=="Agent"){
  formData.append('agent_id',getAgentId())
  formData.append('rent_hop_export',this.state.rent_hop_export);
 }
    
else{
  formData.append('agent_id',0);
}
imageForDb.map((image,index)=>{
  formData.append('images',image.file);

})
imageForDb.map((image,index)=>{
     formData.append('images_rotation',image.current_rotation);
  
   })
      postApiData(where,formData).then(res => {
        this.setState({linkingLoader:true},()=>{
          if(res.data.count ===1){
            this.setState({ linkingLoader:true},()=>{
             if(window.location.pathname=="/manager/listing/listingfeed/"){
              window.location.href="../../feed/edit/"+this.state.listing_id;
             }else if(window.location.pathname=="/manager/listing/listingfeed"){
              window.location.href="../feed/edit/"+this.state.listing_id;
             }else if(window.location.pathname=="/agent/listing/listingfeed/"){
              window.location.href="../../feed/edit/"+this.state.listing_id;
             }else if(window.location.pathname=="/agent/listing/listingfeed"){
              window.location.href="../feed/edit/"+this.state.listing_id;
             }
            })  
       }else{
        if(this.props.userType=="Agent"){
          window.location.href="/agent/list/edit"
        }else if(this.props.userType=="Manager"){
          window.location.href="/managerlist/edit";
        }
          // this.setState({linkingLoader:false, successQuote: '1',});
       }
        });
                 
      }).catch(error => {
      this.setState({linkingLoader:false,myerrormsg: error.response});
      });
  //  }
   
  

// handleChange = e => {
//     const id = e.target.id;
//     const value = e.target.value;
//     const dataset = e.currentTarget.dataset.id;
//     const name = e.target.name;
//         this.setState(
//           {
//             [id]: value
//           },
//           () => {
            
//           }
//         );
     
// }

}

handleChangeDate=(date)=>{
  this.setState({
  startDate: date,
  });
  }


  changeHandler=(event)=>{
  
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;

    const name = event.target.name;
    if(id==="Owner_pays"){
      if(this.state.owner_pays!=""){
        this.setState({
          owner_pays : '',
        })
      }
      else{
        this.setState({
          owner_pays : dataset,
        })
      }
      
    }
 
    if(id==="owner_amount"){
      if(value==""){
        this.setState({
          owner_amount :""
        })
      }else if(isNaN(value)){
        this.setState({
          owner_amount:""
        })
      }
      else{
      this.setState({
        owner_amount : value,
      })
    }
    }
    
  }

  onChangeHandler=(event)=>{
    // commission_percentage,
    // my_split,
    // co_broke_split
    
                if(event.target.id==="co_broke_split"){
                  this.setState({
                    [event.target.id]:event.target.value,
                    [event.target.id+"error"]:""
                  })
                }
                if(event.target.id==="commission_percentage"){
                  if(event.target.value =="" ){
                    this.setState({
                      [event.target.id]:"",
                      [event.target.id+"error"]:"Field can not be empty."
                        
                    })
                  }
                  else if(isNaN(event.target.value)){
                    this.setState({
                      [event.target.id]:"",
                      [event.target.id+"error"]:"Enter only numbers."
                    })
                  }else{
                    if( event.target.value <=100){
                    var _commission=this.state.my_split*event.target.value;
                    var _percentage=_commission / 100;
  
                    var _cocommission=event.target.value *(100-this.state.my_split);
                    var _copercentage=_cocommission/100;
  
                  this.setState({
                    [event.target.id]:event.target.value,
                    final_precentage:_percentage,
                    [event.target.id+"error"]:"",
                    copercentage_broker:_copercentage
                })
              }
            }
           }
                if( event.target.id==="my_split"){
                  if(event.target.value =="" ){
                    this.setState({
                      [event.target.id]:event.target.value,
                          co_broker_split:100,
                          final_precentage:0,
                          copercentage_broker:this.state.commission_percentage,
                          [event.target.id+"error"]:"Field can not be empty."
                    })
                  }else if(isNaN(event.target.value)){
                    this.setState({
                      [event.target.id]:"",
                      [event.target.id+"error"]:"Enter only number"
                    })
                  }
                  else{
                   
                    if( event.target.value <=100){
                    var _co_broker_split=100 - event.target.value
                    var _commission=event.target.value * this.state.commission_percentage;
                    var _percentage=_commission / 100;
                             
                    var _cocommission=this.state.commission_percentage*_co_broker_split;
                    var _copercentage=_cocommission/100;
  
   
                  this.setState({
                    [event.target.id]:event.target.value,
                    co_broker_split:100 - event.target.value,
                    final_precentage:_percentage,
                    [event.target.id+"error"]:"",
                    copercentage_broker:_copercentage
                  })
                  
                 
                }
              }
            }
  }
  layoutChange=e=>{
    if(e.target.value.indexOf("1 Bedroom")>=0){
      this.setState({total_rooms:'3'});
    }
    else if(e.target.value.indexOf("2 Bedroom")>=0){
      this.setState({total_rooms:'4'});
    }
    else if(e.target.value.indexOf("3 Bedroom")>=0){
      this.setState({total_rooms:'5'});
    }
    else if(e.target.value.indexOf("4 Bedroom")>=0){
      this.setState({total_rooms:'6'});
    }
    else if(e.target.value.indexOf("Studio")>=0){
      this.setState({total_rooms:'2'});
    }
    else
    {
      this.setState({total_rooms:''});
    }
  }

handleChange=(event)=>{
  const id =event.target.id;
  const value=event.target.value;
  const dataset = event.currentTarget.dataset.id;
  const name = event.target.name;
  if(id==="owner_pays_broker"){
    this.setState({
      owner_pays_broker:value
    })
  }

  if (id == "bedrooms" || id == "bathrooms" || id == "date_available" || id == "price" || id=="min_lease_term") {
    var error = id + "_error";
    if (value == "") {
      
      this.setState({
        [error]: "Field cannot be empty",
        [id]: [value]
      });
    } 
    if (isNaN(value) || value<-1) {
      
      this.setState({
        [error]: "Please enter numbers only",
        [id]: [value]
      });
    } 
    else {
      this.setState({
        [error]: "",
        [id]: [value]
      });
    }
  }
  
  if( id === 'square_footage' || id==='note' || id=="total_rooms")
    var error = id + "_error";
    if ((value == "" || value ==">" || value== "<") && id != 'square_footage') {
     
      this.setState({
        [error]: "Field cannot be empty",
        [id]: [value],
        validate :false
      });
     }else if ( value <0) {
      this.setState({
        [error]: "Enter only positive number",
        [id]: [value],
        validate :false
      });
     }
     else if(isNaN(value))
     {
      this.setState({
        [error]: "Enter numbers only",
        [id]: [value],
        validation :false
      });
     }
     else{
      this.setState({
        [error]: "",
        [id]: [value],
        validate:true
      });
     }

     if (id == "Skyward" || id == "Nestio" || id == "Localize" || id =="Renthop") {
      this.multiselectCheckbox("marketing", value, name);
    }


     if (
      id == "CYOF" ||
      // id == "Owner pays" ||
      id == "Co-broke" ||
      id == "tenant_incentive" ||
      id == "noFee" || id=="OwnerPaysPlusConcessionsYN"
    ) {
      if (id == "tenant_incentive") {
        this.setState({
          tenant_incentive: value
        });
      } else {
        if( id == "CYOF"){
          if(this.state.incentives=="CYOF"){
            this.setState({
              incentives: '',
              commission_percentage:'',
              my_split:'',
              co_broker_split:'',
              final_precentage:''
              
            },()=>{
              
            // this.setState({incentives: dataset})
            });
          }
          else{
            this.setState({
              incentives: dataset,
              commission_percentage:'',
              my_split:0,
              co_broker_split:100,
              final_precentage:''
            });
          }
          
        }else{
          if(id=="Co-broke"){
            if(this.state.incentives=="Co-broke"){
              this.setState({
                incentives: '',
                
              },()=>{
               // this.setState({incentives: dataset})
              });
            }
            else{
              this.setState({
                incentives: dataset
              });
            }

          }
          else{
            this.setState({
              incentives: dataset
            });
          } 
       
      }
      }
      if (id == "noFee") {
        if (this.state.noFee == 0) {
          this.setState({
            noFee: 1
          });
        } else {
          this.setState({
            noFee: 0
          });
        }
      }
      if (id == "OwnerPaysPlusConcessionsYN") {
        if (this.state.OwnerPaysPlusConcessionsYN == 0) {
          this.setState({
            OwnerPaysPlusConcessionsYN: 1
          });
        } else {
          this.setState({
            OwnerPaysPlusConcessionsYN: 0
          });
        }
      }
      // if(id=="Ower pays"){
      //   this.setState({
      //     owner_pay: ''
      //   });
      // }
    }
    if (id == "CoBrokeAgreement") {
      if(document.getElementById("CoBrokeAgreement").value==""){
        this.setState(
          { 
            CoBrokeAgreement_error:"field cannot be empty",
            CoBrokeAgreement: ''
          });
      }
      else{
        this.setState(
          {
            CoBrokeAgreement_error:"",
            CoBrokeAgreement: document.getElementById("CoBrokeAgreement").value
          });
      }
      
    }

    if (id == "pets") {
      if(document.getElementById("pets").value==""){
        this.setState(
          { 
            pets_policy_error:"field cannot be empty",
            pets_policy: ''
          });
      }
      else{
        this.setState(
          {
            pets_policy_error:"",
            pets_policy: document.getElementById("pets").value
          });
      }
      
    }

    if (id == "building_ownership") {

        this.setState(
          {
            building_ownership: document.getElementById("building_ownership").value
          });

    }

    if(id == "description")
    {

      var error = id + "_error";
      if (value.toString().trim() == "") {
        
        this.setState({
          [error]: "Field cannot be empty",
          [id]: [value.toString().trim()]
        });
      } else {
        document.getElementById('listingDesc').innerHTML=value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        this.setState({
          [error]: "",
          [id]: [value]
        });
      }


    }
    }

      //name attribute here is id from the Database 
      multiselectCheckbox = (param, value, name) => {
        var stateArray;
        if (param == "marketing") {
         
           stateArray = this.state.marketing;
        }
        var flag = 0;
        var  temp ={related_id:name, name:value};
        var removeIndex;
        if (stateArray.length === 0) {
          stateArray.push(temp);
        } else {
          stateArray.map(item => {
            if (item.name == value) {
              removeIndex = item;
              flag = 1;
            }
          });
        }
     
        if (flag === 0) {
          stateArray.push(temp);
          if(value=="Renthop"){

             this.setState({
               rent_hop_export:1
             })
           }
          stateArray = [...new Set(stateArray)];
        } else {
        
          var index = stateArray.indexOf(removeIndex);
          stateArray.splice(index, 1);
          if(value=="Renthop"){
              this.setState({
               rent_hop_export:0
             })
           }
        }
        this.setState(
          {
            [param]: stateArray
          },
          () => {
            let len =this.state.marketing.length;
            let count=0;
            let flag=0;
            this.state.marketing.map(item=>{
              count=count+1;
                if(item.name=='Renthop' && this.state.renthop_approval==false){
                  flag=1;
                  this.setState({
                    status:0
                  })
                }
               
                if(item.name=='Skyward' && this.state.skyward_approval==false){
                  flag=1;
                  this.setState({
                    status:0
                  })
                }
                if(item.name=='Localize' && this.state.streetEasy_approval==false){
                  flag=1;
                  this.setState({
                    status:0
                  })
                }
            })
              if(count==len && flag==0){
                this.setState({
                  status:1
                })
              }
          }
        
        );
      };
    
 
validation = () => {
 
    const {
      bedrooms,
      bathrooms,
      price,
      date_available,
      min_lease_term,
      total_rooms,
      description,
      pets_policy,
      kuula_vr_link,
   } = this.state;

  //   return (
  //     imageCount >1     
  //   );
  console.log("Total==",this.state.newImageCount)
  if(this.state.newImageCount>0){
    return (this.state.newImageCount>2) &&
      bedrooms != "" &&
      bathrooms!= "" &&
      price!= "" &&
      date_available!= "" &&
      min_lease_term!= "" && 
      !isNaN(bedrooms) &&
      bedrooms >-1 &&
      !isNaN(bathrooms) &&
      bathrooms>-1 &&
      !isNaN(price) &&
      price>0 &&
      !isNaN(total_rooms)&&
       total_rooms >-1 &&
       total_rooms != null&&
      description!="" &&
      this.ValidURL(this.state.kuula_vr_link) &&
      pets_policy!=""
  }
  else{
      return bedrooms != "" &&
      bathrooms!= "" &&
      price!= "" &&
      date_available!= "" &&
      min_lease_term!= "" && 
      !isNaN(bedrooms) &&
      bedrooms >-1 &&
      !isNaN(bathrooms) &&
      bathrooms>-1 &&
      !isNaN(price) &&
      price>0 &&
      !isNaN(total_rooms)&&
       total_rooms >-1 &&
       total_rooms != null&&
      description!="" &&
      this.ValidURL(this.state.kuula_vr_link) &&
      pets_policy!=""
  }
  };

 
  handleClickOnList=param=>e=>{
    var value=param[0];
  
    this.getData(value,e.target.id);
    document.getElementById('search').classList.remove("show");
  }
  
  getData(value,id){
    
    if(id=='searchNeighborData')
    {
      this.setState({ searchNeighborhood: value}, () => {
        this.state.neighoberData.forEach(function(address,index) {
          if(address.neighborhood==value){
            document.getElementById('neighborClick').classList.add("active");
            document.getElementById('searchNeighbor').value=address.neighborhood;
            document.getElementById('neighbor').classList.remove("show");
          }
          else{
            address.flag=0;
          }
        });
        document.getElementById('neighborClick').classList.add("active");
        this.createWhere();
        });
    }
    else if(id=='searchBrokerData')
    {
        this.setState({ brokerSearch: value}, () => {
        this.state.brokerData.forEach(function(address,index) {
          if(address.mgt_company==value){
            document.getElementById('brokerClick').classList.add("active");
            document.getElementById('searchBroker').value=address.mgt_company;
            document.getElementById('broker').classList.remove("show");
           
          }
          else{
            address.flag=0;
          }
        });
        document.getElementById('brokerClick').classList.add("active");
        this.createWhere();
        });
    }
    else{
  this.setState({ searchListingnId: value}, () => {
    this.state.rentalListing.forEach(function(address,index) {
      if(address.listing_info_address==value){
        document.getElementById('searchClick').classList.add("active");
        document.getElementById('searchParm').value=address.listing_info_address;
      }
      else{
        address.flag=0;
      }
    });
    document.getElementById('searchClick').classList.add("active");
    this.createWhere();
    });
  }
  } 
  
 getListAfterFilterApplied(filter){
   //console.log("filter",filter);
  // console.log(this.state.filterValues);
  //this.setState({linkingLoader1:true},()=>{
    document.getElementById('searchParm').value='';
    this.getTotalRecords(filter)
    this.getOpenTotalRecords(filter);
    this.getRLSTotalRecords(filter);
    this.getMarketTotalRecords(filter);
  
         let getFilteredRecord = [{
             url: "/agent/rentalsearchfeedlistings",
             where:filter,
             urlParms:"?type="+this.state.type
         }];
          getApiData(getFilteredRecord,true).then(res => {
            console.log('rentalListing',res.data)
            this.setState({
              listingAddressData:res.data,
              linkingLoader:false,
              rentalListing:res.data,
              filterValues:filter});
              document.getElementById('show_loader_on_click1').style.display='none';


              //console.log(res.tdata)
              //this.state.totalRecords=res.data.length;
              if(this.state.filterValues.recordNextPage<this.state.totalRecords) {
  
                var n= parseInt(this.state.filterValues.recordNextPage)+10;
                this.state.filterValues.recordNextPage=n;
               
            } else {
                this.setState({
                    hasMoreItems: false
                });
            }
              //this.setstate({filterType:0});
              this.state.filterType=0;
          
  
         })
         .catch(error => {
             if(error.response){
                 this.setState({linkingLoader:false, myerrormsg: error.response.data.error.message});
             }
         });
  //})
  
   }


   getRentHopDetailByAgentId=()=>{

    let where = [{
      url:"/agent/renthopbyagentid",
    }];
    postApiData(where,{'agent_id':getAgentId()},true).then(res=>{
      // console.log("---res",res)
      this.setState({
        isRentHopCheck:res.data[0].renthop_slot
      })
    }).catch(err=>{

    })
   }

  
   
   removeImage = (imageId) => { 
    
    this.state.imageIdForDb.push(imageId);
    this.setState({imageIdFromDb: this.state.imageIdForDb})
    this.setState({
      newImageCount:this.state.newImageCount-1
    })
    document.getElementById('img'+imageId).style.display ='none';
    
  };

  toggleChange = event => {
     
if(event.target.checked){
  this.setState({
    isChecked: !this.state.isChecked
})
}
else{
  this.setState({
    isChecked: this.state.isChecked 
})
}
 
   

    
  }

  setOrderBy= param => e => {
    let key = param[0];
    if(key=='BR' && this.state.orderByState=='ASC'){
      this.setState({orderBy:'bedrooms',orderByState:'DESC'},()=>{
        this.createWhere();
        
      });
    }else if(key=='BR' && (this.state.orderByState=='DESC' || this.state.orderByState=='')){
      this.setState({orderBy:'bedrooms',orderByState:'ASC'},()=>{
        this.createWhere();
      });
    }else if(key=='BA' && this.state.orderByState=='ASC'){
      this.setState({orderBy:'bathrooms',orderByState:'DESC'},()=>{
        this.createWhere();
      });
    }else if(key=='BA'&& (this.state.orderByState=='DESC' || this.state.orderByState=='')){
      this.setState({orderBy:'bathrooms',orderByState:'ASC'},()=>{
        this.createWhere();
      });
    }else if(key=='Price' && this.state.orderByState=='ASC'){
      this.setState({orderBy:'price',orderByState:'DESC'},()=>{
        this.createWhere();
      });
    }else if(key=='Price' && (this.state.orderByState=='DESC' || this.state.orderByState=='')){
      this.setState({orderBy:'price',orderByState:'ASC'},()=>{
        this.createWhere();
      });
    }
  }
 
  handleCheckBox =param=>e=>{
	// 	console.log("---check",check)
  //   let checkedBoxes = [...this.state.checkedBoxes];
  //   console.log("---check--checkedBoxes",checkedBoxes)
  //   if(e.target.checked) {
  // checkedBoxes.push(check)
  // console.log("---checkedBoxes----",checkedBoxes)
  //   } else {
  //    let index = checkedBoxes.findIndex((ch) => ch.id === check.id);
  //     checkedBoxes.splice(index, 1);
  //   }

  //   this.setState({checkedBoxes});

let check=param[0];
console.log("check",check,"this.state.listing_id",this.state.listingid);
let __count=1;
let __len=this.state.amenity_list.length;
let __flag=0;
  for (var i = 0; i < __len; i++) {
    //console.log("this.state.amenity_list",this.state.amenity_list,"i",i,"this.state.amenity_list[i]['name']",this.state.amenity_list[i]['name']);
    
    if(this.state.amenity_list[i]!=undefined && this.state.amenity_list[i]['name']!=undefined && this.state.amenity_list[i]['name'] === check.amenity_name){
      this.state.amenity_list.splice(i, 1);
      __flag=1;
    }
  
  //   else
  //   {
  //     if(this.state.amenity_list[i]['name'] === check.amenity_name){
  //     this.state.amenity_list.push({
  //       'id': '',
  //       'listing_id': '',
  //       'name': check.amenity_name,
  //       'type': check.type,}
  //       )
  //            }
    
  __count=__count+1;
  console.log("__count",__count,"len",__len);
  }
        if(__count> __len && __flag==0){
          
          
          this.state.amenity_list.push({
            'id': check.id,
            'listing_id': this.state.listingid,
            'name': check.amenity_name,
            'type': 0}
            )
        }
       // console.log("this.state.amenity_list",this.state.amenity_list)


  }

  onVrHandleChange=(event)=>{
    console.log('=>',event.target.id,event.target.value);
    const id = event.target.id;
    const value = event.target.value;
    const dataset = event.currentTarget.dataset.id;

     


    if(id==='kuula_vr_link'){
      var error = id + "_error";
      if(value==''){
        this.setState({
          [error]: "Please enter the valid VR Link",
          [id]: [value],
          validation:false
        });
      }
      else{ 
        console.log("=>",'else');

        if((value.indexOf('https://tours.moveskyward.com/') >=0 || value.indexOf('https://view.ricohtours.com/') >=0 || value.indexOf('https://kuula.co/') >=0 || value.indexOf('https://my.matterport.com') >=0 || value.indexOf('https://skyward360tours.com') >=0) || value==''){
          console.log('found');
          this.setState({
            validation:true
          });
        }
        
    else{
      console.log('not found');
    
      this.setState({
        [error]: "Please enter valid url",
        [id]: [value],
        validation:false
      });
   
     }
    }
    }
  }
    onVrHandle=(event)=>{
      console.log('=>',event.target.id,event.target.value);
      const id = event.target.id;
      const value = event.target.value;

      
      if(id==='kuula_vr_link'){
        var error = id + "_error";
        this.setState({
          [error]: "",
          [id]: [value],
          validation:true
        });
       
      }
      }
  
  vrSubmit=(event)=>{
    event.preventDefault();
    // if(this.ValidURL(this.state.kuula_vr_link) == false && this.state.kuula_vr_link!=''){

    //   this.setState({kuula_vr_link_error:'please enter valid url'})
    // }else{
    //   this.setState({kuula_vr_link_error:''})
    let where=[{
        url:'/managers/vrEdit'
    }];
    postApiData(where,{'vrlink':this.state.kuula_vr_link,'listing_id':this.state.listingid},true).then(res=>{
      this.getlazyLoadList();
      document.getElementById("editVR").click();
    }).catch(error=>{

    })
 // }
    }

  handleCheck=()=> {
				
    this.setState({noFee: !this.state.noFee});
  }
  //EDIT DETAIL
  furnishedSubmit=()=>{
    let listingWhere=[{
      url :'/managers/furnishedEdit'
    }];
    postApiData(listingWhere,{'furnished':this.state.furnished,'listing_id':this.state.listingid},true).then(res=>{
      this.getlazyLoadList();
      document.getElementById("furnishedEditClose").click();	
    }).catch(error=>{

    })
  }

  handleChangeStatus = checked => {
    if (checked){
      this.state.furnished=1
        this.furnishedSubmit(); 
    }
    else {
      this.state.furnished=0;
      this.furnishedSubmit();
    }
    };

  priceSubmit=(event)=>{
    event.preventDefault();
    let listingWhere=[{
      url:'/managers/priceEdit'
    }];
    postApiData(listingWhere,{'price':this.state.price,'listing_id':this.state.listingid},true).then(res=>{
      if(res){
        this.getlazyLoadList();
      document.getElementById("priceEditClose").click();}	
    }).catch(error=>{

    })
  }

  bedbathSqSubmit=(event)=>{
    event.preventDefault();
    let listingWhere=[{
      url : '/managers/bedbathsquEdit'
    }];
    postApiData(listingWhere,{'bedrooms':this.state.bedrooms,'bathrooms':this.state.bathrooms,'square_footage':this.state.square_footage,'listing_id':this.state.listingid},true).then(res=>{
      this.getlazyLoadList();
      document.getElementById("bedbathsqEditClose").click();
    }).catch(error=>{

    })
  }

  dateSubmit=(event)=>{
    event.preventDefault();
    let listingWhere=[{
      url:'/managers/dateEdit'
    }];
    postApiData(listingWhere,{'date_available':Moment.utc(this.state.startDate).local().format('YYYY-MM-DD'),'listing_id':this.state.listingid},true).then(res=>{
      this.getlazyLoadList();
      document.getElementById("dateEditClose").click();
    }).catch(error=>{

    })
  }

  descriptionSubmit=(event)=>{
    event.preventDefault();
    let listingWhere=[{
      url:'/managers/descriptionEdit'
    }];
    postApiData(listingWhere,{'description':this.state.description,'listing_id':this.state.listingid},true).then(res=>{
      this.getlazyLoadList();
      document.getElementById("descriptionEditClose").click();
    }).catch(error=>{
    })
  }

  incentivesSubmit=(event)=>{
    event.preventDefault();
    var noFee=0;
    if(this.state.noFee == true){
       noFee = 1;
    }
    let listingWhere=[{
      url : '/managers/incentiveEdit'
    }];
    postApiData(listingWhere,{'incentives':this.state.incentives,'noFee':noFee,'tenant_incentive':this.state.tenant_incentive,'listing_id':this.state.listingid},true).then(res=>{
     if(res){
      this.getlazyLoadList();
      document.getElementById("incentiveEditClose").click();
     }
    }).catch(error=>{

    })
  }

  //incetive detail select function
  incentive=(value)=>{
    this.setState({incentives:value})
  }

  

  
  amenitySubmit=(event)=>{
    event.preventDefault();
    console.log("this.state.amenity_list-----------------",this.state.amenity_list);
    let listingWhere=[{
        url : '/managers/amenityEdit'
    }];
    postApiData(listingWhere,{'amenity':this.state.amenity_list,'listing_id':this.state.listingid},true).then(res=>{
      this.getlazyLoadList();
      document.getElementById("buildingamenityClose").click();
      this.setState({checkedBoxes:[]});
      
    }).catch(error=>{

    })
    // var newbid=document.getElementById('UpdateBuildingId').value;
    
    this.feedDetailById(this.state.listingid);
  }

  // phoneSubmit=(event)=>{
  //   event.preventDefault();
  //   let listingWhere=[{
  //     url:'/managers/phoneEdit'
  //   }];
  //   postApiData(listingWhere,{'phone':this.state.phone,'listing_id':this.state.listingid},true).then(res=>{
  //     this.getRentalListing();
  //     document.getElementById("phoneEditClose").click();
  //   }).catch(error=>{

  //   })
  // }
  // contactSubmit=(event)=>{
  //   event.preventDefault();
  //   let listingWhere=[{
  //     url:'/managers/contactEdit'
  //   }];
  //   postApiData(listingWhere,{'contact':this.state.contact_name,'listing_id':this.state.listingid},true).then(res=>{
  //     this.getRentalListing();
  //     document.getElementById("contactEditClose").click();
  //   }).catch(error=>{

  //   })
  // }
  // emailSubmit=(event)=>{
  //   event.preventDefault();
  //   let listingWhere=[{
  //     url:'/managers/emailEdit'
  //   }];
  //   postApiData(listingWhere,{'email':this.state.email,'listing_id':this.state.listingid},true).then(res=>{
  //     this.getRentalListing();
  //     document.getElementById("emailEditClose").click();
  //   }).catch(error=>{

  //   })
  // }
  notesSubmit=(event)=>{
    event.preventDefault();
    let listingWhere=[{
      url:'/managers/noteEdit'
    }];
    postApiData(listingWhere,{'notes':this.state.notes,'listing_id':this.state.listingid},true).then(res=>{
      this.getlazyLoadList();
      document.getElementById("notesEditClose").click();
    }).catch(error=>{

    })
  }
  //EDIT DETAIL

  
  imageEDIT=(value)=>event=>{
    event.preventDefault();
    console.log("------value",value);
    this.setState({
      image_id:value
    })
  }

  defaultImage=(value)=>event=>{
    event.preventDefault();
    console.log("----check",value);
    this.setState({
      flag:value
    })

  }
  fileHandler = params=>(e) => {
    this.setState({
      image_ID:params
    })
       if(e.target.files[0]){
        let imgFlag = true;
       const maxFileSize = 5242880;   //5mb
       const value = e.target.files[0].name;
       const ext = value.substring(value.lastIndexOf('.'));
       if(![".jpg", ".JPG", ".jpeg", ".png"].includes(ext)){
           this.setState({mymsg: ext+' is not supported file extension.'});
           imgFlag = false;
       }
       if(e.target.files[0].size>maxFileSize){
           this.setState({mymsg:"The uploaded image is too large. The max image file size is set to 5MB"})
           imgFlag = false;
           }
       if(imgFlag) { 
            this.setState({
        selectedFile:e.target.files[0], 
        imagePath3: URL.createObjectURL(e.target.files[0]) ,
        flag:0
               })
               this.imageSubmit(this.state.list_id,e.target.files[0],params);
            }
        }            
   }
  imageSubmit=(id,file,imageId)=>{
	

		console.log('in if-----------');
	let listingWhere=[{
		url : '/containers/listing_images/upload'
	}];
	const formData = new FormData();
	formData.append('file',file);
	formData.append('listing_id',id)
	postApiData(listingWhere,formData,true).then(res=>{
		if(res){
			let where=[{
				url:'/managers/listingImageUpdate'
			}];
			if(imageId!=undefined && imageId!=''){
			var data=postApiData(where,{'id':imageId,'large_image':res.data.file_name,'thumbnail_image':res.data.file_name},true)
			}else{
				var data=postApiData(where,{'listing_id':id,'large_image':res.data.file_name,'thumbnail_image':res.data.file_name},true)
			}
			data.then(async(response)=>{
				if(response){
          this.feedDetailById(this.state.list_id);
          this.getlazyLoadList();
                    //  this.setState({
                    //   imagePath3:''
                    //   })
					}
			}).catch( error=>{
				 
				}) 
		}
	}).catch(error=>{
	
	})
}
 

  // defaultImageUpdate =(id,file)=>{
    
  //   console.log("-----value",this.state.listingid);
  //   let listingWhere=[{
  //     url : '/containers/listing_images/upload'
  //   }];
  //   const formData = new FormData();
  //   formData.append('file',file);
  //   formData.append('listing_id',this.state.listingid)
  //   postApiData(listingWhere,formData,true).then(res=>{
  //     if(res){
  //       let where=[{
  //         url:'/managers/defaultImageUpdate'
  //       }];
  //       postApiData(where,{'listing_id':id,'large_image':res.data.file_name,'thumbnail_image':res.data.file_name},true).then(async(response)=>{
  //           if(response){
  //             this.feedDetailById(this.state.list_id);
  //            this.getlazyLoadList();
  //       //  document.getElementById('imageEditClose').click();
  //           }
  //       }).catch( error=>{
           
  //         }) 
  //     }
  //   }).catch(error=>{
    
  //   })
  // }

  componentWillUpdate(next,previous){
    console.log("next,",next);
    console.log("previous,",previous);
    if(previous){
      
        this.state.image_list=previous.image_list
     
    }
  }

  

   deleteImage=(imageId)=>{

    let where=[{
      url:'/managers/imageDelete'
    }]
    var	form =new FormData();
    form.append("imageId",imageId) 
    postApiData(where,form,true).then(res=>{
      console.log("----res",res.data);
      this.feedDetailById(this.state.list_id);
      this.getlazyLoadList();
    }).catch(error=>{

    })
  }
   openandhide=(event)=>{
    event.preventDefault();
    this.setState({
      hideandshow:1
    })
  }

  closeRow=(event)=>{
    event.preventDefault();
    this.setState({
      hideandshow:0
    })
  }

  getEmailContent=()=>{
         
    let where = [{
      url:'/agent/EmailContent_contact/'
    }]
    getApiData(where,true).then(res=>{
        console.log('Email Contnet==>',res.data);
        this.setState({
          contact_email_content:res.data[0].html_value,
         
        })
        
        document.getElementById('contact_Email_id').innerHTML=this.state.contact_email_content;
       
     
        
    }).catch(error=>{

    })
  }

  contacttoemail(email_data,contact_name_data,listing_info_address,price,bedrooms,listingid,agent_number,agent_name){
   
      this.setState({contact_email_data:email_data,conatct_email_name:contact_name_data,first_email_checked:'checked',contact_list_address:listing_info_address,contact_list_price:price,contact_list_bedroom:bedrooms,contact_list_id:listingid,second_email_checked:'',agent_phone_num: aes.decrypt(agent_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8),agent_name_mail:agent_name})
      if(this.state.contact_list_address){
        document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace(this.state.contact_list_address,listing_info_address);
       
        }
        if(this.state.contact_list_price){
          document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace(this.state.contact_list_price,price);
         
          }
          if(this.state.contact_list_bedroom){
            document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace(this.state.contact_list_bedroom,bedrooms);
          
            }
            if(this.state.contact_list_id){
              document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace(this.state.contact_list_id,listingid);

              }

      document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace("Listing Address",listing_info_address);
      document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace("Listing Bedrooms",bedrooms);
      document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace("listing_id",listingid);      
      document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace("Listing Price",price);
      document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace("baseurl",getBaseUrl());
      if(this.props.userType == 'Agent'){
        document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace("Signature",agent_name+'<br/>'+'Licensed Real Estate Salesperson'+'<br/>'+aes.decrypt(agent_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)+'<br/>'+'Skyward');
      }
      else{
        document.getElementById('contact_Email_id').innerHTML=document.getElementById('contact_Email_id').innerHTML.replace("Signature",'Josiah Haytt'+'<br/>'+'Founder | Principal Real Estate Broker'+'<br/>'+'Skyward');
      }
      
  }

  sendcontactmail(){
  //  this.setState({linkingLoader:true})
  if(this.state.second_email_checked=='checked'){
    this.state.agent_email_id=getAgentId();
    
  }
  else{
    this.state.agent_email_id='';
  }
  if(this.state.first_email_checked=='checked'){
    this.state.contact_send_email=this.state.contact_email_data;
  }
  else{
    this.state.contact_send_email='';
  }

    var divValue='';
   
     divValue=document.getElementById('divContent').innerHTML;
     let where=[{
			url:'/email/contact_send_mail'
    }];  
    console.log('divValue',this.state.contact_email_data) 
    var data={'content':divValue,'client_email':this.state.contact_send_email,'subject':this.state.contact_list_address,'agent_id':this.state.agent_email_id,'agent_unq_id':getAgentId()};
    console.log(data);
    postApiData(where,data,true).then(res=>{
    
      this.setState({linkingLoader:false},()=>{
      
        document.getElementById('contactmailclose').click();
      })
    this.getClientApplication();
      
    
  }).catch(error=>{
    console.log('Error=>',error)
  })
  }

  // get agents client detail

  OpenList() {
    var x = document.getElementById("clientList");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    }

    OpenListMultiple() {
      var x = document.getElementById("clientListmultiple");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
      }

getAgentsClient=()=>{
	

	let where=[{
		url:'/agent/agents_client/'+getAgentId()
	}]
	getApiData(where,true).then(res=>{
		console.log("---res_client",res.data);
    this.setState({client_detail:res.data})
    this.setState({client_detail_multiple:res.data})
    this.setState({client_detail_feed:res.data})
  
		
	}).catch(error=>{

	})
}

// --start-- send mail to client 

handleClickOnClient=param=>event=>{


  let value=param[1]; //id
  let value_name=param[0]; //name
  if(this.state.selected_email==''){
      this.setState({selected_email:value_name,selected_name:value})
  }
  if(!document.getElementById(event.target.id).checked){
	
    this.removeValue(this.state.selected_email,value_name,',',(cberr,cbres)=>{
     
     if(cbres!=''){
       //var listName=cbres.split(',');
       this.setState({selected_email:cbres});
     }
     else{
       this.setState({selected_email:cbres});
     }
    });
     this.removeValue(this.state.selected_name,value,',',(cberr,cbres)=>{

     if(cbres!=''){
      var listName=cbres.split(',');
       this.setState({selected_name:cbres});
     }
     else{
     this.setState({selected_name:cbres});
     }
   
     });
    } 
 
    if(document.getElementById(event.target.id).checked){
     
     this.addValue(this.state.selected_email,value_name,',',(cberr,cbres)=>{
               if(cbres!=''){
                  
                   this.setState({selected_email:cbres});
              }
              
          });
          
          this.addValue(this.state.selected_name,value,',',(cberr,cbres)=>{
           if(cbres!=''){
            var listName=cbres.split(',');
               this.setState({selected_name:cbres});
            }
            
        });
         
     }
}

/**
 * removeValue will manage the amenities
 */
removeValue(list, value, separator,cb){
  separator = separator || ",";
  let list_value=list.toString();
  var values = list_value.split(separator);
  for(var i = 0 ; i < values.length ; i++) {
    if(values[i] == value) {
      values.splice(i, 1);
      //this.setState({amenitiesList:values.join(separator)})
      cb(null,values.join(separator));
    }
  //   else{
  //     let _list_=list_value+","+value;
  //     //this.setState({amenitiesList:list})
  //     cb(null,_list_)
  // }
  }
}

addValue(list, value, separator,cb){
	
  separator = separator || ",";
  if(list!=''){
	var values = list.toString().split(separator);
	for(var i = 0 ; i < values.length ; i++) {
	   if(values[i] == value) {
		  values.splice(i, 1);
		  cb(null,values.join(separator));
		  }
	  else{
		let _list_=list+","+value;
		cb(null,_list_)
	}
  
  }
  }
  else{
	cb(null,value) 
  }
  
}


handleclientmail=(e)=>{
	this.setState({
		[e.target.id]:e.target.value
	})
}

validateclientemail2=()=>{
	return(
		(this.state.selected_email.length>0 || this.state.unregistered_clients_email1!="") &&
		this.state.content_value!="" &&
		this.state.mail_subject!=""
	)  
}

selectedListingId=(e)=>{
  var value = e.target.value;
  
  if(e.target.checked){
    this.setState(state => (this.state.selected_list_id.push(getBaseUrl()+'/client/listing/Details/'+value)))
    this.setState(state => (this.state.selected_list_id_num.push(value)))
    
  }
  else{
    this.setState(state => (this.state.selected_list_id.pop(getBaseUrl()+'/client/listing/Details/'+value)))
    this.setState(state => (this.state.selected_list_id_num.pop(value)))
  }
}

getSelectedListing=()=>{
  let where=[{
    url:'/getSelectedlistings'
  }]
  postApiData(where,{'selectedListing':this.state.selected_list_id_num},true).then(res=>{
    console.log("res.data",res.data);
      this.setState({
        listingData:res.data
      })
     
  }).catch(error=>{

  })
}
getSingleListing=()=>{
  let where=[{
    url:'/getSelectedlistings'
  }]
  postApiData(where,{'selectedListing':this.state.listingid},true).then(res=>{
    console.log("res.data",res.data);
      this.setState({
        listingData:res.data
      })
     
  }).catch(error=>{

  })
}
feedemailsend(){
	 
	var divValue='';
 
	divValue=document.getElementById('feedDivvalue').innerHTML;

    let where=[{
           url:'/email/send_Mali_ToClient'
   }];  
 
   var data={'client_name':this.state.selected_name, 'allclientemail':this.state.selected_email  ,'content':divValue,'subject':this.state.mail_subject,'unregistered_clients_email':this.state.unregistered_clients_email1 };
 
   postApiData(where,data,true).then(res=>{
	   this.setState({mail_subject:'',selected_name:[],selected_email:[],unregistered_clients_email1:[]})
	   this.setState(state => (this.state.content_value="I found a new listing that I thought you'd be interested in-feel free to check through the links for more photos and information. Be sure to let me know what you think!"))
       this.setState(state => (this.state.selected_name=[]))
      document.getElementById('emailtoclient').click();
      document.getElementById('ListingModal').click();
	
	
       
 }).catch(error=>{
   console.log('Error=>',error)
 }) 
}

//  --End of code for Email to Client--

// -- start code for Email to client for multiple check listing--

checkMultipleListId=param=>event=>{
  let value=param[1]; //id
  let value_name=param[0]; //name
  if(this.state.selected_email==''){
      this.setState({multichk_email:value_name,multichk_name:value})
  }
  if(!document.getElementById(event.target.id).checked){
	
    this.removeValue(this.state.multichk_email,value_name,',',(cberr,cbres)=>{
     
     if(cbres!=''){
       //var listName=cbres.split(',');
       this.setState({multichk_email:cbres});
     }
     else{
       this.setState({multichk_email:cbres});
     }
    });
     this.removeValue(this.state.multichk_name,value,',',(cberr,cbres)=>{

     if(cbres!=''){
      var listName=cbres.split(',');
       this.setState({multichk_name:cbres});
     }
     else{
     this.setState({multichk_name:cbres});
     }
   
     });
    } 
 
    if(document.getElementById(event.target.id).checked){
     
     this.addValue(this.state.multichk_email,value_name,',',(cberr,cbres)=>{
               if(cbres!=''){
                  
                   this.setState({multichk_email:cbres});
              }
              
          });
          
          this.addValue(this.state.multichk_name,value,',',(cberr,cbres)=>{
           if(cbres!=''){
            var listName=cbres.split(',');
               this.setState({multichk_name:cbres});
            }
            
        });
         
     }
}

validateMultipleclientemail=()=>{
	return(
		(this.state.multichk_email.length>0 || this.state.unregistered_clients_email!="") &&
		this.state.content_value_multiple!="" &&
    this.state.mail_subject_multiple!="" 
	)  
}

multichksendmail(){

	var multichkdivValue='';
 
	multichkdivValue=document.getElementById('multichkcontent').innerHTML;

    let where=[{
           url:'/email/send_Mali_ToClient'
   }];  
 
   var data={'client_name':this.state.multichk_name, 'allclientemail':this.state.multichk_email  ,'content':multichkdivValue,'subject':this.state.mail_subject_multiple, 'unregistered_clients_email':this.state.unregistered_clients_email };
   postApiData(where,data,true).then(res=>{
	   this.setState({mail_subject_multiple:'',multichk_name:[],multichk_email:[],unregistered_clients_email:[]})
	   this.setState(state => (this.state.content_value_multiple="I found a few listings that I thought you'd be interested in-feel free to check through the links for more photos and information. Be sure to let me know what you think!"))
       this.setState(state => (this.state.multichk_name=[]))
	    document.getElementById('multipleCheckListing').click()
	
	
       
 }).catch(error=>{
   console.log('Error=>',error)
   alert(error);
 }) 
}

// Start MS-752//

layout =()=>{
  let where=[{
    url:'/agent/layout'
  }]
  postApiData(where,{'tableName':'listing_layout'},true).then(res=>{
    console.log("res.data",res.data);
      this.setState({
        layoutData:res.data
      })
     
  }).catch(error=>{

  })
}

ownerpays=()=>{
  let where=[{
    url:'/agent/ownerPays'
  }];
  postApiData(where,{'tableName':'owner_pays_broker_master'},true).then(res=>{
    this.setState({owner_pays_data:res.data})
  }).catch(error=>{

  })
}
contains (arr, key, val) {
  let flag=0;
  let count=0;
  for (var i = 0; i < arr.length; i++) {
    count=count+1;
    if(arr[i][key] == val){
      flag=1;
      return true;
    } 
  }
  if(flag==0 && count==arr.length){
    return false;
  }
 
}


getDatamarketing=()=>{
  let applicationList = [{
        url: "/creator",
  }];
 
    postApiData(applicationList,{'additional_documents':'additional_documents','application_template':'application_template','application_screening':'application_screening','building_amenities':'building_amenities','marketing':'marketing'},false).then(res => {
    
      this.setState({
        linkingLoader: true,
        data:res.data,
      },()=>{
          res.data[4].map((value)=>{
            console.log('marketing===>>>',res.data[4]);
            var val=this.contains(this.state.marketing, "name", value.name); //true
            if(value.approval_required==1 && value.name=='Renthop'){
              this.setState({renthop_approval:false});
              if(value.default_set==1){
                this.setState({status:0});
              }
            }
            if(value.approval_required==1 && value.name=='Skyward'){
              this.setState({skyward_approval:false});
              if(value.default_set==1){
                this.setState({status:0});
              }
            }
            if(value.approval_required==1 && value.name=='Localize'){
              this.setState({streetEasy_approval:false});
              if(value.default_set==1){
                this.setState({status:0});
              }
            }
            if(this.state.marketing.length==0 && value.default_set==1){
              this.setState(state => (this.state.marketing.push({related_id:value.id,name:value.name})));
            }else if(val && value.default_set==1)
            {
              this.setState(state => (this.state.marketing.push({related_id:value.id,name:value.name})));
            }
            this.setState({ linkingLoader: false, flag:1})
          })

      })
       
    }).catch(error => {
    if(error.response){
        this.setState({myerrormsg: error.response.data.error.message});
    }
    });
}

handleMarketCheckBox =(e, check)=>{
    
 
  let marketBoxes = [...this.state.marketBoxes];
  if(e.target.checked) {
  marketBoxes.push(check)
  } else {
   let index = marketBoxes.findIndex((ch) => ch.id === check.id);
    marketBoxes.splice(index, 1);
  }
  this.setState({marketBoxes});
}


// onHandleChange=(event)=>{
//   const id = event.target.id;
//   const value = event.target.value;
//   const dataset = event.currentTarget.dataset.id;
 
//   if(id == "Condo" || id == "Co-op" || id == "Condop" || id == "Single Family" || id == "Multifamily"){
//     if(id!="Single Family"){
//       this.setState({
//         building_ownership :dataset,
      

//       })
//     }else{
//       this.setState({
//         building_ownership : dataset
//      })
//     }
      
//   }
     
   
// }

// handle change for Unregistered client

handleChangeunregclient=(e)=>{
  var value = e.target.value.split(/,|;| /);
  var id = e.target.id;
  const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;

  for(var i=0;i<value.length;i++){

  if(id==="unregistered_clients_email"){
    // if(value==""){
    //     this.setState({
    //     [id+'_error']:"",
    //     [id]: ''
    //     })
    //    }
    //    else 
       if(pattern.test(document.getElementById('unregistered_clients_email').value)===false){
        this.setState({
            [id+'_error']:"Enter valid email format",
            [id]:[value]
            })
       }
       else{
      this.setState({
        [id+'_error']:'',
        [id]:value
      })
    }
}
}
}

handleChangeunregclient1=(e)=>{
  var value = e.target.value.split(/,|;| /);
  var id = e.target.id;
  const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;

  for(var i=0;i<value.length;i++){

  if(id==="unregistered_clients_email1"){
    // if(value==""){
    //     this.setState({
    //     [id+'_error']:"",
    //     [id]: ''
    //     })
    //    }
    //    else 
       if(pattern.test(document.getElementById('unregistered_clients_email1').value)===false){
        this.setState({
            [id+'_error']:"Enter valid email format",
            [id]:[value]
            })
       }
       else{
      this.setState({
        [id+'_error']:'',
        [id]:value
      })
    }
}
}
}

//--get saved search data--

getSaveSearchfeedData()
{
  this.setState({linkingLoader:true})
    let listingWhere='';
       listingWhere=[{
        url :"/agent/get_savesearchfeed/"+getAgentId()
      }];
         
    getApiData(listingWhere,true).then(res=>{
      console.log('feedfilterdata',res.data)
      this.setState({
        feedfilterdata:res.data,
        },()=>{
          this.setState({linkingLoader:false})
      })
    }).catch(err=>{

  
    }) 
  }

  handleChangeAction =param=>checked => {
   var feedSearchId = param[0];
    if (checked){
      this.state.action=1
      this.state.feedfilteractionId=feedSearchId;
        this.actionSubmit(); 
    }
    else {
      this.state.action=0;
      this.state.feedfilteractionId=feedSearchId;
      this.actionSubmit();
    }
    };

    actionSubmit=()=>{
      let listingWhere=[{
        url :'/agent/actionEdit'
      }];
      postApiData(listingWhere,{'action':this.state.action,'id':this.state.feedfilteractionId},true).then(res=>{
       this.getSaveSearchfeedData();
      }).catch(error=>{
  
      })
    }

    handleChangefrequency=param=>e=> {
      
      var id = e.target.id;
      var frequencyId= param[0];
      if (id =="frequency") {
          this.state.frequency= document.getElementById("frequency").value;
          this.state.frequencyId=frequencyId;
          this.frequencySubmit();
    }
  };

  frequencySubmit=()=>{
  
    let listingWhere=[{
      url :'/agent/frequencyEdit'
    }];
    postApiData(listingWhere,{'frequency':this.state.frequency,'id':this.state.frequencyId},true).then(res=>{
     this.getSaveSearchfeedData();
    }).catch(error=>{

    })
  }

  Openfeedfilter() {
    var x = document.getElementById("feedfilteremail");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    }

    // -- start code for Email to client for multiple check listing--

handleClicksavedclient=param=>event=>{
  let value=param[1]; //id
  let value_name=param[0]; //name
  if(this.state.feedfiltercli_email==''){
      this.setState({feedfiltercli_email:value_name,feedfiltercli_name:value})
  }
  if(!document.getElementById(event.target.id).checked){
	
    this.removeValue(this.state.feedfiltercli_email,value_name,',',(cberr,cbres)=>{
     
     if(cbres!=''){
       //var listName=cbres.split(',');
       this.setState({feedfiltercli_email:cbres});
     }
     else{
       this.setState({feedfiltercli_email:cbres});
     }
    });
     this.removeValue(this.state.feedfiltercli_name,value,',',(cberr,cbres)=>{

     if(cbres!=''){
       this.setState({feedfiltercli_name:cbres});
     }
     else{
     this.setState({feedfiltercli_name:cbres});
     }
   
     });
    } 
 
    if(document.getElementById(event.target.id).checked){
     
     this.addValue(this.state.feedfiltercli_email,value_name,',',(cberr,cbres)=>{
               if(cbres!=''){
                  this.setState({feedfiltercli_email:cbres});
              }
              
          });
          
          this.addValue(this.state.feedfiltercli_name,value,',',(cberr,cbres)=>{
           if(cbres!=''){
            var listName=cbres.split(',');
               this.setState({feedfiltercli_name:cbres});
            }
            
        });
         
     }
}
setClientEmailId=(serchsavedId)=>{
  // console.log("feedId",feedId)
this.setState({searchfeedId:serchsavedId
},()=>{
  this.getSaveSearchEmailList();
})
}
setdeletefeedId=(deletesavedId)=>{
  var r=window.confirm("Are you sure to delete the Search Feed?");
  if(r==true){
  let where = [{
    url: '/agent/delete_savesearchfeed',
    urlParms: deletesavedId
}];
deleteApiData(where).then(res => {
      if(res){
        this.getSaveSearchfeedData();
    }
}).catch(error => {
       console.log(error);
   });
  }
}




savedsearchDistribution(){

  let where=[{
           url:'/agent/saved_search_Client'
   }];  
 
   var data={'allclientemail':this.state.feedfiltercli_email,'unregistered_feedclients_email1':this.state.unregistered_feedclients_email1,'id':this.state.searchfeedId };
   postApiData(where,data,true).then(res=>{
	   this.setState({feedfiltercli_name:[],feedfiltercli_email:[],unregistered_clients_email:[]})
	   
	    document.getElementById('multipleCheckListing').click()
	
	
       
 }).catch(error=>{
   console.log('Error=>',error)
   alert(error);
 }) 
}

handleChangeunregfeedclient1=(e)=>{
  var value = e.target.value.split(/,|;| /);
  var id = e.target.id;
  const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;

  for(var i=0;i<value.length;i++){

  if(id==="unregistered_feedclients_email1"){
  
       if(pattern.test(document.getElementById('unregistered_feedclients_email1').value)===false){
        this.setState({
            [id+'_error']:"Enter valid email format",
            [id]:[value]
            })
       }
       else{
      this.setState({
        [id+'_error']:'',
        [id]:value
      })
    }
}
}
}

//-- Get Email list for saved search

getSaveSearchEmailList()
{
  this.setState({linkingLoader:true})
    let listingWhere='';
       listingWhere=[{
        url :"/getSavedSearchEmail/"+this.state.searchfeedId
      }];
         
    getApiData(listingWhere,true).then(res=>{
      console.log('feedfilterEmaillist',res.data[0].distribution_List)
      this.setState({
        feedfilterEmaillist:res.data[0].distribution_List,
        },()=>{
          this.setState({linkingLoader:false})
      })
    }).catch(err=>{

  
    }) 
  }

  deleteclientname=(deleteEmail)=>{
    var r=window.confirm("Are you sure you want to Remove this client from the mailing list?");
    if(r==true){
      
    let where = [{
      url: '/agent/delete_savesearchfeedEmail/'+this.state.searchfeedId
      
  }];
  postApiData(where,{'deleteEmailvalue':deleteEmail},true).then(res => {
        if(res){
          this.getSaveSearchEmailList();
      }
  }).catch(error => {
         console.log(error);
     });
    }
  }


render() {
if(this.state.unregistered_clients_email=='' || this.state.unregistered_clients_email==undefined){
  this.state.unregistered_clients_email_error='';
}
if(this.state.unregistered_clients_email1=='' || this.state.unregistered_clients_email1==undefined){
  this.state.unregistered_clients_email1_error='';
}
  

  const {
 
    bedrooms,
    bathrooms,
    description,
    price,
    date_available,
    min_lease_term,
    incentives,
    tenant_incentive,
    imagePathArray,
    kuula_vr_link,
    amenityList,
    checkedBoxes,
    marketBoxes,
    list_images,
    //error
    building_address_error,
    landlord_name_error,
    landlord_email_error,
    landlord_phone_error,
    unit_number_error,
    bedrooms_error,
    bathrooms_error,
    square_footage_error,
    price_error,
    date_available_error,
    min_lease_term_error,
    default_application_template_error,
    screenings_required_error,kuula_vr_link_error,
    data,
    linkingLoader,
    building_ownership,
    total_rooms,
    total_rooms_error,
    
  } = this.state;
  console.log('this.state.selected_list_id-->',this.state.selected_list_id.join("\n"))

  var placeholder = (
		<div> DROP HERE ! </div>
	);
 console.log("this.state.amenity_list",this.state.amenity_list);
 console.log("this.state.amenityData",this.state.amenityData);
  const addressDW = this.state.listingAddressData.map((dw,index) => 
	(dw.flag==1) ? <li id="searchkey" onClick={this.handleClickOnList([dw.listing_info_address])}>{dw.listing_info_address}</li>:''
  );

  const listingsIdDetails = this.state.listingData.map((value,index) => 


							<div style={{border:'2px solid #d2d2d2', marginRight:'15px', marginBottom:'7px',textTransform:'uppercase',width:'320px',float:'left'}}>
							<a href={getBaseUrl()+'/client/listing/Details/'+value.id} target="_blank"><img src={(value.image!='' && value.image!=null) ?value.image.indexOf("http")==-1?getBaseUrl()+"/containers/listing_images/download/" + value.image:value.image:getBaseUrl()+'/listing-img.png'} width="320" height="189" style={{paddingBottom:'15px'}} /></a>
							
							<div  style={{paddingLeft:'6px',paddingRight:'14px',minHeight:'145px'}}>	
								{/* <strong>East El Paso</strong> <br /> */}
							 {value.listing_info_address} <br /><br />
								<strong>{value.price?'$'+value.price.toLocaleString():'$'+value.price}</strong> <br /><br />
								{value.bathrooms} BR / {value.bedrooms} BA <br /><br />
								<a href={getBaseUrl()+'/client/listing/Details/'+value.id} style={{fontWeight:'600',textDecoration:'none',color:'#000',fontSize:'10px',borderRadius:'3px',border:'1px solid #d2d2d2',padding:'10px'}}>View Details</a>
							</div>
							</div>
					
);
	  
	const neighborDW = this.state.neighoberData.map((dw,index) => 
	(dw.flag==1) ? <li id="searchNeighborData" onClick={this.handleClickOnList([dw.neighborhood])}>{unescape(dw.neighborhood)}</li>:''
  );
  const brokerDW = this.state.brokerData.map((dw,index) => 
	(dw.flag==1) ? <li id="searchBrokerData" onClick={this.handleClickOnList([dw.mgt_company])}>{dw.mgt_company}</li>:''
  );

  // get client data
  var clientData;
	if(this.state.client_detail){
        clientData =  this.state.client_detail.map((item,index)=>{
			return    <span><input type="checkbox" id={`box-`+index} onChange={this.handleClickOnClient([item.client_email,item.realm])} value={[item.client_email,item.realm]}/><label for={`box-`+index}>{item.realm}</label></span>
		})
  }
  
  // ---end---

  // get client data for multiple check listing
  var multiple_clientdata;
	if(this.state.client_detail_multiple){
    multiple_clientdata =  this.state.client_detail_multiple.map((item,index)=>{
			return    <span><input type="checkbox" id={'multi_'+index} onChange={this.checkMultipleListId([item.client_email,item.realm])} value={[item.client_email,item.realm]}/><label for={'multi_'+index}>{item.realm}</label></span>
		})
  }
  
  // ---end---

  // get client data for search saved feed filter
  var feedFilterclientData;
	if(this.state.client_detail_feed){
    feedFilterclientData =  this.state.client_detail_feed.map((item,index)=>{
			return    <span><input type="checkbox" id={index} onChange={this.handleClicksavedclient([item.client_email,item.realm])} value={[item.client_email,item.realm]}/><label for={index}>{item.realm}</label></span>
		})
  }
 

    const blobImages = imagePathArray.map((image,index) => {
      for(let i=0; i<=imagePathArray.length;i++){
        return (
          <div className="col-sm-3">
        <div className="slider-frame" style={{width:'100%'}}>
       
          <div className="add-img" style={{background:'url('+ image.file +') center', backgroundSize:'cover',transform: `rotate(${image.current_rotation}deg)`}} >
            </div>
          
          <div
            to
            className="close-thumb"
            onClick={this.closeBlobImage.bind(this, image)}
          >
            Close
          </div>
          <div className="rotate-wrap"> 
            <input onClick={this.rotateleft([image.file,index,image.current_rotation,])} type="button" value=""  className="left-rotate" />
            <input onClick={this.rotate([image.file,index,image.current_rotation,])} type="button" value="" className="right-rotate"/>
            </div>
        
        </div>
        </div>
        );
      }
      });



    const {isListingExist } = this.state;
    let rentallistingHTML = this.state.rentalListing.map((s) => <tr >
        <td><input type="checkbox" onChange={this.selectedListingId} value={s.id} /></td>
        <Link to data-toggle="modal" data-target="#ListingModal"onClick={()=>this.feedDetailById(s.id)}>
        <td className="addresstd"><Link to={{ pathname: '/listing/details/'+s.id,
									}} target="_blank"><span style={{fontWeight:"bold"}}>{unescape(s.neighborhood)}</span><br /> {s.listing_info_address}</Link></td>
        <td>{s.unit_number}</td>
        <td>{s.bedrooms}BR </td>
				<td>{s.bathrooms}BA{(s.marketable==1)}</td>
        <td>{s.price?'$'+s.price.toLocaleString():'$'+s.price}</td>
        
        <td>{s.mgt_company} {(s.listing_information=='true' || s.listing_information=='Exclusive')?<div className="pull-right brokerspan">E</div>:''} </td>
        </Link>
        <td>{(Moment(s.date_available)>Moment() && s.date_available!='0000-00-00')? Moment(s.date_available).format('MM/DD/YY'):'Immediately'}</td>
        <td width="40">{(s.listing_source!==0)?(s.listing_information=='null') ?(s.marketable==1)
        ?<Link to className="icon-wrap avaliable pull-right"  onClick={this.handleShowImage} id={s.id} data-toggle="modal" data-target="#searchr"   ></Link>
        :<Link to className="icon-wrap inactive pull-right" id={s.id} ></Link> :''
        :<Link to className="icon-wrap gray pull-right" id={s.id} ></Link> }
        <input type="hidden" value={s.kuula_vr_link} id={`vrlink${s.id}`} /></td>
    </tr>
    
    );

    let savedfilterdata='';
     savedfilterdata = this.state.feedfilterdata.map((s) => <tr >
        <td><span>{s.selectedManhattan!='' || s.selectedBrooklyn!='' || s.selectedBronx!='' || s.selectedQueens!='' || s.selectedStatenIsland!='' || s.selectedNewJersey!='' ?`Location:  ${s.selectedManhattan?s.selectedManhattan+',':''}${s.selectedBrooklyn?s.selectedBrooklyn+',':''}${s.selectedBronx?s.selectedBronx+',':''}${s.selectedQueens?s.selectedQueens+',':''}${s.selectedStatenIsland?s.selectedStatenIsland+',':''}${s.selectedNewJersey?s.selectedNewJersey+',':''}`:''}</span>
    <span>{s.maxPrice!='-1' && s.minPrice!='-2'?` Price: ${'$'+s.minPrice.toLocaleString()+'-'+ '$'+s.maxPrice.toLocaleString()},`:''}</span>
        <span>{s.selectedBedRooms?` Bedrooms: ${s.selectedBedRooms},`:''}</span>
        <span>{s.selectedBathRooms?` Bathrooms: ${s.selectedBathRooms},`:''}</span>
        <span>{s.selectedCompany?` Company: ${s.selectedCompany},`:''}</span>
        <span>{s.selectedBuilding?` Building: ${s.selectedBuilding},`:''}</span>
        <span>{s.selectedMedia?` Media: ${s.selectedMedia},`:''}</span>
        <span>{s.amenitiesListvalue ?` Unit Amenities:  ${s.amenitiesListvalue},`:''} </span>

        <span>{s.buildingListvalue?` Building amenities:  ${s.buildingListvalue},`:''}</span>
        </td>
        <td>{ Moment(s.savedCreated_date).format('MMMM DD, YYYY')}</td>
        
        <td style={{textAlign:'center'}}><Link to data-toggle="modal" data-target="#Addemail" onClick={()=>this.setClientEmailId(s.id)}><i class="fa fa-envelope"></i></Link></td>
        <td className="text-center" width="140">
                         <select className="form-control savesdropdown"
                         id='frequency'
                         onChange={this.handleChangefrequency([s.id])}
                         >
                          <option selected={s.frequency=='Daily'} value='Daily'>Daily</option>
                          <option selected={s.frequency=='weekly'} value='weekly'>Weekly </option>
                         </select>
                         </td>
        <td width="200" className="text-center">
                         <div className="form-group" id="switch" style={{display:'inline-block'}}>
                            <Switch
                              onChange={this.handleChangeAction([s.id])}
                              checked={s.action==1?'Checked':''}
                              className="react-switch"
                              id="normal-switch"
                            />
                          </div>
                            <Link to className="trashbox" onClick={()=>this.setdeletefeedId(s.id)}><i class="fa fa-trash-o"></i></Link>
                         </td>
       
    </tr>
    
    );

    let feedfilterEmaillistvalue='';
    if(this.state.feedfilterEmaillist!=null && this.state.feedfilterEmaillist!=''){
    feedfilterEmaillistvalue = this.state.feedfilterEmaillist.split(',').map((s) =>
    <tr >
   
   {s!=''?<td>{s}</td>:''}
   {s!=''?<td style={{textAlign:'center'}}><Link to onClick={()=>this.deleteclientname(s)}><i class="fa fa-remove"></i></Link></td>:''}
    </tr>
    
    );
    }
     const { userType} = this.props;
     if(this.props.userType == 'Agent'){
        }

        function contains (arr, key, val) {
          for (var i = 0; i < arr.length; i++) {
            if(arr[i][key] === val) 
            return true;
          }
          return false;
        }	
        let exist_amenity_name ;let am_id;
        exist_amenity_name=this.state.amenity_list.map(item=>{
           return <li>{item.name}</li>
        })
        var amenity_item ;
        amenity_item =this.state.amenityData.map((data,i)=>{

          return <div className="checkbox-inline" key={data.amenity_name}>
          <input
            type="checkbox"
            id={data.amenity_name}
            checked={(contains(this.state.amenity_list,'name',data.amenity_name))}
            onClick={this.handleCheckBox([data])}
          />
          <label htmlFor={data.amenity_name}>{data.amenity_name}</label>
          </div>

        });




       
        // amenity_item =this.state.amenityData.map((data,i)=>{
		
        //   var  val=contains(this.state.amenity_list, "name", data.amenity_name); //true
          
        //   if(val && this.state.existCheckboxSelection)
        //   {
        //      checkedBoxes.push(data);
        //      this.setState({existCheckboxSelection:false});
        //      this.setState({checkedBoxes});
        //   }
      
        //   return <div className="checkbox-inline" key={data.amenity_name}>
        //   <input
        //     type="checkbox"
        //     id={data.amenity_name}
        //     value={data.amenity_name}
        //     defaultChecked={val}
        //     checked={checkedBoxes.find((ch) => ch.id === data.id)}
        //     onChange={(e) => this.handleCheckBox(e, data)}
        //   />
        //   <label htmlFor={data.amenity_name}>{data.amenity_name}</label>
        //   </div>
        // })
      
        console.log("this.state.checkedbox",this.state.checkedBoxes);
        var image;
	var check;
	var image_url;

  if(this.state.image_list){
         image = this.state.image_list.map((value,index)=>{
          check=value.large_image.indexOf("https://");
          if(check==-1){
            check=value.large_image.indexOf("http://");
          }
            return  <div key={index}>
            <img src={this.state.imagePath2 && this.state.image_id==value.id ? this.state.imagePath2 : (check > -1)  ?  value.large_image : getBaseUrl()+"/containers/listing_images/download/" + value.large_image} style={{transform: `rotate(${value.rotation}deg)`}} width="100%"/>
        
          {/* {this.state.image_list[0].id==value.id ? this.props.userType!='Agent' ? <Link to className="editsmallicon" onClick={this.imageEDIT(value.id)} data-toggle="modal" data-target="#imageEdit"> <i className="fa fa-pencil " aria-hidden="true"></i></Link>:'':''}    */}
              {  this.state.url_vrData!=''  ? this.state.listing_information!='true' ? this.state.url_vrData!='' ?
              <p className="legend" style={{display:'none'}}><img src="/vr.png" /> <a href={this.state.url_vrData} target="_blank">Experience in VR</a> 
              {this.props.userType!='Agent' ?   <Link to className="editsmall" ><i className="fa fa-pencil" aria-hidden="true" data-toggle="modal" data-target="#editVR"></i></Link>:''}</p>	
              :
              <p className="legend" style={{display:'none'}}><img src="/vr.png" /> <a href="#">Experience in VR</a>
              {this.props.userType!='Agent' ?<Link to className="editsmall"><i className="fa fa-pencil" aria-hidden="true" data-toggle="modal" data-target="#editVR"></i></Link>:''} </p>	
               : '' :''}
              
                {/* <p className="legend"><img src="../../vr.png" /> <a href={this.state.url_vrData} target="_blank">Experience in VR </a> </p>	 */}
            		  
            </div>
        })
      }
      let thumbnail;
      if(this.state.image_list.length>0){
   thumbnail= this.state.image_list.map((item, i)=>{
      check=item.large_image.indexOf("https://");
      if(check==-1){
        check=item.large_image.indexOf("http://");
      }

      return {
        content: (
    
          <React.Fragment key={item.id}>
            <tr style={{cursor:'all-scroll'}}>
          <div id={item.id}>
            <td>
          <img src={this.state.imagePath3 && this.state.image_ID==item.id ? this.state.imagePath3: (check > -1)  ?  item.large_image : getBaseUrl()+"/containers/listing_images/download/" + item.thumbnail_image} style={{transform: `rotate(${item.rotation}deg)`}} width="100%"/></td>
            
            <td> <input type="file" onChange={this.fileHandler(item.id)} /></td>
                    <td><i class="fa fa-times" aria-hidden="true" onClick={()=>this.deleteImage(item.id)}></i></td>
          </div>
          </tr>
          </React.Fragment>
        )
      }

   })
   }

  let imageFromNestio= this.state.isImageExist ? this.state.listingImages.map((img,index)=>{
   check=img.thumbnail_image.indexOf("https://");
   if(check==-1){
    check=img.thumbnail_image.indexOf("http://");
  }
   return <div className="col-sm-3"  id={`img${img.id}`} >
  <div className="slider-frame" style={{width:'100%'}}  >
 
 <img src={ (check > -1)  ?  img.thumbnail_image : getBaseUrl()+"/containers/listing_images/download/" + img.thumbnail_image} style={{transform: `rotate(${img.rotation}deg)`}} width="100%"   />       
    <div
      to
      className="close-thumb"
      onClick={this.removeImage.bind(this, img.id)}
    >
      Close
    </div>
    
    {(check==-1)? <div className="rotate-wrap">
    <input onClick={this.rotateleftimagelist([img.thumbnail_image,index,img.rotation,])} type="button" value="" className="left-rotate" />
    <input onClick={this.rotateimagelist([img.thumbnail_image,index,img.rotation,])} type="button" value="" className="left-rotate" />
  </div> :''}
  </div>
  </div> 
     
}):''
   

     var contact_name_data;
     if(this.state.contactData){
     contact_name_data= this.state.contactData.map((item,index)=>{
      return <p>{item.name}</p> 
        }) 
      }
     var agentImage='';
      if(this.state.contactData){
        agentImage= this.state.contactData.map((item,index)=>{
            if(item.image)
            {
              if(item.image.startsWith("https://") || item.image.startsWith("http://"))
              return <img src={item.image} width="75" height="75" className="rounded-circle" />
              else
              return <img src={getBaseUrl()+"/containers/agent_image/download/"+item.image} width="75" height="75" className="rounded-circle" />
            }
         }) 
       } 

      var phone_data;
      if(this.state.contactData){
      phone_data= this.state.contactData.map((item,index)=>{
       return <p>{item.phone_number}</p> 
         }) 
       }

       var email_data;
       if(this.state.contactData){
       email_data= this.state.contactData.map((item,index)=>{
        return <p  className="approve-wrap" data-toggle="modal" data-target="#contacttoemail" onClick={()=>this.contacttoemail(item.email,contact_name_data,this.state.listing_info_address,this.state.price,this.state.bedrooms,this.state.listingid,getAgent_number(),getAgentName())}>{item.email}</p> 
          }) 
        }

        var dropdownData;
        if(this.state.layoutData ){
       dropdownData =  this.state.layoutData.map((item,index)=>{
          return   <option   value={item.layout} selected={this.state.layout===item.layout ? true:false} >{item.layout}</option>
          })}
    
           // Owner Pays DropDown
      var ownerpayData;
      if(this.state.owner_pays_data){
        ownerpayData =  this.state.owner_pays_data.map((item,index)=>{
        return   <option   value={item.owner_pays_broker} >{item.owner_pays_broker}</option>
        })}
      // Owner Pays DropDown

            var marketingdisplay;
            if(this.state.flag == 1){
            marketingdisplay = data[4].map((value)=>{
            if(value.name!="RLS")    
         {
                return <div className="checkbox-inline">
                        <input 
                          type="checkbox" 
                          id={value.name}
                          name={value.id} 
                          value={value.name}
                          defaultChecked={(value.default_set==1)?'checked':''}
                          disabled={this.state.rentHop ==1 && value.name=="Renthop"}
                        
                        />
                        <label htmlFor={value.name}>{value.name}</label>
                      </div>
         }
               });
              }

              function contains (arr, key, val) {
                for (var i = 0; i < arr.length; i++) {
                  if(arr[i][key] === val) return 'Checked';
                }
                return false;
              }	
    
        return (
          

                <div >
                {(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
                <div className="show_loader_on_click" id="show_loader_on_click" style={{'display':'none'}}></div>
                <div className="show_loader_on_click" id="show_loader_on_click1" style={{'display':'none'}}></div>
                {(this.state.linkingLoader)?<div className="show_loader_on_click2" id="show_loader_on_click"></div>:''}

				
              {this.props.userType=='Agent' ? <AgentHeader /> : <ManagerHeader />}
                    <main>
					{this.props.userType=='Agent' ? <AgentSideBar/>:<ManagerSideBar/>}
					<div className="content" style={{overflow:'auto','margin-bottom':'20px'}} ref="iScroll">
						<div className="work-section" >
						<div className="dashboard" >

							<div className="clearfix">
								<h2 className="pull-left">Listings Feed</h2>
                </div>
							
                <Tabs className="feedTab">
    <TabList>
      <Tab  onClick={()=>this.getRentalFeedListing()}>Rental</Tab>
      <Tab  onClick={()=>this.getSalesFeedListing()}>Sales</Tab>
      {this.props.userType!=="Manager"?<Tab  onClick={()=>this.getSaveSearchfeedData()}>Saved Searches</Tab>:''}
    </TabList>
 
    <TabPanel>
    <div className="container ">
                <FeedFilterList listing_type={this.state.type} userType={this.props.userType}  handleReturnedResult = {this.getListAfterFilterApplied.bind(this)} handleResetFilter={this.getRentalListing.bind(this)} />
							
								</div>
    <div className="row clearfix listing_count">
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
                            <div className="form-group "><input type="checkbox" id="tl" value="1" checked={this.state.total_l_checked} onChange={this.getcheckFilterList('total')}/><label for="tl">Total: <span id="Count">{this.state.totalRecords.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span></label></div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
                            <div className="form-group "><input type="checkbox" id="RLS" value="1" checked={this.state.rls_l_checked} onChange={this.getcheckFilterList('rls')} /><label for="RLS">RLS: <span id="Count">{this.state.totalRlsRecords.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span></label></div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
                            <div className="form-group "><input type="checkbox" id="open_L" value="1" checked={this.state.open_l_checked} onChange={this.getcheckFilterList('open')}/><label for="open_L">Open: <span id="Count">{this.state.totalOpenRecords.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span></label></div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
                            <div className="form-group "><input type="checkbox" id="market_L" checked={this.state.market_l_checked}   onChange={this.getcheckFilterList('market')} /><label for="market_L">Marketable: <span id="Count">{this.state.totalMarketRecords.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span></label></div>
                    </div>
                </div>

              </div>
              
              <div className="row clearfix listing_count">
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
        <div className="form-group ">{this.state.selected_list_id.length>0?<button type="button" className="btn btn-big btn-relist" data-toggle="modal" onClick={this.getSelectedListing} data-target="#multipleCheckListing">Email to Client</button>:<button type="button"  className="btn disabled">Email to Client</button>}</div>
                    </div>
                </div>
             </div>

              <div className="panel-body table-responsive">
               {/*<InfiniteScroll
                        pageStart={0}
                        loadMore={this.getlazyLoadList.bind(this)}
                        hasMore={this.state.hasMoreItems}
                        loader={(this.state.hasMoreItems)?<div className="loader">Loading ...</div>:''}
                        useWindow={true}
                        threshold={250}
               >*/}
								<table className="table table-striped agent-listing a-list" style={{marginTop:'0px', marginBottom:'100px'}}>
										<thead>
											<tr>
                        <th></th>
												<th>Address <Link to  id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="search" class="collapse table-collapse client-collapse" ref={this.setWrapperRef1}>
												<div className="box">

												<input type="text" style={{maxWidth:'235px'}} id="searchParm" autocomplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
												<span onClick={this.resetsearchParm}>(X)</span>
													<ul>
													{addressDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
												
												</div>
												</div>
												</th>
												<th>Unit #</th>
                        <th>
<Link to id="order_by_br"  
className={
  (this.state.orderBy!='bedrooms')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='bedrooms' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='bedrooms' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
}
 key='BR' onClick={this.setOrderBy(['BR'])}  >{(this.state.orderBy=='bedrooms')?this.state.orderByState:'None'}</Link> BR {/*<Link to id="bedroomClick" data-toggle="collapse" data-target="#layout" className="icon-wrap status" onClick={this.StatusLayoutClick}  ></Link>
                        <div id="layout" class="collapse table-collapse status-collapse" style={{minWidth:'60px'}} ref={this.setWrapperRef5}>
												<div className="box">
                        <input type="checkbox" id="0_bedroom" defaultChecked={this.state.ckbedroom_0.checked} value={this.state.ckbedroom_0.value} onChange={this.search}/>
													<label for="0_bedroom">0</label>
													<input type="checkbox" id="1_bedroom" defaultChecked={this.state.ckbedroom_1.checked} value={this.state.ckbedroom_1.value} onChange={this.search}/>
													<label for="1_bedroom">1</label>
													<input type="checkbox" id="2_bedroom" defaultChecked={this.state.ckbedroom_2.checked} value={this.state.ckbedroom_2.value} onChange={this.search} />
													<label for="2_bedroom">2</label>
													<input type="checkbox" id="3_bedroom" defaultChecked={this.state.ckbedroom_3.checked} value={this.state.ckbedroom_3.value} onChange={this.search}/>
													<label for="3_bedroom">3</label>
                          							<input type="checkbox" id="4_bedroom" defaultChecked={this.state.ckbedroom_4.checked} value={this.state.ckbedroom_4.value} onChange={this.search}/>
													<label for="4_bedroom">4+</label>


											</div>		
												</div>
*/}
                        </th>
                        <th><Link to id="order_by_ba" className={
  (this.state.orderBy!='bathrooms')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='bathrooms' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='bathrooms' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='BA' onClick={this.setOrderBy(['BA'])}   >{(this.state.orderBy=='bathrooms')?this.state.orderByState:'None'}</Link>BA {/*<Link to id="bathroomClick" data-toggle="collapse" data-target="#ba" className="icon-wrap status" onClick={this.StatusBathClick} ></Link>
                       
                        <div id="bath" class="collapse table-collapse status-collapse" style={{minWidth:'60px'}} ref={this.setWrapperRef6}>
												<div className="box">
                        <input type="checkbox" id="0_bathroom" defaultChecked={this.state.ckbathroom_0.checked} value={this.state.ckbathroom_0.value} onChange={this.search} />
                        <label for="1_bathroom">0</label>
													<input type="checkbox" id="1_bathroom" defaultChecked={this.state.ckbathroom_1.checked} value={this.state.ckbathroom_1.value} onChange={this.search} />
													<label for="1_bathroom">1</label>
													<input type="checkbox" id="2_bathroom" defaultChecked={this.state.ckbathroom_2.checked} value={this.state.ckbathroom_2.value} onChange={this.search} />
													<label for="2_bathroom">2</label>
													<input type="checkbox" id="3_bathroom" defaultChecked={this.state.ckbathroom_3.checked} value={this.state.ckbathroom_3.value} onChange={this.search} />
													<label for="3_bathroom">3</label>
                          							<input type="checkbox" id="4_bathroom" defaultChecked={this.state.ckbathroom_4.checked} value={this.state.ckbathroom_4.value} onChange={this.search}/>
													<label for="4_bathroom">4+</label>
													</div>
												</div>
*/}
                        </th>
										
												<th><Link to id="order_by_Price" className={
  (this.state.orderBy!='price')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='price' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='price' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='Price' onClick={this.setOrderBy(['Price'])}   >{(this.state.orderBy=='price')?this.state.orderByState:'None'}</Link> Price {/*<Link to id="priceClick" data-toggle="collapse" data-target="#price" className="icon-wrap status" onClick={this.StatusPriceClick} ></Link>												
												<div id="price" class="collapse table-collapse" ref={this.setWrapperRef4}>
												
													<div className="listing-filter box " style={{margin:0, height:'90px'}}>
													<InputRange
                          formatLabel={value => (value>10000)?`No Limit`:`${'$'+value}`}
														maxValue={10500}
														minValue={0}
														value={this.state.priceSearch}
														onChange={priceSearch => this.setState({priceSearch })} onChangeComplete={value => this.searchByPrice(value)} />
													</div>
                        </div>
*/}
                        </th>
												
												<th>Management / Listing Broker {/* <Link to  id="brokerClick" data-toggle="collapse" data-target="#search1" className="icon-wrap search" onClick={this.StatusBrokerClick}></Link>
												<div id="broker" class="collapse table-collapse client-collapse" ref={this.setWrapperRef2}>
												<div className="box">

												<input type="text" id="searchBroker" autocomplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
                        <span onClick={this.resetsearchParm}>(X)</span>
											  <ul>{brokerDW}</ul>
                        {(this.state.searchMsg)?'No suggestions available':''}
												</div>
												</div>
            */}
												</th>
												<th>Available</th>
                        <th width="40"><Link to  className="icon-wrap avaliable pull-right"></Link></th>
											</tr>
										</thead>
										<tbody>
                  
                       {(this.state.rentalListing.length>0 && !this.state.linkingLoader)?rentallistingHTML:(this.state.rentalListing.length==0 && !this.state.linkingLoader)?<tr><td colSpan='7'>You don't have any feed.</td></tr>:'' }			
                       {this.state.hasMoreItems ?<tr><td colSpan='9'> <p style={{'padding-bottom':'20px'}} className="loading"> Loading Items....</p></td></tr> : ""}
                      
          
            
										</tbody>
										</table>
                  {/* </InfiniteScroll> */}
									</div>
    </TabPanel>
    <TabPanel>
        <div className="container ">
                <SalesFeedFilterList listing_type={this.state.type} userType={this.props.userType} handleReturnedResult = {this.getListAfterFilterApplied.bind(this)} handleResetFilter={this.getRentalListing.bind(this)} />
							
								</div>

    <div className="row clearfix listing_count">
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
                            <div className="form-group "><input type="checkbox" id="tl" value="1" checked={this.state.total_l_checked} onChange={this.getcheckFilterList('total')}/><label for="tl">Total: <span id="Count">{this.state.totalRecords.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span></label></div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
                            <div className="form-group "><input type="checkbox" id="RLS" value="1" checked={this.state.rls_l_checked} onChange={this.getcheckFilterList('rls')} /><label for="RLS">RLS: <span id="Count">{this.state.totalRlsRecords.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span></label></div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
                            <div className="form-group "><input type="checkbox" id="open_L" value="1" checked={this.state.open_l_checked} onChange={this.getcheckFilterList('open')}/><label for="open_L">Open: <span id="Count">{this.state.totalOpenRecords.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span></label></div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
                            <div className="form-group "><input type="checkbox" id="market_L" checked={this.state.market_l_checked}   onChange={this.getcheckFilterList('market')} /><label for="market_L">Marketable: <span id="Count">{this.state.totalMarketRecords.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span></label></div>
                    </div>
                </div>

                <div className="row clearfix listing_count">
                <div className="col-sm-6 col-md-3">
                    <div className="commoncheck">
        <div className="form-group ">{this.state.selected_list_id.length>0?<button type="button" className="btn btn-big btn-relist" data-toggle="modal" onClick={this.getSelectedListing} data-target="#multipleCheckListing">Email to Client</button>:<button type="button"  className="btn disabled">Email to Client</button>}</div>
                    </div>
                </div>
             </div>

              </div>
              <div className="panel-body table-responsive">
               {/*<InfiniteScroll
                        pageStart={0}
                        loadMore={this.getlazyLoadList.bind(this)}
                        hasMore={this.state.hasMoreItems}
                        loader={(this.state.hasMoreItems)?<div className="loader">Loading ...</div>:''}
                        useWindow={true}
                        threshold={250}
               >*/}
								<table className="table table-striped agent-listing" style={{marginTop:'0px', marginBottom:'80px'}}>
										<thead>
											<tr>
                        <th></th>
												<th>Address <Link to  id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="search" class="collapse table-collapse client-collapse" ref={this.setWrapperRef1}>
												<div className="box">

												<input type="text" style={{maxWidth:'235px'}} id="searchParm" autocomplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
												<span onClick={this.resetsearchParm}>(X)</span>
													<ul>
													{addressDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
												
												</div>
												</div>
												</th>
												<th>Unit #</th>
                        <th>
<Link to id="order_by_br"  
className={
  (this.state.orderBy!='bedrooms')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='bedrooms' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='bedrooms' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
}
 key='BR' onClick={this.setOrderBy(['BR'])}  >{(this.state.orderBy=='bedrooms')?this.state.orderByState:'None'}</Link> BR {/*<Link to id="bedroomClick" data-toggle="collapse" data-target="#layout" className="icon-wrap status" onClick={this.StatusLayoutClick}  ></Link>
                        <div id="layout" class="collapse table-collapse status-collapse" style={{minWidth:'60px'}} ref={this.setWrapperRef5}>
												<div className="box">
                        <input type="checkbox" id="0_bedroom" defaultChecked={this.state.ckbedroom_0.checked} value={this.state.ckbedroom_0.value} onChange={this.search}/>
													<label for="0_bedroom">0</label>
													<input type="checkbox" id="1_bedroom" defaultChecked={this.state.ckbedroom_1.checked} value={this.state.ckbedroom_1.value} onChange={this.search}/>
													<label for="1_bedroom">1</label>
													<input type="checkbox" id="2_bedroom" defaultChecked={this.state.ckbedroom_2.checked} value={this.state.ckbedroom_2.value} onChange={this.search} />
													<label for="2_bedroom">2</label>
													<input type="checkbox" id="3_bedroom" defaultChecked={this.state.ckbedroom_3.checked} value={this.state.ckbedroom_3.value} onChange={this.search}/>
													<label for="3_bedroom">3</label>
                          							<input type="checkbox" id="4_bedroom" defaultChecked={this.state.ckbedroom_4.checked} value={this.state.ckbedroom_4.value} onChange={this.search}/>
													<label for="4_bedroom">4+</label>


											</div>		
												</div>
*/}
                        </th>
                        <th><Link to id="order_by_ba" className={
  (this.state.orderBy!='bathrooms')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='bathrooms' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='bathrooms' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='BA' onClick={this.setOrderBy(['BA'])}   >{(this.state.orderBy=='bathrooms')?this.state.orderByState:'None'}</Link>BA {/*<Link to id="bathroomClick" data-toggle="collapse" data-target="#ba" className="icon-wrap status" onClick={this.StatusBathClick} ></Link>
                       
                        <div id="bath" class="collapse table-collapse status-collapse" style={{minWidth:'60px'}} ref={this.setWrapperRef6}>
												<div className="box">
                        <input type="checkbox" id="0_bathroom" defaultChecked={this.state.ckbathroom_0.checked} value={this.state.ckbathroom_0.value} onChange={this.search} />
                        <label for="1_bathroom">0</label>
													<input type="checkbox" id="1_bathroom" defaultChecked={this.state.ckbathroom_1.checked} value={this.state.ckbathroom_1.value} onChange={this.search} />
													<label for="1_bathroom">1</label>
													<input type="checkbox" id="2_bathroom" defaultChecked={this.state.ckbathroom_2.checked} value={this.state.ckbathroom_2.value} onChange={this.search} />
													<label for="2_bathroom">2</label>
													<input type="checkbox" id="3_bathroom" defaultChecked={this.state.ckbathroom_3.checked} value={this.state.ckbathroom_3.value} onChange={this.search} />
													<label for="3_bathroom">3</label>
                          							<input type="checkbox" id="4_bathroom" defaultChecked={this.state.ckbathroom_4.checked} value={this.state.ckbathroom_4.value} onChange={this.search}/>
													<label for="4_bathroom">4+</label>
													</div>
												</div>
*/}
                        </th>
										
												<th><Link to id="order_by_Price" className={
  (this.state.orderBy!='price')?'icon-wrap non ass-blk blank':
    (this.state.orderBy=='price' && this.state.orderByState=='ASC')?'icon-wrap non ass-blk asce':
    (this.state.orderBy=='price' && this.state.orderByState=='DESC')?'icon-wrap non ass-blk dece':'icon-wrap non ass-blk blank'
} key='Price' onClick={this.setOrderBy(['Price'])}   >{(this.state.orderBy=='price')?this.state.orderByState:'None'}</Link> Price {/*<Link to id="priceClick" data-toggle="collapse" data-target="#price" className="icon-wrap status" onClick={this.StatusPriceClick} ></Link>												
												<div id="price" class="collapse table-collapse" ref={this.setWrapperRef4}>
												
													<div className="listing-filter box " style={{margin:0, height:'90px'}}>
													<InputRange
                          formatLabel={value => (value>10000)?`No Limit`:`${'$'+value}`}
														maxValue={10500}
														minValue={0}
														value={this.state.priceSearch}
														onChange={priceSearch => this.setState({priceSearch })} onChangeComplete={value => this.searchByPrice(value)} />
													</div>
                        </div>
*/}
                        </th>
											{/*	<th>Neighborhood <Link to id="neighborClick" data-toggle="collapse" data-target="#neighborhood" className="icon-wrap status" onClick={this.StatusNeighborClick} style={{display:'none'}}></Link>												
												<div id="neighbor"  class="collapse table-collapse status-collapse" ref={this.setWrapperRef3}>
												<div className="box">
                        <input type="text" style={{maxWidth:'151px'}}  id="searchNeighbor" autocomplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
											    <span onClick={this.resetsearchParm} className="pull-right">(X)</span>
													<ul>
													{neighborDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
													</div>
												</div>
              
												</th>*/}
												<th>Management / Listing Broker {/* <Link to  id="brokerClick" data-toggle="collapse" data-target="#search1" className="icon-wrap search" onClick={this.StatusBrokerClick}></Link>
												<div id="broker" class="collapse table-collapse client-collapse" ref={this.setWrapperRef2}>
												<div className="box">

												<input type="text" id="searchBroker" autocomplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
                        <span onClick={this.resetsearchParm}>(X)</span>
											  <ul>{brokerDW}</ul>
                        {(this.state.searchMsg)?'No suggestions available':''}
												</div>
												</div>
            */}
												</th>
												<th>Available</th>
                        <th width="40"><Link to  className="icon-wrap avaliable pull-right"></Link></th>
											</tr>
										</thead>
										<tbody>
                  
                       {(this.state.rentalListing.length>0 && !this.state.linkingLoader)?rentallistingHTML:(this.state.rentalListing.length==0 && !this.state.linkingLoader)?<tr><td colSpan='7'>You don't have any feed.</td></tr>:'' }			
                       {this.state.hasMoreItems ? <tr><td colSpan='9'><p style={{'padding-bottom':'20px'}} className="loading"> Loading Items....</p> </td></tr>: ""}
                      
           
										</tbody>
										</table>
                  {/* </InfiniteScroll> */}
									</div>
    </TabPanel>

    <TabPanel>
   

              <div className="panel-body table-responsive mt-5">
               {/*<InfiniteScroll
                        pageStart={0}
                        loadMore={this.getlazyLoadList.bind(this)}
                        hasMore={this.state.hasMoreItems}
                        loader={(this.state.hasMoreItems)?<div className="loader">Loading ...</div>:''}
                        useWindow={true}
                        threshold={250}
               >*/}
								<table className="table table-striped agent-listing" style={{marginTop:'0px'}}>
										<thead>
											<tr>
                      
												<th>Search Criteria</th>
												<th>Created on</th>
                        <th className="text-center">Distribution list  </th>
                        <th className="text-center">Frequency </th>
												<th className="text-center">Action </th>
											
											</tr>
										</thead>
										<tbody>
                      
                      {savedfilterdata.length>0?savedfilterdata:<tr><td colSpan="9">You have no Saved Searches</td></tr>}
                    {/* <tr>
                    
                       <td ><span style={{fontWeight:'bold'}}>Greenwich Village</span><br/> 240 Sullivan Street, Manhattan, NY, 10012</td>
                       <td>26-9-2019</td>
                       <td><Link to data-toggle="modal" data-target="#Addemail">Adem</Link></td>
                       <td className="text-center" width="140">
                         <select className="form-control savesdropdown">
                          <option>Daily</option>
                          <option>weekly </option>
                         </select>
                         </td>
                         <td width="200" className="text-center">
                         <div className="form-group" id="switch" style={{display:'inline-block'}}>
                            <Switch
                              onChange={this.handleChangeStatus}
                              checked={this.state.furnished}
                              className="react-switch"
                              id="normal-switch"
                            />
                          </div>
                            <Link to className="trashbox"><i class="fa fa-trash-o"></i></Link>
                         </td>
                    </tr> */}
                    {/* <tr>
                    
                       <td ><span style={{fontWeight:'bold'}}>Murray Hill</span><br/> 151-155 East 31st Street, Manhattan, NY, 10016,W6G</td>
                       <td>26-9-2019</td>
                       <td><Link to data-toggle="modal" data-target="#Addemail">Murray</Link></td>
                       <td className="text-center" >
                         <select className="form-control savesdropdown">
                          <option>Daily</option>
                          <option>weekly </option>
                         </select>
                         </td>
                         <td  className="text-center">
                         <div className="form-group" id="switch" style={{display:'inline-block'}}>
                            <Switch
                              onChange={this.handleChangeStatus}
                              checked={this.state.furnished}
                              className="react-switch"
                              id="normal-switch"
                            />
                          </div>
                            <Link to className="trashbox"><i class="fa fa-trash-o"></i></Link>
                         </td>
                    </tr> */}
                    {/* <tr>
                     
                       <td><span style={{fontWeight:'bold'}}>Midtown West

</span><br/>305 West 50th Street, Manhattan, NY, 10019,06M</td>
                       <td>26-9-2019</td>
                       <td><Link to data-toggle="modal" data-target="#Addemail">Midtown</Link></td>
                       <td className="text-center" >
                         <select className="form-control savesdropdown">
                          <option>Daily</option>
                          <option>weekly </option>
                         </select>
                         </td>
                         <td  className="text-center">
                         <div className="form-group" id="switch" style={{display:'inline-block'}}>
                            <Switch
                              onChange={this.handleChangeStatus}
                              checked={this.state.furnished}
                              className="react-switch"
                              id="normal-switch"
                            />
                          </div>
                            <Link to className="trashbox"><i class="fa fa-trash-o"></i></Link>
                         </td>
                    </tr> */}
                   
            
										</tbody>
										</table>
                  {/* </InfiniteScroll> */}
									</div>

    </TabPanel>
  </Tabs>

  <div id="searchr" className="modal fade" role="dialog">
                        <div className="modal-dialog">

                        
                            <div className="modal-content">
                            
                            <div className="modal-body" >
                            <div id={`searchr`} class="client-collapse quickpost">
                <div className="rental-application listing-creator-section" id='myid'>
                <div className="listing-block" id='testid'>
        <h4>Quick Post from Nestio</h4>
        <form>
        <div className="listing-block">
       
                          <div className="form-group">
                            <label>Listing Type: </label>
                            <select 
                             id="building_ownership"
                             className="form-control"
                              style={{width:'200px'}} 
                              onChange={this.handleChange}
                              > 
                                <option value="Multifamily">Multifamily</option>   
                                <option value="Condo">Condo</option>   
                                <option value="Co-op">Co-op</option>   
                                <option value="Condop">Condop</option>     
                                <option value="Single Family">Single Family</option>                             
                             </select>
                          </div>   
                 
                    </div>
             {/* ..................START...................... */}
        <div className="listing-block">
                        <h4>Unit information</h4>
                        <div className="form-inline">
                        <div className="form-group">
                            <label>Total Rooms*</label>
                            {total_rooms!==0 && this.state.checkFlag==1?   
                            <input
                              type="text"
                              className="form-control"
                              id="total_rooms"
                              value={total_rooms}
                               onChange={this.handleChange}
                              style={ total_rooms.toString().trim()==''? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                              
                            />:
                            <input
                              type="text"
                              className="form-control"
                              id="total_rooms"
                              value={total_rooms}
                              onChange={this.handleChange}
                              style={ total_rooms.toString().trim()==''? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            />
                            }
                            {/* <small className="leftbedge">#</small> */}
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {total_rooms_error !== ""
                                ? total_rooms_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Bedrooms*</label>
                            {bedrooms!=='' && bedrooms!=="null" && bedrooms!==0 && this.state.checkFlag==1 ?   
                            <input
                              type="text"
                              className="form-control"
                              id="bedrooms"
                              value={bedrooms}
                              onChange={this.handleChange}
                              style={ bedrooms =='' || bedrooms==="null"? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                              
                            />
                            :
                            <input
                            type="text"
                            className="form-control"
                            id="bedrooms"
                            value={bedrooms}
                            onChange={this.handleChange}
                            style={ bedrooms =='' || bedrooms==="null"? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                          />
                            }
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {bedrooms_error !== "" ? bedrooms_error : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Layout</label>
                          
                              <select  id="layout" className="form-control"  onChange={this.layoutChange} style={{width:'200px'}}>
                                <option value="">Select</option>
                                {dropdownData}
                              </select>
                           
                          </div>
                          <div className="form-group">
                            <label>Bathrooms*</label>
                            {bathrooms!=='' && bathrooms!=="null" && this.state.checkFlag==1 ?  
                            <input type="text" className="form-control"  id="bathrooms" value={bathrooms} onChange={this.handleChange}
                            style={ bathrooms =='' || bathrooms==="null" || bathrooms<=0 ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            
                              />
                            :
                            <input type="text" className="form-control"  id="bathrooms" value={bathrooms} onChange={this.handleChange}
                              style={ bathrooms =='' || bathrooms==="null"  || bathrooms<=0 ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            />
                            }
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {bathrooms_error !== "" ? bathrooms_error : ""}
                            </span>
                          </div>
                          
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Description*</label>
                            <div className="form-group">                    
                            <textarea id="description"
                             value={this.state.description} 
                             onChange={this.handleChange}
                             style={ this.state.description.toString().trim() =="" ? { border: '2px solid red',width:'98%'} :  { visibility: "visible",width:'98%' }}/>
                            </div>
                          </div>
                        </div>

                        <div className="clearfix">
                  
                      <div class="form-inline pull-left" style={{marginLeft:'24px'}}>
                          <div className="form-group">
                            <label>Pet Policy*</label>
                            <select 
                             id="pets"
                             className="form-control"
                              style={{width:'200px'}} 
                              onChange={this.handleChange}
                              style={ this.state.pets_policy=="" ? { borderBottom: '2px solid red',width:'200px'} :  { visibility: "visible",width:'200px' }}
                              > 
                                <option value="">Select</option>   
                                <option value="Cats Allowed">Cats Allowed</option>   
                                <option value="Dogs Allowed">Dogs Allowed</option>   
                                <option value="Pets Allowed">Pets Allowed</option>     
                                <option value="No Pets Allowed">No Pets Allowed</option>     
                                <option value="case-by-case">Case-by-Case</option>                        
                             </select>
                          </div>   
                    </div>
                    </div>
                      </div> 
   {/* ..................END...................... */}

                 {/* ..................START...................... */}
                      <div className="listing-block">
                        <h4>Lease information</h4>
                        <div className="form-inline">
                          <div className="form-group">
                            <label>Price* </label>
                         {price!=='' && price!=="null"  && price!==0  && this.state.checkFlag==1?    
                            <input
                              type="text"
                              className="form-control"
                              id="price"
                              value={price}
                              onChange={this.handleChange}
                              style={ price == 0 ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            
                            />
                            :  
                            <input
                            type="text"
                            className="form-control"
                            id="price"
                            value={price}
                            onChange={this.handleChange}
                            style={ price == 0 ? { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                            
                          /> 
                            }
                            <small className="leftbedge">$</small>
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {price_error !== ""
                                ? price_error
                                : ""}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Date Available*</label>
                            {date_available!=='' && date_available!=='null' && this.state.checkFlag==1  ?  
                         
                            <DatePicker
                              onChange={this.handleChangeDate}
                              dateFormat="MMMM d, yyyy"
                              timeCaption="time"
                              className="form-control"
                              id="date_available"     
                              value={ this.state.startDate=='' &&  this.state.startDate=='0000-00-00'? Moment(date_available).format('MM/DD/YYYY') :Moment(this.state.startDate).format('MM/DD/YYYY')}                    
                              // style={ bathrooms =='' || bathrooms==="null"? { borderBottom: '2px solid red' } :  { visibility: "visible" }}                   
                                               
                                                  />
                                :
                                <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate}
                                dateFormat="MMMM d, yyyy"
                                timeCaption="time"
                                className="form-control"
                                id="date_available"     
                                value={ this.state.startDate=='' ? Moment(date_available).format('MM/DD/YYYY') :Moment(this.state.startDate).format('MM/DD/YYYY')}                    
                                // style={ bathrooms =='' || bathrooms==="null"? { borderBottom: '2px solid red' } :  { visibility: "visible" }}                   
                                                    />
                                }
                               <br />
                            <span className="inline-error-class">
                              {" "}
                              {date_available_error !== ""
                                ? date_available_error
                                : ""}
                            </span>                   
                           </div>
                          <div className="form-group">
                            <label>Min. Lease Term*</label>
                          {min_lease_term!=='' && min_lease_term!=="null" && min_lease_term!==0 && this.state.checkFlag==1 ?   
                            <input
                              type="text"
                              className="form-control"
                              id="min_lease_term"
                              value={min_lease_term}
                              onChange={this.handleChange}
                              style={ min_lease_term == '' || min_lease_term==="null"?  { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                              
                            />
                            :
                            <input
                            type="text"
                            className="form-control"
                            id="min_lease_term"
                            value={min_lease_term}
                            onChange={this.handleChange}
                            style={ min_lease_term == '' || min_lease_term==="null"?  { borderBottom: '2px solid red' } :  { visibility: "visible" }}
                          />
                             }
                            <br />
                            <span className="inline-error-class">
                              {" "}
                              {min_lease_term_error !== ""
                                ? min_lease_term_error
                                : ""}
                            </span>    
                          </div>
                          <div className="form-group" id="switch"> 
                          {this.state.furnished==1 && this.state.furnishedFlag==1 ?
                            <Switch
                              onChange={this.handleChangeStatus}
                              checked={this.state.furnished}
                              className="react-switch"
                              id="normal-switch"
                           
                           
                            />:
                            <Switch
                              onChange={this.handleChangeStatus}
                              checked={this.state.furnished}
                              className="react-switch"
                              id="normal-switch"
                            />}
                            <span style={{ marginLeft: "10px" }}>
                              Furnished
                            </span>
                          </div>
                        </div>
                      </div>
                         {/* ..................END...................... */}

                      {/* ..................START...................... */}
                      <div className="listing-block">
                        <h4>Incentives</h4>
                        <ul className="btn-inline">
                          <li
                            className="btn"
                            onClick={this.handleChange}
                            style={
                              incentives == "CYOF"
                                ? { background: "#004F6B", color: "white",width:'183px' }
                                : { visibility: "visible",width:'183px' }
                            }
                            id="CYOF"
                            data-id="CYOF"
                          >
                            CYOF
                          </li>
                          <li
                            className="btn"
                            onClick={this.changeHandler}
                            style={
                             this.state.owner_pays == "Owner pays"
                                ? { background: "#004F6B", color: "white",width:'183px' }
                                : { visibility: "visible",width:'183px' }
                            }
                            // onClick={this.handleChange}
                            id="Owner_pays"
                            data-id="Owner pays"
                          >
                            Owner pays
                          </li>
                          <li
                            className="btn"
                            onClick={this.handleChange}
                            style={
                              incentives == "Co-broke"
                                ? { background: "#004F6B", color: "white",width:'183px' }
                                : { visibility: "visible",width:'183px' }
                            }
                            id="Co-broke"
                            data-id="Co-broke"
                          >
                            Co-broke
                          </li>
                        </ul>

                        <div
                          className="form-inline"
                          onChange={this.handleChange}
                        >
                           {this.state.owner_pays=="Owner pays" ?
                          <div>
                        <div className="form-group">
                            <label>Owner Pays Broker</label>
                              <select  id="owner_pays_broker"  className="form-control" style={{width:'200px'}}>
                                <option value="">Select</option>
                                {ownerpayData }
                              </select>
                             
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="owner_amount"
                              value={this.state.owner_amount}
                              onChange={this.changeHandler}
                            />
                            <small className="rightbedge">{this.state.owner_pays_broker=="Month(s) Rent" ? '':(this.state.owner_pays_broker=='Percentage Of Yearly Rent' || this.state.owner_pays_broker=='Percentage Of Monthly Rent') ?  "%" :"$"}</small>
                          </div>
                          </div> :""}
                          {(this.state.incentives=="Co-broke") ? 
                          <div>
                          <div className="form-group">
                            <label for="Tenant incentive">
                              Commission Percentage
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="commission_percentage"
                              value={this.state.commission_percentage}
                              onChange={this.onChangeHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.commission_percentage ==undefined || this.state.commission_percentage=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />
                            <small className="rightbedge">%</small>
                            <br/>
                            <div> <span className="inline-error-class"></span></div>
                           
                          </div>
                          <div className="form-group">
                            <label for="Tenant incentive">
                             My Split
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="my_split"
                              value={this.state.my_split}
                              onChange={this.onChangeHandler}
                              style={(this.state.RLSFlag == 1) ? (this.state.my_split ==undefined || this.state.my_split=="") ?{ borderBottom: '2px solid red' } : {visibility:"visible"}: {visibility:"visible"}}
                            />
                             <span>{this.state.final_precentage ? this.state.final_precentage+"%" : ''}</span>
                            {this.state.final_precentage?"":<small className="rightbedge">%</small>}
                         
                          </div>
                          <div className="form-group">
                            <label for="Tenant incentive">
                              Co-Broker's Split
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="co_broke_split"
                              value={this.state.co_broker_split +"%"}
                              // onChange={this.onChangeHandler}
                              disabled
                            />   <span >{this.state.copercentage_broker ? this.state.copercentage_broker+"%":''}</span>
                            {this.state.copercentage_broker?"":<small className="rightbedge">%</small>} 
                         
                           </div>
                          </div>
                         : ""}
                          <div className="form-group">
                            <label for="Tenant incentive">
                              Tenant incentive
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="tenant_incentive"
                              value={tenant_incentive}
                            />
                            <small className="righttbedge">months free</small>
                          </div>
                          <div
                            className="check-block"
                            style={{ display: "inline" }}
                          >
                            <div className="form-group ">
                              <div className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  id="noFee"
                                  value="noFee"
                                  onChange={this.handleCheck}
                                  checked={this.state.noFee}
                                />
                                <label htmlFor="noFee">Advertise as No Fee</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                         {/* ..................END...................... */}

                         <div className="listing-block check-block">
                        <h4>Marketing</h4>
                        <div
                          className="form-group"
                          onChange={this.handleChange}
                        >
                        {marketingdisplay}
                        </div>
                      </div>

        <p>Photos</p>

        <div className="row clerfix">
                        
                          <div className="col-sm-12">
                          <div className="row clerfix">
                          {/* {
           this.state.isImageExist ? this.state.listingImages.map((img,index)=>

         <div className="col-sm-3"  id={img.id} >
       <div className="slider-frame" style={{width:'100%'}}  >
      <img src={img.thumbnail_image} width="100%"   />       
         <div
           to
           className="close-thumb"
           onClick={this.removeImage.bind(this, img.id)}
         >
           Close
         </div>
       </div>
       </div> 
          
          ):''} */}
          {imageFromNestio}
                          {blobImages}
                          <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
             
              <div className="col-sm-3">
                <div className="file-upload add-image-block"></div>
              </div>
                              </div>
                             
            
          </section>
        )}
      </Dropzone>
                          </div>
                          
                          </div>
                        </div>
    
        <div className="row" style={{marginTop:'10px'}}>
                <div className="col-sm-5">
                    <label>VR Link</label>
                    <div className="form-group">
                        <input type="text" onChange={this.onVrHandle}
                                onBlur={this.onVrHandleChange}
                   defaultValue={this.state.vrlink} id="kuula_vr_link" className="form-control"/>	
                   <span style={{fontSize:'11px'}}>Note: URL must be in these urls tours.moveskyward.com, view.ricohtours.com, kuula.co, my.matterport.com, skyward360tours.com</span> 
                     <span className="inline-error-class"><br />
                              {" "}
                              {kuula_vr_link_error !== ""
                                ? kuula_vr_link_error
                                : ""}
                            </span>	
                    </div>

                </div>
                </div>
                {/* <div className="col-sm-5">
                <div className="row" style={{marginTop:'10px'}}>
                <div className="listing-block check-block ">
                        <h4>Marketing</h4>
                        {this.state.isRentHopCheck>0  ?  
                        <div>
                        <label>
                       <input type="checkbox" value="Renthop" 
                       onChange={this.toggleChange} />&nbsp;Renthop</label>
                        </div>
                       
                        : 
                        <div>
                        <label >
                       <input type="checkbox" value="Renthop" 
                       onChange={this.toggleChange}
                       disabled />&nbsp;Renthop</label>
                        </div>}
                        </div>
                      
                      </div>
                         </div>                                                    */}
        </form>	
            <p><button type="button" className="btn btn-default" disabled={!this.validation()} onClick={this.handleSubmit} id="">Submit listing for approval</button></p>	
</div>

                </div>
        </div>

                              
                                <button type="button" className="btn cl-btn" data-dismiss="modal">Close  <img src="/close.png"/></button>
                            </div>
                          
                            </div>

                        </div>
                        </div>
              <div id="Addemail" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Add Email</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
											<form>
                      <div className="form-group">
                      <lable>Selected Client</lable>
									<div className="neighbor-filter list-filter" onClick={this.Openfeedfilter}> 
										<span id="ClientName">Selected Client</span> {this.state.feedfiltercli_name} <span id="ClientCount"></span>
									</div>
										<div className="boxes neighborhoodDD clientL" id="feedfilteremail" style={{display:'none'}} ref={this.setWrapperRef14}>
										   
										    
											 {feedFilterclientData}
											{/* <span><input type="checkbox" id="box-0" value=""/> <label for="box-0"></label></span>
											<span><input type="checkbox" id="box-1" value="Adem"/> <label for="box-1">Adem</label></span>
											<span><input type="checkbox" id="box-2" value="Carry"/> <label for="box-2">Carry</label></span>
											<span><input type="checkbox" id="box-3" value="Bolt"/> <label for="box-3">Bolt</label></span> */}
										</div>
							    </div>
                  <div className="form-group">
								<lable>Unregistered Clients Email</lable>
									<input type="text" className="form-control" id="unregistered_feedclients_email1" value={this.state.unregistered_feedclients_email1} onChange={this.handleChangeunregfeedclient1}  placeholder="Clients Email"/>
                  <br />
                          <span className="inline-error-class">
                            {" "}
                            {this.state.unregistered_feedclients_email1_error !== ""
                              ? this.state.unregistered_feedclients_email1_error
                              : ""}
                          </span>

                           
							    </div>
                  <div className="form-group">{feedfilterEmaillistvalue}</div>
                          <button className="btn btn-default" onClick={()=>this.savedsearchDistribution()}>Submit</button>
								    	</form>
										</div>
									</div>
								</div>
							</div>
								

          

						
							</div>
							
						</div>
					</div>	
                    </main> 

					 <div id="ListingModal" className="modal fade" role="dialog">
                        <div className="modal-dialog">

                        
                            <div className="modal-content">
                            <div className="modal-header" style={{borderBottom:'0'}}>
                            <div style={{position:'absolute', left:'15px', top:'0px'}}><button type="button"  id="close" className="btn cl-btn " data-dismiss="modal"><img src="/close.png"/></button></div>
                                <div className="row clearfix">
                                    <div className="col-xs-6 col-sm-6">
                                    {this.state.neighborhood!= 'null' ?    
                                        <p className="price">{unescape(this.state.neighborhood)}</p>:<p></p>}
                                        <h3>{this.state.listing_info_address},{this.state.unit_number}</h3>
                                        
                                      {this.state.listing_information!='null' || this.state.listing_information=='Exclusive' ? 
                                      <span className="excListing">Exclusive</span>
                                        // <span className="excListing">{this.state.listing_information=='true' || this.state.listing_information=='Exclusive' ? 'Exclusive': 'Open Listing'}</span>
                                          : <span className="openListing">Open Listing</span>}  
                                    </div>
                                    <div className="col-xs-6 col-sm-6 text-right">
                                        <p className="price">{this.state.price?'$'+this.state.price.toLocaleString():'$'+this.state.price}{this.props.userType!='Agent'? <Link to className="editsmall" data-toggle="modal" data-target="#priceEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link>:''} </p>	
                                        <p className="room_detail">{this.state.bedrooms} bedrooms, {this.state.bathrooms} bath {this.state.square_footage?' | '+this.state.square_footage+' sq. ft.':''} {this.props.userType!='Agent'? <Link to className="editsmall"  data-toggle="modal" data-target="#bedbathsqEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link>:'' }</p>
                                        <p className="listing-update">Listing updated {this.state.time}, {this.state.date}   </p>
                                    </div>
                                </div>    
                                
                            </div>
                            <div className="modal-body" style={{paddingTop:'20px'}}>
                                <div className="listing-banner" >

                                <a data-toggle="modal" data-target="#gallery">
                                 <Carousel showThumbs={false} infiniteLoop={true} emulateTouch={true}>
                                    {/* <div>
                                    <img src="../../listing-banner.png" width="100%"/>
                                    <p className="legend"><img src="../../vr.png" />   Experience in VR </p>								
                                      </div>
                                     <div>
                                     <img src="../../listing-banner.png" width="100%"/>
									 <p className="legend"><img src="../../vr.png" />   Experience in VR </p>								
                                     </div>
                                     <div>
                                     <img src="../../listing-banner.png" width="100%"/>
									 <p className="legend"><img src="../../vr.png" />   Experience in VR </p>								
                                     </div> */}
                                 {/* {image!=''  ?  image
                                 :<div>
                                 <img src="../../listing-banner.png" width="100%"/>
                                 <p className="legend"><img src="../../vr.png" />   Experience in VR </p>								
                                 </div>
                                } */}
                                {/* {image} */}

                                { image!='' ?  image
									: <div>
									{/* {this.props.userType!='Agent' ? <Link to className="img-edit editsmallicon"  onClick={this.defaultImage(1)} data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>:''} */}
								<img src={this.state.imagePath2 ?this.state.imagePath2 :"/listing-banner.png"} width="100%"/>
								<p className="legend" style={{display:'none'}}><img src="/vr.png" />   Experience in VR
          {this.props.userType!='Agent'? <Link to className="editsmall" > <i className="fa fa-pencil" aria-hidden="true"  data-toggle="modal" data-target="#editVR"></i></Link>:''}</p>								
								</div>}
                                </Carousel>
                                </a>
                               {this.props.userType!='Agent'? <Link to className="img-edit editsmallicon" data-toggle="modal" id="imgEdit" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
                                  :""}
                                </div>

                                <div className="detail-exvr">
								<p className="experience-vr" style={{maxWidth:'212px'}}><img src="/vr.png" />   Experience in VR
                {this.props.userType!='Agent'? <Link to className="editsmall"  > <i className="fa fa-pencil" aria-hidden="true" data-toggle="modal" data-target="#editVR"></i></Link>:''}</p>		
                                    </div>
                                <div className="row clearfix">
								
                                    <div className="col-sm-9">
									<div className="incentive-box ">
                  <div className="row clearfix">
                         <div className="col-xs-6 col-sm-4">
                         <h4>Incentive Details {this.props.userType!='Agent'?<Link to className="editsmall" data-toggle="modal" data-target="#incentiveEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link>:''}</h4>
                         <p>{((this.state.incentives!='' && this.state.incentives!='undefined' && this.state.incentives!='undefined,' && this.state.incentives!=',') || (this.state.tenant_incentive!='' && this.state.tenant_incentive!=undefined  && this.state.tenant_incentive!=',' && this.state.tenant_incentive!='undefined,'))? `${this.state.incentives && this.state.incentives!=',' && this.state.incentives!='undefined,'?` Fee structure: ${this.state.incentives}`:''}${this.state.tenant_incentive?` Commission: ${this.state.tenant_incentive}`:''}`:'N/A'}</p> 
                        </div> 
                        <div className="col-xs-6 col-sm-3">
                         <h4>Contact Name</h4>
                        {contact_name_data}
                        </div>
                        <div className="col-xs-6 col-sm-2">
                         <h4>Phone </h4>
                       {phone_data}
                        </div>
                        <div className="col-xs-6 col-sm-3 textWrap" >
                         <h4>Email </h4>
                        {email_data}
                        
                        </div>
                  </div>



                                          
										 
                                        </div>

                                        <div className="amenities-box">
                                           <h4>Amenities {this.props.userType!='Agent'? <Link to className="editsmall pull-right" data-toggle="modal" data-target="#amenitiesEdit" onClick={()=>this.feedDetailById(this.state.listingid)}> <i className="fa fa-pencil" aria-hidden="true" ></i></Link> :''}</h4>
                                           {/* <ul>
                                               <li>Dishwasher</li>
                                               <li>Balcony</li>
                                               <li>Renovated Kitchen</li>
                                               <li>Elevator</li>
                                               <li>Doorman</li>
                                           </ul> */}
                                           {exist_amenity_name!="" ?  
                                           <ul> {exist_amenity_name}</ul>
                                           : <ul> No amenities available</ul>}
                                        </div>
                                        <div className="discription-box">
                                            <h4>Listing Description {this.props.userType!='Agent'?<Link to className="editsmall pull-right" data-toggle="modal" data-target="#descriptionEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link>:''}</h4>
                                            <p id="listingDesc" style={{whiteSpace:'pre-wrap'}}> </p>
                                        </div>

                                        <div className="discription-box">
                                            <h4>Note {this.props.userType!='Agent'?<Link to className="editsmall pull-right" data-toggle="modal" data-target="#noteEdit"> <i className="fa fa-pencil" aria-hidden="true"></i></Link>:''}</h4>
                                            <p>{this.state.notes} </p>
                                        </div>
                                        <div className="info" style={{float:'left'}}>
                                       {agentImage==''?<img src={getBaseUrl()+"/containers/agent_image/download/member.png"} width="75" height="75" className="rounded-circle" />:agentImage}
                    
                                       </div>
                                      <div style={{paddingLeft:'88px'}}> <p className="name">{contact_name_data}</p>
                                          <p className="browselist-email">{email_data}</p>
                                        <p>{phone_data}</p></div>

                                        
                                        <ul className="btn-group-section d-block d-sm-none">
                                        <li><button type="button" className="btn apply-btn" data-dismiss="modal">Apply Now</button></li>
                                        <li><button type="button" className="btn btn-default" data-dismiss="modal">Request a showing</button></li>
                                        {/* <li><button type="button" className="btn btn-default" data-dismiss="modal"> Save Listing <img src="/star.png" width="20"/></button></li> */}
                                        <li><div className="map-wrap">
                                            <img src="/map.png" width="100%"/>

                                        </div></li>
                                    </ul>
                                        {/* <div className="listed-box cleafix">
                                        <h4>Listed by </h4>
                                        <div className="info">
                                                <p className="name">Billy Mays</p>
                                                <p>bmays@moveskyward.com</p>
                                                <p>212.123.4567</p>
                                        </div>
                                        <img src="../../profile.png" width="75" height="75" className="rounded-circle"/>

                                        </div> */}

                                    </div>
                                    <div className="col-sm-3 d-none d-sm-block text-right">
									<p className="availabel-txt">Available {(new Date().setHours(0,0,0,0) > new Date(this.state.date_available).setHours(0,0,0,0) || this.state.date_available=='0000-00-00')? 'Immediately':this.state.date_available}{this.props.userType!='Agent' ? <Link to data-toggle="modal"  data-target="#dateEdit"> <i className="fa fa-pencil editsmall" aria-hidden="true"></i></Link>:''} </p>
									<p className="unfurnish-txt">{this.state.furnished==0 ? "Unfurnished" : "Furnished"} {this.props.userType!='Agent'?<Link to data-toggle="modal" data-target="#furnishedEdit"> <i className="fa fa-pencil editsmall" aria-hidden="true"></i></Link>:''}</p>
                                    <ul className="btn-group-section">
                                   {/* {this.state.showhide==0 
                                        ?<li onClick={this.state.isListingInfo =='null' 
                                        ?(this.state.isMarketable==1)?
                                        ()=>this.feedPostToListing(0):'':''} 
                                        className={this.state.isListingInfo=='null' 
                                        ? (this.state.isMarketable==1)
                                        ?'btn btn-big btn-relist':'btn btn-big btn-relist-inactive'
                                        :'btn btn-big btn-relist-inactive'}>Post to skyward</li>
                                        :''}  
                                        */}
                                       {(this.state.isListingInfo=='null' && this.state.listing_source!==0 && this.state.isMarketable==1) ? <li onClick={()=>this.feedPostToListing(0)}
                                        data-toggle="modal" data-target="#searchr" ><img src="/postskyward.png" width="70"/></li>
                                        :'' }  

                                     {this.state.rent_hop_export!==1 ?this.props.userType!=="Manager" ?this.state.showhide==0 
                                        ?this.state.isListingInfo=='null' && this.state.listing_source!==0 && this.state.isMarketable==1 && this.state.isRentHopCheck>0 ? <li onClick={
                                        (this.state.isMarketable==1)?
                                        ()=>this.postToRentHop(1):''} 
                                        className={ (this.state.isMarketable==1) ?'btn btn-big btn-renthop search-filter':'btn btn-big btn-renthop-inactive search-filter'
                                       }><img src="/postrenthop.png" width="70"/></li>
                                        :'' : '':'' : ''} 
                                        <li style={{marginTop:'10px'}}><button type="button"  className="btn btn-big btn-relist"  data-toggle="modal" onClick={this.getSingleListing} data-target="#emailtoclient">Email to Client</button></li>
                                      
                                    </ul>
                                    </div>
                                </div>

                              
                                <button type="button"  id="close" className="btn cl-btn" data-dismiss="modal">Close  <img src="/close.png"/></button>
                            </div>
                          
                            </div>

                        </div>
                        </div>   

                {/* edit Section */}
                <div id="priceEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Price</h4>
                            <button type="button" id="priceEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
											<form  onSubmit={this.priceSubmit}>
									<input
                                         type="number"
											className="form-control"
											step="0.00000"
											id="price"
                      value={this.state.price}
                      onChange={this.handleChange}
                                   />
                                       <span className="inline-error-class">
                                            {" "}
                                                {this.state.price_error !== ""
                                                           ? this.state.price_error
                                                         : ""}
                                                        </span><br></br>
												<button
                                                    type="submit"
                                                    disabled={!this.state.validate}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>


              <div id="gallery" className="modal fade" role="dialog">
                        <div className="modal-dialog">

                        
                            <div className="modal-content">
						
                        
                            <div className="modal-body" >
                                <div className="listing-banner">
								<button type="button" id="ListingModal" className="btn cl-btn" data-dismiss="modal"> <img src="/close.png"/></button>
	
                         		    <Carousel showThumbs={true} infiniteLoop={true} emulateTouch={true} >
									
												
                                { image!='' ?  image
									: <div>
									{/* {this.props.userType!='Agent' ? <Link to className="img-edit editsmallicon"  onClick={this.defaultImage(1)} data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>:''} */}
								<img src={this.state.imagePath2 ?this.state.imagePath2 :"/listing-banner.png"} width="100%"/>
								<p className="legend"><img src="/vr.png" />   Experience in VR 
          {this.props.userType!='Agent'? <Link to className="editsmall" > <i className="fa fa-pencil" aria-hidden="true"  data-toggle="modal" data-target="#editVR"></i></Link>:''}</p>								
								</div>}
								
                                </Carousel>
								<Link to className="img-edit editsmallicon" data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>
                                </div>
                                         
                               
                            </div>
                          
                            </div>

                        </div>
                        </div> 	



              <div id="bedbathsqEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Information</h4>
                            <button type="button" id="bedbathsqEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
										
									
							<form  onSubmit={this.bedbathSqSubmit}>
								<label>Bedrooms</label>
									<input
                                         type="number"
                                        className="form-control"
                                            id="bedrooms"
                                     value={this.state.bedrooms}
                                 onChange={this.handleChange}
                                   />   
																	 <span className="inline-error-class">
																	 {" "}
																			 {this.state.bedrooms_error !== ""
																			? this.state.bedrooms_error
																		: ""}
																		 </span> 
                                     <br></br>
								     <label>Bathrooms</label>
								   <input
                                         type="number"
                                        className="form-control"
                                            id="bathrooms"
                                      value={this.state.bathrooms}
                                 onChange={this.handleChange}
                                   /> 
																	 <span className="inline-error-class">
																	 {" "}
																			 {this.state.bathrooms_error !== ""
																			? this.state.bathrooms_error
																		: ""}
																		 </span>
                                     <br></br>
								   <label>Sq.ft.</label>
								   <input
                                         type="text"
                                        className="form-control"
                                            id="square_footage"
                                     value={this.state.square_footage}
                                 onChange={this.handleChange}
                                   />
                                       <span className="inline-error-class">
                                            {" "}
                                                {this.state.square_footage_error !== ""
                                               ? this.state.square_footage_error
                                             : ""}
                                              </span>
                                              <br></br>
												<button
                                                    type="submit"
                                                     disabled={!this.state.validate}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>
							<div id="incentiveEdit" className="modal fade" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Incentive Details</h4>
                            <button type="button" id="incentiveEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
													<form onSubmit={this.incentivesSubmit}>
												<ul className="btn-inline">
                        <li className="btn"  style={this.state.incentives == "CYOF"? { background: "#004F6B", color: "#ffffff",margin:'8px'}: { visibility: "visible",margin:'8px' } } ><a onClick={()=>this.incentive('CYOF')}>CYOF</a></li>
														<li className="btn"  style={this.state.incentives == "Owner pays"? { background: "#004F6B", color: "#ffffff" ,margin:'8px' }: { visibility: "visible" ,margin:'8px' }}><a onClick={()=>this.incentive('Owner pays')} >Owner pays</a></li>
														<li className="btn"  style={this.state.incentives == "Co-broke"? { background: "#004F6B", color: "#ffffff" ,margin:'8px' }: { visibility: "visible" ,margin:'8px' }}><a onClick={()=>this.incentive('Co-broke')}>Co-broke</a></li>
													</ul>

													<div class="form-inline">
														
															<div className="form-group">
																<label style={{margin:'7px'}}>Tenant Incentive</label>
																<input type="text" 
																className="form-control"
																id="tenant_incentive"
																value={this.state.tenant_incentive}
																onChange={this.handleChange}
																/>
																<small className="righttbedge" style={{margin:'7px'}}>months free</small>		
															</div>
															<div className="check-block" style={{display:'inline'}}>
																<div className="form-group ">
																	<div className="checkbox-inline">
																		<input type="checkbox" id="15" 
																		onChange={this.handleCheck}
																		checked={this.state.noFee}
																		/>
																		<label for="15">No Fee</label>
																	</div>	
																</div>
															</div>
															
												</div>
												
																												
											
												<button
                                 type="submit"
                                className="btn btn-default"
                                  style={{ margin: "10px" }}
                                >
                                                    Submit 
                                  	    </button>
																												
												</form>		
										
										</div>
									</div>
								</div>
							</div>
							<div id="amenitiesEdit" className="modal fade" role="dialog" ref={this.setWrapperRef10}>
                        <div className="modal-dialog">

                            <div className="modal-content">
                            <div className="modal-header" >
									<h4 className="modal-title">Edit Amenities</h4>
                            <button type="button" id="buildingamenityClose" className="close" data-dismiss="modal" onClick={this.resetCheckbox}  >&times;</button>
                             </div>
                            <div className="modal-body" >

							<div className="detail-box">
							<div className="container">
                                                   <form onSubmit={this.amenitySubmit}>
                                                    <div className="check-block">
                                                        <div
                                                            className="form-group"
																	onChange={this.handleChange}>
                                                   
                                                            {amenity_item}
																															
                                                        </div>
														</div>
			    								<button
                                                    type="submit"
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                              </button>
																					
								</form>
						 </div>
                                         </div>

									</div>
								</div>
							</div>
						</div>	
						
						
							<div id="descriptionEdit" className="modal fade" >
                        <div className="modal-dialog ">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Description</h4>
                            <button type="button" id="descriptionEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
							<form onSubmit={this.descriptionSubmit} >
								
									<textarea 
									id="description"
									value={this.state.description}
									rows="4" cols="60"
									onChange={this.handleChange}
									/>
										
											  	
                                       <span className="inline-error-class">
                                            {" "}
                                                {this.state.description_error !== ""
                                                           ? this.state.description_error
                                                         : ""}
                                                        </span>
												<button
                                                    type="submit"
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
								</div>
								<div id="dateEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Date</h4>
                            <button type="button" id="dateEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
										
							<form onSubmit={this.dateSubmit}>
									<DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleChangeDate}
                              dateFormat="MMMM d, yyyy"
                              timeCaption="time"
                              className="form-control"
															id="date_available" 
															value={ this.state.startDate=='' ? Moment(this.state.date_available).format('MM/DD/YYYY') :Moment(this.state.startDate).format('MM/DD/YYYY')}                        
                                                  />
												<button
                                                    type="submit"
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>

              <div id="furnishedEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit</h4>
                            <button type="button" id="furnishedEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
						        	<form >
									<div className="form-group" id="switch">
                                       <Switch
                                        onChange={this.handleChangeStatus}
                                        checked={this.state.furnished}
                                        className="react-switch"
                                         id="normal-switch"
                                         />
                            <span style={{ marginLeft: "10px" }}>
                              Furnished
                            </span>
                          </div>
									</form>
										</div>
									</div>
								</div>
							</div>
              {/* <div id="contactEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit contact name</h4>
                            <button type="button" id="contactEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
											<form  onSubmit={this.contactSubmit}>
									<input
                                         type="text"
											className="form-control"
											id="contact_name"
                      value={this.state.contact_name}
                      onChange={this.onChangeHandler}
                                   />
                                       <span className="inline-error-class">
                                            {" "}
                                                {this.state.contact_name_error !== ""
                                                           ? this.state.contact_name_error
                                                         : ""}
                                                        </span>
												<button
                                                    type="submit"
                                                    disabled={!this.state.validate}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>
              <div id="phoneEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit phone</h4>
                            <button type="button" id="phoneEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
											<form  onSubmit={this.phoneSubmit}>
									<input
                                         type="text"
											className="form-control"
											id="phone"
                      value={this.state.phone}
                      onChange={this.onChangeHandler}
                                   />
                                       <span className="inline-error-class">
                                            {" "}
                                                {this.state.phone_error !== ""
                                                           ? this.state.phone_error
                                                         : ""}
                                                        </span>
												<button
                                                    type="submit"
                                                    disabled={!this.state.validate}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>
              <div id="emailEdit" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit email</h4>
                            <button type="button" id="emailEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
											<form  onSubmit={this.emailSubmit}>
									<input
                                         type="text"
											className="form-control"
											id="email"
                      value={this.state.email}
                      onChange={this.onChangeHandler}
                                   />
                                       <span className="inline-error-class">
                                            {" "}
                                                {this.state.email_error !== ""
                                                           ? this.state.email_error
                                                         : ""}
                                                        </span>
												<button
                                                    type="submit"
                                                    disabled={!this.state.validate}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div> */}
              <div id="noteEdit" className="modal fade" >
                        <div className="modal-dialog ">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Edit Note</h4>
                            <button type="button" id="noteEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
							<form onSubmit={this.notesSubmit} >
								
									<textarea 
									id="notes"
									value={this.state.notes}
									rows="4" cols="60"
									onChange={this.handleChange}
									/>
										
											  	
                                       <span className="inline-error-class">
                                            {" "}
                                                {this.state.notes_error !== ""
                                                           ? this.state.notes_error
                                                         : ""}
                                                        </span>
												<button
                                                    type="submit"
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Submit 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
								</div>
                <div className="modal fade" id="editVR">
                            <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit VR Link</h4>
                               <button type="button" id="closeVr" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                     <form onSubmit={this.vrSubmit} encType="multipart/form-data">
                                    <div className="clearfix"  style={{marginBottom:"25px"}}>
																		<input
                                        type="text"
																				className="form-control"
																				id="kuula_vr_link"
                                      	value={this.state.kuula_vr_link}
                                        onChange={this.onVrHandle}
                                        onBlur={this.onVrHandleChange}
                                   /> <span className="inline-error-class">
																	 {" "}
																			 {this.state.kuula_vr_link_error !== ""
																									? this.state.kuula_vr_link_error
																								: ""}
																						 </span>
                                        </div>
                              
                                    <p className="text-center"><Button type="submit">Submit</Button></p>
                                    </form>
                                </div>
                           </div>
                            </div>
                        </div>
                        {/* <div className="modal fade" id="imageEdit">
                            <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit image</h4>
                                <button type="button" id="imageEditClose" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                     <form onSubmit={this.imageSubmit} encType="multipart/form-data">
                                    <div className="clearfix">
                                        <input type="file" onChange={this.fileChangedHandler} />
                                        </div>
                               
                                    <p className="text-center"><Button type="submit">Submit</Button></p>
                                    </form>
                                </div>
                            </div>
                            </div>
                        </div> */}


                        <div className="modal fade" id="imageEdit" >
                            <div className="modal-dialog ">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit Image(s)</h4>
                                <button type="button" id="imageEditClose" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                     <form  encType="multipart/form-data">
                                    <div className="clearfix">
                                     <table className="table table-striped">
                                       <th>Image</th>
                                       <th></th>
                                       <th></th>
                                       <tbody>
                                       <DragSortableList items={thumbnail} placeholder={placeholder}  onSort={this.onSort} dropBackTransitionDuration={0.3} type="vertical" />
                                         <tr>
										<td colSpan="3">
											{this.state.hideandshow!=1
										?	<i class="fa fa-plus" style={{cursor:'pointer'}} aria-hidden="true" onClick={this.openandhide}> Add New Image</i>
										:	<i class="fa fa-minus" style={{cursor:'pointer'}} aria-hidden="true" onClick={this.closeRow}> Add New Image</i>}
											</td>
										</tr>
										{this.state.hideandshow !=0 
										  ?
										<tr>
										<td colSpan="3"> <input type="file" onChange={this.fileHandler()} /></td>
                    </tr>:''}
                    
                    <tr><td colSpan="4">
										<span className="inline-error-class">
                                            {" "}
                                                {this.state.mymsg !== ""
                                                           ? this.state.mymsg
                                                         : ""}
                                                        </span></td></tr>
                                       </tbody>
                                     </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            </div>
                        </div>

                {/* edit section */}

  {/*Start  Modal */}
	<div class="application-approval" style={{overflow:'auto'}}>				
  <div class="modal fade" id="contacttoemail" role="dialog">
    <div class="modal-dialog">
		        
      <div class="modal-content">
        <div class="modal-header">        
          <h4 class="modal-title">Contact by Email</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
	
        <div className="modal-body">
        <p class="modal-title"><strong>To</strong></p>
        <div className="contact-info">
        
         {/*<div class="check-block">
            <div class="form-group" style={{marginLeft:'0'}}>
              <div class="checkbox-inline">
                 
                  <input type="checkbox" className="pull-left" id="t2" value="t2" checked={this.state.first_email_checked} onChange={this.getcheckFilterList('contact_email_check')} />
                  <label for="t2">{this.state.conatct_email_name}<span id="Count"></span></label>
                </div>
             </div>   
         </div> */}
                   
                   <div><input type="checkbox" checked={this.state.first_email_checked} onChange={this.getcheckFilterList('contact_email_check')} /><label for="tl">{this.state.conatct_email_name}<span id="Count"></span></label></div>
        {this.props.userType=='Agent'?<div><input type="checkbox" checked={this.state.second_email_checked} onChange={this.getcheckFilterList('agent_email_check')}   /><label for="tl">{getAgentName()}<span id="Count"></span></label></div>:''}  
                   
                   




           {/* <input type="checkbox" className="checkbox" checked={this.state.first_email_checked} onChange={this.getcheckFilterList('contact_email_check')} /> 
          <label for="tl">{this.state.conatct_email_name}<span id="Count"></span></label>*/}
          
          </div>
        {/* <div><input type="checkbox" id="tl" value="1"  /><label for="tl">Second Email<span id="Count"></span></label></div> */}
        <strong>Subject</strong>
        <div><input title={this.state.contact_to_subject + ' ' +this.state.contact_list_address} className="form-group col-sm-12" type="text" value={this.state.contact_to_subject + ' ' +this.state.contact_list_address} autocomplete="off" readOnly /></div>
		
		<div id="divContent" class="application-details" contenteditable="true">	
		  <div id="contact_Email_id">
		  </div>
		  
		  
		</div>
    <div class="application-approval-confirm">		  
		  <button type="button" id="contactmailclose"  class="btn btn-default" data-dismiss="modal">Cancel</button>
          <button type="button"  class="btn btn-default" onClick={()=>this.sendcontactmail()}>Send</button>
        </div>
		
        </div>      
      </div>     
    </div>
  </div>
</div>
{/*End Approve Modal*/}	

{/*Start Email to client Modal*/}	

<div id="emailtoclient" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                            <div className="modal-header" >
								<h4 className="modal-title">Email to Client</h4>
                            <button type="button" id="dateEditClose" className="close" data-dismiss="modal" onClick={this.getAgentsClient}>&times;</button>
                                 </div>
                            <div className="modal-body" >
														
							<form>
								<div className="form-group">
								   <lable>Selected Client</lable>
									<div className="neighbor-filter list-filter" onClick={this.OpenList}> 
										<span id="ClientName">Selected Client</span> {this.state.selected_name} <span id="ClientCount"></span>
									</div>
										<div className="boxes neighborhoodDD clientL" id="clientList" style={{display:'none'}} ref={this.setWrapperRef12}>
										   
										    
											 {clientData}
											{/* <span><input type="checkbox" id="box-0" value=""/> <label for="box-0"></label></span>
											<span><input type="checkbox" id="box-1" value="Adem"/> <label for="box-1">Adem</label></span>
											<span><input type="checkbox" id="box-2" value="Carry"/> <label for="box-2">Carry</label></span>
											<span><input type="checkbox" id="box-3" value="Bolt"/> <label for="box-3">Bolt</label></span> */}
										</div>
							    </div>
                  <div className="form-group">
								<lable>Unregistered Clients Email</lable>
									<input type="text" className="form-control" id="unregistered_clients_email1" value={this.state.unregistered_clients_email1} onChange={this.handleChangeunregclient1}  placeholder="Clients Email"/>
                  <br />
                          <span className="inline-error-class">
                            {" "}
                            {this.state.unregistered_clients_email1_error !== ""
                              ? this.state.unregistered_clients_email1_error
                              : ""}
                          </span>  
							    </div>
								<div className="form-group">
								<lable>Subject</lable>
									<input type="text" id="mail_subject" className="form-control" value={this.state.mail_subject} onChange={this.handleclientmail} placeholder="Subject"/>
							    </div>
								<div className="form-group">
								<lable>Message</lable>
							    </div>
								<div id="feedDivvalue" class="form-group" contenteditable="true" style={{border:'solid 1px', padding:'8px'}}>
							<div id="contact_Email_id">
<p>Hello,</p>								
<p>{this.state.content_value}</p><br/>
<p>Please find the listing detail from below URL</p>
<p>{listingsIdDetails}</p><br/>
<div style={{clear:'both'}}></div>

{this.props.userType=='Manager'?<p>Thank you,<br/><br/> Josiah Hyatt <br/> Founder | Principal Real Estate Broker <br/> Skyward</p>:<p>Thank you,<br/><br/>{getAgentName()}<br/>
{aes.decrypt(getAgentEmail(), key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}<br/>
{aes.decrypt(getAgent_number(), key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}<br/> </p>}


							 


							</div>
					</div>

												<button
                                                    type="button"
                                                    className="btn btn-default"
													style={{ margin: "10px" }}
													disabled={!this.validateclientemail2()}
													onClick={()=>this.feedemailsend()}
                                                >
                                                    Send 
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>


{/*End Email to client Modal*/}	

{/*Start Email to client Modal for multiple check listing*/}	

<div id="multipleCheckListing" className="modal fade" >
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content" style={{minWidth:'475px'}}>
                            <div className="modal-header" >
								<h4 className="modal-title">Email to Client</h4>
                            <button type="button" id="dateEditClose" className="close" data-dismiss="modal">&times;</button>
                                 </div>
                            <div className="modal-body" >
														
							<form>
								<div className="form-group">
								   <lable>Selected Client</lable>
									<div className="neighbor-filter list-filter" onClick={this.OpenListMultiple}> 
										<span id="ClientName">Selected Client</span> {this.state.multichk_name} <span id="ClientCount"></span>
                    
									</div>
                  
										<div className="boxes neighborhoodDD clientL" id="clientListmultiple" style={{display:'none'}} ref={this.setWrapperRef13}>
										   
										    
											 {multiple_clientdata}
											{/* <span><input type="checkbox" id="box-0" value=""/> <label for="box-0"></label></span>
											<span><input type="checkbox" id="box-1" value="Adem"/> <label for="box-1">Adem</label></span>
											<span><input type="checkbox" id="box-2" value="Carry"/> <label for="box-2">Carry</label></span>
											<span><input type="checkbox" id="box-3" value="Bolt"/> <label for="box-3">Bolt</label></span> */}
										</div>
                
							    </div>
                  <div className="form-group">
								<lable>Unregistered Clients Email</lable>
									<input type="text" className="form-control" id="unregistered_clients_email" value={this.state.unregistered_clients_email} onChange={this.handleChangeunregclient}  placeholder="Clients Email"/>
                  <br />
                          <span className="inline-error-class">
                            {" "}
                            {this.state.unregistered_clients_email_error !== ""
                              ? this.state.unregistered_clients_email_error
                              : ""}
                          </span>  
							    </div>

								<div className="form-group">
								<lable>Subject</lable>
									<input type="text" id="mail_subject_multiple" className="form-control" value={this.state.mail_subject_multiple} onChange={this.handleclientmail} placeholder="Subject"/>
							    </div>
								<div className="form-group">
								<lable>Message</lable>
							    </div>
								<div id="multichkcontent" class="form-group" contenteditable="true" style={{border:'solid 1px', padding:'8px'}}>
							<div id="contact_Email_id">
<p>Hello,</p>								
<p>{this.state.content_value_multiple}</p><br/>
<p>Please find the listing details below</p>
<p>{listingsIdDetails}</p><br/>
<div style={{clear:'both'}}></div>
{this.props.userType=='Manager'?<p>Thank you,<br/><br/> Josiah Hyatt <br/> Founder | Principal Real Estate Broker <br/> Skyward</p>:<p>Thank you,<br/><br/>{getAgentName()}<br/>
{aes.decrypt(getAgentEmail(), key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}<br/>
{aes.decrypt(getAgent_number(), key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}<br/> </p>}


							 


							</div>
					</div>

												<button
                                                    
                            className="btn btn-default"
													style={{ margin: "10px" }}
													disabled={!this.validateMultipleclientemail()}
													onClick={()=>this.multichksendmail()}
                                                >
                                                    Send
                                  	    </button>
									</form>
										</div>
									</div>
								</div>
							</div>


{/*End Email to client Modal for multiple check listing*/}	



                </div>
                );
    }
}
export default ListingFeed;