import React from 'react';
import { Link } from 'react-router-dom';
import AgentSidebar from '../common/AgentSideBar';
import AgentHeader from '../common/AgentHeader';
import ManagerSidebar from '../common/ManagerSidebar';
import ManagerHeader from '../common/ManagerHeader';
import { setAgentToken, getAgentToken, getApiData , getAgentId,getBaseUrl, postApiData,setAgentImage} from '../../services/Api';
import Modal from 'react-responsive-modal';
import { FormGroup, FormControl } from "react-bootstrap";
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import {validatePhone,socialSecurityNumber} from '../../services/validation';
import Moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let key = encHex.parse("0123456789abcdef0123456789abcdef");
let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
class AgentEditProfile extends React.Component {
	constructor(props) {
				super(props);
				console.log("----props",this.props.location.type);
				var id=	localStorage.getItem('IdAgent') 
        this.state = {
					first_name:'',
					last_name:'',
					email:'',
					title:'',
					office_phone:'',
					mob_number:'',
					rls_id:'',
					description:'',
					agent_entity:'',
					licence_number:'',
					address:'',
					city:'',
					state:'',
					zipcode:'',
					expiration_date:'',
					website:'',
					hire_date:'',
					end_date:'',
			 	//	public_user:'',
			   	linkingLoader:true,
			  	first_name_error:'',
					isChecked:false,
					successMsg:0,
					agent_id:(!getAgentId())?id :getAgentId(),
					type:(!getAgentId())? "Manager" :"Agent" ,
					image:'',
					date_of_birth:''
				
		  };
			this.clientconatcthandleChange = this.clientconatcthandleChange.bind(this);

		}

	

		componentWillMount(){
			this.getAgentDetail();
		}
		
// get all agent detail on page load
    getAgentDetail(){
			
			let	 where=[{
					url:'/agent/agentDetail/'+this.state.agent_id,
				}];
			
			getApiData(where,true).then(res=>{
				var first_name=res.data[0].name.split(' ');
				var last_name = [];
				if(first_name.length>0){
					for(var i=1;i<first_name.length;i++){
						
						last_name.push(first_name[i]);
					}
				}
				if(res.data[0].date_of_birth !==null){
              
					this.setState({
							  date_of_birth:Moment(res.data[0].date_of_birth).format('MM/DD/YYYY')
					 }); 
				   }
				this.setState({
				  first_name:first_name[0],
				  last_name:last_name.join(' '),
					email:aes.decrypt(res.data[0].email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8),
					title:res.data[0].title,
					office_phone: res.data[0].office_phone?aes.decrypt(res.data[0].office_phone, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):'',
					mob_number: aes.decrypt(res.data[0].mob_number , key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8),
					rls_id:res.data[0].REBNYAgentID,
					description:unescape(res.data[0].description),
					isChecked:res.data[0].public_user==1?true:false,
					image:res.data[0].image,
					last_login:res.data[0].last_login,
					agent_entity:res.data[0].agent_entity,
					licence_number:res.data[0].licence_number,
					expiration_date:res.data[0].expiration_date=='0000-00-00 00:00:00'?'':Moment(res.data[0].expiration_date).format('MMMM DD, YYYY'),
					address:res.data[0].address,
					city:res.data[0].city,
					state:res.data[0].state,
					zipcode:res.data[0].zipcode,
					website:res.data[0].website,
					hire_date:res.data[0].hire_date=='0000-00-00 00:00:00'?'':Moment(res.data[0].hire_date).format('MMMM DD, YYYY'),
					end_date:res.data[0].end_date=='0000-00-00 00:00:00'?'':Moment(res.data[0].end_date).format('MMMM DD, YYYY')
					
						})
						
			}).catch(error=>{
				console.log(error);
			})
		}
		//--------END-----------

		isPhoneNumber(phone){
			var reg = /^\d{3}-\d,{3}-\d{4}$/;
			if(reg.exec(phone))
					return true;
			else
					return false;  
	}


			handleChange=(event)=>{
				console.log("errr",[event.target.id+"_error"])
					if(event.target.id=='first_name' || event.target.id=='last_name' || event.target.id=='title' || event.target.id=='description' || event.target.id=='agent_entity' || event.target.id=='licence_number' || event.target.id=='address' || event.target.id=='city' || event.target.id=='state' || event.target.id=='hire_date' || event.target.id=='end_date')  
					{
						if(event.target.value==""){
							this.setState({
								[event.target.id+"_error"]:"Field cannot be empty",
								[event.target.id]:event.target.value
							})
						}else{
							this.setState({
							[event.target.id+"_error"]:"",
								[event.target.id]:event.target.value
							})
						}
					}
					// add validation for Zipcode only accept number and max length 6
					if(event.target.id=='zipcode'){
						if (event.target.value == "") {
        
							this.setState({
							  [event.target.id+"_error"]: "Field cannot be empty",
							  [event.target.id]: event.target.value
							});
						  } 
						  if (isNaN(event.target.value) || event.target.value<-1) {
							
							this.setState({
							  [event.target.id+"_error"]: "Please enter numbers only",
							  [event.target.id]: event.target.value
							});
						  } 
						  else {
							this.setState({
							  [event.target.id+"_error"]: "",
							  [event.target.id]: event.target.value
							});
						  }
					}

					if(event.target.id=='website'){
						// add regex for validate URL
						var regex = /(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
						if (event.target.value == "") {
        
							this.setState({
							  [event.target.id+"_error"]: "Field cannot be empty",
							  [event.target.id]: event.target.value
							});
						  } 
						  if (!regex.test(event.target.value)) {
							
							this.setState({
							  [event.target.id+"_error"]: "Please enter valid url",
							  [event.target.id]: event.target.value
							});
						  } 
						  else {
							this.setState({
							  [event.target.id+"_error"]: "",
							  [event.target.id]: event.target.value
							});
						  }
					}

				}
		  
				// handle change for expiration date 
				handleChangeExpirationDate=(expirationDate)=> {
					if(expirationDate=="" || expirationDate==null ){
					
					  this.setState({
						expiration_date:'',
						expiration_date_error:'field cannot be empty'
				  
					  });
					}
					else{
					  this.setState({
						expiration_date:Moment(expirationDate).format('MMMM DD, YYYY'),
						expiration_date_error:''
					  });
					}
				  }

				  // ---End-------

				  // handle change for hire date
				  handleChangeHireDate=(hireDate)=> {
					if(hireDate=="" || hireDate==null ){
					
					  this.setState({
						hire_date:'',
						hire_date_error:'field cannot be empty'
				  
					  });
					}
					else{
					  this.setState({
						hire_date:Moment(hireDate).format('MMMM DD, YYYY'),
						hire_date_error:''
					  });
					}
				  }
				  // ---End----
				  
				  //  handle chnage for end date
				  handleChangeEndDate=(endDate)=> {
					if(endDate=="" || endDate==null ){
					
					  this.setState({
						end_date:'',
						end_date_error:'field cannot be empty'
				  
					  });
					}
					else{
					  this.setState({
						end_date:Moment(endDate).format('MMMM DD, YYYY'),
						end_date_error:''
					  });
					}
				  }
                  // -------END---

				handleCheckBox=()=>{
					this.setState({
						isChecked: !this.state.isChecked,
					})
				}

				// update agent records
					handleSubmit=()=>{
						
						let where=[{
							url:'/agent/agentDetailEdit'
						}];
						var formData= new FormData();
						if(this.state.type=="Manager"){
							formData.append("agent_id",this.state.agent_id);
						}else{
							formData.append("agent_id",getAgentId());
						}
						formData.append("name",this.state.first_name+' '+ this.state.last_name);
						formData.append("title",this.state.title);
						formData.append("description",this.state.description);
						formData.append("mob_number",this.state.mob_number);
						formData.append("office_phone",this.state.office_phone);
						formData.append("public_user",this.state.isChecked);
						formData.append("agent_entity",this.state.agent_entity);
						formData.append("expiration_date",this.state.expiration_date);
						formData.append("address",this.state.address);
						formData.append("city",this.state.city);
						formData.append("state",this.state.state);
						formData.append("zipcode",this.state.zipcode);
						formData.append("hire_date",this.state.hire_date);
						formData.append("end_date",this.state.end_date);
						formData.append("website",this.state.website);
						formData.append("licence_number",this.state.licence_number);
						formData.append("date_of_birth",this.formatDate(this.state.date_of_birth));
						postApiData(where,formData,true).then(res=>{
								console.log("res",res);
										this.setState({
											successMsg:1
										})
								if(res.data!=undefined){
									let listingWhere=[{
										url : '/containers/agent_image/upload'
									}];
									const formData = new FormData();
									formData.append('file',this.state.selectedFile);
									postApiData(listingWhere,formData,true).then(res=>{
										localStorage.removeItem('agent_image');
										console.log("--res.data[0].file_name",res.data.file_name)
										if(res.data.file_name!=undefined){
											 this.setState({image:res.data.file_name})
											setAgentImage(res.data.file_name)
										}
										var agentId;
										if(this.state.type=="Manager"){
											agentId=this.state.agent_id
										}else{
											agentId=getAgentId()
										}
										if(res){
											let where=[{
												url:'/agent/agentImageEdit'
											}];
											postApiData(where,{'agent_id':agentId,'fileName':this.state.image},true).then(async(response)=>{
													console.log("----res.data",res.data);
											}).catch( error=>{
												 
												})
												this.getAgentDetail(); 
										}
									}).catch(error=>{
									
									})
								}else{
									console.log("----submit");
								}
								})
							}
					
						//---------END----------------	

					fileChangedHandler = (event) => {
						console.log("evnt--file",event.target.files)
						if(event.target.files[0]){
						 let imgFlag = true;
						const maxFileSize = 10485760;   //10mb
						const value = event.target.files[0].name;
						const ext = value.substring(value.lastIndexOf('.'));
						if(!['.jpg','.jpeg','.JPG','.png','.PNG'].includes(ext)){
								this.setState({mymsg: ext+' is not supported file extension.'});
								imgFlag = false;
						}
						if(event.target.files[0].size>maxFileSize){
								this.setState({mymsg:"File size is too big, upto 5MB allowed."})
								imgFlag = false;
								}
						if(imgFlag) { 
								 this.setState({
						 selectedFile:event.target.files[0], 
						 imagePath1: URL.createObjectURL(event.target.files[0]),
					  	mymsg:''
										})
								}
						 }            
				}


				deleteImage=()=>{
					var agentId;
					if(this.state.type=="Manager"){
						agentId=this.state.agent_id
					}else{
						agentId=getAgentId()
					}
					this.setState({mymsg:''})
					let where = [{
						url:'/agent/deleteAgentImage'
					}]
					postApiData(where,{'agent_id':agentId,'file_name':this.state.image},true).then(res=>{
							// console.log("-----res file delete");
							this.getAgentDetail();
					}).catch(error=>{

					})
				}
				
				clientconatcthandleChange = event =>{
            
          
					if(event.target.id=='mob_number'){
						if(event.target.value==""){
							this.setState({mob_number_error:"Field can not be empty.",
							mob_number:event.target.value});
						 }else{
								this.setState({mob_number:event.target.value});
								this.formValidate(event.target.id,event.target.value);
					 }
					}
					 if(event.target.id=='office_phone'){
						 if(event.target.value==""){
							this.setState({office_phone_error:"Field can not be empty.",
								office_phone:event.target.value
						});
						 }else{
				    		this.setState({office_phone:event.target.value});
								this.formValidate(event.target.id,event.target.value);
					}
				}
			}
				isNum(num){
					if(/^[0-9-]*$/.test(num))
					return true;
					else 
					return false;
			}

			formValidate(key,value){
				switch(key){
						case 'mob_number':        
								 this.validatePhoneNo(value);
								break;
						case 'office_phone':        
								 this.validateOfficeNo(value);
								break;
						 
						}
				}

			validatePhoneNo(value){
				let res=validatePhone(value);
									 if(res.status==false){
											 this.setState({mob_number_error: 'Phone number is not valid.'});
												//document.getElementById("mob_number").value=res.value;
												this.setState({mob_number:res.value});
									 }else{
											 this.setState({mob_number_error: ''});
											//  document.getElementById("clientNo_search_id").value=res.value;
									 }
				 return res.status;
		}

		validateOfficeNo(value){
			let res=validatePhone(value);
								 if(res.status==false){
										 this.setState({office_phone_error: 'Phone number is not valid.'});
											//document.getElementById("mob_number").value=res.value;
											this.setState({office_phone:res.value});
								 }else{
										 this.setState({office_phone_error: ''});
										//  document.getElementById("clientNo_search_id").value=res.value;
								 }
			 return res.status;
	}
		

	// validation=()=>{Date of birth
	handleDOBChange = event => {
		this.setState({
			date_of_birth:event.target.value
			},()=>{
				console.log("this.state.date_of_birth",this.state.date_of_birth);
			});
	   console.log("event.target.value",event.target.value);
		let dob=event.target.value;
		dob=event.target.value.split('/');
		console.log("dob",dob,dob.length);
		let dob_of_user='';
		let flag=0;
		if(dob.length==3 && dob[2].length==4)
		{
			let _MM_=parseInt(dob[0]);
			let _DD_=parseInt(dob[1]);
			let _YYYY_=parseInt(dob[2]);
			let dob_of_user=dob[0]+'/'+dob[1]+'/'+dob[2];
			var maxBirthDate = new Date();
			maxBirthDate = maxBirthDate.getFullYear()-17;
			console.log("3=>", dob[2]);
			if(_MM_>12 || _MM_<1) {
			flag=1;
			}else if((_MM_==1 || _MM_==3 || _MM_==5 || _MM_==7 || _MM_==8 || _MM_==10 || _MM_==12) && (_DD_>31 || _DD_<1)){
				flag=1;
			}else if(( _MM_==4 || _MM_==6 || _MM_==9 || _MM_==11) && (_DD_>30 || _DD_<1)){
				flag=1;
			}else if((_MM_==2) && ((((_YYYY_%4)==0) && (_DD_>29 || _DD_<1)) || (((_YYYY_%4)!=0) && (_DD_>28 || _DD_<1))))
			{
				flag=1;
				console.log("I am here");
			}else if((_YYYY_ > maxBirthDate) || (_YYYY_<1)){
				console.log("_YYYY_=>",_YYYY_,"maxBirthDate",maxBirthDate)
				flag=1;
			}
			this.setState({
				date_of_birth:dob_of_user,
				dob_error:(flag==0)?'':'Invalid Date'
				});
	
		}else if (dob.length==2 && dob[1].length==2){
			let _MM_=parseInt(dob[0]);
			let _DD_=parseInt(dob[1]);
			let dob_of_user=dob[0]+'/'+dob[1]+'/';
			if(_MM_>12 || _MM_<1) {
			flag=1;
			}else if((_MM_==1 || _MM_==3 || _MM_==5 || _MM_==7 || _MM_==8 || _MM_==10 || _MM_==12) && (_DD_>31 || _DD_<1)){
				flag=1;
			}else if(( _MM_==4 || _MM_==6 || _MM_==9 || _MM_==11) && (_DD_>30 || _DD_<1)){
				flag=1;
			}else if((_MM_==2) && (_DD_>29 || _DD_<1)){
				flag=1;
			}
			this.setState({
				date_of_birth:dob_of_user,
				dob_error:(flag==0)?'':'Invalid Date'
				});
				
		}else if (dob.length==1 && dob[0].length==2){
			let _MM_=parseInt(dob[0]);
			let dob_of_user=dob[0]+'/';
				if(_MM_>12 || _MM_<1) {
					flag=1;
				}else{
					this.setState({
						date_of_birth:dob_of_user,
						dob_error:(flag==0)?'':'Invalid Date'
						});
				}
		
	
		}else{
			this.setState({
				date_of_birth:event.target.value,
				dob_error:(flag==0)?'':'Invalid Date'
				});
		}
		if(dob.length!=3 || ((dob[2]!=undefined && dob[2].length!=4) || (dob[1]!=undefined && dob[1].length!=2) || (dob[0]!=undefined && dob[0].length!=2))){
			this.setState({
				dob_error:'Invalid Date'
			})
		}
	}

	formatDate(value){
		var d = new Date(value);
		var stDate = d.getFullYear()+'-'+("0" + (d.getMonth() + 1)).slice(-2)+'-'+d.getDate();
		return stDate;
	}

	
    render() {
		

        return (
                <div>
                   {this.state.type=="Manager" ? <ManagerHeader /> :<AgentHeader />}
                    <main>
									 {this.state.type=="Manager" ? <ManagerSidebar/> :<AgentSidebar />}
					<div className="content" >
						<div className="work-section">
						<div className="dashboard">
						{(this.state.successMsg==1)?(<div className="alert alert-success" ><strong>Profile Edit Successfully!</strong></div>):''} 
						<h2>Editing</h2>
				
				<div className="agentprofile" >
						<div className="row">
						<div className="col-lg-5 visible">
								<div className="agentprofile-Edit profile-edit">
									<div className="row cleafix">
									<div class="col-sm-6">
										{/* <div className="agentprofile-img" style={{background:'url(/profile.png) center no-repeat'}}></div> */}
										{this.state.imagePath1?	<div className="agentprofile-img" style={{background:'url('+this.state.imagePath1+') center no-repeat',borderRadius: '50%'}}></div>:(this.state.image!=null && this.state.image!='' && this.state.image!='undefined' && this.state.image!='member.png')? (this.state.image.startsWith("https://") || this.state.image.startsWith("http://"))? 
										<div className="agentprofile-img" style={{background:'url('+this.state.image+') center no-repeat',borderRadius: '50%'}}></div>
									:	<div className="agentprofile-img" style={{background:'url('+getBaseUrl()+"/containers/agent_image/download/" + this.state.image+') center no-repeat',borderRadius: '50%'}}></div> 
									:	<div className="agentprofile-img" style={{background:'url(/profile.png) center no-repeat',borderRadius: '50%'}}></div> 
									}
										<div className="upload-btn-wrapper edit-upload mtop" >
											<button className="btn btn-default" type="button">Upload New Photo</button>
											<FormGroup >
												<FormControl type="file" onChange={this.fileChangedHandler}/>
											</FormGroup>
										</div>
										<Link to className="delete"  onClick={this.deleteImage}><strong>X</strong> Delete</Link>
									</div>
									<div class="col-sm-6">
										<ul className="user-btn-group">
											<li><button className="btn btn-default" onClick={this.handleSubmit}>Save User</button></li>
											{this.state.type=="Manager" 
											?		<li><Link to={{pathname:'/agent/agentprofile' ,type:"Manager" ,agent_id:this.state.agent_id}} className="btn btn-default">Cancel</Link></li>
									: <li><Link to={{pathname:'/agent/agentprofile' }} className="btn btn-default">Cancel</Link></li> }
											{/* <li><Link to="/agent/agentprofile" className="btn btn-default">Cancel</Link></li> */}
											{/* <li><Link to className="delete">Send Password Reset</Link></li> */}
										</ul>										
										
									</div>
									</div>
								</div>
							</div>
							<div className="col-lg-7">
								<div className="agentprofile-wrap agenteditwrap">								
								<div className="form-horizontal">
								<div className="form-group row">
									<label className="col-md-3 col-form-label">RLS ID</label>
									<div className="col-md-9">
									{this.state.rls_id}
								</div>
								</div>	
								{this.state.type=="Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">Public User?</label>
									<div className="col-md-9 advance-search">
									  	<div className="filter-block">
										 	 <div className="form-group">
												<input type="checkbox" 
													id="public_user"
												 checked={(this.state.isChecked==true)?'checked':''}
												 onChange={this.handleCheckBox}/>
											
											  </div>
										  </div>
									</div>
								</div>:''}	
								<div className="form-group row">
									<label className="col-md-3 col-form-label">First Name</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="first_name" value={this.state.first_name}  onChange={this.handleChange}/>
									<small>  {" "}
                              {this.state.first_name_error !== ""
                                ? this.state.first_name_error
                                : ""}</small>
									</div>
								</div>	
								<div className="form-group row">
									<label className="col-md-3 col-form-label">Last Name</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="last_name" value={this.state.last_name} onChange={this.handleChange}/>
										<small>  {" "}
                              {this.state.last_name_error !== ""
                                ? this.state.last_name_error
                                : ""}</small>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-md-3 col-form-label">Email</label>
									<div className="col-md-9">
										<input type="email" className="form-control" id="email" value={this.state.email}/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-md-3 col-form-label">Title</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="title" value={this.state.title} onChange={this.handleChange}/>
										<small>  {" "}
                              {this.state.title_error !== ""
                                ? this.state.title_error
                                : ""}</small>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-md-3 col-form-label">Office Number</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="office_phone" value={this.state.office_phone} 
										   maxLength="12"
											 placeholder="XXX-XXX-XXXX"
										onChange={this.clientconatcthandleChange}/>
										<small>  {" "}
                              {this.state.office_phone_error !== ""
                                ? this.state.office_phone_error
                                : ""}</small>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-md-3 col-form-label">Mobile Phone Number</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="mob_number" value={this.state.mob_number} 
										   maxLength="12"
											 placeholder="XXX-XXX-XXXX"
										onChange={this.clientconatcthandleChange} />
										<small>  {" "}
                              {this.state.mob_number_error !== ""
                                ? this.state.mob_number_error
                                : ""}</small>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-md-3 col-form-label">Bio</label>
									<div className="col-md-9">
										<textarea className="form-control text-area" style={{height:'250px'}} id="description" value={this.state.description} onChange={this.handleChange}></textarea>
										<small>  {" "}
                              {this.state.description_error !== ""
                                ? this.state.description_error
                                : ""}</small>
									</div>
								</div>
                         {/* Start code for ticket-836 */}
						 {this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">Date of birth</label>
									<div className="col-md-9">
									<input 
                                                type='text'
                                                id="date_of_birth"
                                                required 
                                                className="form-control"
                                                maxLength="10"
                                                placeholder="MM/DD/YYYY"
                                                value={this.state.date_of_birth}
                                                onChange={this.handleDOBChange}
                                                autoComplete="off"
                                            />
                                            
                                            <span className="inline-error-class"> {this.state.dob_error !==''?this.state.dob_error:''} </span>
									</div>
								</div>:''}
						 

					{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">Agent's Entity</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="agent_entity" value={this.state.agent_entity} onChange={this.handleChange}/>
										<span className="inline-error-class">  {" "}
                              {this.state.agent_entity_error !== ""
                                ? this.state.agent_entity_error
                                : ""}</span>
									</div>
								</div>:''}

								{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">License Number</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="licence_number" value={this.state.licence_number} onChange={this.handleChange}/>
										<span className="inline-error-class">  {" "}
                              {this.state.licence_number_error !== ""
                                ? this.state.licence_number_error
                                : ""}</span>
									</div>
								</div>:''}

								{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">Address</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="address" value={this.state.address} onChange={this.handleChange}/>
										<span className="inline-error-class">  {" "}
                              {this.state.address_error !== ""
                                ? this.state.address_error
                                : ""}</span>
									</div>
								</div>:''}

								{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">City</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="city" value={this.state.city} onChange={this.handleChange}/>
										<span className="inline-error-class">  {" "}
                              {this.state.city_error !== ""
                                ? this.state.city_error
                                : ""}</span>
									</div>
								</div>:''}

							{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">State</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="state" value={this.state.state} onChange={this.handleChange}/>
										<span className="inline-error-class">  {" "}
                              {this.state.state_error !== ""
                                ? this.state.state_error
                                : ""}</span>
									</div>
								</div>:''}

								{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">Zip</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="zipcode" value={this.state.zipcode} maxLength={6} onChange={this.handleChange}/>
										<span className="inline-error-class">  {" "}
                              {this.state.zipcode_error !== ""
                                ? this.state.zipcode_error
                                : ""}</span>
									</div>
								</div>:''}

								{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">Expiration Date</label>
									<div className="col-md-9">
										{/* <input type="text" className="form-control" id="expiration_date" value={this.state.expiration_date} onChange={this.handleChange}/> */}
										<DatePicker
                                                 
                                                 onChange={this.handleChangeExpirationDate}
                                                 dateFormat="MMMM d, yyyy"
                                                 value={this.state.expiration_date}
                                                 timeCaption="time"
                                                 className="form-control"
												 id="expiration_date"
												 minDate={new Date()}                               
                                                  /> 
										<span className="inline-error-class">  {" "}
                              {this.state.expiration_date_error !== ""
                                ? this.state.expiration_date_error
                                : ""}</span>
									</div>
								</div>:''}

								{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">Website</label>
									<div className="col-md-9">
										<input type="text" className="form-control" id="website" value={this.state.website} onChange={this.handleChange}/>
										<span className="inline-error-class">  {" "}
                              {this.state.website_error !== ""
                                ? this.state.website_error
                                : ""}</span>
									</div>
								</div>:''}

								{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">Hire Date</label>
									<div className="col-md-9">
										{/* <input type="text" className="form-control" id="hire_date" value={this.state.hire_date} onChange={this.handleChange}/> */}
										<DatePicker
                                                 
                                                 onChange={this.handleChangeHireDate}
                                                 dateFormat="MMMM d, yyyy"
                                                 value={this.state.hire_date}
                                                 timeCaption="time"
                                                 className="form-control"
                                                 id="hire_date"                               
                                                  /> 
										<span className="inline-error-class">  {" "}
                              {this.state.hire_date_error !== ""
                                ? this.state.hire_date_error
                                : ""}</span>
									</div>
								</div>:''}

								{this.state.type == "Manager" ?<div className="form-group row">
									<label className="col-md-3 col-form-label">End Date</label>
									<div className="col-md-9">
										{/* <input type="text" className="form-control" id="end_date" value={this.state.end_date} onChange={this.handleChange}/> */}
										<DatePicker
                                                 
                                                 onChange={this.handleChangeEndDate}
                                                 dateFormat="MMMM d, yyyy"
                                                 value={this.state.end_date}
                                                 timeCaption="time"
                                                 className="form-control"
                                                 id="end_date"                               
                                                  /> 
										<span className="inline-error-class">  {" "}
                              {this.state.end_date_error !== ""
                                ? this.state.end_date_error
                                : ""}</span>
									</div>
								</div>:''}
								{/* End code for ticket-836 */}
								</div>									
								</div>
							</div>
							<div className="col-lg-5 hidden">
								<div className="agentprofile-Edit profile-edit">
									<div className="row cleafix">
									<div class="col-sm-6">
										{this.state.imagePath1?	<div className="agentprofile-img" style={{background:'url('+this.state.imagePath1+') center no-repeat',borderRadius: '50%'}}></div>:(this.state.image!=null && this.state.image!='' && this.state.image!='undefined' && this.state.image!='member.png')? (this.state.image.startsWith("https://") || this.state.image.startsWith("http://"))? 
										<div className="agentprofile-img" style={{background:'url('+this.state.image+') center no-repeat',borderRadius: '50%'}}></div>
									:	<div className="agentprofile-img" style={{background:'url('+getBaseUrl()+"/containers/agent_image/download/" + this.state.image+') center no-repeat',borderRadius: '50%'}}></div> 
									:	<div className="agentprofile-img" style={{background:'url(/profile.png) center no-repeat',borderRadius: '50%'}}></div> 
									}
									<div className="upload-btn-wrapper edit-upload mtop" >
											<button className="btn btn-default" type="button">Upload New Photo</button>
											<FormGroup >
												<FormControl type="file" onChange={this.fileChangedHandler}/>
											</FormGroup>
										
										</div>
										<span>
											{" "}
                              {this.state.mymsg !== ""
                                ?this.state.mymsg
																: ""}
																
											</span>
											{this.state.mymsg ? <br></br>:''}
										<Link to className="delete" onClick={this.deleteImage}><strong>X</strong> Delete</Link>
									</div>
									<div class="col-sm-6">
										<ul className="user-btn-group">
											<li><button className="btn btn-default" onClick={this.handleSubmit}>Save User</button></li>
										{this.state.type=="Manager" 
											?		<li><Link to={{pathname:'/agent/agentprofile' ,type:"Manager" ,agent_id:this.state.agent_id}} className="btn btn-default">Cancel</Link></li>
									: <li><Link to={{pathname:'/agent/agentprofile' }} className="btn btn-default">Cancel</Link></li> }
											{/* <li><Link to className="delete">Send Password Reset</Link></li> */}
										</ul>										
										
									</div>
									</div>
								</div>
							</div>
						</div>
						
				
					</div>																
				</div>

			</div>
		</div>			             				                            
		</main>     
     </div>

     );
    }
}
export default AgentEditProfile;