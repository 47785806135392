import React from 'react';
import {Link} from 'react-router-dom';

class AdminHeader extends React.Component {

    constructor(props) {
        super(props);
          
         
         
    }


    componentDidMount() {
    }

    render() {
  
        return (
               <header className="fixedHeader">     
                    <nav class="navbar navbar-expand-md bg-light navbar-dark">
                    <a class="navbar-brand logo" href="#"><img src="/logo.png" width="100%" /></a> <span className="header-tag"></span>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <img src="/menu-icon.png"/>
                    </button>
                    <div class="collapse navbar-collapse " id="collapsibleNavbar">
                        <ul class="navbar-nav mr-auto">
                             </ul> 
                    </div>  
                    </nav>
                </header>
                );
    }
}
export default AdminHeader;