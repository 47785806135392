import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import { postApiData, getUserId} from '../../services/Api';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {is_stripe_completed: false, card_holder_name: '',amount: '',message:'', application_id: props.applicationId, address: props.address, error_card_name: '',error_token:'',error_msg:''};
        this.stripeSubmit = this.stripeSubmit.bind(this);

    }
    handleStripeChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });

    }
    

    async stripeSubmit() {
        // this.setState({message:document.getElementById('message').value});
        this.setState({amount:document.getElementById('amount').value});
            var client_id=localStorage.getItem('client_id');
        let token = await this.props.stripe.createToken({name: this.state.card_holder_name});
        let where = [{
                url: "/stripe/paydeposit"
            }];
        if (token.token && token.token.id) {
           if (!this.state.card_holder_name) {
                this.setState({error_card_name: 'Card holder name is required.'});
            }
            else if(!this.state.amount)
            {
                this.setState({error_card_name: 'Amount is required.'});
            } 
            else {
                document.getElementById('show_loader_on_click_id').style.display = "block";
                postApiData(where, {"id": token.token.id,'amount': this.state.amount,'client_id': client_id}, true).then(res => {
                   // send token to client server
                    if (res.data.id) {
                        this.props.handleStripeSubmit(token,res.data);
                        this.setState({is_stripe_completed: true});
                        document.getElementById('show_loader_on_click_id').style.display = "none";
                    }
                    else
                    {
                       
                        this.setState({error_token: 'Unable to process your request, please check card details and try again.'});
                        this.setState({is_stripe_completed: false});
                         document.getElementById('show_loader_on_click_id').style.display = "none";
                    }
                })
                        .catch(error => {
                            console.log(error);
                        //this.setState({error_token: 'Something went wrong'});
                        // this.setState({is_stripe_completed: false});
                        //  document.getElementById('show_loader_on_click_id').style.display = "none";
                        });
            }


        } else {
            this.setState({error_token: 'Please check your card details.'});
        }
    }

    render() {
        if (this.state.is_stripe_completed)
            return <div className="alert alert-success"><i class="fa fa-check-square-o"></i> Payment Successful <p> <br />You will be redirect on dashboard in 5 seconds or <Link to='/client/dashboard'>Click Here</Link> to go to dashboard.</p></div>;
        return (
                <div className="stripe_payment">
         {this.state.error_token!==''?(<div className="alert alert-danger">{this.state.error_token}</div>):''}
                    <div className="show_loader_on_click" id="show_loader_on_click_id" style={{display: 'none' }}></div>
                    <p>Card Details</p>
                    <CardElement />
                    <br/>
                    <div className="form-group">
                        <input type="text" name="card_holder_name" id="card_holder_name" className="form-control" placeholder="Card Holder Name"  onChange={this.handleStripeChange}/>
                        <br/>
                        <input type="text" name="amount" id="amount" className="form-control" placeholder="Amount"  onChange={this.handleStripeChange}/>
                        <br/>
                        {/* <input type="text" name="message" id="message" className="form-control" placeholder="Message" onChange={this.handleStripeChange}/> */}
                        {/* <br/> */}
                        <span className="inline-error-class">{this.state.error_card_name}</span>
                    </div>
                    <div className="form-group text-center m-top">
                        <button onClick={this.stripeSubmit}  className="btn btn-secondary btn-submit">Make Payment</button>
                    </div>
                </div>
                            );
                }
    }

    export default injectStripe(CheckoutForm);
