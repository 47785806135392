export function clientApllicationStatus(){
    return {
            "1":"Incomplete",
            "2":"Submitted",
            "3":"Sent"
    };
}
export function clientShowingStatus(){
    return {
            "2":"PENDING CONFIRMATION",
            "1":"CONFIRMED",
            "3":"CANCELLED"
    };
}
export function agentListingStatus(){
    return {
            "0":"Pending",
            "1":"Active",
            "2":"Expired"
    };
}
export function AgentStatus(){
    return {
            "1":"Active",
            "2":"Inactive"
    };
}

export function AgentShowingStatus(){
    return {
            "1":"Active",
            "0":"Inactive"
    };
}


export const DEFAULT_AGENT_NAME = 'JOSIAH HYATT';

export const containerBank = 'client-bank-statement';
export const containerAdditional = 'client-additional-document';
export const GOOGLE_CLIENT_ID = '391125362679-ds399ape7i149j0j90fjf39va7p433a0.apps.googleusercontent.com';