//us formate mobile number started
export function validatePhone(value){
    let ph=value;
    let res={"status":"false","value":""}
    var count = value.length;
    if (count==0)
    {
        return res;
    }else{
            if(count>3 && count<5){
                if (value.indexOf('-') > -1)
                ph = value;
                else
                ph = value.replace(/(\d{3})/, '$1-');
                res.status=false;
                res.value=ph;
                return res;
            }

            if(count>7)
            { 
               
                let st= ph.replace(/\-/g,"");
                let ph1 = st.replace(/(\d{3})(\d{3})/, '$1-$2-');  
               
                if (isPhoneNumber(ph1)==false) 
                {
                    res.status=false;
                    res.value=ph1;
                    return res;
                }
                else
                {
                    res.status=true;    
                    res.value=ph1;
                    return res;   
                }
            }
            if(count<13){
                if (isPhoneNumber(value)==false) 
                {
                    res.status=false;
                    res.value=ph;
                    return res;
                }
                else
                {
                    res.status=true;    
                    res.value=ph;
                    return res;   
                }
            }
            
        }
              return res;
}

function isPhoneNumber(phone){
    var reg = /\d{3}-\d{3}-\d{4}/;
    if(reg.exec(phone))
        return true;
    return false;         
}
//US mobile number ended

//US Social Security Number Start
export function socialSecurityNumber(value){
    let socilaNumber=value;
    let res={"status":"false","value":""}
    var count = value.length;
    if (count==0)
    {
        return res;
    }else{
            if(count>3 && count<5){
                if (value.indexOf('-') > -1)
                socilaNumber = value;
                else
                socilaNumber = value.replace(/(\d{3})/, '$1-');
                res.status=false;
                res.value=socilaNumber;
                return res;
            }
            if(count>6)
            {
                let st= socilaNumber.replace(/\-/g,"");
                let socilaNumber1 = st.replace(/(\d{3})(\d{2})/, '$1-$2-');  
                if (isSocialSecurityNumber(value)==false) 
                {
                    res.status=false;
                    res.value=socilaNumber1;
                    return res;
                }
                else
                {
                    res.status=true;    
                    res.value=socilaNumber1;
                    return res;   
                }
            }
            if(count<13){
                if (isSocialSecurityNumber(value)==false) 
                {
                    res.status=false;
                    res.value=socilaNumber;
                    return res;
                }
                else
                {
                    res.status=true;    
                    res.value=socilaNumber;
                    return res;   
                }
            }
            
        }
              return res;
}

function isSocialSecurityNumber(phone){
    var reg = /\d{3}-\d{2}-\d{4}/;
    if(reg.exec(phone))
        return true;
    return false;         
}
//US Social Security Number Ended

//Name validation
export function  validateName(value){
    let res={"status":"false","value":"","msg":""};
    if (value.length ==0) {
         res.status=false;
         res.value=value;
         res.msg="Name is required";
         return res;
    }else if ((isString(value)==false) || (!value.replace(/\s/g, '').length)) {
        res.status=false;
        res.value=value;
        res.msg="Invalid Name"
        return res;
    }else{
        res.status=true;
        res.value=value;
        res.msg=""
        return res;
    }
}

function isString(str){
    if(/^[a-zA-Z' ' ]+$/.test(str))
    return true;
    else 
    return false;
}
//Name validation ended