import React, { Component, Fragment , } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken, postApiData, getApiData,getBaseUrl} from '../../services/Api';
import {validatePhone} from '../../services/validation';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Video extends Component {
    constructor(){
        super();
        this.state={
           title:'',
           description:'',
           posted_on:'',
           startDate:'',
           linkingLoader:false
        }
    }
    componentWillMount(){
        this.getTopic();
        this.getCategory();
        this.setState({
            startDate:new Date(),
            posted_on:new Date()
          })
      }

    getTopic=()=>{
        let where=[{
            url:'/admin/getMediaTopic'
        }]
        getApiData(where ,true).then(res=>{
          console.log('---res.data',res.data);
          this.setState({
              media_topic:res.data
          })
        }).catch(error=>{

        })
    }

    getCategory=()=>{
        let where=[{
            url:'/admin/getMediaCategory'
        }]
        getApiData(where ,true).then(res=>{
          console.log('---res.data',res.data);
          this.setState({
            media_category:res.data
        })
        }).catch(error=>{

        })
    }

    handleChange=(event)=>{
          if(event.target.id=="title" || event.target.id=="description"){
              this.setState({
                  [event.target.id]:event.target.value
              })
          }
          if(event.target.id=="media_topic" || event.target.id == "media_category"){
            this.setState({
            })
        }
    }

    handleChangeDate=(date)=> {
        this.setState({
          startDate: date,
          posted_on:date
          });
        }

        fileHandler =(e) => {
            this.setState({
                    selectedFile:e.target.files[0],  
            })      
         }

         handleSubmit=(event)=>{
            this.setState({ linkingLoader:true})
             event.preventDefault();
             console.log("this.state.title",this.state.title);
             console.log("this.state.title",this.state.description);
             console.log("this.state.title",this.state.posted_on);
             console.log("this.state.selectedFile",this.state.selectedFile);

             if(document.getElementById("media_topic")){
                var media_topic=document.getElementById("media_topic").value;
                }
             if(document.getElementById("media_category")){
                var media_category=document.getElementById("media_category").value;
                }
             let Where=[{
                url : '/containers/media_video/upload'
            }];
            const formData = new FormData();
            formData.append('file',this.state.selectedFile);
          
            postApiData(Where,formData,true).then(response=>{
                 if(response){
                    let where=[{
                        url:'/admin/detailSave'
                    }]
                    var formData =new FormData();
                    formData.append('title',this.state.title)
                    formData.append('description',this.state.description)
                    formData.append('posted_on',this.state.posted_on)
                    formData.append('video_file',response.data.file_name)
                    formData.append('topic_id',media_topic)
                    formData.append('category_id',media_category)
                    postApiData(where,formData,true).then(res=>{
                        console.log("res.data",res.data.success);
                        if(res.data.success==true){
                            this.setState({ linkingLoader:true},()=>{
                                window.location.href=getBaseUrl()+'/admin/video/listing';
                                
                                this.setState({linkingLoader:false})
                            })
                        }
                    }).catch(error=>{
       
                    })
                 }
                }).catch(error=>{
                    
                })
         }

         validation=()=>{
            const { title}=this.state;
            return(
                title.trim()!=""  &&
                document.getElementById("media_topic").value!="" &&
                document.getElementById("media_category").value!=""
            )
        }

         
    render() {

        var media_topic;
        if(this.state.media_topic){
          media_topic = this.state.media_topic.map(item=>{
            return <option value={item.id}>{item.topic}</option>
          })
        }
        var media_category;
        if(this.state.media_category){
            media_category = this.state.media_category.map(item=>{
              return <option value={item.id}>{item.category}</option>
            })
          }
        return (
            <div >
                 {(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">Videos</h2>
                                     
                        <table className="table table-striped">
                            <thead><tr><th className="managerGridCol">Add Video</th></tr></thead>
                        </table>
                                
                                <form onSubmit={this.handleSubmit} style={{maxWidth:"70%", margin:"10px auto"}}>
                                {/* {this.state.successQuote?(<div className="alert alert-success"><strong>Address  Successfully Added</strong></div>):(null)}  */}
                                 
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label forhtml="name">Title*</label>
                                            <input type="text" className="form-control" id="title" onChange={this.handleChange} value={this.state.title} autoComplete="off"/>
                                            {/* <span className="inline-error-class"> {this.state.office_name_error !==''?this.state.office_name_error:''}</span> */}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label forhtml="Description">Description</label>
                                            <textarea  className="form-control" id="description" onChange={this.handleChange} value={this.state.description} autoComplete="off"/>
                                            {/* <span className="inline-error-class"> {this.state.mob_number_error !==''?this.state.mob_number_error:''}</span> */}
                                        </div>
                                        <div className="form-group col-md-6">
                                       <label>Media Topic*</label>
                                        <select id="media_topic" className="form-control" style={{width:'200px'}} onChange={this.handleChange}>
                                         <option value="">select</option>
                                         {media_topic}
                                       </select>
                                       </div>
                                       <div className="form-group col-md-6">
                                       <label>Media Category*</label>
                                        <select id="media_category" className="form-control" style={{width:'200px'}} onChange={this.handleChange}>
                                         <option value="">select</option>
                                         {media_category}
                                       </select>
                                       </div>
                                        <div className="form-group col-md-6">
                                            <label forhtml="Posted On">Posted On &nbsp;</label>
                                            <DatePicker
                                              selected={this.state.startDate}
                                            onChange={this.handleChangeDate}
                                            
                                              timeCaption="time"
                                              id="date_available"   
                                              className="form-control"                 
                                                  />
                                        </div>
                                        
                                        <div className="form-group col-md-6">
                                            <label  forhtml="Video file">Video File</label>
                                            <input type="file"  accept="video/*"  onChange={this.fileHandler}  />
                                            {/* <span className="inline-error-class"> {this.state.mob_number_error !==''?this.state.mob_number_error:''}</span> */}
                                        </div>
                                        </div>
                                        <div className="form-group">
                                        <button type="submit" disabled={!this.validation()} className="btn btn-primary" >Submit</button>
                                     </div>
                                    </form>
                       
                            </div>
                    </div>
                 
                </div>
            </main>     
        </div>
        );
    }
}

export default Video;