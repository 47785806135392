import React from "react";
import { Redirect } from 'react-router';
import {getApiData, getToken, removeToken} from '../../services/Api';

class Logout extends React.Component {
    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}
class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            logout: false
        }
        this.onLogout = this.onLogout.bind(this);
    }

    componentDidMount() {
        this.onLogout();
    }

    async onLogout() {
        let where = [{
                url: "/Clients/logout"
            }];
        
        getApiData(where,false).then(res => {
            this.setState({
                email: "",
                name: ""
            },()=>{
                removeToken();
                localStorage.removeItem('token');
                localStorage.removeItem('imageUrl');
                localStorage.removeItem('client_id');
                localStorage.removeItem('account_verified');
                localStorage.removeItem('onboarding_data');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user_name');
                localStorage.removeItem('is_onboarding_done');
                localStorage.removeItem('is_password_set');
               // localStorage.clear();
                this.setState({logout:true});
            });
            
        })
                .catch(error => {
                    
                });
    }

    render() {
        if (this.state.logout) {
           return (<Redirect to={'/'}/>);
        }
        return (
                <div>Logged out</div>
                );
    }
}

export default Logout;
