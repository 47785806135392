import React from 'react';
import {Link} from 'react-router-dom';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { Redirect } from 'react-router';
import {postApiData, getApiData, getBaseUrl} from '../../services/Api';


class Urbanform extends React.Component {
    constructor(props) {
        super(props);
        console.log("listing",props)
        this.state = {
         app_id:props.listing.application_id,
         template_name:'urban_american',
         temp_values:{
            apt: "",
            address: "",
            pets_no: "",
            f_1_form: "",
            pets_des: "",
            pets_yes: "",
            guarantor: "",
            last_name: "",
            pay_stubs: "",
            signature: "",
            W2_or_1099: "",
            final_date: "",
            first_name: "",
            lease_term: "",
            tax_return: "",
            today_date: "",
            urban_logo: getBaseUrl()+'/containers/editor_images/download/urban_logo.png',
            leaseholder: "",
            middle_name: "",
            full_address: "",
            monthly_rent: "",
            move_in_date: "",
            off_bedrooms: "",
            submitted_by: "",
            bankruptcy_no: "",
            date_of_birth: "",
            landlord_name: "",
            social_sec_no: "",
            applicant_name: "",
            bankruptcy_des: "",
            bankruptcy_yes: "",
            city_state_zip: "",
            copy_of_credit: "",
            e_mail_address: "",
            monthly_rent_$: "",
            rent_timely_no: "",
            student_income: "",
            address_and_apt: "",
            another_name_no: "",
            been_evicted_no: "",
            cell_phone_no_0: "",
            cell_phone_no_1: "",
            home_phone_no_0: "",
            home_phone_no_1: "",
            rent_timely_des: "",
            rent_timely_yes: "",
            work_phone_no_0: "",
            work_phone_no_1: "",
            another_name_des: "",
            another_name_yes: "",
            been_arrested_no: "",
            been_evicted_des: "",
            been_evicted_yes: "",
            lease_start_date: "",
            sued_landlord_no: "",
            urban_logo_black: getBaseUrl()+'/containers/editor_images/download/urban_logo_black.png',
            been_arrested_des: "",
            been_arrested_yes: "",
            driver_license_no: "",
            sued_landlord_des: "",
            sued_landlord_yes: "",
            criminal_action_no: "",
            dates_of_residency: "",
            number_of_bedrooms: "",
            reason_for_leaving: "",
            criminal_action_des: "",
            criminal_action_yes: "",
            current_status_rent: "",
            current_status_text: "",
            gross_annual_income: "",
            additional_occupants: "",
            additional_occupants_1: "",
            completed_and_signed: "",
            current_status_condo: "",
            current_status_other: "",
            landloard_phone_no_0: "",
            landloard_phone_no_1: "",
            current_stree_address: "",
            other_occupants_dob_0: "",
            other_occupants_dob_1: "",
            other_occupants_dob_2: "",
            other_occupants_dob_3: "",
            relation_to_applicant: "",
            current_bank_statement: "",
            other_occupants_name_0: "",
            other_occupants_name_1: "",
            other_occupants_name_2: "",
            other_occupants_name_3: "",
            proof_of_matriculation: "",
            residency_monthly_rent: "",
            have_you_ever_rented_no: "",
            current_status_homeowner: "",
            have_you_ever_rented_des: "",
            have_you_ever_rented_yes: "",
            notarized_guarantor_form: "",
            occupants_UA_property_no: "",
            occupants_UA_property_des: "",
            occupants_UA_property_yes: "",
            current_employment_retired: "",
            current_employment_student: "",
            government_issued_photo_id: "",
            current_employment_employed: "",
            previous_employment_company: "",
            current_employment_occupation: "",
            current_employment_unemployed: "",
            other_occupants_relationship_0: "",
            other_occupants_relationship_1: "",
            other_occupants_relationship_2: "",
            other_occupants_relationship_3: "",
            previous_employment_occupation: "",
            current_employment_self_employed: "",
            current_employment_city_state_zip: "",
            current_employment_company_school: "",
            current_employment_street_address: "",
            current_employment_supervisor_name: "",
            previous_employment_city_state_zip: "",
            previous_employment_street_address: "",
            current_employment_responsibilities: "",
            previous_employment_supervisor_name: "",
            previous_employment_responsibilities: "",
            current_employment_date_of_employment: "",
            current_employment_gross_annual_salary: "",
            previous_employment_date_of_employment: "",
            previous_employment_gross_annual_salary: "",
            current_employment_supervisor_phone_no_0: "",
            current_employment_supervisor_phone_no_1: "",
            previous_employment_supervisor_phone_no_0: "",
            previous_employment_supervisor_phone_no_1: ""
        }
        };
    }
    componentDidMount(){
        let template_helper = [{
            url: "/template_helper",
            where:{
                'app_id':this.state.app_id,
                'template_name':this.state.template_name
			}
        }];
        getApiData(template_helper,true).then(res => {
            if(res.data.data.length>0){
                this.setState(state => (this.state.temp_values=JSON.parse(res.data.data[0].temp_values)))
            }       
            })
            .catch(error => {
                console.log("error",error)
            });
    }

    saveData=()=>{
        let whereData = [{
            url: "/AddTemplatehelper"
        }];
        let data={
        app_id:this.state.app_id,
        template_name:'urban_american',
        temp_values:JSON.stringify(this.state.temp_values) 
        }
         postApiData(whereData,data,'post',true).then(res => {
            if(res.data.success){
                this.setState({successMsg:res.data.msg,alertMsg:''})
            }else{
                this.setState({alertMsg:res.data.msg,sucessMsg:''})
            }
        })
        .catch(error => {
            console.log(error);
        });

    }
    handleCheckbox=(event)=>{
        //console.log("event.target.type",event.target.type,"-",event.target.id,"-",event.target.value)
       if(event.target.type=='checkbox' && this.state.temp_values[event.target.id]==""){
        this.state.temp_values[event.target.id]='checked';
       this.setState(state => (this.state))
       }else if(event.target.type=='text'){
        this.state.temp_values[event.target.id]=event.target.value;
        this.setState(state => (this.state))
       }else{
        this.state.temp_values[event.target.id]="";
        this.setState(state => (this.state))
       }
    }
    render() {   
             console.log("getBaseUrl()+'/containers/editor_images/download/urban_logo_black.png'",getBaseUrl()+'/containers/editor_images/download/urban_logo_black.png')
        return (
                <div>
                      <main id="urbanForm">
                                       <div className="modal-body" style={{padding:'20px 0'}}>
                                           
                                           <div size="A4">
           <div style={{textAlign:'center', padding:'0px 30px'}} contenteditable="false">
           {(this.state.alertMsg) ?(<div className="alert alert-danger" style={{marginLeft:'200px', marginRight:'300px'}}><strong>{this.state.alertMsg}</strong></div>):(null)}
           {(this.state.successMsg) ?(<div className="alert alert-success" style={{marginLeft:'200px', marginRight:'300px'}}><strong>{this.state.successMsg}</strong></div>):(null)}


               <table width="100%" cellpadding="0" contenteditable="false">
                               <tr>
                                   
                                   <td valign="bottom" align="center" contenteditable="false">
                                           <img src="/ulogo.png" height="40" />
                                   </td>
                                   
                               </tr>
                           </table>
           </div>
           
           <div style={{padding:'10px 35px 40px'}}>
               <table width="100%" cellpadding="0" cellspacing="2" style={{fontSize:'11px', textTransform:'inherit', padding:'0px 5px'}} contenteditable="false">
                   <tr>
                       <td contenteditable="false" style={{textAlign:'right'}}>
                           <label contenteditable="false"><strong>TODAY’S DATE:</strong></label>
                           <input type="text"  id="today_date"  value={this.state.temp_values.today_date} onChange={this.handleCheckbox} style={{border:'0px', borderBottom:'1px solid #000', width:'80px', fontFamily:'Times New Roman', margin:'0px 0px 0 5px', paddingRight:'0px', fontSize:'11px'}} maxlength="10"/>									
                       </td>
                   </tr>
                   <tr>
                       <td contenteditable="false">
                           <label contenteditable="false"><strong>APPLICATION COVER SHEET Submitted by:</strong></label>
                           <input type="text"  id="submitted_by"  value={this.state.temp_values.submitted_by} onChange={this.handleCheckbox} style={{border:'0px', borderBottom:'1px solid #000',   fontFamily:'Times New Roman',  width:'292px', margin:'0px 0px 0 5px',
                           fontSize:'11px'}}  maxlength="70" />	
                           <p style={{fontSize:'9px', textAlign:'right', paddingRight:'80px', margin:'0px'}}  contenteditable="false">(Broker/Agent Name and Agency Name)</p>		
                       </td>
                   </tr>
               </table>
               
               <div style={{border:'1px solid #000', padding:'10px', margin:'10px 0'}}>
                   <table width="100%" cellpadding="1" cellspacing="2" style={{fontSize:'11px', textTransform:'inherit'}}>
                       <tr>
                           <td contenteditable="false">
                               <label contenteditable="false">Applying for Address and Apt. # :</label>
                               <input type="text"  id="address_and_apt"  value={this.state.temp_values.address_and_apt} onChange={this.handleCheckbox} style={{width:'350px'}}/>	
                                                               
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <label contenteditable="false">Number of Bedrooms:</label>
                               <input type="text"  id="number_of_bedrooms"  value={this.state.temp_values.number_of_bedrooms} onChange={this.handleCheckbox} style={{width:'63px'}} maxlength="10" />
                               
                               <label contenteditable="false">Monthly Rent: $</label>
                               <input type="text"  id="monthly_rent"  value={this.state.temp_values.monthly_rent} onChange={this.handleCheckbox} style={{width:'63px'}} maxlength="10" />
   
                               <label contenteditable="false">Lease Term (months):</label>
                               <input type="text"  id="lease_term"  value={this.state.temp_values.lease_term} onChange={this.handleCheckbox} style={{width:'74px'}} maxlength="10" />
                           </td>
                       </tr>
   
                       <tr>
                           <td contenteditable="false">
                               <label contenteditable="false">Lease Start Date (1st or 15th of the month):</label>
                               <input type="text"  id="lease_start_date"  value={this.state.temp_values.lease_start_date} onChange={this.handleCheckbox} style={{width:'98px'}} maxlength="10" />
                               
                               <label contenteditable="false">Ideal Move in Date:</label>
                               <input type="text"  id="move_in_date"  value={this.state.temp_values.move_in_date} onChange={this.handleCheckbox} style={{width:'106px'}} maxlength="10" />
                           </td>
                       </tr>
   
                       <tr>
                           <td contenteditable="false">
                               <label contenteditable="false">Applicant Name:</label>
                               <input type="text"  id="applicant_name"  value={this.state.temp_values.applicant_name} onChange={this.handleCheckbox} style={{width:'206px'}}  maxlength="50"/>
                               
                               <label contenteditable="false">Leaseholder</label>
                               <input type="text"  id="leaseholder"  value={this.state.temp_values.leaseholder} onChange={this.handleCheckbox} style={{width:'40px'}}  maxlength="10"/>
   
                               <label contenteditable="false">Guarantor</label>
                               <input type="text"  id="guarantor"  value={this.state.temp_values.guarantor} onChange={this.handleCheckbox} style={{width:'48px'}}  maxlength="10"/>
                           </td>
                       </tr>
   
                       <tr>
                           <td contenteditable="false">
                               <label contenteditable="false">Gross Annual Income: $</label>
                               <input type="text"  id="gross_annual_income"  value={this.state.temp_values.gross_annual_income} onChange={this.handleCheckbox} style={{width:'97px'}}  maxlength="10"/>
                           </td>
                       </tr>
   
                       <tr>
                           <td contenteditable="false">
                               <label contenteditable="false">Additional Occupants/Tenants/Pets:</label>
                               <input type="text"  id="additional_occupants"  value={this.state.temp_values.additional_occupants} onChange={this.handleCheckbox} style={{width:'342px'}}  maxlength="75"/>
                               <input type="text"  id="additional_occupants_1"  value={this.state.temp_values.additional_occupants_1}  onChange={this.handleCheckbox} style={{width:'100%'}}  maxlength="130"/>
                           </td>
                       </tr>
                   </table>
               </div>
               
               <table width="100%" cellpadding="0" cellspacing="0" style={{fontSize:'12px', textTransform:'inherit', padding:'0px 20px'}} contenteditable="false">
                       <p style={{fontSize:'11px', marginLeft:'10px', marginBottom:'0'}} contenteditable="false">
                           <strong>
                               Attached (in the following order) to this cover sheet is the rental package we are submitting to Urban American:
                           </strong>
                       </p>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="completed_and_signed" checked={this.state.temp_values.completed_and_signed} onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   Urban American Rental Application Form (completed and signed)
                               </label>
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="copy_of_credit" checked={this.state.temp_values.copy_of_credit}  onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   Copy of credit check fee(s), total of $50 per adult (per applicant, guarantor, & adult occupant, 
                                   <span style={{float:'right'}}><em>money order</em>  payable to specific landlord LLC)</span>
                               </label>	
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="government_issued_photo_id" checked={this.state.temp_values.government_issued_photo_id} onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   Driver License or Passport / Visa (current, valid, government-issued photo ID)
                               </label>	
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="current_bank_statement" checked={this.state.temp_values.current_bank_statement} onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   Current Bank Statement (must show applicant name & address, payroll deposits, & rent payments)
                               </label>	
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="pay_stubs" checked={this.state.temp_values.pay_stubs} onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   Pay Stubs (3 consecutive)
                               </label>	
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="W2_or_1099" checked={this.state.temp_values.W2_or_1099} onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   W2 or 1099
                               </label>	
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="tax_return" checked={this.state.temp_values.tax_return} onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   Tax Return (Form 1040)
                               </label>	
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="notarized_guarantor_form" checked={this.state.temp_values.notarized_guarantor_form} onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   Notarized Guarantor Form (if applicable)
                               </label>	
                           </td>
                       </tr>
                   </table>
   
                   <table width="100%" cellpadding="0" cellspacing="2" style={{fontSize:'11px', textTransform:'inherit', paddingLeft:'40px'}} contenteditable="false">
                       <p style={{fontSize:'11px', paddingLeft:'30px', marginBottom:'0'}} contenteditable="false">
                           IF STUDENT APPLICANT, also provide:						
                       </p>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="proof_of_matriculation" checked={this.state.temp_values.proof_of_matriculation} onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   Proof of Matriculation (letter from registrar, transcripts, or current schedule)
                               </label>
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="f_1_form" checked={this.state.temp_values.f_1_form}  onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   I-20 or F-1 Form and Visa (for international students)
                               </label>
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <input type="checkbox" id="student_income" checked={this.state.temp_values.student_income} onClick={this.handleCheckbox} />
                               <label contenteditable="false">
                                   Documentation of Student Income (loans, grants, scholarships, GI Bill verification, etc.)
                               </label>
                           </td>
                       </tr>			
                   </table>
   
                   <table width="100%" cellpadding="0" cellspacing="2" style={{fontSize:'11px', textTransform:'inherit', fontStyle:'italic', paddingLeft:'15px'}} contenteditable="false">
                       <p style={{fontSize:'10px', paddingLeft:'30px', marginBottom:'0', fontStyle:'italic'}} contenteditable="false">
                       Some of the following additional items may be required if the documentation above is insufficient or unclear: 
                       </p>
                       
                       <tr>
                           <td contenteditable="false">
                               <ul style={{margin:'5px 0'}}>
                                   <li>Current Landlord Rent Ledger or other proof of current rent payments </li>
                                   <li>Social Security Card</li>
                                   <li>Current Utility Bill (gas, electric, phone, cable, etc.) </li>
                                   <li>Employer Reference Letter on company letterhead</li>
                                   <li>Birth Certificate(s) or Passport(s) (for minor children)</li>
                                   <li>Marriage Certificate</li>
                               </ul>
                           </td>
                       </tr>								
                   </table>
   
                   <table width="100%" cellpadding="3" cellspacing="0" style={{fontSize:'11px', textTransform:'inherit', paddingLeft:'10px'}} contenteditable="false">
                       <tr>
                           <td contenteditable="false">
                               <strong>
                                   All listed items must be accounted for with ““&#10004” if included, “N/A” if not applicable, or “X” and explain why the item is not included and/or when it is to be expected.
                               </strong>
                           </td>
                       </tr>
                       <tr>
                           <td contenteditable="false">
                               <strong>
                                   Applicants must demonstrate total annual income of approximately 38x the monthly rent.   
                                   <p><div style={{float:'right',fontWeight:'200'}}>Revision 4.17.19</div></p>
                               </strong>	
                           </td>
                       </tr>								
                   </table>
   
           </div>
           <div style={{height:'40px', color:'#d2d2d2', width:'100%',  fontSize:'11px', borderTop:'1px solid #d2d2d2',  marginBottom:'15px', paddingTop:'6px'}}>
               <div style={{marginTop:'10px', textAlign:'center'}}>1</div>
           </div>
       </div>
   
   
   
              <div style={{fontFamily:'Arial', marginTop:'20'}}>
           <div style={{textAlign:'center', padding:'0px 30px'}} contenteditable="false">
               <table width="100%" cellpadding="0" contenteditable="false">
                               <tr>
                                   
                                   <td valign="bottom" align="center" contenteditable="false">
                                           <img src="/logo_black.png" height="40"  />
                                   </td>
                                   
                               </tr>
                           </table>
           </div>
           
           <div style={{padding:'10px 35px 40px'}}>
               <table width="100%" cellpadding="1" cellspacing="0" style={{fontSize:'9px', textTransform:'inherit',  padding:'0px 10px;'}} contenteditable="false">
                   <tr>
                       <td contenteditable="false" style={{textAlign:'center'}}>
                           <label contenteditable="false"><strong>Rental Application</strong></label>
                           <p style={{fontSize:'9px', margin:'0px'}} contenteditable="false">One application per Leaseholder/Guarantor required. PRINT CLEARLY and complete ALL items.</p>			
                       </td>
                   </tr>
                   <tr>
                       <td contenteditable="false">
                           <label contenteditable="false">ADDRESS</label>
                           <input type="text"  id="address"  value={this.state.temp_values.address} onChange={this.handleCheckbox} style={{width:'80px'}} />
                           
                           <label contenteditable="false">APT #</label>
                           <input type="text"  id="apt"  value={this.state.temp_values.apt} onChange={this.handleCheckbox} style={{width:'30px'}}/>
   
                           <label contenteditable="false"># OFF BEDROOMS</label>
                           <input type="text"  id="off_bedrooms"  value={this.state.temp_values.off_bedrooms} onChange={this.handleCheckbox} style={{width:'30px'}} />
   
                           <label contenteditable="false">MONTHLY RENT $</label>
                           <input type="text"  id="monthly_rent_$"  value={this.state.temp_values.monthly_rent_$} onChange={this.handleCheckbox} style={{width:'50px'}}/>
                               
                       </td>
                   </tr>
               </table>
   
               <div style={{background:'#000', marginTop:'10px'}} contenteditable="false">
                   <p style={{background:'#000', color:'#fff', padding:'5px', margin:'0'}} contenteditable="false">Applicant Information</p>
               </div>
               <div style={{border:'3px solid #000'}}>
                   <table width="100%" cellpadding="3" cellspacing="0" style={{textTransform:'inherit', fontSize:'10px'}}>
                       
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'50px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Last<br/> Name:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="last_name"  value={this.state.temp_values.last_name} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'50px', paddingLeft:'3px'}}>
                               <label contenteditable="false">First <br/>Name:</label>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="first_name"  value={this.state.temp_values.first_name} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'50px', paddingLeft:'3px'}}>
                               <label contenteditable="false">M.l:</label>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000'}}>
                               <input type="text"  id="middle_name"  value={this.state.temp_values.middle_name} onChange={this.handleCheckbox} style={{width:'37px', border:'0', fontSize:'10px'}}maxlength="5"/>
                           </td>
                       </tr>
                       
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'50px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Current Stree<br/>Address/Apt.No:</label>	
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}} colspan="5">
                               <input type="text"  id="current_stree_address"  value={this.state.temp_values.current_stree_address} onChange={this.handleCheckbox} style={{width:'450px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'50px', paddingLeft:'3px'}}>
                               <label contenteditable="false">City, State, Zip:</label>	
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000'}}  colspan="5">
                               <input type="text"  id="city_state_zip"  value={this.state.temp_values.city_state_zip} onChange={this.handleCheckbox} style={{width:'450px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>
                       </tr>				
                   </table>
   
                   <table width="100%" cellpadding="1" cellspacing="0" style={{fontSize:'10px', textTransform:'inherit'}}>
                       
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Social Sec. No:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="social_sec_no"  value={this.state.temp_values.social_sec_no} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Home Phone No.:</label>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000',  paddingLeft:'3px'}}>
                               (<input type="text"  id="home_phone_no_0"  value={this.state.temp_values.home_phone_no_0} onChange={this.handleCheckbox} style={{width:'300px', border:'0', fontSize:'10px'}} maxlength="4"/>)
                               <input type="text"  id="home_phone_no_1"  value={this.state.temp_values.home_phone_no_1} onChange={this.handleCheckbox} style={{width:'120px', border:'0', fontSize:'10px'}} maxlength="14"/>
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Date of Birth:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="date_of_birth"  value={this.state.temp_values.date_of_birth} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Cell Phone No.:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px', borderBottom:'1px solid #000'}}>
                               (<input type="text"  id="cell_phone_no_0"  value={this.state.temp_values.cell_phone_no_0} onChange={this.handleCheckbox} style={{width:'30px', border:'0', fontSize:'10px'}} maxlength="4"/>)
                               <input type="text"  id="cell_phone_no_1"  value={this.state.temp_values.cell_phone_no_1} onChange={this.handleCheckbox} style={{width:'120px', border:'0', fontSize:'10px'}} maxlength="14"/>
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Driver's License No:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="driver_license_no"  value={this.state.temp_values.driver_license_no} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>
                           <td contenteditable="false"style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Work Phone No:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px', borderBottom:'1px solid #000'}}>
                               (<input type="text"  id="work_phone_no_0"  value={this.state.temp_values.work_phone_no_0} onChange={this.handleCheckbox} style={{width:'30px', border:'0', fontSize:'10px'}} maxlength="4"/>)
                               <input type="text"  id="work_phone_no_1"  value={this.state.temp_values.work_phone_no_1} onChange={this.handleCheckbox} style={{width:'120px', border:'0', fontSize:'10px'}} maxlength="14"/>
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">E-mail Address:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000'}} colspan="3">
                               <input type="text"  id="e_mail_address"  value={this.state.temp_values.e_mail_address} onChange={this.handleCheckbox} style={{width:'430px', border:'0', fontSize:'10px'}} maxlength="100"/>
                           </td>										
                       </tr>
                   </table>
                   <table width="100%" cellpadding="2" cellspacing="0" style={{fontSize:'10px', textTransform:'inherit'}}>
                       <tr>
                           <td contenteditable="false">
                               <label contenteditable="false">If Gurantor Application, describe relationship to Applicant:</label>
                               <input type="text"  id="relation_to_applicant"  value={this.state.temp_values.relation_to_applicant} onChange={this.handleCheckbox} style={{border:'0px', borderBottom:'1px solid #000', width:'250px', margin:'0px 5px', fontSize:'11px'}} />
                               
                           </td>
                       </tr>
                   </table>
                   
                   <div style={{background:'#000'}}>
                       <p style={{background:'#000' ,color:'#fff', padding:'5px', margin:'0'}} contenteditable="false">Other Occupants</p>
                   </div>
   
                   <table width="100%" cellpadding="2" cellspacing="0" style={{fontSize:'10px', textTransform:'inherit'}}>
                       
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Name:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Date of Birth:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Relationship:</label>							
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_name_0"  value={this.state.temp_values.other_occupants_name_0} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_dob_0"  value={this.state.temp_values.other_occupants_dob_0} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_relationship_0"  value={this.state.temp_values.other_occupants_relationship_0} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="20"/>							
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_name_1"  value={this.state.temp_values.other_occupants_name_1} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_dob_1"  value={this.state.temp_values.other_occupants_dob_1} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_relationship_1"  value={this.state.temp_values.other_occupants_relationship_1} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="20"/>							
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_name_2"  value={this.state.temp_values.other_occupants_name_2} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_dob_2"  value={this.state.temp_values.other_occupants_dob_2} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_relationship_2"  value={this.state.temp_values.other_occupants_relationship_2} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="20"/>							
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_name_3"  value={this.state.temp_values.other_occupants_name_3} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_dob_3"  value={this.state.temp_values.other_occupants_dob_3} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <input type="text"  id="other_occupants_relationship_3"  value={this.state.temp_values.other_occupants_relationship_3} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="20"/>							
                           </td>					
                       </tr>					
                   </table>
   
                   <div style={{background:'#000'}} contenteditable="false">
                       <p style={{background:'#000' , color:'#fff', padding:'5px', margin:'0'}} contenteditable="false">Residency Information</p>
                   </div>
   
                   <table width="100%" cellpadding="1" cellspacing="0" style={{fontSize:'10px', textTransform:'inherit'}}>
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">Current Status:</label>
                               <input type="checkbox" id="current_status_rent" checked={this.state.temp_values.current_status_rent} onClick={this.handleCheckbox} /> Rent
                               <input type="checkbox" id="current_status_condo" checked={this.state.temp_values.current_status_condo} onClick={this.handleCheckbox} /> Co-Op/condo
                               <input type="checkbox" id="current_status_homeowner" checked={this.state.temp_values.current_status_homeowner} onClick={this.handleCheckbox} /> Homeowner
                               <input type="checkbox" id="current_status_other" checked={this.state.temp_values.current_status_other} onClick={this.handleCheckbox} /> Other:
                               <input type="text"  id="current_status_text" checked={this.state.temp_values.current_status_text}  value={this.state.temp_values.current_status_text} onChange={this.handleCheckbox} style={{border:'0px', borderBottom:'1px solid #000', width:'185px', margin:'0px 5px', fontSize:'10px'}} maxlength="10"/>
                               
                           </td>
                       </tr>
                   </table>
   
                   <table width="100%" cellpadding="3" style={{fontSize:'10px'}} cellspacing="0">
                       
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px'}}>
                               <label contenteditable="false">Landlord Name:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="landlord_name"  value={this.state.temp_values.landlord_name} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px'}}>
                               <label contenteditable="false">Landlord Phone No.:</label>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000'}}>
                               (<input type="text"  id="landloard_phone_no_0"  value={this.state.temp_values.landloard_phone_no_0} onChange={this.handleCheckbox} style={{width:'30px', border:'0', fontSize:'10px'}} maxlength="4"/>)
                                <input type="text"  id="landloard_phone_no_1"  value={this.state.temp_values.landloard_phone_no_1} onChange={this.handleCheckbox} style={{width:'120px', border:'0', fontSize:'11px'}} maxlength="14"/>
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px'}}>
                               <label contenteditable="false">Full Address:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="full_address"  value={this.state.temp_values.full_address} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}}  maxlength="100"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px'}}>
                               <label contenteditable="false">Dates of residency:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px', borderBottom:'1px solid #000'}}>
                               <input type="text"  id="dates_of_residency"  value={this.state.temp_values.dates_of_residency} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderRight:'1px solid #000', width:'90px'}}>
                               <label contenteditable="false">Reason for leavng:</label>							
                           </td>
                           <td contenteditable="false" style={{borderRight:'1px solid #000'}}>
                               <input type="text"  id="reason_for_leaving"  value={this.state.temp_values.reason_for_leaving} onChange={this.handleCheckbox}  style={{width:'160px', border:'0', fontSize:'10px'}}  maxlength="100"/>
                           </td>
                           <td contenteditable="false" style={{borderRight:'1px solid #000', width:'90px'}}>
                               <label contenteditable="false">Monthly Rent:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px'}}>
                               $<input type="text"  id="residency_monthly_rent"  value={this.state.temp_values.residency_monthly_rent} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>
                           </td>					
                       </tr>
                   </table>
   
                   
                   <div style={{background:'#000'}}>
                       <p style={{background:'#000', color:'#fff', padding:'5px', margin:'0'}} contenteditable="false">Current Employment (if less than 3 years, also complete next section)</p>
                   </div>
   
                   <table width="100%" cellpadding="1" cellspacing="0" style={{fontSize:'10px', textTransform:'inherit'}}>
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">Current Status:</label>
                               <input type="checkbox" id="current_employment_employed" checked={this.state.temp_values.current_employment_employed} onClick={this.handleCheckbox} /> Employed
                               <input type="checkbox" id="current_employment_self_employed" checked={this.state.temp_values.current_employment_self_employed} onClick={this.handleCheckbox} /> Self-Employed
                               <input type="checkbox" id="current_employment_retired" checked={this.state.temp_values.current_employment_retired} onClick={this.handleCheckbox} /> Retired
                               <input type="checkbox" id="current_employment_student" checked={this.state.temp_values.current_employment_student} onClick={this.handleCheckbox} /> Student
                               <input type="checkbox" id="current_employment_unemployed" checked={this.state.temp_values.current_employment_unemployed} onClick={this.handleCheckbox} /> Unemployed:
                           
                               
                           </td>
                       </tr>
                   </table>
   
                   <table width="100%" cellpadding="3" cellspacing="0" style={{fontSize:'10px', textTransform:'inherit'}}>
                       
                       <tr> 
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Company/School:</label>							
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="current_employment_company_school"  value={this.state.temp_values.current_employment_company_school} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Supervisor's Name:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px', borderBottom:'1px solid #000', BorderLeft:'1px solid #000'}}>
                               <input type="text"  id="current_employment_supervisor_name"  value={this.state.temp_values.current_employment_supervisor_name} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false"style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Street Address:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="current_employment_street_address"  value={this.state.temp_values.current_employment_street_address} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="100"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Supervisor's Phone No.:</label>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               (<input type="text"  id="current_employment_supervisor_phone_no_0"  value={this.state.temp_values.current_employment_supervisor_phone_no_0} onChange={this.handleCheckbox} style={{width:'30px', border:'0', fontSize:'10px'}} maxlength="4"/>) 
                               <input type="text"  id="current_employment_supervisor_phone_no_1"  value={this.state.temp_values.current_employment_supervisor_phone_no_1} onChange={this.handleCheckbox} style={{width:'120px', border:'0', fontSize:'10px'}} maxlength="14"/>
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">City, State, Zip:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000'}} colspan="3">
                               <input type="text"  id="current_employment_city_state_zip"  value={this.state.temp_values.current_employment_city_state_zip} onChange={this.handleCheckbox} style={{width:'430px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>					
                       </tr>
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Occupation:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="current_employment_occupation"  value={this.state.temp_values.current_employment_occupation} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Gross Annual Salary:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px', borderBottom:'1px solid #000'}}>
                               $<input type="text"  id="current_employment_gross_annual_salary"  value={this.state.temp_values.current_employment_gross_annual_salary} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>
                           </td>					
                       </tr>
                       <tr> 
                           <td contenteditable="false" style={{borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Responsibilities:</label>							
                           </td>
                           <td contenteditable="false" style={{borderRight:'1px solid #000'}}>
                               <input type="text"  id="current_employment_responsibilities"  value={this.state.temp_values.current_employment_responsibilities} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="40"/>
                           </td>
                           <td contenteditable="false"style={{borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Date of Employment:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px'}}>
                               <input type="text"  id="current_employment_date_of_employment"  value={this.state.temp_values.current_employment_date_of_employment} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}}  maxlength="10"/>
                           </td>					
                       </tr>
                   </table>
   
               </div>
               
               
           </div>
           <div style={{height:'40px', color:'#d2d2d2', width:'100%',  fontSize:'11px', borderTop:'1px solid #d2d2d2',  marginBottom:'15px', paddingTop:'6px'}}>
               <div style={{marginTop:'10px', textAlign:'center'}}>2</div>
           </div>
       </div>    
   
   
       <div size="A4" style={{fontFamily:'Arial', padding:'35px'}} >
           <div style={{padding:'40'}}>
               
               <div style={{background:'#000', marginTop:'2px'}}>
                   <p style={{background:'#000', color:'#fff', padding:'2px', margin:'0'}} contenteditable="false">Previous Employment</p>
               </div>
               <div style={{border:'3px solid #000'}}>
                   <table width="100%" cellpadding="1" cellspacing="0" style={{fontSize:'9px', textTransform:'inherit'}}>
                       
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Company:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="previous_employment_company"  value={this.state.temp_values.previous_employment_company} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="100"/>
                           </td>
                           <td contenteditable="false" sstyle={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Supervisor's Name:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px', borderBottom:'1px solid #000'}}>
                               <input type="text"  id="previous_employment_supervisor_name"  value={this.state.temp_values.previous_employment_supervisor_name} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Street Address:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="previous_employment_street_address"  value={this.state.temp_values.previous_employment_street_address} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="100"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Supervisor's Phone No.:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px', borderBottom:'1px solid #000'}}>
                               (<input type="text"  id="previous_employment_supervisor_phone_no_0"  value={this.state.temp_values.previous_employment_supervisor_phone_no_0} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>)
                               <input type="text"  id="previous_employment_supervisor_phone_no_1"  value={this.state.temp_values.previous_employment_supervisor_phone_no_1} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>
                           </td>					
                       </tr>
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">City, State, Zip:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000'}} colspan="3">
                               <input type="text"  id="previous_employment_city_state_zip"  value={this.state.temp_values.previous_employment_city_state_zip} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="100"/>
                           </td>					
                       </tr>
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Occupation:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="previous_employment_occupation"  value={this.state.temp_values.previous_employment_occupation} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Gross Annual Salary:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px', borderBottom:'1px solid #000'}}>
                               $<input type="text"  id="previous_employment_gross_annual_salary"  value={this.state.temp_values.previous_employment_gross_annual_salary} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>
                           </td>					
                       </tr>
   
                       <tr> 
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Responsibilities:</label>							
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <input type="text"  id="previous_employment_responsibilities"  value={this.state.temp_values.previous_employment_responsibilities} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="50"/>
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', width:'90px', paddingLeft:'3px'}}>
                               <label contenteditable="false">Date of Employment:</label>
                           </td>
                           <td contenteditable="false" style={{paddingLeft:'3px', borderBottom:'1px solid #000'}}>
                               <input type="text"  id="previous_employment_date_of_employment"  value={this.state.temp_values.previous_employment_date_of_employment} onChange={this.handleCheckbox} style={{width:'160px', border:'0', fontSize:'10px'}} maxlength="10"/>
                           </td>					
                       </tr>
                   </table>
   
                   <div style={{background:'#000'}}>
                       <p style={{background:'#000', color:'#fff', padding:'2px', margin:'0'}} contenteditable="false">Pets</p>
                   </div>
   
                   <table width="100%" cellpadding="2" cellspacing="0" style={{fontSize:'9px', textTransform:'inherit'}}>
                       <tr> 
                           <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Do you currently own or plan to keep pets in the apartment?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="pets_yes" checked={this.state.temp_values.pets_yes}  onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="pets_no" checked={this.state.temp_values.pets_no} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">Description <br />ATTACH PHOTO</label>
                               <input type="text" id="pets_des" onChange={this.handleCheckbox} value={this.state.temp_values.pets_des}/>
                           </td>
                       </tr>
                   </table>
   
                   <div style={{background:'#000'}}>
                       <p style={{background:'#000', color:'#fff', padding:'2px', margin:'0'}} contenteditable="false">Questionnaire</p>
                   </div>
                   
                   <table width="100%" cellpadding="1" cellspacing="0" style={{fontSize:'9px', textTransform:'inherit', lineHeight:'9px'}}>
                       <tr> 
                           <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Have you ever rented or resided in an Urban American apartment before?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="have_you_ever_rented_yes" checked={this.state.temp_values.have_you_ever_rented_yes} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="have_you_ever_rented_no" checked={this.state.temp_values.have_you_ever_rented_no} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">If yes, address and dates:</label>
                               <input type="text" id="have_you_ever_rented_des" onChange={this.handleCheckbox} value={this.state.temp_values.have_you_ever_rented_des}/>
                               
                           </td>
                       </tr>
                   
                       <tr> 
                           <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Are you related any current or former occupants of a UA property?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="occupants_UA_property_yes" checked={this.state.temp_values.occupants_UA_property_yes} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="occupants_UA_property_no" checked={this.state.temp_values.occupants_UA_property_no} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">If yes, explain:</label>
                               <input type="text" id="occupants_UA_property_des" onChange={this.handleCheckbox} value={this.state.temp_values.occupants_UA_property_des}/>
                           </td>
                       </tr>
                   
                       <tr> 
                       <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Have you ever been sued in Landlord/Tenant Court?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="sued_landlord_yes" checked={this.state.temp_values.sued_landlord_yes} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="sued_landlord_no" checked={this.state.temp_values.sued_landlord_no} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">If yes, explain:</label>
                               <input type="text" id="sued_landlord_des" onChange={this.handleCheckbox}  value={this.state.temp_values.sued_landlord_des}/>
                           </td>
                       </tr>
                   
                       <tr> 
                       <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Have you ever been a defendant in a civil/criminal action?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="criminal_action_yes" checked={this.state.temp_values.criminal_action_yes}  onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="criminal_action_no" checked={this.state.temp_values.criminal_action_no}  onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">If yes, explain:</label>
                               <input type="text" id="criminal_action_des" onChange={this.handleCheckbox}  value={this.state.temp_values.criminal_action_des}/>

                           </td>
                       </tr>
                   
                       <tr> 
                       <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Have you ever been evicted?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="been_evicted_yes" checked={this.state.temp_values.been_evicted_yes} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="been_evicted_no" checked={this.state.temp_values.been_evicted_no} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">If yes, explain:</label>
                               <input type="text" id="been_evicted_des" onChange={this.handleCheckbox} value={this.state.temp_values.been_evicted_des}/>

                           </td>
                       </tr>
                   
                       <tr> 
                       <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Have you ever failed to pay your rent timely?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="rent_timely_yes" checked={this.state.temp_values.rent_timely_yes} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="rent_timely_no" checked={this.state.temp_values.rent_timely_no} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">If yes, explain:</label>
                               <input type="text" id="rent_timely_des" onChange={this.handleCheckbox} value={this.state.temp_values.rent_timely_des}/>

                           </td>
                       </tr>
                   
                       <tr> 
                       <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Have you ever been arrested?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="been_arrested_yes" checked={this.state.temp_values.been_arrested_yes} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="been_arrested_no" checked={this.state.temp_values.been_arrested_no} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">If yes, explain:</label>
                               <input type="text" id="been_arrested_des" onChange={this.handleCheckbox}  value={this.state.temp_values.been_arrested_des}/>

                           </td>
                       </tr>
               
                       <tr> 
                       <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Have you ever filed for bankruptcy?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="bankruptcy_yes" checked={this.state.temp_values.bankruptcy_yes} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="bankruptcy_no" checked={this.state.temp_values.bankruptcy_no} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">If yes, explain:</label>
                               <input type="text" id="bankruptcy_des" onChange={this.handleCheckbox} value={this.state.temp_values.bankruptcy_des}/>

                           </td>
                       </tr>
                   
                       <tr> 
                       <td contenteditable="false" style={{width:'170px', borderBottom:'1px solid #000', borderRight:'1px solid #000'}}>
                               <label contenteditable="false">Have you ever used another name?</label>
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               Yes<br /><input type="checkbox" id="another_name_yes" checked={this.state.temp_values.another_name_yes}  onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false" style={{width:'40px', borderBottom:'1px solid #000', borderRight:'1px solid #000', textAlign:'center'}}>
                               No<br /><input type="checkbox" id="another_name_no" checked={this.state.temp_values.another_name_no} onClick={this.handleCheckbox} />
                           </td>
                           <td contenteditable="false"  style={{borderBottom:'1px solid #000'}}>
                               <label contenteditable="false">If yes, name:</label>
                               <input type="text" id="another_name_des" onChange={this.handleCheckbox}  value={this.state.temp_values.another_name_des}/>

                               
                           </td>
                       </tr>
                   </table>
   
                   <div style={{background:'#000'}}>
                       <p style={{background:'#000', color:'#fff', padding:'2px', margin:'0'}} contenteditable="false">Disclosure, Agreement & Consent</p>
                   </div>
   
                   <table width="100%" cellpadding="1" cellspacing="0" style={{fontSize:'9px', textTransform:'inherit'}}>
                       <ul style={{fontSize:'9px', margin:'3px 0'}} contenteditable="false">
                           <li>I understand that Urban American Management, LLC is an agent of the Landlord and is a paid representative of the Landlord. </li>
                           <li>I authorize you to conduct all necessary background checks concerning my/our application and to verify references. I/We have provided: including, but not limited to, credit reports, civil or criminal actions, rental history, employment/salary details, police and vehicle records, and any other relevant information; and I release Landlord, its employees and agents from all liability for any damage whatsover incurred in furnishing or obtaining such information. I agree to pay a non-refundable application free retained by the Landlord as the agreed compensation for credit investigation, processing and verification of the application, other expenses and.or loss of rent, and the Landlord shall have no futher obligation to me/us In no event is the application free refundable. I hereby waive any claim for damages by reason of non-compliance of this application which the Landlord or his agent may reject.</li>
                           <li>I declare that all information listed on this application is true and correct and that providing false information is cause to be rejected as a tenant and/or evicted.</li>
                           <li>I acknowldege that you will require the same information for all adults living in the apartment. Failure to provide this information is cause for this application to be rejected.</li>
                           <li>I acknowldege that no lease will become effective untill management approves this application and has viewed a positive credit report.</li>
                       </ul>
                   </table>
   
                   <table width="100%" cellpadding="1" cellspacing="0" style={{fontSize:'9px', textTransform:'inherit'}}>
                       <tr>
                           <td contenteditable="false">
                               <label contenteditable="false">Signature:</label>
                               <input type="text"  id="signature"  value={this.state.temp_values.signature} onChange={this.handleCheckbox} style={{border:'0px', borderBottom:'1px solid #000', width:'295px', margin:'0px 5px', fontSize:'10px'}}  maxlength="50" />
                               
                               <label contenteditable="false">Date:</label>
                               <input type="text"  id="final_date"  value={this.state.temp_values.final_date} onChange={this.handleCheckbox} style={{border:'0px', borderBottom:'1px solid #000', width:'130px', margin:'0px 5px', fontSize:'10px'}}  maxlength="10" />								
                           </td>
                       </tr>
                   </table>
   
                   <div width="100%" style={{fontSize:'9px', textTransform:'inherit', borderTop:'1px solid #000', marginTop:'5px'}}>
                   <i><p style={{fontSize:'9px', margin:'5px 10px',lineHeight:'8px',fontWeight:'600'}} contenteditable="false">
                           Title VII of the CIVIL RIGHTS ACT of 1966 makes discrimination based upon race, religion, or national origin illegal in connection with of the rental of most housing. The Federal agency, which administers compliance with this law, concerning this company: Department of Housing & Urban Development, Washington, D.C. 20410. 
                       </p>
                       <p style={{fontSize:'9px', margin:'5px 10px',lineHeight:'8px',fontWeight:'600'}} contenteditable="false">
                           The Federal Equal Credit opportunity Act prohibits creditors from discriminating against credit applications on the basis of sex or marital status. The Federal Agency which admisisters compliance with this law concerning this company: Equal Credit opportunity, Federal Trade Commisison, Washingaton, D.C. 20580. 
                           </p></i>
                   </div>
               </div>
                       
           </div>
           <div style={{height:'40px', color:'#d2d2d2', width:'100%',  fontSize:'11px', borderTop:'1px solid #d2d2d2',  bottom:'0px', paddingTop:'6px'}}>
               <div style={{marginTop:'10px', textAlign:'center'}}>3</div>
           </div>
           <button className="btn btn-default" onClick={this.saveData}>Submit</button>
       </div>
                                  
                                             
                                      
                                          
                                           </div>
                    </main>
                </div>

                );
    }
}
export default Urbanform;
