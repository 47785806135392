import React, { Component } from 'react';
import AgentListingFeed from '../../agent/listing/ListingFeed';

class AgentFeed extends Component {
    render() {
        return (
            <div>
                <AgentListingFeed  userType='Agent' />
            </div>
        );
    }
}

export default AgentFeed;