import React from 'react';
import { Link } from 'react-router-dom';
import ManagerHeader from '../common/ManagerHeader';
import ManagerSidebar from '../common/ManagerSidebar';
import AgentSidebar from '../common/AgentSideBar';
import AgentHeader from '../common/AgentHeader';
import { setAgentToken, getAgentToken, getApiData , getAgentId,getBaseUrl} from '../../services/Api';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router';

class ResourcesAgent extends React.Component {
	
	constructor(props) {
		super(props);
		window.scroll(0,0);
        this.state = {
			
		  };
		  window.scroll(0,0);
		}

    render() {
		const { userType} = this.props;
        return (
                <div>
                   {userType == 'Manager' ? <ManagerHeader /> : <AgentHeader />} 
                    <main>
					{userType == 'Manager' ? <ManagerSidebar /> : <AgentSidebar />}
					<div className="content">
						<div className="work-section">
						<div className="dashboard">

							
								<h2>Agent Resources</h2>
									<div className="resources-container">
											<h3>CLIENT SERVICES</h3>
												<div className="row clearfix btn-box">
													
														<div className="col-sm-4">
														<a href="http://urbandigs.com/" target="_blank" className="btn btn-default">Run Comps</a>
														</div>
														<div className="col-sm-4">
																<a href="https://propertyshark.com/"  target="_blank" className="btn btn-default">Landlord Research</a>
														</div>
														<div className="col-sm-4">
																<a href="https://olr.com" target="_blank" className="btn btn-default">Listings Database</a>
														</div>
														
													
												</div>
												
									</div>

									<div className="resources-container">
											<h3>LISTING SERVICES</h3>
												<div className="row clearfix btn-box">
														<div className="col-sm-4">
																<a href="https://www.canva.com/"   target="_blank" className="btn btn-default">Marketing Materials</a>
														</div>
														<div className="col-sm-4">
																<a href="https://docs.google.com/spreadsheets/d/1nmAY3fX2jYZDV3LWuAElpmHPH4xQyYS0GLuRsYJ_oxA/edit#gid=1122761356" target="_blank" className="btn btn-default">Unit Access</a>
														</div>
														<div className="col-sm-4">
																<a href="https://drive.google.com/drive/u/0/folders/1obweXFN7RxWasUdtAbtimhoyDTWMMc2N"   target="_blank" className="btn btn-default">Unit Photos</a>
														</div>
												</div>
												<div className="row clearfix btn-box">
												<div className="col-sm-4">
																<a href="https://drive.google.com/drive/u/0/folders/1dx5utvhfhmYPPUVSNYS4lhTZDlgb9tPg" target="_blank" className="btn btn-default">Application PDFs</a>
														</div>
														<div className="col-sm-4">
																<a href="#" target="_blank" className="btn btn-default">Board Packages</a>
														</div>
														
												</div>
												
									</div>

									<div className="resources-container">
											<h3>AGENT SERVICES</h3>
												<div className="row clearfix btn-box">
												
														{/*<iv className="col-sm-4">
																<a  target="_blank" href="https://drive.google.com/drive/u/0/folders/1B_WLcyxSRLKXy3_V_YK_7Dvz7er9dA5E" className="btn btn-default">Orientation Materials</a>
														</div>
														<div className="col-sm-4">
																<a  target="_blank" href="https://brokersumo.com/#/office-documents" className="btn btn-default">Office Documents</a>
														</div>
														*/}
															<div className="col-sm-4">
																<a  target="_blank" href="https://calendar.google.com/calendar/embed?src=skywardsteelnyc.com_vqhm7qu3ih9ppmmiv1t21npod4%40group.calendar.google.com&ctz=America%2FNew_York" className="btn btn-default">Skyward Calendar</a>
														</div>
														<div className="col-sm-4">
																<a href="https://appext20.dos.ny.gov/nydos/nydoslogin.do?header=true#nbb"   target="_blank" className="btn btn-default">DOS</a>
														</div>
														<div className="col-sm-4">
																<a  target="_blank" href="https://docs.google.com/spreadsheets/d/1CZWc7mMggl6qdorO0fH03Nyw5IXRnoet-rY2c-AuJjo/edit#gid=0" className="btn btn-default">Recommended Vendors</a>
														</div>
												</div>
												<div className="row clearfix btn-box">
														<div className="col-sm-4">
																<a target="_blank"  href="https://docs.google.com/forms/d/e/1FAIpQLSfEgsN11Tq2uRyYPEtYBoojWivmXvOKpdwMQ5h5W4PyuyvQXw/viewform" className="btn btn-default">Financial request form</a>
														</div>
														<div className="col-sm-4">
																<a  target="_blank" href="https://www.brokersumo.com/home" className="btn btn-default">Submit Deals</a>
														</div>
														<div className="col-sm-4">
																<a  target="_blank" href="#" className="btn btn-default">Slack</a>
														</div>
													
												</div>
									</div>

									<div className="resources-container">
											<h3>GENERAL</h3>
												<div className="row clearfix btn-box">
														
														<div className="col-sm-4">
																<a target="_blank"  href='/manual/Welcome/' className="btn btn-default">Welcome Manual</a>
														</div>
														<div className="col-sm-4">
																<a href='/manual/Techno/' target="_blank" className="btn btn-default">Technology Manual</a>
														</div>
														<div className="col-sm-4">
																<a  target="_blank" href="/manual/RentalTraining/" className="btn btn-default">Rental Training Manual</a>
														</div>
												</div>
												<div className="row clearfix btn-box">
												<div className="col-sm-4">
																<a  target="_blank" href="/manual/buyers/" className="btn btn-default">Buyer Training Manual</a>
														</div>
														<div className="col-sm-4">
														<a  href="/manual/Sellers/" target="_blank" className="btn btn-default">Seller Training Manual</a>
														</div>
														
														{<div className="col-sm-4">
																<a href="/manual/Glossary/"   target="_blank" className="btn btn-default">Glossary</a>
													</div>}
													
												</div>
											
									</div>


								
							</div>

						</div>
					</div>	

		
               				      
                          
                    </main>     
                </div>

                );
    }
}
export default ResourcesAgent;