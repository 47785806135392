import React from 'react';
import { Link } from 'react-router-dom';
import { Z_BLOCK } from 'zlib';



class Components extends React.Component {
    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}

class Content extends React.Component {
   
    render() {
        return (
            <div>
                 <div className="container compnent">
                 <h4>Navigation Bars</h4>
                 <header>
                 <nav className="navbar navbar-expand-md bg-light navbar-dark">
                <a className="navbar-brand logo" href="#"><img src="/logo.png" width="100%" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <img src="/menu-icon.png"/>
                </button>
                <div className="collapse navbar-collapse " id="collapsibleNavbar">
             
                     <ul className="navbar-nav mr-auto">
                     <li className="nav-item"><Link to="/client/login" lass="nav-link"> Login</Link></li>
                     <li className="nav-item"><Link to="/" lass="nav-link"> Home</Link></li>
                     <li className="nav-item"><Link to="/" lass="nav-link"><img src="/member.png" width="46" className="user rounded-circle" /></Link></li>
                     </ul>   
                      
                    
                </div>  
                </nav>
                </header>
                <h4 className="text-center">left bar</h4>
                <div className="clearfix">
                    <div class="sidebar">
                        <ul class="sidebar-nav">
                        <li class="active"><a href="/agent/client"><img src="/client.png"/>  CLIENTS</a></li>
                        <li><a href="/agent/application"><img src="/application.png"/> APPLICATIONS</a></li>
                        </ul>
                    </div>
                    </div>
                    <h4 className="text-center">Card</h4>
                    <div className="form-panel">
                             

                                <div className="form-container" >
                                <div className='form-widget login-widget'>
                                
                                    <div className="form-section">
                                     
                                      
                                    </div>
                                </div>
                          
                                
                            </div>
                    </div> 

                     <h4 className="text-center">Form Card</h4>
                    <div className="form-panel">
                             

                                <div className="form-container" >
                                <div className='form-widget login-widget'>
                                <div class="message">Card title goes here</div>
                                
                                    <div className="form-section">
                                     
                                      
                                    </div>
                                </div>
                          
                                
                            </div>
                    </div> 

                    <div className="dashboard">
                    <h4>Application Card</h4>
                        <div className="row">
                            <div className="col-sm-6">
                                <div class="panel-body table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th width="45%">nyc, Unit 1111111111</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Status: </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="form-panel">
                                <h4 >Buttons</h4>

                                <div className="form-container" >
                                <div className='form-widget'>
                                   
                                    <div className="form-section">
                                     
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item left"><button to="" className="page-link" ><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev  </button></li>
                                            <li className="page-item mid"><Link to=""></Link></li>
                                            <li className="page-item right"><button to="" className="page-link">Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                                        </ul>

                                        <div className="clearfix">
                                                <div className="duration-widget">
                                                    
                                                        <label className="container-rd">
                                                            <input type="radio" name="lease_months" value="12"/>
                                                            <span className="checkmark"></span>
                                                            <div className="txt">12</div>
                                                        </label>
                                                        <label className="container-rd">
                                                            <input type="radio"  name="lease_months" value="18" />
                                                            <span className="checkmark"></span>
                                                            <div className="txt">18</div>
                                                        </label>
                                                        <label className="container-rd">
                                                            <input type="radio"  name="lease_months" value="24"/>
                                                            <span className="checkmark"></span>
                                                            <div className="txt">24</div>
                                                        </label>
                                                        
                                               
                                            </div>
                                        </div>   
                                            <Link to class="btn btn-default">Label</Link><br/><br/>
                                            <Link to class="btn btn-default">View Agency & Fee Disclosure</Link><br/>
                                            <div class="upload-btn-wrapper mtop"><button class="btn btn-small" type="button">Take a picture <i class="fa fa-camera" aria-hidden="true"></i></button></div><br/>
                                            <div class="upload-btn-wrapper mtop" ><button class="btn btn-small" type="button">Upload</button><div class="form-group"><input required="" type="file" id="gov_issued_id" /></div></div><br/>
                                            <div to="" className="btn signature-btn">Signature: <span></span></div>
                                            <br/>  <br/>  <br/>
                                            <Link to><img src="/link.png"/></Link>
                                    </div>
                                </div>
                                
                                
                            </div>
                    </div> 

                    <div className="content">
						<div className="work-section">
						<div className="dashboard">

							
								<h2 className="pull-left">Table title</h2>
								
							

								<div className="panel-body table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
												<th>Address</th>
												<th>Unit #</th>
												<th>Client name <Link to  data-toggle="collapse" data-target="#search" className="icon-wrap search"></Link>
												<div id="search" class="collapse table-collapse">

													<div className="box">
													<form>
													<input type="text" class="search-input" />
													</form>
														<ul>
															<li><Link to>Rebecca Brown</Link></li>
															<li><Link to>Andrew Clark</Link></li>
															<li><Link to>John Smith</Link></li>
															<li><Link to>Alex Wilson </Link></li>
														</ul>

													</div>
												</div>
												
												</th>
												<th>Status <Link to data-toggle="collapse" data-target="#status" className="icon-wrap status"></Link>												
												<div id="status" class="collapse table-collapse status-collapse">

													<div className="box">
													<form>
													<input type="checkbox" id="Sent" />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" />
													<label for="submit">Sumbitted by client</label>
													<input type="checkbox" id="Incomplete" />
													<label for="Incomplete">Incomplete</label>
													</form>
														

													</div>
												</div>
												
												</th>
												<th>Link</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>548 W 114th St., New York, NY, 10025</td>
												<td>3D</td>
												<td>John Smith</td>
												<td>Submitted by client</td>
												<td><Link to data-toggle="collapse" data-target="#client" className="icon-wrap link"></Link>												
												<div id="client" class="collapse table-collapse link-collapse">

													<div className="box">
													<form>
													<input type="text" class="search-input" />
													</form>
														<ul>
															<li><Link to>Rebecca Brown <span className="pull-right"><img src="/link.png"/> 2</span></Link>  </li>
															<li><Link to>Andrew Clark <span className="pull-right"><img src="/link.png"/> 1</span></Link></li>
															<li><Link to>Brady Gordon <span className="pull-right"><img src="/inactive-link.png"/></span></Link></li>
														
														</ul>

													</div>
												</div>
												</td>											
											</tr>
											<tr>
												<td>500 W 110th St., New York, NY, 10025</td>
												<td>2F</td>
												<td>Rebecca Brown</td>
												<td>Incomplete</td>
												<td><Link to><img src="/link.png"/></Link></td>											
											</tr>
											<tr>
												<td>548 W 125th St., New York, NY, 10025</td>
												<td>5R</td>
												<td>Andrew Clark</td>
												<td>Sent</td>
												<td><Link to><img src="/link.png"/></Link></td>											
											</tr>
											<tr>
												<td>548 W 110th St., New York, NY, 10025</td>
												<td>2F</td>
												<td>Alex Wilson (G)</td>
												<td>Incomplete</td>
												<td><Link to><img src="/link.png"/></Link></td>											
											</tr>
											<tr>
												<td>548 W 114th St., New York, NY, 10025</td>
												<td>3D</td>
												<td>John Smith</td>
												<td>Submitted by client</td>
												<td><Link to><img src="/link.png"/></Link></td>											
											</tr>
										</tbody>
										</table>
									</div>



								
							

								<div className="table-component">
                                
                                <h2>Table Components</h2>
									<table className="table">
										<thead>
											<tr>
												<th>Address</th>
												<th>Unit #</th>
												<th>Client name <Link to  data-toggle="collapse" data-target="#search1" className="icon-wrap search active"></Link>
												<div id="search1" class="table-collapse">

													<div className="box">
													<form>
													<input type="text" class="search-input" />
													</form>
														<ul>
															<li><Link to>Rebecca Brown</Link></li>
															<li><Link to>Andrew Clark</Link></li>
															<li><Link to>John Smith</Link></li>
															<li><Link to>Alex Wilson </Link></li>
														</ul>

													</div>
												</div>
												
												</th>
												<th>Status <Link to data-toggle="collapse" data-target="#status1" className="icon-wrap status active"></Link>												
												<div id="status1" class=" table-collapse status-collapse">

													<div className="box">
													<form>
													<input type="checkbox" id="Sent" />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" />
													<label for="submit">Sumbitted by client</label>
													<input type="checkbox" id="Incomplete" />
													<label for="Incomplete">Incomplete</label>
													</form>
														

													</div>
												</div>
												
												</th>
												<th>Link</th>
											</tr>
										</thead>
										
										</table>
									</div>
							</div>

						</div>
					</div>	
                </div>     
              </div>

                );
    }
}
export default Components;
