import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {isEmail} from 'validator';
import {getApiData, postApiData,checkAdminToken,getAdminToken} from './../../services/Api'
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';

class AdminMediaCategory extends Component {
    constructor(props){
        super(props);
        this.state={
            addManager: false,
            dropdown: 1,
          
            name:"",
            myerror:"", //for email field
            myerror2:"", //for dropdown
            myerror3:"", //for Name field
            successQuote:false,
            myerrormsg:"",
            mediatopicList:[],
            routeFlag:true,
            statusMsg: "",
            myerror4:""           
        };
        this.nameRef = React.createRef();
      
    }

    handleChange = (event) => {   
        switch(event.target.id){
                case 'dropdown':        
                    if (event.target.value.length == null) {
                    this.setState({
                                    myerror2: 'Field is required.'
                                  });
                    }else{
                          this.setState({
                                    [event.target.id]: event.target.value,
                                    myerror2: ''
                                  });
                    }
                break;
                case 'name':
                if(event.target.value.length == 0){
                    this.setState({
                        myerror3:'Field cannot be empty'
                    })
                }else{
                    this.setState({
                        [event.target.id]: event.target.value.trim(),
                        myerror3:''
                    })
                }
        
        }
    }

    async addMediaCategory(name, dropdown) {
        let where = [{
                url: "/admin/addMediaCategory"
            }];
            postApiData(where, {'name': name,'status':dropdown},true).then(res => {
                if(res.data==202){
                    this.setState({ myerror3: 'Field cannot be empty'});
                }
                else if(res.data==203)
                {
                    this.setState({ myerror3: this.state.name+' is already exist, please try any other value.'});
                }
                else{
                        this.setState({ 'pageLoader':true, name:'',dropdown:1,myerror4:''},()=>{
                            this.nameRef.current.value = ''
                          
                            this.setState({ 'pageLoader':false, "successQuote":true,});
                            setTimeout(
                                function() {
                                    this.setState({"successQuote":false});
                                }
                                .bind(this),
                                2000
                            ); 
                        })  
                    }
        }).catch(error => {
           this.setState({myerror4:error.response.data.error.message});
           this.setState({ 'pageLoader':false, "successQuote":true});
           setTimeout(
            function() {
                this.setState({"successQuote":false});
            }
            .bind(this),
            2000
        ); 
        });
    }

    validateForm() {
        return this.state.name.length >0 && this.state.name.trim()!=''   && (this.state.myerror.length  == 0 && this.state.myerror3.length  == 0 ) ;
    }

    addMediaCategoryButton=()=>{
        this.getMediaCategoryList()
        this.setState({
            pageLoader: true
        },()=>{
            this.setState({  pageLoader: false, addManager: !this.state.addManager, successQuote :false, email:'', name:'',dropdown:1,  })
        })
    }

    handleFormSubmit=(e)=>{
        e.preventDefault();
        const { name, dropdown }= this.state
        this.addMediaCategory(name ,dropdown);
    }

    componentWillMount =()=>{
        window.scroll(0,0);
        this.getMediaCategoryList();
       
    }
    getMediaCategoryList=()=> {
        this.setState({'pageLoader':true});
        let applicationList = [{
            url: "/admin/mediacategoryList",
        }];
        getApiData(applicationList,true).then(res => {
        this.setState({'mediatopicList': res.data,'pageLoader':true},()=>{
                this.setState({'pageLoader':false, 'myerrormsg':true});
        })
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
    }

    changeMediaCategoryStatus=(status,id)=>{
           
            let where = [{
                url: `/admin/mediacategory/status/${id}`
            }];
            postApiData(where, {status},true).then(res => {
               if(res.data.message == 'success'){
                if(status == 0)
                {
                    this.setState({statusMsg:"Media Category is deactivated."})
                    setTimeout(
                        function() {
                            this.setState({statusMsg:''});
                        }
                        .bind(this),
                        2000
                    ); 
                }
                else if(status == 1){
                    this.setState({statusMsg:"Media Category is activated."})
                    setTimeout(
                        function() {
                            this.setState({statusMsg:''});
                        }
                        .bind(this),
                        2000
                    ); 
                }
               
            }
                        this.setState({ 'pageLoader':true},()=>{
                            this.getMediaCategoryList();
                        })  
        }).catch(error => {
            this.setState({myerrormsg: error.data.message});
         
        });
    }
    render() {
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        var { addManager, name, dropdown, email } = this.state;
        var style={
            form:{
              maxWidth: '50%', 
              margin: '10px auto'
            },
            dropdown:{
                backgroundColor: '#004f6b',
                borderRadius: '5px',
                color: 'white',
                width: '120px',
                height: '40px'
            }
        }
        var listItems = this.state.mediatopicList.map((item, idex)=>{
            return <tr>
                
                <td>{item.category}</td>
                <td>{item.status == 1?"Active" : "Inactive"}</td>
                <td>{item.status == 1?(<button className="btn btn-default" onClick={()=>this.changeMediaCategoryStatus(0,item.id)} >Deactivate</button>) : (<button className="btn btn-default" onClick={()=>this.changeMediaCategoryStatus(1,item.id)} >Activate</button>) }</td>
            </tr>
        })
       
        
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">Media Category</h2>
                <button className="btn btn-default pull-right" onClick={this.addMediaCategoryButton}>{!addManager ? "Add Media Topic" : "Back"}</button>
                        <div className="panel-body table-responsive">
                      
                        {this.state.statusMsg?(<div style={{marginLeft: '220px', marginRight: '370px'}} className="alert alert-success"><strong>{this.state.statusMsg}</strong></div>):(null)}
                        {!addManager ? 
                        
                            <table className="table table-striped">
                           
                                <thead>
                                    <tr>
                                        <th className="managerGridCol">Name</th> 
                                        <th className="managerGridCol">Status</th> 
                                        <th className="managerGridCol">Action</th> 
                                    </tr>
                                  </thead>
                                <tbody>
                                {listItems}
                                </tbody>
                                </table>
                                :
                                <Fragment>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className="managerGridCol">Add Media Category</th> 
                                        </tr>
                                    </thead>
                                </table>
                                <form onSubmit={this.handleFormSubmit} style={style.form}>

                            {this.state.successQuote? (this.state.myerror4 ? (<div className="alert alert-danger"><strong>{this.state.myerror4}</strong></div>):(<div className="alert alert-success"><strong>Media Category Successfully Added</strong></div>)):(null)}
                 
                                <div className="form-group">
                                    <label forhtml="name">Name</label>
                                    <input type="text" ref={this.nameRef} onChange={this.handleChange} className="form-control" id="name" placeholder="Name"/>
                                    <span className="inline-error-class"> {this.state.myerror3 !==''?this.state.myerror3:''}</span>
                                </div>
                               
                                {/* <div className="form-group">
                                    <div className="dropdown">
                                        <select id="dropdown"  onChange={this.handleChange} value={this.state.DropDownValue} style={style.dropdown}>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                        <span className="inline-error-class"> {this.state.myerror2 !==''?this.state.myerror2:''}</span>
                                    </div>
                                </div> */}
                                <button type="submit" disabled={!this.validateForm()} className="btn btn-primary" >Submit</button>
                                </form>
                                </Fragment>
                        }
                            </div>
                    </div>
                    
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default AdminMediaCategory;