import React from 'react';

import ScrollspyNav from "react-scrollspy-nav";
import $ from 'jquery';
import '../manual/highlight';
import '../manual/finder';
import 'jquery.scrollto';
import { getApiData, postApiData, getAgentId,getBaseUrl } from "../../services/Api";

class Techno extends React.Component {

  constructor(props){
    super(props);
    this.state = {
    pageContent:'',
    linkingLoader:false,
    editable:getAgentId()>0 || getAgentId()==0?"false":"true"
    }
  }
  componentWillMount(){
	
		this.getPageContent();
	}
  getPageContent=()=>{
         
		let where = [{
			url:'/managers/manualPageContent/'
		}]
		postApiData(where,{'page':'Techno_manual_content'},true).then(res=>{
				this.setState({
					pageContent:res.data[0].html_value,
					
				},()=>{
          document.getElementById('result-list').innerHTML=unescape(this.state.pageContent);
        })
		
			
		}).catch(error=>{

		})
  }
  contentSave=(type)=>e=>{
		this.setState({linkingLoader:true})
		var divValue='';

		 divValue=document.getElementById('result-list').innerHTML;
	
		let where=[{
			url:'/managers/manualContetSave'
		}];
		// var formData = new FormData();
		// formData.append('content',abc);
		
		postApiData(where,{'page':'Techno_manual_content','content':divValue},true).then(res=>{
			
				this.setState({linkingLoader:false},()=>{
				alert("Techno Manual Published Successfully.")
				})
				
		}).catch(error=>{

		})
	} 
    render() {
      
        return (
          <div class="manual-section" > 
          
          <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-light" >
          <img src="/logo.png" class="img-fluid logo" style={{maxWidth:'300px'}}/>


<ul class="navbar-nav mr-auto p-1 m-auto">
      <li class="nav-item d-none d-sm-block">
       
      </li>
    </ul>
    <ul class="navbar-nav d-none d-md-block d-lg-block d-xl-block col-md-2 text-right">
      <li class="nav-item ml-2">
      <button className="btn my-2 my-sm-0 finder-activator" type="submit" data-finder-activator data-toggle="collapse" data-target="#demo">
              <i className="fa fa-search"></i>
            </button>
            {getAgentId()>0 || getAgentId()==0 ?'' :<button class="btn btn-default pull-right saveBtnManual" onClick={this.contentSave('approve')}>Publish</button>}</li>

    </ul>
    <ul class="navbar-nav d-block d-sm-none">
      <li class="nav-item ml-2">
          <button class="btn my-2 my-sm-0 finder-activator" type="submit" data-toggle="collapse" data-target="#demo">
            <i class="fa fa-search"></i>
          </button>
      
      
      <button class="btn btn-default pull-right saveBtnManual" >SAVE</button></li>
    </ul>

</nav>
<div class="container-fluid">
  <div class="row">
 <div class="col-xl-9 col-lg-8 col-xs-12 p-1  divtopmargin right content" data-finder-content id="result-list"  contenteditable={this.state.editable}>
        
  </div>
    <nav class="d-none d-sm-none d-md-block col-xl-3 col-lg-4 left" id="myScrollspy">
      <div class="leftdiv col-xl-3 col-sm-4" style={{paddingLeft:'0px'}}>
      <ScrollspyNav scrollTargetIds={["START-YOUR-JOURNEY-SKYWARD", "SkywardOS", "Skyward-Google-Drive","Master-The-Market-Advertise","Nestio","olr","Compit","UrbanDigs","Skyward-Merchendise","Close-The-Deal-Deposits","SkywardOS-Client-Onboarding", "SkywardOS-Client-Application", "SkywardOS-Deposits","Other-Deposit-Methods","APPROVAL-COMPLIANCE-REPORTING","BROKERSUMO","STAY-INFORMED","Webinar-Training-Calendar","FAQ"]} activeNavClass="active" scrollDuration="1500" headerBackground="false">
      <ul class="nav nav-pills flex-column" style={{paddingRight:'15px'}}>
        <li class="nav-item">
          <a class="nav-link" href="#START-YOUR-JOURNEY-SKYWARD">START YOUR JOURNEY SKYWARD!</a>
          <ul class="ml-1 my-1" type="none">
            <li>
              <a class="nav-link" href="#SkywardOS">SkywardOS</a>
            </li>
            <li>
              <a class="nav-link" href="#Skyward-Google-Drive">Skyward Google Drive</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#Master-The-Market-Advertise">Master The Market - Advertise</a>
          <ul class="ml-1 my-1" type="none">
            <li>
              <a class="nav-link" href="#Nestio">Nestio</a>
            </li>
            <li>
              <a class="nav-link" href="#olr">OLR</a>
            </li>
            <li>
              <a class="nav-link" href="#Compit">Compit</a>
            </li>
            <li>
              <a class="nav-link" href="#UrbanDigs">UrbanDigs</a>
            </li>
            <li>
              <a class="nav-link" href="#Skyward-Merchendise">Skyward Merchendise</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#Close-The-Deal-Deposits">CLOSE THE DEAL - CLIENT PORTAL & DEPOSITS</a>
          <ul class="ml-1 my-1" type="none">
            <li>
              <a class="nav-link" href="#SkywardOS-Client-Onboarding">SkywardOS Client Onboarding</a>
            </li>
			 <li>
              <a class="nav-link" href="#SkywardOS-Client-Application">SkywardOS Client Application</a>
            </li>
            <li>
              <a class="nav-link" href="#SkywardOS-Deposits">SkywardOS Deposits</a>
            </li>
            <li>
              <a class="nav-link" href="#Other-Deposit-Methods">Other Deposit Methods</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#APPROVAL-COMPLIANCE-REPORTING">
            APPROVAL - COMPLIANCE & REPORTING
          </a>
          <ul class="ml-1 my-1" type="none">
            <li>
              <a class="nav-link" href="#BROKERSUMO">BROKERSUMO</a>
            </li>
          </ul>
        </li>
		
		 <li class="nav-item">
          <a class="nav-link" href="#STAY-INFORMED">
            STAY INFORMED
          </a>
          <ul class="ml-1 my-1" type="none">
            <li>
              <a class="nav-link" href="#Webinar-Training-Calendar">Webinar Training Calendar</a>
            </li>
			 <li>
              <a class="nav-link" href="#FAQ">FAQ's</a>
            </li>
          </ul>
        </li>
 
        
      </ul></ScrollspyNav>
      </div>
    </nav>
    <div className="finder active collapse" id="demo">
              <input id="finderInput" type="text" className="finder-input" onChange={this.searchDem} placeholder="Search Here..."/>
                <button id="finderPrev" className="btn btn-finder btn-finder-prev">
                  <i className="fa fa-angle-up"></i>
                </button>
                <button id="finderNext" className="btn btn-finder btn-finder-next">
                  <i className="fa fa-angle-down"></i>
                </button>
                <button id="finderClose" data-toggle="collapse" data-target="#demo" className="btn btn-finder btn-finder-close">
                  <i className="fa fa-times"></i>
                </button>
              </div>
  </div>
  
             </div>
</div>
    
);
    }
}
export default Techno;