import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () =>
  <div>
    <main>
      <div className="errorPage text-center">
        <br /><br /><br /><br />
        <h2 className="text-center">Oops !!! Something went wrong</h2>
        <br /> 
        {localStorage.getItem('agent_token') != undefined 
          ? ( <Link to="/agent/landing" className="btn btn-default">Go Back</Link>) 
           :localStorage.getItem('manager_token')!=undefined 
             ? ( <Link to="/manager/landing" className="btn btn-default">Go Back</Link>) 
              :localStorage.getItem('token')!=undefined 
                ? (<Link to="/client/login" className="btn btn-default">Go Back</Link>) 
                 :localStorage.getItem('adminUserDetails')!=undefined || localStorage.getItem('adminUserDetails.uerType')=='admin'
                   ? (<Link to="/admin/login" className="btn btn-default">Go Back</Link>)
                    : ( <Link to="/" className="btn btn-default">Go Back</Link>) }
     
       </div>
    </main>
  </div>

export default NotFound