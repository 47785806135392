import React, { Component } from 'react';
import ListingCreator from './ListingCreator';

class AgentListingCreator extends Component {
    render() {
        return (
            <div>
                <ListingCreator 
                userType='Agent'
                heading='Listing Creator'
                />
            </div>
        );
    }
}

export default AgentListingCreator;