import React, { Component } from 'react';
import ListingCreator from '../../agent/listing/ListingCreator';

class ManagerListingCreator extends Component {
    render() {
        return (
            <div>
                <ListingCreator 
                userType='Manager'
                heading='Listing Creator'
                />
            </div>
        );
    }
}

export default ManagerListingCreator;