import React from 'react';
import { Link } from 'react-router-dom';
import ManagerHeader from '../../common/ManagerHeader';
import ManagerSidebar from '../../common/ManagerSidebar';
import AgentSidebar from '../../common/AgentSideBar';
import AgentHeader from '../../common/AgentHeader'
import { Redirect } from 'react-router';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {getApiData, setAgentToken, getAgentToken,postApiData} from '../../../services/Api';
import 'react-input-range/lib/css/index.css';
import PropTypes from 'prop-types';
import { validatePhone } from "../../../services/validation";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const searchOptions = {
  componentRestrictions: {country: "us"}
  }

class AddBuildings extends React.Component {
	constructor(props) {
		super(props);

        this.state = {
            building_address: this.props.building_addr,
            building_name: "",
            management_name: "",
            management_email: "",
            management_phone: "",
            required_additional_documents: "Default",
            default_application_template: "Default",
            screenings_required: "Default",
            default_application_template: 'Skyward Standard',
            screenings_required: 'Credit',
            building_amenities: [],
            building_notes:"",

            building_address_error: "",
            building_name_error: "",
            management_name_error: "",
            management_email_error: "",
            management_phone_error: "",
            default_application_template_error: "",
            screenings_required_error: "",
            screenings_required_error: "",
            city_error:"",
            state_error:"",
            postal_code_error:"",
            lat_error:"",
            long_error:"",
            data:[],
            buildingList:[],
            building_id:0,
            selected_building_name: 'skyward',
            neighborhood:'',
            city:'',
            state:'',
            postal_code:'',
            nestio_building_id:this.props.building_id,
            listing_id:this.props.listing_id,
            building_notes_error:"",
            addDocString:'',
            addDocValue:'',
            addDocValueName:'',
            screeningString:'',
            is_management:0,
            is_skyward_runs_credit:0,
            screeningValue:'',
            searchMsg:false,
            lat:'',
            long:'',
            laundry:0,
            garage:0,
            doorman:0,
            showGoogleMsg:false,
            enableGoogleSearch:false,
            myerrormsg:''
            ,_required_additional_documents_:[]
		  };
       
      this.setWrapperRef1 = this.setWrapperRef1.bind(this);
      this.setWrapperRef2 = this.setWrapperRef2.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
      
    }
    
      /**
 * making Selection value for Additional Document 
 */
handleAddDoc=param=>event=>{
  let value=param[1]; //id
  let value_name=param[0]; //name
  if(this.state.addDocValue==''){
      this.setState({addDocValue:value,addDocValueName:value_name,addDocString:value_name})
  }
  if(!document.getElementById(event.target.id).checked){
	
    this.removeValue(this.state.addDocValue,value,',',(cberr,cbres)=>{
     
     if(cbres!=''){
       //var listName=cbres.split(',');
       this.setState({addDocValue:cbres});
     }
     else{
       this.setState({addDocValue:cbres});
     }
    });
     this.removeValue(this.state.addDocValueName,value_name,',',(cberr,cbres)=>{
       
      
     if(cbres!=''){
      var listName=cbres.split(',');
       this.setState({addDocValueName:cbres,addDocString:listName[0]});
     }
     else{
     this.setState({addDocValueName:cbres,addDocString:''});
     }
   
     });
    } 
 
    if(document.getElementById(event.target.id).checked){
     
     this.addValue(this.state.addDocValue,value,',',(cberr,cbres)=>{
               if(cbres!=''){
                  
                   this.setState({addDocValue:cbres});
              }
              
          });
          
          this.addValue(this.state.addDocValueName,value_name,',',(cberr,cbres)=>{
           if(cbres!=''){
            var listName=cbres.split(',');
               this.setState({addDocValueName:cbres,addDocString:listName[0]});
            }
            
        });
         
     }



  // if(this.state.addDocValue!=''){
  //  this.removeValue(this.state.addDocValue,value,',',(cberr,cbres)=>{
  //     if(cbres!=''){
  //         //var listName=cbres.split(',');
  //         this.setState({addDocValue:cbres});
  //     }
  //  });
  //   this.removeValue(this.state.addDocValueName,value_name,',',(cberr,cbres)=>{
  //   if(cbres!=''){
  //       var listName=cbres.split(',');
  //       this.setState({addDocValueName:cbres,addDocString:listName[0]});
  //   }else{
  //       this.setState({addDocValueName:cbres,addDocString:''});
  //   }

  //   });
  //  } 
  
}  


/* making Selection value for Screening Values 
*/
handleScreening=(event)=>{
 let value=event.target.value;
if(value=='is_management'){
  if(this.state.is_management==0){
    this.setState({is_management:1},()=>{
    document.getElementById('is_skyward_runs_credit').checked=false
    document.getElementById("box-5").checked=false;
    if(this.state.screeningValue!=''){
      this.removeValue(this.state.screeningValue,'Credit',',',(cberr,cbres)=>{
          if(cbres!=''){
              var listName=cbres.split(',');
              this.setState({screeningValue:cbres,screeningString:listName[0]});
          }else{
              this.setState({screeningValue:cbres,screeningString:''});
          }
        });
      
      }
    })
    this.setState({is_skyward_runs_credit:0},()=>{
    })
  }

}else if(value=='is_skyward_runs_credit'){
  if(this.state.is_skyward_runs_credit==0){
    this.setState({is_skyward_runs_credit:1},()=>{
      document.getElementById('is_management').checked=false
      document.getElementById("box-5").checked=true;
      /********************************* */
       if(this.state.screeningValue==''){
              this.setState({screeningValue:'Credit',screeningString:'Credit'})
          }
          
      /***************************** */
    })
    this.setState({is_management:0},()=>{
    })
  }
  
}else{
  if(this.state.screeningValue==''){
    this.setState({screeningValue:value,screeningString:value},()=>{
      this.setState({is_skyward_runs_credit:1},()=>{
        document.getElementById('is_skyward_runs_credit').checked=true
      })
    })
   
}
if(this.state.screeningValue!=''){
 this.removeValue(this.state.screeningValue,value,',',(cberr,cbres)=>{
    if(cbres!=''){
        var listName=cbres.split(',');
        this.setState({screeningValue:cbres,screeningString:listName[0]});
    }else{
        this.setState({screeningValue:cbres,screeningString:''});
    }
 });
 this.setState({is_skyward_runs_credit:0},()=>{
  document.getElementById('is_skyward_runs_credit').checked=false
})
 
 } 
}
 
}
/**
 * removeValue will manage the amenities
 */
removeValue(list, value, separator,cb){
  separator = separator || ",";
  let list_value=list.toString();
  var values = list_value.split(separator);
  for(var i = 0 ; i < values.length ; i++) {
    if(values[i] == value) {
      values.splice(i, 1);
      //this.setState({amenitiesList:values.join(separator)})
      cb(null,values.join(separator));
    }
  //   else{
  //     let _list_=list_value+","+value;
  //     //this.setState({amenitiesList:list})
  //     cb(null,_list_)
  // }
  }
}

addValue(list, value, separator,cb){
	
  separator = separator || ",";
  if(list!=''){
	var values = list.toString().split(separator);
	for(var i = 0 ; i < values.length ; i++) {
	   if(values[i] == value) {
		  values.splice(i, 1);
		  cb(null,values.join(separator));
		  }
	  else{
		let _list_=list+","+value;
		cb(null,_list_)
	}
  
  }
  }
  else{
	cb(null,value) 
  }
  
}
      multiselectCheckbox = (param, value) => {
        console.log("Value=>=>",value);
            if (param == "building_amenities") {
              var stateArray = this.state.building_amenities;
            }
           
        
            var flag = 0;
            // this is launches only one when the array is empty
            if (stateArray.length === 0) {
              stateArray.push(value);

              if(value=='Doorman'){
                this.setState({
                  "doorman":1
                });
            }else if(value=='Laundry'){
              this.setState({
                "laundry":1
              });
            }else if(value=='garage'){
              this.setState({
                "garage":1
              });
            }

            } else {
              //checking for the element if already exists
              stateArray.map(item => {
                if (item == value) {
                  flag = 1;
                }
              });
            }
            //if flag=0, then element can be pushed
            if (flag === 0) {
              stateArray.push(value);
              if(value=='Doorman'){
                this.setState({
                  "doorman":1
                });
            }else if(value=='Laundry'){
              this.setState({
                "laundry":1
              });
            }else if(value=='Garage'){
              this.setState({
                "garage":1
              });
            }
              //removing duplicate elements if any
              stateArray = [...new Set(stateArray)];
            } else {
              //removing elements from the array on uncheck
              var index = stateArray.indexOf(value);
              stateArray.splice(index, 1);
              if(value=='Doorman'){
                this.setState({
                  "doorman":0
                });
            }else if(value=='Laundry'){
              this.setState({
                "laundry":0
              });
            }else if(value=='Garage'){
              this.setState({
                "garage":0
              });
            }
            }
            this.setState(
              {
                [param]: stateArray
              },
              () => {
                console.log("this.state",this.state.doorman,this.state.laundry,this.state.garage)
                console.log("stateArray : ", stateArray);
              }
            );
          };
          
        handleChange = e => {
            const id = e.target.id;
            const value = e.target.value;
            const dataset = e.currentTarget.dataset.id;
            console.log('id : ', id);
            console.log('value : ', value);
            console.log('dataset : ', dataset);
        
            
            if (
              id == "building_address" ||
              id == "building_name" ||
              id == "management_name" ||
              id == "management_email" ||
              id == "management_phone"
            ) {
              var error = id + "_error";
              if (value == "") {
                console.log("_error : ", error);
                this.setState({
                  [error]: "Field cannot be empty",
                  [id]: [value]
                });
              } else if (id == "management_phone") {
                const phoneValue = this.formValidate("management_phone", value);
                this.setState(
                  {
                    [id]: phoneValue
                  },
                  () => {
                    console.log("management_phone : ", this.state.management_phone);
                  }
                );
              }  else {
                this.setState({
                  [error]: "",
                  [id]: [value]
                });
              }
            }

            if(id=="city"|| id=="state" ){
              var error = id + "_error";
              if (value == "") {
                console.log("_error : ", error);
                this.setState({
                  [error]: "Field cannot be empty",
                  [id]: [value]
                });
              }else if(this.isString(value)==false ){
                this.setState({
                  [error]: "Please enter only character",
                  [id]: [value]
                });
              }
              else {
                this.setState({
                  [error]: "",
                  [id]: [value]
                });
              }
            }

            if( id=="lat" || id=="long"){
              var error = id + "_error";
              if (value == "") {
                console.log("_error : ", error);
                this.setState({
                  [error]: "Field cannot be empty",
                  [id]: [value]
                });
              }else if(isNaN(value) && this.isNumber(value)==false) {
                this.setState({
                  [error]: "Please enter only numbers",
                  [id]: [value]
                });
              }else {
                this.setState({
                  [error]: "",
                  [id]: [value]
                });
              }
            }
            if(id=="postal_code" ){
              var error = id + "_error";
              if (value == "") {
                console.log("_error : ", error);
                this.setState({
                  [error]: "Field cannot be empty",
                  [id]: [value]
                });
              }else if(isNaN(value) && this.isNum(value)==false) {
                this.setState({
                  [error]: "Please enter only numbers",
                  [id]: [value]
                });
              }else {
                this.setState({
                  [error]: "",
                  [id]: [value]
                });
              }
            }
        
            if (
              id == "required_additional_documents" ||
              id == "default_application_template" ||
              id == "screenings_required"
            ) {
              var error = id + "_error";
              if (value == "" || value == 0) {
                console.log("_error : ", error);
                this.setState(
                  {
                    [error]: "Field cannot be empty",
                    [id]: [value]
                  },
                  () => {
                    console.log("dropdown : ", value);
                  }
                );
              } else {
                this.setState(
                  {
                    [error]: "",
                    [id]: [value]
                  },
                  () => {
                    console.log("dropdown : ", value);
                  }
                );
              }
            }
        
            if (
              id == "Elevator" ||
              id == "Doorman" ||
              id == "Laundry" ||
              id == "Garage" ||
              id == "Gym" ||
              id == "Outdoor areas" ||
              id == "Live-in super" ||
              id == "Heat & Hot Water included"
            ) {
              this.multiselectCheckbox("building_amenities", value);
            }
        }
        isString(str){
          if(/^[a-zA-Z\s]*$/.test(str))
          return true;
          else 
          return false;
      }
        isNumber(str){
          if(/^[0-9\s.]*$/.test(str))
          return true;
          else 
          return false;
      }
      isNum(str){
        if(/^[0-9\s]*$/.test(str))
        return true;
        else 
        return false;
    }

        formValidate(key, value) {
            switch (key) {
              case "management_phone":
                return this.validateEmpPhoneNumber(value);
                break;

                case "postal_code":
                return this.validateZip(value);
                break;
            }
          }
          validateEmpPhoneNumber(value) {
            let res = validatePhone(value);
            if (res.status == false) {
              this.setState({ management_phone_error: "Phone number is not valid." });
              document.getElementById("management_phone").value = res.value;
              return res.value;
            } else {
              this.setState({ management_phone_error: "" });
              document.getElementById("management_phone").value = res.value;
              return res.value;
            }
          return res.status;
          }

          buildingNotesChange=(event)=>{
            if(event.target.id == 'building_notes')
            {
              if(event.target.value == ">" || event.target.value == "<"){
                this.setState({
                  building_notes_error :"This character is invalid"
                })
              }else if(this.isBuilding(event.target.value)==false ){
                    this.setState({
                      building_notes_error:"This character is invalid"
                    })
                 
              }else if(event.target.value.length>= 100){
                this.setState({
                  building_notes_error:"Building Notes length is too long"
                })
              }
              else{
                this.setState({
                  building_notes_error:"",
                  [event.target.id]:event.target.value
                })
              }
            }  
          }

          isBuilding(str){
            if(/^[a-zA-Z0-9\s,.@{}():?/$*+!~`^%#&_';="-]*$/.test(str))
            return true;
            else 
            return false;
        }
    
        
          validation = () => {
  
            const {
              building_address,
              management_name,
              management_email,
              management_phone,
              management_phone_error,
              default_application_template,
              screenings_required,
              is_management,
              building_notes,
              postal_code,
              city,
              state,
              lat,
              long,
              city_error,
              state_error,
              lat_error,
              long_error,
              postal_code_error,
              myerrormsg
            } = this.state;
       
            return (
              building_address.toString().trim() !="" &&
              management_name.toString().trim() != "" &&
              management_email.toString().trim() != "" &&
              management_phone.toString().trim() != "" &&
              postal_code!= "" &&
              management_phone_error == "" && (this.state.screeningValue!='' || is_management!=0) &&
              city!="" &&
              state!="" &&
              lat!="" &&
              long!="" &&
              city_error.toString().trim()=="" &&
              state_error.toString().trim()=="" &&
              lat_error.toString().trim()=="" &&
              long_error.toString().trim()=="" &&
              postal_code_error.toString().trim()=="" &&
              myerrormsg==""  
              );
          };

          setWrapperRef1(node) {this.wrapperRef1 = node;}
          setWrapperRef2(node) {this.wrapperRef2 = node;}
         

          handleClickOutside(event) {
            if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
                var x = document.getElementById("documentList");
                x.style.display = "none";
              }
              if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
                var y = document.getElementById("ScreeningsList");
                y.style.display = "none";
              }
          }
        
            componentWillMount=()=>{
              console.log(this.props)
                this.getData();
                this.buildingOpt();
                if(this.state.nestio_building_id!=0)
                {
                  this.getNetiobuildingById(this.state.nestio_building_id);
                   
                }
                this.getRequiredAdditinalDocument();
                document.addEventListener('mousedown', this.handleClickOutside);
              }
              componentDidMount =()=>{
                document.addEventListener('mousedown', this.handleClickOutside);
              }
      // if feed building not found in the skyward database
  getRequiredAdditinalDocument(){
    let where = [{
      url: "/getRequiredAdditinalDocument"
      , where: { "where": { "document_flag": 1 } }
    }];
    getApiData(where, true).then(res => {
      if (res.data) {
        this.setState({
          _required_additional_documents_:res.data.data
        },()=>{
          res.data.data.forEach((row)=>{
            this.state.addDocValue= (this.state.addDocValue!='')? this.state.addDocValue.toString()+','+row.id:row.id;
            this.state.addDocValueName= (this.state.addDocValueName!='')? this.state.addDocValueName.toString()+','+row.document_name:row.document_name;
            let arr=(this.state.addDocValueName!='')?this.state.addDocValueName.toString().split(','):[];
            this.state.addDocString=arr[0];
          })
        })
      }
    });
  }
  
 async getNetiobuildingById(building_id){
    let buildingParam = [{
            url: "/nestio/getnestiobuildingbyid/"+building_id
    }];
        getApiData(buildingParam,true).then(res => {
         
          if(res.data.length==0)
          {
            this.setState({building_address:building_id});
          }
         else {
        this.setState({'buildingList':res.data});
        let _this = this;
        this.state.buildingList.forEach(function(building,index) {
          
                      //document.getElementById('neighborhood').value=building.neighborhood;
                        _this.setState({building_address:building.building_address});
                        _this.setState({neighborhood:building.neighborhood});
                        _this.setState({building_name:building.name});
                        _this.setState({management_name:building.building_ownership});
                        _this.setState({city:building.city});
                        _this.setState({state:building.state});
                        _this.setState({postal_code:building.postal_code});
                        _this.setState({lat:building.latitude});
                        _this.setState({long:building.longitude});
        })
      }
    });
  }

             getData=()=>{
             
                let where = [{
                      url: "/building",
                }];
               
                  postApiData(where,{'additional_documents':'additional_documents','application_template':'application_template','application_screening':'application_screening','building_amenities':'building_amenities'},false).then(res => {
                   this.setState({
                      data:res.data,
                      pageLoader:true
                    },()=>{
                      this.setState({pageLoader:false,flag:1})
                    })
            
                  }).catch(error => {
                  if(error.response){
                      this.setState({myerrormsg: error.response.data.error.message});
                  }
                  });
              }
            
              handleSubmit = (e) => {
                console.log("handleSubmit");
                e.preventDefault();
                if(this.state.addDocValue=='')
                {
                  this.state.addDocValue='NA';
                }
               const {
                 building_address, building_name,management_name,management_email,management_phone,
                 required_additional_documents,default_application_template,screenings_required,
                 building_amenities,neighborhood,city,state,postal_code,building_notes,listing_id,lat,long
               } = this.state;
               let where = [{
                 url: '/building/addDetail'
               }];
                 postApiData(where, {'building_address':building_address,
                  'building_name':building_name,
                  'management_name':management_name,
                  'management_email':management_email,
                  'management_phone':management_phone,
                   'required_additional_documents':this.state.addDocValue,
                   'default_application_template':default_application_template,
                   'screenings_required':this.state.screeningValue,
                   'neighborhood':neighborhood,
                   'city':city,
                   'state':state,
                   'postal_code':postal_code,
                   'building_notes':building_notes,
                   'building_amenities':JSON.stringify(building_amenities),
                   'listing_id':listing_id,
                   'latitude':lat,
                   'longitude':long,
                   'doorman':this.state.doorman,
                   'laundry':this.state.laundry,
                   'garage':this.state.garage,
                   'management_screen':this.state.is_management,
                   'is_skyward_runs_credit':this.state.is_skyward_runs_credit
                   }).then(res => {
                    if(this.state.nestio_building_id>0)
                    {
                      if(this.props.userType=='Agent'){
                        window.location.href="/agent/listing/listingfeed";
                      }
                      else
                      {
                        window.location.href="/manager/listing/listingfeed/";
                      }
                    }
                    else{
                             this.setState({ pageLoader:true},()=>{
                                 this.setState({
                                   pageLoader:false,
                                   successQuote: true,
                                   building_address:'',
                                   building_name:'',
                                   management_name:'',
                                   management_email:'',
                                   management_phone:'',
                                   required_additional_documents:'',
                                   default_application_template:'',
                                   screenings_required:'',
                                   myerrormsg:'',
                                   city:'',
                                   state:'',
                                   postal_code:'',
                                   lat:'',
                                   long:'',
                                   building_notes:''});
                            })  
                            window.scrollTo(0,0);
                            this.setState({addDocString:'', addDocValue:'',addDocValueName:''})
                            this.getData();
                            this.getRequiredAdditinalDocument();
                         
                          }
                 }).catch(error => {
                 this.setState({myerrormsg: error.response});
                 });
              };

              backToAddressPanel=()=>{
              
                this.setState({
                    pageLoader: true
                },()=>{
                    this.setState({  successQuote :false,  })
                    
                })
            }
 async buildingOpt(){
  this.setState({'buildingList':[]});
    /*let buildingParam = [{
            url: "/nestio/getnestiobuildingbyaddress"
    }];
        getApiData(buildingParam,true).then(res => {
        this.setState({'buildingList':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });*/
    }   
    



handleBuildingSearch = event => {
  
    var selected_building_name = event.target.getAttribute('name');
 if(event.target.getAttribute('value')=='')
 {
  this.setState({ postal_code: ''})
 }
    this.setState({
        selected_building_name: selected_building_name
    })
        var key=event.target.id; 
    switch(key){
        case 'building_address':
            let $this_ = this;
                this.setState({searchParm:event.target.value,building_address: event.target.value},()=>{
      //if(this.state.searchParm.length>-1){
                var value=this.state.searchParm;
                var len = this.state.buildingList.length;
                var count=0;
                var vflag=0;

                if(value!=''){
                    this.state.buildingList.forEach(function(building,index) {
                        if(building.building_address.trim().toLowerCase().includes(value.trim().toLowerCase())){
                                building.flag=1;
                                vflag=1;
                                count=count+1;  
                            }else{
                                building.flag=0;
                                if(vflag!=1)
                                vflag=0
                                count=count+1;
                            }
                        }); 
                }else{
                  //document.getElementById('building_search_id_values').style.display = 'none';
                    this.state.buildingList.forEach(function(building,index) {
                        building.flag=0;
                    });
                    
                }
                if(len==count){
                  if(vflag==0){
                    this.setState({searchMsg:true});
                    document.getElementById('building_search_id_values').style.display = 'block';
                  }else{
                    this.setState({searchMsg:false});
                    
                  }
                }
            })       
                //}
    break;
    case 'searchkey':
    var value=event.target.value;
                let $this = this;
      this.setState({ agent_id: event.target.value}, () => {
        this.state.buildingList.forEach(function(building,index) {
          if(building.id==value){
            console.log("--in if-bulding_id",building.id);
            console.log("--in -value",value);
              document.getElementById('building_search_id_values').style.display = "none";
                        document.getElementById('building_address').value=building.building_address;
                        //document.getElementById('neighborhood').value=building.neighborhood;
                        $this.setState({building_address:building.building_address});
                        $this.setState({neighborhood:building.neighborhood});
                        $this.setState({building_name:building.name});
                        $this.setState({management_name:building.building_ownership});
                        $this.setState({city:building.city});
                        $this.setState({state:building.state});
                        $this.setState({postal_code:building.postal_code});
                        $this.setState({lat:building.latitude});
                        $this.setState({long:building.longitude});
                        // document.getElementById("city").readOnly = true;
                        // document.getElementById("state").readOnly = true;
                        // document.getElementById("postal_code").readOnly = true;
                        // document.getElementById("lat").readOnly = true;
                        // document.getElementById("long").readOnly = true;
                        
                       
          }else{
            building.flag=0;
            console.log("in else", building.flag);
          }
        });
          
        document.getElementById('building_search_id_values').style.display = "none";
        });
    break;
    case 'default':
    break;
     
    }
    
    document.getElementById('building_search_id_values').style.display = "block";
}

OpenList() {
  var x = document.getElementById("documentList");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

OpenSList() {
  var x = document.getElementById("ScreeningsList");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

 getNeighborhood(){
  let buildingParam = [{
          url: "/showings/getNeighborhood"
  }];
  let data = {
    'lat':this.state.lat,
    'long':this.state.long,
  };
  postApiData(buildingParam, data,true).then(res => {
    console.log("res.data neigh",res.data);
      this.setState({'neighborhood':res.data.neighborhood,myerrormsg:''});
      }).catch(error => {
      if(error.response){
          this.setState({myerrormsg: error.response.data.error.message});
      }
      });
  }
  
  getZipFromAddress(address) 
 {
   let _address_=address.split(',')
    let where = [{
     url: '/showings/getlatlongbyaddress'
     }];
     var method = 'post';
     let data = {'address':address};
    postApiData(where, data, method,true).then(res => {
      console.log("res.data",res.data);
         if (res.data){
           this.setState({
             postal_code:res.data.zip,
             lat:res.data.lat,
             long:res.data.long,
             myerrormsg:'',
             city:(res.data.complete_data.results[0].address_components[2].short_name!=undefined)?res.data.complete_data.results[0].address_components[2].short_name:'',
             state:(res.data.complete_data.results[0].address_components[5].short_name!=undefined)?res.data.complete_data.results[0].address_components[5].short_name:'',
             building_address:address.substring(0,address.lastIndexOf(','))+", "+res.data.zip
            });
            if(this.state.postal_code && this.state.lat && this.state.long){
              this.setState({
                postal_code_error:"",
                lat_error:"",
                long_error:""              
               });
            }
         }
     }).then(()=>{
      this.getNeighborhood()
     }).catch(error => {
this.setState({
  myerrormsg:error.response.data.error.message
})
     console.log('in catch', error);
     });

}

  onblurforstateChange=()=>{
 var addressVal=this.state.building_address+','+this.state.city+','+this.state.state;

    this.getZipFromAddress(addressVal);
  }


  enableGoogleSearch=()=>{
    this.setState({
      enableGoogleSearch:true
    })
  }

  setGoogleResponse=(event)=>{
    console.log("event",event);
    this.getZipFromAddress(event);
    
  }

  buildingChange = building_address => {
    this.setState({ building_address:building_address });
  };


    render() {
      //console.log("this.state.addDocValue",this.state.addDocValue)
      let ht=<span onClick={this.enableGoogleSearch}>No suggestions available.Click Here to search using google</span>;
      let buildingDW='';
      buildingDW = this.state.buildingList.map((aw,index) => {
          return (aw.flag==1 && aw.id>0) ? <li id="searchkey" key={aw.id} value={aw.id} name={aw.building_address} onClick={this.handleBuildingSearch}>{aw.building_address}</li>:''
        }
        );
        //console.log("this.state.addDocValue.toString().split(',')",this.state.addDocValue.toString().split(','))
        //console.log("this.state.addDocValueName.toString().split(',')",this.state.addDocValueName.toString().split(','))
        let checkselectedvalue=(this.state.addDocValue!='')?this.state.addDocValue.toString().split(','):[];
        const required_additional_documents_list = this.state._required_additional_documents_.map((list,index) => {
         return (<div> 
           <span>
           <input type="checkbox" id={`box-`+index} checked={checkselectedvalue.find((ch)=>ch==list.id)} onClick={this.handleAddDoc([list.document_name,list.id])}/> 
           <label for={`box-`+index}>{list.document_name}</label>
           </span>
        </div>)
        });
      
         
      
      
        const { userType} = this.props;
   if(this.props.userType == 'Manager'){
      
    } 
    const {
        building_address,
        building_name,
        management_name,
        management_email,
        management_phone,
        required_additional_documents,
        default_application_template,
        screenings_required,
        building_notes,
      
    
        building_address_error,
        management_name_error,
        management_email_error,
        management_phone_error,
        default_application_template_error,
       screenings_required_error,
       building_notes_error,
       city_error,state_error,postal_code_error,lat_error,long_error,
       data,
      
      } = this.state;

      let document_name;
      let application_template;
      let screening_name;
      let Building_amenities;
    
      
      if(this.state.flag == 1){
         document_name = data[0].map((value)=>{
          return <option value={value.document_name}>{value.document_name}</option>
        })
        application_template = data[1].map((value)=>{
          return  <option value={value.application_template}>{value.application_template}</option>
        })
        screening_name = data[2].map((value)=>{
          return  <option value={value.screening_name}>{value.screening_name}</option>
        })
        Building_amenities = data[3].map((value)=>{
          if(value.type == 0){
            return <div className="checkbox-inline">
                  <input
                    type="checkbox"
                    id={value.amenity_name}
                    value={value.amenity_name}
                  />
                  <label htmlFor={value.amenity_name}>{value.amenity_name}</label>
                </div>
          }
        });
        
      }

      //console.log("this.state.searchmsg",this.state.searchMsg)
        return (
            <div onclick="myFunction()">

                {userType == 'Manager' ? <ManagerHeader /> : <AgentHeader />}
                <main>
                    {userType == 'Manager' ? <ManagerSidebar /> : <AgentSidebar />}
                    <div className="content">
                        <div className="work-section">
                            <div className="dashboard" >
                                {/* <h2>Listing Creator</h2> */}
                            
                                <div className="rental-application listing-creator-section">
                                {(this.state.myerrormsg!='')?<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>:(null)}
                                {this.state.successQuote?(<div className="alert alert-success"><strong>Building Created Successfully!</strong></div>):(null)}
                                    <div className="listing-block">
                                    {/*userType =='Manager' ?(  <a href="javascript:window.history.back();" className="btn btn-default pull-right">Back</a>) : (null)*/}
                                 
                                        <h4>Add a new building</h4>
                                        <form  onSubmit={this.handleSubmit}>

                                            <div className="row clearfix">
                                                <div className="col-sm-4" style={{display:(!this.state.enableGoogleSearch)?'':'none'}}>
                                                    <label>Building Address*</label>
                                                    <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="building_address" 
                                                        autoComplete="new-password"
                                                        onChange={this.handleBuildingSearch } onKeyDownCapture={this.handleBuildingSearch} onKeyUpCapture={this.handleBuildingSearch} value={(this.state.building_address!=0)?this.state.building_address:''}
                                                    />
                                                <div className="box agentNameOnBoard" id="building_search_id_values" style={{display:'none'}} >
                                              <ul>{buildingDW}</ul>
                                              {(this.state.searchMsg)?ht:''}
                                              {/* {(this.state.searchMsg)?'No suggestions available':''} */}
                                              </div>
                                                        <span className="inline-error-class">
                                                            {" "}
                                                            {building_address_error !== ""
                                                                ? building_address_error
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="col-sm-4" style={{display:(this.state.enableGoogleSearch)?'':'none'}}>
                                                    <label>Building Address*</label>
                                                    <div className="form-group">
    <PlacesAutocomplete
        value={(this.state.building_address!=0)?this.state.building_address:''}
        id="building_address" 
        onChange={this.buildingChange }
        onKeyDownCapture={this.buildingChange}
        onKeyUpCapture={this.buildingChange}
        onSelect={this.setGoogleResponse}
        searchOptions={searchOptions}
        maxLength={50}
        autoComplete="new-password" 
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: '',
                className: 'form-control'
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {

                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{
                    suggestion.description.substring(0,suggestion.description.lastIndexOf(','))
                    }
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                                                   </div>
                                                </div>





                                                  <div className="col-sm-4">
                                                    <label>City*</label>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="city"
                                                            value={this.state.city}
                                                            onChange={this.handleChange} 
                                                            readOnly={true}                                
                                                              />
                                                              <span className="inline-error-class">
                                                            {" "}
                                                            {city_error !== ""
                                                                ? city_error
                                                                : ""}
                                                        </span>
                                                            </div>
                                                         </div>
                                                <div className="col-sm-4">
                                                    <label>State*</label>
                                                     <div className="form-group">
                                                            <input
                                                            type="text"
                                                            className="form-control"
                                                            id="state"
                                                            value={this.state.state}
                                                            onChange={this.handleChange} 
                                                            onBlur={this.onblurforstateChange} 
                                                            readOnly={true}      
                                                            />
                                                           <span className="inline-error-class">
                                                            {" "}
                                                            {state_error !== ""
                                                                ? state_error
                                                                : ""}
                                                        </span> 
                                                            </div>
                                                            </div>
                                                   <div className="col-sm-4">
                                                    <label>Zip Code*</label>
                                                     <div className="form-group">
                                                            <input
                                                            type="text"
                                                            className="form-control"
                                                            id="postal_code"
                                                            value={this.state.postal_code}
                                                            onChange={this.handleChange}  
                                                            maxLength = {5}
                                                            readOnly={true}      
                                                            />
                                                             <span className="inline-error-class">
                                                            {" "}
                                                            {postal_code_error !== ""
                                                                ? postal_code_error
                                                                : ""}
                                                        </span> 
                                                            </div>
                                                            </div>
                                                   <div className="col-sm-4">
                                                    <label>Latitude*</label>
                                                     <div className="form-group">
                                                             <input
                                                            type="text"
                                                            className="form-control"
                                                            id="lat"
                                                            value={this.state.lat}
                                                            onChange={this.handleChange}  
                                                            readOnly={true}      
                                                            />
                                                             <span className="inline-error-class">
                                                            {" "}
                                                            {lat_error !== ""
                                                                ? lat_error
                                                                : ""}
                                                        </span> 
                                                            </div></div>
                                                  <div className="col-sm-4">
                                                    <label>Longitude*</label>
                                                     <div className="form-group">
                                                             <input
                                                            type="text"
                                                            className="form-control"
                                                            id="long"
                                                            value={this.state.long}
                                                            onChange={this.handleChange}  
                                                            readOnly={true}      
                                                            />
                                                             <span className="inline-error-class">
                                                            {" "}
                                                            {long_error !== ""
                                                                ? long_error
                                                                : ""}
                                                        </span> 
                                                            <input
                                                            type="hidden"
                                                            className="form-control"
                                                            id="neighborhood"
                                                            value={this.state.neighborhood}
                                                            />
                                                            <input
                                                            type="hidden"
                                                            className="form-control"
                                                            id="listing_id"
                                                            value={this.state.listing_id}
                                                            />
                                                            
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label>Building name</label>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="building_name"
                                                            value={building_name}
                                                            onChange={this.handleChange}
                                                        />
                                                        </div>
                                                        </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-sm-4">
                                                    <label>Management name*</label>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="management_name"
                                                            value={management_name}
                                                            onChange={this.handleChange}
                                                        />
                                                        <span className="inline-error-class">
                                                            {" "}
                                                            {management_name_error !== ""
                                                                ? management_name_error
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label>Management email*</label>
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="management_email"
                                                            value={management_email}
                                                            onChange={this.handleChange}
                                                        />
                                                        <span className="inline-error-class">
                                                            {" "}
                                                            {management_email_error !== ""
                                                                ? management_email_error
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label>Management phone*</label>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="management_phone"
                                                            value={management_phone}
                                                            onChange={this.handleChange}
                                                            maxLength="12"
                                                            placeholder="XXX-XXX-XXXX"
                                                        />
                                                        <span className="inline-error-class">
                                                            {" "}
                                                            {management_phone_error !== ""
                                                                ? management_phone_error
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-sm-4">
                                                    <label>Required additional documents</label>
                                                    <div className="form-group">
                                                    <div className="neighbor-filter list-filter" onClick={this.OpenList}> 
                                                        <span id="Firstcityname">{this.state.addDocString} </span> <span id="CityCount">{(this.state.addDocValue!='' && this.state.addDocValue.toString().split(',').length>1)? ' +'+(this.state.addDocValue.toString().split(',').length-1) +' more..':''}</span>
                                                    </div>
                                                    <div className="boxes" id="documentList" style={{display:'none'}} ref={this.setWrapperRef1}>
                                                       {required_additional_documents_list}
                                                   </div>

                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label>Default application template*</label>
                                                    <div className="form-group">
                                                    
                                                        <div className="neighbor-filter black">
                                                        
                                                            <select
                                                                id="default_application_template"
                                                                value={default_application_template}
                                                                onChange={this.handleChange}
                                                                className="form-control"
                                                            >
                                                                {application_template}
                                                            </select>
                                                        </div>
                                                        <span className="inline-error-class">
                                                            {" "}
                                                            {default_application_template_error !== ""
                                                                ? default_application_template_error
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="col-sm-4">
                                                    <label>Screenings required*</label>
                                                    <div className="form-group ">
                                                    <div className={(this.state.is_management==1)?'neighbor-filter list-filter disableClick':'neighbor-filter list-filter'} onClick={this.OpenSList}> 
                                                        <span id="Firstcityname">{this.state.screeningString} </span> <span id="CityCount">{(this.state.screeningValue!='' && this.state.screeningValue.split(',').length>1)? ' +'+(this.state.screeningValue.split(',').length-1) +' More..':''}</span>
                                                    </div>
                                                    <div className="boxes" id="ScreeningsList" style={{display:'none'}} ref={this.setWrapperRef2}>
                                                       <span><input type="checkbox" id="box-5" onClick={this.handleScreening} value="Credit"/> <label for="box-5">Credit</label></span>
                                                       {/* <span><input type="checkbox" id="box-6" onClick={this.handleScreening} value="Criminal"/> <label for="box-6">Criminal</label></span>
                                                       <span><input type="checkbox" id="box-7" onClick={this.handleScreening} value="Eviction"/> <label for="box-7">Eviction</label></span> */}
                                                   </div>
                                                  

                                                        {/* <div className="neighbor-filter black">
                                                            <select
                                                                id="screenings_required"
                                                                value={screenings_required}
                                                                onChange={this.handleChange}
                                                            >
                                                                {screening_name}
                                                            </select>
      </div>*/}
                                                        <span className="inline-error-class">
                                                            {" "}
                                                            {screenings_required_error !== ""
                                                                ? screenings_required_error
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row clearfix">
                                                 
                                            <div className="col-sm-12 text-right" style={{marginRight:'20px'}}> 
                                            <div className="check-block">
                                              <div className="form-group">
                                            <div className="checkbox-inline">
                                              <input
                                                type="radio"
                                                id="is_skyward_runs_credit"
                                                value="is_skyward_runs_credit"
                                                onClick={this.handleScreening}
                                              />
                                              <label htmlFor="is_skyward_runs_credit">Skyward Runs Credit</label>
                                            </div>

                                            <div className="checkbox-inline">
                                              <input
                                                type="radio"
                                                id="is_management"
                                                value="is_management"
                                                onClick={this.handleScreening}
                                              />
                                              <label htmlFor="is_management">Management Runs Credit</label>
                                            </div>
                                            
                                            
                                            </div>



                                                  
                                                   
                                                 </div>
                                            <div></div></div>
                                                <div className="col-sm-12">
                                                    <label>Building Notes</label>
                                                    <div className="form-group">
                                                    <textarea
                                                        type="text"
                                                        id="building_notes"
                                                        onChange={this.buildingNotesChange} 
                                                        autoComplete="off" 
                                                        style={{width:'98%'}}
                                                        value={building_notes}
                                                    />
                                                {/* <div className="box agentNameOnBoard" id="building_search_id_values" style={{display:'none'}} > */}
                                                        <span className="inline-error-class">
                                                            {" "}
                                                            {building_notes_error !== ""
                                                                ? building_notes_error
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </div>


                                                {/* ........................................ */}
                                                <div className="container" style={{margin:'0px'}}>
                                                    <h6>Building amenities</h6>
                                                    <div className="check-block">
                                                        <div
                                                            className="form-group"
                                                            onChange={this.handleChange}
                                                        >
                                                
                                                            {Building_amenities}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                                <button
                                                    type="submit"
                                                    disabled={!this.validation()}
                                                    className="btn btn-default"
                                                    style={{ margin: "10px" }}
                                                >
                                                    Add new building 
                                              </button>
                                            </div>

                                        </form>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </main>


            </div>
        );
    }
}
export default AddBuildings;
