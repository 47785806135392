import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import {getApiData, postApiData, checkAdminToken,getBaseUrl,getAdminToken} from '../../services/Api';
import {validatePhone} from '../../services/validation';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import CryptoJS from "crypto-js";
import DragSortableList from 'react-drag-sortable';
import { isNullOrUndefined } from 'util';
let sortedListVar;

class Agent extends Component {
    constructor(props){
        super(props);
        this.state={
            agentList: [],
            routeflag:true,
            agentId:'',
            agentDetail:[],

            agentEditPanel: false,
            pageLoader: false,
            successQuote: false,
            //Errors
            phone_number_error:'',
            office_number_error:'',
            title_error: '',

            //Agent Details
            name:"",
            status:"",
            title:"",
            description:"",
            email:"",
            id:"",
            image:"",
            mob_number:"",
            office_number:'',
            mymsg:''
        };
    }

    componentWillMount =()=>{
        this.getAgentList();
       
    }

    handleChange=(e)=>{
        switch(e.target.id){
            case 'mob_number': 
            this.formValidate(e.target.id, e.target.value);
            break;
            case 'title':
            if(e.target.value == 0){
                this.setState({ title_error : 'Field cannot be empty'})
            }else{
                this.setState({ title_error : ''})
            }
        }
      this.setState({
          [e.target.id] : e.target.value
      })
    }

    formValidate(key,value){
    switch(key){
        case 'mob_number':
        this.validateEmpPhoneNumber(value);
        break;
          
        }
    }
    validateEmpPhoneNumber(value){
        let res=validatePhone(value);
                    if(res.status==false){
                        this.setState({phone_number_error: 'Phone number is not valid.'});
                        document.getElementById('mob_number').value=res.value;
                    }else{
                        this.setState({phone_number_error: ''});
                        document.getElementById('mob_number').value=res.value;
                    }
                return res.status;
    } 

    handlechangeoffice=(e)=>{
        switch(e.target.id){
            
            case 'office_number':
            this.formValidateoffice(e.target.id,e.target.value);
            break;
            case 'title':
            
        }
      this.setState({
          [e.target.id] : e.target.value
      })
    }
    formValidateoffice(key,value){
        switch(key){
            
            case 'office_number':
            this.validateEmpofficeNumber(value);    
            }
        }

        validateEmpofficeNumber(value){
            let res=validatePhone(value);
                        if(res.status==false){
                            this.setState({office_number_error: 'Office number is not valid.'});
                            document.getElementById('office_number').value=res.value;
                        }else{
                            this.setState({office_number_error: ''});
                            document.getElementById('office_number').value=res.value;
                        }
                    return res.status;
        } 

    validateForm() {
        return (this.state.phone_number_error == '' && this.state.mob_number != '' && this.state.title_error == '' && this.state.mymsg =='' && this.state.office_number_error == '') 
    }
    agentRowClick=(agentId)=>{
       // var agentId = e.target.getAttribute('id')
        this.getSpecificAgentDetail(agentId)
        this.setState({agentEditPanel:true})
    }

    getSpecificAgentDetail=(agentId)=>{
        this.setState({'pageLoader':true});
        let whereEditor = [{
			url: "/admin/agentDetail",
			where:{
				'id':agentId
			}
		}];
		getApiData(whereEditor,true).then(res => {
          var data = res.data[0];
          let key = encHex.parse("0123456789abcdef0123456789abcdef");
          let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
          var decrypt_email=aes.decrypt(data.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)
          var decrypt_mobile;
          if(data.mob_number!='NA' && data.mob_number!=null && data.mob_number!=''){
            decrypt_mobile=aes.decrypt(data.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)
          }
          else{
            decrypt_mobile='';
          }
          var decrypt_office;
          if(data.office_phone!='NA' && data.office_phone!=null && data.office_phone!=''){
            decrypt_office=aes.decrypt(data.office_phone, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)
          }
          else{
            decrypt_office='';
          }
          
            this.setState({
                agentDetail: res.data,
                name: data.name,
                status: data.status,
                title: data.title,
                description: data.description,
                email: decrypt_email,
                id: data.id,
                 image: data.image,
                mob_number: decrypt_mobile,
                office_number:decrypt_office
            },()=>{
                this.setState({
                    'pageLoader':false,
                })
            })
		}).catch(error => {
				if(error.res){
					this.setState({myerrormsg: error.res.data});
				}
			});
    }
    
    //Handling insertion of Agent Detail
    handleAgentSubmit=(e)=>{
        
        const {id, title,  mob_number, office_number, description , image} = this.state;
        e.preventDefault();
        this.addAgentDetail(id, title,  mob_number, office_number,description , image)
    }

    async addAgentDetail(id, title, mob_number, office_number, description, image) {
        let where = [{
                url: `/admin/addAgentDetail/${id}`
            }];
            let key = encHex.parse("0123456789abcdef0123456789abcdef");
            let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
            mob_number=aes.encrypt(mob_number, key, {iv:iv, padding:padZeroPadding}).toString();
            let office_phone;
            if(office_number!='' && office_number!=undefined){
                office_phone=aes.encrypt(office_number, key, {iv:iv, padding:padZeroPadding}).toString();
            }
            else{
                office_phone='NA';
            }
            

            postApiData(where, {"title": title, 'mob_number': mob_number, 'office_phone':office_phone, "description":escape(description)  , },true)
            .then(res => {
                if(res.data)
                {
                    let where = [{
                        url: "/containers/agent_image/upload",
                    }];
                    const formData = new FormData();
                    formData.append('file',this.state.selectedFile)
                    postApiData(where,formData,'post',true).then(response => {
                      //  var imgsrc=getBaseUrl()+"/containers/agent_image/upload/"+this.state.selectedFile;       
                          console.log('response.data==>',response.data)   
                        if(response.data)
                        {
                            let where = [{
                                url: `/admin/agent/image/${id}`
                            }];
                            postApiData(where, {'image':response.data.file_name},true)
                            .then(imageres => {
                            })
                        }
                       
                    })
                }
                        this.setState({ 'pageLoader':true},()=>{
                            this.setState({'pageLoader':false, "successQuote":true, });
                        })  
        }).catch(error => {
           this.setState({myerrormsg: error.response});
        });
    }

// .........................................
    //GEt agent list from DB
    getAgentList=()=> {
        let applicationList = [{
            url: "/admin/agentList",
        }];
        getApiData(applicationList,true).then(res => {
        this.setState({'agentList': res.data,'pageLoader':true},()=>{
                this.setState({'pageLoader':false});
        })
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
    }
    backToAgentPanel=()=>{
        this.getAgentList()
        this.setState({
            pageLoader: true
        },()=>{
            this.setState({  pageLoader: false, agentEditPanel: false, successQuote :false, mymsg:'' })
        })
    }

     //image upload
     fileChangedHandler = (event) => {
        this.setState({mymsg:''})
        if(event.target.files[0]){
         let imgFlag = true;
        const maxFileSize = 5242880;   //5mb
        const value = event.target.files[0].name;
        const ext = value.substring(value.lastIndexOf('.'));
        if(!['.jpg','.jpeg','.JPG','.png','.PNG'].includes(ext)){
            this.setState({mymsg: ext+' is not supported file extension.'});
            imgFlag = false;
        }
        if(event.target.files[0].size>maxFileSize){
            this.setState({mymsg:"File size is too big, upto 5MB allowed."})
            imgFlag = false;
            }
        if(imgFlag) { 
             this.setState({selectedFile:event.target.files[0] })
        }
     }            
    }
    onSort =(sortedList, dropEvent)=> {
        console.log("sortedList", sortedList);
        sortedListVar = sortedList;
    
         if( typeof sortedListVar != 'undefined' && sortedListVar instanceof Array ){
             console.log("in saveListOrder--", sortedListVar);
            console.log("length--", sortedListVar.length);
             let tempArr = [];
            
    
    
             if (sortedListVar.length > 0) {
                
                 sortedListVar.map((item,i) => {
                
                     if(!isNullOrUndefined(item.rank)){
                         tempArr[i] = item.content.key+'-'+item.rank;
                         //tempRank[i]= item.rank;
                        
                         console.log('item.content.key',item.content.key+'-'+item.rank)
                        
                     }
                    
                 })
        const formData = new FormData();
         formData.append('file',tempArr);
         formData.append('dbTable','agents');
    
         let listingWhere=[{
             url:'/admin/agentOrderUpdate'
         }];
        
         postApiData(listingWhere,formData,true).then(
        
         )
    
             }
        
         }
    
    }     
    render() {
        var placeholder = (
            <div style={{backgroundColor:'lightpink',height:'54px'}}> DROP HERE ! </div>
        ); 
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        const {agentEditPanel, id, name, title, image, mob_number,office_number, description, status } = this.state;
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv =  encHex.parse("abcdef9876543210abcdef9876543210");

        var listItems = this.state.agentList.map((item, index)=>{
            return{
                content: (
            <tr key={item.id} >
                    <td style={{width:'150px'}}>{item.image.startsWith("http://")?<img alt="Profile"  id={item.id} src={item.image} width="40" className="user rounded-circle"/>:<img alt="Profile"  id={item.id} src={getBaseUrl()+"/containers/agent_image/download/"+item.image} width="40" className="user rounded-circle"/>}</td>
                    <td  style={{width:'300px'}}>{item.name}&nbsp;&nbsp;<Link to onClick={()=>this.agentRowClick([item.id])} style={{cursor:'pointer'}}><i className="fa fa-pencil text-black "  aria-hidden="true"></i></Link></td>
                    <td  style={{width:'200px',textAlign:'left'}}>{aes.decrypt(item.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</td>
                    <td  style={{width:'341px',textAlign:'center'}}>{item.title}</td>
                </tr>
                )
            }
        });
      
        return (

            <div >
                {(!this.state.pageLoader==false)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                        <h2 className="pull-left">Agent</h2>
                        {agentEditPanel &&  <button onClick={this.backToAgentPanel} className="btn btn-default pull-right ">Back</button>}
                        <div className="panel-body table-responsive">
                        {!agentEditPanel ? 
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="managerGridCol">Picture <span style={{paddingLeft:'150px'}}>Name</span><span style={{paddingLeft:'250px'}}>Email</span><span style={{paddingLeft:'300px'}}>Title</span></th> 
                                        {/* <th className="managerGridCol">Name</th> 
                                        <th className="managerGridCol">Email</th> 
                                        <th className="managerGridCol">Title</th>  */}
                                    </tr>
                                </thead>
                                <tbody>
                                <DragSortableList items={listItems}  placeholder={placeholder}  onSort={this.onSort} dropBackTransitionDuration={0.3} type="vertical" />
                                    {/* {listItems} */}
                                </tbody>
                                </table>
                        
                        :
                        <Fragment>
                        <table className="table table-striped">
                            <thead><tr><th className="managerGridCol">Edit Agent Details</th></tr></thead>
                        </table>

                        <form onSubmit={this.handleAgentSubmit} style={{marginTop: "10px"}}>
                        {this.state.successQuote?(<div className="alert alert-success"><strong>Agent Details Successfully Added</strong></div>):(null)}
                        {this.state.mymsg !=''?(<div className="alert alert-danger"><strong>{this.state.mymsg}</strong></div>):(null)}
                        <div className="form-group">
                            <label forhtml="name">Name</label>
                            <input type="text" id="name" className="form-control" disabled placeholder={name} />
                        </div>
                        <div className="form-group">
                            <label forhtml="title">Title</label>
                            <input type="text" id="title" className="form-control" onChange={this.handleChange} value={title} placeholder="Title" />
                            <span className="inline-error-class"> {this.state.title_error !==''?this.state.title_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="mob_number">Mobile Number</label>
                            <input type="text"  id="mob_number" className="form-control" onChange={this.handleChange} value={mob_number == 0? '': mob_number} maxLength='12' placeholder="XXX-XXX-XXXX" />
                            <span className="inline-error-class"> {this.state.phone_number_error !==''?this.state.phone_number_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="mob_number">Office Number</label>
                            <input type="text"  id="office_number" className="form-control" onChange={this.handlechangeoffice} value={office_number == 0? '': office_number} maxLength='12' placeholder="XXX-XXX-XXXX" />
                            <span className="inline-error-class"> {this.state.office_number_error !==''?this.state.office_number_error:''}</span>
                        </div>
                        <div className="form-group">
                            <label forhtml="description">Description</label>
                            <textarea className="form-control" id="description" style={{height:'200px'}} onChange={this.handleChange} value={unescape(description)} placeholder="Description"></textarea>
                        </div>
                        <div className="form-group">
                        <label forhtml="profile_image">Profile Image</label> <br></br>
                      <input type ="file" onChange={this.fileChangedHandler} />
                        </div>

                             <button type="submit" className="btn btn-primary" disabled={!this.validateForm()}>Submit</button>
                        </form>
                        </Fragment>

                        // ........................................................
                        }
                            </div>
                    </div>
                    
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default Agent;