import React from "react";
import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import {getApiData, getToken, removeToken} from '../../services/Api';

class Logout extends React.Component {
    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}
class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            name: "",
            logout: false
        };
        this.onLogout = this.onLogout.bind(this);
    }

    componentDidMount() {
        this.onLogout();
    }

    async onLogout() {
        let where = [{
                url: "/managers/auth/logout"
            }];
        
        getApiData(where,true).then(res => {
            this.setState({logout: true});
            localStorage.removeItem('manager_token');
            localStorage.removeItem('managerImageUrl');
            localStorage.removeItem('manager_name');
            // localStorage.clear();
            //this.props.history.push('/manager/landing'); 
        })
                .catch(error => {
                    console.log(error);
                });
    }

    render() {
        if (this.state.logout == true) {
           return (<Redirect to={'/manager/landing'}/>);
        }
        return (
                <div>Logged out</div>
                );
    }
}

export default withRouter(Logout);
