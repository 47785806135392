import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import Header from '../common/Header';
import Disclosure from '../common/Disclosure';
import { getToken, getOnBoardingDone, getUserId, getApiData, getBaseUrl, setOnboardingData, getOnboardingData, postApiData } from '../../services/Api';
import { Redirect } from 'react-router';
import { clientApllicationStatus, clientShowingStatus } from '../../services/Const';
import Modal from 'react-responsive-modal';
import { getFormatDateMDY } from '../../services/Util';
import ClientAppDetail from './application/Detail';
import OnboardingMiddleware from '../../middleware/Onboarding';
import addDays from "date-fns/add_days";
import set_hours from "date-fns/set_hours";
import set_minutes from "date-fns/set_minutes";
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import ViewClientDoc from '../common/Viewclientdoc';
import ViewClientDisclouser from '../common/Viewclientdisclouser'
class Dashboard extends React.Component {
    render() {
        return (
            <div>
                <Content contentProp={this.props.content} />
            </div>
        );
    }
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            linkingLoader: false,
            isOnboardingDone: null,
            listData: [],
            open: false,
            openDisc: false,
            openChild: false,
            isAppDataExist: '',
            appDetail: [],
            onBoarding: [],
            loader_status: false,
            isShowingDataExist: false,
            isUnconfShowingExist: false,
            showingData: [],
            showingUnconfData: [],
            time: '',
            startDate: new Date(),
            flagReschedule: true,
            s_status: '',
            showingId: '',
            s_showingDate: '',
            s_showingTime: '',
            s_showinAddress: '',
            s_showingUnit: '',
            s_clientName: '',
            s_clientEmail: '',
            Assignflag: '',
            s_agentName: '',
            s_agentEmail: '',
            account_verify: 0,
            client_Document: [],
            uploaded_doc_error: '',
            uploaded_doc_id: '',
            openViewer: false,
            openDisViewer: false,
            uploaded_doc_name: '',
            client_uploaded_file: '',
            add_doc_error: '',
            img_flag: false,
            discouser_url: '',

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
    }
    componentWillMount() {
        this.getApplicationList();
        this.downloadDiscloserFee();
        this.getShowingList();
        this.getUnconfShowingList();
        this.getEmailVerified();
        this.getclientDocument();

        this.state.account_verify = localStorage.getItem('account_verified');

    }
    componentWillUnmount() {
        //                this.getApplicationList();
    }
    handleChange(date) {
        this.setState({
            time: date
        });
    }
    handleChangeDate(date) {
        this.setState({
            startDate: date
        });
    }
    redirectURI = param => e => {

    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    onOpenDiscModal = () => {
        this.setState({ openDisc: true });
    };

    onCloseDiscModal = () => {
        this.setState({ openDisc: false });
    };

    handleClick = param => e => {
        let where = [{
            url: "/applications/detail/" + param[0]
        }];
        getApiData(where, true).then(res => {
            if (res.data) {
                this.setState({ appDetail: res.data });
                this.onOpenModal();

            }
        });
    }
    handleDiscClick = param => e => {

        this.onOpenDiscModal();
        var b = getOnboardingData();
        this.setState({ onBoarding: b, loader_status: true });

    }
    Schudling = (event) => {
        var key = event.target.id;
        var divid = 'r' + key;
        var x = document.getElementById(divid);
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    async getApplicationList() {
        this.setState({ linkingLoader: true });
        let clientRelWhere = [{
            url: "/applications",
            where: { "where": { "clientId": getUserId() } }
        }];
        getApiData(clientRelWhere, true).then(res => {
            this.setState({ listData: res.data });
            if (res.data.length) {
                this.setState({ isAppDataExist: true });
                this.setState({ linkingLoader: false });
            }
        }).catch(error => {

        });
    }
    async getShowingList() {
        this.setState({ linkingLoader: true });
        let clientRelWhere = [{
            url: "/showings/" + getUserId()
        }];
        getApiData(clientRelWhere, true).then(res => {
            this.setState({ showingData: res.data });
            if (res.data.length > 0) {
                this.setState({ isShowingDataExist: true });
                this.setState({ linkingLoader: false });
            }
            else {
                this.setState({ isShowingDataExist: false });
            }
        }).catch(error => {
        });
    }
    async getUnconfShowingList() {
        this.setState({ linkingLoader: true });
        let clientRelWhere = [{
            url: "/showings/unconfirmed/" + getUserId()
        }];
        getApiData(clientRelWhere, true).then(res => {
            this.setState({ showingUnconfData: res.data });
            if (res.data.length > 0) {
                this.setState({ isUnconfShowingExist: true });
                this.setState({ linkingLoader: false });
            }
            else {
                this.setState({ isUnconfShowingExist: false });
            }
        }).catch(error => {
        });
    }
    async getEmailVerified() {
        this.setState({ linkingLoader: true });
        let clientRelWhere = [{
            url: "/Clients/" + getUserId()
        }];
        getApiData(clientRelWhere, true).then(res => {

            this.setState({ account_verify: res.data.emailVerified });
            this.state.account_verify = res.data.emailVerified;
            localStorage.setItem('account_verified', res.data.emailVerified);
            this.setState({ linkingLoader: false });
        }).catch(error => {
        });
    }

    resendEmailForEmailIdVerification = () => {
        var clientId = localStorage.getItem('client_id')
        let where = [{
            url: "/Clients/resend/token"
        }];
        postApiData(where, { client_id: clientId }).then(response => {
            this.setState({ resendMsg: response.data.success })
        })
    }

    getTimeDifference(sDate, status, agentName, agentMobile, showingId, sTime) {

        var startTime = Moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss');
        var endTime = Moment.utc(sDate).local().format('YYYY-MM-DD HH:mm:ss');
        console.log("start--", startTime);
        console.log("endTime--", endTime);

        var fromDate = parseInt(new Date(startTime).getTime() / 1000);
        var toDate = parseInt(new Date(endTime).getTime() / 1000);
        var timeDiff = (toDate - fromDate) / 3600;  // will give difference in hrs
        console.log("timeDiff", timeDiff);
        // var duration = Moment.duration(endTime.diff(startTime));
        // var hours = parseInt(duration.asHours());
        // var minutes = parseInt(duration.asMinutes())%60;
        // console.log (hours + ' hour and '+ minutes+' minutes.');

        if (timeDiff <= 1 && status == 1) {
            //status=>1 refer to confirmed
            this.setState({ agentName: agentName, agentMobile: agentMobile, flagReschedule: false });

        }
        else {
            console.log('in else');
            this.setState({ s_status: status, showingId: showingId, s_showingDate: sDate, s_showingTime: sTime, agentMobile: agentMobile });
        }
    }

    rescheduleShowing = () => {
        var rescheduleTime = Moment(this.state.time, ['HH:mm:ss']).format('HH:mm:ss');
        var tempStartDate = Moment(this.state.startDate, ['YYYY-MM-DD']).format('YYYY-MM-DD');
        var rescheduleDate = tempStartDate + ' ' + rescheduleTime;
        var showingId = this.state.showingId;

        var createdTime = Moment(new Date(), ['HH:mm:ss']).format('HH:mm:ss');
        var createdDate = Moment(new Date(), ['YYYY-MM-DD']).format('YYYY-MM-DD');
        createdDate = createdDate + ' ' + createdTime;


        if (this.state.s_status == 1) {
            let rescheduleShowing = [{
                url: "/showings/rescheduleShowing"

            }];
            var form = new FormData();
            form.append("rescheduled_time", rescheduleTime);
            form.append("rescheduled_date", rescheduleDate);
            form.append("id", showingId);
            form.append("status", this.state.s_status);
            form.append("created_date", createdDate);


            postApiData(rescheduleShowing, form, true).then(res => {
                console.log("response--", res);
                console.log("response--", res.data.message);
                document.getElementById("reSchudling").click();
                // this.updateTimeKitForReschedule(this.state.clientShowingId);
                this.getApplicationList();
                this.getShowingList();
                this.getUnconfShowingList();
            }).catch(error => {

            });
        }
        else {
            //create new showing

            let rescheduleShowing = [{
                url: "/showings/rescheduleShowing"

            }];
            var form = new FormData();
            form.append("rescheduled_time", rescheduleTime);
            form.append("rescheduled_date", rescheduleDate);
            form.append("id", showingId);
            form.append("status", this.state.s_status);
            form.append("created_date", createdDate);


            postApiData(rescheduleShowing, form, true).then(res => {
                console.log("response--", res);
                console.log("response--", res.data.message);
                document.getElementById("reSchudling").click();
                //this.updateTimeKitForReschedule(this.state.clientShowingId);
                this.getApplicationList();
                this.getShowingList();
                this.getUnconfShowingList();
            }).catch(error => {

            });

        }
    }


    cancelShowing() {

        if (this.state.s_status != 3) {
            let cancelShowing = [{
                url: "/showings/cancelShowing"

            }];
            var form = new FormData();
            form.append("status", '3');
            form.append("clientShowingId", this.state.clientShowingId);


            postApiData(cancelShowing, form, true).then(res => {
                console.log("response--", res);
                console.log("response--", res.data.message);
                this.sendEmailForCancellation();
                document.getElementById('cancelSchudling').click();
                // this.updateTimeKitForAction(this.state.clientShowingId);
                this.getApplicationList();
                this.getShowingList();
                this.getUnconfShowingList();
            }).catch(error => {

            });
        }
        else {
            alert("Already cancelled.");
        }
    }

    sendEmailForCancellation = () => {
        let showingDate = Moment(this.state.s_showingDate).format('dddd, MMMM Do');
        let showingTime = Moment(this.state.s_showingTime, ['h:mm:ss']).format('hh:mm A');
        let sendEmail = [{
            url: "/showings/sendEmailForCancellation"

        }];
        if (this.state.s_status != 1) {
            var form = new FormData();
            form.append("showingAddress", this.state.s_showinAddress);
            form.append("showingUnit", this.state.s_showingUnit);
            form.append("showingDate", showingDate);
            form.append("showingTime", showingTime);
            form.append("clientEmail", this.state.s_clientEmail);
            form.append("clientName", this.state.s_clientName);
            form.append("Assignflag", 'dashboardCancellation');
        }
        else {
            var form = new FormData();
            form.append("showingAddress", this.state.s_showinAddress);
            form.append("showingUnit", this.state.s_showingUnit);
            form.append("showingDate", showingDate);
            form.append("showingTime", showingTime);

            form.append("clientName", this.state.s_clientName);
            form.append("agentName", this.state.s_agentName);
            form.append("agentEmail", this.state.s_agentEmail);

            form.append("Assignflag", 'dashboardCancellationToAgent');
        }



        postApiData(sendEmail, form, true).then(res => {
            console.log("response--", res);
            console.log("response--", res.data.message);
            if (res.data.success) {
                this.setState({ mymsg: res.data.message });
            }
            else {
                this.setState({ myerrormsg: res.data.message });
            }

        }).catch(error => {
            this.setState({ myerrormsg: 'There is an error.' });
        });
    }

    setShowingAttr(shoiwngAddress, showingUnit, showing_date, showing_time, client_name, client_email, id, status, agent_name, agent_email) {
        console.log('--in setShowingAttr--');
        this.setState({
            s_showingDate: showing_date, s_showingTime: showing_time, s_showinAddress: shoiwngAddress,
            s_showingUnit: showingUnit, s_clientName: client_name, s_clientEmail: client_email, clientShowingId: id, s_status: status, s_agentName: agent_name,
            s_agentEmail: agent_email
        });
    }

    updateTimeKitForAction(clientShowingId) {

        let timeKitShowing = [{
            url: "/showings/updateShowing/" + clientShowingId + "/status"
        }];
        getApiData(timeKitShowing, true).then(res => {
            console.log("response---", res);
        }).catch(error => {
        });
    }

    updateTimeKitForReschedule(clientShowingId) {

        let timeKitShowing = [{
            url: "/showings/updateRescheduleShowing/" + clientShowingId
        }];
        getApiData(timeKitShowing, true).then(res => {
            console.log("response---", res);
        }).catch(error => {
        });

    }
    applyNow = (event) => {
        event.preventDefault();
        if (this.state.account_verify == 0) {
            let clientRelWhere = [{
                url: "/Clients/" + getUserId()
            }];
            getApiData(clientRelWhere, true).then(res => {
                this.setState({ account_verify: res.data.emailVerified });
                localStorage.setItem('account_verified', this.state.account_verify);
                if (res.data.emailVerified == 1) {
                    window.location.href = '/client/application/';
                }
            }).catch(error => {
            });

        }
        else {
            window.location.href = '/client/application/';
        }

    }
    preventKeystroke = (event) => {
        console.log("keycode---", event.keyCode);
        if (event.keyCode) {
            event.preventDefault();
        }
    }
    onOpenModal1 = () => {
        this.setState({ open: true });
    };

    onCloseModal1 = () => {
        this.setState({ open: false });
    };

    // -- start ticket 882 Client pre-application doc uploader----

    async getclientDocument() {

        let clientRelWhere = [{
            url: "/Clients/clientDocument/get_clientdocument/" + getUserId()
        }];
        getApiData(clientRelWhere, false).then(res => {
            if (res) {
                console.log('clientdocument', res.data)
                this.setState({ client_Document: res.data });

            }

        }).catch(error => {
        });
    }
    setuploadeddocid(doc_id) {

        this.setState({
            uploaded_doc_id: doc_id
        })
    }


    UploadedDocHandler = event => {
        // this.setState({ linkingLoader: true });
        if (event.target.files[0]) {
            let imgFlag = true;
            const maxFileSize = 10485760;   //10mb
            const value = event.target.files[0].name;
            const ext = value.substring(value.lastIndexOf('.')).toLowerCase();
            if (!['.jpg', '.jpeg', '.JPG', '.png', '.pdf', '.PNG'].includes(ext)) {
                this.setState({ add_doc_error: ext + ' is not supported file extension.' })
                imgFlag = false;
                this.setState({ linkingLoader: false });
            }
            if (event.target.files[0].size > maxFileSize) {
                this.setState({ add_doc_error: "File size is too big, upto 10MB allowed." })
                imgFlag = false;
                this.setState({ linkingLoader: false });
            }
            if (imgFlag) {
                this.setState({ uploaded_doc_error: "" })
                this.setState({ uploaded_doc_name: value, client_uploaded_file: event.target.files[0], img_flag: true, add_doc_error: '' })
                this.setState({ linkingLoader: false });
                //this.fileAddDocUpload(event.target.files[0],this.state.uploaded_doc_id);
            }
        }
    }

    fileAddDocUpload = (event) => {
        //this.setState({ linkingLoader: true });
        event.preventDefault();
        let where = [{
            url: '/containers/client-uploaded-doc/upload'
        }];
        const formData = new FormData();
        formData.append('file', this.state.client_uploaded_file);
        this.setState({ showLoader: true });

        postApiData(where, formData, 'post', true).then(res => {

            if (res.data.success) {
                console.log('res.data.file_name', res.data.file_name)
                let where = [{
                    url: "/Clients/upload/clientuploaddoc"
                }];

                postApiData(where, { 'uploadedFile': res.data.file_name, 'doc_id': this.state.uploaded_doc_id, 'client_id': getUserId() }, true).then(res_save => {
                    console.log("res_save=>", res_save);
                    if (res_save.data) {
                        document.getElementById('uploadDoc').click();
                        this.setState({ linkingLoader: false });
                        this.getclientDocument();
                        this.setState({
                            uploaded_doc_name: '',
                            client_uploaded_file: '',
                            img_flag: false
                        })

                        // window.location.reload();
                        //call a function to get and update image
                    }
                    else {
                        this.setState({ linkingLoader: false });
                        window.location.reload();
                    }


                }).catch(error => {
                    this.setState({ linkingLoader: false });
                    console.log('in catch', error);
                });

            }
        })
            .catch(error => {
                console.log(error);
                this.setState({ showLoader: false });
            });
    }
    downloadDiscloserFee = () => {
        this.setState({
            linkingLoader: true
        })
        let where = [{
            url: "/agent/clientDiscloser"
        }]

        postApiData(where, { 'id': localStorage.getItem('client_id') }, true).then(res => {
            this.setState({
                linkingLoader: false
            })
            console.log('--res', res);
            var fileName = res.data[0].discloser_file + '.pdf';
            var url = getBaseUrl() + "/containers/application_pdf/download/" + fileName + "";

            this.setState({ 'discouser_url': url });
        }).catch(error => {
            this.setState({
                linkingLoader: false
            })
        })
    }
    downloadDocument = (appId, clientId) => e => {
        console.log("---", appId, clientId);
        let where = [{
            url: "/managers/downloadDocument/" + appId + "/" + clientId
        }];
        getApiData(where, true).then(res => {
            // console.log("res----",res.data.Zipfile);
            var url = getBaseUrl() + "/containers/download_doc/download/" + res.data.Zipfile;
            // console.log("url",url);
            if (res.data.Zipfile) {
                window.open(url);
            }
            else {
                alert("Unable to create zip,try again later.");
            }
        }).catch(error => {

        })
    }

    deleteDocument(doc, file_path) {

        var r = window.confirm("Are you sure to delete the Uploaded document?");
        if (r == true) {
            this.setState({ linkingLoader: true });
            let where = [{
                url: "/Clients/upload/deleteuploadeddoc"
            }];

            postApiData(where, { 'id': doc, 'file_path': file_path, 'client_id': getUserId() }, true).then(res_save => {
                console.log("res_save=>", res_save);
                if (res_save.data) {
                    this.getclientDocument();
                    //call a function to get and update image
                }
                this.setState({ linkingLoader: false });
            })
        }
    }
    onOpenDisView = param => (e) => {

        this.setState({ disclouserData: param[0], openDisViewer: true });
    }
    onDisCloseView = () => {
        this.setState({ disclouserData: {}, openDisViewer: false }, () => {

        });
    };
    onOpenView = param => (e) => {
        this.setState({ listingData: param[0], openViewer: true });
    };

    onCloseView = () => {
        this.setState({ listingData: {}, openViewer: false }, () => {

        });
    };

    validate() {
        return (
            this.state.add_doc_error == ''
            && this.state.img_flag


        );
    }





    render() {

        if (getToken()) {
            const f = getOnBoardingDone();
            if (f == 0) {
                return (<Redirect to={'/client/onboarding'} />);
            } else {
            }
        } else {
            return (<Redirect to={'/client/login'} />);
        }
        var status = clientApllicationStatus();
        const { open, openDisc, openChild, isAppDataExist } = this.state;
        let listItems = this.state.listData.map((p) =>
            <div key={p.id} className="col-sm-6" onClick={(p.status == 2 || p.status == 3) ? this.handleClick([p.id]) : this.redirectURI(['/client/application'])}>
                <Link to={(p.status == 2 || p.status == 3) ? '#' : `/client/application/edit/${p.id}`}>
                    <div className="panel-body table-responsive dashboard-wrap">
                        <table className="table table-striped">
                            <thead><tr><th width="45%">
                                {p.apartment_address}, Unit {p.unit_number}

                            </th></tr></thead>
                            <tbody><tr><td>Status: {status[p.status]} {(p.status == 1) ? <Link to={`/client/application/edit/${p.id}`} className="btn btn-default small pull-right">Continue</Link> : ''}  </td></tr>
                                {
                                    p.payment_deposit ? ""
                                        : (<tr><td><Link to={`/client/makepayment/${p.id}`} className="btn btn-default pay-btn">Pay Deposit</Link></td></tr>)
                                }
                            </tbody>
                        </table></div>
                </Link>
            </div>
        );
        // var account_verify = localStorage.getItem('account_verified');

        var showingstatus = clientShowingStatus();
        const { isShowingDataExist, isUnconfShowingExist } = this.state;
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv = encHex.parse("abcdef9876543210abcdef9876543210");
        Moment.locale('en');

        let showingItems = this.state.showingData.map((s) =>
            <div key={s.id} className="col-sm-6"> <div className="panel-body table-responsive scheduling-section">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{(s.showingAddress) ? s.showingAddress : s.address_value}, {(s.showingUnit) ? s.showingUnit : s.unit_value}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{showingstatus[s.status]}
                                <strong>{Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A')}<br />
                                    {Moment(s.showing_date).format('dddd, MMMM Do')} </strong>
                                {s.agent_name ? `Contact: ${s.agent_name} ${aes.decrypt(s.agent_mobile, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)}` : ''}<br />
                                {s.agent_email ? `Email: ${aes.decrypt(s.agent_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)}` : <br />}



                                {s.status != 3 ? (<React.Fragment> <ul className="menu" id={`r${s.id}`} style={{ display: 'none' }}><li><Link to data-toggle="modal" onClick={() => { this.getTimeDifference(s.showing_date, s.status, s.agent_name, aes.decrypt(s.agent_mobile, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8), s.id, s.showing_time); this.setShowingAttr(s.showingAddress, s.showingUnit, s.showing_date, s.showing_time, s.client_name, aes.decrypt(s.client_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8), s.id, s.status, s.agent_name, aes.decrypt(s.agent_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)) }} data-target="#reSchudling" >Reschedule</Link></li>
                                    <li><Link to data-toggle="modal" onClick={() => { this.getTimeDifference(s.showing_date, s.status, s.agent_name, aes.decrypt(s.agent_mobile, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8), s.id, s.showing_time); this.setShowingAttr(s.showingAddress, s.showingUnit, s.showing_date, s.showing_time, s.client_name, aes.decrypt(s.client_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8), s.id, s.status, s.agent_name, aes.decrypt(s.agent_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)) }} data-target="#cancelSchudling">Cancel</Link></li> </ul>
                                    <div className="footnav" id={s.id} onClick={this.Schudling}>

                                    </div></React.Fragment>) : (null)}


                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        );
        let showingUnconfItems = this.state.showingUnconfData.map((s) =>
            <div key={s.id} className="col-sm-6"> <div className="panel-body table-responsive scheduling-section">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{(s.showingAddress) ? s.showingAddress : s.address_value}, {(s.showingUnit) ? s.showingUnit : s.unit_value}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{showingstatus[s.status]}
                                <strong>{Moment(s.showing_time, ['h:mm:ss']).format('hh:mm A')}<br />
                                    {Moment(s.showing_date).format('dddd, MMMM Do')} </strong>
                                {s.agent_name ? `Contact: ${s.agent_name} ${aes.decrypt(s.agent_mobile, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)}` : ''}<br />
                                {s.agent_email ? `Email: ${aes.decrypt(s.agent_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)}` : <br />}



                                {(s.agent_name != '' && s.agent_name != 'null' && s.agent_name != undefined) ? s.status != 3 ? (<React.Fragment> <ul className="menu" id={`r${s.id}`} style={{ display: 'none' }}><li><Link to data-toggle="modal" onClick={() => { this.getTimeDifference(s.showing_date, s.status, s.agent_name, aes.decrypt(s.agent_mobile, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8), s.id, s.showing_time); this.setShowingAttr(s.showingAddress, s.showingUnit, s.showing_date, s.showing_time, s.client_name, aes.decrypt(s.client_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8), s.id, s.status, s.agent_name, aes.decrypt(s.agent_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)) }} data-target="#reSchudling" >Reschedule</Link></li>
                                    <li><Link to data-toggle="modal" onClick={() => { this.getTimeDifference(s.showing_date, s.status, s.agent_name, aes.decrypt(s.agent_mobile, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8), s.id, s.showing_time); this.setShowingAttr(s.showingAddress, s.showingUnit, s.showing_date, s.showing_time, s.client_name, aes.decrypt(s.client_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8), s.id, s.status, s.agent_name, aes.decrypt(s.agent_email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)) }} data-target="#cancelSchudling">Cancel</Link></li> </ul>
                                    <div className="footnav" id={s.id} onClick={this.Schudling}>

                                    </div></React.Fragment>) : (null) : ''}


                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        );

        let clientdocument = this.state.client_Document.map((s, i) =>



            <tr>
                <td>{i + 1}</td>
                <td>{s.document_name}</td>
                <td>{s.doc_created_date ? Moment(s.doc_created_date).format('dddd, MMMM Do') : '-'}</td>

                {s.file_path ? <td class="action-view text-center"><Link to onClick={this.onOpenView([s])}><i class="fa fa-eye"></i></Link>
                    <a href={getBaseUrl() + "/containers/client-uploaded-doc/download/" + s.file_path + "/true"} target="_blank" download><i class="fa fa-download"></i></a>
                    <Link to onClick={() => this.deleteDocument(s.id, s.file_path)}><i class="fa fa-trash-o"></i></Link></td>
                    : <td class="action-view text-center"><Link to onClick={() => this.setuploadeddocid([s.id])} data-toggle="modal" data-target="#uploadDoc"><i class="fa fa-plus"></i></Link></td>}


            </tr>


        );

        return (
            <div>
                {(this.state.linkingLoader) ? <div className="show_loader_on_click" id="show_loader_on_click"></div> : ''}
                <Header />
                <main>

                    {this.state.resendMsg == true ? (<div className="alert alert-success"><strong>Account verification link Resend successfully</strong></div>) : (null)}
                    {this.state.account_verify == 0 ? (<div className="alert alert-danger"><strong>Please check your inbox to verify your email address. You must verify your email before applying for an apartment. Please check your spam or junk folder if you haven't received a verification email in the inbox.</strong><Link className="resendLink" to onClick={this.resendEmailForEmailIdVerification} ><u>Resend</u></Link></div>) : (null)}
                    <div className="container dashboard clientDashboard">
                        <h2>Scheduled Showings</h2>

                        <hr />
                        <div className="row clearfix">
                            {(isShowingDataExist) ? showingItems : <div className="col-12"><div >You don’t have any showings yet</div></div>}

                        </div>
                        <h2>Unconfirmed Showings</h2>

                        <hr />
                        <div className="row clearfix">
                            {(isUnconfShowingExist) ? showingUnconfItems : <div className="col-12"><div >You don’t have any unconfirmed showings yet</div></div>}

                        </div>
                        <h2>Applications</h2>
                        <hr />
                        <div className="clearfix row">
                            {(isAppDataExist) ? listItems : <div className="col-12"><div>You haven't started an application yet</div></div>}
                        </div>
                        <div className="clearfix btn-row">
                            <div className="pull-left"><button onClick={this.applyNow} className="btn btn-default">Apply Now</button>
                                <Link to={`/client/paydeposit`} className="btn btn-default pay-btn" style={{ marginLeft: '10px' }}>Pay Deposit</Link>

                                {(this.state.account_verify != 0) ? <Link to className="btn btn-default pay-btn" style={{ marginLeft: '10px' }} data-toggle="modal" data-target="#docTable">Upload Docs</Link> : <button className="btn btn-default pay-btn" style={{ marginLeft: '10px' }} disabled="true">Upload Docs</button>}

                            </div>
                            <div className="pull-right">

                                <button onClick={this.onOpenDisView([this.state.discouser_url])} className="btn btn-default">View Agency & Fee Disclosure</button>


                            </div>
                        </div>
                    </div>

                    <Modal open={this.state.openDisViewer} onCloseEdit={this.onDisCloseView} classNames="modal-sm">
                        <ViewClientDisclouser disclouserData={this.state.discouser_url} onClose={this.onDisCloseView} />
                    </Modal>

                    <Modal open={openDisc} onClose={this.onCloseDiscModal} center>
                        {(!this.state.loader_status) ? <div className="show_loader_on_click" id="show_loader_on_click"></div> : <Disclosure onBoarding={this.state.onBoarding} onClick={this.onCloseDiscModal} />}

                    </Modal>

                    <Modal open={open} onClose={this.onCloseModal} center>
                        <ClientAppDetail listData={this.state.appDetail} onClick={this.onCloseModal} />
                    </Modal>

                    <Modal open={this.state.openViewer} onCloseEdit={this.onCloseView} classNames="modal-sm">
                        <ViewClientDoc listingData={this.state.listingData} onClose={this.onCloseView} />
                    </Modal>


                    <div className="modal fade" id="docTable" >
                        <div className="modal-dialog" style={{ maxWidth: '900px' }}>
                            <div className="modal-content" style={{ minWidth: 'auto' }}>
                                <div className="modal-header">

                                    <h4 className="modal-title">Upload Documents</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body" style={{ padding: '0', background: '#fff', boxShadow: '4px 4px 4px rgba(0,0,0,.2)' }}>
                                    <div class="panel-body table-responsive">
                                        <table class="table table-striped" style={{ marginBottom: '0' }}>
                                            <thead>
                                                <tr>
                                                    <th width="60">#</th>
                                                    <th> Document Name</th>
                                                    <th> Date Uploaded</th>
                                                    <th width="200" class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {clientdocument}
                                            </tbody>


                                        </table>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                    <div className="modal fade " id="uploadDoc" role="dialog">
                        <div className="modal-dialog" style={{ maxWidth: '350px' }}>
                            <div className="modal-content" style={{ minWidth: 'auto' }}>
                                <div className="modal-header">

                                    <h4 className="modal-title">File Upload</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body" style={{ padding: '25px', background: '#fff', boxShadow: '4px 4px 4px rgba(0,0,0,.2)' }}>
                                    <div className="form-widget" style={{ boxShadow: 'none', padding: '0', borderRadius: '0' }}>
                                        <div class="upload-btn-wrapper" style={{ width: '100%', textAlign: 'left', marginTop: '5px' }}>
                                            <button class="btn upload">Browse</button><input type="file" id="addDoc_0" onChange={this.UploadedDocHandler} />
                                            <label>{this.state.uploaded_doc_name}</label>
                                        </div>
                                        <p className="text-center mt-3"><button className="btn btn-default" type="button" disabled={!this.validate()} onClick={this.fileAddDocUpload}>Upload</button></p>
                                    </div>
                                    <span className="inline-error-class">  {" "}
                                        {this.state.add_doc_error !== ""
                                            ? this.state.add_doc_error
                                            : ""}</span>
                                </div>
                            </div>


                        </div>
                    </div>



                    <div className="modal fade requestBox" id="urbanForm" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content" >
                                <div className="modal-header">

                                    <h4 className="modal-title">Urban Form</h4>
                                    <button type="button" className="close" data-dismiss="modal" id="ReScheduleClose">&times;</button>
                                </div>
                            </div>


                        </div>
                    </div>





                    <div className="modal fade requestBox" id="reSchudling" role="dialog">
                        {this.state.flagReschedule ? (<div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">

                                    <h4 className="modal-title">Reschedule a showing</h4>
                                    <button type="button" className="close" data-dismiss="modal" id="ReScheduleClose">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-horizontal">
                                        <small className="light-text">{this.state.s_showinAddress}, {this.state.s_showingUnit}</small>
                                        <div className="form-group row" style={{ marginTop: '0' }} >
                                            <div className="control-label col-sm-4 light-text">
                                                <strong>{Moment(this.state.s_showingTime, ['h:mm:ss']).format('hh:mm A')}</strong>
                                            </div>
                                            <div className="control-label col-sm-8 light-text">
                                                <strong>{Moment(this.state.s_showingDate).format('dddd, MMMM Do')}</strong>
                                            </div>
                                        </div>
                                        <div className="form-group row">

                                            <div className="control-label col-sm-4">
                                                <label>Time</label>
                                                <DatePicker
                                                    selected={this.state.time}
                                                    onChange={this.handleChange}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeFormat="h:mm aa"
                                                    timeIntervals={15}
                                                    dateFormat="h:mm aa"
                                                    timeCaption="time"
                                                    minTime={set_hours(set_minutes(new Date(), 0), 7)}
                                                    maxTime={set_hours(set_minutes(new Date(), 0), 21)}
                                                    className="form-control"
                                                    onKeyDown={(e) => this.preventKeystroke(e)}
                                                />

                                            </div>
                                            <div className="control-label col-sm-8">
                                                <label>Date</label>

                                                <DatePicker
                                                    selected={this.state.startDate}
                                                    onChange={this.handleChangeDate}
                                                    dateFormat="MMMM d, yyyy"
                                                    timeCaption="time"
                                                    className="form-control"
                                                    minDate={new Date()}
                                                    maxDate={addDays(new Date(), 7)}
                                                    onKeyDown={(e) => this.preventKeystroke(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="button" onClick={this.rescheduleShowing} className="btn btn-default">Reschedule</button>
                                    </div>


                                </div>

                            </div>

                        </div>) : (<div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">

                                    <h4 className="modal-title">Reschedule not allowed</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-horizontal">
                                        <p className="title"><strong>YOUR SHOWING IS SCHEDULED WITHIN 60 MINUTES CANNOT RESCHEDULE </strong></p>
                                        <p>Please call your agent directly to reschedule</p>

                                        <div className="form-group">
                                            {/* <p>Call {this.state.agentName} </p><a href={`tel:${this.state.agentMobile}`}><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;{this.state.deliveryContact}</a> */}
                                            <p>Call {this.state.agentName} at <a href={`tel:${this.state.agentMobile}`}><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;{this.state.agentMobile}</a></p>
                                            {/* <button type="button" className="btn btn-default">Call {this.state.agentName} at {this.state.agentMobile}</button> */}


                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>)}


                    </div>

                    <div className="modal fade requestBox" id="cancelSchudling" role="dialog">
                        {this.state.flagReschedule ? (
                            <div className="modal-dialog">


                                <div className="modal-content">
                                    <div className="modal-header">

                                        <h4 className="modal-title">Cancel a showing</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-horizontal">
                                            <p className="title"><strong>ARE YOU SURE YOU WANT TO CANCEL YOUR SHOWING ?  </strong></p>
                                            <p>{this.state.s_showinAddress}, {this.state.s_showingUnit}</p>
                                            <div className="form-group row" style={{ fontSize: '14px', marginTop: '0' }}>
                                                <div className="control-label col-sm-4 light-text">
                                                    <strong>{Moment(this.state.s_showingTime, ['h:mm:ss']).format('hh:mm A')}</strong>
                                                </div>
                                                <div className="control-label col-sm-8 light-text">
                                                    <strong>{Moment(this.state.s_showingDate).format('dddd, MMMM Do')} </strong>
                                                </div>
                                            </div>
                                            <div className="form-group row">

                                                <div className="control-label col-sm-4">
                                                    <button type="button" className="btn btn-default" data-toggle="modal" onClick={() => this.cancelShowing()} data-dismiss="modal">Yes</button>
                                                </div>
                                                <div className="control-label col-sm-8">
                                                    <Link to data-toggle="modal" className="btn btn-default" onClick={() => this.getTimeDifference(this.state.s_showingDate, this.state.s_status, this.state.s_agentName, this.state.agentMobile, this.state.clientShowingId, this.state.s_showingTime)} data-target="#reSchudling" data-dismiss="modal">I’d like to reschedule</Link>
                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>) : (<div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">

                                        <h4 className="modal-title">Cancellation not allowed</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-horizontal">
                                            <p className="title"><strong>YOUR SHOWING IS SCHEDULED WITHIN 60 MINUTES CANNOT CANCEL </strong></p>
                                            <p>Please call your agent directly to cancel</p>

                                            <div className="form-group">
                                                {/* <p>Call {this.state.agentName} </p><a href={`tel:${this.state.agentMobile}`}><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;{this.state.deliveryContact}</a> */}
                                                <p>Call {this.state.agentName} at <a href={`tel:${this.state.agentMobile}`}><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;{this.state.agentMobile}</a></p>
                                                {/* <button type="button" className="btn btn-default">Call {this.state.agentName} at {this.state.agentMobile}</button> */}


                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>)}
                    </div>
                    <div className="modal fade requestBox" id="CallModal" role="dialog">
                        <div className="modal-dialog">


                            <div className="modal-content">
                                <div className="modal-header">

                                    <h4 className="modal-title">Reschedule a showing</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-horizontal">
                                        <p className="title"><strong>YOUR SHOWING IS SCHEDULED IN 30 MINUTES </strong></p>
                                        <p>Please call your agent directly to rescheudle</p>

                                        <div className="form-group">
                                            <button type="button" className="btn btn-default">Call Josiah (646) 123-4567</button>

                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>
                    </div>


                </main>
            </div>
        );
    }
}
export default withRouter(Dashboard);
