import React from 'react';
import Header from '../common/Header';
import {Link} from 'react-router-dom';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { Redirect } from 'react-router';
import FacebookProvider, { Login } from 'react-facebook-sdk';
import { GoogleLogin } from 'react-google-login';
import {postApiData,getApiData, setToken,setUserId, getToken,setOnBoardingDone,getOnBoardingDone,setPasswordSetDone, setOnboardingData, getOnboardingData,getUserId,getBaseUrl} from '../../services/Api';
import {isEmail} from 'validator';
//import validator from 'validator';
import {GOOGLE_CLIENT_ID} from '../../services/Const';


class SignUp extends React.Component {
    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "no name",
            email: "",
            password: "",
            confirm_password:"",
            redirect: false,
            mymsg: "",
            myerrormsg:"",
            myflag:"",
            google_client_id:'',
            customReg:false,
        };
        this.onSignup = this.onSignup.bind(this);
    }
    
   componentWillMount(){
        this.socialLogin();
   } 
   componentWillUnmount(){
//       this.socialLogin();
   }
  
   socialLogin(){
       let social = [{
             url: "/social",
        }];
    getApiData(social,true).then(res => {
     this.setState({'google_client_id':res.data.google.GOOGLE_CLIENT_ID,'linkingLoader':true},()=>{
    
     });
    }).catch(error => {
    if(error.response){
        this.setState({myerrormsg: error.res.data.error.message});
    }
    });
   }
    handleResponse = (data) => {
        if (typeof data.profile !== 'undefined'){
        this.signupFb(data.profile.name, data.profile.email, '111111');
        }
    }
    handleError = (error) => {
        this.setState({error});
    }
    componentWillUnmount(){

    }
    async signupFb(name, email, password) {
        let where = [{
                url: "/Clients"
            }];
        postApiData(where, {'email':email,'username':email,'realm':name,'emailVerified':1,'password':password,'is_social_login':1, 'is_password_set':0}).then(res => {
             this.doLogin(email,'',true); 
        }).catch(error => {
           console.log('in catch',error);
           this.doLogin(email,'',true); 
        });
        
    }

    responseGoogle = (data) => {
        if (typeof data.profileObj !== 'undefined'){
            this.doLogin(data.profileObj.email,'',true);
          this.signupFb(data.profileObj.name, data.profileObj.email, '111111');
        }
    }
async flagstatus(id,token){
        let where = [{
            url: "/Clients",
            urlParms: "/" + id + "?access_token=" + token,
        }];    
        getApiData(where, true).then(res => {       
          // return res.data.flag;
            this.setState({myflag:res.data.flag});
            setOnBoardingDone(res.data.flag);
            this.setState({redirect:true});                     
    });
    }
    async doLogin(email,password,is_social_login=false) {
        
        let where = [{
                url: "/Clients/login"
            }];
        postApiData(where, {'email':email,'password':password,'is_social_login':is_social_login}).then(res => {
            
            if(res.data.id){
                setToken(res.data.id);
                setUserId(res.data.userId);
                setOnboardingData(res.data.onboarding);
                this.setState({myflag:res.data.flag});
                setOnBoardingDone(res.data.flag);
                setPasswordSetDone(res.data.is_password_set);
                localStorage.setItem('user_id', res.data.userId);
                localStorage.setItem('user_name', res.data.realm);

                let clientRelWhere = [{
                    url: "/ClientRels",
                    urlParms: "?filter="+JSON.stringify({"where":{"clientId":getUserId()}})+"&access_token=" + getToken(),
                }];
                getApiData(clientRelWhere,true).then(response => {
                if(response.data.id){
                    
                    var imgsrc;
                    imgsrc=getBaseUrl()+"/containers/profile/download/"+response.data.imagePath;
                    localStorage.setItem('imageUrl',imgsrc);
                }
                }).catch(error => {
                console.log('in catch', error);
                });
                
                if(is_social_login==false){
                    localStorage.setItem('account_verified', 0);
                }
                else{
                    localStorage.setItem('account_verified', 1);
                }
                this.setState({redirect:true});
                //this.flagstatus(res.data.userId,res.data.id)
            }
            
        }).catch(error => {
           console.log('in catch',error);
           this.setState({myerrormsg: error.response.data.error.message});
        });
    }
   

    validateForm() {
        return this.state.name.length > 0 && this.state.email.length > 0 && this.state.password.length > 0;
    }

   handleChange = (event) => {     
        switch(event.target.id){
              case 'email':     
                  if (event.target.value.length ===0) {
                        
                    this.setState({
                                    emailerror: 'Field is required.'
                                    ,email:event.target.value.toLowerCase()
                                  });
                                  break;
                    }else if (!isEmail(event.target.value)) {
                        
                    this.setState({
                                    myerror: 'Email is not valid.'
                                    ,email:event.target.value.toLowerCase()
                                  });
                    }else{
                         this.setState({
                                    myerror: ''
                                    ,email:event.target.value.toLowerCase()
                                  });
                    }
                break;
                case 'password':        
                    if (event.target.value.length<=0) {
                        
                    this.setState({
                                    myerror2: 'Field is required.'
                                    ,password:event.target.value
                                  });
                    }else if (event.target.value.length<6) {
                        
                    this.setState({
                                    myerror2: "Minimum length 6 characters"
                                    ,password:event.target.value
                                  });
                    } else if (document.getElementById('confirm_password').value!=="") {
                        if (event.target.value !== document.getElementById('confirm_password').value){
                            this.setState({
                                myerror2: "Passwords don't match!"
                                ,password:event.target.value
                            });
                        }
                    }
                    else{
                        this.setState({
                                    myerror2: ''
                                    ,password:event.target.value
                                  });
                    }
                break;
            case 'confirm_password':
                 if (event.target.value.length<=0) {
                        
                    this.setState({
                                    myerror3: 'Field is required.'
                                    ,confirm_password:event.target.value
                                  });
                    }
                    else if (event.target.value !== document.getElementById('password').value) {
                    this.setState({
                                    myerror3: "Passwords don't match!"
                                    ,confirm_password:event.target.value
                                  });
                    }else{
                       this.setState({
                                    myerror3: ''
                                    ,confirm_password:event.target.value
                                  }); 
                    }
                break;
            default:
                break;
        
        }
        // this.setState({
        //     [event.target.id]: event.target.value
        // });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.onSignup()
    }
    async onSignup() {
        let where = [{
                url: "/Clients"
            }];
        const {name, email, password, confirm_password} = this.state;
        var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;
    
   if (!pattern.test(email)) {
        this.setState({myerrormsg: "Email is not correct."});
    }else{
        if(password.length < 6){
            this.setState({myerrormsg: "Minimum length 6 characters"});
        }else{
            if (password !== confirm_password) {
                this.setState({myerrormsg: "Password don't match!"});
            }else{
                postApiData(where, {'email':email,'username':email,'realm':name,'emailVerified':0,'password':password,'is_social_login':0,'is_password_set':1})
                .then(res => {
                    if(res.data.id){
                        this.setState({email:"",password:"",confirm_password:""});
                        this.setState({myerrormsg: ''});
                        this.setState({customReg:true});
                        this.doLogin(email, password,false); 
                    }
                })
                .catch(error => {
                    let str = error.response.data.error.message;
                    const n = str.includes("Email already exists");
                    if(n)
                    this.setState({myerrormsg: "Email already exists."});
                    console.log('in catch',error.response.data.error.message);
                });
            }
        } 
    }
          
        
       
    }
    

    render() {
       if(getToken()){
             if (this.state.myflag) {
                return (<Redirect to={'/client/dashboard'}/>);
              }else if(getOnBoardingDone() == 1){
                  return (<Redirect to={'/client/dashboard'}/>);
              }else if(getOnBoardingDone() == 0){
                  return (<Redirect to={'/client/onboarding'}/>);
              }else{
                   return (<Redirect to={'/client/onboarding'}/>);
              }
            }
            if(this.state.google_client_id==''){
            return false;
        }
       
      
        return (
                <div>
                    <Header />
                    <main>
                        <div className='container'>
                        <div className="logoPlaceholder">
                             <Link to ="/" className="navbar-brand" className="text-center"> <img src="/logo.png" width="200" alt="logo"/></Link>
                             </div>
                            <h2 className="text-center">Sign up your account</h2>
                            {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
                            {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                            <div className='form-widget login-widget'>
                            <form onSubmit={this.handleSubmit}>
                                
                                 <FormGroup controlId="email">
                                    <FormControl
                                      autoFocus
                                     type="email"
                                     required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
                                     placeholder="Email"
                                     value={this.state.email}
                                     onChange={this.handleChange}
                                     required
                                 />
                                 <span className="inline-error-class"> {this.state.myerror !==''?this.state.myerror:''}</span>
                                </FormGroup>
                                <FormGroup controlId="password">
                                  <FormControl
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    required
                                    placeholder="Password"
                                    required
                                  />
                                 <span className="inline-error-class"> {this.state.myerror2 !==''?this.state.myerror2:''}</span>
                                </FormGroup>
                                <FormGroup controlId="confirm_password">
                                  <FormControl
                                    value={this.state.confirm_password}
                                    onChange={this.handleChange}
                                    type="password"
                                    required
                                    placeholder="Confirm Password"
                                    required
                                  />
                                  <span className="inline-error-class"> {this.state.myerror3 !==''?this.state.myerror3:''}</span>
                                </FormGroup>
                                <Button
                                  block
                                  disabled={!this.validateForm()}
                                  type="submit"
                                >
                                  Sign Up
                                </Button>
                              </form>
                                    <p className="text-center or-txt">Or Sign Up with</p>
                                    <div className="form-group"> 
                                    <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_ID}>
                                    <Login className="btn facebook"
                                    scope="email"
                                    onResponse={this.handleResponse}
                                    onError={this.handleError}
                                    >
                                    <Link to="" className="btn facebook"> Facebook</Link>
                                    </Login>
                                    </FacebookProvider>
                                    {/* <Link to="" className="btn facebook"> Facebook</Link> */}
                                    </div>
                                    <div className="form-group"> 
                                    <GoogleLogin
                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                        render={renderProps => (
                                            <button className="btn google" onClick={renderProps.onClick}>Google</button>
                                            
                                        )}
                                        buttonText="Login"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        />
                                    {/* <Link to="" className="btn google"> Google</Link> */}
                                    </div>
                                   <div className="need-ac">
                                    Already Registered? <Link to="/client/login/"> Sign In</Link> here
                                   </div>
                            </div>
                        </div>
                    </main>
                </div>

                );
    }
}
export default SignUp;
