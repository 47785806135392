import React from 'react';
import {postApiData,getBaseUrl,getManagerName,getManagerId} from '../../services/Api';
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";

class EditAgentNotes extends React.Component {
    constructor(props) {
		super(props);
		this.state={
           // name:this.props.listingsData.document_name,
            id:this.props.listingsData.id,
           // file_path:this.props.listingsData.file_path,
			showLoader:false,
			error_msg:'',
			success_msg:'',
			invalid:false,
			addNotes:this.props.listingsData.notes_description,
		}
	}

	componentWillMount(){
		// if(this.props.listingsData==null || this.props.listingsData==undefined){
		// 	this.setState({
		// 		invalid:true
		// 	})
		// }
	}
	
	

	handleFrm=(e)=>{
		
		if(e.target.id=='notes'){
			this.setState({
				addNotes:e.target.value
			})
		}
	}
	validateStep(){
		return (this.state.addNotes.trim()!='');
	}



updateFile=()=>{
	
	let _this=this;
        let where = [{
			url: "/updateAgentNotes"
			}];
			postApiData(where, {
				'manager_id':getManagerId(),
                'manager_name':getManagerName(),
				'notes_description':this.state.addNotes,
				'id':this.state.id
				},true).then(res => {
					if(res.data.status=="success"){
						this.setState({
							showLoader: false,
							success_msg:'Notes Submitted',
							addNotes:''
							 },()=>{
								setTimeout(
									function()
									{ 
										_this.props.onClose();
									}
									, 2000
									);
							 });
					}
			}).catch(error => {
			this.setState({
				showLoader: false,
				error_msg:'Something went wrong,Try after some time',
				 });
			});

}


    render() {
		// if(this.state.invalid){
		// 	return (<Redirect to={'/manager/document'}/>);
		// }
	    return (
					
						<div class="modal-content">
							<div class="modal-header" style={{color:'#fff'}}>Edit Note</div>
							<div class="modal-body">
							{(this.state.error_msg!='')?<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>:''}
	                         {(this.state.success_msg !== '') ?<div className="alert alert-success"> {this.state.success_msg !==''?this.state.success_msg:''} </div>:''}
								<div className="form-group">
									
									<textarea id="notes" className="form-control textarea"  value={unescape(this.state.addNotes)} onChange={this.handleFrm} style={{border:'1px solid rgba(0,0,0,.5)', height:'80px', padding:'10px', borderRadius:'4px'}}></textarea>
								</div>
								<button className="btn btn-default" disabled={!this.validateStep} onClick={this.updateFile}>Save</button>
								<p className="text-right"><button type="button" className="btn cl-btn" data-dismiss="modal" onClick={this.props.onClose}>Close  <img src="/close.png" /></button></p>
							</div>


					
				</div>
	);
    }
}



export default EditAgentNotes;