import React from 'react';
import {postApiData,getBaseUrl} from '../../services/Api';
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";

class EditFiles extends React.Component {
    constructor(props) {
		super(props);
		this.state={
            name:this.props.listingData.file_name,
            id:this.props.listingData.id,
            folder_id:this.props.listingData.folder_id,
            file_path:this.props.listingData.file_path,
			showLoader:false,
			error_msg:'',
			success_msg:'',
			invalid:false,
		}
	}

	componentWillMount(){
		if(this.props.listingData==null || this.props.listingData==undefined){
			this.setState({
				invalid:true
			})
		}
	}
	
	createFiles=()=>{
		this.imageSubmit(this.state.folder_id,this.state.selectedFile,this.state.name)
	}

	handleFrm=(e)=>{
		if(e.target.id=='name'){
			this.setState({
				name:e.target.value
			})
		}
	}
	validateStep(){
		return (this.state.name.trim().length>0  && !isNaN(this.state.folder_id))?true:false;
	}

	fileHandler =(e) => {
		console.log('---file',e.target.files[0])   
		if(e.target.files[0]){
			let imgFlag = true;
		 const maxFileSize = 50428800;   //5mb
		 const value = e.target.files[0].name;
		 //const ext = value.substring(value.lastIndexOf('.'));
		//  if(![".jpg", ".JPG", ".jpeg", ".png",'.pdf'].includes(ext)){
		// 		 this.setState(
		// 			 {error_msg: ext+' is not supported file extension.',img_flag:false});
		// 		 imgFlag = false;
		//  }
		 if(e.target.files[0].size>maxFileSize){
				 this.setState({error_msg:"The uploaded image is too large. The max image file size is set to 50MB",img_flag:false})
				 imgFlag = false;
				 }
		 if(imgFlag) { 
					this.setState({
			selectedFile:e.target.files[0], 
			img_flag:true,
			error_msg:'',
			imagePath: URL.createObjectURL(e.target.files[0]) ,
				})
						 //this.imageSubmit(this.state.list_id,e.target.files[0],params);
					}
			}  
	 }

imageSubmit=(folder_id,file,name)=>{
	this.setState({
		showLoader: true})
	if(this.state.img_flag){
		let rentalDoc=[{
			url : '/containers/rental_doc/upload'
		}];
		const formData = new FormData();
		formData.append('file',file);
		postApiData(rentalDoc, formData,'post',true).then(res => {
		if(res.data.success){
			this.updateFile(folder_id,this.state.name,res.data.file_name,this.state.id);
		}
		}).catch(error=>{
			this.setState({
				showLoader: false,
				error_msg:'Something went wrong,Try after some time',
				 });
			});
	}else{
		this.updateFile(folder_id,this.state.name,this.state.file_path,this.state.id);
	}
	
}

updateFile(folder_id,file_name,file_path,id){
	let _this=this;
        let where = [{
			url: "/editFiles"
			}];
			postApiData(where, {
				'folder_id':folder_id,
				'file_name':file_name,
                'file_path':file_path,
                'id':id
				},'post',true).then(res => {
					if(res.data.status=="success"){
						this.setState({
							showLoader: false,
							success_msg:'File updated',
							img_flag:false,
							selectedFile:{},
							name:''
							 },()=>{
								setTimeout(
									function()
									{ 
										_this.props.onClose();
									}
									, 3000
									);
							 });
					}
			}).catch(error => {
			this.setState({
				showLoader: false,
				error_msg:'Something went wrong,Try after some time',
				 });
			});

}


    render() {
		if(this.state.invalid){
			return (<Redirect to={'/manager/document'}/>);
		}
	    return (<div className="modal-content">
{(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}				
		<div className="modal-header">
			<h4 className="modal-title">Edit Document</h4>
		</div>
		<div className="modal-body">
		{(this.state.error_msg!='')?<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>:''}
	{(this.state.success_msg !== '') ?<div className="alert alert-success"> {this.state.success_msg !==''?this.state.success_msg:''} </div>:''}
			<div className="form-group" style={{marginBottom:'20px'}}>
				<input type="text" id="name" value={this.state.name} className="form-control" placeholder="Document Name" onChange={this.handleFrm} style={{border:'1px solid rgba(0,0,0,.5)', height:'50px', padding:'10px', borderRadius:'4px'}}/>
			</div>
			<div className="upload-btn-wrapper upload-doc mb-2" >
				<button className="btn btn-default" id="uploadBtn" type="button">+ Add Document</button>
				<FormGroup >
					<FormControl type="file"  onChange={this.fileHandler} />
				</FormGroup>
                <label style={{marginLeft:20}}>File: {this.state.file_path}</label>
			</div>
			<br/>
			<button type="button" className="btn btn-default"  disabled={!this.validateStep()}  onClick={this.createFiles}>Submit</button>
			<button type="button" className="btn cl-btn pull-right" onClick={this.props.onClose} >Close  <img src="/close.png"/></button> 

		</div>
	</div>);
    }
}



export default EditFiles;