import React from 'react';
import Header from '../../common/Header';
import {Link} from 'react-router-dom';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { getApiData,getBaseUrl, postApiData} from '../../../services/Api';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import CryptoJS from 'crypto-js';
import {validatePhone,socialSecurityNumber} from '../../../services/validation';
import L from 'leaflet';
import MarkerClusterGroup from './../../../react-leaflet-markercluster';
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';
import Moment from 'moment';
import Modal from "react-responsive-modal";

let key = encHex.parse("0123456789abcdef0123456789abcdef");
    let iv =  encHex.parse("abcdef9876543210abcdef9876543210"); 
    
    var email;
class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            listDetails:[],
            listImage:[],
            listAttr:[],
            listSalesInfo:[],
            list_id:this.props.match.params.id,
            first_name:'',
            last_name:'',
            phone_number:'',
            message:'',
            openHouseList:[],
            showBlock:'none',
            closeHouseModal:'',
            openHouseModel:''
        }
      }

    componentDidMount(){
        this.setListingDataForFront();
        let openHouseList = [{
            url: "/getOpenHouseList",
            where:{
                list_id:this.state.list_id
            }
        }];
        getApiData(openHouseList,false).then(res => {
            console.log("res open house=>",res);
            if(res.data.status=='Success'){
                this.setState({
                    openHouseList:res.data.data,
                    showBlock:(res.data.data.length>0)?'block':'none'
                },()=>{
                });
            }

            if(res.data.status=='error'){
                this.setState({myerrormsg: res.data.msg});
            }
            
        }).catch(error => {
            if(error.response){
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });
    }
    // componentWillMount(){
    //     this.setListingDataForFront();
    // }
      setListingDataForFront=()=>{
        let filter={
            list_id:this.state.list_id
        };
        let listInfo = [{
            url: "/listInfo",
            where:filter
        }];
        getApiData(listInfo,false).then(res => {
            console.log("res2-------",res.data[0][0]);
            if(res.data[0]){
                this.setState({listDetails:res.data[0][0]},()=>{
                    document.getElementById('listingDesc').innerHTML=unescape(this.state.listDetails.description).replace(/(?:\r\n|\r|\n)/g, '<br>')
                    if(res.data[1]){
                        this.setState({listImage:res.data[1]});
                    }
                    if(res.data[2]){
                        this.setState({listAttr:res.data[2]});
                    }
                    if(res.data[3]){
                        this.setState({listSalesInfo:res.data[3][0]}); 
                    }           
                });
            }
            
        }).catch(error => {
            if(error.response){
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });
    }    
    
    handleChange=(event)=>{
        if(event.target.id=="first_name" || event.target.id=="last_name"  ){
                if(event.target.id=="first_name" || event.target.id=="last_name"){
                    if(event.target.value==""){
                        this.setState({
                            [event.target.id]:event.target.value,
                            [event.target.id+"_error"]:"field can not be empty."
                        })
                    }else if(!isNaN(event.target.value)){
                        this.setState({
                            [event.target.id]:event.target.value,
                            [event.target.id+"_error"]:"Enter only character."
                         
                        })
                    }
                    else{
                        this.setState({
                            [event.target.id]:event.target.value,
                            [event.target.id+"_error"]:""
                        })
                    } 
                }
        }
    }

    handleMessage=(event)=>{
       console.log("event.target.id",event.target.id,event.target.value)
        this.setState({
            [event.target.id]:event.target.value
        })
    }
    handleEmail=(event)=>{
        this.setState({
            [event.target.id]:event.target.value,
        
        })
    }
    validateEmpPhoneNumber(value){
        let res=validatePhone(value);
                    if(res.status==false){
                        // this.setState({employer_phone_number_error: 'Phone number is not valid.'});
                        document.getElementById("phone_number").value=res.value;
                    }else{
                        // this.setState({employer_phone_number_error: ''});
                        document.getElementById("phone_number").value=res.value;
                    }
                return res.status;
    } 
    OpenModelShowHouse=()=>{
        this.setState({
            openHouseModel:true
        },()=>{
            console.log("openHouse",this.state.openHouseModel)
        })
    }
    
    CloseModelShowHouse=()=>{
        this.setState({
            openHouseModel:false
        },()=>{
            console.log("openHouse else",this.state.openHouseModel)
        })
    }
    formValidate(key,value){
        switch(key){
            case 'phone_number':
                this.validateEmpPhoneNumber(value);
                break;
        }
    }

    handlePhoneChange = event => {

        
            this.formValidate(event.target.id,event.target.value);
        
           if(event.target.id==='phone_number'){
              if(this.isNum(event.target.value)!=true){
                this.setState({phone_number:''})
              }
              else{ 
                this.setState({phone_number:event.target.value});
              }
           }
        }
        
            isNum(num){
                if(/^[0-9-]*$/.test(num))
                return true;
                else 
                return false;
            }

                handleSubmit=(e)=>{
                    e.preventDefault();
                    let where=[{
                        url:'/email/contactDetail'
                    }];
                    var formData=new FormData();
                    formData.append('First_Name',this.state.first_name)
                    formData.append('Last_Name',this.state.last_name)
                    formData.append('Phone_Number',this.state.phone_number)
                    formData.append('Email',email)
                    formData.append('Message',this.state.message)
                    formData.append('listing_address',this.state.listDetails.listing_info_address)
                    formData.append('unit_number',this.state.listDetails.unit_number)
                    formData.append('agent_name',this.state.listDetails.name)
                    postApiData(where,formData,false).then(res=>{
                        console.log("res",res.data);
                        if(res){
                        document.getElementById("close").click();
                        }
                    }).catch(error=>{

                    })
                }


                validation=()=>{
                    const {
                        first_name,
                        last_name,
                        phone_number,
                        message
                    }=this.state;

                    return(
                        first_name!="" &&
                        last_name!="" &&
                        phone_number!="" &&
                        message!="" 
                    )
                }

                onCancel=()=>{
                    this.setState({
                        first_name:"", last_name:"", phone_number:"", message:""
                    })
                }
                
    render() {    
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
        
        if(this.state.listDetails==undefined){
            return(<div className="FrontEnd">
            <Header />
            <main><div style={{textAlign:"center"}}>404 page not found</div></main>

              <footer>
              <div className="container">
              <div className="clearfix">
                  <ul className="left-nav">
                      <li><Link to="/frontend/career">CAREERS</Link></li>
                      <li><img src="/home-icon.png"/></li>
                  </ul>
                  <div className="logo"></div>
                  <ul className="right-nav">
                      <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                      <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                      <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                  </ul>
              </div>
<p className="text-center"><small>280 West 115th Street, 1F |  New York, NY 10026 |  212.222.2843</small></p>
              </div>
          </footer>                    
           </div>

        );
        }

        let openHouseRowList=''
        if(this.state.openHouseList.length>0){

            openHouseRowList= this.state.openHouseList.map((item, index)=>{
                return (<div class="row dateandtime">
                    <div class="col-sm-4 border-right" style={{fontWeight:"bold"}}>{Moment(item.date).format('ddd, MMM DD')} </div>
                    <div class="col-sm-8">
                        {item.games.map(function (time) {
                  return (
                    <span class="time-seprator">{time.show_time}</span>
                  );
                })}
                </div>
                     </div>);
            });


        }

        if(this.state.listDetails.listing_info_address==undefined){
            return (<div className="show_loader_on_click" id="show_loader_on_click"></div>)
        }else{
            let building='';
            building=building+this.state.listDetails.listing_info_address;
           //console.log("building",typeof(building),building.split(','));
           let building_details=building.split(',');
           //console.log("this.state",building_details);
           let list = this.state.listAttr.map((item, index)=>{
            return <li key={index}>{item.name}</li>;
        });
           let listImage;
           if(this.state.listImage.length>0){
           listImage = this.state.listImage.map((item, index)=>{
               let img =(item.large_image!='' && item.large_image!=null)?item.large_image:'/listing-img-detail.png';
               let check=item.large_image.indexOf("https://");
          if(check==-1){
            check=item.large_image.indexOf("http://");
          }
               let image=(check >-1 || img=='/listing-img-detail.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
                var vr_link=(this.state.listDetails.kuula_vr_link!='')?this.state.listDetails.kuula_vr_link:'#';
               return <div key={index} id={`img_`+item.id} data-toggle="modal" data-target="#gallery">
               <img src={image} width="100%" />
           </div>;
           });
       }else{
           listImage =  <div id="img_0" className="poppreviewImage" className="imageNoimageDetail">
              
           </div>;
       }
       let listImagePopup;
    if(this.state.listImage.length>0){

        listImagePopup = this.state.listImage.map((item, index)=>{
           let img =(item.large_image!='' && item.large_image!=null)?item.large_image:'/listing-img-detail.png';
           let check=item.large_image.indexOf("https://");
      if(check==-1){
        check=item.large_image.indexOf("http://");
      }
           let image=(check >-1 || img=='/listing-img-detail.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
            var vr_link=(this.state.listDetails.kuula_vr_link!='')?this.state.listDetails.kuula_vr_link:'#';
           return <div key={index}>
           <img src={image}/>
       </div>;
       });
   }   
  if(this.state.listDetails.email)
     email=aes.decrypt(this.state.listDetails.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8);
 else
    email ='';
       
       return (
                <div className="FrontEnd">
                    <Header />
                    <main>
                    {(this.state.listDetails!=undefined)?<div class="listing-section">
                            <div class="listing-header">
                                <div class="container">
                                    <div class="row clearfix">
                                        <div class="col-sm-6">
                                            <h3>{this.state.listDetails.listing_info_address},{this.state.listDetails.unit_number}</h3>
                                            {/* <p>{this.state.listDetails.neighborhood}{(building_details[1]!=undefined)?', '+building_details[1]:''}{(building_details[2]!=undefined)?' ,'+building_details[2]:''}{(building_details[3]!=undefined)?' ,'+building_details[3]:''}</p> */}
                                        
                                        </div>
                                        <div class="col-sm-6 text-right">
                                            <p class="listing-price">
                                            {this.state.listDetails.price?'$'+this.state.listDetails.price.toLocaleString():'$'+this.state.listDetails.price}
                                            </p>
                                            <p>{(this.state.listDetails.bedrooms>=0)?this.state.listDetails.bedrooms:'NA'} Bedroom | {(this.state.listDetails.bathrooms>=0)?this.state.listDetails.bathrooms:'NA'} Bathroom {(this.state.listDetails.square_footage)?' | '+this.state.listDetails.square_footage+' Sq. Ft.':''} </p>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="listing-slider" >
                             {this.state.listDetails.noFee?<div className="tag"></div>:''}
                           <Carousel showIndicators={false} showThumbs={false} infiniteLoop={true} emulateTouch={true}> 
                           {listImage}
                            </Carousel>
                            {this.state.listDetails.kuula_vr_link?<div className="img-360">
                                    <img src="/images/360.png" /> 
                                </div>:''}
                            </div>
                            {this.state.listDetails.kuula_vr_link?
                            <div className="container detail-exvr">
                                        <p className="experience-vr"><a href={this.state.listDetails.kuula_vr_link} target="_blank"><img src="/vr.png" /><span className="hidden-xs">Experience in VR</span></a></p>
                                    </div>:''}
                                    

                                    {this.state.listDetails.video_link?<div className="container detail-exvr">
                                        <p className="experience-vr"><a href={this.state.listDetails.video_link} target="_blank"><img src="/video.png" /><span className="hidden-xs">Watch Video Tour</span></a></p>
                                    </div>:''}

                          {/*  <div class="after-slider-section"> 
                            <div class="container"> 
                            <div className="row clerfix">                           
                                    <div class="col-md-9">                                  
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <span>{this.state.listDetails.listing_type == "Rental" ?"For Rent":"For Sale"}</span>
                                            
                                            {(new Date().setHours(0,0,0,0) > new Date(this.state.listDetails.date_available).setHours(0,0,0,0) || this.state.listDetails.date_available=='0000-00-00')? 'Immediately':Moment(this.state.listDetails.date_available).format('M/D/YY')}
                                            </div>
                                            <div className="col-sm-7">
                                            <div class="view-map">
                                                <ul>                                                   
                                                    <li>
                                                        <Link to="#"><img src="/picture.svg" width="20" height="20"/>{this.state.listImage.length} Photos </Link>
                                                    </li>                                               
                                                    <li>
                                                        <Link to="#"><img src="/plan.svg" width="20" height="20"/>Floor Plan</Link>
                                                    </li>
                                                    <li>
                                                        <a href="#view-map"><img src="/placeholder.svg" width="20" height="20"/>Map</a>
                                                    </li>
                                                    
                                                    
                                                </ul>
                                            </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                    </div>
                                </div>                                           
       </div>*/}

                            <div class="listing-details">
                                <div class="container">
                                    <div class="row">
                                    {/*<div class="col-md-3 d-blcok d-md-none">
                                            <div class="listing-info text-center">
                                                <div class="listing-info-img">
                                                {this.state.listDetails.image?this.state.listDetails.image.startsWith("https://")?<img src={this.state.listDetails.image} width="75" height="75" className="rounded-circle" />:<img src={getBaseUrl()+"/containers/agent_image/download/"+this.state.listDetails.image} width="75" height="75" className="rounded-circle" />:<img src={getBaseUrl()+"/containers/agent_image/download/"+this.state.listDetails.image} width="75" height="75" className="rounded-circle" />}        
                                                </div>
                                                <h5>{this.state.listDetails.name}</h5>
                                                <p>{this.state.listDetails.email?aes.decrypt(this.state.listDetails.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):'NA'}<br/>
                                                {this.state.listDetails.mob_number?aes.decrypt(this.state.listDetails.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):'NA'}</p>
                                               <button type="button" class="btn btn-default" data-toggle="modal" data-target="#myModal">Contact Us</button>
                                            </div>
    </div>*/}
                                        
                                        <div class="col-md-9">

                                       

                                            <div class="listing-discription">
                                                <h4>Listing Description</h4>
                                                <p id="listingDesc" style={{whiteSpace:'pre-wrap'}}>
                                                {unescape(this.state.listDetails.description)}
                                                </p>
                                            </div>
                                            <div class="listing-amenities">
                                                <h4>Amenities</h4>
                                                <ul>
                                                {list}
                                                </ul>
                                            </div>
                                            <div class="listing-discription">
                                                <h4>Listing Details</h4>
                                                <p>
                                                    <table width='100%'>
                                                        {/* <tr>                        
                                                            <th style={{width:'125px'}}>Listing ID</th><td>{this.state.list_id}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <th>Property Type</th><td>{this.state.listDetails.property_type}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Pet Policy</th><td>{this.state.listDetails.pets}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Exposure</th><td>{this.state.listDetails.exposures?this.state.listDetails.exposures.replace(",", ",").split(' ').join(','):''}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Building Type</th><td>{this.state.listDetails.building_type}</td>
                                                        </tr>
                                                    </table>
                                                </p>
                                            </div>
                                            <div className="discription-box" style={{display:this.state.showBlock}}>
                            <h4>Open House</h4>
<p>
    {(this.state.openHouseList.length>0)?Moment(this.state.openHouseList[0].date).format('ddd, MMM DD'):'-'} | {(this.state.openHouseList.length>0)?this.state.openHouseList[0].games[0].show_time:'-'} 
    <br/>
    <button type="button" className="btn btn-default"   onClick={this.OpenModelShowHouse}>Show More</button>
</p>
                        </div>
                                             {(this.state.listDetails.email!='null' && this.state.listDetails.email!=undefined && this.state.listDetails.agentId!='null' && this.state.listDetails.mob_number!='null')? <div className="listed-box cleafix">
                                             <h4>Listed by</h4>
                                             <div className="info">
                                             {(this.state.listDetails.image!=null && this.state.listDetails.image!='' && this.state.listDetails.image!='undefined' && this.state.listDetails.image!='member.png')?(this.state.listDetails.image.startsWith("https://") || this.state.listDetails.image.startsWith("http://"))?<img src={this.state.listDetails.image} width="75" height="75" className="rounded-circle" />:<img src={getBaseUrl()+"/containers/agent_image/download/"+this.state.listDetails.image} width="75" height="75" className="rounded-circle" />:<img src="/member.png" width="75" height="75" className="rounded-circle" />}   
                                              </div>
                                              <p>{this.state.listDetails.name}</p>
                                                <p className="browselist-email">{this.state.listDetails.email?aes.decrypt(this.state.listDetails.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):'NA'}<br/>
                                                {this.state.listDetails.mob_number?aes.decrypt(this.state.listDetails.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):'NA'}</p>
                                             </div>:<div className="listed-box cleafix">
                                             <h4>Listed by</h4>
                                             <div className="info">
                                             <img src="/skyward-icon.png" width="75" height="75" className="rounded-circle" />  
                                              </div>
                                              <p>Skyward</p>
                                                <p>listings@moveskyward.com<br/>
                                                212.222.2843</p>
                                                    </div>}   


                                          {/*  <div class="listing-map" id="view-map">
                                                <h4>Location</h4>
                                                <div className="map-wrap">
                            <Map className="markercluster-map" style={{width:'130px', height:'207px'}} center={[`${this.state.listDetails.latitude}`, `${this.state.listDetails.longitude}`]} zoom={12} maxZoom={18} minZoom={4}>
          
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
      
          <MarkerClusterGroup>
      
         

                <Marker position={[`${this.state.listDetails.latitude}`, `${this.state.listDetails.longitude}`]}>
                <Popup>
                    <div>
                    {this.state.listDetails.listing_info_address}<br />
                    <b>Price: ${this.state.listDetails.price.toLocaleString()}</b>
                    </div>
                </Popup>
                </Marker>

              
            
      
          </MarkerClusterGroup>
      
        </Map>

                            </div>
                                            </div>*/}


                                        </div>
                                        <div class="col-md-3 d-none d-md-block">


                                        <div class="listing-map" id="view-map">
                                              {/*  <h4>Location</h4>*/}
                                                <div className="map-wrap">
                            <Map className="markercluster-map" style={{width:'130px', height:'300px'}} center={[`${this.state.listDetails.latitude}`, `${this.state.listDetails.longitude}`]} zoom={12} maxZoom={18} minZoom={4}>
          
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
      
          <MarkerClusterGroup>
      
         

                <Marker position={[`${this.state.listDetails.latitude}`, `${this.state.listDetails.longitude}`]}>
                <Popup>
                    <div>
                    {this.state.listDetails.listing_info_address}<br />
                    <b>Price: {this.state.listDetails.price?'$'+this.state.listDetails.price.toLocaleString():'$'+this.state.listDetails.price}</b>
                    </div>
                </Popup>
                </Marker>

              
            
      
          </MarkerClusterGroup>
      
        </Map>

                            </div>
                            {/*  <button type="button" class="btn btn-default" data-toggle="modal" data-target="#myModal">Contact Us</button>*/}
                                            </div>
                                       {/* <div class="listing-info text-center">
                                                <div class="listing-info-img">
                                                {this.state.listDetails.image?this.state.listDetails.image.startsWith("https://")?<img src={this.state.listDetails.image} width="75" height="75" className="rounded-circle" />:<img src={getBaseUrl()+"/containers/agent_image/download/"+this.state.listDetails.image} width="75" height="75" className="rounded-circle" />:<img src={getBaseUrl()+"/containers/agent_image/download/"+this.state.listDetails.image} width="75" height="75" className="rounded-circle" />}        
                                                </div>
                                                <h5>{this.state.listDetails.name}</h5>
                                                <p>O:{this.state.listDetails.office_phone}<br/>
                                                M: {this.state.listDetails.mob_number?aes.decrypt(this.state.listDetails.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):'NA'}</p>
                                              
                                        </div>*/}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
    :<div>Invalid Listing Id</div>}







{/*Modal Start*/}
<div className="listing-detail-form">
  <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">   
      <div class="modal-content">
        <div class="modal-header">       
          <h4 class="modal-title">Contact Listing Agents for {this.state.listDetails.listing_info_address} #{this.state.listDetails.unit_number}</h4>
          <button type="button" onClick={this.onCancel} id="close" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <form onSubmit={this.handleSubmit}>
            <div className="row clearfix">
                <div className="col-sm-6">
                    <div class="form-group">
                        <label>First Name</label>
                        <input type="text" class="form-control" id="first_name" value={this.state.first_name} onChange={this.handleChange} />
                        <span className="inline-error-class">
                                 {" "}
                                    {this.state.first_name_error !== ""
                                        ? this.state.first_name_error
                                            : ""}
                        </span>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div class="form-group">
                        <label>Last Name</label>
                        <input type="text" class="form-control" id="last_name" value={this.state.last_name} onChange={this.handleChange} />
                        <span className="inline-error-class">
                                 {" "}
                                    {this.state.last_name_error !== ""
                                        ? this.state.last_name_error
                                            : ""}
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" id="email" value={email} onChange={this.handleEmail}/>
            </div>
            <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-control"  id="phone_number" maxLength={12} value={this.state.phone_number} onChange={this.handlePhoneChange}/>
                <span className="inline-error-class">
                                 {" "}
                                    {this.state.phone_number_error !== ""
                                        ? this.state.phone_number_error
                                            : ""}
                        </span>
            </div>
            <div class="form-group">
                <label>Message</label>
                <textarea class="form-control" id="message" value={this.state.message} onChange={this.handleMessage}></textarea>
                        <span className="inline-error-class">
                                 {" "}
                                    {this.state.phone_number_error !== ""
                                     ? this.state.phone_number_error
                                     : ""}
                        </span>
            </div>
            <div class="modal-footer">
          <button type="button" onClick={this.onCancel} class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="submit" 
           disabled={!this.validation()} 
           class="btn btn-default">Contact</button>
        </div> 
        </form>
        </div>
      
      </div>
      
    </div>
  </div>
  </div>


  <div id="gallery" className="modal fade" role="dialog">
<div className="modal-dialog" style={{maxWidth:'100%', margin:'0', boxShadow:'none', background:'none'}}>
                        
                        <div className="modal-content">
                                <div className="modal-body" >
                                    <div className="listing-banner">
                                    <button type="button" id="ListingModal" className="btn cl-btn" data-dismiss="modal"> <img src="/close.png"/></button>
                                    <Carousel showThumbs={true} infiniteLoop={true} emulateTouch={true} >
                                             {/* <div>
                                                    <img src="/career-bg1.png" />
                                                
                                              </div>
                                              <div>
                                                    <img src="/career-bg2.png" />
                                                
                                              </div>
                                              <div>
                                                    <img src="/career-bg3.png" />
                                                
                                              </div> */}
                                            {listImagePopup}
         
                                         </Carousel>     
                                                         
                                    </div>
                                
                                </div>
                        </div>

                    </div>
                    </div>
  {/*Modal End*/}

  {/* Open House List Start */}
<Modal open={this.state.openHouseModel} >
        <div className="modal-header contact-agent-modal" >
                <h4 className="modal-title">Open House</h4> 
                <button type="button" className="close" onClick={this.CloseModelShowHouse}>&times;</button>
            </div>

                        <div id="divContent" class="application-details">
							<div id="contact_Email_id" class="time-width">
							
                            <div className="listed-box cleafix">
                            <div className="info" style={{padding:'20px'}}>
                                {openHouseRowList}
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        
							</div>
			</div> 
        </Modal>
{/*Open house list end*/}

                    </main>   
                    <div className="Newskyward-design">
                <footer className>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> 
                     <li className="nav-item get-started-tab" ><Link to="/client/signup" className="getstart btn btn-default" >Login/Get Started</Link></li>
                  
                    
                    </ul>   
                   <div className="clearfix">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img src="/home-icon.png"/></li>
                       </ul>
                       <div className="logo"></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>

                   </div>
               </footer> 

                </div>                   
                </div>

                );

        }
            }
}
export default Details;
