import React from 'react';
import {Link} from 'react-router-dom';
import { getApiData, getToken, getUserId,getOnBoardingDone,getBaseUrl } from '../../services/Api';
import { Redirect } from 'react-router';

class HeaderVR extends React.Component {

    constructor(props) {
        super(props);     
        
        this.state = {
         

          }; 
        
    }

    render() {
        var pathName = window.location.pathname;
      
        return (
            <div className="headVr">
               <header className="main-header headerVR">             
                <nav className="navbar navbar-expand-lg bg-light navbar-dark">
               
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <img src="/w-menu-icon.png"/>
                </button>
                <div className="collapse navbar-collapse " id="collapsibleNavbar" ref={this.setWrapperRef1}>
               
                       
                  
                     <ul className="navbar-nav">
                        <li className="nav-item" ><a href={getBaseUrl()} > Skyward</a></li>
                        <li className="nav-item" className={(pathName=='/frontend/Tour360' || pathName=='/frontend/Tour360/') ?'active':''}><Link to="/frontend/Tour360"> 360˚ TOURS</Link></li>
                        <li className="nav-item" className={(pathName=='/frontend/Philanthropy' || pathName=='/frontend/Philanthropy/')?'active':''}><Link to="/frontend/Philanthropy" >PHILANTHROPY</Link></li>
                        <li className="nav-item" className={(pathName=='/frontend/Notice')?'active':''}><a href="https://dos.ny.gov/system/files/documents/2024/06/nys-housing-and-anti-discrimination-notice_06.2024.pdf" target='_blank'>FAIR HOUSING NOTICE</a></li>
                        <li className="nav-item ml-auto"><Link to="/client/signup" > LOGIN / GET STARTED</Link></li> 
                    </ul>   
                  
                        

                </div>  
                </nav>
                </header>
                </div>
                );
    }
}
export default HeaderVR;