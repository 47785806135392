import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { postApiData,getApiData, getToken, getUserId,putApiData,getBaseUrl, getOnBoardingDone } from '../../services/Api';
import {validatePhone,validateName} from '../../services/validation';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';

class Profile extends React.Component {
    constructor() {
        super();
        let clientId=getUserId();
        this.state = {
            clientId:clientId,
            onboarding:{},
            profile:{},
            userDetails:{},
            nameerror:"",
            redirection:false,
            pageLoader: false,
            searchagentMsg:false,
          };
          this.getDetails = this.getDetails.bind(this);
        }
        componentWillMount() {
          this.getDetails();
        }
        componentWillUnmount(){
            this.getDetails();
        }
    
    async getDetails() {
        //onboard
        let clientOnBoard = [{
            url: "/onboardings/findOne",
            where: {"where":{"clientId":getUserId()}},
        }];
        getApiData(clientOnBoard,true).then(res => {
        this.setState({'onboarding':res.data});
        }).catch(error => {
        if(error.response){
            if(error.response.data.error.code=='MODEL_NOT_FOUND'){
                this.setState({"redirection":true});
            }
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });

        //userinfo
        let where = [{
        url: "/Clients/profile",
        urlParms: "/" + getUserId() + "?access_token=" + getToken(),
        }];
        //const {user_id} = this.state;
        getApiData(where, true).then(res => {
            if(res.data.id){
                 this.setState({'userDetails':res.data});
            }
        }).catch(error => {
            this.setState({myerrormsg: error.response.data.error.message});
        });



    }

    render() {
        if(this.state.redirection){
            return (<Redirect to={'/client/onboarding'}/>);
        }
         if (getToken()) {
                const f = getOnBoardingDone();   
                    if(f == 0){
                         return (<Redirect to={'/client/onboarding'}/>);
                     }else{
                     }
		  }else{
                      return (<Redirect to={'/client/login'}/>);
        }
        if(Object.keys(this.state.onboarding).length<1 || Object.keys(this.state.userDetails).length<1){
           return (
                <div>
                <Header />
                <main>
                <div className='container'>
                    <div className="show_loader_on_click" id="show_loader_on_click"></div>
                    </div>
                 </main>
                </div>
                );
        }else{
            return (
                <div>
                    <Content contentProp = {this.state}/>
                </div>
                );
        }

       
        
    }
}
class Content extends React.Component {
    constructor(props){
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
        super(props);
        this.state={
            "imagePath":"/member.png",
            "imagePath2":"",
            "modelName":this.props.contentProp.onboarding.first_name,
            "modelNumber": aes.decrypt(this.props.contentProp.onboarding.phone_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8),
            "modelAgentName":this.props.contentProp.onboarding.agent_name,
            "modelAgentNameFront":this.props.contentProp.onboarding.agent_name,
            "count_app":this.props.contentProp.onboarding.count_app,
            "clientRel":"0",
            "imagePath":"/member.png",
            "validationStatus":true,
             agentDW:[],
             searchAgentId:'',
             agent_id:''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitName = this.handleSubmitName.bind(this);
        this.handleSubmitNumber = this.handleSubmitNumber.bind(this);
        this.handleSubmitAgentName=this.handleSubmitAgentName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentWillMount() {
        this.getProfile();
        this.agentDD();
    }
    componentWillUnmount(){
           this.getProfile();
           this.agentDD();
    }
     
    handleChange(event) {
        console.log(event.target.id,event.target.value)
        this.formValidate(event.target.id,event.target.value);
        this.setState({[event.target.id]: event.target.value});
    }
    
    //agent Dropdown
	async agentDD(){
		let clientList = [{
            url: "/agent/agentDD",
		}];
        getApiData(clientList,true).then(res => {
        this.setState({'agentDW':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}

      formValidate(key,value){
        switch(key){
            case 'modelName':     
                let resName=validateName(value);
                if(resName.status==false){
                   this.setState({nameerror: resName.msg,validationStatus: false});
                   document.getElementById("modelName").value=resName.value;
               }else{
                   this.setState({nameerror: '',validationStatus: true});
                   document.getElementById("modelName").value=resName.value;
               }
                break;
            case 'modelNumber':        
                let res=validatePhone(value);
                if(res.status==false){
                    this.setState({phoneerror: 'Phone number is not valid.',validationStatus: false});
                    document.getElementById("modelNumber").value=res.value;
                }else{
                    this.setState({phoneerror: '',validationStatus: true});
                    document.getElementById("modelNumber").value=res.value;
                }
                break;
            case 'modelAgentName':  
                 let resAgent=validateName(value);
                 if(resAgent.status==false){
                    this.setState({agenterror: resAgent.msg,validationStatus: false});
                    document.getElementById("modelAgentName").value=resAgent.value;
                }else{
                    this.setState({agenterror: '',validationStatus: true});
                    document.getElementById("modelAgentName").value=resAgent.value;
                }
                 
                break;
                case 'agent_search_id': 
                 let res_agent_search_id=validateName(value);
                 if(res_agent_search_id.status==false){
                    this.setState({agenterror: 'Name is required',validationStatus: false});
                    //document.getElementById("agent_search_id").value=res_agent_search_id.value;
                }else if(this.state.searchagentMsg==true){
                    this.setState({validationStatus: false});
                }
                else{
                    this.setState({agenterror: '',validationStatus: true});
                    //document.getElementById("agent_search_id").value=res_agent_search_id.value;
                }
                break;
            }
    }
    validateName(value){
        var name = true;
        if (value.length ==0) {
                    this.setState({nameerror: 'Field is required.'});
                   name = false;
        }else if ((this.isString(value)==false) || (!value.replace(/\s/g, '').length)) {
                       this.setState({nameerror: 'Name is not valid.'});
                   name = false;
        }else{
                      this.setState({ nameerror: ''});
        }
        return name;
   }
   validatePhone(value){
    var phone = true;
    if (value.length<3) {
                    this.setState({phoneerror: 'Field is required.'});
                     phone = false;
              }else{
                  var count = value.length;
                    if(count>3 && count<5){
                        var ph=value;
                        if (value.indexOf('-') > -1)
                        {
                            ph = value;
                        }else{
                            var ph = value.replace(/(\d{3})/, '$1-');
                        }
                         this.state.modelNumber=ph;
                        document.getElementById("modelNumber").value=ph;
                    }
                    if(count>7){
                        var st= value.replace(/\-/g,"");
                        var ph1 = st.replace(/(\d{3})(\d{3})/, '$1-$2-'); 
                        document.getElementById("modelNumber").value=ph1;
                    }
                    if(count<13){
                        if (this.isPhoneNumber(value)==false) {
                        this.setState({phoneerror: 'Phone number is not valid.'});
                        phone = false;
                        }else{
                            this.setState({modelNumber:value}); 
                            this.setState({phoneerror: ''});   
                        }
                    }
              }
              return phone;
}
isPhoneNumber(phone){
    var reg = /\d{3}-\d{3}-\d{4}/;
    if(reg.exec(phone))
        return true;
    return false;         
}
   validateStepOne(){
    return this.state.modelName.length > 0 ;
   }
   validateNumber(){
    return this.state.modelNumber.length == 12 && this.state.validationStatus==true;
   }

   isString(str){
    if(/^[a-zA-Z' ' ]+$/.test(str))
    return true;
    else 
    return false;
}
    handleSubmitName(event) {
       
        event.preventDefault();
        let whereFind = [{
            url: "/onboardings/"+this.props.contentProp.onboarding.id,
        }];
        let name = this.state.modelName.replace(/\s+/g,' ').trim();
        
        let data=this.props.contentProp.onboarding;
        data.first_name=name;
        postApiData(whereFind,{'first_name':name},true).then(res => {
            this.setState({modelName:name})
           // this.state.modelName=name;
            document.getElementById("myNameClose").click();
        })
        .catch(error => {
          
        }); 
        
    }

    handleSubmitNumber(event) {
        event.preventDefault();
        let whereFind = [{
            url: "/onboardings/"+this.props.contentProp.onboarding.id,
        }];

        let key = encHex.parse("0123456789abcdef0123456789abcdef");
            let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
            var mob_number=aes.encrypt(this.state.modelNumber, key, {iv:iv, padding:padZeroPadding}).toString();

        let phone_number = mob_number;
        postApiData(whereFind,{'phone_number':phone_number},true).then(res => {
           
            this.state.modelNumber= aes.decrypt(phone_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8);
            
            document.getElementById("myNumberClose").click();
        })
        .catch(error => {
            
        }); 
        
    }

    handleSubmitAgentName= event => { 
        event.preventDefault();
        let whereFind = [{
            url: "/onboardings/"+this.props.contentProp.onboarding.id,
        }];
        let agent_id = this.state.agent_id;
        let agent_name=this.state.modelAgentName;
        postApiData(whereFind,{'agent_id':agent_id},true).then(res => {
            this.setState({modelAgentNameFront:agent_name},()=>{
                document.getElementById("myAgentClose").click(); 
            })
             
        })
        .catch(error => {
            
        }); 
    }


    //image upload
    fileChangedHandler = (event) => {
        if(event.target.files[0]){
         let imgFlag = true;
        const maxFileSize = 5242880;   //5mb
        const value = event.target.files[0].name;
        const ext = value.substring(value.lastIndexOf('.'));
        if(!['.jpg','.jpeg','.JPG','.png','.PNG'].includes(ext)){
            this.setState({mymsg: ext+' is not supported file extension.'});
            imgFlag = false;
        }
        if(event.target.files[0].size>maxFileSize){
            this.setState({mymsg:"File size is too big, upto 5MB allowed."})
            imgFlag = false;
            }
        if(imgFlag) { 
             this.setState({
                 selectedFile:event.target.files[0], 
                 imagePath2: URL.createObjectURL(event.target.files[0]) 
                })
        }
     }            
    }
    handleSubmit = event => {
        event.preventDefault();
        this.profileData();
    }

    
    async profileData() {
        console.log('profileData');
        let where = [{
            url: "/containers/profile/upload",
        }];
        const formData = new FormData();
        formData.append('file',this.state.selectedFile)
        postApiData(where, formData).then(res => {
            if(res.status==200 || res.statusText=='OK' && res.data.file_name!='undefined' &&res.data.file_name!='')
            {
                let whereFind = [{
                    url: "/ClientRels",
                }];
                const formData1 = new FormData();
                let data={
                    "clientId":this.props.contentProp.onboarding.clientId,
                    "imagePath":res.data.file_name
                };
                if(this.state.clientRel>0){
                    data.id=this.state.clientRel;
                }
                postApiData(whereFind,data,'post',true).then(response => {
                    var imgsrc=getBaseUrl()+"/containers/profile/download/"+res.data.file_name;                    
                    this.setState({pageLoader: true, "imagePath":imgsrc},()=>{
                        localStorage.setItem('imageUrl',imgsrc); 
                        this.setState({
                            pageLoader: false
                        })
                    });  
                      document.getElementById("myProfileClose").click(); 
                })
                .catch(error => {
                    console.log('in catch', error);
                });  
            }
        })
            .catch(error => {
                
            });

            
    }
    async getProfile(){
    let clientRelWhere = [{
        url: "/ClientRels",
        urlParms: "?filter="+JSON.stringify({"where":{"clientId":getUserId()}})+"&access_token=" + getToken(),
    }];
getApiData(clientRelWhere,true).then(res => {
    if(res.data.id){
        this.setState({'clientRel':res.data.id});
        var imgsrc=getBaseUrl()+"/containers/profile/download/"+res.data.imagePath;
        this.setState({"imagePath":imgsrc});  
    }
}).catch(error => {
    console.log('in catch', error);
   if(error.response){
       this.setState({myerrormsg: error.response.data.error.message});
   }
});
    }
    //model end	
	handleClickOnAgent=param=>e=>{
		var value=param[0], id = param[1];
		this.getAgentData(value, id);
	}
        handleAgentSearch = event => {
            this.setState({searchagentMsg:false}); 
    var key=event.target.id; 
    switch(key){
        case 'agent_search_id':
        this.formValidate('agent_search_id',event.target.value);
                this.setState({modelAgentName: event.target.value},()=>{
                var value=this.state.modelAgentName;
                var len = this.state.agentDW.length;
                var count=0;
                var vflag=0;
                if(value!=''){
                    this.state.agentDW.forEach(function(agent,index) {
                        if(agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
                                agent.flag=1;
                                vflag=1;
                                count=count+1;
                            }else{
                                agent.flag=0;
                                if(vflag!=1)
                                vflag=0
                                count=count+1;
                            }
                        }); 
                }else{
                    document.getElementById('agent_search_id_values').style.display = 'none';
                    this.state.agentDW.forEach(function(agent,index) {
                        agent.flag=0;
                    });
                    
                }

                if(len==count){
                    if(vflag==0){
                        this.setState({searchagentMsg:true});
                        
                    }else{
                        this.setState({searchagentMsg:false});
                        
                    }

                    
                }
            })
							
				              
                //}
		break;
        case 'searchkey':
        this.formValidate('agent_search_id',event.target.value);
		var value=event.target.value;
                let $this = this;
                var len = this.state.agentDW.length;
                var count=0;
                var vflag=0;
			this.setState({ agent_id: event.target.value}, () => {
				this.state.agentDW.forEach(function(agent,index) {
					if(agent.id==value){
                    agent.flag=1;
                    vflag=1;
                    count=count+1;
					    document.getElementById('agent_search_id_values').style.display = "none";
                                            document.getElementById('agent_search_id').value=agent.name;
                                            $this.setState({modelAgentName:agent.name});
					}else{
                    agent.flag=0;
                    if(vflag!=1)
                    vflag=0
                    count=count+1;
					}
                });
                if(len==count){
                    if(vflag==0){
                        this.setState({searchagentMsg:true});
                        
                    }else{
                        this.setState({searchagentMsg:false});
                    }
                    
                }
				document.getElementById('agent_search_id_values').style.display = "none";
			  });
		break;
		case 'default':
		break;
		 
    }
document.getElementById('agent_search_id_values').style.display = "block";
}
	getAgentData(value,id){
		this.setState({ modelAgentName: value,agent_id: id}, () => {
			this.state.agentDW.forEach(function(agent,index) {
			if(agent.name==value){
					document.getElementById('agent_search_id').value=value;
                                        document.getElementById('agent_search_id_values').style.display = "none";
				}else{
					agent.flag=0;
				}
			});
		  });
        }
    clearState=()=>{
        if(this.state.imagePath2){
            this.setState({
                imagePath2:''
            })
        }
    }
    setAgent=()=>{
        var name=this.state.modelAgentNameFront;
        this.setState({
        modelAgentName:name,
        searchagentMsg:false,
        agenterror:'',
        validationStatus:true,
        })
        document.getElementById('agent_search_id_values').style.display = "none";
    }
      
    render() {
  if(this.props.contentProp.onboarding.is_completed==0){
             return (<Redirect to={'/client/onboarding'}/>);
  }
  const agentDW = this.state.agentDW.map((aw,index) => 
                (aw.flag==1) ? <li id="searchkey" key={aw.id} value={aw.id} onClick={this.handleClickOnAgent([aw.name,aw.id])}>{aw.name}</li>:''
        );
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv =  encHex.parse("abcdef9876543210abcdef9876543210");   
        return (
            <div>
            <Header />
                <main>
                 <div className='container'>
                 <div  className="form-panel">                            
                            <h2 className="text-center">Edit profile</h2>
                            <div className='form-widget login-widget edit-profile'>
                                <div className="message"><div>{this.state.modelName} <Link to={``} data-toggle="modal" data-target="#myName"><i className="fa fa-pencil" aria-hidden="true"></i></Link></div></div>
                                <div className="form-section">
                                <form onSubmit={this.handleSubmit} encType="multipart/form-data">
                                    <div className="clearfix">
                                        <div className="info-wrap pull-left">
                                                <div className="heading-wrap">
                                                Contact
                                                </div>
                                                <p>{aes.decrypt(this.props.contentProp.userDetails.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</p>
                                                <p><span>{this.state.modelNumber}</span>  <Link to="" data-toggle="modal" data-target="#myno"><i className="fa fa-pencil" aria-hidden="true"></i></Link></p>
                                        </div>
                                        <div className="img-wrap pull-right">
                                        <img src={`${this.state.imagePath}`} width="90" height="90" className="mx-auto d-block rounded-circle"/>
                                            <span className="edit">
                                            <Link to="" data-toggle="modal" data-target="#myPic"><i className="fa fa-pencil"  onClick={this.clearState} aria-hidden="true"></i></Link> 
                                        </span>
                                        </div>
                                    </div>
                                    </form>
                                    <div className="clearfix agent-wrap"><div className="heading-wrap">Agent</div>
                                        <div className="info-wrap pull-left">
                                                <p>{(this.state.modelAgentNameFront)?this.state.modelAgentNameFront:'Josiah Hyatt'}{(this.state.count_app>0)?'':<Link to="" data-toggle="modal" data-target="#myAgent"><i className="fa fa-pencil" aria-hidden="true" onClick={this.setAgent}></i></Link>}</p>
                                        </div>
                                        <div className="img-wrap pull-right">
                                            <img src="/member.png" width="65" height="65" className="mx-auto d-block rounded-circle"/>
                                        </div>
                                        <div className="clearfix"></div>
                                       <Link to={`/client/change-password`} className="btn btn-default" >Change Password </Link>
                                    </div>
                                </div>  
                            </div>
                        </div>
                           </div>
                        <div className="modal fade" id="myAgent">
                            <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Search Agent</h4>
                                <button type="button" className="close" data-dismiss="modal" id="myAgentClose">&times;</button>
                                </div>
                                <div className="modal-body">
                                <form id="frm3" onSubmit={this.handleSubmitAgentName}>
                                <div className="box">						
					<input type="text" id="agent_search_id" onChange={this.handleAgentSearch} onKeyDownCapture={this.handleAgentSearch} onKeyUpCapture={this.handleAgentSearch}  autoComplete="off" value={this.state.modelAgentName}  className="form-control"/>
                                        <div className="box agentNameOnBoard" id="agent_search_id_values" style={{display:'none'}} >
                                              <ul>{agentDW}</ul>
                                              <sapn className="inline-error-class">{(this.state.searchagentMsg)?this.state.modelAgentName + ' is not a registered agent.':''}</sapn>
                                        </div>
                                        <span className="inline-error-class"> {(this.state.agenterror !='')?this.state.agenterror:''} </span>
				</div>
                                    <br/>
                                    <button className="btn btn-default" disabled={!this.state.validationStatus}>Save</button> 
                                    </form> 
                                <div id="app"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="modal fade" id="myPic">
                            <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit</h4>
                                <button type="button" id="myProfileClose" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                     <h5 className="text-center">Update Picture</h5> 
                                     <form onSubmit={this.handleSubmit} encType="multipart/form-data">
                                    <div className="clearfix">
                                        <div className="info-wrap pull-left">
                                        <img src={this.state.imagePath2 ? this.state.imagePath2 : this.state.imagePath} width="120" height="120" className="mx-auto d-block rounded-circle"/>
                                        <div className="img-wrap">
                                        <FormGroup controlId="imageUpload">
                                        <FormControl type="file" onChange={this.fileChangedHandler} required style={{marginTop: '10px',width:'230px'}}/>
                                        </FormGroup>
                                             <Link to="" className="edit"><i className="fa fa-pencil" aria-hidden="true"></i></Link> 
                                        </div>
                                        </div>
                                    </div>
                                    <p className="text-center"><Button type="submit">Save</Button></p>
                                    </form>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="modal fade" id="myno">
                            <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit</h4>
                                <button type="button" id="myNumberClose" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                     <label>Edit Number</label>  
                                     <form id="frm2" onSubmit={this.handleSubmitNumber}>
                                    <input 
                                    type='text'
                                    id="modelNumber"
                                    required 
                                    maxLength="12"
                                    value={this.state.modelNumber}
                                    onChange={this.handleChange}
                                    style={{marginBottom: '10px'}}
                                    /><br/>
                                    <span className="inline-error-class"> {this.state.phoneerror !==''?this.state.phoneerror:''} </span>
                                    <br/>
                                    <button className="btn btn-default" disabled={!this.validateNumber()}>Save</button> 
                                    </form> 
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="modal fade" id="myName">
                            <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                <h4 className="modal-title">Edit</h4>
                                <button type="button" id="myNameClose" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                     <label>Edit Name</label>   
                                    <form id="frm1" onSubmit={this.handleSubmitName}>
                                    <input 
                                    type='text'
                                    id="modelName"
                                    required 
                                    value={this.state.modelName}
                                    onChange={this.handleChange}
                                    style={{marginBottom: '10px'}}
                                    maxLength={40}
                                    />
                                    <span className="inline-error-class"> {this.state.nameerror !==''?this.state.nameerror:''} </span>
                                 <button className="btn btn-default" disabled={!this.state.validationStatus}>Save</button> 
                                    </form>
                                </div>
                            </div>
                            </div>
                        </div>
                </main>
        </div>

                );
    }
}
export default Profile;
