import React from "react"
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import fetch from 'isomorphic-fetch'



const MapWithAMarkers = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?sensor=false&region=IN",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentDidMount() {
          
            this.setState({

                zoomToMarkers: map => {
                    console.log("Zoom to markers");
                    const bounds = new window.google.maps.LatLngBounds();
                    map.props.children.forEach((child) => {
                        if (child.type === Marker) {
                            bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                        }
                    })
                    map.fitBounds(bounds);
                    //map.panToBounds(bounds);     //auto-center
                }
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap ref={props.zoomToMarkers} defaultZoom={5} defaultCenter={{ lat: 25.0391667, lng: 121.525 }}>
        {props.markers.map(marker => (
            <Marker
                key={marker.id}
                position={{lat: marker.lat, lng: marker.lng }}
            />
        ))}
    </GoogleMap>
    );

class MapComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        console.log("this.props=>",this.props);
                this.state = {
                    markers:[],
                }
        }



    componentWillMount() {
        //this.setState({ markers: [] })
    }


    componentDidMount() {
        const url = [
            // Length issue
            `https://gist.githubusercontent.com`,
            `/vgrem/e2e488fbe70372854174aba38cd3d820`,
            `/raw/71a5a528bb229c2bf9dd49c4c2d6d8d080245d33/data.json`
        ].join("")

        fetch(url)
            .then(res => res.json())
            .then(data => {
                this.setState({ markers: data });
            });
    }

    setNewMarker=()=>{
        let data=[{lat: 28.704060, lng: 77.102493, id: 1}];

        this.setState({

            zoomToMarkers: map => {
                console.log("Zoom to markers");
                const bounds = new window.google.maps.LatLngBounds();
                data.children.forEach((child) => {
                    if (child.type === Marker) {
                        bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                    }
                })
                map.fitBounds(bounds);
                //map.panToBounds(bounds);     //auto-center
            }
        })
//                this.setState({ markers: data});
    }

    render() {
        return (
            <div>
            {(this.state.markers.length > 0)?<MapWithAMarkers markers={this.state.markers} />:''}
            <button onClick={this.setNewMarker}>Click Here For new marker</button>
            </div>
        )
    }
}

export default MapComponent;