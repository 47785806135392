import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken, postApiData, getApiData} from '../../services/Api';
import {validatePhone} from '../../services/validation';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import axios from 'axios';

 
class AdminAirconditionEdit extends Component {
    constructor(props){
        super(props);
      
        this.state={
            routeflag:true,
            airconditiontypeList:[],
            //Form Fields States
            name:'',
           
            successQuote:false,
            AddAddressPanel: false,
            id:this.props.match.params.id,
            //Error
            office_name_error:'',
           
            timings_error:'',
            isMarkerShown: false,
           
            updateMsg:''
        }
    }

    componentWillMount =()=>{
        window.scroll(0,0);
        this.getAirCondTypeId();
    }

    handleChange=(e)=>{
        const {name} = this.state;
        switch(e.target.id){
            case 'name':
                if(e.target.value == ''){ this.setState({ office_name_error: 'Field cannot be empty'})  
                }else{ this.setState({ office_name_error: ''}) }
            break;
           
        }
        this.setState({ [e.target.id] : e.target.value})
    }

   
    

    //Handle Insertion of Address information in DB
    handleAirCondSubmit=(e)=>{
        const {name} = this.state;
        e.preventDefault();
        this.updateAirCondType(name)
    }
    async updateAirCondType(name) {
        let where = [{
                url: `/admin/updateAirConditionType/${this.state.id}`
            }];
            postApiData(where, {"air_conditioning_type": name},true).then(res => {
                if(res.data==202){
                    this.setState({ office_name_error: 'Field cannot be empty'});
                }
                else
                {
                this.setState({'updateMsg':res.data.message})
                        this.setState({ 'pageLoader':true},()=>{
                            this.setState({'pageLoader':false, "successQuote": true,  });
                            setTimeout(
                                function() {
                                    this.setState({'updateMsg':''});
                                }
                                .bind(this),
                                2000
                            ); 
                        })  
                    }
        }).catch(error => {
           this.setState({myerrormsg: error.response});
        });
    }

    validateForm() {
        return (this.state.name.trim().length != '' && this.state.name.trim()!='') 
    }

     getAirCondTypeId=()=>{
      
        this.setState({'pageLoader':true});
        let whereEditor = [{
			url: "/admin/aircondtypeDetail",
			where:{
				'id':this.state.id
			}
		}];
		getApiData(whereEditor,true).then(res => {
          var data = res.data[0];
            this.setState({
                name :data.air_conditioning_type,
               
            },()=>{
                this.setState({
                    'pageLoader':false,
                })
            })
		}).catch(error => {
				if(error.res){
					this.setState({myerrormsg: error.res.data});
				}
			});
    }

    
   
    render() {
        const { name} = this.state;
        if (!checkAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }        
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">Air Condition Type</h2>
                {(this.state.deleteMsg) ?(<div className="alert alert-success"><strong>{this.state.deleteMsg}</strong></div>):(null)}
                        <Link to="/admin/airconditiontype" className="btn btn-default pull-right " >  Back</Link>
                        <div className="panel-body table-responsive">
                       
                        <Fragment>
                        <table className="table table-striped">
                            <thead><tr><th className="managerGridCol"> Edit Air Condition Type</th></tr></thead>
                        </table>
                                
                                <form onSubmit={this.handleAirCondSubmit} style={{maxWidth:"70%", margin:"10px auto"}}>
                                {(this.state.updateMsg)?(<div className="alert alert-success"><strong>{this.state.updateMsg}</strong></div>):(null)} 
                                 
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label forhtml="name">Type Name</label>
                                            <input type="text" className="form-control" id="name" onChange={this.handleChange} value={name} placeholder="Type Name" autoComplete="off"/>
                                            <span className="inline-error-class"> {this.state.office_name_error !==''?this.state.office_name_error:''}</span>
                                        </div>
                                        
                                        </div>

                                        <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={!this.validateForm()}>Submit</button>
                                     </div>
                                       
                             
                                    </form>
                        </Fragment>
                      
                            </div>
                    </div>
                 
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default AdminAirconditionEdit;