import axios from "axios";
export function getBaseUrl() {
   //return "http://localhost:3001";               // local
  //   return "https://dev.moveskyward.com";       // dev
  //return "https://stage.moveskyward.com"; // stag

  return "https://www.moveskyward.com";    // prod
  //return "https://www.moveskyward.com"; // prod copy
}

export function setToken(token) {
  localStorage.setItem("token", token);
}
export function getToken() {
  return localStorage.getItem("token");
}
export function setAgentToken(token) {
  localStorage.setItem("agent_token", token);
}
export function getAgentToken() {
  return localStorage.getItem("agent_token");
}
export function setManagerToken(token) {
  localStorage.setItem("manager_token", token);
}
export function getManagerToken() {
  return localStorage.getItem("manager_token");
}

export function setAdminToken(token) {
  localStorage.setItem("admin_token", token);
}
export function getAdminToken() {
  return localStorage.getItem("admin_token");
}

export function setAgentName(name) {
  return localStorage.setItem("agent_name", name);
}
export function getAgentName() {
  return localStorage.getItem("agent_name");
}
export function setAgentEmail(name) {
  return localStorage.setItem("agent_email", name);
}
export function getAgentEmail() {
  return localStorage.getItem("agent_email");
}
export function setAgentImage(image) {
  return localStorage.setItem("agent_image", image);
}
export function setAgentDomain(domain) {
  return localStorage.setItem("agent_domain", domain);
}
export function getAgentDomain() {
  return localStorage.getItem("agent_domain");
}
export function getAgentImage() {
  return localStorage.getItem("agent_image");
}
export function setManagerName(name) {
  return localStorage.setItem("manager_name", name);
}
export function getManagerName() {
  return localStorage.getItem("manager_name");
}
export function setManagerEmail(email) {
  return localStorage.setItem("manager_email", email);
}
export function getManagerEmail() {
  return localStorage.getItem("manager_email");
}
export function setUserId(id) {
  localStorage.setItem("client_id", id);
}
export function setOnBoardingDone(value) {
  localStorage.setItem("is_onboarding_done", value);
}
export function setPasswordSetDone(value) {
  localStorage.setItem("is_password_set", value);
  console.log("setPasswordSetDone");
}
export function getPasswordSetDone() {
  return localStorage.getItem("is_password_set");
}
export function getUserId() {
  return localStorage.getItem("client_id");
}
export function setAgentId(id) {
  localStorage.setItem("agent_id", id);
}
export function getAgentId() {
  return localStorage.getItem("agent_id");
}
export function setAgent_number(number) {
  localStorage.setItem("agent_number", number);
}
export function getAgent_number() {
  return localStorage.getItem("agent_number");
}
export function getOnBoardingDone() {
  return localStorage.getItem("is_onboarding_done");
}
export function getOnboardingData() {
  var data = localStorage.getItem("onboarding_data");
  return JSON.parse(data);
}

export function setOnboardingData(data) {
  //    var data = {name:"mamta", signature:"MS"};
  localStorage.setItem("onboarding_data", JSON.stringify(data));
}
export function unsetOnboardingData() {
  localStorage.removeItem("onboarding_data");
}
export function removeToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("imageUrl");
  localStorage.removeItem("client_id");
  localStorage.removeItem("is_onboarding_done");
}

export function setManagerId(id) {
  return localStorage.setItem("manager_id", id);
}
export function getManagerId() {
  return localStorage.getItem("manager_id");
}

export function setOnboardingId(id) {
  localStorage.setItem("client_onboarding_id", id);
}
export function getOnboardingId() {
  return localStorage.getItem("client_onboarding_id");
}

export function getApiData(where, useToken = false) {
  var url = getBaseUrl() + where[0].url;
  if (where[0].urlParms) {
    url = url + where[0].urlParms;
  }
  var headers = {
    Accept: "application/json",
  };
  if (useToken) {
    if (getManagerToken()) {
      headers.Authorization = "Bearer " + getManagerToken();
    }
    if (getAgentToken()) {
      headers.Authorization = "Bearer " + getAgentToken();
    }
    if (getToken()) {
      headers.Authorization = "Bearer " + getToken();
    }
    if (getAdminToken()) {
      headers.Authorization = "Bearer " + getAdminToken();
    }
  } else {
    headers.Authorization = "Bearer ";
  }

  if (where[0].where) {
    headers.filter = JSON.stringify(where[0].where);
  }
  return axios({
    url: url,
    method: "get",
    headers: headers,
  });
}
export function postApiData(where, form, useToken = false) {
  var url = getBaseUrl() + where[0].url;
  if (where[0].plaid) {
    url = where[0].url;
  }
  if (where[0].urlParms) {
    url = url + "/" + where[0].urlParms;
  }
  var headers = {
    Accept: "application/json",
  };
  if (useToken) {
    if (getManagerToken()) {
      headers.Authorization = "Bearer " + getManagerToken();
    }
    if (getAgentToken()) {
      headers.Authorization = "Bearer " + getAgentToken();
    }
    if (getToken()) {
      headers.Authorization = "Bearer " + getToken();
    }
    if (getAdminToken()) {
      headers.Authorization = "Bearer " + getAdminToken();
    }
  }

  return axios({
    url: url,
    method: "post",
    headers: headers,
    data: form,
  });
}

export function putApiData(where, form) {
  var url = getBaseUrl() + where[0].url;
  if (where[0].urlParms) {
    url = url + "/" + where[0].urlParms;
  }

  return axios({
    url: url,
    method: "put",
    headers: {
      Accept: "application/json",
    },
    data: form,
  });
}

export function deleteApiData(where) {
  var url = getBaseUrl() + where[0].url;
  if (where[0].urlParms) {
    url = url + "/" + where[0].urlParms;
  }

  return axios({
    url: url,
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });
}

export function manageRedirect() {
  var url = "";
  if (!getToken()) {
    url = "/client/login";
  }
  return url;
}

export function checkAdminToken() {
  if (localStorage.getItem("adminUserDetails")) {
    return true;
  } else {
    return false;
  }
}
