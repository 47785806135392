import { getBaseUrl } from "./Api";
const axios = require("axios");
const baseUrl = getBaseUrl();  
export const loadInittialFile = async (token, filePath) => { 
  try {
    const response = await axios.post(`${baseUrl}/modifypdf/loadfile`, { filePath }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    }); 
    return response.data;
  } catch (error) {
    console.error(
      "Error loading file:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
 
export const addNewFileToMerge = async (token, formData) => { 
  try {
    const response = await axios.post(`${baseUrl}/modifypdf/addnew`, formData, {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    }); 
    return response.data;
  } catch (error) {
    console.error(
      "Error uploading file:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const downloadFinalPdf = async (token, formData) => { 
  try {
    const response = await axios.post(`${baseUrl}/modifypdf/merge`, formData, {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    }); 
    return response.data;
  } catch (error) {
    console.error(
      "Error uploading file:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
