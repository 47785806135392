import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken} from '../../services/Api';

class Edit extends Component {
    constructor(){
        super();
        this.state={
            routeflag:true
        }
    }

    

    render() {
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                        <h2 className="pull-left">Edit</h2>
                        <button className="btn btn-default pull-right">Reset All Filter</button>
                        <div className="panel-body table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="managerGridCol" colSpan='5'>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                <form style={{    maxWidth: '50%', margin: '10px auto'}}>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Email address</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
                                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputPassword1">Password</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"/>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                    </form>
                                </tbody>
                                </table>
                            </div>
                    </div>
                    
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default Edit;