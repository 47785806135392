import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken, postApiData, getApiData} from '../../services/Api';
import {validatePhone} from '../../services/validation';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import axios from 'axios';

 
class OnwerPayBrokerEdit extends Component {
    constructor(props){
        super(props);
      
        this.state={
            routeflag:true,
            airconditiontypeList:[],
            //Form Fields States
            name:'',
            building_document:'',
            successQuote:false,
            AddAddressPanel: false,
            id:this.props.match.params.id,
            //Error
            document_name_error:'',
            building_document_error:'',
            isMarkerShown: false,
            updateMsg:'',
        }
    }

    componentWillMount =()=>{
        window.scroll(0,0);
        this.getownerpayById();
    }

    handleChange=(e)=>{
        const {name,building_document} = this.state;
        switch(e.target.id){
            case 'name': 
                if(e.target.value == ''){ 
                    this.setState({ document_name_error: 'Document Name cannot be empty.'}) 
                }else{ 
                    this.setState({ document_name_error: ''}) 
                }
            case 'building_document': 
                if(e.target.value == ''){ 
                    this.setState({ building_document_error: 'Select building document status.'}) 
                }else{ 
                    this.setState({ building_document_error: ''}) 
                }
            break;
           
        }
        this.setState({ [e.target.id] : e.target.value})
    }

    handleOwnerPaySubmit=(e)=>{
        const {name,building_document} = this.state;
        e.preventDefault();
        this.updateOwnerPayType(name,building_document)
    }
    async updateOwnerPayType(name,building_document) {
        let where = [{
            url: `/admin/updateDocument/${this.state.id}`
        }];
        if(name == ""){
            this.setState({ document_name_error: "Document Name cannot be empty."});
        }else if(building_document == ''){
            this.setState({ building_document_error: "Document status cannot be empty."});
        }else{
            postApiData(where, {"document_name": name,"document_flag":building_document},true).then(res => {
                if(res.data==202){
                    this.setState({ document_name_error: 'Field cannot be empty'});
                }
                else
                {
                    this.setState({'updateMsg':res.data.message})
                    this.setState({ 'pageLoader':true},()=>{
                        this.setState({'pageLoader':false, "successQuote": true,  });
                        setTimeout(
                            function() {
                                this.setState({'updateMsg':''});
                            }
                            .bind(this),
                            2000
                        ); 
                    })  
                }
            }).catch(error => {
                this.setState({myerrormsg: error.response});
            });
        }
    }

    validateForm() {
        return (this.state.name.trim().length != '' && this.state.name.trim()!=''  ) 
    }

    getownerpayById=()=>{
      
        this.setState({'pageLoader':true});
        let whereEditor = [{
			url: "/admin/getDocumentDetails",
			where:{
				'id':this.state.id
			}
		}];
		getApiData(whereEditor,true).then(res => {
          var data = res.data[0];
            this.setState({
                name :data.document_name,
                building_document :data.document_flag,
            },()=>{
                this.setState({
                    'pageLoader':false,
                })
            })
		}).catch(error => {
				if(error.res){
					this.setState({myerrormsg: error.res.data});
				}
			});
    }

    
    backToAddressPanel=()=>{
       
        this.setState({
            pageLoader: true
        },()=>{
            this.setState({  pageLoader: false,  AddAddressPanel: !this.state.AddAddressPanel, successQuote :false,  })
        })
    }

    render() {
        const { name} = this.state;
        if (!checkAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }  

        var style={
            dropdown:{
                display:'block',
                backgroundColor: '#004f6b',
                borderRadius: '5px',
                color: 'white',
                width: '120px',
                height: '40px'
            }
        }      
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">Manage Documents</h2>
                {(this.state.deleteMsg) ?(<div className="alert alert-success"><strong>{this.state.deleteMsg}</strong></div>):(null)}
                        <Link to="/admin/manage-documents" className="btn btn-default pull-right " >  Back</Link>
                        <div className="panel-body table-responsive">
                       
                        <Fragment>
                        <table className="table table-striped">
                            <thead><tr><th className="managerGridCol"> Edit Documents</th></tr></thead>
                        </table>
                                
                                <form onSubmit={this.handleOwnerPaySubmit} style={{maxWidth:"70%", margin:"10px auto"}}>
                                {(this.state.updateMsg)?(<div className="alert alert-success"><strong>{this.state.updateMsg}</strong></div>):(null)} 
                                 
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label forhtml="name">Document Name</label>
                                            <input type="text" className="form-control" id="name" onChange={this.handleChange} value={name} placeholder="Document Name" autoComplete="off"/>
                                            <span className="inline-error-class"> {this.state.document_name_error !==''?this.state.document_name_error:''}</span>
                                        </div>
                                        
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label forhtml="name">Building Document</label>
                                            <select id="building_document"  onChange={this.handleChange}  style={style.dropdown}>
                                                <option value="">Select status</option>
                                                <option selected={(this.state.building_document == 1)?"selected":''} value="1">Yes</option>
                                                <option selected={(this.state.building_document == 0)?"selected":''} value="0">No</option>
                                            </select>
                                            <span className="inline-error-class"> {this.state.building_document_error !==''?this.state.building_document_error:''}</span>
                                        </div> 
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={!this.validateForm()}>Submit</button>
                                     </div>
                                       
                             
                                    </form>
                        </Fragment>
                      
                            </div>
                    </div>
                 
                </div>
            </div>	
            </main>     
        </div>
        );
    }
}

export default OnwerPayBrokerEdit;