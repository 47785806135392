import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import Header from '../../common/Header';
import { getApiData, getToken, getUserId,getOnBoardingDone,getBaseUrl,postApiData} from '../../../services/Api';
import { Redirect } from 'react-router';
import { getFormatDateMDY } from '../../../services/Util';
import Modal from 'react-responsive-modal';
import Moment from 'react-moment';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class PlaidDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
                
                app_id:props.app_id,
                plaid_trans:[],
                plaid_Acc:[],
                openBank:false,
                openAdditional:false,
                bankName:"",
                loaderStatus:false,
                flagRemove:this.props.dashboard

        };
    }

    componentWillMount() {
        window.scroll(0,0);
            if(this.state.bankName ===""){
                return this.getBankDetails()
            }               
    }

    getBankDetails = () =>{
        let plaidInfo = [{
            url: "/applications/plaidInfo/"+this.state.app_id,
        }];
            getApiData(plaidInfo, false).then(res => {
                this.setState({plaid_trans:res.data,loaderStatus:true});
            }).catch(error => {
            });
            
            let plaidLogo = [{
                url: "/applications/plaidlogo/"+this.state.app_id,
            }];
                getApiData(plaidLogo, false).then(res => {
                    this.setState({
                        bankName: res.data[0].inst_name,
                    });
                }).catch(error => {
                    console.log('in  catch',error);
                });

                  let plaidAcc = [{
                        url: "/applications/plaidAccount/"+this.state.app_id,
                    }];
                        getApiData(plaidAcc, false).then(res => {
                            this.setState({plaid_Acc:res.data});
                        }).catch(error => {
                            console.log('in  catch',error);
                        });

    }

    componentDidMount() {
     
    }
    
    onOpenBankModal = () => {
      this.setState({ openBank: true });
    };

    onCloseBankModal = () => {
      this.setState({ openBank: false });
    }; 
    
    onOpenAdditionalModal = () => {
      this.setState({ openAdditional: true });
    };

    onCloseAdditionalModal = () => {
      this.setState({ openAdditional: false });
    }; 
  
removePdf=()=>{
  var msg = window.confirm("Are you sure want to remove statement ?")
    if(msg==true){

    console.log("this.state.app_id",this.state.app_id)

    let where=[{
        url:"/applications/removepdf/"+this.state.app_id
    }];

   getApiData(where,true).then(res=>{
            console.log("--res",res);
           document.getElementById('close').click();
           this.props.handleLocationSubmit(0);
    }).catch(error=>{

    })}
}



    render() {
        var pdfpath =getBaseUrl()+"/containers/plaid_pdf/download/"+this.state.app_id+".pdf";
    //     var bankImageName = ((this.state.bankName).toLocaleLowerCase()).replace(/ /g,'') + ".png";
        
    //     const { app_id } = this.state;

    //     if(!this.state.plaid_trans){
    //        return (<div></div>);
    //    }
    //    const plaidAcc= this.state.plaid_Acc.map((account, index)=>{
    //     var flag=0
    //        return <Fragment>
    //               <tr><td colSpan="3" style={{textTransform: "capitalize"}}><strong>{"Account Type: "+account.account_sub_type}</strong></td></tr>

    //               <tr>
    //                   <td colSpan="3">
    //                     <div style={{float:'left', width:'50%', fontWeight:'600'}}>
    //                     {account.available_balance != "null" ?('Availbale Balance :'+'$'+account.available_balance) : ('Availbale Balance :'+' --- ')}
    //                     </div>
    //                     <div style={{float:'right', width:'50%', fontWeight:'600'}}>
    //                     {account.current_balance != "null" ? ('Current Balance :'+'$'+account.current_balance) : ('Current Balance :'+' --- ')}
    //                     </div>
    //                   </td>
    //               </tr>

    //               <thead style={{display:"contents"}}>

    //               {this.state.plaid_trans.map((trans)=>{
    //                   if(flag!=1 && trans.account_id === account.account_id){
    //                     flag = 1
    //                    return <tr className='table-light'>
    //                             <th>DATE</th>
    //                             <th>DESCRIPTION</th>
    //                             <th>AMOUNT</th>
    //                         </tr>
    //                   }
    //                 })
    //             }
    //             {!flag && (<tr><th colSpan='3'>No Transaction History</th></tr>)}

    //                 </thead>
    //                 <tbody style={{display:"contents"}}>
    //                     {this.state.plaid_trans.map((trans, index)=>{
    //                     if(account.account_id === trans.account_id){
    //                         return  <tr>
    //                                     <td><Moment format="MM/DD/YYYY" date={new Date(trans.trans_date)}/></td>
    //                                     <td >{trans.name}</td>
    //                                     <td>{trans.amount}</td>
    //                                 </tr> 
    //                         }
    //                     })}
    //                     <tr colSpan="3" style={{height:"30px"}}></tr>
    //                 </tbody>
    //               </Fragment>
    //          });
        return (
            <div className="modal-content client-modal">
            <embed src={pdfpath} width="600" height="500" alt="pdf"></embed>
            {/* {(!this.state.loaderStatus)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
            <div className="modal-header">
            <h4 className="modal-title" style={{margin:'auto 0'}}>Consolidated Transaction Statement from {this.state.bankName}</h4>
            <img src={'/bank_logo/' + bankImageName} width="95px" alt="Skywards"></img>
            </div>
                <div className="modal-body profile">
                    <div className="recipt">
                         <table className="table">
                            <tbody>
                                {plaidAcc}
                            </tbody>
                            </table>
                    </div>
                </div> */}
                <p className="text-right" style={{marginRight:'30px'}}><button type="button" id="close" className="btn cl-btn" onClick={this.props.onClick} >Close  <img src="/close.png"/></button></p>
               {this.state.flagRemove!=="true"  ?   
                <p className="text-right" ><button type="button" className="btn cl-btn" onClick={this.removePdf} >Remove  </button></p>
                :""}
                </div>
            


            
                );
    }
}
export default PlaidDetails;