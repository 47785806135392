import React from 'react';
import {Link} from 'react-router-dom';
import { getApiData, getToken, getUserId,getManagerName,getOnBoardingDone } from '../../services/Api';

class ManagerHeader extends React.Component {

    constructor(props) {
        super(props);
        this.nav_bar_col = React.createRef();
        
        this.state = {
            showSign: true,
            user_id: localStorage.getItem('user_id'),
            user_name: ""

          }; 
          this.onLoad = this.onLoad.bind(this);
    }

    componentDidMount() {
        this.onLoad();
    }

    async onLoad(){
        this.setState({user_name: getManagerName()})
        if(getToken()){
           let where = [{
            url: "/Clients",
            urlParms: "/" + getUserId() + "?access_token=" + getToken()
        }];
    getApiData(where, false).then(res => {
        if(res.data.id){
            localStorage.setItem('user_id', res.data.userId);
            this.setState({'user_name':res.data.realm,'showSign':false});
        }
        
    }).catch(error => {
       
    }); 
        }
    }
    render() {
        
        return (
               <header className="fixedHeader">
                         
                <nav className="navbar navbar-expand-md bg-light navbar-dark">
                <a className="navbar-brand logo" href="#"><img src="/logo.png" width="100%" /></a> <span className="header-tag">Manager Dashboard</span>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <img src="/menu-icon.png"/>
                </button>
                <div className="collapse navbar-collapse " id="collapsibleNavbar">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item"><Link to="/manager/application" lass="nav-link"> Home</Link></li> 
                        <li className="nav-item"><Link to="/manager/logout" lass="nav-link"> Logout</Link></li> 
                        <li className="nav-item"><Link to="/manager/application" lass="nav-link">Hi, {this.state.user_name}</Link></li>
                        <li className="">{localStorage.getItem('managerImageUrl')?(<img alt="Profile" src={localStorage.getItem('managerImageUrl')} width="40" className="user rounded-circle"/>):(null)}</li>
                    </ul> 
                </div>  
                </nav>
                </header>
                );
    }
}
export default ManagerHeader;