import React from 'react';
import {Link} from 'react-router-dom';
import { getApiData, getToken, getUserId,getOnBoardingDone } from '../../services/Api';
import { Redirect } from 'react-router';

class HeaderNew extends React.Component {

    constructor(props) {
        super(props);
        this.nav_bar_col = React.createRef();
        
        this.state = {
            showSign: true,
            user_id: localStorage.getItem('user_id'),
            user_name: "",
            account_verify:''

          }; 
          this.onLoad = this.onLoad.bind(this);
      
    }

    componentDidMount() {
        this.onLoad();
       
            
    }
  
    async onLoad(){
        if(getToken()){
            this.setState({'user_name':localStorage.getItem("user_name"),'showSign':false}); 
        }
    }

    render() {
        var imgUrl=localStorage.getItem('imageUrl');
        var flag=getOnBoardingDone();
        //console.log("getOnBoardingDone-----",flag);
        if(imgUrl=="" || imgUrl==null){
                imgUrl="/member.png";
        }
        this.state.account_verify = localStorage.getItem('account_verified');
      
        return (
               <header className="Landing-header">             
                <nav className="navbar navbar-expand-lg bg-light navbar-dark justify-content-center">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" >
                    <img src="/menu-icon.png"/>
                </button>
                <div id="MyID">
                <div className="collapse navbar-collapse " id="collapsibleNavbar">
                { this.state.showSign?(
                      
                     <ul className="navbar-nav" id="hamburgerMenu">
                     
                        {/* <li className="nav-item" ><a href="#experience" className="scroll"> The Experience</a></li> */}
                        <li className="nav-item" id="Agents"><Link to="/frontend/agents" className="agent" > Agents</Link></li>
                        <li className="nav-item" ><a href="#experience" className="scroll"> About Us</a></li>
                        <li className="nav-item" id="Join"><Link to="/frontend/join" > Join Us</Link></li>
                        {/* <li className="nav-item" id="Browse"><Link to="/client/listing/browselist" > Browse Listings</Link></li>  */}
                       {/* <li className="nav-item get-started-tab" style={{width:'100%'}}><Link to="/client/signup" className="getstart btn btn-default" >Login / Get Started</Link></li>*/}
                     
                     
                     </ul> 
                     
                    
                         ):(
                           
                    <ul className="navbar-nav ">
                        {(flag== 1) ? <li className="visible-xs-block"><Link to="/client/profile" >{imgUrl?(<img alt="Profile" src={imgUrl} width="40" className="user rounded-circle"/>):(null)}</Link></li>:(null)} 
                        <li className="nav-item" id="Home"><Link to="/client/dashboard" > Home</Link></li>
							 <li className="nav-item"><Link to="/frontend/agents" id="Agnet"> Agents</Link></li>
                        <li className="nav-item" id="Experience"><a href="#experience" className="scroll">About Us</a></li>
                       
                        

                        {/* {this.state.account_verify == 1  ? (flag== 1) ?<li className="nav-item"><Link to="/client/application" lass="nav-link">Apply</Link></li> :('')  :(null)} */}
 
  {(flag== 1 && this.state.account_verify==1 && this.state.account_verify!="") ?<li className="nav-item"><Link to="/client/application" lass="nav-link">Apply</Link></li> :('')}
                         <li className="nav-item"><Link to="/client/logout" > Logout</Link></li>
                           
                    </ul>
                  
                       )
                    } 
                </div>  
                </div>
                </nav>
               
                </header>
                );
    }
}
export default HeaderNew;