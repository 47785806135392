import React, { useState, useEffect, useMemo } from "react";
import { getBaseUrl } from "../../services/Api";

// Memoized FullViewPdf component
const FullViewPdf = React.memo((props) => {
  const baseUrl = getBaseUrl(); 
  const [pdfArray, setPdfArray] = useState(props.items);

  // Memoize the items array to prevent unnecessary re-renders
  const memoizedItems = useMemo(() => props.items, [props.items]);

  // Set pdfArray when props.items change
  useEffect(() => {
    setPdfArray(memoizedItems);
  }, [memoizedItems]);

  useEffect(() => {
    const scrollToPage = (page) => {
      const pageElement = document.getElementById(`page_${page}`);
      if (pageElement) {
        pageElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };
    scrollToPage(props.renderPageNo);
  }, [props.renderPageNo]);

  return (
    <div>
      {pdfArray.map((item, index) => (
        <div
          key={index}
          id={`page_${index + 1}`}
          className="scroll-container overflow-y-auto rounded mt-3 border "
        >
          <img
            key={`image_${index + 1}`}
            src={`${baseUrl}${props.servePath}png/${item}.1.png`}
            alt={`Page ${index + 1}`}
            style={{ width: "100%" }}
          />
        </div>
      ))}
    </div>
  );
});

export default FullViewPdf;
