import React, { Component } from 'react';
import AddBuilding from './AddBuildings';

class ManagerBuilding extends Component {
	constructor(props) {
        super(props);
        this.state= {
           
            id:(this.props.match.params.id)?this.props.match.params.id:'',
            addr:(this.props.match.params.addr)?this.props.match.params.addr:'',
            listing_id:(this.props.match.params.listing_id)?this.props.match.params.listing_id:'',
           
        }
        console.log('Abhiseh Addr',this.props.match.params.addr);
     }
    render() {
        return (
            <div>
                <AddBuilding 
                userType='Manager'
                building_id={this.state.id}
                building_addr={this.state.addr}
                listing_id={this.state.listing_id}
                />
            </div>
        );
    }
}

export default ManagerBuilding;