import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import {postApiData, setToken, setUserId, getToken,checkAdminToken,setAdminToken,getAdminToken,getManagerToken,getAgentToken} from '../../services/Api';
import {isEmail} from 'validator';
import AdminHeader from '../common/AdminLoginHeader';


class AdminLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            mymsg:"",
            myerrormsg:"",
            email: "",
            password: "",
            redirect:false,
            myflag:""
        }
      
    }
   
    
     validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }
    handleChange = (event) => {     
        switch(event.target.id){
              case 'email':     
                  if (event.target.value.length ==0) {
                        
                    this.setState({
                                    emailerror: 'Field is required.'
                                  });
                                  break;
                    }
                   else if (!isEmail(event.target.value)) {
                        
                    this.setState({
                                    myerror: 'Email is not valid.'
                                  });
                    }else{
                            this.setState({
                                    myerror: ''
                                  });
                    }
                break;
                case 'password':        
                    if (event.target.value.length<=0) {
                        
                    this.setState({
                                    myerror2: 'Field is required.'
                                  });
                    }else{
                          this.setState({
                                    myerror2: ''
                                  });
                    }
                break;
        
        }
        this.setState({
            [event.target.id]: event.target.value
        });
    }

   

    handleSubmit = event => {
        event.preventDefault();
        this.onLogin();
    }
    

    handleError = (error) => {
        this.setState({error});
    }
   
    async doLogin(email,password) {
        
        let where = [{
                url: "/admin/auth/login"
            }];
            postApiData(where, {'email':email,'password':password}).then(res => {
            if(res.data.id){
              // console.log('ssssssssssssssssssssssssssssssssssssssssss',res.data);
              setAdminToken(res.data.id);
                this.setMySession({
                    'id':res.data.userId,
                    'email': res.data.email,
                    'userType': res.data.userType,
                    'username':res.data.username
                },(err,resdata)=>{
                    if(resdata){
                        this.setState({myflag:true});
                    }
                })
            }
        }).catch(error => {
           this.setState({myerrormsg: error.response.data.error.message});
        });
    }

    setMySession(value,cb){
        localStorage.setItem('adminUserDetails',JSON.stringify(value));
        cb(null,true);
    }


    async onLogin() {
        const {email, password} = this.state;
        this.doLogin(email,password);
    }
    render() {
             if (this.state.myflag==true || this.state.myflag|| getAdminToken()) {
                 return (<Redirect to={'/admin/Agent'}/>);
              }
              if(getManagerToken() && this.state.redirect==true){
                return (<Redirect to={'/manager/application'}/>);
            }
            if(getAgentToken()){
                return (<Redirect to={'/agent/application'}/>);
            }
            if(getToken()){
                return (<Redirect to={'/client/dashboard'}/>);
            }
     
        return (
                <div>
                    <AdminHeader />
                        <main>
                            <div className='container'>
                            <div className="logoPlaceholder adminlogin-panel">
                             <Link to ="/" className="navbar-brand" className="text-center"> <img src="/logo.png" width="200"/></Link>
                             </div>
                                <h2 className="text-center">Sign in to your account</h2>
                                {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
                                
                                {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                            
                                <div className='form-widget login-widget'>
                                <form onSubmit={this.handleSubmit}>
                                    <FormGroup controlId="email">
                                    <FormControl
                                        autoFocus
                                        type="email"
                                        placeholder="Email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                    />
                                    <span className="inline-error-class"> {this.state.myerror !==''?this.state.myerror:''}</span>
                                    </FormGroup>
                                    <FormGroup controlId="password">
                                    <FormControl
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        type="password"
                                        placeholder="Password"
                                        className="required"
                                    />
                                    <span className="inline-error-class"> {this.state.myerror2 !==''?this.state.myerror2:''}</span>
                                    </FormGroup>
                                    <Button
                                    block
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    >
                                    Sign In
                                    </Button>
                                </form>
                                </div>                               
                            </div>
                        </main>
                </div>
                );
    }
}
export default AdminLogin;
