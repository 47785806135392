import React from 'react';
import {postApiData,getBaseUrl} from '../../services/Api';

class AddFolder extends React.Component {
    constructor(props) {
		super(props);
		this.state={
			name:'',
			showLoader:false,
			error_msg:'',
			success_msg:''
		}
	}
	
	createFolder=()=>{
		let _this=this;
		this.setState({
			showLoader:true
		})
		let where=[{
			url:'/AddNewFolder'
		}]
		postApiData(where,{"name": this.state.name},true).then(res=>{
			if(res.data.status=="success"){
		  this.setState({
			  name:'',
			  showLoader:false,
			  success_msg:'Folder created'
		  },()=>{
			setTimeout(
				function()
				{ 
					_this.props.onClose();
				}
				, 3000
				);
		  })
		}
		}).catch(error=>{
			this.setState({
				showLoader:false,
				error_msg:'Unable to create folder',
				success_msg:''
			})
		})
	}

	handleFrm=(e)=>{
		if(e.target.id=='name'){
			this.setState({
				name:e.target.value
			})
		}
	}
	validateStep(){
		return (this.state.name.trim().length>0)?true:false;
	}


    render() {
	    return (
			<div className="modal-content">
				{(this.state.showLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
				
			<div className="modal-header">
				<h4 className="modal-title">Add Folder</h4>
				
			</div>
			<div className="modal-body">
			{(this.state.error_msg!='')?<div className="alert alert-danger"><strong>{this.state.error_msg}</strong></div>:''}
				{(this.state.success_msg !== '') ?<div className="alert alert-success"> {this.state.success_msg !==''?this.state.success_msg:''} </div>:''}

				<div className="form-group">
					<input type="text" id="name" value={this.state.name} className="form-control" placeholder="Enter Folder Name" onChange={this.handleFrm}/>
				</div>
				<button type="button" className="btn btn-default" disabled={!this.validateStep()}  onClick={this.createFolder}>Submit</button>
				<button type="button" className="btn cl-btn pull-right" onClick={this.props.onClose} >Close  <img src="/close.png"/></button> 
			</div>
			</div>
                );
    }
}



export default AddFolder;