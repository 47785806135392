import React from 'react';
import {postApiData,getApiData,removeToken} from '../../services/Api';
import Header from '../common/Header';
import {Link} from 'react-router-dom';
import { Redirect } from 'react-router';


class EmailVerifyPage extends React.Component {
    constructor(props){
        super(props);

        this.state={
            // id:this.props.match.params.acc_token,
            msg:''
        }
    }
    componentWillMount(){
        this.resendEmailForToken();
    }
    resendEmailForToken(){
        let where = [{
                url: "/Clients/account-verification?"+this.props.match.params.acc_token
            }];
            postApiData(where).then(res => {
                console.log('response===>>>',res.status)
               this.setState({msg:'true'})
                if(res.data.success == true){
                   
                    let where = [{
                        url: "/Clients/logout"
                    }];

                getApiData(where,false).then(res => {

                        removeToken();
                         localStorage.removeItem('token');
                        // localStorage.removeItem('imageUrl');
                        // localStorage.removeItem('client_id');
                        // localStorage.removeItem('account_verified');
                        // localStorage.removeItem('onboarding_data');
                        // localStorage.removeItem('user_id');
                        // localStorage.removeItem('user_name');
                        // localStorage.removeItem('is_onboarding_done');
                        // localStorage.removeItem('is_password_set');
                        localStorage.clear();
                        this.setState({logout:true});
                        // var t=window.confirm("Your account is verified. Please Login again.")
                        // if(t==true){
                        // window.location.href="/client/login";
                        // }


                })
               
                }
                
            }).catch(error => {
                this.setState({msg: 'false'});
             });
        }

    render() {
        // if (this.state.msg) {
        //     var r=window.confirm("Your account is verified. Please Login again.");
        //     if(r==true){
        //     return (<Redirect to={'/client/login'}/>);
        //  }
        // }
        // else{
        //     var t=window.confirm("Your Account is not verified.");
        //     if(t==true){
        //     return (<Redirect to={'/client/login'}/>);
        //     }
        // }
        return (
                <div>

                  <Header />


                       <div>{this.state.msg!=''?(this.state.msg=='true')?<div className="alert alert-success"><strong>Your account is verified. Please Login again. <Link to='/client/login'><u>click here</u></Link> </strong></div>:<div className="alert alert-danger"><strong>Unauthorized URL</strong></div>:''}
                       </div>


                </div>
                );
    }
}




export default EmailVerifyPage ;
