import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../../common/AdminHeader';
import AdminSideBar from '../../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken, postApiData, getApiData} from '../../../services/Api';
import {validatePhone} from '../../../services/validation';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import axios from 'axios';
import Moment from 'moment';
import DragSortableList from 'react-drag-sortable';
import { isNullOrUndefined } from 'util';
let sortedListVar;

class AdminAudioList extends Component {
    constructor(){
        super();
        this.state={
          
        }
    }

    componentWillMount(){
      this.getVideolisting();
    }

    
    getVideolisting=()=>{
        let where=[{
            url:'/admin/mediaaudiolisting'
        }]
        getApiData(where,true).then(res=>{
         console.log('res',res.data);
         this.setState({
             detail:res.data
         })
        }).catch(error=>{

        })
    }
    deleteFile=(id)=>event=>{
        var r=window.confirm("Are you sure to delete the file?");
  if(r==true){
        let where=[{
            url:'/admin/deleteMediaFile',
        }]
        postApiData(where,{'id':id,'type':'audio'},true).then(res=>{
        
        window.location.reload();
        }).catch(error=>{

        })
     }
    }
    onSort =(sortedList, dropEvent)=> {
        console.log("sortedList", sortedList);
        sortedListVar = sortedList;
    
         if( typeof sortedListVar != 'undefined' && sortedListVar instanceof Array ){
             console.log("in saveListOrder--", sortedListVar);
            console.log("length--", sortedListVar.length);
             let tempArr = [];
            
    
    
             if (sortedListVar.length > 0) {
                
                 sortedListVar.map((item,i) => {
                
                     if(!isNullOrUndefined(item.rank)){
                         tempArr[i] = item.content.key+'-'+item.rank;
                         //tempRank[i]= item.rank;
                        
                         console.log('item.content.key',item.content.key+'-'+item.rank)
                        
                     }
                    
                 })
        const formData = new FormData();
         formData.append('file',tempArr);
         formData.append('dbTable','media_audio');
    
         let listingWhere=[{
             url:'/admin/listingFileOrderUpdate'
         }];
        
         postApiData(listingWhere,formData,true).then(
        
         )
    
             }
        
         }
    
    }     
    render() {
        var placeholder = (
            <div style={{backgroundColor:'lightpink',height:'54px'}}> DROP HERE ! </div>
        );
        if(this.state.detail=="" && this.state.detail==undefined && this.state.detail.length==''){
            return true
        }

        var listItems;
        if(this.state.detail){
            console.log("---",this.state.detail)
         listItems  = this.state.detail.map((item, index)=>{
            return{
                content: (
            <tr key={item.id}>
                    <td style={{width:'150px'}}>{(item.title.length>20)?item.title.substring(0,20)+'...':item.title}<Link to={{ pathname:'/admin/audio/edit', id:item.id}}><i className="fa fa-pencil" aria-hidden="true" style={{padding:'5px'}}></i></Link></td>
                    <td style={{width:'150px'}}>{(item.description.length>20)?item.description.substring(0,20)+'...':item.description}</td>
                    <td style={{width:'150px'}}>{Moment(item.posted_on).format('MM/DD/YYYY')}</td>
                    <td style={{width:'150px'}}>{item.category}</td>
                    <td style={{width:'150px'}}>{item.topic}</td>
                    <td style={{width:'150px'}}>{item.audio_file!='undefined' && item.audio_file!='' ? item.audio_file :''}</td>
                    <td style={{width:'90px'}}><Link to onClick={this.deleteFile(item.id)} className="editsmall">Delete</Link></td>
                </tr>
                )
            }
        });
    }
        return (
            <div >
            <AdminHeader />
            <main>
            <AdminSideBar/>
            <div className="content">
                <div className="work-section">
                <div className="dashboard" >
                <h2 className="pull-left">Audios</h2>
                        <Link to="/admin/audio" className="btn btn-default pull-right ">Add Audio</Link>
                            <table className="table table-striped address-table">
                                <thead>
                                    <tr>
                                    <th className="managerGridCol">Title <span style={{paddingLeft:'90px'}}>Description</span> <span style={{paddingLeft:'80px'}}>Posted On</span> <span style={{paddingLeft:'70px'}}>Category</span> <span style={{paddingLeft:'90px'}}>Topic</span> <span style={{paddingLeft:'120px'}}>Audio File</span><span style={{paddingLeft:'110px'}}> Action</span></th>
                                {/* <th className="managerGridCol">Description</th>  
                                <th className="managerGridCol">Posted On</th> 
                                <th className="managerGridCol">Category</th>
                                <th className="managerGridCol">Topic</th>
                                <th className="managerGridCol">File</th> 
                                <th className="managerGridCol">Action</th>  */}
                                    </tr>
                                </thead>
                                <tbody>
                                <DragSortableList items={listItems}  placeholder={placeholder}  onSort={this.onSort} dropBackTransitionDuration={0.3} type="vertical" />
                                {/* {listItems} */}
                                </tbody>
                            </table>
                        
                        
                            </div>
                    </div>
                 
                </div>
          
            </main>     
        </div>
        );
    }
}

export default AdminAudioList;