import React,  { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../common/AdminHeader';
import AdminSideBar from '../common/AdminSidebar';
import { Redirect } from 'react-router';
import {checkAdminToken,getAdminToken,postApiData, getApiData} from '../../services/Api';

class ManageDocuments extends Component {
    constructor(){
        super();
        this.state={
            routeflag:true,
            ownerpayList:[],
            //Form Fields States
            name:'',
            building_document:'',
            successQuote:false,
            AddAddressPanel: false,

            //Error
            document_name_error:'',
            building_document_error:'',
      
            isMarkerShown: false,
            deleteMsg:''
        }
    }

    componentWillMount =()=>{
        window.scroll(0,0);
        this.getownerpaybrokerList();
    }

    //Handle Insertion of Address information in DB
    handleownerpaySubmit=(e)=>{
        const {name,building_document} = this.state;
        e.preventDefault();
        this.addownerpaybrokerDetail(name,building_document)
    }

    async addownerpaybrokerDetail(name,building_document) {
        let where = [{
            url: '/admin/addDocument/'
        }];
        if(name == ""){
            this.setState({ document_name_error: "Document Name cannot be empty."});
        }else if(building_document == ''){
            this.setState({ building_document_error: "Document status cannot be empty."});
        }else{

            postApiData(where, {"document_name": name,"document_flag":building_document},true).then(res => {
                if(res.data==203)
                {
                    this.setState({ document_name_error: this.state.name+' is already exist, please try any other value.'});
                }
                else{
                    this.setState({ 'pageLoader':true},()=>{
                        this.setState({'pageLoader':false, "successQuote": true, name:''});
                    }) 
                    setTimeout(
                        function() {
                            this.setState({'successQuote':false});
                        }
                        .bind(this),
                        2000
                    );  
                }
            }).catch(error => {
                this.setState({myerrormsg: error.response});
            });
        }

    }

   

    backToAddressPanel=()=>{
        this.getownerpaybrokerList()
        this.setState({
            pageLoader: true
        },()=>{
            this.setState({  pageLoader: false,  AddAddressPanel: !this.state.AddAddressPanel, successQuote :false,  })
        })
    }

    //Get Address list from DB
    getownerpaybrokerList=()=> {
        let applicationList = [{
            url: "/admin/getDocuments",
        }];
        getApiData(applicationList,true).then(res => {
           
            this.setState({'ownerpayList': res.data,'pageLoader':true},()=>{
                this.setState({'pageLoader':false});
            })
        }).catch(error => {
            if(error.response){
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });
    }
     
    validateForm() {
        return ( this.state.name.trim().length != '' && this.state.name.trim()!='') 
    }

    ownerpaybrokerDelete =(id)=>{
        alert('Are you sure want to delete?')
        this.setState({'pageLoader':true},()=>{
            
        let where = [{
            url: "/admin/deleteDocument/delete",
        }];
        postApiData(where,{'id':id},true).then(res => {
            this.setState({'deleteMsg':res.data.message},()=>{
                this.getownerpaybrokerList();
            })
            setTimeout(
                function() {
                    this.setState({'deleteMsg':''});
                }
                .bind(this),
                2000
            ); 
        }).catch(error => {
                if(error.res){
                    this.setState({myerrormsg: error.res.data});
                }
            });
        });
    }

    handleChange=(e)=>{
        const {name,building_document} = this.state;

        console.log(e.target.id);
        switch(e.target.id){
            case 'name': 
                if(e.target.value == ''){ 
                    this.setState({ document_name_error: 'Document Name cannot be empty.'}) 
                }else{ 
                    this.setState({ document_name_error: ''}) 
                }
            case 'building_document': 
                if(e.target.value == ''){ 
                    this.setState({ building_document_error: 'Select building docuent status.'}) 
                }else{ 
                    this.setState({ building_document_error: ''}) 
                }
            break;
           
        }
        this.setState({ [e.target.id] : e.target.value}, ()=>{   })
    }
     

    render() {
        const { name, AddAddressPanel} = this.state;
        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
        }

        var listItems = this.state.ownerpayList.map((item, index)=>{
            return <tr  >
                    <td id={item.id}>{item.document_name}</td>
                    <td>{(item.document_flag == '1')?'YES':'NO'}</td>
                    <td className="action-grid">
                        <div className="pull-right"><button className="fa fa-trash" onClick={()=>this.ownerpaybrokerDelete(item.id)}></button> </div>
                        <div className="pull-right"><Link className="fa fa-edit" to={`/admin/manage-documents/${item.id}`}></Link> </div>
                    </td>
                </tr>
        });

        var style={
            dropdown:{
                display:'block',
                backgroundColor: '#004f6b',
                borderRadius: '5px',
                color: 'white',
                width: '120px',
                height: '40px'
            }
        }

        if (!getAdminToken()) {
            return (<Redirect to={'/admin/login'}/>);
         }
        return (
            <div >
                <AdminHeader />
                <main>
                    <AdminSideBar/>
                    <div className="content">
                        <div className="work-section">
                            <div className="dashboard" >
                                <h2 className="pull-left">Manage Documents</h2>
                                <button onClick={this.backToAddressPanel} className="btn btn-default pull-right ">{!AddAddressPanel ? "Add Document" : "Back"}</button>
                                {(this.state.deleteMsg) ?(<div className="alert alert-danger" style={{marginLeft:'200px', marginRight:'300px'}}><strong>{this.state.deleteMsg}</strong></div>):(null)}  
                                <div className="panel-body table-responsive">
                                    {!AddAddressPanel ? 
                                        <table className="table table-striped address-table">
                                        
                                            <thead>
                                                <tr>
                                                    <th className="managerGridCol">Document</th>
                                                    <th className="managerGridCol">Building Document</th>
                                                    <th className="managerGridCol text-right">Action</th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listItems}
                                            </tbody>
                                        </table>
                                    :
                                    <Fragment>
                                        <table className="table table-striped">
                                            <thead><tr><th className="managerGridCol">Add Document</th></tr></thead>
                                        </table>
                                        <form onSubmit={this.handleownerpaySubmit} style={{maxWidth:"70%", margin:"10px auto"}}>
                                            {this.state.successQuote?(<div className="alert alert-success"><strong>Document added successfully</strong></div>):(null)} 
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label forhtml="name">Document Name</label>
                                                    <input type="text" className="form-control" id="name" onChange={this.handleChange} value={name} placeholder="Document Name" autoComplete="off"/>
                                                    <span className="inline-error-class"> {this.state.document_name_error !==''?this.state.document_name_error:''}</span>
                                                </div> 

                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label forhtml="name">Building Document</label>
                                                    <select id="building_document"  onChange={this.handleChange}  style={style.dropdown}>
                                                        <option value="">Select status</option>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    <span className="inline-error-class"> {this.state.building_document_error !==''?this.state.building_document_error:''}</span>
                                                </div> 
                                                 
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary" disabled={!this.validateForm()}>Submit</button>
                                             </div>
                                        </form>
                                    </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>	
                </main>     
            </div>
        );
    }
}

export default ManageDocuments;