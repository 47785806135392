import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../common/AgentHeader';
import SideBar from '../../common/AgentSideBar';
import { Redirect } from 'react-router';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {getApiData, setAgentToken, getAgentToken, getAgentId} from '../../../services/Api';
import LinkingDetail from '../linkingPage';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {agentListingStatus} from '../../../services/Const';

class AgentListing extends React.Component {

    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}

class Content extends React.Component {
	constructor(props) {
		super(props);
        this.state = {
			rentalListing:[],
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			searchClientId:'',
			searchParm:"",
			addressDW:[],
			statusFil:3,
			nameSearch:false,
			statusSearch:false,
			where:{},
			linkingLoader:false,
			clickedClientId:0,
			searchMsg:false,
			myerrormsg:"",
			isListingExist:false,
			value: { min: 0, max: 5000 },
		  };
		 
		  this.search = this.search.bind(this);
		  this.setWrapperRef1 = this.setWrapperRef1.bind(this);
		  this.setWrapperRef2 = this.setWrapperRef2.bind(this);
		  this.handleClickOutside = this.handleClickOutside.bind(this);
		  this.createWhere();
		}
		
	componentWillMount(){
		this.getRentalListing();
			}
	//get Rental listings

	setWrapperRef1(node) {
			this.wrapperRef1 = node;
		  }
	
		  setWrapperRef2(node) {
			this.wrapperRef2 = node;
		  }
	
		  handleClickOutside(event) {
			if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
				document.getElementById('search').classList.remove("show");
			}
	
			if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
				document.getElementById('status').classList.remove("show");
			  }
		  }

async getRentalListing(){
    let clientRelWhere = [{
        url: "/agent/rentallistings/"+getAgentId()
        
    }];
    getApiData(clientRelWhere, true).then(res => {
		console.log("res",res);
        this.setState({rentalListing:res.data});
         this.setState({addressDW:res.data});
       if(res.data.length){
           this.setState({isListingExist:true});
        }
        }).catch(error => {
            
        });
}
	// generate client Drop Down
	async clientDD(){
		let clientList = [{
            url: "/agent/clientDD",
		}];
        getApiData(clientList,true).then(res => {
        this.setState({'clientDW':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}

	//fliter reset
	resetFilter=()=>{
		this.setState({
			client_list:[],
			where:{},
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
			searchClientId:'',
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('searchParm').value='';
			document.getElementById('searchClick').classList.remove("active");
			document.getElementById('Sent').checked = true;
			document.getElementById('submit').checked = true;
			document.getElementById('Incomplete').checked = true;
			this.getRentalListing();
			this.createWhere();
		  });
	}

	resetsearchParm=()=>{
		this.setState({
			client_list:[],
			where:{},
			searchClientId:'',
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('searchParm').value='';
			document.getElementById('searchClick').classList.remove("active");
			this.getRentalListing();
			this.createWhere();

		  });
	}


	resetStatus=()=>{
		this.setState({
			client_list:[],
			where:{},
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
			myerrormsg:"",
		  },()=>{
			document.getElementById('Sent').checked = true;
			document.getElementById('submit').checked = true;
			document.getElementById('Incomplete').checked = true;
			this.createWhere();

		  });
	}

	//search handling
	search=(event) =>{
		this.setState({[event.target.id]: event.target.value});
	 var key=event.target.id; 
	  switch(key){
		case 'Sent':     
			if(this.state.ckSent.value==0 && this.state.ckSent.checked==false){
				this.state.ckSent.checked=true;
				this.state.ckSent.value=1;
				this.state.statusFil=1;
				this.createWhere();
			}else if(this.state.ckSent.value==1 && this.state.ckSent.checked==true){
				this.state.ckSent.checked=false;
				this.state.ckSent.value=0;
				this.createWhere();
			}
			break;
		case 'submit':
		if(this.state.ckSubmit.value==0 && this.state.ckSubmit.checked==false){
			this.state.ckSubmit.checked=true;
			this.state.ckSubmit.value=1;
			this.state.statusFil=1;
			this.createWhere();
		}else if(this.state.ckSubmit.value==1 && this.state.ckSubmit.checked==true){
			this.state.ckSubmit.checked=false;
			this.state.ckSubmit.value=0;
			this.createWhere();
		}        
			break;
		case 'Incomplete':  
		if(this.state.ckIncomplete.value==0 && this.state.ckIncomplete.checked==false){
			this.state.ckIncomplete.checked=true;
			this.state.ckIncomplete.value=1;
			this.state.statusFil=1;
			this.createWhere();
		}else if(this.state.ckIncomplete.value==1 && this.state.ckIncomplete.checked==true){
			this.state.ckIncomplete.checked=false;
			this.state.ckIncomplete.value=0;
			this.createWhere();
		}        
			break;
			case 'searchParm':
			this.setState({searchParm:event.target.value});
				var value=this.state.searchParm.trim();
				if(event.key=='Enter'){
					this.getData(value);

				}else if(value==''){
					this.state.addressDW.forEach(function(client,index) {
							client.flag=0;
					});
					this.setState({searchMsg:false});
				}else {
					var len = this.state.addressDW.length;
					var count=0;
					var vflag=0;
					this.state.addressDW.forEach(function(client,index) {
						
						if(client.listing_info_address.trim().toLowerCase().includes(value.trim().toLowerCase())){
							client.flag=1;
							vflag=1;
							count=count+1;
						}else{
							client.flag=0;
							if(vflag!=1)
							vflag=0
							count=count+1;
						}
	
					});
					
					if(len==count){
						if(vflag==0){
							this.setState({searchMsg:true});
						}else{
							this.setState({searchMsg:false});
						}

						
					}


				}
		break;
		case 'searchkey':
		var value=event.target.value;
			this.setState({ searchClientId: event.target.value}, () => {
				this.state.addressDW.forEach(function(client,index) {
					if(client.listing_info_address==value){
						document.getElementById('searchClick').classList.add("active");
						document.getElementById('searchParm').value=client.listing_info_address;
					}else{
						client.flag=0;
					}
				});
				document.getElementById('searchClick').classList.add("active");
				this.createWhere();
			  });
		break;
		case 'default':
		this.createWhere();
		break;
		 
		}
		if(this.state.statusFil>0){
			document.getElementById('statusKey').classList.add("active");
		}else{
			document.getElementById('statusKey').classList.remove("active");
		}
		
	  }
	  
	  //search clauses 
	async createWhere(){
		this.state.where={};
		if(this.state.ckSent.value==1){
			this.state.where.sent=3;
		}
		if(this.state.ckSubmit.value==1){
			this.state.where.submit=2;		
		}
		if(this.state.ckIncomplete.value==1){
			this.state.where.incomplete=1;
		}
		if(this.state.searchClientId!=''){
			this.state.where.searchClientId=this.state.searchClientId;
		}
	//	this.getClientApplication();
	}
	
	//click event handling
    AddressHeadClick(){ document.getElementById('status').classList.remove("show");}
	StatusHeadClick(){document.getElementById('search').classList.remove("show");}
	
	//handleLinking
	handleLinking = param => e => {
		if(this.state.clickedClientId[3]==param[3]){
			this.setState({clickedClientId:0},()=>{
				this.setState({clickedClientId:param},()=>{});
			});
		}else{
			this.setState({clickedClientId:param},()=>{});
		}
		
	}
	handleClickOnList=param=>e=>{
		var value=param[0];
		this.getData(value);
	}
getData(value){
this.setState({ searchClientId: value}, () => {
	this.state.addressDW.forEach(function(client,index) {
		if(client.listing_info_address==value){
			document.getElementById('searchClick').classList.add("active");
			document.getElementById('searchParm').value=client.listing_info_address;
		}else{
			client.flag=0;
		}
	});
	document.getElementById('searchClick').classList.add("active");
	this.createWhere();
  });
}

    render() {
		if (!getAgentToken()) {
            return (<Redirect to={'/agent/landing'}/>);
		  }
const addressDW = this.state.addressDW.map((dw,index) => 
(dw.flag==1) ? <li id="searchkey" onClick={this.handleClickOnList([dw.listing_info_address])}>{dw.listing_info_address}</li>:'');

var listingstatus = agentListingStatus();
          const {isListingExist } = this.state;
                  let rentallistingHTML = this.state.rentalListing.map((s) => <tr>
												 <Link to data-toggle="modal" data-target="#ListingModal">
												<td>{s.building_address}</td>
												<td>{s.unit_number}</td>
												<td>{s.bedrooms}BR / {s.bathrooms}BA</td>
												<td>{s.price?'$'+s.price.toLocaleString():	'$'+s.price}</td>
												<td>{s.neighborhood}</td>
												<td>{listingstatus[s.status]}</td>
												</Link>
												<td>{(s.status==1)? (<Link to className="btn btn-small btn-expire">Expire</Link>):''}{(s.status==2)? (<Link to  className="btn btn-small btn-relist">Re-list</Link>):''}</td>
											</tr>);
        return (
                <div onclick="myFunction()">
                    <Header />
                    <main>
					<SideBar/>
					<div className="content">
						<div className="work-section">
						<div className="dashboard" >

							<div className="clearfix">
								<h2 className="pull-left">My Listings</h2>
								<button onClick={this.resetFilter} className="btn btn-default pull-right">Reset all filters</button>
						    </div>	

								<Tabs>
								<TabList>
									<Tab>Rental</Tab>
									<Tab>SALES</Tab>
								</TabList>
						
								<TabPanel>
								<div className="panel-body table-responsive ">
									<table className="table table-striped agent-listing">
										<thead>
											<tr>
												<th>Address <Link to  id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.AddressHeadClick}></Link>
												<div id="search" class="collapse table-collapse client-collapse" ref={this.setWrapperRef1}>
												<div className="box">

												<input type="text" id="searchParm" autocomplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
												<span onClick={this.resetsearchParm}>(X)</span>
													<ul>
													{addressDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
												</div>
												</div>
												</th>
												<th>Unit #</th>
												<th>Layout <Link to id="statusKey" data-toggle="collapse" data-target="#layout" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="layout" class="collapse table-collapse status-collapse">
												
													<div className="box">
													<input type="checkbox" id="Sent" defaultChecked={this.state.ckSent.checked} value={this.state.ckSent.value} onChange={this.search} />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" defaultChecked={this.state.ckSubmit.checked} value={this.state.ckSubmit.value} onChange={this.search}/>
													<label for="submit">Submitted</label>
													<input type="checkbox" id="Incomplete"  defaultChecked={this.state.ckIncomplete.checked} value={this.state.ckIncomplete.value} onChange={this.search}/>
													<label for="Incomplete">Incomplete</label>
													<span onClick={this.resetStatus}>(X)</span>
													</div>
													
												</div></th>
												<th>Price <Link to id="statusKey" data-toggle="collapse" data-target="#price" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="price" class="collapse table-collapse">
												
												<div className="listing-filter box " style={{margin:0, height:'100px'}}>
													<InputRange
														maxValue={5000}
														minValue={0}
														value={this.state.value}
														onChange={value => this.setState({ value })} />
														<br/>&nbsp;&nbsp;&nbsp;&nbsp;<span onClick={this.resetStatus}>(X)</span>
													</div>
													
												</div></th>
												<th>Neighborhood <Link to id="statusKey" data-toggle="collapse" data-target="#neighborhood" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="neighborhood" class="collapse table-collapse status-collapse">
												
													<div className="box">
													<input type="checkbox" id="Sent" defaultChecked={this.state.ckSent.checked} value={this.state.ckSent.value} onChange={this.search} />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" defaultChecked={this.state.ckSubmit.checked} value={this.state.ckSubmit.value} onChange={this.search}/>
													<label for="submit">Submitted</label>
													<input type="checkbox" id="Incomplete"  defaultChecked={this.state.ckIncomplete.checked} value={this.state.ckIncomplete.value} onChange={this.search}/>
													<label for="Incomplete">Incomplete</label>
													<span onClick={this.resetStatus}>(X)</span>
													</div>
													
												</div>
							
												
												</th>
												<th>Status <Link to id="statusKey" data-toggle="collapse" data-target="#status" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="status" class="collapse table-collapse status-collapse">
												
													<div className="box">
													<input type="checkbox" id="Sent" defaultChecked={this.state.ckSent.checked} value={this.state.ckSent.value} onChange={this.search} />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" defaultChecked={this.state.ckSubmit.checked} value={this.state.ckSubmit.value} onChange={this.search}/>
													<label for="submit">Submitted</label>
													<input type="checkbox" id="Incomplete"  defaultChecked={this.state.ckIncomplete.checked} value={this.state.ckIncomplete.value} onChange={this.search}/>
													<label for="Incomplete">Incomplete</label>
													<span onClick={this.resetStatus}>(X)</span>
													</div>
													
												</div>
												
												</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{(isListingExist)?rentallistingHTML:<div className="col-12"><div >You don't have any listing yet</div></div> }
											
										</tbody>
										</table>
									</div>
								</TabPanel>

								<TabPanel>
								<div className="panel-body table-responsive ">
									<table className="table table-striped agent-listing">
										<thead>
											<tr>
												<th>Address <Link to  id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="search" class="collapse table-collapse client-collapse">
												<div className="box">

												<input type="text" id="searchParm" autocomplete="off" class="search-input" onChange={this.search}  onKeyDownCapture={this.search} onKeyUpCapture={this.search}/>
												<span onClick={this.resetsearchParm}>(X)</span>
													<ul>
													{addressDW}
													</ul>
													{(this.state.searchMsg)?'No suggestions available':''}
												</div>
												</div>
												</th>
												<th>Unit #</th>
												<th>Layout <Link to id="statusKey" data-toggle="collapse" data-target="#layout" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="layout" class="collapse table-collapse status-collapse">
												
													<div className="box">
													<input type="checkbox" id="Sent" defaultChecked={this.state.ckSent.checked} value={this.state.ckSent.value} onChange={this.search} />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" defaultChecked={this.state.ckSubmit.checked} value={this.state.ckSubmit.value} onChange={this.search}/>
													<label for="submit">Submitted</label>
													<input type="checkbox" id="Incomplete"  defaultChecked={this.state.ckIncomplete.checked} value={this.state.ckIncomplete.value} onChange={this.search}/>
													<label for="Incomplete">Incomplete</label>
													<span onClick={this.resetStatus}>(X)</span>
													</div>
													
												</div></th>
												<th>Price <Link to id="statusKey" data-toggle="collapse" data-target="#price" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="price" class="collapse table-collapse">
												
												<div className="listing-filter box " style={{margin:0, height:'100px'}}>
													<InputRange
														maxValue={5000}
														minValue={0}
														value={this.state.value}
														onChange={value => this.setState({ value })} />
														<br/>&nbsp;&nbsp;&nbsp;&nbsp;<span onClick={this.resetStatus}>(X)</span>
													</div>
													
												</div></th>
												<th>Neighborhood <Link to id="statusKey" data-toggle="collapse" data-target="#neighborhood" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="neighborhood" class="collapse table-collapse status-collapse">
												
													<div className="box">
													<input type="checkbox" id="Sent" defaultChecked={this.state.ckSent.checked} value={this.state.ckSent.value} onChange={this.search} />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" defaultChecked={this.state.ckSubmit.checked} value={this.state.ckSubmit.value} onChange={this.search}/>
													<label for="submit">Submitted</label>
													<input type="checkbox" id="Incomplete"  defaultChecked={this.state.ckIncomplete.checked} value={this.state.ckIncomplete.value} onChange={this.search}/>
													<label for="Incomplete">Incomplete</label>
													<span onClick={this.resetStatus}>(X)</span>
													</div>
													
												</div>
							
												
												</th>
												<th>Status <Link to id="statusKey" data-toggle="collapse" data-target="#status" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="status" class="collapse table-collapse status-collapse">
												
													<div className="box">
													<input type="checkbox" id="Sent" defaultChecked={this.state.ckSent.checked} value={this.state.ckSent.value} onChange={this.search} />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" defaultChecked={this.state.ckSubmit.checked} value={this.state.ckSubmit.value} onChange={this.search}/>
													<label for="submit">Submitted</label>
													<input type="checkbox" id="Incomplete"  defaultChecked={this.state.ckIncomplete.checked} value={this.state.ckIncomplete.value} onChange={this.search}/>
													<label for="Incomplete">Incomplete</label>
													<span onClick={this.resetStatus}>(X)</span>
													</div>
													
												</div>
												
												</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><Link to data-toggle="modal" data-target="#ListingModal">548 W 114th St.</Link></td>
												<td>3D</td>
												<td>1BR / 1BA</td>
												<td>$2350</td>
												<td>Morningside Heights</td>
												<td>Active</td>
												<td><Link to className="btn btn-small btn-expire">Expire</Link></td>
											</tr>
										
											<tr>
												<td><Link to data-toggle="modal" data-target="#ListingModal">200 W 111th St.</Link></td>
												<td>4R</td>
												<td>2BR / 1.5BA</td>
												<td>$3200</td>
												<td>Morningside Heights</td>
												<td>Pending</td>
												<td><Link to className="btn btn-small btn-expire">Expire</Link></td>
											</tr>
										</tbody>
										</table>
									</div>
								</TabPanel>
								</Tabs>	
							
							</div>
						
							
						</div>
					</div>	
                    </main> 

					 <div id="ListingModal" className="modal fade" role="dialog">
                        <div className="modal-dialog">

                        
                            <div className="modal-content">
                            <div className="modal-header" style={{borderBottom:'0'}}>
                                <div className="row clearfix">
                                    <div className="col-xs-6 col-sm-6">
                                        <h3>548 W 114th St, 3D</h3>
                                        <p>Morningside Heights</p>

                                    </div>
                                    <div className="col-xs-6 col-sm-6 text-right">
                                        <p className="price">$2,400 <Link to className="editsmall"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></p>
                                        <p>2 bedrooms, 1 bath | 600 sq. ft. <Link to className="editsmall"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></p>

                                    </div>
                                </div>    
                                
                            </div>
                            <div className="modal-body" >
                                <div className="listing-banner">


                                 <Carousel>
                                    <div>
                                    <img src="../../listing-banner.png" width="100%"/>
                                        <p className="legend"><img src="../../vr.png" />   Experience in VR <Link to className="editsmall"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></p>
									   <Link to className="img-edit editsmall"> <i className="fa fa-pencil " aria-hidden="true"></i></Link>
                                    </div>
                                    <div>
                                    <img src="../../listing-banner.png" width="100%"/>
									<Link to className="img-edit editsmall"> <i className="fa fa-pencil " aria-hidden="true"></i></Link>
                                        <p className="legend"><img src="../../vr.png"/> Experience in VR <Link to className="editsmall"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></p>
                                    </div>
                                    <div>
                                    <img src="../../listing-banner.png" width="100%"/>
									<Link to className="img-edit editsmall"> <i className="fa fa-pencil " aria-hidden="true"></i></Link>
                                        <p className="legend"><img src="../../vr.png"/> Experience in VR <Link to className="editsmall"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></p>
                                    </div>
                                </Carousel>
                                  

                                </div>
                                <div className="row clearfix">
								
                                    <div className="col-sm-9">
									<div className="incentive-box">
                                           <h4>Incentive Details <Link to className="editsmall pull-right"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></h4>
										  <p>Fee structure: Co-broke, Commission: 13.88%</p> 
                                        </div>

                                        <div className="amenities-box">
                                           <h4>Amenities <Link to className="editsmall pull-right"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></h4>
                                           <ul>
                                               <li>Dishwasher</li>
                                               <li>Balcony</li>
                                               <li>Renovated Kitchen</li>
                                               <li>Elevator</li>
                                               <li>Doorman</li>
                                           </ul>
                                        </div>
                                        <div className="discription-box">
                                            <h4>Listing Description <Link to className="editsmall pull-right"> <i className="fa fa-pencil" aria-hidden="true"></i></Link></h4>
                                            <p>Exceptionally large alcove studio in mint condition on the gold coast of the Upper East side. This home has been completely redesigned and renovated. There is a brand new Chef's kitchen. New hardwood floors and a new bathroom. Three large closets. The home faces East and has very sunny open unobstructed city views. This is a full service door man building with a great staff. </p>
                                        </div>
                                        <ul className="btn-group-section d-block d-sm-none">
                                        <li><button type="button" className="btn apply-btn" data-dismiss="modal">Apply Now</button></li>
                                        <li><button type="button" className="btn btn-default" data-dismiss="modal">Request a showing</button></li>
                                        <li><button type="button" className="btn btn-default" data-dismiss="modal"> Save Listing <img src="../../star.png" width="20"/></button></li>
                                        <li><div className="map-wrap">
                                            <img src="../../map.png" width="100%"/>

                                        </div></li>
                                    </ul>
                                        <div className="listed-box cleafix">
                                        <h4>Listed by </h4>
                                        <div className="info">
                                                <p className="name">Billy Mays</p>
                                                <p>bmays@moveskyward.com</p>
                                                <p>212.123.4567</p>
                                        </div>
                                        <img src="../../profile.png" width="75" height="75" className="rounded-circle"/>

                                        </div>

                                    </div>
                                    <div className="col-sm-3 d-none d-sm-block text-right">
									<p className="availabel-txt">Available 2/1/19 <Link to> <i className="fa fa-pencil editsmall" aria-hidden="true"></i></Link></p>
									<p className="unfurnish-txt">Unfurnished <Link to> <i className="fa fa-pencil editsmall" aria-hidden="true"></i></Link></p>
                                    <ul className="btn-group-section">
                                        
                                        <li><button type="button" className="btn btn-big btn-expire" data-dismiss="modal">Expire listing</button></li>
										
                                        
                                       
                                    </ul>
                                    </div>
                                </div>

                              
                                <button type="button" className="btn cl-btn" data-dismiss="modal">Close  <img src="../../close.png"/></button>
                            </div>
                          
                            </div>

                        </div>
                        </div>    
                </div>
                );
    }
}
export default AgentListing;
