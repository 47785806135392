import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import Header from '../../common/Header';
import { getApiData, getToken, getUserId,getOnBoardingDone,getBaseUrl,postApiData} from '../../../services/Api';
import { Redirect } from 'react-router';
import { getFormatDateMDY } from '../../../services/Util';
import Modal from 'react-responsive-modal';
import Moment from 'react-moment';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class BuildDocDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
                fileDetails:props.fileDetails,
                loaderStatus:false,
        };
    }


    render() {
        var pdfpath =getBaseUrl()+"/containers/building-additional-doc/download/"+this.state.fileDetails.uploadedFile;
        return (
            <div className="modal-content client-modal">
            {(this.state.fileDetails.ext=='.pdf')?<embed src={pdfpath} width="90%" height="500" alt="pdf" style={{margin:'5%'}}></embed>:<img src={pdfpath} className="additionalImg"/>}
            <p style={{marginRight:'5%', textAlign:'right'}}><button type="button" id="close" className="btn cl-btn" onClick={this.props.onClick} >Close  <img src="/close.png"/></button></p>
            </div>
                            );
    }
}
export default BuildDocDetails;