import React from 'react';
import {postApiData,getApiData,removeToken} from '../../services/Api';

import {Link} from 'react-router-dom';
import { Redirect } from 'react-router';


class EmailVerifyPage extends React.Component {
    constructor(props){
        super(props);

        this.state={
            // id:this.props.match.params.acc_token,
            msg:''
        }
    }
    componentWillMount(){
        this.resendEmailForToken();
    }
    resendEmailForToken(){
        let where = [{
                url: "/agents/auth/account-verification?"+this.props.match.params.acc_token
            }];
            postApiData(where).then(res => {
               
               this.setState({msg:'true'})
                if(res.data.success == true){
                   
                    let where = [{
                        url: "/agent/logout"
                    }];

                getApiData(where,false).then(res => {

                        removeToken();
                         localStorage.removeItem('token');
                        
                        localStorage.clear();
                        this.setState({logout:true});
                       
                })
               
                }
                
            }).catch(error => {
                this.setState({msg: 'false'});
             });
        }

    render() {

        return (
                <div>
                    
                  
                  <main>
                      <div className='container'>
                      <div className="logoPlaceholder">
                       <Link to ="/" className="navbar-brand" className="text-center"> <img src="/logo.png" /></Link>
                       </div>   
                       <div>{this.state.msg!=''?(this.state.msg=='true')?<div className="alert alert-success"><strong>Your account is verified. Please Login again. <Link to='/agent/login'><u>click here</u></Link> </strong></div>:<div className="alert alert-danger"><strong>Unauthorized URL</strong></div>:''}
                       </div>
               </div>
                   </main>
                </div>
                );
    }
}




export default EmailVerifyPage ;
