import React from 'react';
//import "react-responsive-carousel/lib/styles/carousel.min.css";
//import { Carousel } from 'react-responsive-carousel';
import { getApiData,getToken,getUserId,getBaseUrl,postApiData} from '../../services/Api';
import { Link  } from 'react-router-dom';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import CryptoJS from 'crypto-js';
import Moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addDays from "date-fns/add_days";
import set_hours from "date-fns/set_hours";
import set_minutes from "date-fns/set_minutes";
import L from 'leaflet';
import MarkerClusterGroup from './../../react-leaflet-markercluster';
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';
import './../../main.scss';
import './../../leaflet.css';
import Modal from "react-responsive-modal";
import {validatePhone,socialSecurityNumber} from '../../services/validation';
import CarouselNew from "react-multi-carousel";
import { Carousel } from 'react-responsive-carousel';
import "react-multi-carousel/lib/styles.css";

export class ListDetail extends React.Component {

    constructor(props) {
        super(props);
        console.log("this.props",this.props);
        this.state = {
            listId:this.props._listId_,
            listDetails:[],
            listImage:[],
            listBuildAttr:[],
            showingClientData:[],
            listAttr:[],
            favList:[],
            listSalesInfo:[],
            time: '',
            startDate:new Date(),
            open: false,
            loginCheck:false,
            client_name:'',
            client_contact:'',
            client_email:'',
            description:'',
            agent_email:'',
            agent_name:'',
            agent_phone:'',
            agent_image:'',
            currentTime:new Date().getHours()+1,
            client_nameInquiry:'',
            mailcopy:false,
            descriptionLoggedIn:'',
            CC_mail:'',
            openHouseList:[],
            showBlock:'none',
            closeHouseModal:'',
            openHouseModel:''
           // split:'',

        };
        
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate= this.handleChangeDate.bind(this);
        this.clientnamehandleChange = this.clientnamehandleChange.bind(this);
        this.clientconatcthandleChange = this.clientconatcthandleChange.bind(this);
       // this.splithandleChange = this.splithandleChange.bind(this);
    }

    componentDidMount() {
        let userId;
        if (getToken()) {
            userId=getUserId();
           
          let filter={
                userId:userId,
            };
            let saveToFav = [{
                url: "/favList",
                where:filter
            }];
            getApiData(saveToFav,true).then(res => {
                   this.setState({favList:res.data});
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message});
                }
            });
        }

        
        let filter={
            list_id:this.state.listId
        };
        let listInfo = [{
            url: "/listInfo",
            where:filter
        }];
        getApiData(listInfo,false).then(res => {
            console.log("res-1111-------",res.data);
            if(res.data[0]){
                this.setState({listDetails:res.data[0][0]},()=>{
                   
                    if(res.data[1]){
                        this.setState({listImage:res.data[1]});
                    }
                    if(res.data[2]){
                        this.setState({listAttr:res.data[2]});
                    }
                    if(res.data[3]){
                        this.setState({listSalesInfo:res.data[3][0]}); 
                    }
                    if(res.data[4]){
                        this.setState({listBuildAttr:res.data[4]}); 
                    }
                    
                });
            }
            
        }).catch(error => {
            if(error.response){
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });

        //check if logged in
        if (getToken()){
            this.setState({loginCheck:true});
        }
        else{
            this.setState({loginCheck:false});
        }
        
        
            let clientinfo = [{
                url: "/showings/clientshowingdetail/"+ userId
            }]
           
            getApiData(clientinfo, true).then(res => {
                console.log('clientinfo',res.data[0]);
                this.setState({showingClientData:res.data[0]});
               
                let key = encHex.parse("0123456789abcdef0123456789abcdef");
                let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
                
                this.state.client_contact = aes.decrypt(this.state.showingClientData.phone_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8);
                this.state.client_name = res.data[0].first_name;
                this.state.client_nameInquiry = res.data[0].first_name;
                this.state.client_email =aes.decrypt(this.state.showingClientData.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8);
                this.state.agent_email =aes.decrypt(this.state.showingClientData.agent_email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8);
                this.state.agent_name = res.data[0].agent_name;
                this.state.agent_phone =aes.decrypt(this.state.showingClientData.agent_contact, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8);
                this.state.agent_image = res.data[0].agent_image;
                }).catch(error => {
                    
                });
        let openHouseList = [{
            url: "/getOpenHouseList",
            where:{
                list_id:this.state.listId
            }
        }];
        getApiData(openHouseList,false).then(res => {
            console.log("res open house=>",res);
            if(res.data.status=='Success'){
                this.setState({
                    openHouseList:res.data.data,
                    showBlock:(res.data.data.length>0)?'block':'none'
                },()=>{
                });
            }

            if(res.data.status=='error'){
                this.setState({myerrormsg: res.data.msg});
            }
            
        }).catch(error => {
            if(error.response){
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });
        
         
        
    }

    validatePhone(value){
        let res=validatePhone(value);
                   if(res.status==false){
                       this.setState({phoneerror: 'Phone number is not valid.'});
                       document.getElementById("client_contact").value=res.value;
                   }else{
                       this.setState({phoneerror: ''});
                       document.getElementById("client_contact").value=res.value;
                   }
         return res.status;
   }
   copyUrl(listid){
    /* Get the text field */
    var copyText = getBaseUrl()+'/client/listing/Details/'+listid;
      var tempInput = document.createElement('INPUT');
      document.body.appendChild(tempInput);
        tempInput.setAttribute('value', copyText)
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
    
    /* Alert the copied text */
    alert("Listing page url copied successfully");
}
   formValidate(key,value){
    switch(key){
        case 'client_contact':        
             this.validatePhone(value);
            break;

        }
}

    getTokenForUpdateData=()=>{
        let userId;
        if (getToken()) {
            userId=getUserId();
          let filter={
                userId:userId,
            };
            let saveToFav = [{
                url: "/favList",
                where:filter
            }];
            getApiData(saveToFav,true).then(res => {
                   this.setState({favList:res.data});
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message});
                }
            });
        }
    }

setListingDataForFront=()=>{
    let filter={
        list_id:this.props._listId_
    };
    let listInfo = [{
        url: "/listInfo",
        where:filter
    }];
    getApiData(listInfo,false).then(res => {
        console.log("res2-------",res.data);
        if(res.data[0]){
            this.setState({listDetails:res.data[0][0]},()=>{
                document.getElementById('listingDesc').innerHTML=this.state.listDetails.description.replace(/(?:\r\n|\r|\n)/g, '<br>')
                if(res.data[1]){
                    this.setState({listImage:res.data[1]});
                }
                if(res.data[2]){
                    this.setState({listAttr:res.data[2]});
                }
                if(res.data[3]){
                    this.setState({listSalesInfo:res.data[3][0]}); 
                }
                if(res.data[4]){
                    this.setState({listBuildAttr:res.data[4][0]}); 
                }

               
            });
        }
        
    }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
    });
}



checkLoginForFront=()=>{
     //check if logged in
     if (getToken()){
        this.setState({loginCheck:true});
    }
    else{
        this.setState({loginCheck:false});
    }
}

    componentDidUpdate(prevProps) {
          if(prevProps._listId_!=this.props._listId_){
            this.getTokenForUpdateData();
            this.setListingDataForFront();
            this.checkLoginForFront();
          } else{
          }
      }


	handleChange=(date)=> {
        
        this.setState({
          time: date
        });
        document.getElementById('datePicker_time').value=date;
      }

      handleChangeDate(date) {

        var selectedDate=Moment(date).format('YYYY-MM-DD');
        var todaysDate=Moment(new Date()).format('YYYY-MM-DD');
        if(selectedDate==todaysDate){
        this.setState({
        currentTime: new Date().getHours()+1
        });
        }
        else
        {
        this.setState({
        currentTime: '7'
        });
        }
        
        this.setState({
        startDate: date
        });
        }

       /**
       * this function will handle/create showing
       */
      createNewShowing=()=>{
        
        let userId;
        if (getToken()) {
            userId=getUserId();
            if(userId){
                
                let startDate = Moment(this.state.startDate).format('YYYY-MM-DD');
                let showingTime = Moment(this.state.time).format('HH:mm:ss');
                startDate = startDate+' '+showingTime;
                let client_name = this.state.client_name;
                let client_contact = this.state.client_contact;
               // let split = this.state.split;
               // console.log('split',split);
                let createShowing = [{
                    url: "/createShowing"
                }];
                var form = new FormData();   
                
                form.append("client_id", userId);
                form.append("agent_id", this.state.listDetails.agentId);
                form.append("agent_name", this.state.listDetails.name);
                form.append("agent_email", this.state.listDetails.email);
                form.append("showing_address", this.state.listDetails.listing_info_address);
                form.append("showing_unit", this.state.listDetails.unit_number);
                form.append("listing_id", this.state.listDetails.id);
                form.append("showing_time", showingTime);
                form.append("showing_date", startDate);
                form.append("client_name", client_name);
                form.append("client_contact", client_contact);
               // form.append("split", split );
                form.append("status", 2);
                form.append("lead",1);
                postApiData(createShowing, form,true).then(res => {
                    console.log("response--",res);
                    
                    if(res.data.success){
                        alert("Showing requested. Please look for confirmation in your email and dashboard.");
                       // document.getElementById("newShowingClose").click();
                        this.setState({ open: false,time:'',startDate:new Date()});
                        // this.getShowingList();
                        // this.getAvailableShowing();
                        this.updateTimeKitForShowing(res.data.message.insertId);
                    }
                    else{
                        this.setState({myerrormsg:res.data.message});
                    }
                    
                    }).catch(error => {
                        
            });
        
        }
    }
        else{

               let startDate = Moment(this.state.startDate).format('YYYY-MM-DD');
                let showingTime = Moment(this.state.time).format('HH:mm:ss');
                startDate = startDate+' '+showingTime;
                let client_name = this.state.client_name;
                let client_contact = this.state.client_contact;
              //  let split = this.state.split;
                let email = 'info@moveskyward.com';
              //  console.log('split',split);
              //  console.log('client_contact',client_contact);
                let createShowing = [{
                    url: "/createShowing"
                }];
                var form = new FormData();   

             //   if(userId){
                   // form.append("client_id", userId);
               // }
                form.append("agent_id", this.state.listDetails.agentId);
                form.append("agent_name", this.state.listDetails.name);
                form.append("agent_email", this.state.listDetails.email);
                form.append("showing_address", this.state.listDetails.listing_info_address);
                form.append("showing_unit", this.state.listDetails.unit_number);
                form.append("listing_id", this.state.listDetails.id);
                form.append("showing_time", showingTime);
                form.append("showing_date", startDate);
                form.append("client_name", client_name);
                form.append("client_contact", client_contact);
                form.append("email",email);
              //  form.append("split", split );
                form.append("status", 2);
                form.append("lead",1);
                postApiData(createShowing, form,true).then(res => {
                   
                    
                    if(res.data.success){
                        alert("Showing requested. Please look for confirmation in your email and dashboard.");
                       // document.getElementById("newShowingClose").click();
                        this.setState({ open: false,time:'',startDate:new Date() });
                        // this.getShowingList();
                        // this.getAvailableShowing();
                        this.updateTimeKitForShowing(res.data.message.insertId);
                    }
                    else{
                        this.setState({myerrormsg:res.data.message});
                    }
                    
                    }).catch(error => {
                        
            });

            this.setState({sendToLogin:true});
        }
    
}

    updateTimeKitForShowing(clientShowingId,flag=false){

        let timeKitShowing = [{
            url: "/showings/createShowing/"+clientShowingId
        }];
        getApiData(timeKitShowing, true).then(res => {
            console.log("response---",res);
            if(flag){
                this.updateTimeKitForAction(clientShowingId,'agent_id');
            }
            this.setState({
                open:false
            })
            }).catch(error => {            
            });
    }

    validateForm=() =>{
        return (
            this.state.time!="" &&
            this.state.client_contact!=""&&
            this.state.client_name!=""
        ) 

        }

  // validation for Inquiry form      
    validateInquiry=()=>{
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;
        return(
            this.state.client_nameInquiry!="" &&
            pattern.test(this.state.client_email)===true &&
            this.state.description!=""
        )
    } 
    
    validateInquiryLoggedin=()=>{
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;
        if(this.state.CC_mail!=''){
            return(
                this.state.descriptionLoggedIn!="" &&
                pattern.test(this.state.CC_mail)===true
            )    
        }
        else{
            return(
                this.state.descriptionLoggedIn!="" 
            )
        }
        
    }

        preventKeystroke=(event)=>{
            console.log("keycode---", event.keyCode);
            if (event.keyCode) {
                event.preventDefault();
            }
        }

        onOpenModal = () => {
            this.setState({ open: true });
          };
        
          onCloseModal = () => {
            this.setState({ open: false,time:'',startDate:new Date() });
         
          };
// Condition for open and close model -- Start--
          onOpenInquiry = () => {
            this.setState({ openInquiry: true });
          };
        
          onCloseInquiry = () => {
            this.setState({ openInquiry: false});
         
          };
// --End-- 

// Condition for open and close model submit inquiry loggedIN -- Start--
onOpenInquiryloggedIN=()=>{
    this.setState({ openInquiryLoggedIn: true });
}

onCloseInquiryloggedIN=()=>{
    this.setState({ openInquiryLoggedIn: false });
}
onOpenGallery=()=>{
    this.setState({ openGallery: true });
}

onCloseGallery=()=>{
    this.setState({ openGallery: false });
}
//--End--

          clientnamehandleChange(event) {
            this.setState({[event.target.id]: event.target.value});
          }

          clientconatcthandleChange = event =>{
            
            
            this.formValidate(event.target.id,event.target.value);

           // this.setState({clientphone: {phone_number:event.target.value}});
        
            // this.setState({client_contact:event.target.value});
            if(event.target.id==='client_contact'){
                if(this.isNum(event.target.value)!=true){
                  this.setState({client_contact:''});
                }
                else{ 
                  this.setState({client_contact:event.target.value});
                }
             }
          }

        //   splithandleChange(event){
        //       this.setState({split: event.target.value});
        //   }
        
          isNum(num){
            if(/^[0-9-]*$/.test(num))
            return true;
            else 
            return false;
        }
// Send Inquiry mail to agent
        sendinquirymail(){
            this.setState({linkingLoader:true})

          
             let where=[{
                    url:'/email/submit_inquiry_send_mail'
            }];  
          
            var data={'client_name':this.state.client_nameInquiry, 'client_email':this.state.client_email, 'content':this.state.description, 'listing_address':this.state.listDetails.listing_info_address, 'listing_unit':this.state.listDetails.unit_number, 'agent_email':this.state.listDetails.email, 'agent_name':this.state.listDetails.name};
            
            postApiData(where,data,false).then(res=>{
            
              this.setState({linkingLoader:false},()=>{
              
                document.getElementById('contactmailclose').click();
              })
          
              
            
          }).catch(error=>{
            console.log('Error=>',error)
          })
          }
// -- End--  

OpenModelShowHouse=()=>{
    this.setState({
        openHouseModel:true
    },()=>{
        console.log("openHouse",this.state.openHouseModel)
    })
}

CloseModelShowHouse=()=>{
    this.setState({
        openHouseModel:false
    },()=>{
        console.log("openHouse else",this.state.openHouseModel)
    })
}


handleCheck=()=> {
				
    this.setState({mailcopy: !this.state.mailcopy});
}

 // LoggedIn client send inquiry email to registered agent agent
sendinquirymailLoggedIn(){
    let key = encHex.parse("0123456789abcdef0123456789abcdef");
    let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
    this.setState({linkingLoader:true})
    var copy_mail;
    var mail_CC;
    if(this.state.mailcopy){
       copy_mail = this.state.showingClientData.email;
    }
    else{
        copy_mail = '';
    }
    if(this.state.CC_mail!=''){
        mail_CC = aes.encrypt(this.state.CC_mail, key, {iv: iv, padding:padZeroPadding}).toString();
    }
    else{
        mail_CC = '';
    }
          
    let where=[{
           url:'/email/loggedIn_client'
   }];  
 
   var data={'client_name':this.state.client_nameInquiry, 'client_email':this.state.showingClientData.email  ,'content':this.state.descriptionLoggedIn, 'listing_address':this.state.listDetails.listing_info_address, 'listing_unit':this.state.listDetails.unit_number, 'agent_email':this.state.showingClientData.agent_email, 'agent_name':this.state.agent_name, 'copy_mail':copy_mail, 'mail_CC':mail_CC};
   
   postApiData(where,data,false).then(res=>{
   
     this.setState({linkingLoader:false},()=>{
     
       document.getElementById('contactmailclose').click();
     })
  
     
   
 }).catch(error=>{
   console.log('Error=>',error)
 }) 
}


    render() {
        var a='';
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
        //console.log("this.state.listSalesInfo",this.state.listSalesInfo);
        if(this.state.listDetails.length<1){
            return <div>Loading</div>
        }

        let list = this.state.listAttr.map((item, index)=>{
            return <li key={index}>{item.name}</li>;
        });
        let listBuildingAttr = this.state.listBuildAttr.map((item, index)=>{
            return <li key={index}>{item.amenity_name}</li>;
        });
        let openHouseRowList=''
        if(this.state.openHouseList.length>0){

            openHouseRowList= this.state.openHouseList.map((item, index)=>{
                return (<div class="row dateandtime">
                    <div class="col-sm-4 border-right" style={{fontWeight:"bold"}}>{Moment(item.date).format('ddd, MMM DD')} </div>
                    <div class="col-sm-8">
                        {item.games.map(function (time) {
                  return (
                    <span class="time-seprator">{time.show_time}</span>
                  );
                })}
                </div>
                     </div>);
            });


        }


        let listImage;
        if(this.state.listImage.length>0){
        listImage = this.state.listImage.map((item, index)=>{
            let img =(item.large_image!='' && item.large_image!=null)?item.large_image:'/listing-img-detail.png';
            
            let check=img.indexOf("https://");
            let image=(check >-1 || img=='/listing-img-detail.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
             var vr_link=(this.state.listDetails.kuula_vr_link!='' && this.state.listDetails.kuula_vr_link!=null)?(this.state.listDetails.kuula_vr_link.startsWith("https://") || this.state.listDetails.kuula_vr_link.startsWith("http://"))?this.state.listDetails.kuula_vr_link:'http://'+this.state.listDetails.kuula_vr_link:'#';
             return <div key={index}><a onClick={this.onOpenGallery}>
             <img src={this.state.imagePath2 && this.state.image_ID==item.id ? this.state.imagePath2 : (check > -1)  ?  item.large_image : getBaseUrl()+"/containers/listing_images/download/" + item.large_image} style={{transform: `rotate(${item.rotation}deg)`}} width="100%"/></a>
             {/*<Link to className="img-edit editsmallicon" onClick={this.imageEDIT(item.id)} data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>*/}
             
                 <p className="legend" style={{display:'none'}}>
                 <img src="/vr.png"/>{item.kuula_vr_link!='' ?<a href={item.kuula_vr_link} target="_blank"> Experience in VR </a> :<a href="#"> Experience in VR </a> }</p>
             </div>;
        });
    }else{
        var vr_link=(this.state.listDetails.kuula_vr_link!='' && this.state.listDetails.kuula_vr_link!=null)?(this.state.listDetails.kuula_vr_link.startsWith("https://") || this.state.listDetails.kuula_vr_link.startsWith("http://"))?this.state.listDetails.kuula_vr_link:'http://'+this.state.listDetails.kuula_vr_link:'#';
        listImage =  <div id="img_0" className="poppreviewImage">
            <img src="/listing-img-detail.png" width="100%" className="imageNoimage"/>
           {this.state.listDetails.kuula_vr_link!=''? <p className="legend" style={{display:'none'}}><a href={this.state.listDetails.kuula_vr_link} target="_blank"><img src="/vr.png" /><span className="hidden-xs" >Experience in VR</span></a></p>:''}
        </div>;
    }
    let listImagepopup;
    if(this.state.listImage.length>0){
        listImagepopup = this.state.listImage.map((item, index)=>{
            let img =(item.large_image!='' && item.large_image!=null)?item.large_image:'/listing-img-detail.png';
            
            let check=img.indexOf("https://");
            let image=(check >-1 || img=='/listing-img-detail.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
             var vr_link=(this.state.listDetails.kuula_vr_link!='' && this.state.listDetails.kuula_vr_link!=null)?(this.state.listDetails.kuula_vr_link.startsWith("https://") || this.state.listDetails.kuula_vr_link.startsWith("http://"))?this.state.listDetails.kuula_vr_link:'http://'+this.state.listDetails.kuula_vr_link:'#';
             return <div>
             <img src={this.state.imagePath2 && this.state.image_ID==item.id ? this.state.imagePath2 : (check > -1)  ?  item.large_image : getBaseUrl()+"/containers/listing_images/download/" + item.large_image} style={{transform: `rotate(${item.rotation}deg)`}}/>
             {/*<Link to className="img-edit editsmallicon" onClick={this.imageEDIT(item.id)} data-toggle="modal" data-target="#imageEdit" > <i className="fa fa-pencil " aria-hidden="true"></i></Link>*/}
             </div>;
        });
    }else{
        var vr_link=(this.state.listDetails.kuula_vr_link!='' && this.state.listDetails.kuula_vr_link!=null)?(this.state.listDetails.kuula_vr_link.startsWith("https://") || this.state.listDetails.kuula_vr_link.startsWith("http://"))?this.state.listDetails.kuula_vr_link:'http://'+this.state.listDetails.kuula_vr_link:'#';
        listImagepopup =  <div>
            <img src="/listing-img-detail.png"/>
           
        </div>;
    }  

    const { open,openInquiry,openInquiryLoggedIn,openGallery } = this.state;
return (<div id="ListingModal"><div className="modal-dialog"><div className="modal-content">
            <div className="modal-header" style={{ borderBottom: '0' }}>
            <button type="button" className="btn cl-btn mobileCloseBtn" onClick={this.props.closeModal([this.state.listDetails.id])}> <img src="/close.png" /></button>
                <div className="row clearfix">
                    <div className="col-xs-6 col-sm-6">
                        <h3 >{this.state.listDetails.listing_info_address}, {this.state.listDetails.unit_number}</h3>
                        <p>{unescape(this.state.listDetails.neighborhood)}</p>

                    </div> 
                    <div className="col-xs-6 col-sm-6 text-right">
                        <p className="price">{this.state.listDetails.price?'$'+this.state.listDetails.price.toLocaleString():'$'+this.state.listDetails.price}</p>
                        <p>{this.state.listDetails.bedrooms} bedrooms, {this.state.listDetails.bathrooms} bath {this.state.listDetails.square_footage?' | '+this.state.listDetails.square_footage+' sq. ft.':''} {(this.state.listDetails.listing_type=='Sales')?'| '+this.state.listDetails.building_ownership:''}</p>

                    </div>
                </div>

            </div>
            <div className="modal-body">
                <div className="listing-banner">
                {this.state.listDetails.kuula_vr_link?<div className="img-360">
                        <img src="/images/360.png" /> 
                    </div>:''}
                {this.state.listDetails.noFee?<div className="tag"></div>:''}
                <CarouselNew
  additionalTransfrom={0}
  arrows
  autoPlaySpeed={3000}
  centerMode={false}
  containerClass="container"
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  }}
  showDots
  sliderClass=""
  slidesToSlide={1}
  swipeable
>
                    {listImage}
                    </CarouselNew>
                    
                   
                      
                </div>
                <div className="row">
                
               
                
                {this.state.listDetails.kuula_vr_link?<div className="col-sm"><p className="experience-vr"><a href={this.state.listDetails.kuula_vr_link} target="_blank"><img src="/vr.png" /><span className="hidden-xs">Experience in VR</span></a></p></div>:''}
                {this.state.listDetails.video_link?<div className="col-sm"><p className="experience-vr"><a href={this.state.listDetails.video_link} target="_blank"><img src="/video.png" /><span className="hidden-xs">Watch Video Tour</span></a></p></div>:''}
                </div>
                <div className="row clearfix">
                    <div className="col-sm-9">
                       {this.state.listAttr.length>0? <div className="amenities-box">
                            <h4>Amenities</h4>
                            <ul>
                                {list}
                            </ul>
                        </div>:''}
                        {this.state.listBuildAttr.length>0? <div className="amenities-box">
                            <h4>Building Amenities</h4>
                            <ul>
                                {listBuildingAttr}
                            </ul>
                        </div>:''}
                        <div className="discription-box">
                            <h4>Listing Description</h4>
                            <p id="listingDesc" style={{whiteSpace:'pre-wrap'}}>{unescape(this.state.listDetails.description)}</p>
                        </div>

                        {(this.state.listDetails.listing_type == 'Sales')?<div className="discription-box">
                            <h4 >Additional Details</h4>
                            <p>CC/Maintenance: {this.state.listSalesInfo!=undefined?this.state.listSalesInfo.maintenance:'N/A'}</p>
                            <p>Max Financing: {this.state.listSalesInfo!=undefined?this.state.listSalesInfo.max_financing:'N/A'}</p>
                        </div>:''}
                        <ul className="btn-group-section d-block d-sm-none">
                        <p className="availabel-txt">Available {(new Date().setHours(0,0,0,0) > new Date(this.state.listDetails.date_available).setHours(0,0,0,0) || this.state.listDetails.date_available=='0000-00-00')? 'Immediately':this.state.listDetails.date_available} </p>
									
						<p className="unfurnish-txt">{this.state.listDetails.furnished == 1 ? ('Furnished') : ('Unfurnished')} </p>   
                        <li><button type="button" className="btn apply-btn" data-dismiss="modal" onClick={this.props.checkLoginFORShowing(['applyNow',this.state.listDetails.listing_info_address,this.state.listDetails.unit_number,this.state.listDetails.buildingId])}>Apply Now</button></li>
   
                       <li>{this.state.loginCheck?(<button type="button" className="btn btn-default"   onClick={this.onOpenModal}>Request a showing</button>):(<button type="button" className="btn btn-default" data-toggle="modal" onClick={this.props.checkLoginFORShowing(['showing'])}>Request a showing</button>)}</li>

                            <li><button type="button" className={(this.state.favList.length>0 && this.state.favList.find(list => list.listId === this.state.listDetails.id))?'btn btn-default star-block active':'btn btn-default star-block'} data-dismiss="modal" id={`favList`+this.state.listDetails.id} onClick={this.props.saveToMYFavList([this.state.listDetails.id])}> Save Listing <img src="/star.png" width="20" /></button></li>
                            <li><button type="button" className={(this.state.favList.length>0 && this.state.favList.find(list => list.listId === this.state.listDetails.id))?'btn btn-default star-block active':'btn btn-default star-block'} data-dismiss="modal" id={`favList`+this.state.listDetails.id} onClick={this.props.saveToMYFavList([this.state.listDetails.id])}> Submit Inquiry</button></li>
                            <li><button type="button" className="btn btn-default" onClick={()=>this.copyUrl(this.state.listDetails.id)}>Copy URL</button></li>
                            <li><div className="map-wrap">
                                <img src="/map.png" width="100%" />

                            </div></li>
                        </ul>
                        
                        <div className="discription-box" style={{display:this.state.showBlock}}>
                            <h4>Open House</h4>
<p>
    {(this.state.openHouseList.length>0)?Moment(this.state.openHouseList[0].date).format('ddd, MMM DD'):'-'} | {(this.state.openHouseList.length>0)?this.state.openHouseList[0].games[0].show_time:'-'} 
    <br/>
    <button type="button" className="btn btn-default"   onClick={this.OpenModelShowHouse}>Show More</button>
</p>
                        </div>
                        <div className="listed-box cleafix">
                            <h4>Listed by</h4>
                            <div className="info">
                            {this.state.listDetails.image?this.state.listDetails.image.startsWith("https://")?<img src={this.state.listDetails.image} width="75" height="75" className="rounded-circle" />:<img src={getBaseUrl()+"/containers/agent_image/download/"+this.state.listDetails.image} width="75" height="75" className="rounded-circle" />:''}        
                               
                            </div>
                            {/* {this.state.listDetails.image=='member.png' || this.state.listDetails.image==''?<img src="/profile.png" width="75" height="75" className="rounded-circle" />:<img src={this.state.listDetails.image} width="75" height="75" className="rounded-circle" />} */}
                            <p className="name">{this.state.listDetails.name}</p>
                                <p className="browselist-email">{aes.decrypt(this.state.listDetails.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</p>
                                <p>{aes.decrypt(this.state.listDetails.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</p>
                        </div>

                    </div>
                    <div className="col-sm-3 d-none d-sm-block">
                        <ul className="btn-group-section">
                            
                        

                        <p className="availabel-txt">Available {(new Date().setHours(0,0,0,0) > new Date(this.state.listDetails.date_available).setHours(0,0,0,0) || this.state.listDetails.date_available=='0000-00-00')? 'Immediately':Moment(this.state.listDetails.date_available).format('M/D/YY')} </p>
					
                        <p className="unfurnish-txt">{this.state.listDetails.furnished == 1 ? ('Furnished') : ('Unfurnished')} </p>     
                        <li><button type="button" className="btn apply-btn" onClick={this.props.checkLoginFORShowing(['applyNow',this.state.listDetails.listing_info_address,this.state.listDetails.unit_number,this.state.listDetails.buildingId])}>Apply Now</button></li>
                       
                        <li>{this.state.loginCheck?(<button type="button" className="btn btn-default"   onClick={this.onOpenModal}>Request a showing</button>):(<button type="button" className="btn btn-default" data-toggle="modal" onClick={this.props.checkLoginFORShowing(['showing'])}>Request a showing</button>)}</li>


                            <li><button type="button" className={(this.state.favList.length>0 && this.state.favList.find(list => list.listId === this.state.listDetails.id))?'btn btn-default star-block active':'btn btn-default star-block'} id={`favList`+this.state.listDetails.id} onClick={this.props.saveToMYFavList([this.state.listDetails.id])}> Save Listing <svg width="20" height="20" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="star" d="M33.6667 12.4L21.6834 11.3667L17 0.333374L12.3167 11.3834L0.333374 12.4L9.43337 20.2834L6.70004 32L17 25.7834L27.3 32L24.5834 20.2834L33.6667 12.4ZM17 22.6667L10.7334 26.45L12.4 19.3167L6.86671 14.5167L14.1667 13.8834L17 7.16671L19.85 13.9L27.15 14.5334L21.6167 19.3334L23.2834 26.4667L17 22.6667Z" fill="#BEB7B3"/>
</svg></button></li>

<li>{this.state.loginCheck?<button type="button" className="btn btn-default star-block" onClick={this.onOpenInquiryloggedIN}> Submit Inquiry </button>:<button type="button" className="btn btn-default star-block" onClick={this.onOpenInquiry}> Submit Inquiry </button>}</li>
<li><button type="button" className="btn btn-default" onClick={()=>this.copyUrl(this.state.listDetails.id)}>Copy URL</button></li>
                            <li><div className="map-wrap">

            {this.state.listDetails.latitude!='null' && this.state.listDetails.longitude!='undefined' && this.state.listDetails.latitude!='undefined' ? <Map className="markercluster-map" style={{width:'130px', height:'207px'}} center={[`${this.state.listDetails.latitude}`, `${this.state.listDetails.longitude}`]} zoom={12} maxZoom={18} minZoom={4}>
          
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
            <MarkerClusterGroup>
       <Marker position={[`${this.state.listDetails.latitude}`, `${this.state.listDetails.longitude}`]}>
                <Popup>
                    <div>
                    {this.state.listDetails.listing_info_address}<br />
                    <b>Price: {this.state.listDetails.price?'$'+this.state.listDetails.price.toLocaleString():'$'+this.state.listDetails.price}</b>
                    </div>
                </Popup>
                </Marker>
              
            
      
          </MarkerClusterGroup>
      
        </Map>:''}


                            </div></li>
                        </ul>
                    </div>
                </div>


                <button type="button" className="btn cl-btn" onClick={this.props.closeModal([this.state.listDetails.id])}>Close  <img src="/close.png" /></button>
            </div>

            {/* <div className="modal fade" id="CreateShowing" role="dialog">
            <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header" >
                <h4 className="modal-title">Create a new showing</h4> 
                <button type="button" className="close" data-dismiss="modal" id="newShowingClose">&times;</button>
            </div>
                <div className="modal-body">
                <div className="form-horizontal">
                    <div className="form-group row">                                            
                        <div className="control-label col-sm-12">
                            <label>Address</label>
                            <div>
                                <input type="text" id="listing_search_id" readOnly autoComplete="off" value={(this.state.listDetails.listing_info_address!=0)?this.state.listDetails.listing_info_address:''}  className="form-control"/>
                            </div>
                        </div>
                    </div>
				<div className="form-group row">                                            
                    <div className="control-label col-sm-12">
                        <label>Unit</label>
                        <div>
                            <input type="text" id="unit_search_id" readOnly  autoComplete="off" value={(this.state.listDetails.unit_number!=0)?this.state.listDetails.unit_number:''}  className="form-control"/>
                        </div>
                    </div>
                </div>
                    <div className="form-group row">
                    <div className="control-label col-sm-4">
                                <label>Time</label>
                                <DatePicker
                                id="datePicker_time"
                                selected={this.state.time}
                                onChange={this.handleChange}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="h:mm aa"
                                timeCaption="time"
                                minTime={set_hours(set_minutes(new Date(), 0), 7)}
                                maxTime={set_hours(set_minutes(new Date(), 0), 21)}
                                className="form-control"
                                required
                                onKeyDown = {(e)=>this.preventKeystroke(e)}
                                
                            /> 
                            </div>
                            <div className="control-label col-sm-8">
                                <label>Date</label>
                                <DatePicker
                                id="datePicker_date"
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate}
                                dateFormat="MMMM d, yyyy"
                                timeCaption="time"
                                className="form-control"
                                minDate={new Date()}
                                maxDate={addDays(new Date(), 7)}
                                required
                                onKeyDown = {(e)=>this.preventKeystroke(e)}
                            />
                            </div>                                               
                    </div>
                    
                    </div>
                    <div className="form-group text-right">
                    <button type="button"  block='true'
            disabled={!this.validateForm()}  className="btn btn cl-btn"  onClick={()=>this.createNewShowing()} >Create  <img src="/nextEditor-hover.png"/></button>                                              
                    </div>
                    </div>
                    </div>
                    </div>
                    </div> */}

<Modal open={openGallery} onClose={this.onCloseGallery} >
    <div id="gallery">
<div className="modal-dialog" style={{maxWidth:'100%', margin:'0', boxShadow:'none', background:'none'}}>
                        
                        <div className="modal-content">
                                <div className="modal-body" >
                                    <div className="listing-banner">
                                         <button type="button"  className="btn cl-btn"  onClick={this.onCloseGallery}> <img src="/close.png"/></button>
                                    <Carousel showThumbs={true} infiniteLoop={true} emulateTouch={true} >
                                            {/* <div>
                                                    <img src="/career-bg1.png" />
                                                
                                              </div>
                                              <div>
                                                    <img src="/career-bg2.png" />
                                                
                                              </div>
                                              <div>
                                                    <img src="/career-bg3.png" />
                                                
                                              </div> */}
                                              { listImagepopup }
         
                                         </Carousel>     
                                                         
                                    </div>
                                
                                </div>
                        </div>

                    </div>
                    </div>
        </Modal>
     
{/* Open House List Start */}
<Modal open={this.state.openHouseModel} >
        <div className="modal-header contact-agent-modal" >
                <h4 className="modal-title">Open House</h4> 
                <button type="button" className="close" onClick={this.CloseModelShowHouse}>&times;</button>
            </div>

                        <div id="divContent" class="application-details">
							<div id="contact_Email_id" class="time-width">
							
                            <div className="listed-box cleafix">
                            <div className="info" style={{padding:'20px'}}>
                                {openHouseRowList}
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        
							</div>
			</div> 
        </Modal>
{/*Open house list end*/}



<Modal open={open} onClose={this.onCloseModal} >
        <div className="modal-header" >
                <h4 className="modal-title">Request a showing</h4> 
                <button type="button" className="close" data-dismiss="modal" onClick={this.onCloseModal}>&times;</button>
            </div>
                <div className="modal-body">
                <div className="form-horizontal">
                    <div className="form-group row">                                            
                        <div className="control-label col-sm-12" >
                            <label>Address</label>
                            <div>
                                <input type="text" id="listing_search_id" readOnly autoComplete="off" value={(this.state.listDetails.listing_info_address!=0)?this.state.listDetails.listing_info_address:''}  className="form-control"/>
                            </div>
                        </div>
                    </div>
				<div className="form-group row">                                            
                    <div className="control-label col-sm-12">
                        <label>Unit</label>
                        <div>
                            <input type="text" id="unit_search_id" readOnly  autoComplete="off" value={(this.state.listDetails.unit_number!=0)?this.state.listDetails.unit_number:''}  className="form-control"/>
                        </div>
                    </div>

                    {/* <div className="control-label col-sm-4">
                        <label>Split</label>
                        <div>
                            <input
                             type="text"
                             className="form-control" 
                             id="split" 
                             value={this.state.split}
                             onChange={this.splithandleChange}
                             />
                        </div>
                    </div> */}
                </div>
                    <div className="form-group row">
                    <div className="control-label col-sm-6">
                                <label>Time</label>
                                <DatePicker
                                id="datePicker_time"
                                selected={this.state.time}
                                onChange={this.handleChange}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="h:mm aa"
                                autoComplete="off"
                                timeIntervals={15}
                                dateFormat="h:mm aa"
                                timeCaption="time"
                                minTime={set_hours(set_minutes(new Date(), 0), 0)}
                                maxTime={set_hours(set_minutes(new Date(), 45), 23)}
                                className="form-control"
                                required
                                onKeyDown = {(e)=>this.preventKeystroke(e)}
                                
                            /> 
                            </div>
                            <div className="control-label col-sm-6">
                                <label>Date</label>
                                <DatePicker
                                id="datePicker_date"
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate}
                                dateFormat="MMMM d, yyyy"
                                timeCaption="time"
                                className="form-control"
                                minDate={new Date()}
                                maxDate={addDays(new Date(), 7)}
                                required
                                onKeyDown = {(e)=>this.preventKeystroke(e)}
                            />
                            </div>                                               
                    </div>


                    <div className="detail-box row">
                    <div className="detail-box col-sm-6">
                                <label>Client Name*</label>
                                <input 
                                type="text" 
                                
                                className="form-control"
                                id="client_name"
                               value={this.state.loginCheck?this.state.client_name: this.state.client_name}
                               onChange={this.clientnamehandleChange}
                               readOnly
                             />
                            </div>
                            <div className="detail-box col-sm-6">
                                <label>Client number*</label>
                                <input type="text"
                                
                                className="form-control"
                                id="client_contact"
                                value={this.state.loginCheck?this.state.client_contact: this.state.client_contact}
                                maxLength="12"
                                placeholder="XXX-XXX-XXXX"
                                onChange={this.clientconatcthandleChange}
                                readOnly
                            />
                            </div>                                               
                    </div>
                    
                    </div>
                    <div className="form-group text-right">
                    <button type="button"  block='true'
            disabled={!this.validateForm()}  className="btn btn cl-btn"  onClick={()=>this.createNewShowing()} >Request  <img src="/nextEditor-hover.png"/></button>                                              
                    </div>
                    </div>
        </Modal>

{/*Start  Modal for submit inquiry */}
    <Modal open={openInquiry} onClose={this.onCloseInquiry} >
        <div className="modal-header" >
                <h4 className="modal-title">Submit Inquiry</h4> 
                <button type="button" className="close" data-dismiss="modal" onClick={this.onCloseInquiry}>&times;</button>
            </div>
            <div class="modal-body contact-agent-modal" >
            
            <div className="listed-box cleafix" style={{marginBottom:'30px'}}>
                            
                            <div className="info">
                            {this.state.listDetails.image.startsWith("https://")?<img src={this.state.listDetails.image} width="75" height="75" className="rounded-circle" />:<img src={getBaseUrl()+"/containers/agent_image/download/"+this.state.listDetails.image} width="75" height="75" className="rounded-circle" />}        
                            </div>
                            <div className="agent-wrap">
                            <p class="modal-title">
				<strong>To</strong></p>
				<div class="contact-info"><div>
					<label for="tl"><p>
                    {aes.decrypt(this.state.listDetails.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</p><span id="Count"></span>
                    <p>{aes.decrypt(this.state.listDetails.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</p>
						</label></div>
						</div>
                                </div>
                        </div>
                        <div className="clearfix"></div>

			
					<strong>Subject</strong>
						
					<div>
						<input  title={'Request for Inquiry'+' '+this.state.listDetails.listing_info_address+','+ this.state.listDetails.unit_number} class="form-group col-sm-12 form-control" type="text" autocomplete="off" readonly="" value={'Request for Inquiry'+' '+this.state.listDetails.listing_info_address+','+ this.state.listDetails.unit_number}/></div>
						<div id="divContent" class="application-details" contenteditable="true">
							<div id="contact_Email_id">
							
<p>Name:<input 
            type="text" 
            className="form-control"
            id="client_nameInquiry"
            value={this.state.loginCheck?this.state.client_nameInquiry: this.state.client_nameInquiry}
            onChange={this.clientnamehandleChange}
            /> </p>
<p>Email:<input type="text"
                                
                                className="form-control"
                                id="client_email"
                                value={this.state.loginCheck?this.state.client_email: this.state.client_email}
                                onChange={this.clientnamehandleChange}
                                
                            /> </p>
                             <p><textarea  className="form-control txtarea"  id="description" rows="7" onChange={this.clientnamehandleChange}  value={this.state.description} placeholder="Description"></textarea></p>

 

							</div>
					</div>
				<div class="application-approval-confirm">
					<button type="button" id="contactmailclose" class="btn btn-default" onClick={this.onCloseInquiry} >Cancel</button>
					<button type="button" class="btn btn-default"  disabled={!this.validateInquiry()}  onClick={()=>this.sendinquirymail()}>Send</button>
				</div>
			</div> 
        </Modal>
{/*End Modal*/}	

{/*Start  Modal for submit inquiry LoddedIn */}
    <Modal open={openInquiryLoggedIn} onClose={this.onCloseInquiryloggedIN} >
        <div className="modal-header" >
                <h4 className="modal-title">Contact Listing Agent</h4> 
                <button type="button" className="close" data-dismiss="modal" onClick={this.onCloseInquiryloggedIN}>&times;</button>
            </div>
            <div class="modal-body contact-agent-modal">
			{/* <p class="modal-title">
				<strong>To</strong></p>
				<div class="contact-info"><div>
					<label for="tl"><p>
                    {aes.decrypt(this.state.listDetails.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)}</p><span id="Count"></span>
						</label></div>
						</div> */}
					
						
					<div>
						{/* <input title={'Request for Inquiry'+' '+this.state.listDetails.listing_info_address+','+ this.state.listDetails.unit_number} class="form-group col-sm-12" type="text" autocomplete="off" readonly="" value={'Request for Inquiry'+' '+this.state.listDetails.listing_info_address+','+ this.state.listDetails.unit_number}/></div> */}
						</div>
                        <div id="divContent" class="application-details">
							<div id="contact_Email_id">
							
                            <div className="listed-box cleafix">
                            
                            <div className="info">
                            {this.state.agent_image.startsWith("https://")?<img src={this.state.agent_image} width="75" height="75" className="rounded-circle" />:<img src={getBaseUrl()+"/containers/agent_image/download/"+this.state.agent_image} width="75" height="75" className="rounded-circle" />}        
                            </div>
                            <div className="agent-wrap">
                                <p className="name">{this.state.agent_name}</p>
                                <p>{this.state.agent_phone}</p>
                                </div>
                        </div>
                        <div className="clearfix"></div>
                        <p><input 
                        className="form-control"
            type="text" 
            value={this.state.agent_email}
            readOnly
            /></p>
                        <p><input 
                         className="form-control"
            type="text"
            id="CC_mail" 
            value={this.state.CC_mail}
            onChange={this.clientnamehandleChange}
            placeholder="Add CC"
            /> </p>
                           <p>  <textarea  className="form-control txtarea" id="descriptionLoggedIn" rows="7" onChange={this.clientnamehandleChange}  value={this.state.descriptionLoggedIn} placeholder="Add note.."></textarea></p>

                             <p><input type="checkbox" id="15" 
																		onChange={this.handleCheck}
																		checked={this.state.mailcopy}
																		/>
																		<label for="15">Send me a copy of this</label></p>

							</div>
					</div>
				<div class="application-approval-confirm">
					<button type="button" id="contactmailclose" class="btn btn-default" onClick={this.onCloseInquiryloggedIN} >Cancel</button> 
					<button type="button" class="btn btn-default"  disabled={!this.validateInquiryLoggedin()}  onClick={()=>this.sendinquirymailLoggedIn()}>Send</button>
				</div>
			</div> 
        </Modal>
{/*End Modal*/}



        </div></div></div>
        
        
        
        );
    }
}
export default ListDetail;