import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/ManagerHeader';
import Sidebar from '../common/ManagerSidebar';
import { getManagerToken, getApiData ,postApiData} from '../../services/Api';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";




class Templates extends React.Component {

	constructor(props) {
        super(props);
        this.state= {
			file_error:'',
			loader_status:true,
			template_list:[],
			success_msg:'',
			file_success:'',
        };
        
	}
	
	componentDidMount(){
			this.templateList();
	}


	async templateList(){
		this.setState({loader_status:false});
		let where = [{
		  url: "/managers/getAll",
	  }];
		  getApiData(where, true).then(res => {
			  this.setState({template_list:res.data,loader_status:true});
		  }).catch(error => {
			 console.log('in catch',error);
			 this.setState({
				loader_status: true,
				file_error:'Something went wrong,Try after some time',
				file_success:'',
				 },()=>{
					 document.getElementById('uploadfile').value='';
				 });
		  });
  }


	fileChangedHandler = (event) => {
		
        if(event.target.files[0]){
         let imgFlag = true;
        const maxFileSize = 5242880;   //5mb
        const value = event.target.files[0].name;
        const ext = value.substring(value.lastIndexOf('.')).toLowerCase();
        if(!['.pdf'].includes(ext)){
            this.setState({file_error: ext+' is not supported file extension.',file_success:''})
            imgFlag = false;
        }
        if(event.target.files[0].size>maxFileSize){
            this.setState({file_error:"File size is too big, upto 5MB allowed.",file_success:''})
            imgFlag = false;
            }
        if(imgFlag) {  
            this.setState({
			loader_status: false
		   });
        this.fileUpload(event.target.files[0]);
        }
     }            
}

async fileUpload(file,container='templates') {
   let where = [{
		url: '/containers/'+container+'/upload'
   }];
   const formData = new FormData();
   formData.append('file',file);
   postApiData(where, formData,'post',true).then(res => {
	   if(res.data.success)
	   {
			let where = [{
			url: "/managers/templates"
			}];
			postApiData(where, {
				'name':res.data.file_name,
				}).then(res => {
					this.setState({
						loader_status: true,
						file_error:'',
						file_success:'File Uploaded',
						success_msg:'',
						 },()=>{
							 document.getElementById('uploadfile').value='';
							 this.templateList();
						 });
			}).catch(error => {
			console.log('in catch',error);
			this.setState({
				loader_status: true,
				file_error:'Something went wrong,Try after some time',
				file_success:'',
				 },()=>{
					 document.getElementById('uploadfile').value='';
				 });
			});
	   }
   })
	   .catch(error => {
		   console.log(error);
		   this.setState({
			loader_status: true,
			file_error:'Something went wrong,Try after some time',
			file_success:'',
			 },()=>{
				 document.getElementById('uploadfile').value='';
			 });
	   });
}

handleLinking = param => e => {
	this.setState({loader_status:false});
	let where = [{
	  url: "/managers/remove/template",
	  where: {"where":{"id":param}},
  }];
	  getApiData(where, true).then(res => {
		  this.setState({success_msg:'Record Deleted', loader_status:true});
		  this.templateList();
	  }).catch(error => {
		 console.log('in catch',error);
		 this.setState({
			loader_status: true,
			file_error:'Something went wrong,Try after some time',
			file_success:'',
			 },()=>{
				 document.getElementById('uploadfile').value='';
			 });
	  });

	
}
	render(){

		const tr = this.state.template_list.map((temp,index) => 
            <tr>
            	<td>{temp.name}</td>
		<td className="text-center"><Link to className="icon-wrap delete" key={temp.id}  onClick={this.handleLinking([temp.id])}></Link></td>
            </tr>
       );
			 return (
				
                <div>
					{(!this.state.loader_status)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}
                    <Header />
                    <main>
					<Sidebar />
					<div className="content">
						<div className="work-section templatesGrid">
						<div className="dashboard">

							
								<h2 className="pull-left">Templates</h2>
								{(this.state.success_msg !== '') ?<div className="alert alert-success pull-right"> {this.state.success_msg !==''?this.state.success_msg:''} </div>:''}
								{/* <button onClick={this.resetFilter} className="btn btn-default pull-right ">Reset Filter</button> */}
								<div className="panel-body table-responsive">
								
									<table className="table table-striped">
										<thead>
											<tr>
												<th width="80%">Template Name</th>
												<th className="text-center">Delete</th>
											</tr>
										</thead>
										<tbody>
										{tr}
											
										</tbody>
										</table>
									</div>
									<div className="clearfix btn-row managerTemplates">
									
									
								
								
									<div className="upload-btn-wrapper mtop" id="gov_issued_id_cont">
										<button className="btn btn-default" id="uploadBtn" type="button">Upload a new Template</button>
										<FormGroup controlId="gov_issued_id">
											<FormControl type="file" id="uploadfile" onChange={this.fileChangedHandler} required style={{marginTop: '10px'}} />
										</FormGroup>
									</div>

									
									{(this.state.file_error !== '') ?<div className="alert alert-danger text-left"> {this.state.file_error !==''?this.state.file_error:''} </div>:''}
									{(this.state.file_success !== '') ?<div className="alert alert-success text-left"> {this.state.file_success !==''?this.state.file_success:''} </div>:''}
								
                            </div>
							</div>
							
						</div>
					</div>	

	
               				      
                          
                    </main>     
                </div>

				);
    }
}
export default Templates;