import React from 'react';

import ScrollspyNav from "react-scrollspy-nav";
import $ from 'jquery';
import '../manual/highlight';
import '../manual/finder';
import 'jquery.scrollto';
import { getApiData, postApiData, getAgentId,getBaseUrl } from "../../services/Api";

class Welcome extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    pageContent:'',
    linkingLoader:false,
    editable:getAgentId()>0 || getAgentId()==0?"false":"true"
    }
  }
  componentWillMount(){
		this.getPageContent();
	}
  getPageContent=()=>{
         
		let where = [{
			url:'/managers/manualPageContent/'
		}]
		postApiData(where,{'page':'glossary_content'},true).then(res=>{
				this.setState({
					pageContent:res.data[0].html_value,
					
				},()=>{
          document.getElementById('result-list').innerHTML=unescape(this.state.pageContent);
        })
		
			
		}).catch(error=>{

		})
  }
  contentSave=(type)=>e=>{
		this.setState({linkingLoader:true})
		var divValue='';

		 divValue=document.getElementById('result-list').innerHTML;
	
		let where=[{
			url:'/managers/manualContetSave'
		}];
		// var formData = new FormData();
		// formData.append('content',abc);
		
		postApiData(where,{'page':'glossary_content','content':divValue},true).then(res=>{
			
				this.setState({linkingLoader:false},()=>{
				alert("Welcome Manual Published Successfully.")
				})
				
		}).catch(error=>{

		})
	}    
    render() {
        return (
          <div class="manual-section" > 
          
          <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-light" >
<img src="/logo.png" class="img-fluid logo" style={{maxWidth:'300px'}}/>


<ul class="navbar-nav mr-auto p-1 m-auto">
      <li class="nav-item d-none d-sm-block">
       
      </li>
    </ul>
    <ul class="navbar-nav d-none d-md-block d-lg-block d-xl-block col-md-2 text-right">
      <li class="nav-item ml-2">
      <button className="btn my-2 my-sm-0 finder-activator" type="submit" data-finder-activator data-toggle="collapse" data-target="#demo">
              <i className="fa fa-search"></i>
            </button>
        {getAgentId()>0 || getAgentId()==0 ?'' :<button class="btn btn-default pull-right saveBtnManual" onClick={this.contentSave('approve')}>Publish</button>}</li>

    </ul>
    <ul class="navbar-nav d-block d-sm-none">
      <li class="nav-item ml-2">
          <button class="btn my-2 my-sm-0 finder-activator" type="submit" data-toggle="collapse" data-target="#demo">
            <i class="fa fa-search"></i>
          </button>
      
      
      <button class="btn btn-default pull-right saveBtnManual" >SAVE</button></li>
    </ul>

</nav>
     
           <div class="container-fluid">
       <div class="row">
         <div class="col-xl-9 col-lg-8 col-xs-12 p-1 divtopmargin right content" id="result-list" contentEditable={this.state.editable}>

        </div>
               
                 <nav class="d-none d-sm-none d-md-block col-xl-3 col-lg-4 left" id="myScrollspy">
      <div class="leftdiv col-xl-3 col-sm-4" style={{paddingLeft:'15px'}}>
      <ScrollspyNav scrollTargetIds={["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]} activeNavClass="active" scrollDuration="800" headerBackground="false">
              
      <ul class="nav nav-pills flex-column" style={{paddingRight:'15px'}}>
        <li class="nav-item">
          <a class="nav-link" href="#A">A</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#B" >B</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#C"> C</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#D">D</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#E">E</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#F">F</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#G">G</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#H">H</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#I" >I</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#J" >J</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#K" >K</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#L" >L</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#M" >M</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#N" >N</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#O" >O</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#P" >P</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#Q" >Q</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#R" >R</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#S" >S</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#T" >T</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#U" >U</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#V" >V</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#W" >W</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#X" >X</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#Y" >Y</a>
        </li>  
        <li class="nav-item">
          <a class="nav-link" href="#Z" >Z</a>
        </li>    
      </ul>
      </ScrollspyNav>
      </div>
    </nav>
    <div className="finder active collapse" id="demo">
              <input id="finderInput" type="text" className="finder-input" onChange={this.searchDem} placeholder="Search Here..."/>
                <button id="finderPrev" className="btn btn-finder btn-finder-prev">
                  <i className="fa fa-angle-up"></i>
                </button>
                <button id="finderNext" className="btn btn-finder btn-finder-next">
                  <i className="fa fa-angle-down"></i>
                </button>
                <button id="finderClose" data-toggle="collapse" data-target="#demo" className="btn btn-finder btn-finder-close">
                  <i className="fa fa-times"></i>
                </button>
              </div>
  </div>
  
             </div>
</div>
    
);
    }
}
export default Welcome;