import React from 'react';
import {Link} from 'react-router-dom';
import { getApiData, getToken, getUserId,getOnBoardingDone } from '../../services/Api';
import { Redirect } from 'react-router';

class PdfEditorHeader extends React.Component {

    constructor(props) {
        super(props);
        this.nav_bar_col = React.createRef();
        
        this.state = {
            showSign: true,
            user_id: localStorage.getItem('user_id'),
            user_name: "",
            account_verify:''

          }; 
          this.onLoad = this.onLoad.bind(this);
          this.setWrapperRef1 = this.setWrapperRef1.bind(this);
          this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.onLoad();
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    setWrapperRef1(node) {
        this.wrapperRef1 = node;
        }
        handleClickOutside(event) {
         // alert(event.target.id)              
        if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
            document.getElementById('collapsibleNavbar').classList.remove("show");
          } 
       }  
    async onLoad(){
        if(getToken()){
            this.setState({'user_name':localStorage.getItem("user_name"),'showSign':false}); 
        }
    }

    render() {
        console.log('localStorage.getItem',localStorage.getItem('imageUrl'))
        var imgUrl=localStorage.getItem('imageUrl');

        var flag=getOnBoardingDone();
        console.log("getOnBoardingDone-----",flag);
        if(imgUrl=="" || imgUrl==null){
                imgUrl="/member.png";
        }
        this.state.account_verify = localStorage.getItem('account_verified');
      
        return (
               <header className="L-header">             
                <nav className="navbar navbar-expand-lg bg-light navbar-dark">
                <Link className="navbar-brand logo" to="/frontend/landing"><img src="/logo.png" width="100%" /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <img src="/menu-icon.png"/>
                </button>
                <div className="collapse navbar-collapse " id="collapsibleNavbar"  ref={this.setWrapperRef1}>
                 </div>  
                </nav>
                </header>
                );
    }
}
export default PdfEditorHeader;