import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/AgentHeader';
import { getAgentId, getAgentToken, getApiData, getBaseUrl, postApiData } from '../../services/Api';
import { Redirect } from 'react-router';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import set_hours from "date-fns/set_hours";
import set_minutes from "date-fns/set_minutes";
import DatePicker from "react-datepicker";
import addDays from "date-fns/add_days";
import Moment from 'moment';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import { validatePhone, socialSecurityNumber } from '../../services/validation';

class CreateShowingPopUp extends React.Component {
    constructor(props) {
        super(props);
        console.log("props", props)
        this.state = {
            listingCreator: []
            , clientSearchList: []
            , split: ''
            , checkSplit: true
            , time: ''
            , startDate: new Date()
            , clientphone: { phone_number: '' }
            , client_fill_email: {email:''}
            , client_name: ''
            , linkingLoader: true
            , unit_value: ''
            ,unitListingCreator:[]
            ,isChecked:false
            ,address_value:''
            ,phone_number:''
            ,currentTime:new Date().getHours()+1
        }
    }
    componentDidMount() {

        Promise.all([
            this.clientSearchOpt()
            , this.listingOpt()
        ]).then(result => {
            console.log("result", result);
            this.setState({
                linkingLoader: false
            })
        })
            .catch((error) => {
                console.log("something went wrong", error)
                this.setState({
                    linkingLoader: false
                })
            })

    }

    async clientSearchOpt() {
        return new Promise((resolve, reject) => {
            let agentParam = [{
                url: "/showings/addShowing/clientSearch"

            }];
            getApiData(agentParam, true).then(res => {
                this.setState({ clientSearchList: res.data });
                resolve(true)
            }).catch(error => {
                if (error.response) {
                    this.setState({ myerrormsg: error.response.data.error.message });
                    reject(false)
                }
            });
        })



    }

    listingOpt() {
        return new Promise((resolve, reject) => {
            this.setState({
                linkingLoader: true
            })
            let agentParam = [{
                url: "/showings/listingCreator"
            }];
            getApiData(agentParam, true).then(res => {
                this.setState({ 'listingCreator': res.data, linkingLoader: false });
                resolve(true);
            }).catch(error => {
                if (error.response) {
                    this.setState({ myerrormsg: error.response.data.error.message, linkingLoader: false });
                    reject(true);
                }
            });
        }).catch(error => {
            this.setState({ myerrormsg: error.response.data.error.message, linkingLoader: false });
        });

    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        }, () => {
            if (this.state.isChecked == true) {
                if (this.state.split.length > 0) {

                    this.setState({
                        checkSplit: true
                    })
                } else {
                    this.setState({
                        checkSplit: false
                    })
                }
            } else {
                this.setState({
                    checkSplit: true
                })
            }
        });


    }

    splithandleChange = (event) => {
        this.setState({ split: event.target.value });
        if (this.state.split.length > 0 && this.state.isChecked == true) {
            this.setState({
                checkSplit: true
            })
        } else {
            this.setState({
                checkSplit: false
            })
        }

    }
    handleChange = (date) => {
        this.setState({
            time: date
        });
        document.getElementById('datePicker_time').value = date;
    }
    handleChangeDate = (date) => {

        var selectedDate = Moment(date).format('YYYY-MM-DD');
        var todaysDate = Moment(new Date()).format('YYYY-MM-DD');
        if (selectedDate == todaysDate) {
            if (this.state.time != '') {
                this.state.time = '';
            }
            this.setState({
                currentTime: new Date().getHours() + 1
            });
        }
        else {
            this.setState({
                currentTime: '7'
            });
        }

        this.setState({
            startDate: date
        });
    }
    handleListingCreatorSearch = event => {
        var selected_listing_name = event.target.getAttribute('name');

        this.setState({ address_value: event.target.value });
        //alert(selected_client_name);
        this.setState({
            selected_listing_name: selected_listing_name
        })
        var key = event.target.id;
        switch (key) {
            case 'listing_search_id':
                this.setState({ listingSearchParm: event.target.value, listing_name: event.target.value }, () => {
                    if (this.state.listingSearchParm.length > 2) {

                        var value = this.state.listingSearchParm;
                        var len = this.state.listingCreator.length;
                        var count = 0;
                        var vflag = 0;
                        var showCount = 0;
                        if (value != '') {
                            this.state.listingCreator.forEach(function (listing, index) {
                                // console.log("listingName", listing.name);
                                if (listing.name.trim().toLowerCase().includes(value.trim().toLowerCase()) && showCount < 10) {
                                    listing.flag = 1;
                                    vflag = 1;
                                    count = count + 1;
                                    showCount = showCount + 1;
                                    document.getElementById('listing_search_id_values').style.display = "block";
                                } else if (listing.flag == 1) {
                                    listing.flag = 0;
                                    if (vflag != 1) vflag = 0
                                    count = count + 1;
                                } else {
                                    count = count + 1;
                                }
                            });
                        } else {
                            document.getElementById('listing_search_id_values').style.display = 'none';
                            this.state.listingCreator.forEach(function (listing, index) {
                                listing.flag = 0;
                            });

                        }

                        if (len == count) {
                            if (vflag == 0) {
                                document.getElementById('listing_search_id_values').style.display = 'none';
                                //this.setState({searchMsg:true});

                            } else {
                                this.setState({ searchMsg: false });

                            }
                        }
                    }
                    if (this.state.listingSearchParm.length <= 2) {

                        document.getElementById('listing_search_id_values').style.display = 'none';
                    }
                })
                //}
                break;
            case 'searchkey_listing':
                var value = event.target.value;
                let $this = this;
                this.setState({ listing_id: event.target.value }, () => {
                    this.state.listingCreator.forEach(function (listing, index) {
                        if (listing.id == value) {
                            document.getElementById('listing_search_id_values').style.display = "none";
                            document.getElementById('listing_search_id').value = listing.name;
                            //document.getElementById('listing_search_id').value=listing.id;
                            //document.getElementById('unit_search_id').value=listing.id;
                            $this.setState({ listing_name: listing.name });
                            if (document.getElementById('listing_search_id').value != "") {
                                $this.setState({
                                    linkingLoader:true
                                },()=>{
                                    let agentParam = [{
                                        url: "/showings/UnitlistingCreator",
                                        urlParms: "?listingname=" + document.getElementById('listing_search_id').value
                                    }];
                                    getApiData(agentParam, true).then(res => {
                                        $this.setState({ 'unitListingCreator': res.data,linkingLoader:false });
                                    }).catch(error => {
                                        if (error.response) {
                                            $this.setState({ myerrormsg: error.response.data.error.message,linkingLoader:false });
                                        }
                                    });
                                })
                                
                            }
                        } else {
                            listing.flag = 0;
                        }
                    });

                    document.getElementById('listing_search_id_values').style.display = "none";
                });
                break;
            case 'default':
                break;

        }



    }
    handleClientSearch = event => {
        var selected_client_name = event.target.getAttribute('name');

        this.setState({
            selected_client_name: selected_client_name,client_id:''
        })
        // this.createUpperWhere();
        var key = event.target.id;
        switch (key) {
            case 'client_search_id':

                this.setState({ searchParm: event.target.value, client_name: event.target.value }, () => {

                    //if(this.state.searchParm.length>-1){
                    var value = this.state.searchParm;
                    var len = this.state.clientSearchList.length;
                    var count = 0;
                    var vflag = 0;
                    if (value != '') {
                        this.state.clientSearchList.forEach(function (client, index) {
                            if (client.name.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                                client.flag = 1;
                                vflag = 1;
                                count = count + 1;
                            } else {
                                client.flag = 0;
                                if (vflag != 1)
                                    vflag = 0
                                count = count + 1;
                            }
                        });
                    } else {
                        document.getElementById('client_search_id_values').style.display = 'none';
                        this.state.clientSearchList.forEach(function (client, index) {
                            client.flag = 0;
                        });

                    } if (len == count) {
                        if (vflag == 0) {
                            // this.setState({searchMsg:true});
                            document.getElementById('client_search_id_values').style.display = 'none';

                        } else {
                            this.setState({ searchMsg: false });

                        }
                    }
                }



                )
                // this.createUpperWhere();      
                //}
                break;
            case 'clientNo_search_id':
                // this.setState({clientphone: {phone_number:''}}); 
                this.setState({ phone_number: '', custom_client_email: '' })

                break;
            case 'searchkey':
                var value = event.target.value;
                let $this = this;
                this.setState({ agent_id: event.target.value }, () => {
                    this.state.clientSearchList.forEach(function (client, index) {
                        if (client.id == value) {
                            document.getElementById('client_search_id_values').style.display = "none";
                            document.getElementById('client_search_id').value = client.name;
                            document.getElementById('client_selected_id').value = client.id;
                            document.getElementById('clientNo_search_id').value = client.id;
                            // alert(client.id);
                            $this.setState({ client_name: client.name });
                            $this.setState({ client_id: client.id },()=>{
                                $this.clientphone();
                                $this.clientfillemail();
                            });
                            
                        } else {
                            client.flag = 0;
                        }
                    });

                    document.getElementById('client_search_id_values').style.display = "none";
                });
                break;
            case 'default':
                break;

        }

        document.getElementById('client_search_id_values').style.display = "block";

    }

    async clientphone() {
this.setState({linkingLoader:true},()=>{
    let clientParam = [{
        url: "/showings/clientphone/" + this.state.client_id
    }];
    getApiData(clientParam, true).then(res => {
        this.setState({ clientphone: res.data[0],linkingLoader:false });
    }).catch(error => {
        if (error.response) {
            this.setState({ myerrormsg: error.response.data.error.message });
        }
    });
})
        
    }

    async clientfillemail() {
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv = encHex.parse("abcdef9876543210abcdef9876543210");
        this.setState({linkingLoader:true},()=>{
        let clientParam = [{
            url: "/showings/clientemail/" + this.state.client_id
        }];
        getApiData(clientParam, true).then(res => {
            console.log('client_fill_email', res.data);
            let email_name=aes.decrypt(res.data[0].email, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) 
            this.setState({ 
                client_fill_email: {email:email_name}
                ,linkingLoader:false });
        }).catch(error => {
            if (error.response) {
                this.setState({ myerrormsg: error.response.data.error.message,linkingLoader:false });
            }
        });
    });
    }

    validateForm=()=>{
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv = encHex.parse("abcdef9876543210abcdef9876543210");
        let phone_number=this.state.clientphone.phone_number;
        if(phone_number.length>20){
            phone_number = aes.decrypt(this.state.clientphone.phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8)
        }
        return (
            ((this.state.isChecked == true)?(this.state.split.length>0)?true:false:true)
            && ((this.state.address_value != "" && this.state.unit_value != "" && this.state.client_fill_email.email != '')?true:false)
            && (this.state.client_name.length>0)
            && ((pattern.test(this.state.client_fill_email.email)===true)?true:false)
            && (phone_number.length == 12)
            )?true:false;
    }
    formValidate(key, value) {
        switch (key) {
            case 'clientNo_search_id':
                this.validatePhone(value);
                break;
        }
    }
    validatePhone(value) {
        let res = validatePhone(value);
        if (res.status == false) {
            this.setState({ phoneerror: 'Phone number is not valid.' });
            document.getElementById("clientNo_search_id").value = res.value;
        } else {
            this.setState({ phoneerror: '' });
            document.getElementById("clientNo_search_id").value = res.value;
        }
        return res.status;
    }
    handlePhoneChange = event => {

        console.log('Events Phone=>', event.target.id, event.target.value);
        let ph=validatePhone(event.target.value);
        this.setState(state => (this.state.clientphone.phone_number=ph.value))
        this.setState({client_id:''})
        // console.log("validatePhone(event.target.value)",validatePhone(event.target.value))
        // if(ph.status){
        //     this.setState(state => (this.state.clientphone.phone_number=ph.value))
        // }else{
        //     this.setState(state => (this.state.clientphone.phone_number=ph.value))
        // }
        // this.formValidate(event.target.id, event.target.value);
        // if (event.target.id === 'clientNo_search_id') {
        //     if (this.isNum(event.target.value) != true) {
        //         this.setState({ phone_number: '' })
        //     }
        //     else {
        //         this.setState(state => (this.state.clientphone.phone_number=event.target.value))
        //     }
        // }
    }

    isNum(num) {
        if (/^[0-9-]*$/.test(num))
            return true;
        else
            return false;
    }

    handleEmailChange = event => {
        let email=event.target.value;
        if (event.target.id == 'clientEmail_search_id') {
            this.setState(state => (this.state.client_fill_email.email=email))
            this.setState({client_id:''})
        }
    }
    createNewShowing = () => {
        console.log('this.state.clientphone.phone_number',this.state.clientphone.phone_number)
        document.getElementById('createnewshowing').disabled=true;
        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv = encHex.parse("abcdef9876543210abcdef9876543210");

        let agentFlag = 0;
        let showingTime;
        let startDate = Moment(this.state.startDate).format('YYYY-MM-DD');
        if (this.state.time != '' && this.state.time != undefined) {
            showingTime = Moment(this.state.time).format('HH:mm:ss');
        }
        else {
            showingTime = '23:59:59';
        }

        startDate = startDate + ' ' + showingTime;
        let split = this.state.split;
        let email = 'info@moveskyward.com';
        let createShowing = [{
            url: "/showings/createShowing"

        }];
        var form = new FormData();
        if (this.state.isChecked != true) {
            form.append("agent_id", getAgentId());
        }
        else {
            form.append("created_by", getAgentId());
        }


        if (this.state.client_id == 0) {

            form.append("client_id", "");
        }
        else {
            form.append("client_id", this.state.client_id);
        }
        if (document.getElementById('listing_search_id').value) {
            form.append("listing_add", document.getElementById('listing_search_id').value);
        }
        else {
            form.append("listing_add", this.state.address_value);
        }

        if (document.getElementById('unit_search_id').value) {
            form.append("listing_unit", document.getElementById('unit_search_id').value);
        }
        else {
            form.append("listing_unit", this.state.unit_value);
        }


        form.append("showing_time", showingTime);
        form.append("showing_date", startDate);
        // form.append("client_name",this.state.client_name);
        if (this.state.client_name) {
            form.append("client_name", this.state.client_name);
        }
        else {
            form.append("client_name", this.state.client_fill_name);
        }
        if (this.state.clientphone.phone_number.length>12) {
            form.append("client_contact", aes.decrypt(this.state.clientphone.phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8));
        }
        else {
            form.append("client_contact", this.state.clientphone.phone_number);
        }
        if (this.state.client_fill_email.email) {
            form.append("email", this.state.client_fill_email.email);
        }
        else {
            form.append("email", this.state.custom_client_email);
        }

        // form.append("email",email);
        form.append("split", split);
        form.append("status", 2);
        form.append('notes', this.state.notes);

        postApiData(createShowing, form, false).then(res => {
            console.log("response--", res);
            console.log("response--", res.data.message.insertId);
            if (res.data.success) {
                this.setState({ unit_value: '', unit_name: '', address_value: '', client_name: '', phone_number: '', custom_client_email: '' })
                this.setState({ clientphone: { phone_number: '' } });
                document.getElementById("newShowingClose").click();
                // window.location.reload();
                //this.updateTimeKitForShowing(res.data.message.insertId, true);
                this.props.reList();
            }
            else {
                this.setState({ myerrormsg: res.data.message });
            }

        }).catch(error => {

        });
    }
    // updateTimeKitForShowing(clientShowingId, flag = false) {

    //     let timeKitShowing = [{
    //         url: "/showings/createShowing/" + clientShowingId
    //     }];
    //     getApiData(timeKitShowing, false).then(res => {
    //         console.log("response---", res);
    //         if (flag) {
    //             this.updateTimeKitForAction(clientShowingId, 'agent_id');
    //         }
    //     }).catch(error => {
    //     });
    // }
    handleListingCreatorUnitSearch = event => {
        var selected_unit_name = event.target.getAttribute('name');

        this.setState({ unit_value: event.target.value });
        //alert(selected_client_name);
        this.setState({
            selected_unit_name: selected_unit_name
        })
        var key = event.target.id;
        switch (key) {
            case 'unit_search_id':
                this.setState({ unitSearchParm: event.target.value, unit_name: event.target.value }, () => {
                    //if(this.state.searchParm.length>-1){
                    var value = this.state.unitSearchParm;
                    var len = this.state.unitListingCreator.length;
                    var count = 0;
                    var vflag = 0;
                    console.log('unitSearchParm', this.state.unitSearchParm);
                    if (value != '') {
                        this.state.unitListingCreator.forEach(function (unit, index) {
                            console.log("unitName", unit.unit);
                            if (unit.unit.trim().toLowerCase().includes(value.trim().toLowerCase())) {
                                unit.flag = 1;
                                vflag = 1;
                                count = count + 1;
                            } else {
                                unit.flag = 0;
                                if (vflag != 1)
                                    vflag = 0
                                count = count + 1;
                            }
                        });
                    } else {
                        document.getElementById('unit_search_id_values').style.display = 'none';
                        this.state.unitListingCreator.forEach(function (unit, index) {
                            unit.flag = 0;
                        });

                    } if (len == count) {
                        if (vflag == 0) {
                            // this.setState({searchMsg:true});
                            document.getElementById('unit_search_id_values').style.display = "none";

                        } else {
                            this.setState({ searchMsg: false });

                        }
                    }
                })
                //}
                break;
            case 'searchkey_unit':
                var value = event.target.value;
                let $this = this;
                this.setState({ unit_id: event.target.value }, () => {
                    this.state.unitListingCreator.forEach(function (unit, index) {
                        if (unit.id == value) {
                            document.getElementById('unit_search_id_values').style.display = "none";
                            document.getElementById('unit_search_id').value = unit.unit;
                            //document.getElementById('unit_search_id').value=unit.id;
                            //document.getElementById('listing_search_id').value=unit.id;
                            $this.setState({ unit_name: unit.unit });
                        } else {
                            unit.flag = 0;
                        }
                    });

                    document.getElementById('unit_search_id_values').style.display = "none";
                });
                break;
            case 'default':
                break;

        }
        document.getElementById('unit_search_id_values').style.display = "block";
    }
    notesHandleChange = (event) => {
        this.setState({
            notes: event.target.value
        })
    }
    render() {
        const listingDWList = this.state.listingCreator.map((ls, index) =>
            (ls.flag == 1 && ls.id > 0) ? <li id="searchkey_listing" key={index} value={ls.id} name={ls.name} onClick={this.handleListingCreatorSearch}>{ls.name}</li> : '');

        const clientDW = this.state.clientSearchList.map((aw, index) =>
            (aw.flag == 1 && aw.id > 0) ? <li id="searchkey" key={aw.id} value={aw.id} name={aw.name} onClick={this.handleClientSearch}>{aw.name}</li> : '');

        const listingDWUnitList = this.state.unitListingCreator.map((un, index) =>
        (un.flag == 1) ? <li id="searchkey_unit" key={un.id} value={un.id} name={un.unit} onClick={this.handleListingCreatorUnitSearch}>{un.unit}</li> : '');

        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv = encHex.parse("abcdef9876543210abcdef9876543210");

        return (
            <div>
                <main>
                    <div className="modal-content" id="createShowingNewPu">
                        <div className="modal-header">

                            <h4 className="modal-title">Create a new showing</h4>
                            <button type="button" className="close" id="newShowingClose" onClick={this.props.onClick}>&times;</button>
                        </div>
                        <div className="modal-body">
                            {(this.state.linkingLoader) ? <div className="show_loader_on_click" id="show_loader_on_click"></div> : ''}

                            <div className="form-horizontal">
                                <div className="form-group row">
                                    <div className="control-label col-sm-12">

                                        {/* <label>Split</label> */}
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="split_checkbox"
                                                checked={this.state.isChecked}
                                                onChange={this.toggleChange}

                                            />
                                            <span style={{ marginLeft: '5px' }}>Is this an available lead?</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group row">
                                    <div className="control-label col-sm-12">
                                        <label>Address</label>
                                        <div>
                                            <input type="text" id="listing_search_id" onChange={this.handleListingCreatorSearch} onKeyDownCapture={this.handleListingCreatorSearch} onKeyUpCapture={this.handleListingCreatorSearch} autoComplete="off" value={(this.state.listing_name != 0) ? this.state.listing_name : this.state.address_value} className="form-control" />
                                            <input type="hidden" name="listing_selected_id" id="listing_selected_id" value="0" />
                                            <div className="box agentNameOnBoard" id="listing_search_id_values" style={{ display: 'none' }} >
                                                <ul>{listingDWList}</ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="control-label col-sm-6">
                                        <label>Unit</label>
                                        <div>
                                            <input type="text" id="unit_search_id" onChange={this.handleListingCreatorUnitSearch} onKeyDownCapture={this.handleListingCreatorUnitSearch} onKeyUpCapture={this.handleListingCreatorUnitSearch} autoComplete="off" value={(this.state.unit_name != 0) ? this.state.unit_name : this.state.unit_value} className="form-control" />
                                            <input type="hidden" name="unit_selected_id" id="unit_selected_id" value="0" />
                                            <div className="box agentNameOnBoard" id="unit_search_id_values" style={{ display: 'none' }} >
                                                <ul>{listingDWUnitList}</ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="control-label col-sm-6" >
                                        <label>Split</label>
                                        <div >
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="split"
                                                value={this.state.split}
                                                onChange={this.splithandleChange}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group row">

                                    <div className="control-label col-sm-4">
                                        <label>Time</label>
                                        <DatePicker
                                            id="datePicker_time"
                                            selected={this.state.time}
                                            onChange={this.handleChange}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeFormat="h:mm aa"
                                            autoComplete="off"
                                            timeIntervals={15}
                                            dateFormat="h:mm aa"
                                            timeCaption="time"
                                            autoComplete="off"
                                            minTime={set_hours(set_minutes(new Date(), 0), 0)}
                                            maxTime={set_hours(set_minutes(new Date(), 45), 23)}
                                            className="form-control"
                                            onKeyDown={(e) => this.preventKeystroke(e)}
                                        />
                                    </div>
                                    <div className="control-label col-sm-8">
                                        <label>Date</label>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            onChange={this.handleChangeDate}
                                            dateFormat="MMMM d, yyyy"
                                            timeCaption="time"
                                            className="form-control"
                                            minDate={new Date()}
                                            maxDate={addDays(new Date(), 7)}
                                            onKeyDown={(e) => this.preventKeystroke(e)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="control-label col-sm-6">
                                        <label>CLIENT NAME</label>
                                        <div>
                                            <input type="text" id="client_search_id" onChange={this.handleClientSearch} onKeyDownCapture={this.handleClientSearch} onKeyUpCapture={this.handleClientSearch} autoComplete="off" value={(this.state.client_name != 0) ? this.state.client_name : this.state.client_fill_name} className="form-control" />
                                            <input type="hidden" name="client_selected_id" id="client_selected_id" value="0" />
                                            <div className="box agentNameOnBoard" id="client_search_id_values" style={{ display: 'none' }} >
                                                <ul>{clientDW}</ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="control-label col-sm-6">
                                        <label>CLIENT NUMBER</label>
                                        <div>
                                            <input type="text" id="clientNo_search_id" onChange={this.handlePhoneChange} onKeyDownCapture={this.handlePhoneChange} onFocus={this.hideClientPopup} onKeyUpCapture={this.handlePhoneChange} autoComplete="off" value={(this.state.clientphone.phone_number.length>20) ? aes.decrypt(this.state.clientphone.phone_number, key, { iv: iv, padding: padZeroPadding }).toString(CryptoJS.enc.Utf8) : this.state.clientphone.phone_number} maxLength='20' className="form-control" />
                                            <span style={{color:'#A9A9A9',fontSize:'14px'}}>ex. xxx-xxx-xxxx</span>
                                            <input type="hidden" name="clientNo_selected_id" id="clientNo_selected_id" value="0" />
                                            <div className="box agentNameOnBoard" id="clientNo_search_id_values" style={{ display: 'none' }} >
                                                <ul>{clientDW}</ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="control-label col-sm-10">
                                        <label>Email address</label>
                                        <div>
                                            <input type="email" id="clientEmail_search_id" onChange={this.handleEmailChange} onKeyDownCapture={this.handleEmailChange} onKeyUpCapture={this.handleEmailChange} autoComplete="off" value={this.state.client_fill_email.email} className="form-control" />
                                            <div className="box agentNameOnBoard" id="clientNo_search_id_values" style={{ display: 'none' }} >
                                                <ul>{clientDW}</ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="form-group row">                                            
                                                <div className="control-label col-sm-12">
                                                     <label>AGENT NAME (OPTIONAL)</label>
                                                    <div>
                                           <input type="text" id="agent_search_id" onChange={this.handleAgentSearch} onKeyDownCapture={this.handleAgentSearch} onKeyUpCapture={this.handleAgentSearch}  autoComplete="off" value={(this.state.agent_name!=0)?this.state.agent_name:''}  className="form-control"/>
                                          <input type="hidden" name="agent_selected_id" id="agent_selected_id" value="0" /> 
                                        <div className="box agentNameOnBoard" id="agent_search_id_values" style={{display:'none'}} >
                                              <ul>{agentDW}</ul>
			            </div>
                                                 </div>
                                            </div>
                                            </div> */}


                                <div className="form-group row">
                                    <div className="control-label col-sm-12" >
                                        <label>Notes</label>
                                        <div >
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="notes"
                                                value={this.state.notes}
                                                onChange={this.notesHandleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group text-right">
                                <button type="button" block='true'
                                    disabled={!this.validateForm()} className="btn btn cl-btn" id="createnewshowing" onClick={this.createNewShowing}>Create  <img src="/nextEditor-hover.png" /></button>
                            </div>


                        </div>

                    </div>
                </main>
            </div>
        );
    }
}



export default CreateShowingPopUp;