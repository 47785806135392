import React, { Component } from 'react';
import AddBuilding from '../../manager/listing/AddBuildings';

class AgentBuildingAdd extends Component {
	constructor(props) {
        super(props);
        this.state= {
           
            id:(this.props.match.params.id)?this.props.match.params.id:'',
             addr:(this.props.match.params.addr)?this.props.match.params.addr:'',
           listing_id:(this.props.match.params.listing_id)?this.props.match.params.listing_id:'',
        }
     }

    render() {
    	console.log(this.props.match.params.id);
        return (
            <div>
                <AddBuilding  userType='Agent' building_id={this.state.id} building_addr={this.state.addr} listing_id={this.state.listing_id} />
            </div>
        );
    }
}

export default AgentBuildingAdd;