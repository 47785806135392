import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/AgentHeader';
import SideBar from '../common/AgentSideBar';
import { Redirect } from 'react-router';
import {getApiData, setAgentToken, getAgentToken} from '../../services/Api';
import LinkingDetail from './linkingPage';
import DragSortableList from 'react-drag-sortable';

class Dashboard extends React.Component {

    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}

class Content extends React.Component {

	

	constructor(props) {
		super(props);
		this.setWrapperRef1 = this.setWrapperRef1.bind(this);
		this.setWrapperRef2 = this.setWrapperRef2.bind(this);


    	this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
			client_application:[],
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			searchClientId:0,
			searchParm:"",
			clientDW:[],
			statusFil:3,
			nameSearch:false,
			statusSearch:false,
			where:{},
			linkingLoader:false,
			clickedClientId:0,
			myerrormsg:"",
		  };
		  this.createWhere();
		  this.search = this.search.bind(this);
		//  this.handleBodyClick = this.handleBodyClick.bind(this);
		}
		
	componentWillMount(){
		document.addEventListener('mousedown', this.handleClickOutside);

		this.getClientApplication();
		this.clientDD();
		
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	  }

	  setWrapperRef1(node) {
		this.wrapperRef1 = node;
	  }

	  setWrapperRef2(node) {
		this.wrapperRef2 = node;
	  }

	  handleClickOutside(event) {
		if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
			document.getElementById('search').classList.remove("show");
		}

		if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
			document.getElementById('status').classList.remove("show");
		  }


	  }
	//get application list
	async getClientApplication() {
		let applicationList = [{
            url: "/agent/applicationList",
		}];
		var len =Object.keys(this.state.where);
		if(len.length>0){
			applicationList[0].where=this.state.where;
		}
        getApiData(applicationList,true).then(res => {
		this.setState({'client_application':res.data});
		document.getElementById('search').classList.remove("show");
		document.getElementById('status').classList.remove("show");
		
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}

	// generate client Drop Down
	async clientDD(){
		let clientList = [{
            url: "/agent/clientDD",
		}];
        getApiData(clientList,true).then(res => {
        this.setState({'clientDW':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
	}

	//fliter reset
	resetFilter=()=>{
		this.setState({
			client_list:[],
			where:{},
			ckIncomplete:{'checked':true,'value':1},
			ckSent:{'checked':true,'value':1},
			ckSubmit:{'checked':true,'value':1},
			statusFil:3,
			statusKey:0,
			searchClientId:0,
			searchParm:"",
			myerrormsg:"",
		  },()=>{
			document.getElementById('searchParm').value='';
			document.getElementById('searchClick').classList.remove("active");
			document.getElementById('Sent').checked = true;
			document.getElementById('submit').checked = true;
			document.getElementById('Incomplete').checked = true;
			this.clientDD();
			this.createWhere();
		  });
	}

	//search handling
	search=(event) =>{
		this.setState({[event.target.id]: event.target.value});
	 var key=event.target.id; 
	  switch(key){
		case 'Sent':     
			if(this.state.ckSent.value==0 && this.state.ckSent.checked==false){
				this.state.ckSent.checked=true;
				this.state.ckSent.value=1;
				this.state.statusFil=1;
				this.createWhere();
			}else if(this.state.ckSent.value==1 && this.state.ckSent.checked==true){
				this.state.ckSent.checked=false;
				this.state.ckSent.value=0;
				this.createWhere();
			}
			break;
		case 'submit':
		if(this.state.ckSubmit.value==0 && this.state.ckSubmit.checked==false){
			this.state.ckSubmit.checked=true;
			this.state.ckSubmit.value=1;
			this.state.statusFil=1;
			this.createWhere();
		}else if(this.state.ckSubmit.value==1 && this.state.ckSubmit.checked==true){
			this.state.ckSubmit.checked=false;
			this.state.ckSubmit.value=0;
			this.createWhere();
		}        
			break;
		case 'Incomplete':  
		if(this.state.ckIncomplete.value==0 && this.state.ckIncomplete.checked==false){
			this.state.ckIncomplete.checked=true;
			this.state.ckIncomplete.value=1;
			this.state.statusFil=1;
			this.createWhere();
		}else if(this.state.ckIncomplete.value==1 && this.state.ckIncomplete.checked==true){
			this.state.ckIncomplete.checked=false;
			this.state.ckIncomplete.value=0;
			this.createWhere();
		}        
			break;
		case 'searchParm':
			this.setState({searchParm:event.target.value});
			if(this.state.searchParm.length>1){	
				var value=this.state.searchParm;			
				this.state.clientDW.forEach(function(client,index) {
				if(client.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
						client.flag=1;
					}else{
						client.flag=0;
					}

				});
			}
		break;
		case 'searchkey':
		var value=event.target.value;
			this.setState({ searchClientId: event.target.value}, () => {
				this.state.clientDW.forEach(function(client,index) {
					if(client.clientId==value){
						document.getElementById('searchClick').classList.add("active");
						document.getElementById('searchParm').value=client.name;
					}else{
						client.flag=0;
					}
				});
				document.getElementById('searchClick').classList.add("active");
				this.createWhere();
			  });
		break;
		case 'default':
		this.createWhere();
		break;
		 
		}
		if(this.state.statusFil>0){
			document.getElementById('statusKey').classList.add("active");
		}else{
			document.getElementById('statusKey').classList.remove("active");
		}
		
	  }
	  
	  //search clauses 
	async createWhere(){
		this.state.where={};
		if(this.state.ckSent.value==1){
			this.state.where.sent=3;
		}
		if(this.state.ckSubmit.value==1){
			this.state.where.submit=2;		
		}
		if(this.state.ckIncomplete.value==1){
			this.state.where.incomplete=1;
		}
		if(this.state.searchClientId>0){
			this.state.where.searchClientId=this.state.searchClientId;
		}
		this.getClientApplication();
	}
	
	//click event handling
	ClientHeadClick(){ document.getElementById('status').classList.remove("show");}
	StatusHeadClick(){document.getElementById('search').classList.remove("show");}
	
	//handleLinking
	handleLinking = param => e => {
		if(this.state.clickedClientId[3]==param[3]){
			this.setState({clickedClientId:0},()=>{
				this.setState({clickedClientId:param},()=>{});
			});
		}else{
			this.setState({clickedClientId:param},()=>{});
		}
		
	}
	

 onSort = function(sortedList, dropEvent) {

	 }	
													
													
													
													

    render() {
		var list = [
			{content: (<li><Link to>NameA 1 (1)<div className="pull-right"><span className="icon-wrap link"></span> </div></Link></li>)},
			{content: (<li><Link to>NameB 2 (1)<div className="pull-right"><span className="icon-wrap link"></span> </div></Link></li>)},
			{content: (<li><Link to>NameC 3 (1)<div className="pull-right"><span className="icon-wrap link"></span> </div></Link></li>)},
			{content: (<li className="no-drag"><Link to className="no-drag">Non Draggable Item 4 (1)<div className="pull-right"><span className="icon-wrap link"></span> </div></Link></li>)},
			{content: (<li  className="no-drag"><Link to className="no-drag">Non Draggable Item 5 (1)<div className="pull-right"><span className="icon-wrap link"></span> </div></Link></li>)}
		];
		if (!getAgentToken()) {
            return (<Redirect to={'/agent/landing'}/>);
		  }
const clientDW = this.state.clientDW.map((dw,index) => 
(dw.flag==1) ? <li id="searchkey" value={dw.clientId} onClick={this.search}>{dw.name}</li>:''
);



var listItems='';
		if(this.state.client_application.length>0){
			listItems = this.state.client_application.map((application,index) => 
			<tr>
				<td>{application.apartment_address}</td>
				<td>{application.unit_number}</td>
				<td  className="managerGridCol">{application.realm}</td>
				<td  className="managerGridCol">{application.status}</td>
				{(application.status=='Submitted')?<td><Link to data-toggle="collapse" data-target={"#client"+index}  className="icon-wrap link"></Link>												
											<div id={"client"+index} class="collapse table-collapse link-collapse" >

												<div className="box">
												
												<div>
												<input id="filter" type="text" class="search-input" autoComplete="off"  onKeyPressCapture={this.filterList}  onKeyDownCapture={this.filterList}  onKeyUpCapture={this.filterList}/>
												
												
													{/* <ul>{liLinkedList}</ul> */}
													<ul>
													<DragSortableList items={list} onSort={this.onSort} dropBackTransitionDuration={0.3} type="vertical"/>
													

													
													
													</ul>	
																 
												</div>

												</div>
											</div>
											</td>:<td>-</td>}											
			</tr>
);	
		}else{
			listItems = 
			<tr>
			<td colSpan="5">No Record found for the searched criteria.</td>				
		</tr>
		}

        return (

			
                <div onclick="myFunction()">
                    <Header />
                    <main>
					<SideBar/>
					<div className="content">
						<div className="work-section">
						<div className="dashboard" >

							
								<h2 className="pull-left">Applications</h2>
								<button onClick={this.resetFilter} className="btn btn-default pull-right">Reset Filter</button>
								<div className="panel-body table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
												<th>Address</th>
												<th>Unit #</th>
												<th>Client name <Link to  id="searchClick" data-toggle="collapse" data-target="#search" className="icon-wrap search" onClick={this.ClientHeadClick}></Link>
												<div id="search" class="collapse table-collapse" ref={this.setWrapperRef1}>

													<div className="box">
												
													<input type="text" id="searchParm" autocomplete="off" class="search-input" onChange={this.search}/>
													
														<ul>
														{clientDW}
														</ul>
													<div className="filterSearchReset"><Link to className="clearFilter" ><i class="fa fa-undo"></i> Clear</Link></div>
													</div>
												</div>
												
												</th>
												<th>Status <Link to id="statusKey" data-toggle="collapse" data-target="#status" className="icon-wrap status" onClick={this.StatusHeadClick} ></Link>												
												<div id="status" class="collapse table-collapse status-collapse" ref={this.setWrapperRef2}>
													<div className="box">
													<div className="text-right"><Link to className="clearFilter" ><i class="fa fa-undo"></i> Clear</Link></div>
													<input type="checkbox" id="Sent" defaultChecked={this.state.ckSent.checked} value={this.state.ckSent.value} onChange={this.search} />
													<label for="Sent">Sent</label>
													<input type="checkbox" id="submit" defaultChecked={this.state.ckSubmit.checked} value={this.state.ckSubmit.value} onChange={this.search}/>
													<label for="submit">Sumbitted</label>
													<input type="checkbox" id="Incomplete"  defaultChecked={this.state.ckIncomplete.checked} value={this.state.ckIncomplete.value} onChange={this.search}/>
													<label for="Incomplete">Incomplete</label>
													
													</div>
												</div>
												
												</th>
												<th>Link</th>
											</tr>
										</thead>
										<tbody>
											{listItems}
										</tbody>
										</table>
									</div>
							</div>
							
						</div>
					</div>	
                    </main>     
                </div>
                );
    }
}
export default Dashboard;
