import React from 'react';

import ScrollspyNav from "react-scrollspy-nav";
import $ from 'jquery';
import '../manual/highlight';
import '../manual/finder';
import 'jquery.scrollto';
import { getApiData, postApiData, getAgentId,getBaseUrl } from "../../services/Api";

class Rentalform extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    pageContent:'',
    linkingLoader:false,
    editable:getAgentId()>0 || getAgentId()==0?"false":"true"
    }
  }
  componentWillMount(){
	
		this.getPageContent();
	}
  getPageContent=()=>{
         
		let where = [{
			url:'/managers/manualPageContent/'
		}]
		postApiData(where,{'page':'training_manual_content'},true).then(res=>{
				this.setState({
					pageContent:res.data[0].html_value,
					
				},()=>{
          document.getElementById('result-list').innerHTML=unescape(this.state.pageContent);
        })
		
			
		}).catch(error=>{

		})
  }
  contentSave=(type)=>e=>{
		this.setState({linkingLoader:true})
		var divValue='';

		 divValue=document.getElementById('result-list').innerHTML;
	
		let where=[{
			url:'/managers/manualContetSave'
		}];
		// var formData = new FormData();
		// formData.append('content',abc);
		
		postApiData(where,{'page':'training_manual_content','content':divValue},true).then(res=>{
			
				this.setState({linkingLoader:false},()=>{
				alert("Rental Training Manual Published Successfully.")
				})
				
		}).catch(error=>{

		})
	} 
    render() {
        return (
          <div class="manual-section" > 
          
          <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-light" >
          <img src="/logo.png" class="img-fluid logo" style={{maxWidth:'300px'}}/>


<ul class="navbar-nav mr-auto p-1 m-auto">
      <li class="nav-item d-none d-sm-block">
       
      </li>
    </ul>
    <ul class="navbar-nav d-none d-md-block d-lg-block d-xl-block col-md-2 text-right">
      <li class="nav-item ml-2">
      <button className="btn my-2 my-sm-0 finder-activator" type="submit" data-finder-activator data-toggle="collapse" data-target="#demo">
              <i className="fa fa-search"></i>
            </button>
            {getAgentId()>0 || getAgentId()==0 ?'' :<button class="btn btn-default pull-right saveBtnManual" onClick={this.contentSave('approve')}>Publish</button>}</li>

    </ul>
    <ul class="navbar-nav d-block d-sm-none">
      <li class="nav-item ml-2">
          <button class="btn my-2 my-sm-0 finder-activator" type="submit" data-toggle="collapse" data-target="#demo">
            <i class="fa fa-search"></i>
          </button>
      
      
      <button class="btn btn-default pull-right saveBtnManual" >SAVE</button></li>
    </ul>

</nav>
     
           <div class="container-fluid">
       <div class="row">
         <div class="col-xl-9 col-lg-8 col-xs-12 p-1 divtopmargin right content" id="result-list" data-finder-content contenteditable={this.state.editable}>
        </div>
               
                 <nav class="d-none d-sm-none d-md-block col-xl-3 col-lg-4 left" id="myScrollspy">
      <div class="leftdiv col-xl-3 col-sm-4" style={{paddingLeft:'15px'}}>
      <ScrollspyNav scrollTargetIds={["INTRODUCTION", "STEP-1", "STEP-2", "STEP-3", "STEP-4", "STEP-5", "STEP-6", "STEP-7", "PRO-TIPS-TERMS"]} activeNavClass="active" scrollDuration="1500" headerBackground="false">
              
      <ul class="nav nav-pills flex-column" style={{paddingRight:'15px'}}>
        <li class="nav-item">
          <a class="nav-link" href="#INTRODUCTION">INTRODUCTION</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#STEP-1" >STEP 1: MASTER THE MARKET</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#STEP-2" >STEP 2: ADVERTISE APARTMENTS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#STEP-3">STEP 3: CONVERT LEADS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#STEP-4">STEP 4: SHOWING APARTMENTS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#STEP-5">STEP 5: CLOSE THE DEAL</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#STEP-6">STEP 6: YOU’RE APPROVED</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#STEP-7">STEP 7: FOLLOW UP</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#PRO-TIPS-TERMS" >PRO TIPS AND TERMS</a>
        </li>  
      </ul>
      </ScrollspyNav>
      </div>
    </nav>
    <div className="finder active collapse" id="demo">
              <input id="finderInput" type="text" className="finder-input" onChange={this.searchDem} placeholder="Search Here..."/>
                <button id="finderPrev" className="btn btn-finder btn-finder-prev">
                  <i className="fa fa-angle-up"></i>
                </button>
                <button id="finderNext" className="btn btn-finder btn-finder-next">
                  <i className="fa fa-angle-down"></i>
                </button>
                <button id="finderClose" data-toggle="collapse" data-target="#demo" className="btn btn-finder btn-finder-close">
                  <i className="fa fa-times"></i>
                </button>
              </div>
  </div>
  
             </div>
</div>
    
);
    }
}
export default Rentalform;