import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import {postApiData} from '../../services/Api';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import {isEmail} from 'validator';


class Forgot extends React.Component {
    render() {
        return (
                <div>
                    <Content contentProp = {this.props.content}/>
                </div>
                );
    }
}

class Content extends React.Component {

 constructor(props) {
        super(props);
        this.state= {
            mymsg:"",
            myerrormsg:"",
            email: "",
            redirect:false
        };
        
    }
    
   async forgotPassword() {
        let where = [{
                url: "/Clients/reset"
            }];
          const {email} = this.state;
        postApiData(where, {'email':email}).then(res => {
            
            this.setState({mymsg: "Please check your email to reset your password."});
            this.setState({myerrormsg: ''});
            this.setState({email: ''});

        })
        .catch(error => {
              this.setState({myerrormsg: error.response.data.error.message});
        });
    }
     validateForm() {
        return this.state.email.length > 0;
    }
    handleChange = event => {
        switch (event.target.id) {
            case 'email':
            if (!isEmail(event.target.value)) {
                    
                this.setState({
                                myerror: 'Email is not valid.'
                                ,email:event.target.value.toLowerCase()
                              });
                }else{
                     this.setState({
                                myerror: ''
                                ,email:event.target.value.toLowerCase()
                              });
                }
            break;

        }
        // this.setState({
        //     [event.target.id]: event.target.value
        // });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.forgotPassword();
    }

    render() {
        return (
                <div>
                    <Header />
                    <main>
                        <div className='container'>
                            <h2 className="text-center">Forgot Password</h2>
                            {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
                            {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                            
                            <div className='form-widget login-widget'>
                
                <form onSubmit={this.handleSubmit}>
         <FormGroup controlId="email">
           <FormControl
             autoFocus
             type="email"
             placeholder="Email"
             value={this.state.email}
             onChange={this.handleChange}
           />
            <span className="inline-error-class"> {this.state.myerror !==''?this.state.myerror:''}</span>
         </FormGroup>
        
         <Button
           block
           disabled={!this.validateForm()}
           type="submit"
         >
           Submit
         </Button>
       </form>
       </div>
       </div>
       </main>
       </div>

                );
    }
}
export default Forgot;
