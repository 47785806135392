import React from 'react';
import { Link } from 'react-router-dom';


class Landing extends React.Component {
   
    render() {
        return (
            <div>
            {/*<video autoPlay muted loop id="myVideo">
            <source src="/videos/real.mp4" type="video/mp4"/>
        </video>*/}
            <div className='container landing-content'>
            <div className='landing-section'>
             <img src="logo.png" alt="Skywards" />
              <p>ELEVATING THE RENTALS EXPERIENCE</p>
               <Link to="/client/signup" className='btn btn-default'>Get Started</Link>
               </div>
        </div>
    </div>

                );
    }
}
export default Landing;
