import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/FrontHeader';
import { postApiData} from '../../services/Api';
import {validatePhone} from '../../services/validation';

class Join extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef() ;
        this.state = {
            shown:true,
      
      
      }
    
    }
  


  
   


   

   


    render() {

      
        return (
   
          
            <div className="w-bg FrontEnd Newskyward-design" style={{height:'100vh'}}>
             <Header/> 
             
             <div className="panel-2">
                    <div className="container workbody vr-section" >
                            <h1 >Skyward is excited to announce Virtual tours on all listings starting December 15!</h1>
                         
                        </div>
                
                     
               
               
              </div>
                  
           
                   
               
              <div className="Newskyward-design">
              <footer style={{top:'-12px'}}>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li>
                     <li className="nav-item"><a href="https://blog.moveskyward.com/" target="_blank" > Blog </a></li>  
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
                    
                     <li className="nav-item"><Link to="/frontend/privacy-policy"> Privacy Policy</Link></li>
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
                     
                  
                    
                    </ul>   
                    <div className="clearfix new_logo">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img src="/home-icon.png"/></li>
                       </ul>
                       <div className="logo" style={{marginTop:-36}}></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
                   <p className='text_1' style={{marginTop:48}}>*Skyward Steel, LLC DBA Skyward and Skyward Real Estate is committed to adhering to the guidelines of The New York State Fair Housing Regulations. To view The Fair Housing Notice <a style={{textDecoration:'underline'}} href="https://dos.ny.gov/system/files/documents/2024/06/nys-housing-and-anti-discrimination-notice_06.2024.pdf" target="_blank"> Please click here</a>
<br></br>
*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. <a href="https://kwnyc.com/app/uploads/2022/03/Standardized-Operating-Procedure-for-Purchasers-of-Real-Estate.pdf" style={{textDecoration:'underline'}} target="_blank">To View Please Click Here.</a>


</p>       </div>
               </footer> 

                </div>
              


              
              
    </div>

                );
    }
}
export default Join;
