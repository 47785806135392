import React, { Component } from 'react';
import ListingCreatorEdit from '../../agent/listing/SalesListingCreatorEdit';

class ManagerCreatorEdit extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div>
                <ListingCreatorEdit 
                userType='Manager'
                heading='Edit Listing'
                building_id={this.props.location.building_id  ? this.props.location.building_id  : this.props.match.params.buildingid}
                listing_id={this.props.location.listing_id ? this.props.location.listing_id : this.props.match.params.listingid}
                unit_number={this.props.location.unit_number ? this.props.location.unit_number : this.props.match.params.unitnumber}
                type={this.props.location.type}
                />
            </div>
        );
    }
}

export default ManagerCreatorEdit;