import React from 'react';
import { Link , withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import {postApiData, getApiData, getUserId, getToken, setOnBoardingDone, getOnBoardingDone , unsetOnboardingData, setOnboardingData, getOnboardingData} from '../../services/Api';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";
import Header from '../common/Header';
import { Redirect } from 'react-router';
import {validatePhone,socialSecurityNumber} from '../../services/validation';
import { getCurrentFormatDate } from '../../services/Util';
import Tooltip from "react-simple-tooltip";
import {BrowserView, MobileView, isBrowser,isMobile} from "react-device-detect";
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import CryptoJS from "crypto-js";

class OnBoarding extends React.Component {
    constructor(props) {
    super(props);
            this.state = {
                    id:'',
                    name: "",
                    last_name:"",
                    phone_number: "",
                    phone_number_encrypted:'',
                    already_have_agent: false,
                    agent_name: 0,
                    signature: "",
                    is_guarantor: 0,
                    guarantor_name: "",
                    clientId: getUserId(),
                    termsOne:1,
                    termsTwo:1,
                    termsThree:1,
                    redirect: false,
                    mymsg: "",
                    final_redirect:false,
                    myerrormsg:"",
                    stage:'onboarding_form_section_one',
                    agentList:[],
                    agent_id:0,
                    searchParm:'',
                    account_verify:'',
                    resendMsg:'',
                    completed_date:'',
                    selected_agent_name:'Josiah Hyatt', //default agent name,
                    _selected_agent_name_:'',
                    randomFileName:'',
                    searchMsg:false,
                    is_completed:false,
                    disclose_tooltip:true,
                    term_tooltip:true,
                    third_tooltip:true
            };
            this.saveOnboarding = this.saveOnboarding.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.getOnboardingData = this.getOnboardingData.bind(this);
    }
    
    
async updateOnboarding(stage,next,isDraft=false,isFinal = false) {
    const randomFileName = Math.floor(Math.random() * (+100000 - +0)) + +0;
    this.setState({ randomFileName   })
    if(this.state.id){

        let key = encHex.parse("0123456789abcdef0123456789abcdef");
            let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
            this.state.phone_number_encrypted=aes.encrypt(this.state.phone_number, key, {iv:iv, padding:padZeroPadding}).toString();

             let data = {'first_name':this.state.name.replace(/^\s+|\s+$/g, '')+' '+this.state.last_name.replace(/^\s+|\s+$/g, ''),
             'last_name':this.state.last_name,
            'phone_number':this.state.phone_number_encrypted,
            'already_have_agent':this.state.already_have_agent,
            'agent_id':this.state.agent_id,
            'signature':this.state.signature,
            'is_guarantor':(this.state.is_guarantor)?'1':'0',
            'is_completed':(isFinal)?1:0,
            'stage':stage,
            'terms_one':(this.state.termsOne)?'1':'0',
            'terms_two':(this.state.termsTwo)?'1':'0',
            'terms_three':(this.state.termsThree)?'1':'0',
            'clientId':getUserId(),
            };
         if(isFinal===true){
            //document.getElementById('show_loader_on_click_id').style.display = "block";
            data.completed_date = getCurrentFormatDate();
            this.setState({ completed_date : data.completed_date})
         }
              var url = "/onboardings/"+this.state.id;
       let where = [{
        url: url
        }];
      postApiData(where,data,true).then(res => {
        if (res.data){
            this.setState({id:res.data.id,completed_date:res.data.completed_date},()=>{
              
                if(isFinal===true){
                    this.setState({mymsg: "Saved Successfully.",final_redirect:true});
                    setOnBoardingDone('1');
                    localStorage.setItem('is_onboarding_done', 1);
                    document.getElementById('show_loader_on_click_id').style.display = "none";
                    this.pdfDownload();
                }
                if(isDraft===true){
                     this.setState({mymsg: "Data Saved As Draft."});
                }
            })
        }
      
        this.setState({id:res.data.id},()=>{
            this.nextStepC(next,stage);
        });
        
        }).catch(error => {
            this.setState({myerrormsg: error.response.data.error.message});
            document.getElementById('show_loader_on_click_id').style.display = "none";
            if(error.response.data.error.statusCode!=undefined && error.response.data.error.statusCode=='600'){
                this.setState({logout: true});
            }
            
        });
    }    
}
         //Email send to the agent after onBoarding complete
        sendMailToAgent = ()=>{
          var disfileName=this.state.name+'_'+this.state.randomFileName;
                    disfileName=disfileName.replace(/ /g,"_")
            let where = [{
                url: "/email/onboarding/send"
            }];
            postApiData(where,{agent_id:disfileName ,OnBoardingId:this.state.id}).then(response=>{
                if(response){
                    
                let where=[{
                    url:"/managers/updatediscloser"
                }]
                postApiData(where,{'id':this.state.id,'discloser_file':disfileName},true).then(response=>{
                        console.log("---",response);
                }).catch(error=>{

                })
            }
            })
        
        } 

//.........................................................
pdfDownload=()=>{
    var disfileName=this.state.name+'_'+this.state.randomFileName;
    disfileName=disfileName.replace(/ /g,"_")
    this.setState({ 'linkingLoader':true},()=>{
       
let where = [{
    url: "/onboardings/onboardingPdf/createPdf",
}];
postApiData(where,{'application_id':disfileName, 'signature':this.state.signature, 'completed_date':this.state.completed_date, 'name': this.state.name+' '+this.state.last_name, 'selected_agent_name': this.state.selected_agent_name},true).then(res => {
    this.sendMailToAgent();
    
        this.getFile(res.data,(err,response)=>{
            if(response){
                window.open(response);
                this.setState({ 'linkingLoader':false});
            }
        });
       
}).catch(error => {
        if(error.res){
            this.setState({myerrormsg: error.res.data});
        }
    });
});
}
//.......................


async updateRaw(rawData,isFinal=false){
      var url = "/onboardings/"+this.state.id;
       let where = [{
        url: url
        }];
     if(isFinal===true){
            rawData.first_name=this.state.name.replace(/^\s+|\s+$/g, '')+' '+this.state.last_name.replace(/^\s+|\s+$/g, '');
             rawData.completed_date = getCurrentFormatDate();
             rawData.is_completed = '1';
             document.getElementById('show_loader_on_click_id').style.display = "block";
         }
        postApiData(where, rawData,true).then(res => {
        if (res.data){
            this.setState({id:res.data.id});
        }
        if(isFinal===true){
             this.setState({mymsg: "Saved Successfully.",final_redirect:true,completed_date:rawData.completed_date},()=>{
                this.pdfDownload();
            });
             setOnBoardingDone('1');
             unsetOnboardingData();
             setOnboardingData({
                 'id':this.state.id,
                 'name':this.state.name.replace(/^\s+|\s+$/g, '')+' '+this.state.last_name.replace(/^\s+|\s+$/g, ''),
                 'signature': this.state.signature,
                 'agent_name':this.state.agent_name,
                 'is_completed':1,
                 'completed_date':rawData.completed_date});
             localStorage.setItem('is_onboarding_done', 1);

             document.getElementById('show_loader_on_click_id').style.display = "none";
             if(this.state.is_guarantor === '0'){
                    this.props.history.push('/client/dashboard'); 
             }else{
                    this.props.history.push('/client/application'); 
             } 
        }

        }).catch(error => {
            this.setState({myerrormsg: error.response.data.error.message});
            document.getElementById('show_loader_on_click_id').style.display = "none";
            if(error.response.data.error.statusCode!=undefined && error.response.data.error.statusCode=='600'){
                this.setState({logout: true});
            }
        });
}

async saveOnboarding(stage,next,isDraft=false) {
       let where = [{
        url: "/onboardings"
        }];
        var method = 'post';

        let key = encHex.parse("0123456789abcdef0123456789abcdef");
        let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
        this.state.phone_number_encrypted=aes.encrypt(this.state.phone_number, key, {iv:iv, padding:padZeroPadding}).toString();
       
        let data = {'first_name':this.state.name.replace(/^\s+|\s+$/g, '')+' '+this.state.last_name.replace(/^\s+|\s+$/g, ''),
            'last_name':this.state.last_name.replace(/^\s+|\s+$/g, '').trim(),
            'phone_number':this.state.phone_number_encrypted,
            'stage':stage,
            'clientId':getUserId()
        };
       postApiData(where, data, true).then(res => {
        if (res.data){
            this.setState({id:res.data.id},()=>{
                //document.getElementById('show_loader_on_click_id').style.display = "block";
                this.nextStepC(next,stage);
            });
        }

        }).catch(error => {
            // console.log("error.response",error.response);
            this.setState({myerrormsg: error.response.data.error.message});
            document.getElementById('show_loader_on_click_id').style.display = "none";
            if(error.response.data.error.statusCode!=undefined && error.response.data.error.statusCode=='600'){
                this.setState({logout: true});
            }
            
        });
        
}

ShowCurrentDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [month, day, year].join('-');
}

isString(str){
    if(/^[a-zA-Z.' ' ]+$/.test(str))
    return true;
    else 
    return false;
}

isPhoneNumber(phone){
    
    var reg = /^\d{3}-\d{3}-\d{4}$/;
    if(reg.exec(phone))
        return true;
    else
        return false;  
}

validateName(value){
     var name = true;
     if (value.length ==0) {
                 this.setState({nameerror: 'Field is required.'});
                name = false;
     }else if ((!value.replace(/\s/g, '').length)) {
                    this.setState({nameerror: 'First Name is not valid.'});
                name = false;
     }else if(value.length >=30){
        this.setState({nameerror: 'First Name length is too long'});
        name = false;
     } else{
                   this.setState({ nameerror: ''});
     }
     return name;
}
validateLastName(value){
    var name = true;
    if (value.length ==0) {
                this.setState({last_name_error: 'Field is required.'});
               name = false;
    }else if ((!value.replace(/\s/g, '').length)) {
                   this.setState({last_name_error: 'Last Name is not valid.'});
               name = false;
    }else if(value.length >=30){
       this.setState({last_name_error: 'Last Name length is too long'});
       name = false;
    } else{
                  this.setState({ last_name_error: ''});
    }
    return name;
}

validatePhone(value){
     let res=validatePhone(value);
                if(res.status==false){
                    this.setState({phoneerror: 'Phone number is not valid.'});
                    document.getElementById("phone_number").value=res.value;
                }else{
                    this.setState({phoneerror: ''});
                    document.getElementById("phone_number").value=res.value;
                }
      return res.status;
}
validateAgent(value){
    var agent = true;
    if (value.length ==0) {
         this.setState({agenterror: 'Field is required.'});
          agent = false;
    }else if ((this.isString(value)==false) || (!value.replace(/\s/g, '').length)) {
         this.setState({agenterror: 'Agent Name is not valid.'});
          agent = false;
    }else{
         this.setState({ agenterror: ''});
    }
    return agent;
}
validateGuarantor(value){
    var guarantor = true;
    if (value.length ==0) {
                 this.setState({guarantorerror: 'Field is required.'});
                guarantor = false;
    }else if (this.isString(value)==false) {
                    this.setState({guarantorerror: 'Name is not valid.'});
                guarantor = false;
    }else{
                   this.setState({ guarantorerror: ''});
    }
    return guarantor;
}
validateSignature(value){
    var signature = true;
    if (value.length ==0) {
        this.setState({signatureorerror: 'Field is required.'});
        signature = false;
    }else if (this.isString(value)==false) {
        this.setState({signatureorerror: 'Signature Name is not valid.'});
        signature = false;
    }else{
        this.setState({ signatureorerror: '',signature:value});
    }
    return signature;
}
formValidate(key,value){
    switch(key){
        case 'name':     
            this.validateName(value);
            break;
        case 'last_name':     
        this.validateLastName(value);
        break;    
        case 'phone_number':        
             this.validatePhone(value);
            break;
        case 'signature':  
             this.validateSignature(value.trim());
            break;
        }
}

handleChange = event => {
    this.formValidate(event.target.id,event.target.value);
  if(event.target.id=='name' && event.target.value.length > 30){
    
  }else{
    this.setState({
        [event.target.id]: event.target.value
        });
  }
        

}
handleAgentSearch = event => {
    var selected_agent_name = event.target.getAttribute('name')
    this.setState({
        selected_agent_name: selected_agent_name,
        _selected_agent_name_: event.target.value
    })
    var key=event.target.id; 
    switch(key){
        case 'agent_search_id':
        var len = this.state.agentList.length;
        var count=0;
        var vflag=0;
                this.setState({searchParm:event.target.value,agent_name: event.target.value},()=>{
			//if(this.state.searchParm.length>-1){
                var value=this.state.searchParm;
                if(value!=''){
                    this.state.agentList.forEach(function(agent,index) {
                        if(agent.name.trim().toLowerCase().includes(value.trim().toLowerCase())){
                                agent.flag=1;
                                vflag=1;
							count=count+1;
                            }else{
                                agent.flag=0;
                                if(vflag!=1)
							vflag=0
							count=count+1;
                            }
                        }); 
                }else{
                    document.getElementById('agent_search_id_values').style.display = 'none';
                    this.state.agentList.forEach(function(agent,index) {
                        agent.flag=0;
                        vflag=0
                        count=count+1;
                    });
                    
                }
                if(len==count){
                    if(vflag==0){
                        this.setState({searchMsg:true});
                    }else{
                        this.setState({searchMsg:false});
                    }
    
                    
                }
            })

            
							
				              
                //}
		break;
		case 'searchkey':
		var value=event.target.value;
                let $this = this;
			this.setState({ agent_id: event.target.value}, () => {
				this.state.agentList.forEach(function(agent,index) {
					if(agent.id==value){
					    document.getElementById('agent_search_id_values').style.display = "none";
                                            document.getElementById('agent_search_id').value=agent.name;
                                            $this.setState({agent_name:agent.name},()=>{
                                                console.log($this.state.agent_name);
                                            });
                                            
					}else{
						agent.flag=0;
					}
				});
				document.getElementById('agent_search_id_values').style.display = "none";
			  });
		break;
		case 'default':
		break;
		 
    }
    
        
//    agent_search_id_values
document.getElementById('agent_search_id_values').style.display = "block";
}
selectAgent = event =>{
        this.setState({agent_id: event.target.value});
        this.setState({ agent_id: event.target.value}, () => {
				this.state.agentList.forEach(function(agent,index) {
				if(agent.id==event.target.value){
					document.getElementById('agent_search_id_values').style.display = "none";
					document.getElementById('agent_search_id').value=agent.name;
				}else{
					agent.flag=0;
				}
				});
				document.getElementById('agent_search_id_values').style.display = 'none';
	});
}
handleChecked = event => {
     this.setState({termsOne: event.target.checked});
}
handleCheckedThree = event => {
    this.setState({termsThree: event.target.checked});
}
handleCheckedTwo = event => {
     this.setState({termsTwo: event.target.checked,term_tooltip:false});
     if(document.getElementById('terms_and_condition_two').checked)
     {
        this.setState({term_tooltip:false});
     }
     else
     {
        this.setState({term_tooltip:true});  
     }
}
handleAgent = param=>event => {
            this.setState(
                {already_have_agent: param},
                ()=>{
                    this.showHavingAgent(param,true);
                });
            
}
handleGuarantor = event => {
        this.setState({
              is_guarantor: event.target.value
            });      
        this.showGuarantor(event.target.value);
}
componentWillMount() {
    this.agentOpt();
    this.getOnboardingData();
}
componentDidMount(){
//    if(isMobile){
//         this.setState({termsOne:"1"});
//         document.getElementById("terms_and_condition_two").disabled = false;
//    }
}
componentWillUnmount(){
//     this.agentOpt();
//     this.getOnboardingData();
}
listenScrollEventTermOne() {
    const wrappedElement = document.getElementById('container_terms_and_condition_one');

  if (Math.floor(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= (wrappedElement.scrollHeight/2)) {
        this.setState({termsOne:"1"});
        
  }
  if (Math.floor(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= (wrappedElement.scrollHeight/9)) {
    this.setState({disclose_tooltip:false});
    }
}
listenScrollEventTermThree() {
        const wrappedElement = document.getElementById('container_terms_and_condition_three');

  if (Math.floor(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= (wrappedElement.scrollHeight/2)) {
        this.setState({termsThree:"1"});
        
  }
  if (Math.floor(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= (wrappedElement.scrollHeight/9)) {
    this.setState({third_tooltip:false});
    }
}
listenScrollEventTermTwo() {
    const wrappedElement = document.getElementById('container_terms_and_condition_two');
    if (Math.floor(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= (wrappedElement.scrollHeight/2)) {
          document.getElementById("terms_and_condition_two").disabled = false;
    }
}
async getOnboardingData(){
      let clientRelWhere = [{
        url: "/onboardings/findOne",
        where: {"where":{"clientId":getUserId()}},
    }];
        getApiData(clientRelWhere, true).then(res => {

            let key = encHex.parse("0123456789abcdef0123456789abcdef");
            let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
            var pnumber='';
            if(res.data.phone_number!=12)
            pnumber=aes.decrypt(res.data.phone_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)
            else
            pnumber=res.data.phone_number;

             this.setState({id:res.data.id, 
                name:res.data.first_name.substring(0,res.data.first_name.lastIndexOf(' ')),
                last_name:res.data.first_name.substring(res.data.first_name.lastIndexOf(' ')),
                phone_number:pnumber,
                already_have_agent:res.data.already_have_agent,
                agent_id:res.data.agent_id,
                agent_name:res.data.agent_name,
                signature:res.data.signature,
                is_guarantor:res.data.is_guarantor,
                clientId:res.data.clientId,
                stage:res.data.stage,
                completed_date:res.data.completed_date,
                termsOne:(res.data.terms_one)?true:false,
                termsTwo: (res.data.terms_two)?true:false,
                termsThree:(res.data.terms_three)?true:false,
                is_completed:(res.data.is_completed==1)?true:false,
            });
            this.manageTabStage(this.state.stage);
            var agentKey = (this.state.already_have_agent)?'1':'0';
            document.getElementById("button_is_already_have_agent_"+agentKey).checked = true;
            var guaKey = (this.state.is_guarantor)?'1':'0';
          //  document.getElementById("button_guarantor_"+guaKey).checked = true;
            if(this.state.termsTwo){
                 document.getElementById("terms_and_condition_two").disabled = false;
                 document.getElementById("terms_and_condition_two").checked = true;
            }
            this.showHavingAgent(this.state.already_have_agent);
            this.showGuarantor(this.state.is_guarantor);
        }).catch(error => {
           
        });
}
manageTabStage(tab){
      document.getElementById('onboarding_form_section_one').style.display = "none";
      document.getElementById('onboarding_form_section_two').style.display = "none";
      document.getElementById('onboarding_form_section_three').style.display = "none";
      document.getElementById('onboarding_form_section_four').style.display = "none";
      document.getElementById('onboarding_form_section_five').style.display = "none";
      document.getElementById('onboarding_form_section_six').style.display = "none";
    if(tab){
            document.getElementById(tab).style.display = "block"; 
    }else{
            document.getElementById('onboarding_form_section_one').style.display = "block";
    }
      
}
showHavingAgent(param,show=false){
     if(param=='1'){
                this.setState({already_have_agent:1});
                document.getElementById('agent_wrapper_cont').style.display = "block";
            }else if(param=='0'){
                this.setState({agent_id:0,already_have_agent:0,agent_name:''},()=>{
                    document.getElementById('agent_wrapper_cont').style.display = "none";
                    if(show){
                        this.updateRaw({'already_have_agent':0,'agent_id':0});
                        this.nextStepC("onboarding_form_section_two","onboarding_form_section_three");
                 }

                });
                
            }
             
}

    showGuarantor(param){
       if(param){
          if(param== '1'){
                 this.setState({is_guarantor:'1'});
                 document.getElementById('button_guarantor_0').classList.remove("btn-secondary");
                 document.getElementById('button_guarantor_1').classList.add("btn-secondary");
                  this.updateRaw({'is_guarantor':'1'},true);
            }else if(param=='0'){
                this.setState({is_guarantor:'0'});
                document.getElementById('button_guarantor_1').classList.remove("btn-secondary");
                document.getElementById('button_guarantor_0').classList.add("btn-secondary");
                this.updateRaw({'is_guarantor':'0'},true);
            } 
       }else{
           document.getElementById('button_guarantor_0').classList.remove("btn-secondary");
           document.getElementById('button_guarantor_1').classList.remove("btn-secondary");
       }
    }
     isGuarantor = param => e => {
        e.preventDefault();
        this.showGuarantor(param);   
    };
    
    
        prevStep = param => e => {
            e.preventDefault();
            document.getElementById(param[0]).style.display = "none";
            document.getElementById(param[1]).style.display = "block";
        };
        saveFinal = param => e => {
            e.preventDefault();
            this.updateOnboarding('onboarding_form_section_five','onboarding_form_section_five',false,true);
        };
        nextStep = param => e => {
           e.preventDefault();
           var flag =  this.validateName(this.state.name);
            if(flag){
                document.getElementById(param[0]).style.display = "none";
                document.getElementById(param[1]).style.display = "block";
                if(this.state.id){
                    this.updateOnboarding(param[1]);
                }else{
                    this.saveOnboarding(param[1]);
                }
            }
            
        };
        nextStepC(current,next){
            document.getElementById('show_loader_on_click_id').style.display = "none";
            
             document.getElementById(current).style.display = "none";
             document.getElementById(next).style.display = "block";
             
        }
        saveData(stage,next,isDraft=false){
            document.getElementById('show_loader_on_click_id').style.display = "block";
            if(this.state.id){
                    this.updateOnboarding(stage,next,isDraft);
                }else{
                    this.saveOnboarding(stage,next,isDraft);
                }
        }
        validateStepOne(){
            return this.state.name.length > 0 && this.state.name.length < 30 && this.state.phone_number.length == 12;
        }
        validateStepTwo(){
            if(this.state.already_have_agent === 0){
              return true;  
            }else{
                return this.state.already_have_agent > 0 && this.state.searchMsg!=true && this.state.agent_name.length>0 && this.state.agent_name!=0;
            }
        }
        validateStepThree(){
             return this.state.signature.length > 0 && this.state.signature.trim()!='';
        }
        validateStepFour(){
             return this.state.signature.length > 0;
        }
        validateStepFive(){
            return this.state.termsThree && this.state.signature.length > 0;
 
       }
        
        stepOne = param =>e =>{
           e.preventDefault();
           var name =  this.validateName(this.state.name);
           var last_name =  this.validateName(this.state.last_name);
           var phone =  this.validatePhone(this.state.phone_number);
            if(name && phone && last_name){
                this.saveData(param[1],param[0]);
            }
            this.setState({resendMsg:''});
        }
        stepTwo = param =>e =>{
           
           e.preventDefault();
           var agentname = true;
           if(this.state.already_have_agent==='1'){
              // var agentname =  this.validateAgent(this.state.agent_name);
               var agentname = this.state.agent_id;
               if(agentname){
                //this.nextStepC(param[0],param[1]);
                 this.saveData(param[1],param[0]);
                
            }
           }else{
                //this.nextStepC(param[0],param[1]);
                this.saveData(param[1],param[0]);
           }
           this.setState({resendMsg:''});
           window.scrollTo(0, 0);
   }
        stepThree = param =>e =>{
           e.preventDefault();
           var name =  this.validateSignature(this.state.signature.trim());
            if(name){
                //this.nextStepC(param[0],param[1]);
                this.saveData(param[1],param[0]);
            }
            window.scrollTo(0, 0);
            this.setState({resendMsg:''});
        }
        stepFour = param =>e =>{
           e.preventDefault();
           var name =  this.validateSignature(this.state.signature.trim());
            if(name){
                //this.nextStepC(param[0],param[1]);
                this.saveData(param[1],param[0]);
            }
            this.setState({resendMsg:''});
        }
        stepFive = param =>e =>{
            e.preventDefault();
            var name =  this.validateSignature(this.state.signature.trim());
             if(name){
                 
                 //this.nextStepC(param[0],param[1]);
                 this.saveData(param[1],param[0]);
             }
             this.setState({resendMsg:''});
         }
        async agentOpt(){
		let agentParam = [{
            url: "/agent/agentDD"
		}];
        getApiData(agentParam,true).then(res => {
        this.setState({'agentList':res.data});
        }).catch(error => {
        if(error.response){
            this.setState({myerrormsg: error.response.data.error.message});
            document.getElementById('show_loader_on_click_id').style.display = "none";
            if(error.response.data.error.statusCode!=undefined && error.response.data.error.statusCode=='600'){
                this.setState({logout: true});
            }
            
        }
        });
    }
    
    resendEmailForEmailIdVerification=()=>{
        var clientId=localStorage.getItem('client_id')
        let where = [{
            url: "/Clients/resend/token"
        }];
        postApiData(where,{client_id:clientId}).then(response=>{
            this.setState({resendMsg:response.data.success})
        })
    }
        
        render() {
             if(getToken()){
              if(getOnBoardingDone()==1){
                return (<Redirect to={'/client/dashboard'}/>); 
              }
            }else{
                   return (<Redirect to={'/client/login'}/>);
              }

              if(this.state.logout!=undefined && this.state.logout){
                return (<Redirect to={'/client/logout'}/>);
              }
             const clientDW = this.state.agentList.map((aw,index) => 
		(aw.flag==1) ? <li id="searchkey" key={aw.id} value={aw.id} name={aw.name} onClick={this.handleAgentSearch}>{aw.name}</li>:''
             );
             var account_verify =localStorage.getItem('account_verified');
            
return (
<div>
    <Header />
    <main className="strip-bg">
        <div className="container">
        <div className="show_loader_on_click" id="show_loader_on_click_id" style={{display: 'none' }}></div>
            <div  className="form-panel">
                <Link to ="/"> <img src="/logo.png" width="320" className="mx-auto d-block" /></Link>
                <h2 className="text-center">Onboarding Form</h2>
                {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
                {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
               {this.state.resendMsg == true ? (<div className="alert alert-success"><strong>Account verification link resent successfully</strong></div>):(null) }
                {account_verify == 0 ?  (<div className="alert alert-danger"><strong>Please check your inbox to verify your email address. You must verify your email before applying for an apartment. Please check your spam or junk folder if you haven't received a verification email in the inbox.</strong><Link className="resendLink" to onClick={this.resendEmailForEmailIdVerification} ><u>Resend</u></Link></div>
                
                ):(null)}
                <div className="form-container" id="onboarding_form_section_one">
                    <div className='form-widget login-widget'>
                        <div className="message">Let’s get to know a little more about you</div>
                        <div className="form-section">
                            <form onSubmit={this.stepOne(["onboarding_form_section_one", "onboarding_form_section_two"])}>
                                <label> First Name</label> 
                                <FormGroup controlId="name">
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        required 
                                        placeholder=""
                                        value={this.state.name}
                                        onChange={this.handleChange}
                                       maxLength={30}
                                        />
                                    <span className="inline-error-class"> {this.state.nameerror !==''?this.state.nameerror:''} </span>
                                </FormGroup>

                                <label> Last Name</label> 
                                <FormGroup controlId="last_name">
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        required 
                                        placeholder=""
                                        value={this.state.last_name}
                                        onChange={this.handleChange}
                                       maxLength={30}
                                        />
                                    <span className="inline-error-class"> {this.state.last_name_error !==''?this.state.last_name_error:''} </span>
                                </FormGroup>

                                <label> Phone Number</label>
                                <div className="form-group">
                                 <input 
                                    type='text'
                                    id="phone_number"
                                    className="form-control"
                                    maxLength="12"
                                    value={this.state.phone_number}
                                    onChange={this.handleChange}
                                     placeholder="XXX-XXX-XXXX"
                                    />
                                </div>
                                    <span className="inline-error-class"> {this.state.phoneerror !==''?this.state.phoneerror:''} </span>
                                <ul className="pagination justify-content-center">
                                <li className="page-item left"></li>
                                <li className="page-item mid"></li>
                                <li className="page-item right">
                                <button className="page-link btn active" id="one_step_next_button" type="submit" disabled={!this.validateStepOne()}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                </li>
                            </ul>
                            </form>

                            
                        </div>  
                    </div>
                    <div className="progress-widget">
                        <div className="bar step1">

                        </div>
                    </div>
                </div>
                <div className="form-container" id="onboarding_form_section_two" style={{display:'none'}}>
                    <div className='form-widget login-widget'>
                        <form onSubmit={this.stepTwo(["onboarding_form_section_two", "onboarding_form_section_three"])}>
                            <div className="message">Do you already have a Skyward Agent?</div>
                            <div className="form-section">
                                <div className="btn-wrap">
                                
                            <FormGroup>
                            <label className="container-rd">
                                <input type="radio" name="already_have_agent" value="0" key="0" id="button_is_already_have_agent_0" onClick={this.handleAgent([0])}/>
                                <span className="checkmark"></span>
                                <div className="txt">No</div>
                            </label>
                            <label className="container-rd">
                                <input type="radio"  name="already_have_agent" value="1" key="1" id="button_is_already_have_agent_1" onClick={this.handleAgent([1])}/>
                                <span className="checkmark"></span>
                                <div className="txt">Yes</div>
                            </label>
                            </FormGroup>
                                <div id="agent_wrapper_cont" style={{display:'none'}}>
                                   <label> Agent Name</label>
                                     <div>
                                       <input type="text" id="agent_search_id" onChange={this.handleAgentSearch} onKeyDownCapture={this.handleAgentSearch} onKeyUpCapture={this.handleAgentSearch}  autoComplete="off" value={(this.state.agent_name!=0)?this.state.agent_name:''}  className="form-control"/>
                                        <div className="box agentNameOnBoard" id="agent_search_id_values" style={{display:'none'}} >
                                              <ul>{clientDW}</ul>
                                              <span className="inline-error-class">{(this.state.searchMsg)? this.state._selected_agent_name_+' is not a registered agent':''}</span>
                                        </div>
			            </div>
                                      <span className="inline-error-class"> {this.state.agenterror !==''?this.state.agenterror:''}   </span>
                                </div>
                            </div>
                            <ul className="pagination justify-content-center">
                                <li className="page-item left"><button to="#" className="page-link" onClick={this.prevStep(["onboarding_form_section_two", "onboarding_form_section_one"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>                            
                                <li className="page-item mid"></li>
                                <li className="page-item right">
                                <button className="page-link btn active" type="submit" id="two_step_next_button" disabled={!this.validateStepTwo()}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                </li>
                            </ul>
                    </div>
                    </form>  

                </div>
                <div className="progress-widget">
                    <div className="bar step4">

                    </div>
                </div>
            </div>

            <div className="form-container" id="onboarding_form_section_three" style={{display:'none'}}>

                <div className='form-widget login-widget'>
                    <div className="message">New York State makes us show you this! We’re trying to cut down on paperwork, we promise. </div>
                    <div className="form-section">
                            <form onSubmit={this.stepThree(["onboarding_form_section_three", "onboarding_form_section_four"])}>
                            <div className="form-group"> 
                                      <div className="form-control text-area" id="container_terms_and_condition_one">
                                        <div className="term_one_wrapper">
                                            <div className="clearfix">
                                              <p><img src="/images/app-logo.png" width="60" className="mx-auto d-block" /></p>
                                              <p className="text-center"> <strong>New York State<br/> Department of State Division of Licensing Services
                                              P.O. Box 22001 Albany, NY 12201-2001<br/>
                                              Customer Service: (518) 474-4429<br/>
                                              www.dos.state.ny.us
                                              </strong></p>


                                            </div>

                                        <h4>THIS  IS  NOT  A  CONTRACT</h4>
                                        <p><em>New York State law requires real estate licensees who are  acting  as  agents  of  landlords  and  tenants  of  real property  to  advise  the  potential  landlords  and  tenants with whom they work of the nature of their agency rela-tionship  and  the  rights  and  obligations  it  creates.  This disclosure will help you to make informed choices about your relationship with the real estate broker and its sales agents.</em></p>
                                        <p><em>Throughout the transaction you may receive more than one  disclosure  form.  The  law  may  require  each  agent assisting in the transaction to present  you with this dis- closure form. A real estate agent is a person qualified to advise about real estate.</em></p>
                                        <p><em>If  you  need  legal,  tax  or  other  advice,  consult  with  a professional in that field.</em></p>
                                        <h5>Disclosure Regarding Real Estate Agency Relationships </h5>
                                        <h4>Landlord’s Agent</h4>
                                        <p>A landlord’s agent is an agent who is engaged by a land- lord  to  represent  the  landlord’s  interest.  The  landlord’s agent  does  this  by  securing  a  tenant  for  the  landlord’s apartment  or house at a  rent  and on terms  acceptable to the  landlord.  A  landlord’s  agent  has,  without  limitation, the following fiduciary duties to the landlord: reasonable care,  undivided  loyalty,  confidentiality,  full  disclosure, obedience  and  duty to  account.  A  landlord’s  agent  does not  represent  the  interests  of  the  tenant.  The  obligations of a landlord’s agent are also subject to any specific pro- visions set forth in an agreement between the agent and the  landlord.  In  dealings  with  the  tenant,  a  landlord’s agent should (a) exercise reasonable skill and care in per- formance  of  the  agent’s  duties;  (b)  deal  honestly,  fairly and in good faith; and (c) disclose all facts known to the agent  materially  affecting  the  value  or  desirability  of property, except as otherwise provided by law.</p>
                                        <h4>Tenant’s Agent</h4>
                                        <p>A tenant’s agent is an agent who is engaged by a tenant to represent  the  tenant’s  interest.  The  tenant’s  agent  does this by negotiating the rental or lease of an apartment or house at a rent and on terms acceptable to the tenant. A tenant’s agent has, without limitation, the following fidu- ciary  duties  to  the  tenant:  reasonable  care,  undivided loyalty,  confidentiality,  full  disclosure,  obedience  and duty to account. A tenant’s agent does not represent the interest  of  the  landlord.  The  obligations  of  a  tenant’s agent are also subject to any specific provisions set forth in  an  agreement  between  the  agent  and  the  tenant.  In dealings with the landlord, a tenant’s agent should (a) ex- ercise  reasonable  skill  and  care  in  performance  of  the agent’s duties; (b) deal honestly, fairly and in good faith; and  (c)  disclose  all  facts  known  to  the  agent  materially affecting  the  tenant’s  ability  and/or  willingness  to  per- form  a  contract  to  rent  or  lease  landlord’s  property  that are not consistent with the agent’s fiduciary duties to the tenant.</p>
                                        <h4>Broker’s Agents</h4>
                                        <p>A broker’s agent is an agent that cooperates or is engaged by a listing agent or a tenant’s agent (but does not work for the same firm as the listing agent or tenant’s agent) to assist  the  listing  agent  or  tenant’s  agent  in  locating  a property to rent or lease for the listing agent’s landlord or the  tenant  agent’s  tenant.  The  broker’s  agent  does  not have a direct relationship with the tenant or landlord and the tenant or landlord can not provide instructions or di- rection directly to the broker’s agent. The tenant and the landlord therefore  do not have vicarious liability for the acts  of  the  broker’s  agent.  The  listing  agent  or  tenant’s agent do provide direction and instruction to the broker’s agent and therefore the listing agent or tenant’s agent will have liability for the acts of the broker’s agent.</p>
                                        <h4>Dual Agent</h4>
                                        <p>A real estate broker may represent both the tenant and the landlord  if  both  the  tenant  and  landlord  give  their  in-formed  consent  in  writing.  In  such  a  dual  agency situa- tion, the agent will not be able to provide the full range of fiduciary duties to the landlord and the tenant. The obli- gations of an agent are also subject to any specific provi- sions set forth in an agreement between the agent, and the tenant and landlord. An agent acting as a dual agent must explain carefully to both the landlord and tenant that the agent  is  acting  for  the  other  party  as  well.  The  agent should also explain the possible effects of dual represen- tation,  including  that  by  consenting  to  the  dual  agency relationship  the  landlord  and  tenant  are  giving  up  their right to undivided loyalty.  A landlord and tenant  should carefully  consider  the  possible  consequences  of  a  dual agency  relationship  before  agreeing  to  such  representa- tion. A landlord or tenant may provide advance informed consent  to  dual  agency  by  indicating  the  same  on  this form.</p>
                                        <h4>Dual Agent with Designated Sales Agents</h4>
                                        <p>If the tenant and the landlord provide their informed con- sent  in  writing,  the  principals  and  the  real  estate  broker who represents both parties as a dual agent may designate a  sales  agent  to  represent  the  tenant  and  another  sales agent to represent the landlord. A sales agent works under the  supervision  of  the  real  estate  broker.  With  the  in- formed consent in writing of the tenant and the landlord, the designated sales agent for the tenant will function as the tenant’s agent representing the interests of and advo- cating  on  behalf  of  the  tenant  and  the  designated  sales agent  for  the  landlord  will  function  as  the  landlord’s agent representing the interests of and advocating on be- half of the landlord in the negotiations between the tenant and the landlord. A designated sales agent cannot provide the full range of fiduciary duties to the landlord or tenant. The designated sales agent must explain that like the dual agent under whose supervision they function, they cannot provide  undivided  loyalty.  A  landlord  or  tenant  should carefully  consider  the  possible  consequences  of  a  dual agency  relationship  with  designated  sales  agents  before agreeing to such representation. A landlord or tenant may provide  advance  informed  consent  to  dual  agency  with designated  sales  agents  by  indicating  the  same  on  this form. </p>
                                        
                                        
                                      <p>This form was provided to me by       <strong>{(this.state.agent_name!=0)?this.state.agent_name:'Josiah Hyatt'}</strong> a licensed real estate broker acting in the interest of the:</p>
                                      
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Landlord as a (check relationship below)</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                        <span className="box fill"></span> <span className="info-txt">Tenant as a (check relationship below) </span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Landlord’s agent</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                        <span className="box fill"></span> <span className="info-txt">Tenant’s agent </span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Broker’s agent </span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Broker’s agent</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Dual agent</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                        <span className="box blank"></span> <span className="info-txt">Dual agent with designated sales agent For advance informed consent to either dual agency or dual agency with designated sales agents complete section below:</span>
                                        </label>
                                        </div>

                                        <div className="form-check">
                                        <label className="form-check-label">
                                        <span className="box fill"></span> <span className="info-txt">Advance informed consent dual agency</span>
                                        </label>
                                        </div>
                                        <div className="form-check">
                                        <label className="form-check-label">
                                             <span className="box blank"></span> <span className="info-txt">Advance informed consent to dual agency with designated sales agents</span>
                                        </label>
                                        </div>
                                       
                                                                           
                                       

                                        <p>(We)  <strong>{this.state.name} {this.state.last_name} acknowledge receipt of a copy of this disclosure form</strong> </p>
                                        <p>
                            <strong>Signature : </strong><span className="signaturebox"><i>{this.state.signature}</i></span>
                            </p>
                            <p><strong>DATE :</strong> <span>{this.ShowCurrentDate()}</span></p> 
                                            </div>
                                        </div> 
                            
                                    </div>
                                    <div style={{ display: 'none' }}>
                                        <BrowserView>
                                            <input type="checkbox" onChange={this.handleChecked} defaultChecked={this.state.termsOne} id="terms_condition_one"/>
                                        </BrowserView>
                                        <MobileView>
                                           <input type="checkbox" onChange={this.handleChecked} defaultChecked={true} id="terms_condition_one"/>
                                        </MobileView>
                                         <span> I accept the <u>Terms and Conditions</u></span>
                                      </div>
                          
                            <FormGroup controlId="signature">
                                 <label> Signature Name</label>
                                    <p className="add-txt-panel">By signing below, you acknowledge that you have read the <Link to data-toggle="modal" data-target="#uetaModal">Skyward e-Signature Disclosure</Link> and affirm that the typed signature constitutes a legally binding signature in compliance with the E-SIGN Act and the Uniform Electronic Transactions Act</p>
                                    <FormControl
                                                value={this.state.signature}
                                                onChange={this.handleChange}
                                                type="text"
                                                id="signature"
                                                placeholder="Please type your signature here"
                                                required
                                                />
                <span className="inline-error-class"> {this.state.signatureorerror !==''? this.state.signatureorerror:''}  </span>

                            </FormGroup>
                            <ul className="pagination justify-content-center">
                            <li className="page-item left"><button to="#" className="page-link" onClick={this.prevStep(["onboarding_form_section_three", "onboarding_form_section_two"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>                            
                            <li className="page-item mid"></li>
                            <li className="page-item right">
                            <button className="page-link btn active" type="submit" id="three_step_next_button" disabled={!this.validateStepThree()}>
                             <BrowserView>
                                {this.state.disclose_tooltip?<Tooltip content="Please read discloser till end">Next</Tooltip>:'Next'} <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                
                            </BrowserView>
                            <MobileView>
                                Next <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </MobileView>
                            </button>
                            </li>
                        </ul>
                        </form>
                    </div>  
                </div>
                <div className="progress-widget">
                    <div className="bar step6">

                    </div>
                </div>
            </div>

            <div className="form-container" id="onboarding_form_section_four" style={{display:'none'}}>

                <div className='form-widget login-widget'>
                    <div className="message">Just a heads up, some of the apartments on our site have a fee </div>
                    <div className="form-section">
                        <form onSubmit={this.stepFour(["onboarding_form_section_four", "onboarding_form_section_six"])}>
                            <div className="form-group">                                     
                                    <div className="form-control text-area" id="container_terms_and_condition_two">
                                        <h5 className="text-center">Commission Agreement </h5>
                                        <p><strong>DATE</strong> <span>{this.ShowCurrentDate()}</span></p>
                                        <h4>TL;DR:</h4>
                                        <p>By signing below, you acknowledge that:</p>
                                            <ul>
                                                 <li> Viewing apartments will always be free, but if you apply for one and are approved, there may be a fee. </li>
                                                <li>We’ll always be upfront and let you know the fee in writing.</li>
                                                <li>Let us deal with the landlord! If we showed you the apartment and you’re approved for it (even if you applied directly with the landlord or another broker), you have to pay us the fee on the apartment.</li>
                                            </ul>
                                        <h5>Lawyerspeak:</h5>
                                        <p>There is no fee for viewing apartments through our platform or with one of our agents. Should you decide to lease an apartment that we have shown to you, the written commission on the listing (or 15% of the annual rent if none is specified) is due when you are approved by the Landlord. This fee applies whether or not you move into the apartment. You agree that Skyward is entitled to this commission if you decide to rent an apartment in a property, building or complex shown to you by Skyward or any of its Agents acting on its behalf whether you lease the property through us, the landlord, or another broker. This agreement shall remain in effect for one year AND SHALL SUPERCEDE ANY OTHER AGREEMENT MADE BY ANY AGENT UNLESS APPROVED IN WRITING BY MANAGEMENT. </p>
                                        <p>By signing below, you acknowledge the above terms and authorize us to act as your agent for the procurement of any properties shown to you by Skyward. Under no circumstances shall any form of compensation (monetary or non-monetary) be made payable directly to an Agent acting on behalf of Skyward. All fees must be directly paid to Skyward. </p>
                                        <p>It is also hereby acknowledged that in certain instances Skyward may receive additional compensation from the owner/landlord of the property and such compensation does not forgo or offset brokerage commission that you have agreed to pay to Skyward pursuant to the terms of this client registration/commission agreement. Thank you for allowing us to be of service to you.</p>
                                        
                                        <p><strong>{this.state.name} {this.state.last_name} </strong></p>
                                        
                                       
                                        <p>
                                        <strong>Signature : </strong><span className="signaturebox"><i>{this.state.signature}</i></span>
                            </p>
                                        </div>
                             </div>
                             <Tooltip content="Please read the disclosure till end." >
                             <div>
                                <span>
                                        <BrowserView>
                                            <input type="checkbox" onChange={this.handleCheckedTwo} defaultChecked={this.state.termsTwo} id="terms_and_condition_two"/> I accept the <Link to data-toggle="modal" data-target="#termModal">Terms and Conditions</Link>
                                        </BrowserView>
                                        <MobileView>
                                           <input type="checkbox" onChange={this.handleCheckedTwo} defaultChecked={this.state.termsTwo} id="terms_and_condition_two"/> I accept the <Link to data-toggle="modal" data-target="#termModal">Terms and Conditions</Link>
                                        </MobileView>
                                
                                </span>
                            </div></Tooltip>
                           
                            <FormGroup controlId="signature">
                                 <label> Signature Name</label>
                               
                                    <FormControl
                                                value={this.state.signature}
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder="Please type your signature here"
                                                required
                                               
                                                />
                                       <span className="inline-error-class"> {this.state.signatureorerror !==''? this.state.signatureorerror:''}  </span>
                            </FormGroup>
                            <ul className="pagination justify-content-center">
                            <li className="page-item left"><button to="#" className="page-link" onClick={this.prevStep(["onboarding_form_section_four", "onboarding_form_section_three"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>                            
                            <li className="page-item mid"></li>
                            <li className="page-item right">
                            <button className="page-link btn active" type="submit" id="four_step_next_button" disabled={!this.validateStepFour()}>
                            <BrowserView>
                            {this.state.term_tooltip?<Tooltip content="Accept Terms and Conditions to proceed further.">Next</Tooltip>:'Next'}<i className="fa fa-chevron-right" aria-hidden="true"></i>
                              
                            </BrowserView>
                            <MobileView>
                                Next <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </MobileView>
                            </button>
                           </li>
                        </ul>
                        </form>
                        
                    </div>  
                </div>
                <div className="progress-widget">
                    <div className="bar step6">

                    </div>
                </div>
            </div>

            <div className="form-container" id="onboarding_form_section_six" style={{display:'none'}} >

                <div className='form-widget login-widget'>
                    <div className="message">Wiring Fraud Advisory Notice </div>
                    <div className="form-section">
                        <form onSubmit={this.stepFive(["onboarding_form_section_six", "onboarding_form_section_seven"])}>
                            <div className="form-group">                                     
                                    <div className="form-control text-area" >
                                    <div className="term_three_wrapper">
                                    <p><img src="/images/fraud.png" width="60" className="mx-auto d-block" /></p>

                                        <h6 className="text-center">Wiring Fraud Advisory Notice </h6>
                                        <p><strong>DATE</strong> <span>{this.ShowCurrentDate()}</span></p>
                                        <p>Cybercrime is a potential threat in real estate transactions. Instances have occurred where criminals have hacked email accounts of entities related to real estate transactions (such as lawyers, escrow holder's, title company representatives, or real estate brokers and agents). Using email, hackers have invaded real estate transactions and used fraudulent wiring instructions to direct parties to wire funds to the criminals' bank accounts, often to off-shore accounts, with little chance of recovery. It also appears that some hackers have provided false phone numbers for verifying the wiring instructions. In those cases, the buyers called the number provided to confirm the instructions, and then unwittingly authorized a transfer to somewhere other than escrow. Sellers also have had their sales proceeds taken through similar schemes.</p>
                                        <h4>PLEASE BE ADVISED THAT SKYWARD, WILL NEVER SEND VIA EMAIL WIRING INSTRUCTIONS RELATED TO YOUR TRANSACTION.</h4>
                                        <p>	BUYERS/LESSEES AND SELLERS/LESSORS ARE ALSO ADVISED:</p>
                                            <ul>
                                                 <li>Obtain the phone number of the Escrow Officer at the beginning of the transaction. </li>
                                                <li> NEVER WIRE FUNDS PRIOR TO CALLING YOUR ESCROW OFFICER TO CONFIRM WIRE INSTRUCTIONS. ONLY USE A PHONE NUMBER YOU WERE PROVIDED PREVIOUSLY. Do not use any different phone number included in the emailed wire transfer instructions.</li>
                                                <li>Orally confirm the wire transfer instruction is legitimate and confirm the bank routing number, account numbers and other codes before taking steps to transfer the funds.</li>
                                                <li>Avoid sending personal information in emails or texts. Provide such information in person or over the telephone directly to the Escrow Officer.</li>
                                                <li>Take steps to secure the system you are using with your email account. These steps include creating strong passwords and using secure WiFi as well as two-step verification processes.</li>
                                            </ul>
                                        <p>If an email or a telephone call seems suspicious refrain from taking any action until the communication has been independently verified. Promptly notify your bank, your real estate agent and the Escrow Officer. The sources below, as well as others, can also provide information:</p>
                                        <ul>
				<li contenteditable="false">The Federal Bureau of Investigation <a href="http://www.fbi.gov/">www.fbi.gov</a></li>
				<li contenteditable="false">The National White-Collar Crime Center <a href="http://www.nw3c.org/">www.nw3c.org</a></li>
				<li contenteditable="false">On Guard Online: <a href="http://www.onguardonline.gov/">www.onguardonline.gov</a></li>
			</ul>

            <p>	The undersigned acknowledge receipt of this Advisory.</p>
<p>DATE: <span>________________</span><label>Seller/Landlord: ________________</label></p>
<p>DATE: <span>________________</span><label>Seller/Landlord: ________________</label></p>
<p>DATE: <span>{this.ShowCurrentDate()}</span><br/><label>Buyer/Tenant: {this.state.signature}</label></p>
<p>DATE: <span>________________</span><label>Buyer/Tenant: ________________</label></p>
            
                       
            <p>(For Colorado Residents Only): This form has not been approved by the Colorado Real Estate Commission.</p>
            
            

            
                                
                                        </div>
                             </div>
          
                            <ul className="pagination justify-content-center">
                            <li className="page-item left"><button to="#" className="page-link" onClick={this.prevStep(["onboarding_form_section_six", "onboarding_form_section_four"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>                            
                            <li className="page-item mid"></li>
                            <li className="page-item right">
                             <button className="page-link btn active" type="submit" id="four_step_next_button">
                            <BrowserView>
                            <Tooltip content="Please read the advisory till end.">Next</Tooltip><i className="fa fa-chevron-right" aria-hidden="true"></i>
                              
                            </BrowserView>
                            <MobileView>
                                Next <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </MobileView>
                            </button>
                           </li>
                        </ul>
                        </div>
                        </form>
                        
                    </div>  
                </div>


                <div className="progress-widget">
                    <div className="bar step8">
                    </div>
                </div>
            </div>
            <div className="form-container" id="onboarding_form_section_seven" style={{display:'none'}} >

<div className='form-widget login-widget'>
    <div className="message">NYS Housing Discrimination Disclosure Form </div>
    <div className="form-section">
        <form onSubmit={this.stepFive(["onboarding_form_section_seven", "onboarding_form_section_eight"])}>
            <div className="form-group">                                     
                    <div className="form-control text-area"  >
                    <div className="term_three_wrapper">
                    <p><img src="/inner-logo.png" width="150" className="mx-auto d-block" /></p>
                    <div className="text-center">
                    <p>
                        New York State <br/> <strong>Department of State, Division of Licensing Services</strong> <br/> (518) 474-4429<br/> <a href="https://www.dos.ny.gov/">www.dos.ny.gov</a>
                    </p>
                    <p>
                        New York State <br/> <strong>Division of Consumer Rights</strong> <br/> (888) 392-3644
                    </p>
                    </div>
                        <h6 className="text-center">Division of Licensing Services </h6>
                      
                        <p>Federal, State and local Fair Housing Laws provide comprehensive protections from discrimination in
                                housing. It is unlawful for any property owner, landlord, property manager or other person who sells, rents or
                                leases housing, to discriminate based on certain protected characteristics, which include, but are not limited
                                to <strong>race, creed, color, national origin, sexual orientation, gender identity or expression, military status,
                                sex, age, disability, marital status,</strong> lawful source of income or familial status. Real estate professionals
                                must also comply with all Fair Housing Laws</p>
                        <h4>Real estate brokers and real estate salespersons, and their employees and agents violate the Law if they:</h4>
                            <ul>
                                 <li>Discriminate based on any protected characteristic when negotiating a sale, rental or lease,including representing that a property is not available when it is available</li>
                                <li>Negotiate discriminatory terms of sale, rental or lease, such as stating a different price because of race, national origin or other protected characteristic.</li>
                                <li>Discriminate based on any protected characteristic because it is the preference of a seller or landlord</li>
                                <li>Discriminate by “steering” which occurs when a real estate professional guides prospective buyers or renters towards or away from certain neighborhoods, locations or buildings, based on any protected characteristic.</li>
                                <li> Discriminate by “blockbusting” which occurs when a real estate professional represents that a change has occurred or may occur in future in the composition of a block, neighborhood or area, with respect to any protected characteristics, and that the change will lead to undesirable consequences for that area, such as lower property values, increase in crime, or decline in the quality of schools. </li>
                                <li>Discriminate by pressuring a client or employee to violate the Law. </li>
                                <li> Express any discrimination because of any protected characteristic by any statement, publication, advertisement, application, inquiry or any Fair Housing Law record.</li>
                            </ul>
                            <h4>YOU HAVE THE RIGHT TO FILE A COMPLAINT </h4>
                        <p><strong>If you believe you have been the victim of housing discrimination.</strong> you should file a complaint with the New York State Division of Human Rights (DHR). Complaints may be filed by</p>
                        <ul>
<li contenteditable="false"> Downloading a complaint form from the DHR website: <a href="http://www.dhr.ny.gov/" target="_blank">www.dhr.ny.gov;</a> </li>
<li contenteditable="false">Stop by a DHR office in person, or contact one of the Division’s offices, by telephone or by mail, to obtain a complaint form and/or other assistance in filing a complaint. A list of office locations is available online at:<a href="https://dhr.ny.gov/contact-us" target="_blank"> https://dhr.ny.gov/contact-us</a>, and the Fair Housing HOTLINE at (844)-862-8703).</li>
</ul>
<p>You may also file a complaint with the NYS Department of State, Division of Licensing Services. Complaints may be filed by: </p>
<ul>
<li contenteditable="false">Downloading a complaint form from the Department of State’s <a href="https://www.dos.ny.gov/licensing/complaint_links.html" traget="_blank">website</a> </li>
<li contenteditable="false"> Stop by a Department’s office in person, or contact one of the Department’s offices, by telephone or by mail, to obtain a complaint form.</li>
<li contenteditable="false">Call the Department at (518) 474-4429.</li>
</ul>
<p>There is no fee charged to you for these services. It is unlawful for anyone to retaliate against you for filing a complaint.</p>
<p>For more information on Fair Housing Act rights and responsibilities please visit <a href="https://dhr.ny.gov/fairhousing" target="_blank">https://dhr.ny.gov/fairhousing</a> and <a href="https://www.dos.ny.gov/licensing/fairhousing.html" target="_blank">.</a></p>
<p>This form was provided to me by <span>_<u>{this.state.selected_agent_name}</u>_</span>(print name of Real Estate Salesperson/

Broker) of _<u>Skyward </u>_(print name of Real Estate company, firm or brokerage) </p>
<p>(I)(We) <u>_{this.state.name} {this.state.last_name}</u>_ (Real Estate Consumer/Seller/Landlord) acknowledge receipt of a copy of this disclosure form: </p>
        <p>Real Estate Consumer/Seller/Landlord Signature<span><u>_<span style={{fontFamily:'Caveat, cursive',fontSize:'14px'}}><i>{this.state.signature} </i></span>_</u></span> <span>Date: <u>{this.ShowCurrentDate()}</u></span></p>
       
<p>Real Estate broker and real estate salespersons are required by New York State law to provide you with this Disclosure. </p>

                        </div>
             </div>
           
            <ul className="pagination justify-content-center">
            <li className="page-item left"><button to="#" className="page-link" onClick={this.prevStep(["onboarding_form_section_seven", "onboarding_form_section_six"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>                            
            <li className="page-item mid"></li>
            <li className="page-item right">
             <button className="page-link btn active" type="submit" id="four_step_next_button">
            <BrowserView>
            <Tooltip content="Please read the advisory till end.">Next</Tooltip><i className="fa fa-chevron-right" aria-hidden="true"></i>
              
            </BrowserView>
            <MobileView>
                Next <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </MobileView>
            </button>
           </li>
        </ul>
        </div>
        </form>
        
    </div>  
</div>


<div className="progress-widget">
    <div className="bar step8">
    </div>
</div>
</div>
<div className="form-container" id="onboarding_form_section_eight" style={{display:'none'}} >

<div className='form-widget login-widget'>
    <div className="message">Limitation of Liability Form for Coronavirus (COVID-19) </div>
    <div className="form-section">
        <form onSubmit={this.stepFive(["onboarding_form_section_eight", "onboarding_form_section_five"])}>
            <div className="form-group">                                     
                    <div className="form-control text-area"  >
                    <div className="term_three_wrapper">
                    <p><img src="/rebny_logo.png" width="150" className="mx-auto d-block" /></p>
                    <div className="text-center">
                    <p>
                    <strong> Limitation of Liability Form <p style={{color:'#CB0639'}}>for Coronavirus (COVID-19)</p></strong> 
                    </p>
                    </div>
                        <p>With stay-at-home orders being lifted in New York State, any parties involved in a real estate transaction must continue  to be aware of the risks that are associated with the Coronavirus (COVID-19).</p>
                        
                        <p>Throughout the course of a real estate transaction, it may become necessary for a party to enter or access a residential or commercial property in-person, which raises the possibility of potential liability resulting from exposure to the Coronavirus (COVID-19).</p>
                        
<p>By entering the property or permitting a party to enter the property, you acknowledge that there is an assumption of exposure to the Coronavirus (COVID-19) and any and all consequences that may result from such exposure, including  but not limited to, physical injury, psychological injury, pain, suffering, illness, temporary or permanent disability, death  or economic loss.</p>
<p>This form is intended to notify the parties of the risks associated with conducting property visits in-person. All parties associated with the in-person meeting or showing (including the Agent or Broker) should sign this form. By signing this form, you hereby acknowledge and assume such risks and/or potential consequences.</p>
<p><strong>The undersigned hereby acknowledges receipt of this Coronavirus (COVID-19) Limitation of Liability Form and understands that the refusal to sign this form may result in the cancellation of any scheduled in-person meeting or showing.</strong></p>
<p>Property Address <span>________________</span></p><p><label>Signature:</label><span><u>_<span style={{fontFamily:'Caveat, cursive',fontSize:'14px'}}><i>{this.state.signature} </i></span>_</u></span></p>
<p>Full Name<span>_<u>{this.state.name} {this.state.last_name}</u>_</span><label>Date: _<u>{this.ShowCurrentDate()}</u>_</label></p>
<p> This form was presented to me by: Name of Real Estate Licensee_<u>{this.state.selected_agent_name}</u>_ of Real Estate Brokerage Company _<u>Skyward</u>_</p>
        
       
<p>Please note that this form should not be construed as providing legal advice and you should review this form with an attorney before signing.</p>

                        </div>
             </div>
           
            <ul className="pagination justify-content-center">
            <li className="page-item left"><button to="#" className="page-link" onClick={this.prevStep(["onboarding_form_section_eight", "onboarding_form_section_seven"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>                            
            <li className="page-item mid"></li>
            <li className="page-item right">
             <button className="page-link btn active" type="submit" id="four_step_next_button">
            <BrowserView>
            <Tooltip content="Please read the advisory till end.">Next</Tooltip><i className="fa fa-chevron-right" aria-hidden="true"></i>
              
            </BrowserView>
            <MobileView>
                Next <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </MobileView>
            </button>
           </li>
        </ul>
        </div>
        </form>
        
    </div>  
</div>


<div className="progress-widget">
    <div className="bar step8">
    </div>
</div>
</div>
 
            <div className="form-container" id="onboarding_form_section_five" style={{display:'none'}}>
                <div className='form-widget login-widget'>
                    <div className="message">Are you a guarantor looking to submit an application right now?</div>
                    <div className="form-section">
                   
                        <form onSubmit={this.saveFinal()}> 
                        <div className="btn-wrap">
                            <label className="container-rd">
                                <input type="radio" name="is_guarantor" value="0" id="button_guarantor_0" onClick={this.handleGuarantor}/>
                                <span className="checkmark"></span>
                                <div className="txt" >No</div>
                            </label>
                            <label className="container-rd">
                                <input type="radio"  name="is_guarantor" value="1" id="button_guarantor_1" onClick={this.handleGuarantor}/>
                                <span className="checkmark"></span>
                                <div className="txt" >Yes</div>
                            </label>
                            </div>
                        <FormGroup>
                            </FormGroup>
                        
                            <ul className="pagination justify-content-center">
                            <li className="page-item left"><button to="#" type="button" className="page-link" onClick={this.prevStep(["onboarding_form_section_five", "onboarding_form_section_eight"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>                            
                           <li className="page-item mid"></li> 
                           <li className="page-item right">
                            {/* <button className="page-link btn active" id="five_step_next_button" type="submit">Save <i className="fa fa-chevron-right" aria-hidden="true"></i></button> */}
                           </li>
                        </ul>
                        </form>
                        
                    </div>  
                </div>
                <div className="progress-widget">
                    <div className="bar step10">

                    </div>
                </div>
            </div>

        </div>


</div>

</main>

                        <div id="uetaModal" className="modal fade" role="dialog">
                        <div className="modal-dialog" style={{marginTop:'2em'}}>

                        
                            <div className="modal-content">                           
                            <div className="modal-body">
                                
                            <p>This E Disclosure and Consent is provided in compliance with the Electronic Signatures in Global and National Commerce Act, 15 USC §7001, et seq. (“E-SIGN Act”) and the Uniform Electronic Transactions Act (UETA)
                            </p>
                            <h5>Electronic record and signature disclosure</h5>
                            <p>From time to time, Skyward (we, us or Company) may be required by law to provide to you certain written notices or disclosures. Described below are the terms and conditions for providing to you such notices and disclosures electronically through the Skyward electronic signing system. Please read the information below carefully and thoroughly, and if you can access this information electronically to your satisfaction and agree to these terms and conditions, please type your name into the signature box on the attached form.                            </p>
                            <p><strong>Getting paper copies:</strong> At any time, you may request from us a paper copy of any record provided or made available electronically to you by us. You will have the ability to download and print documents we send to you through the Skyward system during and immediately after signing session and, if you elect to create a Skyward Steel LLC account, you may access them for a limited period of time (usually 30 days) after such documents are first sent to you. After such time, if you wish for us to send you paper copies of any such documents from our office to you, you will be charged a $1.00 per page fee. You may request delivery of such paper copies from us by following the procedure described below.</p>
                            <p><strong>Withdrawing your consent:</strong> If you decide to receive notices and disclosures from us electronically, you may at any time change your mind and tell us that thereafter you want to receive required notices and disclosures only in paper format. How you must inform us of your decision to receive future notices and disclosure in paper format and withdraw your consent to receive notices and disclosures electronically is described below.</p>
                            <p><strong>Consequences of changing your mind:</strong> If you elect to receive required notices and disclosures only in paper format, it will slow the speed at which we can complete certain steps in transactions with you and delivering services to you because we will need first to send the required notices or disclosures to you in paper format, and then wait until we receive back from you your acknowledgment of your receipt of such paper notices or disclosures. To indicate to us that you are are changing your mind, you must withdraw your consent using the Skyward Steel LLC ‘Withdraw Consent’ form on the signing page of a Skyward Steel LLC envelope instead of signing it. This will indicate to us that you  have withdraw your consent to receive required notices and disclosures electronically from us and you will no longer be able to use the Skyward Steel LLC system to receive required notices and consents electronically from us or to sign electronically documents from us.</p>
                            <p><strong>All notices and disclosures will be sent to you electronically:</strong> Unless you tell us otherwise in accordance with the procedures described herein, we will provide electronically to you through the Skyward system all required notices, disclosures, authorizations, acknowledgments, and other documents that are required to be provided or made available to you during the course of our relationship with you. To reduce the chance of you inadvertently not receiving any notice or disclosure, we prefer to provide all of the required notices and disclosures to you by the same method and to the same address that you have given us. Thus, you can receive all the disclosures and notices electronically or in paper format through the paper mail delivery system. If you do not agree with this process, please let us know as described below. Please also see the paragraph immediately above that describes the consequences of your electing not to receive delivery of the notices and disclosures electronically from us.</p>
                            <h5>How to contact Skyward</h5>
                            <p>You may contact us at <a href="mailto:support@moveskyward.com" style={{textDecoration:'underline'}}>support@moveskyward.com</a> to let us know your changes as to how we may contact you.</p>
                                

                              
                                <p className="text-right"><button type="button" className="btn cl-btn" data-dismiss="modal">Close  <img src="/close.png"/></button></p>
                            </div>
                          
                            </div>

                        </div>
                        </div> 

                        <div id="termModal" className="modal fade" role="dialog">
                        <div className="modal-dialog" style={{marginTop:'2em'}}>

                        
                            <div className="modal-content">                           
                            <div className="modal-body">
                             <h5>TERMS AND CONDITIONS OF SERVICE</h5>
                             <p><strong>Thank you for conducting you apartment search with Skyward!</strong></p>   
                             <p>Company reserves the right to change these terms at any time, effective upon the posting of modified terms. It is Your obligation to ensure that You have read, understood and agree to the most recent terms available on the Website.</p>
                            <p>By registering to use the Service and Website You acknowledge that You have read and understood this Agreement and have the authority to act on behalf of any person for whom You are using the Service. You are deemed to have agreed to this Agreement on behalf of any entity for whom You use the Service.</p>
                            <p>By accessing or otherwise using the Service and/or Website, You agree to be bound by the terms of this Agreement. If You do not agree to the terms of this Agreement, do not access or otherwise use the Website or the Service</p>
                            <p><em>These Terms were last updated on February 22, 2019</em></p>
                            
                            <h5>1. DEFINITIONS</h5>
                            <p><strong>"Agreement"</strong> means these Terms of Use.</p>
                            <p><strong>"Confidential Information"</strong> includes all information exchanged between the parties to this Agreement, whether in writing, electronically or orally, including the Service but does not include information which is, or becomes, publicly available other than through unauthorized disclosure by the other party.</p>
                            <p><strong>“Company”</strong> means Skyward Holdings Inc. and any of its affiliates</p>
                            <p><strong>"Data"</strong> means any data provided or facilitated through a third-party service by You to the Website.</p>
                            <p><strong>"Intellectual Property Right"</strong> means any patent, trade mark, service mark, copyright, moral right, right in a design, know-how and any other intellectual or industrial property rights, anywhere in the world whether or not registered.</p>
                            <p><strong>"Service"</strong> means the online employee benefit services made available (as the same may be changed or updated from time to time by Company) via the Website.</p>
                            <p><strong>"Website"</strong>means the website located at the domain <a target="_blank" href="https://www.moveskyward.com" style={{textDecoration:'underline'}}>www.moveskyward.com</a> or any other site or application operated by Company.</p>
                            <p><strong>"You"</strong> means the applicable user of the Website and the Services. "Your" has a corresponding meaning.</p>
                            <h5>2. USE OF SERVICE</h5>
                            <p>Provided compliance with this Agreement, Company grants You the right to access and use the Service via the Website. This right is non-exclusive, non-transferable, and limited by and subject to this Agreement. Given the nature of the Service and the Website, we cannot guarantee that the Service and/or Website will not be modified or that any functionalities will not be added, removed or altered at any time. By using this Service and/or the Website, you acknowledge and agree that (a) Company is not performing any background checks or providing any consumer reports itself and is simply providing a platform on which the applicants, consumer reporting agencies and applicable landlord/broker/property manager can interact with each other, (b) Company is not making any decisions with regards to your application, has no role in the process other than to connect the applicable parties and provide information that has been provided by other third parties and is not responsible for ensuring proper authorization, consent or adverse action letters, (c) the applicable third parties (the consumer reporting agency in the case of providing information or the applicable landlord/broker/property manager in the case of reviewing, approving or rejecting any application) are solely responsible for the services they provide and (d) that Company shall not be responsible in any way for any third party’s failure to properly provide services or comply with applicable law.</p>
                            <h5>3. YOUR OBLIGATIONS</h5>
                            <p><strong> Payment obligations:</strong></p>
                            <p>You agree that Company and/or its customers (as applicable) may charge you for Services at the prices set forth on the Website. Company may turn any uncollected amounts over to an outside collection agency. Subscriber is aware that Company may possibly change the site fees on occasion.</p>
                            <p><strong>General obligations:</strong></p>
                            <p>You must only use the Service and Website for Your own lawful purposes, in accordance with this Agreement and any notice sent by Company or condition posted on the Website.</p>
                            <p><strong>Access conditions:</strong></p>
                            <p>You must ensure that all usernames and passwords required to access the Service are kept secure and confidential. You must immediately notify Company of any unauthorized use of Your passwords or any other breach of security.</p>
                            <p>When accessing and using the Services, You must:</p>
                            <ul>
                                <li>not attempt to undermine the security or integrity of Company's computing systems or networks or, where the Service are hosted by a third party, that third party's computing systems and networks; </li>
                                <li>not use, or misuse, the Service in any way which may impair the functionality of the Service or Website, or other systems used to deliver the Service or impair the ability of any other user to use the Service or Website;</li>
                                <li>not attempt to gain unauthorized access to any materials other than those to which You have been given express permission to access or to the computer system on which the Service and Website are hosted;</li>
                                <li>not transmit, or input into the Website, any: files that may damage any other person's computing devices or software, content that may be offensive, or material or Data in violation of any law (including Data or other material protected by copyright or trade secrets which You do not have the right to use); and </li>
                                <li>not attempt to modify, copy, adapt, reproduce, disassemble, decompile or reverse engineer any computer programs used to deliver the Service or to operate the Website except as is strictly necessary to use either of them for normal operation.</li>
                           </ul>
                           <p><strong>Indemnity:</strong></p>
                            <p>You will indemnify, defend and hold harmless Company against all claims, costs, damage and loss arising from Your breach of any of this Agreement or any obligation You may have to Company.</p>
                            <h5>4. INTELLECTUAL PROPERTY</h5>
                            <p><strong>General:</strong></p>
                            <p>Company retains all right, title and interest in and to the Service and Website, including all Intellectual Property Rights associated therewith. Company does not grant to You any license, by implication or otherwise, to use or copy the Service and Website or any subsequent version of the Service and Website, or any license rights in any patent, copyright or other Intellectual Property Rights owned by or licensed to Company.</p>
                            <p><strong>Ownership of Data:</strong></p>
                            <p>You grant Company a license to use, copy, transmit, store, and back-up Your information and Data for the purposes of enabling You to access and use the Service and for any other purpose related to provision of services to You, other users or our customers.</p>
                            <h5>5. WARRANTIES AND ACKNOWLEDGEMENTS</h5>
                            <p>THE SERVICE AND WEBSITE ARE PROVIDED “AS IS,” WITH NO WARRANTIES WHATSOEVER WITH RESPECT TO THEIR FUNCTIONALITY, OPERABILITY OR USE, AND COMPANY DOES NOT WARRANT THAT THE SERVICE OR WEBSITE WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE SERVICE OR WEBSITE WILL BE UNINTERRUPTED OR THAT THE SERVICE AND WEBSITE ARE ERROR-FREE. THE ENTIRE RISK REGARDING THE QUALITY AND PERFORMANCE OF THE SERVICE OR WEBSITE WITH YOU. COMPANY HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE AND WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, NONINFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE.</p>
                            <p>IN NO EVENT SHALL COMPANY OR ANY OTHER PARTY WHICH HAS BEEN INVOLVED IN THE CREATION, PRODUCTION, OR DELIVERY OF THE SERVICE OR WEBSITE BE LIABLE FOR ANY DAMAGES WHATSOEVER ARISING FROM OR RELATED TO THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOST PROFITS OR LOSSES RESULTING FROM BUSINESS INTERRUPTION OR LOSS OF DATA), REGARDLESS OF THE FORM OF ACTION OR LEGAL THEORY UNDER WHICH THE LIABILITY MAY BE ASSERTED, EVEN IF ADVISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. WITHOUT, ANYWAY LIMITING OR MODIFYING ANY OF THE FOREGOING, YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO USE OF COMPANY SERVICES OR THE TERMS OF SERVICE MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER BARRED.</p>
                            <h5>6. ACKNOWLEDGMENTS</h5>
                            <p>You acknowledge and agree that:</p>
                            <ul>
                                <li> Company has no responsibility to any person other than You and nothing in this Agreement confers, or purports to confer, a benefit on any person other than You. </li>
                                <li> The provision of, access to, and use of, the Service is on an "as is" basis and at Your own risk. </li>
                                <li> It is Your sole responsibility to determine that the Service meet the needs of Your business and are suitable for the purposes for which they are used.</li>
                                <li>You remain solely responsible for complying with all applicable laws.</li>
                                <li>If you are entering sensitive personal data including but not limited to social security numbers, dates of birth, names and address information into the Company web-application please understand you do so at your own risk. Although Company takes security very seriously and has safeguards in place, we do not guarantee the security or backup of this or any other data entered into our web-application. We recommend that you obtain an informed consent form from any person whose sensitive data they will be inputting into the Website.</li>

                            </ul> 
                            <h5>7. TERMINATION</h5>
                            <p>Company reserves the right to cancel the account of any customer if, in the judgment of Company, the subscriber's account is the source or target of a violation of any of the other terms of service or for any other reason which Company sees fit in it and our other users’ best interest. Company reserves the right to terminate service without notice for continued and repeated violations of the terms of service. Prior notification to the Customer is not assured.</p>
                            <h5>8. SUPPORT</h5>
                            <p>While we will use commercially reasonable efforts to assist You, we cannot guarantee any specific level of customer support. If You have technical questions, please contact us at support@moveskyward.com</p>
                            <h5>9. GENERAL</h5>
                            <p>If any provision of this Agreement is held to be invalid or unenforceable under any circumstances, its application in any other circumstances and the remaining provisions of this Agreement shall not be affected. No waiver of any right under this Agreement shall be effective unless given in writing by an authorized representative of Company. No waiver by Company of any right shall be deemed to be a waiver of any other right of Company arising under this Agreement. This Agreement shall be governed and construed in all respects by the laws of the State of New York, without giving effect to principles of conflict of laws. This Agreement is the complete and exclusive statement of the agreement between parties regarding the subject matter hereof, and it supersedes any proposal or prior agreement, oral or written, and any other communication relating to the subject matter hereof. You acknowledge that, in providing You access to and use of the Service and Website, Company has relied on Your agreement to be bound by the terms of this Agreement.</p>






                                

                              
                                <p className="text-right"><button type="button" className="btn cl-btn" data-dismiss="modal">Close  <img src="/close.png"/></button></p>
                            </div>
                          
                            </div>

                        </div>
                        </div>       
</div>


                );
        }
        }
export default withRouter(OnBoarding);
