import React from 'react';
import { Link } from 'react-router-dom';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { GoogleLogin } from 'react-google-login';
import {isEmail} from 'validator';
import { Redirect } from 'react-router';
import {postApiData,getApiData, setAgentToken,setAgentName,getAgentId,setToken,getToken,getAdminToken, getAgentToken,getManagerToken,setAgentId,setAgentEmail,setAgentImage} from '../../services/Api';
//import {GOOGLE_CLIENT_ID} from '../../services/Const';


class UserLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
        'mymsg':"",
        'myerrormsg':"",
         email: "",
         password: "",
         agentname:'',
         redirect:false,
         google_client_id:'',
         myflag:""
    }
    
}
responseGoogle = (data) => {
  if (typeof data.profileObj !== 'undefined'){
      console.log('data.profileObj.imageUrl',data.profileObj.imageUrl);
    localStorage.setItem('agentImageUrl',data.profileObj.imageUrl);
    if ( data.profileObj.email.length ==0) {
      this.setState({emailerror: 'Field is required.'});
      }else if(!isEmail(data.profileObj.email)){
          this.setState({myerror: 'Email is not valid.'});
    }else{
      this.doLogin(data.profileObj.name, data.profileObj.email,data.profileObj.imageUrl);
    }
  }
}

async doLogin(name,email,image) {
  let where = [{
          url: "/agents/auth/login"
      }];
      postApiData(where, {'name':name,'email':email,'agentprofileimage':image}).then(res => {
      if(res.data.id){
          setAgentToken(res.data.id);
         this.setValueStorage(res.data,(err,result)=>{
              if(err){

              }
              if(result){
                  this.setState({redirect:true});
              }
          })
           
      }
  }).catch(error => {
     this.setState({myerrormsg: error.response.data.error.message});
  });
}
setValueStorage(data,cb) {
  console.log('data.agent_image',data.agent_image)
  setAgentToken(data.id);
           setAgentName(data.user_name);
           setAgentId(data.userId);
           setAgentEmail(data.user_email);
           setAgentImage(data.agent_image);
           cb(null,true);
}
validateForm() {
  return this.state.agentname.length > 0 && this.state.email.length > 0 && this.state.password.length > 0;
}
handleChange = (event) => {     
  switch(event.target.id){
    case 'name':     
    if (event.target.value.length ===0) {
          
      this.setState({
                      nameerror: 'Field is required.'
                      ,agentname:event.target.value
                    });
                    break;
      }else{
           this.setState({
                       nameerror: ''
                      ,agentname:event.target.value
                    });
      }
  break;
        case 'email':     
            if (event.target.value.length ===0) {
                  
              this.setState({
                              myerror: 'Field is required.'
                              ,email:event.target.value.toLowerCase()
                            });
                            break;
              }else if (!isEmail(event.target.value)) {
                  
              this.setState({
                              myerror: 'Email is not valid.'
                              ,email:event.target.value.toLowerCase()
                            });
              }else{
                   this.setState({
                              myerror: ''
                              ,email:event.target.value.toLowerCase()
                            });
              }
          break;
          case 'password':        
              if (event.target.value.length<=0) {
                  
              this.setState({
                              myerror2: 'Field is required.'
                              ,password:event.target.value
                            });
              }else if (event.target.value.length<6) {
                  
              this.setState({
                              myerror2: "Minimum length 6 characters"
                              ,password:event.target.value
                            });
              } else if (document.getElementById('confirm_password').value!=="") {
                  if (event.target.value !== document.getElementById('confirm_password').value){
                      this.setState({
                          myerror2: "Passwords don't match!"
                          ,password:event.target.value
                      });
                  }
              }
              else{
                  this.setState({
                              myerror2: ''
                              ,password:event.target.value
                            });
              }
          break;
      case 'confirm_password':
           if (event.target.value.length<=0) {
                  
              this.setState({
                              myerror3: 'Field is required.'
                              ,confirm_password:event.target.value
                            });
              }
              else if (event.target.value !== document.getElementById('password').value) {
              this.setState({
                              myerror3: "Passwords don't match!"
                              ,confirm_password:event.target.value
                            });
              }else{
                 this.setState({
                              myerror3: ''
                              ,confirm_password:event.target.value
                            }); 
              }
          break;
      default:
          break;
  
  }
  // this.setState({
  //     [event.target.id]: event.target.value
  // });
}
handleSubmit = event => {
  event.preventDefault();
  this.onSignup()
}    
async onSignup() {
  let where = [{
          url: "/agents/auth/signup"
      }];
  const {agentname, email, password, confirm_password} = this.state;
  var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;

if (!pattern.test(email)) {
  this.setState({myerrormsg: "Email is not correct."});
}else{
  if(password.length < 6){
      this.setState({myerrormsg: "Minimum length 6 characters"});
  }else{
      if (password !== confirm_password) {
          this.setState({myerrormsg: "Password don't match!"});
      }else{
          postApiData(where, {'email':email,'name':agentname,'emailVerified':0,'password':password,'is_social_login':0,'is_password_set':1})
          .then(res => {
              if(res.data.id){
                  this.setState({email:"",password:"",confirm_password:""});
                  this.setState({myerrormsg: ''});
                  this.setState({customReg:true});
                  this.setState({mymsg: 'Your account created successfully. Please follow the link sent in your email to activate your account.'}); 
                 // this.doLogin(email, password,false); 
              }
          })
          .catch(error => {
              this.setState({myerrormsg: error.response.data.error.message});
              
          });
      }
  } 
}
}

    render() {
      if(getAgentToken()){
        return (<Redirect to={'/agent/application'}/>);
    }
    if(getToken()){
        return (<Redirect to={'/client/dashboard'}/>);
    }
    if(getManagerToken()){
        return (<Redirect to={'/manager/application'}/>);
    }
    if(getAdminToken()){
        return (<Redirect to={'/admin/Agent'}/>);
    }
        return (
                <div>
                  
                        <main>
                            <div className='container'>
                            <div className="logoPlaceholder">
                             <Link to ="/" className="navbar-brand" className="text-center"> <img src="/logo.png" /></Link>
                             </div>
                                <h2 className="text-center">Sign Up to your account</h2>
                                {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
                            {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                            
                                <div className='form-widget login-widget'>
                                <form onSubmit={this.handleSubmit}>
                                <FormGroup controlId="name">
                                    <FormControl
                                      autoFocus
                                     type="name" 
                                     placeholder="Full Name"
                                     value={this.state.agentname}
                                     onChange={this.handleChange}
                                     required
                                 />
                                 <span className="inline-error-class"> {this.state.nameerror !==''?this.state.nameerror:''}</span>
                                </FormGroup>
                                <FormGroup controlId="email">
                                    <FormControl
                                      autoFocus
                                     type="email"
                                     required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
                                     placeholder="Email"
                                     value={this.state.email}
                                     onChange={this.handleChange}
                                     required
                                 />
                                 <span className="inline-error-class"> {this.state.myerror !==''?this.state.myerror:''}</span>
                                </FormGroup>
                                <FormGroup controlId="password">
                                  <FormControl
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    required
                                    placeholder="Password"
                                    required
                                  />
                                 <span className="inline-error-class"> {this.state.myerror2 !==''?this.state.myerror2:''}</span>
                                </FormGroup>
                                <FormGroup controlId="confirm_password">
                                  <FormControl
                                    value={this.state.confirm_password}
                                    onChange={this.handleChange}
                                    type="password"
                                    required
                                    placeholder="Confirm Password"
                                    required
                                  />
                                  <span className="inline-error-class"> {this.state.myerror3 !==''?this.state.myerror3:''}</span>
                                </FormGroup>
                                <Button
                                  block
                                  disabled={!this.validateForm()}
                                  type="submit"
                                >
                                  Sign Up
                                </Button>
       </form>
       <p className="text-center or-txt">Or Sign in with</p>
                                    <div className="form-group"> 
                                   
                                   
                                    </div>
                                    <div className="form-group"> 
                                  
                                    <GoogleLogin clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                      render={renderProps => (
                                    <Link to="#" className="btn google" onClick={renderProps.onClick}>Sign in with your Google account</Link>
                                                                  
                                      )}
                                        buttonText="Login"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        /> 
                                            
                                                                       
                                    </div>
                                   

                                   <div className="need-ac">
                                    Already registered user? <Link to="/agent/landing/">Sign in</Link>
                                   </div>
                                </div>                               
                            </div>
                        </main>
                </div>
                );
    }
}
export default UserLogin;
