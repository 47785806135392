import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { getToken, getOnBoardingDone, getUserId, getApiData, getBaseUrl} from '../services/Api';



class Onboarding extends React.Component {
    async checkOnboarding(){
    let clientRelWhere = [{
        url: "/onboardings/isDone",
        where: {"where":{"clientId":getUserId()}}
    }];
    getApiData(clientRelWhere, true).then(res => {
        this.setState({isOnboardingDone:res.data.onboarding});
        if(!res.data.onboarding){
          this.props.history.push('/client/onboarding');
        }
        }).catch(error => {
           
        });
}
    componentDidMount() {
        this.checkOnboarding();
    }
    render() {
        return (
                <div>
                {this.props.children}
                </div>

                );
    }
}
export default withRouter(Onboarding);
