import React, { Component } from 'react';
import FeedCreatorEdit from '../../agent/listing/FeedCreator';

class ManagerFeedCreatorEdit extends Component {
constructor(props){
    super(props);
}
    render() {
        
        return (
            <div>
                <FeedCreatorEdit 
                userType='Manager'
                heading='Listing Detail Edit'
                listing_id={this.props.match.params.listingid}
                />
            </div>
        );
    }
}

export default ManagerFeedCreatorEdit;