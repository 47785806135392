import React from 'react';
import { Link , withRouter } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from '../../common/Header';
import FilterList from '../../common/FilterList';
import { getApiData,getToken,getUserId,getOnBoardingDone,getBaseUrl, postApiData} from '../../../services/Api';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { Resizable, ResizableBox } from 'react-resizable';
import { Redirect } from 'react-router';
import ListDetails from '../../common/listDetails';
import L from 'leaflet';
import MarkerClusterGroup from './../../../react-leaflet-markercluster';
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';
import './../../../main.scss';
import './../../../leaflet.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


class SaveListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
            resultList:[],
            scrollzoom:false,
            filterValues:{
                bedRoom_0:(props.location.bedRoom_0!=undefined)?props.location.bedRoom_0:-1,
                bedRoom_1:(props.location.bedRoom_1!=undefined)?props.location.bedRoom_1:0,
                bedRoom_2:(props.location.bedRoom_2!=undefined)?props.location.bedRoom_2:0,
                bedRoom_3:(props.location.bedRoom_3!=undefined)?props.location.bedRoom_3:0,
                maxBed:(props.location.maxBed!=undefined && props.location.bedRoom_3==3)?true:false,
                maxPrice:(props.location.maxPrice!=undefined)?props.location.maxPrice.toLocaleString():5000,
                minPrice:(props.location.minPrice!=undefined)?props.location.minPrice.toLocaleString():0,
                amenities:(props.location.amenities!=undefined)?props.location.amenities:'',
                building:(props.location.building!=undefined)?props.location.building:'',
                neighborhood:(props.location.neighborhood!=undefined)?props.location.neighborhood:'',
                vr:(props.location.vr!=undefined)?props.location.vr:'0',
                pageType:"search"
            },
            sendToLogin:false,
            sendToOnBor:false,
            favList:[],
            clickedId:'',
            myerrormsg:'',
            listing_id:'',
            NoRecord:false,
            linkingLoader:false
            
        };
       this.myFunction=this.myFunction.bind(this)
       this.onClickactiveMap=this.onClickactiveMap.bind(this)

       
      }
      // to show hide the detail of property
      toggle=param=>e=>{
        this.setState({clickedId:''});
        this.setState({
            shown: !this.state.shown
		},()=>{
            if(this.state.shown){
                this.setState({clickedId:''});
            }
           
            if(!this.state.shown && param[0]!=''){
                this.setState({clickedId:param[0]});
              }else{
                  if(param[0]!=''){
                    this.setState({clickedId:param[0],shown:false});
                  }
              }
             
              
        });
	    }
        checkLoginBeforeShowing=param=>e=>{
            if(param[0]=='applyNow'){
                let clientId;
                if (getToken()) 
                {
                    console.log("building", param[3]);
                    console.log("address", param[1]);
                    console.log("unit", param[2]);
    
                    clientId=getUserId();
                        if(clientId!='' && clientId!=null){
                        let filter={
                            clientId:clientId,
                        };
    
                        let checkForApplyNow = [{
                            url: "/checkForApplyNow",
                            where:filter
                        }];
                        getApiData(checkForApplyNow,true).then(res => {
                            console.log("res applyNow---",res.data);
                            if(res.data.length<=0){
                                this.setState({redirectOnborading: true, redirectApplyNow:false});      //redirect to onboarding
                            }
                            else if(!res.data[0].is_completed)
                            {
                                this.setState({redirectOnborading: true , redirectApplyNow:false});      //redirect to onboarding
                            }
                            else if(!res.data[0].emailVerified){
                                this.setState({redirectDashboard:true , redirectApplyNow:false});   //redirect to dashboard
                            }
                            else{
                                   // this.setState({redirectOnborading: false,redirectDashboard:false, redirectApplyNow:true});
                                    this.setState({redirectOnborading: false,redirectDashboard:false, redirectApplyNow:true},()=>{
                                        // this.props.history.push({
                                        //     pathname:"/client/application",
                                        //     state: { apartment_address: param[1], unit_number: param[2] }
                                            
                                        //    });
                                        localStorage.setItem('apartment_address',JSON.stringify(param[1]));
                                        localStorage.setItem('unit_number',JSON.stringify(param[2]));
                                        localStorage.setItem('building_id',JSON.stringify(param[3]));
                                });
                            }
                        }).catch(error => {
                            if(error.response){
                                this.setState({myerrormsg: error.response.data.error.message});
                            }
                        });
                    }
                    //return true;
                }
                else{
                    this.setState({sendToLogin:true});
                }
            }
            else
            {
                if (getToken()) 
                {
                    return true;
                }
                else{
                    this.setState({sendToLogin:true});
                } 
            }
            
    
        }
        onClickactiveMap(){
     
            var x = document.getElementById("disableMapWrap");
            if (x.style.display === "none") {
              x.style.display = "block";
            } else {
              x.style.display = "none";
            }
            this.setState({scrollzoom:true});
            }   
      myFunction() {
        var x = document.getElementById("myFilter");
        if (x.style.display === "block") {
          x.style.display = "none";
        } else {
          x.style.display = "block";
        }
      }
      AdvanceSearch() {
        var x = document.getElementById("A_Filter");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      }
      componentWillMount(){
          //console.log("getOnBoardingDone()",getOnBoardingDone())
          if(getOnBoardingDone()==1){
            this.OnLoadRun();
          }else{
            this.setState({sendToOnBor:true});
          }
        
      }
      modalClose=param=>e=>{
        this.setState({
        shown: !this.state.shown
    },()=>{
        if(this.state.shown){
            this.setState({clickedId:''});
        }
    });
    }
    OnLoadRun(){
        this.setState({
            linkingLoader:true
        })
        let userId;
        if (getToken()) {
            userId=getUserId();
          let filter={
                userId:userId,
            };
            let saveToFav = [{
                url: "/favList",
                where:filter
            }];
            getApiData(saveToFav,true).then(res => {
                   this.setState({favList:res.data,linkingLoader:false},()=>{
                   this.getListAfterFilterApplied({});
                   });

            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message,linkingLoader:false});
                }
            });
        }
      }
      /**
       * this function will save this list in user fav list
       */
      saveToFavList=param=>e=>{
        let listindId=param[0];
        let userId;
        if (getToken()) {
            userId=getUserId();
            if(userId!='' && userId!=null){
            let filter={
                userId:userId,
                listId:listindId
            };

            let saveToFav = [{
                url: "/saveToFav",
                where:filter
            }];
            getApiData(saveToFav,true).then(res => {
                this.OnLoadRun();
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message});
                }
            });
        }
        }else{
            this.setState({sendToLogin:true});
        }
    }
      /**
       * this function will handle return value from Filter
       * And get the value from DB
       */
      getListAfterFilterApplied(filter){
          let userId=getUserId();
            let getFilteredRecord = [{
                url: "/listing",
                where:{
                    favList:true,
                    userId:userId
                }
            }];
            getApiData(getFilteredRecord,true).then(res => {
                this.setState({resultList:res.data,NoRecord:(res.data.length<1)?true:false});
                this.setState({listing_id:res.data[0].id});
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message});
                }
            });
        }
        componentDidMount() {
            window.scrollTo(0, 0);
           
          }
    render() {
        var a='';
        if (!getToken()) {
            return (<Redirect to={'/client/login'}/>);
          }
        if(this.state.sendToLogin==true){
            return (<Redirect to={'/client/login'}/>);
        }
        if(this.state.sendToOnBor==true){
            return (<Redirect to={'/client/onboarding'}/>);
        }
        var a='';
        console.log("searchType",this.state.searchType);
        if(this.state.sendToLogin==true){
            return (<Redirect to={'/client/login'}/>);
        }
        else {
            if(this.state.redirectOnborading==true){
                return (<Redirect to={'/client/onboarding'}/>);
            }
            else  if(this.state.redirectDashboard==true){
                return (<Redirect to={'/client/dashboard'}/>);
            }
            else  if(this.state.redirectApplyNow==true){
                return (<Redirect to={'/client/application/'}/>);
               
            }
        }
        var shown = {
			display: this.state.shown ? "block" : "none"
		};
		
		var hidden = {
			display: this.state.shown ? "none" : "block"
		}
        if(this.state.resultList!=undefined){
        var result = this.state.resultList.map((item, index)=>{
            return <ResizableBox className="box "  draggableOpts={{grid: [25, 25]}}>
            {/* <Link to data-toggle="modal" data-target="#ListingModal" href="/"> */}
            <div className="list-frame">
            {item.noFee?<div className="tag"></div>:''}
            <Carousel
  additionalTransfrom={0}
  arrows
  autoPlaySpeed={3000}
  centerMode={false}
  containerClass="container"
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  }}
  showDots
  sliderClass=""
  slidesToSlide={1}
  swipeable
>
                {item.image.id!=0 && item.image!='' ? item.image.map(img_data=>{
                     let img =(img_data.thumbnail_image!='' && img_data.thumbnail_image!=null)?img_data.thumbnail_image:'/listing-img.png';
                     let check=img.indexOf("https://");
                     let image=(check >-1 || img=='/listing-img.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
                 return    <Link to onClick={this.toggle([item.id])}> <img src={image} /></Link>
                })  :  <Link to onClick={this.toggle([item.id])}>  <img src='/listing-img.png' /></Link>}
            </Carousel>
                <div className="listing-info clearfix">
                <h3 className="address">{item.listing_info_address}</h3>
                <div className="cleafix">
                <div className="pull-left"><p class="area">{unescape(item.neighborhood)}</p>
                <p class="price">{item.price?'$'+item.price.toLocaleString():'$'+item.price}</p>
                <p class="room-info">{item.bedrooms} br, {item.bathrooms} ba</p></div>
                <div className="pull-right"><Link to className={(this.state.favList.length>0 && this.state.favList.find(list => list.listId === item.id))?'star-block active':'star-block'} id={`fav_`+item.id} onClick={this.saveToFavList([item.id])}><svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="star" d="M33.6667 12.4L21.6834 11.3667L17 0.333374L12.3167 11.3834L0.333374 12.4L9.43337 20.2834L6.70004 32L17 25.7834L27.3 32L24.5834 20.2834L33.6667 12.4ZM17 22.6667L10.7334 26.45L12.4 19.3167L6.86671 14.5167L14.1667 13.8834L17 7.16671L19.85 13.9L27.15 14.5334L21.6167 19.3334L23.2834 26.4667L17 22.6667Z" fill="#BEB7B3"/>
</svg></Link></div>
                </div>
                </div>
                </div>
                {/* </Link> */}
            </ResizableBox>
        });
      
    }
    if(this.state.NoRecord){
        result =<div>You have no Saved Listings</div>
    }
      
        return (
<div className="w-bg FrontEnd">
    <Header />
    <main>
    {(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''}

    {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
        <div className="container listing-container">
                    <h2 className="text-center">Saved Listings</h2>
                    <br/>
                    
                       

    </div>
    <div className="listing-result">
                        <SplitterLayout percentage secondaryInitialSize={35}>
                        <div class="block">
                                {result}
                            </div>
                            <div >
                            <div class="map-section" style={shown}>
                            
        <Map className="markercluster-map" center={[40.78611770186631, -73.97203551192757]} zoom={12} maxZoom={18} minZoom={4}>
          
                        <TileLayer
                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
                    
                        <MarkerClusterGroup>
                    
                        {this.state.resultList.map((s, index)=>{
                     
                  
                     return a=(s.latitude!='null' && s.longitude!='null' && s.longitude!='undefined')?<Marker key={s.id} position={[`${s.latitude}`, `${s.longitude}`]}  onClick={this.toggle([s.id])}>
                    {/* <Popup>
                        <div>
                        {s.listing_info_address}<br />
                        <b>Unit:</b> {s.unit_number}<br />
                        <b>Layout:</b> {s.bedrooms}BD / {s.bathrooms}BA<br />
                        <b>Price:</b> ${s.price.toFixed(2)}
                        </div>
                    </Popup> */}
                    </Marker>:''
                  
              }
                    )};
                            
                    
                        </MarkerClusterGroup>
                        
                    
                        </Map>
                            </div>
                            <div id="ListingModal">
                        <div className="modal-dialog" style={{margin:'0px 10px'}}>
                            {(this.state.clickedId!='')?<ListDetails _listId_={this.state.clickedId} closeModal={this.modalClose.bind(this)} clickToggle={this.toggle.bind(this)} saveToMYFavList={this.saveToFavList.bind(this)} checkLoginFORShowing={this.checkLoginBeforeShowing.bind(this)}/>:''}
                        </div>
                        </div>

                            </div>
                        </SplitterLayout>
                </div>
</main>
<footer>
                   <div className="container">
                   <div className="clearfix">
                       <ul className="left-nav">
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img src="/home-icon.png"/></li>
                       </ul>
                       <div className="logo"></div>
                       <ul className="right-nav">
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
<p className="text-center"><small>99 Park Avenue, 10th Fl |  New York, NY 10016 |  212.498.8890</small></p>
                   </div>
               </footer>   
</div>


                );
        }
        }
export default withRouter(SaveListing);
